import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Link,
  Loader,
  LoadingModal,
  OrgAwareLink,
  TabModal,
  TabModalPane,
} from "@incident-ui";
import React, { useState } from "react";
import { useIntercom } from "react-use-intercom";
import {
  DebriefPlaceholder,
  DebriefSettings,
  DebriefsGenerateCalendarURLResponseBody,
  GoogleWorkspaceCalendar,
  Identity,
  Incident,
  IntegrationSettingsProviderEnum as IntegrationProvider,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI } from "src/utils/swr";
import { sendToSentry, useDebriefName } from "src/utils/utils";

import { useClipboard } from "../../../../utils/useClipboard";
import { ScheduleDebriefInPlaceholderModal } from "./ScheduleDebriefInPlaceholderModal";

export enum ScheduleDebriefMode {
  // In default mode, we'll explain we're going to push them to a pre-filled GCal event.
  Default = "default",
  // In placeholder mode, we'll explain that we're going to create the event for them
  // in one of their placeholders.
  Placeholder = "placeholder",
}

export const ScheduleDebriefModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement => {
  const { debriefNameLower } = useDebriefName();
  const { identity } = useIdentity();

  const { data: settingsResp, isLoading: loadingSettings } = useAPI(
    "debriefsShowSettingsForIncident",
    {
      incidentId: incident.id,
    },
  );

  const { data: placeholdersResp, isLoading: loadingPlaceholders } = useAPI(
    "debriefsListPlaceholders",
    undefined,
  );

  if (loadingSettings || loadingPlaceholders) {
    return (
      <LoadingModal
        isOpen={true}
        title={`Schedule ${debriefNameLower}`}
        onClose={() => null}
      />
    );
  }

  if (!placeholdersResp) {
    return (
      <GenericErrorMessage description="We couldn't load your placeholders." />
    );
  }

  if (!settingsResp) {
    return (
      <GenericErrorMessage description="We couldn't load your Debrief settings." />
    );
  }

  return (
    <ScheduleDebriefModalInner
      incident={incident}
      debriefSettings={settingsResp.settings}
      placeholders={placeholdersResp.placeholders}
      onClose={onClose}
      identity={identity}
    />
  );
};

const ScheduleDebriefModalInner = ({
  incident,
  debriefSettings,
  placeholders,
  onClose,
  identity,
}: {
  incident: Incident;
  debriefSettings: DebriefSettings;
  placeholders: DebriefPlaceholder[];
  onClose: () => void;
  identity: Identity;
}): React.ReactElement => {
  const [mode, setMode] = useState<ScheduleDebriefMode>(
    identity.feature_gates.debrief_placeholders && placeholders.length > 0
      ? ScheduleDebriefMode.Placeholder
      : ScheduleDebriefMode.Default,
  );

  if (mode === ScheduleDebriefMode.Placeholder) {
    return (
      <ScheduleDebriefInPlaceholderModal
        incident={incident}
        debriefSettings={debriefSettings}
        placeholders={placeholders}
        onClose={onClose}
        setMode={setMode}
      />
    );
  } else {
    return (
      <DefaultScheduleDebriefModal
        incident={incident}
        debriefSettings={debriefSettings}
        onClose={onClose}
      />
    );
  }
};

const DefaultScheduleDebriefModal = ({
  incident,
  debriefSettings,
  onClose,
}: {
  incident: Incident;
  debriefSettings: DebriefSettings;
  onClose: () => void;
}): React.ReactElement => {
  const { debriefNameLower } = useDebriefName();

  const { data: urlResp, isLoading: loadingUrl } = useAPI(
    "debriefsGenerateCalendarURL",
    {
      incidentId: incident.id,
    },
  );

  return (
    <TabModal
      analyticsTrackingId="schedule-a-debrief"
      isOpen
      onClose={onClose}
      title={`Schedule ${debriefNameLower}`}
      suppressInitialAnimation={true}
      tabs={[
        {
          id: "google-calendar",
          label: "Google Calendar",
        },
        {
          id: "microsoft-outlook",
          label: "Microsoft Outlook",
        },
        {
          id: "other",
          label: "Other",
        },
      ]}
    >
      {loadingUrl ? (
        <Loader />
      ) : (
        <>
          <GoogleCalendarTab
            debriefSettings={debriefSettings}
            incident={incident}
            urlResp={urlResp}
          />
          <MicrosoftOutlookTab urlResp={urlResp} />
          <OtherTab />
        </>
      )}
    </TabModal>
  );
};

const ScheduleButton = ({
  onClick,
  icon,
  openIn,
  className,
}: {
  onClick: () => Window | null;
  icon: IconEnum;
  openIn: string;
  className: string | undefined;
}): React.ReactElement => {
  return (
    <Button
      analyticsTrackingId={null}
      theme={ButtonTheme.Secondary}
      onClick={onClick}
      className={className}
      icon={icon}
    >
      Open in {openIn}
    </Button>
  );
};

const OtherTab = (): React.ReactElement => {
  return (
    <TabModalPane
      tabId="other"
      className="flex flex-col gap-4 text-sm text-slate-800 !pt-0"
    >
      <UseAnotherCalendarButton />
    </TabModalPane>
  );
};

const GoogleCalendarTab = ({
  debriefSettings,
  incident,
  urlResp,
}: {
  debriefSettings: DebriefSettings;
  incident: Incident;
  urlResp: DebriefsGenerateCalendarURLResponseBody | undefined;
}): React.ReactElement => {
  const { debriefNameWithArticleLower } = useDebriefName();
  const { integrations } = useIntegrations();

  const { data: calendarsResp, isLoading: loadingCalendars } = useAPI(
    "integrationsGoogleWorkspaceGetCalendars",
    undefined,
  );

  if (loadingCalendars || !integrations) {
    return <Loader />;
  }

  if (!calendarsResp) {
    return (
      <GenericErrorMessage description="We couldn't load your Google Workspace calendars." />
    );
  }

  if (!urlResp) {
    return (
      <GenericErrorMessage description="We couldn't load the google calendar URL." />
    );
  }

  const GCalIntegration = integrations.find(
    (integration) =>
      integration.provider === IntegrationProvider.GoogleCalendar,
  );
  if (!GCalIntegration) {
    throw new Error("unreachable: GCal integration not found");
  }
  const googleCalendarIsInstalled = GCalIntegration.installed;
  const googleCalendarIsBroken = GCalIntegration.reconnection_reason !== "";

  let calendarShouldCreateIn: GoogleWorkspaceCalendar | undefined = undefined;
  if (
    googleCalendarIsInstalled &&
    debriefSettings.should_use_calendar_id &&
    debriefSettings.calendar_id
  ) {
    calendarShouldCreateIn = calendarsResp.calendars.find(
      (calendar) => calendar.calendar_id === debriefSettings.calendar_id,
    );
    if (!calendarShouldCreateIn) {
      throw sendToSentry(
        "unreachable: cannot find calendar in subscribed calendars.",
        {
          calendars: calendarsResp.calendars,
          calendarID: debriefSettings.calendar_id,
        },
      );
    }
  }

  return (
    <TabModalPane
      tabId="google-calendar"
      className="flex flex-col gap-4 text-sm text-slate-800"
    >
      {!googleCalendarIsInstalled ? (
        // We can still build a pre-filled event for them, but ask them to install the integration
        <>
          <GoogleCalendarNotInstalledCallout />
          <div className="space-y-2">
            <GoToCalendarProviderInstructions
              debriefNameWithArticleLower={debriefNameWithArticleLower}
              calendarProvider="Google Calendar"
            />
          </div>
        </>
      ) : (
        <>
          <GoToCalendarProviderInstructions
            debriefNameWithArticleLower={debriefNameWithArticleLower}
            calendarProvider="Google Calendar"
          />
          <AutoAttachInstructions
            incident={incident}
            calendarShouldCreateIn={calendarShouldCreateIn}
            googleCalendarIsBroken={googleCalendarIsBroken}
          />
        </>
      )}
      <ScheduleButton
        onClick={() => window.open(urlResp.google_calendar_url, "_blank")}
        icon={IconEnum.GoogleCalendar}
        openIn="Google Calendar"
        className="self-center"
      />
    </TabModalPane>
  );
};

const MicrosoftOutlookTab = ({
  urlResp,
}: {
  urlResp: DebriefsGenerateCalendarURLResponseBody | undefined;
}): React.ReactElement => {
  const { debriefNameWithArticleLower } = useDebriefName();

  if (!urlResp) {
    return (
      <GenericErrorMessage description="We couldn't load the Microsoft Outlook URL." />
    );
  }

  return (
    <TabModalPane
      tabId="microsoft-outlook"
      className="flex flex-col gap-4 text-sm text-slate-800"
    >
      <Callout theme={CalloutTheme.Info}>
        We do not support linking Outlook calendar invites to incidents. We will
        not see any updates, or changes to this calendar invite.
      </Callout>
      <GoToCalendarProviderInstructions
        debriefNameWithArticleLower={debriefNameWithArticleLower}
        calendarProvider="Microsoft Outlook"
      />
      <ScheduleButton
        onClick={() => window.open(urlResp.microsoft_outlook_url, "_blank")}
        icon={IconEnum.Outlook}
        openIn="Microsoft Outlook"
        className="self-center"
      />
    </TabModalPane>
  );
};

const CopyToClipboardButton = ({
  value,
}: {
  value: string;
}): React.ReactElement => {
  const { hasCopied, copyTextToClipboard } = useClipboard();

  return (
    <Button
      icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
      analyticsTrackingId="copy-calendar-id"
      type="button"
      title="Copy to clipboard"
      iconProps={{
        className: hasCopied ? "text-green-content" : "text-slate-700",
        size: IconSize.Large,
      }}
      theme={ButtonTheme.Secondary}
      onClick={() => copyTextToClipboard(value)}
      className={"mt-2 !font-normal !transition-none"}
    >
      Copy calendar ID
    </Button>
  );
};

const GoToCalendarProviderInstructions = ({
  debriefNameWithArticleLower,
  calendarProvider,
}: {
  debriefNameWithArticleLower: string;
  calendarProvider: string;
}): React.ReactElement => {
  return (
    <span>
      We&apos;ll help you schedule {debriefNameWithArticleLower} with{" "}
      {calendarProvider}.
    </span>
  );
};

const GoogleCalendarNotInstalledCallout = (): React.ReactElement => {
  return (
    <Callout theme={CalloutTheme.Info} iconOverride={IconEnum.New}>
      It&apos;s now possible to link this event to your incident, so everyone
      knows when it is! Head to the{" "}
      <Link
        to={"/settings/integrations/google_calendar"}
        analyticsTrackingId="schedule-debrief-google-calendar-integration-link"
      >
        Google Calendar integration
      </Link>{" "}
      to get set up.
    </Callout>
  );
};

const UseAnotherCalendarButton = (): React.ReactElement => {
  const { showMessages } = useIntercom();
  return (
    <span className="-mt-2">
      <span>
        {
          "We don't support other calendar providers currently. If you use a different calendar, please "
        }
      </span>
      <Button
        analyticsTrackingId="use-another-calendar-link"
        theme={ButtonTheme.Link}
        onClick={() => showMessages()}
      >
        let us know
      </Button>
    </span>
  );
};

const AutoAttachInstructions = ({
  incident,
  calendarShouldCreateIn,
  googleCalendarIsBroken,
}: {
  incident: Incident;
  calendarShouldCreateIn: GoogleWorkspaceCalendar | undefined;
  googleCalendarIsBroken: boolean;
}): React.ReactElement => {
  const { debriefNameLower } = useDebriefName();
  const { showArticle } = useIntercom();
  const { data: configResp, isLoading: configLoading } = useAPI(
    "integrationsGoogleWorkspaceGetConfig",
    undefined,
  );

  // While this is visible, poll the debriefs meeting list quite a lot, so that
  // when we get the webhook from Google Calendar, this page updates right away
  useAPI(
    "debriefsListIncidentDebriefs",
    { incidentId: incident.id },
    { refreshInterval: 1000 },
  );

  if (configLoading || !configResp) {
    return <Loader />;
  }

  if (googleCalendarIsBroken) {
    return (
      <Callout theme={CalloutTheme.Warning}>
        There&apos;s a problem with your connection to Google Calendar, which
        means we won&apos;t be able to automatically attach this event to your
        incident.{" "}
        <OrgAwareLink to="/settings/integrations" className="underline">
          Reconnect on the Integrations page
        </OrgAwareLink>
        .
      </Callout>
    );
  }

  return (
    <>
      <span>
        {/* This component only ever follows the blurb about adding to GCal, so we need a leading space. */}{" "}
        Just be sure to keep{" "}
        <span className="font-semibold">{`INC-${incident.external_id}`}</span>{" "}
        and the word <span className="font-semibold">{debriefNameLower}</span>{" "}
        in the title to have it linked automatically.
      </span>
      {calendarShouldCreateIn ? (
        <Callout theme={CalloutTheme.Info}>
          <div>
            Your configuration requires that your debriefs are added to the{" "}
            <span className="font-semibold">{calendarShouldCreateIn.name}</span>{" "}
            calendar. If you can&apos;t see it as an option, copy the Calendar
            ID and{" "}
            <Button
              onClick={() => showArticle(8287505)}
              theme={ButtonTheme.Link}
              analyticsTrackingId={`gcal-setup-more-information`}
            >
              follow these steps
            </Button>
            .
          </div>
          <CopyToClipboardButton value={calendarShouldCreateIn.calendar_id} />
        </Callout>
      ) : (
        <Callout theme={CalloutTheme.Info}>
          Make sure that the connected user (
          <span className="font-semibold">
            {configResp.config.connecting_user.email}
          </span>
          ) stays on the list!
        </Callout>
      )}
    </>
  );
};
