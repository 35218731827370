import { EngineParamBindingValue } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import React from "react";
import { useAllResources } from "src/hooks/useResources";

import { ReferenceSource, referenceSource } from "../referenceSource";
import { EngineLiteralBadge } from "./EngineLiteralBadge";
import { EngineReferenceBadge } from "./EngineReferenceBadge";

export type EngineBindingBadgeProps = {
  referenceSource?: ReferenceSource;
  className?: string;
  value: EngineParamBindingValue;
  resourceType: string;
  mini?: boolean;
};

export const EngineBindingValueBadge = ({
  value,
  referenceSource: source,
  className,
  resourceType,
  mini = false,
}: EngineBindingBadgeProps): React.ReactElement => {
  // We assume that something higher up the chain needed this before we got here, but in case
  // we don't already have it we'll render a fallback if we can't find our resource
  const { resources } = useAllResources();

  if (value.reference) {
    return (
      <EngineReferenceBadge
        label={value.label}
        referenceSource={source || referenceSource(value.reference)}
        mini={mini}
        className={className}
      />
    );
  }

  const ourResource = resources.find(
    (resource) => resource.type === resourceType,
  );
  let icon: IconEnum = IconEnum.Box;
  let color = ColorPaletteEnum.Blue;
  if (ourResource) {
    icon = ourResource.field_config.icon as unknown as IconEnum;
    if (ourResource.field_config.color) {
      color = ourResource.field_config.color as unknown as ColorPaletteEnum;
    }
  }

  return (
    <EngineLiteralBadge
      className={className}
      icon={icon}
      color={color}
      mini={mini}
      label={value.label || value.literal || ""}
    />
  );
};
