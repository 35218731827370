import {
  AvailableIncidentFormEscalationElementElementTypeEnum,
  CatalogEntry,
  IncidentFormEscalationElement,
  IncidentFormsPreviewEscalateFormResponseBody,
  IncidentFormsSubmitEscalateFormRequestBody,
  IncidentVisibilityEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import {
  getCatalogTypeaheadOptions,
  hydrateInitialCatalogOptions,
} from "@incident-shared/catalog";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxRowV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import {
  DynamicMultiSelectV2,
  DynamicSingleSelectV2,
} from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconBadge,
  IconEnum,
  IconSize,
  Spinner,
} from "@incident-ui";
import {
  Drawer,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import React, { useState } from "react";
import { useForm, useFormContext, useFormState } from "react-hook-form";
import {
  IncidentFormFormTypeEnum as FormTypeEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { useIncident } from "../legacy/incident/hooks";

export const EscalationDrawer = ({
  incidentId,
  showDeclareIncident = true,
  onClose,
  onDeclareIncident,
}: {
  incidentId?: string;
  showDeclareIncident?: boolean;
  onClose: () => void;
  onDeclareIncident?: (string) => void;
}) => {
  const incident = useIncident(incidentId || null)?.incident;

  const {
    data: { incident_forms: forms },
    error: formsError,
    isLoading: formsLoading,
  } = useAPI("incidentFormsListForms", undefined, {
    fallbackData: { incident_forms: [] },
  });

  const incidentTypeSpecificForm = forms.find(
    (f) =>
      f.form_type === FormTypeEnum.Escalate &&
      incident?.incident_type?.id &&
      f.incident_type_id === incident?.incident_type?.id,
  );

  const defaultForm = forms.find(
    (f) => f.form_type === FormTypeEnum.Escalate && !f.incident_type_id,
  );

  const escalateForm = incidentTypeSpecificForm ?? defaultForm;

  const {
    data: { incident_form_elements: elements },
    error: elementsError,
    isLoading: elementsLoading,
  } = useAPI(
    escalateForm ? "incidentFormsListEscalationElements" : null,
    { incidentFormId: escalateForm?.id || "" },
    { fallbackData: { incident_form_elements: [] } },
  );

  if (formsLoading || elementsLoading) {
    return <Spinner />;
  }

  if (elementsError || formsError || !escalateForm) {
    return <ErrorModal onClose={onClose} />;
  }

  return (
    <EscalateFormInner
      incidentId={incidentId}
      showDeclareIncident={showDeclareIncident}
      onClose={onClose}
      onDeclareIncident={onDeclareIncident}
      escalateFormId={escalateForm.id}
      elements={elements}
    />
  );
};

type EscalateFormData = IncidentFormsSubmitEscalateFormRequestBody & {
  declare_incident: boolean;
};

const EscalateFormInner = ({
  incidentId,
  showDeclareIncident = true,
  onClose,
  onDeclareIncident,
  escalateFormId,
  elements,
}: {
  incidentId?: string;
  showDeclareIncident?: boolean;
  onClose: () => void;
  onDeclareIncident?: (string) => void;
  escalateFormId: string;
  elements: IncidentFormEscalationElement[];
}) => {
  const incident = useIncident(incidentId || null)?.incident;

  const { identity } = useIdentity();
  const now = new Date().getTime();

  const [idempotencyKey] = useState<string>(
    `manual_escalation:${identity?.user_id}:${now}`,
  ); // This is to keep the idempotencyKey from the first render

  const showToast = useToast();

  const defaultElementBindings: { [key: string]: string[] } = {};

  elements.forEach((element) => {
    if (element.default_value?.value?.value) {
      defaultElementBindings[element.id] = [element.default_value?.value.value];
    }
  });

  const formMethods = useForm<EscalateFormData>({
    defaultValues: {
      title: incident?.name || "",
      idempotency_key: idempotencyKey,
      element_bindings: defaultElementBindings,
      incident_id: incidentId,
      declare_incident: showDeclareIncident && incidentId == null,
    },
  });

  const { trigger, isMutating: isSubmitting } = useAPIMutation(
    "escalationsList",
    {
      pageSize: 50,
    },
    async (apiClient, formData) => {
      const result = await apiClient.incidentFormsSubmitEscalateForm({
        id: escalateFormId,
        submitEscalateFormRequestBody: {
          ...formData,
          element_bindings: Object.fromEntries(
            Object.entries(formData.element_bindings)
              .map(([key, value]) => [
                key,
                Array.isArray(value) ? value : [value],
              ])
              .filter(([, value]) => value.length > 1 || value[0] != null),
          ),
        },
      });

      if (
        formData.declare_incident &&
        result &&
        result.escalations &&
        result.escalations.length > 0
      ) {
        if (onDeclareIncident) onDeclareIncident(result.escalations[0].id);
      }
    },
    {
      onSuccess: () => {
        showToast({
          title: "Escalation created",
          theme: ToastTheme.Success,
        });
        onClose();
      },
      onError: () => {
        showToast({
          title: "Failed to create escalation",
          description: "Please try again.",
          theme: ToastTheme.Error,
        });
      },
      setError: formMethods.setError,
    },
  );

  const elementsWithoutPriority = elements.filter(
    (element) =>
      element.available_element.element_type !==
      AvailableIncidentFormEscalationElementElementTypeEnum.Priority,
  );

  const priorityElement = elements.find(
    (element) =>
      element.available_element.element_type ===
      AvailableIncidentFormEscalationElementElementTypeEnum.Priority,
  );

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          color={ColorPaletteEnum.Slate}
          title="Create escalation"
          onClose={onClose}
          titleAccessory={
            <IconBadge
              icon={IconEnum.Escalate}
              size={IconSize.Small}
              color={ColorPaletteEnum.Slate}
              className="bg-slate-700 text-white"
            />
          }
          closeIcon={IconEnum.Close}
          compact
        />
        <FormV2
          id="escalation-path-create-edit-form"
          onSubmit={trigger}
          formMethods={formMethods}
          outerClassName="h-full"
          loadingWrapperClassName="h-full"
          innerClassName="flex flex-col h-full space-y-0 px-6"
          warnWhenDirty
        >
          {incident?.visibility === IncidentVisibilityEnum.Private && (
            <Callout theme={CalloutTheme.Warning} className="mb-6">
              <span>
                This is a <span className="text-sm-bold">private incident</span>
                . You&apos;ll need to{" "}
                <span className="text-sm-bold">invite any new responders</span>{" "}
                to this channel so they can access the incident.
              </span>
            </Callout>
          )}
          <div className="text-base-bold pt-4 ">Who do you need?</div>
          <div className="text-xs-med text-content-primary pt-[2px] pb-1">
            Pick from any of the options below and we&apos;ll find the right
            people to page
          </div>
          <div className="h-3" />
          <div className="bg-surface-secondary p-4 rounded-3">
            {elementsWithoutPriority.map((element, index) => {
              return (
                <div
                  key={element.id}
                  className={tcx({
                    "pb-6": index !== elementsWithoutPriority.length - 1,
                  })}
                >
                  <EscalateFormElement element={element} />
                </div>
              );
            })}
          </div>
          <div className="text-sm-bold pt-10 pb-1">Notification message</div>
          <InputV2
            name="title"
            className="pb-3"
            formMethods={formMethods}
            placeholder="Something's wrong with the database"
          />
          {priorityElement ? (
            <div key={priorityElement.id} className="py-3">
              <EscalateFormElement element={priorityElement} />
            </div>
          ) : null}
          {showDeclareIncident ? (
            <div className="mt-4">
              <CheckboxRowV2
                name="declare_incident"
                description={undefined}
                className="w-full"
                formMethods={formMethods}
                label="Also declare an incident"
              />
            </div>
          ) : null}
          <div className="flex-grow"></div>
          <NoValidSelectionsCallout elements={elements} />
          <div className="h-4" />
        </FormV2>
        <DrawerFooter className="flex gap-2 justify-end">
          <div className="flex-grow"></div>
          <Button onClick={() => onClose()} analyticsTrackingId={null}>
            Cancel
          </Button>
          <GatedButton
            form="escalate"
            requiredScope={ScopeNameEnum.EscalationsCreate}
            onClick={() => trigger(formMethods.getValues())}
            theme={ButtonTheme.Primary}
            loading={isSubmitting}
            analyticsTrackingId="escalate-form-submit"
          >
            Page
          </GatedButton>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};

const EscalateFormElement = ({
  element,
}: {
  element: IncidentFormEscalationElement;
}) => {
  switch (element.available_element.element_type) {
    case AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType:
      return (
        <CatalogTypeFormElement
          element={element}
          showPreview={true}
          catalogTypeId={element.available_element.catalog_type?.id || ""}
          label={element.available_element.catalog_type?.name || ""}
        />
      );
    case AvailableIncidentFormEscalationElementElementTypeEnum.EscalationPolicy:
      return (
        <CatalogTypeFormElement
          element={element}
          showPreview={true}
          catalogTypeId="EscalationPath"
          label="Escalation path"
        />
      );
    case AvailableIncidentFormEscalationElementElementTypeEnum.User:
      return <UserEscalationFormElement element={element} />;
    case AvailableIncidentFormEscalationElementElementTypeEnum.Priority:
      return (
        <CatalogTypeFormElement
          element={element}
          showPreview={false}
          catalogTypeId="AlertPriority"
          label="Priority"
          isMulti={false}
        />
      );
    default:
      throw new Error(
        `Unrecognised element type: ${element.available_element.element_type}`,
      );
  }
};

const CatalogTypeFormElement = ({
  element,
  catalogTypeId,
  showPreview,
  label,
  isMulti = true,
}: {
  element: IncidentFormEscalationElement;
  catalogTypeId: string;
  showPreview: boolean;
  label: string;
  isMulti?: boolean;
}) => {
  const apiClient = useClient();

  const formMethods = useFormContext<EscalateFormData>();

  const bindings = formMethods.watch(`element_bindings.${element.id}`);

  const { data: result, isLoading: loading } = useAPI(
    "incidentFormsPreviewEscalateForm",
    {
      id: element.incident_form_id,
      previewEscalateFormRequestBody: {
        element_bindings: {
          [element.id]: bindings,
        },
      },
    },
  );

  const pathsPreview = Object.values(result?.for_paths || {});
  const usersPreview = Object.values(result?.for_users || {});
  const preview = pathsPreview.concat(usersPreview);

  const targetsToBePaged = preview
    .map((val) => {
      return val.map((target) => {
        return target.name;
      });
    })
    .flat();
  const uniqueTargetsToBePaged = _.uniq(targetsToBePaged);

  const loadDefaultValueOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: catalogTypeId,
  });

  const hydrateDefaultValueOptions = hydrateInitialCatalogOptions({
    apiClient,
    catalogTypeID: catalogTypeId,
  });
  return (
    <>
      <div className="text-sm-bold">
        {label}{" "}
        <span className="text-content-tertiary text-sm-normal">(optional)</span>
      </div>

      <div className="pb-1"></div>
      <div
        className={tcx({
          "bg-slate-50 rounded-t-lg":
            showPreview && uniqueTargetsToBePaged.length > 0,
          "bg-red-surface rounded-t-lg":
            uniqueTargetsToBePaged.length === 0 &&
            bindings &&
            bindings.length > 0 &&
            showPreview,
        })}
      >
        {isMulti ? (
          <DynamicMultiSelectV2
            name={`element_bindings.${element.id}`}
            loadOptions={loadDefaultValueOptions}
            hydrateOptions={hydrateDefaultValueOptions}
            formMethods={formMethods}
            closeMenuOnSelect={true}
          />
        ) : (
          <DynamicSingleSelectV2
            name={`element_bindings.${element.id}`}
            loadOptions={loadDefaultValueOptions}
            hydrateOptions={hydrateDefaultValueOptions}
            formMethods={formMethods}
          />
        )}
      </div>
      {showPreview ? (
        <div className="">
          <ElementPreview element={element} result={result} loading={loading} />
        </div>
      ) : null}
      {element.description && (
        <div className="text-xs-med text-content-secondary pt-1">
          {element.description}
        </div>
      )}
    </>
  );
};

const UserEscalationFormElement = ({
  element,
}: {
  element: IncidentFormEscalationElement;
}) => {
  const apiClient = useClient();
  const formMethods = useFormContext<EscalateFormData>();

  return (
    <>
      <div className="text-sm-bold">
        User{" "}
        <span className="text-content-tertiary text-sm-normal">(optional)</span>
      </div>
      {element.description && (
        <div className="text-sm-normal pt-[2px]">{element.description}</div>
      )}
      <div className="pb-1"></div>
      <DynamicMultiSelectV2
        name={`element_bindings.${element.id}`}
        loadOptions={getTypeaheadOptions(apiClient, TypeaheadTypeEnum.User, {
          sortKey: "name",
        })}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.User,
        )}
        formMethods={formMethods}
      />
    </>
  );
};

const NoValidSelectionsCallout = ({
  elements,
}: {
  elements: IncidentFormEscalationElement[];
}) => {
  const formMethods = useFormContext<EscalateFormData>();

  const formState = useFormState(formMethods);

  const bindings = formMethods.watch(`element_bindings`);

  const nonPriorityBindings = Object.fromEntries(
    Object.entries(bindings).filter(([element_id, _]) => {
      return (
        elements.find((element) => element.id === element_id)?.available_element
          .element_type !==
        AvailableIncidentFormEscalationElementElementTypeEnum.Priority
      );
    }),
  );

  if (
    (formState.dirtyFields.title || formState.dirtyFields.element_bindings) &&
    Object.values(nonPriorityBindings).every((val) => !val || val.length === 0)
  ) {
    return (
      <Callout theme={CalloutTheme.Danger}>
        You need to select at least one option for who you need
      </Callout>
    );
  }

  return <></>;
};

const ElementPreview = ({
  element,
  result,
  loading,
}: {
  element: IncidentFormEscalationElement;
  result: IncidentFormsPreviewEscalateFormResponseBody | undefined;
  loading: boolean;
}) => {
  const formMethods = useFormContext<EscalateFormData>();

  const bindings = formMethods.watch(`element_bindings.${element.id}`);

  if (!bindings || bindings.length === 0) {
    return <></>;
  }

  const pathsPreview = Object.values(result?.for_paths || {});
  const usersPreview = Object.values(result?.for_users || {});
  const preview = pathsPreview.concat(usersPreview);

  const targetsToBePaged = preview
    .map((val) => {
      return val.map((target) => {
        return target.name;
      });
    })
    .flat();
  const uniqueTargetsToBePaged = _.uniq(targetsToBePaged);

  const messages: React.ReactElement[] = [];

  if (uniqueTargetsToBePaged.length > 0) {
    messages.push(
      <>
        <span className="text-sm-bold">
          {joinSpansWithCommasAndConnectorWord(uniqueTargetsToBePaged)}
        </span>
        <span className="text-sm-normal"> will be notified.</span>
      </>,
    );
  }

  const addServiceMessages = (
    serviceData,
    catalogEntries: CatalogEntry[] | undefined,
    provider: string,
  ) => {
    Object.keys(serviceData).forEach((key) => {
      const catalogEntry = (catalogEntries || []).find(
        (entry) => entry.id === key,
      );
      if (catalogEntry) {
        const serviceName = serviceData[key][0]?.name;
        if (serviceName) {
          if (messages.length > 0) {
            messages.push(<span> </span>);
          }

          messages.push(
            <div className="inline">
              We will escalate to
              <span className="text-sm-bold inline">
                {" "}
                {serviceName}
              </span> in {provider}.{" "}
            </div>,
          );
        }
      }
    });
  };

  addServiceMessages(
    result?.for_pagerduty_service || {},
    result?.catalog_entries,
    "PagerDuty",
  );
  addServiceMessages(
    result?.for_opsgenie_team || {},
    result?.catalog_entries,
    "Opsgenie",
  );

  return (
    <div>
      {loading ? (
        <Callout theme={CalloutTheme.Plain}>
          <Spinner />
        </Callout>
      ) : (
        <>
          {uniqueTargetsToBePaged.length > 0 ? (
            <div className="pt-2">{messages}</div>
          ) : (
            <div className="pt-2 text-red-500">
              You haven&apos;t set an escalation path for{" "}
              {bindings.length === 1 ? "this type" : "these types"}
            </div>
          )}
        </>
      )}
    </div>
  );
};
