import { AlertAttribute } from "@incident-io/api";
import { makeExpressionReference } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { omit } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import {
  useFieldArray,
  UseFieldArrayReturn,
  UseFormReturn,
} from "react-hook-form";

import {
  AlertSourceConfigureFormData,
  getExpressionUsages,
} from "./AlertSourceConfigurePage";

export type ExpressionDefaults = {
  path?: string;
  attribute?: AlertAttribute;
  isCreatingAttribute?: boolean;
};

// useDynamicAttributeEditing contains all the hooks we use to dynamically create attributes via expressions in your
// alert schema.
export function useAttributeExpressions({
  formMethods,
}: {
  formMethods: UseFormReturn<AlertSourceConfigureFormData>;
}): {
  onDeleteExpression: (e: Pick<ExpressionFormData, "reference">) => void;
  onAddExpression: (
    e: ExpressionFormData & { hardcoded?: boolean },
    existingAttribute?: AlertAttribute,
  ) => void;
  onEditExpression: (e: ExpressionFormData) => void;
  showExpressionsModal: boolean;
  openExpressionsModal: () => void;
  closeExpressionsModal: () => void;
  expressionsMethods: UseFieldArrayReturn<
    AlertSourceConfigureFormData,
    "template.expressions",
    "key"
  >;
  expressionDefaults: ExpressionDefaults;
  setExpressionDefaults: Dispatch<SetStateAction<ExpressionDefaults>>;
  initialExpression: ExpressionFormData | undefined;
  setInitialExpression: Dispatch<
    SetStateAction<ExpressionFormData | undefined>
  >;
  removeAttribute: (index?: number | number[] | undefined) => void;
} {
  const [showExpressionsModal, setShowExpressionsModal] =
    useState<boolean>(false);

  const [expressionDefaults, setExpressionDefaults] =
    useState<ExpressionDefaults>({});

  const [initialExpression, setInitialExpression] =
    useState<ExpressionFormData>();

  const { append: appendAttributes, remove: removeAttribute } = useFieldArray({
    control: formMethods.control,
    name: "attributes",
    keyName: "key",
  });

  const expressionsMethods = useFieldArray({
    name: "template.expressions",
    control: formMethods.control,
    keyName: "key",
  });

  const {
    fields: expressions,
    append: appendExpression,
    update: updateExpression,
    remove: removeExpression,
  } = expressionsMethods;

  // Using these rather than directly exposing setShowExpressionsModal
  // means we will always clear the default state correctly
  const openExpressionsModal = () => {
    setShowExpressionsModal(true);
  };
  const closeExpressionsModal = () => {
    setExpressionDefaults({});
    setInitialExpression(undefined);
    setShowExpressionsModal(false);
  };

  const onDeleteExpression = (expr: Pick<ExpressionFormData, "reference">) => {
    const usages = getExpressionUsages(expr, formMethods.getValues());
    // We check for the value `1` rather than `0`, as the form value will
    // be unset asynchronously and run _after_ this code. This means that
    // the value represents how many times the expression was referenced
    // _before_ the deletion was triggered.
    if (usages.usages.length > 1) {
      return;
    }
    const index = expressions.findIndex((e) => e.reference === expr.reference);
    if (index > -1) {
      removeExpression(index);
    }
  };

  const onAddExpression = (
    expr: ExpressionFormData & { hardcoded?: boolean },
    targetAttribute?: AlertAttribute,
  ) => {
    // First, we need to create the attribute if it doesn't exist
    if (!targetAttribute) {
      const newAttribute = {
        id: expr.reference,
        array: expr.returns?.array || false,
        name: expr.label,
        type: expr.returns?.type,
      };
      appendAttributes([newAttribute]);
    }

    // If we're just creating a hardcoded attribute,
    // we can exit here without creating an expression at all
    if (expr.hardcoded) {
      setExpressionDefaults({});
      setShowExpressionsModal(false);
      return;
    }

    // Otherwise, we go ahead and add the expression
    const attributeId = targetAttribute ? targetAttribute.id : expr.reference;
    // We can remove "hardcoded" from the payload now
    delete expr.hardcoded;
    appendExpression(expr as unknown as ExpressionFormData);
    formMethods.setValue?.<
      | `template.bindings.${typeof attributeId}.array_value.0`
      | `template.bindings.${typeof attributeId}.value`
    >(
      expr.returns?.array
        ? `template.bindings.${attributeId}.array_value.0`
        : `template.bindings.${attributeId}.value`,
      {
        reference: makeExpressionReference(expr),
      },
    );
    setExpressionDefaults({});
    setShowExpressionsModal(false);
  };

  const onEditExpression = (expr: ExpressionFormData) => {
    const index = expressions.findIndex((e) => e.reference === expr.reference);
    if (index > -1) {
      updateExpression(
        index,
        omit(expr, "hardcoded") as unknown as ExpressionFormData,
      );
    }
    setExpressionDefaults({});
    setShowExpressionsModal(false);
  };

  return {
    onDeleteExpression,
    onAddExpression,
    onEditExpression,
    showExpressionsModal,
    openExpressionsModal,
    closeExpressionsModal,
    expressionsMethods,
    expressionDefaults,
    setExpressionDefaults,
    removeAttribute,
    initialExpression,
    setInitialExpression,
  };
}
