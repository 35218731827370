import { TrialStatusPlanNameEnum } from "@incident-io/api";
import { Badge, BadgeTheme } from "@incident-ui";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { useIdentity } from "src/contexts/IdentityContext";

export const UpsellBadge = ({
  className,
  isPro,
  isEnterprise,
  newUntil,
}: {
  className?: string;
  isPro?: boolean;
  isEnterprise?: boolean;
  newUntil?: Date;
}): React.ReactElement | null => {
  const { identity } = useIdentity();

  if (!identity) {
    return null;
  }

  // If you're not paying us, we're going to market to you (unless you're a demo
  // account)
  const shouldUpsell =
    !identity.organisation_is_demo &&
    [TrialStatusPlanNameEnum.BasicV2, TrialStatusPlanNameEnum.Trial].includes(
      identity.trial_status.plan_name,
    );

  if ((isPro || isEnterprise) && shouldUpsell) {
    // This is a pro or enterprise feature, and we're in a trial: show the relevant badge.
    return (
      <Badge theme={BadgeTheme.Info} className={className}>
        {isPro ? "Pro" : "Enterprise"}
        <Icon size={IconSize.Small} id={IconEnum.New} />
      </Badge>
    );
  }

  // If there is an expiry time and that date is in the past, don't show the new badge
  if (newUntil && newUntil < new Date()) {
    return null;
  }

  if (newUntil) {
    return (
      <Badge theme={BadgeTheme.Info} className={className}>
        New <Icon size={IconSize.Small} id={IconEnum.New} />
      </Badge>
    );
  }

  return null;
};
