import {
  CatalogEntry,
  CatalogListEntriesResponseBody,
  CatalogType,
  CatalogTypeAttribute,
  CatalogTypeModeEnum,
} from "@incident-io/api";
import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  Loader,
  LoadingBar,
  Tooltip,
} from "@incident-ui";
import { Table } from "@incident-ui/Table/Table";
import { TableCell, TableHeaderCell, TableRow } from "@incident-ui/Table/Table";
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Route, Routes } from "react-router-dom";
import {
  AttributeEntries,
  CatalogEntryBadge,
  isPrimitive,
} from "src/components/@shared/attribute";
import { useAPI, useAPIRefetch } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useCounter } from "usehooks-ts";

import { CatalogEntriesBulkEditDrawer } from "./CatalogEntriesBulkEditDrawer";
import {
  CatalogEntryListColumnHeader,
  DropDownStateEnum,
} from "./CatalogEntryListColumnHeader";
import { CreateTypeFromAttributeModal } from "./CreateTypeFromAttributeModal";

export const CatalogEntryList = ({
  catalogEntries,
  allEntriesLoaded,
  loadMoreEntries,
  entriesLoading,
  refetchEntries,
  catalogType,
  probablyStillSyncing,
  hasOverMaxEntriesForBulkEdit,
  editDrawerBackHref,
  scrollToRightHandColumn,
  canClickEntries,
}: {
  catalogType: CatalogType;
  probablyStillSyncing: boolean;
  catalogEntries: CatalogListEntriesResponseBody[];
  entriesLoading: boolean;
  refetchEntries: () => void;
  allEntriesLoaded: boolean;
  hasOverMaxEntriesForBulkEdit: boolean;
  loadMoreEntries: () => void;
  editDrawerBackHref: string;
  scrollToRightHandColumn?: boolean;
  canClickEntries: boolean;
}) => {
  const lastColRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollToRightHandColumn && lastColRef.current) {
      lastColRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [scrollToRightHandColumn, catalogType.schema.attributes]);

  const refetchCatalogType = useAPIRefetch("catalogShowType", {
    id: catalogType.id,
  });

  const { count: numRefetches, increment } = useCounter(10);

  // If  Refetch the entries by polling (with a backoff) until we find some, or
  // we give up (catalogTypeCreatedInLast5Minutes will only be true for 5 mins)
  useEffect(() => {
    if (!probablyStillSyncing) return undefined;

    // 1s, 2s, 3s, 4s etc.
    const interval = numRefetches * 1000;

    const timeout = setTimeout(() => {
      refetchEntries();
      refetchCatalogType();
    }, interval);
    increment();

    return () => {
      clearTimeout(timeout);
    };
    // Without this, the useEffect infinite loops because of the counter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [probablyStillSyncing]);

  const setDropdown = useRef((_: DropDownStateEnum) => {
    return;
  });

  const [attrToPromote, setAttrToPromote] = useState<
    CatalogTypeAttribute | undefined
  >();
  const entries = catalogEntries.flatMap(
    ({ catalog_entries }) => catalog_entries,
  );

  const {
    data: resourcesListResp,
    isLoading: resourcesListIsLoading,
    error: resourcesListErr,
  } = useAPI("catalogListResources", undefined);

  const navigate = useOrgAwareNavigate();
  const [loadMoreRef] = useInfiniteScroll({
    loading: entriesLoading,
    hasNextPage: !allEntriesLoaded,
    onLoadMore: loadMoreEntries,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });
  if (resourcesListErr) {
    return <GenericErrorMessage error={resourcesListErr} />;
  }
  if (resourcesListIsLoading || !resourcesListResp) {
    return <Loader />;
  }

  const entryHasAdditionalIDs = (entry: CatalogEntry): boolean =>
    entry.aliases?.length > 0 || !!entry.external_id;

  // Every table gets a "name" column by default
  const attrCount = catalogType.schema.attributes.length + 1;

  const isSyncing =
    catalogType.mode === CatalogTypeModeEnum.External &&
    !catalogType.last_synced_at;

  if (!isSyncing && entries.length === 0 && !entriesLoading) {
    return (
      <EmptyState
        title="No entries found"
        icon={IconEnum.Book}
        content="There are no entries for this catalog type."
        cta={
          catalogType.mode === CatalogTypeModeEnum.Manual ? (
            <Button
              analyticsTrackingId={null}
              onClick={() => navigate(`/catalog/${catalogType.id}/create`)}
            >
              Create a new entry
            </Button>
          ) : undefined
        }
      />
    );
  }

  return (
    <>
      <Routes>
        <Route
          path={"bulk-edit/:attributeId"}
          element={
            <CatalogEntriesBulkEditDrawer
              catalogType={catalogType}
              catalogResources={resourcesListResp.resources}
              entries={entries}
              refetchEntries={refetchEntries}
              entriesFullyLoaded={allEntriesLoaded}
              loadMoreRef={loadMoreRef}
              backHref={editDrawerBackHref}
            />
          }
        />
      </Routes>
      <div>
        <Table
          gridTemplateColumns={`repeat(${attrCount}, minmax(300px, 1fr))`}
          className="overflow-x-auto"
          infiniteScroll={{
            ref: loadMoreRef,
            isLoading: entriesLoading,
            isFullyLoaded: allEntriesLoaded,
          }}
          data={entries}
          header={
            <>
              <TableHeaderCell
                title={catalogType.registry_type ? catalogType.name : "Name"}
                className="sticky left-0 bg-white z-10"
              />
              {catalogType.schema.attributes.map((attr, idx) => {
                const isLastCol =
                  idx === catalogType.schema.attributes.length - 1;
                return (
                  <CatalogEntryListColumnHeader
                    setDropdown={setDropdown}
                    attribute={attr}
                    onPromoteAttr={() => setAttrToPromote(attr)}
                    onBulkEditAttr={(autoFill) =>
                      navigate(`bulk-edit/${attr.id}?autofill=${autoFill}`)
                    }
                    lastSyncedAt={catalogType.last_synced_at}
                    sourceRepoUrl={catalogType.source_repo_url}
                    key={`catalog-entry-header-${attr.id}`}
                    hasOverMaxEntriesForBulkEdit={hasOverMaxEntriesForBulkEdit}
                    entries={entries}
                    ref={isLastCol ? lastColRef : undefined}
                  />
                );
              })}
            </>
          }
          renderRow={(entry, index) => (
            <TableRow
              isLastRow={index === entries.length - 1}
              onClick={() =>
                navigate(
                  `/catalog/${slugForCatalogType(catalogType)}/${entry.id}`,
                )
              }
              key={entry.id}
              className={canClickEntries ? "" : "pointer-events-none"}
            >
              <TableCell
                key={`${index}-name`}
                className="truncate sticky left-0 bg-white"
              >
                <Tooltip
                  content={
                    <div>
                      {entry.aliases?.length > 0 && (
                        <>
                          <span className="font-semibold">Aliases:</span>
                          <ul className="list-disc list-inside">
                            {entry.aliases.map((alias) => (
                              <li key={alias}>
                                <code>{alias}</code>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                      {entry.external_id && (
                        <>
                          <span className="font-semibold">External ID:</span>
                          <pre>{entry.external_id}</pre>
                        </>
                      )}
                    </div>
                  }
                  side="bottom"
                  align="end"
                  alignOffset={-200}
                  bubbleProps={{
                    onClick: (e) => {
                      e.preventDefault;
                      e.stopPropagation();
                    },
                    className: tcx("max-w-xs cursor-text", {
                      hidden: !entryHasAdditionalIDs(entry),
                    }),
                  }}
                >
                  <CatalogEntryBadge
                    label={entry.name}
                    icon={catalogType.icon}
                    color={catalogType.color}
                  />
                </Tooltip>
              </TableCell>
              {catalogType.schema.attributes.map((attr) => {
                const binding = entry.attribute_values[attr.id];
                // If it isn't a primitive, it must be a reference to another entry
                const isRef = !isPrimitive(attr.type);
                return (
                  <TableCell
                    key={`${index}-entry-${attr.id}`}
                    className={tcx(
                      "group-hover:cursor-pointer group-hover:bg-surface-secondary pr-2",
                      isRef ? "!py-0" : "",
                    )}
                  >
                    <div className="truncate">
                      <AttributeEntries
                        mode={"catalog"}
                        typeName={attr.type}
                        catalogResources={resourcesListResp.resources}
                        attributeBinding={binding}
                        truncate
                        basePath={`/catalog/${
                          catalogType.registry_type || catalogType.id
                        }`}
                      />
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          )}
          emptyStateRow={
            <CatalogLoadingRow attributes={catalogType.schema.attributes} />
          }
        />
        {attrToPromote && (
          <CreateTypeFromAttributeModal
            attribute={attrToPromote}
            entries={entries}
            close={() => {
              setDropdown.current(DropDownStateEnum.Inactive);
              setAttrToPromote(undefined);
            }}
            catalogType={catalogType}
            setDropdown={setDropdown.current}
          />
        )}
      </div>
    </>
  );
};

type CatalogLoadingRowProps = {
  attributes: CatalogTypeAttribute[];
};
const CatalogLoadingRow = forwardRef(
  (
    { attributes }: CatalogLoadingRowProps,
    ref: ForwardedRef<HTMLTableRowElement>,
  ): React.ReactElement => {
    const namePlusAttributes = [{ id: "name" }, ...attributes];
    return (
      <tr ref={ref}>
        {namePlusAttributes.map((attr) => (
          <td key={attr.id} className="p-6">
            <LoadingBar className="!h-[14px] max-w-[120px]" />
          </td>
        ))}
        {/* This is a horrible table hack: In order to push truncated columns to the left,
                  we have an invisible empty column with no max width that fills all the remaining available space */}
        <td rowSpan={1} className="w-full" />
      </tr>
    );
  },
);
CatalogLoadingRow.displayName = "CatalogLoadingRow";
