import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  Loader,
} from "@incident-ui";
import React from "react";
import { useLocation } from "react-router";
import { useIntercom } from "react-use-intercom";
import {
  EnrichedSlackMessage,
  Incident,
  TimelineItem,
  TimelineItemItemTypeEnum as TimelineItemType,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

import { AddToSlackButton } from "../slack/AddToSlackButton";
import { TimelineItemEnrichmentErrorProps } from "./TimelineItemEnrichmentError";

// NotInSlackChannelWarning is rendered when we weren't able to enrich a message because we weren't in the Slack
// channel. This occurs most often for slack_pin timeline items.
const NotInSlackChannelWarning = (): React.ReactElement => {
  const { showArticle } = useIntercom();

  return (
    <Callout theme={CalloutTheme.Info}>
      <h3 className="font-medium">
        {`We're not in this incident's Slack Channel`}
      </h3>
      <div className="mt-2 space-y-2">
        <p>
          That&apos;s OK, but it means we can&apos;t access any messages
          you&apos;ve pinned to the timeline. If you need to see the full
          timeline, simply unarchive the channel!
        </p>
        <p>
          Learn how you can avoid this in our{" "}
          <Button
            theme={ButtonTheme.Link}
            onClick={() => showArticle(6817870)}
            analyticsTrackingId="timeline-not-in-channel-help"
          >
            help center
          </Button>
          .
        </p>
      </div>
    </Callout>
  );
};

// PrivateSlackChannelWarning is rendered when we weren't able to enrich messages because the Slack channel
// has been made private.
const PrivateSlackChannelWarning = (): React.ReactElement => {
  const { identity } = useIdentity();
  const { pathname } = useLocation();
  const returnPath = pathname.replace("/", "");

  if (!identity) {
    return <Loader />;
  }
  const slackInfo = identity.slack_info;
  if (!slackInfo) {
    // This should never be rendered for an MS Teams organisation, but if it is,
    // let's not render anything!
    return <></>;
  }
  const addToSlackUrl = slackInfo.install_url;

  const reinstallPrompt: React.ReactElement = (
    <>
      <p className="mb-3">
        We&apos;d normally convert this to a{" "}
        <a
          href="https://incident.io/changelog/2022-01-25-private-incidents"
          className="font-medium"
          target="_blank"
          rel="noopener noreferrer"
        >
          Private Incident
        </a>
        , but we don&apos;t have the permissions to manage private channels in
        your Slack workspace.
      </p>
      <p className="mb-3">
        To convert this to a Private Incident, you&apos;ll need to reinstall
        incident.io to your Slack workspace by clicking the &quot;Add to
        Slack&quot; button below.
      </p>
      <p className="text-center">
        <AddToSlackButton url={addToSlackUrl} returnPath={returnPath} />
      </p>
    </>
  );

  const converting: React.ReactElement = (
    <p className="mb-3">
      We&apos;re converting this to a{" "}
      <a
        href="https://incident.io/changelog/2022-01-25-private-incidents"
        className="font-medium"
        target="_blank"
        rel="noreferrer"
      >
        Private Incident
      </a>
      .
    </p>
  );

  return (
    <div className="rounded-[6px] bg-blue-surface p-4 max-w-3xl mx-auto">
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon
            id={IconEnum.LockClosed}
            className="h-5 w-5 text-content-primary"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-base font-medium text-blue-content">
            {`This incident's Slack channel has been made private`}
          </h3>
          <div className="mt-2 text-base text-blue-content">
            {slackInfo.missing_token_scopes.length > 0
              ? reinstallPrompt
              : converting}
          </div>
        </div>
      </div>
    </div>
  );
};

// EnrichmentWarnings currently returns a single warning to render to the user at the
// top of the timeline. In time, it should return a list of warnings based on the
// set of error codes that were present in the enrichment_error fields of the timeline
// items.
export const EnrichmentWarnings = ({
  incident,
  items,
}: {
  incident: Incident;
  items: TimelineItem[] | null;
}): React.ReactElement | null => {
  const notInSlackChannelCausingRenderIssues = items?.some(
    (item) =>
      enrichmentError(incident, item)?.enrichment_error === "not-in-channel",
  );

  if (notInSlackChannelCausingRenderIssues) {
    return <NotInSlackChannelWarning />;
  }

  const privateOrDeletedSlackChannelCausingRenderIssues = items?.some(
    (item) =>
      enrichmentError(incident, item)?.enrichment_error ===
      "channel-is-private-or-deleted",
  );

  if (privateOrDeletedSlackChannelCausingRenderIssues) {
    return <PrivateSlackChannelWarning />;
  }

  return null;
};

export function enrichmentError(
  incident: Incident,
  item?: TimelineItem,
): TimelineItemEnrichmentErrorProps | undefined {
  if (!item) {
    return undefined;
  }

  let msg: EnrichedSlackMessage | undefined;
  switch (item.item_type) {
    case TimelineItemType.SlackImage:
      msg = item.slack_image?.slack_message;
      break;
    case TimelineItemType.SlackPin:
      msg = item.slack_pin?.message;
      break;
    case TimelineItemType.SlackInferGithub:
      msg = item.slack_infer_github?.slack_message;
      break;
    case TimelineItemType.SlackInferSentry:
      msg = item.slack_infer_sentry?.slack_message;
      break;
  }

  if (msg?.enrichment_error) {
    return buildEnrichmentError(
      incident,
      item,
      msg?.enrichment_error,
      msg?.permalink,
    );
  }
  return undefined;
}

function buildEnrichmentError(
  incident: Incident,
  item: TimelineItem,
  message: string,
  permalink?: string,
): TimelineItemEnrichmentErrorProps {
  return {
    incident,
    itemID: item.id,
    comments: item.comments,
    occured_at: item.occured_at,
    enrichment_error: message,
    permalink,
  };
}
