import {
  ErrorMessage,
  ErrorMessageUI,
} from "@incident-shared/forms/ErrorMessage";
import {
  getTypeaheadOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { FormLabel } from "@incident-shared/forms/v1/FormInputHelpers";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  ConfirmationDialog,
  GenericErrorMessage,
  LoadingModal,
  Modal,
  ModalFooter,
  ModalHeader,
  TabPane,
  TabSection,
} from "@incident-ui";
import { DynamicMultiSelectWithObj } from "@incident-ui/Select/DynamicMultiSelect";
import { SelectOption } from "@incident-ui/Select/types";
import React, { useState } from "react";
import { useParams } from "react-router";
import { MultiValue } from "react-select";
import {
  Incident,
  IncidentMembershipsRevokeRequestBody,
  IncidentVisibilityEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

const INVITE_TAB = "invite";
const REVOKE_TAB = "revoke";

export function IncidentManageAccessModal({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement | null {
  if (incident.visibility === IncidentVisibilityEnum.Public) {
    return null;
  }
  return <ManageAccessModal incidentId={incident.id} onClose={onClose} />;
}

function getWarningConfirmationText(
  usersToRevoke: MultiValue<SelectOption>,
  identityUserID: string,
): React.ReactElement | string {
  if (usersToRevoke.length === 0) {
    return "";
  }

  if (usersToRevoke.length === 1) {
    return `Are you sure you want to revoke ${
      usersToRevoke[0]?.value === identityUserID
        ? "your own"
        : `${usersToRevoke[0]?.label}'s` ?? "this person's"
    } access?`;
  }

  return (
    <>
      Are you sure you want to revoke access for:
      <ul className="pt-4 pl-4 list-disc">
        {usersToRevoke.map((user) => (
          <li key={user.value}>{user.label}</li>
        ))}
      </ul>
    </>
  );
}

export const StreamManageAccessModal = ({
  streamId,
  onClose,
}: {
  streamId?: string;
  onClose: () => void;
}): React.ReactElement => {
  const { streamId: paramsStreamId } = useParams() as {
    streamId: string;
  };
  if (!streamId) {
    streamId = paramsStreamId;
  }

  const {
    data,
    isLoading: isLoadingInternalID,
    error: idError,
  } = useAPI(
    streamId ? "incidentsShowInternalID" : null,
    {
      id: streamId as string,
    },
    {
      // Disable revalidations here: this is an immutable mapping
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const streamInternalID = data?.incident.id;

  if (!streamId || isLoadingInternalID || !streamInternalID) {
    return <LoadingModal onClose={onClose} />;
  }

  if (idError) {
    return <GenericErrorMessage error={idError} />;
  }

  return <ManageAccessModal incidentId={streamInternalID} onClose={onClose} />;
};

const ManageAccessModal = ({
  incidentId,
  onClose,
}: {
  incidentId: string;
  onClose: () => void;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const [showRevokeConfirmation, setShowRevokeConfirmation] = useState(false);
  const [selectedTab, setSelectedTab] = useState(INVITE_TAB);
  const [usersToInvite, setUsersToInvite] = useState<MultiValue<SelectOption>>(
    [],
  );
  const [usersToRevoke, setUsersToRevoke] = useState<MultiValue<SelectOption>>(
    [],
  );

  const {
    data: { incident_memberships: memberships },
  } = useAPI(
    "incidentMembershipsList",
    { incidentId: incidentId },
    { fallbackData: { incident_memberships: [] } },
  );
  const { identity } = useIdentity();

  const userIds = usersToRevoke.map((x) => x.value);
  const revokedOwnAccess = userIds.some((x) => x === identity?.user_id);

  const {
    trigger: revokeMembership,
    fieldErrors: revokeFieldErrors,
    genericError: revokeError,
    isMutating: revokeLoading,
  } = useAPIMutation(
    "incidentMembershipsList",
    { incidentId: incidentId },
    async (apiClient, data: IncidentMembershipsRevokeRequestBody) => {
      await apiClient.incidentMembershipsRevoke({
        revokeRequestBody: data,
      });
    },
    {
      onSuccess: () => {
        if (revokedOwnAccess) {
          navigate("/incidents");
        } else {
          onClose();
        }
        setUsersToRevoke([]);
      },
    },
  );

  const {
    trigger: doInvite,
    genericError: inviteError,
    isMutating: inviteLoading,
  } = useAPIMutation(
    "incidentMembershipsList",
    { incidentId: incidentId },
    async (apiClient, { userIds }: { userIds: string[] }) => {
      await Promise.all(
        userIds.map((user_id) =>
          apiClient.incidentMembershipsCreate({
            createRequestBody: { user_id, incident_id: incidentId },
          }),
        ),
      );
    },
    {
      onSuccess: () => {
        setUsersToInvite([]);
        onClose();
      },
    },
  );

  const apiClient = useClient();

  if (!identity) {
    return <LoadingModal onClose={onClose} />;
  }

  async function inviteUsers() {
    await doInvite({ userIds: usersToInvite.map(({ value }) => value) });
  }

  return (
    <>
      <Modal
        isOpen
        onClose={onClose}
        hideHeader
        title="Manage access"
        disableQuickClose
        analyticsTrackingId="incident-manage-access"
        analyticsTrackingMetadata={{ selectedTab }}
      >
        <ModalHeader
          headingLevel={3}
          title="Manage access"
          onClose={onClose}
          hideBorder
        />
        <TabSection
          tabBarClassName="px-4"
          withIndicator
          defaultTab={INVITE_TAB}
          onTabChange={(tab) => setSelectedTab(tab)}
          tabs={[
            {
              id: INVITE_TAB,
              label: "Invite user(s)",
            },
            {
              id: REVOKE_TAB,
              label: "Revoke access",
            },
          ]}
        >
          <TabPane tabId={INVITE_TAB} className="p-6 bg-surface-secondary">
            <FormLabel htmlFor="invite_users">
              Choose user(s) to invite
            </FormLabel>
            <DynamicMultiSelectWithObj
              id="invite_users"
              onChange={(users) => setUsersToInvite(users)}
              value={usersToInvite as SelectOption[]}
              key="invite-users"
              loadOptions={getTypeaheadOptions(
                apiClient,
                TypeaheadTypeEnum.User,
                {
                  sortKey: "name",
                },
              )}
              placeholder="Select user(s)"
              filterOption={(usr: SelectOption) =>
                memberships.every((m) => m.user.id !== usr.value)
              }
            />
          </TabPane>
          <TabPane tabId={REVOKE_TAB} className="p-6 bg-surface-secondary">
            <FormLabel htmlFor="revoke_users">
              Choose user(s) to revoke
            </FormLabel>

            <DynamicMultiSelectWithObj
              onChange={setUsersToRevoke}
              id="revoke_users"
              value={usersToRevoke as SelectOption[]}
              key="revoke-users"
              loadOptions={getTypeaheadOptions(
                apiClient,
                TypeaheadTypeEnum.User,
                {
                  sortKey: "name",
                  incidentId: incidentId,
                },
              )}
              placeholder="Select user(s)"
            />
          </TabPane>
        </TabSection>
        {(inviteError || revokeError || revokeFieldErrors) && (
          <p className="px-6 pb-4 bg-surface-secondary">
            <ErrorMessageUI
              message={
                inviteError ||
                revokeError ||
                (revokeFieldErrors
                  ? Object.values(revokeFieldErrors)?.[0]
                  : null)
              }
            />
          </p>
        )}
        {revokeFieldErrors && <ErrorMessage errors={revokeFieldErrors} />}
        <ModalFooter
          confirmButtonText={
            selectedTab === INVITE_TAB ? "Invite users" : "Revoke members"
          }
          confirmButtonType="button"
          onClose={onClose}
          onConfirm={
            selectedTab === INVITE_TAB
              ? inviteUsers
              : () => setShowRevokeConfirmation(true)
          }
          disabled={
            selectedTab === INVITE_TAB
              ? usersToInvite.length === 0
              : usersToRevoke.length === 0
          }
          saving={inviteLoading || revokeLoading}
        />
      </Modal>
      <ConfirmationDialog
        title="Confirm access revocation"
        onConfirm={async () => {
          setShowRevokeConfirmation(false);
          await revokeMembership({
            user_ids: userIds,
            incident_id: incidentId,
          });
        }}
        onCancel={() => {
          setShowRevokeConfirmation(false);
        }}
        analyticsTrackingId="revoke-incident-membership-confirm"
        isOpen={showRevokeConfirmation}
      >
        {getWarningConfirmationText(usersToRevoke, identity.user_id)}
      </ConfirmationDialog>
    </>
  );
};
