import { ContentBox } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export const QuoteContentBox = ({
  className,
  quoteClassName,
  children,
  preQuote,
  postQuote,
  hidden,
}: {
  children: React.ReactNode;
  preQuote?: React.ReactNode;
  postQuote?: React.ReactNode;
  className?: string;
  quoteClassName?: string;
  hidden?: boolean;
}): React.ReactElement => {
  return (
    <ContentBox
      className={tcx(
        "p-4",
        { "!bg-surface-secondary !border-stroke": hidden },
        className,
      )}
    >
      {preQuote}
      <div
        className={tcx(
          "border-l-2 pl-2",
          hidden ? "border-stroke" : "border-slate-900",
          quoteClassName,
        )}
      >
        {children}
      </div>
      {postQuote}
    </ContentBox>
  );
};
