import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import {
  ClientType,
  ScopeNameEnum,
  StatusPage,
  StatusPageStructure,
  StatusPageSubPageSlim,
  StatusPageThemeEnum,
  StatusPageUpdateBrandingRequestBodyThemeEnum,
  StatusPageUpdateEmailBrandingRequestBodyThemeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { GENERIC_ERROR_MESSAGE } from "src/utils/fetchData";
import { useAPIMutation } from "src/utils/swr";

import { buildDefaultValues } from "../../common/ComponentsEditor/utils";
import { PageTypeProvider } from "../../common/PageTypeContext";
import { getParentPreviewItems } from "../../create/BrandingForm";
import { getPreviewItems } from "../../create/StandalonePageCreateModal";
import {
  BrandingForm,
  BrandingFormData,
  buildBrandingFormData,
} from "./BrandingForm";
import { buildEmailFormData, EmailForm, EmailFormData } from "./EmailForm";

export const Branding = ({
  page,
  structure,
  subpages,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
  subpages: StatusPageSubPageSlim[];
}): React.ReactElement | null => {
  const formMethods = useForm<BrandingFormData>({
    defaultValues: buildBrandingFormData(page),
  });
  const emailFormMethods = useForm<EmailFormData>({
    defaultValues: buildEmailFormData(page),
  });

  const isParentPage = !!page.split_by_catalog_type_id;

  const { trigger, genericError, isMutating } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient: ClientType, data: BrandingFormData) => {
      await apiClient.statusPageUpdateBranding({
        id: page.id,
        updateBrandingRequestBody: {
          darkmode_logo_key:
            data.theme === StatusPageThemeEnum.Dark
              ? data.logo?.key
              : page.darkmode_logo_key,
          lightmode_logo_key:
            data.theme === StatusPageThemeEnum.Light
              ? data.logo?.key
              : page.lightmode_logo_key,
          favicon_key: data.favicon?.key,
          theme:
            data.theme as unknown as StatusPageUpdateBrandingRequestBodyThemeEnum,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: (res) =>
        formMethods.reset(buildBrandingFormData(res.status_page)),
    },
  );

  const {
    trigger: emailTrigger,
    genericError: emailError,
    isMutating: emailIsMutating,
  } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient: ClientType, data: EmailFormData) => {
      await apiClient.statusPageUpdateEmailBranding({
        id: page.id,
        updateEmailBrandingRequestBody: {
          email_logo_key: data.email_logo?.key,
          theme:
            page.theme as unknown as StatusPageUpdateEmailBrandingRequestBodyThemeEnum,
        },
      });
    },
    {
      setError: emailFormMethods.setError,
      onSuccess: (res) =>
        emailFormMethods.reset(buildEmailFormData(res.status_page)),
    },
  );

  const { hasScope } = useIdentity();
  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  const pageStructure = buildDefaultValues({ ...page, structure });

  const previewItems = !isParentPage
    ? getPreviewItems(
        pageStructure.structureItems,
        pageStructure.components,
        page.display_uptime_mode,
      )
    : getParentPreviewItems(structure);

  return (
    <PageTypeProvider value={page.page_type}>
      {/* Theming form */}
      <FormV2
        formMethods={formMethods}
        onSubmit={trigger}
        saving={isMutating}
        innerClassName="bg-surface-secondary rounded-[6px] p-4 border border-stroke"
      >
        <ErrorMessageUI message={genericError ? GENERIC_ERROR_MESSAGE : null} />
        <BrandingForm
          {...page}
          previewItems={previewItems}
          subPageName={
            subpages && subpages.length > 0 ? subpages[0].name : undefined
          }
        />
        <GatedButton
          type="submit"
          theme={ButtonTheme.Primary}
          analyticsTrackingId={"status-page-edit-branding"}
          analyticsTrackingMetadata={{ status_page_id: page.id }}
          loading={isMutating}
          disabled={missingPermission || !formMethods.formState.isDirty}
          disabledTooltipContent={
            missingPermission
              ? "You do not have permission to configure this public status page"
              : undefined
          }
        >
          Save
        </GatedButton>
      </FormV2>

      {/* Email form */}
      <FormV2
        formMethods={emailFormMethods}
        onSubmit={emailTrigger}
        saving={emailIsMutating}
        innerClassName="bg-surface-secondary rounded-[6px] p-4 border border-stroke"
      >
        <ErrorMessageUI message={emailError ? GENERIC_ERROR_MESSAGE : null} />
        <EmailForm {...page} {...buildDefaultValues({ ...page, structure })} />
        <GatedButton
          type="submit"
          theme={ButtonTheme.Primary}
          analyticsTrackingId={"status-page-edit-emails"}
          analyticsTrackingMetadata={{ status_page_id: page.id }}
          loading={emailIsMutating}
          disabled={missingPermission || !emailFormMethods.formState.isDirty}
          disabledTooltipContent={
            missingPermission
              ? "You do not have permission to configure this public status page"
              : undefined
          }
        >
          Save
        </GatedButton>
      </FormV2>
    </PageTypeProvider>
  );
};
