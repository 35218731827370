import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  formatISO,
  isValid,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
} from "date-fns";
import startOfDay from "date-fns/esm/fp/startOfDay/index.js";

// DurationPickerState stores all the information needed to pass to metabase.
// It doesn't include any information needed to control the view component.
export type DateRangePickerState =
  | {
      mode: DateRangePickerMode.Absolute;
      absolute: DateRangePickerAbsoluteState;
      relative?: undefined;
      quick_select?: undefined;
    }
  | {
      mode: DateRangePickerMode.Relative;
      absolute?: undefined;
      relative: DateRangePickerRelativeState;
      quick_select?: undefined;
    }
  | {
      mode: DateRangePickerMode.QuickSelect;
      absolute?: undefined;
      relative?: undefined;
      quick_select: QuickSelectInterval;
    };

// Javascript's Date type is actually a time, and is always in the browser local
// time zone. Instead, we just store these as date strings, which is simpler
export type DateRangePickerAbsoluteState = {
  from: string;
  to: string;
};

export type DateRangePickerRelativeState = {
  interval: Interval;
  numberOfIntervals: number;
  includeThisInterval: boolean;
};

export enum DateRangePickerMode {
  Relative = "relative", // e.g. 7 days up to and including last Sunday
  Absolute = "absolute", // e.g. from 1/1/2022 -> 1/3/2022
  QuickSelect = "quick_select", // e.g. Last 7 days
}

export enum Interval {
  Days = "days",
  Weeks = "weeks",
  Months = "months",
  Quarters = "quarters",
}

export enum QuickSelectInterval {
  Last7Days = "last_7_days",
  LastWeek = "last_week",
  Last4Weeks = "last_4_weeks",
  Last30Days = "last_30_days",
  LastMonth = "last_month",
  Last3Months = "last_3_months",
  Last6Months = "last_6_months",
  Last12Months = "last_12_months",
}

export const QUICK_SELECT_INTERVAL_CONFIG: {
  [key in QuickSelectInterval]: {
    label: string;
    state: DateRangePickerRelativeState;
    // Deprecated intervals are not suggested in the drop-down but are
    // preserved for backwards compatibility with saved views.
    deprecated?: boolean;
  };
} = {
  [QuickSelectInterval.Last7Days]: {
    label: "Last 7 days",
    state: {
      interval: Interval.Days,
      numberOfIntervals: 7,
      includeThisInterval: true,
    },
  },
  [QuickSelectInterval.LastWeek]: {
    label: "Last week",
    state: {
      interval: Interval.Weeks,
      numberOfIntervals: 1,
      includeThisInterval: false,
    },
  },
  [QuickSelectInterval.Last4Weeks]: {
    label: "Last 4 weeks",
    state: {
      interval: Interval.Weeks,
      numberOfIntervals: 4,
      includeThisInterval: false,
    },
  },
  [QuickSelectInterval.Last30Days]: {
    label: "Last 30 days",
    state: {
      interval: Interval.Days,
      numberOfIntervals: 30,
      includeThisInterval: true,
    },
    deprecated: true,
  },
  [QuickSelectInterval.LastMonth]: {
    label: "Last month",
    state: {
      interval: Interval.Months,
      numberOfIntervals: 1,
      includeThisInterval: false,
    },
  },
  [QuickSelectInterval.Last3Months]: {
    label: "Last 3 months",
    state: {
      interval: Interval.Months,
      numberOfIntervals: 3,
      includeThisInterval: false,
    },
  },
  [QuickSelectInterval.Last6Months]: {
    label: "Last 6 months",
    state: {
      interval: Interval.Months,
      numberOfIntervals: 6,
      includeThisInterval: false,
    },
  },
  [QuickSelectInterval.Last12Months]: {
    label: "Last 12 months",
    state: {
      interval: Interval.Months,
      numberOfIntervals: 12,
      includeThisInterval: false,
    },
  },
};

export const DEFAULT_QUICK_INTERVAL = QuickSelectInterval.Last3Months;

export const DEFAULT_DATE_RANGE_PICKER_STATE: DateRangePickerState = {
  mode: DateRangePickerMode.QuickSelect,
  quick_select: DEFAULT_QUICK_INTERVAL,
};

export const INTERVAL_TO_THIS_MAPPING: { [key in Interval]: string } = {
  [Interval.Days]: "today",
  [Interval.Weeks]: "this week",
  [Interval.Months]: "this month",
  [Interval.Quarters]: "this quarter",
};

export const dateRangePickerStateToTimestamps = (
  state: DateRangePickerState,
): { from: string; to: string } => {
  const { from, to } = calculateDateRangePickerDates(state);

  // If we're using a timestamp to filter a date range, we need to make sure we set
  // the time to the start or end of the day, depending on the direction
  return {
    from: formatISO(startOfDay(from)),
    to: formatISO(endOfDay(to)),
  };
};

export const dateRangePickerStateToDates = (
  state: DateRangePickerState,
): DateRangePickerAbsoluteState => {
  const { from, to } = calculateDateRangePickerDates(state);

  return {
    from: format(from, "yyyy-MM-dd"),
    to: format(to, "yyyy-MM-dd"),
  };
};

const calculateDateRangePickerDates = (
  state: DateRangePickerState,
): { from: Date; to: Date } => {
  // If in absolute mode - we just use the dates, but need to
  // set to start and end of those days
  if (state.mode === DateRangePickerMode.Absolute) {
    return {
      from: new Date(state.absolute.from),
      to: new Date(state.absolute.to),
    };
  }

  const IntervalConfig: {
    [key in Interval]: {
      startOf: (date: Date) => Date;
      endOf: (date: Date) => Date;
      add: (date: Date, numberOfIntervals: number) => Date;
    };
  } = {
    [Interval.Days]: {
      startOf: startOfDay,
      endOf: endOfDay,
      add: addDays,
    },
    [Interval.Weeks]: {
      startOf: (d) => startOfWeek(d, { weekStartsOn: 1 }),
      endOf: (d) => endOfWeek(d, { weekStartsOn: 1 }),
      add: addWeeks,
    },
    [Interval.Months]: {
      startOf: startOfMonth,
      endOf: endOfMonth,
      add: addMonths,
    },
    [Interval.Quarters]: {
      startOf: startOfQuarter,
      endOf: endOfQuarter,
      add: addQuarters,
    },
  };

  const relativeState =
    state.mode === DateRangePickerMode.Relative
      ? state.relative
      : QUICK_SELECT_INTERVAL_CONFIG[state.quick_select].state;

  // We're in relative mode: gotta do some date maths! Yay
  const { interval, numberOfIntervals, includeThisInterval } = relativeState;
  const config = IntervalConfig[interval];

  const from = config.startOf(config.add(new Date(), -numberOfIntervals));
  const to = includeThisInterval
    ? config.endOf(new Date())
    : config.endOf(config.add(new Date(), -1));

  return { from, to };
};

export const isValidState = (state: DateRangePickerState): boolean => {
  switch (state.mode) {
    case DateRangePickerMode.Absolute:
      return (
        isValid(new Date(state.absolute.to)) &&
        isValid(new Date(state.absolute.from)) &&
        // yyyy-MM-dd is lovely and sortable!
        state.absolute.from < state.absolute.to
      );
    case DateRangePickerMode.Relative:
      return state.relative.numberOfIntervals > 0;
    case DateRangePickerMode.QuickSelect:
      return Object.values(QuickSelectInterval).includes(state.quick_select);
    default:
      throw new Error("Unreachable: Invalde date range state");
  }
};

export const dateRangePickerStateToString = (
  state: DateRangePickerState,
): string => {
  const s = `date_range[mode]=${state.mode}`;

  if (state.mode === DateRangePickerMode.Absolute) {
    const chunks: string[] = [
      s,
      `date_range[${state.mode}][from]=${state.absolute.from}`,
      `date_range[${state.mode}][to]=${state.absolute.to}`,
    ];
    return chunks.join("&");
  }

  if (state.mode === DateRangePickerMode.Relative) {
    const chunks: string[] = [
      s,
      `date_range[${state.mode}][interval]=${state.relative.interval}`,
      `date_range[${state.mode}][numberOfIntervals]=${state.relative.numberOfIntervals}`,
      `date_range[${state.mode}][includeThisInterval]=${state.relative.includeThisInterval}`,
    ];
    return chunks.join("&");
  }

  if (state.mode === DateRangePickerMode.QuickSelect) {
    const chunks: string[] = [
      s,
      `date_range[quick_select]=${state.quick_select}`,
    ];
    return chunks.join("&");
  }

  return s;
};

export const dateRangePickerStateFromString = (
  s: string,
): DateRangePickerState => {
  const searchParams = new URLSearchParams(s);

  if (!searchParams.has("date_range[mode]")) {
    return DEFAULT_DATE_RANGE_PICKER_STATE as DateRangePickerState;
  }

  const mode = searchParams.get("date_range[mode]");

  if (mode === DateRangePickerMode.Absolute) {
    const fromStr = searchParams.get(`date_range[${mode}][from]`);
    const toStr = searchParams.get(`date_range[${mode}][to]`);

    const fromDefault = format(addMonths(new Date(), -3), "yyyy-MM-dd");
    const from = fromStr ? fromStr : fromDefault;

    const to = toStr ? toStr : format(new Date(), "yyyy-MM-dd");

    return {
      mode,
      absolute: { from, to },
    };
  }

  if (mode === DateRangePickerMode.Relative) {
    const interval = searchParams.get(`date_range[${mode}][interval]`);
    const numberOfIntervalsStr = searchParams.get(
      `date_range[${mode}][numberOfIntervals]`,
    );
    const includeThisIntervalStr = searchParams.get(
      `date_range[${mode}][includeThisInterval]`,
    );

    const numberOfIntervals = parseInt(numberOfIntervalsStr || "1");
    const includeThisInterval = includeThisIntervalStr === "true";

    const relative = {
      interval: (interval as Interval) || Interval.Months,
      numberOfIntervals: numberOfIntervals,
      includeThisInterval: includeThisInterval,
    };

    return {
      mode,
      relative,
    };
  }

  if (mode === DateRangePickerMode.QuickSelect) {
    const qs = searchParams.get("date_range[quick_select]");
    return {
      mode,
      quick_select: (qs as QuickSelectInterval) || DEFAULT_QUICK_INTERVAL,
    };
  }

  return DEFAULT_DATE_RANGE_PICKER_STATE as DateRangePickerState;
};
