/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsightsCustomisableDashboard,
    InsightsCustomisableDashboardFromJSON,
    InsightsCustomisableDashboardFromJSONTyped,
    InsightsCustomisableDashboardToJSON,
} from './InsightsCustomisableDashboard';

/**
 * 
 * @export
 * @interface InsightsListDashboardsResponseBody
 */
export interface InsightsListDashboardsResponseBody {
    /**
     * 
     * @type {Array<InsightsCustomisableDashboard>}
     * @memberof InsightsListDashboardsResponseBody
     */
    dashboards: Array<InsightsCustomisableDashboard>;
}

export function InsightsListDashboardsResponseBodyFromJSON(json: any): InsightsListDashboardsResponseBody {
    return InsightsListDashboardsResponseBodyFromJSONTyped(json, false);
}

export function InsightsListDashboardsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsListDashboardsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboards': ((json['dashboards'] as Array<any>).map(InsightsCustomisableDashboardFromJSON)),
    };
}

export function InsightsListDashboardsResponseBodyToJSON(value?: InsightsListDashboardsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboards': ((value.dashboards as Array<any>).map(InsightsCustomisableDashboardToJSON)),
    };
}

