import {
  CustomField,
  CustomFieldFieldTypeEnum,
  InternalStatusPageThemeEnum,
} from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ModalFooter,
  Steps,
} from "@incident-ui";
import { UseFormReturn } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import {
  PageStructurePreview,
  PageType,
} from "src/components/status-pages/view/PageStructurePreview";

import { QueryExpressionInput } from "../QueryExpressionInput";
import { StructureFormContent } from "../StructureFormContent";
import {
  CreatePageSteps,
  InternalStatusPageFormType,
  stepsConfig,
} from "./CreateInternalStatusPage";

export const ChooseCustomField = ({
  allCustomFields,
  onClose,
  onBack,
  onSuccess,
  formMethods,
}: {
  allCustomFields: CustomField[];
  onClose: () => void;
  onBack: () => void;
  onSuccess: (customField: CustomField) => void;
  formMethods: UseFormReturn<InternalStatusPageFormType>;
}) => {
  const availableCustomFields = allCustomFields.filter(
    (field) =>
      field.field_type === CustomFieldFieldTypeEnum.MultiSelect ||
      field.field_type === CustomFieldFieldTypeEnum.SingleSelect,
  );

  const [selectedFieldID, pageName] = formMethods.watch([
    "component_custom_field_id",
    "name",
  ]);
  const selectedField = allCustomFields.find(
    (field) => field.id === selectedFieldID,
  );

  if (selectedFieldID && !selectedField) {
    throw new Error(
      "Unreachable: expected selected field ID to be in custom fields list",
    );
  }

  const { showArticle } = useIntercom();

  return (
    <FormModalV2
      suppressInitialAnimation
      formMethods={formMethods}
      onSubmit={() => onSuccess(selectedField as CustomField)}
      onClose={onClose}
      disableQuickClose
      title={"Create internal status page"}
      analyticsTrackingId="create-internal-sp-choose-field"
      isExtraLarge
      footer={
        <ModalFooter
          cancelButtonText="Back"
          onClose={onBack}
          confirmButtonType="submit"
          confirmButtonText="Continue"
        />
      }
    >
      <Steps steps={stepsConfig} currentStep={CreatePageSteps.Components} />
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="component_custom_field_id"
        label="Which custom field should power your components?"
        helptext={
          <>
            This custom field will form the components of your page. If this
            field is set on an active incident, the linked components will be
            marked as affected on your page.{" "}
            <Button
              theme={ButtonTheme.Link}
              onClick={() => showArticle(8173723)}
              analyticsTrackingId={null}
              className="!underline transition hover:text-black"
            >
              Learn more
            </Button>
            .
          </>
        }
        required={"Please select which custom field to use for components"}
        options={availableCustomFields.map((field) => ({
          value: field.id,
          label: field.name,
        }))}
      />
      {selectedField ? (
        <>
          <Callout className="mb-2" theme={CalloutTheme.Info}>
            <div className="flex justify-between items-center gap-2">
              <p>
                This custom field controls the components and their order on
                your internal status page. To adjust this, edit the &lsquo;
                {selectedField.name}&rsquo; field.
              </p>
              <Button
                openInNewTab
                analyticsTrackingId="create-internal-sp-edit-custom-field"
                href={`/settings/custom-fields/${selectedField.id}/edit?for-internal-sp`}
              >
                Edit custom field
              </Button>
            </div>
          </Callout>
          <QueryExpressionInput selectedField={selectedField} />
          <StructureFormContent
            formMethods={formMethods}
            selectedField={selectedField}
          />
        </>
      ) : (
        <PageStructurePreview
          logoPreviewURL={null}
          faviconPreviewURL="/apple-icon.png"
          name={pageName}
          theme={InternalStatusPageThemeEnum.Light}
          items={[
            {
              id: "1",
              hidden: false,
              displayUptimeChart: false,
              displayUptimePercentage: false,
              name: "Web dashboard",
            },
            {
              id: "2",
              hidden: false,
              displayUptimeChart: false,
              displayUptimePercentage: false,
              name: "Mobile app",
            },
          ]}
          type={PageType.Internal}
        />
      )}
    </FormModalV2>
  );
};
