import { Condition, EngineScope, Reference, Resource } from "@incident-io/api";
import React from "react";

import { ReferenceSelectorPopover } from "..";
import { ConditionOperationSelect } from "./ConditionOperationSelect";

export type ConditionMenuEntry = Reference & {
  resource: Resource;
};

export const AddConditionPopover = ({
  renderTriggerButton,
  onAddCondition,
  selectedConditions,
  resources,
  allowExpressions,
  scope,
  isSelectable: isSelectableCallback,
}: {
  renderTriggerButton: (props: { onClick: () => void }) => React.ReactElement;
  resources: Resource[];
  scope: EngineScope;
  onAddCondition: (condition: Condition) => void;
  selectedConditions: Condition[];
  allowExpressions?: boolean;
  isSelectable?: (entry: ConditionMenuEntry) => boolean;
}) => {
  const selectedConditionKeys = selectedConditions.map(
    ({ subject }) => subject.reference,
  );

  const isSelectable = (entry: ConditionMenuEntry): boolean => {
    if (!hasOperations(entry)) {
      return false;
    }

    if (entry.hide_filter) {
      return false;
    }

    if (selectedConditionKeys.includes(entry.key)) {
      return false;
    }

    if (isSelectableCallback !== undefined) {
      return isSelectableCallback(entry);
    }

    return true;
  };

  return (
    <ReferenceSelectorPopover
      scope={scope}
      renderTriggerButton={renderTriggerButton}
      allowExpressions={allowExpressions}
      isSelectable={isSelectable}
      // Let people close the popover by clicking outside of it. This is so we don't have to show a
      // close button in the popover itself. Note that if you're in the expression modal, we'll stop you
      // from closing the modal by accident anyway!
      stopPreventClose={true}
      renderOnSelectedForm={({ selectedEntry, onClose }) => {
        const handleSubmit = (newCondition: Condition) => {
          onAddCondition(newCondition);
          onClose();
        };

        return (
          <ConditionOperationSelect
            handleSubmit={handleSubmit}
            resources={resources}
            scope={scope}
            selectedEntry={selectedEntry}
            allowExpressions={allowExpressions}
          />
        );
      }}
    />
  );
};

const hasOperations = (entry: ConditionMenuEntry): boolean => {
  if (entry.array) {
    return entry.resource.array_operations.length > 0;
  } else {
    return entry.resource.operations.length > 0;
  }
};
