/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnboardingInformation
 */
export interface OnboardingInformation {
    /**
     * whether or not the owner has pressed 'invite admins'
     * @type {boolean}
     * @memberof OnboardingInformation
     */
    admins_invited: boolean;
    /**
     * Has the 'buy plan' banner been dismissed?
     * @type {boolean}
     * @memberof OnboardingInformation
     */
    buy_plan_banner_dismissed: boolean;
    /**
     * The plan the organisation said they wanted during sign-up
     * @type {string}
     * @memberof OnboardingInformation
     */
    desired_plan?: OnboardingInformationDesiredPlanEnum;
    /**
     * Has this organisation requested access to On-call?
     * @type {boolean}
     * @memberof OnboardingInformation
     */
    requested_on_call: boolean;
    /**
     * whether or not the owner has completed the initial tell us more form
     * @type {boolean}
     * @memberof OnboardingInformation
     */
    tell_us_more_form_submitted: boolean;
    /**
     * What is the main value the installer wants to get from us?
     * @type {string}
     * @memberof OnboardingInformation
     */
    value_prop?: string;
}

/**
* @export
* @enum {string}
*/
export enum OnboardingInformationDesiredPlanEnum {
    Legacy = 'legacy',
    Trial = 'trial',
    StarterV1 = 'starter_v1',
    ProV1 = 'pro_v1',
    EnterpriseV1 = 'enterprise_v1',
    BasicV2 = 'basic_v2',
    TeamV2 = 'team_v2',
    ProV2 = 'pro_v2',
    EnterpriseV2 = 'enterprise_v2'
}

export function OnboardingInformationFromJSON(json: any): OnboardingInformation {
    return OnboardingInformationFromJSONTyped(json, false);
}

export function OnboardingInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'admins_invited': json['admins_invited'],
        'buy_plan_banner_dismissed': json['buy_plan_banner_dismissed'],
        'desired_plan': !exists(json, 'desired_plan') ? undefined : json['desired_plan'],
        'requested_on_call': json['requested_on_call'],
        'tell_us_more_form_submitted': json['tell_us_more_form_submitted'],
        'value_prop': !exists(json, 'value_prop') ? undefined : json['value_prop'],
    };
}

export function OnboardingInformationToJSON(value?: OnboardingInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'admins_invited': value.admins_invited,
        'buy_plan_banner_dismissed': value.buy_plan_banner_dismissed,
        'desired_plan': value.desired_plan,
        'requested_on_call': value.requested_on_call,
        'tell_us_more_form_submitted': value.tell_us_more_form_submitted,
        'value_prop': value.value_prop,
    };
}

