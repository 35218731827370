import {
  FollowUpCompleteModal,
  FollowUpDeleteModal,
  FollowUpEditModal,
} from "@incident-shared/follow-ups";
import { FollowUpNotDoingModal } from "@incident-shared/follow-ups/FollowUpNotDoingModal";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import {
  ExportToProviderModal,
  GetInstalledIssueTrackers,
  IssueTrackerProviderEnum,
} from "@incident-shared/issue-trackers";
import { FollowUpExportFailureSection } from "@incident-shared/issue-trackers/FollowUpExportFailureSection";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { PolicyViolationNotification } from "@incident-shared/policy/PolicyViolationNotification";
import {
  Avatar,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  IconSize,
  Loader,
  Markdown,
  Tooltip,
} from "@incident-ui";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import _ from "lodash";
import { useState } from "react";
import { SendRemindersModal } from "src/components/post-incident/common/SendRemindersModal";
import {
  ActionStatusEnum,
  FollowUp,
  FollowUpStatusEnum,
  Incident,
  IncidentVisibilityEnum,
  Policy,
  RemindersCreateRequestBodyReminderTypeEnum,
  SuggestedFollowUp,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { tcx } from "src/utils/tailwind-classes";

import { ExternalIssueLink } from "../../../@shared/follow-ups/ExternalIssueLink";
import { ActionStatus } from "../actions/ActionStatus";
import { EmptyTab } from "../EmptyTab";
import { useFollowUps, usePoliciesAndViolations } from "../hooks";
import styles from "./FollowUps.module.scss";
import { FollowUpSuggestionsPanel } from "./FollowUpSuggestions";

export type Props = {
  incident: Incident;
  followUps: FollowUp[];
  followUpSuggestions?: SuggestedFollowUp[];
  showExternalLinkColumn?: boolean;
  editingFollowUp: FollowUp | undefined;
};

export function FollowUpsTabTable({
  incident,
  followUps = [],
  showExternalLinkColumn,
  editingFollowUp,
  followUpSuggestions = [],
}: Props): React.ReactElement {
  const navigate = useOrgAwareNavigate();
  const { identity } = useIdentity();
  const canUseFollowUpPriorities = identity?.feature_gates.follow_up_priorities;

  // Modals
  const [isDeletingFollowUpModalOpen, setIsDeletingFollowUpModalOpen] =
    useState<FollowUp | null>(null);
  const [isNotDoingFollowUpModalOpen, setIsNotDoingFollowUpModalOpen] =
    useState<FollowUp | null>(null);
  const [isCompletingFollowUpModalOpen, setIsCompletingFollowUpModalOpen] =
    useState<FollowUp | null>(null);
  const [isSendReminderModalOpen, setIsSendReminderModalOpen] =
    useState<FollowUp | null>(null);
  const [exportToProvider, setExportToProvider] = useState<{
    provider: IssueTrackerProviderEnum;
    followUp: FollowUp;
  } | null>(null);

  const { policies, policyViolations, refetchPolicyViolations } =
    usePoliciesAndViolations(incident.id);

  const { mutate: refetchFollowUps } = useFollowUps(incident.id);

  const refetch = async () => {
    await Promise.all([refetchFollowUps(), refetchPolicyViolations()]);
  };

  const { integrations } = useIntegrations();
  const issueTrackerIntegrations = GetInstalledIssueTrackers(
    integrations || [],
  );

  if (followUps == null) {
    return <Loader />;
  }

  const isPrivate = incident?.visibility === IncidentVisibilityEnum.Private;

  const showExternalIssueColumn =
    followUps.length > 0
      ? followUps.some(
          (followUp) => followUp.external_issue_reference != null,
        ) || showExternalLinkColumn
      : false;

  // We want to space columns evenly, but have the last one limited in size
  // for the overflow - there might be a nice way of doing this with grid.
  let middleColCount = 2;
  if (showExternalIssueColumn) {
    middleColCount++;
  }
  if (canUseFollowUpPriorities) {
    middleColCount++;
  }

  return (
    <>
      {followUpSuggestions.length > 0 && (
        <FollowUpSuggestionsPanel
          incident={incident}
          followUpSuggestions={followUpSuggestions}
        />
      )}
      {followUps.length === 0 ? (
        <div className="mt-6">
          <EmptyTab
            icon={IconEnum.FollowUps}
            subtitle="There are no follow-ups for this incident. Use follow-ups to track work to clean up after the incident."
          />
        </div>
      ) : (
        <Table
          className="mt-6"
          gridTemplateColumns={`5fr repeat(${middleColCount}, 2fr) 2rem`}
          header={
            <>
              <TableHeaderCell title="Title" />
              <TableHeaderCell title="Owner" />
              {showExternalIssueColumn && (
                <TableHeaderCell title="External link" />
              )}
              {canUseFollowUpPriorities && <TableHeaderCell title="Priority" />}
              <TableHeaderCell title="Status" />
              <TableHeaderCell title="" />
            </>
          }
          data={followUps}
          renderRow={(followUp, index) => {
            const policyViolation =
              policyViolations &&
              policyViolations.find(
                (violation) => violation.resource_id === followUp.id,
              );

            let violatedPolicy: Policy | undefined = undefined;
            if (policyViolation) {
              violatedPolicy = policies?.find(
                (policy) => policy.id === policyViolation.policy_id,
              );
            }

            return (
              <TableRow
                key={followUp.id}
                isLastRow={index === followUps.length - 1}
              >
                <TableCell>
                  <div className="flex grow items-center">
                    <div className="flex grow align-baseline">
                      {followUp.title}
                      {followUp.description && (
                        <Tooltip
                          buttonClassName="pl-1"
                          analyticsTrackingId={null}
                          content={<Markdown>{followUp.description}</Markdown>}
                          light
                        />
                      )}
                    </div>
                    <div className="self-right flex flex-col gap-1 items-center">
                      {policyViolation && violatedPolicy && (
                        <PolicyViolationNotification
                          resourceName="follow-up"
                          policy={violatedPolicy}
                          level={policyViolation.level}
                          daysUntil={policyViolation.days}
                          iconOnly
                          violationID={policyViolation.id}
                        />
                      )}
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <span className={styles.assigneeCell}>
                    {followUp.assignee ? (
                      <>
                        <Avatar
                          size={IconSize.Medium}
                          url={followUp.assignee.avatar_url}
                          name={followUp.assignee.name}
                          className="mr-2"
                        />
                        <span className={styles.assigneeName}>
                          {followUp.assignee.name}
                        </span>
                      </>
                    ) : (
                      <>&mdash;</>
                    )}
                  </span>
                </TableCell>

                {showExternalIssueColumn && (
                  <TableCell>
                    {followUp.external_issue_reference ? (
                      <ExternalIssueLink
                        reference={followUp.external_issue_reference}
                      />
                    ) : followUp.sync_failures &&
                      followUp.sync_failures.length > 0 ? (
                      <FollowUpExportFailureSection followUp={followUp} />
                    ) : (
                      <span>&mdash;</span>
                    )}
                  </TableCell>
                )}

                {canUseFollowUpPriorities && (
                  <TableCell>
                    {followUp.priority ? (
                      <span className={styles.statusCell}>
                        {followUp.priority.name}
                      </span>
                    ) : (
                      <span
                        className={tcx(styles.statusCell, "text-slate-400")}
                      >
                        No priority
                      </span>
                    )}
                  </TableCell>
                )}

                <TableCell>
                  <span className={styles.statusCell}>
                    <ActionStatus
                      status={followUp.status as unknown as ActionStatusEnum}
                    />
                  </span>
                </TableCell>

                <TableCell>
                  <div className="grow">
                    <DropdownMenu
                      triggerButtonTheme={ButtonTheme.Unstyled}
                      analyticsTrackingId="action-more-options"
                      screenReaderText="More options"
                      triggerIcon={IconEnum.DotsVertical}
                    >
                      <DropdownMenuItem
                        analyticsTrackingId="follow-ups-list-edit"
                        label="Edit"
                        onSelect={() =>
                          navigate({
                            pathname: location.pathname,
                            search: `?tab=follow-ups&follow_up_id=${followUp.id}`,
                          })
                        }
                      />
                      <DropdownMenuItem
                        analyticsTrackingId="follow-ups-list-delete-modal-open"
                        label="Delete"
                        onSelect={() =>
                          setIsDeletingFollowUpModalOpen(followUp)
                        }
                      />
                      <DropdownMenuItem
                        analyticsTrackingId="follow-ups-list-mark-as-not-doing"
                        label="Mark as not doing"
                        disabled={
                          followUp.status === FollowUpStatusEnum.NotDoing ||
                          !!followUp.external_issue_reference
                        }
                        onSelect={() =>
                          setIsNotDoingFollowUpModalOpen(followUp)
                        }
                      >
                        <span>
                          Mark as{" "}
                          <span className="font-semibold">Not doing</span>
                        </span>
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        analyticsTrackingId="follow-ups-list-mark-as-complete"
                        label="Mark as complete"
                        disabled={
                          followUp.status === FollowUpStatusEnum.Completed ||
                          !!followUp.external_issue_reference
                        }
                        onSelect={() =>
                          setIsCompletingFollowUpModalOpen(followUp)
                        }
                      >
                        <span>
                          Mark as{" "}
                          <span className="font-semibold">Complete</span>
                        </span>
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        analyticsTrackingId="follow-ups-list-remind"
                        disabled={
                          followUp.status === FollowUpStatusEnum.Completed ||
                          followUp.status === FollowUpStatusEnum.NotDoing
                        }
                        onSelect={() => setIsSendReminderModalOpen(followUp)}
                        label={"Send a reminder"}
                      />

                      {!followUp.external_issue_reference &&
                        _.sortBy(
                          issueTrackerIntegrations,
                          (i) => i.provider,
                        ).map((integration) => {
                          return (
                            <DropdownMenuItem
                              analyticsTrackingId="follow-ups-list-export-modal-open"
                              analyticsTrackingMetadata={{
                                provider: integration.provider,
                              }}
                              key={integration.provider}
                              onSelect={() =>
                                setExportToProvider({
                                  provider: integration.provider,
                                  followUp,
                                })
                              }
                              icon={
                                IntegrationConfigFor(integration.provider).icon
                              }
                              label={`${
                                integration.provider ===
                                IssueTrackerProviderEnum.Shortcut
                                  ? `Create`
                                  : `Create or link`
                              } a ${
                                IntegrationConfigFor(integration.provider).label
                              } issue`}
                              disabled={integration.disabled}
                              tooltipContent={
                                integration.disabled
                                  ? `There is a problem with your connection to ${
                                      IntegrationConfigFor(integration.provider)
                                        .label
                                    }`
                                  : undefined
                              }
                            />
                          );
                        })}
                    </DropdownMenu>
                  </div>
                </TableCell>
              </TableRow>
            );
          }}
        />
      )}

      {/* Modals */}
      {isNotDoingFollowUpModalOpen && (
        <FollowUpNotDoingModal
          followUp={isNotDoingFollowUpModalOpen}
          onClose={() => setIsNotDoingFollowUpModalOpen(null)}
        />
      )}
      {isCompletingFollowUpModalOpen && (
        <FollowUpCompleteModal
          followUp={isCompletingFollowUpModalOpen}
          onClose={() => setIsCompletingFollowUpModalOpen(null)}
        />
      )}
      {isDeletingFollowUpModalOpen && (
        <FollowUpDeleteModal
          followUp={isDeletingFollowUpModalOpen}
          onClose={() => setIsDeletingFollowUpModalOpen(null)}
        />
      )}
      {isSendReminderModalOpen && (
        <SendRemindersModal
          selectedResourceIDs={[isSendReminderModalOpen.id]}
          // You'll only be able to click "send reminder" if the follow-up is open
          numResourcesToIgnore={0}
          resourceType={RemindersCreateRequestBodyReminderTypeEnum.FollowUp}
          onClose={() => {
            setIsSendReminderModalOpen(null);
          }}
          onCancel={() => setIsSendReminderModalOpen(null)}
        />
      )}
      {editingFollowUp && (
        <FollowUpEditModal
          isPrivateIncident={isPrivate}
          followUp={editingFollowUp}
          onClose={() =>
            navigate({
              pathname: location.pathname,
              search: `?tab=follow-ups`,
            })
          }
        />
      )}
      {exportToProvider != null && incident != null && (
        <ExportToProviderModal
          provider={exportToProvider.provider}
          incident={incident}
          followUp={exportToProvider.followUp}
          onClose={() => setExportToProvider(null)}
          isPrivate={isPrivate}
          updateCallback={refetch}
        />
      )}
    </>
  );
}
