/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResourceFieldConfig
 */
export interface ResourceFieldConfig {
    /**
     * String to use as placeholder when collecting an array
     * @type {string}
     * @memberof ResourceFieldConfig
     */
    array_placeholder: string;
    /**
     * Fromm field type for collecting an array of these resources
     * @type {string}
     * @memberof ResourceFieldConfig
     */
    array_type: ResourceFieldConfigArrayTypeEnum;
    /**
     * String telling the UI what colour to use for this field (if there is a specific colour)
     * @type {string}
     * @memberof ResourceFieldConfig
     */
    color?: ResourceFieldConfigColorEnum;
    /**
     * String telling the UI what icon to use for this field
     * @type {string}
     * @memberof ResourceFieldConfig
     */
    icon: ResourceFieldConfigIconEnum;
    /**
     * String to use as placeholder
     * @type {string}
     * @memberof ResourceFieldConfig
     */
    placeholder: string;
    /**
     * Form field type, helping tell the frontend what we need to display
     * @type {string}
     * @memberof ResourceFieldConfig
     */
    type: ResourceFieldConfigTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ResourceFieldConfigArrayTypeEnum {
    None = 'none',
    MultiTextInput = 'multi_text_input',
    MultiStaticSelect = 'multi_static_select',
    MultiDynamicSelect = 'multi_dynamic_select',
    MultiExternalSelect = 'multi_external_select',
    MultiExternalUserSelect = 'multi_external_user_select'
}/**
* @export
* @enum {string}
*/
export enum ResourceFieldConfigColorEnum {
    Yellow = 'yellow',
    Green = 'green',
    Blue = 'blue',
    Violet = 'violet',
    Pink = 'pink',
    Cyan = 'cyan',
    Orange = 'orange'
}/**
* @export
* @enum {string}
*/
export enum ResourceFieldConfigIconEnum {
    Action = 'action',
    Alert = 'alert',
    Bolt = 'bolt',
    ToggleLeft = 'toggle-left',
    Book = 'book',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Checkmark = 'checkmark',
    Clipboard = 'clipboard',
    Clock = 'clock',
    Close = 'close',
    Cog = 'cog',
    Components = 'components',
    CustomField = 'custom_field',
    Database = 'database',
    Delete = 'delete',
    Doc = 'doc',
    Email = 'email',
    Escalate = 'escalate',
    EscalationPath = 'escalation-path',
    Exclamation = 'exclamation',
    Export = 'export',
    ExternalLink = 'external-link',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    FollowUps = 'follow_ups',
    GitBranchNew = 'git-branch-new',
    Globe = 'globe',
    Incident = 'incident',
    IncidentType = 'incident_type',
    Key = 'key',
    MsTeams = 'ms-teams',
    Merge = 'merge',
    Message = 'message',
    Money = 'money',
    Mug = 'mug',
    NumberInput = 'number-input',
    Priority = 'priority',
    Private = 'private',
    TextAlignLeft = 'text-align-left',
    Search = 'search',
    Server = 'server',
    Severity = 'severity',
    Slack = 'slack',
    SlackChannel = 'slack_channel',
    SlackTeam = 'slack_team',
    Star = 'star',
    Status = 'status',
    StatusPage = 'status-page',
    Store = 'store',
    Tag = 'tag',
    Test = 'test',
    Text = 'text',
    Timestamp = 'timestamp',
    Triage = 'triage',
    User = 'user',
    Users = 'users',
    Warning = 'warning'
}/**
* @export
* @enum {string}
*/
export enum ResourceFieldConfigTypeEnum {
    None = 'none',
    Checkbox = 'checkbox',
    TextInput = 'text_input',
    TextNumberInput = 'text_number_input',
    TextAreaInput = 'text_area_input',
    DateInput = 'date_input',
    DateTimeInput = 'date_time_input',
    DurationInput = 'duration_input',
    RichTextEditorInput = 'rich_text_editor_input',
    TemplatedTextEditorInput = 'templated_text_editor_input',
    SlackChannelInput = 'slack_channel_input',
    SingleStaticSelect = 'single_static_select',
    SingleDynamicSelect = 'single_dynamic_select',
    SingleExternalSelect = 'single_external_select',
    SingleExternalUserSelect = 'single_external_user_select'
}

export function ResourceFieldConfigFromJSON(json: any): ResourceFieldConfig {
    return ResourceFieldConfigFromJSONTyped(json, false);
}

export function ResourceFieldConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceFieldConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array_placeholder': json['array_placeholder'],
        'array_type': json['array_type'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'icon': json['icon'],
        'placeholder': json['placeholder'],
        'type': json['type'],
    };
}

export function ResourceFieldConfigToJSON(value?: ResourceFieldConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array_placeholder': value.array_placeholder,
        'array_type': value.array_type,
        'color': value.color,
        'icon': value.icon,
        'placeholder': value.placeholder,
        'type': value.type,
    };
}

