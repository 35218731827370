import { Loader } from "@incident-ui";
import _ from "lodash";
import { useState } from "react";
import { ExploDashboardID } from "src/components/insights/dashboards/dashboards";
import { SavedViewsListContextEnum } from "src/contexts/ClientContext";

import { getFollowUpAttributeOptions } from "../../common/getInsightsSelectOptions";
import { GroupByCustomFieldDropdown } from "../../common/GroupByCustomFieldDropdown";
import { InsightsDashboardSection } from "../../common/InsightsDashboardSection";
import { InsightsPage } from "../../common/InsightsPage";
import { SelectUser } from "../../common/SelectUser";
import { useCustomFieldOptions } from "../../common/useCustomFieldOptions";
import { InsightsContextProvider } from "../../context/InsightsContextProvider";
import {
  FieldConfigGroupOptionsDefined,
  StateConfig,
} from "../../context/types";
import { useInsightsContext } from "../../context/useInsightsContext";
import { SplitByFollowUpAttributesDropdown } from "./SplitByFollowUpAttributesDropdown";

export interface FollowUpsDashboardStateConfig extends StateConfig {
  split_follow_up_by: FieldConfigGroupOptionsDefined;
}

export const useFollowUpsDashboardStateConfig = (): {
  loading: boolean;
  stateConfig: FollowUpsDashboardStateConfig;
} => {
  const { customFieldOptions, loading } = useCustomFieldOptions();

  const { followUpAttributeOptions } =
    useFollowUpAttributeOptions(customFieldOptions);
  return {
    loading: loading,
    stateConfig: {
      group_by_custom_field: {
        options: customFieldOptions,
      },
      split_follow_up_by: {
        options: followUpAttributeOptions,
        isGroupedOptions: true,
      },
    },
  };
};

export const FollowUpsDashboard = (): React.ReactElement => {
  const { loading, stateConfig } = useFollowUpsDashboardStateConfig();
  if (loading) {
    return <Loader />;
  }
  return (
    <InsightsContextProvider<FollowUpsDashboardStateConfig>
      context={SavedViewsListContextEnum.InsightsV2}
      stateConfig={stateConfig}
    >
      <InsightsPage dashboard="follow-ups">
        <FollowUpsCreatedDashboard />
        <FollowUpsPerUserDashboard />
        <FollowUpsForUserDashboard />
        <FollowUpsGroupByFieldDashboard />
      </InsightsPage>
    </InsightsContextProvider>
  );
};

const FollowUpsCreatedDashboard = () => {
  const { getOptions, useState } =
    useInsightsContext<FollowUpsDashboardStateConfig>();

  const [_, setSelectedFollowUpAttributeID] = useState("split_follow_up_by");

  const attribute = useSelectedFollowUpAttribute();

  return (
    <InsightsDashboardSection
      title="Follow-ups created"
      description="Understand how many follow-ups you have, when they were created and what state they are in. You can also group by a custom field to identify which incidents they have been created for."
      dashboardEmbedID={ExploDashboardID.PincFollowUpsCreated}
      dashboardVariables={
        attribute
          ? {
              split_by_type: attribute.description?.toString() ?? "",
              ...(attribute.description?.toString() === "custom_field" && {
                split_by_id: attribute.value,
              }),
            }
          : {}
      }
      controls={
        <SplitByFollowUpAttributesDropdown
          followUpAttributeOptions={getOptions("split_follow_up_by")}
          selectedFollowUpAttributeID={attribute?.value ?? ""}
          setSelectedFollowUpAttributeID={setSelectedFollowUpAttributeID}
        />
      }
      initialHeight="412px"
    />
  );
};

const FollowUpsPerUserDashboard = () => {
  return (
    <InsightsDashboardSection
      title="Follow-ups per user"
      description="See how many follow-ups each user has been assigned to, and what the status of each follow-up is."
      dashboardEmbedID={ExploDashboardID.PincFollowUpsPerUser}
      initialHeight="478px"
      noBottomDivider
    />
  );
};

const FollowUpsForUserDashboard = () => {
  const [userID, setUserID] = useState<string | undefined>();

  return (
    <InsightsDashboardSection
      // Need to provide this key so that we can re-mount the dashboard with the
      // userID and actually filter by that user
      key={userID}
      dashboardEmbedID={ExploDashboardID.PincFollowUpsForUser}
      dashboardVariables={{
        selected_user_id: userID,
      }}
      controls={<SelectUser userID={userID} setUserID={setUserID} />}
      initialHeight="478px"
    />
  );
};

const FollowUpsGroupByFieldDashboard = () => {
  const {
    state: customField,
    setState: setCustomFieldID,
    options,
  } = useGroupByCustomFieldMethods();
  return (
    <InsightsDashboardSection
      title="Follow-ups by custom field"
      description="You can additionally compare follow-ups, grouped by a custom field. You might use this to determine whether incidents involving certain product features or teams result in more follow-ups or a longer completion time."
      dashboardEmbedID={ExploDashboardID.PincFollowUpsByCustomField}
      dashboardVariables={{
        custom_field_id: customField?.value,
        custom_field_name: customField?.label,
      }}
      controls={
        <GroupByCustomFieldDropdown
          customFieldOptions={options}
          selectedCustomFieldID={customField?.value ?? ""}
          setSelectedCustomFieldID={setCustomFieldID}
        />
      }
      initialHeight="874px"
    />
  );
};

export const useSelectedFollowUpAttribute = () => {
  const { useState, getFlatOptions } =
    useInsightsContext<FollowUpsDashboardStateConfig>();

  const [attributeID] = useState("split_follow_up_by");
  return getFlatOptions("split_follow_up_by").find(
    (option) => option.value === attributeID,
  );
};

export const useFollowUpAttributeOptions = (customFieldOptions) => {
  const followUpAttributeOptions = getFollowUpAttributeOptions(
    customFieldOptions.map((option) => ({
      sort_key: option.label,
      id: option.value,
      label: option.label,
    })),
  );

  const groupedOptions = _.groupBy(
    followUpAttributeOptions,
    (option) => option.groupName,
  );

  return {
    followUpAttributeOptions: Object.keys(groupedOptions).map((groupName) => ({
      label: groupName,
      options: groupedOptions[groupName].map((option) => ({
        value: option.id,
        label: option.label,
        icon: option.icon,
        description: option.description,
      })),
    })),
  };
};

const useGroupByCustomFieldMethods = () => {
  const { useState, getOptions } =
    useInsightsContext<FollowUpsDashboardStateConfig>();
  const [customFieldID, setCustomFieldID] = useState("group_by_custom_field");
  const options = getOptions("group_by_custom_field");
  const state = options.find((field) => field.value === customFieldID);
  return {
    state,
    setState: setCustomFieldID,
    options,
  };
};
