import {
  ContentBox,
  GenericErrorMessage,
  Heading,
  Loader,
  StaticSingleSelect,
  Tooltip,
} from "@incident-ui";
import { ErrorBoundary } from "@sentry/react";
import React, { useState } from "react";
import { CalendarEntry } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import {
  Calendar,
  CalendarDefaults,
  calendarHeight,
  CalendarView,
} from "./calendar/Calendar";
import {
  HomeActivityIncidentsList,
  SelectedDateInfo,
} from "./HomeActivityIncidentsList";

const SectionWrapper = ({
  accessory,
  children,
}: {
  accessory?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <div>
      <div className="flex items-center mb-3 gap-1">
        <Heading title="Activity" level={2} size="medium">
          Activity
        </Heading>
        {accessory}
      </div>
      <ContentBox className="p-4 overflow-hidden">{children}</ContentBox>
    </div>
  );
};

// HomeActivity, where we display a calendar view of the users activity.
export const HomeActivity = (): React.ReactElement | null => {
  const {
    data: { calendar: entries },
    error,
    isLoading,
  } = useAPI(
    "insightsGetCalendar",
    {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    {
      fallbackData: { calendar: [] },
    },
  );

  if (error) {
    console.error(error);
    return (
      <SectionWrapper>
        <GenericErrorMessage error={error} />
      </SectionWrapper>
    );
  }

  if (isLoading) {
    return (
      <SectionWrapper>
        <div className="min-h-[187px] flex items-center justify-center">
          <Loader />
        </div>
      </SectionWrapper>
    );
  }

  return <HomeActivityLoaded entries={entries} />;
};

export const HomeActivityLoaded = ({
  entries,
}: {
  entries: CalendarEntry[] | null;
}): React.ReactElement | null => {
  const [view, setView] = useState(CalendarView.Organisation);
  const [selectedDateInfo, setSelectedDateInfo] =
    useState<SelectedDateInfo | null>(null);

  return (
    <SectionWrapper
      accessory={
        <>
          <Tooltip
            content={
              <>
                Each square is shaded according to the amount of time spent
                working on incidents for that day, either across the entire
                organisation or for your user, depending on the selected filter.
              </>
            }
          />
          <div className="ml-auto">
            <ViewSelect
              view={view}
              setView={(view) => {
                setView(view);
              }}
            />
          </div>
        </>
      }
    >
      <ErrorBoundary fallback={<GenericErrorMessage />}>
        {entries ? (
          <Calendar
            {...{
              data: entries,
              ...CalendarDefaults,
              view,
              onSelectItem: setSelectedDateInfo,
              selectedItem: selectedDateInfo,
            }}
          />
        ) : (
          <div className={`min-h-[${calendarHeight(CalendarDefaults)}px]`}>
            <Loader />
          </div>
        )}
        {selectedDateInfo != null ? (
          <HomeActivityIncidentsList selectedDateInfo={selectedDateInfo} />
        ) : null}
      </ErrorBoundary>
    </SectionWrapper>
  );
};

// Pick a view, either of all organisation users or just the person currently
// logged in.
const ViewSelect = ({
  view,
  setView,
}: {
  view: CalendarView;
  setView: (view: CalendarView) => void;
}): React.ReactElement => {
  return (
    <div className={"flex items-center font-normal"}>
      <span className="text-sm text-slate-700 mr-2">Include</span>
      <div className="flex-grow">
        <StaticSingleSelect
          placeholder={"Pick view"}
          isClearable={false}
          isSearchable={false}
          value={view}
          label={"Filter for"}
          onChange={(value) => {
            if (typeof value === "string") {
              setView(value as unknown as CalendarView);
            }
          }}
          options={[
            {
              label: "All users",
              value: CalendarView.Organisation,
            },
            {
              label: "Just me",
              value: CalendarView.User,
            },
          ]}
        />
      </div>
    </div>
  );
};
