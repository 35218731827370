import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import {
  AsanaBulkExportableFields,
  AsanaFormData,
} from "@incident-shared/issue-trackers";
import { ModalFooter } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useEffect } from "react";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import {
  FollowUp,
  IssueTrackersAsanaTypeaheadOptionsFieldEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  BulkExportBlurb,
  BulkExportCallouts,
  BulkExportTitleAndDescription,
  getBulkExportTitle,
  getBulkToastTitle,
  NoFollowUpsToExportModal,
} from "./helpers";

export const BulkExportToAsanaModal = ({
  followUpsToExport,
  numFollowUpsToExportFromPrivate,
  numFollowUpsAlreadyExported,
  onClose,
  onCancel,
  updateCallback,
}: {
  followUpsToExport: FollowUp[];
  numFollowUpsToExportFromPrivate: number;
  numFollowUpsAlreadyExported: number;
  onClose: () => void;
  onCancel: () => void;
  updateCallback: () => void;
}): ReactElement | null => {
  const formMethods = useForm<AsanaFormData>({
    mode: "onSubmit",
  });
  const { watch, setValue } = formMethods;

  const showToast = useToast();
  const providerName = "Asana";

  const {
    data: { typeahead_options: teams },
    isLoading: teamsLoading,
    error: teamsError,
  } = useAPI(
    "issueTrackersAsanaTypeaheadOptions",
    { field: IssueTrackersAsanaTypeaheadOptionsFieldEnum.Team },
    { fallbackData: { typeahead_options: [] } },
  );

  // Load projects and users once we have the team selected
  const teamId = watch("team_id");
  const {
    data: { typeahead_options: projects },
    isLoading: projectsLoading,
    error: projectsError,
  } = useAPI(
    teamId ? "issueTrackersAsanaTypeaheadOptions" : null,
    { field: IssueTrackersAsanaTypeaheadOptionsFieldEnum.Project, teamId },
    { fallbackData: { typeahead_options: [] } },
  );

  const {
    data: { typeahead_options: users },
    isLoading: usersLoading,
    error: usersError,
  } = useAPI(
    teamId ? "issueTrackersAsanaTypeaheadOptions" : null,
    { field: IssueTrackersAsanaTypeaheadOptionsFieldEnum.User, teamId },
    { fallbackData: { typeahead_options: [] } },
  );

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      // Ignore changes to other fields
      if (name !== "team_id") {
        return;
      }

      // Clear the assignee and project: they may be invalid
      // @ts-expect-error there's no clearValue function, apparently
      setValue<"project_id">("project_id", null);
      // @ts-expect-error there's no clearValue function, apparently
      setValue<"assignee_id">("assignee_id", null);
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    {},
    async (apiClient, data: AsanaFormData) => {
      await Promise.all(
        followUpsToExport.map((followUp) =>
          apiClient.issueTrackersAsanaCreateIssue({
            asanaCreateIssueRequestBody: {
              follow_up_id: followUp.id,
              project_id: data.project_id,
              title: followUp.title,
              description: followUp.description,
              assignee_id:
                data.assignee_id === "" ? undefined : data.assignee_id,
            },
          }),
        ),
      );
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: getBulkToastTitle(followUpsToExport.length, providerName),
        });
        updateCallback();
        onClose();
      },
    },
  );

  const title = getBulkExportTitle(followUpsToExport.length);

  const fetchDataError =
    teamsError || usersError || projectsError
      ? "There was a problem loading data from Asana."
      : "";

  if (followUpsToExport.length === 0) {
    return (
      <NoFollowUpsToExportModal
        numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
        onClose={onCancel}
      />
    );
  } else {
    return (
      <FormModalV2
        formMethods={formMethods}
        onSubmit={onSubmit}
        title={title}
        analyticsTrackingId="bulk-add-to-asana"
        onClose={onCancel}
        loading={teamsLoading}
        genericError={genericError || fetchDataError}
        footer={
          <ModalFooter
            confirmButtonText={title}
            saving={saving}
            onClose={onCancel}
            confirmButtonType="submit"
          />
        }
      >
        <BulkExportCallouts
          numFollowUpsToExportFromPrivate={numFollowUpsToExportFromPrivate}
          numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
          accountName="Asana team"
        />
        <BulkExportBlurb
          providerIssues="Asana tickets"
          providerName={providerName}
        />
        <BulkExportTitleAndDescription providerName={providerName} />
        <AsanaBulkExportableFields
          projects={projects ?? []}
          projectsLoading={projectsLoading}
          teams={teams ?? []}
          teamsLoading={teamsLoading}
          users={users ?? []}
          usersLoading={usersLoading}
        />
      </FormModalV2>
    );
  }
};
