import {
  AlertAttribute,
  AlertSourceConfig,
  AlertSourceSuggestedAttribute,
} from "@incident-io/api";
import { ReferenceWithExample } from "@incident-shared/engine/expressions/ExpressionsEditor";
import { Loader } from "@incident-ui";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { EnrichedScope } from "src/utils/scope";
import { useAPI } from "src/utils/swr";

import { AlertSourceSuggestion } from "../../alert-source-create-edit/AlertSourceSuggestion";
type AlertSourceSuggestedAttributesProps = {
  alertSourceConfig: AlertSourceConfig;
  attributes: AlertAttribute[];
  scopeWithExpressions: EnrichedScope<ReferenceWithExample>;
};

export const AlertSourceSuggestedAttributes = (
  props: AlertSourceSuggestedAttributesProps,
) => {
  const {
    data: suggestedAttributesData,
    isLoading: suggestedAttributesLoading,
    error: suggestedAttributesError,
  } = useAPI(
    "alertsSuggestAttributes",
    {
      id: props.alertSourceConfig.id,
      suggestAttributesRequestBody: {
        reset: true,
      },
    },
    {
      keepPreviousData: true,
    },
  );

  const [suggestions, setSuggestions] = useState<
    AlertSourceSuggestedAttribute[]
  >(suggestedAttributesData?.suggestions || []);

  useEffect(() => {
    setSuggestions(suggestedAttributesData?.suggestions || []);
  }, [suggestedAttributesData]);

  if (suggestedAttributesLoading) {
    return <Loader />;
  }
  if (suggestedAttributesError) {
    return null;
  }

  if (suggestedAttributesData?.suggestions.length === 0) {
    return null;
  }

  return (
    <>
      {" "}
      {suggestions.length > 0 && (
        <motion.div
          key="header"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <p
            className="text-sm-bold mb-3"
            style={{
              width: "fit-content",
              background: "linear-gradient(45deg, #3b82f6, #c084fc, #db2777)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {suggestions.length} suggested attribute
            {suggestions.length > 1 ? "s" : ""}
          </p>
        </motion.div>
      )}
      {suggestions.map((suggestion) => (
        <motion.div
          key={suggestion.id}
          layout
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <AlertSourceSuggestion
            suggestion={suggestion}
            scope={props.scopeWithExpressions}
            onAdd={() => {
              setSuggestions((prev) =>
                prev.filter((s) => s.id !== suggestion.id),
              );
            }}
            onRemove={() => {
              setSuggestions((prev) =>
                prev.filter((s) => s.id !== suggestion.id),
              );
            }}
          />
        </motion.div>
      ))}
    </>
  );
};
