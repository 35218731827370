import { Product } from "@incident-shared/billing";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuLink,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { DropdownMenuGroup } from "@incident-ui/DropdownMenu/DropdownMenu";
import { kebabCase } from "lodash";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { SecondaryNavItem } from "./SecondaryNavItem";

export type SecondaryNavGroup = {
  label?: string;
  items: (SecondaryNavItemType | SecondaryNavButton)[];
};

interface BaseSecondaryNavItem {
  label: string;
  slug: string;
  hide?: boolean;
  icon?: IconEnum;
  isPro?: boolean;
  isEnterprise?: boolean;
  newUntil?: Date;
  requiredProduct?: Product;
}

export interface SecondaryNavItemType extends BaseSecondaryNavItem {
  type?: "SecondaryNavItemType";
}
export interface SecondaryNavButton extends BaseSecondaryNavItem {
  type: "SecondaryNavButton";
  onClick: () => void;
  active: boolean;
}

export const SecondaryNavDesktop = ({
  itemGroups,
  pathPrefix,
  className,
  prefixNode,
  title,
  applyPadding = true,
}: {
  itemGroups: SecondaryNavGroup[];
  pathPrefix: string;
  className?: string;
  prefixNode?: React.ReactNode;
  title?: string;
  applyPadding?: boolean;
}): React.ReactElement => {
  return (
    <nav
      className={tcx(
        applyPadding ? "px-6 pb-6" : "",
        "pt-[4px] h-full w-fit relative shrink-0",
        "hidden md:flex flex-col gap-3",
        className,
      )}
    >
      {title && <p className="text-2xl-bold mt-6 mb-4">{title}</p>}
      {prefixNode}
      {itemGroups.map((grp, i) => (
        <div key={i} className="flex flex-col gap-[2px]">
          {grp.label ? (
            <div className="pl-3 pb-2 text-content-tertiary text-xs-bold">
              {grp.label}
            </div>
          ) : null}
          {grp.items
            .filter((i) => !i.hide)
            .map((item) => (
              <SecondaryNavItem
                item={item}
                key={item.slug}
                pathPrefix={pathPrefix}
              />
            ))}
        </div>
      ))}
    </nav>
  );
};

export const SecondaryNavMobile = ({
  itemGroups,
  pathPrefix,
  className,
}: {
  itemGroups: SecondaryNavGroup[];
  pathPrefix: string;
  className?: string;
}): React.ReactElement => {
  return (
    <div className={tcx("md:hidden shrink-0 pb-6", className)}>
      <DropdownMenu
        triggerIcon={IconEnum.Menu}
        triggerIconSize={IconSize.Large}
        screenReaderText="Settings menu"
        analyticsTrackingId="settings-menu"
        scroll
      >
        {itemGroups.map((grp, i) => (
          <DropdownMenuGroup key={i} label={grp.label}>
            {grp.items
              .filter((i) => !i.hide)
              .map((page) =>
                page.type === "SecondaryNavButton" ? (
                  <DropdownMenuItem
                    label={page.label}
                    key={page.slug}
                    icon={page.icon}
                    onSelect={page.onClick}
                    analyticsTrackingId={kebabCase(page.label)}
                  />
                ) : (
                  <DropdownMenuLink
                    label={page.label}
                    key={page.slug}
                    icon={page.icon}
                    to={`/${pathPrefix}/${page.slug}`}
                    analyticsTrackingId={kebabCase(page.label)}
                  />
                ),
              )}
          </DropdownMenuGroup>
        ))}
      </DropdownMenu>
    </div>
  );
};
