import { FollowUp, Incident } from "src/contexts/ClientContext";

import { ExportToAsanaModal } from "./ExportToAsanaModal";
import { ExportToClickUpModal } from "./ExportToClickUpModal";
import { ExportToGitLabModal } from "./ExportToGitLabModal";
import { ExportToIssueTrackerModal } from "./ExportToIssueTrackerModal";
import { ExportToLinearModal } from "./ExportToLinearModal";
import { ExportToShortcutModal } from "./ExportToShortcutModal";
import { ExportToJiraCloudModal } from "./jira/ExportToJiraCloudModal";
import { IssueTrackerProviderEnum } from "./utils";

export const ExportToProviderModal = ({
  provider,
  followUp,
  onClose,
  incident,
  isPrivate,
  updateCallback,
}: {
  provider: IssueTrackerProviderEnum;
  followUp: FollowUp;
  onClose: () => void;
  isPrivate: boolean;
  updateCallback: (newFollowUp: FollowUp) => Promise<void>;
  incident: Incident;
}): React.ReactElement => {
  switch (provider) {
    case IssueTrackerProviderEnum.Asana:
      return (
        <ExportToAsanaModal
          followUp={followUp}
          isPrivateIncident={isPrivate}
          onClose={onClose}
          updateCallback={updateCallback}
        />
      );

    case IssueTrackerProviderEnum.ClickUp:
      return (
        <ExportToClickUpModal
          followUp={followUp}
          isPrivateIncident={isPrivate}
          onClose={onClose}
          updateCallback={updateCallback}
        />
      );

    case IssueTrackerProviderEnum.Gitlab:
      return (
        <ExportToGitLabModal
          followUp={followUp}
          isPrivateIncident={isPrivate}
          onClose={onClose}
          updateCallback={updateCallback}
        />
      );

    case IssueTrackerProviderEnum.Jira:
      return (
        <ExportToJiraCloudModal
          incident={incident}
          followUp={followUp}
          isPrivateIncident={isPrivate}
          onClose={onClose}
          updateCallback={updateCallback}
        />
      );

    case IssueTrackerProviderEnum.Shortcut:
      return (
        <ExportToShortcutModal
          followUp={followUp}
          isPrivateIncident={isPrivate}
          onClose={onClose}
          updateCallback={updateCallback}
        />
      );

    case IssueTrackerProviderEnum.Linear:
      return (
        <ExportToLinearModal
          followUp={followUp}
          isPrivateIncident={isPrivate}
          onClose={onClose}
          updateCallback={updateCallback}
        />
      );

    default:
      return (
        <ExportToIssueTrackerModal
          provider={provider}
          incident={incident}
          followUp={followUp}
          isPrivateIncident={isPrivate}
          onClose={onClose}
          updateCallback={updateCallback}
        />
      );
  }
};
