import { Incident } from "@incident-io/api";
import { IconEnum } from "@incident-ui";

import { TimelineItemObject } from "../../../contexts/ClientContext";
import { getProviderNameAndLogo } from "../../incident-calls/helpers";
import { TimelineItemRenderProps } from "./TimelineItem";

export const TimelineItemIncidentCallStarted = (
  inc: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.incident_call_started) {
    throw new Error(
      "malformed timeline item: missing incident_call_started field",
    );
  }

  const { name, logo } = getProviderNameAndLogo(
    item.incident_call_started.incident_call.external_provider,
  );

  return {
    icon: IconEnum.Phone,
    avatarUrl: logo,
    description: (
      <>
        <strong>{name} </strong>
        call started
      </>
    ),
  };
};
