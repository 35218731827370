/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface TimelineNote
 */
export interface TimelineNote {
    /**
     * The ID of the timeline item that was adjacent to where the note was created, and happened more recently.
     * @type {string}
     * @memberof TimelineNote
     */
    after_timeline_item_id?: string;
    /**
     * The ID of the timeline item that was adjacent to where the note was created, and happened first.
     * @type {string}
     * @memberof TimelineNote
     */
    before_timeline_item_id?: string;
    /**
     * 
     * @type {Actor}
     * @memberof TimelineNote
     */
    creator: Actor;
    /**
     * 
     * @type {TextNode}
     * @memberof TimelineNote
     */
    text: TextNode;
    /**
     * 
     * @type {Actor}
     * @memberof TimelineNote
     */
    updater: Actor;
}

export function TimelineNoteFromJSON(json: any): TimelineNote {
    return TimelineNoteFromJSONTyped(json, false);
}

export function TimelineNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'after_timeline_item_id': !exists(json, 'after_timeline_item_id') ? undefined : json['after_timeline_item_id'],
        'before_timeline_item_id': !exists(json, 'before_timeline_item_id') ? undefined : json['before_timeline_item_id'],
        'creator': ActorFromJSON(json['creator']),
        'text': TextNodeFromJSON(json['text']),
        'updater': ActorFromJSON(json['updater']),
    };
}

export function TimelineNoteToJSON(value?: TimelineNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'after_timeline_item_id': value.after_timeline_item_id,
        'before_timeline_item_id': value.before_timeline_item_id,
        'creator': ActorToJSON(value.creator),
        'text': TextNodeToJSON(value.text),
        'updater': ActorToJSON(value.updater),
    };
}

