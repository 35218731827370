import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";

export const AlertSourceStatusCakeSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Go to the <strong>Contact Groups</strong> section in StatusCake
      </p>
      <p>
        Click <strong>New Contact Group</strong> and create a new group
      </p>
      <p>
        Scroll to the bottom, go to the <strong>Webhook URL</strong> section
      </p>
      <SetupInfoSingleLineBlocks
        blocks={[
          {
            label: (
              <>
                Then copy and paste in the <strong>Webhook URL</strong> below
              </>
            ),
            code: `${publicApiUrl()}/v2/alert_events/statuscake/${
              alertSourceConfig.id
            }`,
          },
        ]}
      />

      <p>
        Save your Contact Group, click <strong>Test</strong> to send a test
        alert
      </p>
      <p>
        Assign the incident.io Contact Group you have just created to the
        Monitoring tests you want to receive alerts for.
      </p>
    </SetupInfoNumberedList>
  );
};
