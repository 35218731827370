import {
  Button,
  ButtonTheme,
  DeprecatedTable,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  Heading,
  IconEnum,
  IconSize,
  Link,
  Loader,
  OrgAwareLink,
} from "@incident-ui";
import _, { groupBy } from "lodash";
import { useMemo, useState } from "react";
import { DismissViolationConfirmationModal } from "src/components/settings/policy/common/PolicyDismissConfirmationModal";
import {
  Incident,
  Policy,
  PolicyPolicyTypeEnum,
  PolicyViolation,
  PolicyViolationLevelEnum,
  SlackTeamConfig,
} from "src/contexts/ClientContext";
import { ScopeNameEnum as ScopeEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

export const HomePolicyViolations = (): React.ReactElement | null => {
  const {
    data: { slack_team_configs: slackTeamConfigs },
    isLoading: slackTeamConfigsLoading,
  } = useAPI("slackTeamConfigsList", undefined, {
    fallbackData: { slack_team_configs: [] },
  });

  const [showMore, setShowMore] = useState(false);

  const {
    data: { policy_violations: allPolicyViolations },
    isLoading: violationsLoading,
  } = useAPI("policiesListViolationsForUser", undefined, {
    fallbackData: { policy_violations: [] },
  });

  const incident_ids = useMemo(
    () => new Set(allPolicyViolations.map(({ incident_id }) => incident_id)),
    [allPolicyViolations],
  );

  const {
    data: { incidents },
    isLoading: incidentsLoading,
  } = useAPI(
    "incidentsList",
    {
      id: { one_of: Array.from(incident_ids) },
      pageSize: incident_ids.size,
      includeFollowUps: true,
    },
    {
      fallbackData: { incidents: [] },
      // If the list changes, we might as well keep the previous list of
      // incidents while we load in any new ones.
      keepPreviousData: true,
    },
  );

  const incidentIDToIncident = incidents.reduce(
    (incidentIDToIncident, incident) => {
      incidentIDToIncident.set(incident.id, incident);
      return incidentIDToIncident;
    },
    new Map<string, Incident>(),
  );

  const policyViolations = allPolicyViolations.filter((violation) =>
    incidentIDToIncident.has(violation.incident_id),
  );

  const {
    data: { policies },
    isLoading: policiesLoading,
  } = useAPI("policiesList", undefined, { fallbackData: { policies: [] } });

  if (
    slackTeamConfigsLoading ||
    policiesLoading ||
    violationsLoading ||
    incidentsLoading
  ) {
    return <Loader />;
  }

  if (policies.length === 0) {
    return null;
  }

  const errorPolicyViolations = policyViolations.filter(
    (v) => v.level === PolicyViolationLevelEnum.Error,
  );

  const violationsByPolicy = groupBy(errorPolicyViolations, (v) => v.policy_id);

  const violationsWithResources = _.compact(
    Object.entries(violationsByPolicy).map(([policyId, violations]) => {
      const incidents: Incident[] = _.compact(
        violations.map((v) => incidentIDToIncident.get(v.incident_id)),
      );

      const policy = policies.find((p) => p.id === policyId);

      if (policy && incidents.length > 0) {
        return {
          policy,
          incidents,
          violations,
        };
      }

      // If there are no incidents, all the violations are on private incidents, so we return nothing
      return null;
    }),
  );

  let sortedViolationsWithIncidents = _.reverse(
    _.sortBy(violationsWithResources, ({ incidents }) =>
      _.minBy(incidents, "reported_at"),
    ),
  );

  let haveTruncated = false;
  if (!showMore) {
    if (sortedViolationsWithIncidents.length > 5) {
      haveTruncated = true;
      sortedViolationsWithIncidents = sortedViolationsWithIncidents.slice(0, 5);
    }
  }

  return (
    <HomePolicyViolationsInner
      errorPolicyViolations={errorPolicyViolations}
      sortedViolationsWithIncidents={sortedViolationsWithIncidents}
      slackTeamConfigs={slackTeamConfigs}
      haveTruncated={haveTruncated}
      setShowMore={setShowMore}
    />
  );
};

export const HomePolicyViolationsInner = ({
  errorPolicyViolations,
  sortedViolationsWithIncidents,
  slackTeamConfigs,
  haveTruncated,
  setShowMore,
}: {
  errorPolicyViolations: PolicyViolation[];
  sortedViolationsWithIncidents: {
    violations: PolicyViolation[];
    incidents: Incident[];
    policy: Policy;
  }[];
  slackTeamConfigs: Array<SlackTeamConfig> | null;
  haveTruncated: boolean;
  setShowMore: (value: ((prevState: boolean) => boolean) | boolean) => void;
}) => {
  return (
    <div data-testid="homepage-policies-section" className="pb-2">
      <HomePolicyViolationsHeader />
      {errorPolicyViolations.length === 0 ? (
        <EmptyState
          icon={IconEnum.Shield}
          content="There are no policy violations that you are responsible for resolving."
        />
      ) : (
        <>
          <p className="text-sm">
            There{errorPolicyViolations.length === 1 ? " is " : " are "}
            <span className="font-semibold">
              {`${errorPolicyViolations.length} policy violation${
                errorPolicyViolations.length === 1 ? "" : "s"
              } `}
            </span>{" "}
            that you are responsible for resolving
          </p>
          {sortedViolationsWithIncidents.map(
            ({ policy, incidents, violations }) => (
              <ViolatingPolicyTable
                policy={policy}
                incidents={incidents}
                slackTeamConfigs={slackTeamConfigs}
                violations={violations}
                key={policy.id}
              />
            ),
          )}
          {haveTruncated ? (
            <Button
              analyticsTrackingId="show-more-followups"
              theme={ButtonTheme.Naked}
              onClick={() => setShowMore(true)}
              className="mt-3"
            >
              Show more
            </Button>
          ) : null}
        </>
      )}
    </div>
  );
};

const HomePolicyViolationsHeader = (): React.ReactElement => (
  <div className="flex items-center mb-3 space-x-1">
    <Heading
      title="Open follow-ups"
      className="px-2 sm:px-0"
      level={2}
      size="medium"
    >
      Policy violations
    </Heading>
  </div>
);

export const ViolatingPolicyTable = ({
  incidents,
  violations,
  policy,
}: {
  incidents: Incident[];
  slackTeamConfigs: SlackTeamConfig[] | null;
  violations: PolicyViolation[];
  policy: Policy;
}): React.ReactElement => {
  const incidentAndViolationPair = incidents.map((inc, i) => ({
    incident: inc,
    violation: violations[i],
  }));

  return (
    <DeprecatedTable className={"mt-4"} roundedTop>
      <PolicyTableHeader policy={policy} />
      <tbody>
        {incidentAndViolationPair.map(({ incident, violation }) => (
          <IncidentRow
            key={incident.id}
            violation={violation}
            incident={incident}
            policy={policy}
          />
        ))}
      </tbody>
    </DeprecatedTable>
  );
};

const PolicyTableHeader = ({
  policy,
}: {
  policy: Policy;
}): React.ReactElement => {
  return (
    <thead className={`border-b-2 border-stroke bg-surface-secondary`}>
      <tr>
        <th colSpan={1} className={`!py-3 `}>
          <div className="flex flex-between space-x-4">
            <div className="flex items-center">
              <Link
                to={`/settings/policies/${policy.id}/view`}
                analyticsTrackingId={`settings-policies-home-link`}
                className={"no-underline"}
              >
                <p className="text-slate-800 font-normal mr-2 hover:underline">
                  {policy.name}
                </p>
              </Link>
            </div>
          </div>
        </th>
      </tr>
    </thead>
  );
};

const IncidentRow = ({
  violation,
  incident,
  policy,
}: {
  violation: PolicyViolation;
  incident: Incident;
  policy: Policy;
}): React.ReactElement | null => {
  const { hasScope } = useIdentity();
  const canDismissViolations = hasScope(ScopeEnum.PolicyViolationsDismiss);

  const [openDismissModal, setOpenDismissModal] = useState(false);

  // If this is a Follow-up violation, show the Follow-up title
  const followUp = incident.follow_ups?.find(
    (followUp) => followUp.id === violation.resource_id,
  );

  return (
    <tr>
      <td>
        <div className="flex flex-between items-center">
          <div>
            <div className={"flex items-center space-x-2"}>
              <OrgAwareLink
                to={`/incidents/${incident.external_id}`}
                className="!no-underline hover:!text-alarmalade-600"
                analyticsTrackingId={"policy-to-incident-link"}
              >
                <span className="font-semibold shrink-0">
                  INC-{incident.external_id}
                </span>{" "}
                <span className="">{incident.name}</span>
              </OrgAwareLink>
            </div>
            {policy.policy_type === PolicyPolicyTypeEnum.FollowUp ? (
              <ul className="list-disc ">
                {followUp ? (
                  <li className="ml-6" key={followUp.id}>
                    {followUp?.title}
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            ) : null}
          </div>
          <div className="flex items-center">
            <OrgAwareLink
              to={`/incidents/${incident.external_id}`}
              className="!text-content-tertiary !no-underline hover:!text-alarmalade-600 px-1.5"
              analyticsTrackingId={"policy-to-incident-link-small"}
            >
              View incident
            </OrgAwareLink>
            {/* Dropdown menu */}
            {canDismissViolations && (
              <DropdownMenu
                analyticsTrackingId={"policy-violation-dropdown"}
                menuClassName={"!py-1 mr-5"}
                triggerButtonTheme={ButtonTheme.Ghost}
                triggerIcon={IconEnum.DotsVertical}
                screenReaderText="Policy violation options"
                side={"bottom"}
                align="end"
              >
                <DropdownMenuItem
                  analyticsTrackingId={"policy-violation-dropdown"}
                  onSelect={() => setOpenDismissModal(true)}
                  label="Dismiss violation"
                  disabled={!canDismissViolations}
                  icon={IconEnum.Close}
                  iconProps={{ size: IconSize.Large }}
                  destructive
                  tooltipContent={
                    !canDismissViolations &&
                    "You do not have permission to dismiss violations."
                  }
                />
              </DropdownMenu>
            )}

            {openDismissModal && (
              <DismissViolationConfirmationModal
                violationId={violation.id}
                onClose={() => setOpenDismissModal(false)}
              />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
