import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import { SelectOptionGroup } from "@incident-ui/Select/types";

export const SplitByFollowUpAttributesDropdown = ({
  followUpAttributeOptions,
  selectedFollowUpAttributeID,
  setSelectedFollowUpAttributeID,
  label,
}: {
  followUpAttributeOptions: SelectOptionGroup[];
  selectedFollowUpAttributeID: string;
  setSelectedFollowUpAttributeID: (value: string) => void;
  label?: React.ReactNode;
}): React.ReactElement => {
  return (
    <div className="flex items-center">
      {label ?? (
        <span className="text-sm text-slate-700 mr-2 flex-shrink-0">
          Split by
        </span>
      )}
      <StaticSingleSelect
        placeholder="Choose field"
        options={followUpAttributeOptions}
        className="!text-slate-700 min-w-[200px]"
        value={selectedFollowUpAttributeID}
        onChange={(value) => {
          value && setSelectedFollowUpAttributeID(value);
        }}
        isClearable={false}
      />
    </div>
  );
};
