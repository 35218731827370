import { Icon, IconEnum, IconSize, Link } from "@incident-ui";
import _ from "lodash";
import {
  ExternalGitHubPullRequest,
  ExternalResource,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { AttachmentContexts } from "./IncidentAttachment";

export const GitHubPullRequest = ({
  className,
  resource,
  ghPr,
  context,
}: {
  className?: string;
  resource: ExternalResource;
  ghPr: ExternalGitHubPullRequest;
  context: AttachmentContexts;
}): React.ReactElement => {
  return (
    <div className={tcx("flex", className)}>
      <Icon
        id={IconEnum.Github}
        size={IconSize.Medium}
        className="text-content-tertiary mr-6 shrink-0 font-semibold mt-1"
      />
      <div>
        <div className="font-medium mb-2">
          <Link
            href={resource.permalink}
            openInNewTab
            analyticsTrackingId={null}
          >
            #{ghPr.number}: {ghPr.title}
          </Link>
        </div>
        <div className="flex space-x-2 text-slate-700">
          {context === AttachmentContexts.RealTime ? (
            <span className="flex-center-y">
              <Icon
                id={IconEnum.Status}
                className="text-content-tertiary mr-1"
              />
              <span>{ghPr.merged ? "Merged" : _.startCase(ghPr.status)}</span>
            </span>
          ) : null}
          <span className="flex-center-y">
            <Icon id={IconEnum.User} className="text-content-tertiary mr-1" />
            <span>{ghPr.author}</span>
          </span>
        </div>
      </div>
    </div>
  );
};
