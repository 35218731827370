import {
  ErrorResponse,
  Resource,
  ResourceFieldConfigTypeEnum as FormFieldType,
} from "@incident-io/api";
import { Path, UseFormReturn } from "react-hook-form";
import { assertUnreachable, sendToSentry } from "src/utils/utils";

import { ClientType } from "../../../../contexts/ClientContext";
import { WorkflowStep } from "../common/types";

export const validateStep = async ({
  data,
  resources,
  setError,
  apiClient,
}: {
  data: WorkflowStep;
  resources: Resource[];
  setError: UseFormReturn<WorkflowStep>["setError"];
  apiClient: ClientType;
}): Promise<boolean> => {
  const bindingsAreValid = validateBindings({ data, resources, setError });
  if (!bindingsAreValid) {
    return false;
  }

  // after we validate the data types, we'll check the validation API
  // for more complex step validation.
  try {
    await apiClient.workflowsValidateStep({
      validateStepRequestBody: { step: data },
    });
  } catch (e) {
    if (e instanceof Response && e.status === 422) {
      const res: ErrorResponse = await e.json();
      res.errors.forEach((err) => {
        const path = err?.source?.pointer as unknown as Path<WorkflowStep>;
        if (!path) {
          sendToSentry("unreachable: validation error without a path.", {
            err,
          });
        } else {
          const error = { type: "manual", message: err.message };
          setError(path, {
            type: "manual",
            message: error.message,
          });
        }
      });

      return false;
    }

    // If we don't get a response back, report to sentry
    sendToSentry("unreachable: unexpected error", { e });
    return false;
  }

  return true;
};

const validateBindings = ({
  data,
  resources,
  setError,
}: {
  data: WorkflowStep;
  resources: Resource[];
  setError: UseFormReturn<WorkflowStep>["setError"];
}): boolean => {
  let valid = true;
  data.param_bindings.forEach((binding, idx) => {
    const param = data.params[idx];
    if (!param) {
      throw sendToSentry(
        "unreachable: found param binding with no equivalent param.",
        { param_bindings: data.param_bindings, params: data.params },
      );
    }
    const resource = resources.find((x) => x.type === param.type);
    if (!resource) {
      throw sendToSentry(
        "unreachable: found param with no associated resource.",
        { param, resources },
      );
    }
    if (param.infer_reference) {
      // we don't validate infer reference params, they're not interacted with by the user.
      return;
    }

    // For array values, validate that at least one value is set if required
    if (param.array) {
      switch (resource.field_config.type) {
        case FormFieldType.DateInput:
        case FormFieldType.TextInput:
        case FormFieldType.TextNumberInput:
        case FormFieldType.TextAreaInput:
        case FormFieldType.RichTextEditorInput:
        case FormFieldType.TemplatedTextEditorInput:
          if (
            !param.optional &&
            !binding.array_value?.some((x) => x.literal || x.reference)
          ) {
            setError(`param_bindings.${idx}.array_value`, {
              type: "manual",
              message: "this field is required",
            });
            valid = false;
          }
      }
    } else {
      const paramKey =
        `param_bindings.${idx}.value` as `param_bindings.${number}.value`;

      switch (resource.field_config.type) {
        case FormFieldType.SingleExternalSelect:
        case FormFieldType.SingleExternalUserSelect:
        case FormFieldType.SingleStaticSelect:
        case FormFieldType.SingleDynamicSelect:
        case FormFieldType.DurationInput:
        case FormFieldType.DateInput:
        case FormFieldType.DateTimeInput:
        case FormFieldType.TextInput:
        case FormFieldType.TextNumberInput:
        case FormFieldType.TextAreaInput:
        case FormFieldType.SlackChannelInput:
          if (!param.optional && !binding.value) {
            setError(paramKey, {
              type: "manual",
              message: "this field is required",
            });
            valid = false;
          }
          break;
        case FormFieldType.RichTextEditorInput:
        case FormFieldType.TemplatedTextEditorInput:
          if (!param.optional && !binding.value?.literal) {
            setError(
              `${paramKey}.literal` as `param_bindings.${number}.value.literal`,
              {
                type: "manual",
                message: "this field is required",
              },
            );
            valid = false;
          }
          break;
        case FormFieldType.Checkbox:
          // do nothing - this can't be invalid
          break;
        case FormFieldType.None:
          throw sendToSentry(
            "unreachable: cannot validate form field type 'none'.",
            { field_config: resource.field_config, binding },
          );
        default:
          assertUnreachable(resource.field_config.type);
      }
    }
  });

  return valid;
};
