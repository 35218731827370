import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import { Input, Modal, ModalContent, ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React from "react";
import { useForm } from "react-hook-form";
import { SavedView } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { useSavedViews } from "./SavedViewContext";

type Props = {
  onClose: () => void;
  savedView: SavedView;
};

type FormState = { id: string; name: string };

export const SavedViewRenameModal = ({
  onClose,
  savedView,
}: Props): React.ReactElement => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormState>({
    defaultValues: { id: savedView.id, name: savedView.name },
  });
  const { context, renameSavedView } = useSavedViews();

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation("savedViewsList", { context }, renameSavedView, {
    setError,
    onSuccess: onClose,
  });

  return (
    <Modal
      as="form"
      isOpen={true}
      title="Rename saved view"
      analyticsTrackingId="saved-view-rename-modal"
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ModalContent className="space-y-4">
        <ErrorMessageUI message={genericError} />
        <FormInputWrapper label="Name" id="name" errors={errors}>
          <Input
            id="saved-view-name"
            autoFocus
            type={InputType.Text}
            {...register("name", {
              required: "Please provide a name",
            })}
          />
        </FormInputWrapper>
      </ModalContent>
      <ModalFooter
        onClose={onClose}
        confirmButtonType="submit"
        saving={saving}
      />
    </Modal>
  );
};
