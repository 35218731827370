import { Badge, BadgeTheme, Card, IconEnum, OrgAwareLink } from "@incident-ui";
import { WorkflowTemplate } from "src/contexts/ClientContext";

export type WorkflowTemplateGridProps = {
  templates: WorkflowTemplate[];
};

export const WorkflowsTemplateGrid = ({
  templates,
}: WorkflowTemplateGridProps) => {
  return (
    <>
      <div>
        <div className="grid grid-cols-2 gap-[12px]">
          {templates.map((template, index) => {
            return (
              <WorkflowTemplateCard
                key={index}
                template={template}
                createHref="/workflows/create"
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export type WorkflowTemplateCardProps = {
  template: WorkflowTemplate;
  createHref: string;
};

export const WorkflowTemplateCard = ({
  template,
  createHref,
}: WorkflowTemplateCardProps) => {
  const isEligible = template.eligibility.is_eligible;
  const href = isEligible
    ? `${createHref}?template=${template.name}`
    : template.eligibility.ineligible_call_to_action_path;

  return (
    <OrgAwareLink
      analyticsTrackingId={`workflows-v2-start-from-template-${template.name}`}
      to={href}
    >
      <Card
        icon={template.icon as unknown as IconEnum}
        title={template.label}
        description={template.description}
        headerAccessory={
          !isEligible && (
            <Badge
              theme={BadgeTheme.Info}
              className="mr-1 ml-2 !font-normal text-xs"
            >
              Needs setup
            </Badge>
          )
        }
      />
    </OrgAwareLink>
  );
};
