import { Icon, IconEnum, IconSize } from "@incident-ui";
import React from "react";
import { formatDurationShort } from "src/utils/datetime";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./TimelineItems.module.scss";

type Props = {
  duration: number;
};

export function TimelineItemTimeGap({ duration }: Props): React.ReactElement {
  const formattedDuration = formatDurationShort(
    new Date(0),
    new Date(duration),
    {
      suffix: "later...",
      significantFigures: 1,
    },
  );

  return (
    <li className={tcx(styles.timelineItem, styles.timeGap)}>
      <Icon className={styles.icon} size={IconSize.Large} id={IconEnum.Clock} />
      <div className={styles.itemDescription}>
        <div className="flex-center-y">
          <p>{formattedDuration}</p>
        </div>
      </div>
    </li>
  );
}
