import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import _, { groupBy } from "lodash";
import { useFormContext, UseFormReturn } from "react-hook-form";
import {
  Incident,
  IncidentStatus,
  PostIncidentTask,
} from "src/contexts/ClientContext";
import { assertUnreachable } from "src/utils/utils";

import { IncidentStatusFormData } from "../statuses/StatusFormElement";
import {
  Category,
  firstStatusOfCategory,
  isOneOfCategories,
} from "./status-utils";
import { StatusSelect } from "./StatusSelect";

export enum PostIncidentFlowDecision {
  // only provide an update but not changing statuses
  Leave = "leave",
  // used by user when there is no tasks in a status and user has to manually
  // mark that the current status does not apply anymore
  MoveOutOfCurrentStatus = "move-out-of-current-status",
  // re-open the incident (make it active)
  ReOpen = "re-open",
  // close and opt out of post-incident flow
  OptOut = "opt-out",
}

export const PostIncidentDecisionRadio = ({
  initialStatus,
  statuses,
  incidentTasks,
  incident,
}: {
  initialStatus: IncidentStatus;
  statuses: IncidentStatus[];
  incidentTasks: PostIncidentTask[];
  incident: Incident;
}) => {
  const formMethods = useFormContext<IncidentStatusFormData>();

  const statusToTasks = _.groupBy(
    incidentTasks,
    (task) => task.config.incident_status_id,
  );

  const allowedDecisions =
    (statusToTasks[initialStatus.id] ?? []).length > 0
      ? [
          PostIncidentFlowDecision.Leave,
          PostIncidentFlowDecision.ReOpen,
          PostIncidentFlowDecision.OptOut,
        ]
      : [PostIncidentFlowDecision.MoveOutOfCurrentStatus];

  const onChooseDecision = (decision: string) => {
    const newStatus = findNextPostIncidentStatus(
      decision as PostIncidentFlowDecision,
      initialStatus,
      statuses,
      incidentTasks,
    );
    if (newStatus) {
      formMethods.setValue<"incident_status_id">(
        "incident_status_id",
        newStatus.id,
      );
    }
  };

  return (
    <>
      <FormHelpTextV2>
        <span>
          This incident is still in the{" "}
          <span className="font-semibold">post-incident flow</span>. You can
          progress to the next post-incident status by completing the tasks.{" "}
          {`Once you've moved
          through all the post-incident statuses, we'll close the incident.`}
        </span>
      </FormHelpTextV2>
      <RadioButtonGroupV2
        label="What do you want to do?"
        srLabel="Select decision"
        formMethods={formMethods}
        boxed
        name="incident_status_decision"
        options={_.map(allowedDecisions, (decision) =>
          radioButtonOption({
            decision,
            formMethods,
            incident,
          }),
        )}
        onValueChange={onChooseDecision}
      />
    </>
  );
};

const radioButtonOption = ({
  decision,
  formMethods,
  incident,
}: {
  decision: PostIncidentFlowDecision;
  formMethods: UseFormReturn<IncidentStatusFormData>;
  incident: Incident;
}): RadioButtonGroupOption => {
  switch (decision) {
    case PostIncidentFlowDecision.Leave:
      return {
        label: "Stay in current status",
        value: decision,
      };
    case PostIncidentFlowDecision.MoveOutOfCurrentStatus:
      return {
        label: "Move into next status",
        value: decision,
      };
    case PostIncidentFlowDecision.ReOpen:
      return {
        label: "Re-open: move it to an active status",
        value: decision,
        renderWhenSelectedNode: () => (
          <StatusSelect
            formMethods={formMethods}
            name="incident_status_id"
            filterByCategories={[Category.Active]}
            incident={incident}
            hideLabel
          />
        ),
      };
    case PostIncidentFlowDecision.OptOut:
      return {
        label: "Close: opt out of the post-incident flow",
        value: decision,
      };
    default:
      return assertUnreachable(decision);
  }
};

export const findNextPostIncidentStatus = (
  decision: PostIncidentFlowDecision,
  currentStatus: IncidentStatus,
  statuses: IncidentStatus[],
  incidentTasks: PostIncidentTask[],
): IncidentStatus | null => {
  const firstClosedStatus = firstStatusOfCategory(statuses, Category.Closed);

  switch (decision) {
    case PostIncidentFlowDecision.OptOut:
      return firstClosedStatus;
    case PostIncidentFlowDecision.ReOpen:
      // Do nothing: the user must select a status
      return null;
    case PostIncidentFlowDecision.Leave:
      // Do nothing: the user must select a status
      return null;
    case PostIncidentFlowDecision.MoveOutOfCurrentStatus:
      const postIncidentStatusesInTheFuture = statuses
        .filter((status) => status.rank > currentStatus.rank)
        .filter(isOneOfCategories([Category.PostIncident]));

      if (postIncidentStatusesInTheFuture.length === 0) {
        return firstClosedStatus;
      }

      if (!incidentTasks || incidentTasks.length === 0) {
        return postIncidentStatusesInTheFuture[0];
      }

      const statusToTasks = groupBy(
        incidentTasks,
        (task) => task.config.incident_status_id,
      );

      for (const status of postIncidentStatusesInTheFuture) {
        const tasks = statusToTasks[status.id];
        if (!tasks || tasks.length === 0) {
          return status;
        }
        const hasUnresolvedTasks = tasks.some(
          (task) => !task.completed_at && !task.rejected_at,
        );
        if (hasUnresolvedTasks) {
          return status;
        }
      }

      return firstClosedStatus;
    default:
      return assertUnreachable(decision);
  }
};
