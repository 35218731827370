import { Incident } from "@incident-io/api";
import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { TimestampFormData } from "@incident-shared/incident-forms";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { Callout, CalloutTheme, ModalFooter } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { getTimeZones } from "@vvo/tzdb";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { LifecycleTabs } from "src/components/settings/lifecycle/LifecyclePage";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { useRevalidate } from "../../../../utils/use-revalidate";
import { IncidentTimestampFormElementV2 } from "../IncidentTimestampFormElementV2";
import { useStatusesForIncident } from "../useIncidentCrudResources";
import { validateTimestampInputForDurationsFunc } from "./EditTimestampsModal";

export function EditDurationTimestampsModal({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement {
  const { statuses } = useStatusesForIncident({ incident });
  const refetchTimeline = useAPIRefetch("timelineItemsList", {
    incidentId: incident.id,
  });
  const refreshIncidentList = useRevalidate(["incidentsList"]);

  const [isReseted, setReset] = useState(false);

  const incidentTimestamps = incident?.incident_timestamps || [];
  const defaultValues = {};
  incidentTimestamps.forEach((ts) => {
    defaultValues[ts.timestamp.id] = ts.value?.value;
  });

  const formMethods = useForm<TimestampFormData>({
    mode: "onChange",
    defaultValues: { incident_timestamp_values: defaultValues },
  });

  const { setError, trigger, reset, getValues } = formMethods;

  // try to find the given duration
  const { duration_id } = useParams<{ duration_id: string }>();
  const duration = incident?.duration_metrics?.find(
    (d) => d.duration_metric.id === duration_id,
  );

  // Find the timestamps we wish to edit for this duration
  const from_ts = incident?.incident_timestamps?.find((t) => {
    return t.timestamp.id === duration?.duration_metric.from_timestamp_id;
  });
  const to_ts = incident?.incident_timestamps?.find((t) => {
    return t.timestamp.id === duration?.duration_metric.to_timestamp_id;
  });

  // The two following effects are to enable us to run validations on load
  // The combination of resetting and triggering when the form first loads allows us to have the validations run on all
  // fields (which does not happen on load annoyingly)
  useEffect(() => {
    reset();
    setReset(true);
  }, [reset]);

  useEffect(() => {
    isReseted && trigger();
  }, [trigger, isReseted]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, formData: TimestampFormData) => {
      const payload = Object.entries(formData.incident_timestamp_values).map(
        ([timestamp_id, value]) => {
          return {
            incident_timestamp_id: timestamp_id,
            value: value,
          };
        },
      );
      await apiClient.incidentsUpdateTimestamps({
        id: incident.id,
        updateTimestampsRequestBody: {
          incident_timestamps: payload,
        },
      });

      await refetchTimeline();
    },
    {
      onSuccess: () => {
        refreshIncidentList();
        onClose();
      },
      setError,
    },
  );

  const timeZone = getTimeZones().find(
    (tz) => tz.name === Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  if (!duration) {
    return <OrgAwareNavigate to="/404" replace />;
  }

  if (!from_ts || !to_ts) {
    const err = new Error("unable to find matching timestamps");
    return <ErrorModal error={err} onClose={onClose} />;
  }

  const mappedTimestamps = {
    [from_ts.timestamp.id]: from_ts,
    [to_ts.timestamp.id]: to_ts,
  };

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      analyticsTrackingId="update-duration-timestamps"
      title={`Update timestamps for '${duration.duration_metric.name}'`}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonText="Save"
          saving={saving}
          confirmButtonType="submit"
        />
      }
      genericError={genericError}
    >
      {duration.duration_metric.validate && (
        <Callout className="mb-2" theme={CalloutTheme.Warning}>
          <div>
            You have enabled validation for this duration metric. This means the
            timestamp <strong>{from_ts.timestamp.name}</strong> must be before{" "}
            <strong>{to_ts.timestamp.name}</strong>.
          </div>
          <br />
          <div>
            Validation can be enabled or disabled in the{" "}
            <a
              href={`/settings/lifecycle?tab=${LifecycleTabs.Timestamps}`}
              className="underline"
            >
              duration metric settings.
            </a>
          </div>
        </Callout>
      )}
      <FormHelpTextV2>{`Update the timestamps that form this duration metric. These may have been automatically set when changing incident status, feel free to edit them.`}</FormHelpTextV2>
      {timeZone && (
        <FormHelpTextV2>
          <>
            {`All times are in your local time zone: `}
            <strong>
              {timeZone.name} ({timeZone.abbreviation}).
            </strong>
          </>
        </FormHelpTextV2>
      )}
      <hr className="my-3" />
      <IncidentTimestampFormElementV2<TimestampFormData>
        validateOnChange
        required={false}
        formMethods={formMethods}
        key={from_ts.timestamp.id}
        name={`incident_timestamp_values.${from_ts.timestamp.id}`}
        timestamp={from_ts.timestamp}
        statuses={statuses}
        customValidate={validateTimestampInputForDurationsFunc(
          from_ts.timestamp,
          mappedTimestamps,
          [duration],
          getValues,
        )}
      />
      <IncidentTimestampFormElementV2<TimestampFormData>
        formMethods={formMethods}
        required={false}
        validateOnChange
        key={to_ts.timestamp.id}
        name={`incident_timestamp_values.${to_ts.timestamp.id}`}
        timestamp={to_ts.timestamp}
        statuses={statuses}
        customValidate={validateTimestampInputForDurationsFunc(
          to_ts.timestamp,
          mappedTimestamps,
          [duration],
          getValues,
        )}
      />
    </FormModalV2>
  );
}
