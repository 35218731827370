import { Button, ButtonTheme, IconEnum, IconSize } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./TimelineItems.module.scss";

export const CustomTimelineEventButtons = ({
  setIsEditingCustomEvent,
  setShowDeleteConfirmationDialog,
  savingDelete,
}: {
  setIsEditingCustomEvent: (isEditing: boolean) => void;
  setShowDeleteConfirmationDialog: (show: boolean) => void;
  savingDelete: boolean;
}) => {
  return (
    <>
      <Button
        theme={ButtonTheme.Naked}
        icon={IconEnum.Edit}
        iconProps={{
          size: IconSize.Large,
        }}
        className="hover:!text-slate-300"
        onClick={() => setIsEditingCustomEvent(true)}
        analyticsTrackingId={"custom-timeline-item-edit"}
        title={"Edit custom event"}
      />
      <Button
        theme={ButtonTheme.Naked}
        icon={IconEnum.Delete}
        iconProps={{
          size: IconSize.Large,
        }}
        title="Delete"
        className={tcx("hover:!text-slate-300", styles.hiddenButton)}
        analyticsTrackingId="custom-timeline-item-delete"
        onClick={() => setShowDeleteConfirmationDialog(true)}
        loading={savingDelete}
      />
    </>
  );
};
