/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableExpressionOperations,
    AvailableExpressionOperationsFromJSON,
    AvailableExpressionOperationsFromJSONTyped,
    AvailableExpressionOperationsToJSON,
} from './AvailableExpressionOperations';
import {
    CastType,
    CastTypeFromJSON,
    CastTypeFromJSONTyped,
    CastTypeToJSON,
} from './CastType';
import {
    ResourceFieldConfig,
    ResourceFieldConfigFromJSON,
    ResourceFieldConfigFromJSONTyped,
    ResourceFieldConfigToJSON,
} from './ResourceFieldConfig';
import {
    ResourceOperation,
    ResourceOperationFromJSON,
    ResourceOperationFromJSONTyped,
    ResourceOperationToJSON,
} from './ResourceOperation';
import {
    SelectOption,
    SelectOptionFromJSON,
    SelectOptionFromJSONTyped,
    SelectOptionToJSON,
} from './SelectOption';
import {
    SelectOptionGroup,
    SelectOptionGroupFromJSON,
    SelectOptionGroupFromJSONTyped,
    SelectOptionGroupToJSON,
} from './SelectOptionGroup';

/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * The operations that are available on an array of this resource
     * @type {Array<ResourceOperation>}
     * @memberof Resource
     */
    array_operations: Array<ResourceOperation>;
    /**
     * If true, resources of this type can be used as as variables in TemplatedText
     * @type {boolean}
     * @memberof Resource
     */
    can_be_interpolated: boolean;
    /**
     * Other types that this resource can be cast to
     * @type {Array<CastType>}
     * @memberof Resource
     */
    cast_types: Array<CastType>;
    /**
     * 
     * @type {AvailableExpressionOperations}
     * @memberof Resource
     */
    expression_operations: AvailableExpressionOperations;
    /**
     * 
     * @type {ResourceFieldConfig}
     * @memberof Resource
     */
    field_config: ResourceFieldConfig;
    /**
     * The operations that are available on this resource
     * @type {Array<ResourceOperation>}
     * @memberof Resource
     */
    operations: Array<ResourceOperation>;
    /**
     * If supported, this is an array of option groups for this resource
     * @type {Array<SelectOptionGroup>}
     * @memberof Resource
     */
    option_groups?: Array<SelectOptionGroup>;
    /**
     * If supported, this is an array of options for this resource
     * @type {Array<SelectOption>}
     * @memberof Resource
     */
    options?: Array<SelectOption>;
    /**
     * Unique identifier of this resource
     * @type {string}
     * @memberof Resource
     */
    type: string;
    /**
     * Human readable identifier of this resource
     * @type {string}
     * @memberof Resource
     */
    type_label: string;
}

export function ResourceFromJSON(json: any): Resource {
    return ResourceFromJSONTyped(json, false);
}

export function ResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Resource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array_operations': ((json['array_operations'] as Array<any>).map(ResourceOperationFromJSON)),
        'can_be_interpolated': json['can_be_interpolated'],
        'cast_types': ((json['cast_types'] as Array<any>).map(CastTypeFromJSON)),
        'expression_operations': AvailableExpressionOperationsFromJSON(json['expression_operations']),
        'field_config': ResourceFieldConfigFromJSON(json['field_config']),
        'operations': ((json['operations'] as Array<any>).map(ResourceOperationFromJSON)),
        'option_groups': !exists(json, 'option_groups') ? undefined : ((json['option_groups'] as Array<any>).map(SelectOptionGroupFromJSON)),
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(SelectOptionFromJSON)),
        'type': json['type'],
        'type_label': json['type_label'],
    };
}

export function ResourceToJSON(value?: Resource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array_operations': ((value.array_operations as Array<any>).map(ResourceOperationToJSON)),
        'can_be_interpolated': value.can_be_interpolated,
        'cast_types': ((value.cast_types as Array<any>).map(CastTypeToJSON)),
        'expression_operations': AvailableExpressionOperationsToJSON(value.expression_operations),
        'field_config': ResourceFieldConfigToJSON(value.field_config),
        'operations': ((value.operations as Array<any>).map(ResourceOperationToJSON)),
        'option_groups': value.option_groups === undefined ? undefined : ((value.option_groups as Array<any>).map(SelectOptionGroupToJSON)),
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(SelectOptionToJSON)),
        'type': value.type,
        'type_label': value.type_label,
    };
}

