import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { TutorialPrompt } from "@incident-shared/tutorial";
import { IconEnum, Loader, Toggle } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import {
  CatalogHeroBanner,
  useShowCatalogBanner,
} from "src/components/catalog/type-list/CatalogTypeSetupBanner";
import { EscalationDrawer } from "src/components/escalations/EscalateDrawer";
import { EscalationGrid } from "src/components/legacy/escalation-grid/EscalationGrid";
import { HomeKanban } from "src/components/legacy/home/HomeKanban";
import { useProductAccess } from "src/hooks/useProductAccess";
import { tcx } from "src/utils/tailwind-classes";

import { HomeActivity } from "../../components/legacy/home/HomeActivity";
import { HomeFollowUps } from "../../components/legacy/home/HomeFollowUps";
import { HomePolicyViolations } from "../../components/legacy/home/HomePolicyViolations";
import { OnCallBanner } from "../../components/legacy/home/OnCallBanner";
import { useShowOnCallBanner } from "../../components/legacy/home/useShowOnCallBanner";
import {
  DeclareIncidentAndEscalateButton,
  DeclareIncidentButton,
} from "../../components/legacy/incident/DeclareIncidentButton";
import { IncidentCreateModal } from "../../components/legacy/incident/IncidentCreateModal";
import { InternalStatusPageLinkButton } from "../../components/legacy/internal-status-pages/InternalStatusPageLinkButton";
import { useIdentity } from "../../contexts/IdentityContext";
import styles from "./HomeRoute.module.scss";

export const HomeRoute = () => {
  const { identity } = useIdentity();
  const { featureSnow } = useFlags();
  const [snow, setSnow] = useState(false);
  const { hasResponse, hasOnCall } = useProductAccess();
  const [showIncidentCreateModal, setShowIncidentCreateModal] = useState(false);
  const [showEscalateDrawer, setShowEscalateDrawer] = useState(false);
  const [escalationIDForIncidentDeclare, setEscalationIDForIncidentDeclare] =
    useState<string | undefined>(undefined);

  if (!identity) {
    return <Loader />;
  }

  return (
    <>
      {snow &&
        Array(500)
          .fill("")
          .map((_, i) => (
            <div className={tcx(styles.snow, "shadow-md")} key={i} />
          ))}
      <PageWrapper
        width={PageWidth.Medium}
        icon={IconEnum.Home}
        loading={!identity}
        title="Home"
        accessory={
          <div className="flex-center-y gap-2 flex-wrap">
            {featureSnow && (
              <Toggle
                toggleClassName="hidden lg:flex"
                id="let-it-snow"
                label="Let it snow"
                toggleLabelClassName="text-slate-400 !mr-1"
                on={snow}
                onToggle={() => setSnow(!snow)}
              />
            )}
            <InternalStatusPageLinkButton />
            <TutorialPrompt />
            {hasOnCall ? (
              <DeclareIncidentAndEscalateButton
                onDeclareIncident={() => setShowIncidentCreateModal(true)}
                onEscalate={() => setShowEscalateDrawer(true)}
              />
            ) : (
              <DeclareIncidentButton
                onClick={() => setShowIncidentCreateModal(true)}
              />
            )}
          </div>
        }
      >
        <div className={"space-y-8"}>
          <Banner />
          <HomeKanban />
          {!hasResponse ? <EscalationGrid /> : null}
          <HomeActivity />
          {hasResponse ? <HomePolicyViolations /> : null}
          <HomeFollowUps />
        </div>
        {showIncidentCreateModal && (
          <IncidentCreateModal
            escalationId={escalationIDForIncidentDeclare}
            onClose={() => {
              setShowIncidentCreateModal(false);
              setEscalationIDForIncidentDeclare(undefined);
            }}
          />
        )}
        {showEscalateDrawer && hasOnCall ? (
          <EscalationDrawer
            onClose={() => setShowEscalateDrawer(false)}
            onDeclareIncident={(escalationID) => {
              setShowIncidentCreateModal(true);
              setEscalationIDForIncidentDeclare(escalationID);
            }}
          />
        ) : null}
      </PageWrapper>
    </>
  );
};

const Banner = () => {
  const catalogBannerType = useShowCatalogBanner();
  const { showOnCallBanner } = useShowOnCallBanner();

  if (catalogBannerType) {
    return <CatalogHeroBanner bannerType={catalogBannerType} />;
  }

  if (showOnCallBanner) {
    return <OnCallBanner />;
  }

  return null;
};
