import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";

export const AlertSourceExpelSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <div className="space-y-4">
        <p>
          Add a webhook integration by navigating to{" "}
          <strong>Organisation Settings &gt; Integrations</strong> and clicking{" "}
          <strong>Add a webhook destination</strong> at the bottom of the page.
        </p>
      </div>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Enter a name for the webhook, the following destination URL and
            click <strong>Add</strong>. This source has been configured with a
            unique email address that you can use to create alerts.
          </>
        }
        blocks={[
          {
            title: "Webhook URL",
            code: `${publicApiUrl()}/v2/alert_events/expel/${
              alertSourceConfig.id
            }`,
          },
        ]}
      />
      <div className="space-y-4">
        <p>
          This webhook integration can be subscribed to Expel notification
          rules. Either add a new <strong>notification rule</strong> or navigate
          to an existing one.
        </p>
        <p>
          Select <strong>Webhook</strong> from the <strong>Notify Via</strong>{" "}
          section. Select the webhook destination that you created in the
          previous step.
        </p>
      </div>
    </SetupInfoNumberedList>
  );
};
