import {
  DateRangePickerMode,
  DateRangePickerState,
  QUICK_SELECT_INTERVAL_CONFIG,
  QuickSelectInterval,
} from "./types";

// Note! We're also using this to serialize dates for our own filters.
// If you're changing it for metabase reasons please make a duplicate
// that preserves the current behaviour and call that in FilterModal.
export const serializeDateRange = (state: DateRangePickerState): string => {
  if (state.mode === DateRangePickerMode.Absolute) {
    return `${state.absolute.from}~${state.absolute.to}`;
  }

  const relativeState =
    state.mode === DateRangePickerMode.QuickSelect
      ? QUICK_SELECT_INTERVAL_CONFIG[state.quick_select].state
      : state.relative;

  return `past${
    relativeState.numberOfIntervals
  }${relativeState.interval.toLowerCase()}${
    relativeState.includeThisInterval ? "~" : "-"
  }`;
};

// This takes the metabase format produced by marshallDateRangePickerStateToMetabase
// and returns the corresponding DateRangePickerState.
export const deserializeDateRange = (s: string): DateRangePickerState => {
  if (s.startsWith("past")) {
    const matches = s
      .matchAll(
        /(past)(?<numberOfIntervals>[0-9]*)(?<interval>\w*)(?<includeThisInterval>[~-]?)/g,
      )
      .next().value.groups;
    const isQuickSelect = !(s.endsWith("-") || s.endsWith("~"));
    const quickSelect = getQuickSelect(
      matches.interval,
      parseInt(matches.numberOfIntervals),
      matches.includeThisInterval,
    );
    if (isQuickSelect && quickSelect) {
      return {
        mode: DateRangePickerMode.QuickSelect,
        quick_select: quickSelect,
      };
    } else {
      return {
        mode: DateRangePickerMode.Relative,
        relative: {
          interval: matches.interval,
          numberOfIntervals: matches.numberOfIntervals,
          includeThisInterval: matches.includeThisInterval === "~",
        },
      };
    }
  } else {
    const dates = s.split("~");
    return {
      mode: DateRangePickerMode.Absolute,
      absolute: {
        from: dates[0],
        to: dates[1],
      },
    };
  }
};

const getQuickSelect = (
  interval: string,
  n: number,
  includeThisInterval: boolean,
): QuickSelectInterval | undefined => {
  if (interval === "days" && n === 30 && !includeThisInterval) {
    return QuickSelectInterval.Last30Days;
  }
  if (interval === "weeks" && n === 1 && includeThisInterval) {
    return QuickSelectInterval.LastWeek;
  }
  if (interval === "months" && n === 1 && !includeThisInterval) {
    return QuickSelectInterval.LastMonth;
  }
  if (interval === "months" && n === 3 && includeThisInterval) {
    return QuickSelectInterval.Last3Months;
  }
  if (interval === "months" && n === 6 && includeThisInterval) {
    return QuickSelectInterval.Last6Months;
  }
  if (interval === "months" && n === 12 && includeThisInterval) {
    return QuickSelectInterval.Last12Months;
  }

  return void 0;
};
