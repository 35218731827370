import { ExternalUser } from "@incident-io/api";
import { TableCell, TableCellStacked } from "@incident-ui/Table/Table";

export const ExternalUserNameAndEmail = ({ user }: { user: ExternalUser }) => {
  if (user.name && user.email) {
    return (
      <TableCellStacked
        className="pr-2 overflow-hidden"
        primary={
          <span className="truncate w-full max-w-full">{user.name}</span>
        }
        secondary={user.email}
      />
    );
  }

  if (user.name) {
    return <TableCell>{user.name}</TableCell>;
  }

  if (user.email) {
    return <TableCell>{user.email}</TableCell>;
  }

  return <TableCell className="font-mono">{user.external_id}</TableCell>;
};
