import { Button, IconEnum } from "@incident-ui";
import React from "react";
import { useFormContext } from "react-hook-form";

import { WorkflowFormData } from "../../common/types";
import { WorkflowDelaySection } from "../../delay/WorkflowDelaySection";
import { WorkflowStepsSection } from "../../steps/WorkflowStepsSection";
import { WorkflowsTriggerSelector } from "../../triggers/WorkflowsTriggerSelector";
import { useWorkflowsSteps } from "../hooks/useStepsController";
import { useWorkflowsTrigger } from "../hooks/useTriggerController";
import {
  RightDrawerState,
  useWorkflowsViewState,
  WorkflowModalState,
} from "../hooks/useViewController";
import { useWorkflowsDeps } from "../WorkflowsFormContext";
import { WorkflowsCreateEditBanners } from "./WorkflowsCreateEditBanners";
import { WorkflowsCreateEditConditions } from "./WorkflowsCreateEditConditions";

export const WorkflowsCreateEditBody = (): React.ReactElement => {
  const { trigger, triggerLoading, onEditTrigger } = useWorkflowsTrigger();
  const { scope, resources, isDraft } = useWorkflowsDeps();
  const formMethods = useFormContext<WorkflowFormData>();
  const { watch } = formMethods;

  const { onOpenRightDrawer, onOpenModal } = useWorkflowsViewState();

  const {
    showEditStepForm,
    clearStepFormState,
    onClickAddStep,
    onDeleteStepGroup,
    onDeleteStep,
    onSetForEach,
    onReorderStepsWithinGroup,
    onReorderGroupSteps,
    hasSteps,
  } = useWorkflowsSteps();

  const [conditionGroups, onceFor] = watch(["condition_groups", "once_for"]);

  const onClickEditTrigger = () => {
    // If the user has set steps, added conditionGroups, or edited once for,
    // we should warn them they'll lose those changes if they change the trigger
    if (hasSteps || conditionGroups.length > 0 || onceFor.length > 0) {
      onOpenModal(WorkflowModalState.ChangeTriggerWarning);
      return;
    }

    // Otherwise it's safe to let them choose a new trigger
    onEditTrigger();
  };

  return (
    <div className="w-full flex justify-center">
      <div className="flex-1 flex flex-col items-center max-w-[600px]">
        {/* Banners if it's read-only and/or disabled */}
        <WorkflowsCreateEditBanners />

        {/* Trigger selector */}
        <WorkflowsTriggerSelector
          triggerLoading={triggerLoading}
          trigger={trigger}
          isDraft={isDraft}
          onClickEditTrigger={onClickEditTrigger}
          onClickChooseTrigger={() => {
            onOpenRightDrawer(RightDrawerState.ChooseTrigger);
          }}
        />

        <LineDivider />

        {/* Conditions */}
        <WorkflowsCreateEditConditions
          trigger={trigger}
          loading={triggerLoading}
          scopeWithExpressions={scope}
        />

        <LineDivider />

        {/* Delays */}
        <div className="p-3 border-[1px] rounded-2 border-stroke bg-surface-secondary w-full">
          <WorkflowDelaySection formMethods={formMethods} />
        </div>

        {/* Steps */}
        <WorkflowStepsSection
          resources={resources}
          scope={scope}
          onSetForEach={onSetForEach}
          onReorderGroupSteps={onReorderGroupSteps}
          onReorderStepsWithinGroup={onReorderStepsWithinGroup}
          onEditStep={showEditStepForm}
          onRemoveStepGroup={onDeleteStepGroup}
          onRemoveStep={onDeleteStep}
          onClickAddStep={onClickAddStep}
        />

        <LineDivider />

        {/* Add step */}
        <div className="flex flex-col items-center">
          <Button
            onClick={() => {
              clearStepFormState();
              onClickAddStep(null, null);
            }}
            disabled={!trigger}
            analyticsTrackingId={"workflows-v2-add-step-button"}
            icon={IconEnum.Add}
          >
            Add step
          </Button>
        </div>
      </div>
    </div>
  );
};

export const LineDivider = () => (
  <div className="h-[16px]  bg-slate-300 w-[1px]"></div>
);
