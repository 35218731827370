import { ScopeNameEnum } from "@incident-io/api";
import { useStatefulQueryParam } from "@incident-shared/filters/useStatefulQueryParamFilters";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ButtonTheme, ConfirmationDialog, IconEnum } from "@incident-ui";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { useQueryParams } from "src/utils/query-params";

import { useIdentity } from "../../../contexts/IdentityContext";
import { AlertSourceConfigurePage } from "./AlertSourceConfigurePage";
import { AlertSourceConnectPage } from "./AlertSourceConnectPage";
import { AlertSourceCreatePage } from "./AlertSourceCreatePage";

export enum AlertSourceStepEnum {
  Create = "create",
  Connect = "connect",
  Configure = "configure",
}

export type AlertSourceStep = {
  id: AlertSourceStepEnum;
  name: string;
  label: string;
} & (
  | {
      submitType: "submit";
      formId: string;
    }
  | {
      submitType: "button";
      formId?: never;
    }
);

export const AlertSourceFormIds = {
  [AlertSourceStepEnum.Create]: "alert-source-create",
  [AlertSourceStepEnum.Configure]: "alert-source-configure",
};

export const stepConfig: AlertSourceStep[] = [
  {
    id: AlertSourceStepEnum.Create,
    name: "Create",
    label: "Create",
    submitType: "submit",
    formId: AlertSourceFormIds[AlertSourceStepEnum.Create],
  },
  {
    id: AlertSourceStepEnum.Connect,
    name: "Connect",
    label: "Connect",
    submitType: "button",
  },
  {
    id: AlertSourceStepEnum.Configure,
    name: "Configure",
    label: "Configure",
    submitType: "submit",
    formId: AlertSourceFormIds[AlertSourceStepEnum.Configure],
  },
];

const getStep = (
  stepId: string,
  stepConfig: AlertSourceStep[],
): AlertSourceStep => {
  const step = stepConfig.find((s) => s.id === stepId);
  if (step === undefined) {
    throw new Error(`Invalid step: ${stepId}`);
  }
  return step;
};

export const AlertSourceCreateEditPage = ({ mode }: { mode: Mode }) => {
  const params = useParams();
  const existingAlertSourceId = params.id;

  const queryParams = useQueryParams();
  let stepId = queryParams.get("step");
  const createdAlertSourceId = queryParams.get("id");
  const from = queryParams.get("from");

  const alertSourceId =
    mode === Mode.Edit ? existingAlertSourceId : createdAlertSourceId;

  // set a default step value
  if (!stepId) {
    stepId =
      mode === Mode.Create
        ? AlertSourceStepEnum.Create
        : AlertSourceStepEnum.Configure;
  }

  const getStepUrl = (stepId: AlertSourceStepEnum) => {
    const fromQueryString = from ? `&from=${from}` : "";

    return mode === Mode.Edit
      ? `/alerts/sources/${alertSourceId}/edit?step=${stepId}${fromQueryString}`
      : `/alerts/sources/create?step=${stepId}&id=${alertSourceId}${fromQueryString}`;
  };

  if (stepId !== AlertSourceStepEnum.Create && !alertSourceId) {
    throw new Error("Unreachable: alertSourceId should be set ");
  }

  const step = getStep(stepId, stepConfig as unknown as AlertSourceStep[]);
  const [currentTab, setCurrentTab] = useStatefulQueryParam(
    "step",
    AlertSourceStepEnum.Configure,
  );
  const [loadingTab, setLoadingTab] = useState<string | null>(null);
  const isTabbed = mode === Mode.Edit;

  const [validSourceSelected, setValidSourceSelected] = useState<boolean>();
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const handleSetCurrentTab = useCallback(
    (tab) => {
      if (tab !== AlertSourceStepEnum.Configure && isFormDirty) {
        setShowWarning(true);
        setLoadingTab(tab);
      } else {
        setCurrentTab(tab);
      }
    },
    [isFormDirty, setCurrentTab],
  );

  return (
    <>
      <ConfirmationDialog
        onConfirm={() => {
          if (loadingTab) {
            setCurrentTab(loadingTab);
            setLoadingTab(null);
            setShowWarning(false);
          }
        }}
        onCancel={() => {
          setShowWarning(false);
        }}
        title="Your changes have not been saved"
        isOpen={showWarning}
      >
        <p>Are you sure you want to navigate away?</p>
      </ConfirmationDialog>
      <PageWrapper
        width={PageWidth.Full}
        noPadding
        title={mode === Mode.Edit ? "Edit alert source" : "Create alert source"}
        crumbs={[
          {
            title: "Alerts",
            to: "/alerts",
          },
          {
            title: "Alert Sources",
            to: "/alerts/sources",
          },
        ]}
        icon={IconEnum.Alert}
        backHref="/alerts/sources"
        overflowY={false}
      >
        {
          {
            [AlertSourceStepEnum.Create]: (
              <AlertSourceCreatePage
                setValidSourceSelected={setValidSourceSelected}
                setCurrentTab={handleSetCurrentTab}
                continueButton={
                  <ContinueButton
                    step={step}
                    validSourceSelected={validSourceSelected}
                    getStepUrl={getStepUrl}
                    mode={mode}
                    text={"Continue"}
                  />
                }
              />
            ),
            [AlertSourceStepEnum.Connect]: (
              <AlertSourceConnectPage
                alertSourceId={alertSourceId as string}
                mode={mode}
                setCurrentTab={handleSetCurrentTab}
              />
            ),
            [AlertSourceStepEnum.Configure]: (
              <AlertSourceConfigurePage
                alertSourceId={alertSourceId as string}
                setFormDirty={setIsFormDirty}
                mode={mode}
                setCurrentTab={handleSetCurrentTab}
              />
            ),
          }[isTabbed ? currentTab : step.id]
        }
      </PageWrapper>
    </>
  );
};

export const ContinueButton = ({
  step,
  validSourceSelected,
  getStepUrl,
  mode,
  text,
}: {
  step: AlertSourceStep;
  validSourceSelected?: boolean;
  getStepUrl: (stepId: AlertSourceStepEnum) => string;
  mode: Mode;
  text: string;
}) => {
  const { hasScope } = useIdentity();
  const stepIndex = stepConfig.findIndex((s) => s.id === step.id);

  const hasPermission =
    mode === Mode.Edit
      ? hasScope(ScopeNameEnum.AlertSourceUpdate)
      : hasScope(ScopeNameEnum.AlertSourceCreate);

  return (
    <GatedButton
      // This needs a key or clicks carry across steps, and you end up submitting
      // a form you haven't even seen yet!
      key={step.id}
      analyticsTrackingId="alert-source-create-continue"
      className="pt-2.5 pb-2.5"
      href={
        step.submitType === "button"
          ? getStepUrl(stepConfig[stepIndex + 1]?.id)
          : undefined
      }
      theme={ButtonTheme.Primary}
      type={step.submitType}
      // You can use a submit button outside a form by giving that form an ID,
      // and then setting the `form` prop on the button to match.
      // This is a much lighter weight solution than using a portal!
      form={step.formId}
      disabled={
        (step.id === AlertSourceStepEnum.Create && !validSourceSelected) ||
        !hasPermission
      }
      disabledTooltipContent={
        !hasPermission
          ? "You do not have permission to do this"
          : "Select an alert source"
      }
    >
      {text}
    </GatedButton>
  );
};
