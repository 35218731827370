import { Product } from "@incident-shared/billing";
import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { sortBy } from "lodash";
import React from "react";
import graphic from "src/components/settings/banners/banner-severity.svg";
import { ScopeNameEnum, Severity } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { prepareRankUpdate } from "src/utils/utils";

import { LevelUpIncidentResponseBanner } from "../../LevelUpIncidentResponseBanner";
import { SettingsHeading } from "../../SettingsHeading";
import { SettingsSubPageWrapper } from "../../SettingsRoute";
import { SettingsSortableList } from "../../SettingsSortableList";
import { SeverityViewListItem } from "./SeverityListItem";

export const SeveritiesListPage = (): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const {
    data: { severities: originalSeverities },
    isLoading,
    error,
  } = useAPI("severitiesList", undefined, { fallbackData: { severities: [] } });
  const severities = sortBy(originalSeverities, (sev) => -sev.rank);

  const {
    trigger: updateSeverityRanks,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    "severitiesList",
    undefined,
    async (apiClient, updatedSevs: Severity[]) =>
      await apiClient.severitiesUpdateRanks({
        updateRanksRequestBody: prepareRankUpdate(updatedSevs),
      }),
  );

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <SettingsSubPageWrapper
      accessory={
        <GatedButton
          href="/settings/severities/create"
          requiredProduct={Product.Response}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          analyticsTrackingId="add-severity"
          icon={IconEnum.Add}
          theme={ButtonTheme.Primary}
        >
          Add severity
        </GatedButton>
      }
    >
      <SettingsHeading
        title="Prioritize incidents"
        subtitle="Define and assign severities to incidents to manage response efforts effectively."
        graphic={<img src={graphic} className="h-40" />}
        learnMoreURL="https://incident.io/guide/foundations/severities"
      />
      <ErrorMessageUI message={genericError} />

      {!error ? (
        <SettingsSortableList
          items={severities}
          updateItemRanks={updateSeverityRanks}
          canEdit={canEditSettings}
          biggestRankAtTheTop
          saving={saving}
          renderItem={(item) => (
            <SeverityViewListItem severity={item} severities={severities} />
          )}
        />
      ) : (
        <ContentBox className="p-4">
          <GenericErrorMessage error={error} />
        </ContentBox>
      )}
      <LevelUpIncidentResponseBanner />
    </SettingsSubPageWrapper>
  );
};
