import {
  EmptyState,
  IconEnum,
  InlineErrorMessage,
  ToastTheme,
} from "@incident-ui";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { ErrorBoundary } from "@sentry/react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { TimelineItemsToggleVisibilityRequestBodyVisibilityEnum } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { scrollToAnchor } from "../../utils/anchor";
import { useIncident, useTimelineItems } from "../legacy/incident/hooks";
import { enrichmentError } from "./EnrichmentWarnings";
import { TimelineUI } from "./TimelineUI";

export enum SortOrder {
  NewestFirst = "newest_first",
  OldestFirst = "oldest_first",
}

export function TimelineWrapper({
  incidentId,
  sortOrder = SortOrder.NewestFirst,
  activeFilters,
  isEditMode,
}: {
  incidentId: string | null;
  sortOrder: SortOrder;
  activeFilters: string[];
  isEditMode: boolean;
}): React.ReactElement {
  return (
    <ErrorBoundary
      fallback={
        <InlineErrorMessage description="We couldn't load the timeline for this incident." />
      }
    >
      <Timeline
        incidentId={incidentId}
        sortOrder={sortOrder}
        activeFilters={activeFilters}
        isEditMode={isEditMode}
      />
    </ErrorBoundary>
  );
}

function Timeline({
  incidentId,
  sortOrder = SortOrder.NewestFirst,
  activeFilters,
  isEditMode,
}: {
  incidentId: string | null;
  sortOrder: SortOrder;
  activeFilters: string[];
  isEditMode: boolean;
}): React.ReactElement {
  const { incident } = useIncident(incidentId);
  const { timelineItems, isLoading: timelineItemsLoading } =
    useTimelineItems(incidentId);

  const showToast = useToast();

  const { trigger: onVisibilityChange } = useAPIMutation(
    "timelineItemsList",
    { incidentId: incidentId ?? "" },
    async (
      apiClient,
      { itemIds, hide }: { itemIds: string[]; hide: boolean },
    ) => {
      await Promise.all(
        itemIds.map((itemId) =>
          apiClient.timelineItemsToggleVisibility({
            id: itemId,
            toggleVisibilityRequestBody: {
              visibility: hide
                ? TimelineItemsToggleVisibilityRequestBodyVisibilityEnum.Hide
                : TimelineItemsToggleVisibilityRequestBodyVisibilityEnum.Show,
            },
          }),
        ),
      );
    },
    {
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: `We weren't able to update the visibility of this timeline item`,
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const [hasScrolledTo, setHasScrolledTo] = useState("");
  const [anchorNotFound, setAnchorNotFound] = useState(false);
  useEffect(() => {
    // When the timeline items have finished loading, scroll to the anchor if there's one stated in
    // the URL. We delay by a second because it takes a little while to render the timeline items on
    // the page.
    //
    // Note that we'll only delay when scrolling the first time. If a user clicks on another
    // notification related to the same incident, the URL will change and we'll scroll elsewhere on
    // the page without a delay.
    const delay = hasScrolledTo === "" ? 1000 : 0;
    scrollToAnchor(
      timelineItemsLoading,
      delay,
      hasScrolledTo,
      setHasScrolledTo,
      setAnchorNotFound,
    );
  }, [location.href, hasScrolledTo, timelineItemsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  // Create an error toast when the anchor ID is no longer found
  useEffect(() => {
    if (anchorNotFound) {
      showToast({
        title: "The requested item no longer exists",
        theme: ToastTheme.Error,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorNotFound]);

  const onHide = (itemIds: string[]) =>
    onVisibilityChange({ hide: true, itemIds });

  if (timelineItemsLoading || !incident) {
    return <LoadingBar className="mt-5 h-28" />;
  }

  if (_.isEmpty(timelineItems)) {
    return (
      <EmptyState
        content="There are no timeline updates yet."
        icon={IconEnum.Activity}
      />
    );
  }

  const filteredItems = timelineItems
    // We serve up some timeline items _knowing_ that in reality we don't want
    // to show them. This is for legacy backend reasons and to help with
    // debugging.
    .filter(
      (item) =>
        enrichmentError(incident, item)?.enrichment_error !==
        "thread-not-found",
    )
    .filter((item) =>
      // Only filter the items if any filters are selected
      activeFilters.length > 0 ? activeFilters.includes(item.item_type) : true,
    );

  // TODO [PINC-3067]: We can remove this when cleaning up old timeline code, because TimelineUIV2 checks this for us.
  if (filteredItems.length === 0 && activeFilters.length > 0) {
    return (
      <EmptyState
        icon={IconEnum.Filter}
        content="There are no timeline updates that match your filters."
      />
    );
  }

  return (
    <TimelineUI
      incident={incident}
      filteredItems={filteredItems}
      isEditMode={isEditMode}
      onHide={onHide}
      sortOrder={sortOrder}
      activeFilters={activeFilters}
    />
  );
}
