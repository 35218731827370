import { PolicyReportSchedule, ScopeNameEnum } from "@incident-io/api";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  ExpandableContentBox,
  Icon,
  IconEnum,
  IconSize,
  Toggle,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import { useForm, useFormContext } from "react-hook-form";

import { PolicyCreateEditSubDrawer } from "../common/PolicyCreateEditDrawer";
import { PolicyCreateEditFormData } from "../common/PolicyCreateEditForm";
import { PolicyReportCreateEditDrawer } from "../common/PolicyReportCreateEditDrawer";
import {
  PolicyReportSchedulePreview,
  PolicyReportScheduleSummary,
} from "../common/PolicyReportSchedulesPreview";
import { PolicyEditableSection } from "./PolicyEditableSection";

type ChoosePolicyReportsData = Pick<
  PolicyCreateEditFormData,
  "policy_report_ids"
>;

export const PolicyReportSection = ({
  showDrawer,
  setShowDrawer,
  drawerProps,
  allReportSchedules,
}: {
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
  drawerProps: (subDrawer: PolicyCreateEditSubDrawer) => {
    showDrawer: boolean;
    setShowDrawer: (show: boolean) => void;
  };
  allReportSchedules: PolicyReportSchedule[];
}) => {
  const formMethods = useFormContext<PolicyCreateEditFormData>();
  const { setValue, watch } = formMethods;

  const [policyReportIDs] = watch(["policy_report_ids"]);

  const onSubmit = (data: ChoosePolicyReportsData) => {
    setValue("policy_report_ids", data.policy_report_ids, {
      shouldDirty: true,
    });

    setShowDrawer(false);
  };

  return (
    <>
      <AnimatePresence>
        {showDrawer && (
          <ChoosePolicyReportsDrawer
            onSubmit={onSubmit}
            onClose={() => setShowDrawer(false)}
            initialData={{ policy_report_ids: policyReportIDs }}
            allReportSchedules={allReportSchedules}
          />
        )}
      </AnimatePresence>
      <PolicyEditableSection
        // Only show the edit button if the org has at least one report
        onEdit={
          allReportSchedules.length > 0 ? () => setShowDrawer(true) : undefined
        }
        icon={IconEnum.Email}
        title={
          <div className="flex flex-wrap">
            How should violations be
            <span className="text-blue-content pl-1">{" reported"}</span>?
          </div>
        }
      >
        <ViewPolicyReports
          {...drawerProps("new_report")}
          allReportSchedules={allReportSchedules}
        />
      </PolicyEditableSection>
    </>
  );
};

const ViewPolicyReports = ({
  showDrawer,
  setShowDrawer,
  allReportSchedules,
}: {
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
  allReportSchedules: PolicyReportSchedule[];
}) => {
  const formMethods = useFormContext<PolicyCreateEditFormData>();
  const { watch, setValue } = formMethods;

  const [policyReportIDs] = watch(["policy_report_ids"]);

  const selectedReportSchedules = allReportSchedules.filter((report) =>
    policyReportIDs.has(report.id),
  );

  const onCreatePolicyReport = (data: PolicyReportSchedule) => {
    const reports =
      formMethods.getValues<"policy_report_ids">("policy_report_ids");

    reports.add(data.id);

    setValue<"policy_report_ids">("policy_report_ids", reports);
  };

  if (allReportSchedules.length === 0) {
    return (
      <>
        <Button
          analyticsTrackingId={null}
          size={ButtonSize.Small}
          icon={IconEnum.Add}
          theme={ButtonTheme.Secondary}
          onClick={() => {
            setShowDrawer(true);
          }}
          className="w-fit"
        >
          Create a policy report
        </Button>
        <AnimatePresence>
          {showDrawer && (
            <PolicyReportCreateEditDrawer
              title={`Create a new policy report`}
              onClose={() => setShowDrawer(false)}
              initialData={{
                mode: Mode.Create,
              }}
              hidePolicySelector
              fullWidth={false}
              onCreateCallback={onCreatePolicyReport}
            />
          )}
        </AnimatePresence>
      </>
    );
  }

  const onAddReport = (report: PolicyReportSchedule) => {
    const existingReports = policyReportIDs;
    const newReports = existingReports.add(report.id);
    setValue<"policy_report_ids">("policy_report_ids", newReports);
  };

  if (selectedReportSchedules.length === 0) {
    return (
      <div className="flex flex-wrap items-center bg-surface-primary text-sm gap-2">
        {allReportSchedules.map((report) => (
          <Button
            analyticsTrackingId={null}
            size={ButtonSize.Small}
            key={report.id}
            theme={ButtonTheme.Dashed}
            onClick={() => onAddReport(report)}
          >
            {report.name}
            <Icon id={IconEnum.Add} size={IconSize.Small} />
          </Button>
        ))}
      </div>
    );
  }

  // If the org has at least one report, and there is a policy report selected
  return (
    <PolicyReportSchedulePreview
      reportSchedules={selectedReportSchedules}
      miniBadge={false}
    />
  );
};

const ChoosePolicyReportsDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  allReportSchedules,
}: {
  onSubmit: (data: ChoosePolicyReportsData) => void;
  onClose: () => void;
  initialData: ChoosePolicyReportsData;
  allReportSchedules: PolicyReportSchedule[];
}) => {
  const formMethods = useForm<ChoosePolicyReportsData>({
    defaultValues: initialData,
  });

  const { watch, setValue } = formMethods;

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  const [selectedPolicyReportIds] = watch(["policy_report_ids"]);

  const onAddReport = (report: PolicyReportSchedule) => {
    setValue<"policy_report_ids">(
      "policy_report_ids",
      selectedPolicyReportIds.add(report.id),
    );
  };
  const onRemoveReport = (report: PolicyReportSchedule) => {
    selectedPolicyReportIds.delete(report.id);
    setValue<"policy_report_ids">("policy_report_ids", selectedPolicyReportIds);
  };

  return (
    <Drawer width="medium" onClose={onClose}>
      <DrawerContents className="overflow-hidden">
        <DrawerTitle
          title="Policy reports"
          onClose={onClose}
          icon={IconEnum.Filter}
          subtitle="Select which reports you wish to apply this policy"
        />
        <DrawerBody className="overflow-y-hidden">
          <FormV2
            fullHeight
            formMethods={formMethods}
            onSubmit={onSubmit}
            id="which-reports"
          >
            <div className="flex flex-col gap-6">
              {allReportSchedules.map((report) => {
                const isSelected = selectedPolicyReportIds.has(report.id);

                return (
                  <PolicyReportSelector
                    key={report.id}
                    report={report}
                    isSelected={isSelected}
                    onChangeSelected={() =>
                      isSelected ? onRemoveReport(report) : onAddReport(report)
                    }
                  />
                );
              })}
            </div>
          </FormV2>
        </DrawerBody>
        <DrawerFooter className="flex gap-2 justify-end">
          <Button onClick={() => onClose()} analyticsTrackingId={null}>
            Back
          </Button>
          <GatedButton
            form="which-reports"
            requiredScope={ScopeNameEnum.PoliciesCreate}
            type="submit"
            theme={ButtonTheme.Primary}
            analyticsTrackingId={null}
          >
            Apply
          </GatedButton>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};

const PolicyReportSelector = ({
  report,
  isSelected,
  onChangeSelected,
}: {
  report: PolicyReportSchedule;
  isSelected: boolean;
  onChangeSelected: () => void;
}): React.ReactElement => {
  return (
    <ExpandableContentBox
      isExpanded
      titleNode={
        <Toggle
          id={report.id}
          label={report.name}
          onToggle={onChangeSelected}
          on={isSelected}
          toggleClassName="justify-between"
        />
      }
    >
      <PolicyReportScheduleSummary report={report} whiteBadge />
    </ExpandableContentBox>
  );
};
