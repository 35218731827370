import {
  AlertRouteIncidentTemplatePayload,
  AlertRouteIncidentTemplatePayloadPrioritySeverityEnum as SeverityPriorityRule,
  CatalogType,
  ConditionGroup,
  CustomField,
  CustomFieldFieldTypeEnum,
  EngineScope,
  Expression,
  Resource,
  ScopeNameEnum,
} from "@incident-io/api";
import { assertUnreachable } from "@incident-io/status-page-ui";
import {
  EngineFormElement,
  EngineReferenceBadge,
  ReferenceSelectorPopover,
} from "@incident-shared/engine";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import { CheckboxRowV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { ToggleRowV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  SlackButtonPreview,
  SlackMessagePreview,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import { InputType } from "@incident-ui/Input/Input";
import { PopoverDropdownMenu } from "@incident-ui/PopoverDropdownMenu/PopoverDropdownMenu";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { AnimatePresence, motion } from "framer-motion";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { filterScope } from "src/utils/scope";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { purgeEmpty } from "src/utils/utils";
import { useDebounce } from "use-hooks";

import { useAlertResources } from "../common/useAlertResources";
import {
  AlertRouteFormSection,
  AlertRouteSplitDrawerSection,
  AlertRouteYesNoButtonGroup,
} from "./AlertRouteFormSection";
import { AlertRouteGroupBySelector } from "./AlertRouteGroupBySelector";
import { AlertRouteIncidentPreview } from "./AlertRouteIncidentPreview";
import { GroupedAlertsTable } from "./GroupedAlertsTable";
import {
  AlertRouteFormData,
  alertSourcesToPayload,
  CreateIncidentsFormData,
  parseIncidentTemplateData,
} from "./types";

export const AlertRouteCreateIncidentSection = ({
  scope,
  resources,
}: {
  scope: EngineScope;
  resources: Resource[];
}) => {
  const formMethods = useFormContext<AlertRouteFormData>();

  const onSubmit = (data: CreateIncidentsFormData) => {
    formMethods.setValue<"enableIncidents">(
      "enableIncidents",
      data.enableIncidents,
      {
        shouldDirty: true,
      },
    );

    formMethods.setValue<"expressions">("expressions", data.expressions, {
      shouldDirty: true,
    });

    if (data.enableIncidents) {
      formMethods.setValue<"incidentConditionGroups">(
        "incidentConditionGroups",
        data.incidentConditionGroups,
        {
          shouldDirty: true,
        },
      );

      formMethods.setValue<"enableGrouping">(
        "enableGrouping",
        data.enableGrouping,
        {
          shouldDirty: true,
        },
      );
      if (data.enableGrouping) {
        formMethods.setValue<"groupingKeys">("groupingKeys", data.groupingKeys);
        formMethods.setValue<"groupingWindow">(
          "groupingWindow",
          data.groupingWindow,
        );
        formMethods.setValue<"deferTime">("deferTime", data.deferTime);
      }

      formMethods.setValue<"template">("template", data.template);
      formMethods.setValue<"autoDeclineEnabled">(
        "autoDeclineEnabled",
        data.autoDeclineEnabled,
      );
    }

    setEditingContext({ isEditing: false });
    formMethods.setValue<"hasEverConfiguredIncidents">(
      "hasEverConfiguredIncidents",
      true,
      {
        shouldDirty: true,
      },
    );
  };

  const [editingContext, setEditingContext] = useState<{
    isEditing: boolean;
    initialData?: AlertRouteFormData;
  }>({
    isEditing: false,
  });

  const hasEverConfiguredEscalations = formMethods.watch(
    "hasEverConfiguredEscalations",
  );
  const hasEverConfiguredIncidents = formMethods.watch(
    "hasEverConfiguredIncidents",
  );
  const enableIncidents = formMethods.watch("enableIncidents");
  const enableGrouping = formMethods.watch("enableGrouping");
  const groupingKeys = formMethods.watch("groupingKeys");
  const groupingWindow = formMethods.watch("groupingWindow");
  const incidentConditionGroups = formMethods.watch("incidentConditionGroups");

  const groupingWindowLabel =
    groupingWindow &&
    DEBOUNCE_WINDOW_OPTIONS.find(
      (opt) => opt.value === groupingWindow.toString(),
    )?.label;

  return (
    <>
      {editingContext.isEditing && (
        <CreateIncidentsDrawer
          onSubmit={onSubmit}
          onClose={() => setEditingContext({ isEditing: false })}
          initialData={editingContext.initialData}
          scope={scope}
          resources={resources}
        />
      )}
      <AlertRouteFormSection
        title={getIncidentSectionTitle({
          enableIncidents,
          enableGrouping,
          incidentConditionGroups,
          hasEverConfiguredIncidents,
        })}
        color={ColorPaletteEnum.Red}
        isCurrentFirstTimeStep={
          !hasEverConfiguredIncidents && hasEverConfiguredEscalations
        }
        firstTimeContent={
          <>
            Choose whether to create incidents from this route. You can also set
            up alert grouping to decrease noise when multiple alerts are
            happening.
          </>
        }
        disabled={hasEverConfiguredIncidents && !enableIncidents}
        icon={IconEnum.Incident}
        accessory={
          !hasEverConfiguredIncidents ? (
            <AlertRouteYesNoButtonGroup
              disabled={!hasEverConfiguredEscalations}
              onChange={(shouldCreateIncidents) => {
                formMethods.setValue<"enableIncidents">(
                  "enableIncidents",
                  shouldCreateIncidents,
                );

                if (shouldCreateIncidents) {
                  setEditingContext({
                    isEditing: true,
                    initialData: formMethods.getValues(),
                  });
                } else {
                  formMethods.setValue<"hasEverConfiguredIncidents">(
                    "hasEverConfiguredIncidents",
                    true,
                  );
                }
              }}
            />
          ) : (
            <GatedButton
              requiredScope={ScopeNameEnum.AlertRouteUpdate}
              theme={ButtonTheme.Naked}
              onClick={() =>
                setEditingContext({
                  isEditing: true,
                  initialData: formMethods.getValues(),
                })
              }
              icon={IconEnum.Edit}
              className="text-content-tertiary"
              title="Edit incident configuration"
              analyticsTrackingId="alert-routes-edit-create-incidents"
            />
          )
        }
      >
        {hasEverConfiguredIncidents &&
          (enableIncidents ? (
            <>
              {incidentConditionGroups?.length > 0 && (
                <ConditionGroupsList
                  groups={incidentConditionGroups}
                  className="w-full p-0 shadow-none"
                />
              )}
              {incidentConditionGroups?.length > 0 && enableGrouping && (
                <div className="text-sm-bold">And group alerts by</div>
              )}
              {enableGrouping && (
                <div className="rounded-2 border flex flex-wrap border-stroke p-3 gap-2 w-full">
                  {groupingKeys.length > 0 ? (
                    groupingKeys.map(({ key, label }) => (
                      <EngineReferenceBadge key={key} label={label} />
                    ))
                  ) : (
                    <EngineReferenceBadge
                      label={`Time window (${groupingWindowLabel})`}
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <Callout showIcon={false} theme={CalloutTheme.Plain}>
              This alert route will not create any incidents or incident
              channels
            </Callout>
          ))}
      </AlertRouteFormSection>
    </>
  );
};

export type AlertRouteEngineFormProps = {
  resources: Resource[];
  scope: EngineScope;
  array: boolean;
  required: boolean;
  showPlaceholder: boolean;
  disabled: boolean;
  mode: "variables_and_expressions" | "variables_only";
};

const getIncidentSectionTitle = ({
  enableIncidents,
  enableGrouping,
  incidentConditionGroups,
  hasEverConfiguredIncidents,
}: {
  enableIncidents?: boolean;
  enableGrouping?: boolean;
  incidentConditionGroups: ConditionGroup[];
  hasEverConfiguredIncidents: boolean;
}) => {
  if (!hasEverConfiguredIncidents) {
    return "Create incidents";
  }

  if (!enableIncidents) {
    return "Don't create incidents";
  }

  if (incidentConditionGroups?.length > 0) {
    return "Create incidents from alerts matching";
  }

  if (enableGrouping) {
    return "Create incidents, and group alerts by";
  }

  return "Create incidents from all alerts";
};

const CreateIncidentsDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  scope,
  resources,
}: {
  onSubmit: (data: CreateIncidentsFormData) => void;
  onClose: () => void;
  initialData?: AlertRouteFormData;
  scope: EngineScope;
  resources: Resource[];
}) => {
  const formMethods = useForm<CreateIncidentsFormData>({
    defaultValues: initialData,
  });

  const expressionMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });
  const scopeWithExpressions = addExpressionsToScope(
    scope,
    expressionMethods.fields,
  );

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  const [
    enableIncidents,
    enableGrouping,
    groupingKeys,
    incidentConditionGroups,
    expressions,
    template,
  ] = formMethods.watch([
    "enableIncidents",
    "enableGrouping",
    "groupingKeys",
    "incidentConditionGroups",
    "expressions",
    "template",
  ]);

  const selectedSeverityLiteral = template?.severity?.value?.literal;
  const { data: severityData } = useAPI(
    selectedSeverityLiteral ? "severitiesShow" : null,
    {
      id: selectedSeverityLiteral || "",
    },
  );

  const {
    data: { custom_fields: customFields },
    isLoading: customFieldsLoading,
    error: customFieldsError,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });

  const {
    data: { catalog_types: catalogTypes },
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  const {
    error: resourcesError,
    isLoading: resourcesLoading,
    schemaResponse,
    configsResp,
    resourcesListResp,
  } = useAlertResources();

  const selectedIncTypeLiteral = template?.incident_type?.value?.literal;
  const { data: incTypeData } = useAPI(
    selectedIncTypeLiteral ? "incidentTypesShow" : null,
    {
      id: selectedIncTypeLiteral || "",
    },
  );

  const {
    data: previewAlerts,
    isLoading: previewAlertsLoading,
    mutate: previewAlertsMutate,
  } = useAPI("alertRoutesPreviewFindAndGroupMatchingAlerts", {
    previewFindAndGroupMatchingAlertsRequestBody: {
      grouping_keys: enableGrouping
        ? groupingKeys.map((x) => {
            return { id: x.key };
          })
        : [],
      expressions: expressions?.map(expressionToPayload) || [],
      condition_groups: conditionGroupsToGroupPayloads([
        ...(initialData?.conditionGroups || []),
        ...incidentConditionGroups,
      ]),
      alert_sources: alertSourcesToPayload(initialData?.alertSources || {}),
    },
  });

  const selectedIncTypeName = incTypeData?.incident_type.name;
  const selectedSeverityName = severityData?.severity.name;

  const allAlerts = [
    ...(previewAlerts?.ungrouped_alerts ?? []),
    ...(previewAlerts?.grouped_alerts?.flatMap(
      (groupedAlerts) => groupedAlerts.alerts,
    ) || []),
  ];

  const {
    data: incidentPreview,
    isLoading: incidentPreviewLoading,
    mutate: refreshIncidentPreview,
  } = useAPI(
    allAlerts.length >= 1 && template ? "alertRoutesPreviewIncident" : null,
    {
      previewIncidentRequestBody: {
        alert_id: allAlerts.length >= 1 ? allAlerts[0].id : "",
        template: parseIncidentTemplateData(
          purgeEmpty(template as AlertRouteIncidentTemplatePayload),
        ),
        expressions: expressions
          ? expressions.map((e) =>
              expressionToPayload(e as unknown as Expression),
            )
          : undefined,
      },
    },
  );

  // We JSON.stringify this so that we can trigger the use effect whenever the
  // template changes, as otherwise swr does not refire.
  const templateChecksum = useDebounce(JSON.stringify(template), 500);

  useEffect(() => {
    previewAlertsMutate();
    refreshIncidentPreview();
  }, [templateChecksum, previewAlertsMutate, refreshIncidentPreview]);

  const returnedGroupedAlerts =
    previewAlerts &&
    (previewAlerts.grouped_alerts.length > 0 ||
      previewAlerts.ungrouped_alerts.length > 0);

  const engineFormProps: AlertRouteEngineFormProps = {
    resources: resources,
    scope: scopeWithExpressions,
    array: false,
    required: false,
    showPlaceholder: true,
    disabled: false,
    mode: "variables_and_expressions" as const,
  };

  if (customFieldsError || catalogTypesError || resourcesError) {
    return (
      <GenericErrorMessage error={customFieldsError ?? catalogTypesError} />
    );
  }

  if (
    customFieldsLoading ||
    catalogTypesLoading ||
    resourcesLoading ||
    !schemaResponse ||
    !resourcesListResp ||
    !configsResp
  ) {
    return (
      <Drawer width="full" onClose={onClose}>
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  const selectedSourceConfigs = configsResp.alert_source_configs.filter(
    (config) => initialData?.alertSources[config.id]?.selected,
  );

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionMethods}
      allowAllOfACatalogType={false}
    >
      <Drawer width="full" onClose={onClose}>
        <div className="flex flex-col h-full">
          <DrawerContents className=" grow overflow-hidden">
            <DrawerTitle
              title="Create incidents"
              onClose={onClose}
              icon={IconEnum.Incident}
              compact
              theme={DrawerTitleTheme.Bordered}
            />

            <div className="flex flex-col grow overflow-y-auto lg:flex-row">
              <DrawerBody className="p-0 overflow-y-hidden">
                <FormV2
                  id="alert-route-create-incidents"
                  fullHeight
                  formMethods={formMethods}
                  onSubmit={onSubmit}
                  outerClassName="overflow-auto"
                >
                  <div className="flex flex-col h-full">
                    <AlertRouteSplitDrawerSection
                      className="grow"
                      left={
                        <>
                          <div className="text-sm text-content-secondary">
                            Choose whether to create incidents from alerts in
                            this route. You can also filter on which alerts in
                            this route should create incidents and group to
                            decrease noise when multiple alerts are happening.
                          </div>
                          <BooleanRadioButtonGroupV2
                            trueOption={{
                              label: "Yes",
                            }}
                            falseOption={{
                              label: "No",
                            }}
                            name="enableIncidents"
                            label="Create incidents from this route?"
                            formMethods={formMethods}
                            srLabel="Enable escalations"
                            boxed
                          />

                          {!enableIncidents && (
                            <Callout
                              showIcon={false}
                              theme={CalloutTheme.Warning}
                            >
                              You are not creating incidents from the alerts in
                              this route. This means your alerts will not be
                              grouped, which can increase response and
                              resolution time for alerts due to alert noise.
                            </Callout>
                          )}
                        </>
                      }
                    />
                    {enableIncidents && (
                      <>
                        <AlertRouteSplitDrawerSection
                          left={
                            <>
                              <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                  <div className="text-base-bold">
                                    Filtering
                                  </div>
                                  <div className="text-sm text-content-secondary">
                                    Add further filtering to only create
                                    incidents from certain alerts
                                  </div>
                                </div>

                                <div
                                  className={tcx(
                                    "flex gap-4 p-4 rounded-2 border border-stroke",
                                    incidentConditionGroups.length > 0
                                      ? "flex-col"
                                      : "items-center justify-between",
                                  )}
                                >
                                  <div className="flex items-center gap-2">
                                    <Icon
                                      id={IconEnum.Filter}
                                      size={IconSize.Large}
                                      className="text-content-tertiary"
                                    />
                                    {incidentConditionGroups.length > 0 ? (
                                      <>
                                        Create incidents when alerts match the
                                        following criteria:
                                      </>
                                    ) : (
                                      <>Create incidents from all alerts</>
                                    )}
                                  </div>

                                  <ConditionGroupsEditorV2
                                    formMethods={formMethods}
                                    name="incidentConditionGroups"
                                    scope={scopeWithExpressions}
                                    entityNameLabel="alert route"
                                    subjectsLabel="alerts"
                                    conditionLabel="filter"
                                    allowAllOfACatalogTypeInQueryExpression={
                                      false
                                    }
                                    expressions={expressionMethods.fields}
                                    allowFilteringByExpression
                                    boxless
                                    hideIntroSentence
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col gap-4 pt-8">
                                <div className="flex flex-col gap-2">
                                  <div className="text-base-bold">Grouping</div>
                                  <div className="text-sm text-content-secondary">
                                    When an alert fires, we find incidents with
                                    similar alerts and ask the incident channel
                                    if it’s related.
                                  </div>
                                </div>

                                <div>
                                  <ToggleRowV2
                                    name="enableGrouping"
                                    formMethods={formMethods}
                                    label="Group alerts"
                                  />
                                  <AnimatePresence>
                                    {enableGrouping && (
                                      <GroupingForm
                                        scope={scopeWithExpressions}
                                        resources={resources}
                                      />
                                    )}
                                  </AnimatePresence>
                                </div>
                              </div>
                            </>
                          }
                          right={
                            <div
                              className={tcx(
                                returnedGroupedAlerts
                                  ? "mb-24 space-y-2"
                                  : "h-full",
                              )}
                            >
                              <div className="text-sm-med text-content-tertiary">
                                Preview
                              </div>
                              {previewAlertsLoading ? (
                                <Loader />
                              ) : (
                                <GroupedAlertsTable
                                  className={tcx("!w-full overflow-x-auto")}
                                  schema={schemaResponse.alert_schema}
                                  resources={resourcesListResp.resources}
                                  alertSourceConfigs={
                                    selectedSourceConfigs || []
                                  }
                                  groupingKeys={groupingKeys.map((x) => x.key)}
                                  groupedAlerts={
                                    previewAlerts?.grouped_alerts ?? []
                                  }
                                  ungroupedAlerts={
                                    previewAlerts?.ungrouped_alerts ?? []
                                  }
                                />
                              )}
                            </div>
                          }
                        />
                        <AlertRouteSplitDrawerSection
                          left={
                            <>
                              <div>
                                <div className="space-y-2">
                                  <div className="text-base-bold">
                                    Incident details
                                  </div>
                                  <div className="text-sm text-content-secondary">
                                    Customise the details these incidents will
                                    be created with.
                                  </div>
                                </div>

                                {/* Incident Template */}
                                <IncidentTemplateForm
                                  engineFormProps={engineFormProps}
                                  resources={resources}
                                  customFields={customFields}
                                  catalogTypes={catalogTypes}
                                />
                              </div>
                              {/* Auto-decline */}
                              <CheckboxRowV2
                                className="w-full"
                                formMethods={formMethods}
                                name="autoDeclineEnabled"
                                label="Decline triage incidents if the linked alerts are resolved"
                                helptext="Triage incidents which have yet to be accepted will be automatically declined if the alert(s) are resolved."
                                helptextAlign={"end"}
                              />
                            </>
                          }
                          right={
                            previewAlertsLoading || incidentPreviewLoading ? (
                              <Loader />
                            ) : (
                              <AlertRouteIncidentPreview
                                alert={allAlerts?.[0]}
                                incidentPreview={incidentPreview}
                                customFields={customFields}
                                customFieldValues={template?.custom_fields}
                                alertRouteName={initialData?.name || ""}
                                incidentTypeLabel={selectedIncTypeName}
                                severityLabel={selectedSeverityName}
                              />
                            )
                          }
                        />
                      </>
                    )}
                  </div>
                </FormV2>
              </DrawerBody>
            </div>

            <DrawerFooter className="flex gap-2 justify-end">
              <Button onClick={() => onClose()} analyticsTrackingId={null}>
                Back
              </Button>
              <GatedButton
                form="alert-route-create-incidents"
                requiredScope={ScopeNameEnum.AlertRouteUpdate}
                type="submit"
                theme={ButtonTheme.Primary}
                analyticsTrackingId="alert-routes-edit-sources-save"
              >
                Apply
              </GatedButton>
            </DrawerFooter>
          </DrawerContents>
        </div>
      </Drawer>
    </ExpressionsMethodsProvider>
  );
};

const GroupingForm = ({
  scope,
  resources,
}: {
  scope: EngineScope;
  resources: Resource[];
}) => {
  const formMethods = useFormContext<CreateIncidentsFormData>();

  const groupingKeyFieldMethods = useFieldArray({
    control: formMethods.control,
    name: "groupingKeys",
  });

  return (
    <motion.div
      className="bg-slate-50 flex flex-col gap-5 p-5 rounded-b-lg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col gap-1">
        <div className="text-sm-med">Grouping rule</div>
        <div>
          If multiple alerts are received within
          <PopoverSingleSelectV2
            formMethods={formMethods}
            required={true}
            name="groupingWindow"
            className="inline-flex mx-1"
            options={DEBOUNCE_WINDOW_OPTIONS}
            placeholder="Select grouping window..."
            triggerStyle="inline-button"
          />
          <span>
            group by
            {groupingKeyFieldMethods.fields.length > 0 && ":"}
          </span>
          {groupingKeyFieldMethods.fields.length === 0 ? (
            <ReferenceSelectorPopover
              scope={filterScope(scope, (x) => {
                return (
                  !x.type.includes("TemplatedText") &&
                  !x.key.startsWith("expressions") &&
                  !groupingKeyFieldMethods.fields.find(
                    (field) => field.key === x.key,
                  )
                );
              })}
              allowExpressions={false}
              renderTriggerButton={({ onClick }) => (
                <Button
                  theme={ButtonTheme.Unstyled}
                  onClick={onClick}
                  analyticsTrackingId="alert-route-add-property"
                  className={tcx(
                    "mx-1 !p-0.5 !pl-2 !-mt-1 !font-normal rounded-[6px] border !border-stroke !bg-surface-secondary !text-content-primary",
                  )}
                >
                  <div className="flex items-center gap-1">
                    <div>time-window only</div>
                    <Icon id={IconEnum.Expand} className="text-slate-400" />
                  </div>
                </Button>
              )}
              isSelectable={() => true}
              onSelectReference={(ref) => {
                groupingKeyFieldMethods.append({
                  key: ref.key,
                  label: ref.label,
                });
              }}
            />
          ) : null}
        </div>
        {groupingKeyFieldMethods.fields.length > 0 ? (
          <AlertRouteGroupBySelector
            fieldMethods={groupingKeyFieldMethods}
            scope={scope}
            resources={resources}
          />
        ) : null}
      </div>
      <InputV2
        formMethods={formMethods}
        label="Grace period"
        helptext="For subsequent grouped alerts, how long should we wait for a response within the incident before escalating?"
        type={InputType.Number}
        name="deferTime"
        placeholder="2"
        insetSuffixNode={
          <span className="text-content-secondary">minutes</span>
        }
        inputClassName="bg-white"
      />
      <div className="flex flex-col gap-1">
        <div className="text-sm-med">Preview</div>
        <div>
          When an alert is grouped in an active incident, we will send a message
          to the channel.
        </div>
        <AnotherAlertSlackMessagePreview />
      </div>
    </motion.div>
  );
};

const INCIDENT_TYPE_TYPE = "IncidentType";
const INCIDENT_SEVERITY_TYPE = "IncidentSeverity";
const INCIDENT_MODE_TYPE = "IncidentMode";
const INCIDENT_NAME_TYPE = `TemplatedText["plain_single_line"]`;
const INCIDENT_SUMMARY_TYPE = `TemplatedText["plain_multi_line"]`;
export const INCIDENT_SLACK_TEAM_TYPE = "SlackTeam";

const IncidentTemplateForm = ({
  resources,
  engineFormProps,
  customFields,
  catalogTypes,
}: {
  resources: Resource[];
  engineFormProps: AlertRouteEngineFormProps;
  customFields: CustomField[];
  catalogTypes: CatalogType[];
}) => {
  const formMethods = useFormContext<CreateIncidentsFormData>();

  const initialCustomFields = formMethods.getValues("template.custom_fields");
  const initialCustomFieldIDs = initialCustomFields
    ? Object.keys(initialCustomFields)
    : [];
  const [selectedCustomFieldIDs, setSelectedCustomFieldIDs] = useState(
    new Set<string>(initialCustomFieldIDs),
  );

  const addCustomField = (id: string) => {
    // Since these are dynamic form elements, we manually set
    // the default value as we create the element.
    const selectedField = customFields.find((x) => x.id === id);
    const selectedFieldPriority =
      selectedField?.field_type === CustomFieldFieldTypeEnum.MultiSelect
        ? "append"
        : "first-wins";
    formMethods.setValue<`template.custom_field_priorities.${typeof id}`>(
      `template.custom_field_priorities.${id}`,
      selectedFieldPriority,
    );
    setSelectedCustomFieldIDs((prev) => new Set(prev.add(id)));
  };

  const removeCustomField = (id: string) => {
    // When removing a custom field, we want to reset all the form
    // fields associated with it to avoid junk data in the form state.
    const template = cloneDeep(formMethods.getValues("template"));
    if (template?.custom_fields) {
      delete template.custom_fields[id];
    }
    if (template?.custom_field_priorities) {
      delete template.custom_field_priorities[id];
    }
    formMethods.setValue<"template">("template", template, {
      shouldDirty: true,
    });
    setSelectedCustomFieldIDs((prev) => {
      const next = new Set(prev);
      next.delete(id);
      return next;
    });
  };

  const selectedCustomFields = customFields.filter((x) =>
    selectedCustomFieldIDs.has(x.id),
  );
  const availableCustomFields = customFields.filter(
    (x) => !selectedCustomFieldIDs.has(x.id),
  );

  const [selectedIncType] = formMethods.watch(["template.incident_type"]);

  const selectedIncTypeLiteral = selectedIncType?.value?.literal;
  const { data: incTypeData } = useAPI(
    selectedIncTypeLiteral ? "incidentTypesShow" : null,
    {
      id: selectedIncTypeLiteral || "",
    },
  );

  const selectedPrivateIncType =
    incTypeData?.incident_type.private_incidents_only;
  const selectedExpressionIncType = !!selectedIncType?.value?.reference;

  const workspace = resources.find((x) => x.type === INCIDENT_SLACK_TEAM_TYPE);

  return (
    <div className="space-y-6">
      {/* Workspaces */}
      {(workspace?.options ?? []).length > 1 ? (
        <EngineFormElement<CreateIncidentsFormData>
          {...engineFormProps}
          name="template.workspace"
          resourceType={INCIDENT_SLACK_TEAM_TYPE}
          label="Incident Workspace"
          description="When the incident is created, which workspace should it be created in?"
          required
        />
      ) : null}
      <EngineFormElement<CreateIncidentsFormData>
        name={`template.name`}
        resourceType={INCIDENT_NAME_TYPE}
        label="Name"
        description={
          "We default to the alert title, but you can customise it with attributes and text here."
        }
        {...engineFormProps}
      />
      <EngineFormElement<CreateIncidentsFormData>
        name={`template.summary`}
        resourceType={INCIDENT_SUMMARY_TYPE}
        label="Summary"
        description={"Do you want to set a default summary?"}
        {...engineFormProps}
      />
      <EngineFormElement<CreateIncidentsFormData>
        name={`template.incident_mode`}
        resourceType={INCIDENT_MODE_TYPE}
        label="Incident mode"
        description={
          "We will default to real incidents, but you can also create test incidents if needed."
        }
        {...engineFormProps}
      />

      {selectedPrivateIncType || selectedExpressionIncType ? (
        <Callout theme={CalloutTheme.Warning}>
          {selectedPrivateIncType ? (
            <>
              <div>
                This incident type creates{" "}
                <span className="text-sm-bold">private incidents.</span>
              </div>
              <div>
                Private incidents can&rsquo;t be created by alerts, so no
                incidents will be triggered by this alert route.
              </div>
            </>
          ) : (
            <div>
              <div className="text-sm-bold">
                This incident type relies on an expression.
              </div>
              <div>
                If your expression returns a private incident type, no incident
                will be triggered by this alert route.
              </div>
            </div>
          )}
        </Callout>
      ) : null}
      {resources.find((x) => x.type === INCIDENT_TYPE_TYPE) ? (
        <EngineFormElement<CreateIncidentsFormData>
          name={`template.incident_type`}
          resourceType={INCIDENT_TYPE_TYPE}
          label="Type"
          description={
            "You can choose to set an incident type based on alert attributes."
          }
          {...engineFormProps}
        />
      ) : null}
      <fieldset>
        <EngineFormElement<CreateIncidentsFormData>
          name={`template.severity`}
          resourceType={INCIDENT_SEVERITY_TYPE}
          label="Severity"
          description={
            "You can choose to set an incident severity based on alert attributes."
          }
          {...engineFormProps}
        />
        <RadioButtonGroupV2
          name={"template.priority_severity"}
          className="mt-2"
          horizontal
          options={[
            {
              label: "Maximum",
              value: SeverityPriorityRule.Max,
              tooltip:
                "Incident severity will be the maximum severity derived from all attached alerts.",
              tooltipProps: {
                align: "start",
              },
            },
            {
              label: "First wins",
              value: SeverityPriorityRule.FirstWins,
              tooltip:
                "Only the first relevant alert will set the incident severity.",
              tooltipProps: {
                align: "start",
              },
            },
          ]}
          srLabel={"priority_single_value"}
          formMethods={formMethods}
        />
      </fieldset>

      {/* Custom fields configuration */}
      {selectedCustomFields?.map((field) => {
        const fieldPriorityOptions: RadioButtonGroupOption[] = [
          {
            label: "First wins",
            value: "first-wins",
            tooltip: `Only the first relevant alert will set the value of ${field.name}.`,
          },
          {
            label: "Last wins",
            value: "last-wins",
            tooltip: `The most recent relevant alert will set the value of ${field.name}.`,
          },
        ];
        if (field.field_type === CustomFieldFieldTypeEnum.MultiSelect) {
          fieldPriorityOptions.unshift({
            label: "Accumulate",
            value: "append",
            tooltip: `${field.name} will accumulate new values each time a new alert is attached.`,
            tooltipProps: {
              align: "start",
            },
          });
        }
        return (
          <fieldset key={field.id}>
            <EngineFormElement<CreateIncidentsFormData>
              className="w-full"
              name={`template.custom_fields.${field.id}`}
              resourceType={resourceTypeForCustomField(field, catalogTypes)}
              label={field.name}
              {...engineFormProps}
              array={field.field_type === CustomFieldFieldTypeEnum.MultiSelect}
              labelAccessory={
                <Button
                  theme={ButtonTheme.Unstyled}
                  className="text-red-500 hover:text-red-800 ml-auto text-sm"
                  onClick={() => {
                    removeCustomField(field.id);
                  }}
                  analyticsTrackingId={null}
                >
                  Remove
                </Button>
              }
            />
            <RadioButtonGroupV2
              name={`template.custom_field_priorities.${field.id}`}
              className="mt-2"
              horizontal
              options={fieldPriorityOptions}
              srLabel={"priority_single_value"}
              formMethods={formMethods}
            />
          </fieldset>
        );
      })}

      {/* Add custom fields */}
      <PopoverDropdownMenu
        triggerButton={
          <Button analyticsTrackingId={null} icon={IconEnum.Add}>
            Add custom field
          </Button>
        }
        elementName="custom fields"
        options={availableCustomFields.map((x) => {
          return {
            label: x.name,
            value: x.id,
            icon: iconForCustomField(x),
          };
        })}
        onSelect={(field) => addCustomField(field.value)}
      />
    </div>
  );
};

const iconForCustomField = (customField: CustomField): IconEnum => {
  switch (customField.field_type) {
    case CustomFieldFieldTypeEnum.Link:
    case CustomFieldFieldTypeEnum.Text:
      return IconEnum.Text;
    case CustomFieldFieldTypeEnum.Numeric:
      return IconEnum.Numeric;
    case CustomFieldFieldTypeEnum.SingleSelect:
      return IconEnum.SingleSelect;
    case CustomFieldFieldTypeEnum.MultiSelect:
      return IconEnum.MultiSelect;
    default:
      throw new Error("Unknown custom field type");
  }
};

const resourceTypeForCustomField = (
  customField: CustomField,
  catalogTypes: CatalogType[],
): string => {
  switch (customField.field_type) {
    case CustomFieldFieldTypeEnum.Link:
      return "String";
    case CustomFieldFieldTypeEnum.Numeric:
      return "Number";
    case CustomFieldFieldTypeEnum.Text:
      return "String";
    case CustomFieldFieldTypeEnum.MultiSelect:
    case CustomFieldFieldTypeEnum.SingleSelect:
      if (customField.catalog_type_id) {
        const catalogType = catalogTypes.find(
          (ct) => ct.id === customField.catalog_type_id,
        );
        if (!catalogType) {
          throw new Error("Catalog type not found");
        }
        if (catalogType.registry_type) {
          return `CatalogEntry["${catalogType.registry_type}"]`;
        }
        return `CatalogEntry["${customField.catalog_type_id}"]`;
      }
      return `IncidentCustomFieldOption["${customField.id}"]`;
    default:
      assertUnreachable(customField.field_type);
  }
  return "";
};

const AnotherAlertSlackMessagePreview = () => {
  return (
    <SlackMessagePreview
      className="min-h-[116px] overflow-hidden"
      showTitle={false}
    >
      <div className={"space-y-2"}>
        <div className="flex items-center space-x-1 !my-0.5">
          <span>⌛</span>
          <span className="font-['lato'] font-bold">
            We&apos;ve received another alert...
          </span>
        </div>
        <div className={"flex gap-4"}>
          <div className="bg-[#DDDDDD] rounded-2 max-h-[70px] min-w-[5px]" />
          <div className={"flex-row"}>
            <div className={"text-[#39629B] hover:underline"}>
              Database Error: Connection timeouts on DB01
            </div>
            <div className={"flex items-center space-x-1"}>
              <span className={"text-[#39629B] font-semibold hover:underline"}>
                Dashboard
              </span>
              <span>| Team: </span>
              <span className="bg-[#EFEFEF] text-[#e03165] border-stroke border-[0.01rem] rounded p-0.5">
                Core
              </span>
            </div>
          </div>
        </div>
        <div className={"flex gap-2 flex-wrap"}>
          <SlackButtonPreview
            emoji="paperclip"
            text={"Part of this incident"}
          />
          <SlackButtonPreview emoji="-1" text={"Unrelated to incident"} />
          <SlackButtonPreview
            emoji="rotating_light"
            text={"Manage pending alerts"}
          />
        </div>
      </div>
    </SlackMessagePreview>
  );
};

const DEBOUNCE_WINDOW_OPTIONS = [
  {
    label: "10 minutes",
    sort_key: "2",
    value: "600",
  },
  {
    label: "15 minutes",
    sort_key: "3",
    value: "900",
  },
  {
    label: "20 minutes",
    sort_key: "4",
    value: "1200",
  },
  {
    label: "30 minutes",
    sort_key: "5",
    value: "1800",
  },
  {
    label: "1 hour",
    sort_key: "6",
    value: "3600",
  },
  {
    label: "3 hours",
    sort_key: "7",
    value: "10800",
  },
  {
    label: "24 hours",
    sort_key: "8",
    value: "86400",
  },
];
