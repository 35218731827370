import React, { createContext, useContext, useRef } from "react";

type DrawerContextT = {
  // pageDrawerRef is our main ref used by the Drawer component
  // it renders full height over the page header
  pageDrawerRef: React.RefObject<HTMLDivElement>;
  // pageDrawerRefRight and pageDrawerRefLeft are legacy to support
  // the InPageDrawer which is deprecated - we'll switch these to our
  // Drawer component and remove these refs in future.
  pageDrawerRefRight: React.RefObject<HTMLDivElement>;
  pageDrawerRefLeft: React.RefObject<HTMLDivElement>;
};

const defaultValues = {
  pageDrawerRefRight: React.createRef<HTMLDivElement>(),
  pageDrawerRefLeft: React.createRef<HTMLDivElement>(),
  pageDrawerRef: React.createRef<HTMLDivElement>(),
};
export const DrawerContext = createContext<DrawerContextT>(defaultValues);

export function DrawerProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const pageDrawerRefLeft = useRef<HTMLDivElement>(null);
  const pageDrawerRefRight = useRef<HTMLDivElement>(null);
  const pageDrawerRef = useRef<HTMLDivElement>(null);

  return (
    <DrawerContext.Provider
      value={{
        pageDrawerRefLeft,
        pageDrawerRefRight,
        pageDrawerRef,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}

export const useDrawer = (): DrawerContextT => {
  return useContext(DrawerContext);
};
