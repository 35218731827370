import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import {
  GenericStatusBadge,
  GenericStatusBadgeEnum,
} from "@incident-ui/Badge/GenericStatusBadge";
import { InputType } from "@incident-ui/Input/Input";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  ScopeNameEnum,
  StatusPage,
  StatusPagePageTypeEnum,
  StatusPageUpdateCustomDomainRequestBody,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

export const CustomDomainSettingsEditPage = ({
  page,
}: {
  page: StatusPage;
}): React.ReactElement | null => {
  const isParentPage = page.component_catalog_type_id;
  const isCustomerPage = page.page_type === StatusPagePageTypeEnum.Customer;

  return (
    <>
      <div className="bg-surface-secondary rounded-[6px] p-4 border border-stroke space-y-4">
        <div className="space-y-2">
          <h3 className="text-base font-medium">Custom domain</h3>
          <FormHelpTextV2 className="text-slate-700 text-sm">
            Configure a custom domain to host your status page on any domain you
            own.{" "}
            {isParentPage &&
              `Your ${
                isCustomerPage ? "customer pages" : "sub-pages"
              } will be hosted on subpaths of this domain.`}
          </FormHelpTextV2>
        </div>

        <div className="divide-y divide-stroke">
          <SetCustomDomainForm page={page} />
          <CustomDomainView page={page} />

          <Verify page={page} />
        </div>
      </div>
    </>
  );
};

const SetCustomDomainForm = ({
  page,
}: {
  page: StatusPage;
}): React.ReactElement => {
  const formMethods = useForm<StatusPageUpdateCustomDomainRequestBody>({
    defaultValues: {
      custom_domain: page.custom_domain,
    },
  });
  const { hasScope } = useIdentity();

  const {
    trigger: onSaveDomain,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, data: StatusPageUpdateCustomDomainRequestBody) => {
      await apiClient.statusPageUpdateCustomDomain({
        id: page.id,
        updateCustomDomainRequestBody: {
          custom_domain: data.custom_domain,
        },
      });
    },
    {
      setError: formMethods.setError,
    },
  );

  return (
    <FormV2
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSaveDomain}
      saving={saving}
      innerClassName="pb-4"
    >
      <h4 className="font-medium text-sm">1. Add your custom domain</h4>

      <div className="flex justify-between items-start gap-3">
        <InputV2
          formMethods={formMethods}
          name="custom_domain"
          type={InputType.Text}
          placeholder="status.example.com"
          className="grow"
          required
          disabled={page.custom_domain !== undefined}
          inputClassName="!bg-white"
        />
        {page.custom_domain === undefined ? (
          <GatedButton
            type="submit"
            className="mt-0.5"
            analyticsTrackingId="save-custom-domain"
            theme={ButtonTheme.Primary}
            loading={saving}
            disabled={!hasScope(ScopeNameEnum.StatusPagesConfigure)}
            disabledTooltipContent={
              "You do not have permission to configure this public status page"
            }
          >
            Save
          </GatedButton>
        ) : (
          <DeleteButton page={page} />
        )}
      </div>
    </FormV2>
  );
};

const CustomDomainView = ({ page }: { page: StatusPage }) => {
  const isUnverified =
    page.custom_domain !== undefined &&
    page.custom_domain_verified_at === undefined;

  return (
    <div className={tcx("space-y-4 py-4", isUnverified ? "" : "opacity-50")}>
      <h4 className="font-medium text-sm">2. Configure DNS records</h4>

      {isUnverified && (
        <>
          <FormHelpTextV2>
            You can configure these in your DNS provider, for example Cloudflare
            or AWS Route 53.
          </FormHelpTextV2>

          <div className="bg-white p-4 border border-slate-100 rounded space-y-4 text-sm">
            <p>
              To serve your page at{" "}
              <span className="font-medium">{page.custom_domain}</span> you must
              add these DNS records.
            </p>
            <Callout theme={CalloutTheme.Warning}>
              If you&apos;re using Cloudflare, be careful to create these
              records in &lsquo;DNS-only&rsquo; mode, not proxy mode.
            </Callout>
            <div className="bg-surface-secondary border border-stroke rounded">
              <table className="text-slate-600 text-sm table-auto">
                <tbody className="divide-y divide-stroke">
                  {page.custom_domain_records.map((record, idx) => (
                    <tr key={idx}>
                      <td className="font-mono pl-4 pr-2 py-4">
                        {record.type}
                      </td>
                      <td className="font-mono px-2 py-4">{record.domain}</td>
                      <td className="font-mono pl-2 pr-4 py-4">
                        {record.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Verify = ({ page }: { page: StatusPage }) => {
  const { trigger: verifyDomain, isMutating: verifyingDomain } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient) => {
      await apiClient.statusPageCheckCustomDomain({
        id: page.id,
      });
    },
  );
  const isVerified = page.custom_domain_verified_at;

  return (
    <div
      className={tcx(
        "space-y-2 pt-4",
        page.custom_domain !== undefined ? "" : "opacity-50",
      )}
    >
      <h4 className="font-medium text-sm">3. Verify your configuration</h4>
      {page.custom_domain !== undefined && (
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <GenericStatusBadge
              status={
                isVerified
                  ? GenericStatusBadgeEnum.Completed
                  : GenericStatusBadgeEnum.Error
              }
            />
            <p className="text-content-primary text-sm">
              {isVerified
                ? "Domain configured successfully"
                : "Domain is pending verification"}
            </p>
          </div>
          <Button
            analyticsTrackingId="check-custom-domain"
            theme={ButtonTheme.Primary}
            onClick={() => verifyDomain({})}
            loading={verifyingDomain}
            disabled={page.custom_domain === undefined}
          >
            Check
          </Button>
        </div>
      )}
    </div>
  );
};

const DeleteButton = ({ page }: { page: StatusPage }) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState<boolean>(false);

  const { trigger: onDeleteDomain, isMutating: deletingDomain } =
    useAPIMutation("statusPageShow", { id: page.id }, async (apiClient, _) => {
      await apiClient.statusPageDeleteCustomDomain({
        id: page.id,
      });
    });

  if (page.custom_domain === undefined) return null;

  return (
    <>
      {showDeleteConfirmationModal && (
        <DeleteConfirmationModal
          page={page}
          onSubmit={() => onDeleteDomain({})}
          onClose={() => setShowDeleteConfirmationModal(false)}
        />
      )}
      <Button
        analyticsTrackingId="clear-custom-domain"
        className="mt-0.5 mr-2"
        theme={ButtonTheme.DestroySecondary}
        onClick={() => setShowDeleteConfirmationModal(true)}
        loading={deletingDomain}
      >
        Delete
      </Button>
    </>
  );
};

const DeleteConfirmationModal = ({
  onClose,
  onSubmit,
  page,
}: {
  onSubmit: () => void;
  onClose: () => void;
  page: StatusPage;
}): React.ReactElement => {
  return (
    <Modal
      isOpen
      title="Delete custom domain"
      analyticsTrackingId="status-page-delete-custom-domain-modal"
      as="form"
      onClose={onClose}
      onSubmit={() => {
        onSubmit();
        onClose();
      }}
      disableQuickClose={false}
    >
      <ModalContent className="space-y-4">
        <Callout theme={CalloutTheme.Warning}>
          Deleting your custom domain means your status page will no longer be
          hosted where your users expect.
        </Callout>
        <p>
          Are you sure you want to delete the{" "}
          <span className="font-medium">{page.custom_domain}</span> domain?
        </p>
        <p>
          Your status page will no longer be hosted on the domain you set up.
          You will have to reconfigure a domain to host your status page.
        </p>
      </ModalContent>
      <ModalFooter
        confirmButtonType="button"
        onClose={onClose}
        onConfirm={() => {
          onSubmit();
          onClose();
        }}
        confirmButtonText="Delete my custom domain"
      />{" "}
    </Modal>
  );
};
