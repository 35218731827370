/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ManagementMeta,
    ManagementMetaFromJSON,
    ManagementMetaFromJSONTyped,
    ManagementMetaToJSON,
} from './ManagementMeta';
import {
    ScheduleConfig,
    ScheduleConfigFromJSON,
    ScheduleConfigFromJSONTyped,
    ScheduleConfigToJSON,
} from './ScheduleConfig';
import {
    ScheduleEntry,
    ScheduleEntryFromJSON,
    ScheduleEntryFromJSONTyped,
    ScheduleEntryToJSON,
} from './ScheduleEntry';
import {
    ScheduleHolidaysPublicConfig,
    ScheduleHolidaysPublicConfigFromJSON,
    ScheduleHolidaysPublicConfigFromJSONTyped,
    ScheduleHolidaysPublicConfigToJSON,
} from './ScheduleHolidaysPublicConfig';

/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * Whether we should add the bot to the mirrored user group
     * @type {boolean}
     * @memberof Schedule
     */
    add_bot_to_mirrored_user_group?: boolean;
    /**
     * 
     * @type {ScheduleConfig}
     * @memberof Schedule
     */
    config?: ScheduleConfig;
    /**
     * 
     * @type {Date}
     * @memberof Schedule
     */
    created_at: Date;
    /**
     * Shifts that are on-going for this schedule, if a native schedule
     * @type {Array<ScheduleEntry>}
     * @memberof Schedule
     */
    current_shifts?: Array<ScheduleEntry>;
    /**
     * ID of the pay config connected with this schedule
     * @type {string}
     * @memberof Schedule
     */
    default_pay_config_id?: string;
    /**
     * If set, the schedule description synced from the external provider
     * @type {string}
     * @memberof Schedule
     */
    description?: string;
    /**
     * Name of external provider, e.g. pagerduty
     * @type {string}
     * @memberof Schedule
     */
    external_provider: ScheduleExternalProviderEnum;
    /**
     * ID of existing schedule in external system
     * @type {string}
     * @memberof Schedule
     */
    external_provider_id: string;
    /**
     * 
     * @type {ScheduleHolidaysPublicConfig}
     * @memberof Schedule
     */
    holidays_public_config?: ScheduleHolidaysPublicConfig;
    /**
     * Unique internal ID of the schedule
     * @type {string}
     * @memberof Schedule
     */
    id: string;
    /**
     * 
     * @type {ManagementMeta}
     * @memberof Schedule
     */
    management_meta?: ManagementMeta;
    /**
     * Slack ID for a Slack user group that we will mirror to
     * @type {string}
     * @memberof Schedule
     */
    mirrored_slack_user_group_id?: string;
    /**
     * Human readable name synced from external provider
     * @type {string}
     * @memberof Schedule
     */
    name: string;
    /**
     * Timestamp schedule entries are synced until
     * @type {Date}
     * @memberof Schedule
     */
    synced_until?: Date;
    /**
     * Timezone of the schedule, as interpreted at the point of generating the report
     * @type {string}
     * @memberof Schedule
     */
    timezone: string;
    /**
     * 
     * @type {Date}
     * @memberof Schedule
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum ScheduleExternalProviderEnum {
    Native = 'native',
    Pagerduty = 'pagerduty',
    Opsgenie = 'opsgenie'
}

export function ScheduleFromJSON(json: any): Schedule {
    return ScheduleFromJSONTyped(json, false);
}

export function ScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Schedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'add_bot_to_mirrored_user_group': !exists(json, 'add_bot_to_mirrored_user_group') ? undefined : json['add_bot_to_mirrored_user_group'],
        'config': !exists(json, 'config') ? undefined : ScheduleConfigFromJSON(json['config']),
        'created_at': (new Date(json['created_at'])),
        'current_shifts': !exists(json, 'current_shifts') ? undefined : ((json['current_shifts'] as Array<any>).map(ScheduleEntryFromJSON)),
        'default_pay_config_id': !exists(json, 'default_pay_config_id') ? undefined : json['default_pay_config_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'external_provider': json['external_provider'],
        'external_provider_id': json['external_provider_id'],
        'holidays_public_config': !exists(json, 'holidays_public_config') ? undefined : ScheduleHolidaysPublicConfigFromJSON(json['holidays_public_config']),
        'id': json['id'],
        'management_meta': !exists(json, 'management_meta') ? undefined : ManagementMetaFromJSON(json['management_meta']),
        'mirrored_slack_user_group_id': !exists(json, 'mirrored_slack_user_group_id') ? undefined : json['mirrored_slack_user_group_id'],
        'name': json['name'],
        'synced_until': !exists(json, 'synced_until') ? undefined : (new Date(json['synced_until'])),
        'timezone': json['timezone'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function ScheduleToJSON(value?: Schedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'add_bot_to_mirrored_user_group': value.add_bot_to_mirrored_user_group,
        'config': ScheduleConfigToJSON(value.config),
        'created_at': (value.created_at.toISOString()),
        'current_shifts': value.current_shifts === undefined ? undefined : ((value.current_shifts as Array<any>).map(ScheduleEntryToJSON)),
        'default_pay_config_id': value.default_pay_config_id,
        'description': value.description,
        'external_provider': value.external_provider,
        'external_provider_id': value.external_provider_id,
        'holidays_public_config': ScheduleHolidaysPublicConfigToJSON(value.holidays_public_config),
        'id': value.id,
        'management_meta': ManagementMetaToJSON(value.management_meta),
        'mirrored_slack_user_group_id': value.mirrored_slack_user_group_id,
        'name': value.name,
        'synced_until': value.synced_until === undefined ? undefined : (value.synced_until.toISOString()),
        'timezone': value.timezone,
        'updated_at': (value.updated_at.toISOString()),
    };
}

