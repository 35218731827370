import { Mode } from "@incident-shared/forms/v2/formsv2";
import {
  Button,
  ButtonTheme,
  FloatingFooter,
  IconEnum,
  Steps,
} from "@incident-ui";
import { StepConfig } from "@incident-ui/Steps/Steps";
import { TabBar, TabSection } from "@incident-ui/TabSection/TabSection";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { useQueryParams } from "src/utils/query-params";
import { tcx } from "src/utils/tailwind-classes";

import {
  AlertSourceStep,
  AlertSourceStepEnum,
  ContinueButton,
  stepConfig,
} from "./AlertSourceCreateEditPage";

export const AlertSourceSplitLayout = ({
  step,
  mode,
  alertSourceId,
  setCurrentTab,
  left,
  right,
  onSuggestChanges,
}: {
  step: AlertSourceStepEnum;
  mode: Mode;
  alertSourceId: string;
  setCurrentTab: (tabId: string) => void;
  left: React.ReactNode;
  right: React.ReactNode;
  onSuggestChanges?: () => Promise<void>;
}) => {
  return (
    <div className={"flex flex-col lg:flex-row h-full"}>
      {/* Left Panel: Set-up components */}
      <div className={"flex flex-col px-8 py-6 h-full w-full lg:w-[50%]"}>
        <div className="w-full mb-6">
          <AlertSourceStepsOrTabs
            setCurrentTab={setCurrentTab}
            step={step}
            mode={mode}
            className="grow"
          />
        </div>
        <div className="overflow-y-auto grow">{left}</div>
        <AlertSourceFooter
          stepId={step}
          mode={mode}
          alertSourceId={alertSourceId}
          onSuggestChanges={onSuggestChanges}
        />
      </div>
      {right}
    </div>
  );
};

export const AlertSourceStepsOrTabs = ({
  step,
  mode,
  setCurrentTab,
  className,
}: {
  step: AlertSourceStepEnum;
  mode: Mode;
  setCurrentTab: (tabId: string) => void;
  className?: string;
}) => {
  if (mode === Mode.Edit) {
    const tabsList = stepConfig.filter(
      (t) => t.id !== AlertSourceStepEnum.Create,
    );

    return (
      <TabSection
        rootClassName="h-full"
        tabs={stepConfig}
        withIndicator
        onTabChange={(tabId) => {
          setCurrentTab(tabId);
        }}
        value={step}
        hideTabBar
      >
        <TabBar
          tabs={tabsList}
          withIndicator
          tabBarClassName={tcx(
            "sticky mb-6 border-b !border-1 border-stroke bg-white font-normal space-x-4 top-0 z-10",
          )}
          rootClassName="flex flex-col space-y-0 grow overflow-y-hidden"
        />
      </TabSection>
    );
  }

  return (
    <Steps
      steps={stepConfig as unknown as StepConfig<AlertSourceStepEnum>[]}
      currentStep={step}
      hideNumbers
      containerClassName={tcx("max-w-[640px] mx-auto font-normal", className)}
    />
  );
};

const AlertSourceFooter = ({
  stepId,
  mode,
  alertSourceId,
  onSuggestChanges,
}: {
  stepId: AlertSourceStepEnum;
  mode: Mode;
  alertSourceId: string;
  onSuggestChanges?: () => Promise<void>;
}) => {
  const queryParams = useQueryParams();
  const from = queryParams.get("from");

  const getStepUrl = (stepId: AlertSourceStepEnum) => {
    const fromQueryString = from ? `&from=${from}` : "";

    return mode === Mode.Edit
      ? `/alerts/sources/${alertSourceId}/edit?step=${stepId}${fromQueryString}`
      : `/alerts/sources/create?step=${stepId}&id=${alertSourceId}${fromQueryString}`;
  };

  const step = stepConfig.find((s) => s.id === stepId);

  const { featureAlertSuggestions } = useFlags();
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);

  return (
    <FloatingFooter>
      {featureAlertSuggestions ? (
        <Button
          analyticsTrackingId="alert-source-suggest-changes"
          theme={ButtonTheme.Secondary}
          icon={IconEnum.MagicWand}
          className="mr-auto"
          disabled={onSuggestChanges ? false : true}
          loading={suggestionsLoading}
          onClick={() => {
            if (!onSuggestChanges) {
              return;
            }
            setSuggestionsLoading(true);
            onSuggestChanges().finally(() => {
              setSuggestionsLoading(false);
            });
          }}
        >
          Make suggestions
        </Button>
      ) : null}
      {/* Show back button on configure step */}
      {stepId === AlertSourceStepEnum.Configure ? (
        <Button
          analyticsTrackingId="alert-source-create-back"
          theme={ButtonTheme.Secondary}
          href={getStepUrl(AlertSourceStepEnum.Connect)}
          className="mr-2"
        >
          Back
        </Button>
      ) : null}
      <ContinueButton
        step={step as AlertSourceStep}
        getStepUrl={getStepUrl}
        mode={mode}
        text={
          step?.id === AlertSourceStepEnum.Configure
            ? "Save"
            : "Save and continue"
        }
      />
    </FloatingFooter>
  );
};
