import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";

import { PathNode } from "../../common/types";
import { getNextNodeId } from "./getNextNodeId";

export const shouldDisplayWorkingHoursTimeToAck = (
  nodes: Record<string, PathNode>,
  node: PathNode,
  firstNodeId: string,
) => {
  const nextNodeId = getNextNodeId({ node });

  if (!nextNodeId) {
    // We cannot throw an error here, because
    // when you change a level node to a condition node, this component rerenders
    // and an error is throw here every time (even though the entire component is
    // about to be destroyed).
    return false;
  }
  const nextNode: PathNode = nodes[nextNodeId];

  // We only show the working hours time to ack option if this is the last level node in the
  // tree (on the branch). Meaning there's either no next node, or the next node is a repeat node.
  const lastLevelNode =
    !nextNode || nextNode.data.nodeType === NodeTypes.Repeat;
  if (!lastLevelNode) {
    return false;
  }

  // Helper function to determine if a node is in a branched path or not
  const isBranchedNode = (
    currentNodeId: string,
    targetNode: PathNode,
    isBranched: boolean,
  ) => {
    const currentNode = nodes[currentNodeId];

    // Base case: we've found the node we're looking for, and have travered all nodes
    // in the path before it and know if it's branched or not
    if (currentNode.id === targetNode.id) {
      return isBranched;
    }

    switch (currentNode.data.nodeType) {
      case NodeTypes.Repeat:
        return false;
      case NodeTypes.Level:
        if (!currentNode.data.level) {
          throw new Error(
            "Unreachable: level node must have a level data field",
          );
        }
        // If we've found a level node that's at the end of a branch, and which doesn't
        // have a next node, then we know it's not branched, since we've looked
        // through the entire tree and not found the node we were looking for.
        if (!currentNode.data.level.nextNodeId) {
          return false;
        }

        return isBranchedNode(
          currentNode.data.level.nextNodeId,
          targetNode,
          isBranched,
        );
      case NodeTypes.NotifyChannel:
        if (!currentNode.data.notifyChannel) {
          throw new Error(
            "Unreachable: notifyChannel node must have a notifyChannel data field",
          );
        }

        if (!currentNode.data.notifyChannel.nextNodeId) {
          return false;
        }

        return isBranchedNode(
          currentNode.data.notifyChannel.nextNodeId,
          targetNode,
          isBranched,
        );
      case NodeTypes.IfElse:
        if (!currentNode.data.ifElse) {
          throw new Error(
            "Unreachable: condition node must have a if_else data field",
          );
        }

        const thenPath = isBranchedNode(
          currentNode.data.ifElse.thenNodeId,
          targetNode,
          true,
        );

        const elsePath = isBranchedNode(
          currentNode.data.ifElse.elseNodeId,
          targetNode,
          true,
        );

        return thenPath || elsePath;

      default:
        throw new Error(
          "Unreachable: reached end of path without finding node",
        );
    }
  };

  // We show the working hours time to ack options if the next node is a repeat node
  // and if the node is in a branched path.
  return isBranchedNode(firstNodeId, node, false) && lastLevelNode;
};
