import { OrgAwareNavLink } from "@incident-shared/org-aware";
import { UpsellBadge } from "@incident-shared/settings";
import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { useProductAccess } from "src/hooks/useProductAccess";
import { tcx } from "src/utils/tailwind-classes";

import { SecondaryNavButton, SecondaryNavItemType } from "./SecondaryNav";

export const SecondaryNavItem = ({
  pathPrefix,
  item,
  onClick,
}: {
  pathPrefix: string;
  item: SecondaryNavItemType | SecondaryNavButton;
  onClick?: () => void;
}): React.ReactElement => {
  const { hasProduct } = useProductAccess();

  const isMissingProduct = item.requiredProduct
    ? !hasProduct(item.requiredProduct)
    : false;

  const ItemContent = ({
    isActive,
    isMissingProduct,
  }: {
    isActive: boolean;
    isMissingProduct: boolean;
  }) => {
    return (
      <div className="flex items-center gap-2 grow rounded-full">
        {item.icon && (
          <div className="pl-3">
            <Icon
              id={item.icon}
              className={
                isActive ? "text-alarmalade-500" : "text-content-secondary"
              }
            />
          </div>
        )}
        <span
          className={tcx(
            isActive ? "" : "text-content-secondary",
            "grow truncate text-sm-med",
            !item.icon ? "pl-3" : "",
          )}
        >
          {item.label}
        </span>

        <div className="grow"></div>

        <div className="mr-3">
          {isMissingProduct ? (
            <Icon
              id={IconEnum.LockClosed}
              size={IconSize.Small}
              className="text-content-tertiary"
            />
          ) : (
            <UpsellBadge
              isPro={item.isPro}
              isEnterprise={item.isEnterprise}
              newUntil={item.newUntil}
            />
          )}
        </div>
      </div>
    );
  };

  const activeStyles = "bg-surface-tertiary font-medium text-content-primary";
  const itemStyles =
    "!flex py-2 text-left items-center gap-2 justify-start text-sm w-full rounded-2 text-content-secondary hover:bg-surface-tertiary min-w-[200px]";

  return item.type === "SecondaryNavButton" ? (
    <Button
      className={tcx(itemStyles, {
        [activeStyles]: item.active,
      })}
      onClick={item.onClick}
      theme={ButtonTheme.Naked}
      analyticsTrackingId={null}
    >
      <ItemContent isActive={item.active} isMissingProduct={isMissingProduct} />
    </Button>
  ) : (
    <OrgAwareNavLink
      to={`/${pathPrefix}/${item.slug}`}
      onClick={onClick}
      className={({ isActive }) =>
        tcx(
          itemStyles,
          "text-content-tertiary hover:!text-content-primary transition hover:!no-underline",
          {
            [activeStyles]: isActive,
          },
        )
      }
      data-intercom-target={`${pathPrefix}-nav-${item.slug}`}
    >
      {({ isActive }) => (
        <ItemContent
          isActive={isActive}
          isMissingProduct={isMissingProduct || false}
        />
      )}
    </OrgAwareNavLink>
  );
};
