import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { useFormContext } from "react-hook-form";
import { useNodeFormErrors } from "src/components/escalation-paths/nodes/useNodeFormErrors";

import { EscalationPathFormData, PathNode } from "../common/types";
import { deleteNode } from "../node-editor/helpers/deleteNode";
import { getConditionCount } from "../node-editor/helpers/getNodeCount";
import { NodeCard } from "./NodeCard";

export const DONT_REPEAT_VALUE = "dont_repeat";

// NodeRepeat is used for all Repeat nodes in an escalation path, and is a wrapper around the box element.
export const NodeRepeat = ({ id }: { id: string }) => {
  const formMethods = useFormContext<EscalationPathFormData>();

  const icon = (
    <div className="bg-purple-50 rounded-2 w-10 h-10 flex items-center justify-center">
      <Icon
        id={IconEnum.Loop}
        size={IconSize.Medium}
        className="text-purple-500 bg-purple-50 -scale-x-100"
      />
    </div>
  );

  const relatedErrors = useNodeFormErrors({
    formMethods,
    id,
    nodeType: NodeTypes.Repeat,
  });

  const nodes = formMethods.watch("nodes");
  const firstNodeId = formMethods.watch("firstNodeId");

  if (nodes[id].data.nodeType !== NodeTypes.Repeat) {
    throw new Error("NodeRepeat must be used with a Repeat node");
  }

  if (!nodes[id].data.repeat) {
    throw new Error("Unreachable: repeat nodes must have repeat data");
  }

  const showRepeatTimes = nodes[id].data.repeat?.to_node !== DONT_REPEAT_VALUE;

  const repeatOptions = getRepeatOptions(nodes, id, firstNodeId);

  const repeatFromEntries = repeatOptions.map((option) => {
    const isFirst = option === firstNodeId;
    const label = isFirst
      ? "From start"
      : `From condition ${getConditionCount(nodes, firstNodeId, option)}`;

    return {
      value: option,
      label,
      icon: isFirst ? IconEnum.Flag : IconEnum.GitBranch,
    };
  });

  const dontRepeatOption = {
    label: "Don't repeat",
    value: DONT_REPEAT_VALUE,
    icon: IconEnum.Stop,
  };

  const onDeleteNode = () => {
    deleteNode({
      nodeId: id,
      nodes: formMethods.getValues().nodes,
      firstNodeId: formMethods.getValues().firstNodeId,
      updateNodes: (nodes: Record<string, PathNode>) => {
        formMethods.setValue<"nodes">("nodes", nodes);
      },
      updateFirstNodeId: (firstNodeId: string) => {
        formMethods.setValue<"firstNodeId">("firstNodeId", firstNodeId);
      },
    });
  };

  return (
    <NodeCard
      nodeId={id}
      title={<div className="text-xs text-slate-600">Retry</div>}
      subtitle="If still unacknowledged, retry"
      icon={icon}
      allowAddAbove={false}
      allowAddBelow={false}
      hideDeleteButton={false}
      onDeleteNode={onDeleteNode}
    >
      <div className="flex items-center space-x-2 mt-4">
        <PopoverSingleSelectV2
          options={[dontRepeatOption, ...repeatFromEntries]}
          sideOffset={1}
          name={`nodes.${id}.data.repeat.to_node`}
          formMethods={formMethods}
          renderTriggerNode={({ onClick, selectedOption }) => {
            return (
              <Button
                onClick={onClick}
                theme={ButtonTheme.Tertiary}
                analyticsTrackingId={null}
                title={selectedOption?.label || "Select repeat from"}
                size={ButtonSize.Small}
              >
                {selectedOption?.icon ? (
                  <Icon
                    id={selectedOption?.icon}
                    size={IconSize.Small}
                    className="fill-slate-500"
                  />
                ) : null}{" "}
                {selectedOption?.label || "Select repeat from"}
              </Button>
            );
          }}
        />
        {showRepeatTimes && (
          <PopoverSingleSelectV2
            options={repeatTimesEntries}
            sideOffset={1}
            name={`nodes.${id}.data.repeat.repeat_times`}
            formMethods={formMethods}
            renderTriggerNode={({ onClick, selectedOption }) => (
              <Button
                onClick={onClick}
                theme={ButtonTheme.Tertiary}
                analyticsTrackingId={null}
                title={
                  selectedOption?.label || "Select number of times to repeat"
                }
                size={ButtonSize.Small}
              >
                {selectedOption?.icon ? (
                  <Icon
                    id={selectedOption?.icon}
                    size={IconSize.Small}
                    className="fill-slate-500"
                  />
                ) : null}{" "}
                {selectedOption?.label || "Select number of times to repeat"}
              </Button>
            )}
          />
        )}

        {relatedErrors.length > 0 ? (
          <ErrorMessageUI message={relatedErrors[0]} className={"text-xs"} />
        ) : null}
      </div>
    </NodeCard>
  );
};

// You can either repeat from the start of the escalation path, or from the current branch.
const getRepeatOptions = (
  nodes: Record<string, PathNode>,
  id: string,
  firstNodeId: string,
) => {
  const candidates: string[] = [firstNodeId];

  // Helper function to check if a node is a parent of the repeat node.
  const isParent = (candidate: string) => {
    const node = nodes[candidate];
    switch (node.data.nodeType) {
      case NodeTypes.Repeat:
        // A repeat node is never a parent node
        return false;
      case NodeTypes.IfElse:
        // Either the ifelse node is the parent, or either the first node
        // in either branch is a parent node (not both, because the node
        // is either in the branch or not)
        if (node.data.ifElse) {
          return (
            node.data.ifElse.thenNodeId === id ||
            node.data.ifElse.elseNodeId === id ||
            isParent(node.data.ifElse.thenNodeId) ||
            isParent(node.data.ifElse.elseNodeId)
          );
        }
        return false;
      case NodeTypes.Level:
        // Either the level node is the parent, or the next node is a parent
        if (node.data.level) {
          return (
            node.data.level.nextNodeId === id ||
            (node.data.level.nextNodeId && isParent(node.data.level.nextNodeId))
          );
        }
        return false;
      case NodeTypes.NotifyChannel:
        // Either the channel node is the parent, or the next node is a parent
        if (node.data.notifyChannel) {
          return (
            node.data.notifyChannel.nextNodeId === id ||
            (node.data.notifyChannel.nextNodeId &&
              isParent(node.data.notifyChannel.nextNodeId))
          );
        }
        return false;
    }
    return false;
  };

  // Helper function to iterate through the nodes and find nodes which
  // are eligible to repeat from.
  const findRepeatCandidates = (id: string) => {
    const node = nodes[id];

    if (isParent(id) && !candidates.includes(id)) {
      if (node.data.nodeType === NodeTypes.IfElse) {
        candidates.push(id);
      }
    }

    switch (node.data.nodeType) {
      case NodeTypes.Repeat:
        return;
      case NodeTypes.IfElse:
        if (node.data.ifElse) {
          findRepeatCandidates(node.data.ifElse.thenNodeId);
          findRepeatCandidates(node.data.ifElse.elseNodeId);
        }
        return;
      case NodeTypes.Level:
        if (node.data.level && node.data.level.nextNodeId) {
          findRepeatCandidates(node.data.level.nextNodeId);
        }
        return;
      case NodeTypes.NotifyChannel:
        if (node.data.notifyChannel && node.data.notifyChannel.nextNodeId) {
          findRepeatCandidates(node.data.notifyChannel.nextNodeId);
        }
        return;
    }
  };

  findRepeatCandidates(firstNodeId);

  return candidates;
};

// repeatValues is a list of options for the repeat times select input.
const repeatTimesEntries = [
  {
    value: "1",
    label: "1 time",
    icon: IconEnum.RepeatOnce,
  },
  {
    value: "2",
    label: "2 times",
    icon: IconEnum.RepeatOnce,
  },
  {
    value: "3",
    label: "3 times",
    icon: IconEnum.RepeatOnce,
  },
];
