import {
  Avatar,
  ButtonTheme,
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  IconSize,
  Link,
  Loader,
  Markdown,
} from "@incident-ui";
import { sortBy } from "lodash";
import React from "react";
import { Action, ActionStatusEnum } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { followUpAnchorPrefix, getAnchorId } from "../../../../utils/anchor";
import { EmptyTab } from "../EmptyTab";
import styles from "./Actions.module.scss";
import { ActionStatus } from "./ActionStatus";

export type Props = {
  actions: Action[];
  isClosed: boolean;
  onEdit: (action: Action) => void;
  onDelete: (action: Action) => void;
  onConvertToFollowUp: (action: Action) => void;
  roundedTop?: boolean;
};

export function ActionsTable({
  actions = [],
  isClosed,
  onEdit,
  onDelete,
  onConvertToFollowUp,
  roundedTop = true,
}: Props): React.ReactElement {
  if (actions == null) {
    return <Loader />;
  }

  if (actions.length === 0) {
    return (
      <EmptyTab
        icon={IconEnum.Action}
        subtitle={
          isClosed ? (
            <>
              There are no actions for this incident. Learn more in our{" "}
              <Link
                openInNewTab
                href="https://help.incident.io/en/articles/6857880"
                analyticsTrackingId="actions-table-help-center"
              >
                help center
              </Link>
              .
            </>
          ) : (
            `You haven't created any actions yet.`
          )
        }
      />
    );
  }

  return (
    <DeprecatedTable className={styles.table} roundedTop={roundedTop}>
      <colgroup>
        <col className={styles.descriptionCol} />
        <col className={styles.ownerCol} />
        <col className={styles.statusCol} />
        <col />
      </colgroup>
      <DeprecatedTableHeaderRow>
        <DeprecatedTableHeaderCell>Description</DeprecatedTableHeaderCell>
        <DeprecatedTableHeaderCell>Owner</DeprecatedTableHeaderCell>
        <DeprecatedTableHeaderCell>Status</DeprecatedTableHeaderCell>
        <DeprecatedTableHeaderCell textHidden>
          More options
        </DeprecatedTableHeaderCell>
      </DeprecatedTableHeaderRow>
      <tbody>
        {actions.map((action) => {
          const anchorId = `${followUpAnchorPrefix}/${action.id}`;

          return (
            <tr
              key={action.id}
              className={tcx(
                "align-middle",
                getAnchorId() === anchorId ? styles.anchored : "",
              )}
              id={anchorId}
            >
              <td>
                <div className="flex items-center">
                  <Markdown>{action.description}</Markdown>
                </div>
              </td>
              <td>
                <span className={styles.assigneeCell}>
                  {action.assignee ? (
                    <>
                      <Avatar
                        size={IconSize.Large}
                        url={action.assignee.avatar_url}
                        name={action.assignee.name}
                        className="mr-2"
                      />
                      <span className={styles.assigneeName}>
                        {action.assignee.name}
                      </span>
                    </>
                  ) : (
                    <>&mdash;</>
                  )}
                </span>
              </td>
              <td>
                <span className={styles.statusCell}>
                  <ActionStatus status={action.status} />
                </span>
              </td>

              <td className={styles.menuCell}>
                <DropdownMenu
                  triggerButtonTheme={ButtonTheme.Unstyled}
                  analyticsTrackingId="action-more-options"
                  screenReaderText="More options"
                  triggerIcon={IconEnum.DotsVertical}
                >
                  <DropdownMenuItem
                    analyticsTrackingId="actions-list-edit"
                    label="Edit"
                    onSelect={() => onEdit(action)}
                  />
                  <DropdownMenuItem
                    analyticsTrackingId="actions-list-convert-to-follow-up"
                    label="Mark as follow-up"
                    onSelect={() => onConvertToFollowUp(action)}
                  />
                  <DropdownMenuItem
                    analyticsTrackingId="actions-list-delete-modal-open"
                    label="Delete"
                    onSelect={() => onDelete(action)}
                  />
                </DropdownMenu>
              </td>
            </tr>
          );
        })}
      </tbody>
    </DeprecatedTable>
  );
}

const orderedActionStatuses = [
  ActionStatusEnum.Outstanding,
  ActionStatusEnum.Completed,
  ActionStatusEnum.NotDoing,
  ActionStatusEnum.Deleted,
];

export const sortActions = (actions: Action[]): Action[] => {
  return sortBy(actions, (action) =>
    orderedActionStatuses.findIndex((x) => x === action.status),
  );
};
