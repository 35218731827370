import { Mode } from "@incident-shared/forms/v2/formsv2";
import { FadeInOut } from "@incident-shared/utils/FadeInOut";
import {
  Avatar,
  Button,
  ButtonTheme,
  Checkbox,
  ConfirmationDialog,
  Icon,
  IconEnum,
  IconSize,
  LocalDateTime,
  Spinner,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { SpinnerTheme } from "@incident-ui/Spinner/Spinner";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import {
  TimelineItemEnrichmentError,
  TimelineItemEnrichmentErrorProps,
} from "src/components/timeline/TimelineItemEnrichmentError";
import {
  EnrichedSlackMessage,
  Incident,
  IncidentStatusCategoryEnum,
  TimelineItem,
  TimelineItemComment,
  TimelineItemItemTypeEnum,
  TimelineItemItemTypeEnum as TimelineItemType,
  TimelineItemObject,
  TimelineItemsListResponseBody,
} from "src/contexts/ClientContext";
import { formatDurationShort, formatTimestampLocale } from "src/utils/datetime";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { CreateEditTimelineCustomEventModal } from "./CreateEditTimelineCustomEventModal";
import { CustomTimelineEventButtons } from "./CustomTimelineEventButtons";
import {
  TimelineItemRenderProps,
  TimelineItemTypeToComponentMap,
} from "./timeline-items/TimelineItem";
import { statusHasChanged } from "./timeline-items/TimelineItemIncidentUpdateComponent";
import { TimelineItemCommentList } from "./TimelineItemComment";
import styles from "./TimelineItems.module.scss";
import { TimelineElement } from "./TimelineUI";
import { WebTimelineNoteUI } from "./WebTimelineNoteUI";

type TimelineItemUIProps = TimelineElement & {
  onHide: (itemIds: string[]) => Promise<TimelineItemsListResponseBody>;
  isEditMode: boolean;
  incident: Incident;
  zoomImageSource: string | undefined;
  setZoomImageSource: (value: React.SetStateAction<string | undefined>) => void;
};

export const enrichmentError = (
  incident: Incident,
  item?: TimelineItem,
): TimelineItemEnrichmentErrorProps | undefined => {
  if (!item) {
    return undefined;
  }

  let msg: EnrichedSlackMessage | undefined;
  switch (item.item_type) {
    case TimelineItemType.SlackImage:
      msg = item.slack_image?.slack_message;
      break;
    case TimelineItemType.SlackPin:
      msg = item.slack_pin?.message;
      break;
    case TimelineItemType.SlackInferGithub:
      msg = item.slack_infer_github?.slack_message;
      break;
    case TimelineItemType.SlackInferSentry:
      msg = item.slack_infer_sentry?.slack_message;
      break;
  }

  if (msg?.enrichment_error) {
    return buildEnrichmentError(
      incident,
      item,
      msg?.enrichment_error,
      msg?.permalink,
    );
  }
  return undefined;
};

export const buildEnrichmentError = (
  incident: Incident,
  item: TimelineItem,
  message: string,
  permalink?: string,
): TimelineItemEnrichmentErrorProps => {
  return {
    incident,
    itemID: item.id,
    comments: item.comments,
    occured_at: item.occured_at,
    enrichment_error: message,
    permalink,
  };
};

export const TimelineItemUIWrapper = (
  props: TimelineItemUIProps,
): React.ReactElement | null => {
  if (!props.item && !props.element) {
    return null;
  }

  const enrichmentInfo = enrichmentError(props.incident, props.item);
  if (enrichmentInfo) {
    return <TimelineItemEnrichmentError {...enrichmentInfo} />;
  }

  const errorItem = props.item;
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => {
        if (!errorItem) {
          return <></>;
        }

        return (
          <TimelineItemEnrichmentError
            {...buildEnrichmentError(props.incident, errorItem, "fallback")}
            originatingError={error}
            componentStack={componentStack}
          />
        );
      }}
    >
      <TimelineItemUI {...props} />
    </Sentry.ErrorBoundary>
  );
};

// High level component which we can chuck a timeline item into and get back the
// right thing based on the item type.
export const TimelineItemUI = ({
  incident,
  item,
  zoomImageSource,
  setZoomImageSource,
  element,
  onHide,
  isEditMode,
  ...props
}: TimelineItemUIProps): React.ReactElement | null => {
  const errorProps = enrichmentError(incident, item);
  if (errorProps) {
    return <TimelineItemEnrichmentError {...errorProps} />;
  }

  if (element) {
    return element;
  }

  // Timeline notes are special in that they do not look like other items on the timeline,
  // and they are cannot be hidden. Therefore we have a special UI for them.
  // We don't want to show them when in Edit mode.
  if (item.item_type === TimelineItemType.TimelineNote) {
    return !isEditMode ? (
      <WebTimelineNoteUI
        incident={incident}
        item={item}
        comments={item?.comments}
        {...props}
      />
    ) : null;
  }

  const TimelineItemComponent = TimelineItemTypeToComponentMap[item.item_type];

  if (!TimelineItemComponent) {
    return null;
  }

  const constructorProps = TimelineItemComponent(
    incident,
    item,
    zoomImageSource,
    setZoomImageSource,
  );
  if (!constructorProps) {
    return null;
  }

  const isStatusUpdate =
    !item.is_stream &&
    (constructorProps.isStatusUpdate ||
      item.item_type === TimelineItemType.StatusChange ||
      (item.item_type === TimelineItemType.IncidentUpdate &&
        statusHasChanged(item.incident_update)));

  return (
    <TimelineItemUIInner
      incident={incident}
      timestamp={item.occured_at}
      onHide={() => onHide([item.id])}
      isEditMode={isEditMode}
      {...constructorProps}
      {...props}
      id={item.id}
      comments={item?.comments}
      isStatusUpdate={isStatusUpdate}
      item={item}
    />
  );
};

type TimelineItemUpdateProps = {
  onHide?: () => Promise<TimelineItemsListResponseBody>;
  isEditMode: boolean;
};

type TimelineItemUIInnerProps = TimelineItemRenderProps &
  TimelineItemUpdateProps & {
    id: string;
    comments?: TimelineItemComment[];
    isStatusUpdate: boolean;
    item?: TimelineItemObject; // The only reason we pass this is so that we can check if its a custom event, and provide some additional buttons if so.
    incident: Incident;
    streamId?: string; // ID of the stream if passed from a timeline item group
  };

enum CommentsExpanded {
  AutoCollapsed = "auto_collapsed",
  ManuallyCollapsed = "manually_collapsed",
  AutoExpanded = "auto_expanded",
  ManuallyExpanded = "manually_expanded",
}

const commentsAreExpanded = (commentsExpanded: CommentsExpanded): boolean => {
  return (
    commentsExpanded === CommentsExpanded.AutoExpanded ||
    commentsExpanded === CommentsExpanded.ManuallyExpanded
  );
};

export const TimelineItemUIInner = ({
  incident,
  id,
  children,
  description,
  avatarUrl,
  secondaryAvatarUrl,
  icon,
  timestamp,
  isEditMode,
  comments,
  isStatusUpdate,
  item,
  onHide,
}: TimelineItemUIInnerProps): React.ReactElement => {
  const start = timestamp ?? new Date();
  const showAbsoluteTimestamp =
    incident.incident_status.category === IncidentStatusCategoryEnum.Closed;

  // Show an absolute timestamp in certain scenarios like the incident being closed.
  // formatDurationShort will also show an absolute timestamp for really old stuff, because
  // durations like '1mo' or '2 days' aren't useful.
  const formatTime = (timestamp: Date): string => {
    return showAbsoluteTimestamp
      ? formatTimestampLocale({ timestamp: timestamp })
      : formatDurationShort(timestamp, new Date(), {
          max: {
            hours: 12,
          },
          suffix: "ago",
        });
  };

  const showToast = useToast();

  const [isEditingCustomEvent, setIsEditingCustomEvent] = useState(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);

  const { trigger: onDelete, isMutating: savingDelete } = useAPIMutation(
    "timelineItemsList",
    { incidentId: incident.id },
    async (apiClient, { id }: TimelineItemObject) => {
      await apiClient.timelineItemsDestroy({
        id,
      });
    },
    {
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: `We weren't able to delete this custom event`,
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const duration = formatTime(start);

  comments = comments || [];
  const [commentsExpanded, setCommentsExpanded] = useState(
    comments.length > 0
      ? CommentsExpanded.AutoExpanded
      : CommentsExpanded.AutoCollapsed,
  );

  useEffect(() => {
    if (
      commentsExpanded === CommentsExpanded.ManuallyCollapsed ||
      commentsExpanded === CommentsExpanded.ManuallyExpanded
    ) {
      return;
    }

    if (comments && comments.length > 0) {
      setCommentsExpanded(CommentsExpanded.AutoExpanded);
    } else {
      setCommentsExpanded(CommentsExpanded.AutoCollapsed);
    }
  }, [comments, commentsExpanded]);

  const isCustomEvent =
    item?.item_type === TimelineItemItemTypeEnum.CustomEvent;

  // Item might be associated with a stream of this incident, in which case we should
  // load it to display the details.
  const isStreamItem = item?.is_stream;
  const isStreamCreate =
    item?.incident_update?.previous_incident_status == null &&
    !item?.role_update;

  const {
    data: { stream },
  } = useAPI(
    isStreamItem ? "streamsShow" : null,
    {
      id: item?.incident_id ?? "",
    },
    { fallbackData: { stream: undefined } },
  );
  // The underlying url logic is the same as navigating to modals
  const navigateToDrawer = useNavigateToModal();

  return (
    <li
      className={tcx(
        styles.timelineItem,
        isStatusUpdate && styles.statusUpdate,
        !isEditMode ? "!mb-5" : "!mb-0 !py-2", // TODO: when we implement the new UI, let's use tailwind instead of the SCSS
      )}
    >
      {item && showDeleteConfirmationDialog && (
        <ConfirmationDialog
          title={"Delete custom event"}
          analyticsTrackingId="delete-timeline-custom-event"
          isOpen={showDeleteConfirmationDialog}
          onCancel={() => setShowDeleteConfirmationDialog(false)}
          onConfirm={() => {
            onDelete(item);
            setShowDeleteConfirmationDialog(false);
          }}
        >
          <div className="p-2">
            <p className="pb-1">
              Are you sure you want to delete this custom event?
            </p>
            {(item.comments.length && (
              <p>{item.comments.length + 1} comments will be deleted</p>
            )) ||
              null}
          </div>
        </ConfirmationDialog>
      )}
      <div className={tcx(styles.timelineItemHeader)}>
        <div className="flex ml-1.5">
          <Icon className={styles.icon} size={IconSize.Large} id={icon} />
          <div className={styles.itemDescription}>
            <div className="flex items-center gap-2">
              {!isEmpty(avatarUrl) ? (
                <div className={styles.avatar}>
                  <Avatar
                    size={IconSize.Large}
                    url={avatarUrl}
                    className="!w-8 !h-8"
                  />
                  {!isEmpty(secondaryAvatarUrl) && (
                    <Avatar
                      size={IconSize.Medium}
                      url={secondaryAvatarUrl}
                      className={`${styles.secondaryAvatar} border-2 border-white`}
                    />
                  )}
                </div>
              ) : null}
              <div className="flex flex-wrap gap-1">
                {description}
                {isStreamItem && stream && !isStreamCreate && (
                  <Button
                    theme={ButtonTheme.Unstyled}
                    className="ml-2 flex gap-1 items-center min-w-0 shrink max-w-64"
                    onClick={() =>
                      navigateToDrawer(`streams/${stream.external_id}`)
                    }
                    analyticsTrackingId="view-stream-from-timeline"
                  >
                    <Icon
                      size={IconSize.Small}
                      id={IconEnum.GitBranch}
                      className="text-content-secondary"
                    />
                    <div className="text-xs-med text-content-secondary truncate">
                      {stream.name}
                    </div>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center shrink-0">
          {timestamp && (
            <LocalDateTime
              timestamp={timestamp}
              showIcon
              className={
                "text-sm pr-2 text-content-tertiary hover:!text-slate-300"
              }
            >
              {duration}
            </LocalDateTime>
          )}
          {isCustomEvent && isEditMode && (
            <CustomTimelineEventButtons
              setIsEditingCustomEvent={setIsEditingCustomEvent}
              setShowDeleteConfirmationDialog={setShowDeleteConfirmationDialog}
              savingDelete={savingDelete}
            />
          )}
          {(!isEditMode || comments.length > 0) && (
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId="timeline-item-comment"
              title="Comment on item"
              icon={IconEnum.Message}
              iconProps={{
                size: IconSize.Large,
                className: "mr-0 group-hover:!text-slate-300",
              }}
              onClick={() => {
                let newCommentsExpanded;
                switch (commentsExpanded) {
                  case CommentsExpanded.AutoExpanded:
                    newCommentsExpanded = CommentsExpanded.ManuallyCollapsed;
                    break;
                  case CommentsExpanded.ManuallyExpanded:
                    newCommentsExpanded = CommentsExpanded.ManuallyCollapsed;
                    break;
                  case CommentsExpanded.AutoCollapsed:
                    newCommentsExpanded = CommentsExpanded.ManuallyExpanded;
                    break;
                  case CommentsExpanded.ManuallyCollapsed:
                    newCommentsExpanded = CommentsExpanded.ManuallyExpanded;
                    break;
                }

                setCommentsExpanded(newCommentsExpanded);
              }}
              disabled={isEditMode}
              className={
                isStatusUpdate
                  ? commentsAreExpanded(commentsExpanded) && !isEditMode
                    ? "text-slate-300 bg-slate-700 rounded-[6px]"
                    : "text-content-tertiary"
                  : commentsAreExpanded(commentsExpanded)
                  ? "text-content-tertiary bg-surface-secondary rounded-[6px]"
                  : "text-content-tertiary"
              }
            >
              {comments.length > 0 && <p className="mr-1">{comments.length}</p>}
            </Button>
          )}
          {isEditMode && !isCustomEvent && (
            <ToggleVisibilityButton
              onClick={onHide}
              lightTheme={isStatusUpdate}
              checked={true}
            />
          )}
        </div>
      </div>
      {children && (
        <div className={`${styles.itemContent} mt-4`}>{children}</div>
      )}
      <FadeInOut show={commentsAreExpanded(commentsExpanded) && !isEditMode}>
        <TimelineItemCommentList
          incident={incident}
          itemId={id}
          comments={comments}
          shouldFocus={commentsExpanded === CommentsExpanded.ManuallyExpanded}
        />
      </FadeInOut>
      {isEditingCustomEvent && (
        // @ts-expect-error: ts is complaining that we can't pass an undefined item, but this component actually _does_ expect a `TimelineItemObject` or undefined.
        <CreateEditTimelineCustomEventModal
          incident={incident}
          onClose={() => {
            setIsEditingCustomEvent(false);
          }}
          occurredAt={item?.occured_at}
          initialData={item}
          mode={Mode.Edit}
        />
      )}
    </li>
  );
};

export const ToggleVisibilityButton = ({
  onClick,
  lightTheme,
  checked,
}: {
  onClick: (() => Promise<TimelineItemsListResponseBody>) | undefined;
  lightTheme?: boolean;
  checked: boolean;
}): React.ReactElement | null => {
  const [loading, setLoading] = useState(false);
  if (!onClick) {
    return null;
  }
  if (loading) {
    return (
      <Spinner
        className="ml-2 mr-0"
        theme={lightTheme ? SpinnerTheme.White : SpinnerTheme.Slate}
      />
    );
  }
  return (
    <Tooltip
      analyticsTrackingId={null}
      content={
        checked ? (
          <p className="text-xs">Remove this item from the timeline</p>
        ) : (
          <p className="text-xs">Add this item to the timeline</p>
        )
      }
      side="top"
      delayDuration={300}
    >
      <Checkbox
        id="timeline-item-curation-toggle"
        className="!mr-0 !ml-2"
        checked={checked}
        onChange={() => {
          setLoading(true);
          onClick().finally(() => setLoading(false));
        }}
      />
    </Tooltip>
  );
};
