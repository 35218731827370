import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { SelectOption } from "@incident-ui/Select/types";
import { useFormContext } from "react-hook-form";

import { IncidentStatusFormData } from "./StatusFormElement";

export enum ActiveIncidentDecision {
  Stay = "stay",
  Resolved = "resolved",
  Paused = "paused",
}

const options: SelectOption[] = [
  {
    label: "Active - it's still ongoing",
    value: ActiveIncidentDecision.Stay,
  },
  {
    label: "Paused - it's temporarily on hold",
    value: ActiveIncidentDecision.Paused,
  },
  {
    label: "Resolved - it's no longer a problem",
    value: ActiveIncidentDecision.Resolved,
  },
];

export const ActiveIncidentDecisionRadio = ({
  selectedDecision,
}: {
  selectedDecision?: ActiveIncidentDecision;
}) => {
  const formMethods = useFormContext<IncidentStatusFormData>();

  return (
    <div>
      <RadioButtonGroupV2
        formMethods={formMethods}
        label="Which category best describes the current state?"
        srLabel="Select category"
        options={options}
        name="incident_status_decision"
        boxed
      />
      {selectedDecision === ActiveIncidentDecision.Resolved && (
        <p className="mt-1 text-xs text-slate-700">
          {`ℹ️ We won't mark this incident as resolved until you click 'Next' and provide the required information.`}
        </p>
      )}
    </div>
  );
};
