import { FormContextTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { customComponents } from "@incident-ui/Select/customComponents";
import { customStyles } from "@incident-ui/Select/customStyles";
import {
  isSelectOption,
  isSelectOptionGroup,
  SelectOption,
  SelectOptionGroup,
  SelectOptionOrderBy,
  SelectOptions,
  SharedStaticSelectProps,
  sortSelectOptions,
} from "@incident-ui/Select/types";
import { useAutoAdjustingMenuPlacement } from "@incident-ui/Select/useAutoAdjustingMenuPlacement";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import React from "react";
import ReactSelect, { GroupBase, StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";

import { SelectWrapper } from "./SelectWrapper";

export type StaticSingleSelectProps = SharedStaticSelectProps & {
  value: string | null | undefined;
  onChange: (val: string | null | undefined) => void;
  wrapperClassName?: string;
};

const isOptionGroups = (
  options: SelectOptions,
): options is SelectOptionGroup[] => {
  return options.every((x) => isSelectOptionGroup(x));
};

const findValueInOptions = (
  value: string | null | undefined,
  options: SelectOptions,
): SelectOption | undefined => {
  if (!value) {
    return undefined;
  }
  if (isOptionGroups(options)) {
    for (let i = 0; i < options.length; i++) {
      const optionGroup = options[i];
      const hydratedValue = optionGroup.options.find(
        (opt) => opt.value === value,
      );
      if (hydratedValue) {
        return hydratedValue;
      }
    }
  } else {
    return options.filter(isSelectOption).find((opt) => opt.value === value);
  }
  return undefined;
};

export const StaticSingleSelect = ({
  options,
  defaultValue,
  value,
  onChange: onValueChange,
  renderDescription,
  wrapperClassName,
  ...restProps
}: StaticSingleSelectProps): React.ReactElement => {
  // the outside world wants to deals with values that are strings, but react select expects
  // SelectOptions as values. So we have to do some hacking to make the outside world not
  // know about this sadness.
  const hydratedValue = findValueInOptions(value, options);
  const onChange = (selectedOption: SelectOption) => {
    onValueChange(selectedOption ? selectedOption.value : null);
  };

  return (
    <>
      <StaticSingleSelectWithObj
        // If the value is null, that means "there is no value: show nothing", but
        // undefined means "not sure what the value is - leave whatever you had
        // before there", which is useful if we're still hydrating the value!
        value={value == null ? null : hydratedValue ?? undefined}
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        wrapperClassName={wrapperClassName}
        {...restProps}
      />
      {renderDescription === "below" && hydratedValue?.description && (
        <FormContextTextV2>{hydratedValue?.description}</FormContextTextV2>
      )}
    </>
  );
};

StaticSingleSelect.displayName = "StaticSingleSelect";

export type StaticSingleSelectWithObjProps = SharedStaticSelectProps & {
  // null = set to empty; undefined = leave it as it was last picked
  value: SelectOption | null | undefined;
  onChange: (val: SelectOption) => void;
  onValueChange?: (val: SelectOption) => void;
  sortingOrder?: SelectOptionOrderBy;
  wrapperClassName?: string;
  styles?: (
    invalid: boolean,
    hasSuffixNode: boolean,
  ) => StylesConfig<SelectOption, boolean, GroupBase<SelectOption>>;
};

export const StaticSingleSelectWithObj = (
  props: StaticSingleSelectWithObjProps,
): React.ReactElement => {
  const { disabled, disabledTooltipContent } = props;

  if (disabled && disabledTooltipContent) {
    return (
      <Tooltip content={disabledTooltipContent}>
        <div>
          <Select {...props} />
        </div>
      </Tooltip>
    );
  }

  return <Select {...props} />;
};

const Select = ({
  options,
  icon,
  optionIcon,
  optionColor,
  value,
  onChange,
  invalid,
  id,
  defaultValue,
  styles = customStyles,
  insetSuffixNode,
  sortingOrder,
  allowAdding,
  onCreateOption,
  className,
  isDisplayingPill,
  wrapperClassName,
  disabled,
  ...rest
}: StaticSingleSelectWithObjProps) => {
  const sortedOptions = sortSelectOptions(options, sortingOrder);
  const [menuPlacement, internalRef] = useAutoAdjustingMenuPlacement();
  if (!!optionIcon && !!optionColor) {
    isDisplayingPill = true;
  }

  if (allowAdding) {
    return (
      <SelectWrapper
        suffixNode={insetSuffixNode}
        className={className}
        disabled={disabled}
      >
        <CreatableSelect<SelectOption, false>
          value={value}
          inputId={id}
          // @ts-expect-error this is fine, just about the react-select types
          onChange={onChange}
          onCreateOption={onCreateOption}
          defaultValue={defaultValue}
          options={sortedOptions}
          isOptionDisabled={(option) => option.disabled ?? false}
          // @ts-expect-error this is fine, just about the react-select types
          ref={internalRef}
          menuPlacement={menuPlacement}
          menuPosition="fixed"
          menuPortalTarget={document.body}
          isMulti={false}
          isLoading={false}
          closeMenuOnSelect={true}
          styles={styles(!!invalid, !!insetSuffixNode, !!isDisplayingPill)}
          components={customComponents({ icon, optionIcon, optionColor })}
          className={wrapperClassName}
          isDisabled={disabled}
          {...rest}
        />
      </SelectWrapper>
    );
  }

  return (
    <SelectWrapper
      suffixNode={insetSuffixNode}
      className={className}
      disabled={disabled}
    >
      <ReactSelect<SelectOption, false>
        value={value}
        inputId={id}
        // @ts-expect-error this is fine, just about the react-select types
        onChange={onChange}
        defaultValue={defaultValue}
        options={sortedOptions}
        isOptionDisabled={(option) => option.disabled ?? false}
        // @ts-expect-error this is fine, just about the react-select types
        ref={internalRef}
        menuPlacement={menuPlacement}
        menuPosition="fixed"
        menuPortalTarget={document.body}
        isMulti={false}
        isLoading={false}
        closeMenuOnSelect={true}
        styles={styles(!!invalid, !!insetSuffixNode, !!isDisplayingPill)}
        components={customComponents({ icon, optionIcon, optionColor })}
        // Inputs get priority over things with tabIndex=0, since inputs are
        // generally more important than other things on screen.
        tabIndex={1}
        className={wrapperClassName}
        isDisabled={disabled}
        {...rest}
      />
    </SelectWrapper>
  );
};

StaticSingleSelectWithObj.displayName = "StaticSingleSelectWithObj";
