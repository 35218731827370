import { BillingSettingPlanNameEnum } from "@incident-io/api";
import { Icon, IconEnum, Link, Txt } from "@incident-ui";
import React from "react";
import { BillingSetting, Plan, PlanNameEnum } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { HorizontalLine } from "../lifecycle/overview/LifecycleUIElements";
import { BillingPlanUpgradeButton } from "./BillingPlanUpgradeButton";

export const BillingPlanCards = ({
  plans,
  onSelectPlan,
}: {
  plans: Plan[];
  onSelectPlan: (plan: Plan) => void;
}): React.ReactElement | null => {
  if (plans.length === 0) {
    return null;
  }

  if (plans.length === 1) {
    return <HorizontalPlanCard plan={plans[0]} onSelectPlan={onSelectPlan} />;
  }

  return (
    <div
      className={tcx(
        "gap-4 grid",
        // On narrow screens, we stack everything into 1 column
        "grid-cols-1",
        // On wider screens, we want to show plans side-by-side. To achieve
        // that, we want to use 2-columns for 2 plans, or 3-columns for 3 plans.
        //
        // These values are hard-coded, because `xl:grid-cols-${plans.length}` confuses
        // tailwind's class generation and therefore doesn't work.
        plans.length === 2
          ? "xl:grid-cols-2"
          : plans.length === 3
          ? "xl:grid-cols-3"
          : null,
        "justify-center w-full",
      )}
    >
      {plans.map((plan, idx) => (
        <PlanCard
          key={plan.name}
          plan={plan}
          showingPrevious={idx > 0}
          onSelectPlan={onSelectPlan}
          highlight={
            plan.name === PlanNameEnum.ProV1 || plan.name === PlanNameEnum.ProV2
          }
        />
      ))}
    </div>
  );
};

type PlanWording = {
  intro?: React.ReactNode;
  topThree: string[];
};

const planWordings: { [key in PlanNameEnum]: PlanWording } = {
  [PlanNameEnum.Trial]: {
    topThree: [], // We don't advertise this one
  },
  [PlanNameEnum.Legacy]: {
    topThree: [], // We don't advertise this one
  },
  [PlanNameEnum.StarterV1]: {
    topThree: ["Unlimited users", "One status page", "3 workflows"],
  },
  [PlanNameEnum.ProV1]: {
    intro: "Everything in Starter, plus...",
    topThree: [
      "Unlimited integrations",
      "Private incidents",
      "Advanced insights",
    ],
  },
  [PlanNameEnum.EnterpriseV1]: {
    intro: "Everything in Pro, plus...",
    topThree: [
      "Audit logs",
      "Custom user roles",
      "Dedicated customer success manager",
    ],
  },
  [PlanNameEnum.BasicV2]: {
    topThree: [], // We don't advertise this one
  },
  [PlanNameEnum.TeamV2]: {
    topThree: [
      "On-call and alerting",
      "Slack native incident response",
      "Beautiful status pages",
      "Supercharged AI and Workflows",
    ],
  },
  [PlanNameEnum.ProV2]: {
    intro: "Everything from Team, plus:",
    topThree: [
      "Advanced insights and AI Assistant",
      "Seemless post-incident process",
      "Private incidents and Policies",
    ],
  },
  [PlanNameEnum.EnterpriseV2]: {
    intro: "Everything from Pro, plus:",
    topThree: [
      "Dedicated account manager",
      "Advanced access control",
      "Multiple environments",
    ],
  },
};

const upgradeButtonLabelFor = (plan: Plan) =>
  plan.monthly_purchase.can_self_serve && plan.annual_purchase.can_self_serve
    ? `Upgrade to ${plan.label}`
    : "Get in touch";

export const PlanCard = ({
  plan,
  onSelectPlan,
  highlight,
  showingPrevious,
  upgradeButtonLabel,
  upgradeButtonLoading = false,
}: {
  plan: Plan;
  onSelectPlan: (plan: Plan) => void;
  showingPrevious: boolean;
  highlight?: boolean;
  upgradeButtonLabel?: string;
  upgradeButtonLoading?: boolean;
}): React.ReactElement => {
  return (
    <>
      <div
        className={tcx(
          "bg-gradient-to-b p-px rounded-[12px] xl:order-1",
          highlight ? "from-brand" : "from-slate-200",
        )}
      >
        <div className="flex flex-col bg-white rounded-[11px] p-4 h-full items-center">
          <div
            className={tcx(
              "text-2xl leading-8 font-medium my-2",
              highlight ? "text-alarmalade-600" : "text-content-primary",
            )}
          >
            {plan.label}
          </div>
          <span className="my-2 pr-2 text-content-secondary text-center h-[50px]">
            {plan.description}
          </span>
          <HorizontalLine className="w-4/5 mt-2" />
          <PlanHighlights
            planName={plan.name}
            showingPrevious={showingPrevious}
          />
        </div>
      </div>
      <div className="px-4 xl:order-2">
        <BillingPlanUpgradeButton
          planName={plan.name}
          onSelectPlan={() => onSelectPlan(plan)}
          highlight={highlight}
          buttonLabel={upgradeButtonLabel ?? upgradeButtonLabelFor(plan)}
          loading={upgradeButtonLoading}
        />
      </div>
    </>
  );
};

const HorizontalPlanCard = ({
  plan,
  onSelectPlan,
}: {
  plan: Plan;
  onSelectPlan: (plan: Plan) => void;
}): React.ReactElement => {
  return (
    <div
      className={tcx(
        "border border-stroke rounded-2",
        "flex flex-col lg:flex-row",
        "bg-white",
        "items-center lg:items-start justify-between",
        "text-center lg:text-start",
        "p-6 lg:p-8 gap-6",
      )}
    >
      <div className="flex flex-col items-center lg:items-start gap-2 grow">
        <div className={"text-2xl leading-8 font-medium text-content-primary"}>
          {plan.label}
        </div>
        <Txt grey className="mb-2">
          {plan.description}
        </Txt>
        <HorizontalLine className="w-4/5" />
        <PlanHighlights planName={plan.name} />
      </div>
      <div className="flex-none">
        <BillingPlanUpgradeButton
          planName={plan.name}
          onSelectPlan={() => onSelectPlan(plan)}
          buttonLabel={upgradeButtonLabelFor(plan)}
        />
      </div>
    </div>
  );
};

const PlanHighlights = ({
  planName,
  showingPrevious = false,
}: {
  planName: PlanNameEnum;
  showingPrevious?: boolean;
}) => {
  const { intro, topThree } = planWordings[planName];

  return (
    <ul className="my-6 space-y-4">
      {showingPrevious && intro && (
        <li className="flex text-slate-700 font-normal">{intro}</li>
      )}
      {topThree.map((feature, idx) => (
        <li key={idx} className="flex text-slate-700 font-normal gap-1">
          <Icon id={IconEnum.Tick} className="text-content-primary" />
          {feature}
        </li>
      ))}
    </ul>
  );
};

export const GetMoreFromIncident = ({
  billingSetting,
}: {
  billingSetting: BillingSetting;
}) => {
  if (
    billingSetting.plan_name === BillingSettingPlanNameEnum.EnterpriseV1 ||
    billingSetting.plan_name === BillingSettingPlanNameEnum.EnterpriseV2
  ) {
    // You're enterprise, so hide the "get more" stuff
    return null;
  }

  return (
    <div className="flex flex-row justify-between w-full pt-3">
      <Txt className="text-lg font-bold">Get more from incident.io</Txt>
      <Link
        href="https://incident.io/pricing"
        analyticsTrackingId="billing-settings-to-pricing-page"
        className="text-md font-medium text-alarmalade-600 border-b border-transparent hover:border-alarmalade-600 transition"
        openInNewTab
        noUnderline
      >
        Full plan comparison →
      </Link>
    </div>
  );
};
