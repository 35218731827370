/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsSalesforceGetAccountListViewsResponseBody,
    IntegrationsSalesforceGetAccountListViewsResponseBodyFromJSON,
    IntegrationsSalesforceGetAccountListViewsResponseBodyToJSON,
    IntegrationsSalesforceGetConfigResponseBody,
    IntegrationsSalesforceGetConfigResponseBodyFromJSON,
    IntegrationsSalesforceGetConfigResponseBodyToJSON,
    IntegrationsSalesforceUpdateAccountViewRequestBody,
    IntegrationsSalesforceUpdateAccountViewRequestBodyFromJSON,
    IntegrationsSalesforceUpdateAccountViewRequestBodyToJSON,
    IntegrationsSalesforceUpdateAccountViewResponseBody,
    IntegrationsSalesforceUpdateAccountViewResponseBodyFromJSON,
    IntegrationsSalesforceUpdateAccountViewResponseBodyToJSON,
} from '../models';

export interface IntegrationsSalesforceHandleInstallRequest {
    organisationId: string;
}

export interface IntegrationsSalesforceHandleRedirectRequest {
    state?: string;
    code?: string;
}

export interface IntegrationsSalesforceUpdateAccountViewRequest {
    updateAccountViewRequestBody: IntegrationsSalesforceUpdateAccountViewRequestBody;
}

/**
 * 
 */
export class IntegrationsSalesforceApi extends runtime.BaseAPI {

    /**
     * Get Accounts list views
     * GetAccountListViews Integrations - Salesforce
     */
    async integrationsSalesforceGetAccountListViewsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsSalesforceGetAccountListViewsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/salesforce/listviews`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsSalesforceGetAccountListViewsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get Accounts list views
     * GetAccountListViews Integrations - Salesforce
     */
    async integrationsSalesforceGetAccountListViews(initOverrides?: RequestInit): Promise<IntegrationsSalesforceGetAccountListViewsResponseBody> {
        const response = await this.integrationsSalesforceGetAccountListViewsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the org\'s Salesforce config
     * GetConfig Integrations - Salesforce
     */
    async integrationsSalesforceGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsSalesforceGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/salesforce/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsSalesforceGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the org\'s Salesforce config
     * GetConfig Integrations - Salesforce
     */
    async integrationsSalesforceGetConfig(initOverrides?: RequestInit): Promise<IntegrationsSalesforceGetConfigResponseBody> {
        const response = await this.integrationsSalesforceGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Handle Salesforce installation by redirecting to the Salesforce install endpoint
     * HandleInstall Integrations - Salesforce
     */
    async integrationsSalesforceHandleInstallRaw(requestParameters: IntegrationsSalesforceHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsSalesforceHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/salesforce_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Salesforce installation by redirecting to the Salesforce install endpoint
     * HandleInstall Integrations - Salesforce
     */
    async integrationsSalesforceHandleInstall(requestParameters: IntegrationsSalesforceHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsSalesforceHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Salesforce redirect
     * HandleRedirect Integrations - Salesforce
     */
    async integrationsSalesforceHandleRedirectRaw(requestParameters: IntegrationsSalesforceHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/salesforce`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Salesforce redirect
     * HandleRedirect Integrations - Salesforce
     */
    async integrationsSalesforceHandleRedirect(requestParameters: IntegrationsSalesforceHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsSalesforceHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Remove Salesforce OAuth token
     * HandleUninstall Integrations - Salesforce
     */
    async integrationsSalesforceHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/salesforce`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove Salesforce OAuth token
     * HandleUninstall Integrations - Salesforce
     */
    async integrationsSalesforceHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsSalesforceHandleUninstallRaw(initOverrides);
    }

    /**
     * Set the synced account ID
     * UpdateAccountView Integrations - Salesforce
     */
    async integrationsSalesforceUpdateAccountViewRaw(requestParameters: IntegrationsSalesforceUpdateAccountViewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsSalesforceUpdateAccountViewResponseBody>> {
        if (requestParameters.updateAccountViewRequestBody === null || requestParameters.updateAccountViewRequestBody === undefined) {
            throw new runtime.RequiredError('updateAccountViewRequestBody','Required parameter requestParameters.updateAccountViewRequestBody was null or undefined when calling integrationsSalesforceUpdateAccountView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/salesforce/actions/update_account_view`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsSalesforceUpdateAccountViewRequestBodyToJSON(requestParameters.updateAccountViewRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsSalesforceUpdateAccountViewResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set the synced account ID
     * UpdateAccountView Integrations - Salesforce
     */
    async integrationsSalesforceUpdateAccountView(requestParameters: IntegrationsSalesforceUpdateAccountViewRequest, initOverrides?: RequestInit): Promise<IntegrationsSalesforceUpdateAccountViewResponseBody> {
        const response = await this.integrationsSalesforceUpdateAccountViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
