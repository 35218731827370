import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { IconEnum } from "@incident-ui";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

import { QuoteContentBox } from "./QuoteContextBox";

export const TimelineItemCustomEvent = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps | null => {
  if (!item.custom_event) {
    throw new Error(
      "malformed timeline item: custom_event was missing custom_event field",
    );
  }

  let children: React.ReactNode = null;
  if (item.custom_event.description) {
    children = (
      <QuoteContentBox>
        {item.custom_event.description && (
          <TemplatedTextDisplay
            value={item.custom_event.description}
            style={TemplatedTextDisplayStyle.Compact}
          />
        )}
      </QuoteContentBox>
    );
  }

  return {
    description: item.custom_event.title,
    icon: IconEnum.Bookmark,
    children: children,
  };
};
