import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { add, differenceInCalendarDays, format, isSameDay } from "date-fns";
import {
  IncidentModeEnum,
  IncidentStatusCategoryEnum,
  IncidentUpdate,
  Stream,
} from "src/contexts/ClientContext";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { tcx } from "src/utils/tailwind-classes";

import { useNavigateToModal } from "../../../../utils/query-params";
import { useUpdateRequested } from "../header/RequestUpdateModal";
import { useIncident } from "../hooks";

export const IncidentUpdatesHeader = ({
  incidentId,
  mostRecentUpdate,
  stream,
}: {
  incidentId: string | null;
  mostRecentUpdate: IncidentUpdate;
  stream?: Stream;
}) => {
  const { incident } = useIncident(incidentId);
  const navigateToModal = useNavigateToModal();

  if (stream) {
    return stream.status.category ===
      IncidentStatusCategoryEnum.Closed ? null : (
      <IncidentUpdatesHeaderInner
        mostRecentUpdate={mostRecentUpdate}
        currentID={stream.id}
        onUpdateRequested={() =>
          navigateToModal(IncidentHeaderModal.RequestUpdate)
        }
      />
    );
  }

  if (!incident || invalidIncidentForUpdateRequest(incident)) {
    return null;
  }

  return (
    <IncidentUpdatesHeaderInner
      mostRecentUpdate={mostRecentUpdate}
      currentID={incident.id}
      onUpdateRequested={() =>
        navigateToModal(IncidentHeaderModal.RequestUpdate)
      }
    />
  );
};

const invalidIncidentForUpdateRequest = (incident) => {
  return (
    [
      IncidentStatusCategoryEnum.Declined,
      IncidentStatusCategoryEnum.Merged,
      IncidentStatusCategoryEnum.Closed,
      IncidentStatusCategoryEnum.Canceled,
    ].includes(incident.incident_status.category) ||
    incident.mode === IncidentModeEnum.Retrospective
  );
};

const IncidentUpdatesHeaderInner = ({
  onUpdateRequested,
  mostRecentUpdate,
  currentID,
}: {
  onUpdateRequested: () => void;
  mostRecentUpdate: IncidentUpdate;
  currentID: string;
}) => {
  const { updateRequestedSinceMostRecentUpdate, updateRequestedForIDs } =
    useUpdateRequested();

  const updateRequestedThisSession = updateRequestedForIDs?.has(currentID);

  const showUpdateRequestedMessage =
    updateRequestedThisSession &&
    updateRequestedSinceMostRecentUpdate(mostRecentUpdate.created_at);

  const nextUpdateTime = mostRecentUpdate?.next_update_in_minutes
    ? add(mostRecentUpdate.created_at, {
        minutes: mostRecentUpdate?.next_update_in_minutes,
      })
    : new Date();
  const nextUpdateDays = getNextUpdateDays(nextUpdateTime);

  return (
    <div className="flex text-sm">
      <div className="flex flex-col items-center mr-4 flex-none">
        {mostRecentUpdate?.next_update_in_minutes ? (
          <div
            className={tcx(
              "flex items-center justify-center inline-block rounded-full align-middle",
              "bg-red-surface text-alarmalade-600",
            )}
          >
            <Icon id={IconEnum.Add} size={IconSize.Large} />
          </div>
        ) : (
          <div
            className={tcx(
              "flex items-center justify-center inline-block rounded-full align-middle",
              "bg-surface-tertiary text-slate-600",
            )}
          >
            <Icon
              id={IconEnum.DotsHorizontal}
              size={IconSize.Large}
              className="!p-1"
            />
          </div>
        )}
        <div className="border-l border-solid h-full border-stroke" />
      </div>
      <div className="grow pb-6">
        <div className="flex items-center">
          <div className="mr-2">
            {mostRecentUpdate?.next_update_in_minutes ? (
              <span>
                {`Next update expected at ${format(
                  nextUpdateTime,
                  "HH:mm",
                )}${nextUpdateDays}`}
              </span>
            ) : (
              <span>No update scheduled</span>
            )}
          </div>
          {showUpdateRequestedMessage ? (
            <span className="text-green-600 flex items-center space-x-2">
              <Icon id={IconEnum.Checkmark} /> Update requested
            </span>
          ) : (
            <Button
              theme={ButtonTheme.Naked}
              icon={IconEnum.Hand}
              iconProps={{ size: IconSize.Large }}
              analyticsTrackingId="request-incident-update"
              onClick={onUpdateRequested}
            >
              Request update
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const getNextUpdateDays = (nextUpdateTime: Date): string => {
  if (isSameDay(Date.now(), nextUpdateTime)) {
    return " today";
  }
  const diff = differenceInCalendarDays(nextUpdateTime, Date.now());
  if (diff > 0) {
    return `, in ${diff} day${diff > 1 ? "s" : ""}`;
  }
  return `, ${-diff} day${-diff > 1 ? "s" : ""} ago`;
};
