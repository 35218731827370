import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { SingleTimezoneSelectV2 } from "@incident-shared/forms/v2/inputs/SingleTimezoneSelectV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  LoadingModal,
  ModalFooter,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { TypeaheadTypeEnum } from "src/components/@shared/forms/Typeahead";
import { IntegrationConfigFor } from "src/components/@shared/integrations";
import { SidebarDivider } from "src/components/legacy/incident/sidebar/IncidentSidebar";
import {
  Incident,
  IncidentModeEnum,
  IntegrationSettingsProviderEnum,
  PostmortemDestination,
  PostmortemDestinationDocumentProviderEnum,
  PostmortemsCreateDocumentRequestBody,
  PostmortemsCreateDocumentRequestBodyDocumentProviderEnum,
  PostmortemsEnqueueCreateDocumentRequestBody,
  PostmortemSettingsDefaultDocumentProviderCopyPasteEnum,
  PostmortemsSettingsShowResponseBody,
  PostmortemTemplate,
  SelectOption,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { usePostmortemName } from "src/utils/utils";

import { CopyPostMortemButton } from "./CopyPostMortemButton";

type Templates = {
  label: string;
  value: string;
}[];

export const CreatePostMortemModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(incident);

  const {
    data: { destinations },
    isLoading: destinationsLoading,
  } = useAPI("postmortemsListDestinations", undefined, {
    fallbackData: { destinations: [] },
  });

  const {
    data: { postmortem_templates: templates },
    isLoading: templatesLoading,
  } = useAPI(
    "postmortemsListTemplates",
    {},
    {
      fallbackData: { postmortem_templates: [] },
    },
  );

  const { data: settings, isLoading: settingsLoading } = useAPI(
    "postmortemsSettingsShow",
    undefined,
  );

  if (destinationsLoading || templatesLoading || settingsLoading || !settings) {
    return (
      <LoadingModal
        onClose={onClose}
        title={`Create ${postmortemNameFormatted}`}
        isOpen
      />
    );
  }

  return (
    <CreatePostMortemModalInner
      incident={incident}
      onClose={onClose}
      destinations={destinations}
      allTemplates={templates}
      settings={settings}
    />
  );
};

type FormData = PostmortemsCreateDocumentRequestBody &
  PostmortemsEnqueueCreateDocumentRequestBody["generation_options"];

const defaultValues = ({
  allTemplates,
  incident,
  destinations,
  settings,
}: {
  allTemplates: PostmortemTemplate[];
  incident: Incident;
  destinations: PostmortemDestination[];
  settings: PostmortemsSettingsShowResponseBody;
}) => {
  // Timezone
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultSettingsTimezone = settings.settings.default_timezone;
  const defaultTimezone = defaultSettingsTimezone || localTimezone;

  // Templates
  const defaultTemplate = allTemplates.find((t) => t.is_default);
  const defaultTemplateForIncident =
    incident.incident_type?.default_postmortem_template_id;

  const defaultTemplateId = defaultTemplateForIncident || defaultTemplate?.id;

  // Destinations
  const defaultDestination = destinations.find((d) => d.is_default);
  const defaultTestDestination = destinations.find(
    (d) => d.id === settings.settings.test_or_tutorial_destination_id,
  );
  const isTestOrTutorial =
    incident.mode === IncidentModeEnum.Test ||
    incident.mode === IncidentModeEnum.Tutorial;
  const defaultDestinationForIncident =
    incident.incident_type?.default_postmortem_destination_id;

  const defaultDestinationId =
    (isTestOrTutorial ? defaultTestDestination?.id : undefined) ||
    defaultDestinationForIncident ||
    defaultDestination?.id ||
    "copy_and_paste";

  return {
    timezone: defaultTimezone,
    destination_id: defaultDestinationId,
    template_id: defaultTemplateId,
  };
};

const CreatePostMortemModalInner = ({
  incident,
  onClose,
  destinations,
  allTemplates,
  settings,
}: {
  incident: Incident;
  onClose: () => void;
  destinations: PostmortemDestination[];
  allTemplates: PostmortemTemplate[];
  settings: PostmortemsSettingsShowResponseBody;
}): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(incident);

  const formMethods = useForm<FormData>({
    defaultValues: defaultValues({
      allTemplates,
      incident,
      destinations,
      settings,
    }),
  });
  const { setError, watch } = formMethods;

  const selectedDestinationId = watch("destination_id");
  const showExportView = destinations.some(
    (d) => d.id === selectedDestinationId,
  );

  const refetchPostIncidentTasks = useAPIRefetch("postIncidentFlowListTasks", {
    incidentId: incident.id,
  });
  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });
  const {
    trigger: createDocument,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident.id },
    async (
      apiClient,
      { timezone, template_id, destination_id, permalink }: FormData,
    ) => {
      const initialBody:
        | PostmortemsCreateDocumentRequestBody
        | PostmortemsEnqueueCreateDocumentRequestBody = {
        incident_id: incident.id,
      };

      if (showExportView) {
        if (!timezone || !template_id || !destination_id) {
          throw new Error(
            "Expected timezone, template and destination to be set",
          );
        }

        const body: PostmortemsEnqueueCreateDocumentRequestBody = initialBody;
        body.generation_options = {
          timezone,
          template_id,
          destination_id,
        };

        await apiClient.postmortemsEnqueueCreateDocument({
          enqueueCreateDocumentRequestBody: body,
        });
      } else {
        const body: PostmortemsCreateDocumentRequestBody = initialBody;
        let documentProvider: PostmortemsCreateDocumentRequestBodyDocumentProviderEnum =
          PostmortemsCreateDocumentRequestBodyDocumentProviderEnum.Empty;
        if (destination_id === "copy_and_paste") {
          documentProvider = settings.settings
            .default_document_provider_copy_paste as unknown as PostmortemsCreateDocumentRequestBodyDocumentProviderEnum;
        }
        body.document_provider = documentProvider;
        // trim the link so that it doesn't break slack links
        body.permalink = permalink?.trim();

        await apiClient.postmortemsCreateDocument({
          createDocumentRequestBody: body,
        });
      }

      await refetchPostIncidentTasks();

      // Refetch the incident so anything on the page that uses postmortem_document_url will know
      // about it
      await refetchIncident();
    },
    { setError, onSuccess: onClose },
  );

  const templateOptions = allTemplates.map((template) => ({
    label: template.name,
    value: template.id,
  }));

  const confluenceProviderLocations = destinations
    .filter(
      ({ document_provider }) =>
        document_provider ===
        PostmortemDestinationDocumentProviderEnum.Confluence,
    )
    .map((d) => d.provider_location);

  const { data: destinationsExternalInfo } = useAPI(
    confluenceProviderLocations.length > 0 ? "typeaheadsList" : null,
    {
      typeaheadType: TypeaheadTypeEnum.ConfluenceContent,
      idList: confluenceProviderLocations,
    },
  );

  return (
    <FormModalV2
      formMethods={formMethods}
      genericError={genericError}
      title={`Create ${postmortemNameFormatted}`}
      analyticsTrackingId="create-post-mortem"
      onClose={onClose}
      onSubmit={createDocument}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          saving={saving}
          confirmButtonText={showExportView ? "Create" : "Attach"}
          gadget={
            <Button
              analyticsTrackingId={"postmortem-settings"}
              href="/settings/post-mortem"
              theme={ButtonTheme.Naked}
            >
              {`Go to ${postmortemNameFormatted} settings`}
            </Button>
          }
        />
      }
    >
      <SelectPostmortemDestination
        formMethods={formMethods}
        postmortemNameFormatted={postmortemNameFormatted}
        destinations={destinations}
        destinationsExternalInfo={destinationsExternalInfo?.options}
      />

      {showExportView && (
        <ExportPostMortemSection
          formMethods={formMethods}
          incident={incident}
          templates={templateOptions}
        />
      )}

      {selectedDestinationId === "copy_and_paste" && (
        <CopyAndAttachPostMortemSection
          formMethods={formMethods}
          incident={incident}
          templates={templateOptions}
          defaultDocumentProviderCopyPaste={
            settings.settings.default_document_provider_copy_paste
          }
        />
      )}

      {selectedDestinationId === "manually_attach" && (
        <ManuallyAttachPostMortemSection
          formMethods={formMethods}
          incident={incident}
        />
      )}
    </FormModalV2>
  );
};

const ExportPostMortemSection = ({
  formMethods,
  incident,
  templates,
}: {
  formMethods: UseFormReturn<FormData>;
  incident: Incident;
  templates: Templates;
}): React.ReactElement => {
  return (
    <>
      <SelectPostMortemTemplate
        formMethods={formMethods}
        incident={incident}
        templates={templates}
      />
      <SingleTimezoneSelectV2
        formMethods={formMethods}
        name="timezone"
        label="Timezone"
        fullWidth
      />
    </>
  );
};

const CopyAndAttachPostMortemSection = ({
  incident,
  formMethods,
  templates,
  defaultDocumentProviderCopyPaste,
}: {
  incident: Incident;
  formMethods: UseFormReturn<FormData>;
  templates: Templates;
  defaultDocumentProviderCopyPaste?: PostmortemSettingsDefaultDocumentProviderCopyPasteEnum;
}): React.ReactElement => {
  const showToast = useToast();
  const { postmortemName, postmortemNameFormatted } =
    usePostmortemName(incident);
  const onCopied = (didSucceed: boolean) => {
    showToast(
      didSucceed
        ? {
            theme: ToastTheme.Success,
            title: `${postmortemName} copied to clipboard`,
          }
        : {
            theme: ToastTheme.Error,
            title: `Sorry, we had an unexpected problem copying the ${postmortemNameFormatted} to your clipboard. Our engineers have been notified.`,
          },
    );
  };

  const [timezone, selectedTemplateId] = formMethods.watch([
    "timezone",
    "template_id",
  ]);

  return (
    <>
      <SelectPostMortemTemplate
        formMethods={formMethods}
        incident={incident}
        templates={templates}
      />
      <SingleTimezoneSelectV2
        formMethods={formMethods}
        name="timezone"
        label="Timezone"
        fullWidth
      />
      <SidebarDivider />
      <FormInputWrapper
        id="copy-document-contents"
        errors={{}}
        label="1. Copy document contents"
        helptext={`Create a new document in the provider of your choice and copy and paste the ${postmortemNameFormatted} using the button below.`}
      >
        {timezone && (
          <CopyPostMortemButton
            incident={incident}
            onCopied={onCopied}
            timezone={timezone}
            templateId={selectedTemplateId}
            defaultDocumentProviderCopyPaste={defaultDocumentProviderCopyPaste}
          />
        )}
      </FormInputWrapper>
      <PostMortemURLForm
        postmortemNameFormatted={postmortemNameFormatted}
        formMethods={formMethods}
        titlePrefix="2. "
      />
    </>
  );
};

const ManuallyAttachPostMortemSection = ({
  formMethods,
  incident,
}: {
  formMethods: UseFormReturn<FormData>;
  incident: Incident;
}): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(incident);

  return (
    <PostMortemURLForm
      formMethods={formMethods}
      postmortemNameFormatted={postmortemNameFormatted}
      titlePrefix=""
    />
  );
};

const PostMortemURLForm = ({
  formMethods,
  postmortemNameFormatted,
  titlePrefix,
}: {
  formMethods: UseFormReturn<FormData>;
  postmortemNameFormatted: string;
  titlePrefix: string;
}): React.ReactElement => {
  const url = formMethods.watch("permalink");
  const isNotion = (url || "").includes("notion.so");
  const isConfluence = (url || "").includes("atlassian.net/wiki");
  const isGoogle = (url || "").includes("docs.google.com");

  return (
    <>
      <InputV2
        formMethods={formMethods}
        name="permalink"
        type={InputType.Url}
        label={`${titlePrefix}Attach document`}
        helptext={`Add the URL of your ${postmortemNameFormatted} here so that we can link it to this incident.`}
        placeholder={"https://docs.example"}
        required="Please enter a valid link"
        maxLength={1000}
      />

      {isNotion && (
        <Callout
          theme={CalloutTheme.Info}
          iconOverride={IconEnum.New}
          className={"mt-2"}
          title={
            <span>
              incident.io offers a native{" "}
              <a href="https://help.incident.io/en/articles/6804517-notion">
                Notion Integration
              </a>
            </span>
          }
          subtitle={
            <span>
              Allowing a seamless export experience with richer formatting,
              Notion databases with custom properties, native timestamps,
              tagging users and more.
            </span>
          }
        />
      )}

      {isConfluence && (
        <Callout
          theme={CalloutTheme.Info}
          iconOverride={IconEnum.New}
          className={"mt-2"}
          title={
            <span>
              incident.io offers a native{" "}
              <a href="https://help.incident.io/en/articles/6643127-confluence">
                Confluence Integration
              </a>
            </span>
          }
          subtitle={
            <span>
              Allowing a seamless & more reliable export experience with richer
              formatting.
            </span>
          }
        />
      )}

      {isGoogle && (
        <Callout
          theme={CalloutTheme.Info}
          iconOverride={IconEnum.New}
          className={"mt-2"}
          title="incident.io offers a native Google Docs integration"
          subtitle=" Allowing a seamless & more reliable export experience with richer
        formatting."
        />
      )}
    </>
  );
};

const SelectPostMortemTemplate = ({
  formMethods,
  incident,
  templates,
}: {
  formMethods: UseFormReturn<FormData>;
  incident: Incident;
  templates: Templates;
}): React.ReactElement => {
  const { postmortemName } = usePostmortemName(incident);

  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      name="template_id"
      label={`${postmortemName} template`}
      placeholder="Select a template"
      options={templates}
    />
  );
};

const SelectPostmortemDestination = ({
  formMethods,
  destinations,
  destinationsExternalInfo,
  postmortemNameFormatted,
}: {
  postmortemNameFormatted: string;
  destinations: PostmortemDestination[];
  formMethods: UseFormReturn<FormData>;
  destinationsExternalInfo?: SelectOption[];
}): React.ReactElement => {
  const destinationsIncludingDummies = destinations.concat([
    {
      id: "copy_and_paste",
      name: "Copy & Paste",
      is_default: false,
      document_provider:
        PostmortemDestinationDocumentProviderEnum.CopyPasteGoogleDocs,
      provider_location: "Clipboard",
    },
    {
      id: "manually_attach",
      name: "Manually attach",
      is_default: false,
      document_provider:
        PostmortemDestinationDocumentProviderEnum.CopyPasteGoogleDocs,
      provider_location: "Manually attach",
    },
  ]);

  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      name="destination_id"
      label="Destination"
      helptext={`Where should we create the ${postmortemNameFormatted}?`}
      options={destinationsIncludingDummies?.map((destination) => {
        if (destination.id === "copy_and_paste") {
          return {
            label: "Copy & paste",
            value: "copy_and_paste",
            renderFn: () => {
              return (
                <div className="py-2">
                  <p className="font-medium">{destination.name}</p>
                  <div className="flex items-end space-x-4 mt-1">
                    <Icon id={IconEnum.Copy} className={"mr-1"} />
                    Clipboard
                  </div>
                </div>
              );
            },
          };
        }
        if (destination.id === "manually_attach") {
          return {
            label: "Manually attach",
            value: "manually_attach",
            renderFn: () => {
              return (
                <div className="py-2">
                  <p className="font-medium">{destination.name}</p>
                  <div className="flex items-end space-x-4 mt-1">
                    <Icon id={IconEnum.Link} className={"mr-1"} />
                    Manually attach
                  </div>
                </div>
              );
            },
          };
        }

        const config = IntegrationConfigFor(
          destination.document_provider as unknown as IntegrationSettingsProviderEnum,
        );

        return {
          label: destination.name,
          value: destination.id,
          // eslint-disable-next-line react/display-name
          renderFn: () => {
            const providerLocation =
              destinationsExternalInfo &&
              destinationsExternalInfo.find((item) => {
                const itemContentId = item.value.split("/")[1];
                const destinationContentId =
                  destination.provider_location.split("/")[1];
                return itemContentId === destinationContentId;
              })?.label;

            return (
              <div className="py-2">
                <p className="font-medium">{destination.name}</p>
                <div className="flex items-end space-x-4 mt-1">
                  {config.icon && <Icon id={config.icon} className={"mr-1"} />}
                  {config.label}
                  {providerLocation && (
                    <p className="ml-6 text-content-tertiary">
                      {providerLocation}
                    </p>
                  )}
                </div>
              </div>
            );
          },
        };
      })}
      className="caret-transparent" // hide the blinking cursor
    />
  );
};
