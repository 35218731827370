import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

export type CodeBlockProps = {
  title?: string;
  hideHeader?: boolean;
  hideLineNumbers?: boolean;
  hideCopyButton?: boolean;
  showCopyButtonText?: boolean;
  code: string;
  className?: string;
  theme?: "light" | "dark";
};

export const CodeBlock = ({
  title,
  code,
  hideHeader = false,
  hideLineNumbers = false,
  hideCopyButton = false,
  showCopyButtonText = false,
  className,
  theme = "light",
}: CodeBlockProps) => {
  const lines = code.split("\n");
  const { copyTextToClipboard, hasCopied } = useClipboard();

  let copyButtonLocation = "header";
  if (hideCopyButton) {
    copyButtonLocation = "none";
  } else if (hideHeader) {
    copyButtonLocation = "inline";
  }

  const renderTextCopyButton = (className?: string) => {
    return (
      <Button
        theme={ButtonTheme.Unstyled}
        icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
        analyticsTrackingId="copy-token"
        type="button"
        title="Copy to clipboard"
        iconProps={{
          className: tcx({
            "text-green-content": theme === "light" && hasCopied,
            "text-content-tertiary group-hover:text-content-primary":
              theme === "light",
          }),
          size: IconSize.Medium,
        }}
        onClick={() => copyTextToClipboard(code)}
        className={tcx(
          "flex-center-y py-2 px-3 rounded-[6px] text-sm border border-transparent group",
          {
            "bg-white text-content-primary !border-stroke hover:text-content-primary hover:!border-slate-900":
              theme === "light",
            "bg-surface-invert text-white hover:bg-slate-700":
              theme !== "light",
          },
          className,
        )}
      >
        {showCopyButtonText ? "Copy" : undefined}
      </Button>
    );
  };

  const renderIconCopyButton = (className?: string) => {
    return (
      <Button
        theme={ButtonTheme.Unstyled}
        icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
        analyticsTrackingId="copy-token"
        type="button"
        title="Copy to clipboard"
        iconProps={{
          className: tcx({
            "text-content-tertiary group-hover:text-content-primary":
              theme === "light",
            "text-slate-400 group-hover:text-white": theme === "dark",
            "!text-green-content": theme === "light" && hasCopied,
          }),
          size: IconSize.Medium,
        }}
        onClick={() => copyTextToClipboard(code)}
        className={tcx("group py-2", className)}
      />
    );
  };

  const renderCopyButton = (className?: string) => {
    if (showCopyButtonText) {
      return renderTextCopyButton(className);
    }
    return renderIconCopyButton(className);
  };

  return (
    <div className={tcx("flex flex-col items-stretch", className)}>
      <div
        className={tcx(
          "flex flex-row w-full px-4 py-2 rounded-t-lg items-center",
          {
            hidden: hideHeader,
            "bg-surface-secondary": theme === "light",
            "bg-slate-950": theme === "dark",
          },
        )}
      >
        <div className={"flex-1"}>
          <div
            className={tcx("!font-medium text-sm", {
              "text-grey-900": theme === "light",
              "text-slate-100": theme === "dark",
            })}
          >
            {title}
          </div>
        </div>
        {copyButtonLocation === "header" && renderCopyButton()}
      </div>
      <div
        className={tcx("relative rounded-b-lg overflow-hidden", {
          "bg-slate-50": theme === "light",
          "bg-surface-invert": theme === "dark",
          // If there's no header, we need to round the top of the code block and add some padding
          "rounded-t-lg pt-4": hideHeader,
        })}
      >
        <div className="absolute right-2 top-0 z-10">
          {copyButtonLocation === "inline" &&
            renderCopyButton(hideLineNumbers ? "mt-2 -mr-2" : "mr-2 mt-2")}
        </div>
        <div
          className={tcx(
            "max-h-[650px] overflow-auto w-full relative break-normal rounded-b-lg flex gap-0 items-start",
            "scrollbar scrollbar-w-2 scrollbar-h-2 scrollbar-track-rounded-full scrollbar-thumb-rounded-full",
            {
              "scrollbar-track-slate-50 scrollbar-corner-slate-50 scrollbar-thumb-slate-200":
                theme === "light",
              "scrollbar-track-slate-900 scrollbar-corner-slate-900 scrollbar-thumb-slate-800":
                theme === "dark",
            },
          )}
        >
          <table className={"table-fixed w-full"}>
            <tbody>
              {lines.map((line, i) => {
                const isLast = i === lines.length - 1;
                return (
                  // Rounding on the bottom isn't working here :(
                  // doing it on the table cell isn't working either due to the block positioning
                  <tr
                    key={`${line}-${i}`}
                    className={tcx({
                      "bg-slate-50": theme === "light",
                      "bg-surface-invert": theme === "dark",
                    })}
                  >
                    {hideLineNumbers ? null : (
                      <td
                        className={tcx(
                          "pl-4 select-none sticky text-sm left-0 pr-2 w-[50px]",
                          {
                            "pb-4": isLast,
                            "bg-slate-50 text-slate-400": theme === "light",
                            "bg-surface-invert text-slate-600":
                              theme === "dark",
                          },
                        )}
                      >
                        <code>{i + 1}</code>
                      </td>
                    )}
                    <td
                      className={tcx("whitespace-pre-wrap", {
                        "pb-4": isLast,
                        "text-slate-700": theme === "light",
                        "text-slate-200": theme === "dark",
                        "pl-4": hideLineNumbers,
                      })}
                    >
                      <code className={"whitespace-pre pr-2 text-sm"}>
                        {line}
                      </code>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
