import { ConfirmationDialog } from "@incident-ui";
import React from "react";
import { ErrorMessageUI } from "src/components/@shared/forms/ErrorMessage";
import {
  FollowUp,
  FollowUpsUpdateRequestBodyStatusEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { useRefetchFollowups } from "./use-refetch-followups";

type FormData = { id: string };

export const FollowUpNotDoingModal = ({
  onClose,
  followUp,
  refetchIncidents,
}: {
  onClose: () => void;
  followUp: FollowUp;
  refetchIncidents?: () => Promise<void>;
}): React.ReactElement | null => {
  const refetch = useRefetchFollowups();
  const {
    trigger: markFollowUpNotDoing,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    { incidentId: followUp.incident_id },
    async (apiClient, { id }: FormData) => {
      await apiClient.followUpsUpdate({
        id: id,
        updateRequestBody: {
          ...followUp,
          assignee_id: followUp.assignee?.id,
          status: FollowUpsUpdateRequestBodyStatusEnum.NotDoing,
        },
      });
    },
    {
      onSuccess: () => {
        refetch();
        refetchIncidents && refetchIncidents();
        onClose();
      },
    },
  );

  return (
    <ConfirmationDialog
      onCancel={onClose}
      onConfirm={() => markFollowUpNotDoing({ id: followUp.id })}
      isOpen={true}
      title={`Mark follow-up as not doing`}
      analyticsTrackingId="mark-follow-up-not-doing"
      saving={saving}
    >
      <p>Mark this follow-up as not doing?</p>
      <p className="font-medium mt-2">{followUp.title}</p>
      <ErrorMessageUI message={genericError} />
    </ConfirmationDialog>
  );
};
