import { Mode } from "@incident-shared/forms/v2/formsv2";
import { Button, ButtonTheme, FloatingFooter } from "@incident-ui";
import React from "react";
import { useQueryParams } from "src/utils/query-params";

import {
  AlertSourceContinueButton,
  AlertSourceStep,
  AlertSourceStepEnum,
  stepConfig,
} from "./AlertSourceCreatePage";

export const AlertSourceSplitLayout = ({
  step,
  mode,
  alertSourceConfigId,
  left,
  right,
  hideFooter = false,
}: {
  step: AlertSourceStepEnum;
  mode: Mode;
  alertSourceConfigId: string;
  left: React.ReactNode;
  right: React.ReactNode;
  hideFooter?: boolean;
}) => {
  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex flex-col lg:flex-row grow overflow-auto">
        {/* Left Panel: Set-up components */}
        <div className="flex flex-col h-full lg:w-[560px] xl:w-[640px]">
          <div className="overflow-y-auto px-8 pt-6 grow">{left}</div>
        </div>
        {/* Right Panel */}
        <div className="w-full overflow-y-auto">{right}</div>
      </div>
      {/* Footer */}
      {!hideFooter && (
        <div className="mt-auto">
          <AlertSourceFooter
            stepId={step}
            mode={mode}
            alertSourceConfigId={alertSourceConfigId}
          />
        </div>
      )}
    </div>
  );
};

const AlertSourceFooter = ({
  stepId,
  mode,
  alertSourceConfigId,
}: {
  stepId: AlertSourceStepEnum;
  mode: Mode;
  alertSourceConfigId: string;
}) => {
  const queryParams = useQueryParams();
  const from = queryParams.get("from");

  const getStepUrl = (stepId: AlertSourceStepEnum) => {
    const fromQueryString = from ? `&from=${from}` : "";

    return mode === Mode.Edit
      ? `/alerts/sources-v2/${alertSourceConfigId}/edit?step=${stepId}${fromQueryString}`
      : `/alerts/sources-v2/create?step=${stepId}&id=${alertSourceConfigId}${fromQueryString}`;
  };

  const step = stepConfig.find((s) => s.id === stepId);

  return (
    <FloatingFooter containerClassName={"px-4 !mt-0"}>
      {/* Show back button on configure step */}
      {stepId === AlertSourceStepEnum.Configure && mode === Mode.Create ? (
        <Button
          analyticsTrackingId="alert-source-create-back"
          theme={ButtonTheme.Secondary}
          href={getStepUrl(AlertSourceStepEnum.Connect)}
        >
          Back
        </Button>
      ) : null}
      <AlertSourceContinueButton
        step={step as AlertSourceStep}
        getStepUrl={getStepUrl}
        mode={mode}
        text={
          step?.id === AlertSourceStepEnum.Configure
            ? "Save"
            : "Save and continue"
        }
      />
    </FloatingFooter>
  );
};
