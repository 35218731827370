import { useEffect } from "react";
import { unstable_useBlocker } from "react-router";
import { useSavedViews } from "src/components/saved-views/SavedViewContext";
import { useQueryParams } from "src/utils/query-params";

export const UnsavedViewChangesPrompt = () => {
  const queryParams = useQueryParams();
  const { viewIsDirty } = useSavedViews();

  const blocker = unstable_useBlocker(
    ({ nextLocation }) =>
      queryParams.get("view") != null &&
      viewIsDirty &&
      !nextLocation.pathname.match(/.*\/insights\/.+/),
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      const userConfirmed = window.confirm(
        "You have unsaved view changes, are you sure you want to leave insights?",
      );

      if (userConfirmed) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  });

  return null;
};
