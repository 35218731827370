import { DateRangePickerState } from "@incident-shared/forms/v1/DateRangePicker";
import { SelectOption, SelectOptionGroup } from "@incident-ui/Select/types";
import { createContext } from "react";

import { DateAggregation, FieldKey, StateConfig } from "./types";

export interface IInsightsContext<SC extends StateConfig> {
  // Common controls across insights dashboards
  dateRange: DateRangePickerState;
  setDateRange: (newDateRange: DateRangePickerState) => void;
  refetchFilters: () => Promise<void>;
  dateAggregation: DateAggregation;
  setDateAggregation: (newDateAggregation: DateAggregation) => void;
  comparePreviousPeriod: boolean;
  setComparePreviousPeriod: (compare: boolean) => void;

  // Dashboard specific controls
  useState: <K extends FieldKey>(
    key: K,
  ) => [
    SC[K] extends { isArray: true } ? string[] : string,
    (value: SC[K] extends { isArray: true } ? string[] : string) => void,
  ];

  getOptions: <K extends FieldKey>(
    key: K,
  ) => SC[K] extends { isGroupedOptions: true }
    ? SelectOptionGroup[]
    : SelectOption[];

  getFlatOptions: <K extends FieldKey>(key: K) => SelectOption[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InsightsContext = createContext<IInsightsContext<any> | null>(
  null,
);
