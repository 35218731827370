import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { AnchorProps } from "@incident-ui/Button/Button";
import { tcx } from "src/utils/tailwind-classes";

export type WorkflowCardButtonProps = {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  analyticsTrackingId: string | null;
} & AnchorProps;

// WorkflowCardButton wraps its children with a button
// that can be clicked to trigger an action.
export const WorkflowCardButton = ({
  onClick,
  analyticsTrackingId,
  children,
  target,
  href,
}: WorkflowCardButtonProps): React.ReactElement => {
  return (
    <Button
      analyticsTrackingId={analyticsTrackingId}
      onClick={onClick}
      href={href}
      target={target}
      theme={ButtonTheme.Secondary}
      // While buttons usually have a fixed height, we want out button to grow to fit the content
      className="!h-auto !py-3 !whitespace-normal text-left !justify-start"
    >
      {children}
    </Button>
  );
};

type WorkflowCardButtonIconProps = {
  icon: IconEnum;
  colorPalette?: ColorPaletteEnum;
};

// WorkflowCardButtonIcon provides a simple API for
// rendering an icon that looks right within a
// WorkflowCardButton - which can optionally be
// passed to the WorkflowCardButton as a child.
export const WorkflowCardButtonIcon = ({
  icon,
  colorPalette,
}: WorkflowCardButtonIconProps): React.ReactElement => {
  const colors = getColorPalette(colorPalette);
  return (
    <div
      className={tcx(
        "flex justify-center items-center !min-w-[36px] !max-w-[36px] !max-h-[36px] !min-h-[36px] p-[5px] mr-[10px] rounded",
        colors.background,
        colors.border,
        colors.text,
      )}
    >
      <Icon id={icon} size={IconSize.Large} className={colors.icon} />
    </div>
  );
};
