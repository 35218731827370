import { captureException } from "@sentry/react";
import _ from "lodash";
import { createContext, useContext } from "react";
import { Environment, getEnvironment } from "src/utils/environment";
import { v4 as uuid } from "uuid";

import { AvailableFilter } from "./utils";

// This type is also used in the dbt user-defined-function that applies these
// filters at the BigQuery layer, for Insights purposes.
//
// Don't change these filters unless you're also changing that file!
//
// https://github.com/incident-io/dbt/blob/master/user-defined-functions/filter_incidents.ts
export type FormFieldValue = {
  field_key: string;
  // unqiue identifier for this field, will usually match field_key but for custom fields
  // or roles it will be the unique ID of the specific field
  field_id: string;
  operator: string;

  bool_value?: boolean;
  multiple_options_value?: Array<string>;
  single_option_value?: string;
  string_value?: string;
};

export type ExtendedFormFieldValue = FormFieldValue & {
  key: string;
  // unique identifier for this filter, used to know which filter has been added/edited/removed
  filter_id: string;
  // identifier we'll use when calling the typeahead API to get possible values for this filter
  typeahead_lookup_id?: string | null;
  // if this filter is synthesised from a catalog type, this is the ID of that catalog type
  catalog_type_id?: string;
};

export type FiltersContextType = {
  filters: ExtendedFormFieldValue[];
  addFilter: (f: ExtendedFormFieldValue) => void;
  editFilter: (f: ExtendedFormFieldValue) => void;
  deleteFilter: (f: string) => void;
  clearFilters: () => void;
  availableFilterFields: AvailableFilter[];
  kind: FiltersContextKind;
};

export const FiltersContext = createContext<FiltersContextType | null>(null);

export type FiltersContextKind =
  | "incident"
  | "alert"
  | "user"
  | "escalation_targets"
  | "escalations";

export const FiltersContextProvider = ({
  filters,
  setFilters,
  availableFilterFields,
  kind,
  children,
}: React.PropsWithChildren<{
  filters: ExtendedFormFieldValue[];
  setFilters: (newFields: ExtendedFormFieldValue[]) => void;
  kind: FiltersContextKind;
  availableFilterFields: AvailableFilter[];
}>) => {
  return (
    <FiltersContext.Provider
      value={{
        kind,
        filters,
        addFilter: (f) => setFilters([...filters, { ...f, key: uuid() }]),
        editFilter: (f) =>
          setFilters(
            _.cloneDeep(filters).map((oldFilter) =>
              oldFilter.filter_id === f.filter_id ? f : oldFilter,
            ),
          ),
        deleteFilter: (filterId) =>
          setFilters(
            _.cloneDeep(filters).filter(
              (oldFilter) => oldFilter.filter_id !== filterId,
            ),
          ),
        clearFilters: () => setFilters([]),
        availableFilterFields,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = (): FiltersContextType => {
  const context = useContext(FiltersContext);
  if (context) {
    return context;
  }

  const error = new Error(
    "useFiltersContext hook must be used in a child of FiltersContextProvider",
  );

  const environment = getEnvironment();
  if (environment === Environment.Development) {
    throw error;
  }

  captureException(error);

  return {
    filters: [],
    addFilter: () => {
      // do nothing
    },
    editFilter: () => {
      // do nothing
    },
    deleteFilter: () => {
      // do nothing
    },
    clearFilters: () => {
      // do nothing
    },
    availableFilterFields: [],
    kind: "incident",
  };
};
