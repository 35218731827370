import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList } from "./helpers";

export const AlertSourceSumoLogicSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Go to the <strong>Manage Data → Monitoring → Connections</strong>{" "}
        section in Sumo Logic
      </p>
      <p>
        Click <strong>+</strong> in the top right and select{" "}
        <strong>Webhook</strong> as the connection type.
      </p>
      <p>Give your connection a name and description.</p>
      <div className="overflow-hidden flex flex-col gap-4 mb-4">
        <p>Configure the fields as below</p>
        <SingleLineCodeBlock
          title={"URL"}
          code={`${publicApiUrl()}/v2/alert_events/sumo_logic/${
            alertSourceConfig.id
          }`}
        />
        <SingleLineCodeBlock
          title={"Authorization"}
          code={`Bearer ${alertSourceConfig.secret_token}`}
        />
        <CodeBlock
          title={"Alert payload"}
          code={`{
\t"title": "{{Name}}",
\t"description": "{{Description}}",
\t"source_url": "{{AlertResponseURL}}",
  \t"status": "firing",
  \t"deduplication_key": "{{Id}}",
  \t"metadata": {
      \t"alert_response_url": "{{AlertResponseURL}}",
\t\t"monitor_type": "{{MonitorType}}",
      \t"query": {
\t\t\t"text": "{{Query}}",
          \t"ref": "{{QueryURL}}"
        },
      \t"detection_method": "{{DetectionMethod}}",
      \t"trigger": {
\t\t\t"type": "{{TriggerType}}",
          \t"condition": "{{TriggerCondition}}"
        },
      \t"results": "{{ResultsJSON}}"
    }
}`}
        />
        <CodeBlock
          title={"Resolve payload"}
          code={`{
\t"title": "{{Name}}",
\t"description": "{{Description}}",
\t"source_url": "{{AlertResponseURL}}",
  \t"status": "resolved",
  \t"deduplication_key": "{{Id}}",
  \t"metadata": {
      \t"alert_response_url": "{{AlertResponseURL}}",
\t\t"monitor_type": "{{MonitorType}}",
      \t"query": {
\t\t\t"text": "{{Query}}",
          \t"ref": "{{QueryURL}}"
        },
      \t"detection_method": "{{DetectionMethod}}",
      \t"trigger": {
\t\t\t"type": "{{TriggerType}}",
          \t"condition": "{{TriggerCondition}}"
        },
      \t"results": "{{ResultsJSON}}"
    }
}`}
        />
      </div>
      <p>
        Once you&rsquo;ve configured your payloads, click{" "}
        <strong>Test Alert</strong>. You should see a test alert on the right.
      </p>
      <p>
        To ensure the Recovery Payload is configured correctly, click{" "}
        <strong>Test Recovery</strong>. You should see the test alert resolve.
      </p>
      <p>
        Click <strong>Save</strong> to save your connection.
      </p>
      <p>
        You can now use your Sumo Logic connection to send alerts to
        incident.io. Do this by going to your monitor, and selecting the
        connection you just created under <strong>Notifications</strong>.
      </p>
    </SetupInfoNumberedList>
  );
};
