import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { STATUS_PAGE_INCIDENT_STATUS_NAME } from "@incident-shared/utils/StatusPages";
import { Icon, IconEnum, IconSize, Link } from "@incident-ui";
import {
  Incident,
  StatusPageIncidentUpdateToStatusEnum,
  TimelineItemObject,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { TimelineItemSource } from "../TimelineItemSource";
import { QuoteContentBox } from "./QuoteContextBox";
import { TimelineItemRenderProps } from "./TimelineItem";

export const TimelineItemStatusPageIncidentUpdated = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  const update = item.status_page_incident_updated;
  if (!update) {
    throw new Error(
      "malformed timeline item: status_page_incident_updated was missing status_page_incident_updated field",
    );
  }

  const {
    updater,
    status_page: page,
    status_page_incident: spInc,
    status_page_incident_update: spUpdate,
  } = update;
  const verb =
    spUpdate.sort_key === 1
      ? "created"
      : spUpdate.to_status === StatusPageIncidentUpdateToStatusEnum.Resolved
      ? "resolved"
      : "updated";

  const description = (
    <>
      <TimelineItemSource actor={updater} /> {verb} a status page incident
    </>
  );

  const linkClass = "font-medium flex !no-underline transition";

  return {
    icon: IconEnum.StatusPageHifi,
    avatarUrl: updater.user?.avatar_url,
    timestamp: item.occured_at,
    description,
    children: (
      <>
        <QuoteContentBox
          className="space-y-1.5"
          quoteClassName="py-1"
          preQuote={
            spInc.public_url ? (
              <Link
                href={spInc.public_url}
                openInNewTab
                analyticsTrackingId={null}
                className={tcx(linkClass, "group flex items-center flex-wrap")}
              >
                {page.name} - {spInc.name}
                <Icon
                  id={IconEnum.ExternalLink}
                  size={IconSize.Small}
                  className="ml-1 text-slate-400 transition group-hover:text-alarmalade-600"
                />
              </Link>
            ) : (
              <Link
                to={`/status-pages/${page.id}/incident/${spInc.id}`}
                analyticsTrackingId={null}
                className={linkClass}
              >
                {page.name} - {spInc.name}
              </Link>
            )
          }
          postQuote={
            <div className="gap-2 flex text-slate-600">
              {STATUS_PAGE_INCIDENT_STATUS_NAME[spUpdate.to_status]}
            </div>
          }
        >
          <TemplatedTextDisplay
            value={spUpdate.message}
            style={TemplatedTextDisplayStyle.Compact}
          />
        </QuoteContentBox>
      </>
    ),
  };
};
