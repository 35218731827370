import { JiraIssueTemplateContextEnum } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { useParams } from "react-router";

import { CreateEditJiraIssueTemplateModal } from "../integrations/list/jira/CreateEditJiraIssueTemplateModal";

export const JiraIssueTemplateCreateEditModal = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate(`/settings/follow-ups`);

  const { id } = useParams();

  return (
    <CreateEditJiraIssueTemplateModal
      id={id}
      context={JiraIssueTemplateContextEnum.FollowUp}
      onClose={onClose}
    />
  );
};
