import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { useFormContext } from "react-hook-form";
import { PostIncidentFlowCreateTaskConfigRequestBodyTaskTypeEnum as TaskType } from "src/contexts/ClientContext";
import { useDebriefName } from "src/utils/utils";

import { FormDataType } from "./FormDataType";

const taskTypeToDescription = (debriefNameLower) => ({
  [TaskType.Custom]:
    "E.g. Create a document in Notion and change the visibility to public.",
  [TaskType.SetCustomFields]: `E.g. We should capture the learnings we identified in our ${debriefNameLower}.`,
  [TaskType.SetTimestamps]: `E.g. We should set the values for the following timestamps to accurately capture key moments of the incident.`,
  [TaskType.AssignRole]: `E.g. The person assigned to this role should be best placed to run the ${debriefNameLower} meeting.`,
});

export const TaskDescriptionEditor = () => {
  const formMethods = useFormContext<FormDataType>();

  const selectedTaskType = formMethods.watch("task_type");
  const { debriefNameLower } = useDebriefName();

  if (!selectedTaskType) {
    return null;
  }

  return (
    <TemplatedTextInputV2
      key={selectedTaskType}
      name="description"
      formMethods={formMethods}
      label="Description"
      helptext="These instructions will be shown to responders when completing the task."
      format="mrkdwn"
      required="Please enter a description"
      includeExpressions={false}
      includeVariables={false}
      placeholder={taskTypeToDescription(debriefNameLower)[selectedTaskType]}
    />
  );
};
