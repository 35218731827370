import {
  DependentResource,
  SCIMShowSettingsResponseBody,
  UsersUpdateSeatAndRoleRequestBodySeatTypesEnum,
  UserWithRoles,
} from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { Callout, CalloutTheme, Link } from "@incident-ui";
import { LoadingModal, ModalFooter } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { RolesSelect, RolesSelectFormData } from "../common/RolesSelect";
import {
  SeatAndRolesSelect,
  SeatAndRolesSelectFormData,
} from "../common/SeatAndRolesSelect";
import {
  UserEditFormData,
  useUserEditFormMethods,
} from "../common/useUserEditFormMethods";
import { isScimEnabled } from "../scim/ScimShowPage";
import { SeatSelect, SeatSelectFormData } from "./SeatSelect";
import { useOnCallSeatDependentResources } from "./useOnCallSeatDependentResources";

export const UserEditModal = ({
  user,
  onClose,
  refetchUsers,
}: {
  user: UserWithRoles;
  onClose: () => void;
  refetchUsers: () => Promise<void>;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const { data: scimConfig } = useAPI("sCIMShowSettings", undefined);

  const { oncResourcesError, oncResourcesLoading, dependentResources } =
    useOnCallSeatDependentResources({
      user,
    });

  const { formMethods, onSubmit, saving, genericError } =
    useUserEditFormMethods(
      user,
      async () => {
        onClose();
      },
      refetchUsers,
    );

  if (oncResourcesError) {
    return <ErrorModal error={oncResourcesError} onClose={onClose} />;
  }

  if (!identity || !scimConfig || oncResourcesLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  return (
    <>
      <FormModalV2
        onClose={onClose}
        onSubmit={onSubmit}
        formMethods={formMethods}
        genericError={genericError}
        disableQuickClose
        title={`Edit permissions for ${user.name}`}
        analyticsTrackingId="edit-user-role"
        footer={
          <ModalFooter
            saving={saving}
            disabled={!formMethods.formState.isDirty}
            confirmButtonType="submit"
            onClose={onClose}
          />
        }
      >
        <EditForm
          formMethods={formMethods}
          user={user}
          scimConfig={scimConfig}
          dependentResources={dependentResources}
        />
      </FormModalV2>
    </>
  );
};

const EditForm = ({
  formMethods,
  user,
  scimConfig,
  dependentResources,
}: {
  formMethods: UseFormReturn<UserEditFormData>;
  user: UserWithRoles;
  scimConfig: SCIMShowSettingsResponseBody;
  dependentResources: DependentResource[];
}) => {
  const { onCallOnlyBillingSeats } = useFlags();

  const selectedState = formMethods.watch("state");

  if (isScimEnabled(scimConfig)) {
    const isLinkedWithScim = user.scim_user_id !== undefined;

    // This user doesn't have any permissions controlled by SCIM. That means we
    // can change their seat type manually.
    return (
      <>
        <Callout theme={CalloutTheme.Warning}>
          {isLinkedWithScim ? (
            <>
              Your organisation is using SCIM to configure user&apos;s roles. If
              you&apos;d like to grant them extra permissions you&apos;ll need
              to do this in your SCIM configuration.
            </>
          ) : (
            <>
              This user could not be linked to your organisation&apos;s SCIM
              directory. If you&apos;d like to grant them extra permissions
              you&apos;ll need to do this in your SCIM configuration.
            </>
          )}
        </Callout>
        <SeatSelect
          formMethods={
            formMethods as unknown as UseFormReturn<SeatSelectFormData>
          }
          userId={user.id}
          dependentResources={dependentResources}
          showSeatSelector={false}
        />
      </>
    );
  }

  if (!onCallOnlyBillingSeats) {
    return (
      <SeatAndRolesSelect
        userId={user.id}
        formMethods={
          formMethods as unknown as UseFormReturn<SeatAndRolesSelectFormData>
        }
        dependentResources={dependentResources}
      />
    );
  }

  // Non-SCIM is much simpler: role changes are fine.
  return (
    <>
      <div>
        <div className="px-2 pb-2">
          <RolesSelect
            selectedState={
              selectedState ??
              UsersUpdateSeatAndRoleRequestBodySeatTypesEnum.Viewer
            }
            userId={user.id}
            formMethods={
              formMethods as unknown as UseFormReturn<RolesSelectFormData>
            }
          />
        </div>
        <Callout
          theme={CalloutTheme.Info}
          className="mt-4 mb-1"
          title={
            <div className="text-sm-normal text-blue-800">
              Configure custom roles and permissions via the{" "}
              <Link
                to="/settings/users/roles"
                analyticsTrackingId={null}
                openInNewTab
              >
                Roles tab
              </Link>
              .
            </div>
          }
        />
      </div>
    </>
  );
};
