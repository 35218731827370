/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TimelineItemCommentsCreateRequestBody,
    TimelineItemCommentsCreateRequestBodyFromJSON,
    TimelineItemCommentsCreateRequestBodyToJSON,
    TimelineItemCommentsCreateResponseBody,
    TimelineItemCommentsCreateResponseBodyFromJSON,
    TimelineItemCommentsCreateResponseBodyToJSON,
    TimelineItemCommentsListResponseBody,
    TimelineItemCommentsListResponseBodyFromJSON,
    TimelineItemCommentsListResponseBodyToJSON,
    TimelineItemCommentsUpdateRequestBody,
    TimelineItemCommentsUpdateRequestBodyFromJSON,
    TimelineItemCommentsUpdateRequestBodyToJSON,
    TimelineItemCommentsUpdateResponseBody,
    TimelineItemCommentsUpdateResponseBodyFromJSON,
    TimelineItemCommentsUpdateResponseBodyToJSON,
} from '../models';

export interface TimelineItemCommentsCreateRequest {
    createRequestBody: TimelineItemCommentsCreateRequestBody;
}

export interface TimelineItemCommentsDestroyRequest {
    id: string;
}

export interface TimelineItemCommentsListRequest {
    incidentId: string;
    since?: Date;
}

export interface TimelineItemCommentsUpdateRequest {
    id: string;
    updateRequestBody: TimelineItemCommentsUpdateRequestBody;
}

/**
 * 
 */
export class TimelineItemCommentsApi extends runtime.BaseAPI {

    /**
     * Creates a timeline item comment
     * Create TimelineItemComments
     */
    async timelineItemCommentsCreateRaw(requestParameters: TimelineItemCommentsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TimelineItemCommentsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling timelineItemCommentsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/timeline_item_comments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimelineItemCommentsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimelineItemCommentsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates a timeline item comment
     * Create TimelineItemComments
     */
    async timelineItemCommentsCreate(requestParameters: TimelineItemCommentsCreateRequest, initOverrides?: RequestInit): Promise<TimelineItemCommentsCreateResponseBody> {
        const response = await this.timelineItemCommentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an existing timeline item comment
     * Destroy TimelineItemComments
     */
    async timelineItemCommentsDestroyRaw(requestParameters: TimelineItemCommentsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling timelineItemCommentsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/timeline_item_comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an existing timeline item comment
     * Destroy TimelineItemComments
     */
    async timelineItemCommentsDestroy(requestParameters: TimelineItemCommentsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.timelineItemCommentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Lists Timeline Item Comments
     * List TimelineItemComments
     */
    async timelineItemCommentsListRaw(requestParameters: TimelineItemCommentsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TimelineItemCommentsListResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling timelineItemCommentsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        if (requestParameters.since !== undefined) {
            queryParameters['since'] = (requestParameters.since as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/timeline_item_comments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimelineItemCommentsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists Timeline Item Comments
     * List TimelineItemComments
     */
    async timelineItemCommentsList(requestParameters: TimelineItemCommentsListRequest, initOverrides?: RequestInit): Promise<TimelineItemCommentsListResponseBody> {
        const response = await this.timelineItemCommentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing timeline item comment
     * Update TimelineItemComments
     */
    async timelineItemCommentsUpdateRaw(requestParameters: TimelineItemCommentsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TimelineItemCommentsUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling timelineItemCommentsUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling timelineItemCommentsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/timeline_item_comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TimelineItemCommentsUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimelineItemCommentsUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing timeline item comment
     * Update TimelineItemComments
     */
    async timelineItemCommentsUpdate(requestParameters: TimelineItemCommentsUpdateRequest, initOverrides?: RequestInit): Promise<TimelineItemCommentsUpdateResponseBody> {
        const response = await this.timelineItemCommentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
