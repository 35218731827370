/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemGenerationOptions,
    PostmortemGenerationOptionsFromJSON,
    PostmortemGenerationOptionsFromJSONTyped,
    PostmortemGenerationOptionsToJSON,
} from './PostmortemGenerationOptions';

/**
 * 
 * @export
 * @interface PostmortemsEnqueueCreateDocumentRequestBody
 */
export interface PostmortemsEnqueueCreateDocumentRequestBody {
    /**
     * 
     * @type {PostmortemGenerationOptions}
     * @memberof PostmortemsEnqueueCreateDocumentRequestBody
     */
    generation_options?: PostmortemGenerationOptions;
    /**
     * 
     * @type {string}
     * @memberof PostmortemsEnqueueCreateDocumentRequestBody
     */
    incident_id: string;
    /**
     * 
     * @type {string}
     * @memberof PostmortemsEnqueueCreateDocumentRequestBody
     */
    postmortem_document_id?: string;
}

export function PostmortemsEnqueueCreateDocumentRequestBodyFromJSON(json: any): PostmortemsEnqueueCreateDocumentRequestBody {
    return PostmortemsEnqueueCreateDocumentRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsEnqueueCreateDocumentRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsEnqueueCreateDocumentRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generation_options': !exists(json, 'generation_options') ? undefined : PostmortemGenerationOptionsFromJSON(json['generation_options']),
        'incident_id': json['incident_id'],
        'postmortem_document_id': !exists(json, 'postmortem_document_id') ? undefined : json['postmortem_document_id'],
    };
}

export function PostmortemsEnqueueCreateDocumentRequestBodyToJSON(value?: PostmortemsEnqueueCreateDocumentRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'generation_options': PostmortemGenerationOptionsToJSON(value.generation_options),
        'incident_id': value.incident_id,
        'postmortem_document_id': value.postmortem_document_id,
    };
}

