import { Callout, CalloutTheme } from "@incident-ui";
import { DynamicMultiSelectWithObj } from "@incident-ui/Select/DynamicMultiSelect";
import { useState } from "react";
import { FieldValues, Path, useController } from "react-hook-form";
import {
  getTypeaheadOptions,
  TypeaheadTypeEnum,
} from "src/components/@shared/forms/Typeahead";
import { useClient } from "src/contexts/ClientContext";

import { FormInputWrapperV2 } from "../FormInputWrapperV2";
import { InputElementProps, parseProps } from "../formsv2";
import {
  PrivateChannelInput,
  ToggleUsePrivateChannelButton,
} from "./SlackChannelEditorV2";

export const SlackChannelsEditorV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, SlackChannelEditorProps>,
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const {
    // We don't want to pass the ref onwards here: react-select and our wrappers
    // have their own refs, so we always use a controlled input here.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapperV2<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <SlackChannelsEditor
        {...inputProps}
        {...field}
        disabled={props.disabled}
      />
    </FormInputWrapperV2>
  );
};

export type SlackChannelFormValue = {
  value: string;
  label: string;
  is_private: boolean;
  is_archived?: boolean;
  is_deleted?: boolean;
};

type SlackChannelEditorProps = {
  name: string;
  initialValue?: SlackChannelFormValue[];
  value: SlackChannelFormValue[];
  onChange: (val: SlackChannelFormValue[]) => void;
  onBlur: () => void;
  disallowPrivateChannels?: boolean;
  placeholder?: string;
  disabled?: boolean;
  hideWarning?: boolean;
};

const SlackChannelsEditor = ({
  name,
  value,
  onChange,
  onBlur,
  initialValue,
  disallowPrivateChannels,
  placeholder,
  disabled,
  hideWarning,
}: SlackChannelEditorProps): React.ReactElement => {
  const [usePrivateChannel, setUsePrivateChannel] = useState(
    initialValue?.length === 1 && initialValue[0].is_private,
  );
  const apiClient = useClient();

  value?.forEach((v) => {
    if (!v.label.startsWith("#")) {
      v.label = `#${v.label}`;
    }
  });

  return (
    <>
      <InvalidSlackChannelWarning value={value} />
      {usePrivateChannel ? (
        <PrivateChannelInput
          name={`${name}.0.value`}
          hideWarning={hideWarning}
        />
      ) : (
        <DynamicMultiSelectWithObj
          value={value}
          onChange={(val) =>
            onChange(val.map((v) => ({ ...v, is_private: false })))
          }
          isDisabled={disabled}
          loadOptions={getTypeaheadOptions(
            apiClient,
            TypeaheadTypeEnum.SlackChannel,
          )}
          onBlur={onBlur}
          placeholder={placeholder ?? "Select channels"}
        />
      )}
      {!disallowPrivateChannels && (
        <ToggleUsePrivateChannelButton
          usePrivateChannel={usePrivateChannel}
          disabled={disabled}
          onClick={() => {
            onChange(
              usePrivateChannel
                ? []
                : [{ is_private: true, label: "", value: "" }],
            );
            setUsePrivateChannel(!usePrivateChannel);
          }}
        />
      )}
    </>
  );
};

const InvalidSlackChannelWarning = ({
  value,
}: {
  value: SlackChannelFormValue[];
}) => {
  const archivedChannels = value.filter((v) => v.is_archived);
  const deletedChannels = value.filter((v) => v.is_deleted);

  if (archivedChannels.length > 0 || deletedChannels.length > 0) {
    return (
      <Callout theme={CalloutTheme.Danger} className="my-2">
        <div className="space-y-2">
          <p>
            Some of the selected channels are no longer available to announce
            to. Please choose another channel.
          </p>
          {archivedChannels.length > 0 && (
            <p>
              The following channels are archived:{" "}
              {archivedChannels
                .map((v) => (v.is_private ? v.value : v.label))
                .join(", ")}
            </p>
          )}
          {deletedChannels.length > 0 && (
            <p>
              The following channels are deleted:{" "}
              {deletedChannels
                .map((v) => (v.is_private ? v.value : v.label))
                .join(", ")}
            </p>
          )}
        </div>
      </Callout>
    );
  }

  return null;
};
