import { AlertSourceSourceTypeEnum } from "@incident-io/api";
import { Icon, IconProps } from "@incident-ui/Icon/Icon";
import { ALERT_SOURCE_TYPE_CONFIGS } from "src/components/@shared/integrations/IntegrationConfig";

export const AlertSourceTypeIcon = ({
  sourceType,
  ...props
}: {
  sourceType: AlertSourceSourceTypeEnum;
} & Omit<IconProps, "id">) => {
  if (!sourceType) return null;

  const config = ALERT_SOURCE_TYPE_CONFIGS[sourceType];

  return <Icon {...props} id={config.icon} className={config.className} />;
};
