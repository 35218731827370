import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  DependentResource,
  ScopeNameEnum,
  StatusPage,
  StatusPageSetStructureAndComponentsRequestBodyDisplayUptimeModeEnum as DisplayUptimeModeRequestEnum,
  StatusPageStructure,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { ComponentsEditor } from "../common/ComponentsEditor";
import {
  buildDefaultValues,
  buildStructureAndComponentsItems,
  EditablePath,
  FormType,
} from "../common/ComponentsEditor/utils";

export const StatusPagesEditStructurePage = ({
  page,
  structure,
  dependentsForComponent,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
  dependentsForComponent: Record<string, DependentResource[] | undefined>;
}): React.ReactElement | null => {
  const formMethods = useForm<FormType>({
    defaultValues: buildDefaultValues({ ...page, structure }),
  });

  // We only allow one component or group to be inline-edited at a time,
  // otherwise things start looking messy. There's an exception to this rule
  // which is that if a group/component has an empty name we'll leave it in
  // editing-mode so that it can receive validation errors.
  const [editing, setEditing] = useState<EditablePath>(null);

  const showToast = useToast();
  const {
    trigger: saveStructure,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, data: FormType) => {
      await apiClient.statusPageSetStructureAndComponents({
        statusPageId: page.id,
        setStructureAndComponentsRequestBody: {
          items: buildStructureAndComponentsItems(data),
          display_uptime_mode:
            data.displayUptimeMode as unknown as DisplayUptimeModeRequestEnum,
        },
      });
    },
    {
      onSuccess: (res) => {
        formMethods.reset(
          buildDefaultValues({
            ...res.status_page,
            structure: res.current_structure,
          }),
        );

        setEditing(null);

        showToast({
          theme: ToastTheme.Success,
          title: "Success",
          description: "Successfully saved status page structure",
        });
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Error",
          description: "Error saving status page structure",
        });
      },
    },
  );

  const { hasScope } = useIdentity();
  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  return (
    <FormV2
      formMethods={formMethods}
      onSubmit={saveStructure}
      innerClassName="bg-surface-secondary rounded-[6px] p-4 border border-stroke w-full"
    >
      <h3 className="font-medium !-mb-2">Components</h3>
      <ErrorMessageUI message={genericError} />
      <ComponentsEditor
        dependentsForComponent={dependentsForComponent}
        editing={editing}
        setEditing={setEditing}
        saving={saving}
      />

      <GatedButton
        type="submit"
        theme={ButtonTheme.Primary}
        analyticsTrackingId={"status-page-edit-structure"}
        analyticsTrackingMetadata={{ status_page_id: page.id }}
        loading={saving}
        disabled={missingPermission || !formMethods.formState.isDirty}
        disabledTooltipContent={
          missingPermission
            ? "You do not have permission to configure this public status page"
            : undefined
        }
      >
        Save
      </GatedButton>
    </FormV2>
  );
};
