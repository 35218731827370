/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AICreateAssistantResponseFeedbackRequestBody,
    AICreateAssistantResponseFeedbackRequestBodyFromJSON,
    AICreateAssistantResponseFeedbackRequestBodyToJSON,
    AICreateAssistantThreadQuestionRequestBody,
    AICreateAssistantThreadQuestionRequestBodyFromJSON,
    AICreateAssistantThreadQuestionRequestBodyToJSON,
    AICreateAssistantThreadQuestionResponseBody,
    AICreateAssistantThreadQuestionResponseBodyFromJSON,
    AICreateAssistantThreadQuestionResponseBodyToJSON,
    AICreateAssistantThreadResponseBody,
    AICreateAssistantThreadResponseBodyFromJSON,
    AICreateAssistantThreadResponseBodyToJSON,
    AIListAssistantThreadMessagesResponseBody,
    AIListAssistantThreadMessagesResponseBodyFromJSON,
    AIListAssistantThreadMessagesResponseBodyToJSON,
    AIShowAIAssistantResponseBody,
    AIShowAIAssistantResponseBodyFromJSON,
    AIShowAIAssistantResponseBodyToJSON,
    AIShowAssistantThreadRunResponseBody,
    AIShowAssistantThreadRunResponseBodyFromJSON,
    AIShowAssistantThreadRunResponseBodyToJSON,
    AIShowConfigResponseBody,
    AIShowConfigResponseBodyFromJSON,
    AIShowConfigResponseBodyToJSON,
    AITestPromptRequestBody,
    AITestPromptRequestBodyFromJSON,
    AITestPromptRequestBodyToJSON,
    AITestPromptResponseBody,
    AITestPromptResponseBodyFromJSON,
    AITestPromptResponseBodyToJSON,
    AIToggleFeatureRequestBody,
    AIToggleFeatureRequestBodyFromJSON,
    AIToggleFeatureRequestBodyToJSON,
    AIToggleFeatureResponseBody,
    AIToggleFeatureResponseBodyFromJSON,
    AIToggleFeatureResponseBodyToJSON,
    AIUpdateIncidentSummariesConfigRequestBody,
    AIUpdateIncidentSummariesConfigRequestBodyFromJSON,
    AIUpdateIncidentSummariesConfigRequestBodyToJSON,
    AIUpdateIncidentSummariesConfigResponseBody,
    AIUpdateIncidentSummariesConfigResponseBodyFromJSON,
    AIUpdateIncidentSummariesConfigResponseBodyToJSON,
} from '../models';

export interface AICreateAssistantResponseFeedbackRequest {
    createAssistantResponseFeedbackRequestBody: AICreateAssistantResponseFeedbackRequestBody;
}

export interface AICreateAssistantThreadQuestionRequest {
    assistantId: string;
    threadId: string;
    createAssistantThreadQuestionRequestBody: AICreateAssistantThreadQuestionRequestBody;
}

export interface AIListAssistantThreadMessagesRequest {
    assistantId: string;
    threadId: string;
}

export interface AIShowAssistantThreadRunRequest {
    assistantId: string;
    threadId: string;
    runId: string;
}

export interface AITestPromptRequest {
    testPromptRequestBody: AITestPromptRequestBody;
}

export interface AIToggleFeatureRequest {
    toggleFeatureRequestBody: AIToggleFeatureRequestBody;
}

export interface AIUpdateIncidentSummariesConfigRequest {
    updateIncidentSummariesConfigRequestBody: AIUpdateIncidentSummariesConfigRequestBody;
}

/**
 * 
 */
export class AIApi extends runtime.BaseAPI {

    /**
     * Give feedback on a response Assistant has given.
     * CreateAssistantResponseFeedback AI
     */
    async aICreateAssistantResponseFeedbackRaw(requestParameters: AICreateAssistantResponseFeedbackRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createAssistantResponseFeedbackRequestBody === null || requestParameters.createAssistantResponseFeedbackRequestBody === undefined) {
            throw new runtime.RequiredError('createAssistantResponseFeedbackRequestBody','Required parameter requestParameters.createAssistantResponseFeedbackRequestBody was null or undefined when calling aICreateAssistantResponseFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/assistant/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AICreateAssistantResponseFeedbackRequestBodyToJSON(requestParameters.createAssistantResponseFeedbackRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Give feedback on a response Assistant has given.
     * CreateAssistantResponseFeedback AI
     */
    async aICreateAssistantResponseFeedback(requestParameters: AICreateAssistantResponseFeedbackRequest, initOverrides?: RequestInit): Promise<void> {
        await this.aICreateAssistantResponseFeedbackRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new Thread with the AI Assistant.
     * CreateAssistantThread AI
     */
    async aICreateAssistantThreadRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AICreateAssistantThreadResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/assistant/thread`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AICreateAssistantThreadResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new Thread with the AI Assistant.
     * CreateAssistantThread AI
     */
    async aICreateAssistantThread(initOverrides?: RequestInit): Promise<AICreateAssistantThreadResponseBody> {
        const response = await this.aICreateAssistantThreadRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create a new question in a Thread with the AI Assistant.
     * CreateAssistantThreadQuestion AI
     */
    async aICreateAssistantThreadQuestionRaw(requestParameters: AICreateAssistantThreadQuestionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AICreateAssistantThreadQuestionResponseBody>> {
        if (requestParameters.assistantId === null || requestParameters.assistantId === undefined) {
            throw new runtime.RequiredError('assistantId','Required parameter requestParameters.assistantId was null or undefined when calling aICreateAssistantThreadQuestion.');
        }

        if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
            throw new runtime.RequiredError('threadId','Required parameter requestParameters.threadId was null or undefined when calling aICreateAssistantThreadQuestion.');
        }

        if (requestParameters.createAssistantThreadQuestionRequestBody === null || requestParameters.createAssistantThreadQuestionRequestBody === undefined) {
            throw new runtime.RequiredError('createAssistantThreadQuestionRequestBody','Required parameter requestParameters.createAssistantThreadQuestionRequestBody was null or undefined when calling aICreateAssistantThreadQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/assistant/{assistant_id}/thread/{thread_id}/question`.replace(`{${"assistant_id"}}`, encodeURIComponent(String(requestParameters.assistantId))).replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AICreateAssistantThreadQuestionRequestBodyToJSON(requestParameters.createAssistantThreadQuestionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AICreateAssistantThreadQuestionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new question in a Thread with the AI Assistant.
     * CreateAssistantThreadQuestion AI
     */
    async aICreateAssistantThreadQuestion(requestParameters: AICreateAssistantThreadQuestionRequest, initOverrides?: RequestInit): Promise<AICreateAssistantThreadQuestionResponseBody> {
        const response = await this.aICreateAssistantThreadQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the status of a Run for a specific Thread on the AI Assistant.
     * ListAssistantThreadMessages AI
     */
    async aIListAssistantThreadMessagesRaw(requestParameters: AIListAssistantThreadMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIListAssistantThreadMessagesResponseBody>> {
        if (requestParameters.assistantId === null || requestParameters.assistantId === undefined) {
            throw new runtime.RequiredError('assistantId','Required parameter requestParameters.assistantId was null or undefined when calling aIListAssistantThreadMessages.');
        }

        if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
            throw new runtime.RequiredError('threadId','Required parameter requestParameters.threadId was null or undefined when calling aIListAssistantThreadMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/assistant/{assistant_id}/thread/{thread_id}/messages`.replace(`{${"assistant_id"}}`, encodeURIComponent(String(requestParameters.assistantId))).replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIListAssistantThreadMessagesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Gets the status of a Run for a specific Thread on the AI Assistant.
     * ListAssistantThreadMessages AI
     */
    async aIListAssistantThreadMessages(requestParameters: AIListAssistantThreadMessagesRequest, initOverrides?: RequestInit): Promise<AIListAssistantThreadMessagesResponseBody> {
        const response = await this.aIListAssistantThreadMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Shows if the organisation has an OpenAI Assistant.
     * ShowAIAssistant AI
     */
    async aIShowAIAssistantRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIShowAIAssistantResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/assistant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIShowAIAssistantResponseBodyFromJSON(jsonValue));
    }

    /**
     * Shows if the organisation has an OpenAI Assistant.
     * ShowAIAssistant AI
     */
    async aIShowAIAssistant(initOverrides?: RequestInit): Promise<AIShowAIAssistantResponseBody> {
        const response = await this.aIShowAIAssistantRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the status of a Run for a specific Thread on the AI Assistant.
     * ShowAssistantThreadRun AI
     */
    async aIShowAssistantThreadRunRaw(requestParameters: AIShowAssistantThreadRunRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIShowAssistantThreadRunResponseBody>> {
        if (requestParameters.assistantId === null || requestParameters.assistantId === undefined) {
            throw new runtime.RequiredError('assistantId','Required parameter requestParameters.assistantId was null or undefined when calling aIShowAssistantThreadRun.');
        }

        if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
            throw new runtime.RequiredError('threadId','Required parameter requestParameters.threadId was null or undefined when calling aIShowAssistantThreadRun.');
        }

        if (requestParameters.runId === null || requestParameters.runId === undefined) {
            throw new runtime.RequiredError('runId','Required parameter requestParameters.runId was null or undefined when calling aIShowAssistantThreadRun.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/assistant/{assistant_id}/thread/{thread_id}/run/{run_id}`.replace(`{${"assistant_id"}}`, encodeURIComponent(String(requestParameters.assistantId))).replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId))).replace(`{${"run_id"}}`, encodeURIComponent(String(requestParameters.runId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIShowAssistantThreadRunResponseBodyFromJSON(jsonValue));
    }

    /**
     * Gets the status of a Run for a specific Thread on the AI Assistant.
     * ShowAssistantThreadRun AI
     */
    async aIShowAssistantThreadRun(requestParameters: AIShowAssistantThreadRunRequest, initOverrides?: RequestInit): Promise<AIShowAssistantThreadRunResponseBody> {
        const response = await this.aIShowAssistantThreadRunRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the AI config
     * ShowConfig AI
     */
    async aIShowConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIShowConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIShowConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the AI config
     * ShowConfig AI
     */
    async aIShowConfig(initOverrides?: RequestInit): Promise<AIShowConfigResponseBody> {
        const response = await this.aIShowConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Test a prompt against a set list of incidents.
     * TestPrompt AI
     */
    async aITestPromptRaw(requestParameters: AITestPromptRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AITestPromptResponseBody>> {
        if (requestParameters.testPromptRequestBody === null || requestParameters.testPromptRequestBody === undefined) {
            throw new runtime.RequiredError('testPromptRequestBody','Required parameter requestParameters.testPromptRequestBody was null or undefined when calling aITestPrompt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/test-prompt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AITestPromptRequestBodyToJSON(requestParameters.testPromptRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AITestPromptResponseBodyFromJSON(jsonValue));
    }

    /**
     * Test a prompt against a set list of incidents.
     * TestPrompt AI
     */
    async aITestPrompt(requestParameters: AITestPromptRequest, initOverrides?: RequestInit): Promise<AITestPromptResponseBody> {
        const response = await this.aITestPromptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * When true, we\'ll suggest an AI-generated summary after an incident update.
     * ToggleFeature AI
     */
    async aIToggleFeatureRaw(requestParameters: AIToggleFeatureRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIToggleFeatureResponseBody>> {
        if (requestParameters.toggleFeatureRequestBody === null || requestParameters.toggleFeatureRequestBody === undefined) {
            throw new runtime.RequiredError('toggleFeatureRequestBody','Required parameter requestParameters.toggleFeatureRequestBody was null or undefined when calling aIToggleFeature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/config/actions/toggle_feature`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIToggleFeatureRequestBodyToJSON(requestParameters.toggleFeatureRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIToggleFeatureResponseBodyFromJSON(jsonValue));
    }

    /**
     * When true, we\'ll suggest an AI-generated summary after an incident update.
     * ToggleFeature AI
     */
    async aIToggleFeature(requestParameters: AIToggleFeatureRequest, initOverrides?: RequestInit): Promise<AIToggleFeatureResponseBody> {
        const response = await this.aIToggleFeatureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the settings for the AI summaries feature.
     * UpdateIncidentSummariesConfig AI
     */
    async aIUpdateIncidentSummariesConfigRaw(requestParameters: AIUpdateIncidentSummariesConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIUpdateIncidentSummariesConfigResponseBody>> {
        if (requestParameters.updateIncidentSummariesConfigRequestBody === null || requestParameters.updateIncidentSummariesConfigRequestBody === undefined) {
            throw new runtime.RequiredError('updateIncidentSummariesConfigRequestBody','Required parameter requestParameters.updateIncidentSummariesConfigRequestBody was null or undefined when calling aIUpdateIncidentSummariesConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/incident_summaries_section_labels`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AIUpdateIncidentSummariesConfigRequestBodyToJSON(requestParameters.updateIncidentSummariesConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIUpdateIncidentSummariesConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates the settings for the AI summaries feature.
     * UpdateIncidentSummariesConfig AI
     */
    async aIUpdateIncidentSummariesConfig(requestParameters: AIUpdateIncidentSummariesConfigRequest, initOverrides?: RequestInit): Promise<AIUpdateIncidentSummariesConfigResponseBody> {
        const response = await this.aIUpdateIncidentSummariesConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
