import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { IncidentsListContextProvider } from "@incident-shared/incidents";
import {
  Button,
  ButtonTheme,
  DynamicMultiSelect,
  IconEnum,
  ModalFooter,
  StackedList,
} from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import {
  isSelectOption,
  isSelectOptionGroup,
  SelectOption,
  SelectOptionGroup,
  SelectOptionOrGroup,
} from "@incident-ui/Select/types";
import _ from "lodash";
import { useForm, useFormContext } from "react-hook-form";
import { CondensedIncidentListItem } from "src/components/@shared/incidents/CondensedIncidentListItem";
import {
  Incident,
  IncidentRelationship,
  IncidentRelationshipDetails,
  IncidentRelationshipStatusEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

type FormData = {
  incident_ids: string[];
};

export const AddRelatedIncidentModal = ({
  incident,
  relatedIncidents,
  onClose,
}: {
  incident: Incident;
  relatedIncidents: IncidentRelationship[];
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<FormData>({
    defaultValues: { incident_ids: [] },
  });

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    "incidentRelationshipsList",
    { incidentId: incident.id },
    async (apiClient, data: FormData) => {
      await Promise.all(
        data.incident_ids.map((incidentId) =>
          apiClient.incidentRelationshipsCreate({
            createRequestBody: {
              left_incident_id: incidentId,
              right_incident_id: incident.id,
              status: IncidentRelationshipStatusEnum.Confirmed,
            },
          }),
        ),
      );
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  const incidentIds = formMethods.watch("incident_ids");

  const { buildGroups, filterOptions } = useSearchIncidentsOptionsLogic(
    relatedIncidents,
    incident.id,
    incidentIds,
  );

  return (
    <FormModalV2<FormData>
      isExtraLarge
      formMethods={formMethods}
      analyticsTrackingId="add-related-incident"
      onClose={onClose}
      onSubmit={onSubmit}
      title="Add related incident"
      genericError={genericError}
      footer={
        <ModalFooter
          disabled={incidentIds.length === 0}
          saving={saving}
          confirmButtonText={
            incidentIds.length > 0
              ? `Link ${incidentIds.length} incident${
                  incidentIds.length > 1 ? "s" : ""
                }`
              : `Link`
          }
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <FormInputWrapperV2
        name="incident_ids"
        label="We'll link the current incident to these incidents"
      >
        <IncidentSearchInput
          onSelect={(incidentId) => {
            if (_.intersection(incidentIds, [incidentId]).length === 0) {
              formMethods.setValue("incident_ids", [
                ...incidentIds,
                incidentId,
              ]);
            }
          }}
          filterOptions={filterOptions}
          buildGroups={buildGroups}
        />
      </FormInputWrapperV2>

      <RelatedIncidentsPreview
        remove={(incidentId) => {
          formMethods.setValue(
            "incident_ids",
            incidentIds.filter((id) => id !== incidentId),
          );
        }}
      />
    </FormModalV2>
  );
};

const RelatedIncidentsPreview = ({
  remove,
}: {
  remove: (incidentId: string) => void;
}) => {
  const formMethods = useFormContext<FormData>();
  const incident_ids = formMethods.watch("incident_ids");

  return (
    <IncidentsListContextProvider>
      <>
        {incident_ids.length > 0 && (
          <StackedList>
            {incident_ids.map((incidentId) => (
              <ChosenIncident
                key={incidentId}
                incidentId={incidentId}
                remove={() => remove(incidentId)}
              />
            ))}
          </StackedList>
        )}
      </>
    </IncidentsListContextProvider>
  );
};

const ChosenIncident = ({
  incidentId,
  remove,
}: {
  incidentId: string;
  remove: () => void;
}) => {
  const { data } = useAPI("incidentsShow", {
    id: incidentId,
  });

  return data?.incident ? (
    <CondensedIncidentListItem
      incident={data.incident}
      showIncidentTypes={false}
      openInNewTab
      hideArrow
      accessory={
        <Button
          theme={ButtonTheme.Naked}
          onClick={remove}
          analyticsTrackingId="remove-related-incident"
          className="pr-2"
          icon={IconEnum.Close}
        >
          {""}
        </Button>
      }
    />
  ) : (
    <LoadingWrapper loading={true}>
      <div className="flex w-full p-3">Loading incident...</div>
    </LoadingWrapper>
  );
};

const IncidentSearchInput = ({
  onSelect,
  buildGroups,
  filterOptions,
}: {
  onSelect: (incidentId: string) => void;
  filterOptions: (options: SelectOptionOrGroup[]) => SelectOptionOrGroup[];
  buildGroups: (
    optionOrGroupCollection: SelectOptionOrGroup[],
    filterInput?: string,
  ) => SelectOptionOrGroup[];
}) => {
  const apiClient = useClient();
  const buildOptions = (options: SelectOptionOrGroup[], filterInput?: string) =>
    buildGroups(filterOptions(options), filterInput);

  return (
    <DynamicMultiSelect
      // we don't want to show any values in the select.
      // we are using this component as a dropdown with a typeahead
      value={[]}
      onChange={(value) => value.forEach((option) => onSelect(option))}
      closeMenuOnSelect={true}
      loadOptions={async (input) => {
        const options = await getTypeaheadOptions(
          apiClient,
          TypeaheadTypeEnum.Incident,
        )(input);

        return buildOptions(options, input);
      }}
      hydrateOptions={async (input) => {
        const options = await hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.Incident,
        )(input);

        return buildOptions(options);
      }}
      placeholder={"Start typing or select from the dropdown"}
    />
  );
};

const useSearchIncidentsOptionsLogic = (
  relationships: IncidentRelationship[],
  currentIncidentId: string,
  selectedIncidentIds: string[],
) => {
  const relationshipsByStatus: {
    confirmed?: IncidentRelationship[];
    suggested?: IncidentRelationship[];
  } = _.groupBy(
    relationships,
    (relationship) =>
      relationship.status === "confirmed" ? "confirmed" : "suggested", // we collapse suggested and created together, we don't care if we have notified the user in Slack yet
  );

  const relatedIncidentsByStatus: {
    confirmed?: IncidentRelationshipDetails[];
    suggested?: IncidentRelationshipDetails[];
  } = _.mapValues(
    relationshipsByStatus,
    (relationships) =>
      relationships?.flatMap(
        ({ left_incident_details, right_incident_details }) =>
          [left_incident_details, right_incident_details].filter(
            (incDetails) => incDetails.id !== currentIncidentId,
          ),
      ),
  );

  return {
    filterOptions: (options: SelectOptionOrGroup[]) => {
      const optionIsValid = (option: SelectOption) => {
        // not the current incident
        if (option.value === currentIncidentId) {
          return false;
        }
        // not already selected
        if (selectedIncidentIds.includes(option.value)) {
          return false;
        }
        // not already an already confirmed relationship
        if (
          _.some(
            relatedIncidentsByStatus.confirmed,
            (inc) => inc.id === option.value,
          )
        ) {
          return false;
        }
        return true;
      };

      return options
        .filter((optionOrGroup) => {
          // lets filter the options first
          if (isSelectOptionGroup(optionOrGroup)) {
            return true;
          }
          return optionIsValid(optionOrGroup);
        })
        .map((optionOrGroup) => {
          // lets then filter the options of the groups
          if (isSelectOptionGroup(optionOrGroup)) {
            return {
              ...optionOrGroup,
              options: optionOrGroup.options.filter(optionIsValid),
            };
          }
          return optionOrGroup;
        });
    },
    buildGroups: (
      optionOrGroupCollection: SelectOptionOrGroup[],
      filterInput?: string,
    ) => {
      // 1. Group of suggested options
      const suggestedOptionsGroup: SelectOptionGroup = {
        label: "✨ Suggested related incidents",
        options: (relatedIncidentsByStatus?.suggested ?? [])
          .sort((a, b) => b.externalId - a.externalId)
          .map((incident) => ({
            label: `#INC-${incident.externalId}: ${incident.name}`,
            value: incident.id,
          }))
          .filter(
            (option) => !filterInput || option.label.includes(filterInput),
          ),
      };
      // 2. All other groups
      const groups = optionOrGroupCollection.filter((option) =>
        isSelectOptionGroup(option),
      ) as SelectOptionGroup[];

      // 3. Bucket all options into a final group
      const everythingElseGroup: SelectOptionGroup = {
        label: "All",
        options: (
          optionOrGroupCollection.filter(isSelectOption) as SelectOption[]
        ).filter(
          // remove options that we have in the suggested section
          (option) =>
            _.some(
              suggestedOptionsGroup.options,
              (suggestedOption) => suggestedOption.value === option.value,
            ) === false,
        ),
      };
      return [suggestedOptionsGroup, ...groups, everythingElseGroup];
    },
  };
};
