import {
  Button,
  ButtonModal,
  ButtonTheme,
  Link,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { v4 as uuid } from "uuid";

type ChannelInfo = {
  channelUrl: string;
  channelName: string;
};

export const TutorialPrompt = ({
  buttonTheme = ButtonTheme.Secondary,
  alwaysShowButton = false,
}: {
  buttonTheme?: ButtonTheme;
  alwaysShowButton?: boolean;
}): React.ReactElement | null => {
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [channelInfo, setChannelInfo] = useState<ChannelInfo | null>(null);
  const { identity } = useIdentity();

  const idempotencyKey = uuid();

  const { trigger: createTutorial, isMutating: loading } = useAPIMutation(
    // This might change "have you done a tutorial"
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      const { channel_url: channelUrl, channel_name: channelName } =
        await apiClient.tutorialsCreate({
          createRequestBody: {
            idempotency_key: idempotencyKey,
          },
        });
      setChannelInfo({ channelUrl, channelName });
      setShowTutorialModal(true);
    },
  );

  if (!identity) {
    return <Loader />;
  }

  if (!alwaysShowButton && identity.has_created_tutorial) {
    return null;
  }

  if (!identity.can_create_tutorial_incidents) {
    return null;
  }

  return (
    <>
      <ButtonModal
        buttonProps={{
          analyticsTrackingId: "start-tutorial-intro",
          children: "Start tutorial",
          theme: buttonTheme,
        }}
        modalProps={{
          analyticsTrackingId: "start-tutorial-intro",
          title: "Start a tutorial incident",
        }}
        renderModalContents={({ onClose }) => (
          <>
            <ModalContent>
              A tutorial incident is the best way to get to know incident.io.
              We&rsquo;ll invite you to a fresh new Slack channel, and walk you
              through everything you need to know about running an incident.
            </ModalContent>
            <ModalFooter
              confirmButtonType="button"
              confirmButtonText="Let’s go!"
              saving={loading}
              onConfirm={() => {
                createTutorial({}).then(() => onClose());
              }}
            />
          </>
        )}
      />
      <Modal
        analyticsTrackingId="start-tutorial"
        onClose={() => setShowTutorialModal(false)}
        title="Start your tutorial"
        isOpen={showTutorialModal && !loading && channelInfo != null}
      >
        <ModalContent>
          <div>
            <p>
              We&apos;ve created{" "}
              <Link
                analyticsTrackingId="open-tutorial-channel"
                href={channelInfo?.channelUrl ?? ""}
                className="font-medium"
              >
                #{channelInfo?.channelName}
              </Link>{" "}
              and invited you, head over there to get started.
            </p>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            analyticsTrackingId="tutorial-prompt-join-channel"
            theme={ButtonTheme.Primary}
            className="mt-4"
            href={channelInfo?.channelUrl}
            openInNewTab
          >
            Join channel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
