/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';
import {
    PagerDutyIncidentTemplate,
    PagerDutyIncidentTemplateFromJSON,
    PagerDutyIncidentTemplateFromJSONTyped,
    PagerDutyIncidentTemplateToJSON,
} from './PagerDutyIncidentTemplate';

/**
 * 
 * @export
 * @interface LegacyIncidentTriggerConfig
 */
export interface LegacyIncidentTriggerConfig {
    /**
     * Whether we should auto-decline incidents that match our predefined rules
     * @type {boolean}
     * @memberof LegacyIncidentTriggerConfig
     */
    auto_decline_enabled: boolean;
    /**
     * Under what conditions will we auto-create incidents for PagerDuty incidents?
     * @type {Array<ConditionGroup>}
     * @memberof LegacyIncidentTriggerConfig
     */
    condition_groups?: Array<ConditionGroup>;
    /**
     * How long after an Incident fires on a service do we assume this is probably related, rather than defaulting to assuming this is a new thing
     * @type {number}
     * @memberof LegacyIncidentTriggerConfig
     */
    debounce_window_in_minutes?: number;
    /**
     * Whether auto creation for this trigger is enabled
     * @type {boolean}
     * @memberof LegacyIncidentTriggerConfig
     */
    enabled: boolean;
    /**
     * The expressions to use in the workflow
     * @type {Array<Expression>}
     * @memberof LegacyIncidentTriggerConfig
     */
    expressions?: Array<Expression>;
    /**
     * Unique identifier of the resource type
     * @type {string}
     * @memberof LegacyIncidentTriggerConfig
     */
    external_resource_type: LegacyIncidentTriggerConfigExternalResourceTypeEnum;
    /**
     * 
     * @type {PagerDutyIncidentTemplate}
     * @memberof LegacyIncidentTriggerConfig
     */
    incident_template?: PagerDutyIncidentTemplate;
    /**
     * Unique identifier of the corresponding organisation
     * @type {string}
     * @memberof LegacyIncidentTriggerConfig
     */
    organisation_id: string;
}

/**
* @export
* @enum {string}
*/
export enum LegacyIncidentTriggerConfigExternalResourceTypeEnum {
    PagerDutyIncident = 'pager_duty_incident',
    OpsgenieAlert = 'opsgenie_alert'
}

export function LegacyIncidentTriggerConfigFromJSON(json: any): LegacyIncidentTriggerConfig {
    return LegacyIncidentTriggerConfigFromJSONTyped(json, false);
}

export function LegacyIncidentTriggerConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegacyIncidentTriggerConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_decline_enabled': json['auto_decline_enabled'],
        'condition_groups': !exists(json, 'condition_groups') ? undefined : ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'debounce_window_in_minutes': !exists(json, 'debounce_window_in_minutes') ? undefined : json['debounce_window_in_minutes'],
        'enabled': json['enabled'],
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'external_resource_type': json['external_resource_type'],
        'incident_template': !exists(json, 'incident_template') ? undefined : PagerDutyIncidentTemplateFromJSON(json['incident_template']),
        'organisation_id': json['organisation_id'],
    };
}

export function LegacyIncidentTriggerConfigToJSON(value?: LegacyIncidentTriggerConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_decline_enabled': value.auto_decline_enabled,
        'condition_groups': value.condition_groups === undefined ? undefined : ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'debounce_window_in_minutes': value.debounce_window_in_minutes,
        'enabled': value.enabled,
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'external_resource_type': value.external_resource_type,
        'incident_template': PagerDutyIncidentTemplateToJSON(value.incident_template),
        'organisation_id': value.organisation_id,
    };
}

