// we special case this filter field in a bunch of places.
export const FULL_TEXT_SEARCH = "full_text_search";
export const INCLUDE_PRIVATE = "include_private";
export const INCIDENT_TYPE = "incident_type";
export const ALERT_STATUS = "status";
export const ALERT_ID = "id";
export const INCIDENT_ID = "id";

// make sure full_text_search, although present in the config, is hidden
// from the add and edit modals. We do this because the full text search is special-cased
// in the UI, and is always present. include_private is the same.
export const SPECIAL_INCIDENT_FILTERS = [
  FULL_TEXT_SEARCH,
  INCLUDE_PRIVATE,
  INCIDENT_ID,
];
export const SPECIAL_ALERT_FILTERS = [FULL_TEXT_SEARCH, ALERT_STATUS, ALERT_ID];

export const SPECIAL_ESCALATION_FILTERS = [FULL_TEXT_SEARCH];
