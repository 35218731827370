import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconSize,
} from "@incident-ui";
import { useAPI } from "src/utils/swr";

export const InternalStatusPageLinkButton = (): React.ReactElement | null => {
  const { data, isLoading } = useAPI("internalStatusPageListPages", undefined);
  const navigate = useOrgAwareNavigate();

  if (isLoading || !data || data?.internal_status_pages?.length === 0) {
    return null;
  }

  if (data.internal_status_pages.length === 1) {
    const statusConfig = data.internal_status_pages[0].has_ongoing_incident
      ? COMPONENT_STATUS_CONFIG.partial_outage
      : COMPONENT_STATUS_CONFIG.operational;

    return (
      <Button
        href={"/status"}
        iconProps={{
          className: statusConfig.colour,
        }}
        icon={statusConfig.icon}
        theme={ButtonTheme.Secondary}
        analyticsTrackingId={"visit-isp-from-dashboard"}
      >
        View internal status
      </Button>
    );
  }

  const statusConfig = data.internal_status_pages.some(
    (p) => p.has_ongoing_incident,
  )
    ? COMPONENT_STATUS_CONFIG.partial_outage
    : COMPONENT_STATUS_CONFIG.operational;

  return (
    <DropdownMenu
      triggerButton={
        <Button
          iconProps={{
            className: statusConfig.colour,
          }}
          icon={statusConfig.icon}
          theme={ButtonTheme.Secondary}
          analyticsTrackingId="open-dashboard-isp-dropdown"
        >
          View internal status
        </Button>
      }
    >
      {data.internal_status_pages.map((page) => (
        <DropdownMenuItem
          icon={
            page.has_ongoing_incident
              ? COMPONENT_STATUS_CONFIG.partial_outage.icon
              : COMPONENT_STATUS_CONFIG.operational.icon
          }
          iconProps={{
            className: page.has_ongoing_incident
              ? COMPONENT_STATUS_CONFIG.partial_outage.colour
              : COMPONENT_STATUS_CONFIG.operational.colour,
            size: IconSize.Medium,
          }}
          key={page.id}
          onSelect={() => {
            navigate(`/status/${page.subpath}`);
          }}
          analyticsTrackingId={"visit-isp-from-dashboard-dropdown"}
          label={page.name}
        />
      ))}
    </DropdownMenu>
  );
};
