import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  Link,
  Modal,
  ModalContent,
  ModalFooter,
  StackedList,
  Tooltip,
} from "@incident-ui";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useState } from "react";
import { useIntercom } from "react-use-intercom";
import {
  DebriefPlaceholder,
  GoogleWorkspaceCalendarAccessRoleEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useDebriefName } from "src/utils/utils";

import { SettingsListItem } from "../../@shared/settings/SettingsList/SettingsListItem";
import { ExternalLink } from "../../legacy/incident/sidebar/ExternalLink";
import { SettingsSubHeading } from "../SettingsSubHeading";

export const DebriefPlaceholdersSection = ({
  placeholders,
}: {
  placeholders: DebriefPlaceholder[];
}): React.ReactElement => {
  const [showAddPlaceholderModal, setShowAddPlaceholderModal] =
    useState<boolean>(false);

  return (
    <div>
      {/* Heading, explanation, and click to copy detection string. */}
      <SettingsSubHeading
        title="Placeholders"
        titleHeadingLevel={2}
        className="pt-4"
        explanationClassName="mr-4 mt-1 max-w-4xl"
        accessory={
          <AddPlaceholderButton
            onClick={() => setShowAddPlaceholderModal(true)}
          />
        }
        explanation={
          <>
            Instead of the debrief organiser being responsible for scheduling a
            debrief, you can track placeholder timeslots for when your debriefs
            should be scheduled. We&apos;ll show the organiser all the possible
            options when scheduling a debrief for an incident, and we&apos;ll
            create the event in the chosen placeholder.
            <br />
            <span className="font-semibold">
              We will not suggest placeholder slots if they are shorter than the
              debrief&apos;s duration.
            </span>
          </>
        }
      />

      {/* Empty state. */}
      {placeholders.length === 0 && (
        <EmptyState
          icon={IconEnum.Calendar}
          content="You have not registered any placeholders yet"
        />
      )}

      {/* Display placeholders. */}
      {placeholders.length > 0 && (
        <DebriefPlaceholdersList placeholders={placeholders} />
      )}

      {/* Show the modal. */}
      {showAddPlaceholderModal && (
        <AddPlaceholderModal
          onClose={() => setShowAddPlaceholderModal(false)}
        />
      )}
    </div>
  );
};

const AddPlaceholderButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <GatedButton
      onClick={onClick}
      requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
      analyticsTrackingId="add-debrief-placeholder"
      icon={IconEnum.Add}
      theme={ButtonTheme.Secondary}
    >
      Add placeholder
    </GatedButton>
  );
};

const AddPlaceholderModal = ({ onClose }: { onClose: () => void }) => {
  const showToast = useToast();
  const { debriefName } = useDebriefName();

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText("#incident-io-debrief-placeholder");
    showToast({
      theme: ToastTheme.Info,
      title: "Copied to clipboard.",
      toastSide: ToastSideEnum.TopRight,
    });
  };

  const getEventStartAndEnd = (): {
    start: string;
    end: string;
    day: string;
  } => {
    const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
    const startTime = new Date();
    startTime.setHours(startTime.getHours() + 1, 0, 0, 0); // Set to the next full hour

    const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // 1 hour later

    const dayOfWeek = days[startTime.getDay()];

    return {
      start: startTime.toISOString().replace(/-|:|\.\d\d\d/g, ""), // Format for Google Calendar URL
      end: endTime.toISOString().replace(/-|:|\.\d\d\d/g, ""),
      day: dayOfWeek,
    };
  };

  const { start, end, day } = getEventStartAndEnd();

  const baseURL = "https://calendar.google.com/calendar/render?action=TEMPLATE";
  const eventName = `Incident ${debriefName} Placeholder`;
  const eventDescription = "#incident-io-debrief-placeholder";
  const repeatWeekly = `RRULE:FREQ=WEEKLY;BYDAY=${day}`;

  const googleCalendarURL = `${baseURL}&text=${encodeURIComponent(
    eventName,
  )}&dates=${start}/${end}&details=${encodeURIComponent(
    eventDescription,
  )}&recur=${encodeURIComponent(repeatWeekly)}`;

  return (
    <Modal
      isOpen
      onClose={onClose}
      title="Add placeholder"
      analyticsTrackingId="settings-debriefs-register-placeholder-modal"
    >
      <ModalContent className="text-sm">
        <p className="mb-2">
          A placeholder is a repeating event in one of your{" "}
          <Link
            to="/settings/integrations/google-calendar"
            analyticsTrackingId={`settings-debriefs-register-placeholder-modal-go-to-cals`}
          >
            subscribed calendars
          </Link>{" "}
          which contains the following phrase in the description:
        </p>

        <Tooltip content={"Click to copy to clipboard."}>
          <div
            className="bg-surface-tertiary px-4 py-4 rounded-2 flex justify-between items-center hover:cursor-pointer mb-2 hover:border-slate-900 border border-stroke"
            onClick={handleCopyToClipboard}
          >
            <code>#incident-io-debrief-placeholder</code>
            <span>
              <Icon size={IconSize.Medium} id={IconEnum.Copy} />
            </span>
          </div>
        </Tooltip>

        <p className="mb-2">
          If you have an existing placeholder for debriefs, make sure it meets
          these requirements, otherwise you can use the button below to create a
          new placeholder event.
        </p>

        <p>
          We&apos;ll detect it within a few seconds of the event being created
          or updated.
        </p>
      </ModalContent>

      <ModalFooter
        confirmButtonText={"Create with Google Calendar"}
        confirmButtonType={"button"}
        onConfirm={() => {
          window.open(googleCalendarURL, "_blank");
          onClose();
        }}
        onClose={onClose}
        hideConfirmButton={false}
      />
    </Modal>
  );
};

const strippedID = (id: string): string => {
  return id.split("_R")[0];
};

const DebriefPlaceholdersList = ({
  placeholders,
}: {
  placeholders: DebriefPlaceholder[];
}): React.ReactElement => {
  const { showArticle } = useIntercom();

  // If a user makes non-time changes to future occurances of a scheduled event
  // we will get two events with similar IDs, but different suffixes.
  //
  // This will look like: asdfasdfas987897123123123_R20220101T000000
  // So, dropping the suffix will remove duplicated events as reported in PR-1327
  const deduplicatedPlaceholders = placeholders.filter(
    (placeholder, index, self) =>
      index ===
      self.findIndex(
        (p) =>
          strippedID(p.external_id) === strippedID(placeholder.external_id),
      ),
  );

  return (
    <StackedList>
      {deduplicatedPlaceholders.map((placeholder) => {
        let accessory = (
          <Badge theme={BadgeTheme.Info} icon={IconEnum.Calendar}>
            {placeholder.calendar.name}
          </Badge>
        );
        if (
          !(
            placeholder.calendar.access_role ===
              GoogleWorkspaceCalendarAccessRoleEnum.Writer ||
            placeholder.calendar.access_role ===
              GoogleWorkspaceCalendarAccessRoleEnum.Owner
          )
        ) {
          accessory = (
            <Tooltip
              content={
                <>
                  <span>
                    The connected user does not have permission to create events
                    in this calendar, so we won&apos;t show any timeslots for
                    this placeholder.{" "}
                  </span>
                  <Button
                    onClick={() => showArticle(8344655)}
                    theme={ButtonTheme.Link}
                    analyticsTrackingId={"settings-learn-more"}
                  >
                    Learn more
                  </Button>
                </>
              }
            >
              <Button theme={ButtonTheme.Unstyled} analyticsTrackingId={null}>
                <Badge icon={IconEnum.Warning} theme={BadgeTheme.Error}>
                  {placeholder.calendar.name}
                </Badge>
              </Button>
            </Tooltip>
          );
        }
        return (
          <SettingsListItem
            key={placeholder.id}
            title={
              <ExternalLink
                analyticsTrackingId={
                  "settings-debriefs-placeholder-gcal-permalink"
                }
                href={placeholder.permalink}
                label={placeholder.title}
                icon={IconEnum.GoogleCalendar}
              />
            }
            accessory={accessory}
            description={
              <>
                {placeholder.recurrence_description}, at{" "}
                {placeholder.start_time} ({placeholder.duration} mins)
              </>
            }
          />
        );
      })}
    </StackedList>
  );
};
