import { OrgAwareLink } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Card,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
  Loader,
  TabSection,
} from "@incident-ui";
import { partition, sortBy, uniq } from "lodash";
import React, { useState } from "react";
import graphic from "src/components/settings/banners/banner-forms.svg";
import {
  IncidentForm,
  IncidentFormFormTypeEnum as FormTypeEnum,
  IncidentType,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";

import { SettingsHeading } from "../../SettingsHeading";
import { SettingsSubPageWrapper } from "../../SettingsRoute";
import { FORM_TYPE_CONFIG, formEditPath } from "../common/config";
import { IncidentFormsCreateModal } from "./IncidentFormsCreateModal";

export const IncidentFormsListPage = (): React.ReactElement => {
  const { identity } = useIdentity();

  const {
    data: { incident_forms: forms },
    error: formsError,
    isLoading: formsLoading,
  } = useAPI("incidentFormsListForms", undefined, {
    fallbackData: { incident_forms: [] },
  });
  const {
    data: { incident_types: incidentTypes },
    isLoading: incidentTypesLoading,
    error: incidentTypesError,
  } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });
  const {
    data: { escalation_paths: escalationPaths },
    isLoading: escalationPathsLoading,
    error: escalationPathsError,
  } = useAPI("escalationPathsList", undefined, {
    fallbackData: { escalation_paths: [], first_level_users: {} },
  });

  const isLoading =
    formsLoading || incidentTypesLoading || escalationPathsLoading;

  if (formsError || incidentTypesError || escalationPathsError) {
    return (
      <GenericErrorMessage
        error={formsError ?? incidentTypesError ?? escalationPathsError}
      />
    );
  }

  const filteredForms = forms.filter((f) => {
    let include = true;
    // Hide the escalate form if the org doesn't have any incident.io escalation paths
    if (escalationPaths.length === 0 && f.form_type === FormTypeEnum.Escalate) {
      include = false;
    }
    return include;
  });

  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        articleId={8460306}
        title="Customize user forms"
        subtitle="Design the forms users fill out during different stages of an incident."
        graphic={<img src={graphic} className="h-40" />}
      />
      <div>
        {isLoading || !identity ? (
          <Loader />
        ) : (
          <IncidentFormsPerTypeTabs
            forms={filteredForms}
            incidentTypes={incidentTypes}
          />
        )}
      </div>
    </SettingsSubPageWrapper>
  );
};

const IncidentFormsPerTypeTabs = ({
  forms,
  incidentTypes,
}: {
  forms: IncidentForm[];
  incidentTypes: IncidentType[];
}) => {
  const { identity } = useIdentity();

  const defaultIncidentType = useQueryParams().get("incident_type");
  const [selectedIncidentTypeID, setSelectedIncidentTypeID] = useState<
    string | null
  >(defaultIncidentType);

  const incidentTypesIDsWithForms = forms.map((form) => form.incident_type_id);

  // This tracks when a user adds an incident type using the right-hand dropdown.
  // This allows us to show that tab, even when there's no forms.
  const [incidentTypeIDsAdded, setIncidentTypeIDsAdded] = useState<string[]>(
    [],
  );

  const incidentTypeIDTabsToShow = uniq([
    ...incidentTypesIDsWithForms,
    ...incidentTypeIDsAdded,
    selectedIncidentTypeID,
  ]);

  const [incidentTypesToShow, incidentTypesToHide] = partition(
    incidentTypes,
    (incidentType) => incidentTypeIDTabsToShow.includes(incidentType.id),
  );

  if (!identity?.incident_types_enabled || incidentTypes.length === 1) {
    return (
      <IncidentFormsList
        forms={forms}
        incidentTypeId={null}
        incidentTypes={incidentTypes}
      />
    );
  }

  const onTabChange = (tabId: string) => {
    setSelectedIncidentTypeID(tabId === "default" ? null : tabId);
  };

  const onAddIncidentType = (incidentTypeId: string) => {
    setIncidentTypeIDsAdded([...incidentTypeIDsAdded, incidentTypeId]);
    setSelectedIncidentTypeID(incidentTypeId);
  };

  const tabs = [
    {
      id: "default",
      label: "All incidents",
    },
    ...incidentTypesToShow.map((type) => ({
      id: type.id,
      label: type.name,
    })),
  ];

  return (
    <TabSection
      withIndicator
      value={selectedIncidentTypeID ?? "default"}
      tabBarClassName="border-b border-stroke mb-4"
      tabs={tabs}
      defaultTab="default"
      onTabChange={onTabChange}
      tabBarAccessory={
        <DropdownMenu
          align="start"
          triggerButton={
            <Button
              analyticsTrackingId="incident-forms-add-type"
              className="ml-auto"
              theme={ButtonTheme.Naked}
              icon={IconEnum.Add}
            >
              Incident type
            </Button>
          }
          menuClassName="w-[220px]"
        >
          {incidentTypesToHide.map((incidentType) => (
            <DropdownMenuItem
              className="truncate max-w-[220px] !block text-left"
              key={incidentType.id}
              label={incidentType.name}
              onSelect={() => onAddIncidentType(incidentType.id)}
              analyticsTrackingId={null}
            />
          ))}
          {incidentTypesToHide.length === 0 && (
            <div className="text-sm text-content-tertiary p-2">
              All incident types are in use
            </div>
          )}
        </DropdownMenu>
      }
    >
      <IncidentFormsList
        forms={forms}
        incidentTypeId={selectedIncidentTypeID}
        incidentTypes={incidentTypes}
      />
    </TabSection>
  );
};

const IncidentFormsList = ({
  incidentTypeId,
  forms,
  incidentTypes,
}: {
  forms: IncidentForm[];
  incidentTypes: IncidentType[];
  incidentTypeId: string | null;
}) => {
  const filteredForms = forms.filter(
    (f) => f.incident_type_id === (incidentTypeId || undefined),
  );

  const sortedForms = sortBy(
    filteredForms,
    (f) => FORM_TYPE_CONFIG[f.form_type].rank,
  );

  const availableForms = Object.entries(FORM_TYPE_CONFIG).filter(
    ([_, config]) => !config.secret,
  );

  const canAddAnotherForm =
    incidentTypeId && sortedForms.length < availableForms.length;

  const [showAddFormModal, setShowAddFormModal] = useState(false);

  return (
    <div className="grid gap-4 xl:grid-cols-3 md:grid-cols-2">
      {sortedForms.map((form) => {
        const config = FORM_TYPE_CONFIG[form.form_type];
        return (
          <OrgAwareLink to={formEditPath(form)} key={form.id}>
            <Card
              key={form.id}
              icon={config.icon}
              title={config.label}
              color={ColorPaletteEnum.Blue}
              description={config.description}
              clickable={true}
              requiredProduct={config.requiredProduct}
            />
          </OrgAwareLink>
        );
      })}
      {canAddAnotherForm && (
        <button onClick={() => setShowAddFormModal(true)}>
          <Card
            key="add"
            className="!shadow-none border-dashed hover:!shadow-md"
            icon={IconEnum.Add}
            title={
              <span className="text-content-secondary">
                {sortedForms.length === 0
                  ? "Override a form"
                  : "Override another form"}
              </span>
            }
            color={ColorPaletteEnum.SlateOnWhite}
          />
        </button>
      )}
      {showAddFormModal && incidentTypeId && (
        <IncidentFormsCreateModal
          allForms={forms}
          allIncidentTypes={incidentTypes}
          incidentTypeId={incidentTypeId}
          onClose={() => setShowAddFormModal(false)}
        />
      )}
    </div>
  );
};
