/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalesforceConfig
 */
export interface SalesforceConfig {
    /**
     * Which Salesforce account was used to connect
     * @type {string}
     * @memberof SalesforceConfig
     */
    connected_username?: string;
    /**
     * If set, this indicates an issue with the connection
     * @type {string}
     * @memberof SalesforceConfig
     */
    reconnection_reason?: SalesforceConfigReconnectionReasonEnum;
    /**
     * Which Salesforce account list is connected
     * @type {string}
     * @memberof SalesforceConfig
     */
    synced_account_list_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum SalesforceConfigReconnectionReasonEnum {
    Empty = '',
    PagerdutyLimitedUser = 'pagerduty_limited_user',
    GithubMissingScopes = 'github_missing_scopes',
    TokenExpired = 'token_expired',
    InvalidConfig = 'invalid_config',
    InsufficientPermissions = 'insufficient_permissions',
    IncompleteConfig = 'incomplete_config',
    SalesforceTooManyAccounts = 'salesforce_too_many_accounts',
    MultipleAccounts = 'multiple_accounts'
}

export function SalesforceConfigFromJSON(json: any): SalesforceConfig {
    return SalesforceConfigFromJSONTyped(json, false);
}

export function SalesforceConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesforceConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connected_username': !exists(json, 'connected_username') ? undefined : json['connected_username'],
        'reconnection_reason': !exists(json, 'reconnection_reason') ? undefined : json['reconnection_reason'],
        'synced_account_list_id': !exists(json, 'synced_account_list_id') ? undefined : json['synced_account_list_id'],
    };
}

export function SalesforceConfigToJSON(value?: SalesforceConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connected_username': value.connected_username,
        'reconnection_reason': value.reconnection_reason,
        'synced_account_list_id': value.synced_account_list_id,
    };
}

