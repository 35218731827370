import {
  Button,
  ButtonSize,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { isEmpty } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import {
  IncidentsGetSummarySuggestionResponseBody,
  useClient,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { useIncident } from "../hooks";
import { PendingSummarySuggestion } from "./PendingSummarySuggestion";
import { SummaryDisplay } from "./SummaryDisplay";
import { SummaryEditor } from "./SummaryEditor";

// SummaryContext allows other children of the IncidentBody to trigger opening the summary editor
export const SummaryContext = createContext<{
  isEditingSummary: boolean;
  setIsEditingSummary: (a: boolean) => void;
} | null>(null);
export const useSummaryContext = (): {
  isEditingSummary: boolean;
  setIsEditingSummary: (a: boolean) => void;
} => {
  const context = useContext(SummaryContext);
  if (!context) {
    throw new Error(
      `useSummaryContext must be used as a child of a SummaryContext provider`,
    );
  }

  return context;
};

export const Summary = ({ incidentId }: { incidentId: string | null }) => {
  return (
    <SummaryWrapper>
      {incidentId == null ? (
        <LoadingBar className="w-full h-5" />
      ) : (
        <SummaryInner incidentId={incidentId} />
      )}
    </SummaryWrapper>
  );
};

const SummaryInner = ({
  incidentId,
}: {
  incidentId: string;
}): React.ReactElement => {
  const apiClient = useClient();
  const { incident, isLoading: incidentLoading } = useIncident(incidentId);

  // Default to not polling for suggestions
  const [refreshIntervalMs, setRefreshIntervalMs] = useState(0);

  const {
    data: suggestion,
    isLoading: suggestionLoading,
    mutate: refetchSuggestion,
  } = useAPI(
    "incidentsGetSummarySuggestion",
    {
      id: incidentId,
    },
    {
      refreshInterval: refreshIntervalMs,
    },
  );

  // If AI is available to you, and there is no suggestion: poll every 10 seconds
  useEffect(() => {
    if (
      suggestion &&
      suggestion.has_feature &&
      suggestion.has_suggestion === false
    ) {
      setRefreshIntervalMs(10_000);
    }
  }, [suggestion]);

  const { isEditingSummary, setIsEditingSummary } = useSummaryContext();

  const [acceptedSuggestion, setAcceptedSuggestion] =
    useState<IncidentsGetSummarySuggestionResponseBody>();

  // When editing is cancelled, revert back to the incident's current summary
  const onEditCancel = () => {
    setIsEditingSummary(false);
  };

  // When the suggestion is accepted, set the summary to the suggested one
  const onSuggestionAccept = (
    suggestionData: IncidentsGetSummarySuggestionResponseBody,
  ) => {
    setAcceptedSuggestion(suggestionData);
    setIsEditingSummary(true);
  };

  // When the suggestion is rejected, call the API to reject it
  const onSuggestionReject = async (
    suggestionData: IncidentsGetSummarySuggestionResponseBody,
  ) => {
    setAcceptedSuggestion(undefined);
    if (!incident || !suggestionData.suggestion) {
      return;
    }
    await apiClient.incidentsRejectSummarySuggestion({
      incidentId: incident.id,
      suggestionId: suggestionData.suggestion.id,
    });
    refetchSuggestion();
  };

  if (incidentLoading || suggestionLoading || incident == null) {
    return <LoadingBar className="w-full h-[28px]" />;
  }

  if (isEditingSummary) {
    return (
      <>
        {!acceptedSuggestion && (
          <PendingSummarySuggestion
            suggestionData={suggestion}
            onAccept={onSuggestionAccept}
            onReject={onSuggestionReject}
          />
        )}
        <SummaryEditor
          incidentId={incidentId}
          summary={incident.summary}
          suggestion={acceptedSuggestion?.suggestion}
          onCancel={onEditCancel}
          onSave={async () => {
            await refetchSuggestion();
            acceptedSuggestion && setAcceptedSuggestion(undefined);
            setIsEditingSummary(false);
          }}
        />
      </>
    );
  }

  if (isEmpty(incident.summary)) {
    return (
      <>
        <div className="flex text-sm-med gap-4 items-center text-content-tertiary">
          <div className="border border-slate-100 p-1 rounded-1 border-dashed border-2 -my-0.5">
            <Icon id={IconEnum.Quote} size={IconSize.Medium} />
          </div>
          <div className="grow">No summary yet</div>
          <Button
            analyticsTrackingId={"add-summary"}
            icon={IconEnum.Add}
            theme={ButtonTheme.Secondary}
            size={ButtonSize.Small}
            onClick={() => setIsEditingSummary(true)}
            className="intercom-summary"
          >
            Add a summary
          </Button>
        </div>
        <PendingSummarySuggestion
          suggestionData={suggestion}
          onAccept={onSuggestionAccept}
          onReject={onSuggestionReject}
        />
      </>
    );
  }

  return (
    <>
      <PendingSummarySuggestion
        suggestionData={suggestion}
        onAccept={onSuggestionAccept}
        onReject={onSuggestionReject}
      />
      <SummaryDisplay
        summary={incident.summary}
        onStartEditing={() => setIsEditingSummary(true)}
      />
    </>
  );
};

const SummaryWrapper = ({ children }: { children: React.ReactNode }) => (
  <div
    className={tcx(
      "transition duration-300",
      "shadow-sm hover:shadow-md",
      "border border-stroke-primary hover:border-stroke-hover",
      "p-6 rounded-lg flex flex-col gap-4",
    )}
  >
    {children}
  </div>
);
