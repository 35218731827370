import { OrgAwareLink } from "@incident-shared/org-aware";
import React from "react";
import { Actor } from "src/contexts/ClientContext";

export const TimelineItemSource = ({
  actor,
  lowercase,
}: {
  lowercase?: boolean;
  actor?: Actor;
}): React.ReactElement => {
  const content = actor?.workflow ? (
    <a href={`/workflows/${actor.workflow.id}`}>
      Workflow: {actor.workflow.name}
    </a>
  ) : actor?.user ? (
    <>{actor.user.name}</>
  ) : actor?.api_key ? (
    <OrgAwareLink
      to={"/settings/api-keys"}
      target="_blank"
      rel="noopener noreferrer"
    >
      API Key: {actor.api_key.name}
    </OrgAwareLink>
  ) : actor?.external_resource ? (
    <span>{lowercase ? "we" : "We"}</span>
  ) : actor?.alert ? (
    <OrgAwareLink
      to={`/alerts/sources`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {lowercase ? "an alert" : "An alert"}
    </OrgAwareLink>
  ) : (
    // We have a tiny number of timeline items which have neither a user nor a
    // workflow, as they were created before we started attributing timeline
    // items properly. This is a hack so they render in an acceptable way.
    <>{lowercase ? "an" : "An"} automation</>
  );

  const isFound = actor?.workflow
    ? true
    : actor?.user
    ? true
    : actor?.api_key
    ? true
    : false;

  // We want non-bold if we're just returning "An automation" or "We"
  if (!isFound) return content;
  return <strong>{content}</strong>;
};
