import { UserPreferences } from "@incident-io/api";
import { Checkbox, Modal } from "@incident-io/status-page-ui";
import { ReactElement } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

// This is largely a copy of ../../incident/header/UserPreferencesModal.tsx, but
// with styling adapted to internal status pages.
export const SubscribeModal = ({
  incidentId,
  internalStatusPageId,
  onClose,
  userPreferences,
}: {
  onClose: () => void;
  userPreferences: UserPreferences;
} & (
  | { incidentId: string; internalStatusPageId?: never }
  | { internalStatusPageId: string; incidentId?: never }
)): ReactElement => {
  const { identity } = useIdentity();

  const formMethods = useForm<UserPreferences>({
    defaultValues: {
      ...userPreferences,
    },
  });
  const {
    setError,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = formMethods;

  const refetchPrefs = useAPIRefetch("userPreferencesShow", undefined);
  const { trigger: onSubmit } = useAPIMutation(
    incidentId
      ? "incidentSubscriptionsGet"
      : "incidentSubscriptionsListAutoSubscribeRules",
    incidentId ? { incidentId } : undefined,
    async (apiClient, data: UserPreferences) => {
      await apiClient.userPreferencesUpdateSubscriptionPreferences({
        updateSubscriptionPreferencesRequestBody: {
          receives_subscriptions_via_email:
            data.receives_subscriptions_via_email || false,
          receives_subscriptions_via_slack_DM:
            data.receives_subscriptions_via_slack_DM || false,
          // we don't let the user configure SMS here, so we should use whatever their current config is
          receives_subscriptions_via_sms:
            userPreferences.receives_subscriptions_via_sms || false,
        },
      });
      // Don't await this - it's not worth waiting for
      refetchPrefs();

      if (incidentId) {
        await apiClient.incidentSubscriptionsCreate({
          createRequestBody: { incident_id: incidentId },
        });
      }

      if (internalStatusPageId) {
        await apiClient.incidentSubscriptionsCreateAutoSubscribeRule({
          createAutoSubscribeRuleRequestBody: {
            internal_status_page_id: internalStatusPageId,
            conditions: [],
          },
        });
      }
    },
    {
      onSuccess: onClose,
      setError,
    },
  );

  const validateAndSubmit: SubmitHandler<UserPreferences> = async (data) => {
    const hasNoMethods =
      !data.receives_subscriptions_via_slack_DM &&
      !data.receives_subscriptions_via_email &&
      !data.receives_subscriptions_via_sms;

    if (hasNoMethods) {
      // Attach the error to the email button as it's at the bottom - sad
      setError("receives_subscriptions_via_email", {
        type: "manual",
        message: "You must select at least one method of notification",
      });
      return;
    }

    await onSubmit(data);
  };

  return (
    <FormProvider {...formMethods}>
      <Modal
        as="form"
        isOpen={true}
        title={"Subscribe to updates"}
        onClose={onClose}
        analyticsTrackingId="internal-status-page-subscribe-preferences-modal"
        submitButtonText="Subscribe"
        onSubmit={handleSubmit(validateAndSubmit)}
        loading={isSubmitting}
      >
        <div className="space-y-4">
          <div className="text-sm">
            {internalStatusPageId ? (
              <>
                How would you like to receive updates for incidents on this
                status page?
              </>
            ) : (
              <>
                How would you like to receive updates about this incident?
                We&rsquo;ll remember your preference if you subscribe to other
                incidents.
              </>
            )}
          </div>
          <div className="flex flex-col">
            {identity.slack_info?.user_slack_user_id && (
              <Checkbox
                className="mb-2"
                label="Slack direct messages"
                id="receive_subscriptions_via_slack_DM"
                {...register("receives_subscriptions_via_slack_DM")}
              />
            )}
            <Checkbox
              id="receive_subscriptions_via_email"
              label="Email"
              {...formMethods.register("receives_subscriptions_via_email")}
            />
            {errors.receives_subscriptions_via_email && (
              <p className="text-red-500 text-xs">
                {errors.receives_subscriptions_via_email.message}
              </p>
            )}
          </div>
        </div>
      </Modal>
    </FormProvider>
  );
};
