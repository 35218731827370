import React, { useState } from "react";
import {
  AvailableExpressionOperationOperationTypeEnum,
  EngineScope,
  ExpressionElseBranch,
  ExpressionOperationOperationTypeEnum as OperationTypeEnum,
  Resource,
} from "src/contexts/ClientContext";

import { ExpressionCreateChooseType } from "./ExpressionCreateChooseType";
import { ExpressionDeletionUsages } from "./ExpressionsEditor";
import { ExpressionFormData } from "./expressionToPayload";
import { ExpressionFixedResultType } from "./ifelse/createDefaultExpressionFormValues";
import { IfElseExpressionModal } from "./ifelse/IfElseExpressionModal";
import { QueryExpressionEditModal } from "./query/QueryExpressionEditModal";

// CreateEditExpressionFormData is the data that is used to edit an expression within
// the editor. When you're calling it, you should be using `ExpressionFormData` as
// your canonical representation, this is just for use inside the editor.
export interface CreateEditExpressionFormData extends ExpressionFormData {
  else_branch: ExpressionElseBranch;
}

export interface ExpressionFormProps {
  // If no initial expression is provided, this will be called when the modal is submitted
  onAddExpression: (f: ExpressionFormData) => void;
  // If provided, the modal will be in edit mode with the expression as the default values
  initialExpression?: ExpressionFormData;
  // If an initial expression is provided, this is called when the modal is submitted
  onEditExpression: (f: ExpressionFormData) => void;
  onClose: () => void;
  // This is the entire scope, allowing us to lookup references and provide
  // parameter options.
  scope: EngineScope;
  // resources is the universe of types for an organisation, allowing us to find
  // resource operations. These are stored on the parent to save re-requesting these on
  // each modal open. If they are null, you can fetch them using `getExpressionResources`
  resources: Resource[];
  // This should be an identifier of the form, e.g. 'workflows'. Gets prepended
  // to `-add-expression`.
  analyticsTrackingContext: string;
  // We compare the expression being edited to existing expressions to ensure no name conflicts
  existingExpressions: ExpressionFormData[];
  // disableResultArrays stops a user being able to choose to return an array of types.
  disableResultArrays?: boolean;
  // fixed result is used when we want to force a result type, e.g. when configuring
  // export config on an issue tracker
  fixedResult?: ExpressionFixedResultType;
  // Is this expression currently being used? If so, we won't let you delete it.
  currentlyInUse?: ExpressionDeletionUsages;
  // Whether to hide the fixed result label
  hideFixedResultLabel?: boolean;
  // Whether we should allow a user to start from 'all of a catalog type' in this expression
  allowAllOfACatalogType: boolean;
  // Callback to allow deleting of expressions
  onDeleteExpression?: (exp: Partial<ExpressionFormData>) => void;
  // This is displayed at the top of the modal to add context
  helptext?: string;
  // Limit the operations that can be used in a query, leave empty to allow all
  allowedOperations?: AvailableExpressionOperationOperationTypeEnum[];
  // The root reference to default to if the form is in 'create' mode (i.e. there's
  // no initial expression)
  defaultRootReference?: string;
  // Validate the return type of an expression
  validateReturnType?: (
    resource: string,
    isArray: boolean,
  ) => boolean | string | undefined;
  // Override help text for the "can return multiple items" checkbox
  returnMultipleItemsCheckboxHelptext?: string;
  // Whether or not we should filter out return types that can't be used as
  // variables in templated text
  filterNonInterpolatable?: boolean;
  // Whether or not the else branch is required
  elseBranchRequired?: boolean;
}

export type ExpressionType = "if_else" | "query" | "unset";

export const AddEditExpressionModal = (
  props: ExpressionFormProps,
): React.ReactElement | null => {
  let initialExpressionType: ExpressionType = "unset";
  if (props.initialExpression && props.initialExpression.operations) {
    if (
      props.initialExpression.operations[0].operation_type ===
      OperationTypeEnum.Branches
    ) {
      initialExpressionType = "if_else";
    } else {
      initialExpressionType = "query";
    }
  }

  const [expressionType, setExpressionType] = useState<ExpressionType>(
    initialExpressionType,
  );

  switch (expressionType) {
    case "unset":
      return (
        <ExpressionCreateChooseType
          onClose={props.onClose}
          chooseType={(type) => setExpressionType(type)}
        />
      );
    case "if_else":
      return <IfElseExpressionModal {...props} />;
    case "query":
      return <QueryExpressionEditModal {...props} />;
    default:
      return <></>;
  }
};
