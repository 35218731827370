import { branchesOnlyExpressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import {
  Button,
  Callout,
  CalloutTheme,
  IconEnum,
  SharedToasts,
  StackedList,
} from "@incident-ui";
import { PopoverSingleSelect } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useForm, UseFormReturn, useWatch } from "react-hook-form";
import {
  EngineScope,
  ExpressionBranch,
  IncidentLifecycle,
  PostIncidentFlow,
} from "src/contexts/ClientContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useAPIMutation } from "src/utils/swr";

import { CreateEditRuleModal } from "./CreateEditRuleModal";
import {
  PostIncidentExpressionFormData,
  usePostIncidentBranchResult,
} from "./formHooks";
import { PostIncidentFlowBranchEditable } from "./PostIncidentFlowExpressionBranch";

export const PostIncidentFlowExpressionEditor = ({
  canEditSettings,
  lifecycle,
  scope,
  onSuccess,
  flows,
}: {
  canEditSettings: boolean;
  lifecycle: IncidentLifecycle;
  scope: EngineScope;
  onSuccess?: () => void;
  flows: PostIncidentFlow[];
}) => {
  const defaultValues: PostIncidentExpressionFormData = {
    expression: lifecycle.automatic_post_incident_flow_expression ?? {
      branches: Array<ExpressionBranch>(),
      else_branch: {
        result: {
          array_value: [
            {
              literal: flows[0].id,
              label: flows[0].name,
              sort_key: flows[0].name,
              value: flows[0].id,
            },
            {
              literal: "PINC_FLOW_IS_OPTIONAL",
              value: "PINC_FLOW_IS_OPTIONAL",
              sort_key: "",
              label: "",
            },
          ],
        },
      },
    },
    enabled: lifecycle.post_incident_flow_enabled,
  };
  const formMethods = useForm<PostIncidentExpressionFormData>({
    defaultValues,
  });

  const [openModal, setOpenModal] = useState<
    | { mode: Mode.Create; branchIndex?: never }
    | { mode: Mode.Edit; branchIndex: number }
    | null
  >(null);

  const { update, genericError, hasSaved, saving } =
    useIncidentLifecycleUpdatePostIncidentFlow(
      lifecycle,
      defaultValues,
      flows,
      onSuccess,
    );

  const formState = useWatch({
    control: formMethods.control,
    disabled: saving,
  });
  useEffect(() => {
    update(formState);
  }, [formState, update]);

  return (
    <FormV2
      formMethods={formMethods}
      onSubmit={() => null}
      innerClassName="bg-white rounded-[6px] shadow-sm p-4"
    >
      <div className="flex">
        <ToggleV2
          name="enabled"
          formMethods={formMethods}
          label={"Enable post-incident flow for incidents in this lifecycle"}
          disabled={!canEditSettings}
        />
        <div className="grow" />
        <div className="pr-4">
          <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
        </div>
      </div>
      {openModal != null && (
        <CreateEditRuleModal
          formMethods={formMethods}
          scope={scope}
          flows={flows}
          branchIndex={openModal.branchIndex}
          onClose={() => setOpenModal(null)}
        />
      )}
      {formMethods.watch<"enabled">("enabled") &&
        (!formMethods.watch<"expression.branches">("expression.branches")
          ?.length ? (
          <PostIncidentFlowBaseCase
            formMethods={formMethods}
            flows={flows}
            disabled={!canEditSettings}
            openCreateRuleModal={() => setOpenModal({ mode: Mode.Create })}
          />
        ) : (
          <div className="space-y-4">
            <StackedList className="text-sm">
              {formMethods
                .watch("expression.branches")
                ?.map((_, branchIndex) => (
                  <PostIncidentFlowBranchEditable
                    branchType="if_branch"
                    key={branchIndex}
                    formMethods={formMethods}
                    branchIndex={branchIndex}
                    flows={flows}
                    onEdit={() =>
                      setOpenModal({
                        mode: Mode.Edit,
                        branchIndex: branchIndex,
                      })
                    }
                  />
                ))}
              {formMethods.watch("expression.else_branch") && (
                <PostIncidentFlowBranchEditable
                  branchType="else_branch"
                  formMethods={formMethods}
                  flows={flows}
                />
              )}
            </StackedList>
            <Button
              analyticsTrackingId={null}
              icon={IconEnum.Add}
              onClick={() => setOpenModal({ mode: Mode.Create })}
              disabled={!canEditSettings}
            >
              Add rule
            </Button>
          </div>
        ))}
      <ErrorMessageUI message={genericError} />
    </FormV2>
  );
};

// This is the simplest use case, where customers can configure:
// 1. Whether the post-incident flow is required or not
// 2. Which post-incident flow to use
// This will be apply to all incidents
const PostIncidentFlowBaseCase = ({
  flows,
  formMethods,
  disabled,
  openCreateRuleModal,
}: {
  flows: PostIncidentFlow[];
  formMethods: UseFormReturn<PostIncidentExpressionFormData>;
  disabled?: boolean;
  openCreateRuleModal: () => void;
}) => {
  const { isRequired, setIsRequired, flowIDs, setFlowIDs } =
    usePostIncidentBranchResult(formMethods, "expression.else_branch.result");
  return (
    <>
      <div className="text-content-primary">
        For all incidents, responders will &nbsp;
        <div className="inline-flex">
          <PopoverSingleSelect
            options={[
              {
                value: "required",
                label: "be required",
              },
              {
                value: "not_required",
                label: "have the choice",
              },
            ]}
            triggerStyle="inline-button"
            value={isRequired ? "required" : "not_required"}
            onChange={(val) => setIsRequired(val === "required")}
            disabled={disabled}
          />
        </div>
        &nbsp;to enter the&nbsp;
        <div className="inline-flex">
          {flowIDs.length > 0 && (
            <PopoverSingleSelect
              options={flows.map((flow) => ({
                value: flow.id,
                label: flow.name,
              }))}
              value={flowIDs[0]}
              triggerStyle="inline-button"
              onChange={(val) => setFlowIDs(val ? [val] : [])}
              disabled={disabled}
            />
          )}
        </div>
        &nbsp;&nbsp;post-incident flow
      </div>
      <Callout
        theme={CalloutTheme.Plain}
        title="Want more flexibility?"
        subtitle={
          <div>
            Add custom rules to determine when to require a post-incident flow
            based on incident attributes.
          </div>
        }
        cta={
          <Button
            analyticsTrackingId={null}
            icon={IconEnum.Add}
            onClick={openCreateRuleModal}
            disabled={disabled}
          >
            Add rule
          </Button>
        }
      />
    </>
  );
};

const useIncidentLifecycleUpdatePostIncidentFlow = (
  lifecycle: IncidentLifecycle,
  defaultValues: PostIncidentExpressionFormData,
  flows: PostIncidentFlow[],
  onSaved?: () => void,
) => {
  const showToast = useToast();
  const { trigger, genericError } = useAPIMutation(
    "incidentLifecyclesList",
    undefined,
    async (apiClient, data: PostIncidentExpressionFormData) => {
      await apiClient.incidentLifecyclesUpdatePostIncidentFlow({
        id: lifecycle.id,
        updatePostIncidentFlowRequestBody: {
          post_incident_flow_enabled: data.enabled,
          automatic_post_incident_flow_expression:
            branchesOnlyExpressionToPayload(data.expression, true),
        },
      });
    },
    {
      onSuccess: () => {
        onSaved && onSaved();
        showToast(SharedToasts.SETTINGS_SAVED);
      },
    },
  );

  const { state, setState, hasSaved, saving } = useOptimisticAutoSave({
    saveState: trigger,
    initialState: defaultValues,
  });
  const update = useCallback(
    async (newState) => {
      if (saving || _.isEqual(newState, state)) {
        return;
      }
      setState(newState);
    },
    [saving, state, setState],
  );

  return {
    update: _.throttle(update, 10, { trailing: true }),
    genericError,
    saving,
    hasSaved,
  };
};
