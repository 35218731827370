import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { NumberedList } from "@incident-ui/NumberedList/NumberedList";
import React from "react";

export const SetupInfoSingleLineBlocks = ({
  intro,
  blocks,
}: {
  intro?: React.ReactNode;
  blocks: { title?: string; code: string; label?: React.ReactNode }[];
}) => {
  return (
    <div className="flex flex-col gap-2 overflow-hidden pb-2 text-content-secondary">
      <p>{intro}</p>
      {blocks.map((block, index) => (
        <>
          {block.label && <p>{block.label}</p>}
          <SingleLineCodeBlock
            key={index}
            title={block.title}
            code={block.code}
          />
        </>
      ))}
    </div>
  );
};

export const SetupInfoNumberedList = ({
  children,
}: {
  children: React.ReactNode[];
}) => {
  return (
    <NumberedList
      className={
        "text-content-secondary [&_strong]:!text-content-primary [&_strong]:font-semibold overflow-x-hidden"
      }
    >
      {children}
    </NumberedList>
  );
};
