import {
  AlertSchema,
  AlertSourceConfig,
  AlertSourceSourceTypeEnum,
  Expression,
} from "@incident-io/api";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { FormDivider } from "@incident-shared/forms/v2/FormDivider";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import {
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  Txt,
} from "@incident-ui";
import { InlineTextInput } from "@incident-ui/InlineTextInput/InlineTextInput";
import React, { ComponentType } from "react";
import { UseFormReturn } from "react-hook-form";
import { AlertSourceGoogleCloudSetupInfo } from "src/components/alerts/alert-source-create-edit/alert-source-setup-info/AlertSourceGoogleCloudSetupInfo";
import { useAPI } from "src/utils/swr";

import { AlertSourceTypeIcon } from "../common/AlertSourceTypeConfigs";
import { AlertsTable } from "../common/AlertsTable";
import { AlertSourceAlertmanagerSetupInfo } from "./alert-source-setup-info/AlertSourceAlertmanagerSetupInfo";
import { AlertSourceAppOpticsSetupInfo } from "./alert-source-setup-info/AlertSourceAppOpticsSetupInfo";
import { AlertSourceAzureMonitorSetupInfo } from "./alert-source-setup-info/AlertSourceAzureMonitorSetupInfo";
import { AlertSourceBugSnagSetupInfo } from "./alert-source-setup-info/AlertSourceBugSnagSetupInfo";
import { AlertSourceChecklySetupInfo } from "./alert-source-setup-info/AlertSourceChecklySetupInfo";
import { AlertSourceChronosphereSetupInfo } from "./alert-source-setup-info/AlertSourceChronosphereSetupInfo";
import { AlertSourceCloudflareSetupInfo } from "./alert-source-setup-info/AlertSourceCloudflareSetupInfo";
import { AlertSourceCloudWatchSetupInfo } from "./alert-source-setup-info/AlertSourceCloudWatchSetupInfo";
import { AlertSourceCronitorSetupInfo } from "./alert-source-setup-info/AlertSourceCronitorSetupInfo";
import { AlertSourceCrowdstrikeFalconSetupInfo } from "./alert-source-setup-info/AlertSourceCrowdstrikeFalconSetupInfo";
import { AlertSourceDatadogSetupInfo } from "./alert-source-setup-info/AlertSourceDatadogSetupInfo";
import { AlertSourceDynatraceSetupInfo } from "./alert-source-setup-info/AlertSourceDynatraceSetupInfo";
import { AlertSourceElasticSetupInfo } from "./alert-source-setup-info/AlertSourceElasticSetupInfo";
import { AlertSourceEmailSetupInfo } from "./alert-source-setup-info/AlertSourceEmailSetupInfo";
import { AlertSourceExpelSetupInfo } from "./alert-source-setup-info/AlertSourceExpelSetupInfo";
import { AlertSourceGrafanaSetupInfo } from "./alert-source-setup-info/AlertSourceGrafanaSetupInfo";
import { AlertSourceHoneycombSetupInfo } from "./alert-source-setup-info/AlertSourceHoneycombSetupInfo";
import { AlertSourceHTTPSetupInfo } from "./alert-source-setup-info/AlertSourceHTTPSetupInfo";
import { AlertSourceJiraSetupInfo } from "./alert-source-setup-info/AlertSourceJiraSetupInfo";
import { AlertSourceMonteCarloSetupInfo } from "./alert-source-setup-info/AlertSourceMonteCarloSetupInfo";
import { AlertSourceNewRelicSetupInfo } from "./alert-source-setup-info/AlertSourceNewRelicSetupInfo";
import { AlertSourcePantherSetupInfo } from "./alert-source-setup-info/AlertSourcePantherSetupInfo";
import { AlertSourcePingdomSetupInfo } from "./alert-source-setup-info/AlertSourcePingdomSetupInfo";
import { AlertSourceRunscopeSetupInfo } from "./alert-source-setup-info/AlertSourceRunscopeSetupInfo";
import { AlertSourceSentrySetupInfo } from "./alert-source-setup-info/AlertSourceSentrySetupInfo";
import { AlertSourceSNSSetupInfo } from "./alert-source-setup-info/AlertSourceSNSSetupInfo";
import { AlertSourceSplunkSetupInfo } from "./alert-source-setup-info/AlertSourceSplunkSetupInfo";
import { AlertSourceStatusCakeSetupInfo } from "./alert-source-setup-info/AlertSourceStatusCakeSetupInfo";
import { AlertSourceStatusPageViewsSetupInfo } from "./alert-source-setup-info/AlertSourceStatusPageViewsSetupInfo";
import { AlertSourceSumoLogicSetupInfo } from "./alert-source-setup-info/AlertSourceSumoLogicSetupInfo";
import { AlertSourceUptimeSetupInfo } from "./alert-source-setup-info/AlertSourceUptimeSetupInfo";
import { AlertSourceConfigureFormData } from "./AlertSourceConfigurePage";
import { AlertSourceStepEnum } from "./AlertSourceCreateEditPage";
import { AlertSourceSplitLayout } from "./AlertSourceLayout";
import { stripInvalidBindings } from "./stripInvalidBindings";

export const AlertSourceConnectPage = ({
  alertSourceId,
  mode,
  setCurrentTab,
}: {
  alertSourceId: string;
  mode: Mode;
  setCurrentTab: (tabId: string) => void;
}) => {
  const { data: schemaResponse } = useAPI("alertsShowSchema", undefined);

  const { data: alertSourceResponse } = useAPI(
    alertSourceId ? "alertsShowSourceConfig" : null,
    {
      id: alertSourceId || "",
    },
    {
      refreshInterval: 1000,
      revalidateOnFocus: true,
    },
  );

  if (!alertSourceResponse || !schemaResponse) {
    return <Loader className={"h-full"} />;
  }

  return (
    <AlertSourceConnectContent
      alertSource={alertSourceResponse.alert_source_config}
      schema={schemaResponse.alert_schema}
      mode={mode}
      setCurrentTab={setCurrentTab}
    />
  );
};

const AlertSourceConnectContent = ({
  alertSource,
  schema,
  mode,
  setCurrentTab,
}: {
  alertSource: AlertSourceConfig;
  schema: AlertSchema;
  mode: Mode;
  setCurrentTab: (tabId: string) => void;
}) => {
  const SetupComponent = AlertSourceSetupComponent[alertSource.source_type];
  const requiresSetup = !!SetupComponent;

  const template = alertSource?.template;
  const { data: previewAlerts, isLoading: previewAlertsLoading } = useAPI(
    template ? "alertsPreviewSourceConfigAlerts" : null,
    {
      id: alertSource.id,
      previewSourceConfigAlertsRequestBody: {
        template: stripInvalidBindings({
          expressions: template.expressions.map((e) =>
            expressionToPayload(e as unknown as Expression),
          ),
          title: template.title,
          description: template.description,
          bindings: template.bindings,
        }),
        attributes: schema.attributes,
        version: schema.version,
      },
    },
  );

  const {
    data: schemaData,
    isLoading: schemaLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", undefined);
  const {
    data: alertSourcesData,
    isLoading: alertSourcesLoading,
    error: alertSourcesError,
  } = useAPI("alertsListSources", undefined);
  const {
    data: resourcesData,
    isLoading: resourcesLoading,
    error: resourcesError,
  } = useAPI("catalogListResources", undefined);

  if (schemaError || alertSourcesError || resourcesError) {
    return (
      <GenericErrorMessage
        error={schemaError ?? alertSourcesError ?? resourcesError}
      />
    );
  }

  if (
    schemaLoading ||
    alertSourcesLoading ||
    resourcesLoading ||
    !schemaData ||
    !alertSourcesData ||
    !resourcesData
  ) {
    return <Loader />;
  }

  return (
    <AlertSourceSplitLayout
      step={AlertSourceStepEnum.Connect}
      mode={mode}
      alertSourceId={alertSource.id}
      setCurrentTab={setCurrentTab}
      left={
        <>
          <AlertSourceHeader
            alertSource={alertSource}
            description={
              <div>
                <Heading level={1} size="2xl" className="my-2">{`Connect ${
                  alertSource.source_type === "http"
                    ? ""
                    : alertSource.alert_source.name
                } to incident.io`}</Heading>
                <Txt grey>
                  {`Follow the steps below to connect ${
                    alertSource.source_type === "http"
                      ? "your HTTP source"
                      : alertSource.name
                  } to incident.io and start receiving alerts.`}
                </Txt>
                {alertSource.alert_source.externally_resolved && (
                  <Txt grey className="mt-4">
                    This alert source is set up to be externally resolved. In
                    order to resolve an alert in incident.io, you will need to
                    resolve it in {alertSource.alert_source.name}
                  </Txt>
                )}
              </div>
            }
          />
          <FormDivider className="my-8" />
          {requiresSetup ? (
            <SetupComponent alertSourceConfig={alertSource} schema={schema} />
          ) : (
            <div className="bg-slate-50 border border-slate-100 rounded-2 p-5 flex">
              <div className="flex-center rounded-full border border-green-600 w-4 h-4 mt-0.5 mr-3">
                <Icon
                  id={IconEnum.Tick}
                  size={IconSize.Small}
                  className="text-green-600"
                />
              </div>
              <div>
                <Txt bold>No further set up required</Txt>
                <Txt
                  grey
                >{`${alertSource.name} is already connected and sending alerts to incident.io. You may continue to the next step.`}</Txt>
              </div>
            </div>
          )}
        </>
      }
      right={
        <div
          className={
            "flex flex-col p-6 bg-slate-50 border-l border-stroke h-full w-full lg:w-[50%] overflow-y-auto pb-24"
          }
        >
          {" "}
          <Txt greySmallCaps className="mb-6 text-xs">
            Received alerts
          </Txt>
          {previewAlerts?.alerts.length === 0 ? (
            <ConnectAlertSourceEmptyState />
          ) : (
            <AlertsTable
              schema={schemaData.alert_schema}
              resources={resourcesData.resources}
              alertSourceConfigs={[alertSource]}
              alerts={previewAlerts?.alerts ?? []}
              allEntriesLoaded={true}
              enableSelection={false}
              isLoading={previewAlertsLoading}
              // Only show the attributes, don't show the alert source as we're in it
              visibleColumns={schemaData.alert_schema.attributes.map(
                (attribute) => {
                  return {
                    type: "attribute",
                    attribute: {
                      name: attribute.name,
                      type: attribute.type,
                      array: attribute.array,
                      id: attribute.id ?? "",
                    },
                  };
                },
              )}
              wrappedInBox
            />
          )}
        </div>
      }
    />
  );
};

const ConnectAlertSourceEmptyState = () => {
  return (
    <div className={"w-full h-full flex justify-center items-center"}>
      <div className={"flex flex-col items-center max-w-sm gap-1"}>
        <Icon
          id={IconEnum.Alert}
          size={IconSize.Large}
          className={"text-content-tertiary"}
        />
        <Txt className={"font-medium text-content-primary"}>
          No alerts received yet
        </Txt>
        <div
          className={"text-center text-sm text-slate-600"}
          // This is a legit property, that makes the lines all roughly
          // equal widths. Tailwind is supporting it "soon" as text-balance.
          //
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={{ textWrap: "balance" }}
        >
          Follow the steps opposite to set up your alert source so that we can
          start receiving alerts.
        </div>
      </div>
    </div>
  );
};

export const AlertSourceHeader = ({
  alertSource,
  description,
  formMethods,
}: {
  alertSource: AlertSourceConfig;
  description: React.ReactNode;
  formMethods?: UseFormReturn<AlertSourceConfigureFormData>;
}) => {
  return (
    <div className="space-y-6">
      <div className={"flex gap-2 items-center"}>
        <div className="inline-flex h-10 w-10 items-center justify-center rounded-2 border border-stroke">
          <AlertSourceTypeIcon
            size={IconSize.XL}
            sourceType={
              alertSource.source_type as unknown as AlertSourceSourceTypeEnum
            }
          />
        </div>
        {alertSource.alert_source.max_instances !== 1 && (
          <div className={"flex flex-col gap-0.5 grow"}>
            {formMethods ? (
              <InlineTextInput
                placeholder={alertSource.name}
                formMethods={formMethods}
                name={"name"}
              />
            ) : (
              <Txt className={"font-medium"}>{alertSource.name}</Txt>
            )}
            <span className="text-content-tertiary text-xs pl-1">
              {alertSource.alert_source.name}
            </span>
          </div>
        )}
      </div>
      {description}
    </div>
  );
};

export const AlertSourceSetupComponent: Record<
  AlertSourceSourceTypeEnum,
  ComponentType<AlertSourceSetupProps> | null
> = {
  [AlertSourceSourceTypeEnum.Alertmanager]: AlertSourceAlertmanagerSetupInfo,
  [AlertSourceSourceTypeEnum.AppOptics]: AlertSourceAppOpticsSetupInfo,
  [AlertSourceSourceTypeEnum.AzureMonitor]: AlertSourceAzureMonitorSetupInfo,
  [AlertSourceSourceTypeEnum.Bugsnag]: AlertSourceBugSnagSetupInfo,
  [AlertSourceSourceTypeEnum.Checkly]: AlertSourceChecklySetupInfo,
  [AlertSourceSourceTypeEnum.Cloudflare]: AlertSourceCloudflareSetupInfo,
  [AlertSourceSourceTypeEnum.Cloudwatch]: AlertSourceCloudWatchSetupInfo,
  [AlertSourceSourceTypeEnum.Cronitor]: AlertSourceCronitorSetupInfo,
  [AlertSourceSourceTypeEnum.CrowdstrikeFalcon]:
    AlertSourceCrowdstrikeFalconSetupInfo,
  [AlertSourceSourceTypeEnum.Chronosphere]: AlertSourceChronosphereSetupInfo,
  [AlertSourceSourceTypeEnum.Datadog]: AlertSourceDatadogSetupInfo,
  [AlertSourceSourceTypeEnum.Dynatrace]: AlertSourceDynatraceSetupInfo,
  [AlertSourceSourceTypeEnum.Email]: AlertSourceEmailSetupInfo,
  [AlertSourceSourceTypeEnum.Elasticsearch]: AlertSourceElasticSetupInfo,
  [AlertSourceSourceTypeEnum.Expel]: AlertSourceExpelSetupInfo,
  [AlertSourceSourceTypeEnum.GithubIssue]: null,
  [AlertSourceSourceTypeEnum.GoogleCloud]: AlertSourceGoogleCloudSetupInfo,
  [AlertSourceSourceTypeEnum.Grafana]: AlertSourceGrafanaSetupInfo,
  [AlertSourceSourceTypeEnum.Honeycomb]: AlertSourceHoneycombSetupInfo,
  [AlertSourceSourceTypeEnum.Http]: AlertSourceHTTPSetupInfo,
  [AlertSourceSourceTypeEnum.Jira]: AlertSourceJiraSetupInfo,
  [AlertSourceSourceTypeEnum.MonteCarlo]: AlertSourceMonteCarloSetupInfo,
  [AlertSourceSourceTypeEnum.NewRelic]: AlertSourceNewRelicSetupInfo,
  [AlertSourceSourceTypeEnum.Panther]: AlertSourcePantherSetupInfo,
  [AlertSourceSourceTypeEnum.Opsgenie]: null,
  [AlertSourceSourceTypeEnum.PagerDuty]: null,
  [AlertSourceSourceTypeEnum.Pingdom]: AlertSourcePingdomSetupInfo,
  [AlertSourceSourceTypeEnum.Runscope]: AlertSourceRunscopeSetupInfo,
  [AlertSourceSourceTypeEnum.Sentry]: AlertSourceSentrySetupInfo,
  [AlertSourceSourceTypeEnum.SumoLogic]: AlertSourceSumoLogicSetupInfo,
  [AlertSourceSourceTypeEnum.StatusPageViews]:
    AlertSourceStatusPageViewsSetupInfo,
  [AlertSourceSourceTypeEnum.Sns]: AlertSourceSNSSetupInfo,
  [AlertSourceSourceTypeEnum.Splunk]: AlertSourceSplunkSetupInfo,
  [AlertSourceSourceTypeEnum.StatusCake]: AlertSourceStatusCakeSetupInfo,
  [AlertSourceSourceTypeEnum.Uptime]: AlertSourceUptimeSetupInfo,
  [AlertSourceSourceTypeEnum.Zendesk]: null,
};

export type AlertSourceSetupProps = {
  alertSourceConfig: AlertSourceConfig;
  schema: AlertSchema;
};
