/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalResource,
    ExternalResourceFromJSON,
    ExternalResourceFromJSONTyped,
    ExternalResourceToJSON,
} from './ExternalResource';

/**
 * 
 * @export
 * @interface LegacyIncidentTriggersExampleResourceResponseBody
 */
export interface LegacyIncidentTriggersExampleResourceResponseBody {
    /**
     * 
     * @type {ExternalResource}
     * @memberof LegacyIncidentTriggersExampleResourceResponseBody
     */
    resource?: ExternalResource;
}

export function LegacyIncidentTriggersExampleResourceResponseBodyFromJSON(json: any): LegacyIncidentTriggersExampleResourceResponseBody {
    return LegacyIncidentTriggersExampleResourceResponseBodyFromJSONTyped(json, false);
}

export function LegacyIncidentTriggersExampleResourceResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegacyIncidentTriggersExampleResourceResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resource': !exists(json, 'resource') ? undefined : ExternalResourceFromJSON(json['resource']),
    };
}

export function LegacyIncidentTriggersExampleResourceResponseBodyToJSON(value?: LegacyIncidentTriggersExampleResourceResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resource': ExternalResourceToJSON(value.resource),
    };
}

