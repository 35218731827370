import { IconEnum } from "@incident-ui";
import React from "react";
import {
  Attachment,
  AttachmentContexts,
} from "src/components/legacy/incident/attachments/IncidentAttachment";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemContentBox } from "src/components/timeline/TimelineItemContentBox";
import { Actor, Incident } from "src/contexts/ClientContext";
import { TimelineItemObject } from "src/contexts/ClientContext";

export const TimelineItemPagerdutyIncidentAcknowledged = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.pagerduty_incident_acknowledged) {
    throw new Error(
      "malformed timeline item: pagerduty_incident_acknowledged was missing pagerduty_incident_acknowledged field",
    );
  }

  return {
    icon: IconEnum.Attachment,
    avatarUrl:
      item.pagerduty_incident_acknowledged?.acknowledger?.user?.avatar_url,
    description: (
      <>
        <TimelineItemPagerDutyUser
          actor={item.pagerduty_incident_acknowledged.acknowledger}
        />{" "}
        acknowledged an attached{" "}
        {
          item.pagerduty_incident_acknowledged.external_resource
            .resource_type_label
        }
      </>
    ),
    children: (
      <TimelineItemContentBox>
        <Attachment
          context={AttachmentContexts.Timeline}
          resource={item.pagerduty_incident_acknowledged.external_resource}
        />
      </TimelineItemContentBox>
    ),
  };
};

// TimelineItemPagerDutyUser should be used solely when the timeline item involves an actor in
// PagerDuty. If the PagerDuty actor was a user that we can match in our system, we can return the
// name of the user. But if the actor was a non-user actor like an integration, we end up with
// an actor that is of the external resource type. In this case, let's just say "An automation" did
// it rather than pretending it was a user.
export const TimelineItemPagerDutyUser = ({
  actor,
}: {
  actor?: Actor;
}): React.ReactElement => {
  let content = <>Someone </>;

  if (actor?.external_resource?.pager_duty_incident) {
    content = <>PagerDuty</>;
  }

  if (actor?.external_resource) {
    content = <>An automation</>;
  }

  if (actor?.user) {
    content = <strong>{actor.user.name} </strong>;
  }

  return content;
};
