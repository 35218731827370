import {
  Button,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
} from "@incident-ui";

import { DateAggregation, dateAggregationAdjective } from "../context/types";
import { useInsightsContext } from "../context/useInsightsContext";

export const DateAggregationPicker = () => {
  const { dateAggregation, setDateAggregation } = useInsightsContext();
  return (
    <DropdownMenu
      menuClassName="mt-2"
      menuWidthMatchesTrigger
      align="end"
      triggerButton={
        <Button
          analyticsTrackingId="selected-date-aggregation"
          className="!font-normal !pr-2 !py-1.5 flex justify-between"
        >
          {/* The label of the selected quick interval */}
          <span className="shrink-0 mr-0.5 flex">
            {dateAggregationAdjective(dateAggregation)}
          </span>
          <Icon id={IconEnum.ChevronDown} />
        </Button>
      }
    >
      {Object.entries(DateAggregation).map(([_, option]) => (
        <DropdownMenuItem
          key={option}
          analyticsTrackingId={`select-${option}-date-aggregation`}
          onSelect={() => setDateAggregation(option)}
          label={dateAggregationAdjective(option)}
        />
      ))}
    </DropdownMenu>
  );
};
