import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { DynamicSingleSelect } from "@incident-ui";
import { useClient } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

export const SelectUser = ({
  userID,
  setUserID,
}: {
  userID: string | undefined;
  setUserID: (newID: string) => void;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const apiClient = useClient();

  // If there's no user ID in the URL, use the current user's ID as a default unless they're impersonating
  if (!userID) {
    if (identity.actor_organisation_id === identity.organisation_id) {
      setUserID(identity.user_id);
    }
  }

  return (
    <div className="flex items-center">
      <DynamicSingleSelect
        placeholder="Select a user"
        className="!text-slate-700 min-w-[200px]"
        value={userID || ""}
        onChange={(value) => {
          if (value && typeof value === "string") {
            setUserID(value);
          }
        }}
        isClearable={false}
        loadOptions={getTypeaheadOptions(apiClient, TypeaheadTypeEnum.User, {
          forInsights: true,
        })}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.User,
          true,
        )}
      />
    </div>
  );
};
