import { Button, ButtonTheme, Txt } from "@incident-ui";
import React from "react";

import AppStoreIcon from "../alerts/images/app-store.png";
import AppIcon from "../alerts/images/orange-app-icon.png";
import PlayStoreIcon from "../alerts/images/play-store.png";

const detectMobilePlatform = (
  userAgent: string,
): "android" | "ios" | "desktop" => {
  if (userAgent.includes("android")) {
    return "android" as const;
  } else if (userAgent.includes("iphone")) {
    return "ios" as const;
  } else if (userAgent.includes("ipad")) {
    return "ios" as const;
  } else {
    return "desktop" as const;
  }
};

export const QrCodePage = (): React.ReactElement => {
  const userAgent = navigator.userAgent.toLowerCase();
  const platform = detectMobilePlatform(userAgent);

  if (platform === "ios") {
    window.location.href =
      "https://apps.apple.com/us/app/incident-io/id6471268530";
  } else if (platform === "android") {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.incidentio.incidentio";
  }

  return (
    <div className="flex flex-col h-full min-h-full items-center justify-center text-center">
      <img
        className="max-h-[72px] max-w-[72px]"
        src={AppIcon}
        alt="The icon of incident.io's On-call app"
      />
      <div>
        <h2 className="font-medium text-content-primary text-lg mt-2">
          Set up On-call
        </h2>
        <Txt grey className="mt-2">
          Download the mobile app to receive and acknowledge alerts natively on
          your device
        </Txt>
      </div>
      <div className="mt-4 flex flex-row">
        <Button
          analyticsTrackingId="on-call-onboarding-user-download-ios"
          className="truncate"
          theme={ButtonTheme.Primary}
          href="https://apps.apple.com/us/app/incident-io/id6471268530"
        >
          <img
            className="max-h-[20px] max-w-[20px] mr-1"
            src={AppStoreIcon}
            alt="The Apple App Store icon"
          />
          App Store
        </Button>
        <Button
          analyticsTrackingId="on-call-onboarding-user-download-android"
          className="ml-4 truncate"
          theme={ButtonTheme.Primary}
          href="https://play.google.com/store/apps/details?id=com.incidentio.incidentio"
        >
          <img
            className="max-h-[20px] max-w-[20px] mr-1"
            src={PlayStoreIcon}
            alt="The Google Play Store icon"
          />
          Google Play
        </Button>
      </div>
    </div>
  );
};
