/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    SlimIncident,
    SlimIncidentFromJSON,
    SlimIncidentFromJSONTyped,
    SlimIncidentToJSON,
} from './SlimIncident';

/**
 * 
 * @export
 * @interface IncidentMerged
 */
export interface IncidentMerged {
    /**
     * 
     * @type {SlimIncident}
     * @memberof IncidentMerged
     */
    incident: SlimIncident;
    /**
     * 
     * @type {Actor}
     * @memberof IncidentMerged
     */
    merger: Actor;
}

export function IncidentMergedFromJSON(json: any): IncidentMerged {
    return IncidentMergedFromJSONTyped(json, false);
}

export function IncidentMergedFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentMerged {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident': SlimIncidentFromJSON(json['incident']),
        'merger': ActorFromJSON(json['merger']),
    };
}

export function IncidentMergedToJSON(value?: IncidentMerged | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident': SlimIncidentToJSON(value.incident),
        'merger': ActorToJSON(value.merger),
    };
}

