import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import React, { useEffect } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { useLogoutAll } from "src/hooks/useLogoutAll";
import { tcx } from "src/utils/tailwind-classes";

import { NavigationLink } from "./Navigation";
import { useSidebarItems } from "./useSidebarItems";

type Props = {
  isOpen: boolean;
  closeSidebar: () => void;
};

export function MobileSidebar({
  isOpen,
  closeSidebar,
}: Props): React.ReactElement | null {
  const { identity, isImpersonating } = useIdentity();
  const sidebarItems = useSidebarItems();

  const logoutAll = useLogoutAll();

  // Disable body scroll if the sidebar is open
  useEffect(() => {
    if (!isOpen) return undefined;
    document.querySelector("body")?.setAttribute("data-noscroll", "");
    return () => {
      document.querySelector("body")?.removeAttribute("data-noscroll");
    };
  }, [isOpen]);

  // The sidebar links lead into the Response-product setup flow (`/setup`).
  //
  // That means that showing them while you're in the setup flow for the
  // Response product would be quite frustrating: they'll just take you back to
  // wherever you are.
  //
  // However, if you're using the on-call calculator, we want to show you those
  // links as a teaser to get you into the Response-product setup flow.
  const shouldShowNav = identity?.app_installed;

  return (
    <div
      id="mobile-sidebar"
      className={tcx(
        isOpen ? "flex md:hidden" : "hidden",
        "z-40",
        "h-[calc(100vh-64px)] min-w-full",
        "pl-4 pr-2 pb-6  flex-col",
        "transition-all ease-in-out",
        "bg-surface-invert text-slate-300",
        { ["bg-purple-800"]: process.env.REACT_APP_DEV_SIDEBAR === "true" },
        { ["bg-red-700"]: isImpersonating },
      )}
    >
      {shouldShowNav && (
        <div className="flex flex-col gap-3">
          <nav className="flex flex-col gap-1 pt-6">
            {sidebarItems.map((item) => (
              <NavigationLink
                key={item.slug}
                item={item}
                closeSidebar={closeSidebar}
                isExpanded
              />
            ))}
          </nav>
        </div>
      )}
      <div className="grow" />
      <Button
        analyticsTrackingId="sign-out"
        onClick={logoutAll}
        icon={IconEnum.Exit}
        className="md:hidden bg-slate-700 border-slate-800 border hover:border-slate-600 shadow-button-dark text-sm px-3 py-2 rounded-2"
        theme={ButtonTheme.Unstyled}
      >
        Sign out
      </Button>
    </div>
  );
}
