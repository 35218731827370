import {
  Button,
  Checkbox,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  Input,
  StaticSingleSelect,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { isValid, parse } from "date-fns";
import { debounce, padStart } from "lodash";
import _ from "lodash";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import {
  DateRangePickerAbsoluteState,
  DateRangePickerMode,
  DateRangePickerRelativeState,
  DateRangePickerState,
  dateRangePickerStateToDates,
  DEFAULT_QUICK_INTERVAL,
  Interval,
  INTERVAL_TO_THIS_MAPPING,
  isValidState,
  QUICK_SELECT_INTERVAL_CONFIG,
  QuickSelectInterval,
} from "./types";

export const DateRangePicker = ({
  state,
  setState: setParentState,
  allowInvalid = false,
  showRangeCaption = true,
}: {
  state: DateRangePickerState;
  setState: (s: DateRangePickerState) => void;
  allowInvalid?: boolean;
  showRangeCaption?: boolean;
}): React.ReactElement | null => {
  // We want to make sure that the debouncing applies to the setParentState even if
  // its identity changes. To achieve that we:
  // 1. Put the setParentState into a ref, and update that whenever the prop changes.
  //    That means React doesn't do any re-rendering when its identity changes
  const setParentStateRef = useRef(setParentState);
  setParentStateRef.current = setParentState;
  // 2. Create the debounced function inside a ref that never changes.
  //    This means that `debounce` will only be called on the initial render and
  //    never again. If the contents of the setParentStateRef change that's alright -
  //    we'll execute whatever it's currently set to when the debounce fires.
  const debouncedSetParentState = useRef(
    debounce((s) => setParentStateRef.current(s), 250),
  ).current;

  // we have an intermediary state to allow for validation before passing the
  // state up to the parent
  const [internalState, _setInternalState] =
    useState<DateRangePickerState>(state);

  // Only persist state up to the parent when it's valid
  const setInternalState: typeof _setInternalState = (props) => {
    const newState = typeof props === "function" ? props(internalState) : props;
    if (allowInvalid || isValidState(newState)) {
      debouncedSetParentState(newState);
    }
    _setInternalState(newState);
  };

  // if the parent state changes, update the internal state
  useEffect(() => {
    if (!_.isEqual(state, internalState)) {
      setInternalState(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const changeToAbsolute = () => {
    setInternalState((state) => ({
      mode: DateRangePickerMode.Absolute,
      absolute: dateRangePickerStateToDates(state),
    }));
  };

  const changeToRelative = () => {
    setInternalState((state) => ({
      mode: DateRangePickerMode.Relative,
      relative:
        QUICK_SELECT_INTERVAL_CONFIG[
          state.quick_select || DEFAULT_QUICK_INTERVAL
        ].state,
    }));
  };

  // Always render the quick-select dropdown.
  // If relative or absolute are selected, render them below.
  return (
    <div className="flex flex-col gap-3">
      <QuickIntervalDateRangeSelect
        updateQuickSelectState={(interval) =>
          setInternalState({
            mode: DateRangePickerMode.QuickSelect,
            quick_select: interval,
          } as DateRangePickerState)
        }
        state={internalState}
        changeToAbsolute={changeToAbsolute}
        changeToRelative={changeToRelative}
        showRangeCaption={showRangeCaption}
      />
      {internalState.mode === DateRangePickerMode.Relative && (
        <RelativeDurationSelect
          state={internalState.relative}
          updateRelativeState={(diff) =>
            setInternalState(
              (state) =>
                ({
                  mode: DateRangePickerMode.Relative,
                  relative: { ...state.relative, ...diff },
                }) as DateRangePickerState,
            )
          }
        />
      )}
      {internalState.mode === DateRangePickerMode.Absolute && (
        <AbsoluteDurationSelect
          state={internalState.absolute}
          updateAbsoluteState={(diff) =>
            setInternalState(
              (state) =>
                ({
                  mode: DateRangePickerMode.Absolute,
                  absolute: { ...state.absolute, ...diff },
                }) as DateRangePickerState,
            )
          }
        />
      )}
    </div>
  );
};

const QuickIntervalDateRangeSelect = ({
  updateQuickSelectState,
  state,
  changeToAbsolute,
  changeToRelative,
  showRangeCaption = true,
}: {
  updateQuickSelectState: (interval: QuickSelectInterval) => void;
  state: DateRangePickerState;
  changeToAbsolute: () => void;
  changeToRelative: () => void;
  showRangeCaption?: boolean;
}): React.ReactElement => {
  const modeToLabel = {
    [DateRangePickerMode.Relative]: "Custom relative range",
    [DateRangePickerMode.Absolute]: "Custom date range",
  };
  return (
    <div>
      <DropdownMenu
        align="end"
        menuClassName="mt-2"
        scroll={false}
        menuWidthMatchesTrigger
        triggerButton={
          <Button
            analyticsTrackingId="selected-duration"
            className="!font-normal !pr-2 !py-1.5 w-full"
            iconProps={{ size: IconSize.Medium }}
          >
            <div className="flex justify-between w-full">
              {state.mode === DateRangePickerMode.QuickSelect ? (
                <span>
                  {/* The label of the selected quick interval */}
                  <span className="shrink-0 mr-2">
                    {QUICK_SELECT_INTERVAL_CONFIG[state.quick_select].label}
                  </span>
                  {showRangeCaption && (
                    <DisplayDateRange
                      state={{
                        mode: DateRangePickerMode.QuickSelect,
                        quick_select: state.quick_select,
                      }}
                    />
                  )}
                </span>
              ) : (
                <>{modeToLabel[state.mode]}</>
              )}

              <Icon id={IconEnum.ChevronDown} className="ml-1" />
            </div>
          </Button>
        }
      >
        {/* One item for each quick interval */}
        {Object.entries(QUICK_SELECT_INTERVAL_CONFIG)
          .filter(
            // Show if not deprected or if it's currently selected
            ([intervalID, config]) =>
              !config.deprecated || intervalID === state.quick_select,
          )
          .map(([intervalID, config]) => (
            <DropdownMenuItem
              key={intervalID}
              analyticsTrackingId="select-quick-interval"
              onSelect={() =>
                updateQuickSelectState(intervalID as QuickSelectInterval)
              }
              label={config.label}
            />
          ))}
        <hr className="mx-2 my-2" />
        <DropdownMenuItem
          analyticsTrackingId="select-custom-absolute-range"
          onSelect={changeToAbsolute}
          label="Custom date range"
        />
        <DropdownMenuItem
          analyticsTrackingId="select-custom-relative-range"
          onSelect={changeToRelative}
          label="Custom relative range"
        />
      </DropdownMenu>
    </div>
  );
};

const RelativeDurationSelect = ({
  updateRelativeState,
  state,
}: {
  updateRelativeState: (diff: Partial<DateRangePickerRelativeState>) => void;
  state: DateRangePickerRelativeState;
}): React.ReactElement => {
  return (
    <div className="mr-2">
      <div className="flex items-center text-slate-700 text-sm flex-wrap gap-2">
        <div className="flex flex-col gap-3">
          <div className="w-full flex items-center">
            Last
            <Input
              type={InputType.Number}
              id="number-of-intervals"
              placeholder=""
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const val = parseInt(e.target.value);
                if (isNaN(val) || val <= 0) {
                  // Don't set invalid values, e.g. ''
                  return;
                }
                updateRelativeState({
                  numberOfIntervals: val,
                });
              }}
              className={tcx("!w-12 mx-2 text-center")}
              min={1}
              defaultValue={state?.numberOfIntervals?.toString() ?? ""}
            />
            <StaticSingleSelect
              id="interval"
              placeholder="Select interval"
              isClearable={false}
              className="mr-2"
              value={state?.interval || Interval.Months}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  updateRelativeState({
                    interval: selectedOption as Interval,
                  });
                }
              }}
              options={Object.entries(Interval).map(([label, value], idx) => ({
                label: label.toLowerCase(),
                value,
                sort_key: padStart(idx.toString(), 2, "0"),
              }))}
            />
          </div>
          <div className="w-full">
            <Checkbox
              id={"toggle-include-week"}
              onChange={() =>
                updateRelativeState({
                  includeThisInterval: !state.includeThisInterval,
                })
              }
              label={`Include ${
                INTERVAL_TO_THIS_MAPPING[state.interval || Interval.Months]
              }`}
              checked={state.includeThisInterval}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const AbsoluteDurationSelect = ({
  updateAbsoluteState,
  state,
}: {
  updateAbsoluteState: (diff: Partial<DateRangePickerAbsoluteState>) => void;
  state: DateRangePickerAbsoluteState;
}): React.ReactElement => {
  return (
    <div className="flex flex-col items-center gap-3 text-slate-700 text-sm w-full">
      <div className="flex items-center gap-4 w-full">
        <div className="w-10 text-content-tertiary">Start</div>
        <Input
          type={InputType.Date}
          id="from-date"
          placeholder="Start date"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const newDate = e.target.valueAsDate;

            // Don't allow the date to be set before Jan 1st 2000 or after to date
            if (
              !newDate ||
              newDate < new Date("2000-01-01") ||
              newDate > new Date(state.to)
            ) {
              return;
            }

            updateAbsoluteState({
              from: e.target.value,
            });
          }}
          value={state?.from}
          className="!pr-2 !py-1"
        />
      </div>
      <div className="flex items-center gap-4 w-full">
        <div className="w-10 text-content-tertiary">End</div>
        <Input
          type={InputType.Date}
          id="to-date"
          placeholder="End date"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const newDate = e.target.valueAsDate;

            // Don't allow the date to be set before Jan 1st 2000 or before from date
            if (
              !newDate ||
              newDate < new Date("2000-01-01") ||
              newDate < new Date(state.from)
            ) {
              return;
            }

            updateAbsoluteState({
              to: e.target.value,
            });
          }}
          value={state?.to}
          className="!pr-2 !py-1"
        />
      </div>
    </div>
  );
};

export const DisplayDateRange = ({
  state,
  hideParentheses = false,
  className,
}: {
  state: DateRangePickerState;
  hideParentheses?: boolean;
  className?: string;
}): React.ReactElement | null => {
  const { from, to } = dateRangePickerStateToDates(state);
  const dateFmtOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };
  const fromDate = parse(from, "yyyy-MM-dd", new Date("2000-01-01"));
  const toDate = parse(to, "yyyy-MM-dd", new Date("2000-01-01"));
  if (fromDate.getFullYear() !== toDate.getFullYear()) {
    dateFmtOptions.year = "2-digit";
  }

  if (!isValid(fromDate) || !isValid(toDate)) {
    return null;
  }

  const range = `${fromDate.toLocaleDateString(
    "default",
    dateFmtOptions,
  )} → ${toDate.toLocaleDateString("default", dateFmtOptions)}`;

  return (
    <span className={tcx("shrink-0 text-content-tertiary", className)}>
      {hideParentheses ? range : `(${range})`}
    </span>
  );
};
