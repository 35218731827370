import { Button, ButtonTheme, IconEnum, IconSize } from "@incident-ui";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";

import { useNavigateToModal } from "../../../../utils/query-params";

export const AddCallURLButton = () => {
  const navigateToModal = useNavigateToModal();

  return (
    <Button
      icon={IconEnum.CallPlus}
      iconProps={{
        size: IconSize.Medium,
      }}
      theme={ButtonTheme.Naked}
      onClick={() => navigateToModal(IncidentHeaderModal.UpdateCall)}
      analyticsTrackingId="set-call-url"
      className="-ml-0.5"
    >
      Add call link
    </Button>
  );
};
