/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffSetPlanInfoRequestBody
 */
export interface StaffSetPlanInfoRequestBody {
    /**
     * The billing mode to set.
     * @type {string}
     * @memberof StaffSetPlanInfoRequestBody
     */
    billing_mode: StaffSetPlanInfoRequestBodyBillingModeEnum;
    /**
     * The minimum number of on-call addons we will bill this organisation for.
     * @type {number}
     * @memberof StaffSetPlanInfoRequestBody
     */
    committed_minimum_oncall_addons?: number;
    /**
     * The minimum number of responder seats we will bill this organisation for.
     * @type {number}
     * @memberof StaffSetPlanInfoRequestBody
     */
    committed_minimum_seats?: number;
    /**
     * The name of the plan to set.
     * @type {string}
     * @memberof StaffSetPlanInfoRequestBody
     */
    plan_name: StaffSetPlanInfoRequestBodyPlanNameEnum;
}

/**
* @export
* @enum {string}
*/
export enum StaffSetPlanInfoRequestBodyBillingModeEnum {
    Unset = 'unset',
    PerSeat = 'per_seat',
    FlatRate = 'flat_rate'
}/**
* @export
* @enum {string}
*/
export enum StaffSetPlanInfoRequestBodyPlanNameEnum {
    Legacy = 'legacy',
    Trial = 'trial',
    StarterV1 = 'starter_v1',
    ProV1 = 'pro_v1',
    EnterpriseV1 = 'enterprise_v1',
    BasicV2 = 'basic_v2',
    TeamV2 = 'team_v2',
    ProV2 = 'pro_v2',
    EnterpriseV2 = 'enterprise_v2'
}

export function StaffSetPlanInfoRequestBodyFromJSON(json: any): StaffSetPlanInfoRequestBody {
    return StaffSetPlanInfoRequestBodyFromJSONTyped(json, false);
}

export function StaffSetPlanInfoRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffSetPlanInfoRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billing_mode': json['billing_mode'],
        'committed_minimum_oncall_addons': !exists(json, 'committed_minimum_oncall_addons') ? undefined : json['committed_minimum_oncall_addons'],
        'committed_minimum_seats': !exists(json, 'committed_minimum_seats') ? undefined : json['committed_minimum_seats'],
        'plan_name': json['plan_name'],
    };
}

export function StaffSetPlanInfoRequestBodyToJSON(value?: StaffSetPlanInfoRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billing_mode': value.billing_mode,
        'committed_minimum_oncall_addons': value.committed_minimum_oncall_addons,
        'committed_minimum_seats': value.committed_minimum_seats,
        'plan_name': value.plan_name,
    };
}

