import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { IconEnum } from "@incident-ui";

import { UserPreferencesHeading } from "../common/UserPreferencesHeading";
import { ConnectAccountButton } from "./ConnectAccountButton";
import { ConnectedAccountsTable } from "./ConnectedAccountsTable";

export const ConnectedAccountsPage = () => {
  return (
    <SecondaryNavSubPageWrapper
      title="Connected accounts"
      icon={IconEnum.Integrations}
    >
      <UserPreferencesHeading
        title="Connected accounts"
        description="Link your incident.io account with your accounts in connected services"
      />
      <ConnectedAccountsTable />
      <ConnectAccountButton />
    </SecondaryNavSubPageWrapper>
  );
};
