import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import {
  ButtonSize,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
} from "@incident-ui";
import React, { ReactNode } from "react";
import { tcx } from "src/utils/tailwind-classes";

export const AlertRouteFormSection = ({
  title,
  color,
  icon,
  accessory,
  disabled,
  children,
  isCurrentFirstTimeStep,
  firstTimeContent,
}: {
  title: string;
  color?: ColorPaletteEnum;
  icon: IconEnum;
  accessory: React.ReactNode;
  disabled?: boolean;
  children: React.ReactNode;
  isCurrentFirstTimeStep?: boolean;
  firstTimeContent?: React.ReactNode;
}) => {
  const palette = getColorPalette(color);

  return (
    <div
      className={tcx(
        "flex flex-col items-start max-w-[560px] w-[560px] shadow-sm rounded-2 p-4 gap-4 bg-white",
        isCurrentFirstTimeStep
          ? "border border-blue-500"
          : "border border-stroke",
      )}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-2">
          <Icon
            id={icon}
            className={tcx(palette.icon, {
              "text-content-tertiary": disabled,
            })}
          />
          <div
            className={tcx("text-sm-bold", {
              "text-content-tertiary": disabled,
            })}
          >
            {title}
          </div>
        </div>
        {accessory}
      </div>
      {firstTimeContent && isCurrentFirstTimeStep && (
        <Callout iconOverride={IconEnum.Bulb} theme={CalloutTheme.Info}>
          {firstTimeContent}
        </Callout>
      )}
      {children}
    </div>
  );
};

export const AlertRouteSteppedSections = ({
  sections,
}: {
  sections: React.ReactNode[];
}) => {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      {sections.map((section, index) => (
        <div key={index}>
          {section}
          {index !== sections.length - 1 && (
            <div className="flex flex-col items-center py-2">
              <div className="bg-slate-200 w-[5px] h-[5px] rounded-full" />
              <div className="h-10 w-[1px] bg-slate-200" />
              <div className="bg-slate-200 w-[5px] h-[5px] rounded-full" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const AlertRouteSplitDrawerSection = ({
  left,
  right,
  className,
}: {
  left: ReactNode;
  right?: ReactNode;
  className?: string;
}) => {
  return (
    <div className={tcx("flex flex-col lg:flex-row", className)}>
      <div className="flex flex-col p-6 gap-4 lg:w-[50%] overflow-auto">
        {left}
      </div>
      <div
        className={tcx(
          "px-8 py-6 bg-slate-50 border-l border-stroke gap-4 lg:w-[50%] overflow-auto",
          // If we don't have a right side, we only want to show an empty grey box if
          // we're in side-by side mode (so when screen is large)
          right ? "flex flex-col" : "hidden lg:flex flex-col",
        )}
      >
        {right}
      </div>
    </div>
  );
};

export const AlertRouteYesNoButtonGroup = ({
  onChange,
  disabled,
}: {
  onChange: (boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <div className="flex gap-2">
      <GatedButton
        disabled={disabled}
        disabledTooltipContent="Complete previous steps"
        size={ButtonSize.Small}
        onClick={() => onChange(true)}
        analyticsTrackingId={null}
      >
        Yes
      </GatedButton>
      <GatedButton
        disabled={disabled}
        disabledTooltipContent="Complete previous steps"
        size={ButtonSize.Small}
        onClick={() => onChange(false)}
        analyticsTrackingId={null}
      >
        No
      </GatedButton>
    </div>
  );
};
