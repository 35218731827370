/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnboardingSetDesiredPlanRequestBody
 */
export interface OnboardingSetDesiredPlanRequestBody {
    /**
     * The desired plan
     * @type {string}
     * @memberof OnboardingSetDesiredPlanRequestBody
     */
    plan_name: OnboardingSetDesiredPlanRequestBodyPlanNameEnum;
}

/**
* @export
* @enum {string}
*/
export enum OnboardingSetDesiredPlanRequestBodyPlanNameEnum {
    Legacy = 'legacy',
    Trial = 'trial',
    StarterV1 = 'starter_v1',
    ProV1 = 'pro_v1',
    EnterpriseV1 = 'enterprise_v1',
    BasicV2 = 'basic_v2',
    TeamV2 = 'team_v2',
    ProV2 = 'pro_v2',
    EnterpriseV2 = 'enterprise_v2'
}

export function OnboardingSetDesiredPlanRequestBodyFromJSON(json: any): OnboardingSetDesiredPlanRequestBody {
    return OnboardingSetDesiredPlanRequestBodyFromJSONTyped(json, false);
}

export function OnboardingSetDesiredPlanRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingSetDesiredPlanRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plan_name': json['plan_name'],
    };
}

export function OnboardingSetDesiredPlanRequestBodyToJSON(value?: OnboardingSetDesiredPlanRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plan_name': value.plan_name,
    };
}

