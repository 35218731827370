import {
  ScheduleEntry,
  ScheduleLayer,
  ScheduleOverride,
  ScheduleRotation,
  SchedulesListEntriesResponseBody,
  SchedulesListHolidayEntriesResponseBody,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ScheduleEntryComponent } from "@incident-shared/schedules/ScheduleOverview/ScheduleEntryComponent";
import { TimelineMarkerLine } from "@incident-shared/schedules/ScheduleOverview/TimelineMarker/TimelineMarkerLine";
import { calculateEntryStyles } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/calculateEntryStyles";
import { CollapsableContainer } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/CollapsableContainer";
import { ROTA_NAME_COLUMN_WIDTH } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/constants";
import { HolidaysRow } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/HolidaysRow";
import { TimelineBackground } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/TimelineBackground";
import { TimezoneRow } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/TimezoneRow";
import {
  calculateSegments,
  TimePeriodOption,
} from "@incident-shared/schedules/ScheduleOverview/types";
import { flattenScheduleEntries } from "@incident-shared/schedules/ScheduleOverview/utils/flattenScheduleEntries";
import { useKeyboardEvents } from "@incident-shared/schedules/useKeyboardEvents";
import { Button, ButtonTheme, LoadingBar } from "@incident-ui";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import * as d3 from "d3";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { OverrideData } from "src/components/legacy/on-call/schedules/overrides/OverrideCreateEditDrawer";
import { tcx } from "src/utils/tailwind-classes";

export type ScheduleEntryOrOverride = (ScheduleEntry | ScheduleOverride) & {
  user_id: string;
};

export type UpcomingRotaChange = {
  at: DateTime;
  rotaId: string;
};

// If we receive an entriesResponse, we'll render a collapsed 'final'
// rota, that can be expanded to show scheduled and override entries.
//
// If we receive a list of entries, we'll just show a plain, unexpandable
// list of entries.
type PlainOrGroupedEntries =
  | {
      entriesResponse: SchedulesListEntriesResponseBody | undefined;
    }
  | {
      entries: ScheduleEntryOrOverride[];
    };

export type TimelineSectionProps = {
  // If collapsable is provided, we'll render a button to expand/collapse the timeline and add a border
  collapsable?: boolean;
  collapseByDefault?: boolean;
  // If a title is provided, we'll give the timeline a title and a border
  title?: string;

  width: number;
  rotations: (ScheduleRotation & { isDraft?: boolean })[];
  isLoadingEntries: boolean;
  timePeriod: TimePeriodOption;
  now: DateTime;
  timelineStartPoint: DateTime;
  timelineEndpoint: DateTime;
  disableTooltip?: boolean;
  overriddenEntry?: Partial<OverrideData>;
  disableOverride?: boolean;
  scheduleTimezone: string;
  upcomingRotaChanges?: UpcomingRotaChange[];
  selectedTimezones?: string[];
  holidaysResponse?: SchedulesListHolidayEntriesResponseBody;

  // The ID of the schedule, if we've got one yet (we might be previewing, so it's optional!)
  scheduleId: string | undefined;
} & PlainOrGroupedEntries;

export const TimelineSection = ({
  collapsable,
  collapseByDefault,
  title,
  timePeriod,
  timelineEndpoint,
  timelineStartPoint,
  now,
  isLoadingEntries,
  disableTooltip,
  rotations,
  overriddenEntry,
  disableOverride,
  scheduleTimezone,
  upcomingRotaChanges,
  selectedTimezones = [],
  width,
  holidaysResponse,
  scheduleId,
  ...entriesProps
}: TimelineSectionProps) => {
  timelineStartPoint = timelineStartPoint.setZone(scheduleTimezone);
  timelineEndpoint = timelineEndpoint.setZone(scheduleTimezone);

  // We're in a small screen if we're in the override modal or if we're in the schedule form.
  // (Overrides are disabled in there). In a small screen we want to render some dates differently.
  const isInSmallScreen = Boolean(overriddenEntry || disableOverride);

  // Use d3 to make an xScale, which makes it easy to convert
  // from dates to absolute positions, and vice versa.
  const xScale = d3
    .scaleTime()
    .domain([timelineStartPoint.toJSDate(), timelineEndpoint.toJSDate()])
    .range([0, width - ROTA_NAME_COLUMN_WIDTH]);

  const uniqueRotaIds: string[] = _.uniq(
    rotations.map((r: ScheduleRotation) => r.id),
  );

  const showMultitimezones =
    timePeriod === TimePeriodOption.ThreeHours ||
    timePeriod === TimePeriodOption.OneDay;

  const tzForStack = showMultitimezones
    ? _.uniqBy(selectedTimezones.concat(scheduleTimezone), (tz) => {
        return new Intl.DateTimeFormat("en", {
          timeZone: tz,
          timeZoneName: "shortOffset",
        })
          .formatToParts()
          .find((e) => e.type === "timeZoneName")?.value;
      })
    : [scheduleTimezone];

  let hasWrapper: boolean;
  let Wrapper: React.FC<{
    collapseByDefault?: boolean;
    title?: string;
    collapsable?: boolean;
    children: React.ReactNode;
  }>;
  if (title || collapsable) {
    hasWrapper = true;
    Wrapper = CollapsableContainer;
  } else {
    hasWrapper = false;
    Wrapper = React.Fragment;
  }

  return (
    <Wrapper
      collapseByDefault={collapseByDefault}
      title={title}
      collapsable={collapsable}
    >
      <div className={"h-full relative overflow-hidden"}>
        {/* Render the vertical indicator lines for each time segment */}
        <div
          className={"absolute inset-0"}
          style={{
            paddingLeft: ROTA_NAME_COLUMN_WIDTH,
          }}
        >
          <TimelineBackground
            xScale={xScale}
            timelineStartPoint={timelineStartPoint}
            timePeriod={timePeriod}
          />
        </div>

        {/* We use a flex column to render the rotas,
          but then use xScale to work out horizontal
           position of things within each rota */}
        <div
          id="timeline-content"
          className={tcx(
            "flex flex-col w-full relative overflow-hidden h-full flex-1",
          )}
        >
          {/* Render the heading that shows each segment of time in all selected timezones */}
          {tzForStack.map((tz, i) => {
            const tzStartPoint = timelineStartPoint.setZone(tz);
            const tzEndpoint = timelineEndpoint.setZone(tz);
            const tzSegments = calculateSegments({
              timePeriod,
              timelineStartPoint: tzStartPoint,
            });

            return (
              <TimezoneRow
                key={tz}
                tz={tz}
                isFirst={i === 0}
                isLast={i === tzForStack.length - 1}
                noBottomBorder={!!holidaysResponse}
                hasMultipleTimezones={
                  showMultitimezones && tzForStack.length > 1
                }
                xScale={xScale}
                segments={tzSegments}
                timePeriod={timePeriod}
                timelineStartPoint={tzStartPoint}
                timelineEndpoint={tzEndpoint}
                isInSmallScreen={isInSmallScreen}
                upcomingRotaChanges={upcomingRotaChanges}
                now={now}
                hasWrapper={hasWrapper}
              />
            );
          })}

          {holidaysResponse ? (
            <HolidaysRow
              holidaysResponse={holidaysResponse}
              scheduleTimezone={scheduleTimezone}
              xScale={xScale}
              timelineStartPoint={timelineStartPoint}
              timelineEndpoint={timelineEndpoint}
              disableOverride={disableOverride}
              now={now}
              timePeriod={timePeriod}
              hasWrapper={hasWrapper}
              scheduleId={scheduleId}
              upcomingRotaChanges={upcomingRotaChanges}
            />
          ) : null}

          {/* Render the rotas */}
          <div id={"rotas"}>
            {uniqueRotaIds.map((rotaId, index) => {
              return (
                <TimelineRotation
                  now={now}
                  key={rotaId}
                  rotaId={rotaId}
                  rotations={rotations}
                  isLoadingEntries={isLoadingEntries}
                  xScale={xScale}
                  timelineStartPoint={timelineStartPoint}
                  timelineEndpoint={timelineEndpoint}
                  overriddenEntry={overriddenEntry}
                  disableTooltip={disableTooltip}
                  disableOverride={disableOverride}
                  upcomingRotaChanges={upcomingRotaChanges}
                  isFirst={index === 0}
                  isLast={index === uniqueRotaIds.length - 1}
                  hasWrapper={hasWrapper}
                  {...entriesProps}
                />
              );
            })}
          </div>

          {/* Render the 'now' marker down to the bottom of the page */}
          <div
            className={"flex-1 w-full"}
            style={{
              paddingLeft: ROTA_NAME_COLUMN_WIDTH,
            }}
          >
            <div className={"w-full h-full relative"}>
              <NowMarker
                now={now}
                xScale={xScale}
                timelineStartPoint={timelineStartPoint}
                timelineEndpoint={timelineEndpoint}
              />
              {upcomingRotaChanges && (
                <>
                  {upcomingRotaChanges?.map((c) => {
                    if (c.at < timelineStartPoint || c.at > timelineEndpoint) {
                      return null;
                    }

                    return (
                      <TimelineMarkerLine
                        key={c.rotaId + c.at.toISO()}
                        dashed={true}
                        style={{
                          position: "absolute",
                          left: xScale(c.at.toJSDate()),
                          top: 0,
                          bottom: 0,
                        }}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const TimelineRotation = ({
  now,
  isLoadingEntries,
  rotaId,
  rotations,
  xScale,
  timelineStartPoint,
  timelineEndpoint,
  overriddenEntry,
  disableTooltip,
  disableOverride,
  upcomingRotaChanges,
  isLast,
  isFirst,
  hasWrapper,
  ...entriesProps
}: {
  now: DateTime;
  isLoadingEntries: boolean;
  rotaId: string;
  rotations: ScheduleRotation[];
  xScale: d3.ScaleTime<number, number>;
  timelineStartPoint: DateTime;
  timelineEndpoint: DateTime;
  overriddenEntry?: Partial<OverrideData>;
  disableTooltip?: boolean;
  disableOverride?: boolean;
  upcomingRotaChanges?: UpcomingRotaChange[];
  hasWrapper?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
} & PlainOrGroupedEntries) => {
  const [isShowingOverrides, setIsShowingOverrides] = React.useState(false);
  const navigate = useOrgAwareNavigate();
  const { id: scheduleId } = useParams<{ id: string }>();

  const canExpandOverrides = "entriesResponse" in entriesProps;
  const entriesResponse = canExpandOverrides
    ? entriesProps.entriesResponse
    : undefined;

  const finalEntries: ScheduleEntryOrOverride[] = useMemo(() => {
    return flattenScheduleEntries(entriesResponse?._final ?? []).map((e) => ({
      ...e,
      user_id: "user_id" in e ? e.user_id : e.external_user_id,
    }));
  }, [entriesResponse]);

  const scheduledEntries: ScheduleEntryOrOverride[] = useMemo(() => {
    return flattenScheduleEntries(entriesResponse?.scheduled ?? []).map(
      (e) => ({
        ...e,
        user_id: "user_id" in e ? e.user_id : e.external_user_id,
      }),
    );
  }, [entriesResponse]);

  const overrideEntries: ScheduleEntryOrOverride[] = useMemo(() => {
    return entriesResponse?.overrides ?? [];
  }, [entriesResponse]);

  const rotation = rotations.find((r) => r.id === rotaId);

  const uniqueLayers: ScheduleLayer[] = useMemo(
    () =>
      _.chain(rotations)
        .filter((r) => r.id === rotaId)
        .flatMap((r) => r.layers)
        .uniqBy((l) => l.id)
        .value(),
    [rotations, rotaId],
  );

  useKeyboardEvents(
    useCallback(
      (e) => {
        if (!canExpandOverrides) {
          return;
        }

        // If you press down or up, we expand or collapse the overrides
        if (e.key === "ArrowDown") {
          setIsShowingOverrides(true);
        } else if (e.key === "ArrowUp") {
          setIsShowingOverrides(false);
        }
      },
      [setIsShowingOverrides, canExpandOverrides],
    ),
  );

  if (!rotation) {
    return null;
  }

  const isOnlyRota = isFirst && isLast;

  return (
    <div className={"flex flex-col items-stretch relative"}>
      <div
        id={isShowingOverrides ? "scheduled-entries" : "final-entries"}
        className={tcx("flex flex-row", {
          "border-dashed": isShowingOverrides,
          "border-b border-stroke": hasWrapper ? isOnlyRota || !isLast : true,
        })}
      >
        {/* Render the label on the left hand side */}
        {canExpandOverrides ? (
          <ExpandingRotationName
            hasWrapper={hasWrapper}
            rotation={rotation}
            isExpanded={isShowingOverrides}
            onClick={() => setIsShowingOverrides(!isShowingOverrides)}
          />
        ) : (
          <div
            style={{
              width: ROTA_NAME_COLUMN_WIDTH,
              maxWidth: ROTA_NAME_COLUMN_WIDTH,
              minWidth: ROTA_NAME_COLUMN_WIDTH,
            }}
            className={"px-4 text-content-secondary text-xs flex items-center"}
          >
            {rotation?.name}
          </div>
        )}

        {/* Render each layer in this rotation */}
        <div className={"flex flex-col flex-1 items-stretch gap-y-0.5 py-4"}>
          {uniqueLayers.map((layer) => {
            return (
              <TimelineLayer
                key={layer.id}
                layer={layer}
                isLoadingEntries={isLoadingEntries}
                entries={
                  canExpandOverrides
                    ? isShowingOverrides
                      ? scheduledEntries
                      : finalEntries
                    : entriesProps.entries
                }
                xScale={xScale}
                timelineStartPoint={timelineStartPoint}
                timelineEndpoint={timelineEndpoint}
                overriddenEntry={overriddenEntry}
                disableTooltip={disableTooltip}
                disableOverride={disableOverride}
              />
            );
          })}
        </div>
      </div>

      <AnimatePresence>
        {isShowingOverrides && (
          <motion.div
            initial={{ height: "0" }}
            animate={{ height: "auto" }}
            exit={{ height: "0" }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            id={"override-entries"}
            className={"flex flex-row border-b border-stroke"}
          >
            {/* Render the override label on the left hand side */}
            <motion.div
              key={`${rotaId}-overrides`}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              style={{
                width: ROTA_NAME_COLUMN_WIDTH,
                maxWidth: ROTA_NAME_COLUMN_WIDTH,
                minWidth: ROTA_NAME_COLUMN_WIDTH,
              }}
              className={tcx(
                "text-content-secondary text-xs flex items-center justify-between",
                {
                  "px-6": hasWrapper,
                  "px-8": !hasWrapper,
                },
              )}
            >
              <span>Overrides</span>
              {scheduleId && (
                <Button
                  title={"Add override"}
                  theme={ButtonTheme.Naked}
                  icon={IconEnum.Add}
                  analyticsTrackingId={"add-override"}
                  onClick={() => {
                    navigate(
                      `/on-call/schedules/${scheduleId}/overrides/create?initial_rotation_id=${rotaId}`,
                    );
                  }}
                />
              )}
            </motion.div>

            {/* Render each layer for overrides */}
            <div className={"flex flex-col flex-1 items-stretch gap-y-2 py-4"}>
              {uniqueLayers.map((layer) => {
                return (
                  <TimelineLayer
                    key={layer.id}
                    layer={layer}
                    isLoadingEntries={isLoadingEntries}
                    entries={overrideEntries}
                    xScale={xScale}
                    timelineStartPoint={timelineStartPoint}
                    timelineEndpoint={timelineEndpoint}
                    overriddenEntry={overriddenEntry}
                    disableTooltip={disableTooltip}
                    disableOverride={disableOverride}
                  />
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div
        className={"absolute inset-0 pointer-events-none"}
        style={{
          paddingLeft: ROTA_NAME_COLUMN_WIDTH,
        }}
      >
        <div className={"w-full h-full relative"}>
          {/* Render a line for the upcoming rotation changes */}
          {upcomingRotaChanges?.map((c) => {
            if (c.at < timelineStartPoint || c.at > timelineEndpoint) {
              return null;
            }

            return (
              <TimelineMarkerLine
                key={c.rotaId + c.at.toISO()}
                dashed={c.rotaId !== rotaId}
                style={{
                  position: "absolute",
                  left: xScale(c.at.toJSDate()),
                  top: 0,
                  bottom: 0,
                }}
              />
            );
          })}

          <NowMarker
            now={now}
            xScale={xScale}
            timelineStartPoint={timelineStartPoint}
            timelineEndpoint={timelineEndpoint}
          />
        </div>
      </div>
    </div>
  );
};

const TimelineLayer = ({
  layer,
  entries,
  xScale,
  timelineStartPoint,
  timelineEndpoint,
  overriddenEntry,
  disableTooltip,
  disableOverride,
  isLoadingEntries,
}: {
  isLoadingEntries: boolean;
  layer: ScheduleLayer;
  entries: ScheduleEntryOrOverride[];
  xScale: d3.ScaleTime<number, number>;
  timelineStartPoint: DateTime;
  timelineEndpoint: DateTime;
  overriddenEntry?: Partial<OverrideData>;
  disableTooltip?: boolean;
  disableOverride?: boolean;
}) => {
  return (
    <div key={layer.id} className={"relative h-[28px] overflow-hidden"}>
      {isLoadingEntries ? (
        <div className={"absolute inset-0"}>
          <LoadingBar />
        </div>
      ) : (
        entries.map((e) => {
          if (e.layer_id !== layer.id) {
            return null;
          }

          const styles = calculateEntryStyles({
            timelineStartPoint,
            timelineEndpoint,
            start_at: e.start_at,
            end_at: e.end_at,
            xScale,
          });

          if (!styles) {
            return null;
          }

          return (
            <div
              key={`${e.layer_id}-${e.start_at}-${e.end_at}-${e.user_id}`}
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: styles.left,
                width: styles.width,
              }}
            >
              <ScheduleEntryComponent
                userId={e.user_id}
                width={"100%"}
                clipContent
                roundedLeft={!styles.hasClippedStart}
                roundedRight={!styles.hasClippedEnd}
                isDraft={
                  overriddenEntry &&
                  overriddenEntry.layerId === e.layer_id &&
                  segmentIsOverridden(
                    e.start_at,
                    e.end_at,
                    overriddenEntry.startAt as Date,
                    overriddenEntry.endAt as Date,
                  )
                }
                {...(disableTooltip
                  ? {
                      enableTooltip: false,
                    }
                  : {
                      enableTooltip: true,
                      enableCreateOverride: !disableOverride,
                      entries: [e],
                      timezone: timelineStartPoint.zoneName,
                    })}
              />
            </div>
          );
        })
      )}
    </div>
  );
};

const ExpandingRotationName = ({
  isExpanded,
  rotation,
  onClick,
  hasWrapper,
}: {
  rotation: ScheduleRotation;
  isExpanded: boolean;
  onClick?: () => void;
  hasWrapper?: boolean;
}) => (
  <div
    style={{
      width: ROTA_NAME_COLUMN_WIDTH,
      maxWidth: ROTA_NAME_COLUMN_WIDTH,
      minWidth: ROTA_NAME_COLUMN_WIDTH,
    }}
    className={tcx(
      "text-content-secondary hover:text-content-primary !no-underline text-xs flex flex-row items-center gap-0.5 hover:cursor-pointer w-full py-4 justify-between select-none",

      {
        "px-4": hasWrapper,
        "px-8": !hasWrapper,
      },
    )}
    onClick={onClick}
  >
    <span>{rotation?.name}</span>
    <Icon
      id={isExpanded ? IconEnum.Collapse : IconEnum.Expand}
      size={IconSize.Small}
    />
  </div>
);

const segmentIsOverridden = (
  segmentStart: Date,
  segmentEnd: Date,
  overrideStart: Date,
  overrideEnd: Date,
) => {
  return (
    segmentStart.getTime() >= overrideStart.getTime() &&
    segmentEnd.getTime() <= overrideEnd.getTime()
  );
};

// NowMarker is the red line that indicates 'now' on the timeline/calendar
export const NowMarker = ({
  now,
  xScale,
  timelineStartPoint,
  timelineEndpoint,
}: {
  timelineStartPoint: DateTime;
  timelineEndpoint: DateTime;
  now: DateTime;
  xScale: d3.ScaleTime<number, number>;
}) => {
  if (now < timelineStartPoint || now > timelineEndpoint) {
    return null;
  }
  return (
    <div
      id="now-marker"
      style={{
        pointerEvents: "none",
        position: "absolute",
        left: xScale(now.toJSDate()),
        top: 0,
        bottom: 0,
      }}
    >
      <div
        style={{}}
        className={`z-10 border-r-[1px] border-r-red-500 h-full`}
      />
    </div>
  );
};
