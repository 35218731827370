import { Product } from "@incident-shared/billing";
import React from "react";
import graphic from "src/components/settings/banners/banner-follow-ups.png";
import { useProductAccess } from "src/hooks/useProductAccess";

import { Plurality, ProductUpsellNotice } from "../ProductUpsellNotice";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { FollowUpExportingSection } from "./FollowUpExportSection";
import { FollowUpRequiredOwnerSection } from "./FollowUpRequiredOwnerSection";
import { PrioritiesViewSection } from "./PrioritiesViewSection";

export const SettingsFollowUpsPage = (): React.ReactElement => {
  const { hasProduct } = useProductAccess();
  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        title="Prioritize follow-ups"
        subtitle="Set priorities for follow-up tasks to ensure timely completion."
        articleId={7169106}
        graphic={<img src={graphic} className="h-40" />}
      />
      {hasProduct(Product.Response) ? (
        <div className="space-y-8">
          <FollowUpExportingSection />
          <FollowUpRequiredOwnerSection />
          <PrioritiesViewSection />
        </div>
      ) : (
        <ProductUpsellNotice
          featureName="Follow-ups"
          plurality={Plurality.Plural}
          requiredProduct={Product.Response}
        />
      )}
    </SettingsSubPageWrapper>
  );
};
