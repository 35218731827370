import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import { ErrorMessageV2 } from "@incident-shared/forms/v2/Errors";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ButtonTheme, ConfirmationDialog, Input, Txt } from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { useAPIMutation } from "src/utils/swr";

export const DisableButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  let disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.TeardownOrganisation,
  );
  if (data.stripe_subscription_id !== undefined) {
    disabledProps = {
      disabled: true,
      disabledTooltipContent:
        "This organisation has paid us, so cannot be disabled",
    };
  }

  return (
    <>
      <GatedButton
        analyticsTrackingId={null}
        onClick={() => setIsOpen(true)}
        theme={ButtonTheme.Destroy}
        {...disabledProps}
      >
        Disable
      </GatedButton>

      {isOpen && <DisableModal data={data} onClose={() => setIsOpen(false)} />}
    </>
  );
};

const DisableModal = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const formMethods = useForm<{ check: string }>();
  const navigate = useOrgAwareNavigate();

  const { trigger, isMutating, genericError } = useAPIMutation(
    "staffShowOrganisation",
    { organisationSlug: data.organisation_slug },
    async (apiClient) => {
      await apiClient.staffDisableOrganisation({
        organisationSlug: data.organisation_slug,
      });

      // Once disabled, the org is not visible in staff room anymore, so
      // redirect away
      navigate("/staff-room");
    },
  );

  const requiredInput = `I want to troll ${data.organisation_name}`;

  return (
    <ConfirmationDialog
      isOpen
      analyticsTrackingId={null}
      title="Disable organisation"
      onCancel={onClose}
      onConfirm={() => trigger({})}
      confirmButtonText={`Disable ${data.organisation_name}`}
      saving={isMutating}
      disabled={formMethods.watch("check") !== requiredInput}
      disabledTooltipContent={`Type '${requiredInput}' to confirm you really want to do this`}
      className="text-sm space-y-2"
    >
      {genericError && <ErrorMessageV2 message={genericError} />}
      <p>
        Disabling an organisation means that we{" "}
        <Txt inline bold>
          send them to the careers page
        </Txt>
        .
      </p>
      <p>
        We use this to boot out competitors: you{" "}
        <Txt inline bold>
          can&apos;t undo this action
        </Txt>{" "}
        without paging an on-call engineer.
      </p>
      <hr className="text-slate-200 !my-4" />
      <FormInputWrapper
        id="name"
        label={`Type '${requiredInput}' to confirm`}
        labelWrapperClassName="mb-1"
        errors={{}}
      >
        <Input id="check" {...formMethods.register("check")} />
      </FormInputWrapper>
    </ConfirmationDialog>
  );
};
