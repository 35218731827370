import {
  StaffSetUserPermissionsRequestBody,
  StaffSetUserPermissionsRequestBodyPermissionsEnum,
  UserWithStaffPermissions,
} from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { StaticMultiSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";

import { permissionOptions } from "./staffPermissions";

export const EditPermissionsModal = ({
  user,
  onClose,
}: {
  user: UserWithStaffPermissions;
  onClose: () => void;
}) => {
  const formMethods = useForm<StaffSetUserPermissionsRequestBody>({
    defaultValues: {
      permissions:
        user.permissions as unknown as StaffSetUserPermissionsRequestBodyPermissionsEnum[],
    },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "staffListUsers",
    undefined,
    async (apiClient, data: StaffSetUserPermissionsRequestBody) =>
      await apiClient.staffSetUserPermissions({
        id: user.id,
        setUserPermissionsRequestBody: data,
      }),
    { onSuccess: onClose },
  );

  return (
    <FormModalV2
      formMethods={formMethods}
      onClose={onClose}
      genericError={genericError}
      title={`Staff permissions for ${user.name}`}
      analyticsTrackingId={null}
      onSubmit={trigger}
      footer={
        <ModalFooter
          saving={isMutating}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      <StaticMultiSelectV2
        formMethods={formMethods}
        name="permissions"
        placeholder="Select permissions..."
        options={permissionOptions}
      />
    </FormModalV2>
  );
};
