import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  LoadingBar,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import pluralize from "pluralize";
import { useState } from "react";
import { useLocation, useParams } from "react-router";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import {
  CatalogEntry,
  CatalogRelation,
  CatalogType,
  IntegrationSettingsProviderEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useCatalogResources } from "src/hooks/useCatalogResources";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { CatalogUserEntryConnectedAccountsTable } from "../connected-accounts/CatalogUserEntryConnectedAccountsTable";
import { CatalogEntryReferences } from "../entry-list/CatalogEntryReferences";
import { CatalogEntryAttributes } from "./CatalogEntryAttributes";
import { CatalogEntryIncidents } from "./CatalogEntryIncidents";

export const CatalogEntryViewDrawer = ({
  refetchEntries,
  onClose,
}: {
  refetchEntries: () => void;
  onClose: () => void;
}) => {
  const { id: listViewCatalogTypeId, entry_id: entryIdOrExternalID } =
    useParams() as {
      id: string;
      entry_id: string;
    };

  const location = useLocation();
  const isEditing = location.pathname.split("/").includes("edit");

  const { data, isLoading, error } = useAPI("catalogShowEntry", {
    id: entryIdOrExternalID,
    includeReferences: true,
    includeDerivedAttributes: true,
  });

  const { resourcesLoading, userLinkResources } = useCatalogResources();

  const {
    data: catalogTypesData,
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {});

  const {
    data: relationsData,
    isLoading: relationsLoading,
    error: relationsError,
  } = useAPI(
    "catalogListRelations",
    { id: entryIdOrExternalID },
    {
      fallbackData: {
        catalog_relations: [],
      },
    },
  );

  if (error?.status === 404) {
    return <CatalogNotFoundDrawer notFoundNoun="entry" onClose={onClose} />;
  }

  // Deliberately ignore relationsError here, as we want to show the entry even if
  // the relations (which are just non-created backlinks) fail to load.
  if (error || catalogTypesError) {
    return (
      <Drawer width="medium" onClose={onClose} isInBackground={isEditing}>
        <GenericErrorMessage error={error || catalogTypesError} />
      </Drawer>
    );
  }

  const loading =
    isLoading ||
    !data ||
    catalogTypesLoading ||
    resourcesLoading ||
    !catalogTypesData ||
    // If we're loading relations, we should wait until they're here. But if we get an error,
    // shrug and show the entry anyway.
    (relationsLoading && !relationsError);

  return (
    <>
      <Drawer width="medium" onClose={onClose} isInBackground={isEditing}>
        {loading ? (
          <CatalogEntrySkeletonDrawerContents />
        ) : (
          <CatalogEntryViewDrawerContents
            onCloseDrawer={onClose}
            catalogType={data?.catalog_type}
            entry={data?.catalog_entry}
            catalogRelations={relationsData.catalog_relations}
            userLinkResources={userLinkResources}
            refetchEntries={refetchEntries}
            listViewCatalogTypeId={listViewCatalogTypeId}
          />
        )}
      </Drawer>
    </>
  );
};

const CatalogEntryViewDrawerContents = ({
  catalogType,
  entry,
  catalogRelations: allCatalogRelations,
  onCloseDrawer,
  userLinkResources,
  refetchEntries,
  listViewCatalogTypeId,
}: {
  catalogType: CatalogType;
  entry: CatalogEntry;
  catalogRelations: CatalogRelation[];
  onCloseDrawer: () => void;
  userLinkResources: string[];
  refetchEntries: () => void;
  listViewCatalogTypeId: string;
}) => {
  const { hasScope } = useIdentity();
  const showToast = useToast();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  let catalogRelations = allCatalogRelations;
  const isUserCatalogType = catalogType?.name === "User";

  if (isUserCatalogType) {
    catalogRelations = catalogRelations.filter((relation) => {
      if (!relation.catalog_type.registry_type) {
        // If it's not a registry type, it's always allowed
        return true;
      }
      // Otherwise, check if it's in the userLinkResources
      return !userLinkResources.includes(relation.catalog_type.registry_type);
    });
  }

  const handleDelete = useAPIMutation(
    "catalogListEntries",
    { catalogTypeId: catalogType.id },
    async (apiClient, _) => {
      await apiClient.catalogDestroyEntry({ id: entry.id });
    },
    {
      onSuccess: async () => {
        await refetchEntries();
        showToast({
          theme: ToastTheme.Success,
          title: `Deleted ${entry.name}`,
          toastSide: ToastSideEnum.Bottom,
        });
        onCloseDrawer();
      },
    },
  );

  const deletionCallout = (
    <Callout theme={CalloutTheme.Warning}>
      This action will result in permanent loss of data.
    </Callout>
  );

  const isAboveADifferentType =
    listViewCatalogTypeId !== catalogType.id &&
    listViewCatalogTypeId !== catalogType.type_name;

  const deletionConfirmationContent = () => {
    if (catalogRelations.length === 0) {
      return deletionCallout;
    }
    return (
      <>
        {deletionCallout}

        <div className="!my-4">
          There are catalog entries that are linked to this one. Deleting this
          entry will remove these links:
        </div>
        {catalogRelations.map((catalogRelation) => {
          return catalogRelation.attributes.map((attribute) => {
            return (
              <div className="!my-2" key={catalogType.name}>
                <CatalogEntryReferences
                  catalogType={catalogRelation.catalog_type}
                  entries={attribute.entries}
                />
              </div>
            );
          });
        })}
      </>
    );
  };

  return (
    <>
      <DrawerContents>
        <DrawerTitle
          title={entry.name}
          onClose={onCloseDrawer}
          color={catalogType.color as unknown as ColorPaletteEnum}
          icon={catalogType.icon}
          compact
          secondaryAccessory={
            <div className="space-x-2 flex-center-y">
              {entry.native_resource_path && (
                <Button
                  analyticsTrackingId={null}
                  icon={IconEnum.ExternalLink}
                  theme={ButtonTheme.Secondary}
                  size={ButtonSize.Small}
                  href={entry.native_resource_path}
                >
                  Go to {catalogType.name}
                </Button>
              )}
              {isAboveADifferentType && (
                <Button
                  analyticsTrackingId={null}
                  icon={IconEnum.ExternalLink}
                  theme={ButtonTheme.Secondary}
                  size={ButtonSize.Small}
                  href={`/catalog/${slugForCatalogType(catalogType)}`}
                >
                  All {pluralize(catalogType.name)}
                </Button>
              )}
              {catalogType.is_editable && (
                <GatedButton
                  title="Delete"
                  icon={IconEnum.Delete}
                  theme={ButtonTheme.Secondary}
                  size={ButtonSize.Small}
                  analyticsTrackingId={null}
                  onClick={() => setShowConfirmDeleteModal(true)}
                  loading={handleDelete.isMutating}
                  disabled={
                    !hasScope(ScopeNameEnum.CatalogEntriesDestroy) ||
                    catalogType.source_repo_url !== undefined
                  }
                />
              )}
              {!isUserCatalogType && (
                <GatedButton
                  title="Edit"
                  icon={IconEnum.Edit}
                  theme={ButtonTheme.Secondary}
                  size={ButtonSize.Small}
                  analyticsTrackingId={null}
                  href="edit"
                  disabled={
                    !hasScope(ScopeNameEnum.CatalogEntriesEdit) ||
                    catalogType.source_repo_url !== undefined
                  }
                  disabledTooltipContent={
                    catalogType.source_repo_url
                      ? "Action unavailable for externally managed types."
                      : "You do not have permission to edit catalog entries."
                  }
                />
              )}
            </div>
          }
        />
        <DrawerBody>
          <div className="space-y-6">
            {entry.archived_at && (
              <EntryArchivedBanner catalogType={catalogType} />
            )}
            {Object.keys(entry.attribute_values).length === 0 ? (
              <EmptyState
                icon={IconEnum.Book}
                content="This catalog entry has no attributes set"
              />
            ) : (
              <CatalogEntryAttributes
                catalogType={catalogType}
                excludeUserRelations={isUserCatalogType}
                catalogEntry={entry}
                catalogRelations={catalogRelations}
                basePath={`/catalog/${listViewCatalogTypeId}`}
              />
            )}
            {isUserCatalogType && (
              <CatalogUserEntryConnectedAccountsTable entry={entry} />
            )}
            <CatalogEntryIncidents
              catalogTypeID={catalogType.id}
              catalogEntryID={entry.id}
            />
          </div>
          {showConfirmDeleteModal && (
            <DeletionConfirmationModal
              resourceTitle={entry.name}
              onDelete={() => {
                handleDelete.trigger({});
              }}
              isDeleting={false}
              isOpen={true}
              onClose={() => setShowConfirmDeleteModal(false)}
              title="Are you sure?"
              deleteConfirmationContent={deletionConfirmationContent()}
              analyticsTrackingId="delete-catalog-entry"
            />
          )}
        </DrawerBody>
      </DrawerContents>
    </>
  );
};

const CatalogNotFoundDrawer = ({
  notFoundNoun,
  onClose,
}: {
  notFoundNoun: string;
  onClose: () => void;
}) => {
  return (
    <Drawer width="medium" onClose={onClose}>
      <DrawerContents>
        <DrawerTitle
          icon={IconEnum.Warning}
          title="Not Found"
          onClose={onClose}
          compact
        />
        <DrawerBody>
          <div>
            <Callout theme={CalloutTheme.Info}>
              We weren&apos;t able to find that {notFoundNoun}.
            </Callout>
          </div>
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};

const EntryArchivedBanner = ({ catalogType }: { catalogType: CatalogType }) => {
  const integration = catalogType.required_integration;
  const integrationConfig = integration
    ? IntegrationConfigFor(
        integration as unknown as IntegrationSettingsProviderEnum,
      )
    : null;

  return (
    <Callout theme={CalloutTheme.Warning}>
      <p className="!m-0">This catalog entry has been archived.</p>
      {integrationConfig && (
        <p>
          It&apos;s likely this resource has been deleted in{" "}
          {integrationConfig.label} and is no longer available for use.
        </p>
      )}
    </Callout>
  );
};

export const CatalogEntrySkeletonDrawerContents = () => {
  const widths = ["w-32", "w-64", "w-40"];
  return (
    <DrawerContents>
      <div className="p-1 w-full">
        <LoadingBar className="h-16 w-full" />
      </div>

      <DrawerBody className="flex flex-col gap-5">
        {widths.map((width) => (
          <div key={width} className="flex flex-col gap-1">
            <LoadingBar className="h-4 w-32" />
            <LoadingBar className={tcx("h-8", width)} />
          </div>
        ))}
      </DrawerBody>
    </DrawerContents>
  );
};
