/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Alert,
    AlertFromJSON,
    AlertFromJSONTyped,
    AlertToJSON,
} from './Alert';

/**
 * 
 * @export
 * @interface IncidentAlert
 */
export interface IncidentAlert {
    /**
     * 
     * @type {Alert}
     * @memberof IncidentAlert
     */
    alert: Alert;
    /**
     * The ID of the alert route that created this incident alert
     * @type {string}
     * @memberof IncidentAlert
     */
    alert_route_id: string;
    /**
     * The ID of this alert
     * @type {string}
     * @memberof IncidentAlert
     */
    id: string;
    /**
     * The ID of the incident that this alert is potentially related to
     * @type {string}
     * @memberof IncidentAlert
     */
    incident_id: string;
    /**
     * The state of this incident alert
     * @type {string}
     * @memberof IncidentAlert
     */
    state: IncidentAlertStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentAlertStateEnum {
    Pending = 'pending',
    Related = 'related',
    Unrelated = 'unrelated'
}

export function IncidentAlertFromJSON(json: any): IncidentAlert {
    return IncidentAlertFromJSONTyped(json, false);
}

export function IncidentAlertFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentAlert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert': AlertFromJSON(json['alert']),
        'alert_route_id': json['alert_route_id'],
        'id': json['id'],
        'incident_id': json['incident_id'],
        'state': json['state'],
    };
}

export function IncidentAlertToJSON(value?: IncidentAlert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert': AlertToJSON(value.alert),
        'alert_route_id': value.alert_route_id,
        'id': value.id,
        'incident_id': value.incident_id,
        'state': value.state,
    };
}

