import { ReferenceColorEnum } from "@incident-io/api";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { ReferenceSource } from "../referenceSource";
import { TruncatingReferenceLabel } from "./TruncatingReferenceLabel";

export type EngineReferenceBadgeProps = {
  label: string;
  referenceSource?: ReferenceSource;
  className?: string;
  editable?: boolean;
  // Overrides can be used to force a certain icon or color palette, for
  // example when rendering query expression root references that are from
  // the catalog.
  iconOverride?: IconEnum;
  colorOverride?: ColorPaletteEnum | ReferenceColorEnum;
  mini?: boolean;
};

export const EngineReferenceBadge = ({
  label,
  referenceSource,
  className,
  editable = false,
  iconOverride,
  colorOverride,
  mini = false,
}: EngineReferenceBadgeProps): React.ReactElement => {
  const theme = ThemeForReferenceSource[referenceSource || "reference"];

  if (iconOverride) {
    theme.icon = iconOverride;
  }

  if (colorOverride) {
    const colorPalette = getColorPalette(colorOverride as ColorPaletteEnum);
    theme.iconClassName = colorPalette.icon;
    theme.separator = colorPalette.paleText;
    theme.editIcon = "group-hover:text-content-primary";
  }

  return (
    <Tooltip content={<div className="text-left text-xs">{label}</div>}>
      <Badge
        className={tcx(
          "select-text w-fit max-w-full cursor-default",
          "flex items-center justify-between gap-2 group",
          { "cursor-pointer": editable },
          theme.background,
          theme.content,
          !editable ? "" : "flex",
          className,
        )}
        theme={BadgeTheme.Unstyled}
        size={mini ? BadgeSize.Small : BadgeSize.Medium}
      >
        <div
          className={tcx(
            "flex flex-row min-w-0 overflow-hidden text-sm font-medium items-center gap-1",
          )}
        >
          {referenceSource && (
            <Icon
              id={theme.icon}
              size={IconSize.Small}
              className={theme.iconClassName}
            />
          )}
          <TruncatingReferenceLabel
            label={label}
            separatorClassName={theme.separator}
          />
        </div>
        {editable && (
          <Icon
            id={IconEnum.Edit}
            size={IconSize.Small}
            className={tcx("transition", theme.editIcon)}
          />
        )}
      </Badge>
    </Tooltip>
  );
};

export const ThemeForReferenceSource: {
  [key in ReferenceSource]: {
    icon: IconEnum;
    background: string;
    content: string;
    separator: string;
    editIcon: string;
    iconClassName?: string;
  };
} = {
  expression: {
    icon: IconEnum.Expression,
    background: "bg-amber-surface",
    content: "text-amber-content",
    separator: "text-amber-400",
    editIcon: "group-hover:text-amber-900",
  },
  loop: {
    icon: IconEnum.Refresh1,
    background: "bg-purple-surface",
    content: "text-purple-content",
    separator: "text-purple-400",
    editIcon: "group-hover:text-purple-900",
  },
  reference: {
    icon: IconEnum.Bolt,
    background: "bg-surface-secondary",
    content: "text-content-primary",
    separator: "text-content-tertiary",
    editIcon: "group-hover:text-content-secondary",
  },
};
