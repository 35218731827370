/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimelineItemCommentsCreateRequestBody
 */
export interface TimelineItemCommentsCreateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof TimelineItemCommentsCreateRequestBody
     */
    incident_id: string;
    /**
     * Text of the timeline item comment
     * @type {string}
     * @memberof TimelineItemCommentsCreateRequestBody
     */
    text: string;
    /**
     * ID of the timeline item, that this comment belongs to
     * @type {string}
     * @memberof TimelineItemCommentsCreateRequestBody
     */
    timeline_item_id: string;
}

export function TimelineItemCommentsCreateRequestBodyFromJSON(json: any): TimelineItemCommentsCreateRequestBody {
    return TimelineItemCommentsCreateRequestBodyFromJSONTyped(json, false);
}

export function TimelineItemCommentsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineItemCommentsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_id': json['incident_id'],
        'text': json['text'],
        'timeline_item_id': json['timeline_item_id'],
    };
}

export function TimelineItemCommentsCreateRequestBodyToJSON(value?: TimelineItemCommentsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_id': value.incident_id,
        'text': value.text,
        'timeline_item_id': value.timeline_item_id,
    };
}

