import { Icon, IconEnum, IconSize, Link } from "@incident-ui";
import _ from "lodash";
import {
  ExternalGitLabMergeRequest,
  ExternalResource,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { AttachmentContexts } from "./IncidentAttachment";

export const GitLabMergeRequest = ({
  className,
  resource,
  mergeRequest,
  context,
}: {
  className?: string;
  resource: ExternalResource;
  mergeRequest: ExternalGitLabMergeRequest;
  context: AttachmentContexts;
}): React.ReactElement => {
  return (
    <div className={tcx("flex", className)}>
      <Icon
        id={IconEnum.Gitlab}
        size={IconSize.Medium}
        className="text-content-tertiary mr-6 shrink-0 font-semibold mt-1"
      />
      <div>
        <div className="font-medium mb-2">
          <Link
            href={resource.permalink}
            openInNewTab
            analyticsTrackingId={null}
          >
            #{mergeRequest.number}: {mergeRequest.title}
          </Link>
        </div>
        <div className="flex space-x-2 text-slate-700">
          {context === AttachmentContexts.RealTime ? (
            <span className="flex-center-y">
              <Icon
                id={IconEnum.Status}
                className="text-content-tertiary mr-1"
              />
              <span>
                {mergeRequest.merged
                  ? "Merged"
                  : _.startCase(mergeRequest.status)}
              </span>
            </span>
          ) : null}
          <span className="flex-center-y">
            <Icon id={IconEnum.User} className="text-content-tertiary mr-1" />
            <span>{mergeRequest.author}</span>
          </span>
        </div>
      </div>
    </div>
  );
};
