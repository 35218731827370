import {
  AddFilterButton,
  ToggleIncludePrivateIncidentsFilter,
} from "@incident-shared/filters";
import { AppliedFiltersBanner } from "@incident-shared/filters/AppliedFilters";
import { FormLabelV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import {
  ButtonModal,
  IconEnum,
  ModalContent,
  ModalFooter,
  RadioButtonGroup,
  Txt,
} from "@incident-ui";
import _ from "lodash";
import React, { useState } from "react";
import { CreateOrUpdateSavedViewButtonModal } from "src/components/saved-views/CreateOrUpdateSavedViewButtonModal";
import { Incident, IncidentVisibilityEnum } from "src/contexts/ClientContext";
import { downloadObjsAsCsv } from "src/utils/csv";
import { presentFollowUp } from "src/utils/presenters";

import { SavedViewHeading } from "../../saved-views/SavedViewHeading";

export const FollowUpsListHeader = ({
  incidents,
  totalNumberOfIncidents,
}: {
  incidents: Incident[];
  totalNumberOfIncidents: number | null;
}): React.ReactElement => (
  <div className="mb-8">
    <div className="w-full text-content-primary font-semibold bg-white z-30 mb-4">
      <div className="flex flex-wrap items-center gap-4 justify-between z-30">
        <SavedViewHeading pageName="Follow-ups" headingSize="2xl" />
        <FollowUpHeaderActions incidents={incidents} />
      </div>
    </div>
    <AppliedFiltersBanner
      totalNumberOfItems={totalNumberOfIncidents}
      itemsLabel={"incident"}
      style="partOfPage"
    />
  </div>
);

const FollowUpHeaderActions = ({
  incidents,
}: {
  incidents: Incident[];
}): React.ReactElement => (
  <div className="flex flex-nowrap justify-end items-end gap-4 flex-center-y">
    <ToggleIncludePrivateIncidentsFilter className="pt-2 pb-2 flex-center-y" />
    <div className="flex gap-2">
      <ButtonModal
        buttonProps={{
          analyticsTrackingId: "export-follow-ups-modal-open",
          icon: IconEnum.Export,
          children: <span className="mobile-hidden">Export CSV </span>,
        }}
        modalProps={{
          analyticsTrackingId: "export-follow-ups",
          title: "Export to CSV",
        }}
        renderModalContents={({ onClose }) => (
          <ExportModalContent onClose={onClose} incidents={incidents} />
        )}
      />
      <AddFilterButton />
      <CreateOrUpdateSavedViewButtonModal />
    </div>
  </div>
);

const ExportModalContent = ({
  onClose,
  incidents,
}: {
  onClose: () => void;
  incidents: Incident[];
}): React.ReactElement => {
  const [separator, setSeparator] = useState<string>(",");

  const data = formatFollowUpData(
    incidents.filter((x) => x.visibility === IncidentVisibilityEnum.Public),
  );

  const onDownload = () => {
    if (data != null) {
      downloadObjsAsCsv("follow_ups.csv", data, separator);
      onClose();
    }
  };

  return (
    <>
      <ModalContent className="flex flex-col p-4 gap-2">
        <Txt grey>
          This will download a CSV containing all the follow-up actions that
          you&apos;ve created (except those created against test or private
          incidents).
        </Txt>
        <div className="flex flex-col gap-1">
          <FormLabelV2 htmlFor={"separator"}>Separator</FormLabelV2>
          <RadioButtonGroup
            options={[
              {
                label: "Comma",
                value: ",",
              },
              {
                label: "Pipe",
                value: "|",
              },
              {
                label: "Semicolon",
                value: ";",
              },
            ]}
            name={"separator"}
            srLabel={"Separator"}
            onChange={(e) => setSeparator(e)}
            value={separator}
            boxed
          />
        </div>
      </ModalContent>
      <ModalFooter
        onClose={onClose}
        confirmButtonText="Download"
        confirmButtonType="button"
        onConfirm={() => onDownload()}
      />
    </>
  );
};

const formatFollowUpData = (incidents: Incident[]) => {
  return _.compact(
    incidents.flatMap((i) => i.follow_ups?.map((f) => presentFollowUp(f, i))),
  );
};
