import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import {
  LinearBulkExportableFields,
  LinearFormData,
} from "@incident-shared/issue-trackers";
import { ModalFooter } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { isEmpty } from "lodash";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import {
  FollowUp,
  IssueTrackersLinearTypeaheadOptionsFieldEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  BulkExportBlurb,
  BulkExportCallouts,
  BulkExportTitleAndDescription,
  getBulkExportTitle,
  getBulkToastTitle,
  NoFollowUpsToExportModal,
} from "./helpers";

export const BulkExportToLinearModal = ({
  followUpsToExport,
  numFollowUpsToExportFromPrivate,
  numFollowUpsAlreadyExported,
  onClose,
  onCancel,
  updateCallback,
}: {
  followUpsToExport: FollowUp[];
  numFollowUpsToExportFromPrivate: number;
  numFollowUpsAlreadyExported: number;
  onClose: () => void;
  onCancel: () => void;
  updateCallback: () => void;
}): ReactElement | null => {
  const formMethods = useForm<LinearFormData>({
    mode: "onSubmit",
  });
  const { watch, clearErrors } = formMethods;

  const linearTeamId = watch("team_id");
  const showToast = useToast();
  const providerName = "Linear";

  const {
    data: { typeahead_options: teams },
    isLoading: teamsLoading,
    error: teamsError,
  } = useAPI(
    "issueTrackersLinearTypeaheadOptions",
    {
      field: IssueTrackersLinearTypeaheadOptionsFieldEnum.Team,
    },
    {
      fallbackData: { typeahead_options: [] },
      onSuccess: () => clearErrors("assignee_id"),
    },
  );

  const {
    data: { typeahead_options: users },
    isLoading: usersLoading,
    error: usersError,
  } = useAPI(
    isEmpty(linearTeamId) ? null : "issueTrackersLinearTypeaheadOptions",
    {
      field: IssueTrackersLinearTypeaheadOptionsFieldEnum.User,
      userTeamId: linearTeamId,
    },
    {
      fallbackData: { typeahead_options: [] },
    },
  );

  const {
    data: { typeahead_options: labels },
    isLoading: labelsLoading,
    error: labelsError,
  } = useAPI(
    isEmpty(linearTeamId) ? null : "issueTrackersLinearTypeaheadOptions",
    {
      field: IssueTrackersLinearTypeaheadOptionsFieldEnum.Label,
      userTeamId: linearTeamId,
    },
    {
      fallbackData: { typeahead_options: [] },
    },
  );

  const {
    data: { typeahead_options: projects },
    isLoading: projectsLoading,
    error: projectsError,
  } = useAPI(
    isEmpty(linearTeamId) ? null : "issueTrackersLinearTypeaheadOptions",
    {
      field: IssueTrackersLinearTypeaheadOptionsFieldEnum.Project,
      userTeamId: linearTeamId,
    },
    {
      fallbackData: { typeahead_options: [] },
    },
  );

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    {},
    async (apiClient, data: LinearFormData) => {
      await Promise.all(
        followUpsToExport.map((followUp) =>
          apiClient.issueTrackersLinearCreateIssue({
            linearCreateIssueRequestBody: {
              team_id: data.team_id,
              title: followUp.title,
              description: followUp.description,
              assignee_id:
                data.assignee_id === "" ? undefined : data.assignee_id,
              follow_up_id: followUp.id,
              labels: data.labels || [],
            },
          }),
        ),
      );
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: getBulkToastTitle(followUpsToExport.length, providerName),
        });
        updateCallback();
        onClose();
      },
    },
  );

  const title = getBulkExportTitle(followUpsToExport.length);

  const fetchDataError =
    teamsError || usersError || projectsError || labelsError
      ? "There was a problem loading data from Linear."
      : "";

  if (followUpsToExport.length === 0) {
    return (
      <NoFollowUpsToExportModal
        numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
        onClose={onCancel}
      />
    );
  } else {
    return (
      <FormModalV2
        formMethods={formMethods}
        onSubmit={onSubmit}
        title={title}
        analyticsTrackingId="bulk-add-to-linear"
        onClose={onCancel}
        loading={teamsLoading}
        genericError={genericError || fetchDataError}
        footer={
          <ModalFooter
            confirmButtonText={title}
            saving={saving}
            onClose={onCancel}
            confirmButtonType="submit"
          />
        }
      >
        <BulkExportCallouts
          numFollowUpsToExportFromPrivate={numFollowUpsToExportFromPrivate}
          numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
          accountName="Linear team"
        />
        <BulkExportBlurb
          providerIssues="Linear tickets"
          providerName={providerName}
        />
        <BulkExportTitleAndDescription providerName={providerName} />
        <LinearBulkExportableFields
          projects={projects ?? []}
          projectsLoading={projectsLoading}
          teams={teams ?? []}
          teamsLoading={teamsLoading}
          users={users ?? []}
          usersLoading={usersLoading}
          labels={labels ?? []}
          labelsLoading={labelsLoading}
          selectedTeamId={linearTeamId}
        />
      </FormModalV2>
    );
  }
};
