import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  IconEnum,
  IconSize,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useEffect, useState } from "react";
import {
  Incident,
  TimelineItemComment,
  TimelineItemObject,
} from "src/contexts/ClientContext";
import { getAnchorId, timelineNoteAnchorPrefix } from "src/utils/anchor";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { CreateEditTimelineNoteSection } from "./CreateEditTimelineNoteSection";
import { TimelineItemCommentList } from "./TimelineItemComment";
import styles from "./TimelineItems.module.scss";

interface FormData {
  id: string;
}

export function WebTimelineNoteUI({
  incident,
  item,
  comments,
}: {
  incident: Incident;
  item: TimelineItemObject;
  comments: TimelineItemComment[];
}): React.ReactElement {
  const showToast = useToast();
  const note = item.timeline_note;
  if (!note) {
    throw new Error(
      "malformed timeline item: timeline_note was missing timeline_note field",
    );
  }

  const [editingNote, setEditingNote] = React.useState(false);

  const { trigger: onDelete, isMutating: savingDelete } = useAPIMutation(
    "timelineItemsList",
    { incidentId: incident.id },
    async (apiClient, { id }: FormData) => {
      await apiClient.timelineItemsDestroy({
        id,
      });
    },
    {
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: `We weren't able to delete this note`,
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);

  comments = comments || [];
  const [commentsExpanded, setCommentsExpanded] = useState(
    comments.length > 0
      ? CommentsExpanded.AutoExpanded
      : CommentsExpanded.AutoCollapsed,
  );

  useEffect(() => {
    if (
      commentsExpanded === CommentsExpanded.ManuallyCollapsed ||
      commentsExpanded === CommentsExpanded.ManuallyExpanded
    ) {
      return;
    }

    if (comments && comments.length > 0) {
      setCommentsExpanded(CommentsExpanded.AutoExpanded);
    } else {
      setCommentsExpanded(CommentsExpanded.AutoCollapsed);
    }
  }, [comments, commentsExpanded]);

  if (editingNote) {
    return (
      <CreateEditTimelineNoteSection
        incident={incident}
        occurredAt={item.occured_at}
        onClose={() => {
          setEditingNote(false);
        }}
        initialData={item}
        mode={Mode.Edit}
      />
    );
  }

  const anchorId = `${timelineNoteAnchorPrefix}/${item.id}`;

  return (
    <>
      {showDeleteConfirmationDialog && (
        <ConfirmationDialog
          title={"Delete note"}
          analyticsTrackingId="delete-timeline-note"
          isOpen={showDeleteConfirmationDialog}
          onCancel={() => setShowDeleteConfirmationDialog(false)}
          onConfirm={() => onDelete({ id: item.id })}
        >
          <div className="p-2">
            <p className="pb-1">Are you sure you want to delete this note?</p>
            {(item.comments.length && (
              <p>{item.comments.length + 1} comments will be deleted</p>
            )) ||
              null}
          </div>
        </ConfirmationDialog>
      )}
      <li className={tcx(styles.timelineNote)}>
        <div
          className={tcx(
            "flex",
            styles.noteBox,
            getAnchorId() === anchorId ? styles.anchored : "",
          )}
          id={anchorId}
        >
          <div className={styles.itemDescription}>
            <TemplatedTextDisplay
              value={note.text}
              style={TemplatedTextDisplayStyle.Compact}
              className="!mb-1"
            />
          </div>
          <div className={styles.buttons}>
            <Button
              theme={ButtonTheme.Naked}
              icon={IconEnum.Edit}
              iconProps={{ size: IconSize.Medium }}
              title="Edit"
              analyticsTrackingId="timeline-note-edit"
              onClick={() => setEditingNote(true)}
              className={styles.hiddenButton}
            />
            <Button
              theme={ButtonTheme.Naked}
              icon={IconEnum.Delete}
              iconProps={{ size: IconSize.Medium }}
              title="Delete"
              analyticsTrackingId="timeline-note-delete"
              onClick={() => setShowDeleteConfirmationDialog(true)}
              loading={savingDelete}
              className={styles.hiddenButton}
            />
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId="timeline-item-comment"
              title="Comment on item"
              icon={IconEnum.Message}
              iconProps={{
                size: IconSize.Medium,
                className: "mr-0",
              }}
              onClick={() => {
                let newCommentsExpanded;
                switch (commentsExpanded) {
                  case CommentsExpanded.AutoExpanded:
                    newCommentsExpanded = CommentsExpanded.ManuallyCollapsed;
                    break;
                  case CommentsExpanded.ManuallyExpanded:
                    newCommentsExpanded = CommentsExpanded.ManuallyCollapsed;
                    break;
                  case CommentsExpanded.AutoCollapsed:
                    newCommentsExpanded = CommentsExpanded.ManuallyExpanded;
                    break;
                  case CommentsExpanded.ManuallyCollapsed:
                    newCommentsExpanded = CommentsExpanded.ManuallyExpanded;
                    break;
                }

                setCommentsExpanded(newCommentsExpanded);
              }}
              className={
                commentsAreExpanded(commentsExpanded)
                  ? "text-content-tertiary bg-amber-300 rounded-[6px]"
                  : "text-content-tertiary"
              }
            >
              {comments.length > 0 && <p className="mr-1">{comments.length}</p>}
            </Button>
          </div>
        </div>
        {commentsAreExpanded(commentsExpanded) && (
          <TimelineItemCommentList
            incident={incident}
            itemId={item.id}
            comments={comments}
            shouldFocus={commentsExpanded === CommentsExpanded.ManuallyExpanded}
          />
        )}
      </li>
    </>
  );
}

enum CommentsExpanded {
  AutoCollapsed = "auto_collapsed",
  ManuallyCollapsed = "manually_collapsed",
  AutoExpanded = "auto_expanded",
  ManuallyExpanded = "manually_expanded",
}

const commentsAreExpanded = (commentsExpanded: CommentsExpanded): boolean => {
  return (
    commentsExpanded === CommentsExpanded.AutoExpanded ||
    commentsExpanded === CommentsExpanded.ManuallyExpanded
  );
};
