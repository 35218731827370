import {
  AIConfigEnabledFeaturesEnum,
  InsightsCreateDashboardRequestBody,
  InsightsCreateDashboardRequestBodyVisibilityEnum,
  InsightsCustomisableDashboard,
  InsightsCustomisableDashboardVisibilityEnum as ListVisibilityEnum,
  SavedViewsListContextEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Avatar,
  Button,
  ButtonTheme,
  DeprecatedTable,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  Link,
  LocalDateTime,
} from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { AssistantBannerV2 } from "src/components/insights/assistant/AssistantBannerV2";
import { LevelUpIncidentResponseBanner } from "src/components/settings/LevelUpIncidentResponseBanner";
import { PlanBadge } from "src/components/settings/PlanBadge";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAIFeatureForOrg } from "src/hooks/useAI";
import { useProductAccess } from "src/hooks/useProductAccess";
import { formatDurationInSeconds } from "src/utils/datetime";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { InsightsHeader } from "../common/InsightsHeader";
import { InsightsContextProvider } from "../context/InsightsContextProvider";
import { StateConfig } from "../context/types";
import InsightsGatedVisual from "../insights-empty-state.png";
import { InsightsListRow, useDashboardGroups } from "./dashboards";

export type OverviewStateConfig = StateConfig;

const ProductAvailableDashboard = ({
  requiredProduct,
  children,
}: {
  requiredProduct?: Product;
  children: React.ReactNode;
}) => {
  const { hasProduct } = useProductAccess();

  if (!requiredProduct) {
    return <>{children}</>;
  }

  if (hasProduct(requiredProduct)) {
    return <>{children}</>;
  }

  return null;
};

export const InsightsListPage = () => {
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;
  const canUseAI = useAIFeatureForOrg();
  const DashboardGroups = useDashboardGroups();
  const { hasResponse } = useProductAccess();

  return (
    <InsightsContextProvider<StateConfig>
      context={SavedViewsListContextEnum.InsightsV2}
      stateConfig={{}}
    >
      <PageWrapper
        width={PageWidth.Medium}
        headerNode={
          insightsFeatureGate ? (
            <InsightsHeader
              title={"Insights"}
              disableFilters={true}
              hideFilters={true}
              icon={IconEnum.Chart}
            />
          ) : null
        }
        title="Insights"
        icon={IconEnum.Chart}
      >
        {!insightsFeatureGate ? (
          <InsightsLandingPage />
        ) : (
          <div className="flex flex-col h-full gap-4">
            {canUseAI(AIConfigEnabledFeaturesEnum.Assistant) && (
              <AssistantBannerV2 />
            )}
            <div className="space-y-4 pb-4">
              {DashboardGroups.map((group) => (
                <ProductAvailableDashboard
                  key={group.title}
                  requiredProduct={group.requiredProduct}
                >
                  <DashboardSection
                    key={group.title}
                    title={group.title}
                    icon={group.icon}
                    iconColour={group.homepageIconColour}
                    dashboards={group.dashboards}
                  />
                </ProductAvailableDashboard>
              ))}
              {!hasResponse ? <LevelUpIncidentResponseBanner /> : null}
            </div>
          </div>
        )}
      </PageWrapper>
    </InsightsContextProvider>
  );
};

const InsightsLandingPage = (): React.ReactElement => (
  <div className="flex flex-grow flex-col h-full justify-center items-center">
    <img className="w-[646px] mb-10" src={InsightsGatedVisual} />
    <div className="w-96 flex flex-col gap-2 justify-center items-center mb-6">
      <PlanBadge planName="Pro" />
      <p className="font-semibold text-base">Dig deeper into your incidents</p>
      <p className="font-normal text-sm text-center">
        Identify incident trends and recurring pain points with Insights. See
        what types of incidents are occurring across your organization and
        improve how you respond to them.
      </p>
    </div>
    <div className="flex gap-2">
      <Button
        analyticsTrackingId="insights-upgrade"
        theme={ButtonTheme.Primary}
        href={"/settings/billing"}
      >
        Upgrade
      </Button>
      <Button
        analyticsTrackingId="insights-learn-more"
        theme={ButtonTheme.Secondary}
        href="https://incident.io/changelog/insights-updates"
      >
        Learn more
      </Button>
    </div>
  </div>
);

type DashboardSectionProps = {
  title: string;
  icon: IconEnum;
  iconColour: string;
  dashboards: { name: string; to: string; description: string }[];
};

const DashboardSection = ({
  title,
  icon,
  iconColour,
  dashboards,
}: DashboardSectionProps) => {
  return (
    <div className="bg-surface-secondary p-4 rounded-xl">
      <div className="flex items-center">
        <Icon
          id={icon}
          className={tcx(
            "!p-1 w-7 h-7 rounded border-radius text-white",
            iconColour,
          )}
        />

        <p className="ml-2 text-base font-semibold">{title}</p>
      </div>
      <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
        {dashboards.map(({ name, to, description }) => (
          <Link
            noUnderline
            noHoverColor
            analyticsTrackingId={`insights-core-dashboard-${name}`}
            to={to}
            key={name}
            className="w-full px-4 py-3 rounded-2 bg-white shadow-sm border border-stroke hover:shadow hover:border-stroke transition-colors"
          >
            <h3 className="text-sm font-semibold mb-0">{name}</h3>
            <p className="text-sm text-slate-600 mt-1">{description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export const CustomDashboardUpsell = (): React.ReactElement => (
  <div className="flex shadow-sm rounded-2 items-center border border-stroke p-4 justify-between">
    <p className="text-sm font-normal">
      Tailor insights to your business with our fully customisable dashboards.
    </p>
    <div className="flex gap-4">
      <Button
        analyticsTrackingId="upsell-notice-learn-more-insights-custom-dashboards-upsell"
        theme={ButtonTheme.Naked}
        href="https://help.incident.io/en/articles/9247059-custom-dashboards"
      >
        Learn more
      </Button>
      <Button
        analyticsTrackingId={"upsell-notice-insights-custom-dashboards-upsell"}
        href="/settings/billing"
      >
        Upgrade
      </Button>
    </div>
  </div>
);

export const CustomDashboardsHeading = ({
  showButton,
  upgradeRequired,
}: {
  showButton: boolean;
  upgradeRequired: boolean;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const formMethods = useForm<InsightsCreateDashboardRequestBody>({
    defaultValues: {
      name: "Untitled dashboard",
      visibility: InsightsCreateDashboardRequestBodyVisibilityEnum.Personal,
      url_params: "",
    },
  });

  const {
    trigger: onSubmit,
    isMutating: creating,
    genericError,
  } = useAPIMutation(
    "insightsListDashboards",
    undefined,
    async (apiClient, body: InsightsCreateDashboardRequestBody) => {
      const { dashboard } = await apiClient.insightsCreateDashboard({
        createDashboardRequestBody: { ...body },
      });

      if (dashboard) {
        navigate(`/insights/custom/${dashboard.id}`);
      }
    },
    { setError: formMethods.setError },
  );

  return (
    <FormV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      genericError={genericError}
    >
      <div className="flex items-center gap-2">
        <Heading
          size="medium"
          level={2}
          data-intercom-target="insights-core-dashboard-subtitle"
          className="shrink-0 !mb-0"
        >
          Custom dashboards
        </Heading>
        {showButton && !upgradeRequired && (
          <GatedButton
            analyticsTrackingId={null}
            icon={IconEnum.Add}
            type="submit"
            disabled={creating}
            loading={creating}
            requiredScope={ScopeNameEnum.InsightsCustomDashboardsCreate}
          >
            Create
          </GatedButton>
        )}
        {upgradeRequired && <PlanBadge planName="Enterprise" />}
      </div>
    </FormV2>
  );
};

export const NoCustomDashboards = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const formMethods = useForm<InsightsCreateDashboardRequestBody>({
    defaultValues: {
      name: "Untitled dashboard",
      visibility: InsightsCreateDashboardRequestBodyVisibilityEnum.Personal,
      url_params: "",
    },
  });

  const {
    trigger: onSubmit,
    isMutating: creating,
    genericError,
  } = useAPIMutation(
    "insightsListDashboards",
    undefined,
    async (apiClient, body: InsightsCreateDashboardRequestBody) => {
      const { dashboard } = await apiClient.insightsCreateDashboard({
        createDashboardRequestBody: { ...body },
      });

      if (dashboard) {
        navigate(`/insights/custom/${dashboard.id}`);
      }
    },
    { setError: formMethods.setError },
  );

  return (
    <FormV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      genericError={genericError}
    >
      <div className="flex justify-between items-center rounded-2 p-4 border-stroke border shadow-sm border-radius mb-4">
        <p className="text-sm font-normal">
          You don&apos;t have any custom dashboards
        </p>
        <Button
          analyticsTrackingId={null}
          type="submit"
          disabled={creating}
          loading={creating}
        >
          Create a custom dashboard
        </Button>
      </div>
    </FormV2>
  );
};

export const RecommendedInsightsCard = ({
  item,
}: {
  item: InsightsListRow;
}): React.ReactElement => {
  return (
    <div className="flex flex-col border shadow-sm rounded-2 border-radius border-stroke hover:border-slate-500 hover:shadow-md transition bg-white min-w-64 w-full">
      <Link
        openInNewTab={false}
        className={"no-underline p-4"}
        analyticsTrackingId={`insights-core-dashboard-${item.name}`}
        to={`/insights/${item.slug}`}
      >
        <div className="space-y-1.5 text-content-primary">
          {item.icon && (
            <Icon
              id={item.icon.icon}
              size={IconSize.XL}
              className={tcx(
                "mb-8 !p-1 rounded-[6px] border-radius",
                item.icon.iconColour,
                item.icon.bgColour,
              )}
            />
          )}
          <p className="text-sm font-medium">{item.name}</p>
          <div>
            <p className="text-sm font-normal">{item.description}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export const CustomDashboardList = ({
  items,
}: {
  items: InsightsCustomisableDashboard[];
}): React.ReactElement => {
  if (items.length === 0) {
    return <></>;
  }

  return (
    <DeprecatedTable className="mb-4" tableClassName="table-fixed">
      <colgroup>
        <col className="w-auto" />
        <col className="w-56" />
        <col className="w-56" />
      </colgroup>
      <tbody>
        {items.map((item) => {
          return <CustomDashboardListRow key={item.id} item={item} />;
        })}
      </tbody>
    </DeprecatedTable>
  );
};

export const CustomDashboardListRow = ({
  item,
}: {
  item: InsightsCustomisableDashboard;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const reportedAgo = formatDurationInSeconds(
    (new Date().getTime() - item.updated_at.getTime()) / 1000,
    1,
  );

  return (
    <tr
      className="hover:bg-surface-secondary cursor-pointer first-of-type:!border-t-0"
      onClick={(e) => {
        const path = `/insights/custom/${item.id}?${item.url_params}`;
        if (e.metaKey || e.ctrlKey) {
          const win = window.open(path, "_blank");
          win?.focus();
        } else {
          navigate(path);
        }
      }}
    >
      <td>
        <div className="flex text-sm font-medium gap-1">
          {item.name}
          {item.visibility === ListVisibilityEnum.Personal && (
            <Icon
              id={IconEnum.LockClosed}
              className="text-slate-400 flex-none"
            />
          )}
        </div>
      </td>
      <td>
        <span className="flex items-center">
          <Avatar
            size={IconSize.Medium}
            url={item.creator.user?.avatar_url}
            name={item.creator.user?.name}
            className="mr-1.5"
          />
          <div className="truncate">{item.creator.user?.name}</div>
        </span>
      </td>
      <td className="text-right">
        <LocalDateTime
          timestamp={item.updated_at}
          className="text-content-tertiary hover:!no-underline"
        >
          <div>Last updated {reportedAgo} ago</div>
        </LocalDateTime>
      </td>
    </tr>
  );
};
