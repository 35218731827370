import {
  IncidentsListRequest,
  IncidentsListSortByEnum,
} from "@incident-io/api";
import { useSyntheticCatalogFilters } from "@incident-shared/filters/useSyntheticCatalogFilters";
import {
  ExtendedFormFieldValue,
  filtersToListParams,
} from "src/components/@shared/filters";
import { useAPIInfinite } from "src/utils/swr";

const DEFAULT_INCIDENTS_PAGE_SIZE = 50;

export const useGetIncidentsWithSyntheticFilters = ({
  filters,
  fixedFilters = {
    pageSize: DEFAULT_INCIDENTS_PAGE_SIZE,
    sortBy: IncidentsListSortByEnum.NewestFirst,
  },
  eagerLoad,
}: {
  filters: ExtendedFormFieldValue[];
  fixedFilters?: Partial<IncidentsListRequest> & {
    pageSize: number;
  };
  eagerLoad?: boolean;
}) => {
  const { data, isLoading, error } = useSyntheticCatalogFilters(filters);

  const output = useGetIncidents({ filters: data, fixedFilters, eagerLoad });

  return {
    ...output,
    isLoading: isLoading || output.isLoading,
    error: error || output.error,
  };
};

export const useGetIncidents = ({
  filters,
  fixedFilters = {
    pageSize: DEFAULT_INCIDENTS_PAGE_SIZE,
    sortBy: IncidentsListSortByEnum.NewestFirst,
  },
  eagerLoad,
}: {
  filters: ExtendedFormFieldValue[];
  fixedFilters?: Partial<IncidentsListRequest> & {
    pageSize: number;
  };
  eagerLoad?: boolean;
}) => {
  const queryFilters: IncidentsListRequest = filtersToListParams(filters);

  const baseRequest = { ...queryFilters, ...fixedFilters };

  const {
    responses,
    refetch: refetchIncidents,
    isLoading,
    isFullyLoaded,
    isValidating,
    loadMore,
    error,
  } = useAPIInfinite("incidentsList", baseRequest, { eagerLoad });

  const incidents = responses.flatMap(({ incidents }) => incidents);
  const totalNumberOfIncidents = responses?.find(
    (resp) => resp.pagination_meta?.total_record_count,
  )?.pagination_meta?.total_record_count;

  return {
    incidents,
    totalNumberOfIncidents,
    refetchIncidents,
    loadMore,
    isLoading,
    allIncidentsLoaded: isFullyLoaded,
    someIncidentsLoaded: responses.length > 0,
    isValidating,
    error,
  };
};
