import { InputProps } from "@incident-ui/Input/Input";
import { SyntheticEvent } from "react";
import { useEffect, useRef, useState } from "react";
import { FieldValues, Path, useController } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { FormInputWrapperV2 } from "../FormInputWrapperV2";
import { InputElementProps, parseProps } from "../formsv2";

const insertAt = (str: string, index: number, val: string) => {
  return str.slice(0, index) + val + str.slice(index);
};
const replaceAt = (str: string, index: number, val: string) => {
  return str.slice(0, index) + val + str.slice(index + 1);
};

const handleUpdate = (
  e: React.ChangeEvent<HTMLInputElement>,
  val: string,
): [string, number] => {
  let caretLocation = e.target.selectionStart;
  if (caretLocation == null) {
    throw new Error("No caret location found");
  }
  const validTime = /^([0-1][0-9]|2[0-3]):([0-5][0-9])+$/;
  const charIndex = Math.max(caretLocation - 1, 0);
  // If we've deleted a character, replace it with 0
  // ...unless it's the ":" character, in which case we delete the prev number
  if (e.target.value.length < val.length) {
    let newVal;
    if (caretLocation === 2) {
      newVal = replaceAt(val, caretLocation - 1, "0");
      caretLocation -= 1;
    } else {
      newVal = insertAt(e.target.value, caretLocation, "0");
    }
    if (!validTime.test(newVal)) {
      return [val, charIndex];
    }
    return [newVal, caretLocation];
  }
  // If we've reached here, we need to overwrite whichver digit we're on
  // Skip the ":"
  if (caretLocation === 3) {
    caretLocation += 1;
  }

  const newVal = replaceAt(
    val,
    caretLocation - 1,
    e.target.value.charAt(charIndex),
  );

  if (!validTime.test(newVal)) {
    return [val, charIndex + 1];
  }
  return [newVal, caretLocation];
};

type TimeInputProps = {
  iconTooltipContent?: string;
  iconOnClick?: (
    e?: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement, Event>,
  ) => void;
};

export const TimeInputV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, InputProps> & TimeInputProps,
) => {
  const [val, setVal] = useState("00:00");
  const [caret, setCaret] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const { field } = useController({
    name,
    rules,
  });

  const onChange = (e) => {
    const [newVal, newCaret] = handleUpdate(e, val);
    setVal(newVal);
    field.onChange(newVal);
    setCaret(newCaret);
  };

  useEffect(() => {
    const input = inputRef?.current;
    if (input) {
      input.setSelectionRange(caret, caret);
    }
  }, [inputRef, caret]);

  // If we've set the form value from somewhere else, we need to sync it with what's being displayed
  const formValue = props.formMethods.watch(name);
  useEffect(() => {
    if (formValue !== val) {
      setVal(formValue);
    }
  }, [formValue, val]);

  return (
    <FormInputWrapperV2<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
      className={tcx(
        "bg-white flex-center-y text-sm text-content-primary border border-stroke focus-within:border-slate-900 rounded-[6px]",
        props.className,
      )}
    >
      <input
        {...field}
        {...inputProps}
        id={name}
        className={tcx(
          "px-2 py-3 text-left bg-transparent tabular-nums",
          props.inputClassName,
        )}
        ref={inputRef}
        value={val}
        onChange={onChange}
      />
    </FormInputWrapperV2>
  );
};
