import { UpgradeRequiredMessage } from "@incident-shared/billing";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxGroupV2 } from "@incident-shared/forms/v2/inputs/CheckboxGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ConfigureDrawerProps } from "@incident-shared/integrations";
import {
  ButtonTheme,
  CalloutTheme,
  ContentBox,
  GenericErrorMessage,
  Loader,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useForm, useFormContext } from "react-hook-form";
import { Identity } from "src/contexts/ClientContext";
import {
  AtlassianStatuspageConfig,
  AtlassianStatuspagePage,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

export const AtlassianStatuspageConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const { identity } = useIdentity();

  const {
    data: { pages },
    isLoading: loadingPages,
    error: errorPages,
  } = useAPI("integrationsAtlassianStatuspageListPages", undefined, {
    fallbackData: { pages: [] },
  });

  const {
    data: configData,
    isLoading: loadingConfig,
    error: errorConfig,
  } = useAPI("integrationsAtlassianStatuspageGetConfig", undefined);
  const config = configData?.config;

  if (loadingPages || loadingConfig || !identity) {
    return <IntegrationDrawerContentLoader />;
  }

  const error = errorPages || errorConfig;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <DrawerInner pages={pages} config={config} identity={identity} {...props} />
  );
};

const DrawerInner = ({
  pages,
  config,
  identity,
  ...props
}: {
  pages: AtlassianStatuspagePage[];
  config: AtlassianStatuspageConfig | undefined;
  identity: Identity;
} & ConfigureDrawerProps): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const allowedMultiplePages =
    !identity?.feature_gates.atlassian_statuspage_pages_count ||
    identity?.feature_gates.atlassian_statuspage_pages_count > 1;

  const formMethods = useForm<AtlassianStatuspageConfig>({
    defaultValues: config?.included_pages ? config : { included_pages: [] },
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsAtlassianStatuspageGetConfig",
    undefined,
    async (apiClient, data: AtlassianStatuspageConfig) =>
      await apiClient.integrationsAtlassianStatuspageUpdateConfig({
        updateConfigRequestBody: {
          config: data,
        },
      }),
    {
      setError: formMethods.setError,
    },
  );

  if (!identity) {
    return <Loader />;
  }

  return (
    <GenericConfigureDrawerContents {...props}>
      <ContentBox className="p-4">
        <FormV2
          formMethods={formMethods}
          onSubmit={onSubmit}
          saving={saving}
          genericError={genericError}
        >
          <PageSelectionForm
            pages={pages}
            allowedMultiplePages={allowedMultiplePages}
          />
          <GatedButton
            type="submit"
            disabled={!canEditSettings}
            analyticsTrackingId={null}
            theme={ButtonTheme.Primary}
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          >
            Save
          </GatedButton>
        </FormV2>
      </ContentBox>
    </GenericConfigureDrawerContents>
  );
};

export const PageSelectionForm = ({
  allowedMultiplePages,
  pages,
}: {
  allowedMultiplePages: boolean;
  pages: AtlassianStatuspagePage[];
}): React.ReactElement => {
  const { identity } = useIdentity();
  const shouldShowUpgradeMessage = !allowedMultiplePages && pages.length >= 1;
  const formMethods = useFormContext<AtlassianStatuspageConfig>();

  const allStatuspageOptions = pages.map((i): SelectOption => {
    return { label: i.name, value: i.id };
  });

  return (
    <div>
      {allowedMultiplePages ? (
        <CheckboxGroupV2
          formMethods={formMethods}
          name="included_pages"
          label="Select Statuspage pages"
          helptext={"Select which pages will be available within incident.io"}
          options={allStatuspageOptions}
        />
      ) : (
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="included_pages.0"
          label="Select Statuspage page"
          placeholder={"Select page"}
          options={allStatuspageOptions}
          helptext={"Select which page will be available within incident.io"}
          required="Please select a page"
        />
      )}

      {shouldShowUpgradeMessage && (
        <div className="mt-4">
          <UpgradeRequiredMessage
            featureName="Statuspage pages"
            gate={{
              type: "numeric",
              value: identity?.feature_gates.atlassian_statuspage_pages_count,
              featureNameSingular: "Statuspage page",
            }}
            calloutTheme={CalloutTheme.Info}
          />
        </div>
      )}
    </div>
  );
};
