/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalendarEntry,
    CalendarEntryFromJSON,
    CalendarEntryFromJSONTyped,
    CalendarEntryToJSON,
} from './CalendarEntry';

/**
 * 
 * @export
 * @interface InsightsGetCalendarResponseBody
 */
export interface InsightsGetCalendarResponseBody {
    /**
     * 
     * @type {Array<CalendarEntry>}
     * @memberof InsightsGetCalendarResponseBody
     */
    calendar: Array<CalendarEntry>;
}

export function InsightsGetCalendarResponseBodyFromJSON(json: any): InsightsGetCalendarResponseBody {
    return InsightsGetCalendarResponseBodyFromJSONTyped(json, false);
}

export function InsightsGetCalendarResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsGetCalendarResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calendar': ((json['calendar'] as Array<any>).map(CalendarEntryFromJSON)),
    };
}

export function InsightsGetCalendarResponseBodyToJSON(value?: InsightsGetCalendarResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calendar': ((value.calendar as Array<any>).map(CalendarEntryToJSON)),
    };
}

