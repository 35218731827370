import { FieldValuesFromFieldErrors } from "@hookform/error-message/dist/types";
import React from "react";
import { FieldErrors, FieldName, FieldValues, Path } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { ErrorMessage } from "../ErrorMessage";

export const FormLabel = ({
  htmlFor,
  children,
  className,
}: {
  htmlFor: string;
  children: React.ReactNode | string;
  className?: string;
}): React.ReactElement => (
  <label
    htmlFor={htmlFor}
    className={tcx("font-medium text-content-primary text-sm", className)}
  >
    {children}
  </label>
);

export const FormHelpText = ({
  children,
  className,
}: {
  children: React.ReactElement | string;
  className?: string;
}): React.ReactElement => (
  <div className={tcx("mt-1 mb-2 text-sm text-slate-700 max-w-xl", className)}>
    {children}
  </div>
);

export const FormInputWrapper = <FormType extends FieldValues>({
  id,
  errors,
  label,
  helptext,
  children,
  className,
  showErrorAboveComponent = false,
  labelWrapperClassName,
  inputWrapperClassName,
  suffixNode,
}: {
  id: Path<FormType>;
  errors: FieldErrors<FormType>;
  label?: React.ReactNode;
  helptext?: string | React.ReactElement;
  children?: React.ReactNode;
  className?: string;
  labelWrapperClassName?: string;
  inputWrapperClassName?: string;
  showErrorAboveComponent?: boolean;
  suffixNode?: React.ReactNode;
}): React.ReactElement => {
  const hasError = errors[id as string] != null;
  const errorMessage = (
    <ErrorMessage
      className={hasError ? "my-1" : ""}
      errors={errors}
      name={id}
    />
  );

  const LabelAndHelptext = () => {
    return (
      <div
        className={tcx(labelWrapperClassName, {
          "mb-1": hasError && showErrorAboveComponent,
        })}
      >
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        {helptext && <FormHelpText>{helptext}</FormHelpText>}
      </div>
    );
  };

  return (
    <div className={className} data-intercom-target={`form-input-${id}`}>
      {showErrorAboveComponent && errorMessage}
      <LabelAndHelptext />
      <div className={tcx(hasError ? "" : "pb-1", inputWrapperClassName)}>
        {suffixNode ? (
          <div className="flex-center-y w-full space-x-2">
            <div className="grow">{children}</div>
            {suffixNode}
          </div>
        ) : (
          children
        )}
      </div>
      {!showErrorAboveComponent && errorMessage}
    </div>
  );
};

export const FieldsetWrapper = <FormType extends FieldValues>({
  id,
  errors,
  legend,
  helptext,
  children,
  className,
  showErrorAboveComponent = false,
}: {
  id: FieldName<FieldValuesFromFieldErrors<FieldErrors<FormType>>>;
  errors: FieldErrors<FormType>;
  legend: string;
  helptext?: string | React.ReactElement;
  children: React.ReactNode;
  className?: string;
  showErrorAboveComponent?: boolean;
}): React.ReactElement => {
  const hasError = errors[id as string] != null;
  const errorMessage = (
    <ErrorMessage
      className={hasError ? "mb-1 mt-1" : ""}
      errors={errors}
      name={id}
    />
  );
  return (
    <fieldset className={className}>
      <div className="mb-2">
        <legend className={tcx("font-medium text-content-primary text-sm")}>
          {legend}
        </legend>
        {helptext && <FormHelpText>{helptext}</FormHelpText>}
      </div>
      {showErrorAboveComponent && errorMessage}
      {children}
      {!showErrorAboveComponent && errorMessage}
    </fieldset>
  );
};
