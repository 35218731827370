import { AlertStatusEnum } from "@incident-io/api";
import { PopoverMultiSelect } from "@incident-ui";
import { v4 as uuid } from "uuid";

import { useFiltersContext } from "../../@shared/filters/FiltersContext";

export const StatusDropdown = () => {
  const {
    availableFilterFields,
    filters,
    addFilter,
    editFilter,
    deleteFilter,
  } = useFiltersContext();

  const statusConfig = availableFilterFields.find((x) => x.key === "status");
  if (!statusConfig) {
    throw new Error("status field config is missing! Where has it gone?");
  }
  const currentFilterObj = filters.find(
    (x) => x.field_key === statusConfig.key,
  );

  const options = [
    {
      label: "Firing",
      value: AlertStatusEnum.Firing,
    },
    {
      label: "Resolved",
      value: AlertStatusEnum.Resolved,
    },
  ];

  const value = options
    .filter((o) => {
      return (
        currentFilterObj?.multiple_options_value?.includes(o.value) ?? false
      );
    })
    .map((o) => o.value);

  return (
    <div className="flex flex-row items-center gap-2 shrink-0">
      <PopoverMultiSelect
        align="end"
        value={value}
        onChange={(values) => {
          if (values && values.length > 0) {
            if (!currentFilterObj) {
              addFilter({
                key: uuid(),
                field_key: statusConfig.key,
                field_id: statusConfig.key,
                operator: "one_of",
                multiple_options_value: values,
                filter_id: statusConfig.key,
              });
            } else {
              editFilter({
                ...currentFilterObj,
                multiple_options_value: values,
              });
            }
          } else {
            if (currentFilterObj) {
              deleteFilter(currentFilterObj.field_id);
            }
          }
        }}
        options={options}
        placeholder={"All statuses"}
      />
    </div>
  );
};
