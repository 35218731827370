/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    SlimIncident,
    SlimIncidentFromJSON,
    SlimIncidentFromJSONTyped,
    SlimIncidentToJSON,
} from './SlimIncident';
import {
    StatusPageAffectedComponent,
    StatusPageAffectedComponentFromJSON,
    StatusPageAffectedComponentFromJSONTyped,
    StatusPageAffectedComponentToJSON,
} from './StatusPageAffectedComponent';
import {
    StatusPageComponentImpact,
    StatusPageComponentImpactFromJSON,
    StatusPageComponentImpactFromJSONTyped,
    StatusPageComponentImpactToJSON,
} from './StatusPageComponentImpact';
import {
    StatusPageIncidentUpdate,
    StatusPageIncidentUpdateFromJSON,
    StatusPageIncidentUpdateFromJSONTyped,
    StatusPageIncidentUpdateToJSON,
} from './StatusPageIncidentUpdate';
import {
    StatusPageSlim,
    StatusPageSlimFromJSON,
    StatusPageSlimFromJSONTyped,
    StatusPageSlimToJSON,
} from './StatusPageSlim';
import {
    StatusPageSubPageSlim,
    StatusPageSubPageSlimFromJSON,
    StatusPageSubPageSlimFromJSONTyped,
    StatusPageSubPageSlimToJSON,
} from './StatusPageSubPageSlim';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageIncident
 */
export interface StatusPageIncident {
    /**
     * A summary of the affected components
     * @type {Array<StatusPageAffectedComponent>}
     * @memberof StatusPageIncident
     */
    affected_components: Array<StatusPageAffectedComponent>;
    /**
     * Whether or not maintenance window statuses are automatically updated
     * @type {boolean}
     * @memberof StatusPageIncident
     */
    automate_maintenance_status: boolean;
    /**
     * A list of time periods that this incident had an impact on a component
     * @type {Array<StatusPageComponentImpact>}
     * @memberof StatusPageIncident
     */
    component_impacts: Array<StatusPageComponentImpact>;
    /**
     * When this incident was created
     * @type {Date}
     * @memberof StatusPageIncident
     */
    created_at: Date;
    /**
     * Was this imported from Atlassian Statuspage?
     * @type {boolean}
     * @memberof StatusPageIncident
     */
    created_from_atlassian_statuspage_incident: boolean;
    /**
     * 
     * @type {Actor}
     * @memberof StatusPageIncident
     */
    creator: Actor;
    /**
     * A unique ID for this status page incident
     * @type {string}
     * @memberof StatusPageIncident
     */
    id: string;
    /**
     * Whether or not this is a retrospective incident
     * @type {boolean}
     * @memberof StatusPageIncident
     */
    is_retrospective: boolean;
    /**
     * Response (not-publicly-shared) incidents linked to this status page incident
     * @type {Array<SlimIncident>}
     * @memberof StatusPageIncident
     */
    linked_response_incidents: Array<SlimIncident>;
    /**
     * Whether or not the maintenance status was manually updated
     * @type {boolean}
     * @memberof StatusPageIncident
     */
    maintenance_status_manually_updated: boolean;
    /**
     * A title for the incident
     * @type {string}
     * @memberof StatusPageIncident
     */
    name: string;
    /**
     * What is the next sort key that should be used for an update
     * @type {number}
     * @memberof StatusPageIncident
     */
    next_update_sort_key: number;
    /**
     * The canonical public URL for viewing this incident
     * @type {string}
     * @memberof StatusPageIncident
     */
    public_url?: string;
    /**
     * When this incident was published to the status page
     * @type {Date}
     * @memberof StatusPageIncident
     */
    published_at: Date;
    /**
     * Current status for this incident
     * @type {string}
     * @memberof StatusPageIncident
     */
    status: StatusPageIncidentStatusEnum;
    /**
     * 
     * @type {StatusPageSlim}
     * @memberof StatusPageIncident
     */
    status_page: StatusPageSlim;
    /**
     * The ID of the status page this incident is displayed on
     * @type {string}
     * @memberof StatusPageIncident
     */
    status_page_id: string;
    /**
     * How sub-pages are managed, `null` for incidents on standalone pages, and `auto` or `manual` for incidents on sub/customer pages
     * @type {string}
     * @memberof StatusPageIncident
     */
    sub_page_mode?: string;
    /**
     * For a parent page, which sub-pages this incident is included on
     * @type {Array<StatusPageSubPageSlim>}
     * @memberof StatusPageIncident
     */
    sub_pages: Array<StatusPageSubPageSlim>;
    /**
     * The type of incident
     * @type {string}
     * @memberof StatusPageIncident
     */
    type: StatusPageIncidentTypeEnum;
    /**
     * When this incident was last updated
     * @type {Date}
     * @memberof StatusPageIncident
     */
    updated_at: Date;
    /**
     * A list of updates posted to this incident.
     * @type {Array<StatusPageIncidentUpdate>}
     * @memberof StatusPageIncident
     */
    updates: Array<StatusPageIncidentUpdate>;
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageIncident
     */
    write_up_contents?: TextNode;
    /**
     * The URL of a public write-up for the incident
     * @type {string}
     * @memberof StatusPageIncident
     */
    write_up_url?: string;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageIncidentStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved',
    MaintenanceScheduled = 'maintenance_scheduled',
    MaintenanceInProgress = 'maintenance_in_progress',
    MaintenanceComplete = 'maintenance_complete'
}/**
* @export
* @enum {string}
*/
export enum StatusPageIncidentTypeEnum {
    Incident = 'incident',
    Maintenance = 'maintenance'
}

export function StatusPageIncidentFromJSON(json: any): StatusPageIncident {
    return StatusPageIncidentFromJSONTyped(json, false);
}

export function StatusPageIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'affected_components': ((json['affected_components'] as Array<any>).map(StatusPageAffectedComponentFromJSON)),
        'automate_maintenance_status': json['automate_maintenance_status'],
        'component_impacts': ((json['component_impacts'] as Array<any>).map(StatusPageComponentImpactFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'created_from_atlassian_statuspage_incident': json['created_from_atlassian_statuspage_incident'],
        'creator': ActorFromJSON(json['creator']),
        'id': json['id'],
        'is_retrospective': json['is_retrospective'],
        'linked_response_incidents': ((json['linked_response_incidents'] as Array<any>).map(SlimIncidentFromJSON)),
        'maintenance_status_manually_updated': json['maintenance_status_manually_updated'],
        'name': json['name'],
        'next_update_sort_key': json['next_update_sort_key'],
        'public_url': !exists(json, 'public_url') ? undefined : json['public_url'],
        'published_at': (new Date(json['published_at'])),
        'status': json['status'],
        'status_page': StatusPageSlimFromJSON(json['status_page']),
        'status_page_id': json['status_page_id'],
        'sub_page_mode': !exists(json, 'sub_page_mode') ? undefined : json['sub_page_mode'],
        'sub_pages': ((json['sub_pages'] as Array<any>).map(StatusPageSubPageSlimFromJSON)),
        'type': json['type'],
        'updated_at': (new Date(json['updated_at'])),
        'updates': ((json['updates'] as Array<any>).map(StatusPageIncidentUpdateFromJSON)),
        'write_up_contents': !exists(json, 'write_up_contents') ? undefined : TextNodeFromJSON(json['write_up_contents']),
        'write_up_url': !exists(json, 'write_up_url') ? undefined : json['write_up_url'],
    };
}

export function StatusPageIncidentToJSON(value?: StatusPageIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'affected_components': ((value.affected_components as Array<any>).map(StatusPageAffectedComponentToJSON)),
        'automate_maintenance_status': value.automate_maintenance_status,
        'component_impacts': ((value.component_impacts as Array<any>).map(StatusPageComponentImpactToJSON)),
        'created_at': (value.created_at.toISOString()),
        'created_from_atlassian_statuspage_incident': value.created_from_atlassian_statuspage_incident,
        'creator': ActorToJSON(value.creator),
        'id': value.id,
        'is_retrospective': value.is_retrospective,
        'linked_response_incidents': ((value.linked_response_incidents as Array<any>).map(SlimIncidentToJSON)),
        'maintenance_status_manually_updated': value.maintenance_status_manually_updated,
        'name': value.name,
        'next_update_sort_key': value.next_update_sort_key,
        'public_url': value.public_url,
        'published_at': (value.published_at.toISOString()),
        'status': value.status,
        'status_page': StatusPageSlimToJSON(value.status_page),
        'status_page_id': value.status_page_id,
        'sub_page_mode': value.sub_page_mode,
        'sub_pages': ((value.sub_pages as Array<any>).map(StatusPageSubPageSlimToJSON)),
        'type': value.type,
        'updated_at': (value.updated_at.toISOString()),
        'updates': ((value.updates as Array<any>).map(StatusPageIncidentUpdateToJSON)),
        'write_up_contents': TextNodeToJSON(value.write_up_contents),
        'write_up_url': value.write_up_url,
    };
}

