/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';
import {
    StepConfigPayload,
    StepConfigPayloadFromJSON,
    StepConfigPayloadFromJSONTyped,
    StepConfigPayloadToJSON,
} from './StepConfigPayload';

/**
 * 
 * @export
 * @interface WorkflowPayload
 */
export interface WorkflowPayload {
    /**
     * Annotations that track metadata about this resource
     * @type {{ [key: string]: string; }}
     * @memberof WorkflowPayload
     */
    annotations?: { [key: string]: string; };
    /**
     * List of conditions to apply to the workflow
     * @type {Array<ConditionGroupPayload>}
     * @memberof WorkflowPayload
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * If this workflow is delayed, whether the conditions should be rechecked between trigger firing and execution
     * @type {boolean}
     * @memberof WorkflowPayload
     */
    conditions_apply_over_delay?: boolean;
    /**
     * Whether to continue executing the workflow if a step fails
     * @type {boolean}
     * @memberof WorkflowPayload
     */
    continue_on_step_error: boolean;
    /**
     * Delay in seconds between trigger firing and running the workflow
     * @type {number}
     * @memberof WorkflowPayload
     */
    delay_for_seconds?: number;
    /**
     * The expressions to use in the workflow
     * @type {Array<ExpressionPayload>}
     * @memberof WorkflowPayload
     */
    expressions: Array<ExpressionPayload>;
    /**
     * Folder to display the workflow in
     * @type {string}
     * @memberof WorkflowPayload
     */
    folder?: string;
    /**
     * Whether to include private incidents
     * @type {boolean}
     * @memberof WorkflowPayload
     */
    include_private_incidents: boolean;
    /**
     * Whether to include retrospective incidents
     * @type {boolean}
     * @memberof WorkflowPayload
     */
    include_retrospective_incidents: boolean;
    /**
     * Whether to include test incidents
     * @type {boolean}
     * @memberof WorkflowPayload
     */
    include_test_incidents: boolean;
    /**
     * Name provided by the user when creating the workflow
     * @type {string}
     * @memberof WorkflowPayload
     */
    name: string;
    /**
     * Once For strategy to apply to this workflow
     * @type {Array<string>}
     * @memberof WorkflowPayload
     */
    once_for: Array<string>;
    /**
     * Which incidents should the workflow be applied to?
     * @type {string}
     * @memberof WorkflowPayload
     */
    runs_on_incidents: WorkflowPayloadRunsOnIncidentsEnum;
    /**
     * What state this workflow is in
     * @type {string}
     * @memberof WorkflowPayload
     */
    state?: WorkflowPayloadStateEnum;
    /**
     * List of step to execute as part of the workflow
     * @type {Array<StepConfigPayload>}
     * @memberof WorkflowPayload
     */
    steps: Array<StepConfigPayload>;
}

/**
* @export
* @enum {string}
*/
export enum WorkflowPayloadRunsOnIncidentsEnum {
    Created = 'newly_created',
    CreatedAndActive = 'newly_created_and_active'
}/**
* @export
* @enum {string}
*/
export enum WorkflowPayloadStateEnum {
    Active = 'active',
    Disabled = 'disabled',
    Draft = 'draft',
    Error = 'error'
}

export function WorkflowPayloadFromJSON(json: any): WorkflowPayload {
    return WorkflowPayloadFromJSONTyped(json, false);
}

export function WorkflowPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'conditions_apply_over_delay': !exists(json, 'conditions_apply_over_delay') ? undefined : json['conditions_apply_over_delay'],
        'continue_on_step_error': json['continue_on_step_error'],
        'delay_for_seconds': !exists(json, 'delay_for_seconds') ? undefined : json['delay_for_seconds'],
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'folder': !exists(json, 'folder') ? undefined : json['folder'],
        'include_private_incidents': json['include_private_incidents'],
        'include_retrospective_incidents': json['include_retrospective_incidents'],
        'include_test_incidents': json['include_test_incidents'],
        'name': json['name'],
        'once_for': json['once_for'],
        'runs_on_incidents': json['runs_on_incidents'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'steps': ((json['steps'] as Array<any>).map(StepConfigPayloadFromJSON)),
    };
}

export function WorkflowPayloadToJSON(value?: WorkflowPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'conditions_apply_over_delay': value.conditions_apply_over_delay,
        'continue_on_step_error': value.continue_on_step_error,
        'delay_for_seconds': value.delay_for_seconds,
        'expressions': ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'folder': value.folder,
        'include_private_incidents': value.include_private_incidents,
        'include_retrospective_incidents': value.include_retrospective_incidents,
        'include_test_incidents': value.include_test_incidents,
        'name': value.name,
        'once_for': value.once_for,
        'runs_on_incidents': value.runs_on_incidents,
        'state': value.state,
        'steps': ((value.steps as Array<any>).map(StepConfigPayloadToJSON)),
    };
}

