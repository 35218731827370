import { ErrorMessage } from "@incident-shared/forms/ErrorMessage";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { PopoverSingleSelect } from "@incident-ui";
import { PopoverSelectOption } from "@incident-ui/PopoverSelect";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  FieldPathValue,
  Path,
  PathValue,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import {
  StatusPageAffectedComponentPayload,
  StatusPageAffectedComponentPayloadStatusEnum as ComponentStatusEnum,
  StatusPageIncident,
  StatusPageIncidentUpdate,
  StatusPageStructure,
  StatusPageStructureComponent,
  StatusPageStructureGroup,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { impactOptions } from "../utils/utils";

export type ComponentStatuses = Record<string, ComponentStatusEnum>;

export type AffectedComponentsFormData = {
  component_statuses: ComponentStatuses;
};

// affectedComponentsFormStateToPayload transforms the form state into the
// payload to send to the API. This will only include payloads for components
// that are non-operational, or were already affected by the optional incident
// passed in.
export function affectedComponentsFormStateToPayload(
  data: AffectedComponentsFormData,
  incident?: StatusPageIncident,
): StatusPageAffectedComponentPayload[] {
  const requiredComponentIds = new Set(
    incident?.affected_components?.map(({ component_id }) => component_id),
  );

  return Object.entries(data.component_statuses)
    .filter(
      // Strip out operational components - they're not interesting, unless they
      // were previously affected by this incident
      ([componentId, status]) =>
        requiredComponentIds.has(componentId) ||
        status !== ComponentStatusEnum.Operational,
    )
    .map(([component_id, status]) => ({ component_id, status }));
}

export const AffectedComponentsEditor = <
  FormType extends FieldValues,
  TPrefix extends Path<FormType>,
  FieldPrefix extends FieldPathValue<
    FormType,
    TPrefix
  > extends ComponentStatuses
    ? TPrefix
    : never, // This type ensures that the `fieldNamePrefix` arg will always point to a valid component status key
>({
  structure,
  incident,
  maintenance,
  fieldNamePrefix,
  formMethods,
}: {
  structure: StatusPageStructure;
  incident?: StatusPageIncident;
  maintenance: boolean;
  fieldNamePrefix: FieldPrefix;
  formMethods: UseFormReturn<FormType>;
}): React.ReactElement => {
  const {
    formState: { errors },
  } = formMethods;

  return (
    <div className="text-sm">
      <p className="font-medium mb-1">Affected components</p>
      <p className="text-slate-600">
        Which components are impacted by this{" "}
        {maintenance ? "maintenance window" : "incident"}?
      </p>
      <ErrorMessage
        errors={errors}
        name={fieldNamePrefix}
        message="You must select a component to mark as affected"
      />
      <div className="divide-y divide-stroke bg-white border-stroke border rounded-2 shadow mt-2">
        {structure.items.map(({ group, component }, index) => {
          if (component) {
            return (
              <SingleComponent
                key={component.component_id}
                component={component}
                incident={incident}
                maintenance={maintenance}
                fieldNamePrefix={fieldNamePrefix}
              />
            );
          } else if (group) {
            return (
              <ComponentGroup
                key={index}
                group={group}
                incident={incident}
                maintenance={maintenance}
                fieldNamePrefix={fieldNamePrefix}
              />
            );
          }
          return undefined;
        })}
      </div>{" "}
    </div>
  );
};

const SingleComponent = <
  FormType extends FieldValues,
  TPrefix extends Path<FormType>,
  FieldPrefix extends FieldPathValue<
    FormType,
    TPrefix
  > extends ComponentStatuses
    ? TPrefix
    : never,
>({
  incident,
  component,
  isNested = false,
  maintenance,
  fieldNamePrefix,
}: {
  incident?: StatusPageIncident;
  component: StatusPageStructureComponent;
  isNested?: boolean;
  maintenance?: boolean;
  fieldNamePrefix: FieldPrefix;
}): React.ReactElement | null => {
  const formMethods = useFormContext<FormType>();
  const { setValue, watch } = formMethods;
  const componentStatuses = watch(fieldNamePrefix);

  // If the form state does not have this key yet, leave it out
  if (!Object.hasOwn(componentStatuses, component.component_id)) return null;

  const componentPreviouslyAffected =
    incident &&
    incident.affected_components.find(
      (ac) => ac.component_id === component.component_id,
    ) !== undefined;

  // To remove a component we need to be in update mode, and the component must not have
  // previously been affected
  const canRemove = incident && !componentPreviouslyAffected;

  // Don't show hidden components
  if (component.hidden) return null;

  return (
    <div
      className={tcx("flex items-center px-4 py-1 space-x-2", {
        "ml-6": isNested,
      })}
      key={component.component_id}
    >
      {isNested && (
        <Icon
          id={IconEnum.NestedInfo}
          size={IconSize.XS}
          className="text-content-tertiary"
        />
      )}
      <div className="grow text-sm">{component.name}</div>
      <PopoverSingleSelectV2
        formMethods={formMethods}
        name={`${fieldNamePrefix}.${component.component_id}` as Path<FormType>}
        placeholder="Choose impact level"
        options={impactOptions({
          isUpdate: !!incident,
          componentPreviouslyAffected,
          maintenance,
        })}
        renderSelected={(option: PopoverSelectOption) => (
          <div className="text-right w-full justify-end flex-center-y gap-1">
            {option.icon && <Icon id={option.icon} {...option.iconProps} />}
            {option.label}
          </div>
        )}
        triggerClassName="min-w-[225px] border-none shadow-none"
      />
      {canRemove && (
        <Button
          title="Remove component"
          theme={ButtonTheme.Naked}
          icon={IconEnum.Close}
          analyticsTrackingId={"status-page-remove-affected-component"}
          onClick={() => {
            delete componentStatuses[component.component_id];
            setValue(fieldNamePrefix, componentStatuses);
          }}
        />
      )}
    </div>
  );
};

const ComponentGroup = <
  FormType extends FieldValues,
  TPrefix extends Path<FormType>,
  FieldPrefix extends FieldPathValue<
    FormType,
    TPrefix
  > extends ComponentStatuses
    ? TPrefix
    : never,
>({
  group,
  incident,
  maintenance,
  fieldNamePrefix,
}: {
  group: StatusPageStructureGroup;
  incident?: StatusPageIncident;
  maintenance?: boolean;
  fieldNamePrefix: FieldPrefix;
}): React.ReactElement | null => {
  const [isOpen, setIsOpen] = useState(!!incident);
  const [isBulkEditing, setIsBulkEditing] = useState(false);

  const { setValue, watch } = useFormContext<FormType>();
  const componentStatuses = watch(fieldNamePrefix);
  const worstStatus =
    _.maxBy(
      group.components.map(
        ({ component_id }) =>
          componentStatuses[component_id] || ComponentStatusEnum.Operational,
      ),
      (status) => COMPONENT_STATUS_CONFIG[status].rank,
    ) || ComponentStatusEnum.Operational;
  const worstStatusCfg = COMPONENT_STATUS_CONFIG[worstStatus];

  const visibleComponents = group.components.filter(({ component_id }) =>
    Object.hasOwn(componentStatuses, component_id),
  );

  // When the number of contents in the group changes, pop the group open
  const [lastNumberOfContents, setLastNumberOfContents] = useState(
    visibleComponents.length,
  );
  useEffect(() => {
    if (lastNumberOfContents !== visibleComponents.length) {
      setLastNumberOfContents(visibleComponents.length);
      setIsOpen(true);
    }
  }, [lastNumberOfContents, visibleComponents.length]);

  // If there's nothing visible in this yet, leave it out
  if (visibleComponents.length === 0) return null;

  // If the group is hidden, don't show it
  if (group.hidden) return null;

  return (
    <>
      <div className="flex items-center p-4 h-14 text-sm space-x-2">
        {!incident ? (
          <Button
            onClick={() => setIsOpen(!isOpen)}
            theme={ButtonTheme.Naked}
            analyticsTrackingId={null}
            className="grow flex row-reverse"
          >
            {group.name}
            <Icon
              id={isOpen ? IconEnum.Collapse : IconEnum.Expand}
              size={IconSize.Medium}
            />
          </Button>
        ) : (
          <p className="grow text-black mr-2">{group.name}</p>
        )}
        {isBulkEditing ? (
          <>
            <PopoverSingleSelect
              placeholder="Choose impact level"
              options={impactOptions({ isUpdate: !!incident, maintenance })}
              triggerClassName="text-slate-200 w-52"
              value={undefined}
              onChange={(val) => {
                if (typeof val !== "string") return;

                group.components.forEach(({ component_id }) => {
                  // If this wasn't in the form before, don't add it now
                  if (!Object.hasOwn(componentStatuses, component_id)) return;
                  setValue(
                    `${fieldNamePrefix}.${component_id}` as Path<FormType>,
                    val as PathValue<FormType, Path<FormType>>,
                  );
                });

                setIsBulkEditing(false);
              }}
            />
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId={null}
              icon={IconEnum.Close}
              title="Cancel bulk edit"
              onClick={() => setIsBulkEditing(false)}
            />
          </>
        ) : (
          <>
            <span className="flex items-center">
              <Icon
                id={worstStatusCfg.icon}
                className={worstStatusCfg.colour}
              />
            </span>
            <Button
              onClick={() => {
                setIsOpen(true);
                setIsBulkEditing(!isBulkEditing);
              }}
              theme={ButtonTheme.Naked}
              analyticsTrackingId={null}
            >
              Bulk edit impact
              <Icon id={IconEnum.Edit} className="ml-1" size={IconSize.Small} />
            </Button>
          </>
        )}
      </div>
      {isOpen &&
        group.components.map((component) => (
          <SingleComponent
            key={component.component_id}
            component={component}
            isNested
            incident={incident}
            maintenance={maintenance}
            fieldNamePrefix={fieldNamePrefix}
          />
        ))}
    </>
  );
};

type ComponentToShow = {
  componentId: string;
  componentName: React.ReactNode;
  prevStatus?: ComponentStatusEnum;
  status: ComponentStatusEnum;
};

export const affectedComponentsReviewInfo = (
  structure: StatusPageStructure,
  componentStatuses: AffectedComponentsFormData["component_statuses"],
  incident?: StatusPageIncident,
): ComponentToShow[] => {
  const previousStatuses = incident
    ? new Map(
        (
          _.maxBy(incident.updates, "sort_key") as StatusPageIncidentUpdate
        ).component_statuses.map(({ component_id, status }) => [
          component_id,
          status,
        ]),
      )
    : new Map();

  const buildComponent = (component: StatusPageStructureComponent) => {
    const status = componentStatuses[component.component_id];

    // If this wasn't in the editor, don't show it now.
    if (!status) return [];

    const prevStatus = previousStatuses.get(component.component_id);

    if (
      (!prevStatus || prevStatus === ComponentStatusEnum.Operational) &&
      status === ComponentStatusEnum.Operational
    ) {
      return [];
    }

    return [
      {
        componentId: component.component_id,
        componentName: component.name,
        prevStatus,
        status,
      },
    ];
  };

  return structure.items.flatMap(({ group, component }): ComponentToShow[] => {
    if (component) {
      return buildComponent(component);
    }

    if (group) {
      return group.components.flatMap((component) => {
        return buildComponent(component).map((res) => ({
          ...res,
          componentName: (
            <span className="inline-flex">
              {group.name} <Icon id={IconEnum.ChevronRight} /> {component.name}
            </span>
          ),
        }));
      });
    }

    return [];
  });
};
