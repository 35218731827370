import { IconEnum } from "@incident-ui";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemSource } from "src/components/timeline/TimelineItemSource";
import {
  Incident,
  IncidentEscalate,
  TimelineItemObject,
} from "src/contexts/ClientContext";

export const TimelineItemEscalate = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.incident_escalate) {
    throw new Error(
      "malformed timeline item: incident_escalate was missing incident_escalate field",
    );
  }
  return {
    icon: IconEnum.Escalate,
    description: (
      <>
        <strong>
          <TimelineItemSource actor={item.incident_escalate.escalator} />
        </strong>{" "}
        {incidentEscalationText(item.incident_escalate)}
      </>
    ),
    avatarUrl: item.incident_escalate.escalator?.user?.avatar_url,
  };
};

const incidentEscalationText = (incident_escalate: IncidentEscalate) => {
  if (incident_escalate.assignees !== "") {
    return (
      <>
        escalated this incident to{" "}
        <strong>{incident_escalate.assignees}</strong>
      </>
    );
  }
  return `escalated this incident`;
};
