import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ModalFooter } from "@incident-ui/Modal/ModalFooter";
import { default as React } from "react";
import { useForm } from "react-hook-form";

export const DisableConfirmationModal = ({
  onSubmit,
  onClose,
  helpText,
  submitText,
  title,
  typeToConfirmPhrase,
}: {
  submitText: string;
  typeToConfirmPhrase: string;
  title: string;
  onSubmit: () => Promise<unknown>;
  onClose: () => void;
  helpText: string | React.ReactElement;
}) => {
  const formMethods = useForm();
  const confirmPhrase = formMethods.watch("confirmPhrase");
  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={onClose}
      title={title}
      analyticsTrackingId={`delete-${title}-confirmation`}
    >
      <InputV2
        helptext={helpText}
        formMethods={formMethods}
        name="confirmPhrase"
        placeholder={`Type '${typeToConfirmPhrase}' here.`}
      />
      <ModalFooter
        disabled={
          confirmPhrase?.toLowerCase() !== typeToConfirmPhrase?.toLowerCase()
        }
        confirmButtonText={submitText}
        confirmButtonType="button"
        cancelButtonText="Cancel"
        onConfirm={onSubmit}
        onClose={onClose}
      />
    </FormModalV2>
  );
};
