import _ from "lodash";
import {
  BranchesOnlyExpression,
  BranchesOnlyExpressionPayload,
  EngineParamBindingPayload,
  EngineParamBindingValuePayload,
  Expression,
  ExpressionBranch,
  ExpressionBranchPayload,
  ExpressionOperation,
  ExpressionOperationOperationTypeEnum as OperationType,
  ExpressionOperationPayload,
  ExpressionOperationPayloadOperationTypeEnum,
  ExpressionPayload,
} from "src/contexts/ClientContext";
import { conditionGroupToConditionGroupPayload } from "src/utils/engine";
import { assertUnreachable } from "src/utils/utils";

export type ExpressionFormData = Omit<Expression, "id"> & {
  id?: string;
};

const getResultOrArrayResult = (
  result: EngineParamBindingPayload,
  isArray: boolean,
): EngineParamBindingPayload => {
  const valueToValuePayload = (
    v: EngineParamBindingValuePayload | undefined,
  ): EngineParamBindingValuePayload | undefined => {
    if (!v) {
      return undefined;
    } else if (v.reference) {
      return {
        reference: v.reference,
      };
    } else if (v.literal) {
      return {
        literal: v.literal,
      };
    } else {
      return undefined;
    }
  };

  return isArray
    ? {
        array_value: result.array_value
          ?.map((v) => valueToValuePayload(v) ?? v)
          .filter((v) => !_.isNil(v.literal) || !_.isNil(v.reference)),
      }
    : {
        value: valueToValuePayload(result.value),
      };
};

export const expressionToPayload = (
  expression: ExpressionFormData,
): ExpressionPayload => {
  const result: ExpressionPayload = {
    // Coalesce an empty string to undefined, just in case!
    id: expression.id === "" ? undefined : expression.id,
    label: expression.label,
    reference: expression.reference,
    root_reference: expression.root_reference,
    operations: expression.operations.map(queryOperationToPayload),
  };

  if (
    expression.else_branch &&
    expression.else_branch.result &&
    (!_.isNil(expression.else_branch.result.value) ||
      !_.isNil(expression.else_branch.result.array_value))
  ) {
    result.else_branch = {
      result: getResultOrArrayResult(
        expression.else_branch?.result,
        expression.returns?.array,
      ),
    };
  }

  return result;
};

export const branchesOnlyExpressionToPayload = (
  formData: BranchesOnlyExpression,
  resultArray: boolean,
): BranchesOnlyExpressionPayload => {
  if (formData.branches == null && formData.else_branch == null) {
    throw new Error("unreachable: branches or else_branch must be set");
  }

  const elseBranch = formData.else_branch
    ? {
        result: getResultOrArrayResult(
          formData.else_branch?.result,
          resultArray,
        ),
      }
    : undefined;

  const branches =
    formData.branches?.map((branch) => branchToPayload(branch, resultArray)) ??
    [];

  return { branches, else_branch: elseBranch };
};

const branchToPayload = (
  branch: ExpressionBranch,
  isArray: boolean,
): ExpressionBranchPayload => {
  return {
    condition_groups: branch.condition_groups.map(
      conditionGroupToConditionGroupPayload,
    ),
    result: getResultOrArrayResult(branch.result, isArray),
  };
};

export const queryOperationToPayload = (
  operation: ExpressionOperation,
): ExpressionOperationPayload => {
  const res: ExpressionOperationPayload = {
    operation_type:
      operation.operation_type as unknown as ExpressionOperationPayloadOperationTypeEnum,
  };
  switch (operation.operation_type) {
    case OperationType.Count:
    case OperationType.Max:
    case OperationType.Min:
    case OperationType.Random:
    case OperationType.First:
      // Do nothing!
      break;
    case OperationType.Filter:
      const conditionGroups = operation.filter?.condition_groups || [];
      res.filter = {
        condition_groups: conditionGroups.map(
          conditionGroupToConditionGroupPayload,
        ),
      };
      break;
    case OperationType.Navigate:
      res.navigate = operation.navigate;
      break;
    case OperationType.Parse:
      if (operation.parse) {
        res.parse = {
          ...operation.parse,
          // If the user doesn't touch the checkbox for array, default it to false
          returns: {
            type: operation.returns?.type,
            array: operation.returns?.array ?? false,
          },
        };
      }
      break;
    case OperationType.Branches:
      const branchesOpts = operation.branches;
      res.branches = {
        returns: {
          type: branchesOpts?.returns?.type || "",
          array: branchesOpts?.returns?.array ?? false,
        },
        branches:
          branchesOpts?.branches.map((b) =>
            branchToPayload(b, operation.returns?.array),
          ) || [],
      };
      break;
    default:
      assertUnreachable(operation.operation_type);
  }
  return res;
};
