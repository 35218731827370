import {
  IncidentsCreateUpdateRequestBody,
  IncidentStatusCategoryEnum,
  Stream,
} from "@incident-io/api";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { marshallTextDocumentPayload } from "@incident-shared/incident-forms/marshall";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  ToastTheme,
} from "@incident-ui";
import {
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm, UseFormReturn } from "react-hook-form";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { useRevalidate } from "../../../../utils/use-revalidate";

export const StreamsUpdateDrawer = ({
  incident,
  closeDrawer,
  isClosingStream,
}: {
  incident: Stream;
  closeDrawer: () => void;
  isClosingStream: boolean;
}) => {
  const formMethods = useForm<IncidentsCreateUpdateRequestBody>({
    defaultValues: {
      to_incident_status_id: incident.status.id,
    },
  });
  const showToast = useToast();

  const refetchUpdates = useAPIRefetch("incidentUpdatesListForIncident", {
    incidentId: incident.id,
  });
  const refreshStreamsList = useRevalidate(["streamsList"]);

  const {
    data: { incident_statuses: statuses },
    isLoading: statusesLoading,
    error: statusesError,
  } = useAPI(
    "incidentLifecyclesListStatusesForIncident",
    { incidentId: incident.id },
    {
      fallbackData: { incident_statuses: [] },
    },
  );

  const openStatus = statuses?.find(
    (status) => status.category === IncidentStatusCategoryEnum.Active,
  );
  const closedStatus = statuses?.find(
    (status) => status.category === IncidentStatusCategoryEnum.Closed,
  );

  const { trigger, isMutating } = useAPIMutation(
    "streamsShow",
    {
      id: incident.id,
    },
    async (apiClient, formData: IncidentsCreateUpdateRequestBody) => {
      if (formData.next_update_in_minutes) {
        formData.next_update_in_minutes = parseInt(
          formData.next_update_in_minutes.toString(),
        );
      }
      await apiClient.incidentsCreateUpdate({
        incidentId: incident.id,
        createUpdateRequestBody: {
          message: marshallTextDocumentPayload(formData.message),
          next_update_in_minutes: formData.next_update_in_minutes,
          to_incident_status_id: isClosingStream
            ? closedStatus?.id
            : openStatus?.id,
        },
      });
    },
    {
      onSuccess: () => {
        refetchUpdates();
        refreshStreamsList();
        closeDrawer();
      },
      onError: (err) => {
        showToast({
          title: "Stream update failed",
          description:
            err.message ?? "Something unexpected happened. Please try again.",
          theme: ToastTheme.Error,
        });
      },
      setError: formMethods.setError,
    },
  );

  return (
    <DrawerContents>
      <DrawerTitle
        title={isClosingStream ? "Close stream" : "Share update"}
        icon={IconEnum.Announcement}
        onClose={closeDrawer}
        color={ColorPaletteEnum.Blue}
      />
      <DrawerBody className="flex grow">
        <StreamsUpdateForm
          formMethods={formMethods}
          trigger={trigger}
          isMutating={isMutating}
          loading={statusesLoading}
          error={!!statusesError || !openStatus || !closedStatus}
          isClosingStream={isClosingStream}
        />
      </DrawerBody>
      <DrawerFooter className="flex justify-end">
        <Button
          type="submit"
          form="update-stream"
          analyticsTrackingId={"update-stream"}
          theme={ButtonTheme.Primary}
          loading={isMutating}
          disabled={!formMethods.formState.isValid}
        >
          Share
        </Button>
      </DrawerFooter>
    </DrawerContents>
  );
};

const StreamsUpdateForm = ({
  formMethods,
  trigger,
  isMutating,
  loading,
  error,
  isClosingStream,
}: {
  formMethods: UseFormReturn<IncidentsCreateUpdateRequestBody>;
  trigger: (data: IncidentsCreateUpdateRequestBody) => void;
  isMutating: boolean;
  loading: boolean;
  error: boolean;
  isClosingStream: boolean;
}) => {
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <GenericErrorMessage />;
  }

  return (
    <FormV2
      id="update-stream"
      onSubmit={trigger}
      formMethods={formMethods}
      saving={isMutating}
    >
      {isClosingStream ? (
        <TemplatedTextInputV2
          formMethods={formMethods}
          name="message.text_node"
          label={"Message"}
          required={true}
          placeholder={"Can you share a final update on the stream?"}
          includeVariables={false}
          includeExpressions={false}
          format="slack_rich_text"
        />
      ) : (
        <>
          <TemplatedTextInputV2
            formMethods={formMethods}
            name="message.text_node"
            label={"Message"}
            required={true}
            placeholder={
              "What's happening at the moment? What are you doing next?"
            }
            includeVariables={false}
            includeExpressions={false}
            format="slack_rich_text"
          />
          <StaticSingleSelectV2
            formMethods={formMethods}
            label={"When will you provide the next update?"}
            name="next_update_in_minutes"
            options={[
              { value: "5", label: "5 minutes" },
              { value: "15", label: "15 minutes" },
              { value: "30", label: "30 minutes" },
              { value: "60", label: "60 minutes" },
              { value: "180", label: "3 hours" },
              { value: "360", label: "6 hours" },
              { value: "720", label: "12 hours" },
              { value: "1440", label: "1 day" },
              { value: "10080", label: "7 days" },
            ]}
            placeholder="Choose a time"
            isClearable={true}
            required={false}
          />
        </>
      )}
    </FormV2>
  );
};
