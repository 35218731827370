import { Callout, CalloutTheme, LoadingModal, ModalFooter } from "@incident-ui";
import { orderBy } from "lodash";
import { useForm } from "react-hook-form";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "src/components/@shared/forms/Typeahead";
import { FormModalV2 } from "src/components/@shared/forms/v2/FormV2";
import { DynamicSingleSelectV2 } from "src/components/@shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "src/components/@shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "src/components/@shared/forms/v2/inputs/TextareaV2";
import {
  FollowUpPriority,
  FollowUpsCreateRequestBody,
  Identity,
  Settings,
  SuggestedFollowUp,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useRefetchFollowups } from "./use-refetch-followups";

type Props = {
  onClose: () => void;
  isPrivateIncident: boolean;
  incidentId: string;
  followUpSuggestion?: SuggestedFollowUp;
};

export const FollowUpCreateModal = ({
  incidentId,
  onClose,
  isPrivateIncident,
  followUpSuggestion,
}: Props): React.ReactElement => {
  const { settings } = useSettings();
  const { identity } = useIdentity();

  const { data: prioOptsData, error: followUpPriorityOptionsError } = useAPI(
    "followUpsListPriorityOptions",
    undefined,
  );
  const followUpPriorityOptions = prioOptsData
    ? orderBy(prioOptsData.priority_options, (field) => field.rank, "desc")
    : null;

  if (followUpPriorityOptionsError) {
    throw followUpPriorityOptionsError;
  }

  if (!followUpPriorityOptions || !settings || !identity) {
    return <LoadingModal title={`Create follow-up`} onClose={onClose} />;
  }

  return (
    <FollowUpCreateModalInner
      identity={identity}
      incidentId={incidentId}
      settings={settings}
      onClose={onClose}
      isPrivateIncident={isPrivateIncident}
      followUpPriorityOptions={followUpPriorityOptions}
      followUpSuggestion={followUpSuggestion}
    />
  );
};

const FollowUpCreateModalInner = ({
  identity,
  incidentId,
  settings,
  onClose,
  isPrivateIncident,
  followUpPriorityOptions,
  followUpSuggestion,
}: Props & {
  identity: Identity;
  settings: Settings;
  followUpPriorityOptions: FollowUpPriority[];
}): React.ReactElement => {
  const defaultPriorityOption = followUpPriorityOptions.find(
    (option) => option.is_default,
  );

  const formMethods = useForm<FollowUpsCreateRequestBody>({
    defaultValues: {
      follow_up_priority_option_id: defaultPriorityOption?.id,
      title: followUpSuggestion?.title,
      description: followUpSuggestion?.description,
    },
  });
  const { setError } = formMethods;

  const refetch = useRefetchFollowups();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    { incidentId },
    async (apiClient, body: FollowUpsCreateRequestBody) => {
      await apiClient.followUpsCreate({
        createRequestBody: {
          ...body,
          incident_id: incidentId,
          incident_follow_up_suggestion_id: followUpSuggestion?.id,
        },
      });
    },
    {
      onSuccess: () => {
        refetch();
        onClose();
      },
      setError,
    },
  );

  const apiClient = useClient();

  return (
    <FormModalV2
      formMethods={formMethods}
      analyticsTrackingId={null}
      onClose={onClose}
      title={`Create follow-up`}
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          onClose={onClose}
          saving={saving}
          confirmButtonType="submit"
          confirmButtonText="Create"
        />
      }
    >
      {followUpSuggestion ? (
        <Callout
          theme={CalloutTheme.AIRainbow}
          className="text-sm border-[1px] border-color-purple-100"
        >
          <div className="mb-1">
            Suggested based on what happened in the incident
          </div>
          <div className="text-content-tertiary text-xs-med">
            {followUpSuggestion.context}
          </div>
        </Callout>
      ) : (
        <Callout theme={CalloutTheme.Info} className="text-sm">
          This follow-up will be posted into the incident channel. Once created,
          you&apos;ll also be able to link it to an issue tracker.
        </Callout>
      )}

      <InputV2
        formMethods={formMethods}
        autoFocus
        name="title"
        label="Title"
        required="Please provide a title"
      />
      <TextareaV2
        formMethods={formMethods}
        label="Description"
        required={false}
        name="description"
      />
      {/* Assignee */}
      <DynamicSingleSelectV2
        formMethods={formMethods}
        label="Owner"
        required={settings.misc.follow_up_owner_required}
        isClearable={!settings.misc.follow_up_owner_required}
        helptext={
          isPrivateIncident
            ? "As this is a private incident, you can only assign follow-ups to people with access."
            : undefined
        }
        name="assignee_id"
        placeholder="Select user"
        loadOptions={getTypeaheadOptions(apiClient, TypeaheadTypeEnum.User, {
          incidentId,
        })}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.User,
        )}
      />

      {/* Priority */}
      {identity.feature_gates.follow_up_priorities && (
        <StaticSingleSelectV2
          formMethods={formMethods}
          name={"follow_up_priority_option_id"}
          label="Priority"
          placeholder={"No priority"}
          options={followUpPriorityOptions.map(
            ({ id, name, is_default, description }) => ({
              value: id,
              label: is_default ? name + " (Default)" : name,
              description,
            }),
          )}
          required={settings.misc.follow_up_priority_required}
          isClearable={!settings.misc.follow_up_priority_required}
          renderDescription="below"
        />
      )}
    </FormModalV2>
  );
};
