import { ListEditorV2 } from "@incident-shared/forms/v2/editors/ListEditorV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { Loader, ModalFooter } from "@incident-ui";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  DecisionTree,
  DecisionTreeNode,
  DecisionTreeRootNode,
} from "src/contexts/ClientContext";

type NodeOption = {
  // We auto generate this in the client
  id: number;
  value: string;
  sort_key: number;
};

type EditNodeFormData = {
  name: string;
  prompt: string;
  options: NodeOption[];
  // this just gets passed around to make our life easier
  is_root: boolean;
};

type ourNodeType = DecisionTreeRootNode | DecisionTreeNode | undefined;

const getRandomInt32 = () => {
  const min = 0;
  const max = Math.pow(2, 32) - 1;
  return Math.floor(Math.random() * (max - min) + min);
};

const extractDefaultValues = (
  tree: DecisionTree,
  editingNodeId: number,
): EditNodeFormData => {
  // find our node
  let ourNode: ourNodeType = tree.nodes.find((x) => x.id === editingNodeId);
  if (!ourNode) {
    //  maybe our node is the root?
    if (tree.root.id === editingNodeId) {
      ourNode = tree.root;
    } else {
      throw new Error("unreachable: trying to edit a node we cannot find");
    }
  }

  // find any children
  const childNodes = tree.nodes.filter((x) => x.parent_id === editingNodeId);

  return {
    name: ourNode.name,
    prompt: ourNode.prompt,
    options: childNodes.map((x) => ({
      value: x.option_label,
      id: x.id,
      sort_key: x.sort_order,
    })),
    is_root: tree.root.id === editingNodeId,
  };
};

// This takes our form data and puts it back into an updated decision tree, ready to be
// passed to our API
const mapDataToTree = (
  data: EditNodeFormData,
  editingNodeId: number,
  originalTree: DecisionTree,
): DecisionTree => {
  const tree = _.cloneDeep(originalTree);
  // We are only editing our note (editingNodeId) and its children. We can leave everything
  // else alone.
  // Note that the order of items in the tree array is not relevant, so we don't need to worry about it (phew).

  // First up, let's deal with the node we are directly editing.
  if (data.is_root) {
    // we special case the root because it's stored differently
    tree.root = {
      ...tree.root,
      name: data.name,
      prompt: data.prompt,
    };
  } else {
    const ourNodeIndex = tree.nodes.findIndex((x) => x.id === editingNodeId);
    if (ourNodeIndex === -1) {
      throw new Error(
        "unreachable: we are editing a node that isnt in the tree.",
      );
    }
    tree.nodes[ourNodeIndex] = {
      ...tree.nodes[ourNodeIndex],
      name: data.name,
      prompt: data.prompt,
    };
  }

  // now, let's go through our options and either update or create them.
  data.options.forEach((opt) => {
    const existingChildNodeIndex = tree.nodes.findIndex((x) => x.id === opt.id);
    if (existingChildNodeIndex >= 0) {
      tree.nodes[existingChildNodeIndex] = {
        ...tree.nodes[existingChildNodeIndex],
        option_label: opt.value,
        sort_order: opt.sort_key,
      };
    } else {
      // It's new! lets create a new node.
      tree.nodes.push({
        name: "",
        prompt: "",
        parent_id: editingNodeId,
        id: opt.id,
        option_label: opt.value,
        sort_order: opt.sort_key,
      });
    }
  });

  // What if we deleted a node, I hear you ask?
  const existingChildNodes = tree.nodes.filter(
    (x) => x.parent_id === editingNodeId,
  );

  const newChildIds = data.options.map((x) => x.id);

  existingChildNodes.forEach((node) => {
    if (!newChildIds.includes(node.id)) {
      // find the deleted node, and remove it
      const childNodeToDeleteIndex = tree.nodes.findIndex(
        (x) => x.id === node.id,
      );
      if (childNodeToDeleteIndex === -1) {
        throw new Error("we want to delete something that doesn't exist?");
      }
      tree.nodes.splice(childNodeToDeleteIndex, 1);
    }
  });

  return tree;
};

export const EditNodeModal = ({
  editingNodeId,
  decisionTree,
  onClose,
  onSave,
}: {
  editingNodeId: number;
  decisionTree: DecisionTree;
  onClose: () => void;
  onSave: (data: DecisionTree) => void;
}): React.ReactElement => {
  const [initialValues, setInitialValues] = useState<EditNodeFormData | null>(
    null,
  );

  useEffect(() => {
    const initialValues = extractDefaultValues(decisionTree, editingNodeId);
    setInitialValues(initialValues);
  }, [decisionTree, editingNodeId]);

  if (!initialValues) {
    return <Loader />;
  }

  return (
    <EditNodeModalContent
      editingNodeId={editingNodeId}
      decisionTree={decisionTree}
      onClose={onClose}
      onSave={onSave}
      initialValues={initialValues}
    />
  );
};

// TODO:add validation
const EditNodeModalContent = ({
  initialValues,
  editingNodeId,
  decisionTree,
  onClose,
  onSave: onSaveCallback,
}: {
  initialValues: EditNodeFormData;
  editingNodeId: number;
  decisionTree: DecisionTree;
  onClose: () => void;
  onSave: (data: DecisionTree) => void;
}): React.ReactElement => {
  // Note that we are viewing a node and some aspects of its children (the option labels).
  // This is because the way we render nodes in the UI doesn't map nicely with our
  // backend storage.

  // field arrays in react-hook-form behave weirdly, and I don't have time to figure them out.
  // So we're handling those outside of the form library for now. We should use the library
  // properly if we can - shouldn't be that difficult tbh.
  const formMethods = useForm<EditNodeFormData>({
    defaultValues: {
      ..._.pick(initialValues, "name", "prompt", "is_root"),
      options: initialValues.options.sort((a, b) =>
        a.sort_key > b.sort_key ? 1 : -1,
      ),
    },
  });
  const { setError } = formMethods;

  const onSave = (data: EditNodeFormData) => {
    // our form does some validation for us (prompt and name are required), but react-hook-form
    // doesn't know about the listeditor so we do some validations ourselves
    if (data.options.filter((x) => !x.value).length > 0) {
      return setError("options", {
        type: "manual",
        message: "Options cannot be blank",
      });
    }
    if (data.options.filter((x) => x.value.length > 75).length > 0) {
      return setError("options", {
        type: "manual",
        message: "Options cannot be longer than 75 characters",
      });
    }
    if (
      new Set(data.options.map((x) => x.value)).size !== data.options.length
    ) {
      return setError("options", {
        type: "manual",
        message: "Each option must be unique",
      });
    }
    if (data.options.length === 1) {
      return setError("options", {
        type: "manual",
        message:
          "There must be at least 2 options so the user can make a decision",
      });
    }

    const updatedTree = mapDataToTree(data, editingNodeId, decisionTree);
    onSaveCallback(updatedTree);
    return undefined;
  };

  return (
    <FormModalV2
      onSubmit={onSave}
      formMethods={formMethods}
      analyticsTrackingId="decision-flow-edit-node"
      title="Edit Node"
      onClose={onClose}
      footer={
        <ModalFooter
          confirmButtonText="Continue"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
      // TODO: actually work out if the form is dirty
    >
      {/* Name */}
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        helptext={
          "This is displayed in the configuration UI to help navigate the decision tree. Keep it short and sweet!"
        }
        required="Please provide a name"
        rules={{
          maxLength: {
            value: 75,
            message: "Name must be less than 75 characters",
          },
          minLength: {
            value: 1,
            message: "Name must be at least 1 character",
          },
        }}
      />
      {/* Prompt */}
      <TextareaV2
        formMethods={formMethods}
        name="prompt"
        label="Prompt"
        required="Please provide a prompt"
        rows={4}
        helptext={
          <span>
            This will be shown to the user when they are going through the
            decision flow. You can use Slack{" "}
            <a
              href="https://api.slack.com/reference/surfaces/formatting#basics"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              mrkdwn
            </a>{" "}
            in this field.
          </span>
        }
      />
      {/* Options */}
      <ListEditorV2
        formMethods={formMethods}
        name="options"
        label="Options"
        helptext={`Each of these is a path that a user can follow from this question. If you don’t have any options, the prompt above will be shown as the result.`}
        showErrorAboveComponent
        rowPlaceholder="What options can the user select?"
        onClearErrors={() => undefined}
        getDefaultRow={() => ({
          id: getRandomInt32(),
          value: "",
          sort_key: 10000,
        })}
        addNewText="Add new option"
      />
    </FormModalV2>
  );
};
