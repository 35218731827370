/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationNotificationTransitions,
    EscalationNotificationTransitionsFromJSON,
    EscalationNotificationTransitionsFromJSONTyped,
    EscalationNotificationTransitionsToJSON,
} from './EscalationNotificationTransitions';

/**
 * 
 * @export
 * @interface EscalationNotification
 */
export interface EscalationNotification {
    /**
     * 
     * @type {Date}
     * @memberof EscalationNotification
     */
    created_at: Date;
    /**
     * Unique identifier of the parent escalation target
     * @type {string}
     * @memberof EscalationNotification
     */
    escalation_target_id: string;
    /**
     * Unique internal ID of the escalation transition
     * @type {string}
     * @memberof EscalationNotification
     */
    id: string;
    /**
     * The type of this notification
     * @type {string}
     * @memberof EscalationNotification
     */
    notification_type: EscalationNotificationNotificationTypeEnum;
    /**
     * 
     * @type {Array<EscalationNotificationTransitions>}
     * @memberof EscalationNotification
     */
    transitions: Array<EscalationNotificationTransitions>;
    /**
     * 
     * @type {Date}
     * @memberof EscalationNotification
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum EscalationNotificationNotificationTypeEnum {
    Slack = 'slack',
    Email = 'email',
    App = 'app',
    Sms = 'sms',
    Phone = 'phone',
    Dashboard = 'dashboard',
    SlackChannel = 'slack_channel',
    MicrosoftTeams = 'microsoft_teams'
}

export function EscalationNotificationFromJSON(json: any): EscalationNotification {
    return EscalationNotificationFromJSONTyped(json, false);
}

export function EscalationNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'escalation_target_id': json['escalation_target_id'],
        'id': json['id'],
        'notification_type': json['notification_type'],
        'transitions': ((json['transitions'] as Array<any>).map(EscalationNotificationTransitionsFromJSON)),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function EscalationNotificationToJSON(value?: EscalationNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'escalation_target_id': value.escalation_target_id,
        'id': value.id,
        'notification_type': value.notification_type,
        'transitions': ((value.transitions as Array<any>).map(EscalationNotificationTransitionsToJSON)),
        'updated_at': (value.updated_at.toISOString()),
    };
}

