import { IconEnum } from "@incident-ui";
import React from "react";
import { TimelineItemAtlassianStatuspageUpdate } from "src/components/timeline/timeline-items/TimelineItemAtlassianStatuspageUpdate";
import { TimelineItemClosed } from "src/components/timeline/timeline-items/TimelineItemClosed";
import { TimelineItemCustomFieldValueUpdate } from "src/components/timeline/timeline-items/TimelineItemCustomFieldValueUpdate";
import { TimelineItemEscalate } from "src/components/timeline/timeline-items/TimelineItemEscalate";
import { TimelineItemIncidentAttachmentAdded } from "src/components/timeline/timeline-items/TimelineItemIncidentAttachmentAdded";
import { TimelineItemIncidentAttachmentRemoved } from "src/components/timeline/timeline-items/TimelineItemIncidentAttachmentRemoved";
import { TimelineItemIncidentMembershipRevoked } from "src/components/timeline/timeline-items/TimelineItemIncidentMembershipRevoked";
import { TimelineItemIncidentRename } from "src/components/timeline/timeline-items/TimelineItemIncidentRename";
import { TimelineItemPagerdutyIncidentAcknowledged } from "src/components/timeline/timeline-items/TimelineItemPagerdutyIncidentAcknowledged";
import { TimelineItemPagerdutyIncidentResolved } from "src/components/timeline/timeline-items/TimelineItemPagerdutyIncidentResolved";
import { TimelineItemPagerdutyIncidentTriggered } from "src/components/timeline/timeline-items/TimelineItemPagerdutyIncidentTriggered";
import { TimelineItemReport } from "src/components/timeline/timeline-items/TimelineItemReport";
import { TimelineItemRoleUpdate } from "src/components/timeline/timeline-items/TimelineItemRoleUpdate";
import { TimelineItemSeverityUpdate } from "src/components/timeline/timeline-items/TimelineItemSeverityUpdate";
import { TimelineItemSlackImage } from "src/components/timeline/timeline-items/TimelineItemSlackImage";
import { TimelineItemSlackInferGithub } from "src/components/timeline/timeline-items/TimelineItemSlackInferGithub";
import { TimelineItemSlackInferSentry } from "src/components/timeline/timeline-items/TimelineItemSlackInferSentry";
import { TimelineItemSlackPin } from "src/components/timeline/timeline-items/TimelineItemSlackPin";
import { TimelineItemStatusChange } from "src/components/timeline/timeline-items/TimelineItemStatusChange";
import { TimelineItemStatusPageIncidentLinked } from "src/components/timeline/timeline-items/TimelineItemStatusPageIncidentLinked";
import { TimelineItemStatusPageIncidentUpdated } from "src/components/timeline/timeline-items/TimelineItemStatusPageIncidentUpdated";
import { TimelineItemSummaryUpdate } from "src/components/timeline/timeline-items/TimelineItemSummaryUpdate";
import { TimelineItemVisibilityChanged } from "src/components/timeline/timeline-items/TimelineItemVisibilityChanged";
import {
  Incident,
  TimelineItemItemTypeEnum,
  TimelineItemItemTypeEnum as TimelineItemType,
  TimelineItemObject,
} from "src/contexts/ClientContext";

import { TimelineItemActionCreated } from "./TimelineItemActionCreated";
import { TimelineItemActionUpdated } from "./TimelineItemActionUpdated";
import { TimelineItemCustomEvent } from "./TimelineItemCustomEvent";
import { TimelineItemEscalationCreated } from "./TimelineItemEscalationCreated";
import { TimelineItemFollowUpCreated } from "./TimelineItemFollowUpCreated";
import { TimelineItemFollowUpUpdated } from "./TimelineItemFollowUpUpdated";
import { TimelineItemIncidentCallEnded } from "./TimelineItemIncidentCallEnded";
import { TimelineItemIncidentCallStarted } from "./TimelineItemIncidentCallStarted";
import { TimelineItemIncidentTimestampSet } from "./TimelineItemIncidentTimestampSet";
import { TimelineItemIncidentTypeChanged } from "./TimelineItemIncidentTypeChanged";
import { TimelineItemIncidentUpdateComponent } from "./TimelineItemIncidentUpdateComponent";
import { TimelineItemMerged } from "./TimelineItemMerged";

export type TimelineItemRenderProps = {
  description: React.ReactNode;
  icon: IconEnum;
  children?: React.ReactNode;
  avatarUrl?: string;
  secondaryAvatarUrl?: string;
  timestamp?: Date;
  isStatusUpdate?: boolean;
};

export type TimelineItemConstructor = (
  incident: Incident,
  item: TimelineItemObject,
  zoomImageSource: string | undefined,
  setZoomImageSource: React.Dispatch<React.SetStateAction<string | undefined>>,
) => TimelineItemRenderProps | null;

export const TimelineItemTypeToComponentMap: {
  [key in TimelineItemType]: TimelineItemConstructor | null;
} = {
  [TimelineItemType.CustomEvent]: TimelineItemCustomEvent,
  [TimelineItemType.TimelineNote]: () => null,
  [TimelineItemType.Scrub]: () => null,
  [TimelineItemType.Closed]: TimelineItemClosed,
  [TimelineItemType.Report]: TimelineItemReport,
  [TimelineItemType.RoleUpdate]: TimelineItemRoleUpdate,
  [TimelineItemType.SeverityUpdate]: TimelineItemSeverityUpdate,
  [TimelineItemType.SlackInferGithub]: TimelineItemSlackInferGithub,
  [TimelineItemType.SlackInferSentry]: TimelineItemSlackInferSentry,
  [TimelineItemType.SlackPin]: TimelineItemSlackPin,
  [TimelineItemType.SummaryUpdate]: TimelineItemSummaryUpdate,
  [TimelineItemType.IncidentEscalate]: TimelineItemEscalate,
  [TimelineItemType.ActionCreated]: TimelineItemActionCreated,
  [TimelineItemType.ActionUpdated]: TimelineItemActionUpdated,
  [TimelineItemType.FollowUpCreated]: TimelineItemFollowUpCreated,
  [TimelineItemType.FollowUpUpdated]: TimelineItemFollowUpUpdated,
  [TimelineItemType.StatusChange]: TimelineItemStatusChange,
  [TimelineItemType.AtlassianStatuspageUpdate]:
    TimelineItemAtlassianStatuspageUpdate,
  [TimelineItemType.IncidentUpdate]: TimelineItemIncidentUpdateComponent,
  [TimelineItemItemTypeEnum.Handover]: null, // We only show handover when rolled-up, so this is never used
  [TimelineItemType.CustomFieldValueUpdate]: TimelineItemCustomFieldValueUpdate,
  [TimelineItemType.IncidentMembershipRevoked]:
    TimelineItemIncidentMembershipRevoked,
  [TimelineItemType.IncidentVisibilityChanged]: TimelineItemVisibilityChanged,
  [TimelineItemType.SlackImage]: TimelineItemSlackImage,
  [TimelineItemType.IncidentRename]: TimelineItemIncidentRename,
  [TimelineItemType.IncidentAttachmentAdded]:
    TimelineItemIncidentAttachmentAdded,
  [TimelineItemType.IncidentAttachmentRemoved]:
    TimelineItemIncidentAttachmentRemoved,
  [TimelineItemType.PagerdutyIncidentTriggered]:
    TimelineItemPagerdutyIncidentTriggered,
  [TimelineItemType.PagerdutyIncidentAcknowledged]:
    TimelineItemPagerdutyIncidentAcknowledged,
  [TimelineItemType.PagerdutyIncidentResolved]:
    TimelineItemPagerdutyIncidentResolved,
  [TimelineItemType.IncidentTimestampSet]: TimelineItemIncidentTimestampSet,
  [TimelineItemType.IncidentMerged]: TimelineItemMerged,
  [TimelineItemType.IncidentTypeChanged]: TimelineItemIncidentTypeChanged,
  [TimelineItemType.StatusPageIncidentLinked]:
    TimelineItemStatusPageIncidentLinked,
  [TimelineItemType.StatusPageIncidentUpdated]:
    TimelineItemStatusPageIncidentUpdated,
  [TimelineItemType.EscalationCreated]: TimelineItemEscalationCreated,
  [TimelineItemItemTypeEnum.IncidentCallStarted]:
    TimelineItemIncidentCallStarted,
  [TimelineItemItemTypeEnum.IncidentCallEnded]: TimelineItemIncidentCallEnded,
};
