import { AlertSourceSuggestedAttribute, EngineScope } from "@incident-io/api";
import { RainbowContainer } from "@incident-shared/aisuggestions/RainbowContainer";
import { getVariableScope } from "@incident-shared/engine";
import { ViewExpression } from "@incident-shared/engine/expressions/ViewExpression";
import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconEnum,
  IconSize,
  Loader,
} from "@incident-ui";
import { useFormContext } from "react-hook-form";
import {
  AssistantAvatar,
  AssistantAvatarBackground,
} from "src/components/insights/assistant/AssistantAvatar";
import { useAllResources } from "src/hooks/useResources";
import { v4 } from "uuid";

import { AlertSourceConfigureFormData } from "./AlertSourceConfigurePage";

export type AlertSourceSuggestionProps = {
  suggestion: AlertSourceSuggestedAttribute;
  scope: EngineScope;
  onAdd?: () => void;
  onRemove?: () => void;
};

export const AlertSourceSuggestion = (props: AlertSourceSuggestionProps) => {
  const { resources, resourcesLoading, resourcesError } = useAllResources();
  const { setValue, watch } = useFormContext<AlertSourceConfigureFormData>();

  if (resourcesLoading) {
    return <Loader />;
  }

  if (resourcesError) {
    return null;
  }

  if (
    !props.suggestion ||
    !props.suggestion.attribute_payload ||
    !props.suggestion.attribute_expression
  ) {
    return null;
  }

  const attributes = watch("attributes");
  const expressions = watch("template.expressions");

  return (
    <RainbowContainer
      key={props.suggestion.attribute_payload.type}
      className="border border-purple-100 rounded-lg bg-clip-border overflow-hidden"
    >
      <div className={"flex flex-col gap-4 p-4"}>
        {/* Suggested Team attribute */}
        <div className="flex items-center gap-2">
          <AssistantAvatar
            size={IconSize.Small}
            background={AssistantAvatarBackground.Purple}
            rounded={false}
          />
          <div className="flex text-sm text-content-secondary">
            <span className="ml-2">Suggested attribute for&nbsp;</span>
            <span className="text-content-primary font-medium">
              {props.suggestion.attribute_payload.name}
            </span>
          </div>
        </div>

        <ViewExpression
          expression={props.suggestion.attribute_expression}
          scope={props.scope}
          footer={
            (props.suggestion.attribute_values || []).length === 0 ? (
              <div className="text-sm text-content-secondary">
                No matches found in recent alerts
              </div>
            ) : (
              <div className={"flex items-center gap-1"}>
                {props.suggestion.attribute_values
                  ?.slice(0, 3)
                  .map((value, idx) => (
                    <EngineBinding
                      key={idx}
                      className={"flex flex-row"}
                      variableScope={getVariableScope(props.scope, resources)}
                      binding={{ value: value }}
                      resourceType={props.suggestion.attribute_payload.type}
                      displayExpressionAs="pill"
                    />
                  ))}
                <div className="text-sm text-content-secondary">
                  {props.suggestion.attribute_values &&
                    props.suggestion.attribute_values.length > 3 &&
                    `+${props.suggestion.attribute_values.length - 3} more`}
                  found in alerts
                </div>
              </div>
            )
          }
        />

        <div className="flex gap-3">
          <Button
            analyticsTrackingId="accept-suggestion"
            onClick={() => {
              const ulid = v4();
              setValue<`template.bindings.${string}.value`>(
                `template.bindings.${ulid}.value`,
                {
                  ...props.suggestion.attribute_binding.value,
                },
              );
              setValue<`attributes`>(`attributes`, [
                {
                  array: false,
                  id: ulid,
                  name: props.suggestion.attribute_payload.name,
                  type: props.suggestion.attribute_payload.type,
                },
                ...attributes,
              ]);
              props.suggestion.attribute_expression
                ? setValue<`template.expressions`>(`template.expressions`, [
                    {
                      ...props.suggestion.attribute_expression,
                    },
                    ...expressions,
                  ])
                : null;
              if (props.onAdd) {
                props.onAdd();
              }
            }}
            theme={ButtonTheme.Primary}
            size={ButtonSize.Small}
            title="Add: you can edit the attribute before saving"
            icon={IconEnum.Tick}
          >
            Accept
          </Button>
          <Button
            analyticsTrackingId="reject-suggestion"
            onClick={() => {
              if (props.onRemove) {
                props.onRemove();
              }
            }}
            theme={ButtonTheme.Secondary}
            size={ButtonSize.Small}
            title="Skip: we'll remove the suggestion"
            icon={IconEnum.Close}
          >
            Skip
          </Button>
        </div>
      </div>
    </RainbowContainer>
  );
};
