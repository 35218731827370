/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemTemplateBlock
 */
export interface PostmortemTemplateBlock {
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemTemplateBlock
     */
    Content?: TextNode;
    /**
     * Reference to the type of block, either a standard block, or 'custom'.
     * @type {string}
     * @memberof PostmortemTemplateBlock
     */
    block_type: PostmortemTemplateBlockBlockTypeEnum;
    /**
     * Human readable label for the block, which will be null for custom blocks
     * @type {string}
     * @memberof PostmortemTemplateBlock
     */
    label?: string;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemTemplateBlockBlockTypeEnum {
    Custom = 'custom',
    Header = 'header',
    KeyInformation = 'key_information',
    Summary = 'summary',
    Timeline = 'timeline',
    Actions = 'actions',
    ActionLinks = 'action_links',
    Feedback = 'feedback'
}

export function PostmortemTemplateBlockFromJSON(json: any): PostmortemTemplateBlock {
    return PostmortemTemplateBlockFromJSONTyped(json, false);
}

export function PostmortemTemplateBlockFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemTemplateBlock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Content': !exists(json, 'Content') ? undefined : TextNodeFromJSON(json['Content']),
        'block_type': json['block_type'],
        'label': !exists(json, 'label') ? undefined : json['label'],
    };
}

export function PostmortemTemplateBlockToJSON(value?: PostmortemTemplateBlock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Content': TextNodeToJSON(value.Content),
        'block_type': value.block_type,
        'label': value.label,
    };
}

