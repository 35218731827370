import {
  Button,
  ButtonSize,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  LoadingModal,
  ModalFooter,
  Tooltip,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { orderBy } from "lodash";
import { useForm } from "react-hook-form";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "src/components/@shared/forms/Typeahead";
import { FormModalV2 } from "src/components/@shared/forms/v2/FormV2";
import { DynamicSingleSelectV2 } from "src/components/@shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "src/components/@shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "src/components/@shared/forms/v2/inputs/TextareaV2";
import { IntegrationConfigFor } from "src/components/@shared/integrations";
import {
  ActionStatusEnum as ActionStatus,
  FollowUp,
  FollowUpPriority,
  FollowUpsUpdateRequestBody,
  Identity,
  Settings,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { stripCharacterEntities } from "src/utils/formatting";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useRefetchFollowups } from "./use-refetch-followups";

type Props = {
  onClose: () => void;
  followUp: FollowUp;
  isPrivateIncident: boolean;
  refetchIncidents?: () => Promise<void>;
};

export const FollowUpEditModal = ({
  onClose,
  isPrivateIncident,
  followUp,
  refetchIncidents,
}: Props): React.ReactElement => {
  const { settings } = useSettings();
  const { identity } = useIdentity();

  const { data: prioOptsData, error: followUpPriorityOptionsError } = useAPI(
    "followUpsListPriorityOptions",
    undefined,
  );
  const followUpPriorityOptions = prioOptsData
    ? orderBy(prioOptsData.priority_options, (field) => field.rank, "desc")
    : null;

  if (followUpPriorityOptionsError) {
    return (
      <ErrorModal onClose={onClose} error={followUpPriorityOptionsError} />
    );
  }

  if (!followUpPriorityOptions || !identity || !settings) {
    return <LoadingModal title={`Edit follow-up`} onClose={onClose} />;
  }

  return (
    <FollowUpEditModalInner
      identity={identity}
      settings={settings}
      onClose={onClose}
      followUp={followUp}
      isPrivateIncident={isPrivateIncident}
      followUpPriorityOptions={followUpPriorityOptions}
      refetchIncidents={refetchIncidents}
    />
  );
};

function FollowUpEditModalInner({
  identity,
  settings,
  onClose,
  isPrivateIncident,
  followUp,
  followUpPriorityOptions,
  refetchIncidents,
}: Props & {
  identity: Identity;
  settings: Settings;
  followUpPriorityOptions: FollowUpPriority[];
}): React.ReactElement {
  const formMethods = useForm<FollowUpsUpdateRequestBody>({
    defaultValues: {
      ...(followUp as unknown as FollowUpsUpdateRequestBody),
      title: stripCharacterEntities(followUp.title),
      assignee_id: followUp?.assignee?.id,
      description: followUp.description
        ? stripCharacterEntities(followUp.description)
        : undefined,
      follow_up_priority_option_id: followUp.priority?.id,
    },
  });
  const { setError } = formMethods;

  const refetch = useRefetchFollowups();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    { incidentId: followUp.incident_id },
    async (apiClient, data: FollowUpsUpdateRequestBody) => {
      if (isExportedToIssueTracker) {
        await apiClient.followUpsExternalIssueSetPriority({
          id: followUp.id,
          externalIssueSetPriorityRequestBody: {
            follow_up_priority_option_id: data.follow_up_priority_option_id,
          },
        });
      } else {
        await apiClient.followUpsUpdate({
          id: followUp.id,
          updateRequestBody: {
            ...data,
            assignee_id: data.assignee_id === "" ? undefined : data.assignee_id,
          },
        });
      }
    },
    {
      setError,
      onSuccess: async () => {
        refetch();
        if (refetchIncidents) {
          refetchIncidents();
        }
        onClose();
      },
    },
  );

  const unlinkExternalIssueFormMethods = useForm();
  const { setError: setUnlinkExternalIssueError, handleSubmit } =
    unlinkExternalIssueFormMethods;

  const {
    trigger: unlinkExternalIssue,
    isMutating: unlinkingExternalIssue,
    genericError: unlinkingExternalIssueError,
  } = useAPIMutation(
    "followUpsList",
    { incidentId: followUp.incident_id },
    async (apiClient) => {
      await apiClient.followUpsDisconnectExternalIssue({
        id: followUp.id,
      });
    },
    { setError: setUnlinkExternalIssueError, onSuccess: onClose },
  );

  const isExportedToIssueTracker = !!followUp.external_issue_reference;

  const apiClient = useClient();

  return (
    <FormModalV2
      formMethods={formMethods}
      analyticsTrackingId={null}
      onClose={onClose}
      title={`Edit follow-up`}
      onSubmit={onSubmit}
      genericError={genericError || unlinkingExternalIssueError}
      footer={
        <ModalFooter
          saving={saving || unlinkingExternalIssue}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Save"
          disabled={
            identity.feature_gates.follow_up_priorities
              ? false
              : isExportedToIssueTracker
          }
        />
      }
    >
      {followUp.external_issue_reference ? (
        <div className="bg-surface-secondary rounded-2 w-full flex flex-row gap-2 p-4 text-sm justify-between items-center">
          <div className="flex flex-row gap-2 items-center">
            <div className="max-w-prose">
              Follow-up cannot be edited because we are syncing changes from{" "}
              {
                IntegrationConfigFor(
                  followUp.external_issue_reference?.provider,
                ).label
              }
              .
            </div>
            <Button
              analyticsTrackingId="external-issue-link"
              href={followUp.external_issue_reference.issue_permalink}
              openInNewTab
              size={ButtonSize.Small}
              theme={ButtonTheme.Secondary}
              icon={
                IntegrationConfigFor(followUp.external_issue_reference.provider)
                  .icon
              }
            >
              {followUp.external_issue_reference.issue_name}
            </Button>
          </div>

          <Tooltip content="Unlink from external issue">
            <Button
              analyticsTrackingId="button-unlink-follow-up-external-issue"
              theme={ButtonTheme.Naked}
              onClick={() => handleSubmit(unlinkExternalIssue)()}
              icon={IconEnum.LinkBreak}
              title="edit"
              size={ButtonSize.Medium}
              iconProps={{ className: "w-6 h-6" }}
            />
          </Tooltip>
        </div>
      ) : (
        <Callout theme={CalloutTheme.Info}>
          <p className="text-sm">
            Any changes you make here will be reflected back into the incident
            channel.
          </p>
        </Callout>
      )}
      <>
        <InputV2
          formMethods={formMethods}
          label="Title"
          disabled={isExportedToIssueTracker}
          autoFocus
          name="title"
          required="Please enter a title"
        />
        <TextareaV2
          formMethods={formMethods}
          label="Description"
          disabled={isExportedToIssueTracker}
          name="description"
          rows={6}
        />
        {/* Assignee */}
        <DynamicSingleSelectV2
          formMethods={formMethods}
          name="assignee_id"
          label="Owner"
          required={settings.misc.follow_up_owner_required}
          isClearable={!settings.misc.follow_up_owner_required}
          isDisabled={isExportedToIssueTracker}
          helptext={
            isPrivateIncident
              ? "As this is a private incident, you can only assign follow-ups to people with access."
              : undefined
          }
          placeholder="Select a user"
          loadOptions={getTypeaheadOptions(apiClient, TypeaheadTypeEnum.User, {
            incidentId: followUp?.incident_id,
          })}
          hydrateOptions={hydrateInitialSelectOptions(
            apiClient,
            TypeaheadTypeEnum.User,
          )}
        />

        {/* Status */}
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="status"
          label="Status"
          disabled={isExportedToIssueTracker}
          placeholder="Select status"
          options={[
            {
              label: "Open",
              value: ActionStatus.Outstanding,
            },
            {
              label: "Completed",
              value: ActionStatus.Completed,
            },
            {
              label: "Not doing",
              value: ActionStatus.NotDoing,
            },
          ]}
        />

        {/* Priority */}
        {identity.feature_gates.follow_up_priorities && (
          <>
            <StaticSingleSelectV2
              formMethods={formMethods}
              name={"follow_up_priority_option_id"}
              label={"Priority"}
              placeholder={"No priority"}
              options={followUpPriorityOptions.map(
                ({ id, name, is_default, description }) => ({
                  value: id,
                  label: is_default ? name + " (Default)" : name,
                  description,
                }),
              )}
              required={settings.misc.follow_up_priority_required}
              isClearable={!settings.misc.follow_up_priority_required}
              renderDescription="below"
            />
          </>
        )}
      </>
    </FormModalV2>
  );
}
