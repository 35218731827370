import { Mode } from "@incident-shared/forms/v2/formsv2";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { Route, Routes } from "react-router";

import { AlertDetailsPage } from "./alert-details/AlertDetails";
import { AlertRouteCreatePage } from "./alert-route-create-edit/AlertRouteCreatePage";
import { AlertRouteEditPage } from "./alert-route-create-edit/AlertRouteEditPage";
import { AlertRouteListPage } from "./alert-route-list/AlertRouteListPage";
import { AlertSourceSetupInfoPreviewPage } from "./alert-source-create-edit/alert-source-setup-info/AlertSourceSetupInfoPreviewPage";
import { AlertSourceCreateEditPage } from "./alert-source-create-edit/AlertSourceCreateEditPage";
import { AlertSourceCreatePage as AlertSourceCreatePageV2 } from "./alert-source-create-edit-v2/AlertSourceCreatePage";
import { AlertSourceEditPage as AlertSourceEditPageV2 } from "./alert-source-create-edit-v2/AlertSourceEditPage";
import { AlertsEmptyStatePage } from "./alert-source-list/AlertEmptyStatePage";
import { AlertSourceListPage } from "./alert-source-list/AlertSourceListPage";
import { AlertsListPage } from "./alerts-list/AlertsListPage";
import { AlertsPageWrapper } from "./AlertsPageWrapper";
import { AlertAttributesPage } from "./attributes/AlertAttributesPage";
import { AlertPrioritiesPage } from "./priorities/AlertPrioritiesPage";

export const AlertsRoute = () => {
  return (
    <Routes>
      {/* --- Full-size pages --- */}
      <Route path=":id/details" element={<AlertDetailsPage />} />

      {/* Legacy alert sources */}
      <Route
        path="sources/:id/edit"
        element={<AlertSourceCreateEditPage mode={Mode.Edit} />}
      />
      <Route
        path="sources/create/"
        element={<AlertSourceCreateEditPage mode={Mode.Create} />}
      />

      {/* Alert sources */}
      <>
        <Route path="sources-v2/:id/edit" element={<AlertSourceEditPageV2 />} />
        <Route
          path="sources-v2/create/"
          element={<AlertSourceCreatePageV2 />}
        />
      </>

      {/* Alert routes */}
      <>
        <Route path="routes/create" element={<AlertRouteCreatePage />} />
        <Route path="routes/:id/edit" element={<AlertRouteEditPage />} />
      </>

      <Route path="get-started" element={<AlertsEmptyStatePage />} />

      {/* --- Secondary nav pages --- */}

      <Route element={<AlertsPageWrapper />}>
        <Route path="" element={<OrgAwareNavigate to={"/alerts/recent"} />} />
        <Route path="recent" element={<AlertsListPage />} />
        <Route path="attributes" element={<AlertAttributesPage />} />
        <Route path="priorities" element={<AlertPrioritiesPage />} />
        <Route path="sources" element={<AlertSourceListPage />} />
        <Route path="routes" element={<AlertRouteListPage />} />
      </Route>

      <Route
        path="debug-preview-alert-source-setup"
        element={<AlertSourceSetupInfoPreviewPage />}
      />
      <Route path="*" element={<OrgAwareNavigate to="/404" replace />} />
    </Routes>
  );
};
