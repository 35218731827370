import {
  BillingCreateCheckoutSessionRequestBody,
  BillingCreateCheckoutSessionRequestBodyBillingIntervalEnum as BillingIntervalEnum,
  Plan,
} from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import {
  Badge,
  BadgeTheme,
  Callout,
  CalloutTheme,
  ModalFooter,
} from "@incident-ui";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { PricingMaths } from "./PricingMaths";

export type PurchaseFormData = {
  billing_interval: BillingIntervalEnum;
};

export const BillingSeatsEditModal = ({
  plan,
  currentResponderCount,
  currentOnCallCount,
  onBuyPlan,
  onClose,
}: {
  plan: Plan;
  currentResponderCount: number;
  currentOnCallCount: number;
  onBuyPlan: (data: BillingCreateCheckoutSessionRequestBody) => Promise<void>;
  onClose: () => void;
}): React.ReactElement | null => {
  const formMethods = useForm<PurchaseFormData>({
    mode: "onChange",
    defaultValues: {
      billing_interval: BillingIntervalEnum.Annual,
    },
  });
  const isAnnual =
    formMethods.watch("billing_interval") === BillingIntervalEnum.Annual;

  const onSubmit = (data: PurchaseFormData): void => {
    onBuyPlan({
      plan_name: plan.name,
      billing_interval: data.billing_interval,
    });
  };

  const wrappedOnClose = () => {
    formMethods.reset();
    onClose();
  };

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={wrappedOnClose}
      analyticsTrackingId="billing-choose-seats"
      title={`Upgrade to ${plan.label}`}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          onClose={onClose}
          confirmButtonText="Continue"
        />
      }
    >
      <BillingIntervalInput formMethods={formMethods} />
      <PricingMaths
        plan={plan}
        formMethods={formMethods}
        responderCount={currentResponderCount}
        onCallCount={currentOnCallCount}
      />
      <Callout theme={CalloutTheme.Info} className="mt-3">
        {isAnnual
          ? "You'll be billed automatically for any extra seats you add throughout the year."
          : "You'll be billed automatically for any extra seats you add each month."}
      </Callout>
    </FormModalV2>
  );
};

const BillingIntervalInput = ({
  formMethods,
}: {
  formMethods: UseFormReturn<PurchaseFormData>;
}) => {
  return (
    <RadioButtonGroupV2
      formMethods={formMethods}
      name="billing_interval"
      srLabel="Select annual or monthly billing"
      boxed
      horizontal
      options={[
        {
          label: (
            <div className="flex items-center">
              Annual billing
              <Badge className="ml-3" theme={BadgeTheme.Info}>
                -20%
              </Badge>
            </div>
          ),
          value: BillingIntervalEnum.Annual,
        },
        {
          label: <div className="flex items-center">Monthly billing</div>,
          value: BillingIntervalEnum.Monthly,
        },
      ]}
    />
  );
};
