import { useContext } from "react";
import { createContext } from "react";
import React from "react";
import { ArrayPath, FieldValues, UseFieldArrayReturn } from "react-hook-form";

import { ExpressionFormData } from "./expressionToPayload";

export type FormDataWithExpressions = {
  expressions?: ExpressionFormData[];
};

// ExpressionsFormMethods is a subset of the methods from react-hook-form's useFieldArray
// hook which we use to mutate the expressions array.
export type ExpressionsFormMethods<
  TFormData extends FormDataWithExpressions,
  TPath extends ArrayPath<TFormData>,
> = Pick<
  UseFieldArrayReturn<TFormData, TPath, "key">,
  "append" | "fields" | "update" | "remove"
>;

type ExpressionsMethodProviderContextT<
  TFormData extends FormDataWithExpressions,
  TPath extends ArrayPath<TFormData>,
> = {
  methods?: ExpressionsFormMethods<TFormData, TPath>;
  allowAllOfACatalogType?: boolean;
  showExpressionNames?: boolean;
  displayMini?: boolean;
};

const defaultValues = {
  methods: undefined,
  allowAllOfACatalogType: false,
  showExpressionNames: false,
  displayMini: false,
};

const ExpressionsMethodProviderContext =
  // I can't get the types to work here as the type parameter here
  // will depend on the parent provider. This is fine though as the
  // types elsewhere in this file should ensure that it is safe.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<ExpressionsMethodProviderContextT<any, any>>(defaultValues);

type ExpressionsMethodsProviderProps<
  TFormData extends FormDataWithExpressions,
  TPath extends ArrayPath<TFormData>,
> = {
  children: React.ReactNode;
  expressionsMethods?: ExpressionsFormMethods<TFormData, TPath>;
  allowAllOfACatalogType: boolean;
  showExpressionNames?: boolean;
  displayMini?: boolean;
};

// ExpressionsMethodsProvider allows us to pass the methods from react-hook-form's useFieldArray
// hook to avoid prop drilling to the InputOrVariable/ReferenceSelectorPopover components.
export const ExpressionsMethodsProvider = <
  TFormData extends FieldValues,
  TPath extends ArrayPath<TFormData>,
>(
  props: ExpressionsMethodsProviderProps<TFormData, TPath>,
): React.ReactElement => {
  const {
    children,
    expressionsMethods,
    allowAllOfACatalogType,
    showExpressionNames = false,
    displayMini = false,
  } = props;

  return (
    <ExpressionsMethodProviderContext.Provider
      value={{
        methods: expressionsMethods,
        allowAllOfACatalogType,
        showExpressionNames,
        displayMini,
      }}
    >
      {children}
    </ExpressionsMethodProviderContext.Provider>
  );
};

export const useExpressionsMethods = <
  TFormData extends FormDataWithExpressions,
  TPath extends ArrayPath<TFormData> & "expressions",
>(): ExpressionsMethodProviderContextT<TFormData, TPath> => {
  return useContext(ExpressionsMethodProviderContext);
};
