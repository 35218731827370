import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  MultiTextInput,
  TextEditorRow,
} from "@incident-shared/forms/v2/inputs/MultiTextInput";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { Callout, CalloutTheme, ModalFooter } from "@incident-ui";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { v4 } from "uuid";

import {
  AlertsCreatePriorityRequestBody,
  Priority,
} from "../../../contexts/ClientContext";
import { useAPIMutation } from "../../../utils/swr";

type PriorityFormData = Omit<AlertsCreatePriorityRequestBody, "slugs"> & {
  is_default?: boolean;
  slugs: TextEditorRow[];
};

export const PriorityCreateEditModal = ({
  mode,
  initialData,
  onClose,
}: CreateEditFormProps<Priority> & {
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<PriorityFormData>({
    defaultValues: initialData
      ? {
          ...initialData,
          slugs: initialData.slugs.map((slug) => ({ key: v4(), value: slug })),
        }
      : {
          name: "",
          slugs: [],
        },
  });
  const {
    setError,
    formState: { isDirty },
  } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "alertsListPriorities",
    {},
    async (apiClient, data: PriorityFormData) => {
      switch (mode) {
        case Mode.Create:
          await apiClient.alertsCreatePriority({
            createPriorityRequestBody: {
              ...data,
              slugs: data.slugs.map((slug) => slug.value),
            },
          });
          break;

        case Mode.Edit:
          if (!initialData) {
            throw new Error(
              "unreachable: form is in edit mode, but no Priority was provided",
            );
          }
          {
            await apiClient.alertsUpdatePriority({
              id: initialData.id,
              updatePriorityRequestBody: {
                ...data,
                slugs: data.slugs.map((slug) => slug.value),
              },
            });
            if (data.is_default) {
              await apiClient.alertsSetDefaultPriority({ id: initialData.id });
            }
          }
          break;
      }
    },
    { onSuccess: onClose, setError },
  );

  const isDefault = formMethods.watch("is_default");

  // We want to automatically update the slug to a sluggified version of the
  // name if the slug field is untouched. To do this, we subscribe to
  // changes in the name field and update the slug field accordingly.
  const name = formMethods.watch("name");
  const slugHasChanged = formMethods.getFieldState("slugs").isDirty;
  useEffect(() => {
    if (mode === Mode.Create && !slugHasChanged) {
      formMethods.setValue("slugs", [
        {
          key: v4(),
          value: name.toLowerCase().replace(/\s+/g, "-"),
        },
      ]);
    }
  }, [name, formMethods, mode, slugHasChanged]);

  return (
    <FormModalV2<PriorityFormData>
      analyticsTrackingId={
        mode === Mode.Create ? "create-priority" : "edit-priority"
      }
      title={mode === Mode.Create ? "Create priority" : "Edit priority"}
      disableQuickClose
      onSubmit={onSubmit}
      formMethods={formMethods}
      genericError={genericError}
      onClose={onClose}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          saving={saving}
          onClose={onClose}
          disabled={!isDirty}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        label="Name"
        name="name"
        required="Please provide a name"
        placeholder="P1"
      />
      <MultiTextInput
        name="slugs"
        placeholder="p1"
        disabled={saving}
        label={<div className="flex gap-1 mb-2">Aliases</div>}
        required={false}
      />
      {slugHasChanged && mode === Mode.Edit && (
        <Callout theme={CalloutTheme.Warning}>
          Changing the alias may break the resolution of your priority in
          existing alert sources.
        </Callout>
      )}
      <TemplatedTextInputV2
        formMethods={formMethods}
        includeVariables={false}
        includeExpressions={false}
        name="description"
        label="Description"
        helptext="How would you explain this priority to someone?"
        placeholder="Highest priority alerts that require immediate action from engineering"
        format="mrkdwn"
      />
      <CheckboxV2
        formMethods={formMethods}
        name="is_default"
        label="Set as default"
        disabled={isDefault ?? false}
      />
    </FormModalV2>
  );
};
