/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MSTeamsInfo
 */
export interface MSTeamsInfo {
    /**
     * Whether the bot can be auto-installed
     * @type {boolean}
     * @memberof MSTeamsInfo
     */
    auto_install_available: boolean;
    /**
     * The version of the bot installed in the Team
     * @type {string}
     * @memberof MSTeamsInfo
     */
    installed_bot_version?: string;
    /**
     * List of scopes missing from a customers Microsoft integration
     * @type {Array<string>}
     * @memberof MSTeamsInfo
     */
    missing_token_scopes: Array<string>;
    /**
     * A reason why the install failed, or has become broken.
     * @type {string}
     * @memberof MSTeamsInfo
     */
    reconnection_reason: MSTeamsInfoReconnectionReasonEnum;
    /**
     * The Team that incidents are run in
     * @type {string}
     * @memberof MSTeamsInfo
     */
    team_id?: string;
    /**
     * The current user's ID in Microsoft Teams
     * @type {string}
     * @memberof MSTeamsInfo
     */
    user_ms_teams_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum MSTeamsInfoReconnectionReasonEnum {
    Empty = '',
    TokenExpired = 'token_expired',
    MsTeamsInstallFailed = 'ms_teams_install_failed'
}

export function MSTeamsInfoFromJSON(json: any): MSTeamsInfo {
    return MSTeamsInfoFromJSONTyped(json, false);
}

export function MSTeamsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MSTeamsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_install_available': json['auto_install_available'],
        'installed_bot_version': !exists(json, 'installed_bot_version') ? undefined : json['installed_bot_version'],
        'missing_token_scopes': json['missing_token_scopes'],
        'reconnection_reason': json['reconnection_reason'],
        'team_id': !exists(json, 'team_id') ? undefined : json['team_id'],
        'user_ms_teams_id': !exists(json, 'user_ms_teams_id') ? undefined : json['user_ms_teams_id'],
    };
}

export function MSTeamsInfoToJSON(value?: MSTeamsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_install_available': value.auto_install_available,
        'installed_bot_version': value.installed_bot_version,
        'missing_token_scopes': value.missing_token_scopes,
        'reconnection_reason': value.reconnection_reason,
        'team_id': value.team_id,
        'user_ms_teams_id': value.user_ms_teams_id,
    };
}

