import {
  Alert,
  AlertAttributePayload,
  AlertSchema,
  AlertSourceConfig,
  CatalogResource,
  ErrorResponse,
} from "@incident-io/api";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  Icon,
  IconEnum,
  Loader,
  LoadingWrapper,
} from "@incident-ui";
import { ButtonGroup } from "@incident-ui/ButtonGroup/ButtonGroup";
import { JSONPreview } from "@incident-ui/JSONPreview/JSONPreview";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

import { AlertsTable } from "../../common/AlertsTable";
import { AlertPreview } from "./AlertPreview";

// Enum choosing between the different ways to view a preview.
export type PreviewView = "table" | "single-alert" | "payload";

// Shown on the right split of the alert source edit page, this component
// displays recent alerts in table and other formats to show how alerts would be
// parsed with existing configuration.
export const AlertsPreviewSplit = ({
  alerts,
  resources,
  existingSource,
  schema,
  isLoading,
  error,
  assignedAttributes,
}: {
  alerts: Alert[];
  resources: CatalogResource[];
  existingSource: AlertSourceConfig;
  schema: AlertSchema;
  isLoading: boolean;
  error?: ErrorResponse;
  assignedAttributes: AlertAttributePayload[];
}) => {
  // TODO: Unused right now, but we'll eventually switch between alerts nicely.
  const [alertId, setAlertId] = useState<string | null>(
    !isEmpty(alerts) ? alerts[0].id : null,
  );

  useEffect(() => {
    if (alertId == null && alerts.length > 0) {
      setAlertId(alerts[0].id);
    }
  }, [alerts, alertId, setAlertId]);

  const selectedAlert = alerts.find((alert) => alert.id === alertId);

  // By default show the table.
  const [previewView, setPreviewView] = useState<PreviewView>("table");

  return (
    <div
      className={
        // Borders
        "border-l border-stroke " +
        // Dotted background
        "bg-slate-50 bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] " +
        // Layout
        "px-8 py-6 overflow-auto flex flex-col gap-8 h-full w-full"
      }
    >
      {isLoading && alerts.length === 0 ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-col gap-4">
            <div className="flex flex-row w-full justify-between items-center gap-4">
              <div className="flex-grow">
                {previewView !== "table" && (
                  <DropdownMenu
                    triggerButton={
                      <Button
                        theme={ButtonTheme.Secondary}
                        className="h-8 text-sm-med"
                        analyticsTrackingId="alert-source-preview-select-alert"
                        size={ButtonSize.Medium}
                        icon={IconEnum.ChevronDown}
                        iconPosition="right"
                      >
                        {selectedAlert?.title || "Select alert"}
                      </Button>
                    }
                  >
                    {alerts.map((alert) => (
                      <DropdownMenuItem
                        key={alert.id}
                        label={alert.title}
                        onSelect={() => {
                          setAlertId(alert.id);
                        }}
                        analyticsTrackingId={`alert-source-preview-select-alert-${alert.id}`}
                      />
                    ))}
                  </DropdownMenu>
                )}
              </div>
              <div className="flex flex-row items-center gap-2">
                <p className="text-sm-med">View by</p>
                <ButtonGroup
                  analyticsTrackingId={"alert-source-preview-view"}
                  value={previewView}
                  className="border border-stroke rounded-2 h-8 flex flex-row"
                  buttonClassName="h-full flex items-center justify-center px-2"
                  onChange={(value) => setPreviewView(value as PreviewView)}
                  options={[
                    {
                      value: "table",
                      label: <Icon id={IconEnum.Table} />,
                    },
                    {
                      value: "single-alert",
                      label: <Icon id={IconEnum.List} />,
                    },
                    {
                      value: "payload",
                      label: <Icon id={IconEnum.Code} />,
                    },
                  ]}
                />
              </div>
            </div>
            <LoadingWrapper loading={isLoading}>
              {alerts.length > 0 ? (
                <div className={"w-full grow"}>
                  {previewView === "table" ? (
                    <AlertsTable
                      schema={schema}
                      resources={resources}
                      alertSourceConfigs={[existingSource]}
                      alerts={alerts}
                      allEntriesLoaded={true}
                      enableSelection={false}
                      // Only show the attributes that are assigned by this sources
                      visibleColumns={assignedAttributes.map((attribute) => {
                        return {
                          type: "attribute",
                          attribute: {
                            name: attribute.name,
                            type: attribute.type,
                            array: attribute.array,
                            id: attribute.id ?? "",
                          },
                        };
                      })}
                      maxNameWidth={"100%"}
                      minColumnWidth={"120px"}
                      wrappedInBox
                    />
                  ) : previewView === "single-alert" ? (
                    <AlertPreview
                      alert={selectedAlert || alerts[0]}
                      isLoading={isLoading}
                      resources={resources}
                      schema={schema}
                    />
                  ) : (
                    <JSONPreview
                      lightMode
                      payload={JSON.parse(
                        selectedAlert?.payload || '{json_preview: "payload"}',
                      )}
                    />
                  )}
                </div>
              ) : (
                <AlertsPreviewEmptyState item={"alert"} error={error} />
              )}
            </LoadingWrapper>
          </div>
        </>
      )}
    </div>
  );
};

const AlertsPreviewEmptyState = ({
  item,
  error,
}: {
  item: "alert" | "payload";
  error?: ErrorResponse;
}) => {
  return (
    <EmptyState
      className="bg-surface-secondary"
      icon={IconEnum.Alert}
      title={error ? "Preview unavailable" : `No ${item}s received yet`}
      content={
        error
          ? "We're having trouble getting your alert previews - contact us if this issue persists."
          : "Follow the steps in Connect to set up your alert source so that we can start receiving alerts."
      }
    />
  );
};
