import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import { INTEGRATION_CONFIGS } from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ButtonTheme, DropdownMenuItem, Icon, IconEnum } from "@incident-ui";
import { Popover } from "@incident-ui/Popover/Popover";
import React, { ForwardedRef, useState } from "react";
import { IssueTemplateContextEnum } from "src/components/settings/follow-ups/FollowUpExportSection";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { useAllIssueTemplates } from "./FollowUpExportSection";
import {
  createFollowUpTemplateURL,
  useEnabledExportIssueTrackers,
} from "./useEnabledExportIssueTrackers";

export const AddIssueTemplateButton = () => {
  const { installedTrackers } = useEnabledExportIssueTrackers();

  const navigate = useOrgAwareNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const onAdd =
    installedTrackers.length === 1
      ? () => navigate(createFollowUpTemplateURL(installedTrackers[0]))
      : () => setIsOpen(true);

  return (
    // Using a DropdownMenu here means that we can't control the open state
    // manually, and for some reason the way GatedButton works means that the
    // dropdown menu doesn't manage its own open state properly. Sad.
    <Popover
      trigger={<GatedAddIssueTemplateButton onAdd={onAdd} />}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {installedTrackers.map((provider) => {
        const config = INTEGRATION_CONFIGS[provider];

        return (
          <DropdownMenuItem
            key={provider}
            label={config.label}
            icon={config.icon}
            analyticsTrackingId={null}
            onSelect={() => navigate(createFollowUpTemplateURL(provider))}
          />
        );
      })}
    </Popover>
  );
};

const GatedAddIssueTemplateButton = React.forwardRef(
  (
    { onAdd }: { onAdd: () => void },
    ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    const { identity } = useIdentity();
    const { allTemplates } = useAllIssueTemplates();

    const { allTrackers, installedTrackers } = useEnabledExportIssueTrackers();

    const orgCanCreateIssueTemplates = !numericGateLimitReached(
      identity.feature_gates.follow_up_issue_templates_count,
      allTemplates.filter(
        (template) => template.context === IssueTemplateContextEnum.FollowUp,
      ).length,
    );

    const relevantIntegrationNames = allTrackers.map(
      (provider) => INTEGRATION_CONFIGS[provider].label,
    );

    return (
      <GatedButton
        ref={ref}
        disabled={installedTrackers.length === 0}
        disabledTooltipContent={
          <>
            To use this feature, you need to set up an integration with one of{" "}
            {joinSpansWithCommasAndConnectorWord(
              relevantIntegrationNames,
              "or",
            )}
            .
          </>
        }
        theme={ButtonTheme.Secondary}
        onClick={onAdd}
        analyticsTrackingId="add-follow-up-issue-tracker-template"
        icon={IconEnum.Add}
        requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        upgradeRequired={!orgCanCreateIssueTemplates}
        upgradeRequiredProps={{
          gate: {
            type: "numeric",
            value: identity?.feature_gates.follow_up_issue_templates_count,
            featureNameSingular: "Follow-up export template",
          },
          featureName: "Follow-up export templates",
        }}
      >
        Add new template
        {installedTrackers.length > 1 && <Icon id={IconEnum.ChevronDown} />}
      </GatedButton>
    );
  },
);
GatedAddIssueTemplateButton.displayName = "GatedAddIssueTemplateButton";
