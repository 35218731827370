import { Button, ButtonTheme } from "@incident-ui";
import _ from "lodash";
import { DateTime } from "luxon";
import { EscalationPathShift, Identity } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import appInstallQrCode from "./img/app-install-qr-clear.svg";
import styles from "./MainSidebar.module.scss";

// Show a small widget in the bottom left of the screen that indicates whether
// you're on call.
export const OnCallHelper = ({
  identity,
  needsSetup,
  isExpanded,
}: {
  identity: Identity;
  needsSetup: boolean;
  isExpanded: boolean;
}): React.ReactElement => {
  const { data: escalationPathsForUser } = useAPI(
    "escalationPathsListForUserV2",
    {
      userId: identity.user_id,
    },
    {
      fallbackData: { escalation_paths: [] },
    },
  );

  // Get all current/upcoming shifts for the user for each level
  const highUrgencyShifts = escalationPathsForUser.escalation_paths
    .flatMap((ep) => ep.levels)
    .filter((level) => level.next_shift_for_user !== undefined)
    .map((level) => level.next_shift_for_user as EscalationPathShift)
    .filter((shift) => shift.urgency === "high");

  let isOnCallForever = false;
  // Take all currently active shifts, and merge consecutive shifts to be represented
  // as a single shift.
  const [activeShifts, upcomingShifts] = _.partition(
    highUrgencyShifts,
    (shift) => {
      const now = DateTime.now();

      // The user is on indefinitely as they're referenced directly by an EP
      if (!shift.start_at && !shift.end_at && !shift.schedule_id) {
        isOnCallForever = true;
        return true;
      }

      return (
        shift.start_at &&
        now >= DateTime.fromJSDate(shift.start_at) &&
        (!shift.end_at || now <= DateTime.fromJSDate(shift.end_at))
      );
    },
  );

  const isOnCall = activeShifts.length > 0;

  const scheduleOnEscalationPath = upcomingShifts.some(
    (shift) => shift.schedule_id !== undefined,
  );

  const showYoureOnCall = isOnCall || !needsSetup;

  return (
    <Button
      theme={ButtonTheme.Unstyled}
      className={tcx(
        "transition",
        "flex items-center gap-2",
        "bg-slate-700 border-slate-800 border hover:border-slate-600 shadow-button-dark",
        "text-sm rounded-[6px] grow border-transparent",
        isExpanded ? "p-2" : "w-10 h-10 justify-center",
      )}
      analyticsTrackingId={"on-call-helper"}
      href={
        isOnCall
          ? "/on-call/schedules"
          : scheduleOnEscalationPath
          ? "/on-call/schedules"
          : "/on-call/escalation-paths"
      }
    >
      <div className="flex items-center justify-center gap-2">
        <div
          className={tcx(
            "transition-all",
            isExpanded
              ? "w-1 self-stretch rounded-0.5 bg-slate-700"
              : "w-2 h-2 rounded-full",
            isOnCall ? "bg-utility-success" : "bg-slate-200",
          )}
        />
        <div
          className={tcx(
            "flex flex-col gap-2",
            isExpanded
              ? "transition-all ease-in-out delay-200"
              : "opacity-0 absolute",
          )}
        >
          {showYoureOnCall && (
            <OnCallInfo
              tagline={isOnCall ? "You're on call" : "You're not on call"}
              details={
                isOnCallForever
                  ? "Indefinitely"
                  : nextCurrentShiftInfo({
                      activeShifts,
                      upcomingShifts,
                    })
              }
            />
          )}
          {needsSetup && showYoureOnCall && (
            <div className="border-t border-slate-600 grow w-full" />
          )}
          {needsSetup && (
            <>
              <div className="flex flex-row items-center gap-2">
                <span className="text-xs text-pretty">
                  Download the app and get started.
                </span>
                {isOnCall ? (
                  // This QR code has been generated with QRCode Monkey, and then updated with Pixelmator to remove the
                  // background. If you need to update it, might be worth speaking to Henry.
                  <img
                    src={appInstallQrCode}
                    className="w-9 h-7"
                    alt="The QR code for installing the incident.io app"
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </Button>
  );
};

export const OnCallInfo = ({
  tagline,
  details,
}: {
  tagline: React.ReactNode;
  details: React.ReactNode;
}) => (
  <div className={"flex flex-col text-left"}>
    <span className="text-content-invert text-sm-bold truncate">{tagline}</span>
    <span className={"text-content-tertiary text-xs font-medium truncate"}>
      {details}
    </span>
  </div>
);

const nextCurrentShiftInfo = ({
  activeShifts,
  upcomingShifts,
}: {
  activeShifts: EscalationPathShift[];
  upcomingShifts: EscalationPathShift[];
}) => {
  // Grab the current time so we can compare to see if shifts are starting/ending today.
  const now = DateTime.now();

  const isOnCall = activeShifts.length > 0;
  const [_currentShiftStart, currentShiftEnd] = stitchShifts(activeShifts);

  // Currently on call, so let's show when the shift ends.
  if (isOnCall && currentShiftEnd) {
    let dateString: string;

    if (now.hasSame(currentShiftEnd, "day")) {
      dateString = `today at ${currentShiftEnd.toLocaleString({
        hour: "numeric",
        hourCycle: "h12",
        minute: "numeric",
      })}`;
    } else {
      dateString = currentShiftEnd.toLocaleString({
        weekday: "short",
        day: "numeric",
        month: "short",
        hour: "numeric",
        hourCycle: "h12",
        minute: "numeric",
      });
    }

    return <span className={styles.shiftInfo}>{`Until ${dateString}`}</span>;
  }

  const hasUpcomingShift = upcomingShifts.length > 0;
  const [nextShiftStart, _nextShiftEnd] = stitchShifts(upcomingShifts);

  // Not on call, but have a shift coming up, so let's show when it starts.
  // nextShiftStart should always be defined, since an indefinite shift
  // is handled outside this component.
  if (hasUpcomingShift && nextShiftStart) {
    let dateString: string;

    if (now.hasSame(nextShiftStart, "day")) {
      dateString = `Today at ${nextShiftStart.toLocaleString({
        hour: "numeric",
        hourCycle: "h12",
        minute: "numeric",
      })}`;
    } else {
      dateString = nextShiftStart.toLocaleString({
        weekday: "short",
        day: "numeric",
        month: "short",
        hour: "numeric",
        hourCycle: "h12",
        minute: "numeric",
      });
    }

    return (
      <span className={styles.shiftInfo}>{`Next shift: ${dateString}`}</span>
    );
  }

  // No upcoming shifts.
  return <span className={styles.shiftInfo}>No upcoming shifts</span>;
};

// stitchShifts takes a list of shifts, stitches together consecutive shifts
// from the first in the list, and returns the start and end times of that
// shift. stitchEntries will always start from the first shift and try
// to find the longest consecutive period where a user is on call from that.
const stitchShifts = (
  shifts: EscalationPathShift[],
): [DateTime?, DateTime?] => {
  if (shifts.length === 0) {
    return [DateTime.now(), DateTime.now()];
  }

  if (shifts.length === 1) {
    return [
      shifts[0].start_at ? DateTime.fromJSDate(shifts[0].start_at) : undefined,
      shifts[0].end_at ? DateTime.fromJSDate(shifts[0].end_at) : undefined,
    ];
  }

  const sortedShifts = _.sortBy(shifts, "start_at");

  const currentShift = sortedShifts[0];
  let currentShiftEnd = currentShift.end_at;

  for (let i = 1; i < sortedShifts.length; i++) {
    const nextShift = sortedShifts[i];
    // If we have an indefinite shift, we'll return that, as you can't
    // have a longer shift than that.
    if (currentShiftEnd === undefined) {
      break;
    }

    if (nextShift.start_at && nextShift.start_at <= currentShiftEnd) {
      currentShiftEnd = nextShift.end_at;
    } else {
      break;
    }
  }

  return [
    currentShift.start_at
      ? DateTime.fromJSDate(currentShift.start_at)
      : undefined,
    currentShiftEnd ? DateTime.fromJSDate(currentShiftEnd) : undefined,
  ];
};
