import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { Loader, Modal } from "@incident-ui";
import { useParams } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";

import { PayConfigCreateEditForm } from "../../../components/legacy/on-call-legacy/pay-configurations/PayConfigCreateEditForm";
import { SchedulesCreatePayConfigRequestBody } from "../../../contexts/ClientContext";
import { useAPI, useAPIMutation } from "../../../utils/swr";

export const OnCallPayConfigsEditRoute = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const { identity } = useIdentity();
  const backHref = "/on-call/pay-calculator/pay-configurations";
  const { id: configID } = useParams<{ id: string }>() as { id: string };

  const onClose = () => {
    navigate(backHref);
  };

  const { data: config, error: configError } = useAPI(
    "schedulesShowPayConfig",
    { id: configID },
  );
  if (configError) {
    throw configError;
  }

  const {
    trigger: updatePayConfig,
    isMutating: savingPayConfig,
    genericError: payConfigError,
  } = useAPIMutation(
    "schedulesShowPayConfig",
    { id: configID },
    async (apiClient, data: SchedulesCreatePayConfigRequestBody) =>
      await apiClient.schedulesUpdatePayConfig({
        id: configID,
        updatePayConfigRequestBody: data,
      }),
    {
      onSuccess: onClose,
    },
  );

  if (!identity) {
    return <Loader />;
  }

  if (!identity.feature_gates.on_call_calculator) {
    return <OrgAwareNavigate to="/on-call/pay-calculator" replace />;
  }

  return (
    <Modal
      analyticsTrackingId="edit-pay-configuration"
      title="Update pay configuration"
      onClose={onClose}
      isOpen
      disableQuickClose
      isExtraLarge
    >
      <ErrorMessageUI message={payConfigError} />
      {!config ? (
        <Loader />
      ) : (
        <PayConfigCreateEditForm
          initialConfig={config}
          mode={Mode.Edit}
          continueButtonText="Save"
          onSubmit={updatePayConfig}
          onClose={() => navigate(backHref)}
          saving={savingPayConfig}
        />
      )}
    </Modal>
  );
};
