import { Icon, IconEnum, IconSize, Link } from "@incident-ui";
import _ from "lodash";
import {
  ExternalPagerDutyIncident,
  ExternalResource,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { AttachmentContexts } from "./IncidentAttachment";

export const PagerDutyIncident = ({
  className,
  resource,
  pdInc,
  context,
  greyscale,
}: {
  className: string | undefined;
  resource: ExternalResource;
  pdInc: ExternalPagerDutyIncident;
  context: AttachmentContexts;
  greyscale?: boolean;
}): React.ReactElement => {
  return (
    <div className={tcx("flex", className)}>
      <Icon
        id={IconEnum.Pagerduty}
        size={IconSize.Medium}
        className={tcx(
          "text-content-tertiary mr-6 shrink-0 font-semibold mt-1",
          {
            grayscale: greyscale,
          },
        )}
      />
      <div>
        <div className="font-medium mb-2">
          <Link
            href={resource.permalink}
            openInNewTab
            className={tcx({ "!text-content-tertiary": greyscale })}
            analyticsTrackingId={null}
          >
            #{pdInc.incident_number}: {pdInc.title}
          </Link>
        </div>
        <div
          className={tcx(
            "flex space-x-2",
            greyscale ? "text-content-tertiary" : "text-slate-700",
          )}
        >
          <span className="flex-center-y">
            <Icon
              id={IconEnum.Severity}
              className="text-content-tertiary mr-1"
            />
            <span>{_.startCase(pdInc.urgency)}</span>
          </span>
          {context === AttachmentContexts.RealTime ? (
            <span className="flex-center-y">
              <Icon
                id={IconEnum.Status}
                className="text-content-tertiary mr-1"
              />
              <span>{_.startCase(pdInc.status)}</span>
            </span>
          ) : null}
          <span className="flex-center-y">
            <Icon id={IconEnum.Server} className="text-content-tertiary mr-1" />
            <span>{pdInc.service}</span>
          </span>
        </div>
      </div>
    </div>
  );
};
