/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IssueTrackersAsanaCreateIssueRequestBody
 */
export interface IssueTrackersAsanaCreateIssueRequestBody {
    /**
     * Asana user ID to assign to the issue
     * @type {string}
     * @memberof IssueTrackersAsanaCreateIssueRequestBody
     */
    assignee_id?: string;
    /**
     * Description body of the Asana issue
     * @type {string}
     * @memberof IssueTrackersAsanaCreateIssueRequestBody
     */
    description?: string;
    /**
     * ID of the follow-up that we're exporting this issue from
     * @type {string}
     * @memberof IssueTrackersAsanaCreateIssueRequestBody
     */
    follow_up_id: string;
    /**
     * The Asana team to create the issue to
     * @type {string}
     * @memberof IssueTrackersAsanaCreateIssueRequestBody
     */
    project_id: string;
    /**
     * Title of the Asana issue
     * @type {string}
     * @memberof IssueTrackersAsanaCreateIssueRequestBody
     */
    title: string;
}

export function IssueTrackersAsanaCreateIssueRequestBodyFromJSON(json: any): IssueTrackersAsanaCreateIssueRequestBody {
    return IssueTrackersAsanaCreateIssueRequestBodyFromJSONTyped(json, false);
}

export function IssueTrackersAsanaCreateIssueRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersAsanaCreateIssueRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee_id': !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'follow_up_id': json['follow_up_id'],
        'project_id': json['project_id'],
        'title': json['title'],
    };
}

export function IssueTrackersAsanaCreateIssueRequestBodyToJSON(value?: IssueTrackersAsanaCreateIssueRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_id': value.assignee_id,
        'description': value.description,
        'follow_up_id': value.follow_up_id,
        'project_id': value.project_id,
        'title': value.title,
    };
}

