import { UserWithRoles } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  isHolidayEntry,
  isHolidayPublicEntry,
  isScheduleEntry,
  isScheduleOverride,
  TimelineEntry,
} from "@incident-shared/schedules/ScheduleOverview/types";
import {
  ColorPalette,
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { useUniqueColorGenerator } from "@incident-shared/utils/uniqueColorContext";
import { Avatar } from "@incident-ui";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { closeTooltips, Tooltip } from "@incident-ui/Tooltip/Tooltip";
import { Txt } from "@incident-ui/Txt/Txt";
import { formatTimestampLocale } from "src/utils/datetime";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { sendWarningToSentry } from "src/utils/utils";

import { CopyDebugID } from "../../../../utils/ShowDebugIDProvider";
import stripeBackground from "./hatched.png";

type TooltipProps =
  | {
      enableTooltip: boolean;
      enableCreateOverride?: boolean;
      entries: TimelineEntry[];
      timezone: string;
    }
  | {
      enableTooltip?: never | false;
      entries?: never;
      timezone?: never;
      enableCreateOverride?: boolean; // you can only create overrides if you have a tooltip
    };

export const ScheduleEntryComponent = ({
  userId: userIdProp,
  user: userProp,
  width,
  className,
  roundedLeft = true,
  roundedRight = true,
  enableTooltip,
  isDraft,
  clipContent,
  entries,
  timezone,
  enableCreateOverride,
}: {
  className?: string;
  width?: string; // capturing the width separately and using it in styles as tailwind is not accepting it as a className always
  roundedLeft?: boolean;
  roundedRight?: boolean;
  enableTooltip?: boolean;
  clipContent?: boolean;
  isDraft?: boolean;
  // If user is provided, we'll use it, or we'll look-up the user, or we'll show no one on call
  user?: UserWithRoles;
  userId?: string;
} & TooltipProps) => {
  const navigate = useOrgAwareNavigate();

  const colorGenerator = useUniqueColorGenerator();

  // We just assume we've got _all_ ScheduleEntries or _all_ ScheduleOverrides,
  // not a mix of the two.
  const entry = entries && entries[0];

  const { data: userResp, isLoading } = useAPI(
    isHolidayPublicEntry(entry) || userIdProp === "NOBODY" || userProp
      ? null
      : "usersShow",
    {
      id: userIdProp ?? "",
    },
  );

  const Wrapper = enableCreateOverride ? Button : "div";

  // We might be displaying 'nobody' or a public holiday, so remember this user might never get set.
  const maybeUser = userProp ?? userResp?.user;

  let displayName: string;
  let colorPalette: ColorPalette;
  let avatarUrl: string | undefined;
  if (isHolidayEntry(entry)) {
    displayName = entry.name;
    if (isHolidayPublicEntry(entry)) {
      colorPalette = getColorPalette(ColorPaletteEnum.Slate);
    } else {
      avatarUrl = maybeUser?.avatar_url;
      colorPalette = colorGenerator(entry.user_id);
    }
  } else {
    if (userIdProp === "NOBODY") {
      colorPalette = getColorPalette(ColorPaletteEnum.Slate);
      displayName = "No one";
    } else {
      const userIsDeactivated = (maybeUser?.state as string) === "deactivated";

      displayName = userIsDeactivated
        ? maybeUser?.name + " (deactivated)"
        : maybeUser?.name ?? "";
      colorPalette = colorGenerator(maybeUser?.id ?? "");
      avatarUrl = maybeUser?.avatar_url;
    }
  }

  const { border, background, text, preview, hover, groupHover } = colorPalette;

  let overrideId: string | undefined;
  if (isScheduleEntry(entry)) {
    overrideId = entry.override_id;
  } else if (isScheduleOverride(entry)) {
    // 'user_id' is a discrimnator to work out if we've got a ScheduleEntry or a ScheduleOverride
    // if it's an override, then we'll have 'user_id'.
    overrideId = entry.id;
  } else {
    // Otherwise, it must be a holiday, there's no override to edit.
    overrideId = undefined;
  }

  return (
    <Tooltip
      key={`tooltip-${userIdProp}-${entries?.map((e) => e.start_at).join("-")}`}
      content={
        enableTooltip && entries?.length ? (
          <ScheduledShiftTooltip
            user={maybeUser}
            entries={entries}
            userId={userIdProp ?? maybeUser?.id}
            timezone={timezone || ""}
            enableCreateOverride={enableCreateOverride}
            overrideId={overrideId}
          />
        ) : null
      }
      delayDuration={250}
      light
      followMousePosition
      noMaxWidth
    >
      {/* Apply the width separately to the border, so that we don't include the border in width */}
      <Wrapper
        style={{ width, height: "28px" }}
        className={tcx("box-border relative !no-underline !border-0", {
          "hover:cursor-default": !enableCreateOverride,
        })}
        theme={ButtonTheme.Unstyled}
        analyticsTrackingId={"schedule-entry"}
        onClick={() => {
          if ((entries ?? []).length === 0) {
            return;
          }
          if (enableCreateOverride) {
            const entry = entries && entries[0];
            if (entry) {
              navigateToOverrideForEntry(navigate, entry);
            } else {
              sendWarningToSentry("No entry found for override creation");
            }
          }
        }}
      >
        <div
          className={tcx(
            background,
            border,
            `flex flex-row items-center overflow-hidden group min-h-[28px]`,
            {
              "border-r-0": !roundedRight,
              "border-l-0": !roundedLeft,
              "rounded-r-md": roundedRight,
              "rounded-l-md": roundedLeft,
              [hover]: enableTooltip,
              "border-dashed animate-pulse": isDraft,
              "absolute left-0 right-0 top-0 bottom-0": clipContent,
            },
            className,
          )}
        >
          {overrideId && (
            <div
              style={{
                opacity: 0.6,
                backgroundImage: `url(${stripeBackground})`,
                backgroundSize: "32px 32px",
                mixBlendMode: "color-burn",
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          )}

          <div className={"ml-2"} />

          {/* Placeholder spacing for the colour block,
              which is rendered on top to avoid fading
              from the gradient
           */}
          {!isHolidayEntry(entry) && (
            <div
              id={"placeholder"}
              className={"w-[4px] h-[12px] mr-2 my-2 shrink-0"}
            />
          )}

          {/* User avatar */}
          {!isHolidayEntry(entry) && (
            <div className={"!w-[16px] !h-[16px] mr-2 relative shrink-0"}>
              <Avatar
                size={IconSize.Small}
                url={avatarUrl}
                className={tcx({
                  "opacity-0": isLoading,
                })}
              />
              {/*Add a inside black border to the avatar at 10% opacity*/}
              <div
                className={tcx(
                  "absolute inset-0 rounded-full border border-black/10",
                  {
                    [groupHover]: enableTooltip,
                    transition: enableTooltip,
                    "opacity-0": isLoading,
                  },
                )}
              />
            </div>
          )}

          {/* User name */}
          <div className={tcx(`text-xs text-clip truncate mr-2`, text)}>
            {isLoading ? null : displayName}
          </div>

          {/* When we're clipping avatar/name, we overlay a fading out background to make it feel less abrupt */}
          {clipContent && (
            <div
              id={"fade-out"}
              className={tcx(`absolute right-0 top-0 bottom-0 w-2`, background)}
              style={{
                // mask image to fade out
                maskImage: `linear-gradient(to right, transparent, white)`,
              }}
            />
          )}

          {/* We render this absolutely here so it sits above the fade out gradient */}
          {!isHolidayEntry(entry) && (
            <div
              id={"colour-block-2"}
              className={tcx(
                `absolute w-[4px] h-[12px] rounded-full my-2 mx-2 shrink-0`,
                preview,
              )}
            />
          )}
        </div>
      </Wrapper>
    </Tooltip>
  );
};

type ScheduleEntryTooltipProps = {
  entries: TimelineEntry[];
  timezone: string;
  enableCreateOverride?: boolean;
  user: UserWithRoles | undefined;
  userId: string | undefined;
  overrideId: string | undefined;
};

const ScheduledShiftTooltip = ({
  entries,
  timezone,
  enableCreateOverride,
  user,
  userId,
  overrideId,
}: ScheduleEntryTooltipProps) => {
  const navigate = useOrgAwareNavigate();

  const isHoliday = isHolidayEntry(entries?.[0]);
  return (
    <div className={"flex flex-col space-y-3 py-1"}>
      <div className={"flex flex-row flex-wrap"}>
        <ScheduleEntryComponent
          user={user}
          userId={userId}
          timezone={timezone}
          entries={entries}
          enableTooltip={false}
        />
      </div>
      {!isHoliday ? (
        <div className={"flex flex-col"}>
          {entries.map((entry) => {
            const startTimeString = formatTimestampLocale({
              timestamp: entry.start_at,
              dateStyle: "short",
              timeStyle: "short",
              timeZone: timezone,
              addWeekday: true,
            });
            const endTimeString = formatTimestampLocale({
              timestamp: entry.end_at,
              dateStyle: "short",
              timeStyle: "short",
              timeZone: timezone,
              addWeekday: true,
            });

            const isOngoing = entry.end_at.getTime() > Date.now();

            // we might want to add a title here for each rotation, but I think it's unlikely that someone
            // is simultaneously on multiple rotations, so let's not just yet.
            return (
              <div
                key={entry.start_at.toISOString() + entry.end_at.toISOString()}
                className={"flex flex-col space-y-2 mb-4 last:mb-0"}
              >
                <div className={"space-y-1"}>
                  <CopyDebugID id={userId ?? user?.id} />
                  <div
                    className={
                      "flex flex-row justify-between space-x-2 text-sm"
                    }
                  >
                    <span className={"text-slate-600"}>Starts </span>
                    <span className={"text-slate-800"}>{startTimeString}</span>
                  </div>
                  <div
                    className={
                      "flex flex-row justify-between space-x-2 text-sm"
                    }
                  >
                    <span className={"text-slate-600"}>Ends </span>
                    <span className={"text-slate-800"}>{endTimeString}</span>
                  </div>
                </div>
                {enableCreateOverride && isOngoing && (
                  <div className="flex justify-between w-full space-x-1">
                    <Button
                      analyticsTrackingId={"create-override-tooltip"}
                      className={"flex-1"}
                      onClick={() => {
                        navigateToOverrideForEntry(navigate, entry);
                      }}
                    >
                      {(overrideId ? "Edit" : "Create") + " override"}
                    </Button>
                    {overrideId && (
                      <Button
                        analyticsTrackingId="delete-override-tooltip"
                        onClick={() => {
                          if (
                            isScheduleEntry(entry) ||
                            isScheduleOverride(entry)
                          ) {
                            navigate(
                              `/on-call/schedules/${entry.schedule_id}/overrides/${overrideId}/delete`,
                            );
                          }
                          closeTooltips();
                        }}
                        icon={IconEnum.Delete}
                        theme={ButtonTheme.DestroySecondary}
                        title=""
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className={"flex flex-col space-y-2"}>
          {!isHolidayEntry && (
            <Txt>No one is currently on call for this schedule</Txt>
          )}
          <div className={"space-y-1"}>
            <div className={"flex flex-row justify-between space-x-2 text-sm"}>
              <span className={"text-slate-600"}>Starts </span>
              <span className={"text-slate-800"}>
                {formatTimestampLocale({
                  timestamp: entries[0].start_at,
                  dateStyle: "short",
                  timeStyle: "short",
                  timeZone: timezone,
                  addWeekday: true,
                })}
              </span>
            </div>
            <div className={"flex flex-row justify-between space-x-2 text-sm"}>
              <span className={"text-slate-600"}>Ends </span>
              <span className={"text-slate-800"}>
                {formatTimestampLocale({
                  timestamp: entries[0].end_at,
                  dateStyle: "short",
                  timeStyle: "short",
                  timeZone: timezone,
                  addWeekday: true,
                })}
              </span>
            </div>
          </div>
          <Button
            analyticsTrackingId={"create-override-tooltip"}
            className={"w-full flex justify-center"}
            onClick={() => {
              const entry = entries[0];
              navigateToOverrideForEntry(navigate, entry);
            }}
          >
            Create override
          </Button>
        </div>
      )}
    </div>
  );
};

const navigateToOverrideForEntry = (
  navigate: ReturnType<typeof useOrgAwareNavigate>,
  entry: TimelineEntry,
) => {
  if (isScheduleOverride(entry)) {
    navigate(
      `/on-call/schedules/${entry.schedule_id}/overrides/${entry.id}/edit`,
    );
  } else if (isScheduleEntry(entry)) {
    navigate(
      `/on-call/schedules/${
        entry.schedule_id
      }/overrides/create?initial_rotation_id=${
        entry.rotation_id
      }&initial_layer_id=${
        entry.layer_id
      }&initial_start_at=${entry.start_at.toISOString()}&initial_end_at=${entry.end_at.toISOString()}`,
    );
  } else {
    navigate(
      `/on-call/schedules/${
        entry.schedule_id
      }/overrides/create?&initial_start_at=${entry.start_at.toISOString()}&initial_end_at=${entry.end_at.toISOString()}`,
    );
  }

  closeTooltips();
};
