import { AnnouncementRule, ScopeNameEnum } from "@incident-io/api";
import { NoPermissionMessage } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  AccordionStackedList,
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
  Loader,
  StackedListItem,
  ToastTheme,
} from "@incident-ui";
import { OverflowAction } from "@incident-ui/StackedList/AccordionStackedList";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { AnnouncementRuleDetails } from "../common/AnnouncementRuleDetails";
import { AnnouncementRuleDeleteModalInner } from "./delete/AnnouncementRuleDeleteModal";

export const AnnouncementRuleList = ({
  expandAll,
}: {
  expandAll?: boolean;
}): React.ReactElement => {
  const {
    data: rulesData,
    isLoading: loadingAnnouncementRules,
    error: errorAnnouncementRules,
  } = useAPI("announcementRulesList", undefined);
  const announcementRules = _.sortBy(rulesData?.rules || [], "created_at");
  const {
    data: slackEnterpriseSettingsData,
    isLoading: loadingSlackEnterpriseSettings,
    error: errorSlackEnterpriseSettings,
  } = useAPI("settingsGetSlackEnterpriseSettings", undefined);

  const slackEnterpriseSettings =
    slackEnterpriseSettingsData?.slack_enterprise_settings;

  const navigate = useOrgAwareNavigate();
  const showToast = useToast();
  const { hasScope } = useIdentity();
  const canEditRules = hasScope(ScopeNameEnum.AnnouncementRulesUpdate);
  const canDestroyRules = hasScope(ScopeNameEnum.AnnouncementRulesDestroy);

  const { trigger: deleteAnnouncementRule, isMutating } = useAPIMutation(
    "announcementRulesList",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.announcementRulesDestroy({ id });
    },
    {
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Announcement rule deleted",
          toastSide: ToastSideEnum.Bottom,
        });
      },
      onError: async () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to delete announcement rule",
          toastSide: ToastSideEnum.Bottom,
        });
      },
    },
  );

  const deleteAction = {
    id: "delete",
    label: "Delete rule",
    icon: IconEnum.Delete2,
    onDelete: (id) => {
      deleteAnnouncementRule({ id });
    },
    tooltipContent: () =>
      canDestroyRules ? undefined : <>{NoPermissionMessage}</>,
  };

  const overflowActions: OverflowAction<AnnouncementRule>[] = [
    {
      id: "edit",
      label: "Edit rule",
      icon: IconEnum.Edit,
      onSelect: (id) => {
        navigate(`${id}/edit`);
      },
      tooltipContent: canEditRules ? null : <>{NoPermissionMessage}</>,
      shouldHide: (_) => false,
    },
  ];

  const error = errorAnnouncementRules || errorSlackEnterpriseSettings;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const loadingData =
    loadingAnnouncementRules || loadingSlackEnterpriseSettings;

  if (loadingData) {
    return <Loader />;
  }

  return (
    <AccordionStackedList
      whiteBg
      items={announcementRules}
      expandAll={expandAll}
      overflowActions={canEditRules ? overflowActions : []}
      deleteAction={deleteAction}
      renderAccordion={(item) => (
        <AnnouncementRuleDetails
          rule={item}
          boxlessConditionGroupsList={false}
          displayIcon={slackEnterpriseSettings?.is_slack_enterprise_installed}
        />
      )}
      renderRow={(item) => (
        <StackedListItem
          noPadding
          icon={IconEnum.Announcement}
          title={item.name}
        />
      )}
      renderEmptyState={() => <EmptyState />}
      deleteModalTitle={(item) => `Delete rule: ${item.name}`}
      renderDeleteModalContent={(item) => {
        return (
          <AnnouncementRuleDeleteModalInner
            rule={item}
            isLastRule={announcementRules.length === 1}
          />
        );
      }}
      getRowClassName={() =>
        tcx({ "hover:bg-surface-secondary": canEditRules })
      }
      rowOnClick={(item) =>
        canEditRules ? navigate(`${item.id}/edit`) : void 0
      }
      isDeleting={(_) => isMutating}
    />
  );
};

const EmptyState = (): React.ReactElement => (
  <div className="text-center border-2 border-stroke border-dashed rounded-2 p-6">
    <Icon
      id={IconEnum.Announcement}
      className="mx-auto h-12 w-12 text-content-tertiary"
    />
    <Heading level={3}>You currently have no announcement rules.</Heading>
    <p className="mt-1 text-sm text-slate-700">
      Get started by creating a new rule.
    </p>
  </div>
);
