import {
  FormHelpTextV2,
  FormInputWrapperV2,
} from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Accordion,
  AccordionProvider,
  AccordionTriggerButton,
  Button,
  ButtonSize,
  ButtonTheme,
  Heading,
  Icon,
  IconEnum,
} from "@incident-ui";
import { Drawer, DrawerContents } from "@incident-ui/Drawer/Drawer";
import { InputType } from "@incident-ui/Input/Input";
import { Popover } from "@incident-ui/Popover/Popover";
import { SelectOption } from "@incident-ui/Select/types";
import * as ReactAccordion from "@radix-ui/react-accordion";
import { Searcher } from "fast-fuzzy";
import { AnimatePresence } from "framer-motion";
import { chain } from "lodash";
import { useState } from "react";
import {
  FieldValues,
  FormProvider,
  Path,
  useController,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import {
  FeatureGates,
  StaffSetFeatureGatesRequestBody,
  StaffShowOrganisationResponseBody,
} from "src/contexts/ClientContext";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

export const FeatureGatesButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button analyticsTrackingId={null} onClick={() => setIsOpen(true)}>
        🛂 Change feature access
      </Button>
      <AnimatePresence>
        {isOpen && (
          <FeatureGatesForm
            organisationSlug={data.organisation_slug}
            gates={data.feature_gates}
            onClose={() => setIsOpen(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

type FormType = FeatureGates & { search: string };

const toRequest = (formData: FormType): StaffSetFeatureGatesRequestBody => {
  const res: StaffSetFeatureGatesRequestBody = { ...formData };

  for (const key in res) {
    if (key === "search") {
      delete res[key];
      continue;
    }

    const gateType = FeatureGateConfig[key as FeatureGateEnum].type;

    switch (gateType) {
      case "bool":
        res[key] = !!res[key];
        break;

      case "number":
        res[key] = res[key] === "" ? undefined : parseInt(res[key]);
        break;

      default:
        assertUnreachable(gateType);
    }
  }

  return res;
};

const FeatureGatesForm = ({
  organisationSlug,
  gates,
  onClose,
}: {
  organisationSlug: string;
  gates: FeatureGates;
  onClose: () => void;
}) => {
  const formMethods = useForm<FormType>({
    defaultValues: { ...gates, search: "" },
  });

  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.ManageFeatureAccess,
    !formMethods.formState.isDirty,
  );

  const { trigger, isMutating, genericError } = useAPIMutation(
    "staffShowOrganisation",
    { organisationSlug },
    async (apiClient, data: FormType) => {
      await apiClient.staffSetFeatureGates({
        organisationSlug,
        setFeatureGatesRequestBody: toRequest(data),
      });
    },
    {
      onSuccess: onClose,
    },
  );

  const categories = chain(Object.values(FeatureGateConfig))
    .map((c) => c.category)
    .uniq()
    .sort()
    .value();

  const allKeys = Object.keys(FeatureGateConfig) as FeatureGateEnum[];
  const searcher = new Searcher(allKeys, {
    keySelector: (key) =>
      (FeatureGateConfig[key].search ?? "") + FeatureGateConfig[key].name,
  });

  const term = formMethods.watch("search");
  const activeKeys = new Set(term !== "" ? searcher.search(term) : allKeys);

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <FormV2
          formMethods={formMethods}
          onSubmit={trigger}
          saving={isMutating}
          genericError={genericError}
          outerClassName="px-6 pb-6"
          innerClassName="-mt-2"
        >
          <div className="sticky top-0 w-full bg-white pt-6 pb-4 -mb-2 space-y-2 z-10">
            <div className="flex items-center gap-4">
              <div className="grow">
                <FormHelpTextV2>
                  This won&apos;t affect how much the customer is charged, only
                  what access to features they have!
                </FormHelpTextV2>
              </div>

              <GatedButton
                type="submit"
                analyticsTrackingId={null}
                {...disabledProps}
              >
                Save
              </GatedButton>
            </div>

            <InputV2
              type={InputType.Search}
              formMethods={formMethods}
              name="search"
              label="Search"
            />
          </div>

          <AccordionProvider
            type="multiple"
            className="border border-stroke rounded divide-y divide-slate-200 h-full overflow-y-auto"
          >
            {categories.map((category) => (
              <GateCategory
                key={category}
                category={category}
                activeKeys={activeKeys}
                formMethods={formMethods}
              />
            ))}
          </AccordionProvider>
        </FormV2>
      </DrawerContents>
    </Drawer>
  );
};

const GateCategory = ({
  category,
  activeKeys,
  formMethods,
}: {
  category: string;
  activeKeys: Set<FeatureGateEnum>;
  formMethods: UseFormReturn<FormType>;
}) => {
  const relevantGates = Object.keys(FeatureGateConfig).filter(
    (name) => FeatureGateConfig[name].category === category,
  ) as FeatureGateEnum[];

  const anyActive = relevantGates.some((name) => activeKeys.has(name));

  return (
    <Accordion
      id={category}
      containerClassName={anyActive ? undefined : "hidden"}
      header={
        <ReactAccordion.Trigger asChild>
          <div className="flex items-center cursor-pointer p-3 pr-0 gap-4">
            <div className="grow">
              <Heading level={3} size="small" className={"font-medium"}>
                {category}
              </Heading>
            </div>
            <AccordionTriggerButton />
          </div>
        </ReactAccordion.Trigger>
      }
    >
      <div className="p-4 space-y-3 border-t border-stroke">
        {relevantGates.map((name) => (
          <FeatureGateInput
            key={name}
            formMethods={formMethods}
            gate={name}
            name={name}
            isActive={activeKeys.has(name)}
          />
        ))}
      </div>
    </Accordion>
  );
};

export const FeatureGateInput = <TFormType extends FieldValues>({
  formMethods,
  name,
  gate,
  isActive,
}: {
  formMethods: UseFormReturn<TFormType>;
  name: Path<TFormType>;
  gate: FeatureGateEnum;
  isActive: boolean;
}) => {
  const config = FeatureGateConfig[gate];
  const inputType = config.type;

  const value = formMethods.watch(name);
  const presentedValue =
    inputType === "bool"
      ? value
        ? "Enabled"
        : "Disabled"
      : value ?? "Unlimited";
  const [state, setState] = useState<"open" | "custom" | "closed">("closed");

  const { field } = useController({ name });
  const parseValue = buildParseValue(inputType);

  const handleSelect = (newValue: string) => {
    field.onChange(parseValue(newValue));
    setState("closed");
  };

  if (!isActive) return null;

  return (
    <FormInputWrapperV2
      name={name}
      label={config.name}
      className="flex flex-row items-center gap-4"
      labelWrapperClassName="mb-0"
    >
      <Popover
        onInteractOutside={() => setState("closed")}
        trigger={
          <Button
            size={ButtonSize.Small}
            analyticsTrackingId={null}
            onClick={() => setState("open")}
            className={
              "border !border-stroke !bg-surface-secondary !text-content-primary"
            }
          >
            <div className="flex items-center gap-1">
              <div>{presentedValue}</div>
              <Icon id={IconEnum.Expand} className="text-slate-400" />
            </div>
          </Button>
        }
        className="max-h-[400px] min-h-[50px] !p-0 w-[350px]"
        sideOffset={-38}
        align="start"
        onOpenChange={(open) => {
          if (!open) {
            setState("closed");
          }
        }}
        open={state !== "closed"}
      >
        <menu>
          {state === "custom" ? (
            <CustomInput
              initialValue={value?.toString() ?? ""}
              handleSelect={handleSelect}
            />
          ) : (
            <DropdownOptions
              formMethods={formMethods}
              handleSelect={handleSelect}
              onCustom={() => setState("custom")}
              name={name}
              gate={gate}
            />
          )}
        </menu>
      </Popover>
    </FormInputWrapperV2>
  );
};

const DropdownOptions = <TFormType extends FieldValues>({
  handleSelect,
  onCustom,
  name,
  gate,
  formMethods,
}: {
  handleSelect: (value: string) => void;
  onCustom: () => void;
  name: Path<TFormType>;
  gate: FeatureGateEnum;
  formMethods: UseFormReturn<TFormType>;
}) => {
  const value = formMethods.watch(name);
  const config = FeatureGateConfig[gate];
  const parseValue = buildParseValue(config.type);
  const options =
    config.type === "bool"
      ? boolOptions
      : numberOptions(config.defaults, value);

  return (
    <ul className="py-2">
      {options.map((option) => (
        <li
          key={option.value}
          onClick={() => handleSelect(option.value)}
          className={tcx(
            "flex-center-y text-sm text-content-primary font-normal rounded border",
            parseValue(option.value) === parseValue(value)
              ? "border-stroke bg-slate-50 border-dashed hover:border-solid"
              : "border-transparent",
            "hover:border-stroke hover:bg-surface-secondary hover:cursor-pointer p-1 mx-2",
          )}
        >
          {option.label}
        </li>
      ))}

      {config.type === "number" && (
        <li
          onClick={onCustom}
          className="flex-center-y text-sm text-content-primary font-normal rounded border border-transparent hover:border-stroke hover:bg-surface-secondary hover:cursor-pointer p-1 mx-2"
        >
          Custom value ...
        </li>
      )}
    </ul>
  );
};

const CustomInput = ({
  initialValue,
  handleSelect,
}: {
  initialValue: string;
  handleSelect: (value: string) => void;
}) => {
  const formMethods = useForm<{ value: string }>({
    defaultValues: { value: initialValue },
  });

  return (
    <form
      onSubmit={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        const { value } = formMethods.getValues();
        handleSelect(value);
      }}
      className="p-2"
    >
      <FormProvider {...formMethods}>
        <InputV2
          formMethods={formMethods}
          type={InputType.Number}
          name="value"
          inputClassName="!py-0.5"
          insetSuffixNode={
            <Button
              type="submit"
              analyticsTrackingId={null}
              title="Confirm"
              icon={IconEnum.Checkmark}
              theme={ButtonTheme.Naked}
            />
          }
        />
      </FormProvider>
    </form>
  );
};
// buildParseValue normalises all the garbage we put into the form state into the correct API type.
// Inputs in HTML only have string values, so our form state _starts_ off as API
// types (bools, numbers), and then gets full of strings over time.
//
// This handles any kind of input and returns the right API type for the gate type.
const buildParseValue =
  (inputType: "bool" | "number") =>
  (value: string | number | boolean | null | undefined) => {
    if (inputType === "bool") {
      return value === "true" || value === true;
    } else {
      if (typeof value === "boolean") {
        throw new Error(`Number gate had value ${value} - a bool!`);
      }

      return value === "" || value === undefined || value == null
        ? (null as unknown as undefined) // this is a hack to force react-hook-form to do what we say
        : typeof value === "number"
        ? value
        : parseInt(value);
    }
  };
const boolOptions: SelectOption[] = [
  { label: "Enabled", value: "true" },
  { label: "Disabled", value: "false" },
];

const numberOptions = (
  defaults: SelectOption[] | undefined,
  currentValueForm: number | boolean | undefined,
) => {
  const currentValue = currentValueForm?.toString() ?? "";
  const res: SelectOption[] = defaults ?? [
    { label: "None", value: "0" },
    { label: "1", value: "1" },
    { label: "Unlimited", value: "" },
  ];

  if (!res.some((opt) => opt.value === currentValue?.toString() ?? "")) {
    res.push({
      value: currentValue?.toString() ?? "",
      label: currentValue?.toString() ?? "Unlimited",
    });
  }

  return res;
};

type FeatureGateEnum = keyof Required<FeatureGates>;
type GateType<T extends FeatureGateEnum> =
  Required<FeatureGates>[T] extends boolean ? "bool" : "number";

export const FeatureGateConfig: {
  [key in FeatureGateEnum]: {
    category: string;
    name: string;
    type: GateType<key>;
    search?: string;
    defaults?: GateType<key> extends "number" ? SelectOption[] : undefined;
  };
} = {
  advanced_insights: {
    category: "Insights",
    name: "Advanced Insights",
    type: "bool",
  },
  ai_assistant: { category: "AI", name: "AI: Assistant", type: "bool" },
  ai_features: { category: "AI", name: "AI: Features", type: "bool" },
  api_access: { category: "API", name: "API", type: "bool" },
  atlassian_statuspage_pages_count: {
    category: "Status pages",
    name: "Atlassian Statuspage pages",
    type: "number",
  },
  audit_logs: {
    category: "Security & controls",
    name: "Audit logs",
    type: "bool",
  },
  custom_catalog_types_count: {
    category: "Catalog",
    name: "Catalog: custom types",
    type: "number",
    defaults: [
      { label: "None", value: "0" },
      { label: "2", value: "2" },
      { label: "Unlimited", value: "" },
    ],
  },
  custom_dashboards: {
    category: "Insights",
    name: "Custom dashboards",
    type: "bool",
  },
  custom_field_count: {
    category: "Core",
    name: "Custom fields",
    type: "number",
  },
  custom_field_conditions: {
    category: "Core",
    name: "Custom field conditions",
    type: "bool",
  },
  custom_nudges: { category: "Core", name: "Custom nudges", type: "bool" },
  debrief_placeholders: {
    category: "Post-incident",
    name: "Debrief placeholders",
    type: "bool",
    search: "calendar meeting schedule",
  },
  enabled_atlassian_sites_count: {
    category: "Jira",
    name: "Multiple Jira sites",
    type: "number",
    search: "instances",
  },
  follow_up_issue_templates_count: {
    category: "Follow-ups",
    name: "Follow-up auto-export templates",
    type: "number",
  },
  follow_up_priorities: {
    category: "Follow-ups",
    name: "Follow-up priorities",
    type: "bool",
  },
  follow_up_priorities_customisable: {
    category: "Follow-ups",
    name: "Follow-up priorities: customisable",
    type: "bool",
  },
  incident_lifeycle_count: {
    category: "Core",
    name: "Incident lifecycles",
    type: "number",
    search: "statuses timestamps types",
  },
  incident_ticket_issue_templates_count: {
    category: "Jira",
    name: "Incident ticket: templates",
    type: "number",
  },
  incident_types: {
    category: "Core",
    name: "Incident Types",
    type: "bool",
  },
  integrations_count: {
    category: "Core",
    name: "Integrations",
    type: "number",
  },
  on_call_calculator: {
    category: "On-call",
    name: "On-call calculator",
    type: "bool",
    search: "pay",
  },
  on_call_responders_count: {
    category: "On-call",
    name: "On-call responders",
    type: "number",
    defaults: [
      { label: "None", value: "0" },
      { label: "5", value: "5" },
      { label: "Unlimited", value: "" },
    ],
  },
  on_call_schedules_count: {
    category: "On-call",
    name: "On-call schedules",
    type: "number",
    search: "rota shift",
  },
  policies_count: {
    category: "Post-incident",
    name: "Policies",
    type: "number",
    search: "policy",
  },
  post_incident_flow_custom_tasks: {
    category: "Post-incident",
    name: "Post-incident tasks: custom tasks",
    type: "bool",
    search: "flow checklist",
  },
  post_incident_task_auto_assign: {
    category: "Post-incident",
    name: "Post-incident tasks: auto-assign",
    type: "bool",
    search: "flow checklist",
  },
  post_incident_task_auto_remind: {
    category: "Post-incident",
    name: "Post-incident tasks: auto-remind",
    type: "bool",
    search: "flow checklist",
  },
  postmortem_templates_count: {
    category: "Post-incident",
    name: "Postmortem templates",
    type: "number",
    search: "debrief retrospective",
  },
  private_incidents: {
    category: "Core",
    name: "Private incidents",
    type: "bool",
  },
  rbac_base_roles_customisable: {
    category: "Security & controls",
    name: "RBAC: base roles customisable",
    type: "bool",
  },
  rbac_custom_roles: {
    category: "Security & controls",
    name: "RBAC: custom roles",
    type: "bool",
  },
  saml: {
    category: "Security & controls",
    name: "SAML",
    type: "bool",
    search: "sso single sign on okta onelogin",
  },
  saved_views_per_context_count: {
    category: "Core",
    name: "Saved views",
    type: "number",
  },
  scim: {
    category: "Security & controls",
    name: "SCIM",
    type: "bool",
    search: "okta onelogin",
  },
  slack_enterprise_grid_install: {
    category: "Security & controls",
    name: "Slack Enterprise Grid install",
    type: "bool",
  },
  status_pages_count: {
    category: "Status pages",
    name: "Status pages",
    type: "number",
  },
  status_pages_customer_pages: {
    category: "Status pages",
    name: "Status pages: customer pages",
    type: "bool",
  },
  status_pages_sub_pages: {
    category: "Status pages",
    name: "Status pages: sub-pages",
    type: "bool",
  },
  internal_status_pages_count: {
    category: "Status pages",
    name: "Internal status pages",
    type: "number",
  },
  streams_per_incident_count: {
    category: "Core",
    name: "Streams per incident",
    type: "number",
    search: "swimlane parent child",
  },
  triage_incidents: {
    category: "Core",
    name: "Triage incidents",
    type: "bool",
    search: "status",
  },
  use_telecom: {
    category: "Automation",
    name: "SMS, Phone and Email workflows",
    type: "bool",
  },
  webhooks: { category: "API", name: "Webhooks", type: "bool" },
  workflows_count: {
    category: "Automation",
    name: "Workflows",
    type: "number",
  },
};
