import { EngineFormElement } from "@incident-shared/engine";
import { InputOrVariable } from "@incident-shared/engine";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { Loader } from "@incident-ui";
import { captureMessage } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  EngineScope,
  JiraIssueField as JiraIssueField,
  JiraIssueFieldTypeEnum as FieldTypeEnum,
  Resource,
  SelectOption,
} from "src/contexts/ClientContext";
import { assertUnreachable } from "src/utils/utils";

import { JiraCloudDynamicFieldSelect } from "./JiraCloudDynamicFieldSelect";
import { JiraCloudDropdownOrRefSelect } from "./JiraCloudInputOrRefSelect";
import { JiraCloudTextInputOrRef } from "./JiraCloudTextInputOrRef";

export type OptionLoader = (props: {
  field: SelectOption;
  siteId: string;
  projectId?: string;
  issueTypeId?: string;
  query?: string;
}) => Promise<SelectOption[]>;

const engineTypeLookup = {
  [FieldTypeEnum.Link]: "Link",
  [FieldTypeEnum.Number]: "Number",
  [FieldTypeEnum.Date]: "Date",
  [FieldTypeEnum.DateTime]: "Timestamp",
};

export const JiraIssueFieldInput = ({
  field,
  fieldKey,
  siteId,
  projectId,
  issueTypeId,
  disabled,
  loadOptions,
  scope,
  resources,
}: {
  field: JiraIssueField;
  fieldKey: string;
  siteId: string;
  projectId: string;
  issueTypeId: string;
  disabled?: boolean;
  loadOptions: OptionLoader;
  scope: EngineScope;
  resources: Resource[];
}): React.ReactElement | null => {
  const formMethods = useFormContext();
  const { permanentIncidentJiraAutoExportSupportDynamicSingleSelect } =
    useFlags();

  const engineType = engineTypeLookup[field.type];

  if (engineType) {
    return (
      <EngineFormElement
        resourceType={engineType}
        name={fieldKey}
        label={field.label}
        required={field.required}
        array={false}
        scope={scope}
        resources={resources}
        mode={"variables_only" as const}
        disabled={disabled}
      />
    );
  }

  switch (field.type) {
    case FieldTypeEnum.EpicLink:
      // It doesn't make sense to me that someone would want an incident ticket
      // be linked into another Epic, but the relationship would go the other
      // way around (the incident ticket is an epic, with follow-ups linked to
      // it).
      //
      // If I'm wrong about this, we should treat this like a single select,
      // with a dynamic typeahead to load the parent epic ticket.
      if (field.required) {
        captureMessage("Required Jira Epic Link field", { extra: { field } });
      }
      return null;

    case FieldTypeEnum.RichText:
      // Wiring up templated text for this use case isn't _super_ hard but I
      // don't think it's worth it right now. If someone really complains we can
      // build this, but let's wait and see!
      if (field.required) {
        captureMessage("Required Jira Rich Text field", { extra: { field } });
      }
      return null;

    case FieldTypeEnum.Text:
      return (
        <JiraCloudTextInputOrRef
          fieldKey={fieldKey}
          field={field}
          scope={scope}
          disabled={disabled}
        />
      );
    case FieldTypeEnum.Labels:
    case FieldTypeEnum.MultiSelect:
    case FieldTypeEnum.SingleSelect:
      // We can't use an EngineFormElement here as we can't represent jira custom fields
      // in the engine yet, and so we need to load the options from the issuetracker service
      // instead.

      if (!field.allowed_values && !field.dynamic_typeahead_field) {
        // If there's neither static values, nor a typeahead we can pull options
        // in from, don't render the field at all: we will look quite silly if
        // we render a dropdown with no options.
        if (field.required) {
          captureMessage(
            "Required Jira field has neither options nor a typeahead",
            { extra: { field } },
          );
        }
        return null;
      }

      // 🐉 Warning - here be dragons!
      // If the "Permanent: Incident Jira AutoExport Support Dynamic SingleSelect" flag is enabled
      // for the organisation, then instead of having the user choose a static value from the
      // "single-select" or "multi-select" field's allowed values, we allow them to power it by
      // using a dynamic value, currently only incident severity and custom fields are supported.
      // This is cumbersome as we are relying on the user to have correctly set up their
      // incident.io severities to be a subset of the Jira field's allowed values. If the incident
      // severity resolves to a value that is not an allowed value, then we fallback to using the
      // first allowed value for the field.
      if (
        permanentIncidentJiraAutoExportSupportDynamicSingleSelect &&
        field.key.startsWith("customfield_") &&
        field.allowed_values &&
        field.allowed_values.length > 0
      ) {
        return (
          <div>
            <JiraCloudDropdownOrRefSelect
              fieldKey={fieldKey}
              field={field}
              scope={scope}
              resources={resources}
              disabled={disabled}
              allowedValues={field.allowed_values.map((e) => e.value)}
            />
          </div>
        );
      }

      return (
        <JiraCloudDynamicFieldSelect
          fieldKey={fieldKey}
          label={field.label}
          dependencies={[projectId, issueTypeId]}
          disabled={disabled}
          getJiraOptions={async (queryOrID) => {
            if (!projectId || !issueTypeId) {
              return [];
            }
            return field.allowed_values
              ? field.allowed_values.filter((opt) => {
                  return (
                    // When getting options from the list of allowed values, we need to search against
                    // both the label and the value. This means that we can hydrate options using
                    // either the label or value of the option.
                    opt.label.toLowerCase().includes(queryOrID.toLowerCase()) ||
                    opt.value.toLowerCase().includes(queryOrID.toLowerCase())
                  );
                })
              : field.dynamic_typeahead_field
              ? await loadOptions({
                  field:
                    field.dynamic_typeahead_field as unknown as SelectOption,
                  siteId,
                  projectId,
                  issueTypeId,
                  query: queryOrID,
                })
              : [];
          }}
          isMulti={[FieldTypeEnum.MultiSelect, FieldTypeEnum.Labels].includes(
            field.type,
          )}
          required={field.required}
        />
      );

    case FieldTypeEnum.User:
      // We can't use an EngineFormElement here as we are filtering for only users that are
      // part of the selected project, and we can't represent that in the engine yet.

      if (field.required) {
        return (
          <DynamicSingleSelectV2
            name={`${fieldKey}.value.literal`}
            formMethods={formMethods}
            label={field.label}
            isDisabled={disabled}
            helptext={
              "Because this field is required, you can't use a role assignment here, since not every user in Slack will have an account in Jira."
            }
            loadOptions={async (query) => {
              if (!projectId || !issueTypeId) {
                return [];
              }
              return await loadOptions({
                field: field.dynamic_typeahead_field as unknown as SelectOption,
                siteId,
                projectId,
                issueTypeId,
                query,
              });
            }}
            hydrateOptions={async (val) => {
              return await loadOptions({
                field: field.dynamic_typeahead_field as unknown as SelectOption,
                siteId,
                projectId,
                issueTypeId,
                query: val,
              });
            }}
            required={field.required}
          />
        );
      } else {
        return (
          <JiraCloudOptionalUserField
            fieldKey={fieldKey}
            label={field.label}
            required={field.required}
            scope={scope}
            resources={resources}
            loadOptions={loadOptions}
            field={field}
            siteId={siteId}
            projectId={projectId}
            issueTypeId={issueTypeId}
          />
        );
      }

    case FieldTypeEnum.Link:
    case FieldTypeEnum.Number:
    case FieldTypeEnum.Date:
    case FieldTypeEnum.DateTime:
      // Do nothing: handled above by the engineFormElement!
      return null;

    default:
      assertUnreachable(field.type);
      return null;
  }
};

function JiraCloudOptionalUserField({
  fieldKey,
  label,
  required,
  scope,
  resources,
  loadOptions,
  field,
  siteId,
  projectId,
  issueTypeId,
}: {
  field: JiraIssueField;
  fieldKey: string;
  label: string;
  required: boolean;
  scope: EngineScope;
  resources: Resource[];
  loadOptions: OptionLoader;
  siteId: string;
  projectId: string;
  issueTypeId: string;
}) {
  // We haven't yet made the effort to thread through query params to Jira
  // as it's complicated. For now, this only lists users who can be assigned to a project
  // so isn't huge and should be fine to load all, then filter in memory.
  //
  // If that changes, Jira _does_ support adding a query param for searching for a user
  // which I tried out here then cancelled, that we could try again
  // https://github.com/incident-io/core/pull/10822/files#diff-6ebd85199aedd9699a813aac6b2df8bbc1a28aaa45ad1b3ccf81d6bc7d3de91bR235-R273

  const [options, setOptions] = useState<SelectOption[] | null>(null);

  const formMethods = useFormContext();

  useEffect(() => {
    loadOptions({
      field: field.dynamic_typeahead_field as unknown as SelectOption,
      siteId,
      projectId,
      issueTypeId,
    }).then((o) => {
      setOptions(o);
    });
  }, [
    field.dynamic_typeahead_field,
    siteId,
    projectId,
    issueTypeId,
    loadOptions,
  ]);

  if (!options) {
    return <Loader />;
  }

  const literalKey = `${fieldKey}.value.literal`;

  const resource = resources.find((x) => x.type === "User");
  if (!resource) {
    throw new Error("unreachable: failed to find the User resource");
  }

  return (
    <FormInputWrapperV2
      label={label}
      required={required}
      name={`${fieldKey}.value`}
    >
      <InputOrVariable
        name={`${fieldKey}.value`}
        label={label}
        scopeAndIsAlert={{ scope }}
        resource={resource}
        required={required}
        array={false}
        includeExpressions={false}
        includeVariables={true}
        includeStatic
        renderChildren={(renderLightningButton) => (
          <DynamicSingleSelectV2
            name={literalKey}
            formMethods={formMethods}
            insetSuffixNode={renderLightningButton()}
            loadOptions={async (query) => {
              if (!projectId || !issueTypeId) {
                return [];
              }
              return await loadOptions({
                field: field.dynamic_typeahead_field as unknown as SelectOption,
                siteId,
                projectId,
                issueTypeId,
                query,
              });
            }}
            hydrateOptions={async (val) => {
              return await loadOptions({
                field: field.dynamic_typeahead_field as unknown as SelectOption,
                siteId,
                projectId,
                issueTypeId,
                query: val,
              });
            }}
            className="w-full"
          />
        )}
      />
    </FormInputWrapperV2>
  );
}
