import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import {
  Callout,
  CalloutTheme,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import {
  CurrencyInput,
  currencyValidationRules,
} from "@incident-ui/Input/CurrencyInput";
import { areIntervalsOverlapping, isAfter, isValid } from "date-fns";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { formatCurrency } from "../../../../utils/currency";
import { parseSimpleDate } from "../ReportGenerator";
import { TimezoneHelperText } from "../TimezoneHelperToolTip";
import {
  displayDateRangeFromLocalObj,
  toDateObjFromLocal,
  toDateObjFromUTC,
} from "./date-time-helpers";
import { DateTimeFormType, DateTimeInput } from "./DateTimeInput";
import { OverrideFormType } from "./PayConfigCreateEditForm";

export const OverrideModal = ({
  onClose,
  onAdd: onAddCallback,
  onEdit: onEditCallback,
  editingOverride,
  selectedCurrency,
  baseRateInCents,
  // This is used so we can tell if you've already got an override that
  // overlaps
  existingOverrides,
}: {
  onClose: () => void;
  onAdd: (override: OverrideFormType) => void;
  onEdit: (override: OverrideFormType) => void;
  editingOverride: OverrideFormType | null;
  selectedCurrency: string;
  baseRateInCents: number;
  existingOverrides: OverrideFormType[];
}): React.ReactElement | null => {
  const [genericError, setGenericError] = useState<string | null>(null);

  const checkIsValid = (newOverride: OverrideFormType) => {
    // check if it overlaps with any existing overrides
    for (const override of existingOverrides) {
      // No need to compare it to itself
      if (override.key === newOverride.key) {
        continue;
      }

      if (
        areIntervalsOverlapping(
          overrideFormTypeToInterval(override),
          overrideFormTypeToInterval(newOverride),
        )
      ) {
        setGenericError(
          `Overrides must be mutually exclusive. This override overlaps with ${
            override.name
          } (${displayDateRangeFromLocalObj(
            override.start_at,
            override.end_at,
          )}).`,
        );
        return false;
      }
    }
    return true;
  };

  const onAdd = (data: OverrideFormType) => {
    if (checkIsValid(data)) {
      return onAddCallback(data);
    }
    return undefined;
  };

  const onEdit = (data: OverrideFormType) => {
    if (checkIsValid(data)) {
      return onEditCallback(data);
    }
    return undefined;
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setError,
    watch,
    setValue,
  } = useForm<OverrideFormType>({
    defaultValues: editingOverride || {
      key: uuidv4(),
      start_at: {
        time: "00:00",
        isEnd: false,
      },

      end_at: {
        time: "00:00",
        isEnd: true,
      },
    },
  });

  const startAt = watch("start_at");
  const endAt = watch("end_at");

  // Default end at to be whatever we first change start at to (if the end is unset).
  useEffect(() => {
    if (
      startAt.date &&
      isAfter(toDateObjFromUTC(startAt), new Date(2000)) &&
      endAt.date === ""
    ) {
      setValue<"end_at">("end_at", startAt);
    }
  }, [setValue, startAt, endAt]);

  const onSubmit = (override: OverrideFormType) => {
    if (isAfter(toDateObjFromUTC(startAt), toDateObjFromUTC(endAt))) {
      setError("end_at", {
        type: "manual",
        message: "Holiday must end after it starts",
      });

      return undefined;
    }

    reset();

    if (override.rate_pounds === "") {
      override.rate_pounds = "0";
    }

    if (editingOverride) {
      return onEdit(override);
    }

    return onAdd(override);
  };

  const wrappedOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      isOpen
      title={"Add custom holiday rule"}
      onClose={wrappedOnClose}
      onSubmit={handleSubmit(onSubmit)}
      as={"form"}
      disableQuickClose
      analyticsTrackingId={"pay-config-override-modal"}
    >
      <ModalContent className="space-y-4">
        <ErrorMessageUI message={genericError} />
        <FormInputWrapper
          label="What should this rule be called?"
          errors={errors}
          id="name"
        >
          <Input
            id="name"
            placeholder="Christmas 2022"
            {...register("name", {
              required: "Please name your rule",
            })}
          />
        </FormInputWrapper>

        <FormInputWrapper
          label="How much do you pay per hour during this period?"
          errors={errors}
          id="rate_pounds"
        >
          <CurrencyInput
            currency={selectedCurrency}
            id="rate_pounds"
            defaultValue={editingOverride?.rate_pounds}
            {...register("rate_pounds", {
              validate: currencyValidationRules,
            })}
          />
          <p className="text-content-tertiary text-xs mt-2">
            {`As a reminder, your base rate for this pay configuration is ${formatCurrency(
              selectedCurrency,
              baseRateInCents,
            )} per hour.`}
          </p>
        </FormInputWrapper>

        <FormInputWrapper
          className="mt-2"
          label="When does this holiday start?"
          errors={errors}
          id="start_at"
        >
          <Controller
            control={control}
            name={"start_at"}
            rules={{
              validate: {
                invalid: validateDate,
              },
            }}
            render={({ field: { onChange, value, onBlur } }) => (
              <DateTimeInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                id="start_at"
              />
            )}
          />
        </FormInputWrapper>
        <FormInputWrapper
          label="When does this holiday end?"
          errors={errors}
          id="end_at"
        >
          <Controller
            control={control}
            name={"end_at"}
            rules={{
              validate: {
                invalid: validateDate,
              },
            }}
            render={({ field: { onChange, value, onBlur } }) => (
              <DateTimeInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                id="end_at"
              />
            )}
          />
        </FormInputWrapper>
        <Callout theme={CalloutTheme.Plain}>
          <TimezoneHelperText />
        </Callout>
      </ModalContent>
      <ModalFooter onClose={wrappedOnClose} confirmButtonType="submit" />
    </Modal>
  );
};

const validateDate = (dateTimeObj: DateTimeFormType): string | true => {
  const d = parseSimpleDate(dateTimeObj.date);
  if (!isValid(d)) {
    return "Please choose a valid date";
  }

  if (d.getUTCFullYear() < 2000) {
    return "This date is too far in the past";
  }

  if (d.getUTCFullYear() > 2100) {
    return "This date is too far in the future";
  }

  return true;
};

export const overrideFormTypeToInterval = (
  override: OverrideFormType,
): Interval => {
  return {
    start: toDateObjFromLocal(override.start_at),
    end: toDateObjFromLocal(override.end_at),
  };
};
