/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertsMarkSuggestionRequestBody
 */
export interface AlertsMarkSuggestionRequestBody {
    /**
     * What to respond to the suggestion
     * @type {string}
     * @memberof AlertsMarkSuggestionRequestBody
     */
    response: AlertsMarkSuggestionRequestBodyResponseEnum;
    /**
     * The ID of the suggestion to mark
     * @type {string}
     * @memberof AlertsMarkSuggestionRequestBody
     */
    suggestion_id: string;
}

/**
* @export
* @enum {string}
*/
export enum AlertsMarkSuggestionRequestBodyResponseEnum {
    Accept = 'accept',
    Decline = 'decline'
}

export function AlertsMarkSuggestionRequestBodyFromJSON(json: any): AlertsMarkSuggestionRequestBody {
    return AlertsMarkSuggestionRequestBodyFromJSONTyped(json, false);
}

export function AlertsMarkSuggestionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsMarkSuggestionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'response': json['response'],
        'suggestion_id': json['suggestion_id'],
    };
}

export function AlertsMarkSuggestionRequestBodyToJSON(value?: AlertsMarkSuggestionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'response': value.response,
        'suggestion_id': value.suggestion_id,
    };
}

