import {
  GetInstalledIssueTrackers,
  IssueTrackerProviderEnabled,
} from "@incident-shared/issue-trackers";
import {
  Button,
  ButtonTheme,
  EmptyState,
  Heading,
  IconEnum,
  Loader,
} from "@incident-ui";
import { compact } from "lodash";
import { useState } from "react";
import { FollowUpsTable } from "src/components/post-incident/follow-ups/list/FollowUpsTable";
import {
  FollowUp,
  FollowUpStatusEnum,
  Incident,
  PolicyPolicyTypeEnum,
  SlackTeamConfig,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI } from "src/utils/swr";

export const HomeFollowUps = (): React.ReactElement => {
  const { identity } = useIdentity();

  const {
    data: { slack_team_configs: slackTeamConfigs },
  } = useAPI("slackTeamConfigsList", undefined, {
    fallbackData: { slack_team_configs: [] },
  });

  const [showMore, setShowMore] = useState(false);

  const {
    data: { follow_ups: myFollowUps },
    isLoading: followupsLoading,
  } = useAPI(
    identity ? "followUpsList" : null,
    { assigneeId: identity?.user_id },
    { fallbackData: { follow_ups: [] } },
  );

  const myOpenFollowups = myFollowUps.filter(
    (followup) => followup.status === FollowUpStatusEnum.Outstanding,
  );

  const incidentIDsToFetch = new Set(
    myOpenFollowups.map(({ incident_id }) => incident_id),
  );

  const {
    data: { incidents },
    isLoading: incidentsLoading,
    mutate: refetchIncidents,
  } = useAPI(
    "incidentsList",
    {
      id: { one_of: Array.from(incidentIDsToFetch) },
      pageSize: incidentIDsToFetch.size,
    },
    { fallbackData: { incidents: [] } },
  );

  const incidentIDToIncident = incidents.reduce(
    (incidentIDToIncident, incident) => {
      incidentIDToIncident.set(incident.id, incident);
      return incidentIDToIncident;
    },
    new Map<string, Incident>(),
  );

  const openFollowups = myOpenFollowups.filter((followup) =>
    incidentIDToIncident.has(followup.incident_id),
  );
  const { integrations } = useIntegrations();

  if (!integrations || followupsLoading || incidentsLoading) {
    return <Loader />;
  }

  const incidentToFollowups = new Map<string, FollowUp[]>();
  openFollowups.forEach((followup) => {
    const incidentFollowups =
      incidentToFollowups.get(followup.incident_id) ?? [];
    incidentFollowups.push(followup);
    incidentToFollowups.set(followup.incident_id, incidentFollowups);
  });

  const installedIssueTrackers = GetInstalledIssueTrackers(integrations);

  let incidentEntries = compact(
    Array.from(incidentToFollowups.entries()).map(([incidentID, followups]) => {
      const incident = incidentIDToIncident.get(incidentID);
      if (!incident) {
        return null;
      }
      return {
        incident,
        followups,
      };
    }),
  );

  let haveTruncated = false;
  if (!showMore) {
    if (incidentEntries.length > 5) {
      haveTruncated = true;
      incidentEntries = incidentEntries.slice(0, 5);
    }
  }

  return (
    <div data-testid="homepage-followups-section">
      <HomeFollowupsHeader />
      {openFollowups.length === 0 ? (
        <EmptyState
          icon={IconEnum.FollowUps}
          content="There are no open follow-ups assigned to you."
        />
      ) : (
        <>
          <p className="text-sm">
            There{openFollowups.length === 1 ? " is " : " are "}
            <span className="font-semibold">
              {`${openFollowups.length} follow-up${
                openFollowups.length === 1 ? "" : "s"
              } `}
            </span>{" "}
            assigned to you
          </p>
          {incidentEntries.map(({ incident, followups }) => {
            return (
              <IncidentFollowupsTable
                key={incident.id}
                incident={incident}
                refetchIncidents={async () => {
                  await refetchIncidents();
                }}
                slackTeamConfigs={slackTeamConfigs}
                followups={followups}
                installedIssueTrackers={installedIssueTrackers}
              />
            );
          })}
          {haveTruncated ? (
            <Button
              analyticsTrackingId="show-more-followups"
              theme={ButtonTheme.Naked}
              onClick={() => setShowMore(true)}
              className="mt-3"
            >
              Show more
            </Button>
          ) : null}
        </>
      )}
    </div>
  );
};

const useFetchPoliciesAndViolations = () => {
  // Get all violations for the follow-up policies
  const {
    data: { policies },
    isLoading: policiesLoading,
    error: policiesError,
  } = useAPI("policiesList", undefined, { fallbackData: { policies: [] } });

  const followUpPolicyIDs = policies
    .filter((policy) => {
      return policy.policy_type === PolicyPolicyTypeEnum.FollowUp;
    })
    .map((policy) => policy.id);

  const {
    data: { policy_violations, incidents },
    isLoading: violationsLoading,
    error: violationsError,
  } = useAPI(
    policies.length > 0 ? "policiesListViolations" : null,
    {
      policyIds: followUpPolicyIDs,
    },
    { fallbackData: { incidents: [], policy_violations: [] } },
  );

  return {
    data: {
      policies,
      policy_violations,
      incidents,
    },
    isLoading: policiesLoading || violationsLoading,
    error: policiesError || violationsError,
  };
};

const IncidentFollowupsTable = ({
  incident,
  refetchIncidents,
  slackTeamConfigs,
  followups,
  installedIssueTrackers,
}: {
  incident: Incident;
  refetchIncidents: () => Promise<void>;
  slackTeamConfigs: SlackTeamConfig[] | null;
  followups: FollowUp[];
  installedIssueTrackers: IssueTrackerProviderEnabled[];
}): React.ReactElement => {
  const {
    data: { policies, policy_violations },
  } = useFetchPoliciesAndViolations();

  // We only show Slack Team information if there are multiple teams connected
  // to this organisation.
  const slackTeam =
    (slackTeamConfigs ?? []).length > 1
      ? slackTeamConfigs?.find(
          (config) => config.slack_team_id === incident.slack_team_id,
        )
      : undefined;

  return (
    <FollowUpsTable
      incident={incident}
      slackTeam={slackTeam}
      followUps={followups}
      installedIssueTrackers={installedIssueTrackers}
      policies={policies}
      policyViolations={policy_violations}
      onHomepage={true}
      refetchIncidents={refetchIncidents}
    />
  );
};

const HomeFollowupsHeader = (): React.ReactElement => (
  <div className="flex items-center mb-3 space-x-1">
    <Heading
      title="Open follow-ups"
      className="px-2 sm:px-0"
      level={2}
      size="medium"
    >
      Open follow-ups
    </Heading>
  </div>
);
