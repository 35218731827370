import { ReferenceSelectorV2 } from "@incident-shared/forms/v2/editors/ReferenceSelectorV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  StackedList,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { isEmpty } from "lodash";
import { plural, singular } from "pluralize";
import React, { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ErrorMessage } from "src/components/@shared/forms/ErrorMessage";
import {
  AvailableExpressionOperation as AvailableOperation,
  AvailableExpressionOperationOperationTypeEnum as AvailableOperationTypeEnum,
  CastType,
  EngineScope,
  ExpressionOperation,
  ExpressionOperationOperationTypeEnum as OperationType,
  ExpressionParseOpts,
  Resource,
} from "src/contexts/ClientContext";
import { lookupInScope } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { CreateEditExpressionFormData } from "../AddEditExpressionModal";
import { ExpressionFixedResultType } from "../ifelse/createDefaultExpressionFormValues";
import { ComponentForOperation } from "./ComponentForOperation";
import { UNKNOWN_TYPE } from "./QueryExpressionEditModal";
import { QueryPreviewSelector, usePreviewResults } from "./QueryPreview";
import {
  ReferenceWithConfig,
  useGetPreviousReference,
} from "./useGetPreviousReference";

export const getOperationIconStyles = (
  operationType: OperationType,
  large = false,
) => {
  return {
    // This ensures the icons have consistent size
    // "large" makes them big in the operation dropdown menu
    "w-4 h-4": operationType !== OperationType.Navigate && !large,
    "w-6 h-6": operationType === OperationType.Navigate && large,
  };
};

type QueryOperationsEditorProps = {
  scope: EngineScope;
  resources: Resource[];
  fixedResult?: ExpressionFixedResultType;
  fixedRootReference?: string;
  allowAllOfACatalogType: boolean;
  allowedOperations?: AvailableOperationTypeEnum[]; // if left empty, all operations are valid
  validateReturnType?: (resource: string, isArray: boolean) => boolean;
};

export const QueryOperationsEditor = ({
  scope,
  resources,
  fixedResult,
  fixedRootReference,
  allowAllOfACatalogType,
  allowedOperations,
  validateReturnType,
}: QueryOperationsEditorProps): React.ReactElement => {
  const formMethods = useFormContext<CreateEditExpressionFormData>();
  const [selectedRootReference, operations] = formMethods.watch([
    "root_reference",
    "operations",
  ]);

  const { append, remove, update, fields } = useFieldArray({
    control: formMethods.control,
    name: "operations",
  });

  // We want to clear errors whenever we add or remove any operation.
  useEffect(() => {
    formMethods.clearErrors();
    // This infinite loops if we include formMethods in the deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  if (fixedRootReference) {
    if (!selectedRootReference) {
      formMethods.setValue<"root_reference">(
        "root_reference",
        fixedRootReference,
      );
    }
  }

  const appendOperation = useCallback(
    (operation: AvailableOperation) => {
      append({
        operation_type: operation.operation_type as unknown as OperationType,
        // We use UNKNOWN_TYPE as a placeholder while we're waiting to find out
        // what the result type will be. This is only applicable for the
        // navigate or parse operations, where the result type is variable.
        returns: {
          type: operation.fixed_return?.type || UNKNOWN_TYPE,
          array: operation.fixed_return?.array || false,
        },
        // We want to default any new parse operation to use $ as the source, so
        // the preview opens immediately.
        ...(operation.operation_type === AvailableOperationTypeEnum.Parse
          ? ({
              parse: {
                source: "$",
              },
            } as unknown as ExpressionParseOpts)
          : {}),
      });
    },
    [append],
  );

  const addOperation = (operation: AvailableOperation) => {
    appendOperation(operation);
  };

  const getPreviousRef = useGetPreviousReference({
    scope,
    resources,
    formMethods,
  });

  let availableOperations: AvailableOperation[] = [];
  let currentRef: ReferenceWithConfig | undefined = undefined;
  if (selectedRootReference) {
    currentRef = getPreviousRef();

    if (!currentRef) {
      throw new Error("Expected to find at least one ref");
    }
    if (currentRef.config) {
      if (currentRef.array) {
        availableOperations =
          allowedOperations && allowedOperations.length > 0
            ? currentRef.config?.expression_operations.array.filter((op) =>
                allowedOperations.includes(op.operation_type),
              )
            : currentRef.config?.expression_operations.array;
      } else {
        availableOperations =
          allowedOperations && allowedOperations.length > 0
            ? currentRef.config?.expression_operations.scalar.filter((op) =>
                allowedOperations.includes(op.operation_type),
              )
            : currentRef.config?.expression_operations.scalar;
      }
    }
  }

  // Validate that the result type is valid for the current operation
  let availableReturnTypeResources = resources;
  if (validateReturnType) {
    availableReturnTypeResources = availableReturnTypeResources.filter((r) => {
      if (currentRef) {
        return validateReturnType(r.type, currentRef.array);
      } else {
        return (
          validateReturnType(r.type, true) || validateReturnType(r.type, false)
        );
      }
    });
  }

  availableReturnTypeResources =
    availableReturnTypeResources.filter((r) => {
      return r.type !== "NotYetInvitedSlackUser";
    }) ?? [];

  // We build the root reference here with either the scope or the catalog type, if the type selected is of
  // "All [catalog type]". We need the root reference to power query previews.
  let isAllCatalogSearch = false;
  if (selectedRootReference?.startsWith("catalog.")) {
    isAllCatalogSearch = true;
  }

  const rootReference = lookupInScope(scope, selectedRootReference);

  const { setExampleInput, results: previewResults } = usePreviewResults({
    rootReference,
    scope,
    operations,
  });

  const exampleEnabled = true;

  return (
    <>
      <StackedList>
        <li>
          {/* Root reference */}
          <div className={"flex"}>
            <div className="flex-none p-4 w-[434px]">
              <div className="space-y-2">
                <ReferenceSelectorV2
                  scope={scope}
                  formMethods={formMethods}
                  name="root_reference"
                  label="What would you like to query?"
                  allowExpressions={false}
                  isSelectable={(entry) => {
                    // If we're not allowing all of a catalog type, we need to filter them out
                    if (
                      !allowAllOfACatalogType &&
                      entry.key.startsWith("catalog")
                    ) {
                      return false;
                    }

                    // Don't allow selecting the top-level 'catalog' node
                    if (entry.key === "catalog") {
                      return false;
                    }

                    // Filter out anything with no operations
                    const operations = entry.array
                      ? entry.resource.expression_operations.array
                      : entry.resource.expression_operations.scalar;
                    return operations.length > 0;
                  }}
                  disabled={!isEmpty(operations) || !!fixedRootReference}
                />
              </div>
            </div>
            {!isAllCatalogSearch && (
              <QueryPreviewSelector
                key={rootReference?.type}
                rootReference={rootReference}
                resources={resources}
                setExampleInput={setExampleInput}
              />
            )}
          </div>
        </li>
        {operations?.map((operation, idx) => {
          const operationType = operation.operation_type;
          const previousRef = getPreviousRef(idx);
          const operationOption = getOperationOptions({
            operationType: operationType,
            previousRefLabel: previousRef?.label || "",
            previousRefArray: previousRef?.array || false,
            capitalizeDescription: false,
          });

          const isFinalOperation = idx === operations.length - 1;

          const removeOperation = (idx) => {
            remove(idx);
          };

          return (
            <ComponentForOperation
              key={idx}
              scope={scope}
              resources={resources}
              operationType={operation.operation_type}
              operationIdx={idx}
              update={update}
              isEditable={isFinalOperation}
              operationOption={operationOption}
              isFinalOperation={isFinalOperation}
              removeOperation={() => removeOperation(idx)}
              exampleEnabled={exampleEnabled}
              exampleInput={previewResults[idx]}
              exampleResult={previewResults[idx + 1]}
            />
          );
        })}

        <DropdownMenu
          disabled={
            availableOperations.length === 0 ||
            !validateQueryOperationFilterFields(operations)
          }
          align="start"
          triggerButton={
            <Button
              analyticsTrackingId={null}
              className="flex !p-4 w-full !border-dashed rounded-br-none border-r-0 !rounded-t-none"
              icon={IconEnum.Add}
              theme={ButtonTheme.Naked}
            >
              Then...
            </Button>
          }
          menuClassName=""
        >
          {availableOperations.map((operation) => (
            <OperationDropdownOption
              key={operation.operation_type}
              operation={operation}
              addOperation={addOperation}
              previousRefLabel={currentRef?.label || "entry"}
            />
          ))}
        </DropdownMenu>
      </StackedList>
      <ErrorMessage
        errors={formMethods.formState.errors}
        name={"operations"}
        className="mt-1"
      />
      <ResultTypeExplanation
        hasAtLeastOneOperation={!isEmpty(operations)}
        currentRef={currentRef}
        fixedResult={fixedResult}
        availableReturnTypeResources={availableReturnTypeResources}
      />
    </>
  );
};

// ResultTypeExplanation displays a message explaining what the result type of the expression will be.
// If the expression has a 'fixed result' (i.e. it's being created contextually e.g. in Linear settings config)
// then we warn the user when the result of the expression doesn't match.
const ResultTypeExplanation = ({
  currentRef,
  fixedResult,
  hasAtLeastOneOperation,
  availableReturnTypeResources,
}: {
  currentRef?: ReferenceWithConfig;
  fixedResult?: ExpressionFixedResultType;
  hasAtLeastOneOperation?: boolean;
  availableReturnTypeResources?: Resource[];
  isCustomFieldExpression?: boolean;
}): React.ReactElement | null => {
  if (!fixedResult) {
    if (!currentRef) {
      return null;
    }

    // Check if the expression returns one of the available return types
    if (availableReturnTypeResources) {
      const refInAvailableResources = availableReturnTypeResources.find(
        (r) => r.type === currentRef.type,
      );

      if (!refInAvailableResources) {
        return (
          <Callout theme={CalloutTheme.Info} className="text-sm w-auto">
            This expression must return a text, number or catalog entry, but
            currently returns a{" "}
            <ResultTypeDescription
              typeLabel={currentRef.resultTypeLabel || currentRef.label}
              isArray={currentRef.array}
            />
            , which cannot be set on a custom field.
          </Callout>
        );
      }
    }

    return (
      <Callout theme={CalloutTheme.Plain} className="text-sm w-auto">
        This expression will return a{" "}
        <ResultTypeDescription
          typeLabel={currentRef.resultTypeLabel || currentRef.label}
          isArray={currentRef.array}
        />
      </Callout>
    );
  }

  if (!currentRef) {
    return (
      <Callout theme={CalloutTheme.Plain}>
        This expression must return a{" "}
        <ResultTypeDescription
          typeLabel={fixedResult.typeLabel}
          isArray={fixedResult.array}
        />
      </Callout>
    );
  }

  const currentRefIsCorrect = refMatchesFixedResult(currentRef, fixedResult);

  if (currentRefIsCorrect || !hasAtLeastOneOperation) {
    return (
      <Callout theme={CalloutTheme.Plain} className="text-sm w-auto">
        This expression will return a{" "}
        <ResultTypeDescription
          typeLabel={currentRef.resultTypeLabel || currentRef.label}
          isArray={currentRef.array}
        />
      </Callout>
    );
  }

  return (
    <Callout theme={CalloutTheme.Danger}>
      This expression must return a{" "}
      <ResultTypeDescription
        typeLabel={fixedResult.typeLabel}
        isArray={fixedResult.array}
      />
      , but currently returns a{" "}
      <ResultTypeDescription
        typeLabel={currentRef.resultTypeLabel || currentRef.label}
        isArray={currentRef.array}
      />
      .
    </Callout>
  );
};

export const refMatchesFixedResult = (
  ref: ReferenceWithConfig,
  fixedResult: ExpressionFixedResultType,
): boolean => {
  if (ref.type === fixedResult.type) {
    if (fixedResult.array) {
      // If we want an array, the ref can be a scalar or an array, the backend can handle
      // them both.
      return true;
    }
    // However, we can't take an array value and make it a scalar. So if the fixedResult is a scalar,
    // we need to check that the ref is also a scalar.
    if (!ref.array) {
      return true;
    }
  }

  // Check if we can cast instead
  const castTypes = ref.config?.cast_types || [];
  for (const castType of castTypes) {
    if (castTypeMatchesFixedResult(ref, fixedResult, castType)) {
      return true;
    }
  }
  return false;
};

const castTypeMatchesFixedResult = (
  ref: ReferenceWithConfig,
  fixedResult: ExpressionFixedResultType,
  castType: CastType,
): boolean => {
  if (castType.type !== fixedResult.type) {
    // Types don't match: definitely not useful!
    return false;
  }
  // Types do match, now we have to figure out if it's an array or not.
  // If castType.array = true, that means a single ref will cast to an array.
  // If castType.array = false, that means a single ref will cast to a single
  // of the cast type.

  if (fixedResult.array) {
    // If we want an array, it doesn't matter what the cast type is, we can always handle it - a scalar
    // is fine as part of an array (and the backend handles it the same way).
    return true;
  } else {
    // OK so now we definitely want a single result. If the ref is an array, we're automatically
    // screwed (arrays can't cast to a single resource)
    if (ref.array) {
      return false;
    }
    // Can we cast our single ref to a single of the type we want? If so, yay, we've found a match.
    if (!castType.array) {
      return true;
    }

    return false;
  }
};

export const ResultTypeDescription = ({
  typeLabel,
  isArray,
}: {
  typeLabel: string;
  isArray: boolean;
}): React.ReactElement => {
  if (isArray) {
    return (
      <>
        <span>list of </span>
        <span className="font-semibold">{plural(typeLabel)}</span>
      </>
    );
  }
  return (
    <>
      <span>single </span>
      <span className="font-semibold">{singular(typeLabel)}</span>
    </>
  );
};

export const validateQueryOperationFilterFields = (
  operations?: ExpressionOperation[],
): boolean => {
  // If we have no operations, they can't be invalid
  if (!operations || operations.length === 0) {
    return true;
  }
  const finalOperation = operations[operations.length - 1];
  // If we've created a filter operation, we need to actually be filtering on something
  // before we can add another step
  if (
    finalOperation.operation_type === OperationType.Filter &&
    !finalOperation.filter?.condition_groups?.[0]?.conditions?.length
  ) {
    return false;
  }
  return true;
};

const OperationDropdownOption = ({
  operation,
  addOperation,
  previousRefLabel,
}: {
  operation: AvailableOperation;
  addOperation: (operation: AvailableOperation) => void;
  previousRefLabel: string;
}): React.ReactElement => {
  const operationOpt = getOperationOptions({
    operationType: operation.operation_type as unknown as OperationType,
    previousRefLabel: previousRefLabel,
    capitalizeDescription: true,
  });

  return (
    <DropdownMenuItem
      label={operationOpt.label}
      analyticsTrackingId={null}
      onSelect={() => addOperation(operation)}
      key={operationOpt.value}
    >
      <div className="flex-center-y space-x-2 text-left p-1">
        <Icon
          id={operationOpt.icon as unknown as IconEnum}
          className={tcx(
            "mr-2 shrink-0",
            getOperationIconStyles(
              operation.operation_type as unknown as OperationType,
              true,
            ),
          )}
        />
        <div>
          <div className={"text-content-primary font-medium"}>
            {operationOpt.label}
          </div>
          <div className={"text-slate-700"}>{operationOpt.description}</div>
        </div>
      </div>
    </DropdownMenuItem>
  );
};

export const getOperationOptions = ({
  previousRefLabel,
  previousRefArray,
  operationType,
  capitalizeDescription,
}: {
  previousRefLabel: string;
  previousRefArray?: boolean;
  operationType: OperationType;
  capitalizeDescription: boolean;
}): SelectOption => {
  switch (operationType) {
    case OperationType.Navigate:
      return {
        value: OperationType.Navigate,
        label: "Navigate",
        description: (
          <span>{`${
            capitalizeDescription ? "N" : "n"
          }avigate to a related catalog entry`}</span>
        ),
        icon: IconEnum.ArrowRight,
      };
    case OperationType.Parse:
      return {
        value: OperationType.Parse,
        label: "Parse",
        description: (
          <span>{`${
            capitalizeDescription ? "E" : "e"
          }xtract fields from unstructured JSON data`}</span>
        ),
        icon: IconEnum.ArrowRight,
      };
    case OperationType.Filter:
      return {
        value: OperationType.Filter,
        label: previousRefArray ? "Filter" : "Continue if",
        description: previousRefArray ? (
          <span>
            {`${capitalizeDescription ? "F" : "f"}ilter to a subset of `}
            <span className="font-semibold">{plural(previousRefLabel)}</span>
          </span>
        ) : (
          <>
            <span className="font-semibold">{previousRefLabel}</span>
            <span>{` matches conditions`}</span>
          </>
        ),
        icon: IconEnum.Filter,
      };
    case OperationType.Max:
      return {
        value: OperationType.Max,
        label: "Maximum",
        description: (
          <span>
            {`${capitalizeDescription ? "T" : "t"}ake the most highly ranked `}
            <span className="!font-semibold">
              {singular(previousRefLabel)}
            </span>{" "}
            from this list
          </span>
        ),
        icon: IconEnum.ArrowCircleUp,
      };
    case OperationType.Min:
      return {
        value: OperationType.Min,
        label: "Minimum",
        description: (
          <span>
            {`${capitalizeDescription ? "T" : "t"}ake the least highly ranked `}
            <span className="!font-semibold">
              {singular(previousRefLabel)}
            </span>{" "}
            from this list
          </span>
        ),
        icon: IconEnum.ArrowCircleDown,
      };
    case OperationType.Random:
      return {
        value: OperationType.Random,
        label: "Random",
        description: (
          <span>
            {`${capitalizeDescription ? "C" : "c"}hoose a random `}
            <span className="!font-semibold">
              {singular(previousRefLabel)}
            </span>{" "}
            from the list
          </span>
        ),
        icon: IconEnum.Dice,
      };
    case OperationType.First:
      return {
        value: OperationType.First,
        label: "First",
        description: (
          <span>
            {`${capitalizeDescription ? "C" : "c"}hoose the first `}
            <span className="!font-semibold">
              {singular(previousRefLabel)}
            </span>{" "}
            from the list
          </span>
        ),
        icon: IconEnum.ArrowRight,
      };
    case OperationType.Count:
      return {
        value: OperationType.Count,
        label: "Count",
        description: (
          <span>
            {`${capitalizeDescription ? "C" : "c"}ount how many `}
            <span className="!font-semibold">
              {plural(previousRefLabel)}
            </span>{" "}
            there are
          </span>
        ),
        icon: IconEnum.Numeric,
      };
    case OperationType.Branches:
      throw new Error(
        "Branches operation is not supported in our dashboard yet, it still calls these 'if/else' expressions.",
      );
    default:
      assertUnreachable(operationType);
  }
  throw new Error("fallthrough in unreachable switch-case");
};
