import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { Icon, IconEnum, IconProps, IconSize } from "@incident-ui/Icon/Icon";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

const sizeToStyles = {
  [IconSize.XS]: "h-7 w-7 rounded",
  [IconSize.Small]: "h-7 w-7 rounded",
  [IconSize.Medium]: "h-10 w-10 rounded-2",
  [IconSize.Large]: "h-10 w-10 rounded-2",
  [IconSize.XL]: "h-10 w-10 rounded-2",
  [IconSize.XXL]: "h-12 w-12 rounded-2",
};

export type IconBadgeProps = {
  icon?: IconEnum;
  iconNode?: React.ReactNode;
  size: IconSize;
  iconProps?: Omit<IconProps, "id">;
  color: ColorPaletteEnum;
  hexColor?: string;
  className?: string;
  invert?: boolean;
};

export const IconBadge = React.forwardRef<HTMLDivElement, IconBadgeProps>(
  (
    {
      icon,
      iconNode,
      size,
      iconProps = {},
      color = ColorPaletteEnum.Slate,
      hexColor,
      className,
      invert = false,
      ...rest
    }: IconBadgeProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const palette = getColorPalette(color);
    let colorClasses = invert
      ? tcx(palette.invertBg, "text-white")
      : tcx(palette.background, palette.icon);

    if (hexColor) {
      colorClasses = "bg-white";
    }

    return (
      <div
        ref={ref}
        className={tcx(
          "flex items-center justify-center shrink-0",
          sizeToStyles[size],
          colorClasses,
          { "border border-stroke": color === ColorPaletteEnum.SlateOnWhite },
          className,
        )}
        {...rest}
        style={hexColor ? { color: hexColor } : undefined}
      >
        {icon ? <Icon id={icon} {...iconProps} size={size} /> : iconNode}
      </div>
    );
  },
);

IconBadge.displayName = "IconContainer";
