import { ErrorMessage } from "@hookform/error-message";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import {
  InputElementProps,
  parseProps,
} from "@incident-shared/forms/v2/formsv2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonTheme, ToastTheme } from "@incident-ui";
import { IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { uniq } from "lodash";
import pluralize from "pluralize";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CatalogType, useClient } from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { slugify } from "src/utils/utils";

export interface CatalogEntryBulkCreateEditorData {
  items: string[];
  rawItems: string;
}

export const CatalogEntryBulkCreateDrawer = ({
  refetchEntries,
  catalogType,
  onClose,
}: {
  refetchEntries: () => void;
  catalogType: CatalogType;
  onClose: () => void;
}) => {
  const showToast = useToast();

  const apiClient = useClient();

  const [createEntries, { saving }] = useMutation(
    async ({ items }: CatalogEntryBulkCreateEditorData) => {
      await apiClient.catalogBulkCreateEntries({
        bulkCreateEntriesRequestBody: {
          catalog_type_id: catalogType.id,
          catalog_entries: items.map((item) => ({
            name: item,
            external_id: slugify(item),
            attribute_values: {},
          })),
        },
      });
      return;
    },
    {
      onSuccess: async () => {
        refetchEntries();
        onClose();
        showToast({
          theme: ToastTheme.Success,
          title: "Created entries",
          toastSide: ToastSideEnum.Bottom,
        });
      },
    },
  );

  const formMethods = useForm<CatalogEntryBulkCreateEditorData>({
    defaultValues: {
      items: [],
      rawItems: "",
    },
  });

  return (
    <Drawer
      width="medium"
      onClose={onClose}
      className="overflow-y-hidden"
      warnWhenDirty
    >
      <DrawerContents>
        <DrawerTitle
          compact
          title={`Create multiple entries`}
          onClose={onClose}
          color={catalogType.color as unknown as ColorPaletteEnum}
          icon={catalogType.icon}
        />
        <DrawerBody>
          <FormV2
            formMethods={formMethods}
            id="bulk-create"
            onSubmit={createEntries}
          >
            <CatalogEntryBulkCreateEditor
              formMethods={formMethods}
              name="items"
              color={catalogType.color as unknown as ColorPaletteEnum}
              icon={catalogType.icon}
              pluralNoun={pluralize(catalogType.name)}
              label="Paste in the names of the entries you'd like to create."
              helptext="You'll be able to add other attributes to these entries after they've been created."
            />
          </FormV2>
        </DrawerBody>
        <DrawerFooter>
          <div className="flex justify-end gap-2">
            <Button analyticsTrackingId={null} onClick={onClose}>
              Cancel
            </Button>
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Primary}
              type="submit"
              loading={saving}
              form="bulk-create"
            >
              Create entries
            </Button>
          </div>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};

interface BulkCreateEditorProps {
  color: ColorPaletteEnum;
  icon: IconEnum;
  pluralNoun: string;
}

// CatalogEntryBulkCreateEditor creates a form that allows users to paste a list of entry names and shows a preview.
export const CatalogEntryBulkCreateEditor = (
  props: InputElementProps<
    CatalogEntryBulkCreateEditorData,
    BulkCreateEditorProps
  >,
) => {
  const { name, inputProps, wrapperProps } = parseProps(props);

  const [raw, parsed] = props.formMethods.watch(["rawItems", "items"]);

  const setValue = props.formMethods.setValue;

  useEffect(() => {
    setValue("items", parseBulkCreateString(raw));
  }, [setValue, raw]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-1">
        <div className="text-sm-bold">{wrapperProps.label}</div>
        <div className="text-xs text-content-secondary">
          {wrapperProps.helptext}
        </div>
        <ErrorMessage errors={props.formMethods.formState.errors} name={name} />
      </div>
      <div className="grid grid-cols-2 gap-3">
        <TextareaV2
          formMethods={props.formMethods}
          name="rawItems"
          rows={20}
          className="h-[440px] [&_textarea]:p-4"
          placeholder="Paste your list here, with one item on each line"
        />
        <div className="bg-surface-secondary p-4 rounded-2 flex flex-col gap-3 w-full h-[440px] overflow-y-auto overflow-x-hidden">
          <EntriesPreview parsed={parsed || []} raw={raw} {...inputProps} />
        </div>
      </div>
    </div>
  );
};

const EntriesPreview = ({
  parsed,
  raw,
  color,
  icon,
  pluralNoun,
}: {
  parsed: string[];
  raw: string;
  color: ColorPaletteEnum;
  icon: IconEnum;
  pluralNoun: string;
}) => {
  if (!raw || raw === "" || raw.trim() === "") {
    return (
      <div className="flex grow w-full text-sm-normal text-content-secondary">
        Your {pluralNoun} will appear here
      </div>
    );
  }

  if (parsed && parsed.length > 0) {
    return (
      <div className="flex flex-col gap-1">
        {parsed.map((service) => (
          <CatalogEntryBadge
            key={service}
            label={service}
            color={color}
            icon={icon}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="flex grow w-full items-center justify-center text-xs-med text-red-content">
      Items must be separated by a newline
    </div>
  );
};

const parseBulkCreateString = (raw?: string): string[] => {
  // First, split by newline
  if (!raw || raw === "") return [];

  const items = raw.split("\n");

  return uniq(
    items
      // Remove any empty strings
      .filter((item) => item !== "")
      // Trim L+R whitespace
      .map((item) => item.trim())
      // Remove any commas or semicolons
      .map((item) => item.replace(/[,;]/g, "")),
  );
};
