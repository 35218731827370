import { cooerceToProduct } from "@incident-shared/billing";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsDropdown } from "@incident-shared/engine/expressions/ExpressionsDropdown";
import { ExpressionDeletionUsages } from "@incident-shared/engine/expressions/ExpressionsEditor";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { FormElement } from "@incident-shared/incident-forms";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ConfirmationDialog,
  GenericErrorMessage,
  IconEnum,
  Loader,
  OrgAwareLink,
  SearchableDropdown,
  ToastTheme,
  Txt,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { SlackModalFooterButton } from "@incident-ui/SlackPreviews/SlackPreviews";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { keyBy } from "lodash";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router";
import { buildIncidentTypeOptions } from "src/components/legacy/incident/buildIncidentTypeOptions";
import { containsExpressionReference } from "src/components/legacy/workflows/common/utils";
import { LevelUpIncidentResponseBanner } from "src/components/settings/LevelUpIncidentResponseBanner";
import {
  AvailableIncidentFormLifecycleElement,
  AvailableIncidentFormLifecycleElementElementTypeEnum as ElementTypeEnum,
  IncidentForm,
  IncidentFormEscalationElement,
  IncidentFormFormTypeEnum as IncidentFormType,
  IncidentFormLifecycleElement,
  IncidentFormLifecycleElementRequiredIfEnum,
  IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum,
  IncidentRoleRoleTypeEnum,
  IncidentsBuildScopeContextEnum,
  IncidentType,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useSettings } from "src/hooks/useSettings";
import { formatDurationInSeconds } from "src/utils/datetime";
import { useIncidentTriageIncidentsFeatureGateEnabled } from "src/utils/incident-manual-triage";

import { useAPI, useAPIMutation } from "../../../../../utils/swr";
import { SettingsSortableList } from "../../../SettingsSortableList";
import { FORM_TYPE_CONFIG, FormTypeConfig } from "../../common/config";
import { IncidentFormElementListRow } from "../IncidentFormElementListRow";
import { SlackFormBorder, SlackFormHeaderRow } from "../Slack";
import { useIncidentFormExpressionsMethods } from "../useIncidentFormExpressionsMethods";
import { useIncidentFormPreviewBindings } from "../useIncidentFormPreviewBindings";
import {
  IncidentFormElementCreateEditModal,
  IncidentFormElementFormData,
  transformFormStateToCreateRequestBody,
} from "./IncidentLifecycleFormElementCreateEditModal";

export const IncidentLifecycleFormsEditPage = (): React.ReactElement => {
  const { id: formId } = useParams<{ id: string }>();

  if (!formId) {
    throw new Error("Missing form id");
  }

  const {
    data: formData,
    error: formError,
    isLoading: formLoading,
  } = useAPI("incidentFormsShowForm", { id: formId });

  const {
    data: { incident_form_elements: elements },
    error: elementsError,
    isLoading: elementsLoading,
  } = useAPI(
    "incidentFormsListLifecycleElements",
    { incidentFormId: formId },
    { fallbackData: { incident_form_elements: [] } },
  );

  const {
    data: { incident_types: incidentTypes },
    isLoading: incidentTypesLoading,
    error: incidentTypesError,
  } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });

  if (formError || elementsError || incidentTypesError) {
    return (
      <GenericErrorMessage
        error={formError || elementsError || incidentTypesError}
      />
    );
  }

  if (formLoading || elementsLoading || incidentTypesLoading) {
    return <FullPageLoader />;
  }

  if (!formData) {
    throw new Error("unreachable: form not loading, but data is null");
  }

  const formTypeConfig = FORM_TYPE_CONFIG[formData.form.form_type];

  const formIncidentType = incidentTypes.find(
    ({ id }) => id === formData.form.incident_type_id,
  );

  return (
    <SecondaryNavSubPageWrapper
      icon={formTypeConfig.icon}
      title={`Edit ${
        formIncidentType ? formIncidentType.name + " " : ""
      }${formTypeConfig.label.toLowerCase()} form`}
      crumbs={[{ title: "Forms", to: "/settings/forms" }]}
      backHref="/settings/forms"
    >
      <IncidentLifecycleFormsEditForm
        elements={elements}
        form={formData.form}
        formIncidentType={formIncidentType}
        incidentTypes={incidentTypes}
      />
    </SecondaryNavSubPageWrapper>
  );
};

type CreateEditProps = CreateEditFormProps<IncidentFormLifecycleElement> & {
  availableElement: AvailableIncidentFormLifecycleElement;
};

const IncidentLifecycleFormsEditForm = ({
  elements,
  form,
  formIncidentType,
  incidentTypes,
}: {
  elements: IncidentFormLifecycleElement[];
  form: IncidentForm;
  formIncidentType: IncidentType | undefined;
  incidentTypes: IncidentType[];
}): React.ReactElement => {
  const [createEditElementProps, setCreateEditElementProps] =
    useState<CreateEditProps | null>(null);
  const showToast = useToast();

  const featureGateTriageIncidents =
    useIncidentTriageIncidentsFeatureGateEnabled();

  const { mutate: refetchAvailableElements } = useAPI(
    "incidentFormsListAvailableLifecycleElements",
    {
      incidentFormId: form.id,
    },
  );

  const { hasScope } = useIdentity();

  const { hasProduct } = useProductAccess();

  const { settings } = useSettings();

  const canModifyScope = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const {
    elementBindings: previewBindings,
    refreshPreview: refetchPreview,
    isLoading: previewIsLoading,
    formMethods: previewFormMethods,
  } = useIncidentFormPreviewBindings({ form, incidentTypes });

  const previewBindingsByElementID = keyBy(
    previewBindings,
    (binding) => binding.element.id,
  );

  const { trigger: onDelete } = useAPIMutation(
    "incidentFormsListLifecycleElements",
    { incidentFormId: form.id },
    async (apiClient, { id }: { id: string }) => {
      await apiClient.incidentFormsDestroyLifecycleElement({ id });
    },
    {
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Error deleting field",
        });
      },
      onSuccess: () => {
        refetchAvailableElements();
        refetchPreview();
      },
    },
  );

  const { trigger: updateElementRanks, isMutating: saving } = useAPIMutation(
    "incidentFormsListLifecycleElements",
    { incidentFormId: form.id },
    async (apiClient, data: IncidentFormLifecycleElement[]) => {
      const rankUpdates = elements
        .filter((el) => el.available_element.can_be_reordered)
        .map((field) => {
          let rank = field.rank;
          const updated = data.find(({ id }) => id === field.id);
          if (updated) {
            rank = updated.rank;
          }
          return {
            resource_id: field.id,
            rank,
          };
        });

      return await apiClient.incidentFormsUpdateLifecycleRanks({
        id: form.id,
        updateLifecycleRanksRequestBody: {
          rank_updates: rankUpdates,
        },
      });
    },
  );

  const onClose = () => {
    refetchAvailableElements();
    setCreateEditElementProps(null);
    refetchPreview();
  };

  const { trigger: onAddDivider } = useAPIMutation(
    "incidentFormsListLifecycleElements",
    { incidentFormId: form.id },
    async (
      apiClient,
      data: Omit<IncidentFormElementFormData, "id" | "rank">,
    ) => {
      await apiClient.incidentFormsCreateLifecycleElement({
        createLifecycleElementRequestBody:
          transformFormStateToCreateRequestBody(data, {
            element_type: ElementTypeEnum.Divider,
          }),
      });
      return;
    },
    { onSuccess: () => onClose() },
  );

  const onAdd = (availableElement: AvailableIncidentFormLifecycleElement) => {
    // This is so when you add a divider, the add modal doesn't trigger. There
    // is nothing inside of a divider that could be edited so this modal would
    // be empty
    if (availableElement.element_type === ElementTypeEnum.Divider) {
      onAddDivider({
        available_element: availableElement,
        can_select_no_value: false,
        incident_form_id: form.id,
        required_if: IncidentFormLifecycleElementRequiredIfEnum.NeverRequire,
        required_if_condition_groups: [],
        show_if_condition_groups: [],
        show_if: "always",
      });
    } else {
      setCreateEditElementProps({
        mode: Mode.Create,
        availableElement: availableElement,
      });
    }
  };

  const onEdit = (element: IncidentFormLifecycleElement) => {
    setCreateEditElementProps({
      mode: Mode.Edit,
      initialData: element,
      availableElement: element.available_element,
    });
  };

  const formTypeConfig = FORM_TYPE_CONFIG[form.form_type];

  const hasNoManualTriageIncidentsEnabled =
    form.form_type === IncidentFormType.Accept &&
    !settings?.misc?.manual_triage_incidents_enabled;

  const isAcceptFormWhenOrgDoesntHaveTriage =
    !featureGateTriageIncidents && form.form_type === IncidentFormType.Accept;

  const { expressionsMethods, genericError: expressionsError } =
    useIncidentFormExpressionsMethods({
      expressions: form.expressions,
      incidentFormId: form.id,
    });

  const { scope, scopeLoading } = useIncidentScope(
    form.form_type === IncidentFormType.Declare
      ? IncidentsBuildScopeContextEnum.IncidentDeclareForm
      : IncidentsBuildScopeContextEnum.IncidentForm,
  );

  const scopeWithExpressions = addExpressionsToScope(
    scope,
    expressionsMethods.fields,
  );

  if (previewIsLoading || scopeLoading) {
    return <Loader />;
  }

  const backHref = form.incident_type_id
    ? `/settings/forms/?incident_type=${form.incident_type_id}`
    : "/settings/forms";

  const defaultIncidentType = incidentTypes.find((x) => x.is_default);

  const { selectedIncidentType: incidentType } = buildIncidentTypeOptions({
    selectedIncidentTypeID: form.incident_type_id || defaultIncidentType?.id,
    incidentTypes,
    includeNotYetKnownOption: false,
  });

  const isDeclareForm = form.form_type === IncidentFormType.Declare;

  const activeOrTriage = isDeclareForm
    ? previewFormMethods.watch("active_or_triage")
    : undefined;

  const getExpressionUsages = (
    expression: ExpressionFormData,
  ): ExpressionDeletionUsages => {
    const containsExpressionRef = (serialisable: unknown) =>
      containsExpressionReference(expression, serialisable);

    // Loop through each element, and check if it contains the expression reference. If it does, add it to the usages array.
    return elements.filter(containsExpressionRef).map((element) => ({
      label: "Element",
      usages: [element.available_element.label],
    }));
  };
  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionsMethods}
      allowAllOfACatalogType={false}
    >
      {/* We add a load of margin so you can scroll down the page past the last element */}
      <div className="space-y-4 mb-60">
        {hasNoManualTriageIncidentsEnabled && <TriageFormCallout />}
        {isDeclareForm && (
          <FormTimeEstimateCallout
            config={formTypeConfig}
            elementCount={elements.length}
          />
        )}
        {expressionsError && (
          <GenericErrorMessage description={expressionsError} />
        )}
        <FormProvider {...previewFormMethods}>
          <div className="space-y-6">
            <div className="relative">
              <SlackFormBorder />
              <SettingsSortableList
                items={elements}
                canEdit={true}
                updateItemRanks={updateElementRanks}
                saving={saving}
                className="shadow-none [&>li]:!border-x-0 [&>li]:!border-dashed"
                dragHandleAtTop
                dragHandleClassName="!mt-5"
                border={false}
                isItemDragDisabled={(item) =>
                  !item.available_element.can_be_reordered
                }
                blockSortingFirstNItems={
                  elements.filter(
                    ({ available_element }) =>
                      !available_element.can_be_reordered,
                  ).length
                }
                itemClassName="bg-white"
                headerRow={
                  <SlackFormHeaderRow
                    title={`${formTypeConfig.label} incident${
                      formIncidentType?.name
                        ? ` (${formIncidentType?.name})`
                        : ""
                    }`}
                    backHref={backHref}
                    topRightNode={
                      <div className="flex gap-2 justify-end flex-wrap pb-2">
                        <ExpressionsDropdown
                          scopeWithExpressions={scopeWithExpressions}
                          getExpressionUsages={getExpressionUsages}
                        />
                        <DeleteFormButton form={form} />
                      </div>
                    }
                  />
                }
                renderItem={(element) => {
                  const cannotDeleteReason = !canModifyScope
                    ? "You do not have permission"
                    : !element.available_element.can_be_hidden
                    ? "This field cannot be deleted"
                    : !hasProduct(cooerceToProduct(form.required_product))
                    ? "You do have the required product to edit this form"
                    : undefined;

                  return (
                    <IncidentFormElementListRow
                      key={element.id}
                      onEdit={() => onEdit(element)}
                      onDelete={() => onDelete(element)}
                      canEdit={
                        !isAcceptFormWhenOrgDoesntHaveTriage &&
                        !elementIsDivider(element) &&
                        canModifyScope &&
                        hasProduct(cooerceToProduct(form.required_product))
                      }
                      label={element.available_element.label}
                      canDelete={cannotDeleteReason === undefined}
                      cannotDeleteReason={cannotDeleteReason}
                      showIfText={showIfText(element)}
                      requiredIfText={requiredIfText(element)}
                      formElement={
                        // When we add a new form element, there's a brief period before we've
                        // refetched the preview bindings where we can't render the new element.
                        // In that case, we simply render nothing while we wait. It might be nice
                        // in future to add a fancy skeleton here.
                        previewBindingsByElementID[element.id] ? (
                          <FormElement
                            incidentFormType={
                              form.form_type as unknown as IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum
                            }
                            formMethods={previewFormMethods}
                            elementBinding={
                              previewBindingsByElementID[element.id]
                            }
                            selectedIncidentType={incidentType}
                            activeOrTriage={activeOrTriage}
                            dontAutoFocusOverride
                            customFieldEntryPayloads={[]}
                          />
                        ) : null
                      }
                    />
                  );
                }}
              />
              <div className="grid grid-cols-3 w-full pl-[43px]">
                <div className="flex items-end justify-between col-span-2">
                  {!isAcceptFormWhenOrgDoesntHaveTriage && (
                    <div className="p-4 py-6 border-b border-dashed border-stroke flex items-center z-20">
                      <AddFieldDropdownButton formId={form.id} onAdd={onAdd} />
                    </div>
                  )}
                  <div className="flex items-center justify-end gap-2 p-4">
                    <SlackModalFooterButton>Cancel</SlackModalFooterButton>
                    <SlackModalFooterButton primary>
                      {formTypeConfig.label}
                    </SlackModalFooterButton>
                  </div>
                </div>
              </div>
            </div>
            <LevelUpIncidentResponseBanner />
          </div>
        </FormProvider>
        {!isDeclareForm && (
          <TooManyFieldsCallout
            config={formTypeConfig}
            elementCount={elements.length}
          />
        )}
      </div>
      {createEditElementProps != null && (
        <IncidentFormElementCreateEditModal
          {...createEditElementProps}
          formID={form.id}
          onClose={onClose}
          incidentType={incidentType}
          scope={scopeWithExpressions}
        />
      )}
    </ExpressionsMethodsProvider>
  );
};

const TooManyFieldsCallout = ({
  config,
  elementCount,
}: {
  config: FormTypeConfig;
  elementCount: number;
}): React.ReactElement | null => {
  const threshold = config.tooManyFieldsThreshold;

  const diff = elementCount - threshold;
  if (diff <= 0) {
    return null;
  }

  let type = CalloutTheme.Warning;
  let recommendation = "We recommend";
  if (diff > 3) {
    type = CalloutTheme.Danger;
    recommendation = "We strongly recommend";
  }

  return (
    //  Make a box of the same width with 3 cols so it aligns with the form
    <div className="pl-[43px] grid grid-cols-3 w-full">
      <Callout theme={type} className="col-span-2">
        {`You currently have more than ${threshold} fields on your ${config.label.toLowerCase()} form.
         ${recommendation} reducing the number of fields to ensure
         incidents can be ${config.verb} efficiently.`}
      </Callout>
    </div>
  );
};

const TriageFormCallout = () => {
  return (
    <div className="pl-[43px] grid grid-cols-3 w-full">
      <Callout theme={CalloutTheme.Info} className="col-span-2">
        {`Incidents created from alerts will start in triage. To allow triage incidents to be declared manually, go to `}
        <OrgAwareLink
          to={"/settings/lifecycle?tab=lifecycles"}
          target="_blank"
          className="underline"
        >
          Settings → Lifecycle
        </OrgAwareLink>
        {`.`}
      </Callout>
    </div>
  );
};

const FormTimeEstimateCallout = ({
  config,
  elementCount,
}: {
  config: FormTypeConfig;
  elementCount: number;
}): React.ReactElement | null => {
  // This quick maths is based on a linear regression exploring the relationship between
  // the number of custom fields and the amount of time it takes to declare an incident (in seconds).
  // More information here: https://www.notion.so/Your-incident-declare-form-is-probably-too-long-the-power-of-concise-reporting-26cb85b7385942b881295b8c4f675ea4

  // This is a hand-wavey average. It is not possible to equal the average time given the formula, hence no === case below.
  const avgTime = 60;
  const baseTime = 29.77;
  const slope = 5.33;
  const estimatedTime = Math.round(baseTime + elementCount * slope);
  const diff = Math.abs(Math.round(avgTime - estimatedTime));
  const formattedDiff = formatDurationInSeconds(diff);
  const formattedEstimate = formatDurationInSeconds(estimatedTime);
  let type = CalloutTheme.Success;
  let recommendation = "";
  let comparison: React.ReactNode;

  if (avgTime > estimatedTime) {
    comparison = (
      <>
        about <span className="font-semibold">{formattedDiff}</span> less than
        average
      </>
    );
    recommendation = "Great job!";
  } else if (avgTime < estimatedTime) {
    comparison = (
      <>
        about <span className="font-semibold">{formattedDiff}</span> more than
        average
      </>
    );

    type = diff > 10 ? CalloutTheme.Danger : CalloutTheme.Warning;
    recommendation = `Consider removing some fields, so that incidents can be ${config.verb} efficiently.`;
  }

  return (
    //  Make a box of the same width with 3 cols so it aligns with the form
    <div className="pl-[43px] grid grid-cols-3 w-full">
      <Callout
        theme={type}
        iconOverride={IconEnum.Timer}
        className="col-span-2"
      >
        <>
          With this form, we estimate it will take your responders
          <span className="font-semibold"> {formattedEstimate} </span>
          to declare an incident (that&apos;s {comparison}). {recommendation}
        </>
      </Callout>
    </div>
  );
};

const AddFieldDropdownButton = ({
  formId,
  onAdd,
}: {
  formId: string;
  onAdd: (el: AvailableIncidentFormLifecycleElement) => void;
}): React.ReactElement => {
  const {
    data: { available_elements: elements },
    error,
  } = useAPI(
    "incidentFormsListAvailableLifecycleElements",
    { incidentFormId: formId },
    { fallbackData: { available_elements: [] } },
  );
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <SearchableDropdown
      onSelectItem={onAdd}
      emptyState="There are no more fields to add"
      entries={elements.map((element) => {
        const groupLabel = titleFromElementType(element.element_type);
        return {
          item: element,
          label: element.label,
          icon: element.icon as unknown as IconEnum,
          group: groupLabel
            ? {
                label: groupLabel,
                name: groupLabel,
              }
            : undefined,
          sortKey: sortKeyFromElement(element),
        };
      })}
      renderTriggerButton={({ onClick }) => (
        <Button
          icon={IconEnum.Add}
          onClick={onClick}
          analyticsTrackingId="form-elements-add-element"
          theme={ButtonTheme.Naked}
        >
          Add another field
        </Button>
      )}
    />
  );
};

const sortKeyFromElement = (
  element: AvailableIncidentFormLifecycleElement,
): string | undefined => {
  switch (element.element_type) {
    case ElementTypeEnum.Divider:
    case ElementTypeEnum.Text:
      return "02";
    case ElementTypeEnum.CustomField:
      return "03" + element.custom_field?.rank;
    case ElementTypeEnum.IncidentRole:
      return (
        "04" +
        (element.incident_role?.role_type === IncidentRoleRoleTypeEnum.Lead
          ? "1"
          : "2")
      );
    case ElementTypeEnum.Timestamp:
      return "05" + element.incident_timestamp?.rank;
  }
  return "01" + element.label;
};

const titleFromElementType = (
  elementType: ElementTypeEnum,
): string | undefined => {
  switch (elementType) {
    case ElementTypeEnum.CustomField:
      return "Custom fields";
    case ElementTypeEnum.IncidentRole:
      return "Incident roles";
    case ElementTypeEnum.Timestamp:
      return "Incident timestamps";
    case ElementTypeEnum.Divider:
    case ElementTypeEnum.Text:
      return "Layout";
  }
  return undefined;
};

export const DeleteFormButton = ({
  form,
}: {
  form: IncidentForm;
}): React.ReactElement | null => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const navigate = useOrgAwareNavigate();
  const { hasProduct } = useProductAccess();
  const config = FORM_TYPE_CONFIG[form.form_type];

  const { hasScope, isImpersonating } = useIdentity();
  const canDelete =
    hasScope(ScopeNameEnum.IncidentFormsUpdate) &&
    !isImpersonating &&
    hasProduct(cooerceToProduct(form.required_product));

  const { trigger: onDelete, isMutating: deleting } = useAPIMutation(
    "incidentFormsListForms",
    undefined,
    async (apiClient) => {
      await apiClient.incidentFormsDestroyForm({ id: form.id });
    },
    {
      onSuccess: () =>
        navigate(`/settings/forms?incident_type=${form.incident_type_id}`),
    },
  );

  // Use the list incident types API as that's what we use all over the app, so saves us
  // making an extra call for this specific type.
  const {
    data: { incident_types: incidentTypes },
    isLoading,
    error,
  } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });
  if (error) {
    throw error;
  }

  if (!form.incident_type_id) {
    // you can't delete a non-incident-type form!
    return null;
  }
  const incidentType = incidentTypes.find(
    (t) => t.id === form.incident_type_id,
  );

  return (
    <>
      <ConfirmationDialog
        title={`Delete form`}
        analyticsTrackingId="delete-incident-form"
        isOpen={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={() => onDelete({})}
        saving={deleting}
        confirmButtonTheme={ButtonTheme.DestroySecondary}
        loading={isLoading}
      >
        <Txt>
          Are you sure that you want to delete the{" "}
          <Txt inline bold>
            {config.label}
          </Txt>{" "}
          form for{" "}
          <Txt inline bold>
            {incidentType?.name}
          </Txt>{" "}
          incidents? <br />
          This will mean we use the global form configuration instead.
        </Txt>
      </ConfirmationDialog>
      <div className="text-left">
        <Button
          analyticsTrackingId="delete-incident-form"
          theme={ButtonTheme.DestroySecondary}
          icon={IconEnum.Delete}
          onClick={() => setShowDeleteDialog(true)}
          disabled={!canDelete}
        >
          Delete form
        </Button>
      </div>
    </>
  );
};

const requiredIfText = (element: IncidentFormLifecycleElement) => {
  switch (element.required_if) {
    case IncidentFormLifecycleElementRequiredIfEnum.AlwaysRequire:
      return "Always required";
    case IncidentFormLifecycleElementRequiredIfEnum.CheckEngineConfig:
      return "Conditionally required";
  }
  return "";
};

const showIfText = (element: IncidentFormLifecycleElement) => {
  if (
    element.available_element.can_be_hidden &&
    (element.show_if_condition_groups[0]?.conditions?.length > 0 ||
      element.available_element.show_if_fixed_condition_groups[0]?.conditions
        ?.length > 0)
  ) {
    return "Conditionally hidden";
  }
  return "";
};

export const elementIsDivider = (
  element: IncidentFormLifecycleElement | IncidentFormEscalationElement,
) => {
  return element.available_element.element_type === ElementTypeEnum.Divider;
};
