import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  BlurFilter,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Heading,
  IconEnum,
  IconSize,
  Loader,
} from "@incident-ui";
import { ErrorBoundary } from "@sentry/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useNotifications } from "src/components/notifications/useNotifications";
import { tcx } from "src/utils/tailwind-classes";
import { useEventListener } from "use-hooks";

import styles from "./Notifications.module.scss";
import { NotificationList } from "./NotificationsList";

type Props = {
  isOpen: boolean;
  closeSidebar: () => void;
  children?: React.ReactNode;
};

export const NotificationsBar = ({
  isOpen,
  closeSidebar,
}: Props): React.ReactElement => {
  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "Esc" || e.key === "Escape") {
      closeSidebar();
    }
  });

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            onClick={closeSidebar}
            className={tcx(styles.overlay, {
              [styles.overlayVisible]: isOpen,
            })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          />

          <BlurFilter visible={isOpen} />
          <motion.div
            initial={{ x: -500, zIndex: 39 }}
            animate={{ x: 0, zIndex: 39 }}
            exit={{ x: -500, zIndex: 39 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <aside
              className={tcx(
                styles.notificationsBar,
                {
                  [styles.open]: isOpen,
                },
                "text-content-primary text-sm",
              )}
            >
              <NotificationContent closeSidebar={closeSidebar} />
            </aside>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export const NotificationContent = ({
  closeSidebar,
}: {
  closeSidebar: () => void;
}): React.ReactElement => {
  const {
    notifications,
    fetchMoreNotifications,
    loadingInitialNotifications,
    loadingMoreNotifications,
    hasReturnedAllNotifications,
  } = useNotifications();

  const navigate = useOrgAwareNavigate();
  return (
    <div>
      <div
        className={tcx(
          "flex justify-between items-center p-6",
          styles.notificationsHeader,
        )}
      >
        <div className="flex items-center">
          <Heading size="2xl" level={2}>
            Notifications
          </Heading>
          <Button
            icon={IconEnum.Cog}
            title="Notification preferences"
            analyticsTrackingId="notification-preferences"
            theme={ButtonTheme.Naked}
            className={tcx("ml-1", styles.settingsButton)}
            iconProps={{ size: IconSize.Large }}
            onClick={() => {
              navigate("/user-preferences/notifications");
              closeSidebar();
            }}
          />
        </div>
        <Button
          analyticsTrackingId="notifications-bar-close"
          theme={ButtonTheme.Naked}
          icon={IconEnum.Close}
          iconProps={{ size: IconSize.Medium }}
          title="Close sidebar"
          onClick={closeSidebar}
        />
      </div>
      <div className="border-b border-stroke" />
      {!loadingInitialNotifications && notifications ? (
        <ErrorBoundary fallback={<GenericErrorMessage />}>
          <NotificationList
            notifications={notifications}
            fetchMoreNotifications={fetchMoreNotifications}
            loadingMoreNotifications={loadingMoreNotifications}
            hasReturnedAllNotifications={hasReturnedAllNotifications}
            closeSidebar={closeSidebar}
          />
        </ErrorBoundary>
      ) : (
        <Loader />
      )}
    </div>
  );
};
