import { IconEnum } from "@incident-ui";
import {
  Attachment,
  AttachmentContexts,
} from "src/components/legacy/incident/attachments/IncidentAttachment";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemPagerDutyUser } from "src/components/timeline/timeline-items/TimelineItemPagerdutyIncidentAcknowledged";
import { TimelineItemContentBox } from "src/components/timeline/TimelineItemContentBox";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

export const TimelineItemPagerdutyIncidentResolved = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.pagerduty_incident_resolved) {
    throw new Error(
      "malformed timeline item: pagerduty_incident_resolved was missing pagerduty_incident_resolved field",
    );
  }

  return {
    icon: IconEnum.Attachment,
    avatarUrl: item.pagerduty_incident_resolved?.resolver?.user?.avatar_url,
    description: (
      <>
        <TimelineItemPagerDutyUser
          actor={item.pagerduty_incident_resolved.resolver}
        />{" "}
        resolved an attached{" "}
        {item.pagerduty_incident_resolved.external_resource.resource_type_label}
      </>
    ),
    children: (
      <TimelineItemContentBox>
        <Attachment
          context={AttachmentContexts.Timeline}
          resource={item.pagerduty_incident_resolved.external_resource}
        />
      </TimelineItemContentBox>
    ),
  };
};
