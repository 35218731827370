import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormStateReturn,
} from "react-hook-form";

import { FormInputWrapper } from "../FormInputHelpers";

export type Rules = Omit<
  RegisterOptions,
  "valueAsNumber" | "valueAsDate" | "setValueAs"
>;

type RenderFn<FormType extends FieldValues> = ({
  field,
  fieldState,
  formState,
}: {
  field: ControllerRenderProps<FormType>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FormType>;
}) => React.ReactElement;

export type ControlledInputProps<FormType extends FieldValues> = {
  id: Path<FormType>;
  errors: FieldErrors;
  label?: React.ReactNode;
  helptext?: string | React.ReactElement;
  control: Control<FormType>;
  rules?: Rules;
  required?: boolean;
  isClearable?: boolean;
  requiredErrorMessage?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  suffixNode?: React.ReactNode;
};

export const ControlledInputWrapper = <FormType extends FieldValues>({
  id,
  errors,
  label,
  helptext,
  render,
  control,
  rules,
  required,
  requiredErrorMessage,
  className,
  suffixNode,
}: ControlledInputProps<FormType> & {
  render: RenderFn<FormType>;
}): React.ReactElement => (
  <FormInputWrapper
    id={id}
    errors={errors}
    label={label}
    helptext={helptext}
    className={className}
    suffixNode={suffixNode}
  >
    <Controller
      control={control}
      name={id}
      render={render}
      rules={{
        ...rules,
        required: required
          ? requiredErrorMessage ?? "This field is required"
          : false,
      }}
    />
  </FormInputWrapper>
);
