/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InsightsCreateDashboardRequestBody,
    InsightsCreateDashboardRequestBodyFromJSON,
    InsightsCreateDashboardRequestBodyToJSON,
    InsightsCreateDashboardResponseBody,
    InsightsCreateDashboardResponseBodyFromJSON,
    InsightsCreateDashboardResponseBodyToJSON,
    InsightsGenerateExploJWTRequestBody,
    InsightsGenerateExploJWTRequestBodyFromJSON,
    InsightsGenerateExploJWTRequestBodyToJSON,
    InsightsGenerateExploJWTResponseBody,
    InsightsGenerateExploJWTResponseBodyFromJSON,
    InsightsGenerateExploJWTResponseBodyToJSON,
    InsightsGetCalendarResponseBody,
    InsightsGetCalendarResponseBodyFromJSON,
    InsightsGetCalendarResponseBodyToJSON,
    InsightsListCatalogEntryOptionsResponseBody,
    InsightsListCatalogEntryOptionsResponseBodyFromJSON,
    InsightsListCatalogEntryOptionsResponseBodyToJSON,
    InsightsListCatalogTypesResponseBody,
    InsightsListCatalogTypesResponseBodyFromJSON,
    InsightsListCatalogTypesResponseBodyToJSON,
    InsightsListCustomFieldOptionsResponseBody,
    InsightsListCustomFieldOptionsResponseBodyFromJSON,
    InsightsListCustomFieldOptionsResponseBodyToJSON,
    InsightsListDashboardsResponseBody,
    InsightsListDashboardsResponseBodyFromJSON,
    InsightsListDashboardsResponseBodyToJSON,
    InsightsShowDashboardResponseBody,
    InsightsShowDashboardResponseBodyFromJSON,
    InsightsShowDashboardResponseBodyToJSON,
    InsightsUpdateDashboardRequestBody,
    InsightsUpdateDashboardRequestBodyFromJSON,
    InsightsUpdateDashboardRequestBodyToJSON,
    InsightsUpdateDashboardResponseBody,
    InsightsUpdateDashboardResponseBodyFromJSON,
    InsightsUpdateDashboardResponseBodyToJSON,
} from '../models';

export interface InsightsCreateDashboardRequest {
    createDashboardRequestBody: InsightsCreateDashboardRequestBody;
}

export interface InsightsDeleteDashboardRequest {
    id: string;
}

export interface InsightsGenerateExploJWTRequest {
    generateExploJWTRequestBody: InsightsGenerateExploJWTRequestBody;
}

export interface InsightsGetCalendarRequest {
    timezone?: string;
}

export interface InsightsListCatalogEntryOptionsRequest {
    catalogTypeId: string;
    query?: string;
    entryIds?: Array<string>;
}

export interface InsightsListCustomFieldOptionsRequest {
    customFieldId: string;
    query?: string;
    optionIds?: Array<string>;
}

export interface InsightsShowDashboardRequest {
    id: string;
}

export interface InsightsUpdateDashboardRequest {
    id: string;
    updateDashboardRequestBody: InsightsUpdateDashboardRequestBody;
}

/**
 * 
 */
export class InsightsApi extends runtime.BaseAPI {

    /**
     * Creates a customisable dashboard
     * CreateDashboard Insights
     */
    async insightsCreateDashboardRaw(requestParameters: InsightsCreateDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsCreateDashboardResponseBody>> {
        if (requestParameters.createDashboardRequestBody === null || requestParameters.createDashboardRequestBody === undefined) {
            throw new runtime.RequiredError('createDashboardRequestBody','Required parameter requestParameters.createDashboardRequestBody was null or undefined when calling insightsCreateDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/dashboards/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightsCreateDashboardRequestBodyToJSON(requestParameters.createDashboardRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsCreateDashboardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates a customisable dashboard
     * CreateDashboard Insights
     */
    async insightsCreateDashboard(requestParameters: InsightsCreateDashboardRequest, initOverrides?: RequestInit): Promise<InsightsCreateDashboardResponseBody> {
        const response = await this.insightsCreateDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a dashboard
     * DeleteDashboard Insights
     */
    async insightsDeleteDashboardRaw(requestParameters: InsightsDeleteDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsDeleteDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/dashboards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a dashboard
     * DeleteDashboard Insights
     */
    async insightsDeleteDashboard(requestParameters: InsightsDeleteDashboardRequest, initOverrides?: RequestInit): Promise<void> {
        await this.insightsDeleteDashboardRaw(requestParameters, initOverrides);
    }

    /**
     * GenerateExploJWT Insights
     */
    async insightsGenerateExploJWTRaw(requestParameters: InsightsGenerateExploJWTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsGenerateExploJWTResponseBody>> {
        if (requestParameters.generateExploJWTRequestBody === null || requestParameters.generateExploJWTRequestBody === undefined) {
            throw new runtime.RequiredError('generateExploJWTRequestBody','Required parameter requestParameters.generateExploJWTRequestBody was null or undefined when calling insightsGenerateExploJWT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/auth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightsGenerateExploJWTRequestBodyToJSON(requestParameters.generateExploJWTRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsGenerateExploJWTResponseBodyFromJSON(jsonValue));
    }

    /**
     * GenerateExploJWT Insights
     */
    async insightsGenerateExploJWT(requestParameters: InsightsGenerateExploJWTRequest, initOverrides?: RequestInit): Promise<InsightsGenerateExploJWTResponseBody> {
        const response = await this.insightsGenerateExploJWTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * GetCalendar Insights
     */
    async insightsGetCalendarRaw(requestParameters: InsightsGetCalendarRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsGetCalendarResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/calendar`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsGetCalendarResponseBodyFromJSON(jsonValue));
    }

    /**
     * GetCalendar Insights
     */
    async insightsGetCalendar(requestParameters: InsightsGetCalendarRequest, initOverrides?: RequestInit): Promise<InsightsGetCalendarResponseBody> {
        const response = await this.insightsGetCalendarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List entries for a catalog type, for use with typeaheads. This will return the right fields for demo / sample data as needed.
     * ListCatalogEntryOptions Insights
     */
    async insightsListCatalogEntryOptionsRaw(requestParameters: InsightsListCatalogEntryOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsListCatalogEntryOptionsResponseBody>> {
        if (requestParameters.catalogTypeId === null || requestParameters.catalogTypeId === undefined) {
            throw new runtime.RequiredError('catalogTypeId','Required parameter requestParameters.catalogTypeId was null or undefined when calling insightsListCatalogEntryOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.catalogTypeId !== undefined) {
            queryParameters['catalog_type_id'] = requestParameters.catalogTypeId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.entryIds) {
            queryParameters['entry_ids'] = requestParameters.entryIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/catalog_entry_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsListCatalogEntryOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List entries for a catalog type, for use with typeaheads. This will return the right fields for demo / sample data as needed.
     * ListCatalogEntryOptions Insights
     */
    async insightsListCatalogEntryOptions(requestParameters: InsightsListCatalogEntryOptionsRequest, initOverrides?: RequestInit): Promise<InsightsListCatalogEntryOptionsResponseBody> {
        const response = await this.insightsListCatalogEntryOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List catalog types in an org. This will return the right types for demo / sample data as needed.
     * ListCatalogTypes Insights
     */
    async insightsListCatalogTypesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsListCatalogTypesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/catalog_types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsListCatalogTypesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List catalog types in an org. This will return the right types for demo / sample data as needed.
     * ListCatalogTypes Insights
     */
    async insightsListCatalogTypes(initOverrides?: RequestInit): Promise<InsightsListCatalogTypesResponseBody> {
        const response = await this.insightsListCatalogTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List options for a custom field, for use with typeaheads. This will return the right fields for demo / sample data as needed.
     * ListCustomFieldOptions Insights
     */
    async insightsListCustomFieldOptionsRaw(requestParameters: InsightsListCustomFieldOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsListCustomFieldOptionsResponseBody>> {
        if (requestParameters.customFieldId === null || requestParameters.customFieldId === undefined) {
            throw new runtime.RequiredError('customFieldId','Required parameter requestParameters.customFieldId was null or undefined when calling insightsListCustomFieldOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.customFieldId !== undefined) {
            queryParameters['custom_field_id'] = requestParameters.customFieldId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.optionIds) {
            queryParameters['option_ids'] = requestParameters.optionIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/custom_field_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsListCustomFieldOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List options for a custom field, for use with typeaheads. This will return the right fields for demo / sample data as needed.
     * ListCustomFieldOptions Insights
     */
    async insightsListCustomFieldOptions(requestParameters: InsightsListCustomFieldOptionsRequest, initOverrides?: RequestInit): Promise<InsightsListCustomFieldOptionsResponseBody> {
        const response = await this.insightsListCustomFieldOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List customisable dashboards
     * ListDashboards Insights
     */
    async insightsListDashboardsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsListDashboardsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/dashboards/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsListDashboardsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List customisable dashboards
     * ListDashboards Insights
     */
    async insightsListDashboards(initOverrides?: RequestInit): Promise<InsightsListDashboardsResponseBody> {
        const response = await this.insightsListDashboardsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show a customisable dashboards
     * ShowDashboard Insights
     */
    async insightsShowDashboardRaw(requestParameters: InsightsShowDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsShowDashboardResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsShowDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/dashboards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsShowDashboardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a customisable dashboards
     * ShowDashboard Insights
     */
    async insightsShowDashboard(requestParameters: InsightsShowDashboardRequest, initOverrides?: RequestInit): Promise<InsightsShowDashboardResponseBody> {
        const response = await this.insightsShowDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing dashboard
     * UpdateDashboard Insights
     */
    async insightsUpdateDashboardRaw(requestParameters: InsightsUpdateDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsUpdateDashboardResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsUpdateDashboard.');
        }

        if (requestParameters.updateDashboardRequestBody === null || requestParameters.updateDashboardRequestBody === undefined) {
            throw new runtime.RequiredError('updateDashboardRequestBody','Required parameter requestParameters.updateDashboardRequestBody was null or undefined when calling insightsUpdateDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/dashboards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InsightsUpdateDashboardRequestBodyToJSON(requestParameters.updateDashboardRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsUpdateDashboardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing dashboard
     * UpdateDashboard Insights
     */
    async insightsUpdateDashboard(requestParameters: InsightsUpdateDashboardRequest, initOverrides?: RequestInit): Promise<InsightsUpdateDashboardResponseBody> {
        const response = await this.insightsUpdateDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
