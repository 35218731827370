import { FriendlyDuration } from "@incident-shared/durations/helpers";
import { EngineFormElement } from "@incident-shared/engine";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  ContentBox,
  FloatingFooter,
  GenericErrorMessage,
  IconEnum,
  InlineErrorMessage,
  Loader,
} from "@incident-ui";
import { Callout, CalloutTheme } from "@incident-ui";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  EngineParamBinding,
  EngineParamBindingPayload,
  Identity,
  IncidentRole,
  IncidentsBuildScopeContextEnum,
  IncidentStatus,
  PostIncidentFlow,
  PostIncidentFlowCreateTaskConfigRequestBodyTaskTypeEnum,
  PostIncidentTaskConfig,
  PostIncidentTaskConfigTaskTypeEnum,
  PostIncidentTaskOption,
  TextNode,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useAllResources } from "src/hooks/useResources";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { usePrevious } from "use-hooks";

import { FormDataType } from "./FormDataType";
import { SetCustomFieldsTaskInputs } from "./SetCustomFieldsTaskInputs";
import { SetTimestampsTaskInputs } from "./SetTimestampsTaskInputs";
import { TaskAssigneeSection } from "./TaskAssigneeSection";
import { TaskDescriptionEditor } from "./TaskDescriptionEditor";
import { TaskTitleInput } from "./TaskTitleInput";
import { TaskTypePicker } from "./TaskTypePicker";

export const PostIncidentTaskCreateEditPage = () => {
  const { status_id: statusId, task_id: taskId } = useParams<{
    status_id: string;
    task_id: string;
  }>() as {
    status_id: string;
    task_id: string;
  };

  const {
    data: statusData,
    isLoading: statusLoading,
    error: statusErr,
  } = useAPI("incidentLifecyclesShowStatus", {
    id: statusId,
  });

  const {
    data: { post_incident_flows: flows },
    isLoading: flowsLoading,
    error: flowsError,
  } = useAPI("postIncidentFlowList", undefined, {
    fallbackData: { post_incident_flows: [] },
  });

  const {
    data: { task_options: taskOptions },
    isLoading: taskOptionsLoading,
    error: taskOptionsErr,
  } = useAPI("postIncidentFlowListTaskOptions", undefined, {
    fallbackData: { task_options: [] },
  });

  const { identity } = useIdentity();

  if (flowsLoading || taskOptionsLoading || statusLoading || !identity) {
    return <Loader />;
  }

  if (flowsError) {
    return <GenericErrorMessage error={flowsError} />;
  }

  if (taskOptionsErr) {
    return <GenericErrorMessage error={taskOptionsErr} />;
  }

  if (statusErr) {
    return <GenericErrorMessage error={statusErr} />;
  }

  const incidentStatus = statusData?.incident_status;

  if (!incidentStatus) {
    return <InlineErrorMessage description="Incident status not found" />;
  }

  // Find the right post-incident flow.
  const flow = flows.find(
    (flow) => flow.id === incidentStatus.post_incident_flow_id,
  );
  if (!flow) {
    return (
      <InlineErrorMessage description="Post-incident flow not available" />
    );
  }

  // Find the right task config.
  const taskConfig = flow.task_configs.find((task) => task.id === taskId);
  if (!taskConfig && taskId) {
    return <InlineErrorMessage description="Task to edit is not found" />;
  }

  const props: CreateEditFormProps<PostIncidentTaskConfig> = taskConfig
    ? { initialData: taskConfig, mode: Mode.Edit }
    : { mode: Mode.Create };

  return (
    <PostIncidentTaskCreateEditFormInner
      {...props}
      flow={flow}
      taskOptions={taskOptions}
      incidentStatus={incidentStatus}
      identity={identity}
    />
  );
};

type props = {
  flow: PostIncidentFlow;
  taskOptions: PostIncidentTaskOption[];
  incidentStatus: IncidentStatus;
  identity: Identity;
  leadRole?: IncidentRole;
} & CreateEditFormProps<PostIncidentTaskConfig>;

const PostIncidentTaskCreateEditFormInner = ({
  initialData,
  mode,
  flow,
  taskOptions,
  incidentStatus,
  identity,
}: props) => {
  const navigate = useOrgAwareNavigate();

  const formMethods = useForm<FormDataType>({
    defaultValues: {
      ...initialData,
      task_type: initialData?.task_type as unknown as
        | PostIncidentFlowCreateTaskConfigRequestBodyTaskTypeEnum
        | undefined,
      unskippable: initialData?.unskippable ?? false,
      description: initialData?.description as unknown as string | undefined,
      hours_until_due: initialData?.hours_until_due ?? {
        value: {
          literal: "24",
        },
      },
      expressions: initialData?.expressions || [],
    },
  });
  const { setError } = formMethods;

  const backHref = `/settings/post-incident-flow/${flow.id}`;
  const onSuccess = () => navigate(backHref);
  const selectedTaskType = formMethods.watch("task_type");
  const selectedHoursUntilDue = formMethods.watch("hours_until_due");
  const previousTaskType = usePrevious(selectedTaskType);

  // Whenever a new task type is selected, update default values for description etc so that they reflect
  // the default for that type.
  useEffect(() => {
    if (mode === Mode.Edit) {
      // let's not override the description when editing
      return;
    }
    if (previousTaskType === selectedTaskType) {
      return;
    }
    const selectedTaskOption = taskOptions.find((option) => {
      return String(option.task_type) === selectedTaskType;
    });

    // Set the description.
    const description =
      selectedTaskOption?.description &&
      !!selectedTaskOption.description.content?.length // for some weird reason we get object with undefined values for empty descriptions
        ? selectedTaskOption.description
        : undefined;
    formMethods.setValue("description", description as string | undefined);

    // Set the hours until due.
    const hoursUntilDue = selectedTaskOption?.hours_until_due ?? {
      value: {
        literal: "24",
      },
    };
    formMethods.setValue<"hours_until_due">("hours_until_due", hoursUntilDue);
  }, [formMethods, previousTaskType, taskOptions, selectedTaskType, mode]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postIncidentFlowList",
    undefined,
    async (apiClient, data: FormDataType) => {
      const description = data.description as unknown as TextNode | undefined;
      if (mode === Mode.Edit) {
        await apiClient.postIncidentFlowUpdateTaskConfig({
          id: initialData.id,
          updateTaskConfigRequestBody: {
            ...data,
            description,
            expressions: (data.expressions || []).map(expressionToPayload),
          },
        });
      } else {
        await apiClient.postIncidentFlowCreateTaskConfig({
          createTaskConfigRequestBody: {
            ...data,
            description,
            post_incident_flow_id: flow.id,
            incident_status_id: incidentStatus.id,
            expressions: (data.expressions || []).map(expressionToPayload),
          },
        });
      }
    },
    {
      setError,
      onSuccess,
    },
  );

  const expressionsMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });

  const { resources, resourcesLoading } = useAllResources();
  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.PostIncidentTaskConfig,
  );

  if (scopeError) {
    return <GenericErrorMessage error={scopeError} />;
  }
  if (resourcesLoading || scopeLoading) {
    return <Loader />;
  }

  return (
    <SecondaryNavSubPageWrapper
      title={
        initialData ? "Edit post-incident task" : "Create post-incident task"
      }
      icon={IconEnum.Checklist}
      crumbs={[
        {
          title: "Post-incident flow",
          to: "/settings/post-incident-flow",
        },
        {
          title: flow.name,
          to: `/settings/post-incident-flow/${flow.id}`,
        },
      ]}
      backHref={backHref}
    >
      <FormV2
        genericError={genericError}
        onSubmit={onSubmit}
        formMethods={formMethods}
        saving={saving}
      >
        {initialData?.task_type ===
          PostIncidentTaskConfigTaskTypeEnum.Custom && (
          <Callout theme={CalloutTheme.Info}>
            This is a custom task which must be manually marked as complete.
          </Callout>
        )}
        {/* Core details like task type, name and description */}
        <ContentBox className="py-3 px-4 space-y-5">
          <TaskTypePicker
            tasks={flow.task_configs}
            taskOptions={taskOptions}
            mode={mode}
          />
          <TaskTitleInput />
          <TaskDescriptionEditor />
          <SetCustomFieldsTaskInputs />
          <SetTimestampsTaskInputs />
        </ContentBox>

        {/* Until a task type has been chosen, we hide other configuration options */}
        {selectedTaskType && (
          <>
            {/* Task is required */}
            <ContentBox className="py-3 px-4 space-y-3">
              <ToggleV2<FormDataType>
                formMethods={formMethods}
                name="unskippable"
                label="Task is required"
                toggleClassName="grow justify-between"
                description={
                  <>When true, it won&apos;t be possible to skip this task.</>
                }
              />
            </ContentBox>
            {/* Task is overdue */}
            <ContentBox className="py-3 px-4 space-y-3">
              <ExpressionsMethodsProvider
                expressionsMethods={expressionsMethods}
                allowAllOfACatalogType={false}
              >
                <EngineFormElement
                  name="hours_until_due"
                  resourceType="Number"
                  label="Due date"
                  description={`How many hours should this task take to complete? We'll show a visual indicator when a task is
                  overdue. The maximum due date is 30 days (720 hours) - any values longer than this will be set to 30 days instead.`}
                  array={false}
                  scope={scope}
                  required
                  showPlaceholder
                  mode="variables_and_expressions"
                  resources={resources}
                />
                <FormHelpTextV2>
                  <TaskDueDateExplanation
                    paramBinding={selectedHoursUntilDue}
                    incidentStatus={incidentStatus}
                  />
                </FormHelpTextV2>
              </ExpressionsMethodsProvider>
            </ContentBox>
            <TaskAssigneeSection
              expressionsMethods={expressionsMethods}
              scope={scope}
              resources={resources}
              identity={identity}
            />
          </>
        )}

        <FloatingFooter
          analyticsTrackingId="create-edit-post-incident-task"
          saving={saving}
          confirmButtonType="submit"
          confirmButtonText="Save"
          onClose={onSuccess}
        />
      </FormV2>
    </SecondaryNavSubPageWrapper>
  );
};

export const TaskDueDateExplanation = ({
  paramBinding,
  incidentStatus,
  className,
}: {
  paramBinding: EngineParamBindingPayload | EngineParamBinding | null;
  incidentStatus: IncidentStatus;
  className?: string;
}): React.ReactElement => {
  if (paramBinding?.value?.literal) {
    return (
      <div className={className}>
        This task will be due{" "}
        <FriendlyDuration
          seconds={Number(paramBinding.value.literal) * 60 * 60}
        />{" "}
        after the incident enters the{" "}
        <span className="font-semibold">{incidentStatus.name}</span> status.
      </div>
    );
  } else {
    return (
      <div className={className}>
        The due date will be calculated once an incident moves into the{" "}
        <span className="font-semibold">{incidentStatus.name}</span> status.
      </div>
    );
  }
};
