/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertSourceSuggestedAttribute,
    AlertSourceSuggestedAttributeFromJSON,
    AlertSourceSuggestedAttributeFromJSONTyped,
    AlertSourceSuggestedAttributeToJSON,
} from './AlertSourceSuggestedAttribute';

/**
 * 
 * @export
 * @interface AlertsSuggestAttributesResponseBody
 */
export interface AlertsSuggestAttributesResponseBody {
    /**
     * 
     * @type {Array<AlertSourceSuggestedAttribute>}
     * @memberof AlertsSuggestAttributesResponseBody
     */
    suggestions: Array<AlertSourceSuggestedAttribute>;
}

export function AlertsSuggestAttributesResponseBodyFromJSON(json: any): AlertsSuggestAttributesResponseBody {
    return AlertsSuggestAttributesResponseBodyFromJSONTyped(json, false);
}

export function AlertsSuggestAttributesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsSuggestAttributesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'suggestions': ((json['suggestions'] as Array<any>).map(AlertSourceSuggestedAttributeFromJSON)),
    };
}

export function AlertsSuggestAttributesResponseBodyToJSON(value?: AlertsSuggestAttributesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'suggestions': ((value.suggestions as Array<any>).map(AlertSourceSuggestedAttributeToJSON)),
    };
}

