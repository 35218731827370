import {
  Incident,
  IncidentRelationship,
  IncidentRelationshipStatusEnum,
} from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  Heading,
  IconEnum,
  IconSize,
  Link,
} from "@incident-ui";
import React, { useState } from "react";
import { useIsMSTeamsTabFriendlyView } from "src/contexts/MSTeamsTabContext";
import { useAPIMutation } from "src/utils/swr";

import { AddRelatedIncidentModal } from "./AddRelatedIncidentModal";

export function RelatedIncidents({
  incident,
  incident_relationships,
}: {
  incident: Incident;
  incident_relationships: IncidentRelationship[];
}): React.ReactElement | null {
  const isMSTeamsTabFriendlyView = useIsMSTeamsTabFriendlyView();
  const [showAddRelationshipModal, setShowAddRelationshipModal] =
    useState<boolean>(false);
  const [deletingRelationship, setDeletingRelationship] =
    useState<IncidentRelationship | null>(null);

  const { trigger: onRemove } = useAPIMutation(
    "incidentRelationshipsList",
    { incidentId: incident.id ?? "" },
    async (apiClient, relationship: IncidentRelationship) => {
      await apiClient.incidentRelationshipsDestroy({ id: relationship.id });
    },
    {
      onSuccess: () => setDeletingRelationship(null),
    },
  );

  const confirmedRelationships = incident_relationships.filter(
    (relationship) =>
      relationship.status === IncidentRelationshipStatusEnum.Confirmed,
  );

  let buttonText = "Link an incident";
  if (confirmedRelationships.length > 0) {
    buttonText = "Link another incident";
  }

  return (
    <div className="space-y-2 pb-1.5">
      <div className="flex gap-2 items-center mb-2">
        <Heading level={3} size="small">
          Related incidents
        </Heading>
      </div>
      {confirmedRelationships.length > 0 && (
        <>
          {confirmedRelationships.map((relationship) => {
            const relatedInc =
              incident.id === relationship.left_incident_details.id
                ? relationship.right_incident_details
                : relationship.left_incident_details;

            return (
              <div key={relatedInc.id} className="flex justify-between mb-2">
                <Link
                  className="hover:!text-content-primary"
                  analyticsTrackingId={"view-related-incident"}
                  {...(isMSTeamsTabFriendlyView
                    ? {
                        openInNewTab: true,
                        // https://linear.app/incident-io/issue/RESP-3614/deeplink-related-incident-to-ms-teams-channel-if-in-teams-tab
                        href: `/incidents/${relatedInc.externalId}`,
                      }
                    : {
                        to: `/incidents/${relatedInc.externalId}`,
                      })}
                >
                  INC-{relatedInc.externalId}: {relatedInc.name}
                </Link>
                <Button
                  theme={ButtonTheme.Naked}
                  onClick={() => setDeletingRelationship(relationship)}
                  analyticsTrackingId="remove-related-incident"
                  icon={IconEnum.Delete}
                >
                  {``}
                </Button>
              </div>
            );
          })}
        </>
      )}
      <div>
        <Button
          icon={IconEnum.Link}
          iconProps={{
            size: IconSize.Medium,
          }}
          theme={ButtonTheme.Naked}
          onClick={() => {
            setShowAddRelationshipModal(true);
          }}
          analyticsTrackingId="add-related-incident"
          className="-ml-0.5"
        >
          {buttonText}
        </Button>
        {showAddRelationshipModal && (
          <AddRelatedIncidentModal
            key={incident.id}
            incident={incident}
            relatedIncidents={incident_relationships}
            onClose={() => setShowAddRelationshipModal(false)}
          />
        )}
        {deletingRelationship != null && (
          <ConfirmationDialog
            title="Remove incident relationship"
            analyticsTrackingId="remove-relationship"
            isOpen={!!deletingRelationship}
            onCancel={() => setDeletingRelationship(null)}
            onConfirm={() => onRemove(deletingRelationship)}
            confirmButtonText="Unlink"
          >
            <p>
              Remove the link between{" "}
              <span className="font-semibold">
                INC-
                {deletingRelationship.left_incident_details.externalId}
              </span>
              {" and "}
              <span className="font-semibold">
                INC-{deletingRelationship.right_incident_details.externalId}?
              </span>
            </p>
          </ConfirmationDialog>
        )}
      </div>
    </div>
  );
}
