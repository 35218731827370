import {
  Button,
  ButtonTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";

import introducingComparisonView from "./introducing-comparison-view.svg";

export const InsightsComparisonViewModal = ({
  onClose,
  loading,
}: {
  onClose: () => void;
  loading: boolean;
}): React.ReactElement => {
  return (
    <Modal
      isOpen
      title={"Introducing insights comparison view"}
      hideHeader
      onClose={onClose}
      analyticsTrackingId={null}
      className="!w-[600px]"
      isExtraLarge
    >
      <ModalContent className="!p-0 !m-0">
        <div className="bg-[#DBE9FE] py-14 px-7">
          <img className="w-full" src={introducingComparisonView} />
        </div>
        <div className="flex flex-col items-center p-10 gap-3 text-center">
          <div className="text-2xl-bold">
            Compare insights between time periods
          </div>
          <div className="text-base-bold">
            When data is put into context, it&apos;s much easier to gain
            insights. Enable &apos;Compare with previous period&apos; when
            selecting your date range to see a side-by-side comparison of your
            insights over time.
          </div>
        </div>
      </ModalContent>
      <ModalFooter hideBorder>
        <div className="w-4"></div>
        <Button
          analyticsTrackingId={null}
          onClick={onClose}
          theme={ButtonTheme.Primary}
          loading={loading}
          disabled={loading}
        >
          Got it
        </Button>
      </ModalFooter>
    </Modal>
  );
};
