import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { DrawerTitle, InPageDrawer } from "@incident-ui/Drawer/Drawer";
import React from "react";

import { WorkflowsActivityDrawer } from "../../activity/WorkflowsActivityDrawer";
import { WorkflowsAdvancedSettingsDrawer } from "../../advanced-settings/WorkflowsAdvancedSettingsDrawer";
import { WorkflowAddStepForm } from "../../steps/WorkflowAddStepForm";
import { WorkflowEditStepForm } from "../../steps/WorkflowEditStepForm";
import { WorkflowChooseTriggerForm } from "../../triggers/WorkflowChooseTriggerForm";
import {
  RightDrawerState,
  useWorkflowsViewState,
} from "../hooks/useViewController";
import { useWorkflowsDeps } from "../WorkflowsFormContext";
import { WorkflowsCopyToTerraformDrawer } from "./WorkflowsCopyToTerraform";

export const WorkflowsCreateEditDrawers = ({
  onClose,
}: {
  onClose: (opts?: { forceClose: boolean }) => void;
}): React.ReactElement => {
  const { workflow } = useWorkflowsDeps();

  const {
    onOpenLeftDrawer,
    leftDrawerIsOpen,
    onCloseLeftDrawer,
    rightDrawerState,
    hasOpenDrawer,
  } = useWorkflowsViewState();

  return (
    <>
      {!hasOpenDrawer && (
        <Button
          theme={ButtonTheme.Secondary}
          onClick={onOpenLeftDrawer}
          analyticsTrackingId={"workflows-v2-advanced-settings-button"}
          icon={IconEnum.Cog}
          className="absolute left-0 top-0"
        >
          Advanced settings
        </Button>
      )}
      <InPageDrawer side="left" isOpen={leftDrawerIsOpen}>
        <DrawerTitle
          title="Advanced settings"
          onClose={onCloseLeftDrawer}
          closeIcon={IconEnum.AlignArrowLeft}
          compact
        />
        <WorkflowsAdvancedSettingsDrawer />
      </InPageDrawer>
      <InPageDrawer
        isOpen={
          ![RightDrawerState.ExportToTerraform, RightDrawerState.None].includes(
            rightDrawerState,
          )
        }
        side="right"
      >
        {
          {
            // Temporary solution to render the right drawer content, for the time
            // being switching on the content rather than on the whole drawer, but
            // this can be refactored later if we choose to do so.
            [RightDrawerState.ChooseTrigger]: (
              <WorkflowChooseTriggerForm onClose={onClose} />
            ),
            [RightDrawerState.AddStep]: (
              <WorkflowAddStepForm onClose={onClose} />
            ),
            [RightDrawerState.EditStep]: (
              <WorkflowEditStepForm onClose={onClose} />
            ),
            [RightDrawerState.ViewActivity]: (
              <>
                {!!workflow && (
                  <WorkflowsActivityDrawer
                    onClose={onClose}
                    workflow={workflow}
                  />
                )}
              </>
            ),
            [RightDrawerState.None]: null,
          }[rightDrawerState]
        }
      </InPageDrawer>
      {/* The terraform drawer isn't rendered in page, so we have to handle it separately */}
      <WorkflowsCopyToTerraformDrawer
        onClose={onClose}
        isOpen={rightDrawerState === RightDrawerState.ExportToTerraform}
      />
    </>
  );
};
