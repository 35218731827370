import {
  conditionGroupsToConditions,
  conditionsToGroupPayload,
} from "@incident-shared/engine/conditions";
import { SelectedCondition } from "@incident-shared/engine/conditions/SelectedCondition";
import { ConditionsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionsEditorV2";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import {
  Button,
  ButtonTheme,
  Modal,
  ModalFooter,
  StaticSingleSelect,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { SelectOption } from "@incident-ui/Select/types";
import { captureMessage } from "@sentry/react";
import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import {
  Condition,
  EnabledSlackBookmark,
  EnabledSlackBookmarkBookmarkTypeEnum,
  IncidentAttachmentBookmarkConfig,
  IncidentAttachmentBookmarkConfigSelectionOptionEnum,
  IncidentChannelConfigsCreateBookmarkRequestBody,
  IncidentChannelConfigsCreateBookmarkRequestBodyBookmarkTypeEnum,
  IncidentChannelConfigsUpdateBookmarkRequestBody,
  IncidentChannelConfigsUpdateBookmarkRequestBodyBookmarkTypeEnum,
  IncidentsBuildScopeContextEnum,
  SlackBookmarkOption,
  SlackBookmarkOptionBookmarkTypeEnum,
  TextNode,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useProductAccess } from "src/hooks/useProductAccess";
import {
  GetUnicodeForSlackEmoji,
  SLACK_EMOJI_TO_UNICODE,
} from "src/utils/slack";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

type FormData = Omit<
  | IncidentChannelConfigsCreateBookmarkRequestBody
  | IncidentChannelConfigsUpdateBookmarkRequestBody,
  "condition_groups"
> & {
  conditions: Condition[];
  radio_button_id: string;
};

enum RadioButtonID {
  ChooseExistingProperty = "choose-existing-property",
  ChooseExistingLink = "choose-existing-link",
  ChooseCustom = "choose-custom",
}

const BookmarkType = EnabledSlackBookmarkBookmarkTypeEnum;

const typeToRadioButtonID = (
  type: EnabledSlackBookmarkBookmarkTypeEnum | undefined,
): RadioButtonID => {
  // Default to the first button otherwise
  if (!type) {
    return RadioButtonID.ChooseExistingProperty;
  }

  switch (type) {
    case BookmarkType.Status:
    case BookmarkType.Severity:
    case BookmarkType.Role:
    case BookmarkType.CustomField:
    case BookmarkType.IncidentType:
    case BookmarkType.JiraTicket:
    case BookmarkType.Call:
    case BookmarkType.Homepage:
      return RadioButtonID.ChooseExistingProperty;
    case BookmarkType.StatusPage:
    case BookmarkType.IncidentAttachment:
    case BookmarkType.Postmortem:
      return RadioButtonID.ChooseExistingLink;
    case BookmarkType.Custom:
      return RadioButtonID.ChooseCustom;
    default:
      return assertUnreachable(type);
  }
};

const toForm = (bookmark: EnabledSlackBookmark | undefined): FormData => ({
  // @ts-expect-error its not going to like this because the bookmark type isn't an enum, but
  // everywhere else in this modal expects it to be passed as a json string so its safe
  bookmark_type: JSON.stringify(bookmark) || undefined,
  radio_button_id: typeToRadioButtonID(bookmark?.bookmark_type),
  custom_text: bookmark?.text,
  custom_emoji: bookmark ? ":" + bookmark.emoji + ":" : undefined,
  custom_url: bookmark?.custom_url,
  custom_templated_url: bookmark?.custom_templated_url,
  conditions: conditionGroupsToConditions(bookmark?.condition_groups),
  custom_field_id: bookmark?.custom_field_id || undefined,
  incident_role_id: bookmark?.incident_role_id || undefined,
  attachment_config: (bookmark?.attachment_config
    ? bookmark.attachment_config
    : {
        selection_option: "first",
      }) as FormData["attachment_config"],
});

export const SlackChannelBookmarksCreateEditModal = ({
  bookmark,
  enabledBookmarks,
  onClose,
}: {
  bookmark?: EnabledSlackBookmark;
  enabledBookmarks: EnabledSlackBookmark[];
  onClose: () => void;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const formMethods = useForm<FormData>({
    defaultValues: toForm(bookmark),
  });
  const { setError, watch } = formMethods;

  const isEditing = !!bookmark;

  const [useTemplatedURL, setUseTemplatedURL] = useState(
    bookmark && bookmark.custom_templated_url != null,
  );

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentChannelConfigsListEnabledBookmarks",
    undefined,
    async (apiClient, data: FormData) => {
      let BookmarkType: string | undefined = undefined;
      let customFieldID: string | undefined = undefined;
      let incidentRoleID: string | undefined = undefined;
      let customEmoji: string | undefined = undefined;
      let customText: string | undefined = undefined;
      let customURL: string | undefined = undefined;
      let customTemplatedURL: TextNode | undefined = undefined;
      let attachmentConfig: IncidentAttachmentBookmarkConfig | undefined =
        undefined;

      if (radioButtonID !== RadioButtonID.ChooseCustom) {
        // Quite gross: we have to get the bookmark type and ID's out of the JSON string,
        // because we don't have a concept of option ID.
        const object = convertSelectOptionToBookmarkOption(data.bookmark_type);
        BookmarkType = object?.bookmark_type;
        customFieldID = object?.custom_field_id;
        incidentRoleID = object?.incident_role_id;
        attachmentConfig = object?.attachment_config;

        // We show 'selection option' as a different dropdown, so it's edited outside the `bookmark_type` static select.
        // Copy over whatever value was chosen in that dropdown.
        if (attachmentConfig) {
          attachmentConfig.selection_option = data.attachment_config
            ?.selection_option as unknown as IncidentAttachmentBookmarkConfigSelectionOptionEnum;
        }
      } else {
        BookmarkType =
          IncidentChannelConfigsUpdateBookmarkRequestBodyBookmarkTypeEnum.Custom;
        // We store emojis in the backend without colons, so we need to remove them here.
        // @ts-expect-error we know this will be set for custom bookmarks
        customEmoji = stripEmojiColons(data.custom_emoji);
        if (data.custom_emoji && !customEmoji) {
          setError("custom_emoji", {
            type: "manual",
            message: "Invalid emoji",
          });
          return;
        }
        customText = data.custom_text;
        if (useTemplatedURL) {
          customTemplatedURL = data.custom_templated_url;
        } else {
          customURL = data.custom_url;
        }
      }
      if (isEditing) {
        await apiClient.incidentChannelConfigsUpdateBookmark({
          id: bookmark.id,
          updateBookmarkRequestBody: {
            bookmark_type:
              BookmarkType as unknown as IncidentChannelConfigsUpdateBookmarkRequestBodyBookmarkTypeEnum,
            custom_field_id: customFieldID,
            incident_role_id: incidentRoleID,
            custom_emoji: customEmoji,
            custom_text: customText,
            custom_templated_url: customTemplatedURL,
            custom_url: customURL,
            condition_groups: conditionsToGroupPayload(data.conditions || []),
            attachment_config:
              attachmentConfig as unknown as IncidentAttachmentBookmarkConfig,
          },
        });
      } else {
        await apiClient.incidentChannelConfigsCreateBookmark({
          createBookmarkRequestBody: {
            bookmark_type:
              BookmarkType as unknown as IncidentChannelConfigsCreateBookmarkRequestBodyBookmarkTypeEnum,
            custom_field_id: customFieldID,
            incident_role_id: incidentRoleID,
            custom_emoji: customEmoji,
            custom_text: customText,
            custom_templated_url: customTemplatedURL,
            custom_url: customURL,
            condition_groups: conditionsToGroupPayload(data.conditions || []),
            attachment_config:
              attachmentConfig as unknown as IncidentAttachmentBookmarkConfig,
          },
        });
      }
    },
    {
      setError,
      onSuccess: onClose,
    },
  );

  const { hasResponse } = useProductAccess();

  const {
    data: { bookmark_options: bookmarkOptions },
    isLoading: bookmarkOptionsLoading,
    error: bookmarkOptionsError,
  } = useAPI("incidentChannelConfigsListBookmarkOptions", undefined, {
    fallbackData: { bookmark_options: [] },
  });

  const {
    scope: conditionsScope,
    scopeLoading: conditionsScopeLoading,
    scopeError: conditionsScopeError,
  } = useIncidentScope(
    IncidentsBuildScopeContextEnum.SlackBookmarkCustomConditions,
  );

  const {
    scope: urlTemplateScope,
    scopeLoading: urlTemplateScopeLoading,
    scopeError: urlTemplateScopeError,
  } = useIncidentScope(
    IncidentsBuildScopeContextEnum.SlackBookmarkTemplatedUrl,
  );

  // Build up the options for the 'emoji' drop-down (only relevant when creating a custom bookmark.
  const emojiOptions: SelectOption[] = [];
  Object.entries(SLACK_EMOJI_TO_UNICODE).forEach(([key, value]) => {
    emojiOptions.push({
      label: value + "  " + key,
      sort_key: key,
      value: key,
    });
  });

  const title = isEditing ? "Edit bookmark" : "Create bookmark";

  const error =
    genericError ||
    conditionsScopeError ||
    bookmarkOptionsError ||
    urlTemplateScopeError;
  if (error) {
    const err = new Error("loading");
    err.cause = error;
    return <ErrorModal error={err} onClose={onClose} />;
  }

  if (
    !bookmarkOptions ||
    bookmarkOptionsLoading ||
    conditionsScopeLoading ||
    urlTemplateScopeLoading
  ) {
    return (
      <Modal
        title={title}
        isOpen={true}
        analyticsTrackingId="create-edit-bookmark-modal"
        loading={true}
        disableQuickClose
        onClose={onClose}
      />
    );
  }

  // Build up the select options for the drop-down in the Create modal.
  const availableOptions = bookmarkOptions.filter(
    (option) =>
      // Only show the option if it's not already enabled
      !enabledBookmarks.some(
        (x) =>
          x.bookmark_type ===
            (option.bookmark_type as unknown as EnabledSlackBookmarkBookmarkTypeEnum) &&
          x.custom_field_id === option.custom_field_id &&
          x.incident_role_id === option.incident_role_id &&
          x.attachment_config?.external_resource_type ===
            option.attachment_config?.external_resource_type,
      ),
  );

  const propertyOptions = availableOptions
    .filter((option) => !option.is_link)
    .map((option) => {
      return convertBookmarkToSelectOption(option);
    });

  const linkOptions = availableOptions
    .filter((option) => option.is_link)
    .map((option) => {
      return convertBookmarkToSelectOption(option);
    });

  // If we're editing a bookmark, we need to find the option that matches the one we're editing.
  // We'll show this in a disabled drop-down on the Edit modal.
  const selectedOption = bookmarkOptions.find((option) => {
    if (bookmark) {
      return (
        (option.bookmark_type as unknown as EnabledSlackBookmarkBookmarkTypeEnum) ===
          bookmark.bookmark_type &&
        option.custom_field_id === bookmark.custom_field_id &&
        option.incident_role_id === bookmark.incident_role_id
      );
    } else {
      return false;
    }
  });
  if (isEditing && !selectedOption) {
    captureMessage("Unable to find selected bookmark in options", {
      extra: { bookmark, BookmarkOptions: bookmarkOptions },
    });
  }

  const radioButtonOptions = [
    {
      value: RadioButtonID.ChooseExistingProperty,
      label: "Pre-defined property",
      description:
        "Choose one of our pre-defined bookmarks which contains a useful piece of information about the incident, like the status or severity. We'll automatically update this when it changes.",
    },
    {
      value: RadioButtonID.ChooseExistingLink,
      label: "Pre-defined link",
      description:
        "Choose one of our pre-defined bookmarks which links you to a page related to the incident, like a Jira issue ticket.",
    },
  ];

  if (hasResponse) {
    radioButtonOptions.push({
      value: RadioButtonID.ChooseCustom,
      label: "Custom link",
      description:
        "Choose the URL you want the bookmark to take you to. This can be useful if you have documentation that is commonly accessed during an incident.",
    });
  }

  const [radioButtonID, bookmarkType] = watch([
    "radio_button_id",
    "bookmark_type",
  ]);

  const selectedBookmarkOption =
    convertSelectOptionToBookmarkOption(bookmarkType);

  const conditions = conditionGroupsToConditions(bookmark?.condition_groups);

  return (
    <FormModalV2
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      title={title}
      onClose={onClose}
      analyticsTrackingId="create-edit-bookmark-modal"
      disableQuickClose
      footer={
        <ModalFooter
          saving={saving}
          confirmButtonText={isEditing ? "Save" : "Create"}
          confirmButtonType="submit"
          onClose={() => onClose()}
        />
      }
      suppressInitialAnimation
    >
      {/* Radio buttons for type of bookmark */}
      {!isEditing && (
        <RadioButtonGroupV2
          formMethods={formMethods}
          label="What sort of bookmark would you like to add?"
          name="radio_button_id"
          srLabel="What sort of bookmark would you like to add?"
          options={radioButtonOptions}
          boxed
        />
      )}
      {radioButtonID === RadioButtonID.ChooseExistingProperty ? (
        // Choose predefined property
        <>
          {isEditing && selectedOption ? (
            // If we're editing, we show a fake disabled dropdown. We can't just modify the dropdown
            // component that we use in the create modal because its tricky to set a correct default
            // value for it. This is because we pass the bookmark_type field around as a JSON string
            // rather than an enum. Even when we convert the enabled bookmark to a JSON string
            // when rendering default values on the form, it might not be the same JSON string as the one
            // we get when stringifying each bookmark option when building SelectOption[].
            <FormInputWrapperV2 label="Type" name="bookmark_type">
              <StaticSingleSelect
                // We pass in all the possible options here, because we could be editing any
                // enabled bookmarks.
                options={bookmarkOptions.map(convertBookmarkToSelectOption)}
                value={JSON.stringify(selectedOption)}
                disabled={true}
                placeholder="Select bookmark type..."
                onChange={() => {
                  return;
                }}
              />
            </FormInputWrapperV2>
          ) : (
            <StaticSingleSelectV2
              formMethods={formMethods}
              label="Type"
              required
              name="bookmark_type"
              options={propertyOptions}
              placeholder="Select bookmark type..."
            />
          )}
        </>
      ) : radioButtonID === RadioButtonID.ChooseExistingLink ? (
        // Choose predefined link
        <>
          {isEditing && selectedOption ? (
            // If we're editing, we show a fake disabled dropdown. We can't just modify the dropdown
            // component that we use in the create modal because its tricky to set a correct default
            // value for it. This is because we pass the bookmark_type field around as a JSON string
            // rather than an enum. Even when we convert the enabled bookmark to a JSON string
            // when rendering default values on the form, it might not be the same JSON string as the one
            // we get when stringifying each bookmark option when building SelectOption[].
            <>
              <FormInputWrapperV2 label="Type" name="bookmark_type">
                <StaticSingleSelect
                  // We pass in all the possible options here, because we could be editing any
                  // enabled bookmarks.
                  options={bookmarkOptions.map((option) =>
                    convertBookmarkToSelectOption(option),
                  )}
                  value={JSON.stringify(selectedOption)}
                  disabled={true}
                  onChange={() => {
                    return;
                  }}
                />
              </FormInputWrapperV2>
              {selectedBookmarkOption?.bookmark_type ===
                SlackBookmarkOptionBookmarkTypeEnum.IncidentAttachment && (
                <IncidentAttachmentSelectionOptionPicker
                  formMethods={formMethods}
                  isDisabled
                />
              )}
            </>
          ) : (
            <>
              <StaticSingleSelectV2
                formMethods={formMethods}
                label="Type"
                required
                name="bookmark_type"
                options={linkOptions}
                placeholder="Select bookmark type..."
              />
              {selectedBookmarkOption?.bookmark_type ===
                SlackBookmarkOptionBookmarkTypeEnum.IncidentAttachment && (
                <IncidentAttachmentSelectionOptionPicker
                  formMethods={formMethods}
                />
              )}
            </>
          )}
        </>
      ) : (
        // Create custom bookmark
        <>
          <InputV2
            formMethods={formMethods}
            label="Text"
            name="custom_text"
            helptext="This will be shown on the bookmark in Slack."
            placeholder="Enter text..."
            rules={{
              required: "Please enter some text",
              maxLength: {
                value: 25,
                message: "Name must be 25 characters or less",
              },
            }}
          />
          <StaticSingleSelectV2
            formMethods={formMethods}
            label="Emoji"
            required
            name="custom_emoji"
            options={emojiOptions}
            helptext="This will be shown on the bookmark in Slack."
            placeholder="Select emoji..."
          />
          {useTemplatedURL ? (
            <TemplatedTextInputV2
              formMethods={formMethods}
              label="URL"
              scope={urlTemplateScope}
              format="plain"
              includeVariables={true}
              includeExpressions={false}
              name="custom_templated_url"
              helptext="Clicking the bookmark will open this URL."
              placeholder="https://example.com"
              required="Please enter a URL"
            />
          ) : (
            <InputV2
              formMethods={formMethods}
              label="URL"
              name="custom_url"
              helptext="Clicking the bookmark will open this URL."
              placeholder="https://example.com"
              required="Please enter a URL"
            />
          )}
          <Button
            analyticsTrackingId={null}
            className="!mt-1 underline"
            theme={ButtonTheme.Naked}
            onClick={() => {
              setUseTemplatedURL(!useTemplatedURL);
            }}
          >
            {useTemplatedURL ? "Use static URL" : "Use a dynamic URL"}
          </Button>
        </>
      )}

      {/* Conditions */}
      {identity?.feature_gates.incident_types ? (
        <ConditionsEditorV2
          formMethods={formMethods}
          label="Conditions"
          showErrorAboveComponent
          name="conditions"
          className="mt-1"
          wrapperClassName={"mt-2"}
          scope={conditionsScope}
          entityNameLabel={"bookmark"}
          subjectsLabel={"incidents"}
          explanationStyle="available"
        />
      ) : bookmark && conditions.length > 0 ? (
        <div className="space-y-2">
          {conditions.map((condition) => (
            <SelectedCondition
              key={condition.subject.reference}
              condition={condition}
              theme="slate"
            />
          ))}
        </div>
      ) : undefined}
    </FormModalV2>
  );
};

const IncidentAttachmentSelectionOptionPicker = ({
  formMethods,
  isDisabled,
}: {
  formMethods: UseFormReturn<FormData>;
  isDisabled?: boolean;
}) => {
  const firstOption = {
    label: "Use the first attachment",
    value: IncidentAttachmentBookmarkConfigSelectionOptionEnum.First,
  };

  const lastOption = {
    label: "Use the most recent attachment",
    value: IncidentAttachmentBookmarkConfigSelectionOptionEnum.Last,
  };

  const options = [firstOption, lastOption];
  return (
    <StaticSingleSelectV2
      label={"Which attachment should be used?"}
      isClearable={false}
      disabled={isDisabled}
      options={options}
      formMethods={formMethods}
      name={"attachment_config.selection_option"}
    />
  );
};

const convertBookmarkToSelectOption = (
  option: SlackBookmarkOption,
): SelectOption => {
  return {
    label: GetUnicodeForSlackEmoji(option.emoji) + "  " + option.text,
    sort_key: option.text,
    // We can only pass a single string for the value when selecting an option in the "type"
    // dropdown. However, an option cannot be identified by its bookmark type alone; if it is a
    // "Set custom field" bookmark type for example then we also need to pass the custom field ID.
    // Therefore, lets just pass the whole object as a string.
    value: JSON.stringify(option),
  };
};

const convertSelectOptionToBookmarkOption = (
  value: string | undefined,
): SlackBookmarkOption | undefined => {
  if (!value) {
    return undefined;
  }
  return JSON.parse(value) as SlackBookmarkOption;
};

const stripEmojiColons = (s: string): string | undefined => {
  if (s.startsWith(":") && s.endsWith(":") && s.length > 2) {
    return s.slice(1, -1);
  }
  return undefined;
};
