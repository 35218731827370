import { marshallCustomFieldEntriesToRequestPayload } from "@incident-shared/forms/v2/CustomFieldFormElement";
import {
  FormElements,
  marshallFormElementDataToIncidentForm,
  marshallIncidentResponseToFormElementData,
  marshallIncidentRolesToFormData,
  marshallTextDocumentPayload,
  SharedIncidentFormData,
  useElementBindings,
} from "@incident-shared/incident-forms";
import { Callout, CalloutTheme, LoadingModal, ModalFooter } from "@incident-ui";
import _ from "lodash";
import React from "react";
import { FieldNamesMarkedBoolean, useForm } from "react-hook-form";
import { FormModalV2 } from "src/components/@shared/forms/v2/FormV2";
import {
  CustomField,
  Incident,
  IncidentFormLifecycleElementBinding,
  IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum as IncidentFormType,
  IncidentRole,
  IncidentsCreateUpdateRequestBody,
  IncidentsCreateUpdateRequestBodySummarySourceEnum,
  IncidentStatus,
  IncidentTimestamp,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { isActive } from "../../../utils/presenters";
import {
  getCustomFieldErrorPath,
  isCustomFieldError,
} from "./ActiveIncidentCreateModal";
import { buildIncidentTypeOptions } from "./buildIncidentTypeOptions";
import { getRestrictedElementBindings } from "./helpers";
import {
  IncidentCrudResourceTypes,
  useIncidentCrudResources,
  useStatusesForIncident,
  useStatusesForIncidentType,
} from "./useIncidentCrudResources";

const MODAL_TITLE = "Accept incident";

export const IncidentAcceptModal = ({
  onClose,
  incident,
}: {
  onClose: (inc?: Incident) => void;
  incident: Incident;
}): React.ReactElement => {
  const { loading, ...resources } = useIncidentCrudResources();
  const { statuses, statusesLoading } = useStatusesForIncident({ incident });

  if (loading || statusesLoading) {
    return <LoadingModal title={MODAL_TITLE} onClose={onClose} />;
  }

  return (
    <IncidentAcceptForm
      onClose={onClose}
      incident={incident}
      originalStatuses={statuses}
      {...resources}
    />
  );
};

const makeRequestPayload = (
  ourElementBindings: IncidentFormLifecycleElementBinding[],
  formData: SharedIncidentFormData,
  touchedFields: Partial<
    Readonly<FieldNamesMarkedBoolean<SharedIncidentFormData>>
  >,
): IncidentsCreateUpdateRequestBody => {
  const incidentFormRequestBody = marshallFormElementDataToIncidentForm(
    ourElementBindings,
    formData,
    touchedFields,
  );
  return {
    ...formData,
    ...incidentFormRequestBody,
    summary: marshallTextDocumentPayload(formData.summary),
    summary_source: IncidentsCreateUpdateRequestBodySummarySourceEnum.Human,
    to_incident_status_id: formData.incident_status_id,
    to_severity_id: formData.severity_id,
  };
};

const IncidentAcceptForm = ({
  incident,
  onClose,
  incidentTypes,
  customFields,
  incidentRoles,
  incidentTimestamps,
  originalStatuses,
}: {
  onClose: (inc?: Incident) => void;
  incident: Incident;
  originalStatuses: IncidentStatus[];
} & IncidentCrudResourceTypes): React.ReactElement => {
  const minStatusId = _.minBy(originalStatuses.filter(isActive), "rank")?.id;

  const convertIncidentResponseToFormData = (
    responseBody: Incident,
    ourCustomFields: CustomField[],
    ourIncidentRoles: IncidentRole[],
    ourTimestamps: IncidentTimestamp[],
  ): SharedIncidentFormData => {
    const elementFormData = marshallIncidentResponseToFormElementData(
      ourCustomFields,
      ourTimestamps,
      ourIncidentRoles,
      responseBody,
    );

    return {
      ...elementFormData,
      name: incident.name,
      incident_status_id: minStatusId,
      severity_id: incident.severity?.id,
      incident_type_id: incident.incident_type?.id,
      summary: incident.summary,
    };
  };

  const formMethods = useForm<SharedIncidentFormData>({
    defaultValues: convertIncidentResponseToFormData(
      incident,
      customFields,
      incidentRoles,
      incidentTimestamps,
    ),
  });

  const { watch, setError, setValue, getValues } = formMethods;

  const [
    selectedIncidentTypeId,
    selectedIncidentStatusId,
    selectedSeverityId,
    selectedCustomFieldEntries,
    selectedIncidentRoleAssignments,
  ] = watch([
    "incident_type_id",
    "incident_status_id",
    "severity_id",
    "custom_field_entries",
    "incident_role_assignments",
  ]);

  const { statuses: statusesForType, statusesLoading } =
    useStatusesForIncidentType({
      incidentTypeId: selectedIncidentTypeId,
    });

  // If the status in the form is no longer available, swap it for the new
  // first-active status.
  const minStatusIdForType = _.minBy(statusesForType.filter(isActive), "rank")
    ?.id;
  if (!statusesLoading && minStatusIdForType !== selectedIncidentStatusId) {
    setValue<"incident_status_id">("incident_status_id", minStatusIdForType);
  }

  const { selectedIncidentType } = buildIncidentTypeOptions({
    selectedIncidentTypeID: selectedIncidentTypeId,
    incidentTypes,
    includeNotYetKnownOption: false,
  });

  const customFieldEntries = marshallCustomFieldEntriesToRequestPayload(
    customFields,
    formMethods.formState.touchedFields,
    selectedCustomFieldEntries,
  );
  const incidentRoleAssignments = marshallIncidentRolesToFormData({
    incidentRoles,
    formAssignments: selectedIncidentRoleAssignments,
  });

  const elementBindingsPayload = {
    incident_form_type: IncidentFormType.Accept,
    incident_type_id: selectedIncidentTypeId,
    incident_status_id: minStatusId as string,
    severity_id: selectedSeverityId,
    custom_field_entries: customFieldEntries,
    incident_role_assignments: incidentRoleAssignments,
    show_all_elements_override: false,
    incident_id: incident.id,
  };
  const useElementBindingsRes = useElementBindings<SharedIncidentFormData>({
    payload: elementBindingsPayload,
    setValue,
    initialValues: formMethods.formState.defaultValues,
    touchedFields: formMethods.formState.touchedFields,
    manualEdits: incident.manual_edits,
  });
  let { elementBindings } = useElementBindingsRes;
  const { isLoading: isLoadingElementBindings } = useElementBindingsRes;

  const incidentTypeRestricted = selectedIncidentType?.restricted;

  if (incidentTypeRestricted) {
    elementBindings = getRestrictedElementBindings(elementBindings);
  }

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, formData: SharedIncidentFormData) => {
      await apiClient.incidentsCreateUpdate({
        incidentId: incident.id,
        createUpdateRequestBody: makeRequestPayload(
          elementBindings,
          formData,
          formMethods.formState.touchedFields,
        ),
      });
    },
    {
      onSuccess: ({ incident }) => onClose(incident),
      setError: (path, error) => {
        if (isCustomFieldError(path)) {
          const requestData = makeRequestPayload(
            elementBindings,
            getValues(),
            formMethods.formState.touchedFields,
          );
          path = getCustomFieldErrorPath<SharedIncidentFormData>(
            path,
            requestData.custom_field_entries ?? [],
          );
        }
        setError(path, error);
      },
    },
  );

  return (
    <FormModalV2<SharedIncidentFormData>
      formMethods={formMethods}
      title={MODAL_TITLE}
      analyticsTrackingId="declare-incident"
      disableQuickClose
      onSubmit={onSubmit}
      onClose={() => {
        onClose(incident);
      }}
      genericError={genericError}
      footer={
        <ModalFooter
          confirmButtonText="Accept"
          onClose={() => {
            onClose(incident);
          }}
          saving={saving}
          confirmButtonType="submit"
          disabled={incidentTypeRestricted || isLoadingElementBindings}
        />
      }
    >
      <FormElements
        elementBindings={elementBindings}
        incident={incident}
        formMethods={formMethods}
        selectedIncidentType={selectedIncidentType}
        incidentFormType={IncidentFormType.Accept}
        customFieldEntryPayloads={customFieldEntries}
        manualEdits={incident.manual_edits}
      />
      {incidentTypeRestricted && (
        <Callout theme={CalloutTheme.Danger}>
          You don&apos;t have permission to accept incidents of type{" "}
          {selectedIncidentType?.name}
        </Callout>
      )}
    </FormModalV2>
  );
};
