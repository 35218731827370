import { ScopeNameEnum } from "@incident-io/api";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, Steps } from "@incident-ui";
import { StepConfig } from "@incident-ui/Steps/Steps";
import { tcx } from "src/utils/tailwind-classes";

import { useIdentity } from "../../../contexts/IdentityContext";

export enum AlertSourceStepEnum {
  Create = "create",
  Connect = "connect",
  Configure = "configure",
}

export type AlertSourceStep = {
  id: AlertSourceStepEnum;
  name: string;
  label: string;
} & (
  | {
      submitType: "submit";
      formId: string;
    }
  | {
      submitType: "button";
      formId?: never;
    }
);

export const AlertSourceFormIds = {
  [AlertSourceStepEnum.Create]: "alert-source-create",
  [AlertSourceStepEnum.Configure]: "alert-source-configure",
};

export const stepConfig: AlertSourceStep[] = [
  {
    id: AlertSourceStepEnum.Create,
    name: "Create",
    label: "Create",
    submitType: "submit",
    formId: AlertSourceFormIds[AlertSourceStepEnum.Create],
  },
  {
    id: AlertSourceStepEnum.Connect,
    name: "Connect",
    label: "Connect",
    submitType: "button",
  },
  {
    id: AlertSourceStepEnum.Configure,
    name: "Configure",
    label: "Configure",
    submitType: "submit",
    formId: AlertSourceFormIds[AlertSourceStepEnum.Configure],
  },
];

export const AlertSourceCreatePage = () => {
  return null;
};

/*
const getStep = (
  stepId: string,
  stepConfig: AlertSourceStep[],
): AlertSourceStep => {
  const step = stepConfig.find((s) => s.id === stepId);
  if (step === undefined) {
    throw new Error(`Invalid step: ${stepId}`);
  }
  return step;
};

export const AlertSourceCreatePage = () => {
  const queryParams = useQueryParams();
  let stepId = queryParams.get("step");
  const alertSourceConfigId = queryParams.get("id");
  const from = queryParams.get("from");
  const titleInputRef = useRef<HTMLDivElement>(null);

  if (!stepId) {
    stepId = AlertSourceStepEnum.Create;
  }

  const getStepUrl = (stepId: AlertSourceStepEnum) => {
    const fromQueryString = from ? `&from=${from}` : "";

    return `/alerts/sources/create?step=${stepId}&id=${alertSourceConfigId}${fromQueryString}`;
  };

  if (stepId !== AlertSourceStepEnum.Create && !alertSourceConfigId) {
    throw new Error("Unreachable: alertSourceConfigId should be set ");
  }

  const step = getStep(stepId, stepConfig as unknown as AlertSourceStep[]);

  const [validSourceSelected, setValidSourceSelected] = useState<boolean>();

  const { loading, error, alertSource, alertSourceConfig, sourceTypeConfig } =
    useAlertSourceConfigDeps({ alertSourceConfigId });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <>
      <PageWrapper
        width={PageWidth.Full}
        noPadding
        title={"Create alert source"}
        titleNode={
          <HeaderBarTitle
            crumbs={[
              {
                title: "Alerts",
                to: "/alerts",
              },
              {
                title: "Alert Sources",
                to: "/alerts/sources",
              },
            ]}
            title="Edit alert source"
            titleNode={
              step.id === AlertSourceStepEnum.Configure ? (
                <div
                  className="flex flex-row items-baseline grow"
                  ref={titleInputRef}
                />
              ) : (
                <div>
                  {alertSourceConfig
                    ? alertSourceConfig.name
                    : "Create Alert Source"}{" "}
                </div>
              )
            }
            isEditable
          />
        }
        accessory={<AlertSourceSteps step={step.id} />}
        icon={sourceTypeConfig?.icon || IconEnum.Alert}
        iconSize={sourceTypeConfig?.icon ? IconSize.XL : undefined}
        color={
          sourceTypeConfig?.icon ? ColorPaletteEnum.SlateOnWhite : undefined
        }
        backHref="/alerts/sources"
        overflowY={false}
      >
        {
          {
            [AlertSourceStepEnum.Create]: (
              <pre>
                {`<AlertSourceChooseSourcePage
                setValidSourceSelected={setValidSourceSelected}
                continueButton={
                  <AlertSourceContinueButton
                    step={step}
                    validSourceSelected={validSourceSelected}
                    getStepUrl={getStepUrl}
                    mode={Mode.Create}
                    text={"Continue"}
                  />
                }
              />`}
              </pre>
            ),
            [AlertSourceStepEnum.Connect]: (
              <pre>
                {`<AlertSourceConnectPage
                  alertSourceConfigId={alertSourceConfigId as string}
                  mode={Mode.Create}
                />`}
                )
              </pre>
            ),
            [AlertSourceStepEnum.Configure]:
              loading || !alertSource || !alertSourceConfig ? (
                <FullPageLoader />
              ) : (
                <pre>
                  {`<AlertSourceConfigurePage
                  mode={Mode.Create}
                  titleInputRef={titleInputRef}
                  {...{ alertSource, alertSourceConfig }}
                />`}
                </pre>
              ),
          }[step.id]
        }
      </PageWrapper>
    </>
  );
};
*/

export const AlertSourceContinueButton = ({
  step,
  validSourceSelected,
  getStepUrl,
  mode,
  text,
}: {
  step: AlertSourceStep;
  validSourceSelected?: boolean;
  getStepUrl: (stepId: AlertSourceStepEnum) => string;
  mode: Mode;
  text: string;
}) => {
  const { hasScope } = useIdentity();
  const stepIndex = stepConfig.findIndex((s) => s.id === step.id);

  const hasPermission =
    mode === Mode.Edit
      ? hasScope(ScopeNameEnum.AlertSourceUpdate)
      : hasScope(ScopeNameEnum.AlertSourceCreate);

  return (
    <GatedButton
      // This needs a key or clicks carry across steps, and you end up submitting
      // a form you haven't even seen yet!
      key={step.id}
      analyticsTrackingId="alert-source-create-continue"
      className="pt-2.5 pb-2.5"
      href={
        step.submitType === "button"
          ? getStepUrl(stepConfig[stepIndex + 1]?.id)
          : undefined
      }
      theme={ButtonTheme.Primary}
      type={step.submitType}
      // You can use a submit button outside a form by giving that form an ID,
      // and then setting the `form` prop on the button to match.
      // This is a much lighter weight solution than using a portal!
      form={step.formId}
      disabled={
        (step.id === AlertSourceStepEnum.Create && !validSourceSelected) ||
        !hasPermission
      }
      disabledTooltipContent={
        !hasPermission
          ? "You do not have permission to do this"
          : "Select an alert source"
      }
    >
      {text}
    </GatedButton>
  );
};

export const AlertSourceSteps = ({ step }: { step: AlertSourceStepEnum }) => {
  return (
    <Steps
      steps={stepConfig as unknown as StepConfig<AlertSourceStepEnum>[]}
      currentStep={step}
      hideNumbers
      containerClassName={tcx(
        "max-w-[640px] min-w-[400px] mx-auto font-normal",
      )}
    />
  );
};
