import {
  IncidentType,
  ManagedResource,
  ManagementMeta,
  ScopeNameEnum,
  WorkflowSlim,
  WorkflowSlimWorkflowStateEnum as WorkflowStateEnum,
  WorkflowsShowWorkflowResponseBody,
  WorkflowsUpdateWorkflowFolderRequest,
  WorkflowsUpdateWorkflowFolderRequestBody,
  WorkflowsUpdateWorkflowFolderResponseBody,
} from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { NoPermissionMessage } from "@incident-shared/gates/GatedButton/GatedButton";
import { ManagementMetaBadge } from "@incident-shared/management-meta/ManagementMetaBadge";
import { isTerraform } from "@incident-shared/management-meta/utils";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  Icon,
  IconEnum,
  ModalFooter,
  Toggle,
} from "@incident-ui";
import { DropdownMenuSubItem } from "@incident-ui/DropdownMenu/DropdownMenu";
import { SearchBar, useSearchContext } from "@incident-ui/SearchBar/SearchBar";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { Searcher, sortKind } from "fast-fuzzy";
import { orderBy } from "lodash";
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import React, { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { CopyDebugID } from "../../../../utils/ShowDebugIDProvider";
import { WorkflowsStatePill } from "../common/WorkflowsStatePill";
import { useMutateWorkflow } from "../hooks";
import { WorkflowsDeletionConfirmationModal } from "./WorkflowsDeletionConfirmationModal";
import { WorkflowsDisableDropdownItem } from "./WorkflowsDisableDropdownItem";
import styles from "./WorkflowsList.module.scss";
import { WorkflowsListItemAccordion } from "./WorkflowsListItemAccordion";

const WorkflowNoFolder = "UNCATEGORIZED";

export const WorkflowsList = ({
  workflows,
  managedResources,
  expandAll,
  orgCanCreateWorkflows,
}: {
  workflows: WorkflowSlim[];
  managedResources: ManagedResource[];
  expandAll: boolean;
  orgCanCreateWorkflows: boolean;
}) => {
  const folders = uniq(
    workflows
      .filter((x) => !!x.folder)
      // TS can't figure out that since we've filtered all the undefined folders this
      // is always a string
      .map((x) => x.folder as unknown as string),
  ).sort();

  const { value: searchString } = useSearchContext();
  const sortedWorkflows = orderBy(workflows, "name");

  const searcher = new Searcher(sortedWorkflows, {
    keySelector: (wf) => wf.name,
    sortBy: sortKind.insertOrder,
    threshold: 0.8,
  });

  // We want to do all filtering and searching before we group, to avoid showing spurious folders
  const searchedWorkflows = searchString
    ? searcher.search(searchString)
    : sortedWorkflows;
  const groupedWorkflows = groupBy(
    searchedWorkflows,
    (x) => x.folder || WorkflowNoFolder,
  );
  const uncategorizedWorkflows = groupedWorkflows[WorkflowNoFolder];
  delete groupedWorkflows[WorkflowNoFolder];

  if (searchString && searcher.search(searchString).length === 0) {
    return (
      <EmptyState
        icon={IconEnum.Filter}
        content=" No workflows match your search criteria."
      />
    );
  }

  return (
    <div className={tcx("text-sm", styles.table)}>
      <div className="space-y-3">
        {Object.entries(groupedWorkflows)
          // Show folders in descending alphabetical order
          .sort(([nameA], [nameB]) => (nameA < nameB ? -1 : 1))
          .map(([folderName, groupWorkflows]) => (
            <Group key={folderName}>
              <WorkflowFolder
                folders={folders}
                name={folderName}
                workflows={groupWorkflows}
                overrideIsExpanded={expandAll}
                expandAllFolders={!!searchString}
                orgCanCreateWorkflows={orgCanCreateWorkflows}
              />
            </Group>
          ))}
        <Group className="divide-y divide-stroke">
          {uncategorizedWorkflows
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((wf) => (
              <WorkflowsListItem
                key={wf.id}
                folders={folders}
                workflow={wf}
                overrideIsExpanded={expandAll}
                orgCanCreateWorkflows={orgCanCreateWorkflows}
                managedResource={managedResources.find(
                  (res) => res.resource_id === wf.id,
                )}
              />
            ))}
        </Group>
      </div>
    </div>
  );
};

const WorkflowFolder = ({
  workflows,
  name,
  overrideIsExpanded,
  expandAllFolders,
  orgCanCreateWorkflows,
  folders,
}: {
  workflows: WorkflowSlim[];
  name: string;
  folders: string[];
  overrideIsExpanded: boolean;
  expandAllFolders: boolean;
  orgCanCreateWorkflows: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const folderIsExpanded = isExpanded || expandAllFolders;
  // This flag is used to expand all folders and workflows when the "expand all" button is pressed
  useEffect(() => {
    setIsExpanded(overrideIsExpanded);
  }, [overrideIsExpanded]);

  return (
    <>
      {/*The top border normally separates the rows from the header, but we have no header here */}
      <div
        className="group hover:cursor-pointer hover:bg-surface-secondary flex-center-y p-3 min-h-[59px]"
        onClick={() => setIsExpanded((current) => !current)}
      >
        {/* Name */}
        <div className="font-medium truncate">
          <div className="flex-center-y gap-2">
            <Icon
              id={
                folderIsExpanded
                  ? IconEnum.FolderOpen
                  : IconEnum.FolderNoPadding
              }
              className="text-content-tertiary group-hover:text-content-primary"
            />
            <span className="truncate">{name}</span>
            <Icon
              id={isExpanded ? IconEnum.ChevronUp : IconEnum.ChevronDown}
              className={tcx("text-slate-400 group-hover:text-content-primary")}
            />
          </div>
        </div>
      </div>
      {folderIsExpanded && (
        <div className="bg-surface-secondary p-3 border-t border-stroke">
          <div className="border border-stroke divide-y divide-slate-200 rounded-2 overflow-hidden">
            {workflows
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((wf) => (
                <WorkflowsListItem
                  folders={folders}
                  key={wf.id}
                  workflow={wf}
                  overrideIsExpanded={overrideIsExpanded}
                  orgCanCreateWorkflows={orgCanCreateWorkflows}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

const ManagedInTFMessage = "This resource can only be changed via Terraform";

const WorkflowsListItem = ({
  workflow,
  overrideIsExpanded,
  orgCanCreateWorkflows,
  folders,
  managedResource,
}: {
  workflow: WorkflowSlim;
  overrideIsExpanded: boolean;
  folders: string[];
  orgCanCreateWorkflows: boolean;
  managedResource?: ManagedResource;
}) => {
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const { hasScope } = useIdentity();
  const requiredScope = workflow.include_private_incidents
    ? ScopeNameEnum.WorkflowsApprovePrivate
    : ScopeNameEnum.WorkflowsUpdate;

  const canEditWorkflow = hasScope(requiredScope);

  const {
    trigger: updateWorkflowFolder,
    genericError,
    isMutating,
  } = useMutateWorkflow(
    workflow.id,
    async (client, { id, updateWorkflowFolderRequestBody }) => {
      return await client.workflowsUpdateWorkflowFolder({
        id,
        updateWorkflowFolderRequestBody,
      });
    },
  );

  const { trigger: disableWorkflow, isMutating: isDisabling } =
    useMutateWorkflow(
      workflow.id,
      async (client, { id }) => {
        return await client.workflowsDisableWorkflow({ id });
      },
      {
        onSuccess: () => {
          showToast({
            theme: ToastTheme.Success,
            title: "Workflow disabled",
            toastBelowHeader: true,
          });
          setShowDeleteConfirmationModal(false);
        },
        onError: () => {
          showToast({
            theme: ToastTheme.Error,
            title: "There was an error disabling this workflow",
            toastBelowHeader: true,
          });
        },
      },
    );
  const { trigger: enableWorkflow, isMutating: isEnabling } = useMutateWorkflow(
    workflow.id,
    async (client, { id }) => {
      return await client.workflowsEnableWorkflow({ id });
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Workflow enabled",
          toastBelowHeader: true,
        });
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "There was an error enabling this workflow",
          toastBelowHeader: true,
        });
      },
    },
  );
  const { trigger: deleteWorkflow, isMutating: isDeleting } = useAPIMutation(
    "workflowsListWorkflows",
    { incidentType: undefined },
    async (client, { id }) => {
      await client.workflowsDestroyWorkflow({ id });
      return;
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Workflow deleted",
          toastBelowHeader: true,
        });
        setShowDeleteConfirmationModal(false);
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "There was an error deleting this workflow",
          toastBelowHeader: true,
        });
      },
    },
  );
  const dropdownMenuStyle = "text-sm font-normal text-content-primary";

  const [isExpanded, setIsExpanded] = useState(false);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  useEffect(() => {
    setIsExpanded(overrideIsExpanded);
  }, [overrideIsExpanded, setIsExpanded]);

  const isBroken = workflow.workflow_state === WorkflowStateEnum.Error;
  const isManagedInTF = isTerraform(managedResource);

  const [workflowBeingMoved, setWorkflowBeingMoved] = useState<string>();
  const handleNewFolder = (
    formData: WorkflowsUpdateWorkflowFolderRequestBody,
  ) => {
    updateWorkflowFolder({
      id: workflowBeingMoved,
      updateWorkflowFolderRequestBody: formData,
    });
    // Close the new folder modal
    setWorkflowBeingMoved(undefined);
  };

  return (
    <>
      {/*The top border normally separates the rows from the header, but we have no header here */}
      <div
        key={`${workflow.id}-${isExpanded}`}
        className={tcx(
          "flex-center-y w-full",
          "items-stretch",
          isBroken ? "text-slate-600" : "bg-white hover:bg-surface-secondary",
          {
            "opacity-40": isDeleting,
          },
        )}
      >
        {/* Name */}
        <div
          className={tcx(
            "font-medium truncate grow  p-3 flex items-center",
            isBroken ? "" : "hover:cursor-pointer",
          )}
          onClick={isBroken ? undefined : () => navigate(workflow.id)}
        >
          <div className="flex flex-row w-full items-center gap-2">
            <span className="truncate">{workflow.name}</span>
            <WorkflowsStatePill
              state={workflow.workflow_state}
              errorMessage={workflow.error_string}
              isDisabling={isDisabling}
              isEnabling={isEnabling}
            />
            <ManagementMetaBadge
              management={
                // Enums make these 'different' but they're actually the same.
                managedResource as unknown as ManagementMeta
              }
              resourceName="workflow"
            />
            <CopyDebugID id={workflow.id} />
          </div>
        </div>
        <div className={tcx("flex-none whitespace-nowrap p-3")}>
          <div className="flex items-center justify-end">
            {/* View/hide details */}
            {!isBroken && (
              <Button
                analyticsTrackingId="workflows-v2-list-toggle-details"
                theme={ButtonTheme.Naked}
                onClick={() => setIsExpanded(!isExpanded)}
                className="group hover:!no-underline"
              >
                <span className="text-slate-600 group-hover:text-content-primary ml-2">
                  {isExpanded ? "Hide details" : "View details"}
                </span>
                <Icon
                  id={isExpanded ? IconEnum.Collapse : IconEnum.Expand}
                  className="text-slate-400 group-hover:text-content-primary"
                />
              </Button>
            )}
            {/* Overflow Menu */}
            <DropdownMenu
              triggerButtonTheme={ButtonTheme.Unstyled}
              analyticsTrackingId={"workflows-v2-more-options"}
              screenReaderText="More options"
              triggerIcon={IconEnum.DotsVertical}
              align="end"
              menuClassName="w-[250px]"
            >
              <DropdownMenuItem
                onSelect={() => navigate(workflow.id)}
                analyticsTrackingId={"workflows-v2-view-workflow"}
                label={canEditWorkflow ? "Edit workflow" : "View workflow"}
                icon={canEditWorkflow ? IconEnum.Edit : IconEnum.View}
                className={dropdownMenuStyle}
                disabled={isBroken}
              />
              <DropdownMenuSubItem
                trigger={"Move to folder"}
                icon={IconEnum.FolderNoPadding}
                disabled={!canEditWorkflow || isManagedInTF}
              >
                {folders.map((folder) => (
                  <AddToFolderMenuItem
                    key={folder}
                    folder={folder}
                    workflow={workflow}
                    updateWorkflowFolder={updateWorkflowFolder}
                  />
                ))}
                <CreateNewFolderMenuItem
                  workflow={workflow}
                  setShowNewFolderModal={setWorkflowBeingMoved}
                />
                {folders.length > 0 ? (
                  <RemoveFromFolderMenuItem
                    workflow={workflow}
                    updateWorkflowFolder={updateWorkflowFolder}
                  />
                ) : null}
              </DropdownMenuSubItem>
              {workflow.workflow_state === WorkflowStateEnum.Disabled && (
                <DropdownMenuItem
                  onSelect={() => enableWorkflow({ id: workflow.id })}
                  analyticsTrackingId={"workflows-v2-enable-workflow"}
                  label={"Enable workflow"}
                  icon={IconEnum.ToggleRight}
                  className={dropdownMenuStyle}
                  disabled={!canEditWorkflow || isBroken || isManagedInTF}
                  tooltipContent={
                    isManagedInTF ? (
                      <>{ManagedInTFMessage}</>
                    ) : canEditWorkflow ? null : (
                      <>{NoPermissionMessage}</>
                    )
                  }
                />
              )}
              {workflow.workflow_state === WorkflowStateEnum.Active && (
                <WorkflowsDisableDropdownItem
                  onDisable={() => disableWorkflow({ id: workflow.id })}
                  workflowId={workflow.id}
                  workflowName={workflow.name}
                  disabled={!canEditWorkflow || isManagedInTF}
                  disabledTooltipContent={
                    isManagedInTF ? (
                      <>{ManagedInTFMessage}</>
                    ) : canEditWorkflow ? null : (
                      <>{NoPermissionMessage}</>
                    )
                  }
                />
              )}

              <DropdownMenuItem
                analyticsTrackingId={"workflows-v2-clone-workflow"}
                onSelect={() => navigate(`create?clone=${workflow.id}`)}
                label={"Duplicate workflow"}
                icon={IconEnum.Copy}
                className={dropdownMenuStyle}
                disabled={
                  !orgCanCreateWorkflows || !canEditWorkflow || isBroken
                }
                tooltipContent={
                  canEditWorkflow ? null : <>{NoPermissionMessage}</>
                }
              />
              <DropdownMenuItem
                analyticsTrackingId={"workflows-v2-delete-workflow"}
                onSelect={() => setShowDeleteConfirmationModal(true)}
                label={"Delete workflow"}
                icon={IconEnum.Delete2}
                className={dropdownMenuStyle}
                disabled={!canEditWorkflow || isManagedInTF}
                tooltipContent={
                  isManagedInTF ? (
                    <>{ManagedInTFMessage}</>
                  ) : canEditWorkflow ? null : (
                    <>{NoPermissionMessage}</>
                  )
                }
              />
            </DropdownMenu>
          </div>
        </div>
      </div>
      {isExpanded && <WorkflowsListItemAccordion workflow={workflow} />}

      {showDeleteConfirmationModal && (
        <WorkflowsDeletionConfirmationModal
          onClose={() => setShowDeleteConfirmationModal(false)}
          onConfirm={() => deleteWorkflow({ id: workflow.id })}
          onDisable={() => disableWorkflow({ id: workflow.id })}
          workflowId={workflow.id}
          workflowName={workflow.name}
          isDeleting={isDeleting}
          isDisabled={workflow.workflow_state === WorkflowStateEnum.Disabled}
          isDisabling={isDisabling}
        />
      )}

      {workflowBeingMoved && (
        <CreateNewFolderModal
          onClose={() => setWorkflowBeingMoved(undefined)}
          onSubmit={(data) => handleNewFolder(data)}
          genericError={genericError}
          saving={isMutating}
        />
      )}
    </>
  );
};

export const WorkflowsFilterBar = ({
  types,
  expandAll,
  setExpandAll,
}: {
  types: IncidentType[];
  expandAll: boolean;
  setExpandAll: (expandAll: boolean) => void;
}) => {
  const formMethods = useFormContext();
  const searchBarProps = useSearchContext();
  const typeOptions = types.map((type) => {
    return { label: type.name, value: type.id };
  });
  return (
    <div className="w-full mb-3 flex-center-y justify-between flex-wrap gap-4">
      <SearchBar {...searchBarProps} placeholder="Search workflows" autoFocus />
      <div className="grow" />
      <Toggle
        id="expand-all"
        onToggle={() => setExpandAll(!expandAll)}
        on={expandAll}
        label={expandAll ? "Collapse all" : "Expand all"}
      />
      <StaticSingleSelectV2
        placeholder="Filter by incident type"
        isClearable={true}
        formMethods={formMethods}
        options={typeOptions}
        name="filter"
      />
    </div>
  );
};

const Group = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  // overflow-hidden prevents the hover state backgrounds from clipping
  // outside of the bounds of the group's rounded corners.
  return (
    <div
      className={tcx(
        className,
        "border border-stroke shadow overflow-hidden rounded-2",
      )}
    >
      {children}
    </div>
  );
};

const AddToFolderMenuItem = ({
  folder,
  workflow,
  updateWorkflowFolder,
}: {
  folder: string;
  workflow: WorkflowSlim;
  updateWorkflowFolder: (
    req: WorkflowsUpdateWorkflowFolderRequest,
  ) => Promise<WorkflowsUpdateWorkflowFolderResponseBody>;
}) => {
  return (
    <DropdownMenuItem
      className={tcx({
        "!text-content-tertiary": folder === workflow.folder,
      })}
      key={`${workflow.id}-${folder}`}
      onSelect={() => {
        if (folder === workflow.folder) {
          return;
        }
        updateWorkflowFolder({
          id: workflow.id,
          updateWorkflowFolderRequestBody: { folder },
        });
      }}
      label={folder}
      analyticsTrackingId="workflows-move-folder"
    />
  );
};

const RemoveFromFolderMenuItem = ({
  workflow,
  updateWorkflowFolder,
}: {
  workflow: WorkflowSlim;
  updateWorkflowFolder: (
    req: WorkflowsUpdateWorkflowFolderRequest,
  ) => Promise<WorkflowsShowWorkflowResponseBody>;
}) => {
  return (
    <DropdownMenuItem
      key={`${workflow.id}-clear`}
      iconProps={{
        className: tcx({
          "!text-content-tertiary hover:!text-content-tertiary":
            !workflow.folder,
        }),
      }}
      onSelect={() => {
        if (!workflow.folder) {
          return;
        }
        updateWorkflowFolder({
          id: workflow.id,
          updateWorkflowFolderRequestBody: { folder: undefined },
        });
      }}
      label="Remove from folder"
      icon={IconEnum.Close}
      analyticsTrackingId="workflows-move-folder"
    />
  );
};

const CreateNewFolderMenuItem = ({
  workflow,
  setShowNewFolderModal,
}: {
  workflow: WorkflowSlim;
  setShowNewFolderModal: (id: string) => void;
}) => {
  return (
    <DropdownMenuItem
      key={`${workflow.id}-new-folder`}
      onSelect={() => setShowNewFolderModal(workflow.id)}
      label="Add folder"
      icon={IconEnum.Add}
      analyticsTrackingId="workflows-move-folder"
    />
  );
};

const CreateNewFolderModal = ({
  onSubmit,
  onClose,
  genericError,
  saving,
}: {
  onSubmit: (data: WorkflowsUpdateWorkflowFolderRequestBody) => void;
  onClose: () => void;
  saving: boolean;
  genericError: string | null | undefined;
}) => {
  const formMethods = useForm();
  return (
    <FormModalV2
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      title="Create new folder"
      analyticsTrackingId="workflows-create-new-folder"
      onClose={onClose}
      footer={
        <ModalFooter
          confirmButtonText="Save"
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      <InputV2
        autoFocus
        name="folder"
        placeholder="Enter a folder name"
        required
        formMethods={formMethods}
      />
    </FormModalV2>
  );
};
