import { IconEnum } from "@incident-ui";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemSource } from "src/components/timeline/TimelineItemSource";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

export const TimelineItemIncidentTypeChanged = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.incident_type_changed) {
    throw new Error(
      "malformed timeline item: incident_type_changed was missing incident_type_changed field",
    );
  }

  return {
    icon: IconEnum.IncidentType,
    avatarUrl: item.incident_type_changed?.actor?.user?.avatar_url,
    description: (
      <>
        <TimelineItemSource actor={item.incident_type_changed?.actor} /> changed
        the incident type from{" "}
        <strong>{item.incident_type_changed?.from.name}</strong> to{" "}
        <strong>{item.incident_type_changed?.to.name}</strong>
      </>
    ),
    children: null,
  };
};
