import { IconEnum, Markdown, StackedList } from "@incident-ui";
import { startCase } from "lodash";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemSource } from "src/components/timeline/TimelineItemSource";
import {
  FollowUpUpdated,
  Incident,
  TimelineItemObject,
} from "src/contexts/ClientContext";

import { TimelineItemChangeRow } from "./TimelineItemChangeRow";

export const TimelineItemFollowUpUpdated = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.follow_up_updated) {
    throw new Error(
      "malformed timeline item: follow_up_updated was missing follow_up_updated field",
    );
  }

  const update = item.follow_up_updated;

  return {
    description: followUpCreateText(update),
    avatarUrl: update.updater?.user?.avatar_url,
    icon: IconEnum.Edit,
    children: (
      <StackedList is="dl">
        {update.title_change && (
          <TimelineItemChangeRow
            icon={IconEnum.Message}
            label="Title"
            previousValue={
              <Markdown>{update.title_change.previous_title}</Markdown>
            }
            nextValue={<Markdown>{update.title_change.next_title}</Markdown>}
          />
        )}
        {update.description_change && (
          <TimelineItemChangeRow
            icon={IconEnum.Message}
            label="Description"
            previousValue={update.description_change.previous_description}
            nextValue={update.description_change.next_description}
          />
        )}
        {update.status_change && (
          <TimelineItemChangeRow
            icon={IconEnum.Status}
            label="Status"
            previousValue={startCase(update.status_change.previous_status)}
            nextValue={startCase(update.status_change.next_status)}
          />
        )}
        {update.assignee_change && (
          <TimelineItemChangeRow
            icon={IconEnum.User}
            label="Owner"
            previousValue={update.assignee_change.previous_user?.name}
            nextValue={update.assignee_change.next_user?.name}
          />
        )}
        {update.follow_up_priority_option_change && (
          <TimelineItemChangeRow
            icon={IconEnum.Warning}
            label="Priority"
            previousValue={
              update.follow_up_priority_option_change
                .previous_follow_up_priority_option?.name
            }
            nextValue={
              update.follow_up_priority_option_change
                .next_follow_up_priority_option?.name
            }
          />
        )}
      </StackedList>
    ),
  };
};

const followUpCreateText = (update: FollowUpUpdated) => {
  return (
    <>
      <TimelineItemSource actor={update.updater} /> updated a follow-up:{" "}
      {update.title_change ? undefined : (
        <strong>{update.follow_up.title}</strong>
      )}
    </>
  );
};
