import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import React from "react";
import { useIntercom } from "react-use-intercom";
import { assertUnreachable } from "src/utils/utils";

export enum CatalogSetupState {
  NO_CATALOG = "no_catalog",
  NO_TEAMS = "no_team",
  NO_ESCALATION_PATH_LINKS = "no_escalation_path_links",
  NO_ESCALATION_PATH_BINDING = "no_escalation_path_binding",
}

export type CatalogSetupWidgetProps = {
  setupState: CatalogSetupState;
  hasPrimaryButton?: boolean;
  children?: React.ReactNode;
};

const getConfigFor = (
  state: CatalogSetupState,
): { header: string; subText: string; href: string } => {
  switch (state) {
    case CatalogSetupState.NO_CATALOG:
      return {
        header: "It looks like you haven’t setup your Catalog",
        subText:
          "Add your teams to Catalog so alerts can be tagged with their owner. If your alerts are associated with Services, Features, or Products, you can add those too.",
        href: "/catalog",
      };
    case CatalogSetupState.NO_TEAMS:
      return {
        header:
          "Set up your teams in Catalog to tag and route alerts to the right people",
        subText:
          "It looks like you don’t have teams in your Catalog: we advise adding Teams so you can tag alerts with the team that's responsible for them, helping route alerts to that Team’s escalation path.",
        href: "/catalog/team-wizard",
      };
    case CatalogSetupState.NO_ESCALATION_PATH_LINKS:
      return {
        header:
          "Attach an escalation path to your team so alert routes can escalate to the right people",
        subText:
          "Alert routes decide which escalation path to page by looking at alert attributes, but nothing in your Catalog points at an escalation path. We recommend tagging each Team with an escalation path.",
        href: "/catalog",
      };
    case CatalogSetupState.NO_ESCALATION_PATH_BINDING:
      return {
        header:
          "Make sure to set either Team, Service, Feature or Product so that alert routes can know what escalation path to use",
        subText:
          "Alert routes decide which escalation path to page by looking at alert attributes, but none of your attributes link to escalation paths.",
        href: "/catalog",
      };
    default:
      assertUnreachable(state);
  }
  return {
    header: "",
    subText: "",
    href: "",
  };
};

export const CatalogSetupWidget = (props: CatalogSetupWidgetProps) => {
  const { showArticle } = useIntercom();
  const config = getConfigFor(props.setupState);

  const buttonTheme =
    props.hasPrimaryButton !== undefined
      ? props.hasPrimaryButton
        ? ButtonTheme.Primary
        : ButtonTheme.Secondary
      : props.children
      ? ButtonTheme.Primary
      : ButtonTheme.Secondary;

  return (
    <div className="bg-purple-surface flex flex-col gap-5 p-4 rounded-lg w-full">
      <IconBadge
        icon={IconEnum.Book}
        size={IconSize.Large}
        color={ColorPaletteEnum.Purple}
        className="bg-purple-200 rounded-2"
      />
      <p className="text-base-bold">{config.header}</p>
      <p className="text-sm-med">{config.subText}</p>
      <div className="flex flex-row justify-start gap-4">
        <Button
          theme={buttonTheme}
          size={ButtonSize.Medium}
          href="/catalog/setup"
          openInNewTab
          analyticsTrackingId="catalog-setup-widget-button"
        >
          Setup Catalog
        </Button>
        <Button
          theme={ButtonTheme.Unstyled}
          size={ButtonSize.Medium}
          className="text-purple-content"
          onClick={() => showArticle(1000)}
          analyticsTrackingId="catalog-setup-widget-button"
          href={config.href}
        >
          Learn More
        </Button>
      </div>
      {props.children ? (
        <div className="flex flex-row justify-start gap-4">
          {props.children}
        </div>
      ) : null}
    </div>
  );
};
