import { Button, ButtonTheme, IconEnum, Input } from "@incident-ui";
import { InputProps, InputType } from "@incident-ui/Input/Input";
import { addYears, subYears } from "date-fns";
import { DateTime } from "luxon";
import { ChangeEvent } from "react";
import { FieldValues, Path, PathValue, useController } from "react-hook-form";

import { FormInputWrapperV2 } from "../FormInputWrapperV2";
import { InputElementProps, parseProps } from "../formsv2";

export const DateInputV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, Omit<InputProps, "type">> & {
    onBlurCallback?: () => void;
    onValueChange?: (val: Date) => void;
    displayNowButton?: boolean;
    clearable?: boolean;
  },
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps(rest);
  const { field, fieldState } = useController({
    name,
    rules,
  });
  let insetSuffixNode = props.displayNowButton ? (
    <Button
      onClick={() =>
        props.formMethods.setValue(
          name,
          new Date() as PathValue<TFormType, Path<TFormType>>,
        )
      }
      analyticsTrackingId=""
      theme={ButtonTheme.Naked}
      className="ml-1 text-xs"
    >
      Now
    </Button>
  ) : (
    props.insetSuffixNode
  );

  const { value: valueAsDate, onChange } = field;

  if (props.clearable) {
    insetSuffixNode = (
      <>
        {insetSuffixNode}
        <Button
          className="ml-1 text-xs"
          analyticsTrackingId=""
          theme={ButtonTheme.Naked}
          icon={IconEnum.Close}
          title="Clear date"
          onClick={() => {
            props.formMethods.setValue(
              name,
              null as PathValue<TFormType, Path<TFormType>>,
            );
          }}
        />
      </>
    );
  }

  const onChangeString = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return undefined;
    }
    const date = DateTime.fromISO(e.target.value, {
      zone: "local",
    }).toJSDate();
    const res = onChange(date);

    onValueChange && onValueChange(date);
    return res;
  };

  const format = "yyyy-MM-dd";

  return (
    <FormInputWrapperV2<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <Input
        id={name}
        type={InputType.Date}
        // Don't allow selecting something >10 years away from now.
        // Note that `inputProps` or `field` might override this.
        min={subYears(new Date(), 10).toDateString() + "T00:00"}
        max={addYears(new Date(), 10).toDateString() + "T00:00"}
        {...field}
        {...inputProps}
        invalid={!!fieldState.error}
        onChange={onChangeString}
        onBlur={() => {
          props.onBlurCallback && props.onBlurCallback();

          return field.onBlur();
        }}
        value={
          valueAsDate
            ? DateTime.fromJSDate(valueAsDate).setZone("local").toFormat(format)
            : ""
        }
        insetSuffixNode={insetSuffixNode}
      />
    </FormInputWrapperV2>
  );
};
