import {
  StatusPageCreateRequestBodyThemeEnum,
  StatusPagePageTypeEnum,
  StatusPageParentPageOptionsPageTypeEnum,
  StatusPageThemeEnum,
} from "@incident-io/api";
import { FormDivider } from "@incident-shared/forms/v2/FormDivider";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Button, ButtonTheme, Heading } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _, { kebabCase } from "lodash";
import { useForm } from "react-hook-form";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { useAPIMutation } from "../../../../utils/swr";
import { ParentFormType, SubPageBrandingForm } from "../../create/BrandingForm";
import { BasicSettingsFormContent } from "../../settings/edit/BasicSettings";
import { buildBrandingFormData } from "../../settings/edit/BrandingForm";
import { SubPageCreationForm } from "./SubPageCreateForm";

export const ParentPageCreateForm = () => {
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const brandingDefaults = buildBrandingFormData();
  const { identity } = useIdentity();

  const formMethods = useForm<ParentFormType>({
    defaultValues: {
      ...brandingDefaults,
      name: identity?.organisation_name ?? "",
      subpath: kebabCase(identity?.organisation_name.toLowerCase() ?? ""),
      reason_for_split: "Region",
      mode: "auto_create",
      parent_page_type: StatusPagePageTypeEnum.Parent,
      auto_create_sub_pages: {
        first_sub_page: {
          // if the timezone is set to US, use the US as the default sub-page
          name: Intl.DateTimeFormat()
            .resolvedOptions()
            .timeZone.includes("America")
            ? "United States"
            : "United Kingdom",
          subpath: Intl.DateTimeFormat()
            .resolvedOptions()
            .timeZone.includes("America")
            ? "united-states"
            : "united-kingdom",
          enabled: true,
          allowed_email_domains: [],
          components: ["first_component", "second_component"],
        },
      },
      auto_create_components: [
        { id: "first_component", name: "App" },
        {
          id: "second_component",
          name: "Website",
        },
      ],
    },
  });

  const { trigger: onSubmit } = useAPIMutation(
    "statusPageList",
    undefined,
    async (apiClient, data: ParentFormType) => {
      // Create the page
      const { status_page: page } = await apiClient.statusPageCreate({
        createRequestBody: {
          ...data,
          theme: data.theme as unknown as StatusPageCreateRequestBodyThemeEnum,
          darkmode_logo_key:
            data.theme === StatusPageThemeEnum.Dark
              ? data.logo?.key
              : undefined,
          lightmode_logo_key:
            data.theme === StatusPageThemeEnum.Light
              ? data.logo?.key
              : undefined,
          favicon_key: data.favicon?.key,
          parent_page_options: {
            page_type: StatusPageParentPageOptionsPageTypeEnum.Parent,
            auto_create_catalog_configuration:
              data.mode === "auto_create"
                ? {
                    catalog_type_name: data.reason_for_split,
                    status_pages: Object.entries(
                      data.auto_create_sub_pages ?? {},
                    ).map(([id, page]) => ({
                      id,
                      ...page,
                      path: page.subpath,
                    })),
                    components: Object.values(
                      data.auto_create_components || {},
                    ).map((component, index) => ({
                      ...component,
                      rank: index,
                    })),
                  }
                : undefined,
            ...(data.mode === "catalog_import"
              ? {
                  split_by_catalog_type_id: data.split_by_catalog_type_id,
                  split_by_component_attribute_id:
                    data.components_defined_by_catalog_attribute_id,
                  group_by_catalog_attribute_id:
                    data.group_by_defined_by_catalog_attribute_id,
                }
              : undefined),
            sub_pages:
              data.mode === "catalog_import"
                ? _.compact(
                    Object.entries(data.catalog_import_sub_pages || {})
                      .filter((page) => page[1].enabled)
                      .map((page) => ({
                        name: page[1].name,
                        path: page[1].subpath,
                        enabled: page[1].enabled,
                        subpath: page[1].subpath,
                        defined_by_catalog_entry_id: page[0],
                      })),
                  )
                : [],
          },
        },
      });
      navigate(`/status-pages/${page.id}`);
    },
    {
      setError: formMethods.setError,
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Could not create status page",
          description:
            "Please try again, or get in touch if the problem persists.",
        });
      },
    },
  );

  return (
    <FormV2
      outerClassName="pb-8"
      innerClassName="space-y-12"
      formMethods={formMethods}
      onSubmit={onSubmit}
    >
      <div className="space-y-6">
        <Heading size="2xl" level={2}>
          Basic details
        </Heading>
        <BasicSettingsFormContent isParentPage />
      </div>
      <FormDivider />
      <div className="space-y-2">
        <Heading size="2xl" level={2}>
          Sub-pages
        </Heading>
        <SubPageCreationForm />
      </div>
      <FormDivider />
      <div className="space-y-6">
        <Heading size="2xl" level={2}>
          Branding
        </Heading>
        <SubPageBrandingForm />
      </div>
      <Button
        className="ml-auto"
        analyticsTrackingId={"create-status-page"}
        theme={ButtonTheme.Primary}
        type={"submit"}
      >
        Create status page
      </Button>
    </FormV2>
  );
};
