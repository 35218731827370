import { StatusPageIncidentUpdate } from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { Callout, CalloutTheme, ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";

type EditIncidentUpdateModalFormType = {
  // Typescript can't handle the TextNode type
  message: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  published_at: Date;
};

export const StatusPageIncidentUpdateEditModal = ({
  onClose,
  update,
  previousUpdate,
  nextUpdate,
}: {
  onClose: () => void;
  update: StatusPageIncidentUpdate;
  previousUpdate?: StatusPageIncidentUpdate;
  nextUpdate?: StatusPageIncidentUpdate;
}) => {
  const formMethods = useForm<EditIncidentUpdateModalFormType>({
    defaultValues: {
      message: update.message,
      published_at: update.published_at,
    },
  });
  const {
    setError,
    formState: { isDirty },
  } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "statusPageShowIncident",
    {
      id: update.status_page_incident_id,
    },
    async (apiClient, data: EditIncidentUpdateModalFormType) =>
      await apiClient.statusPageUpdateIncidentUpdate({
        id: update.id,
        updateIncidentUpdateRequestBody: {
          message: data.message,
          published_at: data.published_at,
          timestamp_updated:
            update?.published_at.setMilliseconds(0) !==
            data.published_at.setMilliseconds(0),
        },
      }),
    {
      onSuccess: onClose,
      setError,
    },
  );

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={onClose}
      disableQuickClose={false}
      genericError={genericError}
      title={`Edit incident update`}
      analyticsTrackingId="edit-status-page-incident-update"
      footer={
        <ModalFooter
          onClose={onClose}
          saving={saving}
          disabled={!isDirty}
          confirmButtonType="submit"
        />
      }
    >
      <DateTimeInputV2
        name="published_at"
        formMethods={formMethods}
        helptext="Updating this timestamp will not have any impact on the affected components timeline."
        rules={{
          validate: (value: Date): string | undefined => {
            if (value > new Date()) {
              return "Date cannot be in the future";
            }

            // The first update must be after the previous one
            if (!nextUpdate) {
              if (!previousUpdate) return undefined;

              if (value < previousUpdate.published_at) {
                return "Date must be after previous update";
              }

              return undefined;
            }

            // The last update must be before the next one
            if (!previousUpdate) {
              if (value > nextUpdate.published_at) {
                return "Date must be before next update";
              }

              return undefined;
            }

            // any other update must be between the surrounding updates
            if (
              value < previousUpdate.published_at ||
              value > nextUpdate.published_at
            ) {
              return "Date must be between the surrounding updates";
            }

            return undefined;
          },
        }}
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="message"
        format="mrkdwn"
        multiLine
        includeVariables={false}
        includeExpressions={false}
        required="You must set a message"
      />
      <Callout theme={CalloutTheme.Info}>
        <p className="mb-1 font-medium">
          This update is displayed on your public status page
        </p>
        <p>
          We won&apos;t notify your subscribers about any changes you make here.
        </p>
      </Callout>
    </FormModalV2>
  );
};
