import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  ModalFooter,
} from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { useAPIMutation } from "src/utils/swr";

export const RenameOrganisation = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.RenameOrganisation,
  );

  return (
    <>
      <GatedButton
        analyticsTrackingId={null}
        onClick={() => setIsOpen(true)}
        title="Rename organisation"
        theme={ButtonTheme.Naked}
        className="group !flex items-center font-medium !text-lg text-content-primary"
        {...disabledProps}
      >
        {data.organisation_name}
        <Icon
          id={IconEnum.Edit}
          className="text-content-tertiary group-hover:text-content-primary transition"
          size={IconSize.Small}
        />
      </GatedButton>

      {isOpen && (
        <RenameOrganisationModal data={data} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

const RenameOrganisationModal = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const formMethods = useForm<{ organisation_name: string }>({
    defaultValues: {
      organisation_name: data.organisation_name,
    },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "staffShowOrganisation",
    {
      organisationSlug: data.organisation_slug,
    },
    async (apiClient, formData) => {
      await apiClient.staffRenameOrganisation({
        organisationSlug: data.organisation_slug,
        renameOrganisationRequestBody: formData,
      });
    },
    { setError: formMethods.setError, onSuccess: onClose },
  );

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={trigger}
      saving={isMutating}
      genericError={genericError}
      title={`Rename ${data.organisation_name}`}
      onClose={onClose}
      analyticsTrackingId={null}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonText="Rename"
          confirmButtonType="submit"
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="organisation_name"
        label="Organisation name"
        helptext="This will be visible to the customer in the organisation switcher."
      />
    </FormModalV2>
  );
};
