/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPreferencesUpdateSubscriptionPreferencesRequestBody
 */
export interface UserPreferencesUpdateSubscriptionPreferencesRequestBody {
    /**
     * Defines whether to send incident subscription updates via email
     * @type {boolean}
     * @memberof UserPreferencesUpdateSubscriptionPreferencesRequestBody
     */
    receives_subscriptions_via_email: boolean;
    /**
     * Defines whether to send incident subscription updates via Slack DMs
     * @type {boolean}
     * @memberof UserPreferencesUpdateSubscriptionPreferencesRequestBody
     */
    receives_subscriptions_via_slack_DM: boolean;
    /**
     * Defines whether to send incident subscription updates via SMS
     * @type {boolean}
     * @memberof UserPreferencesUpdateSubscriptionPreferencesRequestBody
     */
    receives_subscriptions_via_sms: boolean;
}

export function UserPreferencesUpdateSubscriptionPreferencesRequestBodyFromJSON(json: any): UserPreferencesUpdateSubscriptionPreferencesRequestBody {
    return UserPreferencesUpdateSubscriptionPreferencesRequestBodyFromJSONTyped(json, false);
}

export function UserPreferencesUpdateSubscriptionPreferencesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPreferencesUpdateSubscriptionPreferencesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'receives_subscriptions_via_email': json['receives_subscriptions_via_email'],
        'receives_subscriptions_via_slack_DM': json['receives_subscriptions_via_slack_DM'],
        'receives_subscriptions_via_sms': json['receives_subscriptions_via_sms'],
    };
}

export function UserPreferencesUpdateSubscriptionPreferencesRequestBodyToJSON(value?: UserPreferencesUpdateSubscriptionPreferencesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'receives_subscriptions_via_email': value.receives_subscriptions_via_email,
        'receives_subscriptions_via_slack_DM': value.receives_subscriptions_via_slack_DM,
        'receives_subscriptions_via_sms': value.receives_subscriptions_via_sms,
    };
}

