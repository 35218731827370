import { FormLabel } from "@incident-shared/forms/v1/FormInputHelpers";
import { ListEditorValue } from "@incident-shared/forms/v1/ListEditor";
import {
  SlackChannelFormValue,
  SlackChannelsEditorV2,
} from "@incident-shared/forms/v2/editors";
import { ListEditorV2 } from "@incident-shared/forms/v2/editors/ListEditorV2";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxRowV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Button,
  ButtonTheme,
  Checkbox,
  GenericErrorMessage,
  Heading,
  Loader,
  StackedList,
  Txt,
} from "@incident-ui";
import { DrawerBody, DrawerFooter } from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { InputType } from "@incident-ui/Input/Input";
import { isEmpty } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import { useForm } from "react-hook-form";
import styles from "src/components/insights/assistant/AssistantOverlay.module.scss";
import {
  PoliciesCreateReportScheduleRequestBody,
  PoliciesCreateReportScheduleRequestBodyDayOfWeekEnum,
  PoliciesCreateReportScheduleRequestBodyIntervalEnum,
  PoliciesUpdateReportScheduleRequestBody,
  Policy,
  PolicyReportChannel,
  PolicyReportChannelTypeEnum,
  PolicyReportSchedule,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useRevalidate } from "src/utils/use-revalidate";
import { emailRegex } from "src/utils/utils";
import { v4 as uuidv4 } from "uuid";

import PolicyReportScreenshot from "../../images/policy_report_screenshot.png";
import { PolicyReportIntervalEditor } from "./PolicyReportIntervalEditor";
import { findTimeZone } from "./timezone";

export type PolicyReportFormData = {
  name: string;
  slackChannels?: SlackChannelFormValue[];
  emails?: ListEditorValue<string>[];
  policyIds: Set<string>;
  interval: PoliciesCreateReportScheduleRequestBody["interval"];
  hourString: string;
  dayOfTheWeek?: PoliciesCreateReportScheduleRequestBody["day_of_week"];
  dayOfTheMonthString?: string;
  countryCode?: string;
  timezone?: string;
  suppress_if_empty: boolean;
};

const getDefaultEmailRow = () => ({
  id: uuidv4(),
  value: "",
  sort_key: 10000,
});

export const PolicyReportCreateEditForm = ({
  onClose,
  formProps,
  hidePolicySelector,
  fullWidth = true,
  onCreateCallback,
}: {
  onClose: () => void;
  formProps: CreateEditFormProps<PolicyReportSchedule>;
  hidePolicySelector: boolean;
  fullWidth?: boolean;
  onCreateCallback?: (data: PolicyReportSchedule) => void;
}): React.ReactElement => {
  const isEditing = formProps.mode === Mode.Edit;
  const initialData = formProps.initialData;
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const existingEmails =
    initialData?.channels?.filter(
      (c) => c.type === PolicyReportChannelTypeEnum.Email,
    ) ?? [];
  const existingSlackChannels =
    initialData?.channels?.filter(
      (c) => c.type === PolicyReportChannelTypeEnum.SlackChannel,
    ) ?? [];
  const [isSlackChannelSelected, setIsSlackChannelSelected] = React.useState(
    (existingSlackChannels ?? []).length > 0,
  );
  const [isEmailSelected, setIsEmailSelected] = React.useState(
    (existingEmails ?? []).length > 0,
  );

  const {
    data: { policies },
    isLoading: loading,
    error,
  } = useAPI("policiesList", undefined, { fallbackData: { policies: [] } });

  const formMethods = useForm<PolicyReportFormData>({
    defaultValues: getDefaultValues({
      report: initialData,
      existingEmails,
      existingSlackChannels,
      userTimezone,
    }),
  });
  const { setError, setValue, clearErrors, watch } = formMethods;

  const [selectedPolicyIds] = watch(["policyIds"]);
  const refreshPolicyReports = useRevalidate([
    "policiesListReportSchedule",
    "policiesShowReportSchedule",
  ]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "policiesListReportSchedule",
    undefined,
    async (apiClient, data: PolicyReportFormData) => {
      const channels: PolicyReportChannel[] = [];
      if (isSlackChannelSelected && data.slackChannels) {
        channels.push(
          ...data.slackChannels.map((slackChannel) => ({
            type: PolicyReportChannelTypeEnum.SlackChannel,
            id: slackChannel.value,
            label: slackChannel.label,
            is_private: slackChannel.is_private,
          })),
        );
      }
      if (isEmailSelected && data.emails) {
        channels.push(
          ...data.emails.map((email) => ({
            type: PolicyReportChannelTypeEnum.Email,
            id: email.value,
            label: email.value,
            is_private: false,
          })),
        );
      }

      const validFormData = validatePolicyReportFormData(data);

      // We should always resolve a time zone here, but if not, we fall back to UTC below.
      const timeZone = findTimeZone(validFormData.timezone);

      const reportBody = {
        name: validFormData.name,
        interval:
          validFormData.interval as unknown as PoliciesCreateReportScheduleRequestBody["interval"],
        day_of_week: validFormData.dayOfTheWeek,
        day_of_month: parseInt(validFormData.dayOfTheMonthString || ""),
        hour: parseInt(validFormData.hourString),
        policy_ids: Array.from(selectedPolicyIds),
        channels: channels,
        time_zone: timeZone?.name ?? "Etc/UTC",
        country_code: timeZone?.countryCode ?? "etc",
        suppress_if_empty: validFormData.suppress_if_empty,
      };
      if (!isEditing) {
        const data = await apiClient.policiesCreateReportSchedule({
          createReportScheduleRequestBody: reportBody,
        });

        if (onCreateCallback) {
          onCreateCallback(data.report_schedule);
        }
      } else if (initialData) {
        await apiClient.policiesUpdateReportSchedule({
          id: initialData.id,
          updateReportScheduleRequestBody:
            reportBody as unknown as PoliciesUpdateReportScheduleRequestBody,
        });
      }
    },
    {
      setError,
      onSuccess: () => {
        refreshPolicyReports();
        onClose();
      },
    },
  );

  const allItemsSelected = policies?.length === selectedPolicyIds.size;
  const onDeSelectAllSchedules = () =>
    setValue<"policyIds">("policyIds", new Set());
  const onSelectAllSchedules = () =>
    setValue<"policyIds">(
      "policyIds",
      new Set((policies ?? []).map((p) => p.id)),
    );
  const onAddPolicy = (policy: Policy) => {
    setValue<"policyIds">("policyIds", selectedPolicyIds.add(policy.id));
  };
  const onRemovePolicy = (policy: Policy) => {
    selectedPolicyIds.delete(policy.id);
    setValue<"policyIds">("policyIds", selectedPolicyIds);
  };

  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );

  if (loading || !policies) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const validateAndSubmit = (data: PolicyReportFormData) => {
    const numEmails = (data.emails ?? []).length;
    const numSelectedChannels = (data.slackChannels ?? []).length;
    let valid = true;
    if (!isSlackChannelSelected && !isEmailSelected) {
      setError("slackChannels", {
        type: "required",
        message: "You must select at least one channel",
      });
      valid = false;
    }
    if (isSlackChannelSelected && numSelectedChannels === 0) {
      setError("slackChannels", {
        type: "required",
        message: "You must select at least one Slack channel",
      });
      valid = false;
    }
    if (isEmailSelected && numEmails === 0) {
      setError("emails", {
        type: "required",
        message: "You must enter at least one email",
      });
      valid = false;
    }
    if (isEmailSelected && data.emails) {
      const invalidEmails = data.emails.filter(
        (email) => !emailRegex.test(email.value),
      );
      if (invalidEmails.length > 0) {
        setError("emails", {
          type: "invalid",
          message: "You must enter a valid email",
        });
        valid = false;
      }
    }
    if (!data.timezone) {
      setError("timezone", {
        type: "required",
        message: "Please select a valid time zone",
      });
      return;
    }

    if (!hidePolicySelector && isEmpty(data.policyIds)) {
      setError("policyIds", {
        type: "required",
        message: "Please select at least one policy",
      });
      valid = false;
    }

    if (valid) {
      onSubmit(data);
    }
  };

  return (
    <>
      {/* DrawerBody */}
      <DrawerBody
        className={tcx(
          "flex flex-row min-h-0 overflow-y-hidden h-full !p-0 !gap-0",
        )}
      >
        {/* Left hand, input side of the form */}
        <div
          className={tcx(
            "!h-full overflow-auto flex flex-col gap-6 flex-[2] border-r border-slate-200 p-6",
            styles.hideScrollbar,
          )}
        >
          <FormV2
            genericError={genericError}
            onSubmit={validateAndSubmit}
            formMethods={formMethods}
            saving={saving}
            innerClassName="flex flex-col gap-4"
            id="policy-report-create-edit"
          >
            <InputV2
              formMethods={formMethods}
              label="Name"
              name="name"
              helptext="A name for your report, this will be shown in the message we send you."
              data-testid="name-input"
              placeholder="e.g. Weekly critical incident review"
              required="Please provide a name for your report"
            />

            {!hidePolicySelector && (
              <div>
                <div className="flex w-full">
                  <FormLabel htmlFor={"policy_ids"} className={"grow mb-2"}>
                    Which policies should be reported on?
                  </FormLabel>
                  {policies.length > 5 ? (
                    <Checkbox
                      className="mb-4 font-md"
                      id="select_all"
                      onChange={
                        allItemsSelected
                          ? onDeSelectAllSchedules
                          : onSelectAllSchedules
                      }
                      checked={allItemsSelected}
                      label="Select All"
                    />
                  ) : null}
                </div>
                <FormInputWrapperV2 name="policyIds">
                  {policies.length === 0 ? (
                    <Txt grey>You haven&apos;t created any policies yet.</Txt>
                  ) : (
                    <StackedList>
                      {policies.map((policy) => {
                        const isSelected = selectedPolicyIds.has(policy.id);
                        return (
                          <SelectablePolicy
                            key={policy.id}
                            policy={policy}
                            isSelected={isSelected}
                            onChangeSelected={() =>
                              isSelected
                                ? onRemovePolicy(policy)
                                : onAddPolicy(policy)
                            }
                          />
                        );
                      })}
                    </StackedList>
                  )}
                </FormInputWrapperV2>
              </div>
            )}
            <PolicyReportIntervalEditor formMethods={formMethods} />
            <div className="flex flex-row justify-between">
              <div>
                <Txt bold className="mb-4">
                  How would you like to receive your policy report?
                </Txt>
                <div className="min-w-lg max-w-lg mr-4 grow">
                  <div className="flex flex-row items-center mb-2">
                    <Checkbox
                      className={"mr-2"}
                      id={"slack_channel"}
                      onChange={(boolval) =>
                        setIsSlackChannelSelected(boolval.target.checked)
                      }
                      // Note that users can click "deselect all" which will clear the
                      // selectedFollowUpIDs array.
                      checked={isSlackChannelSelected}
                    />
                    <FormLabel htmlFor="slack_channel">
                      To a Slack channel(s)
                    </FormLabel>
                  </div>

                  <div className="ml-6">
                    <SlackChannelsEditorV2
                      formMethods={formMethods}
                      name={"slackChannels"}
                      disabled={!isSlackChannelSelected}
                      hideWarning={true}
                    />
                  </div>
                </div>
                <div className="mt-4 min-w-lg max-w-lg mr-4 grow">
                  <div className="flex flex-row items-center mb-2">
                    <Checkbox
                      className="mr-2"
                      id={"email"}
                      onChange={(boolval) =>
                        setIsEmailSelected(boolval.target.checked)
                      }
                      // Note that users can click "deselect all" which will clear the
                      // selectedFollowUpIDs array.
                      checked={isEmailSelected}
                    />
                    <FormLabel htmlFor={"email"}>By email(s)</FormLabel>
                  </div>
                  <ListEditorV2
                    formMethods={formMethods}
                    name="emails"
                    className={"ml-6"}
                    allowEdit={isEmailSelected}
                    getDefaultRow={getDefaultEmailRow}
                    rowPlaceholder="e.g. erlich@piedpiper.com"
                    onClearErrors={() => clearErrors("emails")}
                    addNewText="Add another email"
                    inputType={InputType.Email}
                    allowReordering={false}
                  />
                </div>
              </div>
            </div>
            <hr className="my-6" />
            {/* Send if empty? */}
            <CheckboxRowV2
              formMethods={formMethods}
              name="suppress_if_empty"
              label="Suppress reports if there are no violations"
            />
          </FormV2>
        </div>

        {/* Right hand, preview side of the form */}
        {fullWidth && (
          <div
            className={
              "flex flex-col items-stretch flex-[1] p-6 bg-slate-50 overflow-auto gap-3"
            }
          >
            <Heading level={2}>Example report</Heading>
            <div className="max-w-md object-scale-down">
              <img
                src={PolicyReportScreenshot}
                alt="screenshot of a policy report message in Slack"
                className={
                  "shadow-lg rounded-2 px-[4px] py-[8px] border border-1 border-stroke bg-white"
                }
              />
            </div>
          </div>
        )}
      </DrawerBody>

      <DrawerFooter className="flex justify-end gap-2">
        <Button
          analyticsTrackingId={null}
          onClick={() => onCloseWithWarn(isDirty)}
          theme={ButtonTheme.Secondary}
        >
          Cancel
        </Button>
        <Button
          type={"submit"}
          analyticsTrackingId={null}
          theme={ButtonTheme.Primary}
          disabled={saving}
          loading={saving}
          form="policy-report-create-edit"
        >
          {formProps.mode === Mode.Edit ? "Save" : "Create report"}
        </Button>
      </DrawerFooter>
    </>
  );
};

const SelectablePolicy = ({
  policy,
  isSelected,
  onChangeSelected,
}: {
  policy: Policy;
  isSelected: boolean;
  onChangeSelected: () => void;
}): React.ReactElement => {
  return (
    <li
      className={tcx("p-6 w-full", {
        "!bg-surface-secondary border-stroke": isSelected,
      })}
    >
      <div
        className="flex items-center cursor-pointer"
        onClick={onChangeSelected}
      >
        <Checkbox
          id={policy.id}
          value={policy.id}
          checked={isSelected}
          onChange={onChangeSelected}
          className="mr-2"
        />
        <div className="grow">
          <Txt className="flex">{policy.name}</Txt>
        </div>
      </div>
    </li>
  );
};

// If someone has changed their mind between daily/weekly/monthly
// intervals, we need to make sure only the valid form data
// gets submitted (eg monthy intervals => no day of week)
const validatePolicyReportFormData = (formData: PolicyReportFormData) => {
  const validatedFormData = cloneDeep(formData);
  switch (validatedFormData.interval) {
    case PoliciesCreateReportScheduleRequestBodyIntervalEnum.Daily:
      delete validatedFormData.dayOfTheWeek;
      delete validatedFormData.dayOfTheMonthString;
      break;
    case PoliciesCreateReportScheduleRequestBodyIntervalEnum.Weekly:
      delete validatedFormData.dayOfTheMonthString;
      break;
    case PoliciesCreateReportScheduleRequestBodyIntervalEnum.Monthly:
      delete validatedFormData.dayOfTheWeek;
      break;
  }
  return validatedFormData;
};

const getDefaultValues = ({
  report,
  existingEmails,
  existingSlackChannels,
  userTimezone,
}: {
  report?: PolicyReportSchedule;
  userTimezone: string;
  existingEmails: PolicyReportChannel[];
  existingSlackChannels: PolicyReportChannel[];
}): Partial<PolicyReportFormData> => {
  if (report) {
    return {
      name: report.name,
      interval:
        report.interval as unknown as PoliciesCreateReportScheduleRequestBodyIntervalEnum,
      policyIds: new Set(report.policy_ids ?? []),
      slackChannels: existingSlackChannels?.map((c) => {
        return {
          value: c.id,
          label: c.label,
          is_private: c.is_private,
        };
      }),
      emails:
        existingEmails.length > 0
          ? existingEmails.map(
              (c, i): ListEditorValue<string> => ({
                value: c.id,
                id: uuidv4(),
                sort_key: i,
              }),
            )
          : [getDefaultEmailRow()],
      hourString: report.hour?.toString(),
      dayOfTheWeek:
        report.day_of_week as unknown as PoliciesCreateReportScheduleRequestBodyDayOfWeekEnum,
      dayOfTheMonthString: report.day_of_month?.toString(),
      timezone: report.time_zone,
      suppress_if_empty: report.suppress_if_empty,
    };
  }

  return {
    interval: PoliciesCreateReportScheduleRequestBodyIntervalEnum.Weekly,
    policyIds: new Set([]),
    slackChannels: [],
    emails: [getDefaultEmailRow()],
    hourString: "10",
    dayOfTheWeek: PoliciesCreateReportScheduleRequestBodyDayOfWeekEnum.Monday,
    timezone: userTimezone,
    suppress_if_empty: true,
  };
};
