import { isEmptyBinding } from "@incident-shared/engine";
import { isEqual } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { Control, Path, UseFormReturn, useWatch } from "react-hook-form";
import {
  EngineParamBindingPayload,
  IssueTrackerField,
  IssueTrackerFieldValuePayload,
  IssueTrackersV2ListCreationFieldsRequestBodyProviderEnum,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { useDeepCompareMemo } from "use-deep-compare";

import { IssueTrackerProviderEnum } from "./utils";

export interface HasFieldValues {
  field_values: Record<string, EngineParamBindingPayload>;
}

export const useIssueCreationFields = ({
  provider,
  formMethods,
  followUpId,
  issueTemplateId,
}: {
  provider: IssueTrackerProviderEnum;
  followUpId?: string;
  issueTemplateId?: string;
  formMethods: UseFormReturn<HasFieldValues>;
}) => {
  const [fieldValues, setFields] = useFieldValuesWithDependencies(
    formMethods.control,
  );

  // This lets us bump the react key for each field's input whenever we change
  // its default. This is a workaround for the fact that our TemplatedTextInput
  // doesn't respond well to changes in form state. Bumping the key forces the
  // component to un- and re-mount, which makes it load in the form state again.
  const fieldKeySuffixes = useRef<Record<string, number>>({});
  const reactKeyForField = useCallback(
    (field: IssueTrackerField) =>
      `${field.param.name}:${field.param.type}:${
        fieldKeySuffixes.current[field.param.name]
      }`,
    [],
  );

  const { data, isValidating, error } = useAPI(
    "issueTrackersV2ListCreationFields",
    {
      listCreationFieldsRequestBody: {
        provider:
          provider as unknown as IssueTrackersV2ListCreationFieldsRequestBodyProviderEnum,
        follow_up_id: followUpId,
        issue_template_id: issueTemplateId,
        field_values: fieldValues,
      },
    },
    {
      fallbackData: { fields: [], fields_complete: false, resources: [] },
      keepPreviousData: true, // we do this so the form doesn't keep collapsing back to an empty form
    },
  );

  useEffect(() => {
    setFields(data.fields);
    // When we get a new set of fields, if any of them have a default value,
    // inject that into the form's default values.
    data.fields.forEach((field) => {
      let keySuffix = fieldKeySuffixes.current[field.param.name] ?? 0;

      if (field.param.default_value) {
        const path: Path<HasFieldValues> = `field_values.${field.param.name}`;
        const defaultValue = field.param.default_value;

        // Grab the current value in the form, in case it's already been set
        const currentValue = formMethods.getValues(path);
        const { isDirty } = formMethods.getFieldState(path);

        // We only want to inject defaults if there's currently no value in that
        // field, or the field hasn't been touched by the user (i.e. it's been
        // only set by us so far)
        const couldOverrideCurrentValue =
          isEmptyBinding(currentValue) || !isDirty;
        // If the value in the form is already what we want, don't re-set it.
        // For unknown reasons, this can confuse our rich-text input.
        const needsSetting = !isEqual(currentValue, defaultValue);

        // If the value hasn't been set yet, replace it with the default
        if (couldOverrideCurrentValue && needsSetting) {
          keySuffix += 1;
          formMethods.resetField(path, {
            defaultValue,
            keepTouched: true,
            keepDirty: true,
          });
          formMethods.setValue(path, defaultValue);
        }
      }

      fieldKeySuffixes.current[field.param.name] = keySuffix;
    });
  }, [data, setFields, formMethods]);

  // For some reason SWR's usual deep-comparison stuff doesn't work properly on
  // this response, so we do it manually.
  const memoisedData = useDeepCompareMemo(() => data, [data]);

  return {
    data: memoisedData,
    isValidating,
    error,
    reactKeyForField,
  };
};

// useFieldValuesWithDependencies returns the field values that should be sent
// to the API when they change, since they:
// 1. have a value; and
// 2. other fields may depend on that value.
const useFieldValuesWithDependencies = (
  control: Control<HasFieldValues>,
): [IssueTrackerFieldValuePayload[], (fields: IssueTrackerField[]) => void] => {
  // We use a ref here rather than state, since we don't want to re-evaulate
  // this when fields change, but only when field _values_ (in the form state)
  // change.
  const fieldsRef = useRef<IssueTrackerField[]>([]);
  const setFields = useCallback((fields: IssueTrackerField[]) => {
    fieldsRef.current = fields;
  }, []);

  // We need to useWatch here rather than `formMethods.watch`, since a `watch`
  // doesn't trigger a re-render of the component on a change, whereas a
  // `useWatch` does.
  const values = useWatch({
    control,
    name: "field_values",
  });

  const fieldValues = useDeepCompareMemo(() => {
    const fieldKeysThatHaveDependencies = new Set(
      fieldsRef.current
        .filter((field) => field.has_dependent_fields)
        .map((field) => field.param.name),
    );

    return (
      Object.entries(values)
        // remove any blank values - this is usually due to an input having just
        // been mounted, and there's no point making another request
        .filter(([_, value]) => !isEmptyBinding(value))
        // remove any values that don't have dependencies (and therefore won't
        // cause the set of fields to change)
        .filter(([key, _]) => fieldKeysThatHaveDependencies.has(key))
        .map(
          ([key, param_binding]): IssueTrackerFieldValuePayload => ({
            key,
            param_binding,
          }),
        )
    );
  }, [values]);

  return [fieldValues, setFields];
};

export const useClearDependencies = (
  { resetField, watch }: UseFormReturn<HasFieldValues>,
  fieldsProp: IssueTrackerField[],
) => {
  // A little hack here to say that we don't care about changing the useEffect
  // if fields change, only on a value change.
  const fields = useRef(fieldsProp);
  fields.current = fieldsProp;

  useEffect(() => {
    const { unsubscribe } = watch((_, { name }) => {
      if (!name?.startsWith("field_values.")) {
        return;
      }

      // Strip the leading `field_values.` and anything inside the binding
      // (.value* or .array_value*), to figure out just the field param name.
      const fieldName = name
        .replace(/^field_values\./, "")
        .replace(/\.(value|array_value).*/, "");

      fields.current.forEach((field) => {
        if (field.depends_on.includes(fieldName)) {
          resetField(`field_values.${field.param.name}`);
        }
      });
    });

    return () => unsubscribe();
  }, [watch, resetField]);
};
