import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonTheme, Card, IconEnum } from "@incident-ui";

import graphic from "./teams-dashboard-empty-graphic.svg";

export const NoTeamsEmptyState = () => {
  return (
    <div className="flex flex-col grow p-6 gap-6">
      <div className="flex flex-col grow justify-center items-center rounded-2 bg-gradient-to-b from-[#F3E8FFFF] to-[#F3E8FF00]">
        <div className="flex flex-col gap-6 items-center justify-center max-w-[428px]">
          <img src={graphic} className="my-6" />
          <div className="flex flex-col gap-2 items-center">
            <div className="text-2xl-bold">Unlock Team-based insights</div>
            <div className="text-base text-center">
              By connecting users with their respective teams in Catalog, you
              can unlock powerful new insights
            </div>
          </div>
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Primary}
            href="/catalog/team-wizard"
            openInNewTab={true}
          >
            Set up Teams in Catalog
          </Button>
        </div>
      </div>
      <div className="flex flex-row gap-6">
        <Card
          icon={IconEnum.Activity}
          color={ColorPaletteEnum.Violet}
          title="Workload"
          description="Understand hours spent on incidents per team"
        />
        <Card
          icon={IconEnum.Action}
          color={ColorPaletteEnum.Violet}
          title="Follow-ups & Tasks"
          description="Spot trends in Follow-up completion rates, by team"
        />
        <Card
          icon={IconEnum.Users}
          color={ColorPaletteEnum.Violet}
          title="Split by"
          description="Break down data by team, or by users within a team"
        />
      </div>
    </div>
  );
};
