import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import React from "react";

export const UnsupportedJiraFieldsErrorMessage = ({
  unsupportedRequiredFields,
}: {
  unsupportedRequiredFields: string[];
}): React.ReactElement => {
  return (
    <ErrorMessageUI
      message={`This Jira issue type contains required fields that we don't yet support: ${unsupportedRequiredFields.join(
        ", ",
      )}`}
    />
  );
};
