import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeTheme,
  ButtonTheme,
  IconEnum,
  Loader,
  StackedList,
  Txt,
} from "@incident-ui";
import React from "react";
import {
  PoliciesDestroyReportScheduleRequest,
  PolicyReportChannelTypeEnum,
  PolicyReportSchedule,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../../../@shared/settings/SettingsList/SettingsListItem";
import PolicyReportScreenshot from "../../../images/policy_report_screenshot.png";
import { SettingsSubHeading } from "../../../SettingsSubHeading";
import { PolicyReportDescription } from "../../common/PolicyReportDescription";

export const PolicyReportList = ({
  policiesCount,
  reportSchedules,
}: {
  policiesCount: number;
  reportSchedules: PolicyReportSchedule[];
}): React.ReactElement => {
  const { trigger: deleteReport, isMutating: saving } = useAPIMutation(
    "policiesListReportSchedule",
    undefined,
    async (apiClient, data: PoliciesDestroyReportScheduleRequest) => {
      await apiClient.policiesDestroyReportSchedule(data);
    },
  );

  if (saving) {
    return <Loader />;
  }

  return (
    <div>
      <SettingsSubHeading
        title="Your policy reports"
        accessory={
          reportSchedules.length > 0 ? (
            <GatedButton
              theme={ButtonTheme.Secondary}
              href="/settings/policies/reports/create"
              requiredScope={ScopeNameEnum.PoliciesCreate}
              analyticsTrackingId="create-policy-report"
              icon={IconEnum.Add}
            >
              New policy report
            </GatedButton>
          ) : undefined
        }
      />
      {reportSchedules.length > 0 ? (
        <StackedList>
          {reportSchedules.map((s) => (
            <ReportScheduleListItem
              key={s.id}
              schedule={s}
              onDelete={() => deleteReport(s)}
            />
          ))}
        </StackedList>
      ) : (
        <WhatArePolicyReportsBlurb policiesCount={policiesCount} />
      )}
    </div>
  );
};

const ReportScheduleListItem = ({
  schedule,
  onDelete,
}: {
  schedule: PolicyReportSchedule;
  onDelete: () => Promise<unknown>;
}): React.ReactElement => {
  const hasEmail = schedule.channels.some(
    (ch) => ch.type === PolicyReportChannelTypeEnum.Email,
  );
  return (
    <SettingsListItem
      title={schedule.name}
      icon={hasEmail ? IconEnum.Email : IconEnum.SlackGreyscale}
      iconColor={ColorPaletteEnum.Yellow}
      badgeProps={
        schedule.policy_ids.length === 0
          ? {
              theme: BadgeTheme.Error,
              icon: IconEnum.Warning,
              label: "No policies",
            }
          : undefined
      }
      buttons={{
        edit: {
          editHref: `/settings/policies/reports/${schedule.id}/edit`,
          requiredScope: ScopeNameEnum.PoliciesCreate,
        },
        delete: {
          resourceTitle: schedule.name,
          requiredScope: ScopeNameEnum.PoliciesDestroy,
          onDelete,
          deleteConfirmationTitle: "Delete report",
          deleteConfirmationContent: (
            <div className="flex gap-1 flex-wrap">
              Are you sure you want to delete the{" "}
              <div className="font-semibold">{schedule.name}</div> policy
              report?
            </div>
          ),
        },
      }}
      description={
        <PolicyReportDescription schedule={schedule} className="text-xs" />
      }
    />
  );
};

export const policyChannelsDescription = (
  reportSchedule: PolicyReportSchedule,
  className?: string,
): React.ReactNode => {
  const classNames = className ? `${className} ` : "";

  const names = reportSchedule.channels
    .map((c) =>
      c.type === PolicyReportChannelTypeEnum.SlackChannel && !c.is_private
        ? `#${c.label}`
        : c.label,
    )
    .filter((n) => n !== "");

  let nameElements = <></>;
  const lastIndex = names.length - 1;
  names.forEach((name, index) => {
    nameElements = (
      <Txt grey inline bold className={classNames}>
        {nameElements}
        {index === 0 ? "" : index === lastIndex ? " and " : ", "}
        {name}
      </Txt>
    );
  });

  return nameElements;
};

const WhatArePolicyReportsBlurb = ({
  policiesCount,
}: {
  policiesCount: number;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  return (
    <div className="border border-stroke rounded-2 p-6 flex flex-col items-center gap-2">
      <Txt>
        Set up a policy report to receive regular updates about how well your
        organisation is adhering to your policies.
      </Txt>
      <img
        src={PolicyReportScreenshot}
        alt={"screenshot of a policy report message in Slack"}
        className="w-[400px] my-4 mx-auto shadow-lg rounded-2 px-[4px] py-[8px] border border-1 border-stroke"
      />
      <GatedButton
        disabledTooltipContent={
          policiesCount === 0
            ? "You need to create a policy before you can create a policy report."
            : undefined
        }
        disabled={policiesCount === 0}
        analyticsTrackingId="add-policy-report"
        onClick={() => {
          navigate("/settings/policies/reports/create");
        }}
        icon={IconEnum.Add}
      >
        New report
      </GatedButton>
    </div>
  );
};
