import {
  AlertSourceSourceTypeEnum,
  EngineScope,
  ScopeNameEnum,
} from "@incident-io/api";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Loader,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import _ from "lodash";
import { useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { AlertSourceTypeIcon } from "../common/AlertSourceTypeConfigs";
import { AlertsTable } from "../common/AlertsTable";
import { useAlertResources } from "../common/useAlertResources";
import {
  AlertRouteFormSection,
  AlertRouteYesNoButtonGroup,
} from "./AlertRouteFormSection";
import {
  AlertRouteFormData,
  alertSourcesToPayload,
  FilterFormData,
} from "./types";

export const AlertRouteFilterSection = ({ scope }: { scope: EngineScope }) => {
  const formMethods = useFormContext<AlertRouteFormData>();

  const selectedSources = formMethods.watch("alertSources");

  const onSubmit = (data: FilterFormData) => {
    formMethods.setValue<"conditionGroups">(
      "conditionGroups",
      data.conditionGroups,
      {
        shouldDirty: true,
      },
    );
    setEditingContext({ isEditing: false });
    formMethods.setValue<"hasEverConfiguredFilters">(
      "hasEverConfiguredFilters",
      true,
      {
        shouldDirty: true,
      },
    );
    formMethods.setValue<"expressions">("expressions", data.expressions, {
      shouldDirty: true,
    });
  };

  const [editingContext, setEditingContext] = useState<{
    isEditing: boolean;
    initialData?: FilterFormData;
  }>({
    isEditing: false,
  });

  const conditionGroups = formMethods.watch("conditionGroups");

  const hasEverConfiguredAlertSources = Object.values(selectedSources).some(
    (source) => source.selected,
  );

  const hasEverConfiguredFilters = formMethods.watch(
    "hasEverConfiguredFilters",
  );
  const hasFilters = conditionGroups && conditionGroups.length > 0;

  return (
    <>
      {editingContext.isEditing && (
        <FilterDrawer
          onSubmit={onSubmit}
          onClose={() => setEditingContext({ isEditing: false })}
          initialData={editingContext.initialData}
          selectedSources={selectedSources}
          scope={scope}
        />
      )}
      <AlertRouteFormSection
        title={
          !hasEverConfiguredFilters
            ? "Filter all alerts"
            : hasFilters
            ? "Filter alerts that match:"
            : "Don't apply filters"
        }
        color={ColorPaletteEnum.Blue}
        isCurrentFirstTimeStep={
          !hasEverConfiguredFilters && hasEverConfiguredAlertSources
        }
        firstTimeContent={
          <>
            Choose whether to apply filters to the alerts your receive from your
            selected sources.
          </>
        }
        icon={IconEnum.Filter}
        disabled={hasEverConfiguredFilters && !hasFilters}
        accessory={
          !hasEverConfiguredFilters ? (
            <AlertRouteYesNoButtonGroup
              disabled={!hasEverConfiguredAlertSources}
              onChange={(shouldFilter) => {
                if (shouldFilter) {
                  setEditingContext({
                    isEditing: true,
                    initialData: formMethods.getValues(),
                  });
                } else {
                  formMethods.setValue<"hasEverConfiguredFilters">(
                    "hasEverConfiguredFilters",
                    true,
                  );
                }
              }}
            />
          ) : (
            <GatedButton
              requiredScope={ScopeNameEnum.AlertRouteUpdate}
              theme={ButtonTheme.Naked}
              onClick={() =>
                setEditingContext({
                  isEditing: true,
                  initialData: formMethods.getValues(),
                })
              }
              icon={IconEnum.Edit}
              className="text-content-tertiary"
              title="Edit filters"
              analyticsTrackingId="alert-routes-edit-filters"
            />
          )
        }
      >
        {hasFilters && (
          <ConditionGroupsList
            groups={conditionGroups}
            className="w-full p-0 shadow-none"
          />
        )}
      </AlertRouteFormSection>
    </>
  );
};

const FilterDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  selectedSources,
  scope,
}: {
  onSubmit: (data: FilterFormData) => void;
  onClose: () => void;
  initialData?: FilterFormData;
  selectedSources: AlertRouteFormData["alertSources"];
  scope: EngineScope;
}) => {
  const formMethods = useForm<FilterFormData>({
    defaultValues: initialData,
  });

  const expressionMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });
  const scopeWithExpressions = addExpressionsToScope(
    scope,
    expressionMethods.fields,
  );

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  const conditionGroups = formMethods.watch("conditionGroups");

  const {
    error: resourcesError,
    isLoading: resourcesLoading,
    schemaResponse,
    configsResp,
    resourcesListResp,
  } = useAlertResources();

  const { data: previewAlerts, isLoading: previewAlertsLoading } = useAPI(
    "alertRoutesPreviewFindMatchingAlerts",
    {
      previewFindMatchingAlertsRequestBody: {
        expressions: expressionMethods.fields.map(expressionToPayload),
        condition_groups: conditionGroupsToGroupPayloads(conditionGroups || []),
        alert_sources: alertSourcesToPayload(selectedSources),
      },
    },
  );

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }

  if (
    resourcesLoading ||
    !schemaResponse ||
    !resourcesListResp ||
    !configsResp
  ) {
    return (
      <Drawer width="full" onClose={onClose}>
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  const selectedAlertSourceConfigs = configsResp.alert_source_configs?.filter(
    (config) => selectedSources[config.id]?.selected,
  );

  const selectedAlertSourceConfigsText = selectedAlertSourceConfigs
    ?.map((config) => config.name)
    .join(", ");

  const deduplicatedAlertSources = _.uniqBy(
    selectedAlertSourceConfigs,
    "source_type",
  );

  const groupedAlertSources = _.groupBy(
    selectedAlertSourceConfigs,
    "source_type",
  );

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionMethods}
      allowAllOfACatalogType={false}
    >
      <Drawer width="full" onClose={onClose}>
        <div className="flex flex-col h-full">
          <DrawerContents className="overflow-hidden">
            <DrawerTitle
              title="Filter all alerts"
              onClose={onClose}
              icon={IconEnum.Filter}
              compact
              theme={DrawerTitleTheme.Bordered}
            />

            <div className="flex flex-col grow overflow-y-auto lg:flex-row">
              <DrawerBody className="p-0 overflow-y-hidden">
                <FormV2
                  fullHeight
                  formMethods={formMethods}
                  onSubmit={onSubmit}
                  id="alert-route-filter"
                >
                  <div className={"flex flex-col lg:flex-row h-full grow"}>
                    {/* Left Panel */}
                    <div className="flex flex-col p-6 gap-4 lg:w-[50%]">
                      <div className="space-y-2">
                        <div className="flex space-x-2">
                          {deduplicatedAlertSources?.map((config) => {
                            return (
                              <Tooltip
                                key={config?.id}
                                content={
                                  groupedAlertSources[config.source_type]
                                    .length > 1
                                    ? groupedAlertSources[config.source_type]
                                        .map((x) => x.name)
                                        .join(", ")
                                    : undefined
                                }
                              >
                                <div className="w-10 h-10 bg-white text-center border border-stroke rounded-[6px] flex items-center justify-center">
                                  <AlertSourceTypeIcon
                                    sourceType={
                                      config?.source_type as unknown as AlertSourceSourceTypeEnum
                                    }
                                    size={IconSize.Large}
                                  />
                                </div>
                              </Tooltip>
                            );
                          })}
                        </div>
                        {conditionGroups.length > 0 ? (
                          <div className="text-sm">
                            Receive alerts from{" "}
                            <span className="font-semibold">
                              {selectedAlertSourceConfigsText}
                            </span>{" "}
                            that match the following criteria:
                          </div>
                        ) : (
                          <div className="text-sm">
                            Receive all alerts from{" "}
                            <span className="font-semibold">
                              {selectedAlertSourceConfigsText}
                            </span>
                          </div>
                        )}
                      </div>

                      <ConditionGroupsEditorV2
                        formMethods={formMethods}
                        name="conditionGroups"
                        scope={scopeWithExpressions}
                        entityNameLabel="alert route"
                        subjectsLabel="alerts"
                        conditionLabel="filter"
                        allowAllOfACatalogTypeInQueryExpression={false}
                        expressions={expressionMethods.fields}
                        allowFilteringByExpression
                        hideIntroSentence
                        boxless={conditionGroups.length === 0}
                      />
                    </div>

                    {/* Right panel */}
                    <div
                      className={
                        "flex flex-col p-6 bg-slate-50 border-l border-stroke w-full lg:w-[50%] overflow-auto"
                      }
                    >
                      <div className="text-sm-med text-content-tertiary mb-2">
                        Recent alerts{" "}
                      </div>
                      {previewAlertsLoading ? (
                        <Loader />
                      ) : (
                        <div
                          className={tcx(
                            previewAlerts?.alerts &&
                              previewAlerts?.alerts.length > 0
                              ? "mb-24"
                              : "h-full",
                          )}
                        >
                          <AlertsTable
                            schema={schemaResponse.alert_schema}
                            resources={resourcesListResp.resources}
                            alertSourceConfigs={
                              configsResp.alert_source_configs
                            }
                            alerts={previewAlerts?.alerts ?? []}
                            allEntriesLoaded={true}
                            enableSelection={false}
                            wrappedInBox
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </FormV2>
              </DrawerBody>
            </div>

            <DrawerFooter className="flex gap-2 justify-end">
              <Button onClick={() => onClose()} analyticsTrackingId={null}>
                Back
              </Button>
              <GatedButton
                form="alert-route-filter"
                requiredScope={ScopeNameEnum.AlertRouteUpdate}
                type="submit"
                theme={ButtonTheme.Primary}
                analyticsTrackingId="alert-routes-edit-sources-save"
              >
                Apply
              </GatedButton>
            </DrawerFooter>
          </DrawerContents>
        </div>
      </Drawer>
    </ExpressionsMethodsProvider>
  );
};
