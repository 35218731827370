/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionCreated,
    ActionCreatedFromJSON,
    ActionCreatedFromJSONTyped,
    ActionCreatedToJSON,
} from './ActionCreated';
import {
    ActionUpdated,
    ActionUpdatedFromJSON,
    ActionUpdatedFromJSONTyped,
    ActionUpdatedToJSON,
} from './ActionUpdated';
import {
    AtlassianStatuspageUpdate,
    AtlassianStatuspageUpdateFromJSON,
    AtlassianStatuspageUpdateFromJSONTyped,
    AtlassianStatuspageUpdateToJSON,
} from './AtlassianStatuspageUpdate';
import {
    Closed,
    ClosedFromJSON,
    ClosedFromJSONTyped,
    ClosedToJSON,
} from './Closed';
import {
    CustomEvent,
    CustomEventFromJSON,
    CustomEventFromJSONTyped,
    CustomEventToJSON,
} from './CustomEvent';
import {
    CustomFieldValueUpdate,
    CustomFieldValueUpdateFromJSON,
    CustomFieldValueUpdateFromJSONTyped,
    CustomFieldValueUpdateToJSON,
} from './CustomFieldValueUpdate';
import {
    EscalationCreated,
    EscalationCreatedFromJSON,
    EscalationCreatedFromJSONTyped,
    EscalationCreatedToJSON,
} from './EscalationCreated';
import {
    FollowUpCreated,
    FollowUpCreatedFromJSON,
    FollowUpCreatedFromJSONTyped,
    FollowUpCreatedToJSON,
} from './FollowUpCreated';
import {
    FollowUpUpdated,
    FollowUpUpdatedFromJSON,
    FollowUpUpdatedFromJSONTyped,
    FollowUpUpdatedToJSON,
} from './FollowUpUpdated';
import {
    Handover,
    HandoverFromJSON,
    HandoverFromJSONTyped,
    HandoverToJSON,
} from './Handover';
import {
    IncidentAttachmentAdded,
    IncidentAttachmentAddedFromJSON,
    IncidentAttachmentAddedFromJSONTyped,
    IncidentAttachmentAddedToJSON,
} from './IncidentAttachmentAdded';
import {
    IncidentAttachmentRemoved,
    IncidentAttachmentRemovedFromJSON,
    IncidentAttachmentRemovedFromJSONTyped,
    IncidentAttachmentRemovedToJSON,
} from './IncidentAttachmentRemoved';
import {
    IncidentCallEnded,
    IncidentCallEndedFromJSON,
    IncidentCallEndedFromJSONTyped,
    IncidentCallEndedToJSON,
} from './IncidentCallEnded';
import {
    IncidentCallStarted,
    IncidentCallStartedFromJSON,
    IncidentCallStartedFromJSONTyped,
    IncidentCallStartedToJSON,
} from './IncidentCallStarted';
import {
    IncidentEscalate,
    IncidentEscalateFromJSON,
    IncidentEscalateFromJSONTyped,
    IncidentEscalateToJSON,
} from './IncidentEscalate';
import {
    IncidentMembershipRevoked,
    IncidentMembershipRevokedFromJSON,
    IncidentMembershipRevokedFromJSONTyped,
    IncidentMembershipRevokedToJSON,
} from './IncidentMembershipRevoked';
import {
    IncidentMerged,
    IncidentMergedFromJSON,
    IncidentMergedFromJSONTyped,
    IncidentMergedToJSON,
} from './IncidentMerged';
import {
    IncidentRename,
    IncidentRenameFromJSON,
    IncidentRenameFromJSONTyped,
    IncidentRenameToJSON,
} from './IncidentRename';
import {
    IncidentTimestampSet,
    IncidentTimestampSetFromJSON,
    IncidentTimestampSetFromJSONTyped,
    IncidentTimestampSetToJSON,
} from './IncidentTimestampSet';
import {
    IncidentTypeChanged,
    IncidentTypeChangedFromJSON,
    IncidentTypeChangedFromJSONTyped,
    IncidentTypeChangedToJSON,
} from './IncidentTypeChanged';
import {
    IncidentVisibilityChanged,
    IncidentVisibilityChangedFromJSON,
    IncidentVisibilityChangedFromJSONTyped,
    IncidentVisibilityChangedToJSON,
} from './IncidentVisibilityChanged';
import {
    PagerdutyIncidentAcknowledged,
    PagerdutyIncidentAcknowledgedFromJSON,
    PagerdutyIncidentAcknowledgedFromJSONTyped,
    PagerdutyIncidentAcknowledgedToJSON,
} from './PagerdutyIncidentAcknowledged';
import {
    PagerdutyIncidentResolved,
    PagerdutyIncidentResolvedFromJSON,
    PagerdutyIncidentResolvedFromJSONTyped,
    PagerdutyIncidentResolvedToJSON,
} from './PagerdutyIncidentResolved';
import {
    PagerdutyIncidentTriggered,
    PagerdutyIncidentTriggeredFromJSON,
    PagerdutyIncidentTriggeredFromJSONTyped,
    PagerdutyIncidentTriggeredToJSON,
} from './PagerdutyIncidentTriggered';
import {
    Report,
    ReportFromJSON,
    ReportFromJSONTyped,
    ReportToJSON,
} from './Report';
import {
    RoleUpdate,
    RoleUpdateFromJSON,
    RoleUpdateFromJSONTyped,
    RoleUpdateToJSON,
} from './RoleUpdate';
import {
    SeverityUpdate,
    SeverityUpdateFromJSON,
    SeverityUpdateFromJSONTyped,
    SeverityUpdateToJSON,
} from './SeverityUpdate';
import {
    SlackImage,
    SlackImageFromJSON,
    SlackImageFromJSONTyped,
    SlackImageToJSON,
} from './SlackImage';
import {
    SlackInferGithub,
    SlackInferGithubFromJSON,
    SlackInferGithubFromJSONTyped,
    SlackInferGithubToJSON,
} from './SlackInferGithub';
import {
    SlackInferSentry,
    SlackInferSentryFromJSON,
    SlackInferSentryFromJSONTyped,
    SlackInferSentryToJSON,
} from './SlackInferSentry';
import {
    SlackPin,
    SlackPinFromJSON,
    SlackPinFromJSONTyped,
    SlackPinToJSON,
} from './SlackPin';
import {
    StatusChange,
    StatusChangeFromJSON,
    StatusChangeFromJSONTyped,
    StatusChangeToJSON,
} from './StatusChange';
import {
    StatusPageIncidentLinked,
    StatusPageIncidentLinkedFromJSON,
    StatusPageIncidentLinkedFromJSONTyped,
    StatusPageIncidentLinkedToJSON,
} from './StatusPageIncidentLinked';
import {
    StatusPageIncidentUpdated,
    StatusPageIncidentUpdatedFromJSON,
    StatusPageIncidentUpdatedFromJSONTyped,
    StatusPageIncidentUpdatedToJSON,
} from './StatusPageIncidentUpdated';
import {
    SummaryUpdate,
    SummaryUpdateFromJSON,
    SummaryUpdateFromJSONTyped,
    SummaryUpdateToJSON,
} from './SummaryUpdate';
import {
    TimelineItemComment,
    TimelineItemCommentFromJSON,
    TimelineItemCommentFromJSONTyped,
    TimelineItemCommentToJSON,
} from './TimelineItemComment';
import {
    TimelineItemIncidentUpdate,
    TimelineItemIncidentUpdateFromJSON,
    TimelineItemIncidentUpdateFromJSONTyped,
    TimelineItemIncidentUpdateToJSON,
} from './TimelineItemIncidentUpdate';
import {
    TimelineNote,
    TimelineNoteFromJSON,
    TimelineNoteFromJSONTyped,
    TimelineNoteToJSON,
} from './TimelineNote';

/**
 * 
 * @export
 * @interface TimelineItem
 */
export interface TimelineItem {
    /**
     * 
     * @type {ActionCreated}
     * @memberof TimelineItem
     */
    action_created?: ActionCreated;
    /**
     * 
     * @type {ActionUpdated}
     * @memberof TimelineItem
     */
    action_updated?: ActionUpdated;
    /**
     * 
     * @type {AtlassianStatuspageUpdate}
     * @memberof TimelineItem
     */
    atlassian_statuspage_update?: AtlassianStatuspageUpdate;
    /**
     * 
     * @type {Closed}
     * @memberof TimelineItem
     */
    closed?: Closed;
    /**
     * Array of comments on the timeline item
     * @type {Array<TimelineItemComment>}
     * @memberof TimelineItem
     */
    comments: Array<TimelineItemComment>;
    /**
     * When the timeline item was created
     * @type {Date}
     * @memberof TimelineItem
     */
    created_at: Date;
    /**
     * 
     * @type {CustomEvent}
     * @memberof TimelineItem
     */
    custom_event?: CustomEvent;
    /**
     * 
     * @type {CustomFieldValueUpdate}
     * @memberof TimelineItem
     */
    custom_field_value_update?: CustomFieldValueUpdate;
    /**
     * 
     * @type {EscalationCreated}
     * @memberof TimelineItem
     */
    escalation_created?: EscalationCreated;
    /**
     * 
     * @type {FollowUpCreated}
     * @memberof TimelineItem
     */
    follow_up_created?: FollowUpCreated;
    /**
     * 
     * @type {FollowUpUpdated}
     * @memberof TimelineItem
     */
    follow_up_updated?: FollowUpUpdated;
    /**
     * 
     * @type {Handover}
     * @memberof TimelineItem
     */
    handover?: Handover;
    /**
     * When the timeline item was hidden
     * @type {Date}
     * @memberof TimelineItem
     */
    hidden_at?: Date;
    /**
     * Unique identifier for the timeline item
     * @type {string}
     * @memberof TimelineItem
     */
    id: string;
    /**
     * 
     * @type {IncidentAttachmentAdded}
     * @memberof TimelineItem
     */
    incident_attachment_added?: IncidentAttachmentAdded;
    /**
     * 
     * @type {IncidentAttachmentRemoved}
     * @memberof TimelineItem
     */
    incident_attachment_removed?: IncidentAttachmentRemoved;
    /**
     * 
     * @type {IncidentCallEnded}
     * @memberof TimelineItem
     */
    incident_call_ended?: IncidentCallEnded;
    /**
     * 
     * @type {IncidentCallStarted}
     * @memberof TimelineItem
     */
    incident_call_started?: IncidentCallStarted;
    /**
     * 
     * @type {IncidentEscalate}
     * @memberof TimelineItem
     */
    incident_escalate?: IncidentEscalate;
    /**
     * Unique identifier of the incident the action belongs to
     * @type {string}
     * @memberof TimelineItem
     */
    incident_id: string;
    /**
     * 
     * @type {IncidentMembershipRevoked}
     * @memberof TimelineItem
     */
    incident_membership_revoked?: IncidentMembershipRevoked;
    /**
     * 
     * @type {IncidentMerged}
     * @memberof TimelineItem
     */
    incident_merged?: IncidentMerged;
    /**
     * 
     * @type {IncidentRename}
     * @memberof TimelineItem
     */
    incident_rename?: IncidentRename;
    /**
     * 
     * @type {IncidentTimestampSet}
     * @memberof TimelineItem
     */
    incident_timestamp_set?: IncidentTimestampSet;
    /**
     * 
     * @type {IncidentTypeChanged}
     * @memberof TimelineItem
     */
    incident_type_changed?: IncidentTypeChanged;
    /**
     * 
     * @type {TimelineItemIncidentUpdate}
     * @memberof TimelineItem
     */
    incident_update?: TimelineItemIncidentUpdate;
    /**
     * 
     * @type {IncidentVisibilityChanged}
     * @memberof TimelineItem
     */
    incident_visibility_changed?: IncidentVisibilityChanged;
    /**
     * Whether the timeline item is from a stream
     * @type {boolean}
     * @memberof TimelineItem
     */
    is_stream?: boolean;
    /**
     * Type of timeline item
     * @type {string}
     * @memberof TimelineItem
     */
    item_type: TimelineItemItemTypeEnum;
    /**
     * When the timeline item ocurred
     * @type {Date}
     * @memberof TimelineItem
     */
    occured_at: Date;
    /**
     * Unique identifier of the organisation ID
     * @type {string}
     * @memberof TimelineItem
     */
    organisation_id: string;
    /**
     * 
     * @type {PagerdutyIncidentAcknowledged}
     * @memberof TimelineItem
     */
    pagerduty_incident_acknowledged?: PagerdutyIncidentAcknowledged;
    /**
     * 
     * @type {PagerdutyIncidentResolved}
     * @memberof TimelineItem
     */
    pagerduty_incident_resolved?: PagerdutyIncidentResolved;
    /**
     * 
     * @type {PagerdutyIncidentTriggered}
     * @memberof TimelineItem
     */
    pagerduty_incident_triggered?: PagerdutyIncidentTriggered;
    /**
     * 
     * @type {Report}
     * @memberof TimelineItem
     */
    report?: Report;
    /**
     * 
     * @type {RoleUpdate}
     * @memberof TimelineItem
     */
    role_update?: RoleUpdate;
    /**
     * 
     * @type {SeverityUpdate}
     * @memberof TimelineItem
     */
    severity_update?: SeverityUpdate;
    /**
     * 
     * @type {SlackImage}
     * @memberof TimelineItem
     */
    slack_image?: SlackImage;
    /**
     * 
     * @type {SlackInferGithub}
     * @memberof TimelineItem
     */
    slack_infer_github?: SlackInferGithub;
    /**
     * 
     * @type {SlackInferSentry}
     * @memberof TimelineItem
     */
    slack_infer_sentry?: SlackInferSentry;
    /**
     * 
     * @type {SlackPin}
     * @memberof TimelineItem
     */
    slack_pin?: SlackPin;
    /**
     * 
     * @type {StatusChange}
     * @memberof TimelineItem
     */
    status_change?: StatusChange;
    /**
     * 
     * @type {StatusPageIncidentLinked}
     * @memberof TimelineItem
     */
    status_page_incident_linked?: StatusPageIncidentLinked;
    /**
     * 
     * @type {StatusPageIncidentUpdated}
     * @memberof TimelineItem
     */
    status_page_incident_updated?: StatusPageIncidentUpdated;
    /**
     * 
     * @type {SummaryUpdate}
     * @memberof TimelineItem
     */
    summary_update?: SummaryUpdate;
    /**
     * 
     * @type {TimelineNote}
     * @memberof TimelineItem
     */
    timeline_note?: TimelineNote;
    /**
     * When the timeline item was last updated
     * @type {Date}
     * @memberof TimelineItem
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum TimelineItemItemTypeEnum {
    ActionCreated = 'action_created',
    ActionUpdated = 'action_updated',
    AtlassianStatuspageUpdate = 'atlassian_statuspage_update',
    Closed = 'closed',
    CustomEvent = 'custom_event',
    CustomFieldValueUpdate = 'custom_field_value_update',
    FollowUpCreated = 'follow_up_created',
    FollowUpUpdated = 'follow_up_updated',
    Handover = 'handover',
    IncidentAttachmentAdded = 'incident_attachment_added',
    IncidentAttachmentRemoved = 'incident_attachment_removed',
    IncidentEscalate = 'incident_escalate',
    IncidentMembershipRevoked = 'incident_membership_revoked',
    IncidentMerged = 'incident_merged',
    IncidentRename = 'incident_rename',
    IncidentTimestampSet = 'incident_timestamp_set',
    IncidentTypeChanged = 'incident_type_changed',
    IncidentUpdate = 'incident_update',
    IncidentVisibilityChanged = 'incident_visibility_changed',
    EscalationCreated = 'escalation_created',
    PagerdutyIncidentAcknowledged = 'pagerduty_incident_acknowledged',
    PagerdutyIncidentResolved = 'pagerduty_incident_resolved',
    PagerdutyIncidentTriggered = 'pagerduty_incident_triggered',
    Report = 'report',
    RoleUpdate = 'role_update',
    Scrub = 'scrub',
    SeverityUpdate = 'severity_update',
    SlackImage = 'slack_image',
    SlackInferGithub = 'slack_infer_github',
    SlackInferSentry = 'slack_infer_sentry',
    SlackPin = 'slack_pin',
    StatusChange = 'status_change',
    StatusPageIncidentLinked = 'status_page_incident_linked',
    StatusPageIncidentUpdated = 'status_page_incident_updated',
    SummaryUpdate = 'summary_update',
    TimelineNote = 'timeline_note',
    IncidentCallStarted = 'incident_call_started',
    IncidentCallEnded = 'incident_call_ended'
}

export function TimelineItemFromJSON(json: any): TimelineItem {
    return TimelineItemFromJSONTyped(json, false);
}

export function TimelineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action_created': !exists(json, 'action_created') ? undefined : ActionCreatedFromJSON(json['action_created']),
        'action_updated': !exists(json, 'action_updated') ? undefined : ActionUpdatedFromJSON(json['action_updated']),
        'atlassian_statuspage_update': !exists(json, 'atlassian_statuspage_update') ? undefined : AtlassianStatuspageUpdateFromJSON(json['atlassian_statuspage_update']),
        'closed': !exists(json, 'closed') ? undefined : ClosedFromJSON(json['closed']),
        'comments': ((json['comments'] as Array<any>).map(TimelineItemCommentFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'custom_event': !exists(json, 'custom_event') ? undefined : CustomEventFromJSON(json['custom_event']),
        'custom_field_value_update': !exists(json, 'custom_field_value_update') ? undefined : CustomFieldValueUpdateFromJSON(json['custom_field_value_update']),
        'escalation_created': !exists(json, 'escalation_created') ? undefined : EscalationCreatedFromJSON(json['escalation_created']),
        'follow_up_created': !exists(json, 'follow_up_created') ? undefined : FollowUpCreatedFromJSON(json['follow_up_created']),
        'follow_up_updated': !exists(json, 'follow_up_updated') ? undefined : FollowUpUpdatedFromJSON(json['follow_up_updated']),
        'handover': !exists(json, 'handover') ? undefined : HandoverFromJSON(json['handover']),
        'hidden_at': !exists(json, 'hidden_at') ? undefined : (new Date(json['hidden_at'])),
        'id': json['id'],
        'incident_attachment_added': !exists(json, 'incident_attachment_added') ? undefined : IncidentAttachmentAddedFromJSON(json['incident_attachment_added']),
        'incident_attachment_removed': !exists(json, 'incident_attachment_removed') ? undefined : IncidentAttachmentRemovedFromJSON(json['incident_attachment_removed']),
        'incident_call_ended': !exists(json, 'incident_call_ended') ? undefined : IncidentCallEndedFromJSON(json['incident_call_ended']),
        'incident_call_started': !exists(json, 'incident_call_started') ? undefined : IncidentCallStartedFromJSON(json['incident_call_started']),
        'incident_escalate': !exists(json, 'incident_escalate') ? undefined : IncidentEscalateFromJSON(json['incident_escalate']),
        'incident_id': json['incident_id'],
        'incident_membership_revoked': !exists(json, 'incident_membership_revoked') ? undefined : IncidentMembershipRevokedFromJSON(json['incident_membership_revoked']),
        'incident_merged': !exists(json, 'incident_merged') ? undefined : IncidentMergedFromJSON(json['incident_merged']),
        'incident_rename': !exists(json, 'incident_rename') ? undefined : IncidentRenameFromJSON(json['incident_rename']),
        'incident_timestamp_set': !exists(json, 'incident_timestamp_set') ? undefined : IncidentTimestampSetFromJSON(json['incident_timestamp_set']),
        'incident_type_changed': !exists(json, 'incident_type_changed') ? undefined : IncidentTypeChangedFromJSON(json['incident_type_changed']),
        'incident_update': !exists(json, 'incident_update') ? undefined : TimelineItemIncidentUpdateFromJSON(json['incident_update']),
        'incident_visibility_changed': !exists(json, 'incident_visibility_changed') ? undefined : IncidentVisibilityChangedFromJSON(json['incident_visibility_changed']),
        'is_stream': !exists(json, 'is_stream') ? undefined : json['is_stream'],
        'item_type': json['item_type'],
        'occured_at': (new Date(json['occured_at'])),
        'organisation_id': json['organisation_id'],
        'pagerduty_incident_acknowledged': !exists(json, 'pagerduty_incident_acknowledged') ? undefined : PagerdutyIncidentAcknowledgedFromJSON(json['pagerduty_incident_acknowledged']),
        'pagerduty_incident_resolved': !exists(json, 'pagerduty_incident_resolved') ? undefined : PagerdutyIncidentResolvedFromJSON(json['pagerduty_incident_resolved']),
        'pagerduty_incident_triggered': !exists(json, 'pagerduty_incident_triggered') ? undefined : PagerdutyIncidentTriggeredFromJSON(json['pagerduty_incident_triggered']),
        'report': !exists(json, 'report') ? undefined : ReportFromJSON(json['report']),
        'role_update': !exists(json, 'role_update') ? undefined : RoleUpdateFromJSON(json['role_update']),
        'severity_update': !exists(json, 'severity_update') ? undefined : SeverityUpdateFromJSON(json['severity_update']),
        'slack_image': !exists(json, 'slack_image') ? undefined : SlackImageFromJSON(json['slack_image']),
        'slack_infer_github': !exists(json, 'slack_infer_github') ? undefined : SlackInferGithubFromJSON(json['slack_infer_github']),
        'slack_infer_sentry': !exists(json, 'slack_infer_sentry') ? undefined : SlackInferSentryFromJSON(json['slack_infer_sentry']),
        'slack_pin': !exists(json, 'slack_pin') ? undefined : SlackPinFromJSON(json['slack_pin']),
        'status_change': !exists(json, 'status_change') ? undefined : StatusChangeFromJSON(json['status_change']),
        'status_page_incident_linked': !exists(json, 'status_page_incident_linked') ? undefined : StatusPageIncidentLinkedFromJSON(json['status_page_incident_linked']),
        'status_page_incident_updated': !exists(json, 'status_page_incident_updated') ? undefined : StatusPageIncidentUpdatedFromJSON(json['status_page_incident_updated']),
        'summary_update': !exists(json, 'summary_update') ? undefined : SummaryUpdateFromJSON(json['summary_update']),
        'timeline_note': !exists(json, 'timeline_note') ? undefined : TimelineNoteFromJSON(json['timeline_note']),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function TimelineItemToJSON(value?: TimelineItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action_created': ActionCreatedToJSON(value.action_created),
        'action_updated': ActionUpdatedToJSON(value.action_updated),
        'atlassian_statuspage_update': AtlassianStatuspageUpdateToJSON(value.atlassian_statuspage_update),
        'closed': ClosedToJSON(value.closed),
        'comments': ((value.comments as Array<any>).map(TimelineItemCommentToJSON)),
        'created_at': (value.created_at.toISOString()),
        'custom_event': CustomEventToJSON(value.custom_event),
        'custom_field_value_update': CustomFieldValueUpdateToJSON(value.custom_field_value_update),
        'escalation_created': EscalationCreatedToJSON(value.escalation_created),
        'follow_up_created': FollowUpCreatedToJSON(value.follow_up_created),
        'follow_up_updated': FollowUpUpdatedToJSON(value.follow_up_updated),
        'handover': HandoverToJSON(value.handover),
        'hidden_at': value.hidden_at === undefined ? undefined : (value.hidden_at.toISOString()),
        'id': value.id,
        'incident_attachment_added': IncidentAttachmentAddedToJSON(value.incident_attachment_added),
        'incident_attachment_removed': IncidentAttachmentRemovedToJSON(value.incident_attachment_removed),
        'incident_call_ended': IncidentCallEndedToJSON(value.incident_call_ended),
        'incident_call_started': IncidentCallStartedToJSON(value.incident_call_started),
        'incident_escalate': IncidentEscalateToJSON(value.incident_escalate),
        'incident_id': value.incident_id,
        'incident_membership_revoked': IncidentMembershipRevokedToJSON(value.incident_membership_revoked),
        'incident_merged': IncidentMergedToJSON(value.incident_merged),
        'incident_rename': IncidentRenameToJSON(value.incident_rename),
        'incident_timestamp_set': IncidentTimestampSetToJSON(value.incident_timestamp_set),
        'incident_type_changed': IncidentTypeChangedToJSON(value.incident_type_changed),
        'incident_update': TimelineItemIncidentUpdateToJSON(value.incident_update),
        'incident_visibility_changed': IncidentVisibilityChangedToJSON(value.incident_visibility_changed),
        'is_stream': value.is_stream,
        'item_type': value.item_type,
        'occured_at': (value.occured_at.toISOString()),
        'organisation_id': value.organisation_id,
        'pagerduty_incident_acknowledged': PagerdutyIncidentAcknowledgedToJSON(value.pagerduty_incident_acknowledged),
        'pagerduty_incident_resolved': PagerdutyIncidentResolvedToJSON(value.pagerduty_incident_resolved),
        'pagerduty_incident_triggered': PagerdutyIncidentTriggeredToJSON(value.pagerduty_incident_triggered),
        'report': ReportToJSON(value.report),
        'role_update': RoleUpdateToJSON(value.role_update),
        'severity_update': SeverityUpdateToJSON(value.severity_update),
        'slack_image': SlackImageToJSON(value.slack_image),
        'slack_infer_github': SlackInferGithubToJSON(value.slack_infer_github),
        'slack_infer_sentry': SlackInferSentryToJSON(value.slack_infer_sentry),
        'slack_pin': SlackPinToJSON(value.slack_pin),
        'status_change': StatusChangeToJSON(value.status_change),
        'status_page_incident_linked': StatusPageIncidentLinkedToJSON(value.status_page_incident_linked),
        'status_page_incident_updated': StatusPageIncidentUpdatedToJSON(value.status_page_incident_updated),
        'summary_update': SummaryUpdateToJSON(value.summary_update),
        'timeline_note': TimelineNoteToJSON(value.timeline_note),
        'updated_at': (value.updated_at.toISOString()),
    };
}

