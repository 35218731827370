import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Button, ButtonTheme, ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React from "react";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { IntegrationsShortcutInstallRequestBody as FormData } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export const ConnectToShortcutModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement | null => {
  const formMethods = useForm<FormData>();

  const {
    trigger: setShortcutApiToken,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, data: FormData) => {
      await apiClient.integrationsShortcutInstall({
        installRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  const { showArticle } = useIntercom();

  return (
    <FormModalV2
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={setShortcutApiToken}
      analyticsTrackingId="connect-shortcut-integration"
      title="Connect to Shortcut"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText="Connect"
          confirmButtonType="submit"
        />
      }
    >
      <p className="text-sm text-slate-700">
        Add your Shortcut API token here to allow us to export your actions to
        Shortcut. If you need help finding your API token, you can follow our{" "}
        <Button
          theme={ButtonTheme.Link}
          onClick={() => showArticle(5948009)}
          analyticsTrackingId="connect-shortcut-integration-help"
        >
          instructions
        </Button>
        .
      </p>
      <hr className="my-3" />
      <InputV2
        formMethods={formMethods}
        name="token"
        label="API Token"
        type={InputType.Password}
        required="API token is required"
        autoComplete="none"
        placeholder={"xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}
      />
    </FormModalV2>
  );
};
