import { useInitialiseInsightsFilters } from "@incident-shared/filters";
import { useQueryParams } from "src/utils/query-params";

import { getCustomFieldOptions } from "./getInsightsSelectOptions";

export const useCustomFieldOptions = () => {
  const urlParams = useQueryParams();
  const { availableIncidentFilterFields, loading } =
    useInitialiseInsightsFilters(urlParams.toString());

  return {
    customFieldOptions: getCustomFieldOptions(
      availableIncidentFilterFields,
    ).map((option) => ({
      sort_key: option.label,
      value: option.id,
      label: option.label,
    })),
    loading,
  };
};
