import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ModalFooter } from "@incident-ui";
import _ from "lodash";
import { sortBy } from "lodash";
import { useForm } from "react-hook-form";
import {
  FollowUp,
  FollowUpPriority,
  FollowUpsUpdateRequestBody,
  FollowUpsUpdateRequestBodyStatusEnum,
  Settings,
} from "src/contexts/ClientContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";

export const BulkFollowUpUpdatePriorityModal = ({
  selectedFollowUpIDs,
  followUps,
  onClose,
  onCancel,
}: {
  selectedFollowUpIDs: string[];
  followUps: FollowUp[];
  onClose: () => void;
  onCancel: () => void;
}): React.ReactElement => {
  const { settings } = useSettings();

  const { data: prioOptsData, error: followUpPriorityOptionsError } = useAPI(
    "followUpsListPriorityOptions",
    undefined,
  );
  const followUpPriorityOptions = prioOptsData
    ? sortBy(prioOptsData.priority_options, (field) => field.rank)
    : undefined;

  if (followUpPriorityOptionsError) {
    throw followUpPriorityOptionsError;
  }

  if (!followUpPriorityOptions || !settings) {
    return <></>;
  }

  return (
    <BulkFollowUpUpdatePriorityModalInner
      settings={settings}
      followUpPriorityOptions={followUpPriorityOptions}
      selectedFollowUpIDs={selectedFollowUpIDs}
      followUps={followUps}
      onClose={onClose}
      onCancel={onCancel}
    />
  );
};

const BulkFollowUpUpdatePriorityModalInner = ({
  settings,
  followUpPriorityOptions,
  selectedFollowUpIDs: followUpIDs,
  followUps,
  onClose,
  onCancel,
}: {
  settings: Settings;
  followUpPriorityOptions: FollowUpPriority[];
  selectedFollowUpIDs: string[];
  followUps: FollowUp[];
  onClose: () => void;
  onCancel: () => void;
}): React.ReactElement => {
  const formMethods = useForm<FollowUpsUpdateRequestBody>({
    mode: "onSubmit",
  });
  const { watch, setError } = formMethods;
  const selectedFollowUps = followUps.filter((followUp) =>
    followUpIDs.includes(followUp.id),
  );

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    {},
    async (apiClient, data: FollowUpsUpdateRequestBody) => {
      await Promise.all(
        selectedFollowUps.map((followUp) => {
          return apiClient.followUpsUpdate({
            id: followUp.id,
            updateRequestBody: {
              ...followUp,
              assignee_id: followUp.assignee?.id,
              status:
                followUp.status as unknown as FollowUpsUpdateRequestBodyStatusEnum,
              follow_up_priority_option_id: data.follow_up_priority_option_id,
            },
          });
        }),
      );
    },
    {
      onSuccess: () => {
        onClose();
      },
      setError,
    },
  );

  const selectedFollowUpPriorityOptionID = watch(
    "follow_up_priority_option_id",
  );
  const selectedFollowUpPriorityOption = _.find(
    followUpPriorityOptions,
    (item) => {
      return item.id === selectedFollowUpPriorityOptionID;
    },
  );

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      title={
        "Set priority for follow-up" + (selectedFollowUps.length > 1 ? "s" : "")
      }
      analyticsTrackingId="follow-ups-set-priority"
      onClose={onCancel}
      genericError={genericError}
      footer={
        <ModalFooter
          hideConfirmButton={selectedFollowUps.length === 0}
          confirmButtonText="Set priority"
          saving={saving}
          onClose={onCancel}
          confirmButtonType="submit"
        />
      }
      suppressInitialAnimation
    >
      <StaticSingleSelectV2
        formMethods={formMethods}
        label={"Priority"}
        placeholder={"No priority"}
        options={followUpPriorityOptions.map(({ id, name, is_default }) => ({
          value: id,
          label: is_default ? name + " (Default)" : name,
        }))}
        name="follow_up_priority_option_id"
        required={settings.misc.follow_up_priority_required}
        isClearable={!settings.misc.follow_up_priority_required}
      />
      <p className="mt-1 text-xs text-slate-700">
        {selectedFollowUpPriorityOption?.description}
      </p>
    </FormModalV2>
  );
};
