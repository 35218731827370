import { useInitialiseInsightsFilters } from "@incident-shared/filters";
import { IconEnum, Loader, StaticSingleSelect } from "@incident-ui";
import { PopoverMultiSelect } from "@incident-ui";
import { SelectOption, SelectValue } from "@incident-ui/Select/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { ExploDashboardID } from "src/components/insights/dashboards/dashboards";
import { SavedViewsListContextEnum } from "src/contexts/ClientContext";
import { useQueryParams } from "src/utils/query-params";

import { InsightsDashboardSection } from "../../common/InsightsDashboardSection";
import { InsightsPage } from "../../common/InsightsPage";
import { InsightsContextProvider } from "../../context/InsightsContextProvider";
import { StateConfig } from "../../context/types";
import { useInsightsContext } from "../../context/useInsightsContext";

export interface PagerLoadDashboardStateConfig extends StateConfig {
  escalation_targets: {
    isArray: true;
    options: SelectOption[];
  };
}

export const ExternalPagerLoadDashboard = (): React.ReactElement => {
  const urlParams = useQueryParams();
  const flags = useFlags();
  const { loading, escalationTargets } = useInitialiseInsightsFilters(
    urlParams.toString(),
  );

  if (loading) {
    return <Loader />;
  }
  return (
    <InsightsContextProvider<PagerLoadDashboardStateConfig>
      context={SavedViewsListContextEnum.InsightsV2}
      stateConfig={{
        escalation_targets: { isArray: true, options: escalationTargets },
      }}
    >
      <InsightsPage
        dashboard={
          flags.featureNativeOnCallInsights
            ? "external-pager-load"
            : "pager-load"
        }
        disableFilters={true}
      >
        <PagesDashboard />
        <ByUserDashboard />
      </InsightsPage>
    </InsightsContextProvider>
  );
};

const PagesDashboard = () => {
  const { useState } = useInsightsContext<PagerLoadDashboardStateConfig>();

  const [selectedEscalationTargetIDs, setSelectedEscalationTargetIDs] =
    useState("escalation_targets");

  return (
    <InsightsDashboardSection
      title="Pages"
      description="Understand how often people are being paged, and at what times of day. We use the timezone of the user, as taken from their Slack profile."
      dashboardEmbedID={ExploDashboardID.OnCallPagerImpactExternal}
      dashboardVariables={{
        selected_escalation_targets: JSON.stringify(
          selectedEscalationTargetIDs,
        ),
      }}
      controls={
        <SelectTargets
          targetIDs={selectedEscalationTargetIDs}
          setTargetIDs={setSelectedEscalationTargetIDs}
        />
      }
      initialHeight="874px"
    />
  );
};

const ByUserDashboard = () => {
  const [userID, setUserID] = useState<string | undefined>(); // not saved into saved views as we do not want to actively promote targeting an individual

  return (
    <InsightsDashboardSection
      // Need to provide this key so that we can re-mount the dashboard with the
      // userID and actually filter by that user
      key={userID}
      title="By user"
      description="For a specific user, see the individual pages they received to understand how disruptive the pager has been for them during this period."
      dashboardEmbedID={ExploDashboardID.OnCallByUser}
      dashboardVariables={{
        selected_user_id: userID,
      }}
      controls={<SelectUser userID={userID} setUserID={setUserID} />}
      initialHeight="874px"
    />
  );
};

export const SelectTargets = ({
  targetIDs,
  setTargetIDs,
}: {
  targetIDs: string[];
  setTargetIDs: (newIDs: string[]) => void;
}): React.ReactElement => {
  const { getOptions } = useInsightsContext();
  const escalationTargets = getOptions("escalation_targets");

  return (
    <div className="flex items-center">
      <PopoverMultiSelect
        object={false}
        placeholder="Filter by escalation policy, team or service"
        options={escalationTargets.map((field) => {
          return {
            sort_key: field.sort_key,
            value: field.value,
            label: field.label,
            icon: targetToIcon(field),
          };
        })}
        triggerClassName="!text-slate-700 min-w-96"
        value={targetIDs}
        onChange={(value: SelectValue) => {
          setTargetIDs(value as string[]);
        }}
        isClearable
      />
    </div>
  );
};

export const SelectUser = ({
  userID,
  setUserID,
}: {
  userID: string | undefined;
  setUserID: (newID: string) => void;
}): React.ReactElement => {
  const urlParams = useQueryParams();

  const { escalationExternalUsers } = useInitialiseInsightsFilters(
    urlParams.toString(),
  );

  if (!userID && escalationExternalUsers.length > 0) {
    setUserID(escalationExternalUsers[0].value);
  }

  return (
    <div className="flex items-center">
      <StaticSingleSelect
        placeholder="Select a user"
        options={escalationExternalUsers}
        className="!text-slate-700 min-w-[200px]"
        value={userID || ""}
        onChange={(value) => {
          if (value && typeof value === "string") {
            setUserID(value);
          }
        }}
        isClearable={false}
      />
    </div>
  );
};

const targetToIcon = (target: SelectOption): IconEnum => {
  if (
    target.value.startsWith("EscalationPolicy") ||
    target.value.startsWith("EscalationPath")
  ) {
    return IconEnum.Users;
  } else if (target.value.startsWith("Service")) {
    return IconEnum.Server;
  } else {
    return IconEnum.Circle;
  }
};
