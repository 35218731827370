/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsightsGenerateExploJWTRequestBody
 */
export interface InsightsGenerateExploJWTRequestBody {
    /**
     * Internal ID of the custom dashboard
     * @type {string}
     * @memberof InsightsGenerateExploJWTRequestBody
     */
    custom_dashboard_id?: string;
    /**
     * Embed ID of the dashboard the token should grant access to
     * @type {string}
     * @memberof InsightsGenerateExploJWTRequestBody
     */
    dashboard_embed_id: string;
    /**
     * Parameters to provide for dashboard filters
     * @type {object}
     * @memberof InsightsGenerateExploJWTRequestBody
     */
    params?: object;
}

export function InsightsGenerateExploJWTRequestBodyFromJSON(json: any): InsightsGenerateExploJWTRequestBody {
    return InsightsGenerateExploJWTRequestBodyFromJSONTyped(json, false);
}

export function InsightsGenerateExploJWTRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsGenerateExploJWTRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_dashboard_id': !exists(json, 'custom_dashboard_id') ? undefined : json['custom_dashboard_id'],
        'dashboard_embed_id': json['dashboard_embed_id'],
        'params': !exists(json, 'params') ? undefined : json['params'],
    };
}

export function InsightsGenerateExploJWTRequestBodyToJSON(value?: InsightsGenerateExploJWTRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_dashboard_id': value.custom_dashboard_id,
        'dashboard_embed_id': value.dashboard_embed_id,
        'params': value.params,
    };
}

