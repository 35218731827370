import {
  InternalStatusPageContentSummary,
  InternalStatusPageIncident,
  UserPreferences,
} from "@incident-io/api";
import { Button, ButtonTheme } from "@incident-io/status-page-ui";
import { ReactElement, useState } from "react";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { usePrevious } from "use-hooks";

import { useIncidentHasSubscription } from "../../incident/ToggleSubscriptionButton";
import { SubscribeModal } from "./SubscribeModal";
import { UnsubscribeModal } from "./UnsubscribeModal";

export const SubscribeToUpdatesButton = ({
  externalIncidentId,
  summary,
}: {
  externalIncidentId?: number;
  summary: InternalStatusPageContentSummary;
}): ReactElement => {
  const { data: userPrefsData } = useAPI("userPreferencesShow", undefined);

  if (!userPrefsData) {
    return <></>;
  }

  return externalIncidentId ? (
    <SubscribeToIncidentUpdatesButton
      summary={summary}
      externalIncidentId={externalIncidentId}
      userPreferences={userPrefsData.user_preferences}
    />
  ) : (
    <SubscribeToStatusPageButton
      summary={summary}
      userPreferences={userPrefsData.user_preferences}
    />
  );
};

const SubscribeToIncidentUpdatesButton = ({
  summary,
  externalIncidentId,
  userPreferences,
}: {
  summary: InternalStatusPageContentSummary;
  externalIncidentId: number;
  userPreferences: UserPreferences;
}): ReactElement => {
  const { data: incidentData } = useAPI(
    "internalStatusPageContentShowIncident",
    {
      subpath: summary.subpath,
      externalId: externalIncidentId,
    },
  );

  return incidentData?.incident ? (
    <SubscribeToIncidentUpdatesButtonInner
      incident={incidentData.incident}
      userPreferences={userPreferences}
    />
  ) : (
    <></>
  );
};

const SubscribeToIncidentUpdatesButtonInner = ({
  incident,
  userPreferences,
}: {
  incident: InternalStatusPageIncident;
  userPreferences: UserPreferences;
}): ReactElement => {
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  const { isSubscribed, loading, subscribe, unsubscribe } =
    useIncidentHasSubscription({
      incidentId: incident.response_incident_id,
      internalStatusPageId: incident.internal_status_page_id,
    });

  const userPreferencesRequireSetup = !(
    userPreferences.receives_subscriptions_via_email ||
    userPreferences.receives_subscriptions_via_slack_DM ||
    userPreferences.receives_subscriptions_via_sms
  );

  const handleSubscribe = async () => {
    // if the user already has preferences, we should immediately subscribe
    if (!userPreferencesRequireSetup) {
      await subscribe();
      return;
    }
    // otherwise, we'll show them a modal to set their notification prefs
    setShowSubscribeModal(true);
  };

  // stolen from apps/dashboard/src/components/incident/ToggleSubscriptionButton.tsx
  const [isSubscribeHovered, setIsSubscribeHovered] = useState(false);
  const previousHovered = usePrevious(isSubscribeHovered);

  // In order to not immediately show the destructive 'unsubscribe' state as soon as the user subscribes,
  // we check if they've un-hovered since subscribing.
  const hasMouseLeft = previousHovered !== isSubscribeHovered;

  // The check for || loading prevents jumpiness if the user quickly leaves the
  // button before the request completes, which would otherwise look like
  // Unsubscribe -> Subscribed -> Subscribe to incident.
  const showUnsubscribeHoverAppearance =
    isSubscribed && ((hasMouseLeft && isSubscribeHovered) || loading);

  return (
    <>
      <Button
        className="ml-auto"
        title={isSubscribed ? "Unsubscribe" : "Subscribe to incident"}
        analyticsTrackingId={
          isSubscribed
            ? "internal-sp-unsubscribe-from-incident-updates"
            : "internal-sp-subscribe-to-incident-updates"
        }
        onClick={() => (isSubscribed ? unsubscribe() : handleSubscribe())}
        onMouseOver={() => setIsSubscribeHovered(true)}
        onMouseLeave={() => setIsSubscribeHovered(false)}
      >
        {isSubscribed
          ? showUnsubscribeHoverAppearance
            ? "Unsubscribe"
            : "Subscribed"
          : "Subscribe to incident"}
      </Button>
      {showSubscribeModal && (
        <SubscribeModal
          userPreferences={userPreferences}
          incidentId={incident.response_incident_id}
          onClose={() => setShowSubscribeModal(false)}
        />
      )}
    </>
  );
};

const SubscribeToStatusPageButton = ({
  summary,
  userPreferences,
}: {
  summary: InternalStatusPageContentSummary;
  userPreferences: UserPreferences;
}): ReactElement => {
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);

  const { data } = useAPI(
    "incidentSubscriptionsListAutoSubscribeRules",
    undefined,
  );
  const subscribeRule = data?.incident_auto_subscribe_rules?.find(
    (rule) =>
      rule.internal_status_page && rule.internal_status_page.id === summary.id,
  );

  const { trigger: unsubscribe } = useAPIMutation(
    "incidentSubscriptionsListAutoSubscribeRules",
    undefined,
    async (apiClient) => {
      if (!subscribeRule) {
        return;
      }

      await apiClient.incidentSubscriptionsDestroyAutoSubscribeRule({
        id: subscribeRule.id,
      });
    },
  );

  // stolen from apps/dashboard/src/components/incident/ToggleSubscriptionButton.tsx
  const [isSubscribeHovered, setIsSubscribeHovered] = useState(false);

  if (subscribeRule) {
    return (
      <>
        <Button
          className="ml-auto"
          title="Unsubscribe from updates"
          analyticsTrackingId={"internal-sp-unsubscribe-from-incident-updates"}
          onClick={() => setShowUnsubscribeModal(true)}
          onMouseOver={() => setIsSubscribeHovered(true)}
          onMouseLeave={() => setIsSubscribeHovered(false)}
        >
          {isSubscribeHovered ? "Unsubscribe" : "Subscribed"}
        </Button>
        {showUnsubscribeModal && (
          <UnsubscribeModal
            onClose={() => setShowUnsubscribeModal(false)}
            unsubscribe={() => unsubscribe({})}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Button
        className="ml-auto"
        onClick={() => setShowSubscribeModal(true)}
        theme={ButtonTheme.Default}
        title="Subscribe to updates"
        analyticsTrackingId={"internal-sp-subscribe-to-incident-updates"}
      >
        Subscribe
      </Button>
      {showSubscribeModal && (
        <SubscribeModal
          userPreferences={userPreferences}
          internalStatusPageId={summary.id}
          onClose={() => setShowSubscribeModal(false)}
        />
      )}
    </>
  );
};
