import {
  CustomFieldRequiredV2Enum,
  PostIncidentTaskConfigSlim,
  PostIncidentTaskConfigSlimTaskTypeEnum as ConfigTaskTypeEnum,
  PostIncidentTaskOptionTaskTypeEnum,
  PostIncidentTaskSlim,
  ScopeNameEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  CreatePostMortemModal,
  SharePostMortemModal,
} from "@incident-shared/postmortems";
import { Button, ButtonTheme, LoadingModal } from "@incident-ui";
import { IconProps } from "@incident-ui/Icon/Icon";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";
import {
  Incident,
  PostmortemDocumentStatusEnum,
  PostmortemsSetDocumentStatusRequestBodyStatusEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { useDebriefName, usePostmortemName } from "src/utils/utils";

import { BodyTabs } from "../body/IncidentBody";
import { useSummaryContext } from "../body/Summary";
import { EditRoleAssignmentsModal } from "../header/EditRoleAssignmentsModal";
import { EditTimestampsModal } from "../header/EditTimestampsModal";
import { GiveShoutoutModal } from "../header/GiveShoutoutModal";
import { useIncident } from "../hooks";
import { EditSpecificCustomFieldEntriesModal } from "../sidebar/EditCustomFieldEntriesModal";
import { ScheduleDebriefModal } from "../sidebar/ScheduleDebriefModal";
import { TaskCanBeManuallyCompleted } from "./taskCanBeManuallyCompleted";
import { usePostmortem } from "./usePostmortem";

const CreatePostMortemButton = ({
  incident,
  incidentTask,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incident: Incident;
  incidentTask: PostIncidentTaskSlim;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const { postmortemNameFormatted } = usePostmortemName(incident);

  return (
    <>
      <Button
        onClick={(e) => {
          onClick(e);
          setShowCreateModal(true);
        }}
        analyticsTrackingId="post-incident-task-create-postmortem"
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        theme={theme}
        className={buttonClassName}
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.CreatePostmortem,
        )}
        iconProps={iconProps}
      >
        Create {postmortemNameFormatted}
      </Button>
      {showCreateModal && incident && (
        <CreatePostMortemModal
          incident={incident}
          onClose={() => {
            setShowCreateModal(false);
          }}
        />
      )}
    </>
  );
};

const SharePostMortemButton = ({
  incident,
  incidentTask,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incident: Incident;
  incidentTask: PostIncidentTaskSlim;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const { postmortemName, postmortemNameFormatted } =
    usePostmortemName(incident);

  const { availableDocument: document } = usePostmortem(incident);
  const disabled = !document;

  return (
    <>
      <GatedButton
        onClick={(e) => {
          onClick(e);
          setShowShareModal(true);
        }}
        analyticsTrackingId="post-incident-task-share-postmortem"
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        disabled={disabled}
        disabledTooltipContent={`${postmortemName} has not been created yet`}
        theme={theme}
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.SharePostmortem,
        )}
        className={buttonClassName}
        iconProps={iconProps}
      >
        Share {postmortemNameFormatted}
      </GatedButton>
      {showShareModal && incident && (
        <SharePostMortemModal
          incident={incident}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      )}
    </>
  );
};

const ScheduleDebriefButton = ({
  incident,
  incidentTask,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incident: Incident;
  incidentTask: PostIncidentTaskSlim;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { debriefNameLower } = useDebriefName();

  return (
    <>
      <Button
        onClick={(e) => {
          onClick(e);
          setShowModal(true);
        }}
        analyticsTrackingId="post-incident-task-schedule-debrief"
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        theme={theme}
        className={buttonClassName}
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.ScheduleDebrief,
        )}
        iconProps={iconProps}
      >
        Schedule {debriefNameLower}
      </Button>
      {showModal && incident && (
        <ScheduleDebriefModal
          incident={incident}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

const AssignRoleButton = ({
  incident,
  incidentTask,
  onTaskComplete,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incident: Incident;
  onTaskComplete: () => void;
  incidentTask: PostIncidentTaskSlim;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  const [showAssignRoleModal, setShowAssignRoleModal] =
    useState<boolean>(false);

  const roleId = incidentTask.config.incident_role_id;

  if (!roleId) {
    throw new Error("found assign role task with no roleId set");
  }

  return (
    <>
      <Button
        onClick={(e) => {
          onClick(e);
          setShowAssignRoleModal(true);
        }}
        analyticsTrackingId="post-incident-task-assign-role"
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        theme={theme}
        className={buttonClassName}
        icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.AssignRole)}
        iconProps={iconProps}
      >
        Assign role
      </Button>
      {showAssignRoleModal && incident && (
        <EditRoleAssignmentsModal
          incident={incident}
          onClose={() => {
            setShowAssignRoleModal(false);
            onTaskComplete();
          }}
          shownRoleIds={[roleId]}
        />
      )}
    </>
  );
};

const SetCustomFieldsButton = ({
  incident,
  incidentTask,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incident: Incident;
  incidentTask: PostIncidentTaskSlim;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  const { applicableFields } = useIncident(incident.id);

  const {
    data: { custom_fields },
    isLoading,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });
  const customFields = sortBy(custom_fields, (field) => field.rank);

  const [showCustomFieldsModal, setShowCustomFieldsModal] =
    useState<boolean>(false);

  if (!applicableFields) {
    return null;
  }

  const applicableIDs = incidentTask.config.custom_field_ids ?? [];

  // We only want to show the fields that they've chosen for this post-incident step.
  // We also want to fake that they're required, regardless of their regular
  // custom field setting.
  const requiredCustomFields = (
    customFields?.filter((x) => applicableIDs.includes(x.id)) || []
  ).map((c) => ({
    ...c,
    required_v2: CustomFieldRequiredV2Enum.Always,
  }));

  return (
    <>
      <Button
        onClick={(e) => {
          onClick(e);
          setShowCustomFieldsModal(true);
        }}
        analyticsTrackingId="post-incident-task-assign-role"
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        theme={theme}
        className={buttonClassName}
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.SetCustomFields,
        )}
        iconProps={iconProps}
      >
        Update custom fields
      </Button>
      {showCustomFieldsModal &&
        incident &&
        (isLoading ? (
          <LoadingModal onClose={() => setShowCustomFieldsModal(false)} />
        ) : (
          <EditSpecificCustomFieldEntriesModal
            filterToCustomFieldIDs={applicableIDs}
            allCustomFields={requiredCustomFields}
            incident={incident}
            onClose={() => setShowCustomFieldsModal(false)}
          />
        ))}
    </>
  );
};

const CompletePostMortemButton = ({
  incident,
  incidentTask,
  onTaskComplete,
  onError,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incident: Incident;
  incidentTask: PostIncidentTaskSlim;
  onTaskComplete: () => void;
  task: PostIncidentTaskConfigSlim | undefined;
  onError: (error: string) => void;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement => {
  const { postmortemName, postmortemNameFormatted } =
    usePostmortemName(incident);

  const { availableDocument: document } = usePostmortem(incident);
  const disabled = !document;

  const refetchPostIncidentFlowTasks = useAPIRefetch(
    "postIncidentFlowListTasks",
    { incidentId: incident.id },
  );
  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });

  const {
    trigger: updateDocumentStatus,
    isMutating: updatingStatus,
    genericError: statusError,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident?.id ?? "" },
    async (
      apiClient,
      {
        id,
        status,
      }: {
        id: string;
        status: PostmortemDocumentStatusEnum;
      },
    ) => {
      await apiClient.postmortemsSetDocumentStatus({
        id,
        setDocumentStatusRequestBody: {
          status:
            status as unknown as PostmortemsSetDocumentStatusRequestBodyStatusEnum,
        },
      });

      await refetchPostIncidentFlowTasks();

      // Don't bother waiting for this
      refetchIncident();
    },
  );

  useEffect(() => {
    if (!updatingStatus && statusError) {
      onError(statusError);
    }
    // We want to check for an error after every fetch completes, rather than
    // after the error changes so we can report whenever a mutation fails.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingStatus]);

  return (
    <GatedButton
      disabledTooltipContent={`${postmortemName} has not been created yet`}
      onClick={async (e) => {
        onClick(e);
        if (document != null) {
          await updateDocumentStatus({
            id: document.id,
            status: PostmortemDocumentStatusEnum.Complete,
          });
          onTaskComplete();
        }
      }}
      analyticsTrackingId="post-incident-task-complete-postmortem"
      analyticsTrackingMetadata={{
        incident_task_id: incidentTask.id,
      }}
      requiredScope={ScopeNameEnum.PostMortemsMarkAsComplete}
      disabled={disabled}
      loading={updatingStatus}
      theme={theme}
      className={buttonClassName}
      icon={TaskTypeToIcon(
        PostIncidentTaskOptionTaskTypeEnum.CompletePostmortem,
      )}
      iconProps={iconProps}
    >
      Complete {postmortemNameFormatted}
    </GatedButton>
  );
};

const InReviewPostMortemButton = ({
  incident,
  incidentTask,
  onTaskComplete,
  onError,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incident: Incident;
  incidentTask: PostIncidentTaskSlim;
  onTaskComplete: () => void;
  task: PostIncidentTaskConfigSlim | undefined;
  onError: (error: string) => void;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement => {
  const { postmortemName, postmortemNameFormatted } =
    usePostmortemName(incident);

  const { availableDocument: document } = usePostmortem(incident);
  const disabled = !document;

  const refetchPostIncidentFlowTasks = useAPIRefetch(
    "postIncidentFlowListTasks",
    { incidentId: incident.id },
  );
  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });

  const {
    trigger: updateDocumentStatus,
    isMutating: updatingStatus,
    genericError: statusError,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident?.id ?? "" },
    async (
      apiClient,
      {
        id,
        status,
      }: {
        id: string;
        status: PostmortemDocumentStatusEnum;
      },
    ) => {
      await apiClient.postmortemsSetDocumentStatus({
        id,
        setDocumentStatusRequestBody: {
          status:
            status as unknown as PostmortemsSetDocumentStatusRequestBodyStatusEnum,
        },
      });

      await refetchPostIncidentFlowTasks();

      // Don't bother waiting for this
      refetchIncident();
    },
  );

  useEffect(() => {
    if (!updatingStatus && statusError) {
      onError(statusError);
    }
    // We want to check for an error after every fetch completes, rather than
    // after the error changes so we can report whenever a mutation fails.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingStatus]);

  return (
    <GatedButton
      disabledTooltipContent={`${postmortemName} has not been created yet`}
      onClick={async (e) => {
        onClick(e);
        if (document != null) {
          await updateDocumentStatus({
            id: document.id,
            status: PostmortemDocumentStatusEnum.Review,
          });
          onTaskComplete();
        }
      }}
      analyticsTrackingId="post-incident-task-in-review-postmortem"
      analyticsTrackingMetadata={{
        incident_task_id: incidentTask.id,
      }}
      disabled={disabled}
      loading={updatingStatus}
      theme={theme}
      className={buttonClassName}
      icon={TaskTypeToIcon(
        PostIncidentTaskOptionTaskTypeEnum.InReviewPostmortem,
      )}
      iconProps={iconProps}
    >
      Mark {postmortemNameFormatted} as in review
    </GatedButton>
  );
};

const ReviewTimelineButton = ({
  incidentTask,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incidentTask: PostIncidentTaskSlim;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  const { search, pathname } = useLocation();
  const navigate = useOrgAwareNavigate();

  return (
    <>
      <Button
        theme={theme}
        onClick={(e) => {
          onClick(e);
          // If we're on the incident details page, just switch tabs.
          if (pathname.includes("/incidents/")) {
            const newSearch = new URLSearchParams(search);
            newSearch.set("tab", BodyTabs.Timeline);
            navigate(
              {
                pathname,
                search: newSearch.toString(),
              },
              { replace: true },
            );
          } else {
            // Otherwise, we're on the post-incident flow page, so we need to
            // navigate to the incident details page.
            navigate(`/incidents/${incidentTask.incident_id}?tab=timeline`);
          }
        }}
        analyticsTrackingId="post-incident-task-review-timeline"
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        className={buttonClassName}
        icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.ReviewTimeline)}
        iconProps={iconProps}
      >
        View timeline
      </Button>
    </>
  );
};

const UpdateIncidentSummaryButton = ({
  incidentTask,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incidentTask: PostIncidentTaskSlim;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  const { setIsEditingSummary } = useSummaryContext();
  const { pathname } = useLocation();
  const navigate = useOrgAwareNavigate();

  return (
    <>
      <Button
        theme={theme}
        onClick={(e) => {
          onClick(e);
          // If we're on the incident details page, we can just set the state.
          if (pathname.includes("/incidents/")) {
            setIsEditingSummary(true);
          } else {
            // Otherwise, we're on the post-incident flow page, so we need to
            // navigate to the incident details page.
            navigate(`/incidents/${incidentTask.incident_id}?`);
          }
        }}
        analyticsTrackingId="post-incident-task-update-incident-summary"
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        className={buttonClassName}
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.UpdateIncidentSummary,
        )}
        iconProps={iconProps}
      >
        Update the summary
      </Button>
    </>
  );
};

const ReviewFollowUpsButton = ({
  incidentTask,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incidentTask: PostIncidentTaskSlim;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  const { search, pathname } = useLocation();
  const navigate = useOrgAwareNavigate();

  return (
    <>
      <Button
        theme={theme}
        onClick={(e) => {
          onClick(e);
          // If we're on the incident details page, just switch tabs.
          if (pathname.includes("/incidents/")) {
            const newSearch = new URLSearchParams(search);
            newSearch.set("tab", BodyTabs.FollowUps);
            navigate(
              {
                pathname,
                search: newSearch.toString(),
              },
              { replace: true },
            );
          } else {
            // Otherwise, we're on the post-incident flow page, so we need to
            // navigate to the incident details page.
            navigate(`/incidents/${incidentTask.incident_id}?tab=follow-ups`);
          }
        }}
        analyticsTrackingId="post-incident-task-review-follow-ups"
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        className={buttonClassName}
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.ReviewFollowups,
        )}
        iconProps={iconProps}
      >
        View follow-ups
      </Button>
    </>
  );
};

const UpdateTimestampsButton = ({
  incident,
  incidentTask,
  onTaskComplete,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incident: Incident;
  incidentTask: PostIncidentTaskSlim;
  onTaskComplete: () => void;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  const [showTimestampsModal, setShowTimestampsModal] =
    useState<boolean>(false);

  return (
    <>
      <Button
        theme={theme}
        onClick={(e) => {
          onClick(e);
          setShowTimestampsModal(true);
        }}
        analyticsTrackingId="post-incident-task-update-timestamps"
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        className={buttonClassName}
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.UpdateTimestamps,
        )}
        iconProps={iconProps}
      >
        View timestamps
      </Button>
      {showTimestampsModal && (
        <EditTimestampsModal
          incident={incident}
          filterToTimestampIds={incidentTask.config.timestamp_ids ?? []}
          onClose={() => {
            setShowTimestampsModal(false);
            onTaskComplete();
          }}
        />
      )}
    </>
  );
};

const GiveShoutoutButton = ({
  incident,
  incidentTask,
  onTaskComplete,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incident: Incident;
  incidentTask: PostIncidentTaskSlim;
  onTaskComplete: () => void;
  theme: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  const [showShoutoutModal, setShowShoutoutModal] = useState<boolean>(false);

  return (
    <>
      <Button
        theme={theme}
        onClick={(e) => {
          onClick(e);
          setShowShoutoutModal(true);
        }}
        analyticsTrackingId="post-incident-task-give-shoutout"
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        className={buttonClassName}
        icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.GiveShoutout)}
        iconProps={iconProps}
      >
        Give shoutout
      </Button>
      {showShoutoutModal && (
        <GiveShoutoutModal
          incident={incident}
          onClose={() => {
            setShowShoutoutModal(false);
            onTaskComplete();
          }}
        />
      )}
    </>
  );
};

const ShareLearningsButton = ({
  incident,
  incidentTask,
  theme,
  buttonClassName,
  iconProps,
}: {
  incident: Incident;
  incidentTask: PostIncidentTaskSlim;
  theme: ButtonTheme;
  buttonClassName?: string;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  return (
    <Button
      theme={theme}
      href={incident.slack_channel_url}
      openInNewTab={true}
      analyticsTrackingId="post-incident-task-share-learnings"
      analyticsTrackingMetadata={{
        incident_task_id: incidentTask.id,
      }}
      className={buttonClassName}
      icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.ShareLearnings)}
      iconProps={iconProps}
    >
      Share learnings
    </Button>
  );
};

// PostIncidentTaskActionButton returns a button for doing the given task (e.g. Create postmortem).
// If the task can only be completed by marking it as complete, it will return null.
export const PostIncidentTaskActionButton = ({
  incidentId,
  incidentTask,
  task,
  onError,
  onTaskComplete,
  theme,
  buttonClassName,
  onClick,
  iconProps,
}: {
  incidentId: string;
  incidentTask: PostIncidentTaskSlim;
  task: PostIncidentTaskConfigSlim | undefined;
  onTaskComplete: () => void;
  onError: (error: string) => void;
  theme?: ButtonTheme;
  buttonClassName?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement | null => {
  const { identity } = useIdentity();
  const { incident } = useIncident(incidentId);

  if (!incident || !task) {
    return null;
  }

  if (!theme) {
    // If there's no theme, we check whether or not this is the only CTA for the task.
    // If it's the only CTA, we should make it have a secondary theme. But if there's going to be a
    // "Complete" button alongisde, we should give it an invisible theme so they're not competing.
    if (TaskCanBeManuallyCompleted(task)) {
      theme = ButtonTheme.Naked;
    } else {
      theme = ButtonTheme.Secondary;
    }
  }

  switch (task?.task_type) {
    case ConfigTaskTypeEnum.CreatePostmortem:
      return (
        <CreatePostMortemButton
          incident={incident}
          incidentTask={incidentTask}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.SharePostmortem:
      if (!identity.can_share_postmortems) {
        return null;
      }
      return (
        <SharePostMortemButton
          incident={incident}
          incidentTask={incidentTask}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.CompletePostmortem:
      return (
        <CompletePostMortemButton
          incident={incident}
          incidentTask={incidentTask}
          task={task}
          onTaskComplete={onTaskComplete}
          onError={onError}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.InReviewPostmortem:
      return (
        <InReviewPostMortemButton
          incident={incident}
          incidentTask={incidentTask}
          task={task}
          onTaskComplete={onTaskComplete}
          onError={onError}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.ReviewFollowups:
      return (
        <ReviewFollowUpsButton
          incidentTask={incidentTask}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.UpdateTimestamps:
      return (
        <UpdateTimestampsButton
          incident={incident}
          incidentTask={incidentTask}
          onTaskComplete={onTaskComplete}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.GiveShoutout:
      return (
        <GiveShoutoutButton
          incident={incident}
          incidentTask={incidentTask}
          onTaskComplete={onTaskComplete}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.ReviewTimeline:
      return (
        <ReviewTimelineButton
          incidentTask={incidentTask}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.UpdateIncidentSummary:
      return (
        <UpdateIncidentSummaryButton
          incidentTask={incidentTask}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.AssignRole:
      return (
        <AssignRoleButton
          incident={incident}
          incidentTask={incidentTask}
          onTaskComplete={onTaskComplete}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.SetCustomFields:
      return (
        <SetCustomFieldsButton
          incident={incident}
          incidentTask={incidentTask}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.SetTimestamps:
      return (
        <UpdateTimestampsButton
          incident={incident}
          incidentTask={incidentTask}
          onTaskComplete={onTaskComplete}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.ScheduleDebrief:
      return (
        <ScheduleDebriefButton
          incident={incident}
          incidentTask={incidentTask}
          theme={theme}
          buttonClassName={buttonClassName}
          onClick={onClick}
          iconProps={iconProps}
        />
      );
    case ConfigTaskTypeEnum.ShareLearnings:
      return (
        <ShareLearningsButton
          incident={incident}
          incidentTask={incidentTask}
          theme={theme}
          buttonClassName={buttonClassName}
          iconProps={iconProps}
        />
      );
    default:
      return null;
  }
};
