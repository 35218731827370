/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldEntryPayload,
    CustomFieldEntryPayloadFromJSON,
    CustomFieldEntryPayloadFromJSONTyped,
    CustomFieldEntryPayloadToJSON,
} from './CustomFieldEntryPayload';
import {
    IncidentRoleAssignmentPayload,
    IncidentRoleAssignmentPayloadFromJSON,
    IncidentRoleAssignmentPayloadFromJSONTyped,
    IncidentRoleAssignmentPayloadToJSON,
} from './IncidentRoleAssignmentPayload';
import {
    IncidentTimestampValuePayload,
    IncidentTimestampValuePayloadFromJSON,
    IncidentTimestampValuePayloadFromJSONTyped,
    IncidentTimestampValuePayloadToJSON,
} from './IncidentTimestampValuePayload';
import {
    TextDocumentPayload,
    TextDocumentPayloadFromJSON,
    TextDocumentPayloadFromJSONTyped,
    TextDocumentPayloadToJSON,
} from './TextDocumentPayload';

/**
 * 
 * @export
 * @interface IncidentsCreateUpdateRequestBody
 */
export interface IncidentsCreateUpdateRequestBody {
    /**
     * The call URL attached to this incident
     * @type {string}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    call_url?: string;
    /**
     * Set the incident's custom fields to these values
     * @type {Array<CustomFieldEntryPayload>}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    custom_field_entries?: Array<CustomFieldEntryPayload>;
    /**
     * Assign incident roles to these people
     * @type {Array<IncidentRoleAssignmentPayload>}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    incident_role_assignments?: Array<IncidentRoleAssignmentPayload>;
    /**
     * Assign the incident's timestamps to these values
     * @type {Array<IncidentTimestampValuePayload>}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    incident_timestamp_values?: Array<IncidentTimestampValuePayload>;
    /**
     * The incident type ID that should be assigned to this incident
     * @type {string}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    incident_type_id?: string;
    /**
     * If you're merging the incident, which incident are you merging it into?
     * @type {string}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    merged_into_incident_id?: string;
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    message?: TextDocumentPayload;
    /**
     * Changes incident name, if provided
     * @type {string}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    name?: string;
    /**
     * When to prompt for the next update
     * @type {number}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    next_update_in_minutes?: number;
    /**
     * An optional decision that was made about entering or exiting the post-incident flow
     * @type {string}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    post_incident_flow_decision?: IncidentsCreateUpdateRequestBodyPostIncidentFlowDecisionEnum;
    /**
     * Override the name of the incident Slack channel
     * @type {string}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    slack_channel_name_override?: string;
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    summary?: TextDocumentPayload;
    /**
     * The source of the summary, if provided
     * @type {string}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    summary_source?: IncidentsCreateUpdateRequestBodySummarySourceEnum;
    /**
     * Update moved the incident to this incident status ID
     * @type {string}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    to_incident_status_id?: string;
    /**
     * Update moved the incident to this severity
     * @type {string}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    to_severity_id?: string;
    /**
     * When to automatically unpause the incident
     * @type {number}
     * @memberof IncidentsCreateUpdateRequestBody
     */
    unpause_in_minutes?: number;
}

/**
* @export
* @enum {string}
*/
export enum IncidentsCreateUpdateRequestBodyPostIncidentFlowDecisionEnum {
    None = 'none',
    OptIn = 'opt_in',
    OptOut = 'opt_out',
    AutomaticOptIn = 'automatic_opt_in'
}/**
* @export
* @enum {string}
*/
export enum IncidentsCreateUpdateRequestBodySummarySourceEnum {
    Human = 'human',
    GeneratedAccepted = 'generated_accepted',
    GeneratedEdited = 'generated_edited'
}

export function IncidentsCreateUpdateRequestBodyFromJSON(json: any): IncidentsCreateUpdateRequestBody {
    return IncidentsCreateUpdateRequestBodyFromJSONTyped(json, false);
}

export function IncidentsCreateUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsCreateUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'call_url': !exists(json, 'call_url') ? undefined : json['call_url'],
        'custom_field_entries': !exists(json, 'custom_field_entries') ? undefined : ((json['custom_field_entries'] as Array<any>).map(CustomFieldEntryPayloadFromJSON)),
        'incident_role_assignments': !exists(json, 'incident_role_assignments') ? undefined : ((json['incident_role_assignments'] as Array<any>).map(IncidentRoleAssignmentPayloadFromJSON)),
        'incident_timestamp_values': !exists(json, 'incident_timestamp_values') ? undefined : ((json['incident_timestamp_values'] as Array<any>).map(IncidentTimestampValuePayloadFromJSON)),
        'incident_type_id': !exists(json, 'incident_type_id') ? undefined : json['incident_type_id'],
        'merged_into_incident_id': !exists(json, 'merged_into_incident_id') ? undefined : json['merged_into_incident_id'],
        'message': !exists(json, 'message') ? undefined : TextDocumentPayloadFromJSON(json['message']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'next_update_in_minutes': !exists(json, 'next_update_in_minutes') ? undefined : json['next_update_in_minutes'],
        'post_incident_flow_decision': !exists(json, 'post_incident_flow_decision') ? undefined : json['post_incident_flow_decision'],
        'slack_channel_name_override': !exists(json, 'slack_channel_name_override') ? undefined : json['slack_channel_name_override'],
        'summary': !exists(json, 'summary') ? undefined : TextDocumentPayloadFromJSON(json['summary']),
        'summary_source': !exists(json, 'summary_source') ? undefined : json['summary_source'],
        'to_incident_status_id': !exists(json, 'to_incident_status_id') ? undefined : json['to_incident_status_id'],
        'to_severity_id': !exists(json, 'to_severity_id') ? undefined : json['to_severity_id'],
        'unpause_in_minutes': !exists(json, 'unpause_in_minutes') ? undefined : json['unpause_in_minutes'],
    };
}

export function IncidentsCreateUpdateRequestBodyToJSON(value?: IncidentsCreateUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_url': value.call_url,
        'custom_field_entries': value.custom_field_entries === undefined ? undefined : ((value.custom_field_entries as Array<any>).map(CustomFieldEntryPayloadToJSON)),
        'incident_role_assignments': value.incident_role_assignments === undefined ? undefined : ((value.incident_role_assignments as Array<any>).map(IncidentRoleAssignmentPayloadToJSON)),
        'incident_timestamp_values': value.incident_timestamp_values === undefined ? undefined : ((value.incident_timestamp_values as Array<any>).map(IncidentTimestampValuePayloadToJSON)),
        'incident_type_id': value.incident_type_id,
        'merged_into_incident_id': value.merged_into_incident_id,
        'message': TextDocumentPayloadToJSON(value.message),
        'name': value.name,
        'next_update_in_minutes': value.next_update_in_minutes,
        'post_incident_flow_decision': value.post_incident_flow_decision,
        'slack_channel_name_override': value.slack_channel_name_override,
        'summary': TextDocumentPayloadToJSON(value.summary),
        'summary_source': value.summary_source,
        'to_incident_status_id': value.to_incident_status_id,
        'to_severity_id': value.to_severity_id,
        'unpause_in_minutes': value.unpause_in_minutes,
    };
}

