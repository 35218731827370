/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReturnsMeta,
    ReturnsMetaFromJSON,
    ReturnsMetaFromJSONTyped,
    ReturnsMetaToJSON,
} from './ReturnsMeta';

/**
 * 
 * @export
 * @interface AvailableExpressionOperation
 */
export interface AvailableExpressionOperation {
    /**
     * 
     * @type {ReturnsMeta}
     * @memberof AvailableExpressionOperation
     */
    fixed_return?: ReturnsMeta;
    /**
     * The type of the operation
     * @type {string}
     * @memberof AvailableExpressionOperation
     */
    operation_type: AvailableExpressionOperationOperationTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AvailableExpressionOperationOperationTypeEnum {
    Navigate = 'navigate',
    Filter = 'filter',
    Count = 'count',
    Min = 'min',
    Max = 'max',
    Random = 'random',
    First = 'first',
    Parse = 'parse',
    Branches = 'branches'
}

export function AvailableExpressionOperationFromJSON(json: any): AvailableExpressionOperation {
    return AvailableExpressionOperationFromJSONTyped(json, false);
}

export function AvailableExpressionOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableExpressionOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fixed_return': !exists(json, 'fixed_return') ? undefined : ReturnsMetaFromJSON(json['fixed_return']),
        'operation_type': json['operation_type'],
    };
}

export function AvailableExpressionOperationToJSON(value?: AvailableExpressionOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fixed_return': ReturnsMetaToJSON(value.fixed_return),
        'operation_type': value.operation_type,
    };
}

