import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconBadge, IconSize } from "@incident-ui";
import { ErrorBoundary } from "@sentry/react";

import { AlertSourceSetupComponent } from "../AlertSourceConnectPage";

export const AlertSourceSetupInfoPreviewPage = () => {
  const sourceConfig = {
    name: "My alert source",
    secret_token: "my-token-123",
    id: "my-id-123",
    routing: {},
    template: { expressions: [], bindings: [] },
  };

  return (
    <div className="flex flex-col gap-10 overflow-y-auto overflow-x-hidden h-screen p-10">
      {Object.keys(AlertSourceSetupComponent).map((key) => {
        const Component = AlertSourceSetupComponent[key];
        if (Component == null) {
          return null;
        }

        const config = ALERT_SOURCE_TYPE_CONFIGS[key];

        return (
          <ErrorBoundary key={key}>
            <div className="flex flex-col gap-2" key={key}>
              <div className="text-2xl-bold flex gap-2 items-center pb-4">
                <IconBadge
                  icon={config.icon}
                  color={ColorPaletteEnum.SlateOnWhite}
                  size={IconSize.Medium}
                />
                <div>{config.label}</div>
              </div>
              <Component
                alertSourceConfig={sourceConfig}
                schema={{ attributes: [] }}
              />
            </div>
            <hr />
          </ErrorBoundary>
        );
      })}
    </div>
  );
};
