import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  IconSize,
  Input,
  StackedList,
} from "@incident-ui";
import { DrawerTitle } from "@incident-ui/Drawer/Drawer";
import { InputType } from "@incident-ui/Input/Input";
import _ from "lodash";
import { ChangeEvent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { StepSlim, Trigger } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { WorkflowFormData } from "../common/types";
import { WorkflowStepButton } from "../common/WorkflowStepButton";
import { useWorkflowsSteps } from "../create-edit-form/hooks/useStepsController";
import { useWorkflowsDeps } from "../create-edit-form/WorkflowsFormContext";
import { findInferReferenceParamInScope } from "./prepareStep";

// We want to render the first few steps in this order. If we add a step and don't
// add it here, that's fine, it'll just go at the end.
const stepsInOrder = [
  "incident.create_action",
  "incident.create_follow_ups",
  "decision_flow.prompt",
  "slack.invite_user",
  "slack.post_message",
  "slack.post_ephemeral_message",
  "slack.send_message",
  "pagerduty.escalate",
  "incident.post_announcement",
];

// makeStepsForTrigger removes any steps that include an `infer_reference` param that can't be found
// in the trigger scope. E.g. if a trigger has no followup, then the 'export followup to linear' step
// would be removed.
const makeStepsForTrigger = (
  trigger: Trigger,
  steps: StepSlim[],
): StepSlim[] => {
  return steps.filter((step) => {
    return step.params.every((param) => {
      if (param.infer_reference) {
        // We can only use this step if we have a reference that is both
        // the right type, but ALSO has the correct 'array' value.
        const ref = findInferReferenceParamInScope(trigger.scope, param);
        return !!ref;
      }
      return true;
    });
  });
};

// WorkflowAddStepForm handles the choosing of a step to add to a workflow.
export const WorkflowAddStepForm = ({ onClose }: { onClose: () => void }) => {
  const formMethods = useFormContext<WorkflowFormData>();
  const { showArticle } = useIntercom();

  const {
    onChooseStep,
    onCreateLoopingStep: onAddLoop,
    stepFormState,
  } = useWorkflowsSteps();

  const selectedGroup = stepFormState?.selectedGroup;
  const isLoop =
    selectedGroup != null &&
    formMethods.getValues(`step_groups.${selectedGroup}.isLoop`);

  const { steps, trigger } = useWorkflowsDeps();

  const { showMessages: openIntercom } = useIntercom();

  const [searchFilter, setSearchFilter] = useState("");

  if (!trigger) {
    // The user should never see this.
    return (
      <div className="p-6">
        <Callout theme={CalloutTheme.Danger}>
          <p>Please select a trigger to add a step.</p>
        </Callout>
      </div>
    );
  }

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchFilter(e.target.value || "");
  };

  const stepsForTrigger = makeStepsForTrigger(trigger, steps);

  const orderedSteps = _.sortBy(stepsForTrigger, (s) => {
    const idx = stepsInOrder.findIndex((x) => x === s.name);
    return idx === -1 ? Number.POSITIVE_INFINITY : idx;
  });

  // If a step is not available, and we have no way of prompting the org to
  // become eligible, completely remove it.
  const visibleSteps = orderedSteps.filter(
    (step) => step.organisation_is_eligible || step.become_eligible_cta,
  );

  let filteredSteps = visibleSteps;
  if (searchFilter && searchFilter !== "") {
    filteredSteps = visibleSteps.filter(
      (x) =>
        x.description.toLowerCase().includes(searchFilter.toLowerCase()) ||
        x.label.toLowerCase().includes(searchFilter.toLowerCase()),
    );
  }

  const groupedSteps = _.groupBy(filteredSteps, (step) => {
    return step.group_label;
  });

  const applicableRedirects = PRODUCT_MARKETING_REDIRECTS.filter((redirect) =>
    redirect.searchTerms.some(
      (term) =>
        searchFilter.toLowerCase().includes(term.toLowerCase()) ||
        term.toLocaleLowerCase().includes(searchFilter.toLowerCase()),
    ),
  );

  return (
    <>
      <DrawerTitle
        title="Add step"
        onClose={onClose}
        closeIcon={IconEnum.Close}
        compact
      />
      <div className="p-6">
        <>
          <div className="flex mb-6">
            <div className="flex-grow">
              <Input
                id="search_steps"
                type={InputType.Search}
                placeholder={"Search steps"}
                onChange={onChangeSearch}
                value={searchFilter}
                iconName={IconEnum.Search}
                className={"bg-white !rounded-full"}
                autoFocus={true}
              />
            </div>
            {/* If we're adding a step to a loop, we don't want to show the add loop button! */}
            {!isLoop && (
              <Button
                icon={IconEnum.Refresh1}
                iconProps={{
                  size: IconSize.Large,
                }}
                className="ml-4 text-purple-content"
                analyticsTrackingId="workflows-v2-add-loop"
                onClick={onAddLoop}
              >
                Add a loop
              </Button>
            )}
          </div>

          <div className="flex flex-col gap-6">
            {Object.entries(groupedSteps).map(([group, stepsInGroup]) => (
              <div key={group} className="flex flex-col">
                <span
                  className={tcx(
                    "text-xs font-semibold tracking-[1.5px] text-content-tertiary uppercase mb-[12px]",
                  )}
                >
                  {group}
                </span>
                <StackedList className="overflow-hidden">
                  {stepsInGroup.map((step, i) => {
                    const isEligible = step.organisation_is_eligible;

                    const buttonProps = {};

                    if (isEligible) {
                      buttonProps["onClick"] = () => {
                        onChooseStep(step);
                      };
                    } else if (step.become_eligible_cta?.is_talk_to_us) {
                      buttonProps["onClick"] = () => {
                        // If the cta is to talk to us, we want to open intercom
                        // instead of ushering the user to another page in the app.
                        openIntercom();
                      };
                    } else if (step.become_eligible_cta?.url !== undefined) {
                      buttonProps["href"] = step.become_eligible_cta?.url;
                      buttonProps["target"] = "_blank";
                    }

                    return (
                      <WorkflowStepButton
                        key={`${group}-${i}`}
                        step={step}
                        {...buttonProps}
                        analyticsTrackingId={`workflows-v2-add-step`}
                      />
                    );
                  })}
                </StackedList>
              </div>
            ))}
            {applicableRedirects.map((redirect) => (
              <Callout
                key={redirect.key}
                title={redirect.title}
                theme={CalloutTheme.Info}
                subtitle={redirect.content}
                ctaPosition="bottom"
                iconOverride={redirect.icon}
                cta={
                  <Button
                    analyticsTrackingId={`workflows-redirect-${redirect.key}`}
                    theme={ButtonTheme.Secondary}
                    href={redirect.path}
                    openInNewTab
                  >
                    {redirect.ctaText}
                  </Button>
                }
                secondaryCta={
                  <Button
                    analyticsTrackingId={`workflows-learn-more`}
                    onClick={() => showArticle(redirect.helpArticle)}
                    theme={ButtonTheme.Naked}
                  >
                    Learn more{" "}
                  </Button>
                }
              />
            ))}
          </div>
        </>
      </div>
    </>
  );
};

type ProductMarketingRedirect = {
  key: string;
  searchTerms: string[];
  title: string;
  content: string;
  helpArticle: number;
  path: string;
  ctaText: string;
  icon: IconEnum;
};
// PRODUCT_MARKETING_REDIRECTS are used to help redirect someone to another part of the product if it
// looks like they're trying to use workflows for something that we have a first class feature for.
const PRODUCT_MARKETING_REDIRECTS: ProductMarketingRedirect[] = [
  {
    key: "automated-custom-fields",
    searchTerms: [
      "custom",
      "custom field",
      "set field",
      "set custom",
      "update field",
      "update custom",
    ],
    title: "Automatically set custom fields",
    content:
      "You can automatically set custom fields by creating an automated custom field. You can choose whether a User is able to override that value.",
    helpArticle: 9638159,
    path: "/settings/custom-fields",
    ctaText: "Settings → Custom fields",
    icon: IconEnum.CustomField,
  },
  {
    key: "announcement-rules",
    searchTerms: ["announce", "announcement"],
    title: "Announcement rules",
    content:
      "Use announcement rules to announce incidents to the right Slack channels at the right time.",
    helpArticle: 8595371,
    path: "/settings/announcements",
    ctaText: "Settings → Announcements",
    icon: IconEnum.Announcement,
  },
  {
    key: "channel-bookmarks",
    searchTerms: ["bookmark", "runbook"],
    title: "Channel bookmarks",
    content:
      "You can customise your channel bookmarks based on properties of the incident, and use them to help responders access the right runbook or dashboard really quickly.",
    helpArticle: 6853833,
    path: "/settings/slack-channel",
    ctaText: "Settings → Slack channel",
    icon: IconEnum.SlackGreyscale,
  },
];
