import {
  DependentResource,
  StatusPage,
  StatusPagePageTypeEnum,
  StatusPageUpdateRequestBody,
} from "@incident-io/api";
import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ModalFooter,
  ToastTheme,
} from "@incident-ui";
import { Input, InputType } from "@incident-ui/Input/Input";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _, { kebabCase } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  FieldValues,
  Path,
  useController,
  useForm,
  useFormContext,
  UseFormReturn,
  ValidateResult,
} from "react-hook-form";
import { DeletionConfirmationFormModal } from "src/components/settings/DeletionConfirmationModal";
import {
  ErrorResponse,
  ScopeNameEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { getAnchorId, scrollToAnchor } from "src/utils/anchor";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { statusPageDefaultDomain } from "../../common/utils/utils";
import { FormType } from "../../create/StandalonePageCreateModal";
import styles from "./BasicSettings.module.scss";

export const BasicSettings = ({
  page,
  dependentResources,
}: {
  page: StatusPage;
  dependentResources: DependentResource[];
}): React.ReactElement | null => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [hasScrolledTo, setHasScrolledTo] = useState("");
  useEffect(() => {
    scrollToAnchor(
      false,
      0,
      hasScrolledTo,
      setHasScrolledTo,
      undefined,
      "smooth",
    );
  }, [location.href, hasScrolledTo, setHasScrolledTo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {showDeleteModal && (
        <DeletePageModal
          dependentResources={dependentResources}
          page={page}
          onClose={() => setShowDeleteModal(false)}
        />
      )}

      <BasicSettingsForm page={page} />

      <CustomizationForm page={page} />

      <DeletePageSection setShowDeleteModal={setShowDeleteModal} />
    </>
  );
};

const DeletePageSection = ({
  setShowDeleteModal,
}: {
  setShowDeleteModal: (shouldShow: boolean) => void;
}): React.ReactElement => {
  return (
    <div className="bg-surface-secondary rounded-[6px] p-4 border border-stroke text-sm">
      <div className="font-medium mb-2">Delete page</div>
      <p className="text-slate-600 mb-3">
        Deleting your status page can&apos;t be undone. Your page will no longer
        be available to view for your users.
      </p>

      <Button
        analyticsTrackingId={"status-page-delete-page"}
        theme={ButtonTheme.Destroy}
        onClick={() => setShowDeleteModal(true)}
      >
        Delete status page
      </Button>
    </div>
  );
};

export type DeletePageFormType = {
  confirmString: string;
};
const DeletePageModal = ({
  page,
  onClose,
  dependentResources,
}: {
  page: StatusPage;
  onClose: () => void;
  dependentResources: DependentResource[];
}): React.ReactElement => {
  const formMethods = useForm<DeletePageFormType>({
    mode: "all",
    defaultValues: { confirmString: "" },
  });

  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const { trigger, isMutating: saving } = useAPIMutation(
    "statusPageList",
    undefined,
    async (apiClient, _: DeletePageFormType) => {
      await apiClient.statusPageDestroy({ id: page.id });
    },
    {
      onSuccess: () => {
        onClose();

        showToast({
          title: "Status page successfully deleted",
          theme: ToastTheme.Success,
        });
        navigate("/status-pages");
      },
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: "We weren't able to delete this status page",

          theme: ToastTheme.Error,
        });
      },
    },
  );

  return (
    <DeletionConfirmationFormModal
      onClose={onClose}
      onDelete={trigger}
      resourceTitle={page.name}
      isOpen={true}
      title="Delete status page"
      analyticsTrackingId="status-page-delete-page-modal"
      dependentResources={dependentResources}
      formMethods={formMethods}
      footer={
        <ModalFooter
          analyticsTrackingId={"status-page-delete-page-modal-confirm"}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Delete status page"
          cancelButtonText="Cancel"
          saving={saving}
          disabled={!formMethods.formState.isValid}
          disabledTooltipContent="Please type the name of the page to confirm deletion."
        />
      }
    >
      <>
        <p>
          This action cannot be undone. This will permanently delete the page{" "}
          <span className="font-semibold">{page.name}</span> and all associated
          incidents.
        </p>
        <p>
          Your users will no longer be able to see view this page, via your
          custom domain or through incident.io.
        </p>
        <InputV2
          helptext={
            <p className="text-content-primary">
              Please type <span className="font-semibold">{page.name}</span> to
              confirm.
            </p>
          }
          rules={{ validate: (value) => value.trim() === page.name.trim() }}
          formMethods={formMethods}
          name="confirmString"
        />
      </>
    </DeletionConfirmationFormModal>
  );
};

// This uses empty strings rather than undefined, so that resetting the form
// works nicely
const buildBasicSettingsState = (
  page: StatusPage,
): StatusPageUpdateRequestBody => ({
  ...page,
  google_analytics_tag: page.google_analytics_tag ?? "",
  privacy_policy_url: page.privacy_policy_url ?? "",
  terms_of_service_url: page.terms_of_service_url ?? "",
  support_url: page.support_url ?? "",
  support_label: page.support_label ?? "Report a problem",
});

export const BasicSettingsForm = ({
  page,
}: {
  page: StatusPage;
}): React.ReactElement => {
  const formMethods = useForm<StatusPageUpdateRequestBody>({
    defaultValues: buildBasicSettingsState(page),
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, data: StatusPageUpdateRequestBody) => {
      await apiClient.statusPageUpdate({
        id: page.id,
        updateRequestBody: {
          ...data,
          // TODO: once we're happy with this setup, and have added all the fields we need, we should make apis to do this specifically
          // Right now this is a hack, because we're changing this up, and I'm not convinced that we'll have a separate customize section until we've\
          // added the final fields and it feels good. That's why I need to use the update one and copy across these 5 fields for now.
          allow_search_engine_indexing: page.allow_search_engine_indexing,
          google_analytics_tag: page.google_analytics_tag,
          privacy_policy_url: page.privacy_policy_url,
          terms_of_service_url: page.terms_of_service_url,
          support_url: page.support_url,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: (res) =>
        formMethods.reset(buildBasicSettingsState(res.status_page)),
    },
  );
  const { hasScope } = useIdentity();
  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  return (
    <FormV2
      formMethods={formMethods}
      onSubmit={trigger}
      saving={isMutating}
      innerClassName="bg-surface-secondary rounded-[6px] p-4 border border-stroke"
    >
      <h3 className="font-medium">Basic settings</h3>
      <ErrorMessageUI message={genericError} />
      <BasicSettingsFormContent page={page} />

      <GatedButton
        type="submit"
        theme={ButtonTheme.Primary}
        analyticsTrackingId={"status-page-edit-basics"}
        analyticsTrackingMetadata={{ status_page_id: page.id }}
        loading={isMutating}
        disabled={missingPermission || !formMethods.formState.isDirty}
        disabledTooltipContent={
          missingPermission
            ? "You do not have permission to configure this public status page"
            : undefined
        }
      >
        Save
      </GatedButton>
    </FormV2>
  );
};

export const BasicSettingsFormContent = ({
  page,
  callout,
  isParentPage,
}: {
  page?: StatusPage;
  callout?: React.ReactNode;
  isParentPage?: boolean;
}): React.ReactElement => {
  const formMethods = useFormContext<FormType>();
  const { setValue, watch } = formMethods;

  const name = watch("name");
  const invalidSuffix = name && name.toLowerCase().trim().endsWith("status");

  // Generate a sensible-looking path as you type in a title
  const [pathIsLinked, setPathIsLinked] = useState(true);
  useEffect(() => {
    const { unsubscribe } = watch((data, { name }) => {
      // Never change the path if the page is already created
      if (page?.id) {
        return false;
      }
      if (name === "subpath") {
        if (data.subpath !== undefined) {
          setPathIsLinked(
            (data.name !== undefined && data.subpath === "") ||
              kebabCase(data.name) === data.subpath,
          );
        }
      }

      if (name === "name" && pathIsLinked && data.name !== undefined) {
        setValue("subpath", kebabCase(data.name));
      }
      return undefined;
    });
    return () => unsubscribe();
  }, [pathIsLinked, setValue, watch, page?.id]);

  const apiClient = useClient();
  const checkAvailable = async (subpath: string): Promise<ValidateResult> => {
    try {
      await apiClient.statusPageCheckSubpathAvailable({
        checkSubpathAvailableRequestBody: { status_page_id: page?.id, subpath },
      });

      return true;
    } catch (e) {
      if (e instanceof Response) {
        const jsonErr: ErrorResponse = await e.json();
        if (jsonErr.type === "validation_error") {
          const firstErr = jsonErr.errors[0];
          return firstErr.message;
        }
      }

      // Bit yikes here - something has gone wrong that isn't a validation
      // error. We could let the user carry on, but they might get stuck later
      // in the form. Let's crash instead.
      throw e;
    }
  };

  const statusPageDefaultDomainURL = `https://${statusPageDefaultDomain()}/`;

  return (
    <>
      {callout}

      <InputV2
        formMethods={formMethods}
        name="name"
        required="Please choose a title for your status page"
        label="Page title"
        helptext={
          !isParentPage
            ? "We'll show this as the page title when someone views your status page, and as a header on the page if you don't upload a logo."
            : undefined
        }
      />
      {invalidSuffix && (
        <Callout theme={CalloutTheme.Warning}>
          Your page title includes the word &quot;status&quot;. We&apos;ll
          automatically add this for you when we display it, so this page will
          appear as{" "}
          <span className="font-semibold">&quot;{name} Status&quot;</span>.
        </Callout>
      )}
      <InputV2
        key={"subpath"}
        formMethods={formMethods}
        name="subpath"
        spellCheck={false}
        required="Please choose a URL for your status page"
        label={
          page?.page_type === StatusPagePageTypeEnum.Customer
            ? "Base URL"
            : "Status page URL"
        }
        helptext={
          page
            ? undefined
            : isParentPage
            ? undefined
            : "This is where we'll host your status page for now."
        }
        inputClassName="bg-white"
        rules={{ validate: { checkAvailable } }}
        inputPrefixNode={
          <label
            htmlFor="subpath"
            className="text-slate-400 mt-[2px] text-nowrap"
          >
            {statusPageDefaultDomainURL}
          </label>
        }
      />
      {!page && (
        <Callout theme={CalloutTheme.Plain}>
          <h4 className="font-medium">Want to use your own domain?</h4>
          <p>You can set this up once you&apos;ve created your status page.</p>
        </Callout>
      )}
    </>
  );
};

export const CustomizationForm = ({
  page,
}: {
  page: StatusPage;
}): React.ReactElement => {
  const formMethods = useForm<StatusPageUpdateRequestBody>({
    defaultValues: buildBasicSettingsState(page),
  });

  const currentSupportURL = formMethods.watch("support_url");

  const { hasScope } = useIdentity();
  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  const { trigger, isMutating, genericError } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, data: StatusPageUpdateRequestBody) => {
      await apiClient.statusPageUpdate({
        id: page.id,
        updateRequestBody: {
          // We want our nullable fields to be written as undefined, not empty strings
          ...(_.omitBy(data, (v) => v === "") as StatusPageUpdateRequestBody),
          // Keep unchanged paths constant
          // TODO edit this to be two api calls once we're happy with this approach
          name: page.name,
          subpath: page.subpath,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: (res) =>
        formMethods.reset(buildBasicSettingsState(res.status_page)),
    },
  );

  return (
    <div id="customization">
      <FormV2
        formMethods={formMethods}
        onSubmit={trigger}
        saving={isMutating}
        innerClassName={tcx(
          "bg-surface-secondary rounded-[6px] p-4 border border-stroke",
          getAnchorId() === "customization" && styles.anchored,
        )}
      >
        <h3 className="font-medium">Customization</h3>
        <ErrorMessageUI message={genericError} />
        <InputV2
          formMethods={formMethods}
          name="support_url"
          type={InputType.Url}
          label="Support URL"
          placeholder="e.g. mailto:help@example.com or https://help.example.com"
          helptext="Where can customers report issues directly to you? We'll link to this from your public status page."
        />
        {currentSupportURL && (
          <SupportLabelInput
            formMethods={formMethods}
            name="support_label"
            label="Support Button Label"
            helptext="What text should lead customers to the above support URL?"
          />
        )}
        <InputV2
          formMethods={formMethods}
          name="google_analytics_tag"
          spellCheck={false}
          type={InputType.Text}
          label="Google Analytics tag"
          placeholder="e.g. G-3TEK2BQEYA"
          helptext="Tag from your Google analytics account, to collect data on page views."
        />
        {page.page_type !== StatusPagePageTypeEnum.Customer && (
          <div>
            <p className="text-sm font-medium text-content-primary max-w-xl">
              Show in search results
            </p>
            <CheckboxV2
              name="allow_search_engine_indexing"
              label="Show in search results"
              formMethods={formMethods}
              helptext="Should your status page be visible search results in Google and other search engines? "
            />
          </div>
        )}
        <InputV2
          formMethods={formMethods}
          type={InputType.Url}
          name="privacy_policy_url"
          label="Privacy policy"
          helptext={
            "Link to your organisation's publicly accessible privacy policy. You should be able to find this linked on your organisation's website, usually in the webpage footer."
          }
          placeholder="e.g. https://example.com/privacy-policy"
        />
        <InputV2
          formMethods={formMethods}
          name="terms_of_service_url"
          type={InputType.Url}
          label="Terms of service"
          helptext={
            "Link to your organisation's publicly accessible website terms of service. You should be able to find this linked on your organisation's website, usually in the webpage footer."
          }
          placeholder="e.g. https://example.com/terms-of-service"
        />
        <GatedButton
          type="submit"
          theme={ButtonTheme.Primary}
          analyticsTrackingId={"status-page-edit-customisation"}
          analyticsTrackingMetadata={{ status_page_id: page.id }}
          loading={isMutating}
          disabled={missingPermission || !formMethods.formState.isDirty}
          disabledTooltipContent={
            missingPermission
              ? "You do not have permission to configure this public status page"
              : undefined
          }
        >
          Save
        </GatedButton>
      </FormV2>
    </div>
  );
};

// N.B near-duplicate of PostmortemNameInput
export const SupportLabelInput = <FormData extends FieldValues>({
  name,
  formMethods,
  label,
  helptext,
  canEdit = true,
}: {
  name: Path<FormData>;
  formMethods: UseFormReturn<FormData>;
  label: string;
  helptext: string;
  canEdit?: boolean;
}) => {
  const options = [
    { label: "Report a problem", value: "Report a problem", sortOrder: 1 },
    { label: "Support center", value: "Support center", sortOrder: 2 },
    { label: "Other", value: "other", sortOrder: 3 },
  ];

  const { field } = useController({
    name,
    rules: {
      validate: (value) => {
        return !!value.trim() || "Please enter a support label";
      },
      required: "Please enter a support label",
      minLength: {
        value: 1,
        message: "Please enter a support label",
      },
      maxLength: {
        value: 30,
        message: "Support label cannot exceed 30 characters",
      },
    },
  });

  const isPresetName = !!options.find(({ value }) => field.value === value);

  const [selectedOption, setSelectedOption] = useState<string>(
    isPresetName ? field.value : "other",
  );

  // null means we are not in custom name mode
  // undefined means we are in custom name mode but no value has been entered
  // string means we are in custom name mode and a value has been entered
  const [customName, setCustomName] = useState<string | undefined>(
    isPresetName ? undefined : field.value,
  );

  const selectOnChange = (value: string) => {
    if (value !== "other") {
      field.onChange(value);
    }
    setCustomName(undefined);
    setSelectedOption(value);
  };

  const customNameOnChange = (value: string) => {
    setCustomName(value);
    field.onChange(value);
  };

  return (
    <FormInputWrapperV2
      {...formMethods}
      name={name}
      label={label}
      helptext={helptext}
    >
      <div className="space-y-2 w-[230px]">
        <StaticSingleSelect
          placeholder="Select a support label"
          options={options}
          value={selectedOption}
          disabled={!canEdit}
          onChange={(value) => value && selectOnChange(value)}
        />
        {selectedOption === "other" && (
          <Input
            placeholder='e.g. "Report a problem"'
            disabled={!canEdit}
            id="custom_support_label_input"
            value={customName ?? undefined}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              customNameOnChange(e.target.value);
            }}
          />
        )}
      </div>
    </FormInputWrapperV2>
  );
};
