import { ScopeNameEnum } from "@incident-io/api";
import { DurationMetricCreateEditModal } from "@incident-shared/durations/DurationMetricCreateEditModal";
import { useInitialiseInsightsFilters } from "@incident-shared/filters";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, IconEnum } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useState } from "react";
import { useQueryParams } from "src/utils/query-params";
import { tcx } from "src/utils/tailwind-classes";

import { SelectMetric } from "./SelectMetric";

export const FilterByDurationMetricDropdown = ({
  selectedDurationMetricID,
  setSelectedDurationMetricID,
  durationMetricOptions,
  refetchFilters,
  className,
}: {
  selectedDurationMetricID: string;
  setSelectedDurationMetricID: (newDurationMetric: string) => void;
  durationMetricOptions: SelectOption[];
  refetchFilters: () => void;
  className?: string;
}) => {
  // We need the timestamps for populating the create metric modal
  const urlParams = useQueryParams();
  const { timestamps } = useInitialiseInsightsFilters(urlParams.toString());

  const [showAddMetric, setShowAddMetric] = useState(false);

  return (
    <>
      {selectedDurationMetricID && (
        <div
          className={tcx(
            "flex items-center text-sm text-slate-700 gap-2",
            className,
          )}
        >
          <SelectMetric
            metrics={durationMetricOptions}
            selectedDurationMetricID={selectedDurationMetricID}
            setDurationMetric={setSelectedDurationMetricID}
          />
          <div className="flex items-center">
            <GatedButton
              icon={IconEnum.Add}
              analyticsTrackingId="add-duration-metric"
              theme={ButtonTheme.Naked}
              requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
              onClick={() => setShowAddMetric(true)}
            >
              Create new metric
            </GatedButton>
          </div>
        </div>
      )}
      {showAddMetric && (
        <DurationMetricCreateEditModal
          includeAdditionalContext
          mode={Mode.Create}
          onClose={() => {
            refetchFilters();
            setShowAddMetric(false);
          }}
          timestamps={timestamps}
        />
      )}
    </>
  );
};
