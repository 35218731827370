/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalendarEntry
 */
export interface CalendarEntry {
    /**
     * Incidents that had activity on this day
     * @type {Array<string>}
     * @memberof CalendarEntry
     */
    active_incident_external_ids: Array<string>;
    /**
     * Incidents that had activity on this day
     * @type {Array<string>}
     * @memberof CalendarEntry
     */
    active_incident_ids: Array<string>;
    /**
     * Incidents that had activity on this day
     * @type {Array<string>}
     * @memberof CalendarEntry
     */
    active_incident_names: Array<string>;
    /**
     * Date of the activity (in the given time zone)
     * @type {string}
     * @memberof CalendarEntry
     */
    activity_day: string;
    /**
     * Minutes spent working across incidents
     * @type {number}
     * @memberof CalendarEntry
     */
    minutes_spent: number;
    /**
     * Incidents that the user contributed to on this day
     * @type {Array<string>}
     * @memberof CalendarEntry
     */
    user_active_incident_external_ids: Array<string>;
    /**
     * Incidents that the user contributed to on this day
     * @type {Array<string>}
     * @memberof CalendarEntry
     */
    user_active_incident_ids: Array<string>;
    /**
     * Incidents that the user contributed to on this day
     * @type {Array<string>}
     * @memberof CalendarEntry
     */
    user_active_incident_names: Array<string>;
    /**
     * Minutes spent working across incidents by user
     * @type {number}
     * @memberof CalendarEntry
     */
    user_minutes_spent: number;
}

export function CalendarEntryFromJSON(json: any): CalendarEntry {
    return CalendarEntryFromJSONTyped(json, false);
}

export function CalendarEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active_incident_external_ids': json['active_incident_external_ids'],
        'active_incident_ids': json['active_incident_ids'],
        'active_incident_names': json['active_incident_names'],
        'activity_day': json['activity_day'],
        'minutes_spent': json['minutes_spent'],
        'user_active_incident_external_ids': json['user_active_incident_external_ids'],
        'user_active_incident_ids': json['user_active_incident_ids'],
        'user_active_incident_names': json['user_active_incident_names'],
        'user_minutes_spent': json['user_minutes_spent'],
    };
}

export function CalendarEntryToJSON(value?: CalendarEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_incident_external_ids': value.active_incident_external_ids,
        'active_incident_ids': value.active_incident_ids,
        'active_incident_names': value.active_incident_names,
        'activity_day': value.activity_day,
        'minutes_spent': value.minutes_spent,
        'user_active_incident_external_ids': value.user_active_incident_external_ids,
        'user_active_incident_ids': value.user_active_incident_ids,
        'user_active_incident_names': value.user_active_incident_names,
        'user_minutes_spent': value.user_minutes_spent,
    };
}

