import { IntegrationsServiceNowHandleInstallRequestBody as FormData } from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Button, ButtonTheme, ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

export const ConnectToServiceNowModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { identity } = useIdentity();
  const orgSlug = identity.organisation_slug;

  const formMethods = useForm<FormData>({});

  const { trigger, isMutating, genericError } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, data: FormData) =>
      await apiClient.integrationsServiceNowHandleInstall({
        handleInstallRequestBody: data,
      }),
    {
      onSuccess: onClose,
      setError: formMethods.setError,
    },
  );

  const { showArticle } = useIntercom();

  return (
    <FormModalV2
      onSubmit={trigger}
      onClose={onClose}
      title="Connect ServiceNow"
      analyticsTrackingId={"install-service-now"}
      saving={isMutating}
      genericError={genericError}
      formMethods={formMethods}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Connect"
          saving={isMutating}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="subdomain"
        label="Subdomain"
        helptext={
          <>
            If your ServiceNow instance is accessible on &lsquo;
            {orgSlug}.service-now.com&rsquo;, your subdomain is &lsquo;{orgSlug}
            &rsquo;.
          </>
        }
        placeholder={orgSlug}
        required
      />
      <InputV2
        formMethods={formMethods}
        name="oauth_client_id"
        label="OAuth Client ID"
        helptext={
          <>
            Follow{" "}
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Naked}
              onClick={() => showArticle(10132378)}
              className="underline transition"
            >
              our guide
            </Button>{" "}
            on how to create an OAuth app for incident.io
          </>
        }
        placeholder="000a0000b0c00de000f00a0000000000"
        required
      />
      <InputV2
        formMethods={formMethods}
        name="oauth_client_secret"
        type={InputType.Password}
        label="OAuth Client Secret"
        helptext={
          "This is generated by ServiceNow once you've created the OAuth app"
        }
        required
      />

      <InputV2
        formMethods={formMethods}
        name="username"
        label="Username"
        helptext={
          <>
            Follow{" "}
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Naked}
              onClick={() => showArticle(10132378)}
              className="underline transition"
            >
              our guide
            </Button>{" "}
            on how to create a user for incident.io inside ServiceNow
          </>
        }
        required
      />
      <InputV2
        formMethods={formMethods}
        name="password"
        type={InputType.Password}
        label="Password"
        helptext={"This must be generated after creating the user account."}
        required
      />
    </FormModalV2>
  );
};
