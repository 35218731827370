/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface PagerDutyIncidentTemplate
 */
export interface PagerDutyIncidentTemplate {
    /**
     * Custom field keys mapped to values
     * @type {{ [key: string]: EngineParamBinding; }}
     * @memberof PagerDutyIncidentTemplate
     */
    custom_fields?: { [key: string]: EngineParamBinding; };
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof PagerDutyIncidentTemplate
     */
    incident_type?: EngineParamBinding;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof PagerDutyIncidentTemplate
     */
    name?: EngineParamBinding;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof PagerDutyIncidentTemplate
     */
    severity?: EngineParamBinding;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof PagerDutyIncidentTemplate
     */
    summary?: EngineParamBinding;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof PagerDutyIncidentTemplate
     */
    workspace?: EngineParamBinding;
}

export function PagerDutyIncidentTemplateFromJSON(json: any): PagerDutyIncidentTemplate {
    return PagerDutyIncidentTemplateFromJSONTyped(json, false);
}

export function PagerDutyIncidentTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagerDutyIncidentTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_fields': !exists(json, 'custom_fields') ? undefined : (mapValues(json['custom_fields'], EngineParamBindingFromJSON)),
        'incident_type': !exists(json, 'incident_type') ? undefined : EngineParamBindingFromJSON(json['incident_type']),
        'name': !exists(json, 'name') ? undefined : EngineParamBindingFromJSON(json['name']),
        'severity': !exists(json, 'severity') ? undefined : EngineParamBindingFromJSON(json['severity']),
        'summary': !exists(json, 'summary') ? undefined : EngineParamBindingFromJSON(json['summary']),
        'workspace': !exists(json, 'workspace') ? undefined : EngineParamBindingFromJSON(json['workspace']),
    };
}

export function PagerDutyIncidentTemplateToJSON(value?: PagerDutyIncidentTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_fields': value.custom_fields === undefined ? undefined : (mapValues(value.custom_fields, EngineParamBindingToJSON)),
        'incident_type': EngineParamBindingToJSON(value.incident_type),
        'name': EngineParamBindingToJSON(value.name),
        'severity': EngineParamBindingToJSON(value.severity),
        'summary': EngineParamBindingToJSON(value.summary),
        'workspace': EngineParamBindingToJSON(value.workspace),
    };
}

