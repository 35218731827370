import { Icon, IconEnum } from "@incident-ui";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export const SplitByPostIncidentFlow = ({
  postIncidentFlowOptions,
  selectedPostIncidentFlowID,
  setSelectedPostIncidentFlowID,
  label,
}: {
  postIncidentFlowOptions: SelectOption[];
  selectedPostIncidentFlowID: string;
  setSelectedPostIncidentFlowID: (value: string) => void;
  label?: React.ReactNode;
}): React.ReactElement => {
  return (
    <div className="flex items-center">
      {label ?? (
        <span className="text-sm text-slate-700 mr-2 flex-shrink-0">
          For flow
        </span>
      )}
      <StaticSingleSelect
        placeholder="Choose field"
        options={postIncidentFlowOptions.map((field) => ({
          value: field.value,
          label: field.label,
          renderFn: ({ isSelected }) => (
            <div className={tcx("flex text-sm mt-0.5 items-center")}>
              <Icon
                id={IconEnum.Checklist}
                className={tcx(
                  "shrink-0",
                  isSelected ? "text-slate-200" : "text-content-tertiary",
                )}
              />
              <span className="ml-1 mr-2 flex-wrap">{field.label}</span>
            </div>
          ),
        }))}
        className="!text-slate-700 min-w-[200px]"
        value={selectedPostIncidentFlowID}
        onChange={(value) => {
          value && setSelectedPostIncidentFlowID(value);
        }}
        isClearable={false}
      />
    </div>
  );
};
