import { AIConfigEnabledFeaturesEnum } from "@incident-io/api";
import { Button, IconSize } from "@incident-ui";
import { useAIFeatureForOrg } from "src/hooks/useAI";
import { tcx } from "src/utils/tailwind-classes";

import { AssistantAvatar, AssistantAvatarBackground } from "./AssistantAvatar";
import styles from "./AssistantAvatar.module.scss";
import { useAssistantOverlay } from "./AssistantOverlayContext";
import { useAssistantThreadContext } from "./AssistantThreadContext";

// AssistantBanner is an interactive banner that sits at the top of pages to promote Assistant.
export const AssistantBannerV2 = () => {
  const canUseAI = useAIFeatureForOrg();
  const { toggleOverlay } = useAssistantOverlay();
  const assistantThreadContext = useAssistantThreadContext();

  if (!assistantThreadContext) {
    return null;
  }

  if (!canUseAI(AIConfigEnabledFeaturesEnum.Assistant)) {
    return null;
  }

  return (
    <div className={tcx("flex rounded-2 bg-black", styles.rainbowSoft)}>
      <div className="flex justify-between items-center p-4 rounded-[6px] w-full">
        <div className="">
          <div className="flex items-center">
            <AssistantAvatar
              size={IconSize.Medium}
              className={
                "!p-0 !rounded w-7 h-7 flex items-center justify-center"
              }
              iconClassName="fill-white"
              background={AssistantAvatarBackground.Purple}
            />
            <h3 className="font-semibold ml-2">Assistant</h3>
          </div>
          <p className="text-sm font-normal mt-3">
            Use our AI assistant to understand historical trends, identify
            patterns, and build your own charts.
          </p>
        </div>
        <Button
          analyticsTrackingId={"open-assistant"}
          title="Ask Assistant"
          className={styles.rainbowButton}
          onClick={() => toggleOverlay()}
        >
          {/* Unsure why, but this 'z-0' is needed for the text to be
              shown on the button with this style */}
          <span className="z-0">Ask Assistant</span>
        </Button>
      </div>
    </div>
  );
};
