import { PlanNameEnum as PlanNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useQueryParams } from "src/utils/query-params";

const AE_DRIVEN_PLANS = [
  PlanNameEnum.ProV1,
  PlanNameEnum.ProV2,
  PlanNameEnum.EnterpriseV1,
  PlanNameEnum.EnterpriseV2,
];

export const useIsAEDriven = () => {
  const { identity, outsideOfProvider } = useIdentity();
  const queryParams = useQueryParams();

  if (outsideOfProvider === "outside-of-IdentityProvider") {
    // If we've not authed yet, we can figure out if this is an AE driven flow
    // from the query params
    const queryIsAEDriven = ["pro", "enterprise"].some(
      (search) => queryParams.get("plan")?.includes(search),
    );

    return queryIsAEDriven;
  }

  // Otherwise, we can use the identity
  return AE_DRIVEN_PLANS.includes(
    identity.onboarding_information.desired_plan as unknown as PlanNameEnum,
  );
};
