import {
  GenericErrorMessage,
  Interpose,
  OrgAwareLink,
  Txt,
} from "@incident-ui";
import { last } from "lodash";
import {
  IncidentLifecycle,
  IncidentStatus,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { PostIncidentFlowExpressionPreview } from "../../post-incident/PostIncidentFlowExpressionPreview";
import { PostIncidentFlowSection } from "../../post-incident/PostIncidentFlowSection";
import { useLifecycleOverview } from "../LifecycleOverviewContext";
import { HorizontalLine, Pill } from "../LifecycleUIElements";
import { PreparedStatusWithTimestamp } from "../prepareLifecycle";
import { prefillEnteringStatus } from "../TimestampSetByRuleAddPopover";
import { TimestampsOnStatusChange } from "../TimestampsOnStatusChange";
import { LifecycleOverviewSection } from "./LifecycleOverviewSection";

export const PostIncidentSection = ({
  lifecycle,
  preparedStatuses,
  closedStatus,
}: {
  lifecycle: IncidentLifecycle;
  preparedStatuses: PreparedStatusWithTimestamp[];
  closedStatus: IncidentStatus;
}): React.ReactElement | null => {
  // Separate timestamps for the last post-incident status based on whether they are
  // set on leaving the post-incident status or entering closing. We want to show the
  // entering closing timestamps in a position on the flow that shows that
  // they are set regardless of if the post-incident flow is opted-into.
  const exitingLastStatusTimestamps =
    last(preparedStatuses)?.onEnteringNextStatusTimestamps || [];

  const { getConfigurationState } = useLifecycleOverview();
  const state = getConfigurationState(StatusCategoryEnum.PostIncident);
  const isBackground = state === "background";

  const {
    data: { post_incident_flows: flows },
    isLoading: flowsLoading,
    error: flowsError,
  } = useAPI("postIncidentFlowList", undefined, {
    fallbackData: { post_incident_flows: [] },
  });

  if (flowsLoading) {
    return null;
  }
  if (flowsError) {
    return <GenericErrorMessage error={flowsError} />;
  }

  const hasAtLeastOneFlow = flows.length > 0;

  return (
    <LifecycleOverviewSection
      title="Post-incident"
      description="Use a post-incident flow to help responders learn from incidents, and do other clean-up tasks once the incident is resolved."
      category={StatusCategoryEnum.PostIncident}
      diagram={
        lifecycle.post_incident_flow_enabled ? (
          <div className="space-y-4">
            <Interpose separator={<HorizontalLine className="w-11 shrink-0" />}>
              <PostIncidentFlowExpressionPreview
                lifecycle={lifecycle}
                flows={flows}
              />
            </Interpose>
          </div>
        ) : (
          <Pill className="!block !w-auto max-w-lg" disabled={isBackground}>
            <PostIncidentFlowCTA hasAtLeastOneFlow={hasAtLeastOneFlow} />
          </Pill>
        )
      }
      configurationNode={
        hasAtLeastOneFlow ? (
          <PostIncidentFlowSection lifecycle={lifecycle} />
        ) : undefined
      }
      bottomBorderNode={
        <TimestampsOnStatusChange
          disabled={isBackground}
          timestamps={exitingLastStatusTimestamps}
          setByRulePrefill={prefillEnteringStatus(closedStatus.id)}
          betweenStatuses={[
            last(preparedStatuses)?.status as IncidentStatus,
            closedStatus,
          ]}
        />
      }
    />
  );
};

const PostIncidentFlowCTA = ({
  hasAtLeastOneFlow,
}: {
  hasAtLeastOneFlow: boolean;
}) => {
  if (hasAtLeastOneFlow) {
    return (
      <Txt grey>
        There&apos;s no post-incident flow configured for this lifecycle.
        <br />
        All incidents will go directly from Active to Closed.
      </Txt>
    );
  }

  return (
    <Txt grey>
      You don&apos;t have any post-incident flows configured. <br />
      Create one in{" "}
      <OrgAwareLink to="/settings/post-incident-flow" className="underline">
        Settings → Post-incident flows
      </OrgAwareLink>
    </Txt>
  );
};
