import {
  ConditionSubjectIconEnum,
  Resource,
  Trigger,
  Workflow,
  WorkflowsShowTriggerResponseBody,
} from "@incident-io/api";
import { useContext, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { ErrorResponse } from "src/contexts/ClientContext";
import { lookupInScope } from "src/utils/scope";
import { useAPI } from "src/utils/swr";

import { referenceToFormOnceFor } from "../../common/marshall";
import { ClonedWorkflow, WorkflowFormData } from "../../common/types";
import { WorkflowsFormContext } from "../WorkflowsFormContext";
import { RightDrawerState } from "./useViewController";

export type TriggerCallbacks = {
  // This is the trigger name that the user has chosen. When you choose a
  // trigger, this will be set before the trigger data is loaded.
  triggerName?: string;
  // This is all the data about the trigger, fetched from the API.
  trigger?: Trigger;
  triggerLoading: boolean;
  onEditTrigger: () => void;
  onChooseTrigger: (name: string) => void;
};

type ManageTriggerReturn = {
  triggerError?: ErrorResponse;
  triggerCallbacks: TriggerCallbacks;
};

export const useTriggerController = ({
  formMethods,
  workflow,
  template,
  resources,
  onOpenRightDrawer,
  onCloseRightDrawer,
}: {
  formMethods: UseFormReturn<WorkflowFormData>;
  workflow?: Workflow | ClonedWorkflow;
  template?: Workflow | ClonedWorkflow;
  resources: Resource[];
  onOpenRightDrawer: (drawer: RightDrawerState) => void;
  onCloseRightDrawer: () => void;
}): ManageTriggerReturn => {
  // This stores the trigger that a user has chosen, so that we can load
  // the associated data below.
  const [triggerName, setTriggerName] = useState<string | undefined>(
    workflow?.trigger.name || template?.trigger.name,
  );

  // BE CAREFUL HERE - this controls setting the "once_for" for new triggers.
  // If we mess this up, workflows will duplicate if once_for is not set correctly.
  // When we change the trigger we want to ensure we're resetting the once_for to a sensible value.
  // It must be true on first page load, so that we set it on initial trigger selection.
  const [isChangingTrigger, setIsChangingTrigger] = useState(
    !workflow && !template,
  );

  const onEditTrigger = () => {
    // Reset all the fields dependent on trigger, using shouldDirty so
    // we know our state is dirty!
    formMethods.setValue<"step_groups">("step_groups", [], {
      shouldDirty: true,
    });
    formMethods.setValue<"condition_groups">("condition_groups", [], {
      shouldDirty: true,
    });
    formMethods.setValue<"once_for">("once_for", [], { shouldDirty: true });
    formMethods.setValue<"expressions">("expressions", [], {
      shouldDirty: true,
    });

    // Empty our state
    setTriggerName(undefined);
    // Tell us that we are changing the trigger, so we reset the once_for when
    // we choose a new trigger
    setIsChangingTrigger(true);
    onOpenRightDrawer(RightDrawerState.ChooseTrigger);
  };

  const onChooseTrigger = (trigger: string) => {
    setTriggerName(trigger);
    onCloseRightDrawer();
  };

  const { setValue } = formMethods;
  // Reload the trigger data when the trigger name changes.
  const {
    data: triggerData,
    error: triggerError,
    isLoading: triggerLoading,
  } = useAPI(
    // NOTE: Conditionally resolve the trigger, only if we have a trigger name set.
    triggerName ? "workflowsShowTrigger" : null,
    {
      name: triggerName ?? "",
    },
    {
      onSuccess: (data: WorkflowsShowTriggerResponseBody) => {
        // If the trigger has been changed, we need to update the once for
        // to the trigger's default once for values.
        if (isChangingTrigger) {
          setValue<"once_for">(
            "once_for",
            data.trigger.default_once_for.map(referenceToFormOnceFor),
          );

          // scope includes the ref "incident.status.category", so let's comb
          // through the trigger's scope to find out if we can apply the desired
          // default condition i.e. incident status category is one of ["active"].
          const incidentStatusCategory = lookupInScope(
            data.trigger.scope,
            "incident.status.category",
          );
          if (incidentStatusCategory) {
            const subject = incidentStatusCategory;
            const resource = resources.find(
              (resource) => resource.type === subject.type,
            );
            const desiredOperation = resource?.operations.find(
              (operation) => operation.name === "one_of",
            );
            const desiredOption = resource?.options?.find(
              (option) => option.value === "active",
            );

            if (subject && desiredOperation && desiredOption) {
              setValue<"condition_groups">("condition_groups", [
                {
                  conditions: [
                    {
                      subject: {
                        label: subject.label,
                        icon: ConditionSubjectIconEnum.Status,
                        reference: subject.key,
                      },
                      operation: {
                        label: desiredOperation?.label || "",
                        value: desiredOperation?.name || "",
                      },
                      params: desiredOperation?.params || [],
                      param_bindings: [
                        {
                          array_value: [
                            {
                              label: desiredOption?.label || "",
                              sort_key: desiredOption?.sort_key || "",
                              literal: desiredOption?.value || "",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ]);
            }
          }

          setIsChangingTrigger(false);
        }
      },
    },
  );

  return {
    triggerCallbacks: {
      triggerName,
      trigger: triggerData?.trigger,
      onChooseTrigger,
      onEditTrigger,
      triggerLoading,
    },
    // This is handled up high, so we keep it separate (and don't put it in the context) to avoid confusion
    triggerError,
  };
};

export const useWorkflowsTrigger = (): TriggerCallbacks => {
  const ctx = useContext(WorkflowsFormContext);
  if (ctx && ctx.triggerCallbacks) {
    return ctx.triggerCallbacks;
  }
  throw new Error(
    "useWorkflowsTriggers must be used within a WorkflowsFormProvider",
  );
};
