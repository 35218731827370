import {
  Alert,
  AlertRoutesPreviewIncidentResponseBody,
  AnnouncementPostFieldParamWithPreviewFieldTypeEnum,
  CustomField,
  EngineParamBindingPayload,
  EngineParamBindingValue,
} from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import {
  SlackChannelMessagePreview,
  SlackMessagePreview,
  Txt,
} from "@incident-ui";
import React from "react";
import { AnnouncementPreviewField } from "src/components/settings/announcements/common/AnnouncementPostPreview";

export const AlertRouteIncidentPreview = ({
  alert,
  incidentPreview,
  customFields,
  customFieldValues,
  alertRouteName,
  incidentTypeLabel,
  severityLabel,
}: {
  alert?: Alert;
  incidentPreview?: AlertRoutesPreviewIncidentResponseBody;
  customFields: CustomField[];
  customFieldValues?: {
    [key: string]: EngineParamBindingPayload;
  };
  alertRouteName: string;
  incidentTypeLabel?: string;
  severityLabel?: string;
}): React.ReactElement => {
  if (!alert || !incidentPreview) {
    return (
      <IncidentPreviewEmptyState
        alertRouteName={alertRouteName}
        customFields={customFields}
        customFieldValues={customFieldValues}
        incidentTypeLabel={incidentTypeLabel}
        severityLabel={severityLabel}
      />
    );
  }

  const title = incidentPreview.name || alert.title;
  const truncatedTitle =
    title.length > 150 ? `${title.substring(0, 150)}...` : title;

  return (
    <div className="flex flex-col gap-2">
      <div className="text-sm-med text-content-tertiary">Slack preview</div>
      <SlackMessagePreview>
        <span className="font-['lato'] !text-xl">⏳</span>
        <span className="font-['lato'] font-bold !text-xl break-words">
          {truncatedTitle}
        </span>
        {incidentPreview.incident_mode === "test" ? (
          <span className="bg-[#1d9bd11a] text-[#1264a3] border-stroke border-[0.01rem] rounded-full py-0.5 px-2 ml-2">
            Test
          </span>
        ) : null}
        {incidentPreview.summary && (
          <>
            <HorizontalDivider />
            <TemplatedTextDisplay
              value={incidentPreview.summary.text_node}
              style={TemplatedTextDisplayStyle.Compact}
            />
          </>
        )}
        <HorizontalDivider />
        {incidentPreview.incident_type && (
          <AnnouncementPreviewField
            key={"incident_type"}
            field={{
              id: incidentPreview.incident_type.id,
              rank: incidentPreview.incident_type.rank,
              title: "Type",
              emoji: "control_knobs",
              preview_example: incidentPreview.incident_type.name,
              field_type:
                AnnouncementPostFieldParamWithPreviewFieldTypeEnum.IncidentType,
            }}
          />
        )}
        {incidentPreview.severity && (
          <AnnouncementPreviewField
            key={"severity"}
            field={{
              id: incidentPreview.severity.id,
              rank: incidentPreview.severity.rank,
              title: "Severity",
              emoji: "fire",
              preview_example: incidentPreview.severity.name,
              field_type:
                AnnouncementPostFieldParamWithPreviewFieldTypeEnum.Severity,
            }}
          />
        )}
        <CustomFieldsPreview
          customFields={customFields}
          customFieldValues={customFieldValues}
        />
      </SlackMessagePreview>
    </div>
  );
};

const HorizontalDivider = () => (
  <div className="flex-row bg-slate-300 h-[1px] !my-4"></div>
);

const IncidentPreviewEmptyState = ({
  alertRouteName,
  customFields,
  customFieldValues,
  incidentTypeLabel,
  severityLabel,
}: {
  alertRouteName: string;
  customFields: CustomField[];
  customFieldValues?: {
    [key: string]: EngineParamBindingPayload;
  };
  incidentTypeLabel?: string;
  severityLabel?: string;
}) => {
  return (
    <div>
      <SlackChannelMessagePreview>
        <span className="font-['lato'] !text-xl">⏳</span>
        <span className="font-['lato'] font-bold !text-xl break-words">
          Incident created for {alertRouteName}
        </span>
        <>
          <HorizontalDivider />
          <Txt>
            This is a default summary created for this incident preview as we
            have not received an alert for this alert route yet.
          </Txt>
        </>
        <HorizontalDivider />
        <AnnouncementPreviewField
          key={"incident_type"}
          field={{
            id: "incident_type_id",
            rank: 1,
            title: "Type",
            emoji: "control_knobs",
            preview_example: incidentTypeLabel || "Default",
            field_type:
              AnnouncementPostFieldParamWithPreviewFieldTypeEnum.IncidentType,
          }}
        />
        {/* Severities are optional for Triage incidents which alerts create so only show if we have one */}
        {severityLabel && (
          <AnnouncementPreviewField
            key={"severity"}
            field={{
              id: "severity_id",
              rank: 0,
              title: "Severity",
              emoji: "fire",
              preview_example: severityLabel,
              field_type:
                AnnouncementPostFieldParamWithPreviewFieldTypeEnum.Severity,
            }}
          />
        )}
        <CustomFieldsPreview
          customFields={customFields}
          customFieldValues={customFieldValues}
        />
      </SlackChannelMessagePreview>
    </div>
  );
};

const CustomFieldsPreview = ({
  customFields,
  customFieldValues,
}: {
  customFields: CustomField[];
  customFieldValues?: {
    [key: string]: EngineParamBindingPayload;
  };
}) => {
  if (!customFieldValues) {
    return null;
  }

  return (
    <>
      {Object.entries(customFieldValues).map(([fieldId, paramBinding]) => {
        // We only have the ID of the custom field, so get the full thing
        const cf = customFields.find((cf) => cf.id === fieldId);
        if (!cf) return null;

        // Get the actual value of this field
        const value = (paramBinding?.value ||
          (paramBinding?.array_value &&
            paramBinding?.array_value[0])) as EngineParamBindingValue;

        if (!value?.label) {
          return null;
        }

        return (
          <AnnouncementPreviewField
            key={fieldId}
            field={{
              title: cf.name,
              emoji: "label",
              preview_example: `\`${value.label}\``,
              id: cf.id,
              rank: cf.rank,
              field_type:
                AnnouncementPostFieldParamWithPreviewFieldTypeEnum.CustomField,
            }}
          />
        );
      })}
    </>
  );
};
