import { useContext } from "react";

import { IInsightsContext, InsightsContext } from "./InsightsContext";
import { FieldConfig, FieldKey } from "./types";

export const useInsightsContext = <
  StateConfig extends Record<FieldKey, FieldConfig>,
>(): IInsightsContext<StateConfig> => {
  const context = useContext(InsightsContext);
  if (!context) {
    throw new Error(
      "useInsightsContext must be used within InsightsContextProvider",
    );
  }
  return context;
};

export const useIsInInsightsContext = (): boolean => {
  const context = useContext(InsightsContext);
  return context != null;
};
