import { ScopeNameEnum } from "@incident-io/api";
import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import {
  Callout,
  CalloutTheme,
  Input,
  StepsPageContent,
  StepsPageFooter,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import {
  differenceInCalendarDays,
  differenceInHours,
  isAfter,
  isValid,
} from "date-fns";
import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useIdentity } from "src/contexts/IdentityContext";

import { parseSimpleDate, ReportGeneratorFormType } from "./ReportGenerator";
import { TimezoneHelperText } from "./TimezoneHelperToolTip";

// ReportGeneratorChooseWindow allows you to choose the window
// you want to calculate your report over. In future, it may
// include a prompt to add holidays to your report.
export const ReportGeneratorChooseWindow = ({
  register,
  errors,
  startDate,
  endDate,
  onContinue,
}: {
  register: UseFormRegister<ReportGeneratorFormType>;
  errors: FieldErrors<ReportGeneratorFormType>;
  startDate?: string;
  endDate?: string;
  onContinue: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();

  let startDateAfterEndDate = false;
  let reportNumberOfDays = 0;

  if (startDate != null && endDate != null) {
    const startDateAsDate = parseSimpleDate(startDate);
    const endDateAsDate = parseSimpleDate(endDate);
    startDateAfterEndDate = isAfter(startDateAsDate, endDateAsDate);

    // we need to add 1 as our dates are inclusive
    reportNumberOfDays =
      differenceInCalendarDays(endDateAsDate, startDateAsDate) + 1;
  }

  return (
    <>
      <StepsPageContent className="space-y-4">
        <FormInputWrapper
          label="What should this report be called?"
          id="name"
          errors={errors}
        >
          <Input
            id="name"
            required
            placeholder="e.g. May '22 platform on-call report"
            {...register("name", {
              required: "Please choose a name",
            })}
          />
        </FormInputWrapper>

        <FormInputWrapper
          label="Choose the first full day which should be included in the report"
          helptext={<TimezoneHelperText />}
          id="start_date"
          errors={errors}
        >
          <Input
            type={InputType.Date}
            id="start_date"
            required
            {...register("start_date", {
              required: "Please select a start date",
            })}
          />
        </FormInputWrapper>
        <FormInputWrapper
          label="Choose the last full day which should be included in the report"
          helptext={<TimezoneHelperText />}
          id="end_date"
          errors={errors}
        >
          <Input
            type={InputType.Date}
            id="end_date"
            {...register("end_date", {
              validate: {
                invalid: (dateStr: string): string | true =>
                  isValid(parseSimpleDate(dateStr))
                    ? true
                    : "Please choose a valid date",
                // We want to make sure we've got the schedule data before trying to create a report.
                in_future: (dateStr: string): string | true =>
                  differenceInHours(new Date(), parseSimpleDate(dateStr)) > 25
                    ? true
                    : "Please choose a date in the past.",
              },
            })}
          />
        </FormInputWrapper>
        <div>
          {startDate && endDate && startDateAfterEndDate ? (
            <Callout theme={CalloutTheme.Warning}>
              Please select an end date which is after your start date.
            </Callout>
          ) : reportNumberOfDays != null && !isNaN(reportNumberOfDays) ? (
            <Callout theme={CalloutTheme.Plain} className="text-sm mb-1 w-full">
              The report will cover <strong>{reportNumberOfDays}</strong> full
              day
              {reportNumberOfDays > 1 ? "s" : ""}. If this doesn&apos;t look
              right, adjust the dates above.
            </Callout>
          ) : null}
        </div>
      </StepsPageContent>
      <StepsPageFooter
        onContinue={onContinue}
        continueButtonText="Continue"
        continueDisabled={!hasScope(ScopeNameEnum.ScheduleReportsCreate)}
        continueExplanationText={
          !hasScope(ScopeNameEnum.ScheduleReportsCreate)
            ? "You do not have permission to create reports."
            : undefined
        }
      />
    </>
  );
};
