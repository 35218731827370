/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IssueTrackersAsanaCreateIssueRequestBody,
    IssueTrackersAsanaCreateIssueRequestBodyFromJSON,
    IssueTrackersAsanaCreateIssueRequestBodyToJSON,
    IssueTrackersAsanaCreateIssueResponseBody,
    IssueTrackersAsanaCreateIssueResponseBodyFromJSON,
    IssueTrackersAsanaCreateIssueResponseBodyToJSON,
    IssueTrackersAsanaTypeaheadOptionsResponseBody,
    IssueTrackersAsanaTypeaheadOptionsResponseBodyFromJSON,
    IssueTrackersAsanaTypeaheadOptionsResponseBodyToJSON,
    IssueTrackersClickUpCreateIssueRequestBody,
    IssueTrackersClickUpCreateIssueRequestBodyFromJSON,
    IssueTrackersClickUpCreateIssueRequestBodyToJSON,
    IssueTrackersClickUpCreateIssueResponseBody,
    IssueTrackersClickUpCreateIssueResponseBodyFromJSON,
    IssueTrackersClickUpCreateIssueResponseBodyToJSON,
    IssueTrackersClickUpTypeaheadOptionsResponseBody,
    IssueTrackersClickUpTypeaheadOptionsResponseBodyFromJSON,
    IssueTrackersClickUpTypeaheadOptionsResponseBodyToJSON,
    IssueTrackersGetAllIssueTrackerIssueSyncFailuresResponseBody,
    IssueTrackersGetAllIssueTrackerIssueSyncFailuresResponseBodyFromJSON,
    IssueTrackersGetAllIssueTrackerIssueSyncFailuresResponseBodyToJSON,
    IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBody,
    IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBodyFromJSON,
    IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBodyToJSON,
    IssueTrackersGetIssueTrackerSyncConfigResponseBody,
    IssueTrackersGetIssueTrackerSyncConfigResponseBodyFromJSON,
    IssueTrackersGetIssueTrackerSyncConfigResponseBodyToJSON,
    IssueTrackersGitLabCreateIssueRequestBody,
    IssueTrackersGitLabCreateIssueRequestBodyFromJSON,
    IssueTrackersGitLabCreateIssueRequestBodyToJSON,
    IssueTrackersGitLabCreateIssueResponseBody,
    IssueTrackersGitLabCreateIssueResponseBodyFromJSON,
    IssueTrackersGitLabCreateIssueResponseBodyToJSON,
    IssueTrackersGitLabTypeaheadOptionsResponseBody,
    IssueTrackersGitLabTypeaheadOptionsResponseBodyFromJSON,
    IssueTrackersGitLabTypeaheadOptionsResponseBodyToJSON,
    IssueTrackersJiraCreateIssueRequestBody,
    IssueTrackersJiraCreateIssueRequestBodyFromJSON,
    IssueTrackersJiraCreateIssueRequestBodyToJSON,
    IssueTrackersJiraCreateIssueResponseBody,
    IssueTrackersJiraCreateIssueResponseBodyFromJSON,
    IssueTrackersJiraCreateIssueResponseBodyToJSON,
    IssueTrackersJiraCreateIssueTemplateRequestBody,
    IssueTrackersJiraCreateIssueTemplateRequestBodyFromJSON,
    IssueTrackersJiraCreateIssueTemplateRequestBodyToJSON,
    IssueTrackersJiraCreateIssueTemplateResponseBody,
    IssueTrackersJiraCreateIssueTemplateResponseBodyFromJSON,
    IssueTrackersJiraCreateIssueTemplateResponseBodyToJSON,
    IssueTrackersJiraGetCreateIssueFieldsResponseBody,
    IssueTrackersJiraGetCreateIssueFieldsResponseBodyFromJSON,
    IssueTrackersJiraGetCreateIssueFieldsResponseBodyToJSON,
    IssueTrackersJiraGetIssueTemplateForFollowUpResponseBody,
    IssueTrackersJiraGetIssueTemplateForFollowUpResponseBodyFromJSON,
    IssueTrackersJiraGetIssueTemplateForFollowUpResponseBodyToJSON,
    IssueTrackersJiraGetIssueTemplateResponseBody,
    IssueTrackersJiraGetIssueTemplateResponseBodyFromJSON,
    IssueTrackersJiraGetIssueTemplateResponseBodyToJSON,
    IssueTrackersJiraServerGetCreateIssueFieldsResponseBody,
    IssueTrackersJiraServerGetCreateIssueFieldsResponseBodyFromJSON,
    IssueTrackersJiraServerGetCreateIssueFieldsResponseBodyToJSON,
    IssueTrackersJiraServerTypeaheadOptionsResponseBody,
    IssueTrackersJiraServerTypeaheadOptionsResponseBodyFromJSON,
    IssueTrackersJiraServerTypeaheadOptionsResponseBodyToJSON,
    IssueTrackersJiraTypeaheadOptionsResponseBody,
    IssueTrackersJiraTypeaheadOptionsResponseBodyFromJSON,
    IssueTrackersJiraTypeaheadOptionsResponseBodyToJSON,
    IssueTrackersJiraUpdateIssueTemplateRequestBody,
    IssueTrackersJiraUpdateIssueTemplateRequestBodyFromJSON,
    IssueTrackersJiraUpdateIssueTemplateRequestBodyToJSON,
    IssueTrackersJiraUpdateIssueTemplateResponseBody,
    IssueTrackersJiraUpdateIssueTemplateResponseBodyFromJSON,
    IssueTrackersJiraUpdateIssueTemplateResponseBodyToJSON,
    IssueTrackersLinearCreateIssueRequestBody,
    IssueTrackersLinearCreateIssueRequestBodyFromJSON,
    IssueTrackersLinearCreateIssueRequestBodyToJSON,
    IssueTrackersLinearCreateIssueResponseBody,
    IssueTrackersLinearCreateIssueResponseBodyFromJSON,
    IssueTrackersLinearCreateIssueResponseBodyToJSON,
    IssueTrackersLinearCreateIssueTemplateRequestBody,
    IssueTrackersLinearCreateIssueTemplateRequestBodyFromJSON,
    IssueTrackersLinearCreateIssueTemplateRequestBodyToJSON,
    IssueTrackersLinearCreateIssueTemplateResponseBody,
    IssueTrackersLinearCreateIssueTemplateResponseBodyFromJSON,
    IssueTrackersLinearCreateIssueTemplateResponseBodyToJSON,
    IssueTrackersLinearGetIssueTemplateForFollowUpResponseBody,
    IssueTrackersLinearGetIssueTemplateForFollowUpResponseBodyFromJSON,
    IssueTrackersLinearGetIssueTemplateForFollowUpResponseBodyToJSON,
    IssueTrackersLinearGetIssueTemplateResponseBody,
    IssueTrackersLinearGetIssueTemplateResponseBodyFromJSON,
    IssueTrackersLinearGetIssueTemplateResponseBodyToJSON,
    IssueTrackersLinearTypeaheadOptionsResponseBody,
    IssueTrackersLinearTypeaheadOptionsResponseBodyFromJSON,
    IssueTrackersLinearTypeaheadOptionsResponseBodyToJSON,
    IssueTrackersLinearUpdateIssueTemplateV2RequestBody,
    IssueTrackersLinearUpdateIssueTemplateV2RequestBodyFromJSON,
    IssueTrackersLinearUpdateIssueTemplateV2RequestBodyToJSON,
    IssueTrackersLinearUpdateIssueTemplateV2ResponseBody,
    IssueTrackersLinearUpdateIssueTemplateV2ResponseBodyFromJSON,
    IssueTrackersLinearUpdateIssueTemplateV2ResponseBodyToJSON,
    IssueTrackersShortcutCreateIssueRequestBody,
    IssueTrackersShortcutCreateIssueRequestBodyFromJSON,
    IssueTrackersShortcutCreateIssueRequestBodyToJSON,
    IssueTrackersShortcutCreateIssueResponseBody,
    IssueTrackersShortcutCreateIssueResponseBodyFromJSON,
    IssueTrackersShortcutCreateIssueResponseBodyToJSON,
    IssueTrackersShortcutTypeaheadOptionsResponseBody,
    IssueTrackersShortcutTypeaheadOptionsResponseBodyFromJSON,
    IssueTrackersShortcutTypeaheadOptionsResponseBodyToJSON,
    IssueTrackersUpdateIssueTrackerSyncConfigRequestBody,
    IssueTrackersUpdateIssueTrackerSyncConfigRequestBodyFromJSON,
    IssueTrackersUpdateIssueTrackerSyncConfigRequestBodyToJSON,
    IssueTrackersUpdateIssueTrackerSyncConfigResponseBody,
    IssueTrackersUpdateIssueTrackerSyncConfigResponseBodyFromJSON,
    IssueTrackersUpdateIssueTrackerSyncConfigResponseBodyToJSON,
} from '../models';

export interface IssueTrackersAsanaCreateIssueRequest {
    asanaCreateIssueRequestBody: IssueTrackersAsanaCreateIssueRequestBody;
}

export interface IssueTrackersAsanaTypeaheadOptionsRequest {
    field: IssueTrackersAsanaTypeaheadOptionsFieldEnum;
    teamId?: string;
}

export interface IssueTrackersClickUpCreateIssueRequest {
    clickUpCreateIssueRequestBody: IssueTrackersClickUpCreateIssueRequestBody;
}

export interface IssueTrackersClickUpTypeaheadOptionsRequest {
    field: IssueTrackersClickUpTypeaheadOptionsFieldEnum;
    teamId?: string;
    spaceId?: string;
    folderId?: string;
    listId?: string;
}

export interface IssueTrackersGetAllIssueTrackerIssueSyncFailuresRequest {
    issueTrackerProvider?: IssueTrackersGetAllIssueTrackerIssueSyncFailuresIssueTrackerProviderEnum;
}

export interface IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresRequest {
    incidentId: string;
}

export interface IssueTrackersGetIssueTrackerSyncConfigRequest {
    context: IssueTrackersGetIssueTrackerSyncConfigContextEnum;
}

export interface IssueTrackersGitLabCreateIssueRequest {
    gitLabCreateIssueRequestBody: IssueTrackersGitLabCreateIssueRequestBody;
}

export interface IssueTrackersGitLabTypeaheadOptionsRequest {
    field: IssueTrackersGitLabTypeaheadOptionsFieldEnum;
    repo?: string;
}

export interface IssueTrackersJiraCreateIssueRequest {
    jiraCreateIssueRequestBody: IssueTrackersJiraCreateIssueRequestBody;
}

export interface IssueTrackersJiraCreateIssueTemplateRequest {
    jiraCreateIssueTemplateRequestBody: IssueTrackersJiraCreateIssueTemplateRequestBody;
}

export interface IssueTrackersJiraGetCreateIssueFieldsRequest {
    siteId: string;
    projectId: string;
    issueTypeId: string;
    followUpId?: string;
}

export interface IssueTrackersJiraGetIssueTemplateRequest {
    id: string;
}

export interface IssueTrackersJiraGetIssueTemplateForFollowUpRequest {
    followUpId: string;
    issueTemplateId: string;
}

export interface IssueTrackersJiraServerGetCreateIssueFieldsRequest {
    projectId: string;
    issueTypeId: string;
    followUpId?: string;
}

export interface IssueTrackersJiraServerTypeaheadOptionsRequest {
    field: IssueTrackersJiraServerTypeaheadOptionsFieldEnum;
    projectId?: string;
    issueTypeId?: string;
    query?: string;
}

export interface IssueTrackersJiraTypeaheadOptionsRequest {
    field: IssueTrackersJiraTypeaheadOptionsFieldEnum;
    siteId: string;
    projectId?: string;
    issueTypeId?: string;
    query?: string;
}

export interface IssueTrackersJiraUpdateIssueTemplateRequest {
    id: string;
    jiraUpdateIssueTemplateRequestBody: IssueTrackersJiraUpdateIssueTemplateRequestBody;
}

export interface IssueTrackersLinearCreateIssueRequest {
    linearCreateIssueRequestBody: IssueTrackersLinearCreateIssueRequestBody;
}

export interface IssueTrackersLinearCreateIssueTemplateRequest {
    linearCreateIssueTemplateRequestBody: IssueTrackersLinearCreateIssueTemplateRequestBody;
}

export interface IssueTrackersLinearGetIssueTemplateRequest {
    id: string;
}

export interface IssueTrackersLinearGetIssueTemplateForFollowUpRequest {
    followUpId: string;
    issueTemplateId: string;
}

export interface IssueTrackersLinearTypeaheadOptionsRequest {
    field: IssueTrackersLinearTypeaheadOptionsFieldEnum;
    userTeamId?: string;
}

export interface IssueTrackersLinearUpdateIssueTemplateV2Request {
    id: string;
    linearUpdateIssueTemplateV2RequestBody: IssueTrackersLinearUpdateIssueTemplateV2RequestBody;
}

export interface IssueTrackersShortcutCreateIssueRequest {
    shortcutCreateIssueRequestBody: IssueTrackersShortcutCreateIssueRequestBody;
}

export interface IssueTrackersShortcutTypeaheadOptionsRequest {
    field: IssueTrackersShortcutTypeaheadOptionsFieldEnum;
    teamId?: string;
}

export interface IssueTrackersUpdateIssueTrackerSyncConfigRequest {
    updateIssueTrackerSyncConfigRequestBody: IssueTrackersUpdateIssueTrackerSyncConfigRequestBody;
}

/**
 * 
 */
export class IssueTrackersApi extends runtime.BaseAPI {

    /**
     * Create a Asana issue against an action
     * AsanaCreateIssue IssueTrackers
     */
    async issueTrackersAsanaCreateIssueRaw(requestParameters: IssueTrackersAsanaCreateIssueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersAsanaCreateIssueResponseBody>> {
        if (requestParameters.asanaCreateIssueRequestBody === null || requestParameters.asanaCreateIssueRequestBody === undefined) {
            throw new runtime.RequiredError('asanaCreateIssueRequestBody','Required parameter requestParameters.asanaCreateIssueRequestBody was null or undefined when calling issueTrackersAsanaCreateIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers/asana/issues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersAsanaCreateIssueRequestBodyToJSON(requestParameters.asanaCreateIssueRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersAsanaCreateIssueResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Asana issue against an action
     * AsanaCreateIssue IssueTrackers
     */
    async issueTrackersAsanaCreateIssue(requestParameters: IssueTrackersAsanaCreateIssueRequest, initOverrides?: RequestInit): Promise<IssueTrackersAsanaCreateIssueResponseBody> {
        const response = await this.issueTrackersAsanaCreateIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Typeahead options for Asana fields
     * AsanaTypeaheadOptions IssueTrackers
     */
    async issueTrackersAsanaTypeaheadOptionsRaw(requestParameters: IssueTrackersAsanaTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersAsanaTypeaheadOptionsResponseBody>> {
        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field','Required parameter requestParameters.field was null or undefined when calling issueTrackersAsanaTypeaheadOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.teamId !== undefined) {
            queryParameters['team_id'] = requestParameters.teamId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/asana/typeahead_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersAsanaTypeaheadOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Typeahead options for Asana fields
     * AsanaTypeaheadOptions IssueTrackers
     */
    async issueTrackersAsanaTypeaheadOptions(requestParameters: IssueTrackersAsanaTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<IssueTrackersAsanaTypeaheadOptionsResponseBody> {
        const response = await this.issueTrackersAsanaTypeaheadOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a ClickUp issue against an action
     * ClickUpCreateIssue IssueTrackers
     */
    async issueTrackersClickUpCreateIssueRaw(requestParameters: IssueTrackersClickUpCreateIssueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersClickUpCreateIssueResponseBody>> {
        if (requestParameters.clickUpCreateIssueRequestBody === null || requestParameters.clickUpCreateIssueRequestBody === undefined) {
            throw new runtime.RequiredError('clickUpCreateIssueRequestBody','Required parameter requestParameters.clickUpCreateIssueRequestBody was null or undefined when calling issueTrackersClickUpCreateIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers/click_up/issues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersClickUpCreateIssueRequestBodyToJSON(requestParameters.clickUpCreateIssueRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersClickUpCreateIssueResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a ClickUp issue against an action
     * ClickUpCreateIssue IssueTrackers
     */
    async issueTrackersClickUpCreateIssue(requestParameters: IssueTrackersClickUpCreateIssueRequest, initOverrides?: RequestInit): Promise<IssueTrackersClickUpCreateIssueResponseBody> {
        const response = await this.issueTrackersClickUpCreateIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Typeahead options for ClickUp fields
     * ClickUpTypeaheadOptions IssueTrackers
     */
    async issueTrackersClickUpTypeaheadOptionsRaw(requestParameters: IssueTrackersClickUpTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersClickUpTypeaheadOptionsResponseBody>> {
        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field','Required parameter requestParameters.field was null or undefined when calling issueTrackersClickUpTypeaheadOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.teamId !== undefined) {
            queryParameters['team_id'] = requestParameters.teamId;
        }

        if (requestParameters.spaceId !== undefined) {
            queryParameters['space_id'] = requestParameters.spaceId;
        }

        if (requestParameters.folderId !== undefined) {
            queryParameters['folder_id'] = requestParameters.folderId;
        }

        if (requestParameters.listId !== undefined) {
            queryParameters['list_id'] = requestParameters.listId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/click_up/typeahead_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersClickUpTypeaheadOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Typeahead options for ClickUp fields
     * ClickUpTypeaheadOptions IssueTrackers
     */
    async issueTrackersClickUpTypeaheadOptions(requestParameters: IssueTrackersClickUpTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<IssueTrackersClickUpTypeaheadOptionsResponseBody> {
        const response = await this.issueTrackersClickUpTypeaheadOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the recent sync failures for all incidents.
     * GetAllIssueTrackerIssueSyncFailures IssueTrackers
     */
    async issueTrackersGetAllIssueTrackerIssueSyncFailuresRaw(requestParameters: IssueTrackersGetAllIssueTrackerIssueSyncFailuresRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersGetAllIssueTrackerIssueSyncFailuresResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.issueTrackerProvider !== undefined) {
            queryParameters['issue_tracker_provider'] = requestParameters.issueTrackerProvider;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/sync_failures/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersGetAllIssueTrackerIssueSyncFailuresResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the recent sync failures for all incidents.
     * GetAllIssueTrackerIssueSyncFailures IssueTrackers
     */
    async issueTrackersGetAllIssueTrackerIssueSyncFailures(requestParameters: IssueTrackersGetAllIssueTrackerIssueSyncFailuresRequest, initOverrides?: RequestInit): Promise<IssueTrackersGetAllIssueTrackerIssueSyncFailuresResponseBody> {
        const response = await this.issueTrackersGetAllIssueTrackerIssueSyncFailuresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the recent sync failures for a given incident.
     * GetIncidentIssueTrackerIssueSyncFailures IssueTrackers
     */
    async issueTrackersGetIncidentIssueTrackerIssueSyncFailuresRaw(requestParameters: IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling issueTrackersGetIncidentIssueTrackerIssueSyncFailures.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/sync_failures/incident/{incident_id}`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the recent sync failures for a given incident.
     * GetIncidentIssueTrackerIssueSyncFailures IssueTrackers
     */
    async issueTrackersGetIncidentIssueTrackerIssueSyncFailures(requestParameters: IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresRequest, initOverrides?: RequestInit): Promise<IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBody> {
        const response = await this.issueTrackersGetIncidentIssueTrackerIssueSyncFailuresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the config for syncing issues
     * GetIssueTrackerSyncConfig IssueTrackers
     */
    async issueTrackersGetIssueTrackerSyncConfigRaw(requestParameters: IssueTrackersGetIssueTrackerSyncConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersGetIssueTrackerSyncConfigResponseBody>> {
        if (requestParameters.context === null || requestParameters.context === undefined) {
            throw new runtime.RequiredError('context','Required parameter requestParameters.context was null or undefined when calling issueTrackersGetIssueTrackerSyncConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/sync_config/{context}`.replace(`{${"context"}}`, encodeURIComponent(String(requestParameters.context))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersGetIssueTrackerSyncConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the config for syncing issues
     * GetIssueTrackerSyncConfig IssueTrackers
     */
    async issueTrackersGetIssueTrackerSyncConfig(requestParameters: IssueTrackersGetIssueTrackerSyncConfigRequest, initOverrides?: RequestInit): Promise<IssueTrackersGetIssueTrackerSyncConfigResponseBody> {
        const response = await this.issueTrackersGetIssueTrackerSyncConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a GitLab issue against an action
     * GitLabCreateIssue IssueTrackers
     */
    async issueTrackersGitLabCreateIssueRaw(requestParameters: IssueTrackersGitLabCreateIssueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersGitLabCreateIssueResponseBody>> {
        if (requestParameters.gitLabCreateIssueRequestBody === null || requestParameters.gitLabCreateIssueRequestBody === undefined) {
            throw new runtime.RequiredError('gitLabCreateIssueRequestBody','Required parameter requestParameters.gitLabCreateIssueRequestBody was null or undefined when calling issueTrackersGitLabCreateIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers/gitlab/issues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersGitLabCreateIssueRequestBodyToJSON(requestParameters.gitLabCreateIssueRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersGitLabCreateIssueResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a GitLab issue against an action
     * GitLabCreateIssue IssueTrackers
     */
    async issueTrackersGitLabCreateIssue(requestParameters: IssueTrackersGitLabCreateIssueRequest, initOverrides?: RequestInit): Promise<IssueTrackersGitLabCreateIssueResponseBody> {
        const response = await this.issueTrackersGitLabCreateIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Typeahead options for GitLab fields
     * GitLabTypeaheadOptions IssueTrackers
     */
    async issueTrackersGitLabTypeaheadOptionsRaw(requestParameters: IssueTrackersGitLabTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersGitLabTypeaheadOptionsResponseBody>> {
        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field','Required parameter requestParameters.field was null or undefined when calling issueTrackersGitLabTypeaheadOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.repo !== undefined) {
            queryParameters['repo'] = requestParameters.repo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/gitlab/typeahead_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersGitLabTypeaheadOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Typeahead options for GitLab fields
     * GitLabTypeaheadOptions IssueTrackers
     */
    async issueTrackersGitLabTypeaheadOptions(requestParameters: IssueTrackersGitLabTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<IssueTrackersGitLabTypeaheadOptionsResponseBody> {
        const response = await this.issueTrackersGitLabTypeaheadOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Jira issue against a follow-up
     * JiraCreateIssue IssueTrackers
     */
    async issueTrackersJiraCreateIssueRaw(requestParameters: IssueTrackersJiraCreateIssueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersJiraCreateIssueResponseBody>> {
        if (requestParameters.jiraCreateIssueRequestBody === null || requestParameters.jiraCreateIssueRequestBody === undefined) {
            throw new runtime.RequiredError('jiraCreateIssueRequestBody','Required parameter requestParameters.jiraCreateIssueRequestBody was null or undefined when calling issueTrackersJiraCreateIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers/jira/issues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersJiraCreateIssueRequestBodyToJSON(requestParameters.jiraCreateIssueRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersJiraCreateIssueResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Jira issue against a follow-up
     * JiraCreateIssue IssueTrackers
     */
    async issueTrackersJiraCreateIssue(requestParameters: IssueTrackersJiraCreateIssueRequest, initOverrides?: RequestInit): Promise<IssueTrackersJiraCreateIssueResponseBody> {
        const response = await this.issueTrackersJiraCreateIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Jira issue template
     * JiraCreateIssueTemplate IssueTrackers
     */
    async issueTrackersJiraCreateIssueTemplateRaw(requestParameters: IssueTrackersJiraCreateIssueTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersJiraCreateIssueTemplateResponseBody>> {
        if (requestParameters.jiraCreateIssueTemplateRequestBody === null || requestParameters.jiraCreateIssueTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('jiraCreateIssueTemplateRequestBody','Required parameter requestParameters.jiraCreateIssueTemplateRequestBody was null or undefined when calling issueTrackersJiraCreateIssueTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers/jira/issues/issue_template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersJiraCreateIssueTemplateRequestBodyToJSON(requestParameters.jiraCreateIssueTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersJiraCreateIssueTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Jira issue template
     * JiraCreateIssueTemplate IssueTrackers
     */
    async issueTrackersJiraCreateIssueTemplate(requestParameters: IssueTrackersJiraCreateIssueTemplateRequest, initOverrides?: RequestInit): Promise<IssueTrackersJiraCreateIssueTemplateResponseBody> {
        const response = await this.issueTrackersJiraCreateIssueTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get dynamic field requirements for creating Jira issues
     * JiraGetCreateIssueFields IssueTrackers
     */
    async issueTrackersJiraGetCreateIssueFieldsRaw(requestParameters: IssueTrackersJiraGetCreateIssueFieldsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersJiraGetCreateIssueFieldsResponseBody>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling issueTrackersJiraGetCreateIssueFields.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling issueTrackersJiraGetCreateIssueFields.');
        }

        if (requestParameters.issueTypeId === null || requestParameters.issueTypeId === undefined) {
            throw new runtime.RequiredError('issueTypeId','Required parameter requestParameters.issueTypeId was null or undefined when calling issueTrackersJiraGetCreateIssueFields.');
        }

        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['site_id'] = requestParameters.siteId;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        if (requestParameters.issueTypeId !== undefined) {
            queryParameters['issue_type_id'] = requestParameters.issueTypeId;
        }

        if (requestParameters.followUpId !== undefined) {
            queryParameters['follow_up_id'] = requestParameters.followUpId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/jira/create_issue_fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersJiraGetCreateIssueFieldsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get dynamic field requirements for creating Jira issues
     * JiraGetCreateIssueFields IssueTrackers
     */
    async issueTrackersJiraGetCreateIssueFields(requestParameters: IssueTrackersJiraGetCreateIssueFieldsRequest, initOverrides?: RequestInit): Promise<IssueTrackersJiraGetCreateIssueFieldsResponseBody> {
        const response = await this.issueTrackersJiraGetCreateIssueFieldsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the default follow-up issue template for this organisation
     * JiraGetIssueTemplate IssueTrackers
     */
    async issueTrackersJiraGetIssueTemplateRaw(requestParameters: IssueTrackersJiraGetIssueTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersJiraGetIssueTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling issueTrackersJiraGetIssueTemplate.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/jira/issues/issue_template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersJiraGetIssueTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the default follow-up issue template for this organisation
     * JiraGetIssueTemplate IssueTrackers
     */
    async issueTrackersJiraGetIssueTemplate(requestParameters: IssueTrackersJiraGetIssueTemplateRequest, initOverrides?: RequestInit): Promise<IssueTrackersJiraGetIssueTemplateResponseBody> {
        const response = await this.issueTrackersJiraGetIssueTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Evaluates an issue template for this follow-up
     * JiraGetIssueTemplateForFollowUp IssueTrackers
     */
    async issueTrackersJiraGetIssueTemplateForFollowUpRaw(requestParameters: IssueTrackersJiraGetIssueTemplateForFollowUpRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersJiraGetIssueTemplateForFollowUpResponseBody>> {
        if (requestParameters.followUpId === null || requestParameters.followUpId === undefined) {
            throw new runtime.RequiredError('followUpId','Required parameter requestParameters.followUpId was null or undefined when calling issueTrackersJiraGetIssueTemplateForFollowUp.');
        }

        if (requestParameters.issueTemplateId === null || requestParameters.issueTemplateId === undefined) {
            throw new runtime.RequiredError('issueTemplateId','Required parameter requestParameters.issueTemplateId was null or undefined when calling issueTrackersJiraGetIssueTemplateForFollowUp.');
        }

        const queryParameters: any = {};

        if (requestParameters.followUpId !== undefined) {
            queryParameters['follow_up_id'] = requestParameters.followUpId;
        }

        if (requestParameters.issueTemplateId !== undefined) {
            queryParameters['issue_template_id'] = requestParameters.issueTemplateId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/jira/issues/issue_template_for_follow_up`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersJiraGetIssueTemplateForFollowUpResponseBodyFromJSON(jsonValue));
    }

    /**
     * Evaluates an issue template for this follow-up
     * JiraGetIssueTemplateForFollowUp IssueTrackers
     */
    async issueTrackersJiraGetIssueTemplateForFollowUp(requestParameters: IssueTrackersJiraGetIssueTemplateForFollowUpRequest, initOverrides?: RequestInit): Promise<IssueTrackersJiraGetIssueTemplateForFollowUpResponseBody> {
        const response = await this.issueTrackersJiraGetIssueTemplateForFollowUpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get dynamic field requirements for creating Jira Server issues
     * JiraServerGetCreateIssueFields IssueTrackers
     */
    async issueTrackersJiraServerGetCreateIssueFieldsRaw(requestParameters: IssueTrackersJiraServerGetCreateIssueFieldsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersJiraServerGetCreateIssueFieldsResponseBody>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling issueTrackersJiraServerGetCreateIssueFields.');
        }

        if (requestParameters.issueTypeId === null || requestParameters.issueTypeId === undefined) {
            throw new runtime.RequiredError('issueTypeId','Required parameter requestParameters.issueTypeId was null or undefined when calling issueTrackersJiraServerGetCreateIssueFields.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        if (requestParameters.issueTypeId !== undefined) {
            queryParameters['issue_type_id'] = requestParameters.issueTypeId;
        }

        if (requestParameters.followUpId !== undefined) {
            queryParameters['follow_up_id'] = requestParameters.followUpId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/jira_server/create_issue_fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersJiraServerGetCreateIssueFieldsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get dynamic field requirements for creating Jira Server issues
     * JiraServerGetCreateIssueFields IssueTrackers
     */
    async issueTrackersJiraServerGetCreateIssueFields(requestParameters: IssueTrackersJiraServerGetCreateIssueFieldsRequest, initOverrides?: RequestInit): Promise<IssueTrackersJiraServerGetCreateIssueFieldsResponseBody> {
        const response = await this.issueTrackersJiraServerGetCreateIssueFieldsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Typeahead options for Jira Server fields
     * JiraServerTypeaheadOptions IssueTrackers
     */
    async issueTrackersJiraServerTypeaheadOptionsRaw(requestParameters: IssueTrackersJiraServerTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersJiraServerTypeaheadOptionsResponseBody>> {
        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field','Required parameter requestParameters.field was null or undefined when calling issueTrackersJiraServerTypeaheadOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        if (requestParameters.issueTypeId !== undefined) {
            queryParameters['issue_type_id'] = requestParameters.issueTypeId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/jira_server/typeahead_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersJiraServerTypeaheadOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Typeahead options for Jira Server fields
     * JiraServerTypeaheadOptions IssueTrackers
     */
    async issueTrackersJiraServerTypeaheadOptions(requestParameters: IssueTrackersJiraServerTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<IssueTrackersJiraServerTypeaheadOptionsResponseBody> {
        const response = await this.issueTrackersJiraServerTypeaheadOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Typeahead options for Jira fields
     * JiraTypeaheadOptions IssueTrackers
     */
    async issueTrackersJiraTypeaheadOptionsRaw(requestParameters: IssueTrackersJiraTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersJiraTypeaheadOptionsResponseBody>> {
        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field','Required parameter requestParameters.field was null or undefined when calling issueTrackersJiraTypeaheadOptions.');
        }

        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling issueTrackersJiraTypeaheadOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.siteId !== undefined) {
            queryParameters['site_id'] = requestParameters.siteId;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        if (requestParameters.issueTypeId !== undefined) {
            queryParameters['issue_type_id'] = requestParameters.issueTypeId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/jira/typeahead_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersJiraTypeaheadOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Typeahead options for Jira fields
     * JiraTypeaheadOptions IssueTrackers
     */
    async issueTrackersJiraTypeaheadOptions(requestParameters: IssueTrackersJiraTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<IssueTrackersJiraTypeaheadOptionsResponseBody> {
        const response = await this.issueTrackersJiraTypeaheadOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a Jira issue template
     * JiraUpdateIssueTemplate IssueTrackers
     */
    async issueTrackersJiraUpdateIssueTemplateRaw(requestParameters: IssueTrackersJiraUpdateIssueTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersJiraUpdateIssueTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling issueTrackersJiraUpdateIssueTemplate.');
        }

        if (requestParameters.jiraUpdateIssueTemplateRequestBody === null || requestParameters.jiraUpdateIssueTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('jiraUpdateIssueTemplateRequestBody','Required parameter requestParameters.jiraUpdateIssueTemplateRequestBody was null or undefined when calling issueTrackersJiraUpdateIssueTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers/jira/issues/issue_template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersJiraUpdateIssueTemplateRequestBodyToJSON(requestParameters.jiraUpdateIssueTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersJiraUpdateIssueTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a Jira issue template
     * JiraUpdateIssueTemplate IssueTrackers
     */
    async issueTrackersJiraUpdateIssueTemplate(requestParameters: IssueTrackersJiraUpdateIssueTemplateRequest, initOverrides?: RequestInit): Promise<IssueTrackersJiraUpdateIssueTemplateResponseBody> {
        const response = await this.issueTrackersJiraUpdateIssueTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Linear issue against an action
     * LinearCreateIssue IssueTrackers
     */
    async issueTrackersLinearCreateIssueRaw(requestParameters: IssueTrackersLinearCreateIssueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersLinearCreateIssueResponseBody>> {
        if (requestParameters.linearCreateIssueRequestBody === null || requestParameters.linearCreateIssueRequestBody === undefined) {
            throw new runtime.RequiredError('linearCreateIssueRequestBody','Required parameter requestParameters.linearCreateIssueRequestBody was null or undefined when calling issueTrackersLinearCreateIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers/linear/issues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersLinearCreateIssueRequestBodyToJSON(requestParameters.linearCreateIssueRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersLinearCreateIssueResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Linear issue against an action
     * LinearCreateIssue IssueTrackers
     */
    async issueTrackersLinearCreateIssue(requestParameters: IssueTrackersLinearCreateIssueRequest, initOverrides?: RequestInit): Promise<IssueTrackersLinearCreateIssueResponseBody> {
        const response = await this.issueTrackersLinearCreateIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Linear issue template
     * LinearCreateIssueTemplate IssueTrackers
     */
    async issueTrackersLinearCreateIssueTemplateRaw(requestParameters: IssueTrackersLinearCreateIssueTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersLinearCreateIssueTemplateResponseBody>> {
        if (requestParameters.linearCreateIssueTemplateRequestBody === null || requestParameters.linearCreateIssueTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('linearCreateIssueTemplateRequestBody','Required parameter requestParameters.linearCreateIssueTemplateRequestBody was null or undefined when calling issueTrackersLinearCreateIssueTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers/linear/issues/issue_template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersLinearCreateIssueTemplateRequestBodyToJSON(requestParameters.linearCreateIssueTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersLinearCreateIssueTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Linear issue template
     * LinearCreateIssueTemplate IssueTrackers
     */
    async issueTrackersLinearCreateIssueTemplate(requestParameters: IssueTrackersLinearCreateIssueTemplateRequest, initOverrides?: RequestInit): Promise<IssueTrackersLinearCreateIssueTemplateResponseBody> {
        const response = await this.issueTrackersLinearCreateIssueTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the default follow-up issue template for this organisation
     * LinearGetIssueTemplate IssueTrackers
     */
    async issueTrackersLinearGetIssueTemplateRaw(requestParameters: IssueTrackersLinearGetIssueTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersLinearGetIssueTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling issueTrackersLinearGetIssueTemplate.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/linear/issues/issue_template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersLinearGetIssueTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the default follow-up issue template for this organisation
     * LinearGetIssueTemplate IssueTrackers
     */
    async issueTrackersLinearGetIssueTemplate(requestParameters: IssueTrackersLinearGetIssueTemplateRequest, initOverrides?: RequestInit): Promise<IssueTrackersLinearGetIssueTemplateResponseBody> {
        const response = await this.issueTrackersLinearGetIssueTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Evaluates an issue template for this follow-up
     * LinearGetIssueTemplateForFollowUp IssueTrackers
     */
    async issueTrackersLinearGetIssueTemplateForFollowUpRaw(requestParameters: IssueTrackersLinearGetIssueTemplateForFollowUpRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersLinearGetIssueTemplateForFollowUpResponseBody>> {
        if (requestParameters.followUpId === null || requestParameters.followUpId === undefined) {
            throw new runtime.RequiredError('followUpId','Required parameter requestParameters.followUpId was null or undefined when calling issueTrackersLinearGetIssueTemplateForFollowUp.');
        }

        if (requestParameters.issueTemplateId === null || requestParameters.issueTemplateId === undefined) {
            throw new runtime.RequiredError('issueTemplateId','Required parameter requestParameters.issueTemplateId was null or undefined when calling issueTrackersLinearGetIssueTemplateForFollowUp.');
        }

        const queryParameters: any = {};

        if (requestParameters.followUpId !== undefined) {
            queryParameters['follow_up_id'] = requestParameters.followUpId;
        }

        if (requestParameters.issueTemplateId !== undefined) {
            queryParameters['issue_template_id'] = requestParameters.issueTemplateId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/linear/issues/issue_template_for_follow_up`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersLinearGetIssueTemplateForFollowUpResponseBodyFromJSON(jsonValue));
    }

    /**
     * Evaluates an issue template for this follow-up
     * LinearGetIssueTemplateForFollowUp IssueTrackers
     */
    async issueTrackersLinearGetIssueTemplateForFollowUp(requestParameters: IssueTrackersLinearGetIssueTemplateForFollowUpRequest, initOverrides?: RequestInit): Promise<IssueTrackersLinearGetIssueTemplateForFollowUpResponseBody> {
        const response = await this.issueTrackersLinearGetIssueTemplateForFollowUpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Typeahead options for Linear fields
     * LinearTypeaheadOptions IssueTrackers
     */
    async issueTrackersLinearTypeaheadOptionsRaw(requestParameters: IssueTrackersLinearTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersLinearTypeaheadOptionsResponseBody>> {
        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field','Required parameter requestParameters.field was null or undefined when calling issueTrackersLinearTypeaheadOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.userTeamId !== undefined) {
            queryParameters['user_team_id'] = requestParameters.userTeamId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/linear/typeahead_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersLinearTypeaheadOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Typeahead options for Linear fields
     * LinearTypeaheadOptions IssueTrackers
     */
    async issueTrackersLinearTypeaheadOptions(requestParameters: IssueTrackersLinearTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<IssueTrackersLinearTypeaheadOptionsResponseBody> {
        const response = await this.issueTrackersLinearTypeaheadOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a Linear issue template
     * LinearUpdateIssueTemplateV2 IssueTrackers
     */
    async issueTrackersLinearUpdateIssueTemplateV2Raw(requestParameters: IssueTrackersLinearUpdateIssueTemplateV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersLinearUpdateIssueTemplateV2ResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling issueTrackersLinearUpdateIssueTemplateV2.');
        }

        if (requestParameters.linearUpdateIssueTemplateV2RequestBody === null || requestParameters.linearUpdateIssueTemplateV2RequestBody === undefined) {
            throw new runtime.RequiredError('linearUpdateIssueTemplateV2RequestBody','Required parameter requestParameters.linearUpdateIssueTemplateV2RequestBody was null or undefined when calling issueTrackersLinearUpdateIssueTemplateV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers/linear/issues/issue_template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersLinearUpdateIssueTemplateV2RequestBodyToJSON(requestParameters.linearUpdateIssueTemplateV2RequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersLinearUpdateIssueTemplateV2ResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a Linear issue template
     * LinearUpdateIssueTemplateV2 IssueTrackers
     */
    async issueTrackersLinearUpdateIssueTemplateV2(requestParameters: IssueTrackersLinearUpdateIssueTemplateV2Request, initOverrides?: RequestInit): Promise<IssueTrackersLinearUpdateIssueTemplateV2ResponseBody> {
        const response = await this.issueTrackersLinearUpdateIssueTemplateV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Shortcut issue against an action
     * ShortcutCreateIssue IssueTrackers
     */
    async issueTrackersShortcutCreateIssueRaw(requestParameters: IssueTrackersShortcutCreateIssueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersShortcutCreateIssueResponseBody>> {
        if (requestParameters.shortcutCreateIssueRequestBody === null || requestParameters.shortcutCreateIssueRequestBody === undefined) {
            throw new runtime.RequiredError('shortcutCreateIssueRequestBody','Required parameter requestParameters.shortcutCreateIssueRequestBody was null or undefined when calling issueTrackersShortcutCreateIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers/shortcut/issues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersShortcutCreateIssueRequestBodyToJSON(requestParameters.shortcutCreateIssueRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersShortcutCreateIssueResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Shortcut issue against an action
     * ShortcutCreateIssue IssueTrackers
     */
    async issueTrackersShortcutCreateIssue(requestParameters: IssueTrackersShortcutCreateIssueRequest, initOverrides?: RequestInit): Promise<IssueTrackersShortcutCreateIssueResponseBody> {
        const response = await this.issueTrackersShortcutCreateIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Typeahead options for Shortcut fields
     * ShortcutTypeaheadOptions IssueTrackers
     */
    async issueTrackersShortcutTypeaheadOptionsRaw(requestParameters: IssueTrackersShortcutTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersShortcutTypeaheadOptionsResponseBody>> {
        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field','Required parameter requestParameters.field was null or undefined when calling issueTrackersShortcutTypeaheadOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        if (requestParameters.teamId !== undefined) {
            queryParameters['team_id'] = requestParameters.teamId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers/shortcut/typeahead_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersShortcutTypeaheadOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Typeahead options for Shortcut fields
     * ShortcutTypeaheadOptions IssueTrackers
     */
    async issueTrackersShortcutTypeaheadOptions(requestParameters: IssueTrackersShortcutTypeaheadOptionsRequest, initOverrides?: RequestInit): Promise<IssueTrackersShortcutTypeaheadOptionsResponseBody> {
        const response = await this.issueTrackersShortcutTypeaheadOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the config for syncing issues
     * UpdateIssueTrackerSyncConfig IssueTrackers
     */
    async issueTrackersUpdateIssueTrackerSyncConfigRaw(requestParameters: IssueTrackersUpdateIssueTrackerSyncConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersUpdateIssueTrackerSyncConfigResponseBody>> {
        if (requestParameters.updateIssueTrackerSyncConfigRequestBody === null || requestParameters.updateIssueTrackerSyncConfigRequestBody === undefined) {
            throw new runtime.RequiredError('updateIssueTrackerSyncConfigRequestBody','Required parameter requestParameters.updateIssueTrackerSyncConfigRequestBody was null or undefined when calling issueTrackersUpdateIssueTrackerSyncConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers/sync_config`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersUpdateIssueTrackerSyncConfigRequestBodyToJSON(requestParameters.updateIssueTrackerSyncConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersUpdateIssueTrackerSyncConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the config for syncing issues
     * UpdateIssueTrackerSyncConfig IssueTrackers
     */
    async issueTrackersUpdateIssueTrackerSyncConfig(requestParameters: IssueTrackersUpdateIssueTrackerSyncConfigRequest, initOverrides?: RequestInit): Promise<IssueTrackersUpdateIssueTrackerSyncConfigResponseBody> {
        const response = await this.issueTrackersUpdateIssueTrackerSyncConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum IssueTrackersAsanaTypeaheadOptionsFieldEnum {
    Project = 'project',
    Team = 'team',
    User = 'user'
}
/**
    * @export
    * @enum {string}
    */
export enum IssueTrackersClickUpTypeaheadOptionsFieldEnum {
    Team = 'team',
    Space = 'space',
    Folder = 'folder',
    List = 'list',
    Member = 'member'
}
/**
    * @export
    * @enum {string}
    */
export enum IssueTrackersGetAllIssueTrackerIssueSyncFailuresIssueTrackerProviderEnum {
    Asana = 'asana',
    ClickUp = 'click_up',
    Linear = 'linear',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Github = 'github',
    Gitlab = 'gitlab',
    Shortcut = 'shortcut'
}
/**
    * @export
    * @enum {string}
    */
export enum IssueTrackersGetIssueTrackerSyncConfigContextEnum {
    FollowUp = 'follow-up',
    IncidentTicket = 'incident-ticket'
}
/**
    * @export
    * @enum {string}
    */
export enum IssueTrackersGitLabTypeaheadOptionsFieldEnum {
    Label = 'label'
}
/**
    * @export
    * @enum {string}
    */
export enum IssueTrackersJiraServerTypeaheadOptionsFieldEnum {
    Project = 'project',
    Issuetype = 'issuetype',
    Label = 'label',
    Epic = 'epic',
    User = 'user'
}
/**
    * @export
    * @enum {string}
    */
export enum IssueTrackersJiraTypeaheadOptionsFieldEnum {
    Project = 'project',
    Issuetype = 'issuetype',
    Label = 'label',
    Epic = 'epic',
    User = 'user'
}
/**
    * @export
    * @enum {string}
    */
export enum IssueTrackersLinearTypeaheadOptionsFieldEnum {
    Team = 'team',
    User = 'user',
    Project = 'project',
    Label = 'label'
}
/**
    * @export
    * @enum {string}
    */
export enum IssueTrackersShortcutTypeaheadOptionsFieldEnum {
    Member = 'member',
    Project = 'project',
    Team = 'team'
}
