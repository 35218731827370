import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
} from "@incident-shared/forms/Typeahead";
import { FormLabel } from "@incident-shared/forms/v1/FormInputHelpers";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Button,
  ButtonTheme,
  IconEnum,
  Input,
  ModalFooter,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import {
  IntegrationsSetIntegrationAPITokenRequestBodyProviderEnum as ProviderEnum,
  TypeaheadsListTypeaheadTypeEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

type TokenFormData = {
  api_token: string;
};

type BotUserFormData = {
  bot_user_id: string;
};

enum Steps {
  SetToken = "set_token",
  ChooseBotUser = "choose_bot_user",
}

export const ConnectToPagerDutyModal = ({
  onClose: externalOnClose,
}: {
  onClose: () => void;
}): React.ReactElement | null => {
  const [step, setStep] = useState(Steps.SetToken);
  const [token, setToken] = useState<string | undefined>(undefined);

  const onClose = () => {
    setStep(Steps.SetToken);
    externalOnClose();
  };

  const onTokenSet = (token: string) => {
    setToken(token);
    setStep(Steps.ChooseBotUser);
  };
  const onCompleted = async () => {
    externalOnClose();
  };

  if (step === Steps.SetToken) {
    return <SetToken onClose={onClose} onSuccess={onTokenSet} />;
  } else if (step === Steps.ChooseBotUser) {
    return (
      <ChooseBotUser onClose={onClose} onSuccess={onCompleted} token={token} />
    );
  } else {
    return null;
  }
};

const SetToken = ({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: (token: string) => void;
}): React.ReactElement | null => {
  const { showArticle } = useIntercom();
  const formMethods = useForm<TokenFormData>();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, { api_token }: TokenFormData) => {
      await apiClient.integrationsSetIntegrationApiToken({
        setIntegrationApiTokenRequestBody: {
          provider: ProviderEnum.Pagerduty,
          api_token,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: () => {
        const { api_token } = formMethods.getValues();
        onSuccess(api_token);
      },
    },
  );

  return (
    <FormModalV2
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      analyticsTrackingId="connect-pagerduty-integration"
      title="Connect to PagerDuty"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText="Next"
          confirmButtonType="submit"
        />
      }
    >
      <p className="text-sm text-slate-700 mb-3">
        Add your PagerDuty API key here to allow us to escalate to people on
        your behalf. This key must have <strong>write</strong> access (read-only
        keys won&apos;t work).
      </p>
      <p className="text-sm text-slate-700 mb-3">
        If you need help creating your API key, you can follow our{" "}
        <Button
          analyticsTrackingId="connect-pagerduty-show-help"
          theme={ButtonTheme.Link}
          onClick={() => showArticle(6180917)}
          className="underline"
        >
          instructions
        </Button>
        .
      </p>
      <hr className="my-3" />
      <InputV2
        formMethods={formMethods}
        name="api_token"
        label="API token"
        type={InputType.Password}
        required="API token is required"
        autoComplete="none"
        placeholder={"xxxxxxxxxxxx"}
      />
    </FormModalV2>
  );
};

const ChooseBotUser = ({
  onClose,
  onSuccess,
  token,
}: {
  onClose: () => void;
  onSuccess: () => void;
  token?: string;
}): React.ReactElement | null => {
  const formMethods = useForm<BotUserFormData>();

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "integrationsPagerDutyGetConfig",
    undefined,
    async (apiClient, { bot_user_id }: BotUserFormData) =>
      await apiClient.integrationsPagerDutyUpdateConfig({
        updateConfigRequestBody: { bot_user_id },
      }),
    {
      setError: formMethods.setError,
      onSuccess,
    },
  );

  const apiClient = useClient();
  const loadOptions = getTypeaheadOptions(
    apiClient,
    TypeaheadsListTypeaheadTypeEnum.PagerDutyUser,
  );
  const hydrateOptions = hydrateInitialSelectOptions(
    apiClient,
    TypeaheadsListTypeaheadTypeEnum.PagerDutyUser,
  );

  const { showArticle } = useIntercom();

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      analyticsTrackingId="connect-pagerduty-integration"
      title="Connect to PagerDuty"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText="Connect"
          confirmButtonType="submit"
        />
      }
    >
      <p className="text-sm text-slate-400">
        Add your PagerDuty API key here to allow us to escalate to people on
        your behalf. If you need help finding your API key, you can follow our{" "}
        <Button
          theme={ButtonTheme.Link}
          analyticsTrackingId={"connect-pagerduty-integration-help"}
          onClick={() => showArticle(6180917)}
        >
          instructions
        </Button>
        .
      </p>
      <hr className="my-3" />
      <FormLabel htmlFor="pagerduty_key">API token</FormLabel>
      <Input
        disabled
        id="pagerduty_key"
        type={InputType.Password}
        placeholder={"xxxxxxxxxxxx"}
        value={token}
      />
      <hr className="my-3" />
      <p className="text-sm text-slate-700 mb-2">
        Choose your bot user account. PagerDuty requires all incidents to be
        triggered by a specified user account, which does <strong>not</strong>{" "}
        receive any alerts about the incident. We recommend using a
        limited-access robot account for this.
      </p>
      <DynamicSingleSelectV2
        formMethods={formMethods}
        name="bot_user_id"
        icon={IconEnum.User}
        label="Select a bot user"
        loadOptions={loadOptions}
        hydrateOptions={hydrateOptions}
        placeholder={"Please choose..."}
        required={true}
      />
    </FormModalV2>
  );
};
