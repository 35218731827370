import {
  ExploDashboard,
  ExploDashboardProps,
} from "@incident-shared/explo/ExploDashboard";
import { DateAggregationMap } from "@incident-shared/explo/ExploDashboard";
import { useFiltersContext } from "@incident-shared/filters";
import { useAppliedCurrentUserFilters } from "@incident-shared/filters/FilterFormElement";
import { dateRangePickerStateToTimestamps } from "@incident-shared/forms/v1/DateRangePicker";
import { Badge, BadgeSize, BadgeTheme, Heading, IconEnum } from "@incident-ui";
import {
  addMonths,
  addSeconds,
  endOfMonth,
  format,
  parseISO,
  startOfMonth,
} from "date-fns";
import { tcx } from "src/utils/tailwind-classes";

import { useInsightsContext } from "../context/useInsightsContext";

// InsightsDashboardSection represents a section on one of our core Insights dashboards.
export const InsightsDashboardSection = (
  props: ExploDashboardProps & {
    title?: React.ReactNode; // Dashboards that follow on from the previous section might not have a title
    description?: React.ReactNode;
    controls?: React.ReactNode;
    noBottomDivider?: boolean;
  },
) => {
  const { title, description, controls, noBottomDivider } = props;
  const { filters: rawFilters } = useFiltersContext();
  // Replaces 'current_user' with the actual ID of the current user.
  const filters = useAppliedCurrentUserFilters(rawFilters);

  const { dateRange, dateAggregation, comparePreviousPeriod } =
    useInsightsContext();

  const filterJSON = JSON.stringify(filters);
  let { from: startDate, to: endDate } =
    dateRangePickerStateToTimestamps(dateRange);
  // Strip the timezone information from the datetime as we're only dealing with UTC
  startDate = stripTimezone(startDate);
  endDate = stripTimezone(endDate);

  // For now going to use an offset of '+00:00' to make sure we don't do any
  // timezone calculations within Explo in order to show everything in UTC
  // const timeZoneOffset = format(new Date(), "xxx");
  const timeZoneOffset = "+00:00";

  if (comparePreviousPeriod) {
    return <InisightsSideBySideDashboards {...props} />;
  }

  return (
    <div
      className={tcx("shadow-none flex", {
        "only:border-none [&:not(:last-child)]:border-b border-stroke":
          !noBottomDivider,
      })}
    >
      <div className="flex flex-col gap-2 px-8 py-6 w-64">
        {title && (
          <Heading level={2} size="small">
            {title}
          </Heading>
        )}
        {description && (
          <p className="mb-4 text-slate-700 text-sm">{description}</p>
        )}
      </div>
      <div className="flex-1 flex-col gap-3 px-8 py-6 w-auto">
        {controls && (
          <InsightsDashboardControls>{controls}</InsightsDashboardControls>
        )}
        <ExploDashboard
          {...props}
          dashboardVariables={{
            ...props.dashboardVariables,
            filter_json: filterJSON,
            start_date: startDate,
            end_date: endDate,
            timezone_offset: timeZoneOffset, // used to truncate dates properly
            date_aggregation: DateAggregationMap[dateAggregation],
          }}
        />
      </div>
    </div>
  );
};

export const InisightsSideBySideDashboards = (
  props: ExploDashboardProps & {
    title?: React.ReactNode; // Dashboards that follow on from the previous section might not have a title
    description?: React.ReactNode;
    controls?: React.ReactNode;
    noBottomDivider?: boolean;
  },
) => {
  const { title, description, controls, noBottomDivider } = props;
  const { filters } = useFiltersContext();
  const { dateRange, dateAggregation } = useInsightsContext();

  const filterJSON = JSON.stringify(filters);
  const { from: startDate, to: endDate } =
    dateRangePickerStateToTimestamps(dateRange);

  const { prevEndDate, prevStartDate } = calculatePreviousPeriod(
    startDate,
    endDate,
  );

  // For now going to use an offset of '+00:00' to make sure we don't do any
  // timezone calculations within Explo in order to show everything in UTC
  // const timeZoneOffset = format(new Date(), "xxx");
  const timeZoneOffset = "+00:00";

  return (
    <div
      className={tcx("shadow-none flex flex-col px-8 py-6", {
        "only:border-none [&:not(:last-child)]:border-b border-stroke":
          !noBottomDivider,
      })}
    >
      <div className="flex flex-col gap-2 w-1/3">
        {title && (
          <Heading level={2} size="small">
            {title}
          </Heading>
        )}
        {description && (
          <p className="mb-4 text-slate-700 text-sm">{description}</p>
        )}
      </div>
      <div className="flex-1 flex-col gap-3 w-auto">
        {controls && (
          <InsightsDashboardControls>{controls}</InsightsDashboardControls>
        )}
        {/* Date range badges */}
        <div className="flex flex-row gap-4 pt-4 w-full sticky top-0 bg-gradient-to-b from-surface-primary via-surface-primary via-75% to-90%">
          <div className="w-1/2">
            <Badge
              theme={BadgeTheme.Tertiary}
              className="w-full mb-4 justify-center"
              label={`${parseDateRange(prevStartDate, prevEndDate)}`}
              size={BadgeSize.Medium}
              icon={IconEnum.Compare}
            />
          </div>
          <div className="w-1/2">
            <Badge
              theme={BadgeTheme.Info}
              className="w-full mb-4 justify-center"
              label={`${parseDateRange(startDate, endDate)}`}
              size={BadgeSize.Medium}
              icon={IconEnum.Calendar}
            />
          </div>
        </div>
        {/* The two side by side dashboards */}
        <div className="flex flex-row gap-4 w-full">
          <div className="w-1/2">
            <ExploDashboard
              {...props}
              dashboardVariables={{
                ...props.dashboardVariables,
                filter_json: filterJSON,
                // Strip the timezone information from the datetime as we're only dealing with UTC
                start_date: stripTimezone(prevStartDate),
                end_date: stripTimezone(prevEndDate),
                timezone_offset: timeZoneOffset, // used to truncate dates properly
                date_aggregation: DateAggregationMap[dateAggregation],
              }}
            />
          </div>
          <div className="w-1/2">
            <ExploDashboard
              {...props}
              dashboardVariables={{
                ...props.dashboardVariables,
                filter_json: filterJSON,
                // Strip the timezone information from the datetime as we're only dealing with UTC
                start_date: stripTimezone(startDate),
                end_date: stripTimezone(endDate),
                timezone_offset: timeZoneOffset, // used to truncate dates properly
                date_aggregation: DateAggregationMap[dateAggregation],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const InsightsDashboardControls = ({
  children,
}: React.PropsWithChildren<object>) => {
  return (
    <div className="text-sm text-slate-700 flex items-center space-x-4 pb-4">
      {children}
    </div>
  );
};

// calculatePreviousPeriod expects start and end dates as ISO-formatted strings.
// It returns the start and end dates of the previous period as ISO-formatted strings.
const calculatePreviousPeriod = (startDateISO: string, endDateISO: string) => {
  const [startDate, endDate] = [parseISO(startDateISO), parseISO(endDateISO)];

  // The previous period should end one second before the start of the current period,
  // i.e. it should be the end of the day before.
  const prevEndDate = addSeconds(startDate, -1);

  // If the start date and end date describe the beginning and end of some months,
  // the length of the previous period should be the same number of entire months,
  // rather than the same number of days in those months - which might not be the same.
  if (
    startOfMonth(startDate).toDateString() === startDate.toDateString() &&
    endOfMonth(endDate).toDateString() === endDate.toDateString()
  ) {
    const numberOfMonths = endDate.getMonth() - startDate.getMonth() + 1;
    const prevStartDate = addMonths(startDate, -numberOfMonths);

    return {
      prevStartDate: prevStartDate.toISOString(),
      prevEndDate: prevEndDate.toISOString(),
    };
  }

  // However if instead the start/end dates just represent some arbitrary days,
  // we'll just substract that number of days instead.
  const range = new Date(endDate).getTime() - new Date(startDate).getTime();
  const prevStartDate = new Date(prevEndDate.getTime() - range);

  return {
    prevStartDate: prevStartDate.toISOString(),
    prevEndDate: prevEndDate.toISOString(),
  };
};

const stripTimezone = (dateTime: string): string =>
  dateTime.replace(/(\+|-)[0-9][0-9]:[0-9][0-9]/, "Z");

// This parser expects from and to to be in ISO format with a timezone
const parseDateRange = (from: string, to: string) => {
  // Parse the dates using parseISO
  const fromDate = parseISO(from);
  const toDate = parseISO(to);

  // Format the dates using format
  const dateFmtOptions = "d MMM yy";

  const fromDateString = format(fromDate, dateFmtOptions);
  const toDateString = format(toDate, dateFmtOptions);

  return `${fromDateString} - ${toDateString}`;
};
