import { Helmet } from "react-helmet";

import { AlertSubPageHeading } from "../common/AlertSubPageHeading";
import {
  PrioritiesCreateEditForm,
  PrioritiesDescription,
} from "./PrioritiesCreateEditForm";

export const AlertPrioritiesPage = () => {
  return (
    <div className={"flex flex-col pt-6 max-w-[720px] mx-auto"}>
      <>
        <Helmet title="Alert priorities - incident.io" />
        <div className={"w-full flex justify-between mb-4 items-start"}>
          <AlertSubPageHeading
            title="Priorities"
            subtitle={<PrioritiesDescription />}
          />
        </div>
      </>
      <PrioritiesCreateEditForm />
    </div>
  );
};
