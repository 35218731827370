/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TimelineItemsCreateRequestBody,
    TimelineItemsCreateRequestBodyFromJSON,
    TimelineItemsCreateRequestBodyToJSON,
    TimelineItemsCreateResponseBody,
    TimelineItemsCreateResponseBodyFromJSON,
    TimelineItemsCreateResponseBodyToJSON,
    TimelineItemsListResponseBody,
    TimelineItemsListResponseBodyFromJSON,
    TimelineItemsListResponseBodyToJSON,
    TimelineItemsToggleVisibilityRequestBody,
    TimelineItemsToggleVisibilityRequestBodyFromJSON,
    TimelineItemsToggleVisibilityRequestBodyToJSON,
    TimelineItemsToggleVisibilityResponseBody,
    TimelineItemsToggleVisibilityResponseBodyFromJSON,
    TimelineItemsToggleVisibilityResponseBodyToJSON,
    TimelineItemsUpdateRequestBody,
    TimelineItemsUpdateRequestBodyFromJSON,
    TimelineItemsUpdateRequestBodyToJSON,
    TimelineItemsUpdateResponseBody,
    TimelineItemsUpdateResponseBodyFromJSON,
    TimelineItemsUpdateResponseBodyToJSON,
} from '../models';

export interface TimelineItemsCreateRequest {
    createRequestBody: TimelineItemsCreateRequestBody;
}

export interface TimelineItemsDestroyRequest {
    id: string;
}

export interface TimelineItemsListRequest {
    incidentId: string;
}

export interface TimelineItemsToggleVisibilityRequest {
    id: string;
    toggleVisibilityRequestBody: TimelineItemsToggleVisibilityRequestBody;
}

export interface TimelineItemsUpdateRequest {
    id: string;
    updateRequestBody: TimelineItemsUpdateRequestBody;
}

/**
 * 
 */
export class TimelineItemsApi extends runtime.BaseAPI {

    /**
     * Create a new timeline item
     * Create TimelineItems
     */
    async timelineItemsCreateRaw(requestParameters: TimelineItemsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TimelineItemsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling timelineItemsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/timeline_items`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimelineItemsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimelineItemsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new timeline item
     * Create TimelineItems
     */
    async timelineItemsCreate(requestParameters: TimelineItemsCreateRequest, initOverrides?: RequestInit): Promise<TimelineItemsCreateResponseBody> {
        const response = await this.timelineItemsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives a timeline item
     * Destroy TimelineItems
     */
    async timelineItemsDestroyRaw(requestParameters: TimelineItemsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling timelineItemsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/timeline_items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a timeline item
     * Destroy TimelineItems
     */
    async timelineItemsDestroy(requestParameters: TimelineItemsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.timelineItemsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List timeline items associated with an incident
     * List TimelineItems
     */
    async timelineItemsListRaw(requestParameters: TimelineItemsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TimelineItemsListResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling timelineItemsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/timeline_items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimelineItemsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List timeline items associated with an incident
     * List TimelineItems
     */
    async timelineItemsList(requestParameters: TimelineItemsListRequest, initOverrides?: RequestInit): Promise<TimelineItemsListResponseBody> {
        const response = await this.timelineItemsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Toggle visibility of a timeline item within the incident timeline
     * ToggleVisibility TimelineItems
     */
    async timelineItemsToggleVisibilityRaw(requestParameters: TimelineItemsToggleVisibilityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TimelineItemsToggleVisibilityResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling timelineItemsToggleVisibility.');
        }

        if (requestParameters.toggleVisibilityRequestBody === null || requestParameters.toggleVisibilityRequestBody === undefined) {
            throw new runtime.RequiredError('toggleVisibilityRequestBody','Required parameter requestParameters.toggleVisibilityRequestBody was null or undefined when calling timelineItemsToggleVisibility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/timeline_items/{id}/actions/toggle_visibility`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TimelineItemsToggleVisibilityRequestBodyToJSON(requestParameters.toggleVisibilityRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimelineItemsToggleVisibilityResponseBodyFromJSON(jsonValue));
    }

    /**
     * Toggle visibility of a timeline item within the incident timeline
     * ToggleVisibility TimelineItems
     */
    async timelineItemsToggleVisibility(requestParameters: TimelineItemsToggleVisibilityRequest, initOverrides?: RequestInit): Promise<TimelineItemsToggleVisibilityResponseBody> {
        const response = await this.timelineItemsToggleVisibilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a timeline item
     * Update TimelineItems
     */
    async timelineItemsUpdateRaw(requestParameters: TimelineItemsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TimelineItemsUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling timelineItemsUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling timelineItemsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/timeline_items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TimelineItemsUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimelineItemsUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a timeline item
     * Update TimelineItems
     */
    async timelineItemsUpdate(requestParameters: TimelineItemsUpdateRequest, initOverrides?: RequestInit): Promise<TimelineItemsUpdateResponseBody> {
        const response = await this.timelineItemsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
