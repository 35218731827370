import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Modal, ModalContent, ModalFooter } from "@incident-ui";
import React from "react";
import {
  ScopeNameEnum,
  StatusPage,
  StatusPageStructure,
  StatusPageTemplate,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

import { StatusPagePublishLiveIncident } from "../../common/modals/PublishLiveIncidentModal";

const modalProps = {
  isOpen: true,
  isExtraLarge: true,
  title: "Publish incident",
  analyticsTrackingId: "status-page-publish-incident",
};

export const StatusPageIncidentCreatePage = ({
  page,
  structure,
  templates,
}: {
  page: StatusPage | null;
  structure: StatusPageStructure | null;
  templates: StatusPageTemplate[];
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () =>
    history.length > 0 ? history.back() : navigate(`/status-pages/${page?.id}`);
  const { hasScope } = useIdentity();

  if (!hasScope(ScopeNameEnum.StatusPagesPublishUpdates)) {
    return (
      <Modal {...modalProps} onClose={onClose} disableQuickClose={false}>
        <ModalContent>
          Sorry, you don&apos;t have permission to publish incidents to your
          organisation&apos;s public status page.
        </ModalContent>
        <ModalFooter onClose={onClose} hideConfirmButton />
      </Modal>
    );
  }

  if (!page || !structure) {
    return <Modal {...modalProps} loading onClose={onClose} />;
  }

  return (
    <StatusPagePublishLiveIncident
      page={page}
      structure={structure}
      templates={templates}
      onClose={onClose}
    />
  );
};
