import { Icon, IconEnum } from "@incident-ui";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";

interface SelectMetricProps {
  metrics: SelectOption[];
  selectedDurationMetricID: string;
  setDurationMetric: (newDurationMetric: string) => void;
}

export const SelectMetric = ({
  metrics,
  selectedDurationMetricID,
  setDurationMetric,
}: SelectMetricProps): React.ReactElement => {
  return (
    <div className={"flex items-center text-sm text-slate-700 gap-2"}>
      <div className="flex-grow">
        <StaticSingleSelect
          placeholder={"Duration metric"}
          options={metrics.map((metric) => ({
            label: metric.label,
            value: metric.value,
            renderFn: () => (
              <div className="flex text-smmt-0.5 items-center flex-wrap text-slate-700">
                <Icon
                  id={IconEnum.Timer}
                  className="shrink-0 text-content-tertiary"
                />
                <span className="ml-1 mr-2">{metric.label}</span>
                <span className="text-content-tertiary">
                  {metric.description}
                </span>
              </div>
            ),
          }))}
          className="min-w-48"
          value={selectedDurationMetricID}
          onChange={(value) => {
            if (value) {
              const metric = metrics.find((x) => x.value === value);
              if (metric) {
                setDurationMetric(metric.value);
              }
            }
          }}
          isClearable={false}
        />
      </div>
    </div>
  );
};
