import { ControlledInputWrapper } from "@incident-shared/forms/v1/controlled/ControlledInputWrapper";
import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import {
  Callout,
  CalloutTheme,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import { CheckboxGroup } from "@incident-ui/Checkbox/CheckboxGroup";
import {
  CurrencyInput,
  currencyStrToCents,
  currencyValidationRules,
} from "@incident-ui/Input/CurrencyInput";
import { InputType } from "@incident-ui/Input/Input";
import React from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { formatCurrency } from "../../../../utils/currency";
import {
  sortWeekdays,
  weekdaySelectOptions,
} from "../../../../utils/frequencies";
import { TimezoneHelperText } from "../TimezoneHelperToolTip";
import { PayRuleFormType } from "./PayConfigCreateEditForm";

export const PayRuleModal = ({
  onClose,
  onAdd,
  onEdit,
  editingRule,
  selectedCurrency,
  baseRate,
}: {
  onClose: () => void;
  onAdd: (payRule: PayRuleFormType) => void;
  onEdit: (payRule: PayRuleFormType) => void;
  editingRule: PayRuleFormType | null;
  selectedCurrency: string;
  baseRate: string;
}): React.ReactElement | null => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
    setError,
  } = useForm<PayRuleFormType>({
    defaultValues: editingRule || {
      key: uuidv4(),
      start_time: "00:00",
      end_time: "00:00",
    },
  });

  const onSubmit = (rule: PayRuleFormType) => {
    if (!rule.weekdays || rule.weekdays.length === 0) {
      setError("weekdays", {
        type: "manual",
        message: "Please select at least one weekday",
      });

      return undefined;
    }

    // sort the weekdays! (I don't think this is actually required,
    // but definitely doesn't hurt)
    rule.weekdays = sortWeekdays(rule.weekdays);

    const startTimeBeforeEndTime =
      new Date("01/01/2022 " + rule.start_time) <
      new Date("01/01/2022 " + rule.end_time);
    if (rule.end_time !== "00:00" && !startTimeBeforeEndTime) {
      setError("start_time", {
        type: "manual",
        message: "Start time should be before end time",
      });

      return undefined;
    }

    reset();

    if (rule.rate_pounds === "") {
      rule.rate_pounds = "0";
    }

    if (editingRule) {
      return onEdit(rule);
    }

    return onAdd(rule);
  };

  const wrappedOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      isOpen
      title={"Configure a weekly rule"}
      disableQuickClose
      onClose={wrappedOnClose}
      onSubmit={handleSubmit(onSubmit)}
      as={"form"}
      analyticsTrackingId={"schedule-pay-rule-modal"}
    >
      <ModalContent className="space-y-4">
        <ControlledInputWrapper
          label="Which days should this rule apply to?"
          control={control}
          id="weekdays"
          errors={errors}
          render={({ field: { onChange, value } }) => (
            <CheckboxGroup
              className="mt-2"
              showSelectAll
              options={weekdaySelectOptions}
              onChange={onChange}
              value={value as string[]}
            />
          )}
        ></ControlledInputWrapper>

        <FormInputWrapper
          label="Start time"
          errors={errors}
          id="start_time"
          helptext="When should this rule apply from? (use 00:00 for the start of the day)"
        >
          <Input
            id="start_time"
            {...register("start_time", {
              required: "Please enter an start time",
            })}
            type={InputType.Time}
          />
        </FormInputWrapper>
        <FormInputWrapper
          label="End time"
          errors={errors}
          id="end_time"
          helptext="When should this rule stop applying? (use 00:00 for the end of the day)"
        >
          <Input
            id="end_time"
            {...register("end_time", {
              required: "Please enter an end time",
            })}
            type={InputType.Time}
          />
        </FormInputWrapper>
        <Callout theme={CalloutTheme.Plain}>
          <TimezoneHelperText />
        </Callout>

        <FormInputWrapper
          label="How much do you pay per hour during this time range?"
          errors={errors}
          id="rate_pounds"
        >
          <CurrencyInput
            currency={selectedCurrency}
            id="rate_pounds"
            defaultValue={editingRule?.rate_pounds}
            {...register("rate_pounds", {
              validate: currencyValidationRules,
            })}
          />
          <p className="text-content-tertiary text-xs mt-2">
            {`As a reminder, your base rate for this pay configuration is ${formatCurrency(
              selectedCurrency,
              currencyStrToCents(baseRate),
            )} per hour.`}
          </p>
        </FormInputWrapper>
      </ModalContent>
      <ModalFooter onClose={wrappedOnClose} confirmButtonType="submit" />
    </Modal>
  );
};
