import { IconEnum } from "@incident-ui";
import AtlassianStatuspageLogo from "src/components/timeline/atlassian_statuspage_logo.svg";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

import {
  statuspageUpdateTitle,
  TimelineItemAtlassianStatuspageUpdateContentBox,
} from "./TimelineItemAtlassianStatuspageUpdateContentBox";

export const TimelineItemAtlassianStatuspageUpdate = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.atlassian_statuspage_update) {
    throw new Error(
      "malformed timeline item: statuspage_update was missing statuspage_update field",
    );
  }

  return {
    icon: IconEnum.Announcement,
    avatarUrl: AtlassianStatuspageLogo,
    secondaryAvatarUrl:
      item.atlassian_statuspage_update.updater?.user?.avatar_url,
    description: statuspageUpdateTitle(item.atlassian_statuspage_update),
    children: (
      <TimelineItemAtlassianStatuspageUpdateContentBox
        hidden={false}
        statuspageUpdates={[item.atlassian_statuspage_update]}
      />
    ),
  };
};
