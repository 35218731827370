import { Trigger } from "@incident-io/api";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { Icon, IconEnum, IconSize } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import { toSentenceCase } from "../../../../utils/formatting";
import { groupToColorPalette } from "./WorkflowChooseTriggerForm";

type WorkflowActiveTriggerCard = {
  trigger: Trigger;
  iconName: IconEnum;
  deleteButton?: React.ReactElement;
};

export function WorkflowActiveTriggerCard(
  props: WorkflowActiveTriggerCard,
): React.ReactElement {
  const { trigger, iconName, deleteButton } = props;

  const colorPalette = groupToColorPalette(
    trigger?.group_label || ColorPaletteEnum.Blue,
  );
  const colors = getColorPalette(colorPalette);

  return (
    <div className="flex flex-row items-center p-3 border rounded-[6px] bg-surface-secondary border-stroke">
      <div
        className={tcx(
          "flex-center !min-w-[36px] !max-w-[36px] !max-h-[36px] !min-h-[36px] p-[5px] mr-[10px] rounded",
          colors.background,
          colors.border,
          colors.text,
        )}
      >
        <Icon id={iconName} size={IconSize.Large} className={colors.icon} />
      </div>
      <div className="text-sm text-content-primary font-medium">
        {toSentenceCase(trigger.label)}
      </div>
      {deleteButton && (
        <div className="hover:cursor-pointer ml-auto flex items-center">
          {deleteButton}
        </div>
      )}
    </div>
  );
}
