/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomEventPayload,
    CustomEventPayloadFromJSON,
    CustomEventPayloadFromJSONTyped,
    CustomEventPayloadToJSON,
} from './CustomEventPayload';
import {
    TimelineNotePayload,
    TimelineNotePayloadFromJSON,
    TimelineNotePayloadFromJSONTyped,
    TimelineNotePayloadToJSON,
} from './TimelineNotePayload';

/**
 * 
 * @export
 * @interface TimelineItemsCreateRequestBody
 */
export interface TimelineItemsCreateRequestBody {
    /**
     * 
     * @type {CustomEventPayload}
     * @memberof TimelineItemsCreateRequestBody
     */
    custom_event?: CustomEventPayload;
    /**
     * Unique ID of the incident to add the timeline item to
     * @type {string}
     * @memberof TimelineItemsCreateRequestBody
     */
    incident_id: string;
    /**
     * Type of timeline item
     * @type {string}
     * @memberof TimelineItemsCreateRequestBody
     */
    item_type: TimelineItemsCreateRequestBodyItemTypeEnum;
    /**
     * When the item occured
     * @type {Date}
     * @memberof TimelineItemsCreateRequestBody
     */
    occured_at: Date;
    /**
     * 
     * @type {TimelineNotePayload}
     * @memberof TimelineItemsCreateRequestBody
     */
    timeline_note?: TimelineNotePayload;
}

/**
* @export
* @enum {string}
*/
export enum TimelineItemsCreateRequestBodyItemTypeEnum {
    CustomEvent = 'custom_event',
    TimelineNote = 'timeline_note'
}

export function TimelineItemsCreateRequestBodyFromJSON(json: any): TimelineItemsCreateRequestBody {
    return TimelineItemsCreateRequestBodyFromJSONTyped(json, false);
}

export function TimelineItemsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineItemsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_event': !exists(json, 'custom_event') ? undefined : CustomEventPayloadFromJSON(json['custom_event']),
        'incident_id': json['incident_id'],
        'item_type': json['item_type'],
        'occured_at': (new Date(json['occured_at'])),
        'timeline_note': !exists(json, 'timeline_note') ? undefined : TimelineNotePayloadFromJSON(json['timeline_note']),
    };
}

export function TimelineItemsCreateRequestBodyToJSON(value?: TimelineItemsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_event': CustomEventPayloadToJSON(value.custom_event),
        'incident_id': value.incident_id,
        'item_type': value.item_type,
        'occured_at': (value.occured_at.toISOString()),
        'timeline_note': TimelineNotePayloadToJSON(value.timeline_note),
    };
}

