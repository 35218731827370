import { tcx } from "src/utils/tailwind-classes";

export const SecondaryPageWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={tcx("w-full flex-col pt-4 space-y-4", className)}>
      {children}
    </div>
  );
};
