import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  IconEnum,
  Loader,
  Tooltip,
} from "@incident-ui";
import React from "react";
import {
  Condition,
  ConditionGroup,
  EngineScope,
  Resource,
} from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";
import { filterScope } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";

import { AddConditionOrGroupPopover } from "./AddConditionGroupPopover";
import { ConditionMenuEntry } from "./AddConditionPopover";
import {
  ConditionsListSentenceProps,
  IntroSentence,
} from "./EditableConditionsList";
import { SelectedCondition } from "./SelectedCondition";

export type ExplanationStyle = "available" | "apply";

export type EditableConditionGroupListMutations = {
  onAddGroup: () => void;
  onDeleteGroup: (idx: number) => void;
  onAddCondition: (groupIdx: number, condition: Condition) => void;
  onUpdateCondition: (
    groupIdx: number,
    idx: number,
    condition: Condition,
  ) => void;
  onDeleteCondition: (groupIdx: number, idx: number) => void;
};

export type FixedConditionGroupsProps = {
  fixedConditionGroups?: ConditionGroup[];
  fixedConditionExplainText?: React.ReactNode;
};

export type EditableConditionGroupsListProps = {
  selectedConditionGroups: ConditionGroup[];
  scope: EngineScope;
  wrapperClassName?: string;
  listClassName?: string;
  // boxless is used for contexts where we don't want a container (e.g. inside expressions)
  boxless?: boolean;
  iconless?: boolean;
  hideConditionLabel?: boolean;
  conditionLabel?: "condition" | "requirement" | "filter"; // The way we describe conditions to the user, e.g. `Edit {label}`. The default is `condition`.
  disabled?: boolean;
  allowFilteringByExpression?: boolean;
  collapseButtons?: boolean;
  isSelectable?: (entry: ConditionMenuEntry) => boolean;
};

type AllEditableConditionGroupsProps = EditableConditionGroupsListProps &
  ConditionsListSentenceProps &
  FixedConditionGroupsProps & {
    mutations: EditableConditionGroupListMutations;
  };

// EditableConditionGroupsList displays a list of selected engine condition, with hooks to
// edit/update/remove the conditions.
export function EditableConditionGroupsList({
  selectedConditionGroups,
  scope,
  wrapperClassName,
  listClassName = "space-y-1",
  conditionLabel = "condition",
  disabled = false,
  allowFilteringByExpression = false,
  isSelectable: customIsSelectable,
  boxless = false,
  iconless = false,
  hideConditionLabel = false,
  fixedConditionGroups = [],
  fixedConditionExplainText = "These conditions are fixed and cannot be removed.",
  mutations,
  ...sentenceProps
}: AllEditableConditionGroupsProps): React.ReactElement {
  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }

  if (resourcesLoading) {
    return <Loader />;
  }

  const isEmpty =
    fixedConditionGroups.length === 0 && selectedConditionGroups?.length === 0;

  const hasFixedConditions =
    fixedConditionGroups.flatMap((g) => g.conditions).length > 0;

  let groupsIncludingFake = selectedConditionGroups;
  if (selectedConditionGroups?.length === 0) {
    groupsIncludingFake = [{ conditions: [] }];
  }

  return (
    <div className="flex flex-col gap-2">
      <IntroSentence {...sentenceProps} isEmpty={isEmpty} />
      {hasFixedConditions && (
        <>
          <ContentBox
            className={tcx(
              "flex flex-col justify-center bg-white text-sm space-y-2",
              { "border-none shadow-none": boxless },
              wrapperClassName,
            )}
            data-testid="conditions-list"
          >
            <Tooltip
              analyticsTrackingId={null}
              content={fixedConditionExplainText}
              delayDuration={0}
            >
              {/* We need this div so that the tooltip magic can position itself correctly */}
              <div className="w-fit">
                {fixedConditionGroups.map((group, groupIdx) => (
                  <ConditionGroupSection
                    key={groupIdx}
                    group={group}
                    groupIdx={groupIdx}
                    scope={scope}
                    resources={resources}
                    allowExpressions={allowFilteringByExpression}
                    fixedConditionGroups={[]}
                    conditionLabel={conditionLabel}
                    disabled={disabled}
                    boxless={boxless}
                    iconless={iconless}
                    customIsSelectable={customIsSelectable}
                  />
                ))}
              </div>
            </Tooltip>
          </ContentBox>
          <div className="font-mono text-xs-med text-content-tertiary">AND</div>
        </>
      )}
      <ContentBox
        className={tcx(
          "flex flex-col justify-centerbg-white text-sm space-y-2 max-w-4xl",
          { "border-none shadow-none": boxless },
          wrapperClassName,
        )}
        data-testid="conditions-list"
      >
        <div className={tcx("flex flex-col", listClassName)}>
          {groupsIncludingFake.map((group, groupIdx) => (
            <ConditionGroupSection
              key={groupIdx}
              group={group}
              groupIdx={groupIdx}
              scope={scope}
              resources={resources}
              mutations={mutations}
              fixedConditionGroups={fixedConditionGroups}
              conditionLabel={conditionLabel}
              customIsSelectable={customIsSelectable}
              allowExpressions={allowFilteringByExpression}
              disabled={disabled}
              boxless={boxless}
              iconless={iconless}
              hideConditionLabel={hideConditionLabel}
            />
          ))}
        </div>
      </ContentBox>
    </div>
  );
}

const ConditionGroupSection = ({
  group,
  groupIdx,
  scope,
  resources,
  mutations,
  fixedConditionGroups,
  allowExpressions,
  customIsSelectable,
  conditionLabel,
  disabled,
  boxless,
  iconless,
  hideConditionLabel,
}: {
  group: ConditionGroup;
  groupIdx: number;
  fixedConditionGroups: ConditionGroup[];
  scope: EngineScope;
  resources: Resource[];
  allowExpressions: boolean;
  disabled?: boolean;
  boxless?: boolean;
  iconless?: boolean;
  hideConditionLabel?: boolean;
  customIsSelectable?: (entry: ConditionMenuEntry) => boolean;
  conditionLabel?: "condition" | "requirement" | "filter";
  mutations?: EditableConditionGroupListMutations;
}) => {
  const { hasAvailableReferences, isSelectable } = getIsSelectable(
    fixedConditionGroups,
    group.conditions,
    scope,
    customIsSelectable,
  );

  const isFirstGroup = groupIdx === 0;
  const isEditable = !!mutations;

  return (
    <div
      className={tcx("flex flex-wrap gap-2", {
        "p-3": !boxless,
        "border-t border-dotted border-stroke": !isFirstGroup,
      })}
    >
      {/* Header section, if not the first */}
      {!isFirstGroup && (
        <div className="flex justify-between gap-2 w-full text-content-tertiary">
          <div className="font-mono text-xs-med">OR</div>
          {isEditable && (
            <Button
              analyticsTrackingId={null}
              icon={IconEnum.Close}
              size={ButtonSize.Small}
              theme={ButtonTheme.Naked}
              title="Remove condition"
              onClick={() => mutations.onDeleteGroup(groupIdx)}
              className="text-content-tertiary"
            />
          )}
        </div>
      )}
      {group.conditions.map((condition, conditionIdx) => (
        <SelectedCondition
          scopeLoading={false}
          key={condition.subject.reference}
          allowExpressions={allowExpressions}
          condition={condition}
          scope={scope}
          resources={resources}
          noTooltip={!isEditable}
          onDelete={
            disabled || !isEditable
              ? undefined
              : () => mutations.onDeleteCondition(groupIdx, conditionIdx)
          }
          // Make the badge look disabled when it's a fixed condition
          theme={"slate"}
          color={isEditable ? undefined : ColorPaletteEnum.Slate}
          className={isEditable ? undefined : "text-content-tertiary"}
          hideIcon={iconless}
          handleEdit={
            isEditable
              ? (newCondition: Condition) =>
                  mutations.onUpdateCondition(
                    groupIdx,
                    conditionIdx,
                    newCondition,
                  )
              : undefined
          }
        />
      ))}
      {isEditable && (
        <AddConditionOrGroupPopover
          allowExpressions={allowExpressions}
          thisGroupConditions={group.conditions}
          isSelectable={isSelectable}
          scope={scope}
          resources={resources}
          onAddCondition={(condition) =>
            mutations.onAddCondition(groupIdx, condition)
          }
          onAddGroup={mutations.onAddGroup}
          renderTriggerButton={({ onClick }) => (
            <Button
              analyticsTrackingId={null}
              size={ButtonSize.Small}
              icon={IconEnum.Add}
              theme={ButtonTheme.Secondary}
              title={`Add ${conditionLabel}`}
              onClick={onClick}
              disabled={!hasAvailableReferences || disabled}
            >
              {group.conditions.length === 0 && !hideConditionLabel
                ? `Add ${conditionLabel}`
                : undefined}
            </Button>
          )}
        />
      )}
    </div>
  );
};

const getIsSelectable = (
  fixedConditionGroups: ConditionGroup[],
  groupConditions: Condition[],
  scope: EngineScope,
  isSelectable?: (entry: ConditionMenuEntry) => boolean,
): {
  hasAvailableReferences: boolean;
  isSelectable: (entry: ConditionMenuEntry) => boolean;
} => {
  const keysInUse: string[] = [];

  fixedConditionGroups.forEach((group) => {
    group.conditions.forEach((condition) => {
      keysInUse.push(condition.subject.reference);
    });
  });

  groupConditions.forEach((condition) => {
    keysInUse.push(condition.subject.reference);
  });

  const availableReferences = filterScope(
    scope,
    (c) => !keysInUse.includes(c.key),
  );

  return {
    hasAvailableReferences: availableReferences.references.length > 0,
    isSelectable: (entry: ConditionMenuEntry): boolean => {
      if (!hasOperations(entry)) {
        return false;
      }

      if (keysInUse.includes(entry.key)) {
        return false;
      }

      if (!!isSelectable && !isSelectable(entry)) {
        return false;
      }

      return true;
    },
  };
};

const hasOperations = (entry: ConditionMenuEntry): boolean => {
  if (entry.array) {
    return entry.resource.array_operations.length > 0;
  } else {
    return entry.resource.operations.length > 0;
  }
};
