import { IconEnum } from "@incident-ui";
import React from "react";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemSource } from "src/components/timeline/TimelineItemSource";
import {
  Incident,
  RoleUpdate,
  TimelineItemObject,
} from "src/contexts/ClientContext";

export const roleUpdateText = (
  _: Incident,
  role_update: RoleUpdate,
  isStream: boolean,
): React.ReactElement => {
  const source = <TimelineItemSource actor={role_update.updater} />;
  const roleName =
    isStream && role_update.role.stream_name
      ? role_update.role.stream_name
      : role_update.role.name;

  if (
    role_update.to_user &&
    role_update.updater?.user?.id === role_update.to_user.id
  ) {
    // This is a self assignment
    if (role_update.from_user && role_update.from_user.id !== "") {
      // They're taking over from someone else
      return (
        <>
          {source} assigned themselves to the <strong>{roleName}</strong> role,
          taking over from <strong>{role_update.from_user.name}</strong>
        </>
      );
    } else {
      // This is the first role change
      return (
        <>
          {source} became the <strong>{roleName}</strong>
        </>
      );
    }
  }

  // Someone is assigning someone else to the role
  if (role_update.to_user && role_update.to_user.id === "") {
    // They're unassigning the role
    return (
      <>
        {source} unassigned the <strong>{roleName}</strong>
      </>
    );
  } else if (
    role_update.from_user &&
    role_update.updater?.user?.id === role_update.from_user.id
  ) {
    // They're changing it from themselves to someone else
    return (
      <>
        {source} handed over the <strong>{roleName}</strong> role to{" "}
        <strong>{role_update.to_user?.name}</strong>
      </>
    );
  } else if (role_update.from_user && role_update.from_user.id !== "") {
    // They're replacing the existing person assigned to the role
    return (
      <>
        {source} assigned <strong>{role_update.to_user?.name}</strong> to the{" "}
        <strong>{roleName}</strong> role, taking over from{" "}
        <strong>{role_update.from_user.name}</strong>
      </>
    );
  } else {
    // They're setting the role for the first time
    return (
      <>
        {source} assigned <strong>{role_update.to_user?.name}</strong> to the{" "}
        <strong>{roleName}</strong> role
      </>
    );
  }
};

export const TimelineItemRoleUpdate = (
  incident: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.role_update) {
    throw new Error(
      "malformed timeline item: role_update was missing role_update field",
    );
  }
  const avatarUrl =
    item.role_update.to_user && item.role_update.to_user.avatar_url !== ""
      ? item.role_update.to_user.avatar_url
      : item.role_update.updater?.user?.avatar_url;

  return {
    description: roleUpdateText(incident, item.role_update, !!item.is_stream),
    icon: IconEnum.SwitchHorizontal,
    avatarUrl: avatarUrl,
  };
};
