import { Product } from "@incident-shared/billing";
import { CommaSeparatedConditions } from "@incident-shared/engine/conditions/CommaSeparatedConditions";
import { conditionsToPayload } from "@incident-shared/engine/conditions/marshall";
import { ConditionsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionsEditorV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  ContentBox,
  IconEnum,
  ModalFooter,
  StackedList,
  ToastTheme,
} from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { SettingsSubHeading } from "src/components/settings/SettingsSubHeading";
import {
  ConditionSubjectIconEnum,
  EngineParamBindingValue,
  EngineScope,
  IncidentAutoSubscribeRule,
  Resource,
} from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { v4 as uuidv4 } from "uuid";

type Rule = IncidentAutoSubscribeRule;

const PLACEHOLDER_ID = "placeholder";

export const AutoSubscribeForm = ({
  scope,
}: {
  scope: EngineScope;
}): React.ReactElement => {
  const [deleteRuleId, setDeleteRuleId] = useState<string | null>(null);
  const [ruleModalState, setRuleModalState] = useState<{
    showModal: boolean;
    rule?: Rule;
  }>({ showModal: false });

  const {
    data: { incident_auto_subscribe_rules: allRules },
    isLoading: loading,
  } = useAPI("incidentSubscriptionsListAutoSubscribeRules", undefined, {
    fallbackData: { incident_auto_subscribe_rules: [] },
  });

  const { resources, resourcesLoading } = useAllResources();

  const rules = allRules.filter(
    (rule) => rule.internal_status_page === undefined,
  );

  const { trigger: onDelete, isMutating: isDeleting } = useAPIMutation(
    "incidentSubscriptionsListAutoSubscribeRules",
    undefined,
    async (apiClient, { id }: { id: string }) =>
      await apiClient.incidentSubscriptionsDestroyAutoSubscribeRule({ id }),
    {
      onSuccess: () => setDeleteRuleId(null),
    },
  );

  const showToast = useToast();
  const { isMutating: placeholderRuleSaving, trigger: savePlaceholderRule } =
    useAPIMutation(
      "incidentSubscriptionsListAutoSubscribeRules",
      undefined,
      async (apiClient, data) => {
        await apiClient.incidentSubscriptionsCreateAutoSubscribeRule({
          createAutoSubscribeRuleRequestBody: {
            conditions: conditionsToPayload(data.conditions),
          },
        });
      },
      {
        onError: () =>
          showToast({
            theme: ToastTheme.Error,
            title: "Something went wrong",
          }),
      },
    );

  const topSeverities = useMemo(
    () =>
      resources && !resourcesLoading ? getTopNSeverities(resources, 2) : [],
    [resources, resourcesLoading],
  );

  const placeholderRule = createPlaceholderRule(topSeverities);

  return (
    <ContentBox className="p-6">
      <LoadingWrapper loading={loading || resourcesLoading}>
        <SettingsSubHeading
          title="Auto-subscribe"
          explanation={`Set up rules for any incidents that you'd like to automatically be subscribed to, such as "all critical incidents" or "incidents belonging to my team".`}
        />
        {rules.length === 0 ? (
          <>
            <h3 className="font-medium text-sm mb-2">
              Here&apos;s an example rule to get you started, would you like to
              add it?
            </h3>
            <StackedList className="mb-4">
              <AutoSubscribeRuleRow
                isFirst
                isPlaceholder
                isSaving={placeholderRuleSaving}
                rule={placeholderRule}
                onEdit={() => {
                  savePlaceholderRule(placeholderRule);
                }}
              />
            </StackedList>
          </>
        ) : (
          <StackedList className={"mb-4"}>
            {rules.map((rule, i) => (
              <AutoSubscribeRuleRow
                isFirst={i === 0}
                key={rule.id}
                rule={rule}
                onDelete={() => setDeleteRuleId(rule.id)}
                onEdit={() => {
                  setRuleModalState({ showModal: true, rule });
                }}
              />
            ))}
          </StackedList>
        )}
        <GatedButton
          theme={ButtonTheme.Secondary}
          analyticsTrackingId="add-autosubscribe-rule"
          requiredProduct={Product.Response}
          onClick={() => {
            setRuleModalState({ showModal: true });
          }}
        >
          Add auto-subscribe rule
        </GatedButton>
        {ruleModalState.showModal && (
          <AutoSubscribeRuleCreateOrEditModal
            scope={scope}
            existingRule={ruleModalState.rule}
            onClose={() => setRuleModalState({ showModal: false })}
            onComplete={() => {
              setRuleModalState({ showModal: false });
            }}
          />
        )}
        <ConfirmationDialog
          title="Delete auto-subscribe rule"
          isOpen={deleteRuleId != null}
          analyticsTrackingId="delete-auto-subscribe-rule"
          onCancel={() => setDeleteRuleId(null)}
          onConfirm={() => onDelete({ id: deleteRuleId ?? "" })}
          saving={isDeleting}
        >
          Are you sure you want to delete this auto-subscribe rule?
        </ConfirmationDialog>
      </LoadingWrapper>
    </ContentBox>
  );
};

type AutoSubscribeRuleRowProps = {
  rule: Rule;
  onEdit: () => void;
} & (
  | {
      isFirst: boolean;
      isPlaceholder?: never;
      isSaving?: never;
      onDelete: () => void;
    }
  | {
      isFirst: true;
      isPlaceholder: true;
      isSaving: boolean;
      onDelete?: never;
    }
);

function AutoSubscribeRuleRow({
  rule,
  isPlaceholder,
  isSaving,
  onEdit,
  onDelete,
  isFirst,
}: AutoSubscribeRuleRowProps): React.ReactElement {
  return (
    <li
      className={
        "px-4 pt-3 pb-2 flex flex-row content-between bg-surface-secondary gap-1"
      }
    >
      <div
        className={tcx("grow flex flex-row", {
          "opacity-50": isPlaceholder,
        })}
      >
        <div
          className={
            "text-sm font-medium flex-shrink-0 w-16 my-1.5 text-slate-700"
          }
        >
          {isFirst || isPlaceholder ? "When" : "Or"}...
        </div>
        <div className={"grow flex items-center gap-1 flex-wrap"}>
          <CommaSeparatedConditions
            conditions={rule.conditions}
            theme="white"
          />
        </div>
      </div>
      {isPlaceholder ? (
        <GatedButton
          theme={ButtonTheme.Secondary}
          analyticsTrackingId={"default-rule-confirm"}
          onClick={onEdit}
          requiredProduct={Product.Response}
          loading={isSaving}
        >
          Add
        </GatedButton>
      ) : (
        <div className={"shrink-0 mt-1.5 flex items-start gap-1"}>
          <Button
            analyticsTrackingId="expression-condition-edit-rule"
            theme={ButtonTheme.Naked}
            onClick={onEdit}
            icon={IconEnum.Edit}
            title="Edit"
          />
          <Button
            analyticsTrackingId="expression-condition-remove-rule"
            theme={ButtonTheme.Naked}
            onClick={onDelete}
            icon={IconEnum.Delete}
            title="Remove rule"
          />
        </div>
      )}
    </li>
  );
}

export const AutoSubscribeRuleCreateOrEditModal = ({
  existingRule,
  scope,
  onClose,
  onComplete,
}: {
  scope: EngineScope;
  existingRule: Rule | undefined;
  onComplete: () => void;
  onClose: () => void;
}): React.ReactElement | null => {
  const defaultValues: Rule = existingRule
    ? existingRule
    : {
        id: uuidv4(),
        conditions: [],
        created_at: new Date(),
        updated_at: new Date(),
      };

  const formMethods = useForm<Rule>({
    defaultValues,
  });

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "incidentSubscriptionsListAutoSubscribeRules",
    undefined,
    async (apiClient, data: Rule) => {
      const conditions = conditionsToPayload(data.conditions);
      if (existingRule) {
        await apiClient.incidentSubscriptionsUpdateAutoSubscribeRule({
          id: existingRule.id,
          updateAutoSubscribeRuleRequestBody: {
            conditions,
          },
        });
      } else {
        await apiClient.incidentSubscriptionsCreateAutoSubscribeRule({
          createAutoSubscribeRuleRequestBody: { conditions },
        });
      }
    },
    {
      setError: formMethods.setError,
      onSuccess: onComplete,
    },
  );

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={onClose}
      analyticsTrackingId={
        existingRule ? "edit-autosubscribe-rule" : "create-autosubscribe-rule"
      }
      title={
        existingRule
          ? "Edit auto-subscribe rule"
          : "Create new auto-subscribe rule"
      }
      disableQuickClose
      loading={saving}
      footer={
        <ModalFooter
          confirmButtonText={existingRule ? "Save" : "Create"}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      {/* Conditions */}
      <ConditionsEditorV2
        formMethods={formMethods}
        name="conditions"
        scope={scope}
        wrapperClassName="!bg-white"
        emptyIntroSentence={
          <p>
            You will be subscribed to{" "}
            <span className="font-medium">all incidents</span>.
          </p>
        }
        populatedIntroSentence="You will be subscribed to incidents where..."
      />
    </FormModalV2>
  );
};

const getTopNSeverities = (resources: Resource[], n: number) => {
  const severity = resources.find((r) => r.type === "IncidentSeverity");
  return _.sortBy(severity?.options ?? [], "sort_key")
    .slice(-n)
    .map(
      (o): EngineParamBindingValue => ({
        sort_key: o.sort_key,
        literal: o.value,
        label: o.label,
      }),
    );
};

function createPlaceholderRule(topSeverities: EngineParamBindingValue[]) {
  return {
    conditions: [
      {
        operation: {
          label: "is one of",
          value: "one_of",
        },
        param_bindings: [
          {
            array_value: topSeverities,
          },
        ],
        params: [
          {
            array: true,
            description: "",
            label: "set",
            name: "set",
            optional: false,
            type: "IncidentSeverity",
            infer_reference: false,
          },
        ],
        subject: {
          icon: ConditionSubjectIconEnum.Severity,
          label: "Incident Severity",
          reference: "incident.severity",
        },
      },
    ],
    created_at: new Date(),
    updated_at: new Date(),
    id: PLACEHOLDER_ID,
  };
}
