import { IconEnum } from "@incident-ui";
import {
  Attachment,
  AttachmentContexts,
} from "src/components/legacy/incident/attachments/IncidentAttachment";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemContentBox } from "src/components/timeline/TimelineItemContentBox";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

export const TimelineItemPagerdutyIncidentTriggered = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.pagerduty_incident_triggered) {
    throw new Error(
      "malformed timeline item: pagerduty_incident_triggered was missing pagerduty_incident_triggered field",
    );
  }

  return {
    icon: IconEnum.Attachment,
    description: (
      <>
        An attached{" "}
        {
          item.pagerduty_incident_triggered.external_resource
            .resource_type_label
        }{" "}
        was triggered
      </>
    ),
    children: (
      <TimelineItemContentBox>
        <Attachment
          context={AttachmentContexts.Timeline}
          resource={item.pagerduty_incident_triggered.external_resource}
        />
      </TimelineItemContentBox>
    ),
  };
};
