/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalStatusPageIncident,
    InternalStatusPageIncidentFromJSON,
    InternalStatusPageIncidentFromJSONTyped,
    InternalStatusPageIncidentToJSON,
} from './InternalStatusPageIncident';
import {
    InternalStatusPageStructure,
    InternalStatusPageStructureFromJSON,
    InternalStatusPageStructureFromJSONTyped,
    InternalStatusPageStructureToJSON,
} from './InternalStatusPageStructure';

/**
 * 
 * @export
 * @interface InternalStatusPageContentSummary
 */
export interface InternalStatusPageContentSummary {
    /**
     * Whether or not groups should be expanded by default
     * @type {boolean}
     * @memberof InternalStatusPageContentSummary
     */
    auto_expand_groups: boolean;
    /**
     * The ID of the internal status page
     * @type {string}
     * @memberof InternalStatusPageContentSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InternalStatusPageContentSummary
     */
    logo_url?: string;
    /**
     * The name of the internal status page
     * @type {string}
     * @memberof InternalStatusPageContentSummary
     */
    name: string;
    /**
     * Current ongoing incidents
     * @type {Array<InternalStatusPageIncident>}
     * @memberof InternalStatusPageContentSummary
     */
    ongoing_incidents: Array<InternalStatusPageIncident>;
    /**
     * 
     * @type {InternalStatusPageStructure}
     * @memberof InternalStatusPageContentSummary
     */
    structure: InternalStatusPageStructure;
    /**
     * The subpath of the internal status page
     * @type {string}
     * @memberof InternalStatusPageContentSummary
     */
    subpath: string;
    /**
     * Whether to use Light mode or Dark mode
     * @type {string}
     * @memberof InternalStatusPageContentSummary
     */
    theme: InternalStatusPageContentSummaryThemeEnum;
    /**
     * In the headsup bar at the top of an internal status page, configure if groups or components are shown
     * @type {boolean}
     * @memberof InternalStatusPageContentSummary
     */
    use_currently_affected_components_over_groups: boolean;
}

/**
* @export
* @enum {string}
*/
export enum InternalStatusPageContentSummaryThemeEnum {
    Light = 'light',
    Dark = 'dark'
}

export function InternalStatusPageContentSummaryFromJSON(json: any): InternalStatusPageContentSummary {
    return InternalStatusPageContentSummaryFromJSONTyped(json, false);
}

export function InternalStatusPageContentSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageContentSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_expand_groups': json['auto_expand_groups'],
        'id': json['id'],
        'logo_url': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'name': json['name'],
        'ongoing_incidents': ((json['ongoing_incidents'] as Array<any>).map(InternalStatusPageIncidentFromJSON)),
        'structure': InternalStatusPageStructureFromJSON(json['structure']),
        'subpath': json['subpath'],
        'theme': json['theme'],
        'use_currently_affected_components_over_groups': json['use_currently_affected_components_over_groups'],
    };
}

export function InternalStatusPageContentSummaryToJSON(value?: InternalStatusPageContentSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_expand_groups': value.auto_expand_groups,
        'id': value.id,
        'logo_url': value.logo_url,
        'name': value.name,
        'ongoing_incidents': ((value.ongoing_incidents as Array<any>).map(InternalStatusPageIncidentToJSON)),
        'structure': InternalStatusPageStructureToJSON(value.structure),
        'subpath': value.subpath,
        'theme': value.theme,
        'use_currently_affected_components_over_groups': value.use_currently_affected_components_over_groups,
    };
}

