/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeatureGates,
    FeatureGatesFromJSON,
    FeatureGatesFromJSONTyped,
    FeatureGatesToJSON,
} from './FeatureGates';
import {
    MSTeamsInfo,
    MSTeamsInfoFromJSON,
    MSTeamsInfoFromJSONTyped,
    MSTeamsInfoToJSON,
} from './MSTeamsInfo';
import {
    OnboardingInformation,
    OnboardingInformationFromJSON,
    OnboardingInformationFromJSONTyped,
    OnboardingInformationToJSON,
} from './OnboardingInformation';
import {
    Scope,
    ScopeFromJSON,
    ScopeFromJSONTyped,
    ScopeToJSON,
} from './Scope';
import {
    SlackInfo,
    SlackInfoFromJSON,
    SlackInfoFromJSONTyped,
    SlackInfoToJSON,
} from './SlackInfo';
import {
    SlackTokenScope,
    SlackTokenScopeFromJSON,
    SlackTokenScopeFromJSONTyped,
    SlackTokenScopeToJSON,
} from './SlackTokenScope';
import {
    TrialStatus,
    TrialStatusFromJSON,
    TrialStatusFromJSONTyped,
    TrialStatusToJSON,
} from './TrialStatus';
import {
    TutorialInfo,
    TutorialInfoFromJSON,
    TutorialInfoFromJSONTyped,
    TutorialInfoToJSON,
} from './TutorialInfo';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Identity
 */
export interface Identity {
    /**
     * Organisation ID associated with the user making the request (when impersonating, this will be different from the organisation_id)
     * @type {string}
     * @memberof Identity
     */
    actor_organisation_id: string;
    /**
     * Organisation Slug associated with the user making the request (when impersonating, this will be different from the organisation_slug)
     * @type {string}
     * @memberof Identity
     */
    actor_organisation_slug: string;
    /**
     * URL for adding the app to the Slack Enterprise Grid.
     * @type {string}
     * @memberof Identity
     */
    add_to_slack_enterprise_url?: string;
    /**
     * URL for adding the app to the Slack workspace
     * @type {string}
     * @memberof Identity
     */
    add_to_slack_url: string;
    /**
     * Whether the app is currently installed to this workspace
     * @type {boolean}
     * @memberof Identity
     */
    app_installed: boolean;
    /**
     * The status of the user's Atlassian connection
     * @type {string}
     * @memberof Identity
     */
    atlassian_connection_status: IdentityAtlassianConnectionStatusEnum;
    /**
     * Whether this user can create retrospective incidents
     * @type {boolean}
     * @memberof Identity
     */
    can_create_retrospective_incidents: boolean;
    /**
     * Whether this user can create tutorial incidents
     * @type {boolean}
     * @memberof Identity
     */
    can_create_tutorial_incidents: boolean;
    /**
     * Whether this user can share postmortems
     * @type {boolean}
     * @memberof Identity
     */
    can_share_postmortems: boolean;
    /**
     * Message to display in an organisation set custom banner for their users
     * @type {string}
     * @memberof Identity
     */
    custom_banner_message?: string;
    /**
     * 
     * @type {FeatureGates}
     * @memberof Identity
     */
    feature_gates: FeatureGates;
    /**
     * When this user first logged in to the dashboard
     * @type {Date}
     * @memberof Identity
     */
    first_login_at: Date;
    /**
     * Whether the first time on call email has been sent
     * @type {boolean}
     * @memberof Identity
     */
    first_time_on_call_email_sent: boolean;
    /**
     * Whether this user has created a status page yet
     * @type {boolean}
     * @memberof Identity
     */
    has_created_a_status_page: boolean;
    /**
     * Whether the user has created a tutorial
     * @type {boolean}
     * @memberof Identity
     */
    has_created_tutorial: boolean;
    /**
     * Whether this user has reported an incident yet
     * @type {boolean}
     * @memberof Identity
     */
    has_reported_first_incident: boolean;
    /**
     * Whether the organisation has incident types turned on.
     * @type {boolean}
     * @memberof Identity
     */
    incident_types_enabled: boolean;
    /**
     * Hash of user ID for intercom security
     * @type {string}
     * @memberof Identity
     */
    intercom_user_id_hash: string;
    /**
     * 
     * @type {TutorialInfo}
     * @memberof Identity
     */
    last_tutorial_info?: TutorialInfo;
    /**
     * Slack token scopes that are missing, requiring a reinstall
     * @type {Array<SlackTokenScope>}
     * @memberof Identity
     */
    missing_slack_token_scopes: Array<SlackTokenScope>;
    /**
     * 
     * @type {MSTeamsInfo}
     * @memberof Identity
     */
    ms_teams_info?: MSTeamsInfo;
    /**
     * 
     * @type {OnboardingInformation}
     * @memberof Identity
     */
    onboarding_information: OnboardingInformation;
    /**
     * DEPRECATED: We have to keep this to avoid breaking old versions of the mobile app, but it'll always be empty
     * @type {Array<string>}
     * @memberof Identity
     */
    onboarding_tasks: Array<string>;
    /**
     * Whether all users or only admins/owners should see the Slack reinstall banner
     * @type {boolean}
     * @memberof Identity
     */
    only_admins_and_owners_see_reinstall_banner: boolean;
    /**
     * List of CTAs that the org has dismissed
     * @type {Array<string>}
     * @memberof Identity
     */
    org_dismissed_ctas: Array<string>;
    /**
     * List of products available to the organisation
     * @type {Array<string>}
     * @memberof Identity
     */
    organisation_available_products?: Array<IdentityOrganisationAvailableProductsEnum>;
    /**
     * When the organisation was created
     * @type {number}
     * @memberof Identity
     */
    organisation_created_at: number;
    /**
     * Whether this organisation has reported an incident yet
     * @type {boolean}
     * @memberof Identity
     */
    organisation_has_reported_first_incident: boolean;
    /**
     * URL of organisation icon
     * @type {string}
     * @memberof Identity
     */
    organisation_icon_url?: string;
    /**
     * Unique identifier for organisation
     * @type {string}
     * @memberof Identity
     */
    organisation_id: string;
    /**
     * Is the organisation marked as a demo account?
     * @type {boolean}
     * @memberof Identity
     */
    organisation_is_demo: boolean;
    /**
     * Is the organisation marked as a sandbox account?
     * @type {boolean}
     * @memberof Identity
     */
    organisation_is_sandbox: boolean;
    /**
     * Whether the organisation is the staff organisation
     * @type {boolean}
     * @memberof Identity
     */
    organisation_is_staff?: boolean;
    /**
     * Human name of the organisation
     * @type {string}
     * @memberof Identity
     */
    organisation_name: string;
    /**
     * Human readable unique identifier for organisation
     * @type {string}
     * @memberof Identity
     */
    organisation_slug: string;
    /**
     * Scopes attached to the user
     * @type {Array<Scope>}
     * @memberof Identity
     */
    scopes: Array<Scope>;
    /**
     * When did the current user last dismiss the set-up checklist
     * @type {Date}
     * @memberof Identity
     */
    setup_checklist_dismissed_at?: Date;
    /**
     * 
     * @type {SlackInfo}
     * @memberof Identity
     */
    slack_info?: SlackInfo;
    /**
     * A reason why we couldn't install our Slack app
     * @type {string}
     * @memberof Identity
     */
    slack_reconnection_reason: IdentitySlackReconnectionReasonEnum;
    /**
     * IDs of Slack teams that this user is a member of (only populated if org is Slack Enterprise Grid installed)
     * @type {Array<string>}
     * @memberof Identity
     */
    slack_team_ids?: Array<string>;
    /**
     * A reason why the Slack user token we have isn't happy
     * @type {string}
     * @memberof Identity
     */
    slack_user_reconnection_reason: IdentitySlackUserReconnectionReasonEnum;
    /**
     * A Slack interaction that requires a user token
     * @type {Array<string>}
     * @memberof Identity
     */
    slack_user_token_behaviours: Array<IdentitySlackUserTokenBehavioursEnum>;
    /**
     * 
     * @type {User}
     * @memberof Identity
     */
    slack_user_token_owner?: User;
    /**
     * The staff permissions this user has.
     * @type {Array<string>}
     * @memberof Identity
     */
    staff_permissions?: Array<IdentityStaffPermissionsEnum>;
    /**
     * 
     * @type {TrialStatus}
     * @memberof Identity
     */
    trial_status: TrialStatus;
    /**
     * URL of user avatar image
     * @type {string}
     * @memberof Identity
     */
    user_avatar_url?: string;
    /**
     * The slug of the user base role
     * @type {string}
     * @memberof Identity
     */
    user_base_role_slug: IdentityUserBaseRoleSlugEnum;
    /**
     * List of CTAs that the user has dismissed
     * @type {Array<string>}
     * @memberof Identity
     */
    user_dismissed_ctas: Array<string>;
    /**
     * Email of user
     * @type {string}
     * @memberof Identity
     */
    user_email: string;
    /**
     * Unique identifier for user in organisation
     * @type {string}
     * @memberof Identity
     */
    user_id: string;
    /**
     * The locale of the user
     * @type {string}
     * @memberof Identity
     */
    user_locale: string;
    /**
     * Full name of user
     * @type {string}
     * @memberof Identity
     */
    user_name: string;
    /**
     * What role does the current user have within Slack
     * @type {string}
     * @memberof Identity
     */
    user_slack_role: IdentityUserSlackRoleEnum;
    /**
     * Unique identifier for user in their Slack workspace
     * @type {string}
     * @memberof Identity
     */
    user_slack_user_id?: string;
    /**
     * The user's current state
     * @type {string}
     * @memberof Identity
     */
    user_state: IdentityUserStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum IdentityAtlassianConnectionStatusEnum {
    Connected = 'connected',
    Expired = 'expired'
}/**
* @export
* @enum {string}
*/
export enum IdentityOrganisationAvailableProductsEnum {
    Response = 'response',
    OnCall = 'on_call'
}/**
* @export
* @enum {string}
*/
export enum IdentitySlackReconnectionReasonEnum {
    Empty = '',
    TokenExpired = 'token_expired',
    MissingChannelCreatePermissions = 'missing_channel_create_permissions',
    AnnouncementChannelsAlreadyExist = 'announcement_channels_already_exist',
    InsufficientRole = 'insufficient_role',
    NoWorkspaces = 'no_workspaces'
}/**
* @export
* @enum {string}
*/
export enum IdentitySlackUserReconnectionReasonEnum {
    Empty = '',
    TokenExpired = 'token_expired',
    MissingChannelCreatePermissions = 'missing_channel_create_permissions',
    AnnouncementChannelsAlreadyExist = 'announcement_channels_already_exist',
    InsufficientRole = 'insufficient_role',
    NoWorkspaces = 'no_workspaces'
}/**
* @export
* @enum {string}
*/
export enum IdentitySlackUserTokenBehavioursEnum {
    CreatePublicChannel = 'create_public_channel',
    CreatePrivateChannel = 'create_private_channel',
    ArchivePublicChannel = 'archive_public_channel',
    ArchivePrivateChannel = 'archive_private_channel',
    UnarchivePublicChannel = 'unarchive_public_channel',
    UnarchivePrivateChannel = 'unarchive_private_channel',
    RemovePrivateChannelMember = 'remove_private_channel_member',
    AddChannelToMultipleWorkspaces = 'add_channel_to_multiple_workspaces',
    ManageUserGroup = 'manage_user_group'
}/**
* @export
* @enum {string}
*/
export enum IdentityStaffPermissionsEnum {
    AdjustPlan = 'adjust_plan',
    AttachSubscription = 'attach_subscription',
    BootstrapCustomerChannel = 'bootstrap_customer_channel',
    GrantStaffPermission = 'grant_staff_permission',
    Impersonate = 'impersonate',
    ImpersonateTrialAndDemo = 'impersonate_trial_and_demo',
    ManageFeatureAccess = 'manage_feature_access',
    ManageTrial = 'manage_trial',
    RenameOrganisation = 'rename_organisation',
    TeardownOrganisation = 'teardown_organisation',
    TeardownOnCallForOrganisation = 'teardown_on_call_for_organisation',
    EnableOnCallForOrganisation = 'enable_on_call_for_organisation',
    TeardownResponseForOrganisation = 'teardown_response_for_organisation',
    EnableResponseForOrganisation = 'enable_response_for_organisation',
    MarkSandboxAndDemo = 'mark_sandbox_and_demo',
    GrantOwnerRole = 'grant_owner_role'
}/**
* @export
* @enum {string}
*/
export enum IdentityUserBaseRoleSlugEnum {
    User = 'user',
    Admin = 'admin',
    Owner = 'owner'
}/**
* @export
* @enum {string}
*/
export enum IdentityUserSlackRoleEnum {
    Empty = '',
    Owner = 'owner',
    Admin = 'admin',
    Regular = 'regular'
}/**
* @export
* @enum {string}
*/
export enum IdentityUserStateEnum {
    Viewer = 'viewer',
    Responder = 'responder',
    OnCall = 'on_call',
    OnCallResponder = 'on_call_responder'
}

export function IdentityFromJSON(json: any): Identity {
    return IdentityFromJSONTyped(json, false);
}

export function IdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Identity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor_organisation_id': json['actor_organisation_id'],
        'actor_organisation_slug': json['actor_organisation_slug'],
        'add_to_slack_enterprise_url': !exists(json, 'add_to_slack_enterprise_url') ? undefined : json['add_to_slack_enterprise_url'],
        'add_to_slack_url': json['add_to_slack_url'],
        'app_installed': json['app_installed'],
        'atlassian_connection_status': json['atlassian_connection_status'],
        'can_create_retrospective_incidents': json['can_create_retrospective_incidents'],
        'can_create_tutorial_incidents': json['can_create_tutorial_incidents'],
        'can_share_postmortems': json['can_share_postmortems'],
        'custom_banner_message': !exists(json, 'custom_banner_message') ? undefined : json['custom_banner_message'],
        'feature_gates': FeatureGatesFromJSON(json['feature_gates']),
        'first_login_at': (new Date(json['first_login_at'])),
        'first_time_on_call_email_sent': json['first_time_on_call_email_sent'],
        'has_created_a_status_page': json['has_created_a_status_page'],
        'has_created_tutorial': json['has_created_tutorial'],
        'has_reported_first_incident': json['has_reported_first_incident'],
        'incident_types_enabled': json['incident_types_enabled'],
        'intercom_user_id_hash': json['intercom_user_id_hash'],
        'last_tutorial_info': !exists(json, 'last_tutorial_info') ? undefined : TutorialInfoFromJSON(json['last_tutorial_info']),
        'missing_slack_token_scopes': ((json['missing_slack_token_scopes'] as Array<any>).map(SlackTokenScopeFromJSON)),
        'ms_teams_info': !exists(json, 'ms_teams_info') ? undefined : MSTeamsInfoFromJSON(json['ms_teams_info']),
        'onboarding_information': OnboardingInformationFromJSON(json['onboarding_information']),
        'onboarding_tasks': json['onboarding_tasks'],
        'only_admins_and_owners_see_reinstall_banner': json['only_admins_and_owners_see_reinstall_banner'],
        'org_dismissed_ctas': json['org_dismissed_ctas'],
        'organisation_available_products': !exists(json, 'organisation_available_products') ? undefined : json['organisation_available_products'],
        'organisation_created_at': json['organisation_created_at'],
        'organisation_has_reported_first_incident': json['organisation_has_reported_first_incident'],
        'organisation_icon_url': !exists(json, 'organisation_icon_url') ? undefined : json['organisation_icon_url'],
        'organisation_id': json['organisation_id'],
        'organisation_is_demo': json['organisation_is_demo'],
        'organisation_is_sandbox': json['organisation_is_sandbox'],
        'organisation_is_staff': !exists(json, 'organisation_is_staff') ? undefined : json['organisation_is_staff'],
        'organisation_name': json['organisation_name'],
        'organisation_slug': json['organisation_slug'],
        'scopes': ((json['scopes'] as Array<any>).map(ScopeFromJSON)),
        'setup_checklist_dismissed_at': !exists(json, 'setup_checklist_dismissed_at') ? undefined : (new Date(json['setup_checklist_dismissed_at'])),
        'slack_info': !exists(json, 'slack_info') ? undefined : SlackInfoFromJSON(json['slack_info']),
        'slack_reconnection_reason': json['slack_reconnection_reason'],
        'slack_team_ids': !exists(json, 'slack_team_ids') ? undefined : json['slack_team_ids'],
        'slack_user_reconnection_reason': json['slack_user_reconnection_reason'],
        'slack_user_token_behaviours': json['slack_user_token_behaviours'],
        'slack_user_token_owner': !exists(json, 'slack_user_token_owner') ? undefined : UserFromJSON(json['slack_user_token_owner']),
        'staff_permissions': !exists(json, 'staff_permissions') ? undefined : json['staff_permissions'],
        'trial_status': TrialStatusFromJSON(json['trial_status']),
        'user_avatar_url': !exists(json, 'user_avatar_url') ? undefined : json['user_avatar_url'],
        'user_base_role_slug': json['user_base_role_slug'],
        'user_dismissed_ctas': json['user_dismissed_ctas'],
        'user_email': json['user_email'],
        'user_id': json['user_id'],
        'user_locale': json['user_locale'],
        'user_name': json['user_name'],
        'user_slack_role': json['user_slack_role'],
        'user_slack_user_id': !exists(json, 'user_slack_user_id') ? undefined : json['user_slack_user_id'],
        'user_state': json['user_state'],
    };
}

export function IdentityToJSON(value?: Identity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor_organisation_id': value.actor_organisation_id,
        'actor_organisation_slug': value.actor_organisation_slug,
        'add_to_slack_enterprise_url': value.add_to_slack_enterprise_url,
        'add_to_slack_url': value.add_to_slack_url,
        'app_installed': value.app_installed,
        'atlassian_connection_status': value.atlassian_connection_status,
        'can_create_retrospective_incidents': value.can_create_retrospective_incidents,
        'can_create_tutorial_incidents': value.can_create_tutorial_incidents,
        'can_share_postmortems': value.can_share_postmortems,
        'custom_banner_message': value.custom_banner_message,
        'feature_gates': FeatureGatesToJSON(value.feature_gates),
        'first_login_at': (value.first_login_at.toISOString()),
        'first_time_on_call_email_sent': value.first_time_on_call_email_sent,
        'has_created_a_status_page': value.has_created_a_status_page,
        'has_created_tutorial': value.has_created_tutorial,
        'has_reported_first_incident': value.has_reported_first_incident,
        'incident_types_enabled': value.incident_types_enabled,
        'intercom_user_id_hash': value.intercom_user_id_hash,
        'last_tutorial_info': TutorialInfoToJSON(value.last_tutorial_info),
        'missing_slack_token_scopes': ((value.missing_slack_token_scopes as Array<any>).map(SlackTokenScopeToJSON)),
        'ms_teams_info': MSTeamsInfoToJSON(value.ms_teams_info),
        'onboarding_information': OnboardingInformationToJSON(value.onboarding_information),
        'onboarding_tasks': value.onboarding_tasks,
        'only_admins_and_owners_see_reinstall_banner': value.only_admins_and_owners_see_reinstall_banner,
        'org_dismissed_ctas': value.org_dismissed_ctas,
        'organisation_available_products': value.organisation_available_products,
        'organisation_created_at': value.organisation_created_at,
        'organisation_has_reported_first_incident': value.organisation_has_reported_first_incident,
        'organisation_icon_url': value.organisation_icon_url,
        'organisation_id': value.organisation_id,
        'organisation_is_demo': value.organisation_is_demo,
        'organisation_is_sandbox': value.organisation_is_sandbox,
        'organisation_is_staff': value.organisation_is_staff,
        'organisation_name': value.organisation_name,
        'organisation_slug': value.organisation_slug,
        'scopes': ((value.scopes as Array<any>).map(ScopeToJSON)),
        'setup_checklist_dismissed_at': value.setup_checklist_dismissed_at === undefined ? undefined : (value.setup_checklist_dismissed_at.toISOString()),
        'slack_info': SlackInfoToJSON(value.slack_info),
        'slack_reconnection_reason': value.slack_reconnection_reason,
        'slack_team_ids': value.slack_team_ids,
        'slack_user_reconnection_reason': value.slack_user_reconnection_reason,
        'slack_user_token_behaviours': value.slack_user_token_behaviours,
        'slack_user_token_owner': UserToJSON(value.slack_user_token_owner),
        'staff_permissions': value.staff_permissions,
        'trial_status': TrialStatusToJSON(value.trial_status),
        'user_avatar_url': value.user_avatar_url,
        'user_base_role_slug': value.user_base_role_slug,
        'user_dismissed_ctas': value.user_dismissed_ctas,
        'user_email': value.user_email,
        'user_id': value.user_id,
        'user_locale': value.user_locale,
        'user_name': value.user_name,
        'user_slack_role': value.user_slack_role,
        'user_slack_user_id': value.user_slack_user_id,
        'user_state': value.user_state,
    };
}

