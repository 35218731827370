import React, { createContext, useContext, useEffect, useState } from "react";
import { useFormState } from "react-hook-form";

import { getOnCloseWithWarning } from "./Drawer";

type DrawerFormStateContextType = {
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  // WarnWhenDirty is included here so that the drawer title can access
  // it without having to remember to pass the prop to both the drawer and it.
  warnWhenDirty: boolean;
};

export const DrawerFormStateContext = createContext<DrawerFormStateContextType>(
  {
    isDirty: false,
    warnWhenDirty: false,
    setIsDirty: () => void 0,
  },
);

export const DrawerFormStateProvider = ({
  children,
  warnWhenDirty,
}: {
  children: React.ReactNode;
  warnWhenDirty: boolean;
}): JSX.Element => {
  const [isDirty, setIsDirty] = useState(false);
  return (
    <DrawerFormStateContext.Provider
      value={{
        isDirty,
        setIsDirty,
        warnWhenDirty,
      }}
    >
      {children}
    </DrawerFormStateContext.Provider>
  );
};

export const useDrawerFormState = (): DrawerFormStateContextType => {
  const context = useContext(DrawerFormStateContext);
  if (!context) {
    throw new Error(
      "useDrawerFormState can only be called in a child of a <Drawer> component. Your form drawer should look like <Drawer><FormStuff <-- call useForm in here/></Drawer>",
    );
  }
  return context;
};

/**
* DrawerFormStateContext allows our Drawer component to see if a child form is dirty,
so that it can warn the user before it closes. The value is kept up-to-date by calling useWarnOnDrawerClose(formMethods, onClose).
*
* useWarnOnDrawerClose also returns a wrapped onClose for convienience, so it can be passed to 'cancel' buttons etc.
*
* A mimimal correct usage of a form in a drawer would look like this:
@example
const MyDrawerComponent = ({onClose}) => {
  return <Drawer warnWhenDirty onClose={onClose}>
     <MyFormComponent onClose={onClose}/>
  <Drawer/>
}
@example
const MyFormComponent = ({onClose}) => {
  const formMethods = useForm()
    
  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );

  return <FormV2 formMethods={formMethods}>
  ... your form components here ... 
  <MyCancelButton onClick={() => onCloseWithWarn(isDirty)} />
  </FormV2>
}
 */
export const useWarnOnDrawerClose = (formMethods, onClose: () => void) => {
  const { isDirty, setIsDirty } = useDrawerFormState();

  const { isDirty: formStateIsDirty } = useFormState({
    control: formMethods.control,
  });

  // The drawer relies on the provider state to know when to warn on close,
  // so we need to keep the provider state in sync with the form state.
  useEffect(() => {
    setIsDirty(!!formStateIsDirty);
  }, [formStateIsDirty, setIsDirty]);

  const onCloseWithWarn = getOnCloseWithWarning(onClose);

  // We return the provider dirty state rather than the form state here so
  // that the value the drawer sees and the value the hook returns are the same,
  // to avoid inconsistent behaviour when debugging.
  return { isDirty, onCloseWithWarn };
};
