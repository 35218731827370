import "react-phone-number-input/style.css";

import {
  OnCallNotificationMethod,
  OnCallNotificationMethodV2,
  OnCallNotificationRuleRuleTypeEnum,
} from "@incident-io/api";
import { FormDivider } from "@incident-shared/forms/v2/FormDivider";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { GenericErrorMessage, IconEnum } from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import _ from "lodash";
import { useState } from "react";
import { UserPreferencesHeading } from "src/components/user-preferences/common/UserPreferencesHeading";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

import { ContactMethods } from "./ContactMethods";
import { NotificationRulesForType } from "./NotificationRulesForType";
import { useShortestEscalationPathForUser } from "./OnCallNotificationConfigurationInvalidCallout";
import { OnCallNotificationsAppOnboardingCallout } from "./OnCallNotificationsAppOnboardingCallout";
import { OnCallNotificationsPhoneOnboarding } from "./OnCallNotificationsPhoneOnboarding";
import { WrongSeatTypeWarning } from "./WrongSeatTypeWarning";

// Allow methods to be either those we add for all users or those that have been
// explicitly created on their account.
export type Method = Omit<OnCallNotificationMethodV2, "id"> & {
  id?: string;
};

export type MethodV1 = Omit<OnCallNotificationMethod, "id"> & {
  id?: string;
};

export const OnCallNotificationsPage = () => {
  const { identity } = useIdentity();

  const {
    data: methodsData,
    error: methodsError,
    isLoading: methodsLoading,
  } = useAPI("onCallNotificationsListMethodsV2", undefined);

  const {
    data: rulesData,
    error: rulesError,
    isLoading: rulesLoading,
  } = useAPI("onCallNotificationsListRules", undefined);

  const { data: onboardingStateData, isLoading: onboardingStateLoading } =
    useAPI("onCallOnboardingCurrentOnboardingState", undefined);

  // Find the escalation path that this user is on with the shortest time to
  // respond.
  const shortestEscalationPath = useShortestEscalationPathForUser(
    identity?.user_id ?? "",
  );

  const userOnboarded =
    (onboardingStateData?.ios?.onboarded ||
      onboardingStateData?.android?.onboarded ||
      onboardingStateData?.web?.onboarded) ??
    false;

  const appOnboarded =
    (onboardingStateData?.ios?.onboarded ||
      onboardingStateData?.android?.onboarded) ??
    false;

  const loading = methodsLoading || rulesLoading;

  // we should be tolerant of escalation levels not being available
  const error = methodsError || rulesError;

  const [showAddMethodModal, setShowAddMethodModal] = useState({
    show: false,
    allowEmail: true,
  });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const rulesByTypes = _.chain(
    Object.values(OnCallNotificationRuleRuleTypeEnum),
  )
    .map((type) => ({
      type,
      rules: rulesData?.rules.filter((r) => r.rule_type === type) ?? [],
      displayItem: ruleTypeToDisplayItem(
        type as OnCallNotificationRuleRuleTypeEnum,
      ),
    }))
    .sortBy("displayItem.rank")
    .value();
  _.chain(rulesByTypes)
    .filter((r) => r.type !== OnCallNotificationRuleRuleTypeEnum.ShiftChanges)
    .flatMap((r) => r.rules)
    .map((r) => r.delay_seconds)
    .min()
    .value();

  const allNotificationMethods = methodsData?.methods ?? [];

  const phoneOnboarded =
    onboardingStateData?.web?.tasks.find(
      (t) => t.task === "dashboard_has_verified_phone_number",
    )?.completed ?? false;

  return (
    <SecondaryNavSubPageWrapper
      title="On-call notifications"
      icon={IconEnum.OnCall}
    >
      <LoadingWrapper loading={loading} isTransparent>
        <div className={"max-w-[640px]"}>
          <WrongSeatTypeWarning />
          {!onboardingStateLoading && (
            <>
              <OnCallNotificationsAppOnboardingCallout
                userAppOnboarded={appOnboarded}
              />
              <OnCallNotificationsPhoneOnboarding
                userOnboarded={userOnboarded}
                phoneOnboarded={phoneOnboarded}
                setShowAddMethodModal={setShowAddMethodModal}
              />
              {!userOnboarded && <FormDivider className="mt-10 mb-6" />}
            </>
          )}
          <UserPreferencesHeading
            className={"mb-4"}
            title={"On-call notifications"}
            description={
              "Configure how you want to be notified for escalations and upcoming on-call shifts."
            }
          />
          <div className="flex flex-col space-y-4 mb-6">
            <ContactMethods
              methods={allNotificationMethods}
              // mobileKeys={mobileKeysData?.mobile_keys ?? []}
              showAddMethodModal={showAddMethodModal}
              setShowAddMethodModal={setShowAddMethodModal}
            />
          </div>
          <div className={"flex flex-col space-y-6 mt-6"}>
            {rulesByTypes.map(({ type, rules, displayItem }) => (
              <NotificationRulesForType
                key={type}
                type={type as OnCallNotificationRuleRuleTypeEnum}
                rules={rules}
                displayItem={displayItem}
                methods={allNotificationMethods}
                shortestEscalationPath={shortestEscalationPath}
                loading={loading}
              />
            ))}
          </div>
        </div>
      </LoadingWrapper>
    </SecondaryNavSubPageWrapper>
  );
};

export type RuleTypeDisplayItem = {
  title: string;
  description: string;
  rank: number;
};
const ruleTypeToDisplayItem = (
  type: OnCallNotificationRuleRuleTypeEnum,
): RuleTypeDisplayItem => {
  switch (type) {
    case OnCallNotificationRuleRuleTypeEnum.HighUrgency:
      return {
        title: "High-urgency notifications",
        description:
          "Notifications that must reach you, no matter the time of day.",
        rank: 0,
      };
    case OnCallNotificationRuleRuleTypeEnum.LowUrgency:
      return {
        title: "Low-urgency notifications",
        description: "Notifications that don't require immediate action.",
        rank: 1,
      };
    case OnCallNotificationRuleRuleTypeEnum.ShiftChanges:
      return {
        title: "Shift changes",
        description:
          "Tell us how and when to notify you when you are coming on an on call shift.",
        rank: 2,
      };
    default:
      assertUnreachable(type);
      return {
        rank: 0,
        title: "",
        description: "",
      };
  }
};
