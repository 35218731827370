import {
  IncidentRoleRoleTypeEnum,
  InternalStatusPage,
  InternalStatusPageUpdateDisplayComponentsRequestBody as FormType,
  ScopeNameEnum,
} from "@incident-io/api";
import {
  FormHelpTextV2,
  FormLabelV2,
} from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, StackedList } from "@incident-ui";
import { StaticSingleSelectWithObj } from "@incident-ui/Select/StaticSingleSelect";
import { useForm } from "react-hook-form";
import {
  customFieldTypeToIcon,
  customFieldTypeToTypeName,
} from "src/components/settings/custom-fields/common/utils";
import { TypeAttributeListItem } from "src/components/settings/incident-types/common/TypeAttributeListItem";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

const buildFormState = (page: InternalStatusPage) => ({
  display_custom_field_ids: page.display_custom_field_ids,
});

export const DisplayCustomFieldsForm = ({
  page,
}: {
  page: InternalStatusPage;
}) => {
  const {
    data: { incident_roles: roles },
  } = useAPI("incidentRolesList", undefined, {
    fallbackData: { incident_roles: [] },
  });

  const leadRoleName =
    roles.find((role) => role.role_type === IncidentRoleRoleTypeEnum.Lead)
      ?.name ?? "Incident Lead";

  const formMethods = useForm<FormType>({
    defaultValues: buildFormState(page),
  });

  const {
    data: { custom_fields: allCustomFields },
    isLoading,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "internalStatusPageShowPage",
    { id: page.id },
    async (apiClient, data: FormType) => {
      // Sometimes displayed custom fields might be deleted, which would make the
      // form impossible to save. To prevent that, we strip those from the form
      // state before saving.
      const display_custom_field_ids = data.display_custom_field_ids.filter(
        (fieldId) => allCustomFields.find((field) => field.id === fieldId),
      );

      await apiClient.internalStatusPageUpdateDisplayComponents({
        id: page.id,
        updateDisplayComponentsRequestBody: {
          display_custom_field_ids,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: (res) =>
        formMethods.reset(buildFormState(res.internal_status_page)),
    },
  );
  const { hasScope } = useIdentity();
  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  const displayCustomFieldIDs = new Set(
    formMethods.watch("display_custom_field_ids"),
  );
  const displayCustomFields = allCustomFields.filter((field) =>
    displayCustomFieldIDs.has(field.id),
  );

  const addableCustomFieldOptions = allCustomFields
    .filter(
      (field) =>
        !displayCustomFieldIDs.has(field.id) &&
        field.id !== page.component_custom_field_id,
    )
    .map((field) => ({
      value: field.id,
      label: field.name,
      icon: customFieldTypeToIcon(field.field_type),
    }));

  const onAddCustomField = (fieldId: string) => {
    formMethods.setValue(
      "display_custom_field_ids",
      [...Array.from(displayCustomFieldIDs), fieldId],
      { shouldDirty: true },
    );
  };

  const onRemoveCustomField = (fieldId: string) => {
    formMethods.setValue(
      "display_custom_field_ids",
      Array.from(displayCustomFieldIDs).filter((id) => id !== fieldId),
      { shouldDirty: true },
    );
  };

  return (
    <FormV2
      formMethods={formMethods}
      onSubmit={trigger}
      saving={isMutating || isLoading}
      innerClassName="bg-surface-secondary rounded-[6px] p-4 border border-stroke"
      genericError={genericError}
    >
      <h3 className="font-medium">Display custom fields</h3>
      <FormHelpTextV2>
        You can optionally choose to provide more context about incidents by
        displaying relevant custom fields. These will be displayed for ongoing
        incidents on your internal status page, along with the name of the{" "}
        {leadRoleName} and the impacted components.
      </FormHelpTextV2>

      <StackedList className={"max-w-lg"}>
        <TypeAttributeListItem
          icon={customFieldTypeToIcon(page.component_custom_field.field_type)}
          iconTooltip={customFieldTypeToTypeName(
            page.component_custom_field.field_type,
          )}
          name={`${page.component_custom_field.name} (as components)`}
        />
        {displayCustomFields.map((field) => (
          <TypeAttributeListItem
            key={field.id}
            icon={customFieldTypeToIcon(field.field_type)}
            iconTooltip={customFieldTypeToTypeName(field.field_type)}
            name={field.name}
            onDelete={
              field.id === page.component_custom_field_id
                ? undefined
                : () => onRemoveCustomField(field.id)
            }
          />
        ))}
      </StackedList>

      <div>
        <FormLabelV2 htmlFor="add_display_custom_field">
          Add custom field
        </FormLabelV2>
        <FormHelpTextV2>
          Choose another custom field to display on your internal status page.
        </FormHelpTextV2>
        <div className={`max-w-lg flex-center-y space-x-2`}>
          <StaticSingleSelectWithObj
            options={addableCustomFieldOptions}
            value={null}
            onChange={(opt) => onAddCustomField(opt.value)}
            placeholder="Choose a field"
            isClearable={false}
            className="w-full"
          />
        </div>
      </div>

      <GatedButton
        type="submit"
        theme={ButtonTheme.Primary}
        analyticsTrackingId={"status-page-edit-basics"}
        analyticsTrackingMetadata={{ status_page_id: page.id }}
        loading={isMutating}
        disabled={missingPermission || !formMethods.formState.isDirty}
        disabledTooltipContent={
          missingPermission
            ? "You do not have permission to configure this internal status page"
            : undefined
        }
      >
        Save
      </GatedButton>
    </FormV2>
  );
};
