import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { ControlledInputWrapper } from "@incident-shared/forms/v1/controlled/ControlledInputWrapper";
import { FormLabel } from "@incident-shared/forms/v1/FormInputHelpers";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Callout,
  CalloutTheme,
  Checkbox,
  Heading,
  Modal,
  ModalContent,
  ModalFooter,
  StepsPageContent,
  StepsPageFooter,
  Textarea,
} from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  ScheduleReport,
  SchedulesPublishReportRequestBody,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { OnCallReport } from "./OnCallReportView/OnCallReportView";
import { dateStrToDisplayStr } from "./pay-configurations/date-time-helpers";
import styles from "./ReportGeneratorPublish.module.scss";
import { TimezoneHelperToolTip } from "./TimezoneHelperToolTip";

export const ReportGeneratorPublish = ({
  report,
  onBack,
}: {
  report: ScheduleReport;
  onBack: () => Promise<void> | void;
}): React.ReactElement => {
  const { identity, hasScope } = useIdentity();
  const [showPublishModal, setShowPublishModal] = useState<boolean>(false);
  return (
    <>
      {showPublishModal && (
        <PublishModal
          report={report}
          onClose={() => setShowPublishModal(false)}
        />
      )}
      <Callout
        className={tcx(styles.callout, "w-full mt-6")}
        theme={CalloutTheme.Plain}
      >
        <div>
          <p className="text-sm">
            <span className="font-semibold">
              This report is currently a draft.
            </span>{" "}
            Publish it in order to export and share it with others.
          </p>
        </div>
      </Callout>
      <StepsPageContent className="!py-6">
        <div className={"text-sm"}>
          <Heading level={2} size="medium" className="mb-4">
            {report.name}
          </Heading>
          <div className="inline-flex">
            <p>
              This report includes shifts between{" "}
              <strong>{dateStrToDisplayStr(report.start_date)}</strong> and{" "}
              <strong>{dateStrToDisplayStr(report.end_date)}</strong>, using the
              timezone set on each respective schedule.{" "}
              <TimezoneHelperToolTip />
            </p>
          </div>
        </div>
      </StepsPageContent>
      <StepsPageContent className="!pt-4">
        <OnCallReport report={report} />
      </StepsPageContent>
      <StepsPageFooter
        // Only let someone go back if they created the report, to avoid problems with missing pay configs.
        onBack={report.creator.id === identity?.user_id ? onBack : undefined}
        continueButtonType="submit"
        continueButtonText="Publish"
        onContinue={() => setShowPublishModal(true)}
        continueDisabled={!hasScope(ScopeNameEnum.ScheduleReportsPublish)}
        continueExplanationText={
          "Only admins can publish reports: share this link with them, and ask them to publish on your behalf."
        }
      />
    </>
  );
};

const PublishModal = ({
  report,
  onClose,
}: {
  report: ScheduleReport;
  onClose: () => void;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setError,
  } = useForm<SchedulesPublishReportRequestBody>({
    defaultValues: {
      cc_emails: [],
    },
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "schedulesShowReport",
    { id: report.id },
    async (apiClient, body: SchedulesPublishReportRequestBody) =>
      await apiClient.schedulesPublishReport({
        id: report.id,
        publishReportRequestBody: body,
      }),
    {
      setError,
      onSuccess: () =>
        navigate(`/on-call/pay-calculator/reports/${report.id}`, {
          replace: true,
        }),
    },
  );

  return (
    <Modal
      isOpen
      title={"Publish Report"}
      disableQuickClose
      onClose={onClose}
      analyticsTrackingId={"schedule-publish-report-modal"}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <ModalContent className="space-y-4">
        <ErrorMessageUI message={genericError} />
        <p className="text-sm text-slate-700">
          Publishing a report allows you to export or share your report. After
          publishing you will no longer be able to edit the report.
        </p>
        <div>
          <FormLabel htmlFor="send_user_breakdowns" className="block mb-2">
            Email notifications
          </FormLabel>
          <Checkbox
            id={"send_user_breakdowns"}
            label={"Email an individual pay breakdown to each payee"}
            defaultChecked={false}
            {...register("send_user_breakdowns")}
          />
        </div>
        <ControlledInputWrapper<SchedulesPublishReportRequestBody>
          label="Additional recipients"
          errors={errors}
          helptext="Add a comma separated list of email addresses here"
          id="cc_emails"
          control={control}
          render={({ field: { onChange, value, onBlur } }) => (
            <Textarea
              value={(value as string[]).join(",")}
              onChange={(event) =>
                onChange(
                  // @ts-expect-error typescript doesn't know the event has a value but it does :)
                  event.target.value.split(",").map((val) => val.trim()),
                )
              }
              id="cc_emails"
              onBlur={onBlur}
            />
          )}
        ></ControlledInputWrapper>
      </ModalContent>
      <ModalFooter
        onClose={onClose}
        confirmButtonType="submit"
        confirmButtonText="Publish"
        saving={saving}
      />
    </Modal>
  );
};
