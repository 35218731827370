import { sortBy } from "lodash";
import { TimelineItemFilterOption } from "src/components/timeline/TimelineFilters";
import {
  TimelineItemItemTypeEnum as TimelineItemType,
  TimelineItemObject,
} from "src/contexts/ClientContext";

export const TimelineItemNames: {
  [key in TimelineItemType]: string;
} = {
  [TimelineItemType.Closed]: "Incident closed",
  [TimelineItemType.Report]: "Incident reported",
  [TimelineItemType.RoleUpdate]: "Role updated",
  [TimelineItemType.SeverityUpdate]: "Severity updated",
  [TimelineItemType.SlackInferGithub]: "GitHub link shared",
  [TimelineItemType.SlackInferSentry]: "Sentry linked",
  [TimelineItemType.SlackPin]: "Slack post pinned",
  [TimelineItemType.SummaryUpdate]: "Summary update",
  [TimelineItemType.IncidentCallStarted]: "Incident call started",
  [TimelineItemType.IncidentCallEnded]: "Incident call ended",
  [TimelineItemType.IncidentEscalate]: "Escalation",
  [TimelineItemType.EscalationCreated]: "Escalation created",
  [TimelineItemType.ActionCreated]: "Action created",
  [TimelineItemType.ActionUpdated]: "Action updated",
  [TimelineItemType.FollowUpCreated]: "Follow-up created",
  [TimelineItemType.FollowUpUpdated]: "Follow-up updated",
  [TimelineItemType.StatusChange]: "Incident status changed",
  [TimelineItemType.AtlassianStatuspageUpdate]: "Statuspage updated",
  [TimelineItemType.IncidentUpdate]: "Incident update shared",
  [TimelineItemType.CustomFieldValueUpdate]: "Custom field value updated",
  [TimelineItemType.IncidentMembershipRevoked]: "Incident access revoked",
  [TimelineItemType.IncidentVisibilityChanged]: "Incident visibility changed",
  [TimelineItemType.SlackImage]: "Image shared",
  [TimelineItemType.IncidentRename]: "Incident renamed",
  [TimelineItemType.IncidentAttachmentAdded]: "Attachment added",
  [TimelineItemType.IncidentAttachmentRemoved]: "Attachment removed",
  [TimelineItemType.PagerdutyIncidentTriggered]: "PagerDuty incident triggered",
  [TimelineItemType.PagerdutyIncidentAcknowledged]:
    "PagerDuty incident acknowledged",
  [TimelineItemType.PagerdutyIncidentResolved]: "PagerDuty incident resolved",
  [TimelineItemType.CustomEvent]: "Custom event",
  [TimelineItemType.TimelineNote]: "Note",
  [TimelineItemType.IncidentTimestampSet]: "Timestamp updated",
  [TimelineItemType.IncidentMerged]: "Incident merged",
  [TimelineItemType.IncidentTypeChanged]: "Incident type changed",
  [TimelineItemType.StatusPageIncidentLinked]: "Status page incident linked",
  [TimelineItemType.Handover]: "Incident handover run",
  [TimelineItemType.StatusPageIncidentUpdated]: "Status page incident updated",
  [TimelineItemType.Scrub]: "Item scrubbed",
};

export function getTimelineFilterOptions(
  timelineItems: TimelineItemObject[],
): TimelineItemFilterOption[] {
  const allTypes = Object.values(TimelineItemType);

  // Filter out items which are only shown in the activity log.
  const consideredTimelineItems = timelineItems.filter(
    (item) => !item.hidden_at,
  );
  return sortBy(
    allTypes
      .filter((type) =>
        consideredTimelineItems.some((item) => item.item_type === type),
      )
      .map((type) => {
        return {
          type,
          label: TimelineItemNames[type],
        };
      }),
    "label",
  );
  // only show applicable timeline item options
}
