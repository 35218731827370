import { Document, Element, Node, NodeWithChildren, Text } from "domhandler";
import { decode } from "entities";
import { all } from "hast-util-to-mdast";
import { parseDocument } from "htmlparser2";
import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import rehypeStringify from "rehype-stringify";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";
import remarkToRehype from "remark-rehype";
import remarkStringify from "remark-stringify";
import { TextDocument, TextNode } from "src/contexts/ClientContext";
import { unified } from "unified";

export const textNodeFromString = (text: string): TextNode => ({
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text,
        },
      ],
    },
  ],
});

export const textDocFromString = (text: string): TextDocument => ({
  markdown: text,
  text_node: textNodeFromString(text),
});

// Our app primarily has 3 rich text types:
// 1. HTML - powerful but dangerous and not friendly
// 2. Mrkdwn - this is the markdown flavout that Slack uses, and is very
// non-standard. Weird things like <@U123> is a mention, but also single-* for
// bold, <url|title> for links, things like that.
// 3. Markdown - mostly Github-flavoured, because that's nice. This is used for
// postmortem generation and emails. This is the markdown you know.
export async function mrkdwnToMarkdown(mrkdwn: string): Promise<string> {
  // This is utterly horrible, but addressing mrkdwn vs markdown is a bigger
  // project than anyone really wants to touch right now, so it'll do.
  const html = mrkdwnToHtml(mrkdwn);
  return await htmlToMarkdown(html);
}

export async function htmlToMarkdown(html: string): Promise<string> {
  const markdownFile = await unified()
    .use(rehypeParse)
    .use(rehypeRemark, {
      handlers: {
        span: (h, node) => {
          if (node.properties.dataOriginalVariable) {
            // For variables, we want to directly output these as pre-formatted
            // HTML, rather than the default, which would be a text node, which
            // would then be eligible to be escaped further down the chain.
            return h(
              node,
              "html",
              `{{${node.properties.dataOriginalVariable}}}`,
            );
          } else {
            // This is the default implementation - for non-variables this is fine!
            return all(h, node);
          }
        },
      },
    })
    .use(remarkStringify, {})
    .process(html);

  const commonMark = String(markdownFile);
  return formatCommonMark(commonMark);
}

function formatCommonMark(input) {
  return (
    input
      // mdast-util-to-markdown adds newlines around our html blocks for
      // variables and I can't configure it not to, so remove them here.
      .replaceAll("\n\n{{", " {{")
      .replaceAll("}}\n\n", "}} ")
      // remarkStringify returns "CommonMark", which the actual react-markdown
      // plugin seems to not really support properly. I can't find a way to
      // configure this properly, so instead we just replace out the silly
      // `\\\n` with `\n` which works fine.
      //
      // https://github.com/syntax-tree/mdast-util-to-markdown/issues/30
      .replaceAll("\\\n", "\n")
  );
}

export async function markdownToHTML(markdown: string): Promise<string> {
  const htmlFile = await unified()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkToRehype)
    .use(rehypeStringify)
    .process(markdown);

  // TinyMCE treats newlines as <br>, but actually remark/rehype have already
  // formatted everything with <p>/<h2> etc tags.
  return String(htmlFile).replaceAll("\n", "");
}

// mrkdwnToHtml takes a markdown string and gives us the HTML equivalent, to the
// best of our abilities.
//
// Why roll our own, I hear you ask? Well, if Slack used actual proper markdown
// and not their own flavour 'mrkdwn', we could use a 3rd party markdown-to-html
// library. But alas, no such thing exists for mrkdwn. Boo.
//
// You need to call this function yourself when you load mrkdwn into form state.
// We can't automatically convert mrkdwn to html in the DeprecatedTextEditor component as
// it causes too many re-renders.
export function mrkdwnToHtml(mrkdwn: string): string {
  if (mrkdwn == null || mrkdwn === "") return "";
  let html = mrkdwn;
  const links = mrkdwn.matchAll(
    /<(mailto:[^|>]+|https?:\/\/[^|>]+)\|(\S[\s\S]*?)>/g,
  );
  Array.from(links).forEach((link) => {
    html = html.replace(
      link[0],
      `<a href=${link[1]}>${link[2] ?? link[1]}</a>`,
    );
  });

  return html
    .replace(/\n\n(\S[\s\S]*?)\n\n/g, (_, txt) => `<p>${txt}</p>`)
    .replace(/\*(\S[\s\S]*?)\*/g, (_, txt) => `<strong>${txt}</strong>`)
    .replace(/_+(\S[\s\S]*?)_+[^\S}]+/g, (_, txt) => `<em>${txt}</em>`)
    .replace(/[\n]/g, "<br>");
}

// This function takes the html output of tinyMCE, and turns it into Slack
// mrkdwn which we need to send to the backend.
//
// This is based on the html-to-mrkdwn library:
// https://github.com/integrations/html-to-mrkdwn
//
// You need to call this function yourself when you send the editor contents to
// the backend. We can't automatically convert html to mrkdwn in the DeprecatedTextEditor
// change handler as it causes too many re-renders.
export function htmlToMrkdwn(html: string | undefined): string {
  if (!html) {
    return "";
  }
  const dom = parseDocument(html);
  if (dom) {
    const mrkdwnString = traverseNodeAndReplaceWithMrkdwn(dom as Document);
    const decoded = decode(mrkdwnString);

    // We saw a bug with nbsps where they prevented the backend from rendering
    // variables properly.
    //
    // This is a bit of a plaster, but should work. In case your editor doesn't
    // show you, the first arg is a nbsp (and the second arg is a normal space).
    return decoded.replaceAll(String.fromCharCode(160), " ");
  }

  return "";
}

// Recursive DOM converting function for htmlToMrkdwn.
// Each DOM element that htmlparser2 identifies has a 'type'.
// We can then match on the tag to convert its children (text contents) into mrkdwn.
function traverseNodeAndReplaceWithMrkdwn(dom: Document | Node[]): string {
  let out = "";
  let iterable: Node[];
  if (!Array.isArray(dom)) {
    iterable = dom.children as Node[];
  } else {
    iterable = dom;
  }
  iterable.forEach((el: Node) => {
    // if it's just text, give us the raw text.
    if ("text" === el.type) {
      out += (el as Text).data;
    }
    // if it's a tag we're dealing with, let's get the name of the tag
    // and act accordingly
    if ("tag" === el.type) {
      const n = el as NodeWithChildren;
      switch ((n as Element).name) {
        case "a":
          // HTML <a href="xyz">children</a> => <href|children>
          out +=
            "<" +
            (n as Element).attribs.href.replace(/"/g, "") +
            "|" +
            traverseNodeAndReplaceWithMrkdwn(n.children) +
            ">";
          break;
        case "br":
          // <br /> -> \n
          out += "\n";
          break;
        case "p":
          // <p>children</p> -> \nchildren\n
          out += "\n" + traverseNodeAndReplaceWithMrkdwn(n.children) + "\n";
          break;
        case "strong":
        case "b":
          // <b>children</b> -> *children*
          out += "*" + traverseNodeAndReplaceWithMrkdwn(n.children) + "*";
          break;
        case "i":
        case "em":
          // <i>children</i> -> _children_
          out += "_" + traverseNodeAndReplaceWithMrkdwn(n.children) + "_";
          break;
        default:
          // <xyz>children</xyz> -> children
          out += traverseNodeAndReplaceWithMrkdwn(n.children);
      }
    }
  });
  return out;
}
