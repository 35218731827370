import {
  IntegrationSettingsProviderEnum as ProviderEnum,
  IntegrationSettingsReconnectionReasonEnum,
} from "@incident-io/api";
import { IssueTrackerProviderEnum } from "@incident-shared/issue-trackers";
import { IconEnum } from "@incident-ui";
import { useIntegrations } from "src/hooks/useIntegrations";

export type ExportIssueTracker = {
  displayName: string;
  icon: IconEnum;
  provider: ProviderEnum;
};

export const createFollowUpTemplateURL = (provider: IssueTrackerProviderEnum) =>
  `/settings/follow-ups/templates/${provider}/create`;

export const useEnabledExportIssueTrackers = (): {
  installedTrackers: IssueTrackerProviderEnum[];
  allTrackers: IssueTrackerProviderEnum[];
} => {
  const allTrackers = [
    IssueTrackerProviderEnum.Linear,
    IssueTrackerProviderEnum.Jira,
    IssueTrackerProviderEnum.JiraServer,
    IssueTrackerProviderEnum.Github,
  ];

  const { integrations } = useIntegrations();

  const installedTrackers = allTrackers.filter((provider) => {
    const integration = integrations?.find(
      (integation) =>
        (integation.provider as unknown as IssueTrackerProviderEnum) ===
        provider,
    );

    return (
      (integration?.installed &&
        integration?.reconnection_reason ===
          IntegrationSettingsReconnectionReasonEnum.Empty) ??
      false
    );
  });

  return { allTrackers, installedTrackers };
};
