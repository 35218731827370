import {
  BranchesOnlyExpression,
  ExpressionBranch,
  ExpressionElseBranch,
  IssueTrackerSyncConfig,
  IssueTrackerSyncConfigContextEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { CommaSeparatedConditionGroups } from "@incident-shared/engine/conditions";
import { SelectedCondition } from "@incident-shared/engine/conditions/SelectedCondition";
import { EngineLiteralBadge } from "@incident-shared/engine/labels/EngineLiteralBadge";
import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { INTEGRATION_CONFIGS } from "@incident-shared/integrations";
import { IssueTrackerProviderEnum } from "@incident-shared/issue-trackers";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  ModalFooter,
  RadioButton,
  StackedList,
} from "@incident-ui";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
} from "react-beautiful-dnd";
import {
  FieldPath,
  FieldValues,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useFollowUpScope } from "src/hooks/useFollowUpScope";
import { tcx } from "src/utils/tailwind-classes";

import { SettingsListItem } from "../@shared/settings/SettingsList/SettingsListItem";
import { UnifiedIssueTemplate } from "../settings/follow-ups/FollowUpExportSection";
import { SettingsSubHeading } from "../settings/SettingsSubHeading";

type FormData = {
  issue_template_expression: BranchesOnlyExpression | undefined;
};

export type BranchWithID = ExpressionBranch & { id: string };

function iconForTemplateProvider(provider: IssueTrackerProviderEnum): IconEnum {
  return INTEGRATION_CONFIGS[provider].icon;
}

function providerFullName(provider: IssueTrackerProviderEnum): string {
  return INTEGRATION_CONFIGS[provider].label;
}

function contextForConfig(context: IssueTrackerSyncConfigContextEnum): string {
  switch (context) {
    case "follow-up":
      return "follow-up";
    case "incident-ticket":
      return "incident ticket";
    default:
      return "ticket";
  }
}

export const IssueTemplateExpressionEditor = ({
  syncConfig,
  issueTemplates,
  branches,
  onMoveBranch,
  onAddBranch,
  onRemoveBranch,
  onEditBranch,
  resetBranches,
  privateIncidentsEnabled,
  copyVerb = "sync",
}: {
  syncConfig: IssueTrackerSyncConfig;
  issueTemplates: UnifiedIssueTemplate[];
  branches: Array<BranchWithID>;
  onMoveBranch: (fromIndex: number, toIndex: number) => void;
  onAddBranch: (newBranch: ExpressionBranch) => void;
  onRemoveBranch: (index: number) => void;
  onEditBranch: (index: number, newBranch: ExpressionBranch) => void;
  resetBranches: () => void;
  privateIncidentsEnabled: boolean;
  copyVerb: string; // e.g. "sync" or "export".
}) => {
  const formMethods = useFormContext<FormData>();
  const contextName = contextForConfig(syncConfig.context);

  const onDragEnd = useCallback(
    (result) => {
      // Only listen for drop events (ignore things like 'CANCEL' events, where
      // the user just cancelled/aborted)
      if (result.reason !== "DROP") {
        return;
      }

      // If we dropped it outside the list, no-op
      if (!result.destination) {
        return;
      }

      const fromIndex = result.source.index;
      const toIndex = result.destination.index;
      onMoveBranch(fromIndex, toIndex);
    },
    [onMoveBranch],
  );

  const [editBranch, setEditBranch] = useState<
    { branch: ExpressionBranch & { id: string }; index: number } | undefined
  >();
  const [addBranch, setAddBranch] = useState(false);
  const [editElseBranch, setEditElseBranch] = useState(false);

  const elseBranch = formMethods.getValues(
    "issue_template_expression.else_branch",
  );
  const elseBranchTemplateID = elseBranch?.result.value?.literal;
  const elseBranchTemplate = elseBranchTemplateID
    ? issueTemplates.find((template) => template.id === elseBranchTemplateID)
    : undefined;

  return (
    <div>
      <SettingsSubHeading
        title={"Conditions"}
        titleHeadingSize="small"
        explanation={`
            We will only create a ticket in an issue tracker if it matches the
            below conditions. When no conditions are set we will use the first
            template you have defined.
          `}
        accessory={
          branches.length > 0 && (
            <div className={`shrink-0`}>
              <Button
                analyticsTrackingId={null}
                icon={IconEnum.Add}
                onClick={() => setAddBranch(true)}
              >
                Add condition
              </Button>
            </div>
          )
        }
      />
      {branches.length > 0 ? (
        <div className="space-y-2">
          <StackedList className="text-sm">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="main_template">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {branches.map((branch, index) => (
                      <Draggable
                        isDragDisabled={branches.length === 1}
                        key={branch.id}
                        draggableId={branch.id}
                        index={index}
                      >
                        {(provided) => (
                          <Branch<FormData>
                            fieldName={`issue_template_expression.branches.${index}`}
                            key={branch.id}
                            isFirstItem={index === 0}
                            branch={branch}
                            prefix={index === 0 ? "If" : "Else if"}
                            issueTemplates={issueTemplates}
                            onEdit={() => setEditBranch({ branch, index })}
                            onDelete={() => onRemoveBranch(index)}
                            isOnlyBranch={branches.length === 1}
                            draggableProvided={provided}
                            configContext={contextName}
                            copyVerb={copyVerb}
                          />
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {elseBranch && elseBranchTemplate ? (
              <SettingsListItem
                title={
                  <FormInputWrapperV2<FormData>
                    name="issue_template_expression.else_branch"
                    className="font-normal"
                  >
                    <div
                      className={
                        "flex items-center flex-wrap text-sm space-x-2 font-normal"
                      }
                    >
                      <div className="-ml-2 mr-2 h-[16px] invisible">
                        <Icon id={IconEnum.Draggable} size={IconSize.Small} />
                      </div>
                      <span className="font-medium">Otherwise</span>,{" "}
                      <>
                        use{" "}
                        <EngineLiteralBadge
                          icon={iconForTemplateProvider(
                            elseBranchTemplate.provider,
                          )}
                          label={elseBranchTemplate.name}
                        />{" "}
                        <span className="text-sm flex-shrink-0">
                          to {copyVerb} {contextName} to{" "}
                          {providerFullName(elseBranchTemplate.provider)}
                        </span>
                      </>
                    </div>
                  </FormInputWrapperV2>
                }
                buttons={{
                  edit: {
                    onEdit: () => setEditElseBranch(true),
                  },
                  delete: {
                    isGatedText: "Must specify fallback behaviour",
                    noDeleteConfirmation: true,
                  },
                  requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
                }}
              />
            ) : (
              <DoNotExportToTracker
                elseBranchTemplate={elseBranchTemplate}
                copyVerb={copyVerb}
                configContext={contextName}
                setEditElseBranch={setEditElseBranch}
              />
            )}
          </StackedList>
          <div className="flex justify-end">
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Naked}
              icon={IconEnum.Delete2}
              onClick={() => {
                formMethods.setValue<"issue_template_expression">(
                  "issue_template_expression",
                  undefined,
                );
                resetBranches();
              }}
              className="mr-1.5 text-slate-700"
            >
              Delete all
            </Button>
          </div>
        </div>
      ) : (
        <EmptyState
          icon={IconEnum.FollowUps}
          cta={
            <Button
              analyticsTrackingId={null}
              onClick={() => setAddBranch(true)}
              icon={IconEnum.Add}
            >
              Add condition
            </Button>
          }
          content={
            <>
              <div className="flex flex-wrap gap-1 items-center">
                With this configuration,{" "}
                <span className="font-semibold">{contextName}s</span> from{" "}
                <span className="font-semibold">
                  {privateIncidentsEnabled ? "all" : "non-private"} incidents
                </span>{" "}
                will be {copyVerb}ed
                <span> to </span>
                <span className="font-semibold">
                  {providerFullName(issueTemplates[0].provider)}
                </span>
                <span> using the </span>
                <EngineLiteralBadge
                  icon={iconForTemplateProvider(issueTemplates[0].provider)}
                  label={issueTemplates[0].name}
                />
                <span>template</span>
              </div>
            </>
          }
        />
      )}
      {editBranch && (
        <EditBranchModal
          issueTemplates={issueTemplates}
          onEditBranch={(newBranch) => {
            onEditBranch(editBranch.index, newBranch);
            setEditBranch(undefined);
          }}
          branch={editBranch.branch}
          onClose={() => setEditBranch(undefined)}
        />
      )}
      {addBranch && (
        <EditBranchModal
          issueTemplates={issueTemplates}
          onEditBranch={(newBranch) => {
            onAddBranch(newBranch);
            if (
              !formMethods.getValues<"issue_template_expression.else_branch">(
                "issue_template_expression.else_branch",
              )
            ) {
              formMethods.setValue<"issue_template_expression.else_branch">(
                "issue_template_expression.else_branch",
                {
                  result: {},
                },
              );
            }
            setAddBranch(false);
          }}
          onClose={() => setAddBranch(false)}
        />
      )}
      {editElseBranch && (
        <EditElseBranchModal
          syncConfig={syncConfig}
          issueTemplates={issueTemplates}
          onEditBranch={(elseBranch) => {
            formMethods.setValue(
              "issue_template_expression.else_branch",
              elseBranch,
            );
            formMethods.clearErrors();
            setEditElseBranch(false);
          }}
          onClose={() => setEditElseBranch(false)}
          copyVerb={copyVerb}
        />
      )}
    </div>
  );
};

const EditBranchModal = ({
  issueTemplates,
  branch,
  onEditBranch,
  onClose,
}: {
  issueTemplates: UnifiedIssueTemplate[];
  branch?: ExpressionBranch;
  onEditBranch: (branch: ExpressionBranch) => void;
  onClose: () => void;
}) => {
  const formMethods = useForm<ExpressionBranch>({
    defaultValues:
      branch ??
      (issueTemplates.length === 1 // default the template select to the first template if there is only one template
        ? {
            result: {
              value: { literal: issueTemplates[0].id },
            },
          }
        : undefined),
  });

  const { scope, scopeLoading, scopeError } = useFollowUpScope();

  if (scopeError) {
    return <GenericErrorMessage error={scopeError} />;
  }
  if (scopeLoading) {
    return <Loader />;
  }

  const options = issueTemplates.map((template) => ({
    label: template.name,
    value: template.id,
    icon: iconForTemplateProvider(template.provider),
  }));

  return (
    <FormModalV2
      analyticsTrackingId={null}
      onClose={onClose}
      title={branch ? "Edit conditions" : "Add new conditions"}
      formMethods={formMethods}
      onSubmit={(data) => {
        onEditBranch(data);
      }}
      footer={
        <ModalFooter
          confirmButtonText="Update"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <ConditionGroupsEditorV2
        formMethods={formMethods}
        name="condition_groups"
        scope={scope}
        subjectsLabel="incidents"
        entityNameLabel="rule"
        populatedIntroSentence="We will use this template when..."
        emptyIntroSentence="Please specify when we should use this template"
        required
        rules={{ minLength: 1 }}
      />
      <StaticSingleSelectV2
        label="Use issue template"
        formMethods={formMethods}
        name="result.value.literal"
        options={options}
        required
      />
    </FormModalV2>
  );
};

const Branch = <FormType extends FieldValues>({
  fieldName,
  branch: { condition_groups, result },
  prefix,
  issueTemplates,
  onEdit,
  onDelete,
  isOnlyBranch,
  isFirstItem,
  draggableProvided,
  configContext,
  copyVerb,
}: {
  fieldName: FieldPath<FormType>;
  branch: ExpressionBranch;
  prefix: string;
  issueTemplates: UnifiedIssueTemplate[];
  onEdit: () => void;
  onDelete: () => void;
  isOnlyBranch?: boolean;
  isFirstItem?: boolean;
  draggableProvided: DraggableProvided;
  configContext: string;
  copyVerb: string; // e.g. "sync" or "export".
}) => {
  const conditions = condition_groups?.[0]?.conditions || [];
  const template = issueTemplates.find(
    (template) => template.id === result.value?.literal,
  );
  return (
    <div
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      className={tcx(
        "flex flex-wrap w-full",
        isFirstItem ? "" : "border-t border-stroke",
      )}
    >
      <SettingsListItem
        title={
          <FormInputWrapperV2<FormType>
            name={fieldName}
            className="font-normal"
          >
            <div className="flex gap-2 !font-normal items-center">
              <div
                className="-ml-2 mr-2 h-[16px]"
                {...draggableProvided.dragHandleProps}
              >
                <Icon id={IconEnum.Draggable} size={IconSize.Small} />
              </div>
              <div>
                <div>
                  {conditions.length > 0 ? (
                    <span className="flex-center-y gap-3 mb-3">
                      <span className="text-sm-med shrink-0">{prefix}</span>
                      <CommaSeparatedConditionGroups
                        groups={condition_groups}
                      />
                    </span>
                  ) : null}
                </div>
                <ul className={"flex items-center flex-wrap text-sm space-x-2"}>
                  <span className="text-sm font-medium flex-shrink-0">
                    then use
                  </span>
                  {template ? (
                    <>
                      <EngineLiteralBadge
                        icon={iconForTemplateProvider(template.provider)}
                        label={template.name}
                      />{" "}
                      <span className="text-sm shrink-0">
                        to {copyVerb} {configContext} to{" "}
                        {providerFullName(template.provider)}
                      </span>
                    </>
                  ) : (
                    <Badge icon={IconEnum.Warning} theme={BadgeTheme.Error}>
                      Template not found
                    </Badge>
                  )}
                </ul>
              </div>
            </div>
          </FormInputWrapperV2>
        }
        buttons={{
          edit: {
            onEdit,
          },
          delete: {
            resourceTitle: "condition",
            onDelete,
            isGatedText: isOnlyBranch
              ? "Must have at least one set of conditions"
              : undefined,
            deleteConfirmationTitle: "Delete condition",
            deleteConfirmationContent: (
              <div className="space-y-2 text-sm">
                <span>
                  Are you sure you wish to delete this set of conditions?
                </span>
                <div>
                  {conditions.map((selectedCondition) => (
                    <SelectedCondition
                      className={"mr-2"}
                      key={selectedCondition.subject.reference}
                      condition={selectedCondition}
                      theme="slate"
                    />
                  ))}
                </div>
              </div>
            ),
          },
        }}
      />
    </div>
  );
};

const EditElseBranchModal = ({
  syncConfig,
  issueTemplates,
  onEditBranch,
  onClose,
  copyVerb,
}: {
  syncConfig: IssueTrackerSyncConfig;
  issueTemplates: UnifiedIssueTemplate[];
  onEditBranch: (branch: ExpressionElseBranch) => void;
  onClose: () => void;
  copyVerb: string; // e.g. "sync" or "export".
}) => {
  const configContext = contextForConfig(syncConfig.context);
  const topLevelFormMethods = useFormContext<FormData>();
  const elseBranch = topLevelFormMethods.watch(
    "issue_template_expression.else_branch",
  );
  const localFormMethods = useForm<ExpressionElseBranch & { is_null: boolean }>(
    {
      defaultValues: {
        ...elseBranch,
        is_null: !elseBranch?.result.value && !elseBranch?.result.array_value,
      },
    },
  );
  const [isNull] = localFormMethods.watch(["is_null"]);

  const options = issueTemplates.map((template) => ({
    label: template.name,
    value: template.id,
    icon: iconForTemplateProvider(template.provider),
  }));

  return (
    <FormModalV2
      analyticsTrackingId={null}
      onClose={onClose}
      title="Edit fallback behaviour"
      formMethods={localFormMethods}
      onSubmit={(data) => {
        onEditBranch(data);
      }}
      footer={
        <ModalFooter
          confirmButtonText="Update"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <FormInputWrapper
        id={"result"}
        errors={localFormMethods.formState.errors}
        label={`If ${configContext} does not match previous conditions`}
      >
        <fieldset className="mt-2 text-sm text-content-primary flex flex-col space-y-2">
          <div>
            <RadioButton
              id="default_value"
              className={"flex-shrink-0"}
              label={`Use a default template to ${copyVerb} ${configContext} to an issue tracker`}
              value={""}
              checked={!isNull}
              onChange={() => {
                localFormMethods.setValue<"is_null">("is_null", false);
                if (issueTemplates.length === 1) {
                  localFormMethods.setValue(
                    "result.value.literal",
                    issueTemplates[0].id,
                  );
                }
              }}
            />
            {!isNull && (
              <div className={"ml-5 mt-2"}>
                <StaticSingleSelectV2
                  formMethods={localFormMethods}
                  name="result.value.literal"
                  options={options}
                  disabled={isNull}
                  required={!isNull}
                />
              </div>
            )}
          </div>
          <RadioButton
            id="nothing"
            label={`Do not ${copyVerb} ${configContext} to any issue tracker`}
            value={""}
            checked={isNull}
            className="mb-2"
            onChange={() => {
              localFormMethods.setValue<"is_null">("is_null", true);
              localFormMethods.setValue<"result.value">(
                "result.value",
                undefined,
              );
              localFormMethods.setValue<"result.array_value">(
                "result.array_value",
                undefined,
              );
            }}
          />
        </fieldset>
      </FormInputWrapper>
    </FormModalV2>
  );
};

export const DoNotExportToTracker = ({
  elseBranchTemplate,
  copyVerb,
  configContext,
  setEditElseBranch,
}: {
  elseBranchTemplate: UnifiedIssueTemplate | undefined;
  copyVerb: string;
  configContext: string;
  setEditElseBranch: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <SettingsListItem
      title={
        <FormInputWrapperV2<FormData>
          name="issue_template_expression.else_branch"
          className="font-normal"
        >
          <div
            className={
              "flex items-center flex-wrap text-sm space-x-2 font-normal"
            }
          >
            <div className="-ml-2 mr-2 h-[16px] invisible">
              <Icon id={IconEnum.Draggable} size={IconSize.Small} />
            </div>
            <span className="font-medium">Otherwise</span>,{" "}
            {elseBranchTemplate ? (
              <>
                use{" "}
                <EngineLiteralBadge
                  icon={iconForTemplateProvider(elseBranchTemplate.provider)}
                  label={elseBranchTemplate.name}
                />{" "}
                <span className="text-sm flex-shrink-0">
                  to {copyVerb} {configContext} to{" "}
                  {providerFullName(elseBranchTemplate.provider)}
                </span>
              </>
            ) : (
              <>
                do not {copyVerb} {configContext} to any issue tracker
              </>
            )}
          </div>
        </FormInputWrapperV2>
      }
      buttons={{
        edit: {
          onEdit: () => setEditElseBranch(true),
        },
        delete: {
          isGatedText: "Must specify fallback behaviour",
          noDeleteConfirmation: true,
          onDelete: () => setEditElseBranch(true),
        },
      }}
    />
  );
};
