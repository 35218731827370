/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeatureGates,
    FeatureGatesFromJSON,
    FeatureGatesFromJSONTyped,
    FeatureGatesToJSON,
} from './FeatureGates';
import {
    PurchaseInfo,
    PurchaseInfoFromJSON,
    PurchaseInfoFromJSONTyped,
    PurchaseInfoToJSON,
} from './PurchaseInfo';

/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * Is annual invoicing supported?
     * @type {boolean}
     * @memberof Plan
     */
    annual_invoicing: boolean;
    /**
     * 
     * @type {PurchaseInfo}
     * @memberof Plan
     */
    annual_purchase: PurchaseInfo;
    /**
     * How long will we retain data
     * @type {string}
     * @memberof Plan
     */
    data_retention: string;
    /**
     * A short description for this plan
     * @type {string}
     * @memberof Plan
     */
    description: string;
    /**
     * 
     * @type {FeatureGates}
     * @memberof Plan
     */
    features: FeatureGates;
    /**
     * A human readable name for the Plan
     * @type {string}
     * @memberof Plan
     */
    label: string;
    /**
     * Minimum yearly price in thousands of dollars
     * @type {number}
     * @memberof Plan
     */
    minimum_price_thousands?: number;
    /**
     * Minimum number of seats that can be purchased
     * @type {number}
     * @memberof Plan
     */
    minimum_seats: number;
    /**
     * 
     * @type {PurchaseInfo}
     * @memberof Plan
     */
    monthly_purchase: PurchaseInfo;
    /**
     * A unique name for this plan
     * @type {string}
     * @memberof Plan
     */
    name: PlanNameEnum;
    /**
     * How fast we'll respond to queries
     * @type {string}
     * @memberof Plan
     */
    response_sla: string;
    /**
     * How we will offer support on this plan
     * @type {string}
     * @memberof Plan
     */
    support_type: string;
}

/**
* @export
* @enum {string}
*/
export enum PlanNameEnum {
    Legacy = 'legacy',
    Trial = 'trial',
    StarterV1 = 'starter_v1',
    ProV1 = 'pro_v1',
    EnterpriseV1 = 'enterprise_v1',
    BasicV2 = 'basic_v2',
    TeamV2 = 'team_v2',
    ProV2 = 'pro_v2',
    EnterpriseV2 = 'enterprise_v2'
}

export function PlanFromJSON(json: any): Plan {
    return PlanFromJSONTyped(json, false);
}

export function PlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): Plan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annual_invoicing': json['annual_invoicing'],
        'annual_purchase': PurchaseInfoFromJSON(json['annual_purchase']),
        'data_retention': json['data_retention'],
        'description': json['description'],
        'features': FeatureGatesFromJSON(json['features']),
        'label': json['label'],
        'minimum_price_thousands': !exists(json, 'minimum_price_thousands') ? undefined : json['minimum_price_thousands'],
        'minimum_seats': json['minimum_seats'],
        'monthly_purchase': PurchaseInfoFromJSON(json['monthly_purchase']),
        'name': json['name'],
        'response_sla': json['response_sla'],
        'support_type': json['support_type'],
    };
}

export function PlanToJSON(value?: Plan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annual_invoicing': value.annual_invoicing,
        'annual_purchase': PurchaseInfoToJSON(value.annual_purchase),
        'data_retention': value.data_retention,
        'description': value.description,
        'features': FeatureGatesToJSON(value.features),
        'label': value.label,
        'minimum_price_thousands': value.minimum_price_thousands,
        'minimum_seats': value.minimum_seats,
        'monthly_purchase': PurchaseInfoToJSON(value.monthly_purchase),
        'name': value.name,
        'response_sla': value.response_sla,
        'support_type': value.support_type,
    };
}

