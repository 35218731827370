import { EngineScope, Trigger } from "@incident-io/api";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { Icon, IconEnum, LoadingBar } from "@incident-ui";
import { useFormContext } from "react-hook-form";

import { WorkflowFormData } from "../../common/types";
import { noConditionsOperationText } from "../../list/WorkflowsListItemAccordion";

export const WorkflowsCreateEditConditions = ({
  loading,
  scopeWithExpressions,
  trigger,
}: {
  loading: boolean;
  scopeWithExpressions: EngineScope;
  trigger?: Trigger;
}) => {
  const formMethods = useFormContext<WorkflowFormData>();

  const conditionGroups = formMethods.watch("condition_groups");

  const triggerName = trigger?.name;

  return (
    <div className="p-3 border-[1px] rounded-2 border-radius border-stroke bg-surface-secondary w-full flex flex-col gap-3 items-start">
      <div className="flex flex-row gap-1">
        <Icon id={IconEnum.Filter} className="text-content-tertiary" />
        {conditionGroups.length === 0 ? (
          <p>
            Then applies to{" "}
            <span className="font-medium">
              {triggerName
                ? noConditionsOperationText(triggerName)
                : "all incidents"}
            </span>
          </p>
        ) : (
          <p>Then checks the following conditions...</p>
        )}
      </div>

      {loading ? (
        <LoadingBar />
      ) : (
        <ConditionGroupsEditorV2
          formMethods={formMethods}
          name="condition_groups"
          scope={scopeWithExpressions}
          className="w-full"
          allowFilteringByExpression
          hideIntroSentence
        />
      )}
    </div>
  );
};
