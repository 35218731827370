import { EngineFormElement, isEmptyBinding } from "@incident-shared/engine";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { INTEGRATION_CONFIGS } from "@incident-shared/integrations";
import {
  GenericErrorMessage,
  LoadingModal,
  ModalFooter,
  Spinner,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { ErrorBoundary } from "@sentry/react";
import { uniqBy } from "lodash";
import { useMemo } from "react";
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import { IssueTemplateContextEnum } from "src/components/settings/follow-ups/FollowUpExportSection";
import {
  EngineParamBindingPayload,
  ErrorResponse,
  IssueTemplate,
  IssueTrackerFieldValue,
  IssueTrackerFieldValuePayload,
  IssueTrackersV2CreateIssueTemplateRequestBody,
  IssueTrackersV2CreateIssueTemplateRequestBodyContextEnum,
  IssueTrackersV2CreateIssueTemplateRequestBodyProviderEnum,
  ScopeNameEnum as ScopeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useFollowUpScope } from "src/hooks/useFollowUpScope";
import { useAllResources } from "src/hooks/useResources";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import {
  HasFieldValues,
  useClearDependencies,
  useIssueCreationFields,
} from "./hooks";
import { ISSUE_TRACKER_PROVIDERS, IssueTrackerProviderEnum } from "./utils";

type FormType = Omit<
  IssueTrackersV2CreateIssueTemplateRequestBody,
  "field_values" | "provider" | "context" | "expressions"
> & {
  field_values: Record<string, EngineParamBindingPayload>;
  expressions: ExpressionFormData[];
};

const MaybeCheckYourIntegrationError = ({
  error,
}: {
  error: Error | ErrorResponse | undefined;
}) => (
  <GenericErrorMessage
    description="If this error persists, you may want to check the status of your integration in integration settings."
    error={error}
  />
);

function fieldValuesToPayload(
  fieldValues: Record<string, EngineParamBindingPayload>,
) {
  return Object.entries(fieldValues).map(
    ([key, value]): IssueTrackerFieldValuePayload => ({
      key,
      param_binding: isEmptyBinding(value) ? undefined : value,
    }),
  );
}

function fieldValuesToFormValues(fieldValues: IssueTrackerFieldValue[]) {
  return fieldValues.reduce((acc, { key, param_binding }) => {
    return {
      ...acc,
      [key]: param_binding,
    };
  }, {});
}

export const CreateEditIssueTemplateModal = ({
  provider,
  context,
  onClose,
  id,
}: {
  id: string | undefined;
  provider: IssueTrackerProviderEnum;
  context: IssueTemplateContextEnum;
  onClose: () => void;
}): React.ReactElement => {
  const {
    data: templateData,
    isLoading: templateLoading,
    error: templateError,
  } = useAPI(id ? "issueTrackersV2ShowIssueTemplate" : null, { id: id ?? "" });

  if (id && templateError) {
    return <ErrorModal error={templateError} onClose={onClose} />;
  }

  if (templateLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  const template = templateData?.issue_template;

  if (id && !template) {
    return (
      <ErrorModal
        error={new Error(`Template with id ${id} not found`)}
        onClose={onClose}
      />
    );
  }

  return (
    <IssueTemplateModal
      {...(template
        ? { mode: Mode.Edit, initialData: template }
        : { mode: Mode.Create })}
      provider={provider}
      context={context}
      onClose={onClose}
    />
  );
};

const IssueTemplateModal = ({
  initialData: template,
  mode,
  provider,
  context,
  onClose,
}: CreateEditFormProps<IssueTemplate> & {
  provider: IssueTrackerProviderEnum;
  context: IssueTemplateContextEnum;
  onClose: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeEnum.OrganisationSettingsUpdate);

  const formMethods = useForm<FormType>({
    defaultValues: {
      name: template?.name ?? undefined,
      field_values: template
        ? fieldValuesToFormValues(template.field_values)
        : {},
      expressions: template?.expressions || [],
    },
    mode: "onSubmit",
  });

  const { data, isValidating, error } = useIssueCreationFields({
    provider,
    issueTemplateId: template?.id,
    formMethods: formMethods as unknown as UseFormReturn<HasFieldValues>,
  });

  // When a field's value changes, we need to clear the value in any fields that
  // depend on it.
  useClearDependencies(
    formMethods as unknown as UseFormReturn<HasFieldValues>,
    data.fields,
  );

  // We want to revalidate the show endpoint as well as the list endpoint, so when we
  // update a new template the user sees the changes immediately.
  const revalidate = useAPIRefetch(
    template ? "issueTrackersV2ShowIssueTemplate" : null,
    {
      id: template?.id ?? "",
    },
  );

  const {
    trigger: onCreate,
    isMutating: savingCreate,
    genericError: createError,
  } = useAPIMutation(
    "issueTrackersV2ListIssueTemplates",
    {},
    async (apiClient, data: FormType) => {
      if (mode === Mode.Edit) {
        await apiClient.issueTrackersV2UpdateIssueTemplate({
          id: template?.id as string,
          updateIssueTemplateRequestBody: {
            ...data,
            field_values: fieldValuesToPayload(data.field_values),
            expressions: (data.expressions || []).map(expressionToPayload),
          },
        });
      } else {
        await apiClient.issueTrackersV2CreateIssueTemplate({
          createIssueTemplateRequestBody: {
            ...data,
            provider:
              provider as unknown as IssueTrackersV2CreateIssueTemplateRequestBodyProviderEnum,
            field_values: fieldValuesToPayload(data.field_values),
            context:
              context as unknown as IssueTrackersV2CreateIssueTemplateRequestBodyContextEnum,
            expressions: (data.expressions || []).map(expressionToPayload),
          },
        });
      }
    },
    {
      onSuccess: () => {
        revalidate();
        onClose();
      },
      setError: formMethods.setError,
    },
  );

  const { scope, scopeLoading, scopeError } = useFollowUpScope();
  const { resources, resourcesLoading, resourcesError } = useAllResources();

  const allResources = useMemo(
    () => uniqBy([...resources, ...data.resources], (res) => res.type),
    [resources, data.resources],
  );

  const { control, watch } = formMethods;

  const expressionsMethods = useFieldArray({
    control,
    name: "expressions",
    keyName: "key",
  });

  const expressions = watch("expressions");

  const scopeWithExpressions = addExpressionsToScope(scope, expressions ?? []);

  if (resourcesError ?? scopeError ?? error) {
    return (
      <ErrorModal
        error={resourcesError ?? scopeError ?? error}
        onClose={onClose}
      />
    );
  }

  if (scopeLoading || !scope || resourcesLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  const providerName = INTEGRATION_CONFIGS[provider].label;
  const { issueNoun } = ISSUE_TRACKER_PROVIDERS[provider];

  const fieldsToShow = data.fields.filter((field) => {
    if (field.is_hidden) return false;

    return true;
  });

  let disabledTooltipContent = "This default is not configurable.";
  if (context === IssueTemplateContextEnum.FollowUp) {
    disabledTooltipContent =
      "This default is not configurable. However you can always modify this field during manual export.";
  }

  return (
    <FormModalV2
      formMethods={formMethods}
      title={`${providerName}: ${
        mode === Mode.Create ? "Create" : "Edit"
      } ${issueNoun} template`}
      analyticsTrackingId={`${mode}-issue-tracker-template`}
      onClose={onClose}
      onSubmit={onCreate}
      genericError={createError}
      // Our engine form elements look much nicer on a white background
      contentClassName="!bg-white"
      footer={
        <ModalFooter
          disabled={!canEditSettings}
          confirmButtonText={mode === Mode.Create ? "Create" : "Save"}
          confirmButtonType="submit"
          saving={savingCreate}
          onClose={onClose}
        />
      }
    >
      <ErrorBoundary fallback={MaybeCheckYourIntegrationError}>
        <ExpressionsMethodsProvider
          expressionsMethods={expressionsMethods}
          allowAllOfACatalogType={false} // We skip catalog types so we're more performant, but might be asked to support this in the future.
        >
          <InputV2
            formMethods={formMethods}
            name="name"
            label="Template name"
            placeholder="Enter a name for your template"
            required="Please provide a name for your template"
          />
          {isValidating && <Spinner className="absolute top-20 right-4" />}
          {fieldsToShow.map((field) => (
            // We use a key here to force the component to un- and re-mount when
            // either its name changes (which means it's a totally different
            // field), or when its _type_ changes.
            //
            // The type is necessary because `react-select` (which powers our
            // dropdowns) caches typeahead options for as long as it remains
            // mounted. When the type changes, the typeahead options will likely
            // change, meaning that we need to remount the dropdown to bust that
            // cache.
            <EngineFormElement
              key={`${field.param.name}:${field.param.type}`}
              name={`field_values.${field.param.name}`}
              label={field.param.label}
              showPlaceholder={true}
              resourceType={field.param.type}
              required={!field.param.optional}
              disabled={field.is_not_templatable}
              disabledTooltipContent={disabledTooltipContent}
              resources={allResources}
              array={field.param.array}
              {...(!field.variables_allowed
                ? { mode: "plain_input" }
                : {
                    mode: "variables_and_expressions",
                    scope: scopeWithExpressions,
                  })}
            />
          ))}
        </ExpressionsMethodsProvider>
      </ErrorBoundary>
    </FormModalV2>
  );
};
