import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { ButtonSize } from "@incident-ui/Button/Button";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { UserPreferencesModal } from "./header/UserPreferencesModal";

function SubscriptionButton({
  isSubscribed,
  isLoadingSubscription,
  subscribe,
  unsubscribe,
  theme,
  size,
  className,
}: {
  subscribe: () => void;
  className?: string;
  unsubscribe: () => void;
  isSubscribed: boolean | undefined;
  isLoadingSubscription: boolean;
  theme: ButtonTheme;
  size: ButtonSize;
}): React.ReactElement {
  const [icon, setIcon] = useState(
    isSubscribed ? IconEnum.Tick : IconEnum.Subscription,
  );

  return (
    <Button
      analyticsTrackingId="incident-subscribe"
      className={tcx(
        "shrink-0 hover:!no-underline transition",
        {
          "hover:!text-alarmalade-600 after:content-['Subscribed'] hover:after:content-['Unsubscribe']":
            isSubscribed,
          "hover:text-content-primary after:content-['Subscribe']":
            !isSubscribed,
          "hover:!bg-alarmalade-100":
            theme === ButtonTheme.Secondary && isSubscribed,
        },
        className,
      )}
      type="button"
      theme={theme}
      size={size}
      icon={icon}
      disabled={isLoadingSubscription}
      onMouseEnter={() => {
        if (isSubscribed) {
          setIcon(IconEnum.Close);
        } else {
          setIcon(IconEnum.Subscription);
        }
      }}
      onMouseLeave={() => {
        if (isSubscribed) {
          setIcon(IconEnum.Tick);
        } else {
          setIcon(IconEnum.Subscription);
        }
      }}
      onClick={(e) => {
        // this is so that when we nest this in a LinkButton we don't propagate the event up
        e.preventDefault();
        if (isSubscribed) {
          setIcon(IconEnum.Subscription);
          unsubscribe();
        } else {
          setIcon(IconEnum.Close);
          subscribe();
        }
      }}
    >
      <></>
    </Button>
  );
}

export const useIncidentHasSubscription = ({
  incidentId,
  internalStatusPageId,
}: {
  incidentId: string;
  internalStatusPageId?: string;
}) => {
  const { data, isLoading } = useAPI("incidentSubscriptionsGet", {
    incidentId,
  });
  const isSubscribed = data
    ? data.incident_subscriptions.length > 0
    : undefined;

  const { trigger: subscribe, isMutating: isSubscribing } = useAPIMutation(
    "incidentSubscriptionsGet",
    { incidentId },
    async (apiClient) => {
      await apiClient.incidentSubscriptionsCreate({
        createRequestBody: {
          incident_id: incidentId,
          internal_status_page_id: internalStatusPageId,
        },
      });
    },
  );

  const { trigger: unsubscribe, isMutating: isUnsubscribing } = useAPIMutation(
    "incidentSubscriptionsGet",
    { incidentId },
    async (apiClient) => {
      await apiClient.incidentSubscriptionsUnsubscribe({
        incidentId,
      });
    },
  );

  return {
    subscribe: () => subscribe({}),
    unsubscribe: () => unsubscribe({}),
    isSubscribed,
    loading: isLoading || isSubscribing || isUnsubscribing,
  };
};

export const ToggleSubscriptionButton = ({
  incidentId,
  theme = ButtonTheme.Secondary,
  size = ButtonSize.Medium,
  className,
}: {
  incidentId: string;
  theme?: ButtonTheme;
  size?: ButtonSize;
  className?: string;
}): React.ReactElement => {
  const userPreferences = useAPI("userPreferencesShow", undefined).data
    ?.user_preferences;

  const [modalIsOpen, setModalOpen] = useState(false);

  const userPreferencesRequireSetup =
    !userPreferences ||
    !(
      userPreferences.receives_subscriptions_via_email ||
      userPreferences.receives_subscriptions_via_slack_DM ||
      userPreferences.receives_subscriptions_via_sms
    );

  const { isSubscribed, subscribe, loading, unsubscribe } =
    useIncidentHasSubscription({ incidentId });

  return (
    <>
      <AnimatePresence>
        {modalIsOpen && (
          <UserPreferencesModal
            incidentId={incidentId}
            onClose={() => setModalOpen(false)}
          />
        )}
      </AnimatePresence>
      <LoadingWrapper loading={loading} large={false}>
        <SubscriptionButton
          subscribe={() =>
            userPreferencesRequireSetup ? setModalOpen(true) : subscribe()
          }
          unsubscribe={unsubscribe}
          isSubscribed={isSubscribed ?? undefined}
          isLoadingSubscription={loading}
          theme={theme}
          className={className}
          size={size}
        />
      </LoadingWrapper>
    </>
  );
};
