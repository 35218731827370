import { Priority } from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  IconEnum,
  OrgAwareLink,
} from "@incident-ui";
import { useAPI } from "src/utils/swr";

enum AlertPriorityMode {
  Highest = "highest",
  Medium = "medium",
  Lowest = "lowest",
}

function getPriorityMode(priority: Priority, count: number) {
  if (priority.rank === 0) {
    return AlertPriorityMode.Highest;
  }
  if (priority.rank === count - 1) {
    return AlertPriorityMode.Lowest;
  }
  return AlertPriorityMode.Medium;
}

function getIconForPriorityMode(mode: AlertPriorityMode): IconEnum {
  switch (mode) {
    case AlertPriorityMode.Highest:
      return IconEnum.HighAlertPriority;
    case AlertPriorityMode.Medium:
      return IconEnum.MediumAlertPriority;
    case AlertPriorityMode.Lowest:
      return IconEnum.LowAlertPriority;
    default:
      throw new Error(`Unknown priority mode: ${mode}`);
  }
}

export const AlertPriorityBadge = ({ priority }: { priority: Priority }) => {
  const { data, isLoading, error } = useAPI("alertsListPriorities", {
    includeArchived: true,
  });

  if (isLoading || error || !data) {
    return null;
  }

  const totalCount = data.priorities.filter((p) => !p.archived_at).length;

  const mode = getPriorityMode(priority, totalCount);

  return (
    <OrgAwareLink to={"/alerts/priorities"} className="hover:underline">
      <Badge
        theme={BadgeTheme.Secondary}
        icon={getIconForPriorityMode(mode)}
        className="hover:cursor-pointer"
        size={BadgeSize.Medium}
      >
        {priority.name}
      </Badge>
    </OrgAwareLink>
  );
};
