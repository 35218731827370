/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostmortemGenerationOptions
 */
export interface PostmortemGenerationOptions {
    /**
     * The destination we should create the postmortem in
     * @type {string}
     * @memberof PostmortemGenerationOptions
     */
    destination_id: string;
    /**
     * 
     * @type {string}
     * @memberof PostmortemGenerationOptions
     */
    template_id: string;
    /**
     * The timezone postmortems will be generated in
     * @type {string}
     * @memberof PostmortemGenerationOptions
     */
    timezone: string;
}

export function PostmortemGenerationOptionsFromJSON(json: any): PostmortemGenerationOptions {
    return PostmortemGenerationOptionsFromJSONTyped(json, false);
}

export function PostmortemGenerationOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemGenerationOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'destination_id': json['destination_id'],
        'template_id': json['template_id'],
        'timezone': json['timezone'],
    };
}

export function PostmortemGenerationOptionsToJSON(value?: PostmortemGenerationOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'destination_id': value.destination_id,
        'template_id': value.template_id,
        'timezone': value.timezone,
    };
}

