import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import React, { ForwardedRef, SyntheticEvent } from "react";
import { tcx } from "src/utils/tailwind-classes";

export enum BadgeSize {
  ExtraSmall = "xs",
  Small = "sm",
  Medium = "md",
  Large = "lg",
}

export enum BadgeTheme {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Success = "success",
  Naked = "naked",
  Error = "error",
  Info = "info",
  Warning = "warning",
  Unstyled = "unstyled",
}

const themeStyles: { [key in BadgeTheme]: string } = {
  [BadgeTheme.Primary]: "bg-surface-invert text-white",
  [BadgeTheme.Secondary]:
    "bg-surface-primary text-content-primary border border-stroke",
  [BadgeTheme.Tertiary]: "bg-surface-secondary text-content-secondary",
  [BadgeTheme.Naked]: "bg-transparent text-content-secondary",
  [BadgeTheme.Error]: "bg-red-surface text-red-content",
  [BadgeTheme.Info]: "bg-[#DBEAFE] text-[#3B82F6]",
  [BadgeTheme.Success]: "bg-green-surface text-green-content ",
  [BadgeTheme.Warning]: "bg-amber-surface text-amber-content",
  [BadgeTheme.Unstyled]: "",
};

type SizeConfig = {
  className: string;
  iconOnlyClassName: string;
  nakedClassName: string;
};

const sizeStyles: { [key in BadgeSize]: SizeConfig } = {
  [BadgeSize.ExtraSmall]: {
    className: "text-xs px-1 h-5 rounded-1 gap-1 font-medium",
    iconOnlyClassName: "h-5 w-5 rounded-1",
    nakedClassName: "text-xs gap-0.5",
  },
  [BadgeSize.Small]: {
    className: "text-xs px-2 h-6 rounded-1 gap-1 font-medium",
    iconOnlyClassName: "h-6 w-6 rounded-1",
    nakedClassName: "text-xs gap-0.5",
  },
  [BadgeSize.Medium]: {
    className: "text-sm px-2 h-7 rounded-1 gap-2 font-medium",
    iconOnlyClassName: "h-7 w-7 rounded-1",
    nakedClassName: "text-sm gap-0.5",
  },
  [BadgeSize.Large]: {
    className: "text-sm px-3 h-10 rounded-2 gap-2 font-medium",
    iconOnlyClassName: "h-10 w-10 rounded-2",
    nakedClassName: "text-sm gap-1",
  },
};

export const badgeSizeToIconSize: { [key in BadgeSize]: IconSize } = {
  [BadgeSize.ExtraSmall]: IconSize.Small,
  [BadgeSize.Small]: IconSize.Small,
  [BadgeSize.Medium]: IconSize.Small,
  [BadgeSize.Large]: IconSize.Medium,
};

export type BadgeProps = {
  children?: React.ReactNode;
  label?: string;
  size?: BadgeSize;
  theme: BadgeTheme;
  onClose?: (e?: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  className?: string;
  icon?: IconEnum;
  iconClassName?: string;
  closeButtonClassName?: string;
};

export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  (props: BadgeProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      children,
      label,
      size = BadgeSize.Small,
      theme,
      onClose,
      className,
      icon,
      iconClassName,
      closeButtonClassName,
      // This 'rest' is what Radix uses to register event handlers, which you need if you want to
      // use a Badge as a trigger for a tooltip or popover.
      ...rest
    } = props;

    const iconOnly = !children && !label;
    const sizeConfig = sizeStyles[size];
    return (
      <span
        ref={ref}
        className={tcx(
          theme === BadgeTheme.Naked
            ? sizeConfig.nakedClassName
            : iconOnly
            ? sizeConfig.iconOnlyClassName
            : sizeConfig.className,
          themeStyles[theme],
          "my-auto truncate",
          "inline-flex items-center shrink-0 whitespace-nowrap",
          iconOnly ? "justify-center" : "justify-start",
          "cursor-default",
          className,
        )}
        {...rest}
      >
        {icon && (
          <Icon
            size={badgeSizeToIconSize[size]}
            className={iconClassName}
            id={icon}
          />
        )}
        {children || label}
        {onClose != null && (
          <Button
            theme={ButtonTheme.Unstyled}
            icon={IconEnum.Close}
            analyticsTrackingId={null}
            className={tcx(
              "h-4 w-4 opacity-40 hover:opacity-100",
              closeButtonClassName,
            )}
            onClick={onClose}
            title="Remove option"
          />
        )}
      </span>
    );
  },
);

Badge.displayName = "Badge";
