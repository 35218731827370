import {
  AlertsCreateDraftSourceConfigResponseBody,
  AlertsListSourcesResponseBody,
  AlertSource,
  AlertSourceClassificationEnum,
  AlertSourceSourceTypeEnum,
  EligibilityInformationIneligibleTypeEnum,
} from "@incident-io/api";
import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations";
import {
  PageWidth,
  pageWidthToStyles,
} from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconEnum,
  IconSize,
  Loader,
  Spinner,
  Tooltip,
  Txt,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { SelectionIndicator } from "@incident-ui/SelectionIndicator/SelectionIndicator";
import { Searcher } from "fast-fuzzy";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useClient } from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useDebounce } from "use-hooks";

import { AlertSourceTypeIcon } from "../common/AlertSourceTypeConfigs";
import alertSourceHeader from "./alert_source_header.svg";
import {
  AlertSourceFormIds,
  AlertSourceStepEnum,
} from "./AlertSourceCreateEditPage";
import { AlertSourceStepsOrTabs } from "./AlertSourceLayout";

export type FormData = {
  name: string;
  source_type: string;
  search_term: string;
};

export const AlertSourceCreatePage = ({
  setValidSourceSelected,
  continueButton,
  setCurrentTab,
}: {
  setValidSourceSelected: (isSelected: boolean) => void;
  continueButton: React.ReactNode;
  setCurrentTab: (tabId: string) => void;
}) => {
  const navigate = useOrgAwareNavigate();
  const queryParams = useQueryParams();
  const { showMessages } = useIntercom();

  const defaultSourceType = queryParams.get("source_type");

  const formMethods = useForm<FormData>({
    defaultValues: {
      source_type: defaultSourceType || undefined,
    },
  });
  const apiClient = useClient();

  const { data: alertSourcesResponse, isLoading: alertSourcesIsLoading } =
    useAPI("alertsListSources", undefined);

  const selectAlertSource = (source: AlertSource) => {
    formMethods.clearErrors("name");
    formMethods.clearErrors("source_type");

    /* This handler is also called when clicking the alert source card, so only
       reset the name if we are changing the source type (we don't want to overwrite
        the user's current input). */
    if (
      formMethods.getValues<"source_type">("source_type") !== source.source_type
    ) {
      formMethods.setValue<"name">("name", `${source.name} alerts`);
      formMethods.setValue<"source_type">("source_type", source.source_type);
    }
    setValidSourceSelected(source.eligibility?.is_eligible ?? true);
  };

  const [onSubmit, { saving, genericError }] = useMutation(
    async (request: FormData) => {
      const resp = await apiClient.alertsCreateDraftSourceConfig({
        createDraftSourceConfigRequestBody: {
          source_type: request.source_type,
          name: request.name,
        },
      });
      return { resp };
    },
    {
      onSuccess: ({
        resp,
      }: {
        resp: AlertsCreateDraftSourceConfigResponseBody;
      }) => {
        const fromQueryParam = queryParams.get("from");
        const fromQueryString = fromQueryParam ? `&from=${fromQueryParam}` : "";

        return navigate(
          `/alerts/sources/create?step=connect&id=${resp.alert_source_config.id}${fromQueryString}`,
        );
      },
      setError: formMethods.setError,
    },
  );

  if (!alertSourcesResponse) {
    return <Spinner />;
  }

  const selectedSourceType = formMethods.watch("source_type");
  const selectedSource = alertSourcesResponse.alert_sources?.find(
    (x) => x.source_type === selectedSourceType,
  );
  if (selectedSourceType && !selectedSource) {
    throw new Error(
      `Unreachable: could not find source type ${selectedSourceType}`,
    );
  }

  const selectedSourceDisplayConfig =
    ALERT_SOURCE_TYPE_CONFIGS[selectedSourceType];

  if (alertSourcesIsLoading) {
    return <Loader className={"h-full"} />;
  }

  return (
    <FormV2
      outerClassName="h-full"
      innerClassName="h-full"
      loadingWrapperClassName="h-full"
      id={AlertSourceFormIds[AlertSourceStepEnum.Create]}
      formMethods={formMethods}
      onSubmit={onSubmit}
      saving={saving}
    >
      <div
        className="w-full h-full"
        style={
          selectedSourceDisplayConfig
            ? // Our hex color with opacity 10, for 10% of the background, then white
              {
                background: `linear-gradient(${selectedSourceDisplayConfig.hexColor}10 0%, white 15%, white 100%)`,
              }
            : undefined
        }
      >
        <div
          className={tcx(
            "flex flex-col text-sm items-center p-8 h-full mx-auto mb-8 overflow-y-auto",
            pageWidthToStyles[PageWidth.Narrow],
          )}
        >
          <div className="w-full flex items-center mb-4">
            <AlertSourceStepsOrTabs
              mode={Mode.Create}
              step={AlertSourceStepEnum.Create}
              className="grow"
              setCurrentTab={setCurrentTab}
            />
          </div>
          <img src={alertSourceHeader} className="mb-5" />
          <Txt slate-200 className="mb-2 text-2xl leading-8 font-bold">
            Where do your alerts come from?
          </Txt>
          <Txt lightGrey className="mb-10 text-sm leading-5 w-3/4 text-center">
            Select your alert source from the list below. If you can&apos;t see
            your alert source, you can request we add it or connect via HTTP.
          </Txt>
          <InputV2
            autoFocus
            formMethods={formMethods}
            name={"search_term"}
            type={InputType.Search}
            iconName={IconEnum.Search}
            iconProps={{ className: "mr-2" }}
            className="mb-8 w-full bg-surface-secondary rounded"
            placeholder="Search for your source"
          />
          <AlertSourceSelection
            alertSources={alertSourcesResponse}
            onClick={selectAlertSource}
            selectedSource={selectedSourceType}
            formMethods={formMethods}
            continueButton={continueButton}
          />
          <div className="pb-16 w-full">
            <div className="flex flex-col space-y-4 bg-slate-50 border rounded-2 border-neutral-100 justify-between p-4 mt-4">
              <div>
                <Txt bold>Can&apos;t see your alert source?</Txt>
                <Txt>
                  Connect your alert source via HTTP or request we add an alert
                  source to this list
                </Txt>
              </div>
              <div className="flex items-center space-x-2">
                <Button
                  analyticsTrackingId={"alert-sources.speak-to-us"}
                  onClick={() => {
                    formMethods.setValue("name", `HTTP alerts`);
                    formMethods.setValue(
                      "source_type",
                      AlertSourceSourceTypeEnum.Http,
                    );
                    setValidSourceSelected(true);
                    document.getElementById("source-http")?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  theme={ButtonTheme.Primary}
                >
                  Connect via HTTP
                </Button>
                <Button
                  analyticsTrackingId={"alert-sources.speak-to-us"}
                  onClick={() => showMessages()}
                >
                  Request alert source
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorMessageUI message={genericError} />
    </FormV2>
  );
};

export const AlertSourceSelection = ({
  alertSources,
  onClick,
  className,
  selectedSource,
  formMethods,
  continueButton,
}: {
  alertSources: AlertsListSourcesResponseBody;
  onClick: (source: AlertSource) => void;
  className?: string;
  selectedSource?: string;
  formMethods: UseFormReturn<FormData>;
  continueButton: React.ReactNode;
}) => {
  const analytics = useAnalytics();
  const { expelAlertSource } = useFlags();

  const [selection, searchTerm] = formMethods.watch([
    "source_type",
    "search_term",
  ]);

  // TODO: Feature flagging Expel for now but this needs removing
  let sources = alertSources.alert_sources.filter(
    (as) => expelAlertSource || as.source_type !== "expel",
  );

  const searcher = new Searcher(sources, {
    keySelector: (s) => s.name,
    threshold: 0.8,
  });

  if (searchTerm && searchTerm !== "") {
    sources = searcher.search(searchTerm);
  }

  const { setValue, getValues } = formMethods;

  // When the user selects a source type, we preset the name field
  useEffect(() => {
    if (selection) {
      const selectedSource = sources.find((x) => x.source_type === selection);
      if (!selectedSource) {
        // Unselect the alert source if it's not in the list anymore (filtered out by search)
        setValue<"source_type">("source_type", "");
        setValue<"name">("name", "");
      }
    }
  }, [sources, setValue, getValues, selection]);

  const debouncedSearchTerm = useDebounce(searchTerm, 250);
  useEffect(() => {
    if (
      searchTerm &&
      debouncedSearchTerm?.length &&
      debouncedSearchTerm[0] === searchTerm
    ) {
      analytics?.track(`alert-sources.searched`, {
        search_term: debouncedSearchTerm[0],
      });
    }
  }, [analytics, debouncedSearchTerm, searchTerm]);

  const alertSourceConfigs = _.orderBy(sources || [], ["name"], "asc");

  return (
    <div className="flex flex-col space-y-2 w-full">
      {alertSourceConfigs
        .filter(
          (alertSource) =>
            alertSource.classification !== AlertSourceClassificationEnum.Native,
        )
        .map((source, index) => (
          <AlertSourceCardSlim
            selectedSource={selectedSource}
            key={index}
            alertSource={source}
            onClick={() => onClick(source)}
            className={className}
            formMethods={formMethods}
            continueButton={continueButton}
          />
        ))}
    </div>
  );
};

const AlertSourceCardSlim = ({
  alertSource,
  onClick,
  selectedSource,
  formMethods,
  continueButton,
}: {
  alertSource: AlertSource;
  onClick: () => void;
  selectedSource?: string;
  className?: string;
  formMethods: UseFormReturn<FormData>;
  continueButton: React.ReactNode;
}) => {
  const navigate = useOrgAwareNavigate();

  const isSelected = selectedSource === alertSource.source_type;
  const isEligible = alertSource.eligibility
    ? alertSource.eligibility.is_eligible
    : true;
  const needsSetup =
    alertSource.eligibility?.ineligible_type ===
    EligibilityInformationIneligibleTypeEnum.NeedsSetup;
  const isEligibleOrNeedsSetup = isEligible || needsSetup;
  const isUnique = alertSource.max_instances === 1;

  return (
    <div
      id={`source-${alertSource.source_type}`} // This is used for the HTTP source button to scroll to this div
      className={tcx(
        "flex flex-col w-full p-3 group hover:cursor-pointer rounded-2",
        {
          "bg-slate-50 border border-neutral-100": !isEligibleOrNeedsSetup,
          "transition border border-stroke hover:border-slate-500 hover:shadow-md":
            isEligibleOrNeedsSetup,
          "!cursor-not-allowed": !isEligible && !needsSetup,
          "!border-slate-900": isSelected,
        },
      )}
      onClick={isEligibleOrNeedsSetup ? onClick : undefined}
    >
      <div className={tcx("flex justify-between text-slate-400")}>
        <div className="flex-center-y space-x-2">
          <div className="flex-center p-1 rounded-2 border border-stroke">
            <AlertSourceTypeIcon
              className={tcx({ "opacity-50": !isEligibleOrNeedsSetup })}
              size={IconSize.Large}
              sourceType={
                alertSource.source_type as unknown as AlertSourceSourceTypeEnum
              }
            />
          </div>
          <div className="flex-center space-x-1">
            <Txt bold lightGrey={!isEligibleOrNeedsSetup}>
              {alertSource.name}
            </Txt>
            {!isSelected && <Tooltip content={alertSource.docstring} />}
          </div>
        </div>
        <div className={"flex-center-y"}>
          <AlertSourceCardIndicators
            isSelected={isSelected}
            isEligible={isEligible}
            needsSetup={needsSetup}
          />
        </div>
      </div>
      {isSelected && (
        <>
          <Txt grey className={"mt-5 mb-4"}>
            {alertSource.docstring}
          </Txt>
          {/* if this is unique, and we don't already have one - show the continue button */}
          {isUnique && !needsSetup && (
            <div className="flex justify-end">{continueButton}</div>
          )}
          {(!isUnique || needsSetup) && (
            <div
              className={tcx(
                "w-full p-4 space-y-2 rounded-2 border",
                needsSetup
                  ? "bg-yellow-50 border-yellow-300"
                  : "bg-slate-50 border-slate-100",
              )}
            >
              {!isUnique && !needsSetup && (
                <div>
                  <Txt bold className={"mb-4"}>
                    Please give this source a unique name
                  </Txt>

                  <div className="flex space-x-2">
                    <div className="grow max">
                      <InputV2
                        // autoFocus
                        formMethods={formMethods}
                        name="name"
                      />
                    </div>
                    <div className="flex-none">{continueButton}</div>
                  </div>
                </div>
              )}
              {needsSetup ? (
                <div className="flex items-center justify-between">
                  <div>
                    <Txt grey bold className="mb-1">
                      This source type requires additional configuration
                    </Txt>
                    <Txt grey>
                      Please install the integration to receive alerts from this
                      source type
                    </Txt>
                  </div>
                  <Button
                    analyticsTrackingId={null}
                    onClick={() => navigate("/settings/integrations")}
                    openInNewTab
                  >
                    Install
                  </Button>
                </div>
              ) : null}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const AlertSourceCardIndicators = ({
  isEligible,
  isSelected,
  needsSetup,
}: {
  isEligible: boolean;
  isSelected: boolean;
  needsSetup?: boolean;
}) => {
  return (
    <>
      {/* --- Checkbox --- */}
      {isEligible || needsSetup ? (
        <SelectionIndicator selected={isSelected} />
      ) : (
        <Badge
          theme={BadgeTheme.Tertiary}
          className={"mr-1 ml-2 !font-normal text-xs !bg-surface-tertiary"}
        >
          Already created
        </Badge>
      )}
    </>
  );
};
