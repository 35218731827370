import { ControlledInputWrapper } from "@incident-shared/forms/v1/controlled";
import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import {
  Input,
  ModalContent,
  ModalFooter,
  StaticSingleSelect,
} from "@incident-ui";
import {
  currencyCentsToStr,
  CurrencyInput,
  currencyStrToCents,
  currencyValidationRules,
} from "@incident-ui/Input/CurrencyInput";
import { Controller, useForm } from "react-hook-form";
import {
  SchedulePayConfig,
  SchedulePayRule,
  SchedulePayRulePayloadWeekdaysEnum,
  SchedulePayRuleWeekdaysEnum,
  ScheduleReportOverride,
  ScheduleReportOverridePayload,
  SchedulesCreatePayConfigRequestBody,
  SchedulesShowPayConfigResponseBody,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { v4 as uuidv4 } from "uuid";

import { getCurrencySelectOptions } from "../../../../utils/currency";
import { fromISOUTCDateStr, toUTCISODateStr } from "./date-time-helpers";
import { DateTimeFormType } from "./DateTimeInput";
import { OverridesEditor } from "./OnCallPayOverridesEditor";
import { PayConfigCalendar } from "./PayConfigCalendar";
import { PayRulesEditor } from "./PayRulesEditor";

export type PayRuleFormType = Omit<SchedulePayRule, "rate_cents"> & {
  key: string;
  rate_pounds: string;
};

export type OverrideFormType = Omit<
  ScheduleReportOverride,
  "rate_cents" | "start_at" | "end_at" | "id"
> & {
  key: string;
  rate_pounds: string;
  start_at: DateTimeFormType;
  end_at: DateTimeFormType;
};

export type PayRuleConfigFormType = Omit<
  SchedulePayConfig,
  "pay_rules" | "overrides" | "base_rate_cents"
> & {
  pay_rules: PayRuleFormType[];
  overrides: OverrideFormType[];
  base_rate_pounds: string;
};

export const marshallOverrideFormTypeToConfig = (
  overrides: OverrideFormType[],
): ScheduleReportOverridePayload[] => {
  return overrides.map((override) => ({
    ...override,
    rate_cents: currencyStrToCents(override.rate_pounds),
    start_at: toUTCISODateStr(override.start_at),
    end_at: toUTCISODateStr(override.end_at),
  }));
};

export const marshallFormTypeToConfig = (
  config: PayRuleConfigFormType,
): SchedulesCreatePayConfigRequestBody => {
  return {
    name: config.name,
    currency: config.currency,
    base_rate_cents: currencyStrToCents(config.base_rate_pounds),
    overrides: marshallOverrideFormTypeToConfig(config.overrides || []),
    pay_rules: config.pay_rules
      ? config.pay_rules.map((rule) => ({
          ...rule,
          rate_cents: currencyStrToCents(rule.rate_pounds),
          weekdays:
            rule.weekdays as unknown as SchedulePayRulePayloadWeekdaysEnum[],
        }))
      : [],
  };
};

export const marshallConfigToOverrideFormType = (
  overrides: ScheduleReportOverride[],
): OverrideFormType[] => {
  return overrides.map((override) => ({
    ...override,
    rate_pounds: currencyCentsToStr(override.rate_cents),
    key: uuidv4(),
    start_at: fromISOUTCDateStr(override.start_at, false),
    end_at: fromISOUTCDateStr(override.end_at, true),
  }));
};

export const marshallConfigToFormType = (
  config: SchedulePayConfig,
): PayRuleConfigFormType => {
  return {
    ...config,
    base_rate_pounds: currencyCentsToStr(config.base_rate_cents),
    overrides: marshallConfigToOverrideFormType(config.overrides || []),
    pay_rules: config.pay_rules
      ? config.pay_rules.map((rule) => ({
          ...rule,
          rate_pounds: currencyCentsToStr(rule.rate_cents),
          weekdays: rule.weekdays as unknown as SchedulePayRuleWeekdaysEnum[],
          key: uuidv4(),
        }))
      : [],
  };
};

type Props = {
  onSubmit: (data: SchedulesCreatePayConfigRequestBody) => void;
  onClose: () => void;
  continueButtonText?: string;
  inModal?: boolean;
  saving?: boolean;
} & (
  | {
      mode: Mode.Create;
      initialConfig?: never;
    }
  | {
      mode: Mode.Edit;
      initialConfig: SchedulesShowPayConfigResponseBody;
    }
);

export function PayConfigCreateEditForm({
  mode,
  initialConfig,
  onSubmit: onSubmitCallback,
  onClose,
  continueButtonText = "Continue",
  saving,
}: Props): React.ReactElement {
  const defaultValues: Partial<PayRuleConfigFormType> =
    mode === Mode.Create
      ? { pay_rules: [], overrides: [] }
      : marshallConfigToFormType(initialConfig.schedule_pay_config);

  const requiredScope =
    mode === Mode.Create
      ? ScopeNameEnum.SchedulePayConfigsCreate
      : ScopeNameEnum.SchedulePayConfigsUpdate;

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm<PayRuleConfigFormType>({
    defaultValues,
  });

  const onSubmit = (data: PayRuleConfigFormType) => {
    onSubmitCallback(marshallFormTypeToConfig(data));
    reset();
  };

  const existingPayRules = watch("pay_rules");
  const selectedCurrency = watch("currency");
  const baseRate = watch("base_rate_pounds");

  return (
    <>
      <ModalContent>
        <div className="space-y-4">
          <p className="text-content-primary text-sm mb-2 max-w-xl">
            Use the form below to let us know what you pay for on-call e.g. your
            base rate, different rates for certain days or public holidays, and
            so on. Each configuration can be applied to one or more schedules.
          </p>
          <FormInputWrapper
            label="Pay configuration name"
            errors={errors}
            id="name"
          >
            <Input
              id="name"
              {...register("name", {
                required: "Please name this pay configuration",
              })}
              placeholder="e.g. Default rate"
            />
          </FormInputWrapper>
          <ControlledInputWrapper<PayRuleConfigFormType>
            id={"currency"}
            label="What currency are you paying in?"
            isClearable={false}
            errors={errors}
            required
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <StaticSingleSelect
                  value={value as string}
                  onChange={onChange}
                  id={"currency"}
                  options={getCurrencySelectOptions()}
                  placeholder="Select a currency"
                  onBlur={onBlur}
                />
              );
            }}
          />
          <FormInputWrapper
            label={"How much do you pay per hour?"}
            errors={errors}
            id="base_rate_pounds"
          >
            <CurrencyInput
              currency={selectedCurrency}
              id="base_rate_pounds"
              defaultValue={defaultValues.base_rate_pounds}
              {...register("base_rate_pounds", {
                required: "Please provide a rate",
                validate: currencyValidationRules,
              })}
            />
          </FormInputWrapper>
          <FormInputWrapper
            label="Weekly rules"
            errors={errors}
            id="pay_rules"
            helptext="These are rules that recur weekly that will override your base pay rate, for example you may pay more than your base rate on weekends."
          >
            <Controller
              control={control}
              name={"pay_rules"}
              render={({ field: { onChange, value } }) => (
                <PayRulesEditor
                  value={value}
                  onChange={onChange}
                  currency={selectedCurrency}
                  baseRate={baseRate}
                />
              )}
            />
          </FormInputWrapper>
          {existingPayRules.length > 0 && (
            <PayConfigCalendar
              currency={selectedCurrency}
              payRules={existingPayRules}
              baseRateStr={baseRate}
            />
          )}

          <FormInputWrapper
            label="Holiday rules"
            errors={errors}
            id="overrides"
            helptext="These are rules specific to particular days of each year that will override your base pay rate, for example you pay more on public holidays or at Christmas."
          >
            <Controller
              control={control}
              name={"overrides"}
              render={({ field: { onChange, value } }) => (
                <OverridesEditor
                  value={value}
                  onChange={onChange}
                  currency={selectedCurrency}
                  baseRateInCents={currencyStrToCents(baseRate)}
                />
              )}
            />
          </FormInputWrapper>
        </div>
      </ModalContent>
      <ModalFooter
        saving={saving}
        onConfirm={handleSubmit(onSubmit)}
        confirmButtonText={continueButtonText}
        confirmButtonType="button"
        onClose={onClose}
        requiredScope={requiredScope}
      />
    </>
  );
}
