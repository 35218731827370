import {
  AvailableIncidentFormEscalationElementElementTypeEnum,
  CatalogType,
  EngineScope,
  Expression,
  IncidentFormEscalationElement,
  IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum,
  IncidentFormsCreateEscalationElementResponseBody,
  IncidentFormsUpdateEscalationElementResponseBody,
  Resource,
  TypeaheadsListTypeaheadTypeEnum,
} from "@incident-io/api";
import { ArrayElement } from "@incident-shared/attribute";
import {
  getCatalogTypeaheadOptions,
  hydrateInitialCatalogOptions,
} from "@incident-shared/catalog";
import { isEmptyBinding } from "@incident-shared/engine";
import { CreateEditExpressionFormData } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import {
  QueryExpressionEditForm,
  QueryExpressionEditModal,
} from "@incident-shared/engine/expressions/query/QueryExpressionEditModal";
import {
  refMatchesFixedResult,
  validateQueryOperationFilterFields,
} from "@incident-shared/engine/expressions/query/QueryOperationsEditor";
import { useGetPreviousReference } from "@incident-shared/engine/expressions/query/useGetPreviousReference";
import { ViewExpression } from "@incident-shared/engine/expressions/ViewExpression";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
} from "@incident-shared/forms/Typeahead";
import {
  FormHelpTextV2,
  FormLabelV2,
} from "@incident-shared/forms/v2/FormInputWrapperV2";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  GenericErrorMessage,
  Icon,
  IconEnum,
  LoadingModal,
  ModalFooter,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { ClientType, useClient } from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { ulid } from "ulid";

import { tcx } from "../../../../../utils/tailwind-classes";
import { EscalateFormType } from "./IncidentEscalateFormsEditPage";
import { formElementToDefaults, marshallFormData } from "./marshall";

export type CreateEditElementForm = {
  description?: string;
  defaultValue: string;
  catalogTypeId?: string;
  // These navigate expressions supersede the & Partial<Expression>,
  // which we can remove once we've enabled the feature flag
  navigation_expression?: Expression;
  opsgenie_team_navigation_expression?: Expression;
  pagerduty_service_navigation_expression?: Expression;
} & Partial<Expression>;

export type IncidentEscalateFormElementCreateEditModalState =
  // Create state
  | {
      open: true;
      mode: Mode.Create;
      initialData?: never;
      setValue: UseFormReturn<EscalateFormType>["setValue"];
      onClose: () => void;
      catalogType: CatalogType;
      formId: string;
      elementType: IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum;
    }
  // Edit state
  | {
      open: true;
      mode: Mode.Edit;
      initialData: IncidentFormEscalationElement;
      setValue: UseFormReturn<EscalateFormType>["setValue"];
      onClose: () => void;
      catalogType?: CatalogType;
      formId: string;
      elementType: IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum;
    }
  // Closed
  | {
      mode: Mode.Create;
      open: false;
      formId?: never;
      onClose?: never;
      createCatalogType?: never;
      setValue?: never;
      initialData?: never;
      elementType?: never;
      catalogType?: never;
    };

export const IncidentEscalateFormElementCreateEditModal = ({
  mode,
  open,
  setValue,
  initialData,
  elementType,
  formId,
  catalogType,
  onClose,
}: IncidentEscalateFormElementCreateEditModalState) => {
  // This should a) never happen and b) makes our types resolve nicely!
  if (!open) {
    throw new Error("CreateEditElementModal rendered when closed");
  }

  const { escalateToMultipleEscalators } = useFlags();

  const isCatalogType =
    initialData?.available_element.element_type ===
      AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType ||
    mode === Mode.Create;

  const showToast = useToast();
  const formMethods = useForm<CreateEditElementForm>({
    defaultValues:
      mode === Mode.Edit
        ? formElementToDefaults(initialData, catalogType)
        : {
            reference: ulid(),
            label: `navigate-${formId}`,
            catalogTypeId: catalogType?.id,
          },
    shouldUnregister: false,
  });
  const {
    data: resourcesData,
    isLoading: resourcesLoading,
    error: resourcesError,
  } = useAPI("engineListAllResources", {});
  const { clearErrors, setError } = formMethods;

  const {
    data: allCatalogTypesData,
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI(
    "catalogListTypes",
    { includeCount: false },
    { fallbackData: { catalog_types: [] } },
  );

  const engineTypeForThisCatalogType = catalogType
    ? `CatalogEntry["${catalogType?.registry_type || catalogType?.id}"]`
    : "";

  const {
    data: { scope },
    isLoading: scopeLoading,
    error: scopeError,
  } = useAPI(
    // Don't fetch if we don't have a catalog type as a starting point
    catalogType ? "engineBuildScope" : null,
    {
      buildScopeRequestBody: {
        scope: [
          {
            type: engineTypeForThisCatalogType,
            label: catalogType?.name || "",
            key: "subject",
            array: true,
          },
        ],
      },
    },
    { fallbackData: { scope: { references: [], aliases: {} } } },
  );

  const { trigger, genericError, isMutating } = useAPIMutation(
    "incidentFormsListEscalationElements",
    { incidentFormId: formId },
    async (apiClient, formData: CreateEditElementForm) => {
      let resp:
        | IncidentFormsCreateEscalationElementResponseBody
        | IncidentFormsUpdateEscalationElementResponseBody;
      if (mode === Mode.Create) {
        resp = await apiClient.incidentFormsCreateEscalationElement({
          createEscalationElementRequestBody: marshallFormData(
            formData,
            elementType,
            formId,
          ),
        });
      } else {
        resp = await apiClient.incidentFormsUpdateEscalationElement({
          id: initialData.id,
          updateEscalationElementRequestBody: marshallFormData(
            formData,
            elementType,
            formId,
          ),
        });
      }

      // We need to manually update the value in our dummy form so it shows the chosen default value immediately
      if (resp?.element?.default_value) {
        setValue(
          `elements.${resp?.element.id ?? ""}`,
          resp?.element.default_value.value?.literal ?? "",
        );
      }
      return;
    },
    {
      onSuccess: onClose,
      onError: () => {
        showToast({
          title: "Failed to create form field",
          description:
            "Please try again. If the problem persists, contact support and they'll get right on it.",
        });
        onClose();
      },
    },
  );

  const apiClient = useClient();

  const getPreviousRef = useGetPreviousReference({
    scope: scope,
    resources: resourcesData?.resources ?? [],
    formMethods:
      formMethods as unknown as UseFormReturn<CreateEditExpressionFormData>,
  });

  const fixedResult = {
    type: `CatalogEntry["EscalationPath"]`,
    typeLabel: "Escalation path",
    label: "Escalation path",
    array: true,
  };

  const onSubmit = (formData: CreateEditElementForm) => {
    clearErrors();
    // When the escalateToMultipleEscalators flag is enabled, we rely on the expression modal doing all this validation
    if (isCatalogType && !escalateToMultipleEscalators) {
      // If the else branch is empty, clear it
      if (formData.else_branch && isEmptyBinding(formData.else_branch.result)) {
        formData.else_branch = undefined;
      }

      // Validate our query has at least one operation
      if (!formData.operations || formData.operations.length === 0) {
        setError("operations", {
          type: "required",
          message:
            "Please use the 'Then...' option to add an operation to your query",
        });
        return;
      }

      // Validate any filter operations
      if (!validateQueryOperationFilterFields(formData.operations)) {
        setError("operations", {
          type: "required",
          message: "Filter operations must include at least one filter",
        });
        return;
      }

      const finalRef = getPreviousRef();
      if (!finalRef) {
        throw new Error(
          `Couldn't get previous ref - expected to get at least one ref`,
        );
      }

      const fixedResultTypeMatches = refMatchesFixedResult(
        finalRef,
        fixedResult,
      );
      if (!fixedResultTypeMatches) {
        setError("operations", {
          type: "manual",
          // This is fully explained in an error callout, so this is just a backup incase
          // someone tries to add one anyway.
          message: `This query does not return the correct type`,
        });
        return;
      }
    }
    trigger(formData);
  };

  if (resourcesError || catalogTypesError || scopeError) {
    return (
      <GenericErrorMessage
        error={resourcesError ?? catalogTypesError ?? scopeError}
      />
    );
  }

  if (
    !resourcesData ||
    resourcesLoading ||
    catalogTypesLoading ||
    scopeLoading
  ) {
    return <LoadingModal onClose={onClose} />;
  }

  const navigateExpression = formMethods.watch("navigation_expression");

  const resources = resourcesData.resources;
  const allCatalogTypes = allCatalogTypesData.catalog_types;
  const escalationPathCatalogType = allCatalogTypes.find(
    (type) => type.registry_type === "EscalationPath",
  );
  const alertPriorityCatalogType = allCatalogTypes.find(
    (type) => type.registry_type === "AlertPriority",
  );

  if (!escalationPathCatalogType) {
    throw new Error("No escalation path catalog type");
  }

  if (!alertPriorityCatalogType) {
    throw new Error("No escalation path catalog type");
  }

  const loadDefaultValueOptions = getTypeaheadFunction(
    initialData?.available_element.element_type ??
      AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType,
    apiClient,
    escalationPathCatalogType,
    alertPriorityCatalogType,
    catalogType?.id,
  );

  const hydrateDefaultValueOptions = getHydrateFunction(
    initialData?.available_element.element_type ??
      AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType,
    apiClient,
    escalationPathCatalogType,
    alertPriorityCatalogType,
    catalogType?.id,
  );

  const fieldTypeLabels = {
    [AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType]:
      initialData?.available_element.catalog_type?.name ?? catalogType?.name,
    [AvailableIncidentFormEscalationElementElementTypeEnum.EscalationPolicy]:
      "Escalation path",
    [AvailableIncidentFormEscalationElementElementTypeEnum.User]: "User",
    [AvailableIncidentFormEscalationElementElementTypeEnum.Priority]:
      "Priority",
  };

  const fieldTypeName = initialData
    ? fieldTypeLabels[initialData?.available_element.element_type]
    : fieldTypeLabels[
        AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType
      ];

  return (
    <FormModalV2
      isExtraLarge
      genericError={genericError}
      analyticsTrackingId="incident-form-element-create-edit"
      onSubmit={onSubmit}
      formMethods={formMethods}
      innerClassName="p-4 space-y-5"
      onClose={onClose}
      title={`${
        mode === Mode.Create ? "Create" : "Edit"
      } field: ${fieldTypeName}`}
      footer={
        <ModalFooter
          saving={isMutating}
          onClose={onClose}
          disabled={isCatalogType && !navigateExpression}
          disabledTooltipContent={
            "You must provide an expression to an incident.io Escalation Path"
          }
          confirmButtonType="submit"
          confirmButtonText={"Save"}
        />
      }
    >
      {isCatalogType && catalogType ? (
        escalateToMultipleEscalators ? (
          <EscalatorsList
            formMethods={formMethods}
            scope={scope}
            resources={resources}
            catalogType={catalogType}
          />
        ) : (
          <>
            <FormLabelV2 htmlFor={""}>Query expression</FormLabelV2>
            <FormHelpTextV2>
              Use this query to tell us how to navigate from your chosen catalog
              type to an escalation path.
            </FormHelpTextV2>
            <QueryExpressionEditForm
              fixedRootReference="subject"
              fixedResult={fixedResult}
              resources={resources}
              scope={scope}
              hideFixedResultLabel={true}
              allowAllOfACatalogType={false}
              validateReturnType={(type, _) =>
                type === 'CatalogEntry["EscalationPath"]'
              }
            />
          </>
        )
      ) : null}

      <InputV2
        autoFocus={false}
        formMethods={formMethods}
        name="description"
        label="Description"
        helptext="Write additional text here and it will be displayed in the form."
        placeholder="Enter a brief message"
      />
      <DynamicSingleSelectV2
        formMethods={formMethods}
        name="defaultValue"
        label="Default value"
        helptext="What value should this field have by default?"
        placeholder="Choose a default value"
        loadOptions={loadDefaultValueOptions}
        hydrateOptions={hydrateDefaultValueOptions}
      />
    </FormModalV2>
  );
};

const EscalatorsList = ({
  scope,
  resources,
  formMethods,
  catalogType,
}: {
  formMethods: UseFormReturn<CreateEditElementForm>;
  scope: EngineScope;
  resources: Resource[];
  catalogType: CatalogType;
}) => {
  const escalatorOptions = [
    {
      title: "incident.io",
      catalogType: `CatalogEntry["EscalationPath"]`,
      catalogTypeName: "Escalation Path",
      formKey: "navigation_expression",
      icon: IconEnum.IncidentFlame,
    },
    {
      title: "PagerDuty",
      catalogType: `CatalogEntry["PagerDutyService"]`,
      catalogTypeName: "PagerDuty Service",
      formKey: "pagerduty_service_navigation_expression",
      icon: IconEnum.Pagerduty,
    },
    {
      title: "Opsgenie",
      catalogType: `CatalogEntry["OpsgenieTeam"]`,
      catalogTypeName: "Opsgenie Team",
      formKey: "opsgenie_team_navigation_expression",
      icon: IconEnum.Opsgenie,
    },
  ] as const;

  const [showExpressionEditorForType, setShowExpressionEditorForType] =
    React.useState<ArrayElement<typeof escalatorOptions> | null>(null);

  const formData = formMethods.watch();

  const currentEditingExpression = showExpressionEditorForType
    ? formData[showExpressionEditorForType.formKey]
    : undefined;

  const hasSetupAllExternalEscalators =
    Boolean(formData.pagerduty_service_navigation_expression) &&
    Boolean(formData.opsgenie_team_navigation_expression);

  const catalogTypeName = catalogType?.name ?? "";

  const catalogNameRef = ["a", "e", "i", "o", "u"].includes(
    catalogTypeName[0].toLowerCase(),
  )
    ? "an"
    : "a";

  const aCatalogType = `${catalogNameRef} ${catalogTypeName}`;

  return (
    <div className={"w-full flex flex-col"}>
      <div className={"font-medium text-content-primary text-sm"}>
        Map {catalogTypeName} to an escalator
      </div>
      <span className={"text-slate-700 mb-2"}>
        To escalate using {aCatalogType}, you need to configure how to map from
        it to an escalation path.
      </span>
      <div className={"border border-stroke rounded-md p-4"}>
        <div className={"flex flex-col w-full gap-y-4"}>
          {escalatorOptions.map((e) => {
            const navigateExpression = formData[e.formKey] as Expression;

            if (!navigateExpression) {
              return null;
            }

            return (
              <div key={e.formKey} className={"flex flex-col w-full"}>
                <div className={"flex items-center gap-x-2"}>
                  <Badge theme={BadgeTheme.Secondary} icon={e.icon} />
                  <span className={"font-medium"}>{e.title}</span>
                </div>
                <div className={"mt-2 w-full"}>
                  <ViewExpression
                    expression={{
                      ...navigateExpression,
                      label: "",
                    }}
                    scope={scope}
                    showExpressionName
                    onEdit={() => setShowExpressionEditorForType(e)}
                    onDelete={
                      // We don't let you delete the incident.io expression once it's there
                      e.formKey === "navigation_expression"
                        ? undefined
                        : () =>
                            formMethods.setValue(e.formKey, undefined, {
                              shouldDirty: true,
                            })
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
        {formData.navigation_expression ? (
          <DropdownMenu
            align="start"
            triggerButton={
              <Button
                analyticsTrackingId={null}
                className="flex mt-4"
                icon={IconEnum.Add}
                theme={ButtonTheme.Naked}
              >
                Escalate to another service
              </Button>
            }
            menuClassName=""
            // If you've already set up all the external escalators, disable the dropdown
            disabled={hasSetupAllExternalEscalators}
            tooltipContent={
              hasSetupAllExternalEscalators
                ? "You've already set up all escalator tools we support"
                : undefined
            }
          >
            <div id={"escalation-options"}>
              {escalatorOptions.map((operation) => {
                const navigateExpression = formData[
                  operation.formKey
                ] as Expression;

                if (navigateExpression) {
                  return null;
                }

                return (
                  <EscalatorDropdownOption
                    key={operation.title}
                    title={operation.title}
                    icon={operation.icon}
                    onSelect={() => {
                      setShowExpressionEditorForType(operation);
                    }}
                  />
                );
              })}
            </div>
          </DropdownMenu>
        ) : (
          <EmptyState
            content={
              <span className={"text-content-primary"}>
                Set up an expression to map {catalogNameRef}{" "}
                <span className={"font-semibold"}>{catalogTypeName}</span> to an{" "}
                <span className={"font-semibold"}>Escalation Path</span>
              </span>
            }
            cta={
              <Button
                onClick={() => {
                  setShowExpressionEditorForType(escalatorOptions[0]);
                }}
                icon={IconEnum.Add}
                title={"Add expression"}
                analyticsTrackingId={"add-expression-escalator"}
              >
                Set up expression
              </Button>
            }
          />
        )}
        {showExpressionEditorForType && (
          <QueryExpressionEditModal
            fixedResult={{
              type: showExpressionEditorForType.catalogType,
              typeLabel: showExpressionEditorForType.catalogTypeName,
              label: showExpressionEditorForType.catalogTypeName,
              array: true,
            }}
            resources={resources}
            scope={scope}
            onAddExpression={(expr) => {
              setShowExpressionEditorForType(null);
              formMethods.setValue(
                showExpressionEditorForType.formKey,
                expr as Expression,
                {
                  shouldDirty: true,
                },
              );
            }}
            onEditExpression={(expr) => {
              setShowExpressionEditorForType(null);
              formMethods.setValue(
                showExpressionEditorForType.formKey,
                expr as Expression,
                {
                  shouldDirty: true,
                },
              );
            }}
            defaultRootReference={`subject`}
            onClose={() => setShowExpressionEditorForType(null)}
            analyticsTrackingContext="internal-sp-settings"
            initialExpression={currentEditingExpression}
            existingExpressions={
              currentEditingExpression ? [currentEditingExpression] : []
            }
            hideFixedResultLabel={true}
            allowAllOfACatalogType={false}
            validateReturnType={(type, _) =>
              type === showExpressionEditorForType.catalogType
            }
          />
        )}
      </div>
    </div>
  );
};

const EscalatorDropdownOption = ({
  onSelect,
  title,
  icon,
}: {
  icon: IconEnum;
  title: string;
  onSelect: () => void;
}): React.ReactElement => {
  return (
    <DropdownMenuItem
      label={title}
      analyticsTrackingId={null}
      onSelect={onSelect}
      key={title}
    >
      <div className="flex-center-y space-x-2 text-left p-1">
        <Icon id={icon} className={tcx("mr-2 shrink-0")} />
        <div>
          <div className={"text-content-primary font-medium"}>{title}</div>
        </div>
      </div>
    </DropdownMenuItem>
  );
};

const getTypeaheadFunction = (
  elementType: AvailableIncidentFormEscalationElementElementTypeEnum,
  apiClient: ClientType,
  escalationPathCatalogType: CatalogType,
  alertPriorityCatalogType: CatalogType,
  catalogTypeId?: string,
) => {
  switch (elementType) {
    case AvailableIncidentFormEscalationElementElementTypeEnum.Priority:
      return getCatalogTypeaheadOptions({
        apiClient,
        catalogTypeID: alertPriorityCatalogType.id,
      });
    case AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType:
      return getCatalogTypeaheadOptions({
        apiClient,
        catalogTypeID: catalogTypeId as string,
      });
    case AvailableIncidentFormEscalationElementElementTypeEnum.EscalationPolicy:
      return getCatalogTypeaheadOptions({
        apiClient,
        catalogTypeID: escalationPathCatalogType.id,
      });
    case AvailableIncidentFormEscalationElementElementTypeEnum.User:
      return getTypeaheadOptions(
        apiClient,
        TypeaheadsListTypeaheadTypeEnum.User,
      );
    default:
      throw new Error("Unreachable: Unknown element type");
  }
};

const getHydrateFunction = (
  elementType: AvailableIncidentFormEscalationElementElementTypeEnum,
  apiClient: ClientType,
  escalationPathCatalogType: CatalogType,
  alertPriorityCatalogType: CatalogType,
  catalogTypeId?: string,
) => {
  switch (elementType) {
    case AvailableIncidentFormEscalationElementElementTypeEnum.Priority:
      return hydrateInitialCatalogOptions({
        apiClient,
        catalogTypeID: alertPriorityCatalogType.id,
      });
    case AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType:
      return hydrateInitialCatalogOptions({
        apiClient,
        catalogTypeID: catalogTypeId as string,
      });
    case AvailableIncidentFormEscalationElementElementTypeEnum.EscalationPolicy:
      return hydrateInitialCatalogOptions({
        apiClient,
        catalogTypeID: escalationPathCatalogType.id,
      });
    case AvailableIncidentFormEscalationElementElementTypeEnum.User:
      return hydrateInitialSelectOptions(
        apiClient,
        TypeaheadsListTypeaheadTypeEnum.User,
      );
    default:
      throw new Error("Unreachable: Unknown element type");
  }
};
