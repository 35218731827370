// eslint-disable-next-line no-restricted-imports
import {
  ActionsApi,
  AIApi,
  AlertRoutesApi,
  AlertsApi,
  AnnouncementPostsApi,
  AnnouncementRulesApi,
  APIKeysApi,
  AtlassianAuthApi,
  AtlassianConnectAppApi,
  AuthApi,
  BillingApi,
  CatalogApi,
  CloudTasksApi,
  CompetitorImportsApi,
  CustomFieldsApi,
  DebriefsApi,
  DecisionFlowsApi,
  DemoApi,
  EmailsApi,
  EngineApi,
  EscalationPathsApi,
  EscalationsApi,
  FollowUpsApi,
  HolidaysApi,
  IdentityApi,
  IncidentAttachmentsApi,
  IncidentCallsApi,
  IncidentCallSettingsApi,
  IncidentChannelConfigsApi,
  IncidentFilterFieldsApi,
  IncidentFormsApi,
  IncidentLifecyclesApi,
  IncidentMembershipsApi,
  IncidentParticipantsApi,
  IncidentRelationshipsApi,
  IncidentRolesApi,
  IncidentsApi,
  IncidentSubscriptionsApi,
  IncidentTimestampsApi,
  IncidentTypesApi,
  IncidentUpdatesApi,
  InsightsApi,
  IntegrationsApi,
  IntegrationsAsanaApi,
  IntegrationsAtlassianStatuspageApi,
  IntegrationsClickUpApi,
  IntegrationsConfluenceApi,
  IntegrationsDatadogApi,
  IntegrationsGithubApi,
  IntegrationsGitLabApi,
  IntegrationsGoogleWorkspaceApi,
  IntegrationsJiraCloudApi,
  IntegrationsJiraServerApi,
  IntegrationsLinearApi,
  IntegrationsMicrosoftApi,
  IntegrationsNotionApi,
  IntegrationsPagerDutyApi,
  IntegrationsSalesforceApi,
  IntegrationsServiceNowApi,
  IntegrationsShortcutApi,
  IntegrationsSplunkOnCallApi,
  IntegrationsVantaApi,
  IntegrationsZendeskApi,
  IntegrationsZoomApi,
  InternalStatusPageApi,
  InternalStatusPageContentApi,
  IssueTrackerIssueTemplatesApi,
  IssueTrackersApi,
  IssueTrackersV2Api,
  LegacyIncidentTriggersApi,
  ManagedResourcesApi,
  MobileApi,
  MSTeamsPreviewsApi,
  NotificationsApi,
  NudgesApi,
  OnboardingApi,
  OnCallNotificationsApi,
  OnCallOnboardingApi,
  PoliciesApi,
  PostIncidentFlowApi,
  PostmortemsApi,
  PostmortemSharesApi,
  PostmortemsSettingsApi,
  RBACRolesApi,
  RemindersApi,
  ReportsApi,
  SAMLApi,
  SavedViewsApi,
  SchedulesApi,
  SCIMApi,
  SentryApi,
  SettingsApi,
  SeveritiesApi,
  ShoutoutsApi,
  SlackAPIApi,
  SlackPreviewsApi,
  SlackTeamConfigsApi,
  StaffApi,
  StatusPageApi,
  StatusPageContentApi,
  StreamsApi,
  SystemApi,
  TimelineItemCommentsApi,
  TimelineItemsApi,
  TutorialsApi,
  TypeaheadsApi,
  UserLinksApi,
  UserPreferencesApi,
  UsersApi,
  WebhookConfigApi,
  WorkflowsApi,
  XerrorsApi,
} from "@incident-io/api/apis";
// This is quite a weird pattern, we need it for enums so we can use them as
// both values and types. There is almost certainly a better way.
// eslint-disable-next-line no-restricted-imports
import {
  CustomFieldFieldTypeEnum,
  StepReleaseChannelEnum,
  StepSlimReleaseChannelEnum,
} from "@incident-io/api/models";
// eslint-disable-next-line no-restricted-imports
import {
  BaseAPI,
  Configuration,
  FetchParams,
  Middleware,
  ResponseContext,
} from "@incident-io/api/runtime";
import _ from "lodash";
import React, {
  createContext,
  MutableRefObject,
  useContext,
  useRef,
} from "react";
import { useSWRConfig } from "swr";
import { useLocalStorage } from "use-hooks";

// We don't have a first-class ErrorResponse object due to how Goa chooses to
// model errors, but our Errors List API is a dummy endpoint that forces Goa to
// generate a compatible type.
//
// Export it here as ErrorResponse, which should be used everywhere.
// eslint-disable-next-line no-restricted-imports
// eslint-disable-next-line no-restricted-imports
export * from "@incident-io/api/apis";
// eslint-disable-next-line no-restricted-imports
export type {
  Action as Action,
  AnnouncementPost as AnnouncementPost,
  SelectOption as APISelectOption,
  CustomField as CustomField,
  ErrorResponse as ErrorResponse,
  Identity as Identity,
  Incident as Incident,
  IncidentRole as IncidentRole,
  TimelineItem as TimelineItemObject,
} from "@incident-io/api/models";
export * from "@incident-io/api/models";

export type StepReleaseChannel = StepReleaseChannelEnum;
export const StepReleaseChannel = StepReleaseChannelEnum;
export type StepSlimReleaseChannel = StepSlimReleaseChannelEnum;
export const StepSlimReleaseChannel = StepSlimReleaseChannelEnum;
export type CustomFieldType = CustomFieldFieldTypeEnum;
export const CustomFieldType = CustomFieldFieldTypeEnum;

interface _ClientType {
  orgHeaders: MutableRefObject<OrgHeaders>;
  setIsAuthenticated: (val: boolean) => void;
  isAuthenticated: boolean;
}

export type OrgHeaders = {
  orgId: string | null;
  impersonationSessionId: string | null;
  impersonatingOrgSlug: string | null;
};

const apiTypes = [
  AIApi,
  APIKeysApi,
  ActionsApi,
  AlertRoutesApi,
  AlertsApi,
  AnnouncementPostsApi,
  AnnouncementRulesApi,
  AtlassianAuthApi,
  AtlassianConnectAppApi,
  AuthApi,
  BillingApi,
  CatalogApi,
  CloudTasksApi,
  CompetitorImportsApi,
  CustomFieldsApi,
  DebriefsApi,
  DecisionFlowsApi,
  DemoApi,
  EmailsApi,
  EngineApi,
  EscalationPathsApi,
  EscalationsApi,
  HolidaysApi,
  FollowUpsApi,
  IdentityApi,
  IncidentAttachmentsApi,
  IncidentCallsApi,
  IncidentCallSettingsApi,
  IncidentChannelConfigsApi,
  IncidentFilterFieldsApi,
  IncidentFormsApi,
  IncidentLifecyclesApi,
  IncidentMembershipsApi,
  IncidentParticipantsApi,
  IncidentRelationshipsApi,
  IncidentRolesApi,
  IncidentSubscriptionsApi,
  IncidentTimestampsApi,
  IncidentTypesApi,
  IncidentUpdatesApi,
  IncidentsApi,
  InsightsApi,
  IntegrationsApi,
  IntegrationsAsanaApi,
  IntegrationsAtlassianStatuspageApi,
  IntegrationsClickUpApi,
  IntegrationsConfluenceApi,
  IntegrationsDatadogApi,
  IntegrationsGithubApi,
  IntegrationsGitLabApi,
  IntegrationsGoogleWorkspaceApi,
  IntegrationsJiraCloudApi,
  IntegrationsJiraServerApi,
  IntegrationsLinearApi,
  IntegrationsMicrosoftApi,
  IntegrationsNotionApi,
  IntegrationsPagerDutyApi,
  IntegrationsSalesforceApi,
  IntegrationsServiceNowApi,
  IntegrationsShortcutApi,
  IntegrationsSplunkOnCallApi,
  IntegrationsVantaApi,
  IntegrationsZendeskApi,
  IntegrationsZoomApi,
  InternalStatusPageApi,
  InternalStatusPageContentApi,
  IssueTrackerIssueTemplatesApi,
  IssueTrackersApi,
  IssueTrackersV2Api,
  LegacyIncidentTriggersApi,
  ManagedResourcesApi,
  MobileApi,
  MSTeamsPreviewsApi,
  NotificationsApi,
  NudgesApi,
  OnCallNotificationsApi,
  OnCallOnboardingApi,
  OnboardingApi,
  PoliciesApi,
  PostIncidentFlowApi,
  PostmortemSharesApi,
  PostmortemsApi,
  PostmortemsSettingsApi,
  RBACRolesApi,
  ReportsApi,
  RemindersApi,
  SAMLApi,
  SCIMApi,
  SavedViewsApi,
  SchedulesApi,
  SentryApi,
  SettingsApi,
  SeveritiesApi,
  ShoutoutsApi,
  SlackAPIApi,
  SlackPreviewsApi,
  SlackTeamConfigsApi,
  StaffApi,
  StatusPageApi,
  StatusPageContentApi,
  StreamsApi,
  SystemApi,
  TimelineItemCommentsApi,
  TimelineItemsApi,
  TutorialsApi,
  TypeaheadsApi,
  UserLinksApi,
  UserPreferencesApi,
  UsersApi,
  WebhookConfigApi,
  WorkflowsApi,
  XerrorsApi,
];

// APITypes flattens out all the array elements of the API, inferring the type A & B from the array [a, b]
export type APITypes = UnionToIntersection<
  typeof apiTypes extends (infer U)[] ? U : never
>;

// Converts a union of two types into an intersection
// i.e. A | B -> A & B
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I extends { prototype: unknown }
    ? I["prototype"]
    : I
  : never;

// When adding a new service, add it to the `apiTypes` array above.
export type ClientType = _ClientType & APITypes;

// Taken from the TypeScript manual itself, this is the 'blessed' mixin pattern.
//eslint-disable-next-line @typescript-eslint/no-explicit-any
function applyMixins(derivedCtor: any, constructors: any[]) {
  constructors.forEach((baseCtor) => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
      const field =
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
        Object.create(null);

      Object.defineProperty(derivedCtor.prototype, name, field);
    });
  });
}

applyMixins(BaseAPI, apiTypes);

// Ignore the casting: TypeScript is as lost as we are about why this works, so
// we forced it.
const Client = (config: Configuration): ClientType => {
  return new BaseAPI(config) as unknown as ClientType;
};

export const ClientContext = createContext<ClientType>(
  Client(new Configuration()),
);

// RetryUnauthorisedLanding ensures that if someone receives a 401 from any of
// our own API requests, we set isAuthenticated to false. We also suppress the
// error so that the caller doesn't have to handle this error (we handle it
// globally at the app level instead)
const RetryUnauthorisedLanding = (
  setIsAuthenticated: (val: boolean) => void,
): Middleware => {
  return {
    post: (context: ResponseContext): Promise<Response | void> => {
      if (context.response.status === 401) {
        setIsAuthenticated(false);
        return Promise.resolve(undefined);
      }
      return Promise.resolve(context.response);
    },
  };
};

const ApplyOrgHeaders = (headers: MutableRefObject<OrgHeaders>): Middleware => {
  return {
    pre: (context: ResponseContext): Promise<FetchParams | void> => {
      const { orgId, impersonatingOrgSlug, impersonationSessionId } =
        headers.current;
      if (orgId) {
        context.init.headers = _.merge(context.init.headers || {}, {
          "X-Incident-Organisation-ID": orgId,
        });
      }

      if (impersonatingOrgSlug) {
        context.init.headers = _.merge(context.init.headers || {}, {
          "X-Incident-Impersonate-Organisation-Slug": impersonatingOrgSlug,
        });
      }
      if (impersonationSessionId) {
        context.init.headers = _.merge(context.init.headers || {}, {
          "X-Incident-Impersonation-Session-ID": impersonationSessionId,
        });
      }

      return Promise.resolve(context);
    },
  };
};

const ApplyDashboardVersionHeaders = (): Middleware => {
  return {
    pre: (context: ResponseContext): Promise<FetchParams | void> => {
      if (process.env.REACT_APP_SENTRY_RELEASE !== "") {
        context.init.headers = _.merge(context.init.headers || {}, {
          "X-Incident-Dashboard-Version": process.env.REACT_APP_SENTRY_RELEASE,
        });
      }

      return Promise.resolve(context);
    },
  };
};

// Wrap all child elements such that they can access a global client
// configuration, enabling easy access to our API.
export const ClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated_] = useLocalStorage(
    "is_authenticated",
    false,
  );
  const { mutate } = useSWRConfig();
  const setIsAuthenticated = (isAuthenticated: boolean) => {
    if (!isAuthenticated) {
      mutate(
        (key) =>
          !!key && key[0] === "authShowPublicInformationForSlug" ? false : true, // update all keys except our public 'info for slug' API.
        undefined, // set value to undefined
        { revalidate: false }, // do not call APIs to revalidate
      );
    }
    setIsAuthenticated_(isAuthenticated);
  };

  const orgHeaders = useRef<OrgHeaders>({
    orgId: null,
    impersonatingOrgSlug: null,
    impersonationSessionId: null,
  });

  const config = new Configuration({
    // Set basePath to be relative to our current URL
    basePath: "",
    middleware: [
      RetryUnauthorisedLanding(setIsAuthenticated),
      ApplyOrgHeaders(orgHeaders),
      ApplyDashboardVersionHeaders(),
    ],
  });

  const contextValue = Client(config);
  contextValue.isAuthenticated = isAuthenticated;
  contextValue.setIsAuthenticated = setIsAuthenticated;
  contextValue.orgHeaders = orgHeaders;
  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = (): ClientType => useContext(ClientContext);

export const useIsAuthenticated = () => useClient().isAuthenticated;
