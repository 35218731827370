import {
  AlertRoute,
  AlertSourceConfig,
  AlertSourceSourceTypeEnum,
} from "@incident-io/api";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Heading,
  IconEnum,
  Txt,
} from "@incident-ui";
import { CSMChattyProductMarketingModal } from "@incident-ui/ProductMarketingBanner/CSMChattyProductMarketingModal";
import { useState } from "react";
import {
  CATALOG_SERVICES_WIZARD_CTA,
  CATALOG_TEAMS_WIZARD_CTA,
  CatalogBannerType,
  CatalogHeroBanner,
  SetupCatalogGraphic,
  useShowCatalogBanner,
} from "src/components/catalog/type-list/CatalogTypeSetupBanner";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import AlertVisual from "../images/alerts-empty-state.png";
import styles from "./AlertSourcesEmptyState.module.scss";

const ALERTS_SETUP_CATALOG_MODAL_CTA = "alerts-setup-catalog-modal";

export const AlertsEmptyStatePage = () => {
  const navigate = useOrgAwareNavigate();
  const {
    data: { alert_source_configs },
    isLoading: configsLoading,
    error: sourceConfigsError,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  const {
    data: alertRoutesData,
    isLoading: alertRoutesLoading,
    error: alertRoutesError,
  } = useAPI("alertRoutesListAlertRoutes", undefined);

  const catalogBanner = useShowCatalogBanner();

  if (configsLoading || alertRoutesLoading) {
    return null;
  }

  const error = sourceConfigsError || alertRoutesError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const shouldShowEmptyState = shouldShowAlertSourcesEmptyState(
    alert_source_configs,
    alertRoutesData?.alert_routes || [],
  );
  // if the user has alert sources, they shouldn't be on this page
  if (!shouldShowEmptyState) {
    navigate("/alerts/sources");
  }

  return (
    <PageWrapper icon={IconEnum.Alert} title="Alerts" width={PageWidth.Narrow}>
      <div className="h-full flex flex-col gap-6">
        {catalogBanner && (
          <CatalogSetupBannerOrModal bannerType={catalogBanner} />
        )}
        <div
          className={tcx(
            styles.wrapper,
            "flex-center flex-col items-center rounded-2xl h-full p-10",
          )}
        >
          <img className="max-h-[240px]" src={AlertVisual} />
          <div className="flex flex-col items-center max-w-[373px]">
            <Heading level={2} size="2xl" className="mb-2">
              Create incidents from alerts
            </Heading>
            <Txt grey className="mb-6 text-center">
              Connect your alert sources and route your alerts to automatically
              create incidents within incident.io
            </Txt>
            <Button
              analyticsTrackingId="alert-sources-create-first"
              theme={ButtonTheme.Primary}
              href={"/alerts/routes/create/"}
            >
              Get started
            </Button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

// we want to show the user our empty state if they've never created an alert
// source, or if they only have the status page views alert source (since we
// create that one automatically). and if they've never created an alert route.
export const shouldShowAlertSourcesEmptyState = (
  alertSourceConfigs: AlertSourceConfig[],
  alertRoutes: AlertRoute[],
) =>
  (alertSourceConfigs.length === 0 ||
    alertSourceConfigs.every(
      (source) =>
        source.source_type === AlertSourceSourceTypeEnum.StatusPageViews,
    )) &&
  alertRoutes.length === 0;

// CatalogSetupBannerOrModal is designed to push users to set up their catalog before configuring alerts.
// The first time someone hits the page, we'll show a modal over the alerts page. If they dismiss that modal,
// we'll show a banner instead.
const CatalogSetupBannerOrModal = ({
  bannerType,
}: {
  bannerType: CatalogBannerType;
}) => {
  const { hasDismissedCTA } = useIdentity();
  const [isOpen, setIsOpen] = useState(true);
  const config =
    bannerType === "team"
      ? {
          ctaId: CATALOG_TEAMS_WIZARD_CTA,
          title: "Set up your teams in Catalog",
          description:
            "Add your teams to the Catalog so that your alert routes can notify the right people when things go wrong",
          buttonText: "Set up teams",
          slug: "team",
          className: "bg-purple-surface",
        }
      : {
          ctaId: CATALOG_SERVICES_WIZARD_CTA,
          title: "Set up your services in Catalog",
          description:
            "Add your services to the Catalog so that your alert routes can notify the right people when things go wrong",
          buttonText: "Set up services",
          slug: "service",
          className: "bg-green-surface",
        };

  const { trigger: dismissModal, isMutating: dismissing } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      await apiClient.identityDismissCta({
        dismissCtaRequestBody: {
          cta: ALERTS_SETUP_CATALOG_MODAL_CTA,
          for_whole_organisation: false,
        },
      });
    },
  );

  const hasDismissedModal = hasDismissedCTA(ALERTS_SETUP_CATALOG_MODAL_CTA);

  if (hasDismissedModal) {
    return <CatalogHeroBanner bannerType={bannerType} />;
  }

  return (
    <CSMChattyProductMarketingModal
      {...config}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      analyticsTrackingId={`alerts-go-to-${config.slug}-wizard`}
      graphic={<SetupCatalogGraphic bannerType={bannerType} />}
      cta={
        <Button
          theme={ButtonTheme.Primary}
          href={`/catalog/${config.slug}-wizard`}
          analyticsTrackingId={`set-up-${config.slug}s-banner`}
        >
          {config.buttonText}
        </Button>
      }
      secondaryCta={
        <Button
          analyticsTrackingId={`set-up-${config.slug}s-later`}
          theme={ButtonTheme.Secondary}
          onClick={() => dismissModal({})}
          loading={dismissing}
        >
          Do it later
        </Button>
      }
    />
  );
};
