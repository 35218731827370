import { ConfigureDrawerProps } from "@incident-shared/integrations";
import {
  Button,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import { PostmortemDestinationDocumentProviderEnum } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { usePostmortemName } from "src/utils/utils";

import { PostmortemDestinationList } from "../../../post-mortem/destination/PostmortemDestinationList";
import { ConnectingUserAndSiteConfluence } from "../../atlassian-connect/ConnectingUserAndSite";
import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

export const ConfluenceConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const {
    data: { destinations },
    isLoading: destinationsLoading,
    error: destinationsError,
  } = useAPI("postmortemsListDestinations", undefined, {
    fallbackData: { destinations: [] },
  });
  if (destinationsError) {
    return <GenericErrorMessage error={destinationsError} />;
  }
  if (destinationsLoading) {
    return <IntegrationDrawerContentLoader />;
  }

  const confluenceDestinations = destinations.filter(
    (destination) =>
      destination.document_provider ===
      PostmortemDestinationDocumentProviderEnum.Confluence,
  );

  return (
    <GenericConfigureDrawerContents {...props}>
      <ConnectingUserAndSiteConfluence />
      {confluenceDestinations.length > 0 ? (
        <PostmortemDestinationList destinations={confluenceDestinations} />
      ) : (
        <EmptyState
          className="grow-0"
          icon={IconEnum.Folder}
          title="Document destinations"
          content={`You'll need to define at least one post-mortem destination connected to Google Docs to export your documents to.`}
          cta={
            <Button
              analyticsTrackingId={null}
              href="/settings/post-mortem/destinations/create"
            >
              Add destination
            </Button>
          }
        />
      )}
    </GenericConfigureDrawerContents>
  );
};

export const useCanDisconnectConfluence = () => {
  const { postmortemNameFormatted } = usePostmortemName(null);

  const {
    data: { destinations },
    isLoading: destinationsLoading,
    error: destinationsError,
  } = useAPI("postmortemsListDestinations", undefined, {
    fallbackData: { destinations: [] },
  });

  const confluenceDestinations = destinations.filter(
    (destination) =>
      destination.document_provider ===
      PostmortemDestinationDocumentProviderEnum.Confluence,
  );
  const cannotDisconnectExplanation =
    confluenceDestinations.length > 0
      ? `There are ${confluenceDestinations.length} ${postmortemNameFormatted} destination(s) using Confluence. Please remove them before disconnecting Confluence integration.`
      : undefined;

  return {
    loading: destinationsLoading,
    error: destinationsError,
    canDisconnect: confluenceDestinations.length === 0,
    cannotDisconnectExplanation,
  };
};
