import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Badge,
  BadgeTheme,
  ButtonTheme,
  Heading,
  IconEnum,
  Link,
  StackedList,
  Tooltip,
} from "@incident-ui";
import { sortBy } from "lodash";
import React from "react";
import { ScopeNameEnum, StatusPage } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import ExamplePage from "../common/images/example-page.png";
import { StatusPageStatusIcon } from "../incidents/view/StatusIcons";
import styles from "./StatusPagesList.module.scss";

export const StatusPagesList = ({
  pages,
}: {
  pages: StatusPage[];
}): React.ReactElement => {
  return (
    <StackedList>
      {sortBy(pages, "name").map((page) => (
        <StatusPageListItem page={page} key={page.id} />
      ))}
    </StackedList>
  );
};

const StatusPageListItem = ({
  page,
}: {
  page: StatusPage;
}): React.ReactElement => {
  return (
    <Link
      className="flex p-4 cursor-pointer no-underline text-sm hover:bg-surface-secondary hover:!text-content-primary"
      to={`/status-pages/${page.id}`}
      analyticsTrackingId={"status-page-view-page"}
      analyticsTrackingMetadata={{ status_page_id: page.id }}
    >
      <div className="block grow">
        <div className="font-medium flex items-center">
          <StatusPageStatusIcon
            hasOngoingIncidents={page.has_ongoing_incident}
            className="mr-1"
          />
          {page.name}
        </div>
        <div className="text-content-tertiary">{page.public_url}</div>
      </div>
      {page.mirroring_atlassian_page && (
        <Tooltip
          content={
            <>
              This page currently fetches its content from your Atlassian
              Statuspage. You can&apos;t update it from Slack until you complete
              the migration to your new incident.io status page.
            </>
          }
          side="left"
        >
          <button>
            <Badge icon={IconEnum.AtlassianStatuspage} theme={BadgeTheme.Info}>
              Mirror of Atlassian page
            </Badge>
          </button>
        </Tooltip>
      )}
    </Link>
  );
};

export const StatusPagesEmptyState = (): React.ReactElement => {
  const { hasScope } = useIdentity();

  const canCreate = hasScope(ScopeNameEnum.StatusPagesConfigure);

  return (
    <div className="bg-surface-secondary rounded-2 border border-stroke flex flex-col md:flex-row justify-between md:items-end px-8 pb-0 max-w-6xl mx-auto">
      <div className="grow md:max-w-[50%] w-full my-auto pt-8 pb-4 md:py-0 space-y-5">
        <Heading level={1} size="medium" className="font-medium">
          Keep your users in the loop
        </Heading>
        <p className="text-sm">
          With our status page, it&apos;s never been easier to make sure your
          users know what&apos;s going on.
        </p>
        <GatedButton
          analyticsTrackingId="create-status-page"
          theme={ButtonTheme.Primary}
          href="/status-pages/create"
          disabled={!canCreate}
          disabledTooltipContent={
            "You don't have permission to configure status pages"
          }
        >
          Create a status page
        </GatedButton>
      </div>
      <div
        className={tcx(
          "flex w-full h-[300px] overflow-hidden relative md:max-w-[40%] mt-8",
          styles.emptyStateImage,
        )}
      >
        <img className="absolute md:top-0 md:left-0 w-full" src={ExamplePage} />
      </div>
    </div>
  );
};
