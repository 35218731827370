import { EngineParamBinding } from "@incident-io/api";

export const defaultOpsGenieIncidentTemplateName: EngineParamBinding = {
  value: {
    label: "opsgenie_alert.message",
    literal: JSON.stringify({
      content: [
        {
          content: [
            {
              attrs: {
                label: "Opsgenie Alert Message",
                missing: false,
                name: "opsgenie_alert.message",
              },
              type: "varSpec",
            },
          ],
          type: "paragraph",
        },
      ],
      type: "doc",
    }),
    sort_key: "opsgenie_alert.message",
  },
};

export const defaultOpsGenieIncidentTemplateSummary: EngineParamBinding = {
  value: {
    label:
      "Created from an OpsGenie incident opsgenie_alert.tiny_id.\n\nSource: opsgenie_alert.source",
    literal: JSON.stringify({
      content: [
        {
          content: [
            {
              text: "Created from an OpsGenie incident ",
              type: "text",
            },
            {
              attrs: {
                label: "Opsgenie Alert Short ID",
                missing: false,
                name: "opsgenie_alert.tiny_id",
              },
              type: "varSpec",
            },
            {
              text: ".",
              type: "text",
            },
          ],
          type: "paragraph",
        },
        {
          content: [
            {
              text: "Source: ",
              type: "text",
            },
            {
              attrs: {
                label: "Opsgenie Alert Source",
                missing: false,
                name: "opsgenie_alert.source",
              },
              type: "varSpec",
            },
          ],
          type: "paragraph",
        },
      ],
      type: "doc",
    }),
    sort_key:
      "Created from an OpsGenie incident opsgenie_alert.tiny_id.\n\nSource: opsgenie_alert.source",
  },
};

export const defaultPagerDutyIncidentTemplateName: EngineParamBinding = {
  value: {
    label: "pager_duty_incident.title",
    literal: JSON.stringify({
      content: [
        {
          content: [
            {
              attrs: {
                label: "PagerDuty Incident Title",
                missing: false,
                name: "pager_duty_incident.title",
              },
              type: "varSpec",
            },
          ],
          type: "paragraph",
        },
      ],
      type: "doc",
    }),
    sort_key: "pager_duty_incident.title",
  },
};

export const defaultPagerDutyIncidentTemplateSummary: EngineParamBinding = {
  value: {
    label:
      "Created from a PagerDuty incident: pager_duty_incident.title\n\nService: pager_duty_incident.service",
    literal: JSON.stringify({
      content: [
        {
          content: [
            {
              text: "Created from a PagerDuty incident: ",
              type: "text",
            },
            {
              attrs: {
                label: "PagerDuty Incident Title",
                missing: false,
                name: "pager_duty_incident.title",
              },
              type: "varSpec",
            },
          ],
          type: "paragraph",
        },
        {
          content: [
            {
              text: "Service: ",
              type: "text",
            },
            {
              attrs: {
                label: "PagerDuty Incident Service",
                missing: false,
                name: "pager_duty_incident.service",
              },
              type: "varSpec",
            },
          ],
          type: "paragraph",
        },
      ],
      type: "doc",
    }),
    sort_key:
      "Created from a PagerDuty incident: pager_duty_incident.title\n\nService: pager_duty_incident.service",
  },
};
