/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface CustomEventPayload
 */
export interface CustomEventPayload {
    /**
     * 
     * @type {TextNode}
     * @memberof CustomEventPayload
     */
    description?: TextNode;
    /**
     * The title of the custom event
     * @type {string}
     * @memberof CustomEventPayload
     */
    title: string;
}

export function CustomEventPayloadFromJSON(json: any): CustomEventPayload {
    return CustomEventPayloadFromJSONTyped(json, false);
}

export function CustomEventPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomEventPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : TextNodeFromJSON(json['description']),
        'title': json['title'],
    };
}

export function CustomEventPayloadToJSON(value?: CustomEventPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': TextNodeToJSON(value.description),
        'title': value.title,
    };
}

