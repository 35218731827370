import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { ModalFooter } from "@incident-ui";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { StaffCreateImpersonationSessionRequestBody } from "src/contexts/ClientContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPIMutation } from "src/utils/swr";

import { useOrganisationTypeahead } from "../staff-room/useOrganisationTypeahead";
import { useStoredSession } from "./useStoredSession";

type FormState = Omit<StaffCreateImpersonationSessionRequestBody, "reason"> & {
  reason_type: ReasonTypeEnum;
  other_reason: string;
};

enum ReasonTypeEnum {
  Debugging = "debugging",
  CustomerMeetingPrep = "customer_meeting_prep",
  ProductAdoptionWork = "product_adoption_work",
  Other = "other",
}

export const ImpersonateModal = ({
  onClose,
  prefillSlug,
}: {
  onClose: () => void;
  prefillSlug?: string;
}): React.ReactElement | null => {
  const initialReason = useInitialReason();
  const formMethods = useForm<FormState>({
    defaultValues: {
      reason_type: initialReason.includes("INC-")
        ? ReasonTypeEnum.Debugging
        : ReasonTypeEnum.Other,
      other_reason: initialReason,
      target_organisation_slug: prefillSlug,
    },
  });
  const { setSession } = useStoredSession({
    orgSlug: formMethods.watch("target_organisation_slug"),
  });
  const { loadOptions, hydrateOptions } = useOrganisationTypeahead();
  const navigate = useNavigate();

  const { trigger, isMutating, genericError } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, data: FormState) => {
      const { impersonation_session: session } =
        await apiClient.staffCreateImpersonationSession({
          createImpersonationSessionRequestBody: {
            target_organisation_slug: data.target_organisation_slug,
            reason:
              data.reason_type === ReasonTypeEnum.Other
                ? data.other_reason
                : `${data.reason_type} ${data.other_reason}`,
          },
        });

      setSession(session);
      if (prefillSlug === undefined) {
        navigate(`/${session.target_organisation_slug}`);
      }
    },
    { setError: formMethods.setError },
  );

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={trigger}
      saving={isMutating}
      genericError={genericError}
      analyticsTrackingId="impersonate"
      title="Impersonate organisation"
      onClose={onClose}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          confirmButtonText="Impersonate"
          onClose={onClose}
          saving={isMutating}
        />
      }
    >
      <DynamicSingleSelectV2
        formMethods={formMethods}
        autoFocus={true}
        name="target_organisation_slug"
        placeholder="Select organisation"
        isDisabled={prefillSlug !== undefined}
        loadOptions={loadOptions}
        hydrateOptions={hydrateOptions}
      />

      <ReasonInput formMethods={formMethods} />

      <FormHelpTextV2>
        Use of impersonation is reviewed periodically to improve this flow and
        our internal processes.
      </FormHelpTextV2>
    </FormModalV2>
  );
};

const useInitialReason = () => {
  const query = useQueryParams().get("reason") ?? "";
  const fragment = useLocation().hash;

  return query + fragment;
};

const ReasonInput = ({
  formMethods,
}: {
  formMethods: UseFormReturn<FormState>;
}) => {
  const isOther = formMethods.watch("reason_type") === ReasonTypeEnum.Other;

  return (
    <>
      <RadioButtonGroupV2
        formMethods={formMethods}
        name="reason_type"
        options={reasonOptions}
        srLabel="Reason for impersonating"
        boxed
        label="Reason for impersonating"
        helptext={
          <>
            This helps us track why we&rsquo;re impersonating customers so we
            have a record of who accessed what and why.
          </>
        }
      />
      <InputV2
        formMethods={formMethods}
        name="other_reason"
        placeholder={
          isOther ? "Enter reason for impersonation" : "Any extra context?"
        }
        required={isOther}
      />
    </>
  );
};

const reasonOptions: RadioButtonGroupOption[] = [
  {
    value: ReasonTypeEnum.Debugging,
    label: "Debugging",
  },
  {
    value: ReasonTypeEnum.CustomerMeetingPrep,
    label: "Customer meeting prep",
  },
  {
    value: ReasonTypeEnum.ProductAdoptionWork,
    label: "Product adoption work",
  },
  {
    value: ReasonTypeEnum.Other,
    label: "Other",
  },
];
