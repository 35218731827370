/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IssueTrackersGitLabCreateIssueRequestBody
 */
export interface IssueTrackersGitLabCreateIssueRequestBody {
    /**
     * GitLab username of the assignee
     * @type {string}
     * @memberof IssueTrackersGitLabCreateIssueRequestBody
     */
    assignee_username?: string;
    /**
     * Description body of the GitLab issue
     * @type {string}
     * @memberof IssueTrackersGitLabCreateIssueRequestBody
     */
    description?: string;
    /**
     * ID of the follow-up that we're exporting this issue from
     * @type {string}
     * @memberof IssueTrackersGitLabCreateIssueRequestBody
     */
    follow_up_id: string;
    /**
     * List of label IDs to set on issue
     * @type {Array<string>}
     * @memberof IssueTrackersGitLabCreateIssueRequestBody
     */
    labels?: Array<string>;
    /**
     * The shortname of the GitLab repo in this installation's org
     * @type {string}
     * @memberof IssueTrackersGitLabCreateIssueRequestBody
     */
    repo: string;
    /**
     * Title of the GitLab issue
     * @type {string}
     * @memberof IssueTrackersGitLabCreateIssueRequestBody
     */
    title: string;
}

export function IssueTrackersGitLabCreateIssueRequestBodyFromJSON(json: any): IssueTrackersGitLabCreateIssueRequestBody {
    return IssueTrackersGitLabCreateIssueRequestBodyFromJSONTyped(json, false);
}

export function IssueTrackersGitLabCreateIssueRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersGitLabCreateIssueRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee_username': !exists(json, 'assignee_username') ? undefined : json['assignee_username'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'follow_up_id': json['follow_up_id'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'repo': json['repo'],
        'title': json['title'],
    };
}

export function IssueTrackersGitLabCreateIssueRequestBodyToJSON(value?: IssueTrackersGitLabCreateIssueRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_username': value.assignee_username,
        'description': value.description,
        'follow_up_id': value.follow_up_id,
        'labels': value.labels,
        'repo': value.repo,
        'title': value.title,
    };
}

