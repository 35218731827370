import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme } from "@incident-ui";

export const NewAlertSourceButton = () => {
  return (
    <GatedButton
      theme={ButtonTheme.Secondary}
      href="/alerts/sources/create"
      requiredScope={ScopeNameEnum.AlertSourceCreate}
      analyticsTrackingId="create-alert-source"
      className="shrink-0 self-end"
    >
      New alert source
    </GatedButton>
  );
};
