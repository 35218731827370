import type { SVGProps } from "react";
import * as React from "react";
const SvgCheck = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.97 14.931c1.766-3.364 3.995-6.063 6.624-8.018A1.062 1.062 0 0 0 14.33 5.21C11.89 7.02 9.777 9.399 8.024 12.29a16.378 16.378 0 0 0-2.21-2.79 1.061 1.061 0 0 0-1.505 1.496c1.056 1.063 1.966 2.347 2.779 3.927a1.06 1.06 0 0 0 1.883.007Z"
    />
  </svg>
);
export default SvgCheck;
