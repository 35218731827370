import { Callout, CalloutTheme } from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList } from "./helpers";

export const AlertSourceNewRelicSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Create a new <strong>Webhook destination</strong> in New Relic from{" "}
        <strong>Alerts & AI → Enrich & Notify → Destinations</strong>
      </p>
      <div className="overflow-hidden">
        <p>
          In the <strong>Endpoint URL</strong> input, copy and paste the
          following URL
        </p>
        <div className={"my-4 flex flex-col gap-4 overflow-hidden"}>
          <SingleLineCodeBlock
            title={"Endpoint URL"}
            code={`${publicApiUrl()}/v2/alert_events/new_relic/${
              alertSourceConfig.id
            }`}
          />
          <p>
            Toggle <span className={"font-medium"}>Use authorization</span>, and
            select <span className={"font-medium"}>Bearer token</span>, pasting
            in the following token:
          </p>
          <SingleLineCodeBlock
            title={"Bearer token"}
            code={`${alertSourceConfig.secret_token}`}
          />
          <p>You can now save the destination.</p>
        </div>
      </div>
      <div className={"flex flex-col gap-4 mb-4"}>
        <p>
          Navigate to the <strong>Workflows</strong> tab on the same screen and
          create a new workflow. Click the <strong>Webhook</strong> button in{" "}
          <strong>Notify</strong> and in the{" "}
          <strong>Edit notification message</strong> modal select your
          destination from the dropdown, and copy and paste the following JSON
          into the <strong>Template</strong> field:
        </p>
        <Callout theme={CalloutTheme.Info}>
          <strong>id</strong>, <strong>title</strong> and <strong>state</strong>{" "}
          are the only required fields. We&apos;ll parse all any fields from the{" "}
          <strong>metadata</strong> object, so you can customise this as you
          would like with any additional New Relic fields that you would like to
          use as alert attributes.
        </Callout>
        <div className={"flex flex-col gap-4 mt-1 mb-1"}>
          <CodeBlock
            title={"Notification message template"}
            code={`{
  "id": {{ json issueId }},
  "title": {{ json annotations.title.[0] }},
  "state": {{ json state }},
  "issueUrl": {{ json issuePageUrl }},
  "metadata": { 
    "workflowName": {{ json workflowName }},
    "priority": {{ json priority }},
    "impactedEntities": {{json entitiesData.names}},
    "totalIncidents": {{json totalIncidents}},
    "trigger": {{ json triggerEvent }},
    "isCorrelated": {{ json isCorrelated }},
    "createdAt": {{ createdAt }},
    "updatedAt": {{ updatedAt }},
    "sources": {{ json accumulations.source }},
    "alertPolicyNames": {{ json accumulations.policyName }},
    "alertConditionNames": {{ json accumulations.conditionName }}
  }
}`}
          />
        </div>
      </div>
      <p>
        Configure your New Relic workflow to use the newly created destination
        in <span className={"font-medium"}>Destination</span>
      </p>
      <div className={"flex flex-col gap-4 mb-4"}>
        <p>
          Click <strong>Send test notification</strong> to send a test alert and
          verify your configuration is working as expected.
        </p>
        <Callout theme={CalloutTheme.Info}>
          You may need to change the sample payload state to{" "}
          <code>ACTIVATED</code> for the alert to show up in the preview to the
          right. We also de-duplicate alerts based on the <code>id</code> field.
          You may need to update this manually in the{" "}
          <span className={"font-semibold"}>template</span> if you want to test
          more than once.
        </Callout>
      </div>
      <p>
        Configure your filters and click <strong>Activate workflow</strong>. You
        should be ready to go!
      </p>
    </SetupInfoNumberedList>
  );
};
