/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EligibilityInformation
 */
export interface EligibilityInformation {
    /**
     * Path to redirect the user to if they want to make this template available
     * @type {string}
     * @memberof EligibilityInformation
     */
    ineligible_call_to_action_path: string;
    /**
     * Explanation of why this template isn't available.
     * @type {string}
     * @memberof EligibilityInformation
     */
    ineligible_explanation_text: string;
    /**
     * The type of ineligibility
     * @type {string}
     * @memberof EligibilityInformation
     */
    ineligible_type: EligibilityInformationIneligibleTypeEnum;
    /**
     * Whether this org can use this template
     * @type {boolean}
     * @memberof EligibilityInformation
     */
    is_eligible: boolean;
}

/**
* @export
* @enum {string}
*/
export enum EligibilityInformationIneligibleTypeEnum {
    NeedsSetup = 'needs_setup',
    MaxedOut = 'maxed_out'
}

export function EligibilityInformationFromJSON(json: any): EligibilityInformation {
    return EligibilityInformationFromJSONTyped(json, false);
}

export function EligibilityInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EligibilityInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ineligible_call_to_action_path': json['ineligible_call_to_action_path'],
        'ineligible_explanation_text': json['ineligible_explanation_text'],
        'ineligible_type': json['ineligible_type'],
        'is_eligible': json['is_eligible'],
    };
}

export function EligibilityInformationToJSON(value?: EligibilityInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ineligible_call_to_action_path': value.ineligible_call_to_action_path,
        'ineligible_explanation_text': value.ineligible_explanation_text,
        'ineligible_type': value.ineligible_type,
        'is_eligible': value.is_eligible,
    };
}

