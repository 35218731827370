import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { createContext, useContext } from "react";

import {
  PrioritiesCreateEditForm,
  PrioritiesDescription,
} from "./PrioritiesCreateEditForm";

export const PrioritiesCreateEditDrawer = ({
  title = "Priorities",
  onClose,
}: {
  title?: string;
  onClose: () => void;
}): React.ReactElement => {
  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents className="font-normal">
        <DrawerTitle
          icon={IconEnum.Priority}
          title={title}
          color={ColorPaletteEnum.Slate}
          onClose={onClose}
          closeIcon={IconEnum.Close}
          sticky
        />
        <DrawerBody>
          <PrioritiesDescription />
          <PrioritiesCreateEditForm />
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};

const PrioritiesDrawerContext = createContext<{
  open: boolean;
  setOpen: (open: boolean) => void;
}>({
  open: false,
  setOpen: () => void 0,
});

export const usePrioritiesDrawer = () => {
  return useContext(PrioritiesDrawerContext);
};

export const PrioritiesDrawerProvider = ({
  children,
  open,
  setOpen,
}: {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}): React.ReactElement => {
  // const [open, setOpen] = useState(false);

  return (
    <PrioritiesDrawerContext.Provider value={{ open, setOpen }}>
      {children}
    </PrioritiesDrawerContext.Provider>
  );
};
