import { WorkflowTemplate } from "@incident-io/api";
import { Button } from "@incident-ui";

import { WorkflowTemplateCard } from "../create/WorkflowsCreateGrid";

export const WorkflowsListEmptyState = ({
  templates,
}: {
  templates: WorkflowTemplate[];
}) => {
  return (
    <div
      className={
        "flex flex-col mx-10 mt-6 mb-10 space-y-6 items-center bg-surface-secondary rounded-2 p-10 !text-sm"
      }
    >
      <div className="flex-center-y space-x-3">
        <Button
          analyticsTrackingId="workflows-start-from-template"
          href={`/workflows/create`}
          disabled={false}
        >
          Start from scratch
        </Button>
        <p>or pick from one of our templates:</p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        {templates.map((template, _) => {
          return (
            <WorkflowTemplateCard
              key={template.name}
              template={template}
              createHref="/workflows/create"
            />
          );
        })}
      </div>
    </div>
  );
};
