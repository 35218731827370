import { FormContextTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { ModalFooter } from "@incident-ui";
import { uniq } from "lodash";
import { useForm } from "react-hook-form";
import {
  StatusPageComponentImpactPayload,
  StatusPageComponentImpactPayloadStatusEnum,
  StatusPageIncident,
} from "src/contexts/ClientContext";
import { formatDurationShort } from "src/utils/datetime";
import { useAPIMutation } from "src/utils/swr";

import { getImpactWindow } from "../utils/utils";

type FormData = {
  start_at: Date;
  end_at: Date;
};

export const MaintenanceTimestampEditModal = ({
  onClose,
  incident,
}: {
  onClose: () => void;
  incident: StatusPageIncident;
}) => {
  const [startAt, endAt] = getImpactWindow(incident);
  const formMethods = useForm<FormData>({
    defaultValues: {
      start_at: startAt,
      end_at: endAt,
    },
  });
  const {
    setError,
    formState: { isDirty },
  } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "statusPageShowIncident",
    { id: incident.id },
    async (apiClient, data: FormData) =>
      await apiClient.statusPageSetIncidentComponentImpacts({
        id: incident.id,
        setIncidentComponentImpactsRequestBody: {
          component_impacts: uniq(
            incident.component_impacts.map((impact) => impact.component_id),
          ).map(
            (component_id): StatusPageComponentImpactPayload => ({
              component_id,
              status_page_incident_id: incident.id,
              status:
                StatusPageComponentImpactPayloadStatusEnum.UnderMaintenance,
              start_at: data.start_at,
              end_at: data.end_at,
            }),
          ),
        },
      }),
    {
      onSuccess: onClose,
      setError,
    },
  );

  const { start_at, end_at } = formMethods.watch();

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={onClose}
      disableQuickClose
      genericError={genericError}
      title={`Edit maintenance window`}
      analyticsTrackingId="edit-status-page-maintenance-window"
      isExtraLarge
      footer={
        <ModalFooter
          onClose={onClose}
          saving={saving}
          disabled={!isDirty}
          confirmButtonType="submit"
        />
      }
    >
      <div className="space-y-4 mx-10">
        <div className="flex flex-row items-baseline gap-2 text-sm text-slate-600">
          Scheduled from{" "}
          <DateTimeInputV2 name="start_at" formMethods={formMethods} /> until{" "}
          <DateTimeInputV2 name="end_at" formMethods={formMethods} />
        </div>
        <MaintenanceWindowDescription start_at={start_at} end_at={end_at} />
      </div>
    </FormModalV2>
  );
};

export const MaintenanceWindowDescription = ({
  start_at,
  end_at,
}: {
  start_at: Date;
  end_at: Date;
}): React.ReactElement => {
  const timeZoneInfo = <>All timestamps are in your local time zone.</>;

  if (!start_at && !end_at) {
    return (
      <FormContextTextV2 className="text-sm text-slate-700">
        {timeZoneInfo}
      </FormContextTextV2>
    );
  }

  let startAtContent: React.ReactNode;
  let endAtContent: React.ReactNode;

  if (start_at < new Date()) {
    startAtContent = (
      <span>
        Window began{" "}
        <span className="font-semibold">
          {formatDurationShort(start_at, new Date())} ago
        </span>{" "}
      </span>
    );
  } else {
    startAtContent = (
      <span>
        This window begins in{" "}
        <span className="font-semibold">
          {formatDurationShort(new Date(), start_at)}
        </span>{" "}
      </span>
    );
  }

  if (!end_at) {
    endAtContent = <span>and has no specified end</span>;
  } else if (end_at < new Date()) {
    endAtContent = (
      <span>
        and lasted{" "}
        <span className="font-semibold">
          {formatDurationShort(start_at, end_at)}
        </span>
      </span>
    );
  } else {
    endAtContent = (
      <span>
        and will last{" "}
        <span className="font-semibold">
          {formatDurationShort(start_at, end_at)}
        </span>
      </span>
    );
  }

  return (
    <FormContextTextV2 className="text-sm text-slate-700">
      {startAtContent}
      {endAtContent}. {timeZoneInfo}
    </FormContextTextV2>
  );
};
