import { PostIncidentTaskConfigSelectOption } from "@incident-io/api/models/PostIncidentTaskConfigSelectOption";
import { hideTriageMetrics } from "@incident-shared/triage-incidents";
import { IconEnum } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import {
  FormField,
  IncidentDurationMetric,
  IncidentTimestamp,
  Severity,
} from "src/contexts/ClientContext";

// InsightsSelectOption represents an option for splitting data by in Insights (e.g. a custom
// field). We clean these options for a couple reasons:
// - To remove values that do not make sense to appear in a split by
// - To remove values that shouldn't be visible for the org.
// - To add additional options that all orgs should have
export type InsightsSelectOption = {
  id: string;
  label: string;
  // For passing extra metadata
  description?: string;
  // Optionally provide this the ranks need to be used downstream
  rank?: number;
  // If you want to display an icon next to the option
  icon?: IconEnum;
  // If you want to be able to group the options
  groupName?: string;
  // Optionally provide a parent ID which can be used to filter
  // the options based on the parent ID
  parent_id?: string;
};

export const getCustomFieldOptions = (
  fields: FormField[],
): InsightsSelectOption[] => {
  // Hack: we want to only include 'select' custom fields here, as insights can't really visualise
  // custom fields with very high cardinality.
  // We don't get the data in a very nice way sadly, but we can use the operators to know if it's a
  // select field.
  const selectCustomFields = fields.filter((f) =>
    f.operators.some((o) => o.key === "one_of"),
  );

  const filteredFields = selectCustomFields.filter(
    (field) => field.field_id && field.key === "custom_field",
  );

  // @ts-expect-error TS can't infer that we've filtered out the fields without a field_id
  return filteredFields.map((field) => ({
    id: field.field_id,
    label: field.label,
  }));
};

export const getDurationMetricOptions = (
  metrics: IncidentDurationMetric[],
  timestamps: IncidentTimestamp[],
  hideTriage: boolean,
): InsightsSelectOption[] => {
  // If the org is not on pro, don't show timestamps which involve the triage metric (this might
  // happen if the org downgraded).
  let visibleMetrics = metrics || [];
  if (hideTriage) {
    visibleMetrics = hideTriageMetrics(timestamps, metrics);
  }

  // Order so that the "lasted" metric comes first
  visibleMetrics.sort((a, b) => {
    if (a.metric_type === "lasted") {
      return -1;
    }
    if (b.metric_type === "lasted") {
      return 1;
    }
    return 0;
  });

  return visibleMetrics.map((metric) => ({
    id: metric.id,
    label: metric.name,
    description: `${timestamps.find((t) => t.id === metric.from_timestamp_id)
      ?.name} → ${timestamps.find((t) => t.id === metric.to_timestamp_id)
      ?.name}`,
  }));
};

export const getSeverityOptions = (
  severities: Severity[],
): InsightsSelectOption[] => {
  return severities.map((severity) => ({
    id: severity.id,
    label: severity.name,
    rank: severity.rank,
  }));
};

export const getPostIncidentFlowOptions = (
  postIncidentFlowOptions: SelectOption[],
): InsightsSelectOption[] => {
  // We always add a "no flow" option to the list of post-incident flows.
  // This lets us see incidents that did not fall into a flow at all
  const noFlowOption = {
    id: "no_flow",
    label: "No flow",
  };

  const options = postIncidentFlowOptions.map(
    (option) =>
      ({
        id: option.value,
        label: option.label,
      }) as InsightsSelectOption,
  );

  options.push(noFlowOption);

  return options;
};

export const getFollowUpAttributeOptions = (
  customFieldOptions: InsightsSelectOption[],
): InsightsSelectOption[] => {
  // The custom field options have already been overridden, and the others are
  // hardcoded across all customers.
  const options: InsightsSelectOption[] = [];

  // You MUST state the type of option as the description, as we pass this to
  // Explo so we can do the right thing with it.
  options.push({
    id: "status",
    label: "Status",
    icon: IconEnum.Status,
    groupName: "Follow-up",
    description: "status",
  });

  options.push({
    id: "is_assigned",
    label: "Is assigned",
    icon: IconEnum.User,
    groupName: "Follow-up",
    description: "is_assigned",
  });

  options.push({
    id: "is_exported",
    label: "Is exported",
    icon: IconEnum.ExternalLink,
    groupName: "Follow-up",
    description: "is_exported",
  });

  customFieldOptions.forEach((field) => {
    options.push({
      id: field.id,
      label: field.label,
      icon: IconEnum.CustomField,
      groupName: "Incident",
      description: "custom_field",
    });
  });
  return options;
};

export const getPostIncidentTaskConfigOptions = (
  postIncidentTaskConfigOptions: PostIncidentTaskConfigSelectOption[],
): InsightsSelectOption[] => {
  return postIncidentTaskConfigOptions.map((option) => ({
    id: option.id,
    label: option.title,
    parent_id: option.post_incident_flow_id,
  }));
};
