import {
  EscalationPath,
  EscalationPathNodeTypeEnum,
  EscalationPathsListResponseBody,
  EscalationPathsShowResponseBody,
  EscalationPathTargetTypeEnum,
} from "@incident-io/api";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  Tooltip,
  Txt,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerTitle,
  Mode as DrawerMode,
  OverlayDrawerProps,
} from "@incident-ui/Drawer/Drawer";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import { createContext, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation, ValidationErrors } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useRevalidate } from "src/utils/use-revalidate";

import {
  PrioritiesCreateEditDrawer,
  PrioritiesDrawerProvider,
} from "../../alerts/priorities/PrioritiesCreateEditDrawer";
import {
  OnCallPromotionConfirmationModal,
  PromotionState,
} from "../../legacy/on-call/common/OnCallPromotionConfirmationModal";
import { isOnCallUser } from "../../settings/users/users/utils";
import { generateDefaultFormData } from "../common/default";
import {
  escalationPathFormDataToCreatePayload,
  escalationPathFormDataToUpdatePayload,
  escalationPathResponseToFormData,
} from "../common/marshall";
import { useHydratedTargets } from "../common/options";
import {
  EscalationPathFormData,
  EscalationPathUserTargetFormData,
  PathNode,
} from "../common/types";
import {
  IntroducingBranchesModal,
  SEEN_INTRODUCING_BRANCHES_MODAL_LOCAL_STORAGE_KEY,
} from "../IntroducingBranchesModal";
import { EscalationPathNodeEditor } from "../node-editor/EscalationPathNodeEditor";
import { ZoomProvider } from "../node-editor/ZoomContext";
import {
  workingHoursAreUsed,
  WorkingHoursInput,
} from "./EscalationPathWorkingHoursInput";

export const EscalationPathCreateEditDrawer = ({
  onClose,
  onSuccess,
  escalationPathId,
}: {
  onClose: () => void;
  onSuccess?: (result: EscalationPath) => void;
  escalationPathId?: string;
}) => {
  const localValue = window.localStorage.getItem(
    SEEN_INTRODUCING_BRANCHES_MODAL_LOCAL_STORAGE_KEY,
  );

  const [showWelcomeModal, setShowWelcomeModal] = useState(
    localValue !== "seen",
  );

  // Get the clone ID from the URL, if present.
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cloneId = queryParams.get("clone");

  // Fetch exisiting escalation path
  const {
    data: response,
    isLoading: escalationPathLoading,
    error: escalationPathError,
  } = useAPI(escalationPathId || cloneId ? "escalationPathsShow" : null, {
    id: (escalationPathId || cloneId) ?? "",
  });

  // Hydrate the targets in the escalation path
  const { targets, targetsLoading, targetsError } = useHydratedTargets(
    response?.escalation_path,
  );

  const sharedDrawerProps: Omit<OverlayDrawerProps, "children"> = {
    onClose,
    width: "full",
  };

  // Handle error being returned from API.
  const error = escalationPathError || targetsError;
  if (error)
    return (
      <Drawer {...sharedDrawerProps}>
        <GenericErrorMessage error={error} />
      </Drawer>
    );

  // Marshall the escalation path data into the form data
  const escalationPath = escalationPathResponseToFormData(
    response?.escalation_path,
    targets,
  );

  // Add a suffix to the name if we're cloning.
  if (cloneId && escalationPath) {
    escalationPath.name = `${escalationPath.name} - Copy`;
  }

  const initialData: CreateEditFormProps<EscalationPathFormData> =
    escalationPath
      ? { mode: Mode.Edit, initialData: escalationPath }
      : { mode: Mode.Create };

  // If we have a clone ID, then we're in create mode, not edit.
  if (cloneId) {
    initialData.mode = Mode.Create;
  }

  // Determine the mode of the drawer, using an enum that includes duplicate.
  let mode = DrawerMode.Create;
  if (cloneId) {
    mode = DrawerMode.Duplicate;
  } else if (escalationPathId) {
    mode = DrawerMode.Edit;
  }

  return (
    <>
      {showWelcomeModal && (
        <IntroducingBranchesModal onClose={() => setShowWelcomeModal(false)} />
      )}
      <EscalationPathDrawer
        mode={mode}
        onClose={onClose}
        onSuccess={onSuccess}
        initialData={initialData}
        loading={escalationPathLoading || targetsLoading}
      />
    </>
  );
};

// Shared.
const EscalationPathDrawer = ({
  mode,
  onClose,
  onSuccess,
  initialData,
  loading,
}: {
  mode: DrawerMode;
  onClose: () => void;
  onSuccess?: (result: EscalationPath) => void;
  initialData: CreateEditFormProps<EscalationPathFormData>;
  loading: boolean;
}) => {
  const sharedDrawerProps: Omit<OverlayDrawerProps, "children"> = {
    onClose,
    width: "full",
  };

  // Conditional copy for drawer title and button text.
  const title = {
    create: "Create a new escalation path",
    edit: "Edit escalation path",
    duplicate: "Duplicate escalation path",
  };
  const button = {
    create: "Create",
    edit: "Save",
    duplicate: "Duplicate",
  };

  const [showPrioritiesDrawer, setShowPrioritiesDrawer] = useState(false);

  const {
    data: priorities,
    isLoading: prioritiesLoading,
    error: errorPriorities,
  } = useAPI("alertsListPriorities", {});
  if (errorPriorities) {
    return <GenericErrorMessage error={errorPriorities} />;
  }
  if (prioritiesLoading) {
    return <Loader />;
  }

  // The 'highest' rank priority is actually rank 0, go figure!
  const highestPriority = _.minBy(priorities?.priorities, "rank");
  if (!highestPriority) {
    throw new Error("no alert priorities found");
  }

  return (
    <PrioritiesDrawerProvider
      open={showPrioritiesDrawer}
      setOpen={setShowPrioritiesDrawer}
    >
      <Drawer
        {...sharedDrawerProps}
        className="!overflow-hidden"
        warnWhenDirty
        isInBackground={showPrioritiesDrawer}
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="flex flex-col h-full !overflow-hidden">
            <DrawerTitle
              icon={IconEnum.EscalationPath}
              title={title[initialData.mode]}
              onClose={onClose}
              closeIcon={IconEnum.Close}
              sticky
              compact
              secondaryAccessory={
                initialData.initialData?.id ? (
                  <Tooltip content="View in catalog">
                    <Button
                      analyticsTrackingId={null}
                      size={ButtonSize.Small}
                      icon={IconEnum.Book}
                      title=""
                      href={`/catalog/EscalationPath/${initialData.initialData.id}`}
                    />
                  </Tooltip>
                ) : undefined
              }
            />
            <DrawerBody>
              <ReactFlowProvider>
                <EscalationPathFormInner
                  mode={mode}
                  onSuccess={(result: EscalationPath) => {
                    onSuccess && onSuccess(result);
                    onClose();
                  }}
                  initialData={initialData}
                  highestPriorityId={highestPriority.id}
                />
              </ReactFlowProvider>

              <div className="flex w-full sticky bottom-0 z-[20] px-6 py-4 bg-white border-t border-stroke">
                <Button
                  type="submit"
                  form="escalation-path-create-edit-form"
                  analyticsTrackingId="create-edit-escalation-path-v2"
                  theme={ButtonTheme.Primary}
                  className="ml-auto"
                >
                  {button[initialData.mode]}
                </Button>
              </div>
            </DrawerBody>
          </div>
        )}
      </Drawer>
      <AnimatePresence>
        {showPrioritiesDrawer && (
          <PrioritiesCreateEditDrawer
            onClose={() => setShowPrioritiesDrawer(false)}
          />
        )}
      </AnimatePresence>
    </PrioritiesDrawerProvider>
  );
};

// Inner escalation path editor form
const EscalationPathFormInner = ({
  mode,
  onSuccess,
  initialData,
  highestPriorityId,
}: {
  mode: DrawerMode;
  onSuccess?: (result: EscalationPath) => void;
  initialData: CreateEditFormProps<EscalationPathFormData>;
  highestPriorityId: string;
}) => {
  const { identity } = useIdentity();
  const showToast = useToast();

  // Form state.
  const formMethods = useForm<EscalationPathFormData>({
    defaultValues: {
      ...(mode === DrawerMode.Create
        ? generateDefaultFormData(identity, highestPriorityId)
        : initialData.initialData),
    },
  });

  // Hold state for the user promotion modal.
  const [promotionState, setPromotionState] =
    useState<PromotionState<EscalationPathFormData>>(null);

  const id = formMethods.watch("id");
  const name = formMethods.watch("name");

  // If you change any fields, stop showing the existing errors
  formMethods.watch(
    useCallback(() => {
      if (Object.keys(formMethods.formState.errors).length > 0) {
        formMethods.clearErrors();
      }
    }, [formMethods]),
  );

  // API function to create an escalation path.
  const { trigger: createEscalationPath, fieldErrors: createFieldErrors } =
    useAPIMutation(
      "escalationPathsList",
      undefined,
      async (
        apiClient,
        data: EscalationPathFormData & { userIdsToPromote: string[] },
      ) => {
        await apiClient.escalationPathsCreate(
          escalationPathFormDataToCreatePayload(
            data,
            identity,
            data.userIdsToPromote,
          ),
        );
      },
      {
        onSuccess: (data: EscalationPathsListResponseBody) => {
          showToast({
            title: "Escalation path created",
            theme: ToastTheme.Success,
            toastSide: ToastSideEnum.Bottom,
          });

          // avoid having to fetch the individual page separately, since our name is unique
          // so we can find the new one using that
          const newlyCreatedPath = data.escalation_paths.find(
            (path) => path.name === name,
          );

          if (!newlyCreatedPath) {
            throw new Error(
              "Unreachable: failed to find newly created escalation path.",
            );
          }

          onSuccess && onSuccess(newlyCreatedPath);
        },
        onError: () => {
          showToast({
            title: "Failed to create the escalation path",
            theme: ToastTheme.Error,
            toastSide: ToastSideEnum.Bottom,
          });
        },
      },
    );

  const refetchPaths = useRevalidate(["escalationPathsList"]);

  // API function to update an escalation path.
  const { trigger: updateEscalationPath, fieldErrors: updateFieldErrors } =
    useAPIMutation(
      "escalationPathsShow",
      { id: id },
      async (
        apiClient,
        data: EscalationPathFormData & { userIdsToPromote: string[] },
      ) => {
        await apiClient.escalationPathsUpdate(
          escalationPathFormDataToUpdatePayload(
            id || "",
            data,
            identity,
            data.userIdsToPromote,
          ),
        );
      },
      {
        onSuccess: (data: EscalationPathsShowResponseBody) => {
          showToast({
            title: "Escalation path updated",
            theme: ToastTheme.Success,
          });

          refetchPaths();
          onSuccess && onSuccess(data.escalation_path);
        },
        setError: formMethods.setError,
        onError: () => {
          showToast({
            title: "Failed to update escalation path",
            theme: ToastTheme.Error,
          });
        },
      },
    );

  const nodes = formMethods.watch("nodes");
  const schedules = getScheduleTargets(nodes);

  const {
    data: { schedules: allSchedules },
  } = useAPI("schedulesList", undefined, {
    fallbackData: { schedules: [], users: [] },
  });
  const hydratedSchedules = allSchedules.filter((schedule) =>
    schedules.includes(schedule.id),
  );

  const showWorkingHoursCallout =
    workingHoursAreUsed(nodes) &&
    hydratedSchedules.some(
      (schedule) =>
        schedule.config?.rotations.some(
          (rota) => rota.working_intervals.length > 0,
        ),
    );

  // Submit function for the form, conditional on the mode.
  const handleSubmit = async (formData: EscalationPathFormData) => {
    const valid = await formMethods.trigger();
    if (!valid) {
      return;
    }

    const usersToPromote = Object.values(formData.nodes).flatMap((node) =>
      (node.data.level?.targets || []).filter(
        (target) => target.type === "user" && !isOnCallUser(target.state),
      ),
    ) as EscalationPathUserTargetFormData[];

    if (usersToPromote.length > 0) {
      setPromotionState({
        formData,
        usersToPromote,
      });
      return;
    }

    realSubmit(formData);
  };

  const realSubmit = (
    formData: EscalationPathFormData,
    userIdsToPromote: string[] = [],
  ) => {
    if (initialData.mode === Mode.Edit) {
      updateEscalationPath({ ...formData, userIdsToPromote });
    } else {
      createEscalationPath({ ...formData, userIdsToPromote });
    }
  };

  return (
    <EscalationPathErrorContext.Provider
      value={updateFieldErrors ?? createFieldErrors ?? null}
    >
      <OnCallPromotionConfirmationModal
        noun="escalation path"
        state={promotionState}
        onClose={() => setPromotionState(null)}
        onSubmit={realSubmit}
      />
      <FormV2
        id="escalation-path-create-edit-form"
        onSubmit={handleSubmit}
        formMethods={formMethods}
        outerClassName="h-full"
        innerClassName="flex h-full space-y-0"
        loadingWrapperClassName="h-full"
        warnWhenDirty
      >
        <div
          className={tcx(
            "h-full flex flex-col space-y-6 pr-6 min-w-[400px] w-[30%] bg-white border-r-[1px] border-stroke",
            "overflow-auto justify-between",
          )}
        >
          <div className="flex flex-col space-y-6">
            <InputV2
              formMethods={formMethods}
              name="name"
              label="Escalation path name"
              helptext="Often named after the team that own it"
              placeholder="Security team"
              required
            />
            <WorkingHoursInput />
            {showWorkingHoursCallout && (
              <Callout
                theme={CalloutTheme.Warning}
                title={"You're already using working hours in your schedules"}
                subtitle={
                  "If we attempt to reach a schedule outside its working hours as part of this path, your escalation could expire earlier than you'd expect. We recommend only using working hours in one or the other."
                }
              />
            )}
          </div>

          <div className="flex flex-col gap-2">
            <Txt xs className="text-content-tertiary">
              Useful links
            </Txt>
            <ul className="flex flex-col gap-1">
              <li>
                <Button
                  theme={ButtonTheme.Naked}
                  className="text-sm-med shadow-button-inner"
                  analyticsTrackingId="escalation-path-docs"
                  href="https://help.incident.io/en/articles/9310668-smart-escalation-paths"
                  openInNewTab
                >
                  Read the help centre article
                </Button>
              </li>
              <li>
                <Button
                  theme={ButtonTheme.Naked}
                  className="text-sm-med shadow-button-inner"
                  analyticsTrackingId="escalation-path-notification-preferences"
                  href="/user-preferences/on-call-notifications"
                  openInNewTab
                >
                  Notification preferences
                </Button>
              </li>
              <li>
                <Button
                  theme={ButtonTheme.Naked}
                  className="text-sm-med shadow-button-inner"
                  analyticsTrackingId="escalation-path-priorities-settings"
                  href="/alerts/attributes"
                  openInNewTab
                >
                  Edit alert priorities
                </Button>
              </li>
            </ul>
          </div>
        </div>
        <ZoomProvider>
          <EscalationPathNodeEditor />
        </ZoomProvider>
      </FormV2>
    </EscalationPathErrorContext.Provider>
  );
};

export const EscalationPathErrorContext = createContext<ValidationErrors<
  EscalationPathFormData & { userIdsToPromote: string[] }
> | null>(null);

const getScheduleTargets = (nodes: Record<string, PathNode>): string[] => {
  return (
    Object.values(nodes)
      // Filter nodes to get only those of type Level
      .filter((node) => node.data.nodeType === EscalationPathNodeTypeEnum.Level)
      // Extract targets from each level node
      .flatMap((node) => node.data.level?.targets || [])
      // Filter targets to get only those of type Schedule
      .filter((target) => target.type === EscalationPathTargetTypeEnum.Schedule)
      // Get all the schedule IDs
      .map((target) => target.value)
  );
};
