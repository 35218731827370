import {
  StatusPage,
  StatusPageAffectedComponentPayloadStatusEnum as ComponentStatusEnum,
  StatusPageCreateIncidentRequestBody,
  StatusPageCreateIncidentRequestBodyStatusEnum as IncidentStatusEnum,
  StatusPagePageTypeEnum,
  StatusPageStructure,
  StatusPageTemplate,
} from "@incident-io/api";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  Icon,
  IconEnum,
  Link,
  ModalFooter,
  Steps,
  Txt,
} from "@incident-ui";
import { StepConfig } from "@incident-ui/Steps/Steps";
import React, { useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";
import { v4 as uuid } from "uuid";

import {
  SubPageEditor,
  SubPageEditorWarning,
} from "../../incidents/common/SubPageEditor";
import { useSubPageData } from "../../incidents/hooks/use-sub-page-data";
import {
  INCIDENT_STATUS_CONFIG,
  INITIAL_INCIDENT_STATUS,
} from "../../incidents/utils/utils";
import {
  AffectedComponentsEditor,
  AffectedComponentsFormData,
  affectedComponentsFormStateToPayload,
  affectedComponentsReviewInfo,
} from "../../incidents/view/AffectedComponentsEditor";
import { useStatusPageSubscriptionCount } from "../hooks/use-status-page-subscription-count";
import { StatusInput } from "../StatusInput";
import { SubPageReviewInfo } from "../SubPageReviewInfo";
import { defaultAllComponentsOperational } from "../utils/default-all-components-operational";
import {
  messageTemplateFor,
  useTemplatedMessageInput,
} from "../utils/template-helpers";

export type ManualSubPageFormData = {
  manual_sub_pages_enabled: boolean;
  manual_sub_page_ids: string[];
};

type FormData = Pick<
  StatusPageCreateIncidentRequestBody,
  | "name"
  | "status"
  | "idempotency_key"
  | "created_from_template_id"
  | "manual_sub_page_ids"
> & {
  // TS can't deal with the recursive type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any;
  notify_subscribers: boolean;
} & AffectedComponentsFormData &
  ManualSubPageFormData;

enum steps {
  Info = "info",
  Review = "review",
}

const stepConfig: StepConfig<steps>[] = [
  {
    id: steps.Info,
    name: "Incident info",
  },
  {
    id: steps.Review,
    name: "Review & publish",
  },
];

const modalProps = {
  isOpen: true,
  isExtraLarge: true,
  title: "Publish incident",
  analyticsTrackingId: "status-page-publish-incident",
};

export const StatusPagePublishLiveIncident = ({
  page,
  structure,
  templates,
  onClose,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
  templates: StatusPageTemplate[];
  onClose: () => void;
}): React.ReactElement => {
  const defaultStatuses = defaultAllComponentsOperational(structure);

  const defaultTemplate = messageTemplateFor(
    templates,
    INITIAL_INCIDENT_STATUS,
  );

  const formMethods = useForm<FormData>({
    defaultValues: {
      idempotency_key: uuid(),
      status: INITIAL_INCIDENT_STATUS as unknown as IncidentStatusEnum,
      component_statuses: defaultStatuses,
      notify_subscribers: page.active_subscriber_count > 0,
      message: defaultTemplate?.content,
      created_from_template_id: defaultTemplate?.id,
      manual_sub_pages_enabled: false,
      manual_sub_page_ids: [],
    },
  });

  const isNotifying = formMethods.watch("notify_subscribers");
  const name = formMethods.watch("name");

  const status = formMethods.watch("status");
  const { renderTemplateSelect } = useTemplatedMessageInput({
    page,
    templates,
    formMethods,
    statusPath: "status",
    messagePath: "message",
    templateIdPath: "created_from_template_id",
    namePath: "name",
  });

  const {
    trigger,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "statusPageListIncidents",
    {
      statusPageId: page.id,
    },
    async (apiClient, data: FormData) => {
      const payload: StatusPageCreateIncidentRequestBody = {
        ...data,
        status_page_id: page.id,
        component_statuses: affectedComponentsFormStateToPayload(data),
        suppress_notifications: !data.notify_subscribers,
        automate_maintenance_status: false,
        message: data.message,
        manual_sub_page_ids: data.manual_sub_pages_enabled
          ? data.manual_sub_page_ids
          : undefined,
      };
      const res = await apiClient.statusPageCreateIncident({
        createIncidentRequestBody: payload,
      });

      navigate(
        `/status-pages/${page.id}/incident/${res.status_page_incident.id}`,
      );

      // Revalidate the list response
      return;
    },
    {
      setError: formMethods.setError,
    },
  );

  const navigate = useOrgAwareNavigate();

  const [currentStep, setCurrentStep] = useState(steps.Info);
  const manualSubPagesEnabled = formMethods.watch("manual_sub_pages_enabled");
  const componentStatuses = formMethods.watch("component_statuses");
  const selectedSubPageIDs = formMethods.watch("manual_sub_page_ids");

  const { catalogType, affectedPages } = useSubPageData(
    page,
    componentStatuses,
    selectedSubPageIDs,
  );

  return (
    <FormModalV2
      {...modalProps}
      disableQuickClose
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={
        currentStep === steps.Review
          ? trigger
          : async () => {
              const isValid = await formMethods.trigger();

              if (isValid) {
                setCurrentStep(steps.Review);
              }
            }
      }
      saving={saving}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={
            currentStep === steps.Info
              ? onClose
              : () => setCurrentStep(steps.Info)
          }
          cancelButtonText={currentStep === steps.Info ? "Cancel" : "Back"}
          confirmButtonType="submit"
          confirmButtonText={
            currentStep === steps.Info ? "Review incident" : "Publish incident"
          }
          disabled={status === IncidentStatusEnum.Resolved}
        />
      }
    >
      <Steps steps={stepConfig} currentStep={currentStep} />
      {currentStep === steps.Info ? (
        <>
          {page.mirroring_atlassian_page && (
            <Callout theme={CalloutTheme.Warning}>
              This incident won&apos;t appear on your Atlassian Statuspage. If
              you&apos;re ready to switch to your incident.io Status Page, click
              &lsquo;Go live&rsquo;.
            </Callout>
          )}
          {renderTemplateSelect()}
          <InputV2
            formMethods={formMethods}
            name="name"
            required="You must set a name for each incident"
            label="Name"
            helptext={
              isNotifying
                ? "We'll show this on your status page, and use it as the email subject on emails to subscribers."
                : "The publicly visible name for this incident."
            }
          />
          <div className="flex gap-8 items-end">
            <StatusInput
              formMethods={formMethods}
              name="status"
              label="Status"
              className="flex-1"
            />
          </div>
          {status === IncidentStatusEnum.Resolved ? (
            <Callout theme={CalloutTheme.Info}>
              To publish a historical incident,{" "}
              <Link
                to={
                  name
                    ? `/status-pages/${
                        page.id
                      }/overview/now?action=retrospective-incident&name=${encodeURIComponent(
                        name,
                      )}`
                    : `/status-pages/${page.id}/overview/now?action=retrospective-incident`
                }
                analyticsTrackingId={"redirect-to-retrospective-incident"}
                className="text-slate-600 hover:!text-content-primary !no-underline"
              >
                create a retrospective incident
              </Link>{" "}
              instead.
            </Callout>
          ) : (
            <>
              <TemplatedTextInputV2
                formMethods={formMethods}
                name={"message"}
                includeVariables={false}
                includeExpressions={false}
                label="Message"
                placeholder="What is the suspected impact right now?"
                required="You must set a message"
                helptext={
                  page.page_type === StatusPagePageTypeEnum.Standalone
                    ? "We'll show this on the status page for this incident."
                    : "We’ll show this on each sub-page impacted by this incident."
                }
                // We only allow basic formatting here - no headings!
                format="mrkdwn"
                multiLine
              />
              <AffectedComponentsEditor<
                FormData,
                "component_statuses",
                "component_statuses"
              >
                formMethods={formMethods}
                fieldNamePrefix="component_statuses"
                structure={structure}
                maintenance={false}
              />
              {page.page_type !== StatusPagePageTypeEnum.Standalone && (
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <Txt bold>Affected sub-pages</Txt>
                    {!manualSubPagesEnabled && (
                      <Button
                        onClick={() => {
                          formMethods.setValue(
                            "manual_sub_pages_enabled",
                            true,
                          );
                          formMethods.setValue(
                            `manual_sub_page_ids`,
                            affectedPages.map((page) => page.id),
                          );
                        }}
                        analyticsTrackingId={"edit-sub-pages"}
                        theme={ButtonTheme.Naked}
                        icon={IconEnum.Edit}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  {manualSubPagesEnabled ? (
                    // show a warning that the user is on their own and that they'll
                    // be responsible for manually updating the sub-pages going forward
                    // and then render a list of checkboxes to enable/disable each page,
                    // defaulting to enabling the current list of affected pages.
                    <>
                      <SubPageEditorWarning affectedPages={affectedPages} />
                      <SubPageEditor page={page} />
                    </>
                  ) : affectedPages.length === 0 ? (
                    <div className="bg-white p-4 shadow-sm rounded-2 border border-stroke text-sm">
                      This incident will not be visible on any{" "}
                      {page.page_type === StatusPagePageTypeEnum.Parent
                        ? "sub-pages"
                        : "customer pages"}
                      . Change the affected components to ensure that it is
                      visible to your users.
                    </div>
                  ) : (
                    <div className="flex items-center flex-wrap gap-2 rounded-2 shadow p-4 bg-white">
                      {affectedPages.map((page) => (
                        <CatalogEntryBadge
                          key={page.id}
                          color={catalogType?.color}
                          icon={catalogType?.icon}
                          label={page.name}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <ReviewInfo page={page} structure={structure} />
      )}
    </FormModalV2>
  );
};

const ReviewInfo = ({
  page,
  structure,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
}): React.ReactElement => {
  const formMethods = useFormContext<FormData>();
  const [
    name,
    status,
    message,
    componentStatuses,
    notifySubscribers,
    manualSubPageIds,
  ] = formMethods.watch([
    "name",
    "status",
    "message",
    "component_statuses",
    "notify_subscribers",
    "manual_sub_page_ids",
  ]);

  const componentsToShow = affectedComponentsReviewInfo(
    structure,
    componentStatuses,
  );

  const componentIds = componentsToShow
    .filter((c) => c.status !== ComponentStatusEnum.Operational)
    .map((c) => c.componentId);

  const activeSubscriberCount = useStatusPageSubscriptionCount({
    statusPageId: page.id,
    componentIds: componentIds.length > 0 ? componentIds : undefined,
    subPageIds: manualSubPageIds.length > 0 ? manualSubPageIds : undefined,
  });

  const isResolved = status === IncidentStatusEnum.Resolved;

  return (
    <>
      <p className="text-sm mb-4">
        Review the information below and hit the{" "}
        <span className="font-semibold">Publish incident</span> button when
        you&apos;re ready.
      </p>
      <ContentBox className="p-4 text-sm grid grid-cols-3 gap-4">
        <div className="text-content-tertiary">Status page</div>
        <div className="col-span-2">{page.name}</div>

        <div className="text-content-tertiary">Incident name</div>
        <div className="col-span-2">{name}</div>

        <div className="text-content-tertiary">Status</div>
        <div className="col-span-2">{INCIDENT_STATUS_CONFIG[status].label}</div>

        {!isResolved && (
          <>
            <div className="text-content-tertiary">Message</div>
            <div className="col-span-2">
              <TemplatedTextDisplay
                value={message}
                style={TemplatedTextDisplayStyle.Compact}
              />
            </div>
          </>
        )}

        <div className="text-content-tertiary">Components</div>
        <div className="col-span-2 space-y-2">
          {componentsToShow.length > 0 ? (
            componentsToShow.map(({ componentId, componentName, status }) => {
              const statusConfig = COMPONENT_STATUS_CONFIG[status];
              return (
                <div
                  key={componentId}
                  className="flex flex-col lg:flex-row lg:space-x-3"
                >
                  <div className="flex space-x-1">
                    <Icon
                      id={statusConfig.icon}
                      className={statusConfig.colour}
                    />
                    <span>{componentName}</span>
                  </div>
                  <span className="text-content-tertiary">
                    {statusConfig.label}
                  </span>
                </div>
              );
            })
          ) : (
            <div className="flex space-x-1">
              <Icon
                id={
                  COMPONENT_STATUS_CONFIG[ComponentStatusEnum.Operational].icon
                }
                className={
                  COMPONENT_STATUS_CONFIG[ComponentStatusEnum.Operational]
                    .colour
                }
              />
              <p>No impacted components</p>
            </div>
          )}
        </div>
        <SubPageReviewInfo page={page} incident={null} />
      </ContentBox>
      {activeSubscriberCount !== undefined && activeSubscriberCount > 0 && (
        <div className="space-y-2">
          <CheckboxV2
            label="Notify subscribers"
            name="notify_subscribers"
            formMethods={formMethods}
          />
          {!notifySubscribers ? (
            <Callout theme={CalloutTheme.Info}>
              We won&rsquo;t notify any of your subscribers. Future updates will
              default to not notifying subscribers too.
            </Callout>
          ) : (
            <Callout theme={CalloutTheme.Info}>
              We&rsquo;ll notify your {activeSubscriberCount.toLocaleString()}{" "}
              subscriber{activeSubscriberCount === 1 ? "" : "s"}.
            </Callout>
          )}
        </div>
      )}
    </>
  );
};
