import {
  ExternalResource,
  ExternalResourceResourceTypeEnum,
} from "src/contexts/ClientContext";
import { assertUnreachable } from "src/utils/utils";

import { AtlassianStatuspageIncident } from "./AtlassianStatuspageIncident";
import { DatadogMonitorAlert } from "./DatadogMonitorAlert";
import { GitHubPullRequest } from "./GithubPullRequest";
import { GitLabMergeRequest } from "./GitLabMergeRequest";
import { GoogleCalendarEvent } from "./GoogleCalendarEvent";
import { OpsgenieAlert } from "./OpsgenieAlert";
import { PagerDutyIncident } from "./PagerDutyIncident";
import { SentryIssue } from "./SentryIssue";
import { ZendeskTicket } from "./ZendeskTicket";

// We render attachments slightly differently on the timeline from elsewhere.
// This is so the timeline doesn't have 'point-in-time' information, as it
// can conflict with the timeline item (e.g. if we're saying a PD incident
// was triggered, it's a bit weird to show that it's now resolved).
export enum AttachmentContexts {
  Timeline = "timeline",
  RealTime = "realtime",
  PostMortem = "postmortem",
}

export const Attachment = ({
  resource,
  className,
  context,
  greyscale,
}: {
  resource: ExternalResource;
  className?: string;
  context: AttachmentContexts;
  greyscale?: boolean;
}): React.ReactElement | null => {
  switch (resource.resource_type) {
    case ExternalResourceResourceTypeEnum.PagerDutyIncident:
      if (!resource.pager_duty_incident) {
        throw new Error("PagerDuty Incident resource without expected content");
      }
      return (
        <PagerDutyIncident
          className={className}
          resource={resource}
          pdInc={resource.pager_duty_incident}
          context={context}
          greyscale={greyscale}
        />
      );
    case ExternalResourceResourceTypeEnum.OpsgenieAlert:
      if (!resource.opsgenie_alert) {
        throw new Error("PagerDuty Incident resource without expected content");
      }
      return (
        <OpsgenieAlert
          className={className}
          resource={resource}
          ogAlert={resource.opsgenie_alert}
          context={context}
          greyscale={greyscale}
        />
      );
    case ExternalResourceResourceTypeEnum.GithubPullRequest:
      if (!resource.github_pull_request) {
        throw new Error(
          "GitHub Pull Request resource without expected content",
        );
      }
      return (
        <GitHubPullRequest
          className={className}
          resource={resource}
          ghPr={resource.github_pull_request}
          context={context}
        />
      );
    case ExternalResourceResourceTypeEnum.GitlabMergeRequest:
      if (!resource.gitlab_merge_request) {
        throw new Error(
          "GitLab Merge Request resource without expected content",
        );
      }
      return (
        <GitLabMergeRequest
          className={className}
          resource={resource}
          mergeRequest={resource.gitlab_merge_request}
          context={context}
        />
      );
    case ExternalResourceResourceTypeEnum.SentryIssue:
      if (!resource.sentry_issue) {
        throw new Error("Sentry resource without expected content");
      }
      return (
        <SentryIssue
          className={className}
          resource={resource}
          issue={resource.sentry_issue}
          context={context}
        />
      );
    case ExternalResourceResourceTypeEnum.AtlassianStatuspageIncident:
      if (!resource.atlassian_statuspage_incident) {
        throw new Error(
          "Atlassian Statuspage incident resource without expected content",
        );
      }
      return (
        <AtlassianStatuspageIncident
          className={className}
          resource={resource}
          spIncident={resource.atlassian_statuspage_incident}
          context={context}
        />
      );
    case ExternalResourceResourceTypeEnum.StatuspageIncident:
      throw new Error(
        "Unexpected StatuspageIncident resource type, use AtlassianStatuspageIncident instead",
      );

    case ExternalResourceResourceTypeEnum.ZendeskTicket:
      if (!resource.zendesk_ticket) {
        throw new Error("Zendesk Ticket resource without expected content");
      }
      return (
        <ZendeskTicket
          className={className}
          resource={resource}
          ticket={resource.zendesk_ticket}
          context={context}
        />
      );
    case ExternalResourceResourceTypeEnum.DatadogMonitorAlert:
      if (!resource.datadog_monitor_alert) {
        throw new Error(
          "Datadog Monitor Alert resource without expected content",
        );
      }
      return (
        <DatadogMonitorAlert
          className={className}
          resource={resource}
          alert={resource.datadog_monitor_alert}
          context={context}
        />
      );
    case ExternalResourceResourceTypeEnum.GoogleCalendarEvent:
      return (
        <GoogleCalendarEvent
          className={className}
          resource={resource}
          context={context}
          event={resource.google_calendar_event}
        />
      );
    case ExternalResourceResourceTypeEnum.Scrubbed:
      return <span>Scrubbed</span>;
    case ExternalResourceResourceTypeEnum.JiraIssue:
      return <></>;
    default:
      assertUnreachable(resource.resource_type);
  }

  throw new Error(
    "External resource without any of the expected resources attached",
  );
};
