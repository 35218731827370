/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LegacyIncidentTriggerConfig,
    LegacyIncidentTriggerConfigFromJSON,
    LegacyIncidentTriggerConfigFromJSONTyped,
    LegacyIncidentTriggerConfigToJSON,
} from './LegacyIncidentTriggerConfig';

/**
 * 
 * @export
 * @interface LegacyIncidentTriggersGetConfigResponseBody
 */
export interface LegacyIncidentTriggersGetConfigResponseBody {
    /**
     * 
     * @type {LegacyIncidentTriggerConfig}
     * @memberof LegacyIncidentTriggersGetConfigResponseBody
     */
    incident_trigger_config: LegacyIncidentTriggerConfig;
}

export function LegacyIncidentTriggersGetConfigResponseBodyFromJSON(json: any): LegacyIncidentTriggersGetConfigResponseBody {
    return LegacyIncidentTriggersGetConfigResponseBodyFromJSONTyped(json, false);
}

export function LegacyIncidentTriggersGetConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegacyIncidentTriggersGetConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_trigger_config': LegacyIncidentTriggerConfigFromJSON(json['incident_trigger_config']),
    };
}

export function LegacyIncidentTriggersGetConfigResponseBodyToJSON(value?: LegacyIncidentTriggersGetConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_trigger_config': LegacyIncidentTriggerConfigToJSON(value.incident_trigger_config),
    };
}

