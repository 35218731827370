import {
  AlertStatusEnum,
  Escalation,
  EscalationPath,
  EscalationsRespondEscalationRequestBodyResponseEnum,
  EscalationsRespondEscalationRequestBodySourceEnum,
  EscalationStatusEnum,
} from "@incident-io/api";
import { AlertFiringIndicator } from "@incident-shared/alerts/AlertFiringIndicator";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  OrgAwareLink,
  OrgAwareNavigate,
  OrgAwareNavLink,
} from "@incident-shared/org-aware";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Loader,
  ToastTheme,
  Txt,
} from "@incident-ui";
import { LocalRelativeDateTime } from "@incident-ui/LocalDateTime/LocalRelativeDateTime";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import { useState } from "react";
import { useParams } from "react-router";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import {
  AlertOverviewColumn,
  AlertSourceBadge,
} from "../../../alerts/common/AlertOverviewColumn";
import { EscalationTimeline } from "../../../escalations/EscalationTimeline";
import { EscalationNoAccess } from "./EscalationNoAccess";
import { EscalationStatusBadge } from "./EscalationStatusBadge";
import { SlimIncidentCard } from "./SlimIncidentCard";

export const EscalationsViewPage = () => {
  const { id: escalationID } = useParams<{ id: string }>();
  const [showAckedButton, setShowAckedButton] = useState<boolean>(false);
  const showToast = useToast();

  const { trigger: ackEscalation, isMutating: isAcking } = useAPIMutation(
    "escalationsShow",
    { escalationId: escalationID ?? "" },
    async (apiClient, _) => {
      await apiClient.escalationsRespondEscalation({
        escalationId: escalationID ?? "",
        respondEscalationRequestBody: {
          source: EscalationsRespondEscalationRequestBodySourceEnum.Dashboard,
          response: EscalationsRespondEscalationRequestBodyResponseEnum.Ack,
        },
      });
    },
    {
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to acknowledge escalation",
          toastSide: ToastSideEnum.Bottom,
        });
      },
    },
  );

  const {
    data: escalationResp,
    isLoading,
    error: escalationError,
  } = useAPI("escalationsShow", { escalationId: escalationID ?? "" });

  if (escalationError) {
    if (escalationError.status === 404) {
      return <OrgAwareNavigate to="/404" replace />;
    }
    if (escalationError.status === 403) {
      return <EscalationNoAccess />;
    }
    return <GenericErrorMessage error={escalationError} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  const escalation = escalationResp?.escalation;
  if (!escalation) {
    return <OrgAwareNavigate to={"/404"} replace />;
  }
  const isManualEscalation = !escalation.alert_id;

  return (
    <PageWrapper
      width={PageWidth.Medium}
      icon={IconEnum.Escalate}
      backHref="/on-call/escalations"
      crumbs={[
        { title: "On-call", to: "/on-call" },
        { title: "Escalations", to: "/on-call/escalations" },
      ]}
      accessory={
        <AnimatePresence>
          <AlertFiringIndicator
            firing={escalation.status === EscalationStatusEnum.Triggered}
            dotClassName={tcx(
              (escalation.status === EscalationStatusEnum.Pending ||
                escalation.status === EscalationStatusEnum.Expired) &&
                "!bg-slate-400",
            )}
          />
          {showAckedButton ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <Button
                analyticsTrackingId={"escalation-acknowledged"}
                theme={ButtonTheme.UnstyledPill}
                icon={IconEnum.Checkmark}
                iconProps={{ size: IconSize.Large }}
                className="bg-green-surface text-green-600 !shadow-none m-0 !mr-1"
                disabled={true}
              >
                <span className="line-clamp-1">Acknowledged</span>
              </Button>
            </motion.div>
          ) : null}

          {/* Only show an acknowledged button for pending or triggered escalations */}
          {escalation.status !== EscalationStatusEnum.Resolved &&
          escalation.status !== EscalationStatusEnum.Acked &&
          escalation.status !== EscalationStatusEnum.Expired &&
          escalation.status !== EscalationStatusEnum.Cancelled &&
          !showAckedButton ? (
            <Button
              analyticsTrackingId={"escalation-acknowledge"}
              theme={ButtonTheme.Primary}
              onClick={() => {
                ackEscalation({});
                setShowAckedButton(true);
                setTimeout(() => {
                  setShowAckedButton(false);
                }, 3000);
              }}
              loading={isAcking}
            >
              <span className="line-clamp-1">Acknowledge</span>
            </Button>
          ) : null}
        </AnimatePresence>
      }
      title={
        isManualEscalation && escalation?.description
          ? escalation?.description
          : escalation?.title
      }
    >
      {/* Two column layout */}
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Left column - Timeline */}
        <div className="lg:w-[65%] grow gap-6">
          <EscalationTimeline
            escalation={escalation}
            escalationPath={escalationResp.escalation_path}
          />
        </div>

        {/* Right column - Related incident and alert */}
        <div className="space-y-6 lg:w-[35%] grow">
          <div className="flex flex-col gap-6">
            <EscalationOverview
              escalation={escalation}
              escalationPath={escalationResp.escalation_path}
            />

            {/* Related incident */}
            <RelatedIncident escalation={escalation} />

            {/* Related alert */}
            {!isManualEscalation ? (
              <RelatedAlert escalation={escalation} />
            ) : null}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

const EscalationOverview = ({
  escalation,
  escalationPath,
}: {
  escalation: Escalation;
  escalationPath?: EscalationPath;
}) => {
  const notificationDates = _.chain(escalation.targets)
    .flatMap((target) => target.notifications)
    .map((notification) => notification.updated_at)
    .value();
  const transitionDates = _.map(
    escalation.transitions,
    (transition) => transition.occurred_at,
  );

  const { data: prioritiesData } = useAPI("alertsListPriorities", {});
  const alertPriority = prioritiesData?.priorities.find(
    (priority) => priority.id === escalation.priority_id,
  );

  const allDates = _.concat(notificationDates, transitionDates).filter(
    (date) => new Date(date).toString() !== "Invalid Date",
  );
  const lastUpdatedAt = _.max(allDates) ?? new Date();

  const { data } = useAPI(escalation?.alert_id ? "alertsShowAlert" : null, {
    id: escalation?.alert_id ?? "",
  });

  return (
    <div className="flex flex-col gap-2">
      <span className="font-medium text-sm">Details</span>
      <ContentBox className="p-4 flex flex-col space-y-4">
        <div className="w-full flex items-center justify-between">
          <Txt lightGrey>Status</Txt>
          <EscalationStatusBadge status={escalation.status} />
        </div>

        <div className="w-full flex items-center justify-between">
          <Txt lightGrey>Source</Txt>
          <>
            {escalation.alert_id && (
              <AlertSourceBadge
                showIcon
                alert={data?.alert}
                className="!text-sm !text-content-primary"
              />
            )}
            {escalation.creator.workflow && (
              <OrgAwareLink
                to={`/workflows/${escalation.creator.workflow.id}`}
                className={
                  "flex items-center space-x-1.5 hover:text-content-primary hover:underline transition text-ellipsis"
                }
                onClick={(e) => e.stopPropagation()}
              >
                <Txt lightGrey>
                  Workflow: {escalation.creator.workflow.name}
                </Txt>
              </OrgAwareLink>
            )}
            {escalation.creator.user && (
              <Txt grey>
                Manual escalation by {escalation.creator.user.name}
              </Txt>
            )}
          </>
        </div>

        <div className="w-full flex items-center justify-between">
          <Txt lightGrey>Path</Txt>
          {escalationPath && (
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Secondary}
              size={ButtonSize.Small}
              href={`/on-call/escalation-paths/${escalation.escalation_path_id}`}
              icon={IconEnum.EscalationPath}
              iconProps={{ className: "text-brand" }}
            >
              <EscalationPathBadge
                escalationPath={escalationPath}
                className="text-content-primary font-medium truncate text-ellipsis max-w-[15rem]"
              />
            </Button>
          )}
        </div>

        <div className="w-full flex items-center justify-between">
          <span className="text-content-secondary">Priority</span>
          <span className="text-content-primary">{alertPriority?.name}</span>
        </div>

        <div className="w-full flex items-center justify-between">
          <Txt lightGrey>Last updated</Txt>
          <LocalRelativeDateTime date={lastUpdatedAt} />
        </div>
      </ContentBox>
    </div>
  );
};

const RelatedIncident = ({ escalation }: { escalation: Escalation }) => {
  const incidentId = escalation?.incident_id || escalation?.incident?.id;
  const { data, isLoading, error } = useAPI(
    incidentId ? "incidentsShow" : null,
    {
      id: incidentId ?? "",
    },
  );

  if (isLoading) {
    return <></>;
  }
  if (error) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-2">
      <span className="font-medium text-sm">Related incident</span>

      {!data?.incident && (
        <ContentBox className="p-4">
          <span className="text-sm text-slate-600">
            There is no incident for this escalation
          </span>
        </ContentBox>
      )}

      {data?.incident && <SlimIncidentCard incident={data.incident} />}
    </div>
  );
};

const RelatedAlert = ({ escalation }: { escalation: Escalation }) => {
  const { data, isLoading, error } = useAPI(
    escalation?.alert_id ? "alertsShowAlert" : null,
    {
      id: escalation?.alert_id ?? "",
    },
  );

  if (isLoading) {
    return <></>;
  }
  if (error) {
    return <></>;
  }

  const alertFiring =
    data?.alert?.status && data?.alert?.status === AlertStatusEnum.Firing;

  return (
    <div className="flex flex-col gap-2">
      <span className="font-medium text-sm">Related alert</span>
      {isLoading && <Loader />}

      {!escalation.alert_id && (
        <ContentBox className="p-4">
          <span className="text-sm text-slate-600">
            There is no alert for this escalation
          </span>
        </ContentBox>
      )}

      {data?.alert && (
        <OrgAwareNavLink to={`/alerts/${data.alert.id}/details`}>
          <ContentBox className="p-4 hover:border-slate-500 flex items-center space-x-2">
            <div className="mr-2 w-[12px] flex items-center justify-center">
              <AlertFiringIndicator firing={alertFiring} />
            </div>
            <AlertOverviewColumn alert={data.alert} showAlertSource />
          </ContentBox>
        </OrgAwareNavLink>
      )}
    </div>
  );
};

const EscalationPathBadge = ({
  escalationPath,
  className,
}: {
  escalationPath?: EscalationPath;
  className?: string;
}) => {
  if (!escalationPath) {
    return <></>;
  }
  return <span className={className}>{escalationPath.name}</span>;
};
