import {
  Alert,
  AlertAttribute,
  AlertAttributePayload,
  AlertSchema,
  AlertSchemaPayload,
  AlertSource,
  AlertSourceConfig,
  AlertSourceSourceTypeEnum,
  AlertSourceSuggestedAttribute,
  AlertsShowSchemaResponseBody,
  AlertsUpdateSourceConfigRequestBody,
  AlertsUpdateSourceConfigResponseBody,
  AlertTemplatePayload,
  CatalogResource,
  CatalogResourceColorEnum,
  CatalogResourceIconEnum,
  EngineParamBinding,
  EngineScope,
  ErrorResponse,
  Expression,
  ExpressionOperationOperationTypeEnum,
  Priority,
  Resource,
  ScopeNameEnum,
  Settings,
} from "@incident-io/api";
import { NonPrimitiveEntry } from "@incident-shared/attribute";
import {
  EngineFormElement,
  EngineFormElementProps,
  isEmptyBinding,
} from "@incident-shared/engine";
import {
  addExpressionsToScope,
  makeExpressionReference,
} from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsDropdown } from "@incident-shared/engine/expressions/ExpressionsDropdown";
import { ReferenceWithExample } from "@incident-shared/engine/expressions/ExpressionsEditor";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import {
  ExpressionFormData,
  expressionToPayload,
} from "@incident-shared/engine/expressions/expressionToPayload";
import { JSONAttributeExpressionEditorModal } from "@incident-shared/engine/expressions/query/operations/JSONAttributeExpressionEditorModal";
import { FormDivider } from "@incident-shared/forms/v2/FormDivider";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  AddNewButton,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  Txt,
} from "@incident-ui";
import { JSONPreview } from "@incident-ui/JSONPreview/JSONPreview";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { Prompt } from "src/components/@shared/utils/Prompt";
import { containsExpressionReference } from "src/components/legacy/workflows/common/utils";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAllResources } from "src/hooks/useResources";
import { useQueryParams } from "src/utils/query-params";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { KeyedMutator } from "swr";
import { useDebounce } from "use-hooks";
import { v4 as uuidv4, v4 } from "uuid";

import { lookupInScopeByType } from "../../../utils/scope";
import { AlertsTable, AlertTableColumn } from "../common/AlertsTable";
import { PrioritiesCreateEditDrawer } from "../priorities/PrioritiesCreateEditDrawer";
import { AlertSourceHeader } from "./AlertSourceConnectPage";
import {
  AlertSourceFormIds,
  AlertSourceStepEnum,
} from "./AlertSourceCreateEditPage";
import { AlertSourceSplitLayout } from "./AlertSourceLayout";
import { AlertSourceSuggestion } from "./AlertSourceSuggestion";
import { stripInvalidBindings } from "./stripInvalidBindings";
import {
  ExpressionDefaults,
  useAttributeExpressions,
} from "./useAttributeExpressions";
import { useScopeWithAlertPreviews } from "./useScopeWithAlertPreviews";
export type AlertSourceAttributeFormElementProps = Omit<
  EngineFormElementProps<AlertSourceConfigureFormData>,
  "name" | "label" | "resourceType"
> & { mode: "variables_and_expressions"; scope: EngineScope };

export const AlertSourceConfigurePage = ({
  alertSourceId,
  setFormDirty,
  mode,
  setCurrentTab,
}: {
  alertSourceId: string;
  setFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
  mode: Mode;
  setCurrentTab: (tabId: string) => void;
}) => {
  const { resources, resourcesLoading, resourcesError } = useAllResources();

  const { data: existingSourceResponse, isLoading: existingSourceLoading } =
    useAPI(
      "alertsShowSourceConfig",
      {
        id: alertSourceId,
      },
      {
        refreshInterval: 10000,
        revalidateOnFocus: true,
      },
    );

  const {
    data: schemaResponse,
    mutate: mutateSchema,
    isLoading: schemaLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", undefined);

  const {
    data: catalogResourcesResponse,
    isLoading: catalogResourcesLoading,
    error: catalogResourcesError,
  } = useAPI("catalogListResources", undefined);

  const {
    data: settingsResponse,
    isLoading: settingsLoading,
    error: settingsError,
  } = useAPI("settingsShow", undefined);

  const {
    data: alertSourcesResponse,
    isLoading: alertSourcesLoading,
    error: alertSourcesError,
  } = useAPI("alertsListSources", undefined);

  const {
    data: prioritiesResponse,
    isLoading: prioritiesLoading,
    error: prioritiesError,
  } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );

  if (
    resourcesError ||
    schemaError ||
    settingsError ||
    alertSourcesError ||
    catalogResourcesError ||
    prioritiesError
  ) {
    return (
      <GenericErrorMessage
        error={
          resourcesError ||
          schemaError ||
          settingsError ||
          alertSourcesError ||
          catalogResourcesError ||
          prioritiesError
        }
      />
    );
  }

  if (
    existingSourceLoading ||
    !existingSourceResponse ||
    schemaLoading ||
    !schemaResponse ||
    alertSourcesLoading ||
    !alertSourcesResponse ||
    settingsLoading ||
    !settingsResponse ||
    catalogResourcesLoading ||
    !catalogResourcesResponse ||
    resourcesLoading ||
    !resources ||
    prioritiesLoading ||
    !prioritiesResponse
  ) {
    return <Loader className={"h-full"} />;
  }

  const existingSource = existingSourceResponse.alert_source_config;

  return (
    <AlertSourceConfigureForm
      existingSource={existingSource}
      allSources={alertSourcesResponse.alert_sources}
      schema={schemaResponse.alert_schema}
      mutateSchema={mutateSchema}
      settings={settingsResponse.settings}
      resources={resources}
      catalogResources={catalogResourcesResponse.resources}
      setFormDirty={setFormDirty}
      defaultPriority={_.find(
        prioritiesResponse.priorities,
        (p) => p.is_default,
      )}
      mode={mode}
      setCurrentTab={setCurrentTab}
    />
  );
};

export const AlertSourceConfigureText = ({
  alertSourceConfig,
}: {
  alertSourceConfig: AlertSourceConfig;
}) => {
  switch (alertSourceConfig.source_type) {
    case AlertSourceSourceTypeEnum.StatusPageViews:
      return (
        <div className={"space-y-2"}>
          <Txt grey>
            Receive alerts when views on any of your status pages are higher
            than normal.
          </Txt>
          <Txt grey>
            If the view count in a 15 minute window is more than three standard
            deviations above the mean in the past 28 days, we&rsquo;ll send you
            an alert. The alert will contain the details of the status page and
            the size of the spike.
          </Txt>
        </div>
      );
    default:
      return (
        <Txt grey>
          Extract metadata directly from the JSON payloads of your{" "}
          {alertSourceConfig.alert_source.name} alerts. This data can be used to
          provide additional context when responding to incidents from these
          alerts and can be the foundation for additional automations, such as
          workflows.
        </Txt>
      );
  }
};

export const AlertSourceConfigureForm = ({
  existingSource,
  allSources,
  schema,
  mutateSchema,
  settings,
  resources,
  catalogResources,
  setFormDirty,
  defaultPriority,
  mode,
  setCurrentTab,
}: {
  existingSource: AlertSourceConfig;
  allSources: AlertSource[];
  schema: AlertSchema;
  mutateSchema: KeyedMutator<AlertsShowSchemaResponseBody>;
  settings: Settings;
  resources: Resource[];
  catalogResources: CatalogResource[];
  setFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
  defaultPriority: Priority | undefined;
  mode: Mode;
  setCurrentTab: (tabId: string) => void;
}) => {
  const navigate = useOrgAwareNavigate();
  const { hasScope } = useIdentity();
  const showToast = useToast();

  const canEditSettings = hasScope(ScopeNameEnum.AlertSourceUpdate);

  const formMethods = useForm<AlertSourceConfigureFormData>({
    defaultValues: makeDefaultValues(existingSource, schema, defaultPriority),
  });

  const sourceName = formMethods.watch("name");
  useEffect(() => {
    setFormDirty(formMethods.formState.isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods.formState.isDirty]);

  const refetchSourceConfigs = useAPIRefetch(
    "alertsListSourceConfigs",
    undefined,
  );

  const queryParams = useQueryParams();

  const { trigger: onSubmit, genericError } = useAPIMutation(
    "alertsShowSourceConfig",
    {
      id: existingSource?.id ?? "",
    },
    async (apiClient, data: AlertSourceConfigureFormData) => {
      const newSchema = await apiClient.alertsUpdateSchema({
        updateSchemaRequestBody: {
          alert_schema: {
            attributes: Array.from(attributes),
            version: schema.version,
          },
        },
      });
      mutateSchema(newSchema);

      const newConfig = await apiClient.alertsUpdateSourceConfig({
        id: existingSource?.id ?? "",
        updateSourceConfigRequestBody: {
          name: data.name,
          template: stripInvalidBindings({
            expressions: template.expressions
              .filter((e) => getExpressionUsages(e, data).usages.length > 0)
              .map((e) => expressionToPayload(e as unknown as Expression)),
            title: data.template.title,
            description: data.template.description,
            priority: data.template.priority,
            bindings: data.template.bindings,
          }),
          routing: existingSource.routing,
        },
      });

      formMethods.reset({
        ...(newConfig.alert_source_config as unknown as AlertSourceConfigureFormData),
        attributes: newSchema.alert_schema.attributes,
      }); // to ensure isDirty=false

      refetchSourceConfigs();
      return newConfig;
    },
    {
      onSuccess: async (data: AlertsUpdateSourceConfigResponseBody) => {
        showToast({
          theme: ToastTheme.Success,
          title: `Alert source saved`,
          toastSide: ToastSideEnum.TopRight,
        });

        if (queryParams.get("from") === "alert-routes") {
          return navigate(
            `/alerts/routes/create?initial_source_id=${data.alert_source_config.id}`,
          );
        }

        return null;
      },
      setError: formMethods.setError,
    },
  );

  const [title, description] = formMethods.watch([
    "template.title",
    "template.description",
  ]);
  const emptyTitleOrDescription =
    isEmptyBinding(title) || isEmptyBinding(description);

  const [template, attributes] = formMethods.watch(["template", "attributes"]);
  const {
    data: previewAlerts,
    isLoading: previewAlertsLoading,
    error: previewAlertsError,
    mutate: previewAlertsMutate,
  } = useAPI("alertsPreviewSourceConfigAlerts", {
    id: existingSource?.id,
    previewSourceConfigAlertsRequestBody: {
      template: stripInvalidBindings({
        expressions: template.expressions.map((e) =>
          expressionToPayload(e as unknown as Expression),
        ),
        title: title,
        description: description,
        bindings: template.bindings,
      }),
      attributes: attributes,
      version: schema.version,
    },
  });

  // We JSON.stringify this so that we can trigger the use effect whenever the
  // template changes, as otherwise swr does not refire.
  const templateChecksum = useDebounce(JSON.stringify(template), 1000);
  useEffect(() => {
    if (!emptyTitleOrDescription) {
      previewAlertsMutate();
    }
  }, [emptyTitleOrDescription, templateChecksum, previewAlertsMutate]);

  const matchingAlertSource = allSources.find(
    (s) => s.source_type === existingSource.source_type,
  );

  if (!matchingAlertSource) {
    throw new Error(
      `Could not find alert source for ${existingSource.source_type}`,
    );
  }

  const {
    onAddExpression,
    onEditExpression,
    onDeleteExpression,
    showExpressionsModal,
    expressionDefaults,
    expressionsMethods,
    setExpressionDefaults,
    openExpressionsModal,
    closeExpressionsModal,
    initialExpression,
    setInitialExpression,
    removeAttribute,
  } = useAttributeExpressions({
    formMethods,
  });

  // Get a scope with the alert JSON previews patched in
  const scope = useScopeWithAlertPreviews({
    alerts: previewAlerts?.alerts ?? [],
    alertSource: matchingAlertSource,
  });
  const scopeWithExpressions = addExpressionsToScope<ReferenceWithExample>(
    scope,
    expressionsMethods.fields as unknown as ExpressionFormData[],
  );

  const onClickNewAttribute = (path: string) => {
    setExpressionDefaults({ path });
    openExpressionsModal();
  };

  const onClickAttribute = (attribute: AlertAttribute, path: string) => {
    setExpressionDefaults({ attribute, path });
    openExpressionsModal();
  };

  const onClickPriority = (path: string) => {
    // Create an expression programmatically, and then open the expression editor
    // for that expression
    const payload = lookupInScopeByType(scopeWithExpressions, "JSON");
    if (!payload) {
      return;
    }

    const expression: ExpressionFormData = {
      // id: initialExpression?.id,
      label: "Priority",
      root_reference: payload.key,
      reference: uuidv4().split("-")[0],
      operations: [
        {
          returns: {
            type: `CatalogEntry["AlertPriority"]`,
            array: false,
          },
          operation_type: ExpressionOperationOperationTypeEnum.Parse,
          parse: {
            source: path,
            returns: {
              type: `CatalogEntry["AlertPriority"]`,
              array: false,
            },
          },
        },
      ],
      returns: {
        type: `CatalogEntry["AlertPriority"]`,
        array: false,
      },
    };

    expressionsMethods.append(expression);
    formMethods.setValue(`template.priority.value`, {
      reference: makeExpressionReference(expression),
    });
  };

  const assignedAttributes = Object.entries(template.bindings).reduce(
    (lookup, [key, binding]) => {
      if (!isEmptyBinding(binding)) {
        lookup.add(key);
      }
      return lookup;
    },
    new Set<string>(),
  );

  const onClickExpressionButton = (attr: AlertAttribute) => {
    setExpressionDefaults({ attribute: attr });
    openExpressionsModal();
  };

  const formData = formMethods.watch();

  const [showPrioritiesDrawer, setShowPrioritiesDrawer] = useState(false);

  const { featureAlertSuggestions } = useFlags();
  const { data: suggestions } = useAPI(
    featureAlertSuggestions ? "alertsSuggestAttributes" : null,
    {
      id: existingSource.id,
      suggestAttributesRequestBody: {
        reset: false,
      },
    },
    {
      revalidateOnFocus: false,
    },
  );

  const revalidateSuggestions = useAPIRefetch("alertsSuggestAttributes", {
    id: existingSource.id,
    suggestAttributesRequestBody: {
      reset: true,
    },
  });

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionsMethods}
      allowAllOfACatalogType={false}
    >
      <AlertSourceSplitLayout
        step={AlertSourceStepEnum.Configure}
        mode={mode}
        setCurrentTab={setCurrentTab}
        alertSourceId={existingSource.id}
        onSuggestChanges={async () => {
          if (!featureAlertSuggestions) {
            return;
          }

          try {
            await revalidateSuggestions();
          } catch (e) {
            showToast({
              theme: ToastTheme.Error,
              title: "Failed to suggest changes",
              toastSide: ToastSideEnum.TopRight,
            });
          }
        }}
        left={
          <>
            <Prompt
              when={formMethods.formState.isDirty}
              message={
                "Your changes have not been saved. Are you sure you want to navigate away?"
              }
            />
            <FormV2
              formMethods={formMethods}
              onSubmit={onSubmit}
              warnWhenDirty
              genericError={genericError}
              id={AlertSourceFormIds[AlertSourceStepEnum.Configure]}
            >
              <AlertSourceHeader
                formMethods={formMethods}
                alertSource={existingSource}
                description={
                  <div>
                    <div className="flex-between">
                      <Heading level={1} size="2xl" className="my-2">
                        {`Configure ${sourceName}${
                          sourceName.endsWith("alerts") ? "" : " alerts"
                        }`}
                      </Heading>
                      <ExpressionsDropdown
                        scopeWithExpressions={scope}
                        getExpressionUsages={(e) => [
                          getExpressionUsages(e, formData),
                        ]}
                      />
                    </div>
                    <AlertSourceConfigureText
                      alertSourceConfig={existingSource}
                    />
                  </div>
                }
              />
              <div className={"py-4"}>
                <FormDivider />
              </div>

              <div className={"flex flex-col items-start space-y-5"}>
                <AlertSourceAttributes
                  scope={scopeWithExpressions}
                  resources={resources}
                  canEditSettings={canEditSettings}
                  attributes={attributes}
                  suggestions={suggestions?.suggestions ?? []}
                  openExpressionsModal={openExpressionsModal}
                  setExpressionDefaults={setExpressionDefaults}
                  onDeleteExpression={onDeleteExpression}
                  onClickExpressionButton={onClickExpressionButton}
                  onClickAttribute={onClickAttribute}
                  setInitialExpression={setInitialExpression}
                  setShowPrioritiesDrawer={setShowPrioritiesDrawer}
                  onRemoveAttributeExpression={(attr) => {
                    // When we remove an attribute expression, if this is a dynamic attribute, we also want to
                    // delete the attribute itself.
                    const existingAttribute = schema.attributes.find(
                      (a) => a.id === attr.id,
                    );
                    if (!existingAttribute) {
                      // Find the index of the dynamic attribute in our form attributes.
                      const index = attributes.findIndex(
                        (a) => a.id === attr.id,
                      );
                      if (index > -1) {
                        removeAttribute(index);
                      }
                    }
                  }}
                />

                {/* We don't use EngineFormElement here because we don't know what the return type will be */}
                {showExpressionsModal && (
                  <JSONAttributeExpressionEditorModal
                    allowHardcodedValue={expressionDefaults.isCreatingAttribute}
                    onClose={closeExpressionsModal}
                    onEditExpression={onEditExpression}
                    onAddExpression={onAddExpression}
                    existingExpressions={
                      expressionsMethods.fields as unknown as Expression[]
                    }
                    scope={scopeWithExpressions}
                    initialExpression={initialExpression}
                    initialPath={expressionDefaults.path}
                    existingAttribute={expressionDefaults.attribute}
                    disabledCatalogTypes={["AlertPriority"]}
                  />
                )}
              </div>
            </FormV2>
          </>
        }
        right={
          <AlertSourceCreateEditPreview
            alertSchema={schema}
            existingSource={existingSource}
            catalogResources={catalogResources}
            settings={settings}
            attributes={attributes ?? []}
            alerts={previewAlerts?.alerts ?? []}
            isLoading={previewAlertsLoading}
            error={previewAlertsError}
            onClickAttribute={onClickAttribute}
            onClickNewAttribute={onClickNewAttribute}
            onClickPriority={onClickPriority}
            formMethods={formMethods}
            assignedAttributes={assignedAttributes}
          />
        }
      />
      <AnimatePresence>
        {showPrioritiesDrawer && (
          <PrioritiesCreateEditDrawer
            onClose={() => setShowPrioritiesDrawer(false)}
          />
        )}
      </AnimatePresence>
    </ExpressionsMethodsProvider>
  );
};

export const makeDefaultValues = (
  config: AlertSourceConfig,
  alertSchema: AlertSchema,
  priority: Priority | undefined,
): AlertSourceConfigureFormData => {
  const defaults = {
    ...config,
    attributes: alertSchema.attributes,
  } as unknown as AlertSourceConfigureFormData;

  if (config.template.priority) {
    defaults.template.priority = config.template.priority;
  } else if (priority) {
    defaults.template.priority = priorityToParamBinding(priority);
  }

  return defaults;
};

// FormData is both an alert source and alert schema payload, as we let you dynamically edit your schema
// by adding attributes here
type TemplateFormData = Omit<AlertTemplatePayload, "expressions"> & {
  expressions: ExpressionFormData[];
};
type SourceConfigFormData = Omit<
  AlertsUpdateSourceConfigRequestBody,
  "template"
> & {
  template: TemplateFormData;
};
export type AlertSourceConfigureFormData = SourceConfigFormData &
  AlertSchemaPayload;

const AlertSourceAttributes = ({
  resources,
  scope,
  canEditSettings,
  attributes,
  suggestions,
  openExpressionsModal,
  onClickExpressionButton,
  onClickAttribute,
  onDeleteExpression,
  onRemoveAttributeExpression,
  setExpressionDefaults,
  setInitialExpression,
  setShowPrioritiesDrawer,
}: {
  resources: Resource[];
  scope: EngineScope;
  canEditSettings: boolean;
  attributes: AlertAttributePayload[];
  suggestions: AlertSourceSuggestedAttribute[];
  openExpressionsModal: () => void;
  onClickExpressionButton: (attr: AlertAttribute) => void;
  onClickAttribute: (attr: AlertAttribute, path: string) => void;
  onDeleteExpression: (expr: ExpressionFormData) => void;
  onRemoveAttributeExpression(attr: AlertAttributePayload): void;
  setExpressionDefaults: (defaults: ExpressionDefaults) => void;
  setInitialExpression: Dispatch<
    SetStateAction<ExpressionFormData | undefined>
  >;
  setShowPrioritiesDrawer: (show: boolean) => void;
}) => {
  const sharedFormElementProps: AlertSourceAttributeFormElementProps = {
    resources,
    scope,
    array: false,
    required: false,
    showPlaceholder: true,
    disabled: !canEditSettings,
    mode: "variables_and_expressions",
  };

  return (
    <div className={"flex flex-col space-y-5 max-w-full"}>
      <div className={"flex flex-col space-y-4"}>
        <div className={"flex flex-col space-y-2"}>
          <Txt bold>Title & description</Txt>
          <Txt grey>
            If you&rsquo;d like to adjust the title and description of your
            alerts using the alert payload, you can do so here, otherwise we
            will automatically set them for you.
          </Txt>
        </div>
        <EngineFormElement
          name={`template.title`}
          resourceType={`TemplatedText["plain_single_line"]`}
          label="Title"
          {...sharedFormElementProps}
          required
        />
        <EngineFormElement
          name={`template.description`}
          resourceType={`TemplatedText["plain_multi_line"]`}
          label="Description"
          {...sharedFormElementProps}
          required
        />
      </div>
      <div className={"py-4"}>
        <FormDivider />
      </div>
      <>
        <div className="space-y-4">
          <div className="space-y-2">
            <h3 className="text-content-primary font-semibold">Priority</h3>
            <p className="text-content-secondary">
              Set the priority for alerts coming from this alert source. You can
              use this later in escalation paths to customise how responders are
              notified.
            </p>
          </div>
          <EngineFormElement
            name={`template.priority`}
            resourceType={`CatalogEntry["AlertPriority"]`}
            label="Priority"
            {...sharedFormElementProps}
            required={false}
            isAlertElement
          />
          <Button
            analyticsTrackingId={
              "configure-alert-source--manage-alert-priorities"
            }
            theme={ButtonTheme.Naked}
            onClick={() => setShowPrioritiesDrawer(true)}
          >
            Manage alert priorities
          </Button>
        </div>
        <div className={"py-4"}>
          <FormDivider />
        </div>
      </>
      <div className={"flex flex-col space-y-4 pb-8"}>
        <div className={"flex flex-col space-y-2"}>
          <Txt bold>Custom attributes</Txt>
          <Txt grey>
            Use attributes to provide additional context on your alerts in
            incident.io, such as the affected feature, affected customer or the
            environment the alert occurred in.
          </Txt>
        </div>

        {attributes.map((a) => {
          return (
            <EngineFormElement
              key={a.id}
              name={`template.bindings.${a.id}`}
              resourceType={a.type}
              label={a.name}
              onDeleteExpression={(expr) => {
                onDeleteExpression(expr);
                onRemoveAttributeExpression(a);
              }}
              onEditExpression={(expr: ExpressionFormData) => {
                setInitialExpression(expr);
                // onClickAttribute sets the defaults for the form and shows the modal.
                onClickAttribute(
                  a as AlertAttribute,
                  (expr.operations?.length === 1 &&
                    expr.operations[0].parse?.source) ||
                    "",
                );
              }}
              onClickVariableButton={() =>
                onClickExpressionButton(a as AlertAttribute)
              }
              {...sharedFormElementProps}
              array={a.array}
              isAlertElement
            />
          );
        })}
        <div
          className={
            "flex flex-col items-start bg-surface-secondary rounded-2 p-4 space-y-4"
          }
        >
          <Txt>
            Have more information you&apos;d like to include on your alerts? You
            can add a new attribute here, and we&apos;ll create it as a global
            attribute for all your alert sources.
          </Txt>
          <Txt>
            You can also add attributes by clicking the interactive alert
            payload opposite.
          </Txt>
          <Button
            onClick={() => {
              setExpressionDefaults({ isCreatingAttribute: true });
              openExpressionsModal();
            }}
            analyticsTrackingId="add-new-attribute"
            icon={IconEnum.Add}
          >
            Add a new attribute
          </Button>
        </div>

        {suggestions
          .filter((s) => !!s.attribute_expression)
          .map((s) => {
            return (
              <AlertSourceSuggestion key={v4()} suggestion={s} scope={scope} />
            );
          })}
      </div>
    </div>
  );
};

const AlertSourceCreateEditPreview = ({
  existingSource,
  alerts,
  alertSchema,
  catalogResources,
  attributes,
  onClickAttribute,
  onClickNewAttribute,
  onClickPriority,
  isLoading,
  error,
  formMethods,
  assignedAttributes,
}: {
  isLoading: boolean;
  error?: ErrorResponse;
  attributes: AlertAttributePayload[];
  alerts: Alert[];
  settings: Settings;
  existingSource: AlertSourceConfig;
  catalogResources: CatalogResource[];
  alertSchema: AlertSchema;
  onClickAttribute: (attribute: AlertAttribute, path: string) => void;
  onClickNewAttribute: (path: string) => void;
  onClickPriority: (path: string) => void;
  formMethods: UseFormReturn<AlertSourceConfigureFormData>;
  assignedAttributes: Set<string>;
}) => {
  const [selectedAlert, setSelectedAlert] = useState<Alert>();

  useEffect(() => {
    if (!selectedAlert && alerts.length > 0) {
      setSelectedAlert(alerts[0]);
    }
  }, [alerts, selectedAlert, setSelectedAlert]);

  const visibleColumns: AlertTableColumn[] = [
    {
      type: "priority" as const,
    },
    ...attributes.map((attribute) => {
      return {
        type: "attribute" as const,
        attribute: {
          name: attribute.name,
          type: attribute.type,
          array: attribute.array,
          id: attribute.id ?? "",
        },
      };
    }),
  ];

  return (
    <div className={"hidden w-[50%] lg:flex flex-col items-stretch"}>
      <FormProvider {...formMethods}>
        <div
          className={
            "h-[50%] border-l border-stroke bg-slate-50 px-8 py-6 overflow-auto flex flex-col gap-6"
          }
        >
          <Txt xs greySmallCaps>
            Alert preview
          </Txt>
          {isLoading ? (
            <div className={"w-full h-full flex justify-center items-center"}>
              <Loader />
            </div>
          ) : alerts.length > 0 ? (
            <div className={"w-full grow"}>
              <AlertsTable
                schema={alertSchema}
                resources={catalogResources}
                alertSourceConfigs={[existingSource]}
                alerts={alerts}
                setSelectedAlert={setSelectedAlert}
                allEntriesLoaded={true}
                enableSelection={false}
                // Only show the attributes, don't show the alert source as we're in it
                visibleColumns={visibleColumns}
                wrappedInBox
              />
            </div>
          ) : (
            <AlertsPreviewEmptyState
              item={"alert"}
              theme={"light"}
              error={error}
            />
          )}
        </div>
        <div className={"h-[50%] bg-surface-invert"}>
          {isLoading ? (
            <div className={"w-full h-full flex justify-center items-center"}>
              <Loader />
            </div>
          ) : alerts.length > 0 ? (
            <JSONPreview
              containerClassName={"h-full"}
              payload={JSON.parse(selectedAlert?.payload || "{}")}
              title="Alert payload"
              getKeyPopoverContent={(path: string) => (
                <JSONPreviewPopover
                  path={path}
                  alertSchema={alertSchema}
                  catalogResources={catalogResources}
                  onClickAttribute={onClickAttribute}
                  onClickNewAttribute={onClickNewAttribute}
                  onClickPriority={onClickPriority}
                  assignedAttributes={assignedAttributes}
                />
              )}
            />
          ) : (
            <AlertsPreviewEmptyState
              item={"payload"}
              theme={"dark"}
              error={error}
            />
          )}
        </div>
      </FormProvider>
    </div>
  );
};

const JSONPreviewPopover = ({
  path,
  alertSchema,
  assignedAttributes,
  catalogResources,
  onClickAttribute,
  onClickNewAttribute,
  onClickPriority,
}: {
  path: string;
  catalogResources: CatalogResource[];
  assignedAttributes: Set<string>;
  alertSchema: AlertSchema;
  onClickAttribute: (attribute: AlertAttribute, path: string) => void;
  onClickNewAttribute: (path: string) => void;
  onClickPriority: (path: string) => void;
}) => {
  const availableAttributes = alertSchema.attributes.filter(
    (a) => !assignedAttributes.has(a.id),
  );
  const dummyCatalogResource = {
    color: CatalogResourceColorEnum.Blue,
    icon: CatalogResourceIconEnum.Box,
  };
  const alertPriorityResource = catalogResources.find(
    (r) => r.type === "AlertPriority",
  );

  return (
    <div className="flex flex-col p-3 min-w-[280px]">
      <NonPrimitiveEntry
        value={{
          literal: "AlertPriority",
          label: "Priority",
          sort_key: "AlertPriority",
        }}
        preview
        className="mb-2"
        enableTooltip={false}
        color={alertPriorityResource?.color || dummyCatalogResource.color}
        icon={alertPriorityResource?.icon || dummyCatalogResource.icon}
        onClick={() => onClickPriority(path)}
      />
      {availableAttributes.length > 0 && (
        <>
          <div className="text-content-secondary font-semibold tracking-widest uppercase text-xs mb-3">
            Existing Attributes
          </div>
          <div className="flex flex-col space-y-2">
            {availableAttributes.map((a) => {
              const catalogResource = catalogResources.find(
                (x) => `CatalogEntry["${x.type}"]` === a.type,
              );
              const entryResource = catalogResource || dummyCatalogResource;
              return (
                <NonPrimitiveEntry
                  key={a.id}
                  value={{ literal: a.name, label: a.name, sort_key: a.name }}
                  preview
                  enableTooltip={false}
                  color={entryResource.color}
                  icon={entryResource.icon}
                  onClick={() => onClickAttribute(a, path)}
                />
              );
            })}
          </div>
          <FormDivider className="my-2" />
        </>
      )}
      <AddNewButton
        title={"Add new attribute"}
        onClick={() => onClickNewAttribute(path)}
        analyticsTrackingId={""}
        className="!ml-0 !text-slate-700 hover:!text-content-primary"
      />
    </div>
  );
};

const AlertsPreviewEmptyState = ({
  item,
  theme,
  error,
}: {
  item: "alert" | "payload";
  error?: ErrorResponse;
  theme: "light" | "dark";
}) => {
  return (
    <div className={"w-full h-full flex justify-center items-center"}>
      <div className={"flex flex-col items-center max-w-sm gap-1"}>
        <Icon
          id={IconEnum.Alert}
          size={IconSize.Large}
          className={tcx({
            "text-content-tertiary": !error,
            "text-alarmalade-600": error,
          })}
        />
        <Txt
          className={tcx("font-medium", {
            "text-content-primary": theme === "light",
            "text-content-invert": theme === "dark",
          })}
        >
          {error ? "Preview unavailable" : `No ${item}s received yet`}
        </Txt>
        <div
          className={tcx("text-center text-sm", {
            "text-slate-600": theme === "light",
            "text-content-tertiary": theme === "dark",
          })}
          // This is a legit property, that makes the lines all roughly
          // equal widths. Tailwind is supporting it "soon" as text-balance.
          //
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={{ textWrap: "balance" }}
        >
          {error
            ? "We're having trouble getting your alert previews - contact us if this issue persists."
            : "Follow the steps in Connect to set up your alert source so that we can start receiving alerts."}
        </div>
      </div>
    </div>
  );
};

export const getExpressionUsages = (
  expression: Pick<ExpressionFormData, "reference">,
  formData: AlertSourceConfigureFormData,
) => {
  const containsExpressionRef = (serialisable: unknown) =>
    containsExpressionReference(expression, serialisable);

  const usedInTitle =
    formData.template.title && containsExpressionRef(formData.template.title);
  const usedInDescription =
    formData.template.description &&
    containsExpressionRef(formData.template.description);
  const usedInPriority =
    formData.template.priority &&
    containsExpressionRef(formData.template.priority);

  const attributeUsages = formData.attributes.filter((attr) => {
    const binding = attr.id && formData.template.bindings[attr.id];
    if (binding) {
      if (binding.value) {
        return containsExpressionRef(binding.value);
      } else if (binding.array_value) {
        return binding.array_value.some((v) => containsExpressionRef(v));
      }
    }
    return false;
  });

  const usages: string[] = [];
  if (usedInTitle) {
    usages.push("Title");
  }
  if (usedInDescription) {
    usages.push("Description");
  }
  if (usedInPriority) {
    usages.push("Priority");
  }

  attributeUsages.forEach((attr) => usages.push(attr.name));

  return { label: "Alert attributes", usages: usages };
};

export const priorityToParamBinding = (
  priority: Priority,
): EngineParamBinding => ({
  value: {
    label: priority.name,
    literal: priority.id,
    value: priority.id,
    sort_key: priority.rank.toString(),
  },
});
