import {
  AppliedFiltersBanner,
  AvailableFilter,
  EmptyStateAllClosed,
  EmptyStateAllFiltered,
  EmptyStateNoIncidentsYet,
  ExtendedFormFieldValue,
  FiltersContextProvider,
  useFiltersContext,
} from "@incident-shared/filters";
import { IncidentsFilterControlSearchBar } from "@incident-shared/filters/IncidentsFilterControlSearchBar";
import {
  DisplayLayout,
  useGetIncidentsWithSyntheticFilters,
  useIncidentsListContext,
} from "@incident-shared/incidents";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { GenericErrorMessage, Icon, IconEnum, IconSize } from "@incident-ui";
import { ButtonGroup } from "@incident-ui/ButtonGroup/ButtonGroup";
import { ErrorBoundary } from "@sentry/react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import {
  IncidentStatus,
  IncidentStatusCategoryEnum,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { incidentTypesEnabled } from "src/utils/incident-types";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useDebounce } from "use-hooks";

import { useStatefulQueryParamFilters } from "../../@shared/filters/useStatefulQueryParamFilters";
import { useAllStatuses } from "../incident/useIncidentCrudResources";
import { IncidentsKanbanBoard } from "./IncidentsKanban";
import { IncidentsList } from "./IncidentsList";
import { IncidentsListHeader } from "./IncidentsListHeader";
import {
  IncidentsKanbanView,
  IntroducingKanbanModal,
} from "./IntroducingKanbanModal";

const CLOSED_STATUSES = [
  IncidentStatusCategoryEnum.Closed,
  IncidentStatusCategoryEnum.Canceled,
  IncidentStatusCategoryEnum.Declined,
  IncidentStatusCategoryEnum.Merged,
];

const ACTIVE_OR_PINC_STATUSES = [
  IncidentStatusCategoryEnum.Triage,
  IncidentStatusCategoryEnum.Active,
  IncidentStatusCategoryEnum.PostIncident,
  IncidentStatusCategoryEnum.Paused,
];

const ACTIVE_STATUS_CATEGORY_FILTER: ExtendedFormFieldValue = {
  field_id: "status_category",
  field_key: "status_category",
  filter_id: "status_category",
  key: "status_categoryone_of",
  operator: "one_of",
  multiple_options_value: ACTIVE_OR_PINC_STATUSES.map((status) =>
    status.toString(),
  ),
};

function IncidentErrorMessage() {
  return (
    <GenericErrorMessage description="We weren't able to load your incidents." />
  );
}

const useIncidentsSelections = (
  allIncidentIDs: string[],
  allIncidentsLoaded: boolean,
): {
  selectAllIncidents: boolean;
  setSelectAllIncidents: (newValue: boolean) => void;
  selectedIncidentIDs: string[];
  setSelectedIncidentIDs: (newIDs: string[]) => void;
} => {
  const [selectAllIncidents, setSelectAllIncidents] = useState(false);
  const [selectedIncidentIDs, setSelectedIncidentIDs] = useState<string[]>([]);
  useEffect(() => {
    if (
      selectAllIncidents &&
      !allIncidentIDs.every((id) => selectedIncidentIDs.includes(id))
    ) {
      // as incidents load in, make sure that we select the new incidents
      // if "select all" is checked
      setSelectedIncidentIDs(allIncidentIDs);
    }
    if (
      allIncidentsLoaded &&
      selectedIncidentIDs.some((id) => !allIncidentIDs.includes(id))
    ) {
      // after all the incidents are loaded, remove all the selections that are not part of the current view
      setSelectedIncidentIDs((selectedIncidentIDs) =>
        selectedIncidentIDs.filter((id) => allIncidentIDs.includes(id)),
      );
    }
  }, [
    selectAllIncidents,
    allIncidentIDs,
    allIncidentsLoaded,
    selectedIncidentIDs,
  ]);

  return {
    selectAllIncidents,
    selectedIncidentIDs,
    setSelectAllIncidents: (newValue) => {
      setSelectedIncidentIDs(newValue ? allIncidentIDs : []);
      setSelectAllIncidents(newValue);
    },
    setSelectedIncidentIDs: (newIDs) => {
      if (newIDs.length !== allIncidentIDs.length) {
        setSelectAllIncidents(false);
      }
      setSelectedIncidentIDs(Array.from(new Set(newIDs)));
    },
  };
};

// This is an extra component to help with re-rendering shenanigans.
const IncidentsListL2 = (): React.ReactElement => {
  const { layoutToDisplay, setLayoutToDisplay } = useIncidentsListContext();
  const showingKanbanView = layoutToDisplay === DisplayLayout.Kanban;
  const { allStatuses, allStatusesLoading } = useAllStatuses();
  const { filters: rawFilters } = useFiltersContext();
  const debouncedFilters = useDebounce(rawFilters, 300);
  const { filters, hasContradictoryFilters: _ } = showingKanbanView
    ? removeClosedStatusFilters(debouncedFilters, allStatuses)
    : { filters: debouncedFilters, hasContradictoryFilters: false };

  const {
    incidents,
    error,
    someIncidentsLoaded,
    allIncidentsLoaded,
    totalNumberOfIncidents,
    refetchIncidents,
    loadMore,
    isLoading,
  } = useGetIncidentsWithSyntheticFilters({
    filters,
    // On the incident list we load more incidents using an infinite scroll, but for the kanban
    // view we want to keep loading incidents until we've got them all (we only show active ones
    // so it shouldn't be loads!)
    eagerLoad: showingKanbanView,
  });

  const { hasDismissedCTA } = useIdentity();

  const [showIntroducingKanbanModal, setShowIntroducingKanbanModal] = useState(
    !hasDismissedCTA(IncidentsKanbanView),
  );

  const { trigger: dismissBanner, isMutating: dismissing } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      await apiClient.identityDismissCta({
        dismissCtaRequestBody: {
          cta: IncidentsKanbanView,
          for_whole_organisation: false,
        },
      });
    },
  );
  const closeAndDismissModal = () => {
    setShowIntroducingKanbanModal(false);
    dismissBanner({});
  };

  const location = useLocation();
  useEffect(() => {
    // Refetch the incident list when navigating back
    // to get the latest incident statuses
    refetchIncidents();
  }, [location, refetchIncidents]);

  const analytics = useAnalytics();
  useEffect(() => {
    analytics?.track("incident-list-viewed", {
      layout: layoutToDisplay,
      filters,
    });
  }, [analytics, layoutToDisplay, filters]);

  const { settings, settingsLoading } = useSettings();

  const {
    selectAllIncidents,
    setSelectAllIncidents,
    selectedIncidentIDs,
    setSelectedIncidentIDs,
  } = useIncidentsSelections(
    incidents.map((inc) => inc.id),
    allIncidentsLoaded,
  );

  // Get the statuses we want to show on the kanban board. We specifically want all active statuses
  // for all lifecycles here. In this case active includes triage!
  const activeOrPINCStatuses = allStatuses.filter(
    (status) =>
      status.category === IncidentStatusCategoryEnum.Triage ||
      status.category === IncidentStatusCategoryEnum.Active ||
      status.category === IncidentStatusCategoryEnum.PostIncident,
  );

  return (
    <PageWrapper
      width={PageWidth.Full}
      title="Incidents"
      noPadding
      overflowY={false}
      headerNode={
        <IncidentsListHeader
          selectedIncidentIDs={selectedIncidentIDs}
          settings={settings}
        />
      }
      banner={
        <AppliedFiltersBanner
          totalNumberOfItems={totalNumberOfIncidents ?? null}
          itemsLabel={"incident"}
        />
      }
    >
      {showIntroducingKanbanModal && (
        <IntroducingKanbanModal
          onClose={closeAndDismissModal}
          loading={dismissing}
        />
      )}
      <div className={"flex flex-col h-full w-full"}>
        <div
          className={tcx(
            "flex justify-between gap-3 pt-6 pb-4 px-8",
            "max-w-[2000px] mx-auto w-full",
          )}
        >
          <IncidentsFilterControlSearchBar
            canCustomiseDisplayedInfo={!showingKanbanView}
            incidents={incidents}
            selectedIncidentIDs={selectedIncidentIDs}
            selectAllIncidents={selectAllIncidents}
            setSelectAllIncidents={setSelectAllIncidents}
            refetchIncidents={refetchIncidents}
            totalNumberOfIncidents={totalNumberOfIncidents ?? 0}
            includesTriageIncidents={incidents
              .filter((i) => selectedIncidentIDs.includes(i.id))
              .some(
                (i) => i.incident_status.category === StatusCategoryEnum.Triage,
              )}
            isAllTriageIncidents={incidents
              .filter((i) => selectedIncidentIDs.includes(i.id))
              .every(
                (i) => i.incident_status.category === StatusCategoryEnum.Triage,
              )}
          />
          <ButtonGroup
            analyticsTrackingId={"toggle-incidents-view"}
            value={layoutToDisplay}
            onChange={setLayoutToDisplay}
            buttonClassName="p-2"
            options={[
              {
                value: DisplayLayout.List,
                label: <Icon id={IconEnum.List} size={IconSize.Large} />,
              },
              {
                value: DisplayLayout.Kanban,
                label: <Icon id={IconEnum.Kanban} size={IconSize.Large} />,
              },
            ]}
          />
        </div>
        <ErrorBoundary fallback={<IncidentErrorMessage />}>
          {error ? (
            <IncidentErrorMessage />
          ) : incidents.length === 0 && !isLoading ? (
            <div className="mb-8 grow w-full max-w-[2000px] mx-auto px-8">
              {debouncedFilters.length > 0 ? (
                <EmptyStateAllFiltered isKanban={showingKanbanView} />
              ) : showingKanbanView ? (
                <EmptyStateAllClosed />
              ) : (
                <EmptyStateNoIncidentsYet />
              )}
            </div>
          ) : showingKanbanView ? (
            <IncidentsKanbanBoard
              incidents={incidents}
              isLoading={isLoading || allStatusesLoading}
              statuses={activeOrPINCStatuses}
            />
          ) : (
            <IncidentsList
              anyIncidentsLoaded={someIncidentsLoaded && !settingsLoading}
              allIncidentsLoaded={allIncidentsLoaded}
              incidents={incidents}
              incidentsIsLoading={isLoading}
              incidentsLoadMore={loadMore}
              incidentTypesEnabled={incidentTypesEnabled(settings)}
              filters={filters}
              selectedIncidentIDs={selectedIncidentIDs}
              setSelectedIncidentIDs={setSelectedIncidentIDs}
              enableSelection
            />
          )}
        </ErrorBoundary>
      </div>
    </PageWrapper>
  );
};

export const IncidentListPage = ({
  availableFilterFields,
}: {
  availableFilterFields: AvailableFilter[];
}): React.ReactElement => {
  const { getSelectedFilters, setSelectedFilters } =
    useStatefulQueryParamFilters({
      availableFilterFields,
      availableParams: ["display_info", "display_layout"],
    });

  return (
    <FiltersContextProvider
      filters={getSelectedFilters()}
      setFilters={setSelectedFilters}
      availableFilterFields={availableFilterFields}
      kind={"incident"}
    >
      <IncidentsListL2 />
    </FiltersContextProvider>
  );
};

const removeClosedStatusFilters = (
  filters: ExtendedFormFieldValue[],
  allStatuses: IncidentStatus[],
): {
  filters: ExtendedFormFieldValue[];
  hasContradictoryFilters: boolean;
} => {
  const closedStatuses = allStatuses.filter((status) =>
    CLOSED_STATUSES.includes(status.category),
  );

  let statusCategoryFilter = filters.find(
    (f) => f.field_key === "status_category",
  );
  let statusFilter = filters.find((f) => f.field_key === "status");
  const otherFilters = filters.filter(
    (f) => !(f.field_key === "status" || f.field_key === "status_category"),
  );

  if (statusCategoryFilter) {
    if (statusCategoryFilter.operator === "one_of") {
      // If operator is 'one_of' then filter out all of the closed statuses
      statusCategoryFilter = {
        ...statusCategoryFilter,
        multiple_options_value:
          statusCategoryFilter.multiple_options_value?.filter(
            (val) =>
              !CLOSED_STATUSES.includes(val as IncidentStatusCategoryEnum),
          ),
      };
    } else {
      // Otherwise we want to add all of the closed statuses, so that we don't
      // include them
      const options = _.uniq([
        ...(statusCategoryFilter.multiple_options_value ?? []), // We do this so we don't modify the filters banner
        ...CLOSED_STATUSES.map((status) => status.toString()),
      ]);

      statusCategoryFilter = {
        ...statusCategoryFilter,
        multiple_options_value: options,
      };
    }
  }

  if (statusFilter) {
    if (statusFilter.operator === "one_of") {
      // If operator is 'one_of' then filter out all of the closed statuses
      statusFilter = {
        ...statusFilter,
        multiple_options_value: statusFilter.multiple_options_value?.filter(
          (id) => !closedStatuses.map((status) => status.id).includes(id),
        ),
      };
    } else {
      // Otherwise we want to add all of the closed statuses, so that we don't
      // include them
      const options = _.uniq([
        ...(statusFilter.multiple_options_value ?? []), // We do this so we don't modify the filters banner
        ...closedStatuses.map((status) => status.id),
      ]);
      for (const status of closedStatuses) {
        if (!options.includes(status.id)) {
          options.push(status.id);
        }
      }
      statusFilter = {
        ...statusFilter,
        multiple_options_value: options,
      };
    }
  }

  // If one of the status filters is empty, that means that they contained only
  // closed filters and therefore is contradictory with the Kanban view.
  // Just include all of the active filters again.
  if (
    statusCategoryFilter?.multiple_options_value?.length === 0 ||
    statusFilter?.multiple_options_value?.length === 0
  ) {
    otherFilters.push(ACTIVE_STATUS_CATEGORY_FILTER);
    return {
      filters: otherFilters,
      hasContradictoryFilters: true,
    };
  }

  // We want to keep the previous status filters if they aren't contradictory
  // or set the status category filter to contain all of the active filters if
  // neither exist
  if (statusFilter === undefined && statusCategoryFilter === undefined) {
    otherFilters.push(ACTIVE_STATUS_CATEGORY_FILTER);
  }
  if (statusCategoryFilter !== undefined) {
    otherFilters.push(statusCategoryFilter);
  }
  if (statusFilter !== undefined) {
    otherFilters.push(statusFilter);
  }

  return { filters: otherFilters, hasContradictoryFilters: false };
};
