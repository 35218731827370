import { TrialStatusPlanNameEnum } from "@incident-io/api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIdentity } from "src/contexts/IdentityContext";
import { isDevelopment } from "src/utils/environment";

type SampleDataResponse = {
  isSampleData: boolean;
  excludeCustomFields: boolean;
  prefix: string;
};

// useIsSampleData should follow the same logic as GetOverrideDetails in insights/override.tsx
export const useIsSampleData = (): SampleDataResponse => {
  const { identity } = useIdentity();

  const { overrideTrialAccountSampleInsightsData } = useFlags();

  // If we're in development, we'll never be able to show sample data because the demo/trial data
  // exists in the prod database.
  if (isDevelopment()) {
    return { isSampleData: false, excludeCustomFields: false, prefix: "" };
  }

  // If it's a demo account, return true.
  if (identity?.organisation_is_demo) {
    return { isSampleData: true, excludeCustomFields: false, prefix: "DEMO-" };
  }

  // If it's a trial account, and the override flag is not set, return true.
  if (
    identity?.trial_status.plan_name === TrialStatusPlanNameEnum.Trial &&
    !overrideTrialAccountSampleInsightsData
  ) {
    return { isSampleData: true, excludeCustomFields: true, prefix: "TRIAL-" };
  }

  return { isSampleData: false, excludeCustomFields: false, prefix: "" };
};
