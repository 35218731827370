import { Heading, Txt } from "@incident-ui";
import React from "react";

export const AlertSubPageHeading = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: React.ReactNode;
}): React.ReactElement => {
  return (
    <div className="flex flex-col mr-4">
      <Heading
        level={1}
        size="2xl"
        className="mb-2 flex-grow"
        data-testid="subpage-heading"
      >
        {title}
      </Heading>
      {subtitle ? <Txt grey>{subtitle}</Txt> : null}
    </div>
  );
};
