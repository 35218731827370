import { EngineReferenceBadge } from "@incident-shared/engine";
import React from "react";
import { EngineScope, Reference } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import {
  MenuEntry,
  ReferenceSelectorPopover,
} from "../../../@shared/engine/ReferenceSelectorPopover/ReferenceSelectorPopover";
import { referenceSource } from "../../../@shared/engine/referenceSource";

export const WorkflowsLoopVariableSelector = ({
  label,
  referenceKey,
  scope,
  onSelectReference,
  isSelectable,
  disabled,
}: {
  label: string;
  referenceKey: string;
  scope: EngineScope;
  onSelectReference: (entry: Reference) => void;
  isSelectable: (entry: MenuEntry) => boolean;
  disabled?: boolean;
}): React.ReactElement => {
  const source = referenceSource(referenceKey);
  return (
    <div className="flex items-center space-x-2 min-w-0 grow">
      <ReferenceSelectorPopover
        scope={scope}
        allowExpressions={true}
        isSelectable={isSelectable}
        onSelectReference={onSelectReference}
        renderTriggerButton={({ onClick }) => (
          <button
            onClick={onClick}
            className="group flex space-x-1 items-center min-w-0 grow text-sm"
            disabled={disabled}
          >
            <EngineReferenceBadge
              className={tcx(
                "grow",
                "text-sm rounded-2 shadow-sm py-2",
                // We want to avoid 'grey-on-grey'
                source === "reference" && "bg-white",
              )}
              label={label}
              editable={!disabled}
              referenceSource={source}
            />
          </button>
        )}
      />
    </div>
  );
};
