import { Expression, IncidentsBuildScopeContextEnum } from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { GenericErrorMessage, Loader, ModalFooter } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { CustomFieldFieldModeEnum as FieldModeEnum } from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";
import { useAPI } from "src/utils/swr";

import { useIncidentScope } from "../../../../hooks/useIncidentScope";
import { CustomFieldFormData } from "./CustomFieldCreateEditDrawer";
import { DerivedCustomFieldExpressionEditSection } from "./DerivedCustomFieldExpressionEditSection";

export const CustomFieldCreateModal = ({
  setExpression,
  onClose,
}: {
  setExpression: (expression: Expression | undefined) => void;
  onClose: () => void;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const formMethods = useForm<CustomFieldFormData>({
    defaultValues: {
      field_mode: FieldModeEnum.Manual,
    },
  });
  const { watch } = formMethods;

  const [engineExpression, fieldMode] = watch([
    "engine_expression",
    "field_mode",
  ]);

  const onSubmit = () => {
    setExpression(engineExpression);
    onClose();
    navigate(`/settings/custom-fields/create`);
  };

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.ApplicableFields,
  );

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  const {
    data: { catalog_types: catalogTypes },
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  if (scopeError || resourcesError) {
    return <GenericErrorMessage error={scopeError || resourcesError} />;
  }

  if (scopeLoading || resourcesLoading) {
    return <Loader />;
  }

  return (
    <FormModalV2
      formMethods={formMethods}
      title="What kind of custom field should we create?"
      analyticsTrackingId="create-custom-field-modal"
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          onClose={onClose}
          confirmButtonText="Continue"
          disabled={
            fieldMode === FieldModeEnum.SensibleDefault && !engineExpression
          }
        />
      }
    >
      <div className="flex flex-row">
        <RadioButtonGroupV2
          className="grow"
          name={"field_mode"}
          label={""}
          srLabel={""}
          formMethods={formMethods}
          boxed
          options={[
            {
              label: "Standard",
              value: FieldModeEnum.Manual,
              description:
                "Values that are set manually during or after incidents.",
            },
            {
              label: "Automated",
              value: FieldModeEnum.SensibleDefault,
              description:
                "Values that are set automatically, based on the value of another field.",
              renderWhenSelectedNode: () => (
                <DerivedCustomFieldExpressionEditSection
                  formMethods={formMethods}
                  name="engine_expression"
                  scope={scope}
                  disabled={false}
                  resources={resources}
                  catalogTypes={catalogTypes}
                  resultArray={false}
                />
              ),
            },
          ]}
        />
      </div>
    </FormModalV2>
  );
};
