import {
  FiltersContextProvider,
  useFiltersContext,
  useInitialiseFilters,
} from "@incident-shared/filters";
import {
  isValidForEnum,
  useStatefulQueryParamFilters,
} from "@incident-shared/filters/useStatefulQueryParamFilters";
import { ReactElement } from "react";
import {
  IncidentFilterFieldsListContextEnum,
  IncidentsListSortByEnum,
  PolicyPolicyTypeEnum,
} from "src/contexts/ClientContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";

import { FollowUpParam, FollowUpParams } from "./FollowUpsPage";

export const usePoliciesWithViolations = () => {
  const {
    data: { policies },
    isLoading: policiesLoading,
    error: policiesError,
  } = useAPI("policiesList", undefined, { fallbackData: { policies: [] } });
  const followUpPolicyIDs = policies
    .filter((policy) => {
      return policy.policy_type === PolicyPolicyTypeEnum.FollowUp;
    })
    .map((policy) => policy.id);

  const {
    data: { policy_violations },
    isLoading: violationsLoading,
    error: violationsError,
  } = useAPI(
    followUpPolicyIDs.length > 0 ? "policiesListViolations" : null,
    {
      policyIds: followUpPolicyIDs,
    },
    { fallbackData: { policy_violations: [], incidents: [] } },
  );

  return {
    data: {
      policies,
      policy_violations,
    },
    isLoading: policiesLoading || violationsLoading,
    error: policiesError || violationsError,
  };
};

export const useSortBy = () => {
  const { availableFilterFields } = useFiltersContext();

  const { useQueryParam } = useStatefulQueryParamFilters<FollowUpParams>({
    availableFilterFields,
    availableParams: Object.values(FollowUpParam),
  });

  const [sortBy, setSortBy] = useQueryParam({
    param: FollowUpParam.SortBy,
    isValid: isValidForEnum(Object.values(IncidentsListSortByEnum)),
    defaultValue:
      IncidentsListSortByEnum.NewestFirst ||
      IncidentsListSortByEnum.NewestFirst,
  });

  return { sortBy, setSortBy };
};

export const FollowUpsFiltersProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const urlParams = useQueryParams();

  const { availableIncidentFilterFields: availableFilterFields } =
    useInitialiseFilters(
      urlParams.toString(),
      IncidentFilterFieldsListContextEnum.FollowUpsAndIncidents,
    );

  const { getSelectedFilters, setSelectedFilters } =
    useStatefulQueryParamFilters<FollowUpParams>({
      availableFilterFields,
      availableParams: Object.values(FollowUpParam),
    });

  const filters = getSelectedFilters();

  return (
    <FiltersContextProvider
      filters={filters}
      setFilters={setSelectedFilters}
      availableFilterFields={availableFilterFields}
      kind={"incident"}
    >
      {children}
    </FiltersContextProvider>
  );
};
