import "lodash";

import { createNullTransformer } from "@aeaton/prosemirror-transformers";
import {
  ChangeHandler,
  Editor,
  EditorProvider,
} from "@aeaton/react-prosemirror";
import { useEditorView } from "@aeaton/react-prosemirror/EditorProvider";
import { Button, ButtonSize, ButtonTheme, IconEnum } from "@incident-ui";
import { isEqual } from "lodash";
import { Node } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { createContext, useContext, useEffect, useState } from "react";
import {
  EngineScope,
  Reference,
  Resource,
  TextNode,
} from "src/contexts/ClientContext";
import { EnrichedScope, getEmptyScope } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";

import { getVariableScope } from "../../../engine";
import { ReferenceSelectorPopover } from "../../../engine";
import { Format, toolbarFor } from "./formats";
import { placeholderPlugin, plugins, schema } from "./schema";
import { TemplatedTextDisplayStyle } from "./TemplatedTextDisplay";
import styles from "./TemplatedTextEditor.module.scss";
import { Toolbar, toolbarButtonProps } from "./Toolbar";
import { insertVar, replaceLabels } from "./variable";
import { VariablePopover } from "./VariablePopover";

export type InterpolatedVariable = {
  label: string;
  key: string;
  icon?: IconEnum;
};

export type InterpolatedRef = Reference & {
  can_be_interpolated: boolean;
};

export type VariableProps = {
  scope?: EngineScope;
  resources?: Resource[];
  // When includeVariables is true, we'll show the 'Use variable' button
  includeVariables: boolean;
  // When includeExpressions is true, we'll show the 'Add expression' option when using a variable.
  // Note that this means that includeVariables must also be true.
  includeExpressions: boolean;
};

export type TemplatedTextEditorProps = {
  id: string;
  placeholder?: string;
  value?: TextNode;
  onChange: (value?: TextNode) => void;
  onValueChange?: (value?: TextNode) => void;
  format: Format;
  style?: TemplatedTextDisplayStyle;
  className?: string;
  autoFocus?: boolean;
  multiLine?: boolean;
  disabled?: boolean;
} & VariableProps;

const nullTransformer = createNullTransformer();

export const plaintextDoc = (text: string): TextNode => {
  return {
    type: "doc",
    content: [
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: text,
          },
        ],
      },
    ],
  };
};

const EditorVarsContext = createContext<VariableProps>({
  scope: getEmptyScope(),
  resources: [],
  includeExpressions: false,
  includeVariables: false,
});

export const useEditorVariables = () => useContext(EditorVarsContext);

/* eslint-disable react/prop-types */
export const TemplatedTextEditor = ({
  id,
  value,
  placeholder,
  format,
  onChange,
  scope = getEmptyScope(),
  resources = [],
  includeVariables,
  includeExpressions,
  className,
  style = TemplatedTextDisplayStyle.Full,
  autoFocus = false,
  multiLine = false,
  disabled = false,
}: TemplatedTextEditorProps): React.ReactElement => {
  let variableScope: EnrichedScope<InterpolatedRef> =
    getEmptyScope<InterpolatedRef>();
  if (includeVariables) {
    variableScope = getVariableScope(scope, resources);
  }

  // when we use this inside react-hook-forms the form will update the
  // initialValue prop with the result of the onChange callback, meaning extra
  // churn as we re-initialise the doc on every render. Instead we create a
  // state instance that we never mutate, so we always hold on to the original
  // document. ProseMirror is handling state changes outside of react anyway, so
  // there's no need to update this state.
  const [doc] = useState(() => {
    if (value) {
      return valueToDoc({
        value,
        includeVariables,
        variableScope: variableScope,
      });
    } else {
      return EditorState.create({ schema }).doc;
    }
  });

  const formatConfig = toolbarFor(format);
  formatConfig.plugins.forEach((p) => plugins.push(p));

  let toolbar = formatConfig.groups;
  if (includeVariables) {
    toolbar = toolbar.concat({
      id: "vars",
      render: ({ view, state }: { view: EditorView; state: EditorState }) => {
        return (
          <ReferenceSelectorPopover
            scope={scope}
            isSelectable={(ref) => ref.resource.can_be_interpolated}
            onSelectReference={(ref) =>
              insertVar(ref.key, ref.label)(state, view.dispatch)
            }
            filterNonInterpolatable={true}
            allowExpressions={includeExpressions}
            pauseOnAddExpression
            renderTriggerButton={({ onClick }) => (
              <Button
                theme={ButtonTheme.Ghost}
                size={ButtonSize.Small}
                analyticsTrackingId={null}
                onClick={onClick}
                icon={IconEnum.Bolt}
                disabled={disabled}
                title="Insert variable"
                {...toolbarButtonProps(false, false)}
              >
                Insert variable
              </Button>
            )}
          />
        );
      },
    });
  }

  let ourPlugins = plugins;
  if (placeholder) {
    ourPlugins = ourPlugins.concat(placeholderPlugin(placeholder));
  }

  const editorConfig = {
    plugins: ourPlugins,
    schema,
    editorProps: { editable: () => !disabled },
  };

  return (
    <EditorVarsContext.Provider
      key={id}
      value={{ scope, resources, includeExpressions, includeVariables }}
    >
      <div
        key={id}
        className={tcx(
          styles.templatedTextEditorContent,
          {
            "bg-white": style !== TemplatedTextDisplayStyle.Naked,
            "border border-stroke rounded-[6px] box-border":
              style === TemplatedTextDisplayStyle.Full,
            compact: style === TemplatedTextDisplayStyle.Compact,
            "bg-surface-secondary":
              disabled && style !== TemplatedTextDisplayStyle.Naked,
            "!text-slate-600": disabled,
          },
          multiLine && styles.multiLine,
          "break-words",
          "leading-5",
          "flex flex-col overflow-hidden",
          className,
        )}
      >
        <EditorProvider doc={doc} {...editorConfig}>
          <VariablePopover scope={scope} resources={resources} />
          <div
            className={tcx({
              // The naked toolbar needs more of a gap between it and the editor
              "flex flex-col gap-4": style === TemplatedTextDisplayStyle.Naked,
            })}
          >
            {toolbar.length > 0 && (
              <Toolbar
                toolbar={toolbar}
                disabled={disabled}
                style={style}
                className={tcx("flex", {
                  "bg-white border-b border-stroke":
                    style === TemplatedTextDisplayStyle.Full,
                })}
              />
            )}

            <div
              className={tcx({
                "p-2": style !== TemplatedTextDisplayStyle.Naked,
              })}
            >
              <Editor autoFocus={autoFocus} />
            </div>
          </div>
          <EditorValueSetterHack
            value={value}
            includeVariables={includeVariables}
            variableScope={variableScope}
            onChange={onChange}
          />
        </EditorProvider>
      </div>
    </EditorVarsContext.Provider>
  );
};

// EditorValueSetterHack is a quite mad hack. It's what enables us to set the
// state of the text editor from outside the editor (i.e. via a react-hook-form
// callback like setValue or reset). This is useful for us if we want to e.g.
// reset a field to a default value based on another field changing.
//
// There are two different ways the editor's state can change:
// 1. User input --> ProseMirror --> TemplatedTextEditor --> onChange --> react-hook-form state
// 2. setValue/reset --> react-hook-form state --> TemplatedTextEditor --> ProseMirror --> editor
//
// The first one works as expected, but number 2 doesn't work 'out of the box'
// as ProseMirror doesn't allow you to pass in a `value` straight into the
// component. It also doesn't render an <input/> in the browser, meaning there's
// no input `ref` for react-hook-form to mutate (which is how it would normally
// set the value).
//
// To make 2 work, we use a useEffect to check if the 'value' (which is passed
// in by the caller like react-hook-form) is different to the current state of
// the editor. If it is, we create a new EditorState with the new value, and
// then set the editor's state to that.
//
// We are incredibly reliant on the isEqual check to avoid infinitely
// re-rendering the editor. So far, we've found that it works as expected, but
// it's possible we'll eventually hit some edge cases here.
const EditorValueSetterHack = ({
  value,
  includeVariables,
  variableScope,
  onChange,
}: {
  value: TextNode | undefined;
  includeVariables: boolean;
  variableScope: EnrichedScope<InterpolatedRef>;
  onChange;
}) => {
  const view = useEditorView();

  // This state is used to switch between programmatically updating the editor ("programmatically")
  // and user input ("user"). We use this to avoid infinite loops and unsure useEffect triggers sufficiently
  // const [isUpdatingState, setIsUpdatingState] = useState(false);
  const [controlType, setControlType] = useState<"user" | "programmatic">(
    "user",
  );

  // This hook is responsible for handling the case where the value is modified programmatically
  // i.e.: setValue or reset from react-hook-form
  useEffect(() => {
    const newDoc = value
      ? valueToDoc({ value, includeVariables, variableScope })
      : EditorState.create({ schema }).doc;

    if (isEqual(newDoc, view.state.doc)) {
      setControlType("user");
      return undefined;
    } else if (controlType === "programmatic") {
      // don't proceed to update the editor if we are in the midst of doing it
      // we can't early return higher up if not isUpdatingState will be always true
      return undefined;
    }

    setControlType("programmatic");
    view.dispatch(
      view.state.tr.replaceWith(0, view.state.doc.content.size, newDoc.content),
    );

    return () => {
      setControlType("user");
    };
    // This infinite loops if we add all the dependencies here!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // By unmounting the change handler, we can ignore the change events that are due to us setting the value
  // we then remount it to capture any user input
  return (
    <ChangeHandler // responsible for handling user input
      handleChange={(v) => {
        // if the doc is empty, send the empty string so forms can tell
        // there's no content, otherwise they get a non-empty doc structure
        // with no content
        const emptyDoc = EditorState.create({ schema }).doc;
        if (v.content.findDiffStart(emptyDoc.content) == null) {
          if (value !== undefined) {
            onChange(undefined);
          }
        } else {
          onChange(v.toJSON());
        }
      }}
      transformer={nullTransformer}
    />
  );
};

const valueToDoc = ({
  value,
  includeVariables,
  variableScope,
}: {
  value: TextNode | undefined;
  includeVariables: boolean;
  variableScope: EnrichedScope<InterpolatedRef>;
}): Node => {
  let newDoc = schema.nodeFromJSON(value);

  if (includeVariables) {
    // make sure any serialised var labels are correct;
    newDoc = replaceLabels(newDoc, variableScope);
  }
  return newDoc;
};
