import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";

export const AlertSourceCloudflareSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Go to the <strong>Notifications</strong> section in Cloudflare
      </p>
      <p>
        Select <strong>Destinations</strong> and click <strong>Create</strong>
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Then copy and paste in the <strong>name</strong>,{" "}
            <strong>URL</strong> and <strong>secret</strong> below
          </>
        }
        blocks={[
          {
            title: "Name",
            code: `${alertSourceConfig.name} (incident.io)`,
          },
          {
            title: "URL",
            code: `${publicApiUrl()}/v2/alert_events/cloudflare/${
              alertSourceConfig.id
            }`,
          },
          {
            title: "Secret",
            code: alertSourceConfig.secret_token ?? "",
          },
        ]}
      />
      <p>
        Once you&apos;ve saved your &apos;destination&apos;, you can select it
        when creating or editing a &apos;notification&apos; in Cloudflare. After
        saving the notification, you can test it by clicking the{" "}
        <strong>Test</strong> button back in the Notifications list.
      </p>
    </SetupInfoNumberedList>
  );
};
