import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";

export const AlertSourceGoogleCloudSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        In the navigation panel of the Google Cloud console, select{" "}
        <strong>Monitoring</strong>, and then select <strong>Alerting</strong>.
      </p>
      <p>
        Click <strong>Edit notification channels</strong> and in the{" "}
        <strong>Webhook</strong> section click <strong>Add new</strong>.
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Then copy and paste in the <strong>URL</strong> and{" "}
            <strong>Name</strong> below
          </>
        }
        blocks={[
          {
            title: "URL",
            code: `${publicApiUrl()}/v2/alert_events/google_cloud/${
              alertSourceConfig.id
            }?token=${alertSourceConfig.secret_token ?? ""}`,
          },
          {
            title: "Name",
            code: `incident.io (${alertSourceConfig.name})`,
          },
        ]}
      />
      <p>
        You can now click <strong>Test connection</strong> to send a test alert
        to incident.io. When you create an alerting policy, select{" "}
        <strong>Webhook</strong> in the <strong>Notifications</strong> section
        and choose your webhook configuration.
        <br />
        <br />
        Please note, we do not currently support Google Cloud&apos;s{" "}
        <strong>Error Reporting</strong> for these alerts.
      </p>
    </SetupInfoNumberedList>
  );
};
