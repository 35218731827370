import { Button, ButtonTheme, IconEnum, IconSize, Txt } from "@incident-ui";
import { SlackAppIcon } from "@incident-ui/SlackPreviews/SlackPreviews";
import React from "react";
import { useNavigate } from "react-router";

export const SlackFormBorder = () => {
  // This is a hack that draws a nice border around the components in the form
  return (
    <div className="absolute flex items-center pl-[43px] justify-center w-full h-full z-10 pointer-events-none">
      <div className="grid grid-cols-3 w-full h-full">
        <div className="col-span-2 w-full h-full rounded-[6px] shadow-lg border border-stroke"></div>
      </div>
    </div>
  );
};

const HeaderBackButton = ({
  backHref,
}: {
  backHref?: string;
}): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <Button
      analyticsTrackingId="go-back"
      href={backHref || ""}
      onClick={(e) => {
        if (!backHref) {
          e.preventDefault();
          navigate(-1);
        }
      }}
      theme={ButtonTheme.Naked}
      icon={IconEnum.ChevronLeft}
      iconProps={{
        size: IconSize.Large,
      }}
      title="Go back"
    />
  );
};

export const SlackFormHeaderRow = ({
  title,
  backHref,
  topRightNode,
}: {
  title: string;
  backHref: string;
  topRightNode?: React.ReactNode;
}) => {
  return (
    <div className="flex !border-0">
      <div className="w-[43px] pl-4 flex items-center">
        <HeaderBackButton backHref={backHref} />
      </div>
      <div className="grid grid-cols-3 w-full !border-0">
        <div className="col-span-2 rounded-t-lg p-4 flex items-center gap-2">
          <SlackAppIcon />
          <Txt bold className="font['lato'] text-lg">
            {title}
          </Txt>
        </div>
        <div className="col-span-1 flex justify-end items-center">
          {topRightNode}
        </div>
      </div>
    </div>
  );
};
