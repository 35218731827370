import {
  EmptyStateAllFiltered,
  ExtendedFormFieldValue,
  filtersToListParams,
} from "@incident-shared/filters";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  Heading,
  IconEnum,
  LoadingBar,
  StaticSingleSelect,
} from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import {
  Identity,
  IncidentsListRequest,
  IncidentsListSortByEnum,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  IncidentType,
  SlackTeamConfig,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";
import { v4 as uuid } from "uuid";

import { useAllStatuses } from "../incident/useIncidentCrudResources";
import { IncidentsKanbanBoard } from "../incidents-list/IncidentsKanban";
import {
  FilterByWorkspaceDropdown,
  PresetOption,
  SingleWorkspaceOption,
} from "./FilterByWorkspaceDropdown";

const MAX_CARDS_PER_COLUMN = 4; // We'll show up to 4 cards per column
const MAX_ACTIVE_INCIDENTS = 99; // If the org has more than 99 active incidents, we'll call this out instead

const useSlackTeamConfigs = () => {
  const { data } = useAPI("slackTeamConfigsList", undefined);
  return data?.slack_team_configs;
};

export const HomeKanban = () => {
  const { identity } = useIdentity();
  const slackTeamConfigs = useSlackTeamConfigs();

  const { data: incidentTypesData } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });

  const { allStatuses } = useAllStatuses();

  if (!identity || !slackTeamConfigs || !incidentTypesData || !allStatuses) {
    return <LoadingBar className="h-24" />;
  }

  return (
    <HomeKanbanInner
      identity={identity}
      slackTeamConfigs={slackTeamConfigs}
      incidentTypes={incidentTypesData.incident_types}
      allStatuses={allStatuses}
    />
  );
};

const getSlackTeamIDsFromOption = (
  identity: Identity,
  slackTeamConfigs: SlackTeamConfig[],
  selectedOption: PresetOption | SingleWorkspaceOption,
): string[] | undefined => {
  switch (selectedOption.type) {
    case "all_workspaces":
      return [];
    case "my_workspaces":
      return getMySlackTeamIDs(identity, slackTeamConfigs);
    case "single_workspace":
      return [selectedOption.slack_team_id];
    default:
      throw new Error(
        `meant to be unreachable, unknown option ${selectedOption}`,
      );
  }
};

const getMySlackTeamIDs = (
  identity: Identity,
  slackTeamConfigs: SlackTeamConfig[],
) =>
  slackTeamConfigs
    .filter(
      (config) => identity.slack_info?.team_ids?.includes(config.slack_team_id),
    )
    .map((config) => config.slack_team_id);

const HomeKanbanInner = ({
  identity,
  slackTeamConfigs,
  incidentTypes,
  allStatuses,
}: {
  identity: Identity;
  slackTeamConfigs: SlackTeamConfig[];
  incidentTypes: IncidentType[];
  allStatuses: IncidentStatus[];
}) => {
  const mySlackTeamIDs = getMySlackTeamIDs(identity, slackTeamConfigs);

  const pollingIntervalInSeconds = identity?.organisation_is_demo ? 5 : 10;

  const activeStatuses = allStatuses.filter(
    (status) =>
      status.category === IncidentStatusCategoryEnum.Triage ||
      status.category === IncidentStatusCategoryEnum.Active,
  );

  const [selectedSlackTeam, setSelectedSlackTeam] = useState<
    PresetOption | SingleWorkspaceOption
  >(() => {
    return {
      type:
        mySlackTeamIDs && mySlackTeamIDs.length >= 1
          ? "my_workspaces"
          : "all_workspaces",
    };
  });

  const [selectedIncidentTypeId, setSelectedIncidentTypeId] = useState<
    string | null
  >(null);

  // Annoyingly we have to build the filters up by hand. When we redesign the homepage, we should
  // grab theme from the filter context like we do elsewhere (which could allow saved views)!
  let filters: ExtendedFormFieldValue[] = [
    {
      key: uuid(),
      field_key: "status_category",
      field_id: "status_category",
      filter_id: "status_category",
      operator: "one_of",
      multiple_options_value: ["active", "triage"],
    },
  ];

  if (selectedIncidentTypeId != null) {
    filters = [
      ...filters,
      {
        key: uuid(),
        field_key: "incident_type",
        field_id: "incident_type",
        filter_id: "incident_type",
        operator: "one_of",
        multiple_options_value: [selectedIncidentTypeId],
      },
    ];
  }

  const slackTeamIDs = getSlackTeamIDsFromOption(
    identity,
    slackTeamConfigs,
    selectedSlackTeam,
  );
  if (slackTeamIDs && slackTeamIDs.length > 0) {
    filters = [
      ...filters,
      {
        key: uuid(),
        field_key: "slack_team_id",
        field_id: "slack_team_id",
        filter_id: "slack_team_id",
        operator: "one_of",
        multiple_options_value: slackTeamIDs,
      },
    ];
  }

  const hasAppliedFilter =
    selectedIncidentTypeId || (slackTeamIDs && slackTeamIDs.length > 0);

  // We can't use useGetIncidents like other components do, because it involves a useAPIInfinite,
  // which doesn't support a refresh interval. A refresh interval is more important to us here
  // because we want the homepage to get updated quickly when incidents are updated. So instead,
  // we'll call out that there are a lot of incidents and that they should use the incident list
  // page instead.
  const queryFilters: IncidentsListRequest = filtersToListParams(filters);

  const baseRequest = {
    ...queryFilters,
    ...{
      pageSize: MAX_ACTIVE_INCIDENTS,
      sortBy: IncidentsListSortByEnum.NewestFirst,
    },
  };

  const {
    data: resp,
    isLoading,
    error,
  } = useAPI("incidentsList", baseRequest, {
    refreshInterval: pollingIntervalInSeconds * 1000,
  });
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const incidents = resp?.incidents;

  const incidentTypeOptions = incidentTypes.map((type) => {
    return { label: type.name, value: type.id };
  });

  const showingMaxIncidents = incidents?.length === MAX_ACTIVE_INCIDENTS;

  return (
    <div className="space-y-3 rounded-xl">
      <div className="flex justify-between">
        <div className="flex space-x-2 align-bottom">
          <Heading
            className="px-2 sm:px-0 flex flex-center-y space-x-1.5"
            level={2}
            size="medium"
          >
            <span>Active incidents</span>
          </Heading>
          {incidents && incidents?.length > 0 && (
            <Badge
              theme={
                showingMaxIncidents ? BadgeTheme.Warning : BadgeTheme.Secondary
              }
              className="px-1"
            >
              {showingMaxIncidents
                ? incidents?.length + "+"
                : incidents?.length}
            </Badge>
          )}
          <div className="flex gap-2 md:gap-4 text-sm items-center flex-wrap">
            <Button
              icon={IconEnum.ExternalLink}
              href={`/incidents?display_layout=kanban&status_category[one_of]=active&status_category[one_of]=triage`}
              openInNewTab={false}
              theme={ButtonTheme.Naked}
              className="text-content-tertiary transition"
              analyticsTrackingId="internal-homepage-view-all-incidents"
              title="View all incidents"
            >
              {showingMaxIncidents && "View all"}
            </Button>
          </div>
        </div>
        <div className="flex space-x-3">
          {/* Prefer a workspace filter, then if you aren't on grid but have types, show a type filter */}
          {slackTeamConfigs && slackTeamConfigs.length > 1 ? (
            <FilterByWorkspaceDropdown
              mySlackTeamIDs={mySlackTeamIDs}
              disabled={isLoading}
              selectedOption={selectedSlackTeam}
              slackTeamConfigs={slackTeamConfigs}
              onSelectSlackTeam={setSelectedSlackTeam}
            />
          ) : (
            <>
              {incidentTypeOptions && incidentTypeOptions.length > 1 && (
                <StaticSingleSelect
                  placeholder="Filter by incident type"
                  isClearable={true}
                  options={incidentTypeOptions}
                  value={selectedIncidentTypeId}
                  className="max-w-[200px] hidden lg:block"
                  onChange={(val) => setSelectedIncidentTypeId(val as string)}
                />
              )}
            </>
          )}
        </div>
      </div>

      <AnimatePresence presenceAffectsLayout>
        {isLoading || incidents === undefined ? (
          <LoadingBar className="h-24" />
        ) : incidents && incidents.length === 0 && !hasAppliedFilter ? (
          <EmptyState
            icon={IconEnum.Incident}
            title="No active incidents"
            content="Good news, you don't have any active incidents &mdash; when you do, they'll appear here"
          />
        ) : incidents && incidents.length === 0 && hasAppliedFilter ? (
          <EmptyStateAllFiltered isKanban={false} /> // If isKanban is true, we'll tell people to go to list view which doesn't make sense here.
        ) : (
          <IncidentsKanbanBoard
            incidents={incidents}
            statuses={activeStatuses}
            isLoading={isLoading}
            maxPerColumn={MAX_CARDS_PER_COLUMN}
            className="px-0"
          />
        )}
      </AnimatePresence>
    </div>
  );
};
