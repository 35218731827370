import { CatalogEntryBadge } from "@incident-shared/attribute";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  StackedList,
} from "@incident-ui";
import React from "react";
import {
  EngineScope,
  ExpressionOperationOperationTypeEnum as OperationTypeEnum,
} from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";
import { tcx } from "src/utils/tailwind-classes";

import { EngineLiteralBadge } from "../labels";
import { ThemeForReferenceSource } from "../labels/EngineReferenceBadge";
import {
  ExpressionsMethodsProvider,
  useExpressionsMethods,
} from "./ExpressionsMethodsProvider";
import { ExpressionFormData } from "./expressionToPayload";
import {
  ViewExpressionBranch,
  ViewIfElseExpression,
} from "./ifelse/ViewIfElseExpression";
import { getOperationOptions } from "./query/QueryOperationsEditor";
import {
  OperationPill,
  ViewQueryExpression,
} from "./query/ViewQueryExpression";

export const ViewExpression = ({
  expression,
  onEdit,
  onDelete,
  disabled,
  scope,
  // We basically never want to show this, except for in Workflows where we surface expressions
  // as their own thing that you might re-use.
  showExpressionName = false,
  hideTitleBar,
  miniBadges = false,
  displayMini,
  footer,
}: {
  expression: ExpressionFormData;
  onEdit?: (() => void) | ((e: ExpressionFormData) => void);
  onDelete?: (() => void) | ((e: ExpressionFormData) => void);
  disabled?: boolean;
  scope: EngineScope;
  showExpressionName?: boolean;
  hideTitleBar?: boolean;
  miniBadges?: boolean;
  displayMini?: boolean;
  footer?: React.ReactNode;
}): React.ReactElement => {
  // Show title bar if mini mode or default display
  const showTitleBar = displayMini || !hideTitleBar;
  // Show expression body if not mini mode
  const showExpressionBody = !displayMini;

  if (
    expression.operations.length === 1 &&
    expression.operations[0].operation_type === OperationTypeEnum.Parse
  ) {
    return (
      <ViewParseExpression
        expression={expression}
        onEdit={onEdit}
        onDelete={onDelete}
        disabled={disabled}
        miniBadges={miniBadges}
        footer={footer}
      />
    );
  }

  return (
    <StackedList className="w-full">
      {showTitleBar && (
        <TitleBar
          expression={expression}
          onEdit={onEdit}
          onDelete={onDelete}
          disabled={disabled}
          showExpressionName={showExpressionName}
        />
      )}
      {showExpressionBody && (
        <ExpressionsMethodsProvider allowAllOfACatalogType showExpressionNames>
          <ExpressionBody
            expression={expression}
            scope={scope}
            miniBadges={miniBadges}
          />
        </ExpressionsMethodsProvider>
      )}
    </StackedList>
  );
};

const TitleBar = ({
  expression,
  onEdit,
  onDelete,
  disabled,
  showExpressionName,
}: {
  expression: ExpressionFormData;
  onEdit?: (() => void) | ((e: ExpressionFormData) => void);
  onDelete?: (() => void) | ((e: ExpressionFormData) => void);
  disabled?: boolean;
  showExpressionName?: boolean;
}) => {
  const expressionColours = ThemeForReferenceSource.expression;

  return (
    <div
      className={tcx(
        "border-[1px] px-2 py-1 text-sm flex items-center justify-between font-medium gap-1 min-w-0 min-h-[42px]",
        expressionColours.background,
        expressionColours.content,
      )}
    >
      <div className="flex items-center space-x-1">
        <Icon id={IconEnum.Expression} className="flex-none mr-1" />
        {showExpressionName ? expression.label : "Expression"}
      </div>
      <div className={tcx("flex items-center gap-1", "shrink-0")}>
        {onEdit && (
          <Button
            analyticsTrackingId="expression-condition-edit-rule"
            theme={ButtonTheme.Naked}
            className={expressionColours.content}
            onClick={() => onEdit(expression)}
            icon={IconEnum.Edit}
            iconProps={{ size: IconSize.Medium }}
            title="Edit"
            disabled={disabled}
          />
        )}

        {onDelete && (
          <Button
            analyticsTrackingId="expression-condition-remove-rule"
            className={expressionColours.content}
            theme={ButtonTheme.Naked}
            onClick={() => onDelete(expression)}
            icon={IconEnum.Delete}
            iconProps={{ size: IconSize.Medium }}
            title="Remove rule"
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

const ExpressionBody = ({
  expression,
  scope,
  miniBadges,
}: {
  expression: ExpressionFormData;
  scope: EngineScope;
  miniBadges: boolean;
}) => {
  const isIfElse =
    expression.operations[0].operation_type === OperationTypeEnum.Branches;

  const { allowAllOfACatalogType } = useExpressionsMethods();

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }

  if (resourcesLoading) {
    return <Loader />;
  }

  return (
    <>
      {isIfElse ? (
        <ViewIfElseExpression expression={expression} miniBadges={miniBadges} />
      ) : (
        <ViewQueryExpression
          expression={expression}
          scope={scope}
          resources={resources}
          allowAllOfACatalogType={allowAllOfACatalogType}
          miniBadges={miniBadges}
        />
      )}
    </>
  );
};

const ViewParseExpression = ({
  expression,
  onEdit,
  onDelete,
  disabled,
  miniBadges,
  footer,
}: {
  expression: ExpressionFormData;
  onEdit?: (() => void) | ((e: ExpressionFormData) => void);
  onDelete?: (() => void) | ((e: ExpressionFormData) => void);
  disabled?: boolean;
  miniBadges: boolean;
  footer?: React.ReactNode;
}) => {
  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }

  if (resourcesLoading) {
    return <Loader />;
  }
  const operation = expression.operations[0];

  const operationOption = getOperationOptions({
    operationType: operation.operation_type,
    previousRefLabel: "",
    capitalizeDescription: false,
  });

  const returnsResource = resources.find(
    (r) => r.type === operation.returns.type,
  );
  if (!returnsResource) {
    return <GenericErrorMessage description="Cannot display expression" />;
  }
  const returnsIcon = returnsResource?.field_config?.icon || IconEnum.Box;
  const returnsColor = returnsResource?.field_config?.color;

  return (
    <ContentBox className="w-full py-2 flex flex-col gap-2">
      {/* Title row: parse type from payload */}
      <div className="flex px-2 justify-between gap-2">
        <div className="flex items-center gap-1 flex-wrap">
          <OperationPill
            operation={operation}
            operationOption={operationOption}
            mini={miniBadges}
          />
          <CatalogEntryBadge
            icon={returnsIcon}
            label={returnsResource.type_label}
            color={returnsColor as unknown as ColorPaletteEnum}
            size={miniBadges ? BadgeSize.Small : BadgeSize.Medium}
          />
          <span>from</span>
          <EngineLiteralBadge label="Payload" mini={miniBadges} />
        </div>
        <div className={tcx("flex items-center gap-1", "shrink-0")}>
          {onEdit && (
            <Button
              analyticsTrackingId="expression-condition-edit-rule"
              theme={ButtonTheme.Naked}
              className="text-slate-400"
              onClick={() => onEdit(expression)}
              icon={IconEnum.Edit}
              title="Edit"
              disabled={disabled}
            />
          )}

          {onDelete && (
            <Button
              analyticsTrackingId="expression-condition-remove-rule"
              theme={ButtonTheme.Naked}
              className="text-slate-400"
              onClick={() => onDelete(expression)}
              icon={IconEnum.Delete}
              title="Remove rule"
              disabled={disabled}
            />
          )}
        </div>
      </div>

      {/* Divider */}
      <div className="flex-grow border-b border-dashed border-gray-300"></div>

      {/* The code we're using to parse */}
      <div className="rounded-2 bg-surface-secondary mx-2 p-2 font-mono">
        {operation.parse?.source}
      </div>

      {/* The else branch */}
      {expression.else_branch && expression.else_branch.result?.value ? (
        <>
          {/* Divider */}
          <div className="flex-grow border-b border-dashed border-gray-300"></div>

          <ViewExpressionBranch
            conditionGroups={[]}
            result={expression.else_branch.result}
            returns={expression.returns}
            isElse
            className="py-0 pl-0"
            miniBadges={miniBadges}
          />
        </>
      ) : null}

      {footer ? (
        <>
          <div className="flex-grow border-b border-dashed border-gray-300"></div>
          <div className="flex flex-col gap-2 pl-2">{footer}</div>
        </>
      ) : null}
    </ContentBox>
  );
};
