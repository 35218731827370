/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormField,
    FormFieldFromJSON,
    FormFieldFromJSONTyped,
    FormFieldToJSON,
} from './FormField';

/**
 * 
 * @export
 * @interface FilterFormFields
 */
export interface FilterFormFields {
    /**
     * Form fields for filtering incidents
     * @type {Array<FormField>}
     * @memberof FilterFormFields
     */
    incident: Array<FormField>;
    /**
     * Form fields for filtering pager load
     * @type {Array<FormField>}
     * @memberof FilterFormFields
     */
    pager_load: Array<FormField>;
    /**
     * Form fields for filtering users
     * @type {Array<FormField>}
     * @memberof FilterFormFields
     */
    user: Array<FormField>;
}

export function FilterFormFieldsFromJSON(json: any): FilterFormFields {
    return FilterFormFieldsFromJSONTyped(json, false);
}

export function FilterFormFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterFormFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident': ((json['incident'] as Array<any>).map(FormFieldFromJSON)),
        'pager_load': ((json['pager_load'] as Array<any>).map(FormFieldFromJSON)),
        'user': ((json['user'] as Array<any>).map(FormFieldFromJSON)),
    };
}

export function FilterFormFieldsToJSON(value?: FilterFormFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident': ((value.incident as Array<any>).map(FormFieldToJSON)),
        'pager_load': ((value.pager_load as Array<any>).map(FormFieldToJSON)),
        'user': ((value.user as Array<any>).map(FormFieldToJSON)),
    };
}

