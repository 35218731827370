import { SavedViewsListContextEnum } from "@incident-io/api";
import { Link, Loader } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { ExploDashboardID } from "src/components/insights/dashboards/dashboards";

import { GroupByCustomFieldDropdown } from "../../common/GroupByCustomFieldDropdown";
import { InsightsDashboardSection } from "../../common/InsightsDashboardSection";
import { InsightsPage } from "../../common/InsightsPage";
import { useCustomFieldOptions } from "../../common/useCustomFieldOptions";
import { InsightsContextProvider } from "../../context/InsightsContextProvider";
import { StateConfig } from "../../context/types";
import { useInsightsContext } from "../../context/useInsightsContext";

export interface TimeSpentOnIncidentsDashboardStateConfig extends StateConfig {
  split_by: {
    options: SelectOption[];
  };
}

export const TimeSpentOnIncidentsDashboard = () => {
  const { customFieldOptions, loading: customFieldsLoading } =
    useCustomFieldOptions();

  if (customFieldsLoading) {
    return <Loader />;
  }

  return (
    <InsightsContextProvider<TimeSpentOnIncidentsDashboardStateConfig>
      context={SavedViewsListContextEnum.InsightsV2}
      stateConfig={{
        split_by: {
          options: customFieldOptions,
        },
      }}
    >
      <InsightsPage dashboard="time-spent-on-incidents">
        <WorkloadTimeSpentDashboard />
        <WorkloadSplitByCustomFieldDashboard />
      </InsightsPage>
    </InsightsContextProvider>
  );
};

const WorkloadTimeSpentDashboard = () => {
  return (
    <InsightsDashboardSection
      title="Time spent on incidents"
      dashboardEmbedID={ExploDashboardID.ResponseTimeSpentInIncidents}
      description={
        <>
          <p>
            Understand the time spent on incidents across your company,
            categorised by the time of day (during working hours, late evening,
            or overnight).
          </p>
          <br />
          <p>
            Understand how workload is measured by reading our{" "}
            <Link
              href="https://incident.io/guide/insights/workload/#how-is-it-measured%3F"
              openInNewTab
              analyticsTrackingId={null}
            >
              Incident Management guide
            </Link>
            .
          </p>
        </>
      }
      initialHeight="1402px"
    />
  );
};

const WorkloadSplitByCustomFieldDashboard = () => {
  const { useState, getOptions } =
    useInsightsContext<TimeSpentOnIncidentsDashboardStateConfig>();
  const [selectedCustomFieldID, setSelectedCustomFieldID] =
    useState("split_by");
  const customFieldOptions = getOptions("split_by");
  const customField = customFieldOptions.find(
    ({ value }) => value === selectedCustomFieldID,
  );

  return (
    <InsightsDashboardSection
      title="Time spent by custom field"
      description="Compare how time is spent, grouped by a custom field. You can use this to determine whether certain product features or teams are involved with more incidents."
      dashboardEmbedID={ExploDashboardID.ResponseWorkloadSplitByCustomField}
      controls={
        <GroupByCustomFieldDropdown
          selectedCustomFieldID={selectedCustomFieldID}
          setSelectedCustomFieldID={setSelectedCustomFieldID}
          customFieldOptions={customFieldOptions}
        />
      }
      dashboardVariables={{
        custom_field_id: customField?.value,
        custom_field_name: customField?.label,
      }}
      initialHeight="956px"
    />
  );
};

export const GroupByCustomFieldDescription = () => {
  return (
    <>
      Group incidents by your chosen custom field — be it users, teams, or
      product features — to uncover specific patterns and trends unique to each
      segment (provided you have{" "}
      <Link to="/settings/custom-fields" analyticsTrackingId={null}>
        configured custom fields
      </Link>
      ).
    </>
  );
};
