import {
  DependentResource,
  DependentResourceResourceTypeEnum,
} from "@incident-io/api";
import { Button, ButtonSize, ButtonTheme, Icon, IconEnum } from "@incident-ui";
import { Popover } from "@incident-ui/Popover/Popover";
import { PopoverItem } from "@incident-ui/Popover/PopoverItem";
import { groupBy, sortBy } from "lodash";
import pluralize from "pluralize";

export const CatalogTypeDependentResourcesBar = ({
  dependentResources,
}: {
  dependentResources: DependentResource[];
}) => {
  const groupedResources = groupBy(dependentResources, (r) => r.resource_type);

  return (
    <div className="flex flex-col gap-2">
      <div className="text-xs-med text-content-secondary">Using this type</div>
      <div className="flex items-center gap-2">
        {Object.entries(DEPENDENT_RESOURCE_CONFIG).map(
          ([resourceType, config]) => {
            const resources = groupedResources[resourceType] || [];

            if (resources.length === 0) {
              // Anything that we haven't explicitly said we know how to render, ignore!
              return <></>;
            }

            return (
              <Popover
                key={resourceType}
                trigger={
                  <Button
                    analyticsTrackingId={null}
                    theme={ButtonTheme.Secondary}
                    size={ButtonSize.Small}
                    key={resourceType}
                    icon={config.icon}
                  >
                    {resources.length}{" "}
                    {pluralize(
                      resources[0].resource_type_label,
                      resources.length,
                    )}
                  </Button>
                }
                align="start"
              >
                {sortBy(resources, (r) => r.label).map((resource) => (
                  <PopoverItem
                    icon={config.icon}
                    suffix={<Icon id={IconEnum.ExternalLink} />}
                    key={resource.id}
                    to={resource.settings_url}
                    openInNewTab
                  >
                    {resource.label}
                  </PopoverItem>
                ))}
              </Popover>
            );
          },
        )}
        {/* And now render any 'create' buttons */}
        {Object.entries(DEPENDENT_RESOURCE_CONFIG).map(
          ([resourceType, config]) => {
            const resources = groupedResources[resourceType] || [];

            // If we have none, and have a create path, render a button to create a new [thing]
            if (config.createPath && resources.length === 0) {
              return (
                <Button
                  analyticsTrackingId={null}
                  theme={ButtonTheme.Secondary}
                  size={ButtonSize.Small}
                  key={resourceType}
                  className="border-dashed border-stroke-hover hover:border-slate-300 shadow-none"
                  icon={IconEnum.Add}
                  href={config.createPath}
                >
                  {config.label}
                </Button>
              );
            }
            // Otherwise, render nothing
            return <></>;
          },
        )}
      </div>
    </div>
  );
};

const DEPENDENT_RESOURCE_CONFIG: {
  [key: string]: { icon: IconEnum } & (
    | { createPath: string; label: string }
    | { createPath?: never; label?: never }
  );
} = {
  [DependentResourceResourceTypeEnum.CustomField]: {
    icon: IconEnum.CustomField,
    createPath: "custom-field-create",
    label: "Custom field",
  },
  [DependentResourceResourceTypeEnum.Workflow]: {
    icon: IconEnum.Workflows,
  },
  [DependentResourceResourceTypeEnum.Policy]: {
    icon: IconEnum.ShieldExclamation,
  },
  [DependentResourceResourceTypeEnum.AlertConfig]: {
    icon: IconEnum.Tag,
  },
  [DependentResourceResourceTypeEnum.AlertRoute]: {
    icon: IconEnum.AlertRoute,
  },
  [DependentResourceResourceTypeEnum.StatusPages]: {
    icon: IconEnum.StatusPage,
  },
  [DependentResourceResourceTypeEnum.AnnouncementRule]: {
    icon: IconEnum.Announcement,
  },
  [DependentResourceResourceTypeEnum.FollowUpIssueTemplate]: {
    icon: IconEnum.FollowUps,
  },
  [DependentResourceResourceTypeEnum.InternalStatusPage]: {
    icon: IconEnum.StatusPage,
  },
};
