import { Mode } from "@incident-shared/forms/v2/formsv2";
import { Button, ButtonTheme, IconEnum, IconSize } from "@incident-ui";
import React, { useState } from "react";
import { Incident } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { CreateEditTimelineCustomEventModal } from "./CreateEditTimelineCustomEventModal";
import styles from "./TimelineItems.module.scss";

export const CreateTimelineCustomEventSection = ({
  incident,
  occuredAt,
}: {
  incident: Incident;
  occuredAt: Date;
}): React.ReactElement => {
  const [showCustomEventModal, setShowCustomEventModal] = useState(false);

  return (
    <>
      <div className={tcx(styles.timelineAddNoteWrapper)}>
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className={styles.horizontalLine}></div>
        </div>
        <div className="relative flex justify-center">
          <span className="text-sm bg-white">
            <Button
              icon={IconEnum.AddCircle}
              iconProps={{
                size: IconSize.Medium,
              }}
              title="Add event"
              analyticsTrackingId="add-timeline-custom-event"
              theme={ButtonTheme.Naked}
              className={"pr-2"}
              onClick={() => setShowCustomEventModal(true)}
            >
              Add event
            </Button>
          </span>
        </div>
      </div>
      {showCustomEventModal && (
        <CreateEditTimelineCustomEventModal
          incident={incident}
          occurredAt={occuredAt}
          onClose={() => {
            setShowCustomEventModal(false);
          }}
          mode={Mode.Create}
        />
      )}
    </>
  );
};
