import { ConfirmationDialog } from "@incident-ui";
import React from "react";
import { ErrorMessageUI } from "src/components/@shared/forms/ErrorMessage";
import { FollowUp } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { useRefetchFollowups } from "./use-refetch-followups";

type FormData = { id: string };

export const FollowUpDeleteModal = ({
  onClose,
  followUp,
  refetchIncidents,
}: {
  onClose: () => void;
  followUp: FollowUp;
  refetchIncidents?: () => Promise<void>;
}): React.ReactElement | null => {
  const refetch = useRefetchFollowups();

  const {
    trigger: deleteFollowUp,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    { incidentId: followUp.incident_id },
    async (apiClient, { id }: FormData) => {
      await apiClient.followUpsDestroy({
        id,
      });
    },
    {
      onSuccess: () => {
        refetch();
        refetchIncidents && refetchIncidents();
        onClose();
      },
    },
  );

  return (
    <ConfirmationDialog
      onCancel={onClose}
      onConfirm={() => deleteFollowUp({ id: followUp.id })}
      isOpen={true}
      title={`Delete follow-up`}
      analyticsTrackingId="delete-follow-up"
      saving={saving}
    >
      <p>Are you sure you want to delete this follow-up?</p>
      <p className="font-medium mt-2">{followUp.title}</p>
      <ErrorMessageUI message={genericError} />
    </ConfirmationDialog>
  );
};
