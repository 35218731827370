import {
  OnCallNotificationMethodV2MethodTypeEnum,
  OnCallNotificationMethodV2StatusEnum,
  OnCallNotificationsCreateRuleRequestBodyNotificationTypeEnum,
  OnCallNotificationsCreateRuleRequestBodyRuleTypeEnum,
} from "@incident-io/api";
import { OnCallNotificationRuleRuleTypeEnum } from "@incident-io/api/models/OnCallNotificationRule";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { StaticSingleSelectWithObjV2 } from "@incident-shared/forms/v2/inputs/StaticSelectWithObjV2";
import { ModalFooter } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { getNotificationIntervals } from "src/components/user-preferences/on-call-notifications/OnCallEditNotificationRuleModal";

import { Identity } from "../../../contexts/ClientContext";
import { useIdentity } from "../../../contexts/IdentityContext";
import { useAPI, useAPIMutation } from "../../../utils/swr";
import { assertUnreachable } from "../../../utils/utils";
import {
  escalationNotificationText,
  shiftChangeNotificationText,
} from "./helpers";
import {
  OnCallNotificationConfigurationInvalidCallout,
  useShortestEscalationPathForUser,
} from "./OnCallNotificationConfigurationInvalidCallout";
import { Method } from "./OnCallNotificationsPage";

export const methodToNotificationOptions = (
  identity: Identity | null,
  method: Method,
): SelectOption[] => {
  switch (method.method_type) {
    case OnCallNotificationMethodV2MethodTypeEnum.Phone:
      const phone = method.phone;
      return phone
        ? [
            {
              label: `Phone call (${phone.phone_number})`,
              value: `${method.id}:${OnCallNotificationsCreateRuleRequestBodyNotificationTypeEnum.Phone}`,
            },
            {
              label: `SMS (${phone.phone_number})`,
              value: `${method.id}:${OnCallNotificationsCreateRuleRequestBodyNotificationTypeEnum.Sms}`,
            },
          ]
        : [];
    case OnCallNotificationMethodV2MethodTypeEnum.App:
      return [
        {
          label: `App`,
          value: `:${OnCallNotificationsCreateRuleRequestBodyNotificationTypeEnum.App}`,
        },
      ];
    case OnCallNotificationMethodV2MethodTypeEnum.Email:
      return [
        {
          label: `Email (${identity?.user_email ?? ""})`,
          value: `:${OnCallNotificationsCreateRuleRequestBodyNotificationTypeEnum.Email}`,
        },
      ];
    case OnCallNotificationMethodV2MethodTypeEnum.Slack:
      return [
        {
          label: `Slack (${identity?.user_name ?? ""})`,
          value: `:${OnCallNotificationsCreateRuleRequestBodyNotificationTypeEnum.Slack}`,
        },
      ];
    case OnCallNotificationMethodV2MethodTypeEnum.MicrosoftTeams:
      return [
        {
          label: `Microsoft Teams (${identity?.user_name ?? ""})`,
          value: `:${OnCallNotificationsCreateRuleRequestBodyNotificationTypeEnum.MicrosoftTeams}`,
        },
      ];
    default:
      assertUnreachable(method.method_type);
      return [];
  }
};

export type AddRuleData = {
  delay_seconds: SelectOption;
  notice_period_seconds?: SelectOption;
  method_type: SelectOption;
};
export const AddRuleModal = ({
  onClose,
  methods,
  ruleType,
}: {
  ruleType: OnCallNotificationRuleRuleTypeEnum;
  onClose: () => void;
  methods: Method[];
}) => {
  const { identity } = useIdentity();
  const formMethods = useForm<AddRuleData>({});
  const {
    trigger: onCreate,
    isMutating,
    genericError,
  } = useAPIMutation(
    "onCallNotificationsListRules",
    undefined,
    async (apiClient, formData: AddRuleData) => {
      const [methodId, notificationType] =
        formData.method_type.value.split(":");
      await apiClient.onCallNotificationsCreateRule({
        createRuleRequestBody: {
          rule_type:
            ruleType as unknown as OnCallNotificationsCreateRuleRequestBodyRuleTypeEnum,
          delay_seconds:
            (ruleType as unknown as OnCallNotificationsCreateRuleRequestBodyRuleTypeEnum) ===
            OnCallNotificationsCreateRuleRequestBodyRuleTypeEnum.ShiftChanges
              ? 0
              : parseInt(formData.delay_seconds.value),
          notice_period_seconds: formData.notice_period_seconds
            ? parseInt(formData.notice_period_seconds.value)
            : undefined,
          method_id: methodId === "" ? undefined : methodId,
          notification_type:
            notificationType as OnCallNotificationsCreateRuleRequestBodyNotificationTypeEnum,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: () => onClose(),
    },
  );

  const addRuleOptions = getNotificationIntervals(ruleType).map((seconds) => ({
    value: String(seconds),
    label:
      ruleType === OnCallNotificationRuleRuleTypeEnum.ShiftChanges
        ? shiftChangeNotificationText(seconds)
        : escalationNotificationText(seconds),
  }));

  const selectedDelaySeconds = formMethods.watch("delay_seconds");

  const { data: currentRules, isLoading: currentRulesLoading } = useAPI(
    "onCallNotificationsListRules",
    undefined,
  );

  const currentHighUrgencyRuleLowestNotificationTime = _.chain(
    currentRules?.rules ?? [],
  )
    .filter(
      (r) => r.rule_type === OnCallNotificationRuleRuleTypeEnum.HighUrgency,
    )
    .map((r) => r.delay_seconds)
    .min()
    .value();

  // Find the escalation path that this user is on with the shortest time to
  // respond.
  const shortestEscalationPath = useShortestEscalationPathForUser(
    identity?.user_id ?? "",
  );

  const shouldShowInvalidConfigBanner =
    !currentRulesLoading &&
    shortestEscalationPath &&
    selectedDelaySeconds &&
    currentHighUrgencyRuleLowestNotificationTime >
      shortestEscalationPath.seconds &&
    parseInt(selectedDelaySeconds.value) > shortestEscalationPath.seconds;

  return (
    <FormModalV2<AddRuleData>
      formMethods={formMethods}
      onSubmit={onCreate}
      title={"Add rule"}
      analyticsTrackingId={"add-rule-modal"}
      onClose={onClose}
      saving={isMutating}
      genericError={genericError}
      disableQuickClose={false} // This is a small enough modal that we shouldn't double up and confirm
      warnWhenDirty={false}
      footer={
        <ModalFooter
          confirmButtonType={"submit"}
          saving={isMutating}
          onClose={onClose}
          confirmButtonText={"Add rule"}
        />
      }
    >
      <div className="flex flex-col gap-4">
        <div className={"flex flex-row space-x-2"}>
          <StaticSingleSelectWithObjV2
            required
            className={"flex-[1_1_0%]"}
            options={addRuleOptions}
            name={
              OnCallNotificationRuleRuleTypeEnum.ShiftChanges === ruleType
                ? "notice_period_seconds"
                : "delay_seconds"
            }
            label={"Notify me"}
            formMethods={formMethods}
          />
          <StaticSingleSelectWithObjV2
            required
            className={"flex-[2_2_0%]"}
            options={_.chain(methods)
              .filter(
                (m) =>
                  m.status === OnCallNotificationMethodV2StatusEnum.Verified,
              )
              .flatMap((m) => methodToNotificationOptions(identity, m))
              .value()}
            name={"method_type"}
            label={"Via"}
            formMethods={formMethods}
          />
        </div>
        {shouldShowInvalidConfigBanner && (
          <OnCallNotificationConfigurationInvalidCallout
            shortestEscalationPath={shortestEscalationPath}
            mode="modal"
            currentLowestNotificationTime={
              currentHighUrgencyRuleLowestNotificationTime <
              parseInt(selectedDelaySeconds.value)
                ? currentHighUrgencyRuleLowestNotificationTime
                : parseInt(selectedDelaySeconds.value)
            }
          />
        )}
      </div>
    </FormModalV2>
  );
};
