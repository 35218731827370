import { Callout, CalloutTheme, ModalFooter } from "@incident-ui";
import React from "react";
import { useForm, useFormContext } from "react-hook-form";
import { FormModalV2 } from "src/components/@shared/forms/v2/FormV2";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "src/components/@shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "src/components/@shared/forms/v2/inputs/TextareaV2";
import {
  FollowUp,
  IssueTrackersShortcutTypeaheadOptionsFieldEnum,
  SelectOption,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

export type ShortcutFormData = {
  title: string;
  description: string;
  team_id: string;
  project_id: string;
  reporter_id: string;
  assignee_id: string;
};

export const ExportToShortcutModal = ({
  followUp,
  onClose,
  updateCallback,
  isPrivateIncident,
}: {
  followUp: FollowUp;
  isPrivateIncident: boolean;
  onClose: () => void;
  updateCallback: (newFollowUp: FollowUp) => Promise<void>;
}): React.ReactElement | null => {
  // @TODO: Don't refresh the entire page to generate this modal.
  const formMethods = useForm<ShortcutFormData>({
    defaultValues: {
      title: followUp.title,
      description: followUp.description,
    },
  });
  const { setError, watch, getValues, resetField } = formMethods;

  const {
    data: { typeahead_options: teams },
    isLoading: teamsLoading,
    error: teamsError,
  } = useAPI(
    "issueTrackersShortcutTypeaheadOptions",
    {
      field: IssueTrackersShortcutTypeaheadOptionsFieldEnum.Team,
    },
    {
      fallbackData: { typeahead_options: [] },
    },
  );

  const {
    data: { typeahead_options: projects },
    isLoading: projectsLoading,
    error: projectsError,
  } = useAPI(
    "issueTrackersShortcutTypeaheadOptions",
    {
      field: IssueTrackersShortcutTypeaheadOptionsFieldEnum.Project,
    },
    {
      fallbackData: { typeahead_options: [] },
    },
  );

  const selectedTeamID = watch("team_id");

  const {
    data: { typeahead_options: members },
    isLoading: membersLoading,
    error: membersError,
  } = useAPI(
    "issueTrackersShortcutTypeaheadOptions",
    {
      field: IssueTrackersShortcutTypeaheadOptionsFieldEnum.Member,
      teamId: selectedTeamID ?? undefined,
    },
    {
      fallbackData: { typeahead_options: [] },
      onSuccess: ({ typeahead_options }) => {
        // If there's an assignee or reporter selected in the form, and it's no
        // longer in the list, clear the input
        const selectedAssigneeID = getValues("assignee_id");
        if (
          !!selectedAssigneeID &&
          typeahead_options?.every(({ value }) => value !== selectedAssigneeID)
        ) {
          resetField("assignee_id");
        }
        const selectedReporterID = getValues("reporter_id");
        if (
          !!selectedReporterID &&
          typeahead_options?.every(({ value }) => value !== selectedReporterID)
        ) {
          resetField("reporter_id");
        }
      },
    },
  );

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    { incidentId: followUp.incident_id },
    async (apiClient, data: ShortcutFormData) => {
      const resp = await apiClient.issueTrackersShortcutCreateIssue({
        shortcutCreateIssueRequestBody: {
          follow_up_id: followUp.id,
          title: data.title,
          description: data.description,
          team_id: data.team_id,
          project_id: data.project_id,
          reporter_id: data.reporter_id,
          assignee_id: data.assignee_id,
        },
      });
      await updateCallback(resp.follow_up);
    },
    { setError, onSuccess: onClose },
  );

  const fetchDataError =
    teamsError || membersError || projectsError
      ? "There was a problem loading data from Shortcut."
      : null;

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      title="Export to Shortcut"
      analyticsTrackingId="add-to-shortcut"
      onClose={onClose}
      loading={teamsLoading || projectsLoading}
      genericError={genericError || fetchDataError}
      footer={
        <ModalFooter
          confirmButtonText="Create"
          onClose={onClose}
          saving={saving}
          confirmButtonType="submit"
        />
      }
    >
      {isPrivateIncident && (
        <Callout className="mb-4" theme={CalloutTheme.Warning}>
          This is a private incident. This story will be visible to anyone with
          access to your Shortcut team.
        </Callout>
      )}
      <p className="text-sm text-slate-700 mb-1">
        We&apos;ll automatically sync any changes in Shortcut.
      </p>
      <hr className="my-3" />
      <InputV2
        formMethods={formMethods}
        label="Title"
        name="title"
        required="Please enter a title"
        defaultValue={followUp.description}
        autoComplete="none"
      />
      <TextareaV2
        formMethods={formMethods}
        label="Description"
        required={false}
        name="description"
        placeholder="Any more details you want to add while you're here?"
        defaultValue={followUp.description}
        autoComplete="none"
      />
      <ShortcutBulkExportableFields
        projects={projects ?? null}
        projectsLoading={projectsLoading}
        members={members ?? null}
        membersLoading={membersLoading}
        teams={teams ?? null}
        teamsLoading={teamsLoading}
      />
    </FormModalV2>
  );
};

// ShortcutBulkExportableFields holds the fields that are present on both the singular export and the bulk
// export modals. If you're adding a new field, please consider whether it should be added here (ie.
// a user might want to set it for multiple actions at once), or above (ie. a user will only want to
// set it for a particular followUp).
export const ShortcutBulkExportableFields = ({
  teams,
  teamsLoading,
  projects,
  projectsLoading,
  members,
  membersLoading,
}: {
  teams: SelectOption[] | null;
  teamsLoading: boolean;
  projects: SelectOption[] | null;
  projectsLoading: boolean;
  members: SelectOption[] | null;
  membersLoading: boolean;
}): React.ReactElement => {
  const formMethods = useFormContext<ShortcutFormData>();
  return (
    <>
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Team"
        name={"team_id"}
        options={teams || []}
        placeholder="Select team"
        isLoading={!teams || teamsLoading}
      />
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Project"
        name={"project_id"}
        options={projects || []}
        placeholder="Select project"
        isLoading={!projects || projectsLoading}
      />
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Reporter"
        required="Please select a reporter"
        name={"reporter_id"}
        options={members || []}
        placeholder="Select reporter"
        isLoading={!members || membersLoading}
      />
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Assignee"
        name={"assignee_id"}
        options={members || []}
        placeholder="Select assignee"
        isLoading={!members || membersLoading}
      />
    </>
  );
};
