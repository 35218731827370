import { Condition, ConditionGroup } from "@incident-io/api";
import { ConditionsListSentenceProps } from "@incident-shared/engine/conditions";
import {
  EditableConditionGroupsList,
  EditableConditionGroupsListProps,
  FixedConditionGroupsProps,
} from "@incident-shared/engine/conditions/EditableConditionGroupsList";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import _ from "lodash";
import {
  ArrayPath,
  FieldValues,
  useController,
  UseFieldArrayReturn,
} from "react-hook-form";

import { FormInputWrapperV2 } from "../FormInputWrapperV2";
import { InputElementProps, parseProps } from "../formsv2";

type ExpressionsProps =
  | {
      allowFilteringByExpression?: boolean;
      expressions?: ExpressionFormData[];
      allowAllOfACatalogTypeInQueryExpression: boolean;
    }
  | {
      allowFilteringByExpression?: never;
      expressions?: never;
      allowAllOfACatalogTypeInQueryExpression?: never;
    };

export type ConditionGroupsEditorV2Props<TFormType extends FieldValues> = Omit<
  EditableConditionGroupsListProps,
  "selectedConditionGroups"
> & {
  expressionsMethods?: UseFieldArrayReturn<
    TFormType,
    ArrayPath<TFormType>,
    "key"
  >;
} & ExpressionsProps &
  ConditionsListSentenceProps &
  FixedConditionGroupsProps;

export const ConditionGroupsEditorV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, ConditionGroupsEditorV2Props<TFormType>>,
): React.ReactElement => {
  const { name, inputProps, wrapperProps } = parseProps(props);

  const {
    field,
    // We don't want to pass the ref onwards here: this is a complex component
    // which references multiple inputs, so we have to use a controller here rather
    // than an uncontrolled form input.
  } = useController({
    name,
    rules: props.rules,
  });

  const value = (field.value ?? []) as ConditionGroup[];
  const onChange = field.onChange as (value: ConditionGroup[]) => void;

  const onAddGroup = () => {
    onChange([...value, { conditions: [] }]);
  };

  const onDeleteGroup = (idx: number) => {
    onChange(value.filter((_, conditionIdx) => conditionIdx !== idx));
  };

  const onAddCondition = (groupIdx: number, condition: Condition) => {
    const newConditionGroups = [...value];
    if (!newConditionGroups[groupIdx]) {
      newConditionGroups[groupIdx] = { conditions: [] };
    }
    newConditionGroups[groupIdx].conditions.push(condition);
    onChange(newConditionGroups);
  };

  const onUpdateCondition = (
    groupIdx: number,
    idx: number,
    condition: Condition,
  ) => {
    const newConditionGroups = [...value];
    newConditionGroups[groupIdx].conditions[idx] = condition;
    onChange(newConditionGroups);
  };

  const onDeleteCondition = (groupIdx: number, idx: number) => {
    const newConditionGroups = [...value];
    newConditionGroups[groupIdx].conditions = newConditionGroups[
      groupIdx
    ].conditions.filter((_, conditionIdx) => conditionIdx !== idx);
    if (newConditionGroups[groupIdx].conditions.length === 0) {
      newConditionGroups.splice(groupIdx, 1);
    }
    onChange(newConditionGroups);
  };

  const mutations = {
    onAddGroup,
    onDeleteGroup,
    onAddCondition,
    onUpdateCondition,
    onDeleteCondition,
  };

  let scope = props.scope;
  const expressions = props.expressions;
  if (expressions) {
    scope = addExpressionsToScope(scope, expressions);
  }

  // Ensure we don't have any duplicates in our scope
  scope.references = _.unionBy(scope.references, "key");

  return (
    <FormInputWrapperV2<TFormType> {...wrapperProps} name={name}>
      {props.expressionsMethods ? (
        <ExpressionsMethodsProvider
          expressionsMethods={props.expressionsMethods}
          allowAllOfACatalogType={
            props.allowAllOfACatalogTypeInQueryExpression || false
          }
        >
          <EditableConditionGroupsList
            {...(inputProps as EditableConditionGroupsListProps)}
            mutations={mutations}
            selectedConditionGroups={value}
            scope={scope}
          />
        </ExpressionsMethodsProvider>
      ) : (
        <EditableConditionGroupsList
          {...(inputProps as EditableConditionGroupsListProps)}
          mutations={mutations}
          selectedConditionGroups={value}
          scope={scope}
        />
      )}
    </FormInputWrapperV2>
  );
};
