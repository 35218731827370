import { assertUnreachable } from "@incident-io/status-page-ui";
import { UpgradeRequiredMessage } from "@incident-shared/billing";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { IconEnum, RadioButtonGroup } from "@incident-ui";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";
import { join } from "lodash";
import _ from "lodash";
import { useFormContext, UseFormReturn } from "react-hook-form";
import {
  PostIncidentFlowCreateTaskConfigRequestBodyTaskTypeEnum as TaskType,
  PostIncidentTaskConfig as TaskConfig,
  PostIncidentTaskOption as TaskOption,
  PostIncidentTaskOptionTaskTypeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { usePostmortemName } from "src/utils/utils";

import { findAvailableSuggestedTasks } from "../PostIncidentStatusRow";
import { FormDataType } from "./FormDataType";
import { taskTypesThatSupportCustomTitles } from "./TaskTitleInput";

enum RadioButtonOption {
  Suggested = "suggested",
  ChooseCustom = "choose-custom",
}

export const TaskTypePicker = ({
  tasks,
  taskOptions,
  mode,
}: {
  tasks: TaskConfig[];
  taskOptions: TaskOption[];
  mode: Mode;
}) => {
  const formMethods = useFormContext<FormDataType>();
  const { watch, setValue } = formMethods;
  const taskType = watch("task_type");

  const selectedRadioButton = taskType
    ? getButtonOptionFromTaskType(taskType)
    : RadioButtonOption.Suggested;

  const availableTaskOptions = findAvailableSuggestedTasks(tasks, taskOptions);

  const options = useTaskTypeQuickSelectOptions(availableTaskOptions);

  const { taskOptionToID, taskOptionID, onOptionChange } = useTaskTypeOption(
    formMethods,
    taskOptions,
  );
  const selectedTaskType = formMethods.watch("task_type");

  // Gross, but the task type dropdown needs a title when we're in edit mode.
  // If the task supports a custom title there'll already be another "Title" input
  // so we'll use "Type" for those tasks.
  let typeLabel = "";
  let typeDescription = "";
  if (mode === Mode.Edit) {
    if (taskTypesThatSupportCustomTitles.includes(selectedTaskType)) {
      typeLabel = "Type";
    } else {
      typeLabel = "Title";
      typeDescription =
        "This is a built-in task, so the title cannot be customised.";
    }
  }

  return (
    <>
      {mode === Mode.Create && (
        <FormInputWrapperV2
          name="task_type"
          label="What sort of task would you like to add?"
        >
          <RadioButtonGroup
            name={"task_type_quick_select"}
            srLabel="What sort of task would you like to add?"
            options={options}
            value={selectedRadioButton}
            onChange={(value) => {
              switch (value as RadioButtonOption) {
                case RadioButtonOption.Suggested:
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore - typescript complains about undefined but for some reason resetField doesn't work. so we'll just set it manually
                  setValue<"task_type">("task_type", undefined);
                  break;
                case RadioButtonOption.ChooseCustom:
                  setValue<"task_type">("task_type", TaskType.Custom);
                  break;
              }
            }}
          />
        </FormInputWrapperV2>
      )}
      {/* single select for task type if its a suggested task type */}
      {selectedRadioButton === RadioButtonOption.Suggested && (
        <FormInputWrapperV2
          name="task_type"
          label={typeLabel}
          helptext={typeDescription}
        >
          <StaticSingleSelect
            disabled={mode === Mode.Edit}
            placeholder={"Select suggested task..."}
            options={taskOptions
              .filter(
                // Remove options that are represented by the radio buttons
                (option) => {
                  const optionType = getButtonOptionFromTaskType(
                    option.task_type as unknown as TaskType,
                  );

                  return optionType !== RadioButtonOption.ChooseCustom;
                },
              )
              .map((option) =>
                convertToSelectOption(
                  option,
                  availableTaskOptions,
                  taskOptionToID,
                ),
              )}
            value={taskOptionID}
            onChange={onOptionChange}
          />
        </FormInputWrapperV2>
      )}
    </>
  );
};

const useTaskTypeQuickSelectOptions = (availableTaskOptions) => {
  const { postmortemNameFormatted } = usePostmortemName(null);
  const { identity } = useIdentity();
  const customTasksEnabled =
    !!identity?.feature_gates?.post_incident_flow_custom_tasks;
  return [
    {
      value: RadioButtonOption.Suggested,
      label: "Suggested",
      description: `Build a task from one of our suggestions like 'Create the ${postmortemNameFormatted}'. We'll automatically mark the task as completed if we recognise that it's been done.`,
      isDisabled: availableTaskOptions.length === 0,
      isDisabledTooltipContent:
        availableTaskOptions.length === 0
          ? "All suggested tasks are already enabled."
          : undefined,
    },
    {
      value: RadioButtonOption.ChooseCustom,
      label: "Custom",
      description:
        "Define your own task e.g. 'Update the runbook'. Responders will have to check these tasks off manually.",
      isDisabled: !customTasksEnabled,
      isDisabledTooltipContent: customTasksEnabled ? undefined : (
        <UpgradeRequiredMessage
          featureName={"custom post-incident tasks"}
          gate={{ type: "boolean" }}
        />
      ),
    },
  ];
};

const getButtonOptionFromTaskType = (taskType: TaskType): RadioButtonOption => {
  switch (taskType) {
    case TaskType.Custom:
      return RadioButtonOption.ChooseCustom;
    case TaskType.SetCustomFields:
    case TaskType.SetTimestamps:
    case TaskType.ReviewFollowups:
    case TaskType.ReviewTimeline:
    case TaskType.UpdateTimestamps:
    case TaskType.AssignRole:
    case TaskType.InReviewPostmortem:
    case TaskType.CreatePostmortem:
    case TaskType.ScheduleDebrief:
    case TaskType.CompletePostmortem:
    case TaskType.SharePostmortem:
    case TaskType.UpdateIncidentSummary:
    case TaskType.GiveShoutout:
    default:
      return RadioButtonOption.Suggested;
  }
};

export const convertToSelectOption = (
  option: TaskOption,
  availableTaskOptions: TaskOption[],
  taskOptionID: (option: TaskOption) => string,
): SelectOption => {
  return {
    label: option.title,
    value: taskOptionID(option),
    icon: TaskTypeToIcon(option.task_type),
    disabled: !availableTaskOptions.includes(option),
    // Put all the disabled options at the bottom of the list
    sort_key: (!availableTaskOptions.includes(option)
      ? option.rank + 100
      : option.rank
    )
      .toString()
      .padStart(4),
  };
};

export function TaskTypeToIcon(
  taskType: PostIncidentTaskOptionTaskTypeEnum,
): IconEnum {
  switch (taskType) {
    case PostIncidentTaskOptionTaskTypeEnum.ReviewTimeline:
      return IconEnum.Clock;
    case PostIncidentTaskOptionTaskTypeEnum.UpdateTimestamps:
      return IconEnum.Clock;
    case PostIncidentTaskOptionTaskTypeEnum.SetTimestamps:
      return IconEnum.Clock;
    case PostIncidentTaskOptionTaskTypeEnum.ReviewFollowups:
      return IconEnum.FollowUps;
    case PostIncidentTaskOptionTaskTypeEnum.AssignRole:
      return IconEnum.User;
    case PostIncidentTaskOptionTaskTypeEnum.CreatePostmortem:
      return IconEnum.Doc;
    case PostIncidentTaskOptionTaskTypeEnum.ScheduleDebrief:
      return IconEnum.Calendar;
    case PostIncidentTaskOptionTaskTypeEnum.InReviewPostmortem:
      return IconEnum.Doc;
    case PostIncidentTaskOptionTaskTypeEnum.CompletePostmortem:
      return IconEnum.Doc;
    case PostIncidentTaskOptionTaskTypeEnum.SharePostmortem:
      return IconEnum.Share;
    case PostIncidentTaskOptionTaskTypeEnum.Custom:
      return IconEnum.Bulb; // We'll never really use this but let's be complete
    case PostIncidentTaskOptionTaskTypeEnum.UpdateIncidentSummary:
      return IconEnum.Message;
    case PostIncidentTaskOptionTaskTypeEnum.SetCustomFields:
      return IconEnum.CustomField;
    case PostIncidentTaskOptionTaskTypeEnum.GiveShoutout:
      return IconEnum.Heart;
    case PostIncidentTaskOptionTaskTypeEnum.ShareLearnings:
      return IconEnum.Bulb;
    default:
      assertUnreachable(taskType);
  }
  return IconEnum.Bulb;
}

// This hook attempts to abstract away the fact that the task type options
// contains both the task type and the incident role ID.
// This is because for assign role task, you could have multiple entries but each for a different role
const useTaskTypeOption = (
  formMethods: UseFormReturn<FormDataType>,
  taskOptions: TaskOption[],
) => {
  const idGenerator = (
    taskType: TaskType | PostIncidentTaskOptionTaskTypeEnum,
    incident_role_id: string | undefined,
  ) => join([taskType, incident_role_id], ":");

  const taskOptionToID = (taskOption: TaskOption) =>
    idGenerator(taskOption.task_type, taskOption.incident_role_id);

  const [taskType, incidentRoleID] = formMethods.watch([
    "task_type",
    "incident_role_id",
  ]);
  const taskOptionID = idGenerator(taskType, incidentRoleID);

  const idToOption = _.keyBy(taskOptions, (option) => taskOptionToID(option));
  const onOptionChange = (newID: string | null | undefined) => {
    if (newID) {
      const { task_type, incident_role_id } = idToOption[newID];
      formMethods.setValue<"task_type">(
        "task_type",
        task_type as unknown as TaskType,
      );
      formMethods.setValue<"incident_role_id">(
        "incident_role_id",
        incident_role_id,
      );
    }
  };
  return {
    taskOptionToID,
    taskOptionID,
    onOptionChange,
  };
};
