import { CSM } from "@incident-io/api";
import { tcx } from "src/utils/tailwind-classes";

export type ChattyProductMarketingBannerProps = {
  className?: string;
  graphic: React.ReactNode;
  cta?: React.ReactNode;
  secondaryCta?: React.ReactNode;
  ctaPosition?: "right" | "bottom";
  copy: React.ReactNode;
  csm?: CSM;
};

export function ChattyProductMarketingBanner({
  csm,
  copy,
  className,
  cta,
  secondaryCta,
  graphic,
}: ChattyProductMarketingBannerProps): React.ReactElement {
  return (
    <div
      className={tcx(
        "w-full text-sm rounded-2",
        "flex items-center gap-6 px-6",
        "bg-surface-secondary",
        className,
      )}
    >
      <div className="flex flex-col gap-4 items-start justify-center py-6 max-w-prose">
        <div>
          <ChatBubble csm={csm} />
        </div>
        <div className="grow" />
        <div className="flex flex-col gap-0.5">
          <div className="text-2xl-bold flex items-center gap-2">{copy}</div>
        </div>
        {(cta || secondaryCta) && (
          <div className="flex gap-4 items-center">
            {cta}
            {secondaryCta}
          </div>
        )}
      </div>
      <div className="grow" />
      <div className="max-sm:hidden">{graphic}</div>
    </div>
  );
}

export const ChatBubble = ({
  csm,
  className,
}: {
  csm?: CSM;
  className?: string;
}) => {
  // Lets just take the first name of the CSM
  let name = "Chris";
  if (csm) {
    name = csm.name.split(" ")[0];
  }

  return (
    <div
      className={tcx(
        "pb-4 transition-opacity",
        csm ? "opacity-100" : "opacity-0",
        className,
      )}
    >
      <div className="relative">
        <div className="relative flex flex-row items-center gap-2 bg-white p-1 pr-3 rounded-full z-10">
          <img
            src={csm?.avatar_url}
            alt={name}
            className="w-8 h-8 rounded-full"
          />
          <div className="text-sm">{name} recommends</div>
        </div>
        <div className="z-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="21"
            viewBox="0 0 30 21"
            fill="none"
            className="absolute left-0 bottom-0"
          >
            <path
              d="M9.93443 4.56774C8.42486 11.1125 4.27487 15.6631 0.598672 18.3696C-0.291833 19.0252 0.208058 20.8484 1.30852 20.7396C15.3853 19.3482 25.2805 12.7037 29.3141 8.67262C29.7051 8.28193 29.6692 7.65336 29.249 7.29427C23.252 2.16884 12.0711 -4.69591 9.93443 4.56774Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
