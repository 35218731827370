import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, ContentBox, SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import { DebriefSettings, ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { toSentenceCase } from "src/utils/formatting";
import { useAPIMutation } from "src/utils/swr";
import { usePostmortemName } from "src/utils/utils";

import { SettingsSubHeading } from "../SettingsSubHeading";

type FormData = {
  debrief_rename: string;
};

export const DebriefRenameSection = ({
  settings,
}: {
  settings: DebriefSettings;
}): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(null);

  const showToast = useToast();

  const formMethods = useForm<FormData>({
    defaultValues: {
      debrief_rename: settings.debrief_rename ?? "Debrief",
    },
  });

  const {
    setError,
    reset,
    formState: { isDirty },
  } = formMethods;
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "debriefsShowSettings",
    undefined,
    async (apiClient, formData: FormData) => {
      await apiClient.debriefsUpdateDebriefName({
        updateDebriefNameRequestBody: {
          debrief_rename: toSentenceCase(formData.debrief_rename),
        },
      });
    },
    {
      onSuccess: ({ settings }) => {
        showToast(SharedToasts.SETTINGS_SAVED);
        reset(settings);
      },
      setError,
    },
  );

  const canEdit = useIdentity().hasScope(
    ScopeNameEnum.OrganisationSettingsUpdate,
  );

  return (
    <FormV2
      onSubmit={onSubmit}
      genericError={genericError}
      saving={saving}
      formMethods={formMethods}
    >
      <SettingsSubHeading
        title="Customise debrief name"
        titleHeadingLevel={2}
        explanationClassName="mr-4 mt-1 max-w-4xl"
        explanation={`You can rename 'Debrief' to your organisation's preferred term, which will be used across the rest of the app.`}
      />
      <ContentBox className="p-6 flex-between">
        <InputV2
          placeholder="Enter a name"
          formMethods={formMethods}
          disabled={!canEdit}
          name="debrief_rename"
          className="w-[230px]"
          rules={{
            validate: (value) => {
              if (value.toLowerCase() === postmortemNameFormatted) {
                return "Your debrief name must be different to your post-mortem name";
              }

              return !!value.trim() || "Please enter a name";
            },
            required: "Please enter a name",
            maxLength: {
              value: 25,
              message: "Name cannot exceed 25 characters",
            },
          }}
        />
        <GatedButton
          type="submit"
          analyticsTrackingId={null}
          className="mb-6 md:mb-0 mt-4 md:mt-0"
          disabled={!isDirty}
          theme={ButtonTheme.Primary}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        >
          Save
        </GatedButton>
      </ContentBox>
    </FormV2>
  );
};
