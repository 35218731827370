/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface TimelineItemComment
 */
export interface TimelineItemComment {
    /**
     * When the timeline item comment was created
     * @type {Date}
     * @memberof TimelineItemComment
     */
    created_at: Date;
    /**
     * Unique identifier of the timeline item comment
     * @type {string}
     * @memberof TimelineItemComment
     */
    id: string;
    /**
     * ID of the org that owns the timeline item comment
     * @type {string}
     * @memberof TimelineItemComment
     */
    organisation_id: string;
    /**
     * Text of the timeline item comment
     * @type {string}
     * @memberof TimelineItemComment
     */
    text: string;
    /**
     * ID of the timeline item, that this comment belongs to
     * @type {string}
     * @memberof TimelineItemComment
     */
    timeline_item_id: string;
    /**
     * When the timeline item comment was last updated
     * @type {Date}
     * @memberof TimelineItemComment
     */
    updated_at: Date;
    /**
     * 
     * @type {User}
     * @memberof TimelineItemComment
     */
    user: User;
}

export function TimelineItemCommentFromJSON(json: any): TimelineItemComment {
    return TimelineItemCommentFromJSONTyped(json, false);
}

export function TimelineItemCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineItemComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'organisation_id': json['organisation_id'],
        'text': json['text'],
        'timeline_item_id': json['timeline_item_id'],
        'updated_at': (new Date(json['updated_at'])),
        'user': UserFromJSON(json['user']),
    };
}

export function TimelineItemCommentToJSON(value?: TimelineItemComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'organisation_id': value.organisation_id,
        'text': value.text,
        'timeline_item_id': value.timeline_item_id,
        'updated_at': (value.updated_at.toISOString()),
        'user': UserToJSON(value.user),
    };
}

