import { IncidentUpdatesRequestRequestBody } from "@incident-io/api";
import { Modal, Textarea } from "@incident-io/status-page-ui";
import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { useForm } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";

import { useMutation } from "../../../../utils/fetchData";

export const RequestUpdateModal = ({
  incidentId,
  onClose,
  setUpdateRequested,
}: {
  onClose: () => void;
  incidentId: string;
  setUpdateRequested: (updateRequested: boolean) => void;
}): React.ReactElement => {
  const { handleSubmit, setError, register } =
    useForm<IncidentUpdatesRequestRequestBody>();

  // This doesn't use useAPIMutation because it doesn't actually mutate any
  // state we have loaded
  const apiClient = useClient();
  const [onSubmit, { saving, genericError }] = useMutation(
    async (data) => {
      await apiClient.incidentUpdatesRequest({
        requestRequestBody: {
          incident_id: incidentId,
          message: data?.message,
        },
      });
      setUpdateRequested(true);
      onClose();
    },
    { setError },
  );

  return (
    <Modal
      as="form"
      isOpen
      title="Request an update"
      onSubmit={handleSubmit(onSubmit)}
      submitButtonText="Send"
      onClose={onClose}
      loading={saving}
      analyticsTrackingId={"internal-sp-request-update"}
    >
      <div className="space-y-4">
        <div className="text-sm">
          We&apos;ll send a message to the incident channel requesting an update
          on your behalf.
        </div>
        <Textarea
          {...register("message")}
          id="message"
          placeholder=""
          label="Message (optional)"
        />
        <ErrorMessageUI message={genericError} />
      </div>
    </Modal>
  );
};
