/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';

/**
 * 
 * @export
 * @interface IncidentChannelConfigsCreateQuickActionRequestBody
 */
export interface IncidentChannelConfigsCreateQuickActionRequestBody {
    /**
     * Type of quick action
     * @type {string}
     * @memberof IncidentChannelConfigsCreateQuickActionRequestBody
     */
    action_type: IncidentChannelConfigsCreateQuickActionRequestBodyActionTypeEnum;
    /**
     * What conditions must be true for this quick action to be enabled in an incident channel?
     * @type {Array<ConditionGroupPayload>}
     * @memberof IncidentChannelConfigsCreateQuickActionRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * Emoji to use when the quick action is of the custom type
     * @type {string}
     * @memberof IncidentChannelConfigsCreateQuickActionRequestBody
     */
    custom_emoji?: string;
    /**
     * ID of the custom field when the quick action involves a custom field
     * @type {string}
     * @memberof IncidentChannelConfigsCreateQuickActionRequestBody
     */
    custom_field_id?: string;
    /**
     * Text to use when the quick action is of the custom type
     * @type {string}
     * @memberof IncidentChannelConfigsCreateQuickActionRequestBody
     */
    custom_text?: string;
    /**
     * URL to use when the quick action is of the custom type
     * @type {string}
     * @memberof IncidentChannelConfigsCreateQuickActionRequestBody
     */
    custom_url?: string;
    /**
     * ID of the incident role when the quick action involves a role
     * @type {string}
     * @memberof IncidentChannelConfigsCreateQuickActionRequestBody
     */
    incident_role_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum IncidentChannelConfigsCreateQuickActionRequestBodyActionTypeEnum {
    ViewRecap = 'quick_actions_view_recap',
    UpdateSummary = 'quick_actions_update_summary',
    ShareUpdate = 'quick_actions_share_update',
    ResolveIncident = 'quick_actions_resolve_incident',
    Escalate = 'quick_actions_escalate',
    TakeRole = 'quick_actions_take_role',
    JoinCall = 'quick_actions_join_call',
    UpdateStatusPage = 'quick_actions_update_status_page',
    SetCustomField = 'quick_actions_set_custom_field',
    SetFields = 'quick_actions_set_fields',
    ViewAllCommands = 'quick_actions_view_all_commands',
    CreateStream = 'quick_actions_create_stream',
    Custom = 'quick_actions_custom'
}

export function IncidentChannelConfigsCreateQuickActionRequestBodyFromJSON(json: any): IncidentChannelConfigsCreateQuickActionRequestBody {
    return IncidentChannelConfigsCreateQuickActionRequestBodyFromJSONTyped(json, false);
}

export function IncidentChannelConfigsCreateQuickActionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentChannelConfigsCreateQuickActionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action_type': json['action_type'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'custom_emoji': !exists(json, 'custom_emoji') ? undefined : json['custom_emoji'],
        'custom_field_id': !exists(json, 'custom_field_id') ? undefined : json['custom_field_id'],
        'custom_text': !exists(json, 'custom_text') ? undefined : json['custom_text'],
        'custom_url': !exists(json, 'custom_url') ? undefined : json['custom_url'],
        'incident_role_id': !exists(json, 'incident_role_id') ? undefined : json['incident_role_id'],
    };
}

export function IncidentChannelConfigsCreateQuickActionRequestBodyToJSON(value?: IncidentChannelConfigsCreateQuickActionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action_type': value.action_type,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'custom_emoji': value.custom_emoji,
        'custom_field_id': value.custom_field_id,
        'custom_text': value.custom_text,
        'custom_url': value.custom_url,
        'incident_role_id': value.incident_role_id,
    };
}

