/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsightsUpdateDashboardRequestBody
 */
export interface InsightsUpdateDashboardRequestBody {
    /**
     * Name of this dashboard
     * @type {string}
     * @memberof InsightsUpdateDashboardRequestBody
     */
    name: string;
    /**
     * URL parameters that should be applied to this dashboard
     * @type {string}
     * @memberof InsightsUpdateDashboardRequestBody
     */
    url_params: string;
    /**
     * Visibility type of this dashboard
     * @type {string}
     * @memberof InsightsUpdateDashboardRequestBody
     */
    visibility: InsightsUpdateDashboardRequestBodyVisibilityEnum;
}

/**
* @export
* @enum {string}
*/
export enum InsightsUpdateDashboardRequestBodyVisibilityEnum {
    Personal = 'personal',
    Shared = 'shared'
}

export function InsightsUpdateDashboardRequestBodyFromJSON(json: any): InsightsUpdateDashboardRequestBody {
    return InsightsUpdateDashboardRequestBodyFromJSONTyped(json, false);
}

export function InsightsUpdateDashboardRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsUpdateDashboardRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'url_params': json['url_params'],
        'visibility': json['visibility'],
    };
}

export function InsightsUpdateDashboardRequestBodyToJSON(value?: InsightsUpdateDashboardRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'url_params': value.url_params,
        'visibility': value.visibility,
    };
}

