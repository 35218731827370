/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomEventPayload,
    CustomEventPayloadFromJSON,
    CustomEventPayloadFromJSONTyped,
    CustomEventPayloadToJSON,
} from './CustomEventPayload';
import {
    TimelineNotePayload,
    TimelineNotePayloadFromJSON,
    TimelineNotePayloadFromJSONTyped,
    TimelineNotePayloadToJSON,
} from './TimelineNotePayload';

/**
 * 
 * @export
 * @interface TimelineItemsUpdateRequestBody
 */
export interface TimelineItemsUpdateRequestBody {
    /**
     * 
     * @type {CustomEventPayload}
     * @memberof TimelineItemsUpdateRequestBody
     */
    custom_event?: CustomEventPayload;
    /**
     * When the item occured
     * @type {Date}
     * @memberof TimelineItemsUpdateRequestBody
     */
    occured_at: Date;
    /**
     * 
     * @type {TimelineNotePayload}
     * @memberof TimelineItemsUpdateRequestBody
     */
    timeline_note?: TimelineNotePayload;
}

export function TimelineItemsUpdateRequestBodyFromJSON(json: any): TimelineItemsUpdateRequestBody {
    return TimelineItemsUpdateRequestBodyFromJSONTyped(json, false);
}

export function TimelineItemsUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineItemsUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_event': !exists(json, 'custom_event') ? undefined : CustomEventPayloadFromJSON(json['custom_event']),
        'occured_at': (new Date(json['occured_at'])),
        'timeline_note': !exists(json, 'timeline_note') ? undefined : TimelineNotePayloadFromJSON(json['timeline_note']),
    };
}

export function TimelineItemsUpdateRequestBodyToJSON(value?: TimelineItemsUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_event': CustomEventPayloadToJSON(value.custom_event),
        'occured_at': (value.occured_at.toISOString()),
        'timeline_note': TimelineNotePayloadToJSON(value.timeline_note),
    };
}

