import {
  IncidentRole,
  IncidentRoleRoleTypeEnum,
  IncidentRoleWithoutConditionsRoleTypeEnum,
  StreamsCreateRequestBody,
  StreamsCreateRequestBodyVisibilityEnum as VisibilityEnum,
  User,
} from "@incident-io/api";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import {
  DynamicMultiSelectV2,
  DynamicSingleSelectV2,
} from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  ToastTheme,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm, UseFormReturn } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useSettings } from "../../../../hooks/useSettings";
import { useIncident } from "../hooks";

type FormData = StreamsCreateRequestBody & { lead: string };

export const StreamsCreateDrawer = ({
  onClose,
  incidentId,
}: {
  onClose: () => void;
  incidentId: string;
}) => {
  const formMethods = useForm<StreamsCreateRequestBody & { lead: string }>({
    defaultValues: {
      name: "",
      lead: "",
      visibility: VisibilityEnum.Public,
      users_to_invite: [],
    },
  });

  const showToast = useToast();
  const { settings } = useSettings();

  const {
    data: { incident_roles: incidentRoles },
    isLoading: incidentRolesLoading,
  } = useAPI("incidentRolesList", undefined, {
    fallbackData: { incident_roles: [] },
  });

  const leadRole = incidentRoles.find(
    (role) => role.role_type === IncidentRoleRoleTypeEnum.Lead,
  );

  const { trigger, isMutating } = useAPIMutation(
    "streamsList",
    {
      parentId: incidentId,
    },
    async (apiClient, body: StreamsCreateRequestBody & { lead: string }) => {
      const leadRoleId = leadRole?.id;
      await apiClient.streamsCreate({
        createRequestBody: {
          ...body,
          parent_id: incidentId,
          idempotency_key: Math.random().toString(36).substring(2),
          incident_role_assignments: leadRoleId
            ? [
                {
                  incident_role_id: leadRoleId,
                  assignee: {
                    id: body.lead,
                  },
                },
              ]
            : [],
        },
      });
    },
    {
      onSuccess: () => {
        onClose();
        showToast({
          title: "Stream created",
          theme: ToastTheme.Success,
        });
      },
      onError: (error, fieldErrors) => {
        if (!fieldErrors) {
          showToast({
            title: "Stream creation failed",
            description:
              error.message ??
              "Something unexpected happened. Please try again.",
            theme: ToastTheme.Error,
          });
        }
      },
      setError: formMethods.setError,
    },
  );

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          title="Create stream"
          icon={IconEnum.GitBranch}
          onClose={onClose}
          color={ColorPaletteEnum.Purple}
        />
        <DrawerBody className="flex grow">
          <StreamsCreateForm
            trigger={trigger}
            formMethods={formMethods}
            isMutating={isMutating}
            incidentId={incidentId}
            incidentRolesLoading={incidentRolesLoading}
            leadRole={leadRole}
            privateStreamsEnabled={settings?.misc.private_streams_enabled}
          />
        </DrawerBody>
        <DrawerFooter className="flex justify-end">
          <Button
            type="submit"
            form="create-stream"
            analyticsTrackingId={"create-stream"}
            theme={ButtonTheme.Primary}
            loading={isMutating}
            disabled={!formMethods.formState.isValid}
          >
            Create
          </Button>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};

const StreamsCreateForm = ({
  trigger,
  formMethods,
  isMutating,
  incidentId,
  incidentRolesLoading,
  leadRole,
  privateStreamsEnabled,
}: {
  trigger: (data: FormData) => void;
  formMethods: UseFormReturn<FormData>;
  isMutating: boolean;
  incidentId: string;
  incidentRolesLoading: boolean;
  leadRole?: IncidentRole;
  privateStreamsEnabled?: boolean;
}) => {
  const client = useClient();
  const { incident } = useIncident(incidentId);
  const streamLead = formMethods.watch("lead");
  const incidentLead = incident?.incident_role_assignments.find(
    (assignment) =>
      assignment.role.role_type ===
      IncidentRoleWithoutConditionsRoleTypeEnum.Lead,
  );
  const streamLeadSameAsParent =
    incidentLead?.assignee?.id === streamLead &&
    incidentLead?.assignee !== undefined;

  const privateStreamSelected =
    formMethods.watch("visibility") === VisibilityEnum.Private;

  if (incidentRolesLoading || !incident) {
    return <Loader />;
  }

  if (!leadRole) {
    return <GenericErrorMessage />;
  }

  return (
    <FormV2
      id="create-stream"
      onSubmit={trigger}
      formMethods={formMethods}
      saving={isMutating}
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        required={true}
        autoFocus={true}
        placeholder="Choose a descriptive name for this stream"
        helptext="It's best to choose a name that is clear and concise. Think about what the focus of the stream will be, e.g Engineering, Legal or Customer Communications"
      />
      <DynamicSingleSelectV2
        formMethods={formMethods}
        name="lead"
        label={leadRole.stream_name}
        required={true}
        isClearable={false}
        loadOptions={getTypeaheadOptions(client, TypeaheadTypeEnum.User, {
          incidentId: incidentId,
        })}
        hydrateOptions={hydrateInitialSelectOptions(
          client,
          TypeaheadTypeEnum.User,
        )}
        placeholder="Select user to assign"
        helptext="Every stream needs a lead assigned, but you can change this in the future"
      />
      {streamLeadSameAsParent && (
        <StreamLeadSameAsParentWarning
          lead={incidentLead.assignee}
          leadRole={leadRole}
        />
      )}
      {privateStreamsEnabled && (
        <div className="space-y-4">
          <StaticSingleSelectV2
            formMethods={formMethods}
            name="visibility"
            label="Who should be able to access this stream?"
            required={true}
            isClearable={false}
            options={[
              { label: "Everyone (public)", value: VisibilityEnum.Public },
              {
                label: "Only invited users (private)",
                value: VisibilityEnum.Private,
              },
            ]}
            helptext="Choose whether everyone should be able to join this stream, and view its updates."
          />
          {privateStreamSelected && <PrivateStreamInfo leadRole={leadRole} />}
        </div>
      )}
      <DynamicMultiSelectV2
        formMethods={formMethods}
        name="users_to_invite"
        label="Participants"
        loadOptions={getTypeaheadOptions(client, TypeaheadTypeEnum.User, {
          incidentId: incidentId,
        })}
        hydrateOptions={hydrateInitialSelectOptions(
          client,
          TypeaheadTypeEnum.User,
        )}
        required={false}
        placeholder="Select users to add to the stream"
        helptext="We'll invite these users to the stream channel"
      />
    </FormV2>
  );
};

const StreamLeadSameAsParentWarning = ({
  lead,
  leadRole,
}: {
  lead?: User;
  leadRole: IncidentRole;
}) => {
  // This shouldn't happen but don't show a warning if we don't have a lead
  if (!lead) {
    return null;
  }
  return (
    <Callout theme={CalloutTheme.Warning}>
      <div className="text-content-primary">
        {lead.name} is the {leadRole.name.toLowerCase()} of the{" "}
        <span className="font-semibold">main</span> incident. You should set a
        different{" "}
        {leadRole.stream_name ? leadRole.stream_name.toLowerCase() : "lead"} for
        the <span className="font-semibold">stream.</span>
      </div>
    </Callout>
  );
};

const PrivateStreamInfo = ({ leadRole }: { leadRole: IncidentRole }) => {
  return (
    <Callout theme={CalloutTheme.Info}>
      <div className="text-content-primary">
        The <span className="font-semibold">stream name</span> and{" "}
        <span className="font-semibold">
          {leadRole.stream_name ? leadRole.stream_name.toLowerCase() : "lead"}
        </span>{" "}
        will be visible to everyone, but access to the stream channel, it&apos;s
        updates and all communication within it will be restricted to the
        selected participants.
      </div>
    </Callout>
  );
};
