import {
  IssueTrackerIssueSyncFailure,
  IssueTrackersGetAllIssueTrackerIssueSyncFailuresIssueTrackerProviderEnum,
} from "@incident-io/api";
import {
  Accordion,
  AccordionProvider,
  AccordionTriggerButton,
  Button,
  ButtonTheme,
  ContentBox,
  LocalDateTime,
  Txt,
} from "@incident-ui";
import { useAPI } from "src/utils/swr";

import { formatDurationShort } from "../../../../../utils/datetime";

export const enum IssueTrackerSyncType {
  FollowUp = "follow-up",
  Incident = "incident",
}

export const IssueTrackerSyncFailureActivity = ({
  issueTrackerProvider,
  syncType,
}: {
  issueTrackerProvider?: IssueTrackersGetAllIssueTrackerIssueSyncFailuresIssueTrackerProviderEnum;
  syncType: IssueTrackerSyncType;
}): React.ReactElement | null => {
  const { data: incidentSyncFailures } = useAPI(
    "issueTrackersGetAllIssueTrackerIssueSyncFailures",
    {
      issueTrackerProvider: issueTrackerProvider,
    },
  );

  if (!incidentSyncFailures || !incidentSyncFailures.sync_failures) {
    return null;
  }

  const filteredForType = filterForType(
    incidentSyncFailures.sync_failures,
    syncType,
  );

  // If we got nothing once filtered, return null
  if (filteredForType.length === 0) {
    return null;
  }

  return (
    <ContentBox className="py-1 pl-4 mt-2 mb-1 mr-1 shadow-none bg-red-50 border-red-200">
      <AccordionProvider type="single" collapsible>
        <Accordion id="text-example" header={<AccordionHeader />}>
          <div className="mb-4 mr-4">
            {<RenderSyncFailures sync_failures={filteredForType} />}
          </div>
        </Accordion>
      </AccordionProvider>
    </ContentBox>
  );
};

const AccordionHeader = () => (
  <div className="flex justify-between items-center">
    <div className="flex items-center">
      <p className="text-sm flex flex-col text-content-primary font-medium grow mr-2.5">
        View latest failures
      </p>
    </div>

    <AccordionTriggerButton />
  </div>
);

const RenderSyncFailures = ({
  sync_failures,
}: {
  sync_failures: IssueTrackerIssueSyncFailure[];
}) => {
  return (
    <>
      {sync_failures.slice(0, 4).map((issue: IssueTrackerIssueSyncFailure) => (
        <div key={issue.id} className="pt-2">
          <Issue issue={issue} />
        </div>
      ))}
    </>
  );
};

const Issue = ({ issue: issue }: { issue: IssueTrackerIssueSyncFailure }) => {
  const incidentLink = getLinkToErrorSource(issue);

  return (
    <div className="">
      <Txt bold className="flex flex-row">
        <LocalDateTime timestamp={issue.created_at} showIcon>
          {formatDurationShort(issue.created_at, new Date(), {
            max: { hours: 12 },
            suffix: "ago",
          })}
        </LocalDateTime>
        {incidentLink}
      </Txt>
      <div className="overflow-x-auto bg-surface-invert rounded-2 mt-2 w-full">
        <code className="text-sm block text-left text-white p-4 pl-6 break-words">
          {issue.raw_error_json}
        </code>
      </div>
    </div>
  );
};

const filterForType = (
  syncFailures: IssueTrackerIssueSyncFailure[],
  type: IssueTrackerSyncType,
) => {
  return syncFailures.filter((syncFailure) => {
    return syncFailure.followup_title
      ? type === IssueTrackerSyncType.FollowUp
      : type === IssueTrackerSyncType.Incident;
  });
};

const getLinkToErrorSource = (issue: IssueTrackerIssueSyncFailure) => {
  if (issue.incident_id) {
    const linkProps = {
      analyticsTrackingId: issue.followup_title
        ? "issue-sync-run-followup-link"
        : "issue-sync-run-incident-link",
      theme: ButtonTheme.Link,
      href: `/incidents/${issue.incident_id}`,
      children: issue.incident_reference,
    };

    return (
      <>
        <div className="font-light mx-1">{" in "}</div>
        <Button {...linkProps} />
      </>
    );
  }

  return null;
};
