import {
  Icon,
  IconEnum,
  IconSize,
  Link,
  LocalDateTime,
  Tooltip,
} from "@incident-ui";
import React from "react";
import {
  ExternalGoogleCalendarEvent,
  ExternalResource,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import {
  formatDurationShort,
  formatRelativeTimestamp,
} from "../../../../utils/datetime";
import { AttachmentContexts } from "./IncidentAttachment";

export const GoogleCalendarEvent = ({
  className,
  event,
  resource,
}: {
  className?: string;
  resource: ExternalResource;
  event?: ExternalGoogleCalendarEvent;
  context: AttachmentContexts;
}): React.ReactElement => {
  const isFutureEvent = event?.start && new Date() < event.start;
  const timeToEventStart = formatRelativeTimestamp(event?.start ?? new Date());
  const prefix = isFutureEvent ? `In ${timeToEventStart}` : timeToEventStart;
  return (
    <div className={tcx("flex", className)}>
      <Icon
        id={IconEnum.GoogleCalendar}
        size={IconSize.Medium}
        className="text-content-tertiary mr-6 shrink-0 font-semibold mt-1"
      />
      <div>
        <div className="font-medium mb-1">
          <Link
            href={resource.permalink}
            openInNewTab
            analyticsTrackingId={null}
          >
            {resource.title}
          </Link>
        </div>
        {event && (
          <div className={tcx("flex space-x-2", "text-slate-700")}>
            <span className="flex-center-y">
              <Icon
                id={IconEnum.Calendar}
                className="text-content-tertiary mr-1"
              />
              <span>
                {prefix}
                {" on "}
                <LocalDateTime timestamp={event.start} />
                <span>
                  {"  "}({formatDurationShort(event.start, event.end)})
                </span>
              </span>
            </span>
            {event.organiser_email !== "" && (
              <span className="flex-center-y">
                <Icon
                  id={IconEnum.User}
                  className="text-content-tertiary mr-1"
                />
                <Tooltip content="The organizer, who created the Google Calendar event.">
                  <div>
                    <span>{event.organiser_email}</span>
                  </div>
                </Tooltip>
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
