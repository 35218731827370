/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Reference to a resource that is dependent upon the parent
 * @export
 * @interface DependentResource
 */
export interface DependentResource {
    /**
     * Whether this resource can be automatically deleted when the parent is deleted
     * @type {boolean}
     * @memberof DependentResource
     */
    can_be_auto_deleted: boolean;
    /**
     * ID of resouce
     * @type {string}
     * @memberof DependentResource
     */
    id: string;
    /**
     * Human readable label for the resource
     * @type {string}
     * @memberof DependentResource
     */
    label: string;
    /**
     * Type of resource
     * @type {string}
     * @memberof DependentResource
     */
    resource_type: DependentResourceResourceTypeEnum;
    /**
     * The plural name of the resource type
     * @type {string}
     * @memberof DependentResource
     */
    resource_type_label: string;
    /**
     * The url of the location in settings where this can be fixed
     * @type {string}
     * @memberof DependentResource
     */
    settings_url?: string;
}

/**
* @export
* @enum {string}
*/
export enum DependentResourceResourceTypeEnum {
    AiConfig = 'ai_config',
    AlertConfig = 'alert_config',
    AlertRoute = 'alert_route',
    AlertSourceConfig = 'alert_source_config',
    AnnouncementRule = 'announcement_rule',
    CatalogType = 'catalog_type',
    CustomField = 'custom_field',
    DebriefInviteRule = 'debrief_invite_rule',
    DebriefSetting = 'debrief_setting',
    EscalationPath = 'escalation_path',
    FollowUpIssueTemplate = 'follow_up_issue_template',
    IssueTrackerSyncConfig = 'issue_tracker_sync_config',
    IncidentAutoSubscribeRule = 'incident_auto_subscribe_rule',
    IncidentForm = 'incident_form',
    IncidentFormEscalationElement = 'incident_form_escalation_element',
    IncidentLifecycle = 'incident_lifecycle',
    IncidentRole = 'incident_role',
    IncidentTicketTemplate = 'incident_ticket_template',
    IncidentTimestamp = 'incident_timestamp',
    IncidentTriggerConfig = 'incident_trigger_config',
    InternalStatusPage = 'internal_status_page',
    InternalStatusPageAutomationRule = 'internal_status_page_automation_rule',
    InternalStatusPageComponentConfiguration = 'internal_status_page_component_configuration',
    JiraCloudSyncConfig = 'jira_cloud_sync_config',
    PostIncidentTaskConfig = 'post_incident_task_config',
    Nudge = 'nudge',
    Policy = 'policy',
    Schedule = 'schedule',
    SlackBookmark = 'slack_bookmark',
    SlackQuickAction = 'slack_quick_action',
    StatusPages = 'status_pages',
    VantaConfig = 'vanta_config',
    Workflow = 'workflow',
    IncidentDurationMetric = 'incident_duration_metric'
}

export function DependentResourceFromJSON(json: any): DependentResource {
    return DependentResourceFromJSONTyped(json, false);
}

export function DependentResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DependentResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'can_be_auto_deleted': json['can_be_auto_deleted'],
        'id': json['id'],
        'label': json['label'],
        'resource_type': json['resource_type'],
        'resource_type_label': json['resource_type_label'],
        'settings_url': !exists(json, 'settings_url') ? undefined : json['settings_url'],
    };
}

export function DependentResourceToJSON(value?: DependentResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_be_auto_deleted': value.can_be_auto_deleted,
        'id': value.id,
        'label': value.label,
        'resource_type': value.resource_type,
        'resource_type_label': value.resource_type_label,
        'settings_url': value.settings_url,
    };
}

