import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { Loader } from "@incident-ui";
import React from "react";
import { Route, Routes } from "react-router";

import { useIdentity } from "../../contexts/IdentityContext";
import { FollowUpsDashboard } from "./dashboards/followups/FollowUpsDashboard";
import { InsightsListPage } from "./dashboards/InsightsListPage";
import { MTTXDashboard } from "./dashboards/mttx/MTTXDashboard";
import { OverviewDashboard } from "./dashboards/overview/OverviewDashboard";
import { ExternalPagerLoadDashboard } from "./dashboards/pager-load/ExternalPagerLoadDashboard";
import { NativePagerLoadDashboard } from "./dashboards/pager-load/NativePagerLoadDashboard";
import { PostIncidentFlowDashboard } from "./dashboards/pinc-flow/PostIncidentFlowDashboard";
import { TeamsDashboard } from "./dashboards/team/TeamsDashboard";
import { TimeSpentOnIncidentsDashboard } from "./dashboards/time-spent-on-incidents/TimeSpentOnIncidentsDashboard";

export const InsightsRoute = (): React.ReactElement => {
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;

  // Really important to wait for loading to finish before proceding, to ensure
  // the URL parameters are set correctly.
  if (!identity) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route path="" element={<InsightsListPage />} />
      {insightsFeatureGate && (
        <>
          <Route path="pager-load" element={<NativePagerLoadDashboard />} />
          <Route
            path="external-pager-load"
            element={<ExternalPagerLoadDashboard />}
          />
          <Route path="overview" element={<OverviewDashboard />} />
          <Route path="teams" element={<TeamsDashboard />} />
          <Route
            path="post-incident-flow"
            element={<PostIncidentFlowDashboard />}
          />
          <Route path="follow-ups" element={<FollowUpsDashboard />} />
          <Route
            path="time-spent-on-incidents"
            element={<TimeSpentOnIncidentsDashboard />}
          />
          <Route path="mttx" element={<MTTXDashboard />} />
        </>
      )}
      <Route path="*" element={<OrgAwareNavigate to="/404" replace />} />
    </Routes>
  );
};
