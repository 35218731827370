import { DebriefSettings, EngineScope, TextNode } from "@incident-io/api";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, ContentBox, SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIsDirtyWithTemplatedText } from "src/hooks/useIsDirtyWithTemplatedText";
import { useAPIMutation } from "src/utils/swr";
import { useDebriefName } from "src/utils/utils";

import { SettingsSubHeading } from "../SettingsSubHeading";

type FormData = {
  event_title: TextNode;
  event_description: TextNode;
};

export const DebriefEventDetailsSection = ({
  incidentScope,
  settings,
}: {
  incidentScope: EngineScope;
  settings: DebriefSettings;
}): React.ReactElement | null => {
  const showToast = useToast();
  const { debriefNameLower } = useDebriefName();

  const defaultValues: FormData = {
    event_title: settings.event_title ?? { type: "text" },
    event_description: settings.event_description ?? { type: "text" },
  };

  const formMethods = useForm<FormData>({
    mode: "onSubmit",
    defaultValues,
  });

  const { setError, reset } = formMethods;
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "debriefsShowSettings",
    undefined,
    async (apiClient, formData: FormData) => {
      await apiClient.debriefsUpdateDebriefTitleAndDescription({
        updateDebriefTitleAndDescriptionRequestBody: {
          event_title: formData.event_title,
          event_description: formData.event_description,
        },
      });
    },
    {
      onSuccess: ({ settings }) => {
        showToast(SharedToasts.SETTINGS_SAVED);
        reset(settings);
      },
      setError,
    },
  );

  const isDirty = useIsDirtyWithTemplatedText({
    values: formMethods.getValues(),
    formState: formMethods.formState,
    textFieldNames: ["event_title", "event_description"],
  });

  return (
    <FormV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      saving={saving}
      genericError={genericError}
    >
      <SettingsSubHeading
        title={"Event details"}
        titleHeadingLevel={2}
        explanationClassName="mr-4 mt-1 max-w-4xl"
        explanation={
          <>
            Choose the default title and description for the debrief event we
            pre-populate for you. You should include the incident reference
            (e.g. INC-123) and the word &quot;debrief&quot;{" "}
            {debriefNameLower !== "debrief" && `or "${debriefNameLower}" `}
            somewhere so we can attach it to the right incident.
          </>
        }
      />
      <ContentBox className="p-6 flex">
        <div className="flex flex-grow flex-col space-y-4">
          <TemplatedTextInputV2
            className="space-y-2"
            scope={incidentScope}
            formMethods={formMethods}
            name="event_title"
            format="plain"
            includeVariables
            includeExpressions={false}
            label="Event Title"
            required="Please provide a title"
            multiLine={false}
          />

          <TemplatedTextInputV2
            className="space-y-2"
            scope={incidentScope}
            formMethods={formMethods}
            name="event_description"
            format="plain"
            includeVariables
            includeExpressions={false}
            label="Event Description"
            required="Please provide a description"
            multiLine={true}
          />
        </div>
        <GatedButton
          type="submit"
          analyticsTrackingId={null}
          className="mb-6 md:mb-0 mt-4 md:mt-0 ml-8"
          disabled={!isDirty}
          theme={ButtonTheme.Primary}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        >
          Save
        </GatedButton>
      </ContentBox>
    </FormV2>
  );
};
