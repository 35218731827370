import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";

export const AlertSourceBugSnagSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Log in to Bugsnag and navigate to the project settings page for the
        project you want to integrate with incident.io.
      </p>
      <p>
        Click on &rsquo;Data forwarding&rsquo; within the &rsquo;Integrations
        and email&rsquo; section of project settings.
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>Click on &rsquo;Webhook&rsquo;, and enter the following details:</>
        }
        blocks={[
          {
            title: "Webhook URL",
            code: `${publicApiUrl()}/v2/alert_events/bugsnag/${
              alertSourceConfig.id
            }`,
          },
        ]}
      />
      <p>Test the integration by clicking on the &rsquo;Test&rsquo; button.</p>
      <p>
        Configure the types of events you want to send to incident.io in the
        integrations settings page.
      </p>
    </SetupInfoNumberedList>
  );
};
