import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { GenericErrorMessage, IconEnum, IconSize, Loader } from "@incident-ui";
import { AppDrawerContainer } from "@incident-ui/Drawer/AppDrawerContainer";
import { useDrawer } from "@incident-ui/Drawer/DrawerProvider";
import { ErrorBoundary } from "@sentry/react";
import { Helmet } from "react-helmet";
import { tcx } from "src/utils/tailwind-classes";

import { BreadcrumbLink } from "../Breadcrumbs/Breadcrumbs";
import { HeaderBar } from "../HeaderBar/HeaderBar";

export enum PageWidth {
  Full = "full",
  Wide = "wide",
  Medium = "medium",
  Narrow = "narrow",
}

export const pageWidthToStyles: { [key in PageWidth]: string } = {
  [PageWidth.Full]: "",
  [PageWidth.Wide]: "max-w-[2000px]",
  [PageWidth.Medium]: "max-w-[1368px]",
  [PageWidth.Narrow]: "max-w-[960px]",
};

export type PagerWrapperProps = {
  children: React.ReactNode;
  loading?: boolean;
  banner?: React.ReactNode;
  width: PageWidth;

  // Layout options
  overflowX?: boolean;
  overflowY?: boolean;
  noPadding?: boolean;
  noHeader?: boolean;

  // Header bar
  accessory?: React.ReactNode;
  title: string;
  titleNode?: React.ReactNode;
  titleAccessory?: React.ReactNode;
  titleInputRef?: React.RefObject<HTMLDivElement>;
  subtitle?: React.ReactNode;
  crumbs?: BreadcrumbLink[];
  backHref?: string;

  className?: string;
  onEditTitle?: () => void;

  icon?: IconEnum | React.ReactNode;
  iconSize?: IconSize;
  color?: ColorPaletteEnum;
  headerNode?: React.ReactNode;
  dottedBackground?: boolean;
};

export function PageWrapper({
  children,
  ...props
}: PagerWrapperProps): React.ReactElement {
  return (
    <div
      className={tcx(
        "min-h-full flex flex-col min-w-0 h-full bg-white overflow-hidden",
        props.className,
      )}
    >
      <Helmet
        title={props.title ? `${props.title} - incident.io` : "incident.io"}
      />
      {props.loading ? (
        <div className={"pb-24"}>
          <Loader />
        </div>
      ) : (
        <>
          <PageWrapperInner {...props}>{children}</PageWrapperInner>
        </>
      )}
    </div>
  );
}

const PageWrapperInner = ({
  children,
  accessory,
  banner,
  title,
  titleNode,
  // We default to only vertical scrolling, as that's how most of our app works
  overflowX = false,
  overflowY = true,
  noPadding,
  noHeader,
  onEditTitle,
  width,
  titleInputRef,
  subtitle,
  crumbs,
  titleAccessory,
  headerNode,
  backHref,
  icon,
  iconSize,
  color,
  dottedBackground,
}: PagerWrapperProps) => {
  const { pageDrawerRefLeft, pageDrawerRefRight, pageDrawerRef } = useDrawer();
  return (
    <>
      <ErrorBoundary fallback={<GenericErrorMessage />}>
        <div ref={pageDrawerRef}></div>
        {headerNode
          ? headerNode
          : !noHeader && (
              <HeaderBar
                titleAccessory={titleAccessory}
                title={title}
                titleNode={titleNode}
                onEditTitle={onEditTitle}
                subtitle={subtitle}
                crumbs={crumbs}
                accessory={accessory}
                backHref={backHref}
                ref={titleInputRef}
                icon={icon as IconEnum}
                iconSize={iconSize}
                color={color}
                className="px-4 md:px-8"
              />
            )}
        {banner}
        <div
          className={tcx(
            "flex flex-auto",
            overflowY ? "overflow-y-auto" : "overflow-y-hidden",
          )}
        >
          <AppDrawerContainer ref={pageDrawerRefLeft} />
          <div
            className={tcx(
              // 89px is the height of the header
              //   - 40px is comes from `basis-40`
              //   - 48px comes from the margin of the header
              //   - 1px comes from the border of the header
              //
              // 8px is the height of the gutter
              //
              // 89 + 8 + 8 = 105
              "w-full h-full max-h-[calc(100vh-105px)]",
              overflowY ? "overflow-y-auto" : "overflow-y-hidden",
              overflowX ? "overflow-x-auto" : "overflow-x-hidden",
              {
                " bg-slate-50 bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]":
                  dottedBackground,
              },
            )}
          >
            <div
              className={tcx(
                "flex flex-col mx-auto w-full min-h-[100%] h-full",
                {
                  "h-fit": overflowY,
                  "min-w-fit": overflowX,
                  "px-4 md:px-8 py-4 md:py-6": !noPadding,
                },
                pageWidthToStyles[width],
              )}
            >
              {children}
            </div>
          </div>
          <AppDrawerContainer ref={pageDrawerRefRight} />
        </div>
      </ErrorBoundary>
    </>
  );
};
