/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PolicyReportChannel,
    PolicyReportChannelFromJSON,
    PolicyReportChannelFromJSONTyped,
    PolicyReportChannelToJSON,
} from './PolicyReportChannel';

/**
 * 
 * @export
 * @interface PoliciesCreateReportScheduleRequestBody
 */
export interface PoliciesCreateReportScheduleRequestBody {
    /**
     * The channels of communication that the report will be sent to
     * @type {Array<PolicyReportChannel>}
     * @memberof PoliciesCreateReportScheduleRequestBody
     */
    channels: Array<PolicyReportChannel>;
    /**
     * The country code of the timezone the report is scheduled in
     * @type {string}
     * @memberof PoliciesCreateReportScheduleRequestBody
     */
    country_code: string;
    /**
     * The day of the month the report should be sent
     * @type {number}
     * @memberof PoliciesCreateReportScheduleRequestBody
     */
    day_of_month?: number;
    /**
     * The day of the week the report should be sent
     * @type {string}
     * @memberof PoliciesCreateReportScheduleRequestBody
     */
    day_of_week?: PoliciesCreateReportScheduleRequestBodyDayOfWeekEnum;
    /**
     * The hour of the day the report should be sent
     * @type {number}
     * @memberof PoliciesCreateReportScheduleRequestBody
     */
    hour: number;
    /**
     * How often the report should be sent
     * @type {string}
     * @memberof PoliciesCreateReportScheduleRequestBody
     */
    interval: PoliciesCreateReportScheduleRequestBodyIntervalEnum;
    /**
     * Human readable name of the schedule
     * @type {string}
     * @memberof PoliciesCreateReportScheduleRequestBody
     */
    name: string;
    /**
     * IDs of the policies to include in the report
     * @type {Array<string>}
     * @memberof PoliciesCreateReportScheduleRequestBody
     */
    policy_ids: Array<string>;
    /**
     * Whether this report should be suppressed if there are no violations
     * @type {boolean}
     * @memberof PoliciesCreateReportScheduleRequestBody
     */
    suppress_if_empty: boolean;
    /**
     * The timezone the report is scheduled in
     * @type {string}
     * @memberof PoliciesCreateReportScheduleRequestBody
     */
    time_zone: string;
}

/**
* @export
* @enum {string}
*/
export enum PoliciesCreateReportScheduleRequestBodyDayOfWeekEnum {
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
    Sunday = 'sunday'
}/**
* @export
* @enum {string}
*/
export enum PoliciesCreateReportScheduleRequestBodyIntervalEnum {
    Daily = 'daily',
    DailyOnWeekdays = 'daily_on_weekdays',
    Weekly = 'weekly',
    Monthly = 'monthly',
    MonthlyOnWeekdays = 'monthly_on_weekdays'
}

export function PoliciesCreateReportScheduleRequestBodyFromJSON(json: any): PoliciesCreateReportScheduleRequestBody {
    return PoliciesCreateReportScheduleRequestBodyFromJSONTyped(json, false);
}

export function PoliciesCreateReportScheduleRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoliciesCreateReportScheduleRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channels': ((json['channels'] as Array<any>).map(PolicyReportChannelFromJSON)),
        'country_code': json['country_code'],
        'day_of_month': !exists(json, 'day_of_month') ? undefined : json['day_of_month'],
        'day_of_week': !exists(json, 'day_of_week') ? undefined : json['day_of_week'],
        'hour': json['hour'],
        'interval': json['interval'],
        'name': json['name'],
        'policy_ids': json['policy_ids'],
        'suppress_if_empty': json['suppress_if_empty'],
        'time_zone': json['time_zone'],
    };
}

export function PoliciesCreateReportScheduleRequestBodyToJSON(value?: PoliciesCreateReportScheduleRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channels': ((value.channels as Array<any>).map(PolicyReportChannelToJSON)),
        'country_code': value.country_code,
        'day_of_month': value.day_of_month,
        'day_of_week': value.day_of_week,
        'hour': value.hour,
        'interval': value.interval,
        'name': value.name,
        'policy_ids': value.policy_ids,
        'suppress_if_empty': value.suppress_if_empty,
        'time_zone': value.time_zone,
    };
}

