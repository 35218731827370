import { CatalogSetupState } from "@incident-shared/catalog/CatalogSetupWidget";
import _ from "lodash";
import { useAPI } from "src/utils/swr";

// Determine which of the catalog widget states should be shown based on the
// current catalog setup progress and the current form state.
//
// This widget encourages both catalog setup _and_ checks for configuration
// issues in the existing alert source bindings. So it's a contextually aware of
// the alert source state rather than being strictly based on the catalog.
export const useSetupProgress = ({
  boundAttributeIds,
}: {
  boundAttributeIds: string[];
}): {
  state: CatalogSetupState | null;
  error: boolean;
  loading: boolean;
} => {
  const {
    data: catalogConfigData,
    isLoading: catalogConfigLoading,
    error: catalogConfigError,
  } = useAPI("catalogGetCatalogConfig", {});

  const {
    data: catalogTypesData,
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {});

  const {
    data: schemaData,
    isLoading: schemaIsLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", {});

  if (catalogConfigError || catalogTypesError || schemaError) {
    return { state: null, error: true, loading: false };
  }
  if (
    !catalogConfigData ||
    catalogConfigLoading ||
    !catalogTypesData ||
    catalogTypesLoading ||
    !schemaData ||
    schemaIsLoading
  ) {
    return { state: null, error: true, loading: false };
  }

  return {
    state: getState(
      catalogTypesData.catalog_types,
      catalogConfigData.config,
      schemaData.alert_schema.attributes,
      boundAttributeIds,
    ),
    error: false,
    loading: false,
  };
};

const getState = (
  catalogTypes,
  catalogConfig,
  attributes,
  boundAttributeIds,
): CatalogSetupState | null => {
  const hasCustomCatalogTypes = catalogTypes.some((type) =>
    type.name.startsWith("Custom[\\"),
  );

  if (hasCustomCatalogTypes) {
    return CatalogSetupState.NO_CATALOG;
  }

  if (catalogConfig.team_types.length === 0) {
    return CatalogSetupState.NO_TEAMS;
  }

  if (catalogConfig.escalation_types.length === 0) {
    return CatalogSetupState.NO_ESCALATION_PATH_LINKS;
  }

  // Check that we have at least one bound attribute which has a type that is in
  // the escalation types.
  const hasBoundEscalationAttribute = _.some(attributes, (attribute) => {
    // If we're not bound, we don't care.
    if (!boundAttributeIds.includes(attribute.id)) {
      return false;
    }

    return _.some(catalogConfig.escalation_types, (escalationType) => {
      return attribute.type === escalationType.engine_type_name;
    });
  });
  if (!hasBoundEscalationAttribute) {
    return CatalogSetupState.NO_ESCALATION_PATH_BINDING;
  }

  return null;
};
