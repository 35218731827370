import { StepConfig } from "@incident-ui/Steps/Steps";
import {
  Identity,
  MSTeamsInfoReconnectionReasonEnum,
  SlackInfoReconnectionReasonEnum as ReconnectionReason,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

import { useIsAEDriven } from "./useIsAEDriven";

export enum InstallationStepID {
  Login = "login",
  ChoosePlan = "choose_plan",
  Install = "install",
  Dashboard = "dashboard",
}

export const useSetupSteps = () => {
  const isAEDriven = useIsAEDriven();

  return [
    {
      id: InstallationStepID.Login,
      name: "Sign in",
    },
    {
      id: InstallationStepID.ChoosePlan,
      name: isAEDriven ? "Start trial" : "Confirm plan",
    },
    {
      id: InstallationStepID.Install,
      name: "Install",
    },
  ];
};

type Step = StepConfig<InstallationStepID>;

// useCurrentStep tells each setup route which part of the setup flow
// the user should be sent to.
export const useCurrentStep = (): {
  steps: Step[];
  currentStep: InstallationStepID;
} => {
  const { identity } = useIdentity();
  const steps = useSetupSteps();

  if (
    identity.app_installed &&
    (slackInstalled(identity) || msTeamsInstalled(identity))
  ) {
    return {
      currentStep: InstallationStepID.Dashboard,
      steps,
    };
  } else if (identity.onboarding_information.tell_us_more_form_submitted) {
    // If you've used the on-call pay calculator we don't ask who you are,
    // because it's a bit weird.
    return {
      currentStep: InstallationStepID.Install,
      steps,
    };
  } else {
    return { currentStep: InstallationStepID.ChoosePlan, steps };
  }
};

const slackInstalled = (identity: Identity) =>
  identity.slack_info &&
  identity.slack_info.reconnection_reason === ReconnectionReason.Empty;

const msTeamsInstalled = (identity: Identity) =>
  // There's a valid Graph connection
  identity.ms_teams_info &&
  identity.ms_teams_info.reconnection_reason ===
    MSTeamsInfoReconnectionReasonEnum.Empty &&
  // And we have a Team that we're installed to
  identity.ms_teams_info.team_id;
