import { Trigger } from "@incident-io/api";
import { Button, ButtonTheme, IconEnum, Loader } from "@incident-ui";

import { WorkflowActiveTriggerCard } from "./WorkflowActiveTriggerCard";

export const WorkflowsTriggerSelector = ({
  trigger,
  isDraft,
  onClickEditTrigger,
  onClickChooseTrigger,
  triggerLoading,
}: {
  triggerLoading: boolean;
  trigger?: Trigger;
  isDraft: boolean;
  onClickEditTrigger: () => void;
  onClickChooseTrigger: () => void;
}) => {
  return (
    <div className="p-3.5 border-[1px] rounded-2 border-radius border-stroke bg-white w-full">
      <p className="mb-3 font-normal text-sm text-content-primary">
        Workflow is triggered when...
      </p>
      {triggerLoading ? (
        <Loader />
      ) : trigger ? (
        <WorkflowActiveTriggerCard
          trigger={trigger}
          iconName={trigger.icon as unknown as IconEnum}
          deleteButton={
            isDraft ? (
              <Button
                onClick={() => {
                  onClickEditTrigger();
                }}
                icon={IconEnum.Close}
                iconProps={{ className: "!w-5 !h-5" }}
                theme={ButtonTheme.Naked}
                title="delete-trigger"
                analyticsTrackingId="workflows-v2-delete-trigger-button"
              />
            ) : undefined
          }
        />
      ) : (
        <Button
          onClick={onClickChooseTrigger}
          analyticsTrackingId={"workflows-v2-choose-trigger-button"}
          icon={IconEnum.FastForward}
        >
          Choose trigger
        </Button>
      )}
    </div>
  );
};
