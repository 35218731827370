import { SchedulesListHolidayEntriesResponseBody } from "@incident-io/api";
import { ScheduleEntryComponent } from "@incident-shared/schedules/ScheduleOverview/ScheduleEntryComponent";
import { calculateEntryStyles } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/calculateEntryStyles";
import { ROTA_NAME_COLUMN_WIDTH } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/constants";
import {
  aggregateHolidaysToRows,
  COLLAPSED_ROW_COUNT,
  HolidayEntry,
  showMorePlaceholdersFromRows,
} from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/holiday-rows";
import { NowMarker } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/TimelineSectionV2";
import {
  TimePeriodOption,
  UpcomingRotaChange,
} from "@incident-shared/schedules/ScheduleOverview/types";
import { useKeyboardEvents } from "@incident-shared/schedules/useKeyboardEvents";
import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { AnimateChangeInHeight } from "@incident-ui/AnimateChangeInHeight";
import * as d3 from "d3";
import { DateTime } from "luxon";
import React, { useCallback, useMemo } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { TimelineMarkerLine } from "../TimelineMarker/TimelineMarkerLine";

export const HolidaysRow = ({
  holidaysResponse,
  scheduleTimezone,
  xScale,
  timelineStartPoint,
  timelineEndpoint,
  now,
  hasWrapper,
  timePeriod,
  scheduleId,
  disableOverride,
  upcomingRotaChanges,
}: {
  holidaysResponse: SchedulesListHolidayEntriesResponseBody;
  scheduleTimezone: string;
  xScale: d3.ScaleTime<number, number>;
  timelineStartPoint: DateTime;
  timelineEndpoint: DateTime;
  now: DateTime;
  hasWrapper?: boolean;
  timePeriod: TimePeriodOption;
  scheduleId: string | undefined;
  disableOverride?: boolean;
  upcomingRotaChanges?: UpcomingRotaChange[];
}) => {
  const [isShowingAllRows, setIsShowingAllRows] = React.useState(false);
  const holidayRows: HolidayEntry[][] = useMemo(() => {
    return aggregateHolidaysToRows({
      holidaysResponse: holidaysResponse,
      scheduleTimezone: scheduleTimezone,
    });
  }, [holidaysResponse, scheduleTimezone]);

  const showMoreButtons = useMemo(() => {
    return showMorePlaceholdersFromRows({
      holidayRows: holidayRows,
      timePeriod: timePeriod,
      timelineStartPoint: timelineStartPoint,
      timelineEndpoint: timelineEndpoint,
    });
  }, [holidayRows, timelineStartPoint, timelineEndpoint, timePeriod]);

  const canExpand = showMoreButtons.length > 0;

  // Expand/contract as you press up/down
  useKeyboardEvents(
    useCallback(
      (e) => {
        if (!canExpand) {
          return;
        }

        // If you press down or up, we expand or collapse the overrides
        if (e.key === "ArrowDown") {
          setIsShowingAllRows(true);
        } else if (e.key === "ArrowUp") {
          setIsShowingAllRows(false);
        }
      },
      [setIsShowingAllRows, canExpand],
    ),
  );

  if (holidayRows.length === 0) {
    return <div className={"w-full border-b border-stroke"} />;
  }

  return (
    <div className={"w-full flex flex-row relative"}>
      <div
        className={tcx("flex items-end justify-end pb-2", {
          "px-4": hasWrapper,
          "px-8": !hasWrapper,
        })}
        style={{
          width: ROTA_NAME_COLUMN_WIDTH,
        }}
      >
        {/* Bottom divider */}
        <div
          className={"absolute bottom-0 left-0 right-0 border-b border-stroke"}
        />

        {canExpand && (
          <Button
            title={"Show all holidays"}
            analyticsTrackingId={"show-all-holidays"}
            icon={isShowingAllRows ? IconEnum.Collapse : IconEnum.Expand}
            className={"h-full"}
            iconProps={{
              className:
                "!w-4 !h-4 text-content-secondary hover:text-content-primary self-end",
            }}
            onClick={() => setIsShowingAllRows(!isShowingAllRows)}
            theme={ButtonTheme.Unstyled}
          />
        )}
      </div>
      <AnimateChangeInHeight className={"flex-1"}>
        <div className={"w-full flex flex-col pt-4 gap-y-0.5 relative"}>
          {(isShowingAllRows || !canExpand
            ? holidayRows
            : holidayRows.slice(0, COLLAPSED_ROW_COUNT)
          ).map((row, i) => {
            return (
              <div key={i} className={"flex flex-row h-[28px] relative"}>
                {row.map((holiday) => {
                  const styles = calculateEntryStyles({
                    timelineStartPoint,
                    timelineEndpoint,
                    start_at: holiday.start_at,
                    end_at: holiday.end_at,
                    xScale,
                  });

                  if (!styles) {
                    return null;
                  }

                  return (
                    <div
                      key={[
                        holiday.name,
                        holiday.start_at.toISOString(),
                        holiday.end_at.toISOString(),
                      ].join("-")}
                      style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: styles.left,
                        width: styles.width,
                      }}
                    >
                      <ScheduleEntryComponent
                        entries={[
                          {
                            ...holiday,
                            schedule_id: scheduleId,
                          },
                        ]}
                        userId={
                          "user_id" in holiday ? holiday.user_id : undefined
                        }
                        width={"100%"}
                        clipContent
                        roundedLeft={!styles.hasClippedStart}
                        roundedRight={!styles.hasClippedEnd}
                        enableTooltip
                        enableCreateOverride={!disableOverride}
                        timezone={scheduleTimezone}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
          {!isShowingAllRows && showMoreButtons.length > 0 ? (
            <div className={"flex flex-row h-[28px] relative"}>
              {showMoreButtons.map((placeholder, i) => {
                return (
                  <ShowMorePlaceholder
                    key={i}
                    xScale={xScale}
                    placeholder={placeholder}
                    setIsShowingAllRows={setIsShowingAllRows}
                    isInSmallScreen={disableOverride}
                  />
                );
              })}
            </div>
          ) : (
            // Take up the padding that would be taken up by the hidden holidays
            <div className={"h-2"} />
          )}

          {upcomingRotaChanges?.map((c) => {
            if (c.at < timelineStartPoint || c.at > timelineEndpoint) {
              return null;
            }

            return (
              <TimelineMarkerLine
                key={c.rotaId + c.at.toISO()}
                dashed={false}
                style={{
                  position: "absolute",
                  left: xScale(c.at.toJSDate()),
                  top: 0,
                  bottom: 0,
                }}
              />
            );
          })}

          <NowMarker
            now={now}
            xScale={xScale}
            timelineStartPoint={timelineStartPoint}
            timelineEndpoint={timelineEndpoint}
          />
        </div>
      </AnimateChangeInHeight>
    </div>
  );
};

const ShowMorePlaceholder = ({
  xScale,
  placeholder,
  setIsShowingAllRows,
  isInSmallScreen,
}: {
  xScale: d3.ScaleTime<number, number>;
  placeholder: {
    start_at: DateTime;
    end_at: DateTime;
    count: number;
  };
  setIsShowingAllRows: (value: boolean) => void;
  isInSmallScreen?: boolean;
}) => {
  return (
    <div
      style={{
        position: "absolute",
        left: xScale(placeholder.start_at.toJSDate()),
        width:
          xScale(placeholder.end_at.toJSDate()) -
          xScale(placeholder.start_at.toJSDate()),
        top: 0,
        bottom: 0,
      }}
    >
      <Button
        analyticsTrackingId={"show-more-holidays"}
        onClick={() => setIsShowingAllRows(true)}
        className={tcx(
          "relative w-full h-full overflow-hidden py-[6px] px-[7px] text-content-secondary hover:text-content-primary text-xs",
        )}
        theme={ButtonTheme.Unstyled}
      >
        {!isInSmallScreen && (
          <span className={"text-nowrap overflow-visible"}>
            {placeholder.count} more
          </span>
        )}
        <Icon id={IconEnum.Expand} size={IconSize.Small} />
      </Button>
    </div>
  );
};
