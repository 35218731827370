import { Icon, IconEnum } from "@incident-ui/Icon/Icon";
import { get } from "lodash";
import { useState } from "react";
import { Path, UseFormReturn } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { ErrorMessageUI } from "src/components/@shared/forms/ErrorMessage";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { tcx } from "src/utils/tailwind-classes";

export const InlineTextInput = <TFormType extends FieldValues>({
  placeholder,
  name,
  formMethods,
}: {
  placeholder: string;
  name: Path<TFormType>;
  formMethods: UseFormReturn<TFormType>;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const title = formMethods.watch(name);
  const error = get(formMethods.formState.errors, name.split("."));

  return (
    <div className={"flex grow items-center gap-1.5"}>
      {isEditing ? (
        <InputV2
          placeholder={placeholder}
          inputClassName="!border-transparent !shadow-none !p-0 !pl-0.5 !m-0 text-sm font-medium text-content-primary placeholder-slate-400"
          className="grow p-0 m-0"
          formMethods={formMethods}
          name={name}
          onBlurCallback={() => setIsEditing(false)}
          autoFocus
        />
      ) : (
        <div className="flex flex-col">
          <div
            className="group flex flex-row gap-1 items-center px-0.5 border-white border border-transparent hover:border-stroke"
            onClick={() => setIsEditing(true)}
          >
            <span
              className={tcx("text-sm truncate font-medium", {
                "!text-content-primary": title,
                "text-slate-400": !title,
              })}
            >
              {title || placeholder}
            </span>
            <Icon
              id={IconEnum.Edit}
              className={tcx(
                "text-slate-400 group-hover:text-slate-400 hover:!text-content-primary transition",
              )}
            />
          </div>
          {error?.message && (
            <ErrorMessageUI className="mt-1" message={error.message} />
          )}
        </div>
      )}
    </div>
  );
};
