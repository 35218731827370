import { Icon, IconEnum } from "@incident-ui";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";
import { tcx } from "src/utils/tailwind-classes";

export const GroupByCustomFieldDropdown = ({
  label,
  selectedCustomFieldID,
  setSelectedCustomFieldID,
  customFieldOptions,
}: {
  selectedCustomFieldID: string;
  customFieldOptions: SelectOption[];
  setSelectedCustomFieldID: (newID: string) => void;
  label?: React.ReactNode;
}): React.ReactElement => {
  return (
    <div className="flex items-center">
      {label ?? (
        <span className="text-sm text-slate-700 mr-2 flex-shrink-0">
          Group by
        </span>
      )}
      <StaticSingleSelect
        placeholder="Choose field"
        options={customFieldOptions.map((field) => ({
          ...field,
          renderFn: (props: { isSelected: boolean }) => (
            <div className={tcx("flex text-sm mt-0.5 items-center")}>
              <Icon
                id={IconEnum.CustomField}
                className={tcx(
                  "shrink-0",
                  props?.isSelected
                    ? "text-slate-200"
                    : "text-content-tertiary",
                )}
              />
              <span className="ml-1 mr-2 flex-wrap">{field.label}</span>
            </div>
          ),
        }))}
        className="!text-slate-700 min-w-[200px]"
        value={selectedCustomFieldID}
        onChange={(value) => value && setSelectedCustomFieldID(value)}
        isClearable={false}
      />
    </div>
  );
};
