import { conditionGroupsToConditions } from "@incident-shared/engine/conditions";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { ConditionsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionsEditorV2";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { FormModalV2, FormV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  ModalFooter,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import React, { ReactElement, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import {
  Condition,
  CustomField,
  CustomFieldFieldModeEnum as FieldModeEnum,
  CustomFieldFieldTypeEnum,
  CustomFieldRequiredV2Enum as RequiredEnum,
  CustomFieldsListResponseBody,
  CustomFieldsShowResponseBody,
  EngineScope,
  Expression,
  IncidentsBuildScopeContextEnum,
  Resource,
} from "src/contexts/ClientContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useAllResources } from "src/hooks/useResources";
import { useConditionalCustomFieldsAvailable } from "src/utils/custom-fields";
import { omitScopeReference } from "src/utils/scope";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { KeyedMutator } from "swr";

import { CustomFieldOptionsEditSection } from "./CustomFieldOptionsEditSection";
import { DerivedCustomFieldSection } from "./DerivedCustomFieldSection";
import {
  CustomFieldOptionOrGroup,
  optionsResponseBodyToFormData,
  transformFormStateToCreateRequestBody,
  transformFormStateToUpdateRequestBody,
} from "./marshall";
import { availableFieldTypes, ManagementType } from "./types";

export const CustomFieldCreateEditDrawer = ({
  template,
  onSuccess,
  onClose,
  customFieldID,
}: {
  template?: Partial<CustomFieldFormData>;
  onSuccess: () => void;
  onClose: () => void;
  customFieldID?: string;
}): React.ReactElement => {
  const {
    data: customFieldData,
    isLoading: customFieldLoading,
    error: customFieldError,
    mutate: setCustomField,
    // We assume that if we don't have a customFieldID, we're creating a new custom field.
  } = useAPI(customFieldID ? "customFieldsShow" : null, {
    id: customFieldID ?? "",
  });

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.ApplicableFields,
  );

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (scopeError) throw scopeError;
  if (resourcesError) throw resourcesError;

  if (customFieldError) {
    return <GenericErrorMessage error={customFieldError} />;
  }

  const loading =
    (!customFieldData && customFieldID) ||
    (customFieldLoading && customFieldID) ||
    scopeLoading ||
    resourcesLoading;

  const props: CreateEditFormProps<CustomField> = customFieldData?.custom_field
    ? { initialData: customFieldData.custom_field, mode: Mode.Edit }
    : { mode: Mode.Create };

  return (
    <Drawer width="medium" onClose={onClose}>
      {loading ? (
        <DrawerContentsLoading />
      ) : (
        <CustomFieldCreateEditFormInner
          {...props}
          scope={scope}
          resources={resources}
          template={template}
          onSuccess={onSuccess}
          onClose={onClose}
          setCustomField={setCustomField}
        />
      )}
    </Drawer>
  );
};

export type CustomFieldFormData = Omit<
  CustomField,
  "options" | "dynamic_options" | "expression" | "condition_groups"
> & {
  options: CustomFieldOptionOrGroup[];
  management: ManagementType;
  backfill_derived_values: string;
  filtering_by_composite_id?: string;
  engine_expression?: ExpressionFormData;
  conditions: Condition[];
};

type customFieldFormProps = {
  scope: EngineScope;
  resources: Resource[];
  template?: Partial<CustomFieldFormData>;
  onSuccess: () => void;
  onClose: () => void;
  setCustomField: KeyedMutator<CustomFieldsShowResponseBody>;
} & CreateEditFormProps<CustomField>;

const getManagementType = (customField: CustomField) => {
  if (customField.catalog_type_id) {
    return ManagementType.Catalog;
  }
  if (customField.dynamic_options) {
    return ManagementType.Dynamic;
  }
  return ManagementType.Manual;
};

export const getDefaultValues = (
  customField?: CustomField,
  template: Partial<CustomFieldFormData> = {},
): Partial<CustomFieldFormData> => {
  if (customField) {
    return {
      ...customField,
      conditions: conditionGroupsToConditions(customField.condition_groups),
      management: getManagementType(customField),
      options: optionsResponseBodyToFormData(customField.options),
      backfill_derived_values: "false",
      filtering_by_composite_id: customField.filter_by_custom_field_id
        ? `${customField.filter_by_custom_field_id}/${customField.filter_by_catalog_attribute_id}`
        : undefined,
    };
  }
  return {
    ...template,
    conditions: [],
    management: ManagementType.Catalog,
    field_mode: FieldModeEnum.Manual,
    backfill_derived_values: "false",
    cannot_be_unset: false,
  };
};

const CustomFieldCreateEditFormInner = ({
  initialData: customField,
  mode,
  scope,
  resources,
  template,
  onSuccess,
  setCustomField,
  onClose,
}: customFieldFormProps): React.ReactElement => {
  const showConditions = useConditionalCustomFieldsAvailable();

  let availableScope = scope;
  if (customField) {
    const ownKey = `incident.custom_field["${customField.id}"]`;
    availableScope = omitScopeReference(scope, ownKey);
  }

  // If updating an existing field, or creating a new derived field from the modal where the expression is set
  // the user can't update the field type, catalog type, or engine expression result type
  const hasFixedReturnType = !!customField?.id || !!template?.engine_expression;

  const formMethods = useForm<CustomFieldFormData>({
    defaultValues: getDefaultValues(customField, template),
    reValidateMode: "onSubmit",
  });
  const { watch, setValue, setError } = formMethods;

  const [
    fieldType,
    fieldMode,
    fieldRequired,
    showBeforeCreation,
    showBeforeClosure,
    showBeforeUpdate,
    engineExpression,
  ] = watch([
    "field_type",
    "field_mode",
    "required_v2",
    "show_before_creation",
    "show_before_closure",
    "show_before_update",
    "engine_expression",
  ]);

  const {
    trigger: onSubmit,
    isMutating,
    genericError: updateError,
  } = useAPIMutation(
    "customFieldsList",
    undefined,
    async (apiClient, data: CustomFieldFormData) => {
      if (customField) {
        const transformed = transformFormStateToUpdateRequestBody(
          customField,
          data,
        );
        const resp = await apiClient.customFieldsUpdate({
          id: customField.id,
          updateRequestBody: transformed,
        });
        setCustomField(resp);
        return;
      } else {
        const transformed = transformFormStateToCreateRequestBody(
          data as unknown as CustomFieldFormData,
        );
        await apiClient.customFieldsCreate({ createRequestBody: transformed });
        return;
      }
    },
    { onSuccess, setError },
  );

  const [showBackfillModal, setShowBackfillModal] = useState(false);

  const [engineExpressionEdited, setEngineExpressionEdited] = useState(false);

  // Whether any of the derived field properties (expression or field mode) have changed
  // and field is still derived
  const derivationHasChanged =
    !!customField &&
    fieldMode !== FieldModeEnum.Manual &&
    (customField.field_mode !== fieldMode || engineExpressionEdited);

  // If a new derived custom field is created, or an existing one is updated
  // with changes to the expression or field mode, show the user a modal asking
  // if they want to backfill the derived values.
  const showBackfillModalOrSubmit = (formData) => {
    if (
      fieldMode !== FieldModeEnum.Manual &&
      (!customField?.id || derivationHasChanged)
    ) {
      return setShowBackfillModal(true);
    } else {
      return onSubmit(formData);
    }
  };

  const {
    data: { catalog_types: catalogTypes },
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  // We keep this as a useEffect rather than onValueChange because the logic here
  // sets many different fields, so it makes sense to handle holistically.
  useEffect(() => {
    // If you're fully derived, you can't be shown in a modal or required
    if (fieldMode === FieldModeEnum.FullyDerived) {
      if (showBeforeCreation) {
        setValue<"show_before_creation">("show_before_creation", false);
      }
      if (showBeforeClosure) {
        setValue<"show_before_closure">("show_before_closure", false);
      }
      if (showBeforeUpdate) {
        setValue<"show_before_update">("show_before_update", false);
      }
      if (fieldRequired !== RequiredEnum.Never) {
        setValue<"required_v2">("required_v2", RequiredEnum.Never);
      }
      // We return out here, as nothing else can be 'wrong' as we're
      // fully locked in place
      return;
    }
    if (fieldMode === FieldModeEnum.SensibleDefault) {
      // Can't be required, as we won't have had a chance to set a default. If you want this behaviour, you should make
      // whatever this is dependent on required instead.
      if (fieldRequired !== RequiredEnum.Never) {
        setValue<"required_v2">("required_v2", RequiredEnum.Never);
      }
    }
    if (fieldRequired === RequiredEnum.Always && !showBeforeCreation) {
      setValue<"show_before_creation">("show_before_creation", true);
    }
    if (fieldRequired === RequiredEnum.Always && !showBeforeClosure) {
      setValue<"show_before_closure">("show_before_closure", true);
    }
    if (fieldRequired === RequiredEnum.BeforeResolution && !showBeforeClosure) {
      setValue<"show_before_closure">("show_before_closure", true);
    }
  }, [
    fieldRequired,
    setValue,
    showBeforeClosure,
    showBeforeCreation,
    showBeforeUpdate,
    fieldMode,
  ]);

  // Set field type from the derived custom field expression if set
  useEffect(() => {
    // Skip this if we're in edit mode - field type cannot be edited!
    if (mode === Mode.Edit) return;
    if (!engineExpression) return;

    const inferredFieldType = expressionToFieldType(engineExpression);

    if (!inferredFieldType) return;
    if (inferredFieldType === fieldType) return;
    setValue<"field_type">("field_type", inferredFieldType);
  }, [engineExpression, setValue, fieldType, mode]);

  // This is where if we set an expression and identify that it's a catalog
  // type, we set the catalog type ID to go with it, and management to catalog
  const expressionResultType = engineExpression?.returns?.type;
  useEffect(() => {
    if (expressionResultType) {
      const matchResult = expressionResultType.match(
        /^CatalogEntry\["(.+)"\]$/,
      );
      const inferredCatalogType = catalogTypes.find(
        (ct) => ct.id === matchResult?.[1] || ct.type_name === matchResult?.[1],
      );
      if (inferredCatalogType) {
        setValue<"catalog_type_id">("catalog_type_id", inferredCatalogType.id);
        setValue<"management">("management", ManagementType.Catalog);
      }
    }
  }, [expressionResultType, catalogTypes, setValue]);

  // react-hook-form refuses to track data where there is no form field
  // watching it, so we have to set the value on page load here in order to track it,
  // but ignore any changes after page load so that the expression editor handles those.
  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      setValue(
        "engine_expression",
        customField?.engine_expression || template?.engine_expression,
      );
    }
    return () => {
      ignore = true;
    };
  }, [customField, template, setValue]);

  return (
    <DrawerContents>
      <DrawerTitle
        title={customField ? "Edit custom field" : "Create custom field"}
        onClose={onClose}
        icon={IconEnum.CustomField}
        compact
      />
      <DrawerBody className="overflow-y-auto">
        <FormV2
          id="custom-field-create-edit"
          genericError={updateError}
          onSubmit={showBackfillModalOrSubmit}
          formMethods={formMethods}
          saving={isMutating}
        >
          {customField ? (
            <CustomFieldHasNoOptionsCallout customField={customField} />
          ) : null}
          <InputV2
            formMethods={formMethods}
            name="name"
            label="Name"
            required="Please enter a name"
            placeholder="Affected team"
          />
          <TextareaV2
            formMethods={formMethods}
            required="Please enter a description"
            name="description"
            label="Description"
            // There's something funky about the textarea which is making the gap underneath
            // it too large - this is a hack to fix it.
            className="-mb-1"
            placeholder="e.g. The team which was responsible for resolving this incident"
            helptext="What does this field represent for an incident?"
          />
          <StaticSingleSelectV2
            formMethods={formMethods}
            label="Field type"
            required
            name={"field_type"}
            options={availableFieldTypes}
            placeholder="Select field type..."
            isClearable={false}
            helptext={"What type of data should this field collect?"}
            // You can't edit a custom field's type after it's created.
            disabled={hasFixedReturnType}
            disabledTooltipContent={
              customField?.id
                ? "You cannot change field types once the field has been created."
                : "You cannot change field types when creating a field using an expression."
            }
          />

          {[
            CustomFieldFieldTypeEnum.SingleSelect,
            CustomFieldFieldTypeEnum.MultiSelect,
          ].includes(fieldType) ? (
            <CustomFieldOptionsEditSection
              customField={customField}
              hasFixedReturnType={hasFixedReturnType}
            />
          ) : null}

          {/* Show the expression editor if updating, or an expression was passed from the create modal */}
          {hasFixedReturnType && (
            <DerivedCustomFieldSection
              customField={customField}
              formMethods={formMethods}
              scope={scope}
              resources={resources}
              catalogTypes={catalogTypes}
              setEngineExpressionEdited={setEngineExpressionEdited}
              templateExpression={template?.engine_expression}
            />
          )}

          {showConditions && !!fieldType ? (
            <ConditionsEditorV2
              formMethods={formMethods}
              label="Conditions"
              name="conditions"
              scope={availableScope}
              entityNameLabel={"custom field"}
              subjectsLabel={"incidents"}
              explanationStyle="available"
            />
          ) : null}
        </FormV2>
      </DrawerBody>
      <DrawerFooter className="flex justify-end gap-2">
        <Button
          analyticsTrackingId={null}
          onClick={onClose}
          theme={ButtonTheme.Secondary}
        >
          Cancel
        </Button>
        <Button
          form="custom-field-create-edit"
          type="submit"
          analyticsTrackingId={null}
          theme={ButtonTheme.Primary}
          loading={isMutating}
        >
          Save
        </Button>
      </DrawerFooter>
      {showBackfillModal && (
        <DerivedCustomFieldBackfillModal
          formMethods={formMethods}
          saving={isMutating}
          onClose={() => setShowBackfillModal(false)}
          onSubmit={onSubmit}
          isUpdate={!!customField?.id}
        />
      )}
    </DrawerContents>
  );
};

// [ENG-5218] If you're using the API to create a single or multi select custom field, you have to
// do two separate calls to create the custom field and its options. This means it's possible to end
// up with option-less custom fields.
//
// We stop this from messing up our modals by filtering out any custom fields that don't have any
// options, but we should really warn the user about why they're not seeing this custom field.
export const CustomFieldHasNoOptionsCallout = ({
  customField,
}: {
  customField: CustomField;
}): ReactElement => {
  if (!customField.is_usable) {
    return (
      <Callout theme={CalloutTheme.Warning} className="mb-3">
        This field does not have any options, and so will not be displayed when
        editing custom fields for an incident.
      </Callout>
    );
  } else {
    return <></>;
  }
};

const expressionToFieldType = (
  expression: Expression,
): CustomFieldFieldTypeEnum => {
  switch (expression.returns?.type) {
    case "String":
      return CustomFieldFieldTypeEnum.Text;
    case "Number":
      return CustomFieldFieldTypeEnum.Numeric;
    case "Link":
      return CustomFieldFieldTypeEnum.Link;
    // If it's not one of those, it's a catalog single or multi-select
    default:
      if (expression.returns?.array) {
        return CustomFieldFieldTypeEnum.MultiSelect;
      }
      return CustomFieldFieldTypeEnum.SingleSelect;
  }
};

const DerivedCustomFieldBackfillModal = ({
  formMethods,
  onClose,
  onSubmit,
  saving,
  isUpdate,
}: {
  formMethods: UseFormReturn<CustomFieldFormData>;
  onClose: () => void;
  onSubmit: (customFieldFormData) => Promise<CustomFieldsListResponseBody>;
  saving: boolean;
  isUpdate: boolean;
}): ReactElement => {
  const createLabel =
    "You've chosen to automatically set this field's value from an expression. Should we backfill values for it on existing incidents?";
  const updateLabel =
    "You've updated the expression for this field. Should we backfill values for it on existing incidents?";

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      title="How should we populate this field?"
      analyticsTrackingId="derived-custom-field-backfill-modal"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Save"
          cancelButtonText="Cancel"
        />
      }
    >
      <RadioButtonGroupV2
        name={"backfill_derived_values"}
        label={isUpdate ? updateLabel : createLabel}
        srLabel={isUpdate ? updateLabel : createLabel}
        formMethods={formMethods}
        options={[
          {
            label: "Yes",
            value: "true",
            description:
              "Backfill the custom field value on all existing incidents.",
          },
          {
            label: "No",
            value: "false",
            description:
              "Only set the custom field value when an incident is created or updated going forwards.",
          },
        ]}
      />
    </FormModalV2>
  );
};
