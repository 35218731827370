import {
  EscalationPathTargetTypeEnum,
  Schedule,
  ScheduleConfig,
  ScheduleConfigPayload,
  ScheduleEntry,
  ScheduleRotationPayload,
  ScheduleVersion,
} from "@incident-io/api";
import { ScheduleOverviewHeader } from "@incident-shared/schedules/ScheduleOverview/ScheduleOverviewHeader";
import {
  makeQueryParams,
  useScheduleTimeWindowReducer,
} from "@incident-shared/schedules/ScheduleOverview/scheduleTimeWindowReducer";
import { AutoSizer } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/CollapsableContainer";
import { TimelineSection } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/TimelineSectionV2";
import { endTimeForTimelinePeriod } from "@incident-shared/schedules/ScheduleOverview/types";
import {
  GenericErrorMessage,
  Modal,
  ModalContent,
  ModalFooter,
  Txt,
} from "@incident-ui";
import {
  Drawer,
  DrawerTitle,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import Loader from "@incident-ui/Icon/icons/Loader";
import { FooterContents } from "@incident-ui/Modal/ModalFooter";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { compact } from "lodash";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { EscalationPathUserTargetFormData } from "src/components/escalation-paths/common/types";
import { tcx } from "src/utils/tailwind-classes";
import { useLocalStorage } from "use-hooks";

import { useClient } from "../../../../contexts/ClientContext";
import { isOnCallSeatCount } from "../../../../hooks/useCanPromoteToOnCall";
import { useAPI, useAPIMutation } from "../../../../utils/swr";
import { useNow } from "../../../../utils/use-now";
import { useRevalidate } from "../../../../utils/use-revalidate";
import { isOnCallUser } from "../../../settings/users/users/utils";
import {
  OnCallPromotionConfirmationModal,
  PromotionState,
} from "../common/OnCallPromotionConfirmationModal";
import { rotaFormDataToPayload, rotaToFormData } from "./marshall";
import { useHydratedUserCache } from "./useHydratedUserCache";

// We need to abstract our request data into a struct in
// order to use the On-call responder promotion state.
type ScheduleVersionData = {
  schedule_version_id: string;
};

export const ScheduleRestoreDrawer = ({
  schedule,
  isOpen,
  onClose,
}: {
  schedule: Schedule;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const showToast = useToast();
  const client = useClient();

  const sharedDrawerProps = {
    isOpen,
    onClose,
  };

  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);
  const [promotionState, setPromotionState] =
    useState<PromotionState<ScheduleVersionData>>(null);
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>();
  const [selectedTimezones, setSelectedTimezones] = useLocalStorage<string[]>(
    `${schedule.id}-selected-timezones`,
    [schedule.timezone],
  );

  const revalidateEntriesAndVersions = useRevalidate([
    "schedulesShow",
    "schedulesListEntries",
    "schedulesListVersions",
  ]);

  // Billing information
  const {
    data: { seat_counts: seatCounts },
  } = useAPI("billingListSeatCounts", undefined, {
    fallbackData: { seat_counts: [] },
  });

  const currentSeatCount = seatCounts?.find(isOnCallSeatCount)?.used ?? 0;

  const onCallSeatCountLimit = seatCounts?.find(isOnCallSeatCount)?.limit ?? 0;

  // Version queries
  const {
    data: { schedule_versions },
    isLoading: versionsLoading,
    error: versionsError,
  } = useAPI(
    "schedulesListVersions",
    {
      scheduleId: schedule.id,
    },
    {
      fallbackData: { schedule_versions: [] },
    },
  );

  const { data: showScheduleVersionResp } = useAPI(
    selectedVersion ? "schedulesShowVersion" : null,
    // we just want to pass the response to the onSuccess
    { id: selectedVersion ?? "" },
  );

  // We access users to track responder promotion state.
  const rotations =
    showScheduleVersionResp?.schedule_version.config.rotations || [];
  const userCache = useHydratedUserCache(rotations.flatMap((r) => r.user_ids));

  // Restore mutation
  const { trigger: restoreSchedule, isMutating: restoreSaving } =
    useAPIMutation(
      "schedulesShow",
      { id: schedule.id },
      async (
        apiClient,
        data: { versionID: string; userIdsToPromote: string[] },
      ) => {
        await apiClient.schedulesRestore({
          id: schedule.id,
          versionId: data.versionID,
          restoreRequestBody: {
            user_ids_to_promote: data.userIdsToPromote,
          },
        });
      },
      {
        onSuccess: () => {
          showToast({
            title: `Schedule restored`,
            theme: ToastTheme.Success,
            toastSide: ToastSideEnum.Bottom,
          });
          revalidateEntriesAndVersions();
          setSelectedVersion(undefined);
          setConfirmationModalOpen(false);
          onClose();
        },
        onError: () => {
          showToast({
            title: "Failed to restore schedule",
            theme: ToastTheme.Error,
            toastSide: ToastSideEnum.Bottom,
          });
        },
      },
    );

  const now = useNow(schedule.timezone);
  const [timeWindowState, timeWindowDispatch] =
    useScheduleTimeWindowReducer(now);
  const currentEndTime = endTimeForTimelinePeriod({
    from: timeWindowState.startTime,
    timePeriod: timeWindowState.timePeriodOption,
  });
  const [from, until] = makeQueryParams(timeWindowState);

  const handleSubmit = async (formData: ScheduleVersionData) => {
    if (!showScheduleVersionResp) {
      return;
    }
    const usersToPromote = compact(
      showScheduleVersionResp.schedule_version.config.rotations
        .flatMap((rotation) => rotation.user_ids)
        .map(
          (id): EscalationPathUserTargetFormData =>
            ({
              ...userCache.getHydratedUser(id),
              type: EscalationPathTargetTypeEnum.User,
              value: id,
            }) as EscalationPathUserTargetFormData,
        ),
    ).filter((user) => user.value !== "NOBODY" && !isOnCallUser(user.state));

    if (usersToPromote.length > 0) {
      const { requires_billing_scope } =
        await client.billingRequiresBillingScopeToPromoteOnCallResponders({
          requiresBillingScopeToPromoteOnCallRespondersRequestBody: {
            number_of_promotions: usersToPromote.length,
          },
        });
      if (requires_billing_scope) {
        setPromotionState({
          formData,
          usersToPromote,
        });
        return;
      }

      if (currentSeatCount + usersToPromote.length > onCallSeatCountLimit) {
        setPromotionState({
          formData,
          usersToPromote,
          requiresSeatCountIncrease: true,
        });
        return;
      }
    }

    submitWithResponderPromotion(
      formData,
      usersToPromote.map((x) => x.value),
    );
  };

  const currentVersion =
    schedule_versions.length > 0 ? schedule_versions[0].id : undefined;
  if (!selectedVersion && currentVersion) {
    setSelectedVersion(currentVersion);
  }

  // When we restore to a previous version, it's unlikely but it might be that this version
  // contains now-downgraded responders, so we go through the promotion dance.
  const submitWithResponderPromotion = async (
    formData: ScheduleVersionData,
    userIdsToPromote: string[] = [],
  ) => {
    await restoreSchedule({
      versionID: formData.schedule_version_id,
      userIdsToPromote,
    });
    userCache.removeFromCache(userIdsToPromote);
  };

  // Preview queries
  const { data: previewResp, isLoading: previewIsLoading } = useAPI(
    showScheduleVersionResp?.schedule_version && isOpen
      ? "schedulesPreviewEntries"
      : null,
    {
      previewEntriesRequestBody: {
        schedule_id: schedule.id,
        config: buildConfigPayload(
          schedule.timezone,
          showScheduleVersionResp?.schedule_version.config,
        ),
        from,
        until,
        overrides: [],
        timezone: schedule.timezone,
      },
    },
    {},
  );

  // Preview data for the version they want to restore back to
  const versionPreviewEntries = (previewResp?._final ?? []).map(
    (e): ScheduleEntry & { user_id: string } => ({
      ...e,
      user_id: e.external_user_id,
    }),
  );
  const versionPreviewRotas =
    showScheduleVersionResp?.schedule_version.config?.rotations ?? [];

  const versionDescription =
    showScheduleVersionResp?.schedule_version.description;

  const resizerRef = useRef<HTMLDivElement | null>(null);

  if (userCache.hydrating) {
    return <Loader />;
  }

  return (
    <Drawer {...sharedDrawerProps} warnWhenDirty width="full">
      {/* This div wrapper is necessary to send the footer to the bottom and stretch the divs */}
      <div className={"flex flex-col h-full"}>
        <div className={"items-start w-full sticky top-0 bg-white z-[10]"}>
          <DrawerTitle
            title={"Restore previous version"}
            onClose={onClose}
            compact
            theme={DrawerTitleTheme.Bordered}
          />
        </div>
        <div
          className={
            "flex flex-row w-full h-full overflow-hidden justify-between"
          }
        >
          <div className={tcx("w-[65%] overflow-y-auto flex flex-col p-6")}>
            {/*Schedule preview*/}
            {selectedVersion && (
              <div className="flex-1 flex flex-col space-y-2">
                {/* Version preview */}
                <ScheduleOverviewHeader
                  className={tcx("mb-3")}
                  timeWindowState={timeWindowState}
                  timeWindowDispatch={timeWindowDispatch}
                  scheduleDefaultTimezone={schedule.timezone}
                  showCalendarOption={false}
                  selectedTimezones={selectedTimezones}
                  setSelectedTimezones={setSelectedTimezones}
                />
                <div className={"flex-1"}>
                  <AutoSizer ref={resizerRef}>
                    {({ width }) => (
                      <TimelineSection
                        now={timeWindowState.now}
                        rotations={versionPreviewRotas}
                        entries={versionPreviewEntries}
                        timelineStartPoint={timeWindowState.startTime}
                        timelineEndpoint={currentEndTime}
                        timePeriod={timeWindowState.timePeriodOption}
                        isLoadingEntries={previewIsLoading}
                        scheduleTimezone={schedule.timezone}
                        selectedTimezones={selectedTimezones}
                        width={width}
                        disableOverride
                        title={"Preview"}
                        scheduleId={schedule.id}
                      />
                    )}
                  </AutoSizer>
                </div>
              </div>
            )}
          </div>
          <div
            className={
              "flex flex-col w-[35%] px-4 py-6 border-l border-stroke gap-4 overflow-y-auto"
            }
          >
            <div className={"flex flex-col"}>
              <Txt bold className={"text-base"}>
                Versions
              </Txt>
              <div className={"text-sm text-content-secondary"}>
                Select a previous version to restore back to. Historical shifts
                will not be changed.
              </div>
            </div>
            {versionsLoading && <Loader />}
            {versionsError && <GenericErrorMessage error={versionsError} />}
            <div className={"flex flex-col"}>
              {schedule_versions.map((version) => {
                return (
                  <ScheduleVersionComponent
                    key={version.id}
                    scheduleVersion={version}
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    currentlyActiveVersionId={currentVersion}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className={"items-end w-full sticky bottom-0 bg-white z-[50]"}>
          <ModalFooter
            confirmButtonText={"Restore version"}
            confirmButtonType={"button"}
            cancelButtonText={"Cancel"}
            onConfirm={() => setConfirmationModalOpen(true)}
            onClose={onClose}
          />
        </div>
        {/*We use a custom Modal here in order to make it smaller*/}
        <Modal
          isOpen={confirmationModalOpen}
          onClose={() => setConfirmationModalOpen(false)}
          analyticsTrackingId={"schedule-confirm-restore"}
          title={"Restore to previous version"}
          className="!my-48 !w-[400px]"
        >
          <ModalContent>
            <Txt grey>
              This will restore the schedule to Version{" "}
              {showScheduleVersionResp?.schedule_version?.config.version}
              {versionDescription && (
                <>
                  <span>: </span>
                  <span className={"font-medium text-content-primary"}>
                    {versionDescription}
                  </span>
                </>
              )}
              . You can restore to another version at any time.
            </Txt>
          </ModalContent>
          <div className={"pb-4 px-4"}>
            <FooterContents
              onClose={() => setConfirmationModalOpen(false)}
              onConfirm={() =>
                handleSubmit({ schedule_version_id: selectedVersion ?? "" })
              }
              cancelButtonText={"Back"}
              confirmButtonText={"Confirm restore"}
              confirmButtonType="button"
              disabled={restoreSaving}
            />
          </div>
        </Modal>
        <OnCallPromotionConfirmationModal
          noun="schedule"
          state={promotionState}
          onClose={() => setPromotionState(null)}
          onSubmit={submitWithResponderPromotion}
        />
      </div>
    </Drawer>
  );
};

const ScheduleVersionComponent = ({
  scheduleVersion,
  selectedVersion,
  setSelectedVersion,
  currentlyActiveVersionId,
}: {
  scheduleVersion: ScheduleVersion;
  selectedVersion?: string;
  setSelectedVersion: (version: string) => void;
  currentlyActiveVersionId?: string;
}) => {
  let dateString: string;
  const createdTs = DateTime.fromJSDate(scheduleVersion.created_at);
  const now = DateTime.now();

  const actor_name = scheduleVersion.actor?.user?.name;
  let display_actor_name = "";
  if (actor_name !== undefined) {
    display_actor_name = " by " + actor_name;
  }

  let version_name = "Version " + scheduleVersion.config.version + " ";
  const version_desc = scheduleVersion.description;
  if (version_desc) {
    version_name = version_desc + " ";
  }

  if (now.hasSame(createdTs, "day")) {
    dateString = `Today, ${createdTs.toLocaleString({
      hour: "numeric",
      hourCycle: "h12",
      minute: "numeric",
    })}`;
  } else {
    dateString = createdTs.toLocaleString({
      weekday: "short",
      day: "numeric",
      month: "short",
      hour: "numeric",
      hourCycle: "h12",
      minute: "numeric",
    });
  }

  return (
    <div
      onClick={() => setSelectedVersion(scheduleVersion.id)}
      className={tcx(
        "px-4 pt-3 flex-col justify-center items-start inline-flex",
        "hover:bg-neutral-100 hover:rounded-2 hover:cursor-pointer",
        {
          "bg-neutral-100 rounded-2": selectedVersion === scheduleVersion.id,
        },
      )}
    >
      <div className={"border-b border-neutral-100 w-full"}>
        <Txt className={"text-slate-800"}>
          {version_name}
          {currentlyActiveVersionId === scheduleVersion.id &&
            "(current version)"}
        </Txt>
        <Txt grey xs className={"pb-3"}>
          {dateString + display_actor_name}
        </Txt>
      </div>
    </div>
  );
};

const buildConfigPayload = (
  timezone: string,
  config?: ScheduleConfig,
): ScheduleConfigPayload => {
  return {
    version: config?.version,
    rotations:
      config?.rotations.map(
        (rota): ScheduleRotationPayload =>
          rotaFormDataToPayload(rotaToFormData({ rota: rota }), rota),
      ) || [],
  };
};
