import {
  EngineParamBinding,
  EngineScope,
  Resource,
  ScopeNameEnum,
} from "@incident-io/api";
import { EngineFormElement, getVariableScope } from "@incident-shared/engine";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import { ToggleRowV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";

import {
  AlertRouteFormSection,
  AlertRouteYesNoButtonGroup,
} from "./AlertRouteFormSection";
import { AlertRouteFormData, EscalateFormData } from "./types";

export const AlertRouteEscalateSection = ({
  scope,
  resources,
  escalationBindingKey,
}: {
  scope: EngineScope;
  resources: Resource[];
  escalationBindingKey: string;
}) => {
  const formMethods = useFormContext<AlertRouteFormData>();

  const [editingContext, setEditingContext] = useState<{
    isEditing: boolean;
    initialData?: EscalateFormData;
  }>({
    isEditing: false,
  });

  const onSubmit = (data: EscalateFormData) => {
    formMethods.setValue<"enableEscalations">(
      "enableEscalations",
      data.enableEscalations,
      {
        shouldDirty: true,
      },
    );
    formMethods.setValue<"expressions">("expressions", data.expressions, {
      shouldDirty: true,
    });

    if (data.enableEscalations) {
      formMethods.setValue<"escalationBinding">(
        "escalationBinding",
        data.escalationBinding,
        {
          shouldDirty: true,
        },
      );
      formMethods.setValue<"autoCancelEscalations">(
        "autoCancelEscalations",
        data.autoCancelEscalations,
        {
          shouldDirty: true,
        },
      );
    } else {
      formMethods.setValue<"escalationBinding">(
        "escalationBinding",
        undefined,
        {
          shouldDirty: true,
        },
      );
    }

    setEditingContext({ isEditing: false });
    formMethods.setValue<"hasEverConfiguredEscalations">(
      "hasEverConfiguredEscalations",
      true,
      {
        shouldDirty: true,
      },
    );
  };

  const hasEverConfiguredFilters = formMethods.watch(
    "hasEverConfiguredFilters",
  );
  const hasEverConfiguredEscalations = formMethods.watch(
    "hasEverConfiguredEscalations",
  );
  const enableEscalations = formMethods.watch("enableEscalations");
  const escalationBinding = formMethods.watch("escalationBinding");

  const variableScope = getVariableScope(scope, resources);

  return (
    <>
      {editingContext.isEditing && (
        <EscalateDrawer
          onSubmit={onSubmit}
          onClose={() => setEditingContext({ isEditing: false })}
          initialData={editingContext.initialData}
          scope={scope}
          escalationBindingKey={escalationBindingKey}
          resources={resources}
        />
      )}
      <AlertRouteFormSection
        title={
          !hasEverConfiguredEscalations
            ? "Escalate"
            : enableEscalations
            ? "Escalate alerts to:"
            : "Don't escalate alerts"
        }
        color={ColorPaletteEnum.Yellow}
        isCurrentFirstTimeStep={
          !hasEverConfiguredEscalations && hasEverConfiguredFilters
        }
        firstTimeContent={
          <>
            Connect this alert route to your escalation paths to automatically
            page people when an alert is triggered.
          </>
        }
        disabled={hasEverConfiguredEscalations && !enableEscalations}
        icon={IconEnum.Escalate}
        accessory={
          !hasEverConfiguredEscalations ? (
            <AlertRouteYesNoButtonGroup
              disabled={!hasEverConfiguredFilters}
              onChange={(shouldEscalate) => {
                formMethods.setValue<"enableEscalations">(
                  "enableEscalations",
                  shouldEscalate,
                );
                if (shouldEscalate) {
                  setEditingContext({
                    isEditing: true,
                    initialData: formMethods.getValues(),
                  });
                } else {
                  formMethods.setValue<"hasEverConfiguredEscalations">(
                    "hasEverConfiguredEscalations",
                    true,
                  );
                }
              }}
            />
          ) : (
            <GatedButton
              requiredScope={ScopeNameEnum.AlertRouteUpdate}
              theme={ButtonTheme.Naked}
              onClick={() => {
                setEditingContext({
                  isEditing: true,
                  initialData: formMethods.getValues(),
                });
              }}
              icon={IconEnum.Edit}
              className="text-content-tertiary"
              title="Edit escalations"
              analyticsTrackingId="alert-routes-edit-esca;atopms"
            />
          )
        }
      >
        {hasEverConfiguredEscalations &&
          escalationBinding &&
          (enableEscalations ? (
            <EngineBinding
              binding={escalationBinding as EngineParamBinding}
              resourceType={'CatalogEntry["EscalationPath"]'}
              variableScope={variableScope}
              className="shadow-none"
              displayExpressionAs="full-view"
            />
          ) : (
            <Callout showIcon={false} theme={CalloutTheme.Warning}>
              By opting out of creating escalations, this route will not notify
              responders of new alerts.
            </Callout>
          ))}
      </AlertRouteFormSection>
    </>
  );
};

const EscalateDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  scope,
  resources,
  escalationBindingKey,
}: {
  onSubmit: (data: EscalateFormData) => void;
  onClose: () => void;
  initialData?: EscalateFormData;
  scope: EngineScope;
  escalationBindingKey: string;
  resources: Resource[];
}) => {
  const formMethods = useForm<EscalateFormData>({
    defaultValues: initialData,
  });

  const expressionMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });
  const scopeWithExpressions = addExpressionsToScope(
    scope,
    expressionMethods.fields,
  );

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  const enableEscalations = formMethods.watch("enableEscalations");

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionMethods}
      allowAllOfACatalogType={false}
    >
      <Drawer width="medium" onClose={onClose}>
        <DrawerContents>
          <DrawerTitle
            title="Escalate"
            onClose={onClose}
            icon={IconEnum.Escalate}
            compact
            theme={DrawerTitleTheme.Bordered}
          />
          <DrawerBody className="grow">
            <FormV2
              id="alert-routes-escalate"
              fullHeight
              formMethods={formMethods}
              onSubmit={onSubmit}
            >
              <div className="text-sm text-content-secondary">
                Connect this alert route to your escalation paths to
                automatically page people when an alert is triggered.
              </div>
              <BooleanRadioButtonGroupV2
                trueOption={{
                  label: "Yes",
                }}
                falseOption={{
                  label: "No",
                }}
                name="enableEscalations"
                label="Create escalations"
                formMethods={formMethods}
                srLabel="Enable escalations"
                boxed
              />
              <AnimatePresence>
                {enableEscalations ? (
                  <motion.div
                    className="space-y-6 rounded-b-lg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <div>
                      <EngineFormElement
                        name="escalationBinding"
                        key={escalationBindingKey}
                        resourceType={'CatalogEntry["EscalationPath"]'}
                        array
                        description="Create or connect an existing escalation path to ensure on call responders are notified when an incident is created."
                        label="Escalate to"
                        resources={resources}
                        scope={scopeWithExpressions}
                        showPlaceholder
                        mode="variables_and_expressions"
                        required={false}
                      />
                    </div>
                    <ToggleRowV2
                      name="autoCancelEscalations"
                      description="Automatically cancel escalations when an alert is resolved"
                      label="Auto-cancel escalations"
                      formMethods={formMethods}
                    />
                  </motion.div>
                ) : (
                  <Callout showIcon={false} theme={CalloutTheme.Warning}>
                    By opting out of creating escalations, this route will not
                    notify responders of new alerts.
                  </Callout>
                )}
              </AnimatePresence>
            </FormV2>
          </DrawerBody>
          <DrawerFooter className="flex gap-2 justify-end">
            <Button onClick={() => onClose()} analyticsTrackingId={null}>
              Back
            </Button>
            <GatedButton
              form="alert-routes-escalate"
              requiredScope={ScopeNameEnum.AlertRouteUpdate}
              type="submit"
              theme={ButtonTheme.Primary}
              analyticsTrackingId="alert-routes-edit-sources-save"
            >
              Apply
            </GatedButton>
          </DrawerFooter>
        </DrawerContents>
      </Drawer>
    </ExpressionsMethodsProvider>
  );
};
