import {
  IntegrationsMicrosoftRedirectToDelegatedTokenAuthPurposeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useAuthRedirectPath } from "@incident-shared/utils/useAuthRedirectPath";
import { Button, ButtonTheme, IconEnum, Spinner, Txt } from "@incident-ui";
import { SpinnerTheme } from "@incident-ui/Spinner/Spinner";
import React from "react";
import { MSTeamsInstallFailure } from "src/components/msteams/InstallFailure";
import { SetupScreen } from "src/components/setup/SetupScreen";
import { useIdentity } from "src/contexts/IdentityContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

export const SetupInstallMsTeams = (): React.ReactElement => {
  const queryParams = useQueryParams();
  if (queryParams.get("pending") === "true") {
    return <PendingMSTeamsInstall />;
  }

  return (
    <>
      <SetupScreen title={"Let’s add incident.io to Teams"} padChildren={false}>
        <GrantConsent />

        <InstallBot />
      </SetupScreen>

      <div className="mt-3 text-slate-700 text-sm text-center">
        We only ask for Microsoft Teams permissions we need to do our job.
        <br />
        See our{" "}
        <Button
          href="https://help.incident.io/en/collections/3766495-security-faqs"
          theme={ButtonTheme.Link}
          openInNewTab
          analyticsTrackingId={"install-security-faqs"}
        >
          Security FAQs
        </Button>{" "}
        for more detail.
      </div>
    </>
  );
};

export const PendingMSTeamsInstall = (): React.ReactElement => {
  useAPI("identitySelf", undefined, {
    refreshInterval: 1000,
  });

  return (
    <>
      <SetupScreen title={"Installing our app to Teams..."}>
        <Txt className="pb-6">
          This can take a couple of minutes. Don&rsquo;t navigate away!
        </Txt>

        <Spinner className="mx-auto" theme={SpinnerTheme.Dark} large />
      </SetupScreen>
    </>
  );
};

const GrantConsent = () => {
  const { identity } = useIdentity();
  const { path: redirectUrl } = useAuthRedirectPath(
    "/auth/microsoft_install",
    `/${identity.organisation_slug}/setup/msteams/install`,
  );
  const disabled = identity.ms_teams_info?.missing_token_scopes?.length === 0;

  return (
    <div
      className={tcx("rounded-t-2 p-6", disabled ? "bg-slate-100" : "bg-white")}
    >
      <div className="block text-sm font-base text-slate-700 space-y-4 text-left mb-4">
        {!disabled && <MSTeamsInstallFailure />}
        <div className="text-content-primary space-y-2 p-2 text-m">
          <p>
            Now you&rsquo;re signed in, there&rsquo;s two parts to get our
            Microsoft Teams bot installed. First, you need to grant consent for
            us to see teams, channels and users in your account.
          </p>
          <p className="!mb-1">
            This won&rsquo;t give us permission to create a team or install our
            Microsoft Teams bot yet.
          </p>
        </div>
      </div>
      <GatedButton
        href={redirectUrl}
        analyticsTrackingId={"install-teams-app"}
        requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        upgradeRequired={false}
        theme={ButtonTheme.Primary}
        icon={IconEnum.Microsoft}
        disabled={disabled}
        disabledTooltipContent={
          "You've already connected your Microsoft account"
        }
        spanClassName="w-full"
        className="w-full"
      >
        Connect your Microsoft account {disabled && "✅"}
      </GatedButton>
    </div>
  );
};

const InstallBot = () => {
  const { identity } = useIdentity();
  const { path: redirectUrl } = useAuthRedirectPath(
    "/auth/microsoft_auth_short_lived_token",
    `/${identity.organisation_slug}/setup/msteams/install`,
    IntegrationsMicrosoftRedirectToDelegatedTokenAuthPurposeEnum.Install,
  );

  const enabled = identity.ms_teams_info?.missing_token_scopes?.length === 0;

  if (!identity.ms_teams_info?.auto_install_available) {
    return (
      <div
        className={tcx(
          "rounded-b-2 p-6",
          !enabled ? "bg-slate-100" : "bg-white",
        )}
      >
        <div className="block text-sm font-base text-slate-700 space-y-4 text-left mb-4">
          <div className="text-content-primary space-y-2 p-2 text-m">
            <p>
              You&rsquo;ll now need to install our Teams App. This currently
              requires taking a few manual steps inside Microsoft Teams:
            </p>
            <p className="!mb-1">This will:</p>
            <>
              <ol className="list-decimal pl-5 space-y-1 mb-2">
                <li>
                  Download our{" "}
                  <GatedButton
                    href={`/auth/microsoft_teams_app_download?organisation_id=${identity.organisation_id}`}
                    analyticsTrackingId={"ms-teams-manifest-download"}
                    theme={ButtonTheme.Link}
                    disabled={!enabled}
                    disabledTooltipContent={
                      "You must connect your Microsoft account first"
                    }
                    className="inline"
                  >
                    Microsoft Teams App file
                  </GatedButton>
                </li>
                <li>
                  Create an <code>Incidents</code> team where incidents will be
                  managed
                </li>
                <li>
                  Install our app to that Team, following the{" "}
                  <GatedButton
                    analyticsTrackingId={"ms-teams-manual-install-instructions"}
                    openInNewTab
                    disabled={!enabled}
                    disabledTooltipContent={
                      "You must connect your Microsoft account first"
                    }
                    href="https://help.incident.io/en/articles/9465048-manually-installing-incident-io-for-microsoft-teams"
                    theme={ButtonTheme.Link}
                    className="inline"
                  >
                    instructions here
                  </GatedButton>
                </li>
              </ol>
            </>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={tcx("rounded-b-2 p-6", !enabled ? "bg-slate-100" : "bg-white")}
    >
      <div className="block text-sm font-base text-slate-700 space-y-4 text-left mb-4">
        {enabled && <MSTeamsInstallFailure />}
        <div className="text-content-primary space-y-2 p-2 text-m">
          <p>
            Now we can see who is in your account, we can create a team for
            managing incidents and add our bot to it.
          </p>
          <p className="!mb-1">This will:</p>
          <>
            <ol className="list-decimal pl-5 space-y-1 mb-2">
              <li>
                Create an <code>Incidents</code> team where incidents will be
                managed
              </li>
              <li>
                Add the <code>@incident</code> bot to that team, which you can
                tag to interact with incidents
              </li>
              <li>
                Give the incident bot access to the Incidents team, and nothing
                else in your account
              </li>
            </ol>
          </>
        </div>
      </div>
      <GatedButton
        href={redirectUrl}
        analyticsTrackingId={"install-teams-app"}
        requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        upgradeRequired={false}
        icon={IconEnum.Microsoft}
        theme={ButtonTheme.Primary}
        disabled={!enabled}
        disabledTooltipContent={"You must connect your Microsoft account first"}
        spanClassName="w-full"
        className="w-full"
      >
        Install to Microsoft Teams
      </GatedButton>
    </div>
  );
};
