import { Link, LoadingBar, Txt } from "@incident-ui";
import { useState } from "react";
import {
  OnboardingSetDesiredPlanRequestBody,
  OnboardingSetDesiredPlanRequestBodyPlanNameEnum,
  Plan,
  PlanNameEnum as PlanNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { PlanCard } from "../settings/billing/BillingPlanCards";
import { BillingPlanUpgradeButton } from "../settings/billing/BillingPlanUpgradeButton";
import { useIsAEDriven } from "./useIsAEDriven";

export const SetupChoosePlan = () => {
  const { identity } = useIdentity();
  const isAEDriven = useIsAEDriven();
  const primaryCommsPlatform = usePrimaryCommsPlatform() || CommsPlatform.Slack;
  const commsPlatformLabel =
    primaryCommsPlatform === CommsPlatform.Slack ? "Slack" : "Microsoft Teams";

  const { trigger } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, data: OnboardingSetDesiredPlanRequestBody) => {
      await apiClient.onboardingSetDesiredPlan({
        setDesiredPlanRequestBody: data,
      });
    },
  );

  const {
    data: { plans },
    isLoading,
  } = useAPI("billingListPlans", undefined, { fallbackData: { plans: [] } });

  const defaultPlan = (identity?.onboarding_information?.desired_plan ??
    PlanNameEnum.TeamV2) as unknown as PlanNameEnum;

  // If you're on an AE-driven flow, don't show self-serve stuff
  const visiblePlans = isAEDriven
    ? plans.filter((plan) => !isSelfServe(plan))
    : plans;

  const [loadingPlan, setLoadingPlan] = useState<PlanNameEnum | null>(null);
  const onSelectPlan = (plan: { name: PlanNameEnum; label: string }) => {
    trigger({
      plan_name:
        plan.name as unknown as OnboardingSetDesiredPlanRequestBodyPlanNameEnum,
    });
    setLoadingPlan(plan.name);
  };

  const wantsBasic = defaultPlan === PlanNameEnum.BasicV2;

  return (
    <>
      <div className="text-center space-y-3">
        <h2 className="text-2xl font-semibold">
          {isAEDriven ? "Start your trial" : "Confirm your plan"}
        </h2>
        {isAEDriven ? (
          <p className="text-sm">
            Once you&rsquo;ve installed our {commsPlatformLabel} app, your
            account manager will be in touch to get your trial started.
          </p>
        ) : (
          <p className="text-sm">
            See our{" "}
            <Link
              analyticsTrackingId={"setup-pricing-page"}
              href="https://incident.io/pricing"
              openInNewTab
              className="transition"
            >
              pricing page
            </Link>{" "}
            for more information on each plan. If you&apos;re not sure, you can
            always change your plan later.
          </p>
        )}
      </div>

      {isLoading ? (
        <>
          <LoadingBar className="h-[351px] max-w-4xl mx-auto" />
        </>
      ) : (
        <>
          <div
            className={tcx(
              "grid grid-cols-1 gap-12 lg:gap-4 mx-4 lg:mx-auto max-w-6xl",
              visiblePlans.length === 2 ? "xl:grid-cols-2" : "xl:grid-cols-3",
            )}
          >
            {visiblePlans.map((plan, idx) => {
              const isDefault =
                (plan.name as unknown as PlanNameEnum) === defaultPlan;

              const buttonText = isAEDriven
                ? "Start trial"
                : isDefault
                ? `Continue with ${plan.label}`
                : `Choose ${plan.label}`;

              return (
                <PlanCard
                  key={plan.name}
                  plan={plan}
                  showingPrevious={idx > 0}
                  onSelectPlan={onSelectPlan}
                  highlight={isDefault}
                  upgradeButtonLabel={buttonText}
                  upgradeButtonLoading={loadingPlan === plan.name}
                />
              );
            })}
          </div>
          {wantsBasic && (
            <div className="mx-auto max-w-prose text-center space-y-2 mt-12">
              <BillingPlanUpgradeButton
                highlight
                planName={PlanNameEnum.BasicV2}
                onSelectPlan={() =>
                  onSelectPlan({ name: PlanNameEnum.BasicV2, label: "Basic" })
                }
                buttonLabel={"Continue with Basic"}
                loading={loadingPlan === PlanNameEnum.BasicV2}
              />

              <Txt grey>
                Incident management and On-call are free up to 5 users.
              </Txt>
            </div>
          )}
        </>
      )}
    </>
  );
};

const isSelfServe = (plan: Plan) =>
  plan.annual_purchase.can_self_serve && plan.monthly_purchase.can_self_serve;
