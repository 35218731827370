import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  SecondaryNavHorizontal,
  SecondaryNavHorizontalItemType,
} from "@incident-shared/layout/SecondaryNavHorizontal";
import { SecondaryPageWrapper } from "@incident-shared/layout/SecondaryPageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { GenericErrorMessage, IconEnum } from "@incident-ui";
import { Outlet } from "react-router";
import { useAPI } from "src/utils/swr";

import { shouldShowAlertSourcesEmptyState as shouldShowAlertsEmptyState } from "./alert-source-list/AlertEmptyStatePage";

export const AlertsPageWrapper = () => {
  const alertsPageGroups: SecondaryNavHorizontalItemType[] = [
    {
      label: "Alerts",
      slug: "recent",
    },
    {
      label: "Sources",
      slug: "sources",
    },
    {
      label: "Routes",
      slug: "routes",
    },
    {
      label: "Attributes",
      slug: "attributes",
    },
    {
      label: "Priorities",
      slug: "priorities",
    },
  ];

  const navigate = useOrgAwareNavigate();

  const {
    data: { alert_source_configs },
    isLoading: configsLoading,
    error: sourceConfigsError,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  const {
    data: alertRoutesData,
    isLoading: alertRoutesLoading,
    error: alertRoutesError,
  } = useAPI("alertRoutesListAlertRoutes", undefined);

  if (configsLoading || alertRoutesLoading) {
    return null;
  }

  const error = sourceConfigsError || alertRoutesError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const shouldShowEmptyState = shouldShowAlertsEmptyState(
    alert_source_configs,
    alertRoutesData?.alert_routes || [],
  );

  if (shouldShowEmptyState) {
    navigate("/alerts/get-started");
  }

  return (
    <PageWrapper width={PageWidth.Full} title="Alerts" icon={IconEnum.Alert}>
      <div className="flex flex-col h-full">
        <SecondaryNavHorizontal
          items={alertsPageGroups}
          pathPrefix={"alerts"}
        />
        <SecondaryPageWrapper className="h-full">
          <Outlet />
        </SecondaryPageWrapper>
      </div>
    </PageWrapper>
  );
};
