import {
  Alert,
  AlertAttribute,
  AlertSource,
  AlertSourceConfig,
  CatalogResourceColorEnum,
  CatalogResourceIconEnum,
  EngineParamBinding,
  EngineScope,
  Expression,
  Resource,
} from "@incident-io/api";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import { CatalogSetupWidget } from "@incident-shared/catalog/CatalogSetupWidget";
import {
  EngineFormElement,
  getVariableScope,
  isEmptyBinding,
  isExpression,
} from "@incident-shared/engine";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ReferenceWithExample } from "@incident-shared/engine/expressions/ExpressionsEditor";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { stylesForInvertedSelect } from "@incident-shared/engine/expressions/query/operations/JSONAttributeExpressionEditor";
import { JSONAttributeExpressionEditorModal } from "@incident-shared/engine/expressions/query/operations/JSONAttributeExpressionEditorModal";
import { ViewExpression } from "@incident-shared/engine/expressions/ViewExpression";
import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  EmptyState,
  IconEnum,
  StaticSingleSelect,
  Txt,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { JSONPreview } from "@incident-ui/JSONPreview/JSONPreview";
import { Popover } from "@incident-ui/Popover/Popover";
import { PopoverItem } from "@incident-ui/Popover/PopoverItem";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useForm, useFormContext, UseFormSetValue } from "react-hook-form";
import {
  AttributeList,
  AttributeListItem,
} from "src/components/catalog/entry-view/AttributeList";

import { useAttributeExpressions } from "../useAttributeExpressions";
import { useScopeWithAlertPreviews } from "../useScopeWithAlertPreviews";
import {
  AlertSourceConfigureFormData,
  TemplateFormData,
} from "./AlertSourceConfigurePage";
import { AlertSourceSection } from "./AlertSourceSection";
import { AlertSourceSuggestedAttributes } from "./AlertSourceSuggestedAttributes";
import { useSetupProgress } from "./useSetupProgress";

// Provides the attributes component that helps open the attributes drawer and
// displays attributes that are currently bound.
export const AlertSourceAttributes = ({
  scopeWithExpressions,
  resources,
  previewAlerts,
  alertSource,
  alertSourceConfig,
}: {
  scopeWithExpressions: EngineScope;
  resources: Resource[];
  alertSource: AlertSource;
  alertSourceConfig: AlertSourceConfig;
  previewAlerts: Alert[];
}) => {
  const formMethods = useFormContext<AlertSourceConfigureFormData>();
  const { watch, setValue } = formMethods;
  const variableScope = getVariableScope(scopeWithExpressions, resources);
  const [isEditing, setIsEditing] = useState(false);

  const [attributes, bindings, expressions] = watch([
    "attributes",
    "template.bindings",
    "template.expressions",
  ]);

  const populatedAttributes = attributes.filter((attr) => {
    if (!attr.id) return false;

    const binding = bindings[attr.id];
    if (!binding) return false;

    return !isEmptyBinding(binding);
  });

  const attributeListItems: AttributeListItem[] = populatedAttributes.map(
    (attribute) => {
      const binding = bindings[attribute.id as string];

      let expression: ExpressionFormData | undefined;
      if (binding.value?.reference && isExpression(binding.value.reference)) {
        expression = expressions.find((expr) =>
          (binding.value?.reference as string).includes(expr.reference),
        );
      }
      return {
        title: attribute.name,
        renderValues: () =>
          expression ? (
            <ViewExpression
              showExpressionName={false}
              expression={expression}
              scope={scopeWithExpressions}
            />
          ) : (
            <EngineBinding
              binding={binding as EngineParamBinding}
              resourceType={attribute.type}
              variableScope={variableScope}
              displayExpressionAs={"pill"}
            />
          ),
      };
    },
  );

  return (
    <>
      <AnimatePresence>
        {isEditing && (
          <AttributesDrawer
            onClose={() => setIsEditing(false)}
            resources={resources}
            setValue={setValue}
            previewAlerts={previewAlerts}
            alertSource={alertSource}
            alertSourceConfig={alertSourceConfig}
            initialData={{
              template: { bindings, expressions },
              attributes: attributes as AlertAttribute[],
            }}
          />
        )}
      </AnimatePresence>
      <AlertSourceSection
        title={"Attributes"}
        icon={IconEnum.Tag}
        onEdit={() => setIsEditing(true)}
      >
        <AttributeList items={attributeListItems} />
      </AlertSourceSection>
    </>
  );
};

type AttributesFormData = {
  template: Pick<TemplateFormData, "bindings" | "expressions">;
  attributes: AlertAttribute[];
};

const AttributesDrawer = ({
  onClose: onCloseCallback,
  resources,
  setValue,
  initialData,
  alertSource,
  alertSourceConfig,
  previewAlerts,
}: {
  onClose: () => void;
  resources: Resource[];
  setValue: UseFormSetValue<AlertSourceConfigureFormData>;
  initialData: AttributesFormData;
  alertSource: AlertSource;
  alertSourceConfig: AlertSourceConfig;
  previewAlerts: Alert[];
}) => {
  const formMethods = useForm<AttributesFormData>({
    defaultValues: initialData,
  });

  const [selectedAlert, setSelectedAlert] = useState<Alert | null>(
    previewAlerts[0],
  );

  const {
    onAddExpression,
    onEditExpression,
    showExpressionsModal,
    expressionDefaults,
    expressionsMethods,
    setExpressionDefaults,
    openExpressionsModal,
    closeExpressionsModal,
    initialExpression,
  } = useAttributeExpressions({
    formMethods,
  });

  // Get a scope with the alert JSON previews patched in
  const scopeWithPreviews = useScopeWithAlertPreviews({
    alerts: previewAlerts,
    alertSource,
  });

  const onClickAttribute = (attribute: AlertAttribute, path?: string) => {
    setExpressionDefaults({ attribute, path });
    openExpressionsModal();
  };

  const onClickNewAttribute = (path?: string) => {
    if (path) {
      setExpressionDefaults({ path });
    }
    openExpressionsModal();
  };

  const scopeWithExpressions = addExpressionsToScope<ReferenceWithExample>(
    scopeWithPreviews,
    expressionsMethods.fields as unknown as ExpressionFormData[],
  );

  const [bindings, attributes] = formMethods.watch([
    "template.bindings",
    "attributes",
  ]);

  const onSubmit = (data: AttributesFormData) => {
    setValue("template.bindings", data.template.bindings, {
      shouldDirty: true,
    });
    setValue("template.expressions", data.template.expressions);

    setValue("attributes", data.attributes);
    // Reset the form and close the drawer
    formMethods.reset();

    onClose();
  };

  const sharedFormElementProps = {
    resources,
    scope: scopeWithExpressions,
    array: false,
    showPlaceholder: true,
  };

  const populatedAttributes = attributes.filter((attr) => {
    if (!attr.id) return false;

    const binding = bindings[attr.id];
    if (!binding) return false;

    return !isEmptyBinding(binding);
  });

  const { state: setupState } = useSetupProgress({
    boundAttributeIds: populatedAttributes.map((b) => b.id),
  });

  const { onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onCloseCallback,
  );
  const onClose = () => onCloseWithWarn(formMethods.formState.isDirty);

  const noAttributesCTAProps = {
    attributes,
    resources,
    onClickAttribute,
    onClickNewAttribute,
    populatedAttributes,
  };

  return (
    <Drawer width="full" onClose={onClose}>
      <DrawerContents className="overflow-hidden">
        <DrawerTitle
          title="Attributes"
          onClose={onClose}
          icon={IconEnum.Tag}
          compact
        />
        <DrawerBody className="p-0 overflow-y-hidden">
          <FormV2
            id="alert-native-properties"
            formMethods={formMethods}
            onSubmit={onSubmit}
            outerClassName="grow overflow-y-hidden"
            loadingWrapperClassName="h-full"
            innerClassName="h-full"
          >
            <ExpressionsMethodsProvider
              expressionsMethods={expressionsMethods}
              allowAllOfACatalogType={false}
            >
              <div className={"flex flex-col lg:flex-row h-full grow"}>
                <div className={"flex flex-col h-full overflow-y-auto"}>
                  <div className="grow flex flex-col gap-6 p-6 max-w-[640px] mx-auto">
                    <div className={"flex flex-col space-y-4"}>
                      <div className="text-content-secondary">
                        Attributes provide additional context on your alerts,
                        such as affected feature, affected customer or the
                        environment the alert occurred in.
                      </div>
                      {setupState ? (
                        <CatalogSetupWidget setupState={setupState} />
                      ) : null}
                      {alertSourceConfig.id ? (
                        <AlertSourceSuggestedAttributes
                          alertSourceConfig={alertSourceConfig}
                          scopeWithExpressions={scopeWithExpressions}
                          attributes={attributes}
                        />
                      ) : null}
                      {populatedAttributes.map((a) => {
                        const binding = bindings[a.id];
                        const bindingIsExpression = isExpression(
                          binding.value?.reference ||
                            binding.array_value?.[0]?.reference ||
                            "",
                        );

                        return (
                          <motion.div
                            key={a.id}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                          >
                            <EngineFormElement
                              name={`template.bindings.${a.id}`}
                              resourceType={a.type}
                              labelNode={
                                <div className="flex w-full justify-between gap-2">
                                  <span className="truncate text-xs-med text-content-tertiary">
                                    {a.name}
                                  </span>
                                  {!bindingIsExpression && (
                                    <Button
                                      analyticsTrackingId={null}
                                      theme={ButtonTheme.Unstyled}
                                      className="text-red-content hover:text-red-800 font-normal"
                                      onClick={() =>
                                        formMethods.setValue(
                                          `template.bindings.${a.id}`,
                                          {},
                                        )
                                      }
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </div>
                              }
                              {...sharedFormElementProps}
                              array={a.array}
                              required
                              mode="variables_and_expressions"
                              isAlertElement
                            />
                          </motion.div>
                        );
                      })}
                      {/* Show the empty state or the copy with a link */}
                      {isEmpty(populatedAttributes) ? (
                        <EmptyState
                          icon={IconEnum.Tag}
                          title="Attributes"
                          content={
                            <NoAttributesCTA {...noAttributesCTAProps} />
                          }
                        />
                      ) : (
                        <NoAttributesCTA
                          className={"text-content-secondary text-sm"}
                          {...noAttributesCTAProps}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "hidden border-l border-stroke bg-surface-invert lg:flex flex-col gap-6 lg:w-[50%] h-full grow"
                  }
                >
                  <JSONPreview
                    containerClassName={"h-full grow"}
                    payload={JSON.parse(selectedAlert?.payload || "{}")}
                    titleElement={
                      <div className="flex items-center justify-between p-6">
                        <Txt xs greySmallCaps className={"w-full"}>
                          Alert payload
                        </Txt>
                        <StaticSingleSelect
                          className={
                            "!w-[220px] !bg-surface-invert !text-white rounded-[6px] border-none"
                          }
                          styles={stylesForInvertedSelect}
                          value={selectedAlert?.id}
                          onChange={(value) => {
                            if (value && typeof value === "string") {
                              const selected = previewAlerts.find(
                                (a) => a.id === value,
                              );
                              if (selected) {
                                setSelectedAlert(selected);
                              }
                            }
                          }}
                          options={previewAlerts.map((a) => ({
                            label: a.title,
                            value: a.id,
                          }))}
                          isClearable={false}
                        />
                      </div>
                    }
                    getKeyPopoverContent={(path: string) => (
                      <JSONPreviewPopover
                        path={path}
                        attributes={attributes}
                        resources={resources}
                        onClickAttribute={onClickAttribute}
                        onClickNewAttribute={onClickNewAttribute}
                        assignedAttributes={
                          new Set(
                            populatedAttributes.map((a) => a.id as string),
                          )
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </ExpressionsMethodsProvider>
          </FormV2>
        </DrawerBody>
        <DrawerFooter>
          <div className="flex items-center justify-end gap-2">
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Secondary}
              onClick={onCloseCallback}
            >
              Cancel
            </Button>
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Primary}
              onClick={formMethods.handleSubmit(onSubmit)}
              form="alert-native-properties"
            >
              Apply
            </Button>
          </div>
        </DrawerFooter>
      </DrawerContents>
      {/* We don't use EngineFormElement here because we don't know what the return type will be */}
      {showExpressionsModal && (
        <JSONAttributeExpressionEditorModal
          allowHardcodedValue={expressionDefaults.isCreatingAttribute}
          onClose={closeExpressionsModal}
          onEditExpression={onEditExpression}
          onAddExpression={onAddExpression}
          existingExpressions={
            expressionsMethods.fields as unknown as Expression[]
          }
          scope={scopeWithExpressions}
          initialExpression={initialExpression}
          initialPath={expressionDefaults.path}
          existingAttribute={expressionDefaults.attribute}
        />
      )}
    </Drawer>
  );
};

// The copy for no attributes is the same whether it is shown in the empty state
// or outside of it, so we extract it into this component to keep it consistent.
const NoAttributesCTA = ({
  className,
  attributes,
  resources,
  onClickAttribute,
  onClickNewAttribute,
  populatedAttributes,
}: {
  className?: string;
  attributes: AlertAttribute[];
  resources: Resource[];
  onClickAttribute: (attribute: AlertAttribute, path?: string) => void;
  onClickNewAttribute: (path?: string) => void;
  populatedAttributes: AlertAttribute[];
}) => {
  return (
    <div className={className}>
      Create new attributes from your payload preview opposite. Or&nbsp;
      <Popover
        trigger={
          <Button
            analyticsTrackingId="add-new-attribute"
            theme={ButtonTheme.Naked}
            className={"text-content-primary underline"}
          >
            create one from scratch.
          </Button>
        }
      >
        <JSONPreviewPopover
          attributes={attributes}
          resources={resources}
          onClickAttribute={onClickAttribute}
          onClickNewAttribute={onClickNewAttribute}
          assignedAttributes={
            new Set(populatedAttributes.map((a) => a.id as string))
          }
        />
      </Popover>
    </div>
  );
};

// Opens a popover with existing alert attributes that can be clicked to start
// binding them to the alert source.
const JSONPreviewPopover = ({
  path,
  attributes,
  resources,
  assignedAttributes,
  onClickAttribute,
  onClickNewAttribute,
}: {
  path?: string;
  attributes: AlertAttribute[];
  resources: Resource[];
  assignedAttributes: Set<string>;
  onClickAttribute: (attribute: AlertAttribute, path?: string) => void;
  onClickNewAttribute: (path?: string) => void;
}) => {
  const availableAttributes = attributes.filter(
    (a) => !assignedAttributes.has(a.id as string),
  );

  return (
    <>
      {availableAttributes.length > 0 && (
        <>
          {availableAttributes.map((a) => {
            const resource = resources.find((r) => r.type === a.type);
            return (
              <PopoverItem
                key={a.id}
                onClick={() => onClickAttribute(a, path)}
                className="p-1"
              >
                <CatalogEntryBadge
                  color={
                    (resource?.field_config
                      .color as unknown as ColorPaletteEnum) ||
                    CatalogResourceColorEnum.Blue
                  }
                  icon={
                    resource?.field_config.icon || CatalogResourceIconEnum.Box
                  }
                  label={a.name}
                  className="cursor-pointer"
                />
              </PopoverItem>
            );
          })}
        </>
      )}
      <hr />
      <PopoverItem
        onClick={() => onClickNewAttribute(path)}
        icon={IconEnum.Add}
      >
        Add new attribute
      </PopoverItem>
    </>
  );
};
