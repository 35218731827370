import { useAPI } from "src/utils/swr";

export const useFollowUpScope = () => {
  const { data, isLoading, mutate, error } = useAPI(
    "followUpsBuildScope",
    {},
    {
      fallbackData: { scope: { references: [], aliases: {} } },
    },
  );
  return {
    scope: data.scope,
    scopeLoading: isLoading,
    refetchScope: async () => {
      await mutate();
    },
    scopeError: error,
  };
};
