/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnboardingSubmitInformationFormRequestBody
 */
export interface OnboardingSubmitInformationFormRequestBody {
    /**
     * What is the main value the installer wants to get from us?
     * @type {string}
     * @memberof OnboardingSubmitInformationFormRequestBody
     */
    value_prop?: string;
}

export function OnboardingSubmitInformationFormRequestBodyFromJSON(json: any): OnboardingSubmitInformationFormRequestBody {
    return OnboardingSubmitInformationFormRequestBodyFromJSONTyped(json, false);
}

export function OnboardingSubmitInformationFormRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingSubmitInformationFormRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value_prop': !exists(json, 'value_prop') ? undefined : json['value_prop'],
    };
}

export function OnboardingSubmitInformationFormRequestBodyToJSON(value?: OnboardingSubmitInformationFormRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value_prop': value.value_prop,
    };
}

