import { EngineScope, Resource } from "@incident-io/api";
import { getVariableScope } from "@incident-shared/engine";
import { MenuEntry } from "@incident-shared/engine";
import {
  AddNewButton,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  SortableList,
} from "@incident-ui";
import React from "react";
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import { lookupInScope } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";

import { WorkflowStepListItem } from "../common/types";
import { useLoopScope } from "../common/utils";
import { WorkflowsLoopVariableSelector } from "./WorkflowsLoopVariableSelector";
import { WorkflowStepCard } from "./WorkflowStepCard";

type WorkflowLoopingStepCardProps = {
  forEachValue: string | undefined;
  resources: Resource[];
  scope: EngineScope;
  steps: WorkflowStepListItem[];
  // Required to make the card draggable.
  innerRef?: React.LegacyRef<HTMLDivElement>;
  draggableProps: DraggableProvidedDraggableProps | null | undefined;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  onClickAddStep: () => void;
  onEditStep: (stepIndex: number) => void;
  onRemoveStep: (stepIndex: number) => void;
  onRemoveStepGroup: () => void;
  onReorderStepsWithinGroup: (steps: WorkflowStepListItem[]) => void;
  onSetForEach: (forEach: string) => void;
};

// WorkflowLoopingStepCard renders a card that represents a looping
// step in a workflow.
export const WorkflowLoopingStepCard = (
  props: WorkflowLoopingStepCardProps,
): React.ReactElement => {
  const {
    resources,
    scope,
    steps,
    innerRef,
    draggableProps,
    dragHandleProps,
    forEachValue,
    onClickAddStep,
    onEditStep,
    onRemoveStep,
    onRemoveStepGroup,
    onReorderStepsWithinGroup,
    onSetForEach,
  } = props;

  const hasSteps = steps.length > 0;
  const scopeVar = forEachValue
    ? lookupInScope(scope, forEachValue)
    : undefined;
  const isForEachValueSet = forEachValue !== undefined;
  const isSelectable = (entry: MenuEntry) => {
    return entry.array;
  };

  const loopScope = useLoopScope(scopeVar?.key || "", scope);

  const variableScope = getVariableScope(loopScope, resources);

  return (
    <div
      ref={innerRef}
      {...draggableProps}
      className="w-full border-[1px] rounded-2 border-radius border-stroke"
    >
      {/* Looping step header section */}
      <div className="flex flex-row items-center rounded-t-lg bg-surface-secondary px-3 py-2">
        <div {...dragHandleProps}>
          <Icon
            className="mr-2"
            id={IconEnum.Draggable}
            size={IconSize.Small}
          />
        </div>
        <div className="space-x-2 mr-2 flex-center-y grow min-w-0">
          <span className="text-sm font-normal min-w-[116px]">
            For each value of
          </span>
          <WorkflowsLoopVariableSelector
            label={scopeVar?.label || "Select a variable"}
            scope={scope}
            onSelectReference={(val) => onSetForEach(val.key)}
            isSelectable={isSelectable}
            disabled={hasSteps}
            referenceKey={scopeVar?.key || ""}
          />
        </div>
        <Button
          analyticsTrackingId="workflowsv2-looping-step-delete"
          onClick={onRemoveStepGroup}
          theme={ButtonTheme.Naked}
          icon={IconEnum.Delete}
          // We need some negative margin to make the icon align with the
          // padding of the card.
          className="-mr-[4px]"
          iconProps={{
            size: IconSize.Medium,
          }}
          title="Delete"
        />
      </div>

      {/* Looping step body section */}
      <div className="flex flex-col items-center p-3">
        <SortableList
          items={steps}
          containerTag="div"
          containerProps={{
            className: "flex flex-col items-center w-full",
          }}
          sortOrder="asc"
          // At this level we want to reorder the steps within a group.
          updateItemRanks={onReorderStepsWithinGroup}
          droppableID="groupsteps-steps"
          renderItem={(renderItemProps) => {
            const {
              item: step,
              index: stepIndex,
              draggableProvidedProps,
            } = renderItemProps;

            // Take common draggable props, which are needed to make
            // the child component draggable.
            const commonDraggableProps = {
              innerRef: draggableProvidedProps.innerRef,
              draggableProps: draggableProvidedProps.draggableProps,
              dragHandleProps: draggableProvidedProps.dragHandleProps,
            };

            const isLastStep = stepIndex === steps.length - 1;
            const isFirstStep = stepIndex === 0;

            return (
              <>
                <WorkflowStepCard
                  isFirstStep={isFirstStep}
                  isLastStep={isLastStep}
                  variableScope={variableScope}
                  step={step}
                  {...commonDraggableProps}
                  stepNumber={stepIndex + 1}
                  onEditStep={() => {
                    onEditStep(stepIndex);
                  }}
                  onRemoveStep={() => {
                    onRemoveStep(stepIndex);
                  }}
                />
                {
                  // NOTE: We want to add a line divider between steps, but not after the last step
                  // hasManySteps && !isLastStep ? <LineDivider /> : null
                }
              </>
            );
          }}
        />

        <AddNewButton
          onClick={() => {
            onClickAddStep();
          }}
          className={tcx(
            "!no-underline text-slate-600 ml-[unset] disabled:pointer-events-none",
            {
              "mt-3": hasSteps,
              "opacity-50": !isForEachValueSet,
            },
          )}
          disabled={!isForEachValueSet}
          analyticsTrackingId={"workflows-v2-add-step-within-loop"}
          title="Add step within loop"
        />
      </div>
    </div>
  );
};
