import _ from "lodash";
import { createContext, useContext, useMemo } from "react";
import {
  EngineScope,
  ErrorResponse,
  Resource,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

export const useAllResources = (): {
  resources: Resource[];
  resourcesLoading: boolean;
  refetchResources: () => Promise<void>;
  resourcesError: ErrorResponse | undefined;
} => {
  const { data, isLoading, mutate, error } = useAPI(
    "engineListAllResources",
    {},
    {
      fallbackData: { resources: [] },
    },
  );

  const { additionalResources } = useContext(AdditionalResourcesContext);

  // We memoise this, to avoid re-renders due to things like the order of the
  // array changing
  const allResources = useMemo(
    () =>
      _.uniqBy([...data.resources, ...additionalResources], (res) => res.type),
    [data.resources, additionalResources],
  );

  return {
    resources: allResources,
    resourcesLoading: isLoading,
    refetchResources: async () => {
      await mutate();
    },
    resourcesError: error,
  };
};

// when its undefined it will not fetch the resources
export const useScopeResources = (
  scope: EngineScope | undefined,
  additionalTypes?: string[],
) => {
  const resourceTypes =
    scope && scope.references.length > 0
      ? _.uniq(
          scope.references.map((ref) => ref.type).concat(additionalTypes ?? []),
        )
      : [];

  const { data, isLoading, mutate, error } = useAPI(
    resourceTypes.length > 0 ? "engineListResources" : null,
    {
      listResourcesRequestBody: {
        resource_types: resourceTypes,
      },
    },
    {
      fallbackData: { resources: [] },
    },
  );

  return {
    resources: data.resources,
    resourcesLoading: isLoading,
    refetchResources: async () => {
      await mutate();
    },
    resourcesError: error,
  };
};

const AdditionalResourcesContext = createContext<{
  additionalResources: Resource[];
}>({ additionalResources: [] });

// AdditionalResourcesProvider allows you to inject extra engine resources into
// the response from `useAllResources`. This is useful if you have an API that
// returns references to resources that are lazily evaluated, and won't be
// enumerated by `engineListAllResources`.
//
// E.g. when loading the creation fields for an issue tracker, the API returns:
// ```json
// {
//   "fields": [
//     { "resource": "JiraServerCustomField[\"customfield_12345\"]", ... },
//     ...
//   ],
//   "resources": [
//      {
//        "type": "JiraServerCustomField[\"customfield_12345\"]",
//        "label": "...",
//         ...
//      },
//      ...
//    ]
// }
// ```
//
// In this case, you'll want to wrap components down the stack in
// `<AdditionalResourcesProvider>` and pass in those extra resources. This
// allows all our engine components (e.g. `<EngineFormElement>`) to be able to
// render based on information about the resource types that are specific to
// that set of `fields` without needing to make extra API calls or tweak how
// those components work.
export const AdditionalResourcesProvider = ({
  additionalResources,
  children,
}: {
  additionalResources: Resource[];
  children: React.ReactNode;
}) => {
  return (
    <AdditionalResourcesContext.Provider value={{ additionalResources }}>
      {children}
    </AdditionalResourcesContext.Provider>
  );
};
