import React from "react";
import { SavedViewsListContextEnum } from "src/contexts/ClientContext";

import { InsightsDashboardSection } from "../../common/InsightsDashboardSection";
import { InsightsPage } from "../../common/InsightsPage";
import { InsightsContextProvider } from "../../context/InsightsContextProvider";
import { StateConfig } from "../../context/types";
import { ExploDashboardID } from "../dashboards";

export type PagerLoadDashboardStateConfig = StateConfig;

export const NativePagerLoadDashboard = (): React.ReactElement => {
  return (
    <InsightsContextProvider<PagerLoadDashboardStateConfig>
      context={SavedViewsListContextEnum.InsightsV2}
      stateConfig={{}}
      filtersKind="escalation_targets"
    >
      <InsightsPage dashboard="pager-load">
        <PagesDashboard />
      </InsightsPage>
    </InsightsContextProvider>
  );
};

const PagesDashboard = () => {
  return (
    <InsightsDashboardSection
      title="Pages"
      description="Understand how often people are being paged, and at what times of day. We use the timezone of the user, as taken from their Slack profile."
      dashboardEmbedID={ExploDashboardID.OnCallPagerImpactNative}
      initialHeight="874px"
    />
  );
};
