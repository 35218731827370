import {
  Button,
  ButtonTheme,
  IconEnum,
  Link,
  SlackTeamAvatar,
} from "@incident-ui";
import { Icon, IconSize } from "@incident-ui/Icon/Icon";
import { useNotifications } from "src/components/notifications/useNotifications";
import { useIdentity } from "src/contexts/IdentityContext";
import { useLogoutAll } from "src/hooks/useLogoutAll";
import { tcx } from "src/utils/tailwind-classes";

import { SidebarDropdownMenu } from "../MainSidebar/SidebarDropdownMenu";
import logoMobile from "./logo-mobile.svg";
import styles from "./MobileHeader.module.scss";

type Props = {
  toggleSidebar: () => void;
  sidebarIsOpen: boolean;
  showNotifications: boolean;
  openNotificationsBar: () => void;
  closeNotificationsBar: () => void;
};

export function MobileHeader({
  toggleSidebar,
  sidebarIsOpen,
  showNotifications,
  openNotificationsBar,
  closeNotificationsBar,
}: Props): React.ReactElement | null {
  const { identity } = useIdentity();
  const logoutAll = useLogoutAll();
  const { unreadCount: unreadNotifications } = useNotifications();

  const headerImg = (
    <Link to="/" analyticsTrackingId={null}>
      <span className="sr-only">incident.io</span>
      <img src={logoMobile} className="h-10" alt=""></img>
    </Link>
  );

  if (identity == null) {
    // We shouldn't ever render this without an identity, if we do return nothing
    return null;
  }

  return (
    <header
      id="mobile-header"
      className={tcx(styles.header, "bg-surface-invert text-white relative", {
        ["bg-purple-800"]: process.env.REACT_APP_DEV_SIDEBAR === "true",
      })}
    >
      <div className="flex justify-center absolute w-full p-4">{headerImg}</div>

      <div className="flex w-full justify-between p-4 z-10">
        <Button
          id="open-sidebar-mobile"
          theme={ButtonTheme.Unstyled}
          analyticsTrackingId="open-sidebar-mobile"
          icon={sidebarIsOpen ? IconEnum.Close : IconEnum.Menu}
          iconProps={{
            size: IconSize.Medium,
          }}
          title={sidebarIsOpen ? "Close main menu" : "Open main menu"}
          onClick={toggleSidebar}
        />
        <div className="flex items-center gap-2">
          <div className="flex items-center">
            <Button
              icon={IconEnum.Notification}
              iconProps={{ size: IconSize.Large }}
              title="Notifications"
              analyticsTrackingId="show-notifications"
              theme={ButtonTheme.Unstyled}
              className={styles.notificationsButton}
              onClick={() => {
                if (showNotifications) {
                  closeNotificationsBar();
                } else {
                  openNotificationsBar();
                }
              }}
            />
            <div className={styles.unreadNotificationsWrapper}>
              {unreadNotifications > 0 && (
                <div className={styles.unreadNotifications}>
                  {unreadNotifications}
                </div>
              )}
            </div>
          </div>
          <SidebarDropdownMenu
            identity={identity}
            logoutAll={logoutAll}
            offset={0}
            renderTriggerButton={() => (
              <button
                type="button"
                className={tcx(
                  "flex items-center gap-0.5 group cursor-pointer",
                )}
              >
                <SlackTeamAvatar
                  title=""
                  url={identity.organisation_icon_url}
                  size={IconSize.Medium}
                />
                <Icon
                  id={IconEnum.Expand}
                  className="text-content-tertiary group-hover:text-white"
                  size={IconSize.Small}
                />
              </button>
            )}
          />
        </div>
      </div>
    </header>
  );
}
