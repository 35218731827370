import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { Loader, Steps } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { addMonths, endOfMonth, format, parse, startOfMonth } from "date-fns";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Schedule,
  SchedulePayConfig,
  ScheduleReport,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

import { ReportGeneratorChooseSchedules } from "./ReportGeneratorChooseSchedules";
import { ReportGeneratorChooseWindow } from "./ReportGeneratorChooseWindow";
import { ReportGeneratorConfirmHolidays } from "./ReportGeneratorConfirmHolidays";
import { ReportGeneratorPublish } from "./ReportGeneratorPublish";

// To generate a report, we send someone through a 'wizard'.
// That wizard takes you through a few steps:
// Step 1: pay configs. We'll only show this if you don't have any, and ask you to create pay configs.
// Step 2: schedules. Select any schedules you want to use. If you need to import some, you'll need to select (or create) a pay config. That's cool.
// Step 3: tell us the start/end of the report.
// You've now got a draft report! That'll be at /reports/R123.

enum ReportGeneratorSteps {
  Schedules = "schedules",
  DateRange = "date_range",
  Holidays = "holidays",
  Publish = "publish",
}

export type ScheduleWithPayConfig = {
  schedule: Schedule;
  pay_config?: SchedulePayConfig;
};

export type DatesAsStrings<T> = {
  [K in keyof T]: T[K] extends Array<infer U>
    ? Array<DatesAsStrings<U>>
    : T[K] extends { [L in keyof T[K]]: T[K][L] }
    ? DatesAsStrings<T[K]>
    : T[K] extends Date
    ? string
    : T[K];
};

export type SerialisedScheduleWithPayConfig =
  DatesAsStrings<ScheduleWithPayConfig>;

// We also want to store dates, but helpfully inputs save those as strings, so
// we mirror that here
export type ReportGeneratorFormType = {
  schedules_with_pay_configs: SerialisedScheduleWithPayConfig[];
  start_date: string;
  end_date: string;
  name: string;
};

const SIMPLE_DATE_FORMAT = "yyyy-MM-dd";

export const parseSimpleDate = (str: string): Date =>
  parse(str, SIMPLE_DATE_FORMAT, new Date());

export const ReportGeneratorWrapper = ({
  reportId,
}: {
  reportId: string | null;
}): React.ReactElement => {
  const { data: reportData, error: fetchReportError } = useAPI(
    reportId ? "schedulesShowReport" : null,
    { id: reportId ?? "" },
  );
  const report = reportData?.schedule_report;

  if (fetchReportError) {
    throw fetchReportError;
  }

  if (reportId && !report) {
    return <Loader />;
  }

  if (report?.published_at) {
    return (
      <OrgAwareNavigate
        to={`/on-call/pay-calculator/reports/${reportId}`}
        replace
      />
    );
  }

  return <ReportGenerator report={report ?? null} />;
};

export const ReportGenerator = ({
  report,
}: {
  report: ScheduleReport | null;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();
  const [currentStep, setCurrentStep] = useState<ReportGeneratorSteps>(
    report ? ReportGeneratorSteps.Publish : ReportGeneratorSteps.DateRange,
  );

  const lastMonth = addMonths(new Date(), -1);

  // If there's a report, this form is totally useless. We can't easily reproduce
  // the data we'd want - for example, the report could be pointing at a schedule that
  // no longer exists. We will fix this one day, but not now. For the time being, there's
  // no way 'back' from a draft report. I think that's OK - you can start again pretty
  // easily.
  const {
    watch,
    register,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = useForm<ReportGeneratorFormType>({
    defaultValues: {
      // Default to the last full month (seems like the most sensible)
      start_date: format(startOfMonth(lastMonth), SIMPLE_DATE_FORMAT),
      end_date: format(endOfMonth(lastMonth), SIMPLE_DATE_FORMAT),
      name: format(startOfMonth(lastMonth), "MMM yyyy") + " Report",
      schedules_with_pay_configs: [],
    },
  });

  const selectedSchedules = watch("schedules_with_pay_configs");
  const startDate = watch("start_date");
  const endDate = watch("end_date");

  const { data: payConfigData, error: payConfigsError } = useAPI(
    "schedulesListPayConfig",
    undefined,
  );
  const payConfigs = payConfigData?.schedule_pay_configs;
  if (payConfigsError) {
    throw payConfigsError;
  }

  const {
    data: { schedules: allSchedules },
  } = useAPI("schedulesList", undefined, {
    fallbackData: { schedules: [], users: [] },
  });
  const {
    data: { schedule_pay_configs: allPayConfigs },
  } = useAPI("schedulesListPayConfig", undefined, {
    fallbackData: { schedule_pay_configs: [] },
  });

  const {
    trigger: onCreateReport,
    isMutating: savingReport,
    genericError: saveReportError,
  } = useAPIMutation(
    "schedulesListReport",
    undefined,
    async (apiClient, data: ReportGeneratorFormType) => {
      const res = await apiClient.schedulesCreateReport({
        createReportRequestBody: {
          name: data.name,
          start_date: data.start_date,
          end_date: data.end_date,
          schedules: data.schedules_with_pay_configs.map((ea) => ({
            schedule_id: ea.schedule.id,
            // This is to make typescript happy: in fact, if we send an empty string, we'll get a 422 from the backend.
            pay_config_id: ea.pay_config?.id || "",
          })),
        },
      });

      setCurrentStep(ReportGeneratorSteps.Publish);
      navigate(
        `/on-call/pay-calculator/reports/create?reportId=${res.schedule_report.id}`,
      );
    },
    {
      setError,
      onError: (_, fieldErrors) => {
        Object.values(fieldErrors || {}).forEach((e) => {
          showToast({
            theme: ToastTheme.Error,
            title: e,
          });
        });
      },
    },
  );
  if (saveReportError) {
    throw saveReportError;
  }

  if (!identity || !payConfigs || savingReport) {
    return <Loader />;
  }

  const onAddSchedule = (schedule: Schedule) => {
    // If the schedule has a default pay config, we should use that. If not, all good,
    // we'll get the user to select it.
    const pay_config = payConfigs.find(
      (config) => config.id === schedule.default_pay_config_id,
    );

    setValue<"schedules_with_pay_configs">("schedules_with_pay_configs", [
      ...(selectedSchedules || []),
      { schedule, pay_config },
    ]);
  };

  const onRemoveSchedule = (scheduleID: string) => {
    setValue<"schedules_with_pay_configs">(
      "schedules_with_pay_configs",
      selectedSchedules?.filter((c) => c.schedule.id !== scheduleID),
    );
  };

  const onUpdatePayConfigOnSchedule = (
    schedule: Schedule,
    pay_config: SchedulePayConfig,
  ) => {
    const updatedSchedules = selectedSchedules.map((ea) => {
      if (ea.schedule.id === schedule.id) {
        return {
          schedule: ea.schedule,
          pay_config,
        };
      }
      return ea;
    });

    setValue<"schedules_with_pay_configs">(
      "schedules_with_pay_configs",
      updatedSchedules,
    );
  };

  const steps = [
    {
      id: ReportGeneratorSteps.DateRange,
      name: "Basic settings",
    },
    {
      id: ReportGeneratorSteps.Schedules,
      name: "Schedules & pay",
    },
    {
      id: ReportGeneratorSteps.Holidays,
      name: "Confirm holidays",
    },
    {
      id: ReportGeneratorSteps.Publish,
      name: "Publish report",
    },
  ];

  let content: React.ReactNode;
  switch (currentStep) {
    case ReportGeneratorSteps.DateRange:
      // TODO: deal with all the validation we need to here!
      content = (
        <ReportGeneratorChooseWindow
          register={register}
          errors={errors}
          startDate={startDate}
          endDate={endDate}
          onContinue={async () => {
            const isValid = await trigger(["start_date", "end_date", "name"]);
            if (isValid) {
              setCurrentStep(ReportGeneratorSteps.Schedules);
            }
          }}
        />
      );
      break;
    case ReportGeneratorSteps.Schedules:
      content = (
        <ReportGeneratorChooseSchedules
          payConfigs={payConfigs}
          onAddSchedule={onAddSchedule}
          onRemoveSchedule={onRemoveSchedule}
          onUpdatePayConfigOnSchedule={onUpdatePayConfigOnSchedule}
          onDeSelectAllSchedules={() => {
            setValue<"schedules_with_pay_configs">(
              "schedules_with_pay_configs",
              [],
            );
          }}
          onSelectAllSchedules={(allSchedules) => {
            setValue<"schedules_with_pay_configs">(
              "schedules_with_pay_configs",
              allSchedules,
            );
          }}
          selectedSchedules={selectedSchedules}
          onBack={() => setCurrentStep(ReportGeneratorSteps.DateRange)}
          onContinue={() => setCurrentStep(ReportGeneratorSteps.Holidays)}
        />
      );
      break;
    case ReportGeneratorSteps.Holidays:
      content = (
        <ReportGeneratorConfirmHolidays
          startDate={parseSimpleDate(startDate)}
          endDate={parseSimpleDate(endDate)}
          selectedSchedules={selectedSchedules}
          onBack={() => setCurrentStep(ReportGeneratorSteps.Schedules)}
          onContinue={() => onCreateReport(watch())}
        />
      );
      break;
    case ReportGeneratorSteps.Publish:
      if (!report) {
        throw new Error(
          "Unreachable: expected a report to exist on publish step",
        );
      }

      content = (
        <ReportGeneratorPublish
          report={report}
          onBack={async () => {
            setValue<"start_date">("start_date", report.start_date);
            setValue<"end_date">("end_date", report.end_date);
            setValue<"name">("name", report.name);

            const schedulesAndPayConfigs = report.schedules.map((sched) => ({
              schedule: allSchedules.find((x) => x.id === sched.id),
              pay_config: allPayConfigs.find(
                (x) => x.id === sched.pay_config_id,
              ),
            }));

            setValue(
              "schedules_with_pay_configs",
              schedulesAndPayConfigs.filter(
                (ea) => ea.schedule != null && ea.pay_config != null,
              ) as unknown as ScheduleWithPayConfig[],
            );

            setCurrentStep(ReportGeneratorSteps.Holidays);
          }}
        />
      );

      break;
    default:
      assertUnreachable(currentStep);
  }

  return (
    <>
      <Steps steps={steps} currentStep={currentStep} />
      {content}
    </>
  );
};
