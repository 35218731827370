import {
  BranchesOnlyExpression,
  ErrorResponse,
  ExpressionBranch,
  ExpressionElseBranch,
  IssueTemplateSlimContextEnum,
  IssueTemplateSlimInvalidReasonEnum as InvalidReasonEnum,
  IssueTrackersGetIssueTrackerSyncConfigContextEnum,
  IssueTrackerSyncConfig,
  IssueTrackerSyncConfigPayloadContextEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { branchesOnlyExpressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { INTEGRATION_CONFIGS } from "@incident-shared/integrations";
import { IssueTrackerProviderEnum } from "@incident-shared/issue-trackers";
import {
  BadgeTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  Link,
  LoadingBar,
  StackedList,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { SettingsListItem } from "../../@shared/settings/SettingsList/SettingsListItem";
import { IssueTemplateExpressionEditor } from "../../issue-templates/IssueTemplateExpressionEditor";
import {
  IssueTrackerSyncFailureActivity,
  IssueTrackerSyncType,
} from "../integrations/list/jira/IssueTrackerSyncFailureActivity";
import { SettingsSection } from "../SettingsSection";
import { SettingsSubHeading } from "../SettingsSubHeading";
import { AddIssueTemplateButton } from "./AddIssueTemplateButton";
import { useEnabledExportIssueTrackers } from "./useEnabledExportIssueTrackers";

export const FollowUpExportingSection = (): React.ReactElement => {
  const {
    allTemplates,
    isLoading: templatesLoading,
    error: templatesError,
  } = useAllIssueTemplates();

  const followUpTemplates = allTemplates.filter(
    (template) => template.context === IssueTemplateContextEnum.FollowUp,
  );

  const invalidTemplatesCount = followUpTemplates.filter(
    (template) => template.invalid_reason !== InvalidReasonEnum.Empty,
  ).length;

  const {
    data: syncData,
    isLoading: syncDataLoading,
    error: syncDataError,
  } = useAPI("issueTrackersGetIssueTrackerSyncConfig", {
    context: IssueTrackersGetIssueTrackerSyncConfigContextEnum.FollowUp,
  });
  const syncConfig = syncData?.sync_config;

  if (syncDataError || templatesError) {
    return <GenericErrorMessage error={syncDataError || templatesError} />;
  }

  return (
    <>
      <div>
        <SettingsSubHeading
          title={"Export templates"}
          accessory={<AddIssueTemplateButton />}
          explanation={
            <span>
              Use templates to define what fields should be used when exporting
              follow-ups to your issue tracker. These will be available when
              exporting manually, or can be used to automatically export
              follow-ups based on conditions below.
            </span>
          }
        />
        {invalidTemplatesCount > 0 && (
          <Callout theme={CalloutTheme.Danger} className="mb-3">
            {invalidTemplatesCount} of the issue templates listed below have
            experienced an error exporting a followup. This is often due to
            required fields that are either missing or invalid. It is likely we
            will not be able to export follow-ups to your issue tracker until
            these issues are resolved.
            <IssueTrackerSyncFailureActivity
              syncType={IssueTrackerSyncType.FollowUp}
            />
          </Callout>
        )}
        {templatesLoading || syncDataLoading ? (
          <LoadingBar />
        ) : (
          <FollowUpsTemplatesList syncConfig={syncConfig} />
        )}
      </div>

      <div>
        {syncConfig ? (
          <AutoExportSettings
            syncConfig={syncConfig}
            issueTemplates={followUpTemplates}
          />
        ) : (
          <LoadingBar />
        )}
      </div>
    </>
  );
};

type FormData = Omit<IssueTrackerSyncConfig, "issue_template_expression"> & {
  issue_template_expression?: BranchesOnlyExpression | undefined;
};

const AutoExportSettings = ({
  syncConfig,
  issueTemplates,
}: {
  syncConfig: IssueTrackerSyncConfig;
  issueTemplates: UnifiedIssueTemplate[];
}): React.ReactElement => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      ...(syncConfig || { enabled: false }),
      include_private_incidents: syncConfig?.include_private_incidents || false,
      issue_template_expression: syncConfig?.issue_template_expression || {
        branches: [],
      },
    },
    mode: "onChange",
  });

  const branchesFieldMethods = useFieldArray({
    control: formMethods.control,
    name: "issue_template_expression.branches",
  });

  const { setError, watch, reset } = formMethods;

  const [includePrivateIncidents] = [watch("include_private_incidents")];

  const showToast = useToast();

  const validIssueTemplateExists = issueTemplates.some(
    (tmpl) =>
      tmpl.invalid_reason === InvalidReasonEnum.Empty &&
      tmpl.context === IssueTemplateContextEnum.FollowUp,
  );

  const mutation = useAPIMutation(
    "issueTrackersGetIssueTrackerSyncConfig",
    {
      context: IssueTrackersGetIssueTrackerSyncConfigContextEnum.FollowUp,
    },
    async (apiClient, data: FormData) => {
      return await apiClient.issueTrackersUpdateIssueTrackerSyncConfig({
        updateIssueTrackerSyncConfigRequestBody: {
          sync_config: {
            ...data,
            context: IssueTrackerSyncConfigPayloadContextEnum.FollowUp,
            issue_template_expression: data.issue_template_expression?.branches
              ?.length
              ? branchesOnlyExpressionToPayload(
                  data.issue_template_expression,
                  false,
                )
              : undefined,
          },
        },
      });
    },
    {
      onSuccess: () => {
        showToast({
          title: "Auto-export configuration updated.",
          theme: ToastTheme.Success,
        });
        reset({}, { keepValues: true });
      },
      setError,
    },
  );

  const { hasScope } = useIdentity();

  const { allTrackers } = useEnabledExportIssueTrackers();

  if (!syncConfig) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <SettingsSubHeading title={"Auto-export"} />

      <SettingsSection
        formMethods={formMethods}
        mutation={mutation}
        enabledPath="enabled"
        title="Enable auto-export"
        titleHeadingLevel={3}
        // If private incidents are exported, only owners can modify the auto-export settings
        requiredScope={
          includePrivateIncidents
            ? ScopeNameEnum.IncidentsGlobalAccess
            : ScopeNameEnum.OrganisationSettingsUpdate
        }
        explanation={
          <>
            When enabled, we&apos;ll automatically export follow-ups to your
            issue tracker when they are created.
          </>
        }
        // Disable the save button if we do not have a valid issue template.
        isDirty={
          validIssueTemplateExists ? formMethods.formState.isDirty : false
        }
      >
        {/* There are no valid export templates, so lets show a warning. */}
        {!validIssueTemplateExists && (
          <Callout theme={CalloutTheme.Info}>
            An installed issue tracker integration and at least one{" "}
            {joinSpansWithCommasAndConnectorWord(
              allTrackers.map((provider) => (
                <span key={provider} className="font-medium">
                  {INTEGRATION_CONFIGS[provider].label}
                </span>
              )),
              "or",
            )}{" "}
            export template is required to enable auto-export. Read more in this{" "}
            <Link
              analyticsTrackingId="open-tutorial-channel"
              href="https://help.incident.io/en/articles/8447561-auto-exporting-follow-ups"
              className="font-medium"
            >
              help article
            </Link>
            .
          </Callout>
        )}

        {/* There are valid export templates, lets allow the user to enable auto-export if they like. */}
        {validIssueTemplateExists && (
          <div className="space-y-4">
            <div className="space-y-4 mt-4">
              {includePrivateIncidents && (
                <Callout theme={CalloutTheme.Warning}>
                  Tickets created for private incidents will be visible to
                  anyone with access to your issue tracker. Only Owners will be
                  able to change these settings.
                </Callout>
              )}
              <CheckboxV2
                formMethods={formMethods}
                name="include_private_incidents"
                label={`Include follow-ups for private incidents`}
                className="w-full"
                disabled={!hasScope(ScopeNameEnum.IncidentsGlobalAccess)}
              />
              <IssueTemplateExpressionEditor
                syncConfig={syncConfig}
                issueTemplates={issueTemplates}
                branches={branchesFieldMethods.fields}
                onMoveBranch={clearErrorsThen(
                  formMethods,
                  branchesFieldMethods.move,
                )}
                onAddBranch={clearErrorsThen(
                  formMethods,
                  branchesFieldMethods.append,
                )}
                onEditBranch={clearErrorsThen(
                  formMethods,
                  branchesFieldMethods.update,
                )}
                onRemoveBranch={clearErrorsThen(
                  formMethods,
                  branchesFieldMethods.remove,
                )}
                resetBranches={clearErrorsThen(formMethods, () =>
                  branchesFieldMethods.replace([]),
                )}
                privateIncidentsEnabled={includePrivateIncidents}
                copyVerb="export"
              />
            </div>
          </div>
        )}
      </SettingsSection>
    </>
  );
};

export type IssueTemplateContextEnum = IssueTemplateSlimContextEnum;
export const IssueTemplateContextEnum = IssueTemplateSlimContextEnum;
export type UnifiedIssueTemplate = {
  id: string;
  name: string;
  provider: IssueTrackerProviderEnum;
  context: IssueTemplateContextEnum;
  invalid_reason: InvalidReasonEnum;
  // 1 is legacy, 2 is using the generic methods.
  version: 1 | 2;
};

const clearErrorsThen =
  (formMethods: UseFormReturn<FormData>, callback: (...args) => void) =>
  (...args) => {
    formMethods.clearErrors();
    return callback(...args);
  };

const FollowUpsTemplatesList = ({
  syncConfig,
}: {
  syncConfig?: IssueTrackerSyncConfig;
}): React.ReactElement => {
  const { installedTrackers } = useEnabledExportIssueTrackers();
  const { allTemplates, isLoading } = useAllIssueTemplates();

  const followUpTemplates = allTemplates.filter(
    (template) => template.context === IssueTemplateContextEnum.FollowUp,
  );

  if (isLoading) {
    return <LoadingBar className="h-16" />;
  }

  if (followUpTemplates.length === 0) {
    return (
      <EmptyState
        icon={IconEnum.FollowUps}
        content="You have not created any follow-up templates yet."
      />
    );
  }

  return (
    <StackedList>
      {_.orderBy(followUpTemplates, ["provider", "name"], ["asc", "asc"]).map(
        (template) => {
          const templateInUse = isIssueTemplateReferencedInExpression(
            syncConfig?.issue_template_expression?.branches ?? [],
            syncConfig?.issue_template_expression?.else_branch,
            template.id,
          );

          const hasInstalledProvider = installedTrackers.find(
            (provider) =>
              provider ===
              (template.provider as unknown as IssueTrackerProviderEnum),
          );

          return (
            <FollowUpsTemplateRow
              key={template.id}
              template={template}
              templateInUse={templateInUse}
              isLastTemplateForAutoExport={
                !!(followUpTemplates.length === 1 && syncConfig?.enabled)
              }
              disabled={!hasInstalledProvider}
            />
          );
        },
      )}
    </StackedList>
  );
};

export const useAllIssueTemplates = (): {
  allTemplates: UnifiedIssueTemplate[];
  isLoading: boolean;
  error?: ErrorResponse;
} => {
  const {
    data: issueTemplatesV1,
    isLoading: v1Loading,
    error: v1Error,
  } = useAPI(
    "issueTrackerIssueTemplatesList",
    {},
    {
      fallbackData: { issue_tracker_issue_templates: [] },
    },
  );

  const {
    data: issueTemplates,
    isLoading: v2Loading,
    error: v2Error,
  } = useAPI(
    "issueTrackersV2ListIssueTemplates",
    {},
    {
      fallbackData: { issue_templates: [] },
    },
  );

  const allTemplates: UnifiedIssueTemplate[] = [
    ...issueTemplatesV1.issue_tracker_issue_templates.map((template) => ({
      id: template.id,
      name: template.name,
      provider:
        template.issue_tracker_provider as unknown as IssueTrackerProviderEnum,
      context: template.context as unknown as IssueTemplateContextEnum,
      invalid_reason: template.invalid_reason as unknown as InvalidReasonEnum,
      version: 1 as const,
    })),
    ...issueTemplates.issue_templates.map((template) => ({
      id: template.id,
      name: template.name,
      provider: template.provider as unknown as IssueTrackerProviderEnum,
      context: template.context,
      invalid_reason: template.invalid_reason,
      version: 2 as const,
    })),
  ];

  return {
    allTemplates,
    isLoading: v1Loading || v2Loading,
    error: v1Error ?? v2Error,
  };
};

const FollowUpsTemplateRow = ({
  template,
  templateInUse,
  disabled,
  isLastTemplateForAutoExport,
}: {
  template: UnifiedIssueTemplate;
  templateInUse: boolean;
  disabled: boolean;
  isLastTemplateForAutoExport: boolean;
}): React.ReactElement => {
  const apiClient = useClient();

  const revalidate = useAPIRefetch(
    template.version === 2
      ? "issueTrackersV2ListIssueTemplates"
      : "issueTrackerIssueTemplatesList",
    {},
  );
  const onDelete = async () => {
    if (template.version === 1) {
      await apiClient.issueTrackerIssueTemplatesDestroy({ id: template.id });
    } else {
      await apiClient.issueTrackersV2DestroyIssueTemplate({ id: template.id });
    }
    await revalidate();
  };

  const { icon, label: providerLabel } = INTEGRATION_CONFIGS[template.provider];

  return (
    <>
      <SettingsListItem
        title={template.name}
        badgeProps={
          template.invalid_reason !== InvalidReasonEnum.Empty
            ? {
                theme: BadgeTheme.Error,
                icon: IconEnum.Warning,
              }
            : undefined
        }
        buttons={{
          requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
          edit: {
            editHref: `/settings/follow-ups/templates/${template.provider}/${template.id}`,
          },
          delete: {
            resourceTitle: template.name,
            isGatedText: isLastTemplateForAutoExport
              ? "You cannot delete your last template while auto-export is enabled"
              : templateInUse
              ? "Cannot delete a template used in a condition for auto-exporting"
              : undefined,
            deleteConfirmationTitle: "Delete follow-up export template",
            deleteConfirmationContent: (
              <>
                Are you sure you wish to delete the{" "}
                <span className="font-bold">{template.name}</span> template?
              </>
            ),
            onDelete,
          },
        }}
        icon={icon}
        disabled={disabled}
        disabledExplanation={
          <>
            To modify this template you need to set up an integration with{" "}
            {providerLabel}.
          </>
        }
      />
    </>
  );
};

const isIssueTemplateReferencedInExpression = (
  branches: ExpressionBranch[],
  elseBranch: ExpressionElseBranch | undefined,
  issueTemplateID: string,
) => {
  // check if branches
  const existsInIfBranch = branches.some(
    (branch) => branch.result.value?.literal === issueTemplateID,
  );
  if (existsInIfBranch) {
    return true;
  }

  // check else branch
  const existsInElseBranch =
    elseBranch?.result.value?.literal === issueTemplateID;
  if (existsInElseBranch) {
    return true;
  }

  return false;
};
