import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { STATUS_PAGE_INCIDENT_STATUS_NAME } from "@incident-shared/utils/StatusPages";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Link,
  LocalDateTime,
  OrgAwareLink,
  Txt,
} from "@incident-ui";
import { ActorAvatar } from "@incident-ui/Avatar/ActorAvatar";
import { LocalRelativeDateTime } from "@incident-ui/LocalDateTime/LocalRelativeDateTime";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAlertSource } from "src/components/alerts/useAlertSource";
import {
  Actor,
  AlertActor,
  APIKey,
  ExternalResource,
  IncidentStatusCategoryEnum,
  IncidentUpdate,
  IntegrationActorNameEnum,
  StatusPageIncidentSlim,
  StatusPageIncidentUpdate,
  StatusPageIncidentUpdateToStatusEnum,
  StatusPageSlim,
  TextDocumentPayload,
  User,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { severityNameOrFallback } from "../../../timeline/timeline-items/TimelineItemIncidentUpdateComponent";
import { useIncident } from "../hooks";
import { Update } from "./IncidentUpdatesTab";

export const IncidentUpdateComponent = ({
  incidentId,
  update,
  isFirst,
  isStream,
}: {
  incidentId: string | null;
  update: Update;
  isFirst: boolean;
  isStream?: boolean;
}) => {
  if (update.incident_update) {
    return (
      <InternalUpdate
        incidentId={incidentId}
        update={update.incident_update}
        isFirst={isFirst}
        isStream={isStream}
      />
    );
  } else {
    return (
      <StatusPageUpdate
        update={update.status_page_incident_update}
        incident={update.status_page_incident}
        page={update.status_page}
        isFirst={isFirst}
      />
    );
  }
};

const StatusPageUpdate = ({
  page,
  incident,
  update,
  isFirst,
}: {
  page: StatusPageSlim;
  incident: StatusPageIncidentSlim;
  update: StatusPageIncidentUpdate;
  isFirst: boolean;
}) => {
  const verb =
    update.sort_key === 1
      ? "published"
      : update.to_status === StatusPageIncidentUpdateToStatusEnum.Resolved
      ? "resolved"
      : "updated";

  return (
    <div className="flex text-sm">
      <div className="flex flex-col items-center mr-4 flex-none">
        <div className="rounded-full h-[24px] w-[24px] flex items-center justify-center">
          <Icon id={IconEnum.StatusPageHifi} size={IconSize.Large} />
        </div>
        {!isFirst && (
          <div className="border-l border-solid h-full border-stroke" />
        )}
      </div>
      <div className="grow pb-6">
        <div className="mb-3 mt-[1px]">
          <span className="font-medium">Status page incident {verb}</span> by{" "}
          <ActorSource actor={update.creator} useLowerCaseAutomation={true} />
          <LocalRelativeDateTime
            showIcon={false}
            date={update.published_at}
            className={"ml-2 text-content-tertiary hover:text-slate-600"}
          />
        </div>
        <div
          className={tcx("flex flex-col", "rounded-2 bg-surface-secondary p-4")}
        >
          <div
            className={tcx(
              "space-y-2.5",
              "border-l-[2px] pl-2.5",
              update.to_status === StatusPageIncidentUpdateToStatusEnum.Resolved
                ? "border-green-400/80"
                : "border-brand/80",
            )}
          >
            {incident.public_url ? (
              <Button
                analyticsTrackingId={"status-page-from-updates"}
                analyticsTrackingMetadata={{
                  status_page_incident_id: incident.id,
                }}
                openInNewTab
                href={incident.public_url}
                theme={ButtonTheme.Naked}
              >
                {page.name} - {incident.name}{" "}
                <Icon
                  id={IconEnum.ExternalLink}
                  size={IconSize.Small}
                  className="ml-1 text-content-tertiary transition group-hover:text-content-primary"
                />
              </Button>
            ) : (
              <Button
                analyticsTrackingId={"status-page-from-updates"}
                analyticsTrackingMetadata={{
                  status_page_incident_id: incident.id,
                }}
                href={`/status-pages/${page.id}/incident/${incident.id}`}
                className="font-medium block"
                theme={ButtonTheme.Naked}
              >
                {page.name} - {incident.name}
              </Button>
            )}

            <TemplatedTextDisplay
              value={update.message}
              style={TemplatedTextDisplayStyle.Compact}
            />
            <div className="text-content-tertiary mr-2">
              {STATUS_PAGE_INCIDENT_STATUS_NAME[update.to_status]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InternalUpdate = ({
  incidentId,
  update,
  isFirst,
  isStream = false,
}: {
  incidentId: string | null;
  update: IncidentUpdate;
  isFirst: boolean;
  isStream?: boolean;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const isEditable = update.message && !isStream;

  if (
    update.new_incident_status.category === IncidentStatusCategoryEnum.Merged
  ) {
    return (
      <UpdateUI
        actor={update.updater}
        date={update.created_at}
        isFirst={isFirst}
        lastEditedAt={update.last_edited_at}
        lastEditedBy={update.last_edited_by_user}
        headline={
          <>
            <span className="font-medium">
              <ActorSource actor={update.updater} />{" "}
            </span>
            merged this incident into{" "}
            <Link
              to={`/incidents/${update.merged_into_incident?.id}`}
              analyticsTrackingId={null}
              openInNewTab
              className="no-underline font-medium"
            >
              #{update.merged_into_incident?.reference}
              {": "}
              {update.merged_into_incident?.name}
            </Link>
          </>
        }
      />
    );
  }

  const severityUpdated =
    update.new_severity?.id !== update.previous_severity?.id;
  const statusUpdated =
    update.new_incident_status?.id !== update.previous_incident_status?.id;

  const didEnterPostIncident =
    update.previous_incident_status?.category ===
      IncidentStatusCategoryEnum.Active &&
    update.new_incident_status?.category ===
      IncidentStatusCategoryEnum.PostIncident;

  const didLeavePostIncident =
    update.previous_incident_status?.category ===
      IncidentStatusCategoryEnum.PostIncident &&
    update.new_incident_status?.category === IncidentStatusCategoryEnum.Closed;

  return (
    <UpdateUI
      actor={update.updater}
      date={update.created_at}
      isFirst={isFirst}
      lastEditedAt={update.last_edited_at}
      lastEditedBy={update.last_edited_by_user}
      wrapContent={isEditing === false}
      headline={
        update.previous_incident_status === undefined ? (
          getIncidentCreatorInfo(update.updater, isStream)
        ) : didEnterPostIncident || didLeavePostIncident ? (
          <PostIncidentFlowUpdate
            incidentId={incidentId}
            update={update}
            didEnterPostIncident={didEnterPostIncident}
          />
        ) : (
          <span className="font-medium">
            <ActorSource actor={update.updater} />
          </span>
        )
      }
      content={
        isEditing ? (
          <EditUpdate update={update} onClose={() => setIsEditing(false)} />
        ) : (
          <div className="flex gap-2 items-start justify-between">
            <div>
              {severityUpdated && (
                <div>
                  <span className="text-content-tertiary mr-2">Severity</span>
                  {update.previous_severity && (
                    <>
                      <span className="line-through">
                        {severityNameOrFallback(update.previous_severity)}
                      </span>
                      <span> &rarr; </span>
                    </>
                  )}
                  {severityNameOrFallback(update.new_severity)}
                </div>
              )}
              {statusUpdated && (
                <div>
                  <span className="text-content-tertiary mr-2">Status</span>
                  {update.previous_incident_status && (
                    <>
                      <span className="line-through">
                        {update.previous_incident_status?.name}
                      </span>
                      <span> &rarr; </span>
                    </>
                  )}
                  {update.new_incident_status.name}
                </div>
              )}
              {update.message && (
                <TemplatedTextDisplay
                  style={TemplatedTextDisplayStyle.Compact}
                  value={update.message?.text_node}
                />
              )}
            </div>
            {isEditable && (
              <Button
                title="Edit update"
                analyticsTrackingId="incident-update-edit"
                icon={IconEnum.Edit}
                iconProps={{ size: IconSize.Medium }}
                theme={ButtonTheme.Naked}
                onClick={() => setIsEditing(true)}
              />
            )}
          </div>
        )
      }
    />
  );
};

type EditUpdateFormData = {
  message: TextDocumentPayload;
};
const EditUpdate = ({
  update,
  onClose,
}: {
  update: IncidentUpdate;
  onClose: () => void;
}) => {
  const formMethods = useForm<EditUpdateFormData>({
    defaultValues: { message: update.message },
  });

  const { reset } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentUpdatesListForIncident",
    { incidentId: update.incident_id },
    async (apiClient, data: EditUpdateFormData) => {
      await apiClient.incidentUpdatesUpdate({
        id: update.id,
        updateRequestBody: {
          message: data.message,
        },
      });
    },
    { onSuccess: onClose, setError: formMethods.setError },
  );

  return (
    <FormV2
      formMethods={formMethods}
      genericError={genericError}
      saving={saving}
      onSubmit={onSubmit}
    >
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="message.text_node"
        required={false}
        format="slack_rich_text"
        includeExpressions={false}
        includeVariables={false}
      />
      <div className="flex justify-end gap-2">
        <Button
          analyticsTrackingId="save-update"
          type="submit"
          size={ButtonSize.Small}
          theme={ButtonTheme.Primary}
        >
          Save
        </Button>
        <Button
          analyticsTrackingId="cancel-editing-update"
          type="reset"
          size={ButtonSize.Small}
          onClick={() => {
            reset();
            onClose();
          }}
        >
          Cancel
        </Button>
      </div>
    </FormV2>
  );
};

const PostIncidentFlowUpdate = ({
  incidentId,
  update,
  didEnterPostIncident,
}: {
  incidentId: string | null;
  didEnterPostIncident: boolean;
  update: IncidentUpdate;
}) => {
  const { incident } = useIncident(incidentId);

  const optedOutOfPostIncidentFlow =
    incident?.did_opt_out_of_post_incident_flow ?? false;

  return (
    <>
      <span className="font-medium">
        <ActorSource actor={update.updater} />{" "}
      </span>
      {didEnterPostIncident
        ? "marked the incident as resolved"
        : optedOutOfPostIncidentFlow
        ? "opted out of the post-incident flow"
        : "completed the post-incident flow"}
    </>
  );
};

export const UpdateUI = ({
  actor,
  headline,
  content,
  date,
  wrapContent = true,
  isFirst = false,
  lastEditedBy,
  lastEditedAt,
}: {
  actor: Actor;
  headline: React.ReactNode;
  content?: React.ReactNode;
  date: Date;
  wrapContent?: boolean;
  isFirst?: boolean;
  lastEditedBy?: User;
  lastEditedAt?: Date;
}): React.ReactElement => {
  return (
    <div className="flex text-sm">
      <div className="flex flex-col items-center mr-4 flex-none">
        <ActorAvatar
          actor={actor}
          size={IconSize.Large}
          className="flex-none"
        />
        {isFirst ? null : (
          <div className="border-l border-solid h-full border-stroke" />
        )}
      </div>
      <div className="grow pb-6">
        <div className="flex justify-between gap-2 items-center">
          <div className="mb-3 mt-[1px] ">
            {headline}
            <LocalRelativeDateTime
              showIcon={false}
              date={date}
              className={"ml-2 text-content-tertiary hover:text-slate-600"}
            />
          </div>
          {lastEditedAt && (
            <div className="ml-2 text-content-tertiary text-xs">
              <LocalDateTime timestamp={lastEditedAt}>
                Edited by {lastEditedBy?.name}
              </LocalDateTime>
            </div>
          )}
        </div>
        {content &&
          (wrapContent ? (
            <div className="flex space-y-4 flex-col rounded-2 bg-surface-secondary p-4">
              {content}
            </div>
          ) : (
            <>{content}</>
          ))}
      </div>
    </div>
  );
};

const getIncidentCreatorInfo = (
  creator: Actor,
  isStream: boolean,
): React.ReactElement | undefined => {
  if (creator.user) {
    return <IncidentCreatedByUser user={creator.user} isStream={isStream} />;
  }
  if (creator.api_key) {
    return <IncidentCreatedViaAPI apiKey={creator.api_key} />;
  }
  if (creator.external_resource) {
    return (
      <IncidentCreatedByExternalResource resource={creator.external_resource} />
    );
  }
  if (creator.alert) {
    return <IncidentCreatedByAlert alert={creator.alert} />;
  }
  return <></>;
};

const IncidentCreatedByUser = ({
  user,
  isStream,
}: {
  user: User;
  isStream: boolean;
}) => (
  <>
    <span className="font-medium">{`${user.name} `}</span>
    {isStream ? "created a stream" : "declared an incident"}
  </>
);

const IncidentCreatedByExternalResource = ({
  resource,
}: {
  resource: ExternalResource;
}) => (
  <>
    Incident triggered by a{" "}
    <Link
      analyticsTrackingId={"external-resource-link"}
      href={resource.permalink}
      className="no-underline"
    >
      <span className="font-medium">{resource.resource_type_label}</span>
    </Link>
  </>
);

const IncidentCreatedByAlert = ({ alert }: { alert: AlertActor }) => {
  const { alertSource } = useAlertSource(alert?.source_type);
  return (
    <>
      <Txt bold>
        Incident triggered by{" "}
        {alert.source_type
          ? `the  ${alertSource?.name ?? alert.source_type} alert source`
          : "an alert"}
      </Txt>
      <OrgAwareLink
        analyticsTrackingId={"alert-from-incident-updates"}
        to={`/alerts/${alert.id}/details`}
        className="no-underline"
      >
        {alert.title}
      </OrgAwareLink>
    </>
  );
};

const IncidentCreatedViaAPI = ({ apiKey }: { apiKey: APIKey }) => (
  <>
    Incident created via{" "}
    <Link
      analyticsTrackingId={"api-key"}
      href={"/settings/api-keys"}
      className="no-underline"
      openInNewTab
    >
      API key <span className="font-medium">{apiKey.name}</span>
    </Link>
  </>
);

interface ActorSourceProps {
  actor: Actor;
  useLowerCaseAutomation?: boolean;
}

export const ActorSource = ({
  actor,
  useLowerCaseAutomation = false,
}: ActorSourceProps): React.ReactElement => {
  if (actor.user) {
    return <>{actor.user.name}</>;
  }
  if (actor.workflow) {
    return (
      <a href={`/workflows/${actor.workflow.id}`}>
        Workflow: {actor.workflow.name}
      </a>
    );
  }
  if (actor.api_key) {
    return (
      <a href={"/settings/api-keys"} target="_blank" rel="noopener noreferrer">
        API Key: {actor.api_key.name}
      </a>
    );
  }
  if (actor.integration) {
    if (actor.integration.name === IntegrationActorNameEnum.IncidentBackfill) {
      return <>someone</>;
    }
  }

  if (useLowerCaseAutomation) {
    return <>an automation</>;
  }

  return <>An automation</>;
};
