/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostIncidentTaskOption
 */
export interface PostIncidentTaskOption {
    /**
     * 
     * @type {TextNode}
     * @memberof PostIncidentTaskOption
     */
    description: TextNode;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof PostIncidentTaskOption
     */
    hours_until_due: EngineParamBinding;
    /**
     * ID of the incident role when the task involves a role
     * @type {string}
     * @memberof PostIncidentTaskOption
     */
    incident_role_id?: string;
    /**
     * Rank of this option, used for sorting the options
     * @type {number}
     * @memberof PostIncidentTaskOption
     */
    rank: number;
    /**
     * Type of post-incident task
     * @type {string}
     * @memberof PostIncidentTaskOption
     */
    task_type: PostIncidentTaskOptionTaskTypeEnum;
    /**
     * Title of the task
     * @type {string}
     * @memberof PostIncidentTaskOption
     */
    title: string;
}

/**
* @export
* @enum {string}
*/
export enum PostIncidentTaskOptionTaskTypeEnum {
    Custom = 'custom',
    ReviewFollowups = 'review_followups',
    ReviewTimeline = 'review_timeline',
    UpdateTimestamps = 'update_timestamps',
    CreatePostmortem = 'create_postmortem',
    InReviewPostmortem = 'in_review_postmortem',
    CompletePostmortem = 'complete_postmortem',
    SharePostmortem = 'share_postmortem',
    ScheduleDebrief = 'schedule_debrief',
    AssignRole = 'assign_role',
    UpdateIncidentSummary = 'update_incident_summary',
    SetCustomFields = 'set_custom_fields',
    SetTimestamps = 'set_timestamps',
    GiveShoutout = 'give_shoutout',
    ShareLearnings = 'share_learnings'
}

export function PostIncidentTaskOptionFromJSON(json: any): PostIncidentTaskOption {
    return PostIncidentTaskOptionFromJSONTyped(json, false);
}

export function PostIncidentTaskOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentTaskOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': TextNodeFromJSON(json['description']),
        'hours_until_due': EngineParamBindingFromJSON(json['hours_until_due']),
        'incident_role_id': !exists(json, 'incident_role_id') ? undefined : json['incident_role_id'],
        'rank': json['rank'],
        'task_type': json['task_type'],
        'title': json['title'],
    };
}

export function PostIncidentTaskOptionToJSON(value?: PostIncidentTaskOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': TextNodeToJSON(value.description),
        'hours_until_due': EngineParamBindingToJSON(value.hours_until_due),
        'incident_role_id': value.incident_role_id,
        'rank': value.rank,
        'task_type': value.task_type,
        'title': value.title,
    };
}

