import { Button, ButtonTheme, Modal, ModalContent } from "@incident-ui";
import { WorkflowTemplate } from "src/contexts/ClientContext";

import { WorkflowsTemplateGrid } from "./WorkflowsCreateGrid";

export type WorkflowCreateModalProps = {
  templates: WorkflowTemplate[];
  onClose: () => void;
};

export const WorkflowsCreateModal = ({
  templates,
  onClose,
}: WorkflowCreateModalProps) => {
  return (
    <Modal
      isExtraLarge={true}
      onClose={onClose}
      analyticsTrackingId="workflows-choose-trigger"
      isOpen={true}
      title="Create workflow"
      loading={false}
    >
      <ModalContent>
        <div className={"flex flex-col mx-10 mt-6 mb-10 text-sm"}>
          <div className={"flex flex-row items-baseline mb-6"}>
            <div>
              <Button
                theme={ButtonTheme.Secondary}
                analyticsTrackingId="workflows-v2-start-from-template"
                href={`/workflows/create`}
                disabled={false}
              >
                Start from scratch
              </Button>
            </div>
            <div className={"ml-2"}>
              <p>or pick from one of our templates:</p>
            </div>
          </div>
          <div>
            <WorkflowsTemplateGrid templates={templates} />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
