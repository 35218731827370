import {
  Alert,
  IncidentAlertStateEnum,
  IncidentModeEnum,
  IncidentStatusCategoryEnum,
} from "@incident-io/api";
import { GenericErrorMessage, Loader, Txt } from "@incident-ui";
import { captureException } from "@sentry/core";
import { IncidentsListContextProvider } from "src/components/@shared/incidents/IncidentsListContext";
import { useAPI } from "src/utils/swr";

import { SlimIncidentCard } from "../../legacy/on-call/escalations/SlimIncidentCard";

export const IncidentAlertsSection = ({ alert }: { alert: Alert }) => {
  const {
    data: { incident_alerts: incidentAlerts },
    isLoading: incidentAlertsIsLoading,
    error: incidentAlertsError,
  } = useAPI(
    "alertsListIncidentAlerts",
    {
      alertId: alert.id,
    },
    {
      fallbackData: {
        incident_alerts: [],
      },
    },
  );

  const {
    data: { incidents },
    isLoading: incidentsLoading,
    error: incidentsError,
  } = useAPI(
    incidentAlertsIsLoading ? null : "incidentsList",
    {
      id: {
        one_of: incidentAlerts.map((i) => i.incident_id),
      },
      statusCategory: {
        // The reason we have a statusCategory filter is that without it, we wouldn't show declined incidents.
        // Really, we want to show all incidents we've seen: so let's just map from the enum.
        one_of: Object.values(IncidentStatusCategoryEnum),
      },
      pageSize: incidentAlerts.length,
      // We include private incidents here so that people who have access to them can see them: if you do not have
      // access, we will _not_ show them.
      includePrivate: { is: true },
      // We need to query for incidents in every mode, since we allow to create test incidents.
      mode: {
        one_of: Object.values(IncidentModeEnum),
      },
    },
    { fallbackData: { incidents: [] } },
  );

  const isLoading = incidentAlertsIsLoading || incidentsLoading;

  const err = incidentAlertsError ?? incidentsError;
  if (err) {
    captureException(err);
    return <GenericErrorMessage error={err} />;
  }

  const isRelated =
    incidentAlerts.length === 0 ||
    incidentAlerts.some((ia) => ia.state === IncidentAlertStateEnum.Related);

  return (
    <IncidentsListContextProvider>
      <div>
        <div
          className={"flex-row flex items-center space-x-1 mb-2 shrink mr-auto"}
        >
          <Txt className={"font-medium"}>
            {isRelated ? "Related incidents" : "Pending incidents"}
          </Txt>
        </div>
        <>
          {/* Dummy row - When it's loading */}
          {isLoading && (
            <li
              className={
                "flex items-center border rounded-2 shadow-sm border-stroke p-2 h-20"
              }
            >
              <Loader />
            </li>
          )}

          {/* Dummy row - When there's no incidents */}
          {!isLoading && incidents.length === 0 && (
            <li
              className={
                "border rounded-2 shadow-sm border-stroke flex items-center justify-center p-2 h-20"
              }
            >
              <Txt lightGrey>There are no pending or related incidents</Txt>
            </li>
          )}

          <div className={"flex flex-col gap-2"}>
            {!isLoading &&
              incidents
                .sort((a, b) => (a.id < b.id ? -1 : 1))
                .map((incident) => {
                  return (
                    <SlimIncidentCard key={incident.id} incident={incident} />
                  );
                })}
          </div>
        </>
      </div>
    </IncidentsListContextProvider>
  );
};
