import { Icon, IconEnum, IconSize } from "@incident-ui";

export const TimelineItemChangeRow = ({
  icon,
  label,
  previousValue,
  nextValue,
  hasNotChanged = false,
}: {
  icon: IconEnum;
  label: string;
  previousValue?: React.ReactNode;
  nextValue?: React.ReactNode;
  hasNotChanged?: boolean;
}): React.ReactElement => {
  return (
    <>
      <div className="flex-center-y p-4">
        <Icon
          id={icon}
          size={IconSize.Large}
          className="text-slate-600 mr-2 shrink-0"
        />
        <dt className="mr-2">{label}:</dt>
        {hasNotChanged || previousValue === "Unset" ? (
          <dd className="font-semibold">{nextValue}</dd>
        ) : (
          <div className="flex-center-y">
            <del className="line-through text-slate-600" aria-hidden>
              {previousValue}
            </del>
            <Icon id={IconEnum.ArrowRight} className="mx-1 shrink-0" />
            <dd className="font-semibold">{nextValue}</dd>
          </div>
        )}
      </div>
    </>
  );
};
