import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations";
import { useAPI } from "src/utils/swr";

// Shared hook to provide alert source config on a 10s refresh interval, and to
// filter available alert sources to find which is relevant for our source
// config.
export const useAlertSourceConfigDeps = ({
  alertSourceConfigId,
}: {
  alertSourceConfigId: string | null;
}) => {
  const {
    data: sourceConfigData,
    isLoading: sourceConfigLoading,
    error: sourceConfigError,
  } = useAPI(
    alertSourceConfigId ? "alertsShowSourceConfig" : null,
    {
      id: alertSourceConfigId || "",
    },
    {
      refreshInterval: 10000,
      revalidateOnFocus: true,
    },
  );

  const {
    data: alertSourcesResponse,
    isLoading: alertSourcesLoading,
    error: alertSourcesError,
  } = useAPI("alertsListSources", undefined, {
    fallbackData: { alert_sources: [] },
  });
  const alertSourceConfig = sourceConfigData?.alert_source_config;

  const allSources = alertSourcesResponse.alert_sources;

  const alertSource = allSources.find(
    (s) => s.source_type === alertSourceConfig?.source_type,
  );

  const loading = sourceConfigLoading || alertSourcesLoading;
  const error = alertSourcesError || sourceConfigError;

  const sourceTypeConfig = alertSourceConfig?.source_type
    ? ALERT_SOURCE_TYPE_CONFIGS[alertSourceConfig?.source_type]
    : undefined;

  return {
    sourceTypeConfig,
    alertSourceConfig,
    alertSource,
    loading,
    error,
  };
};
