import {
  Incident,
  IncidentsChangeVisibilityRequest,
  IncidentsChangeVisibilityRequestBodyVisibilityEnum,
  IncidentVisibilityEnum,
} from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { Button, ButtonTheme, LoadingModal, ModalFooter } from "@incident-ui";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { useAPI, useAPIMutation } from "src/utils/swr";

export const ChangePrivacyModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement => {
  const [isMakingPrivate, setIsMakingPrivate] = useState(false);
  const { showArticle } = useIntercom();

  const formMethods = useForm<IncidentsChangeVisibilityRequest>({
    defaultValues: {
      id: incident.id,
      changeVisibilityRequestBody: {
        visibility: IncidentsChangeVisibilityRequestBodyVisibilityEnum.Private,
      },
    },
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, data) => {
      await apiClient.incidentsChangeVisibility({
        id: incident.id,
        changeVisibilityRequestBody: data.changeVisibilityRequestBody,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: () => {
        setIsMakingPrivate(true);
      },
    },
  );

  if (isMakingPrivate) {
    return (
      <CheckingForIncidentNowPrivate
        incidentId={incident.id}
        onClose={onClose}
      />
    );
  }

  return (
    <FormModalV2
      onClose={onClose}
      title="Make incident private"
      analyticsTrackingId="change-privacy"
      formMethods={formMethods}
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          confirmButtonText="Make incident private"
          confirmButtonTheme={ButtonTheme.Destroy}
          saving={saving}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <div>
        Making an incident private limits its visibility to only those with
        access, and changes the incident channel to be private.
      </div>
      <div>
        We will grant access to all users who are currently present in the
        incident channel. You can always change this later by granting or
        revoking memberships individually.
      </div>
      <div>
        You can read more about incident privacy in our{" "}
        <Button
          onClick={() => showArticle(5947963)}
          analyticsTrackingId="change-privacy-help-center-link"
          theme={ButtonTheme.Link}
        >
          help center
        </Button>
      </div>
    </FormModalV2>
  );
};

function CheckingForIncidentNowPrivate({
  incidentId,
  onClose,
}: {
  incidentId: string;
  onClose: () => void;
}): React.ReactElement | null {
  const { data } = useAPI(
    incidentId ? "incidentsShow" : null,
    {
      id: incidentId || "",
    },
    {
      refreshInterval: 1000,
      revalidateOnFocus: true,
    },
  );

  if (data?.incident.visibility === IncidentVisibilityEnum.Private) {
    onClose();
    return null;
  }

  return <LoadingModal onClose={onClose} title="Making incident private.." />;
}
