import { IssueTrackerProviderEnum } from "@incident-shared/issue-trackers";
import { CreateEditIssueTemplateModal } from "@incident-shared/issue-trackers/CreateEditIssueTemplateModal";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { useParams } from "react-router";

import { IssueTemplateContextEnum } from "./FollowUpExportSection";

export const IssueTemplateCreateEditModal = ({
  provider,
}: {
  provider: IssueTrackerProviderEnum;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate(`/settings/follow-ups`);

  const { id } = useParams<{ id?: string }>();

  return (
    <CreateEditIssueTemplateModal
      id={id}
      provider={provider}
      context={IssueTemplateContextEnum.FollowUp}
      onClose={onClose}
    />
  );
};
