import {
  AlertRoute,
  AlertSourceConfig,
  AlertSourceSourceTypeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { FormDivider } from "@incident-shared/forms/v2/FormDivider";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { GatedToggle } from "@incident-shared/gates/GatedToggle/GatedToggle";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  Heading,
  IconEnum,
  IconSize,
  Loader,
  Tooltip,
  Txt,
} from "@incident-ui";
import { LocalRelativeDateTime } from "@incident-ui/LocalDateTime/LocalRelativeDateTime";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  CatalogHeroBanner,
  useShowCatalogBanner,
} from "src/components/catalog/type-list/CatalogTypeSetupBanner";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import { useIdentity } from "src/contexts/IdentityContext";
import { useOptimisticAutoSave } from "src/hooks/useOptimisticAutoSave";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import {
  parseAlertRoute,
  parseFormData,
} from "../alert-route-create-edit/types";
import { AlertSourceTypeIcon } from "../common/AlertSourceTypeConfigs";
import { AlertSubPageHeading } from "../common/AlertSubPageHeading";
import AlertVisual from "../images/alerts-empty-state.png";

export const AlertRouteListPage = () => {
  const { hasScope } = useIdentity();
  const navigate = useOrgAwareNavigate();

  const canEdit = hasScope(ScopeNameEnum.AlertRouteUpdate);
  const bannerType = useShowCatalogBanner();

  const {
    data: alertRoutesData,
    isLoading: alertRoutesLoading,
    error,
  } = useAPI("alertRoutesListAlertRoutes", undefined);

  const {
    data: alertsData,
    isLoading: alertsLoading,
    error: alertError,
  } = useAPI("alertsListSourceConfigs", {});

  if (error || alertError) {
    return <GenericErrorMessage error={error || alertError} />;
  }

  if (alertRoutesLoading || alertsLoading) {
    return <Loader />;
  }

  const isEmpty = alertRoutesData?.alert_routes.length === 0;

  const alertSources = alertsData?.alert_source_configs || [];
  const alertRoutes = alertRoutesData?.alert_routes || [];
  const alertRoutesSorted = _.orderBy(
    alertRoutes || [],
    ["enabled", "name"],
    ["desc", "asc"],
  );

  return (
    <div
      className={tcx(
        "flex flex-col gap-6 max-w-[720px] mx-auto",
        !bannerType && "pt-6",
      )}
    >
      {bannerType && <CatalogHeroBanner bannerType={bannerType} />}
      <AlertRoutePageHeader showButton={!isEmpty} />
      {isEmpty ? (
        <AlertRouteEmptyState />
      ) : (
        <div className="flex flex-col rounded-2 border border-stroke divide-y divide-slate-100">
          {alertRoutesSorted.map((alertRoute) => {
            const usedSources = _.filter(alertSources, (alertSource) => {
              return (alertRoute.alert_sources || []).some(
                (source) => source.alert_source_id === alertSource.id,
              );
            });
            return (
              <AlertRouteRow
                alertRoute={alertRoute}
                usedAlertSources={usedSources}
                key={alertRoute.id}
                onClick={
                  canEdit
                    ? () => navigate(`/alerts/routes/${alertRoute.id}/edit`)
                    : undefined
                }
                rightHandChildren={
                  <AlertRouteRowActions alertRoute={alertRoute} />
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export const AlertRouteRow = ({
  alertRoute,
  usedAlertSources,
  onClick,
  rightHandChildren,
}: {
  alertRoute: AlertRoute;
  usedAlertSources: AlertSourceConfig[];
  onClick?: () => void;
  rightHandChildren: React.ReactElement;
}) => {
  const { hasScope } = useIdentity();

  const canEdit = hasScope(ScopeNameEnum.AlertRouteUpdate);

  return (
    <div
      className={tcx("flex-center-y p-5 space-x-3", {
        "cursor-pointer hover:bg-surface-secondary hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg ":
          canEdit,
      })}
      onClick={onClick}
    >
      <AlertSourceTypeIcons alertSources={usedAlertSources} />
      <div className="flex flex-col grow">
        <Txt bold>{alertRoute.name}</Txt>
        {alertRoute.last_fired_at ? (
          <Txt lightGrey className="text-xs">
            Last created an incident{" "}
            <LocalRelativeDateTime
              date={alertRoute.last_fired_at}
              className={"hover:!no-underline text-xs"}
            />
          </Txt>
        ) : (
          <Txt lightGrey className="text-xs">
            Never triggered
          </Txt>
        )}
      </div>
      {rightHandChildren}
    </div>
  );
};

const AlertRouteRowActions = ({ alertRoute }: { alertRoute: AlertRoute }) => {
  const showToast = useToast();

  const { trigger: onDelete } = useAPIMutation(
    "alertRoutesListAlertRoutes",
    undefined,
    async (apiClient, { id }) => {
      await apiClient.alertRoutesDestroyAlertRoute({
        id,
      });
      return;
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: `${alertRoute.name} deleted.`,
        });
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: `Could not delete ${alertRoute.name}`,
        });
      },
    },
  );

  return (
    <>
      <EnabledBadge enabled={alertRoute.enabled} />
      <AlertRouteOverflowMenu
        alertRoute={alertRoute}
        onDelete={() => onDelete({ id: alertRoute.id })}
      />
    </>
  );
};

const AlertSourceTypeIcons = ({
  alertSources,
}: {
  alertSources: AlertSourceConfig[];
}) => {
  const deduplicatedAlertSourceTypes = _.uniqBy(
    alertSources,
    "source_type",
  ).map((x) => x.source_type);
  deduplicatedAlertSourceTypes.sort();
  const groupedAlertSources = _.groupBy(alertSources, "source_type");
  const firstAlertSourceType = deduplicatedAlertSourceTypes[0];
  // If we've only got one source, we can just show a single large icon
  if (deduplicatedAlertSourceTypes.length === 1) {
    return (
      <Tooltip
        key={firstAlertSourceType}
        content={
          groupedAlertSources[firstAlertSourceType].length > 1
            ? groupedAlertSources[firstAlertSourceType]
                .map((x) => x.name)
                .join(", ")
            : undefined
        }
      >
        <div className="flex-center w-10 h-10 p-1 border bg-white border-stroke rounded-2">
          <AlertSourceTypeIcon
            size={IconSize.XL}
            sourceType={firstAlertSourceType as AlertSourceSourceTypeEnum}
          />
        </div>
      </Tooltip>
    );
  }
  // Otherwise, we show two smaller overlapping icons, with the second being either
  // the second source type, or a +N icon if there are more than two.
  const hasOverflow = deduplicatedAlertSourceTypes.length > 2;
  const secondAlertSourceType = deduplicatedAlertSourceTypes[1];
  return (
    <div className="relative w-10 h-10">
      <div className="absolute left-0 top-0">
        <Tooltip
          content={
            groupedAlertSources[firstAlertSourceType] &&
            groupedAlertSources[firstAlertSourceType].length > 1
              ? groupedAlertSources[firstAlertSourceType]
                  .map((x) => x.name)
                  .join(", ")
              : undefined
          }
        >
          <div className="flex-center w-6 h-6 p-1 border bg-white border-stroke rounded-2">
            <AlertSourceTypeIcon
              size={IconSize.XL}
              sourceType={firstAlertSourceType as AlertSourceSourceTypeEnum}
            />
          </div>
        </Tooltip>
      </div>
      <div className="absolute left-4 top-4">
        <Tooltip
          content={
            hasOverflow
              ? deduplicatedAlertSourceTypes
                  .slice(1)
                  .flatMap((type) =>
                    groupedAlertSources[type].map((source) => source.name),
                  )
                  .join(", ")
              : groupedAlertSources[secondAlertSourceType] &&
                groupedAlertSources[secondAlertSourceType].length > 1
              ? groupedAlertSources[secondAlertSourceType]
                  .map((x) => x.name)
                  .join(", ")
              : undefined
          }
        >
          <div
            className={tcx(
              "flex-center w-6 h-6 p-1 border bg-white border-stroke rounded-2",
              { "bg-slate-50": hasOverflow },
            )}
          >
            {hasOverflow ? (
              <Txt lightGrey xs>{`+${
                deduplicatedAlertSourceTypes.length - 1
              }`}</Txt>
            ) : (
              <AlertSourceTypeIcon
                size={IconSize.XL}
                sourceType={secondAlertSourceType as AlertSourceSourceTypeEnum}
              />
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const AlertRouteOverflowMenu = ({
  alertRoute,
  onDelete,
}: {
  alertRoute: AlertRoute;
  onDelete: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const navigate = useOrgAwareNavigate();

  const canEdit = hasScope(ScopeNameEnum.AlertRouteUpdate);
  const hasDeletionPermissions = hasScope(ScopeNameEnum.AlertRouteDestroy);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const tooltipContent = canEdit
    ? null
    : "You don't have permission to edit this alert route.";

  const { trigger: toggleActivatedState } = useAPIMutation(
    "alertRoutesListAlertRoutes",
    undefined,
    async (apiClient, data: { enabled: boolean }) => {
      await apiClient.alertRoutesUpdateAlertRoute({
        id: alertRoute.id,
        updateAlertRouteRequestBody: {
          ...parseFormData(parseAlertRoute(alertRoute, null), []),
          enabled: data.enabled,
        },
      });
    },
  );

  const { setState: setActivatedState, saving } = useOptimisticAutoSave<{
    enabled: boolean;
  }>({
    initialState: alertRoute,
    saveState: async (data: { enabled: boolean }) => {
      await toggleActivatedState(data);
    },
  });

  const onToggleDeactivated = () => {
    if (saving) {
      return;
    }
    setActivatedState({
      enabled: !alertRoute.enabled,
    });
  };

  return (
    <>
      <DeletionConfirmationModal
        title="Delete alert route"
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        fetchDependentResources={[
          {
            resource_type: "AlertRoute",
            id: alertRoute.id,
          },
        ]}
        onDelete={onDelete}
        analyticsTrackingId="alert-routes.delete"
        resourceTitle={alertRoute.name}
        deleteConfirmationContent={
          <>
            Are you sure you want to delete{" "}
            <span className="font-bold">{alertRoute.name}</span>?
          </>
        }
      />
      <DropdownMenu
        menuClassName={"w-[140px]"}
        side={"bottom"}
        triggerButton={
          <Button
            theme={ButtonTheme.Naked}
            type="button"
            className="-ml-2"
            analyticsTrackingId="alert-routes.options"
            icon={IconEnum.DotsVerticalNopad}
            title="Alert route options"
            iconProps={{ size: IconSize.Large, className: "-my-2" }}
            onClick={(e) => e.stopPropagation()}
          />
        }
      >
        <DropdownMenuItem
          disabled={!canEdit}
          tooltipContent={tooltipContent}
          onSelect={() => navigate(`/alerts/routes/${alertRoute.id}/edit`)}
          analyticsTrackingId={"alert-routes.edit"}
          label={"Edit"}
        >
          Edit
        </DropdownMenuItem>
        <DropdownMenuItem
          disabled={!hasDeletionPermissions}
          tooltipContent={
            hasDeletionPermissions
              ? undefined
              : "You don't have permission to delete this alert route"
          }
          onSelect={() => setShowDeleteModal(true)}
          analyticsTrackingId={"alert-routes.delete"}
          label="Delete"
          destructive
        >
          Delete
        </DropdownMenuItem>
        <FormDivider />
        <DropdownMenuItem
          disabled={!canEdit}
          tooltipContent={tooltipContent}
          onSelect={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onToggleDeactivated();
          }}
          analyticsTrackingId={"alert-routes.edit"}
          label={"Enabled"}
        >
          <div className="flex flex-row justify-between">
            <GatedToggle
              id="deactivate"
              disabled={!canEdit}
              tooltipContent={<>{tooltipContent}</>}
              align="right"
              label="Deactivate"
              on={!alertRoute.enabled}
              onToggle={onToggleDeactivated}
              isLoading={saving}
            />
          </div>
        </DropdownMenuItem>
      </DropdownMenu>
    </>
  );
};

const NewAlertRouteButton = () => {
  const navigate = useOrgAwareNavigate();

  return (
    <GatedButton
      theme={ButtonTheme.Secondary}
      onClick={() => navigate("/alerts/routes/create")}
      requiredScope={ScopeNameEnum.AlertRouteCreate}
      analyticsTrackingId="alert-routes.create"
      className={"ml-auto self-end"}
    >
      New alert route
    </GatedButton>
  );
};

const EnabledBadge = ({ enabled }: { enabled: boolean }) => {
  return (
    <>
      {enabled ? (
        <Badge theme={BadgeTheme.Success} className={"border border-green-300"}>
          <span className={"px-1"}>Active</span>
        </Badge>
      ) : (
        <Badge theme={BadgeTheme.Warning} className={"border border-amber-200"}>
          <span className={"px-1"}>Inactive</span>
        </Badge>
      )}
    </>
  );
};

const AlertRoutePageHeader = ({ showButton }: { showButton?: boolean }) => {
  return (
    <>
      <Helmet title={`Alert routes - incident.io`} />
      <div className="bg-white w-full flex justify-between mb-6 items-center">
        <AlertSubPageHeading
          title={"Alert routes"}
          subtitle={`Alert routes allow you to create incidents and escalations from your alert sources.`}
        />
        {showButton && <NewAlertRouteButton />}
      </div>
    </>
  );
};

const AlertRouteEmptyState = () => {
  return (
    <div className="flex-center h-full bg-slate-50 px-10 py-5 rounded-2">
      <div className="flex flex-col gap-6">
        <div className={"space-y-2"}>
          <Heading level={1}>
            <>No alert routes</>
          </Heading>
          <div className="text-content-secondary">
            Start automatically creating incidents and escalations in
            incident.io.
          </div>
        </div>
        <Button
          theme={ButtonTheme.Secondary}
          className={"w-fit max-h-[40px]"}
          analyticsTrackingId="alert-routes.create"
          href="/alerts/routes/create"
        >
          <span className={"px-1"}>New alert route</span>
        </Button>
      </div>
      <div>
        <img className="max-h-[220px]" src={AlertVisual} />
      </div>
    </div>
  );
};
