import { Link } from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList } from "./helpers";

export const AlertSourceAlertmanagerSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <div>
        <p>
          Open your <strong>alertmanager.yml</strong> and add a receiver as
          follows:
        </p>
        <div className={"my-4 flex flex-col gap-4"}>
          <CodeBlock
            title={"alertmanager.yml"}
            code={`kind: AlertmanagerConfig
spec:
  route:
    groupBy: ['job']
    repeatInterval: 1m
    receiver: 'webhook'
  receivers:
    - name: '${alertSourceConfig.name} (incident.io)'
      webhookConfigs:
        - url: '${publicApiUrl()}/v2/alert_events/alertmanager/${
          alertSourceConfig.id
        }'
          send_resolved: true
          http_config:
            authorization:
              credentials: '${alertSourceConfig.secret_token ?? ""}'`}
          />
        </div>
      </div>
      <p>
        To see more configuration options, see the{" "}
        <Link
          analyticsTrackingId={"alertmanager-docs"}
          openInNewTab
          href={"https://prometheus.io/docs/alerting/latest/configuration/"}
        >
          Alertmanager documentation
        </Link>
        .
      </p>
    </SetupInfoNumberedList>
  );
};
