/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentFilterFieldsListInsightsResponseBody,
    IncidentFilterFieldsListInsightsResponseBodyFromJSON,
    IncidentFilterFieldsListInsightsResponseBodyToJSON,
    IncidentFilterFieldsListResponseBody,
    IncidentFilterFieldsListResponseBodyFromJSON,
    IncidentFilterFieldsListResponseBodyToJSON,
} from '../models';

export interface IncidentFilterFieldsListRequest {
    context: IncidentFilterFieldsListContextEnum;
}

export interface IncidentFilterFieldsSetInsightsOverrideRequest {
    fields: string;
}

/**
 * 
 */
export class IncidentFilterFieldsApi extends runtime.BaseAPI {

    /**
     * List incident filter fields
     * List IncidentFilterFields
     */
    async incidentFilterFieldsListRaw(requestParameters: IncidentFilterFieldsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFilterFieldsListResponseBody>> {
        if (requestParameters.context === null || requestParameters.context === undefined) {
            throw new runtime.RequiredError('context','Required parameter requestParameters.context was null or undefined when calling incidentFilterFieldsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.context !== undefined) {
            queryParameters['context'] = requestParameters.context;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_filter_fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFilterFieldsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident filter fields
     * List IncidentFilterFields
     */
    async incidentFilterFieldsList(requestParameters: IncidentFilterFieldsListRequest, initOverrides?: RequestInit): Promise<IncidentFilterFieldsListResponseBody> {
        const response = await this.incidentFilterFieldsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List filter fields for Insights, optionally invoking the local override
     * ListInsights IncidentFilterFields
     */
    async incidentFilterFieldsListInsightsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFilterFieldsListInsightsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_filter_fields_insights`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFilterFieldsListInsightsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List filter fields for Insights, optionally invoking the local override
     * ListInsights IncidentFilterFields
     */
    async incidentFilterFieldsListInsights(initOverrides?: RequestInit): Promise<IncidentFilterFieldsListInsightsResponseBody> {
        const response = await this.incidentFilterFieldsListInsightsRaw(initOverrides);
        return await response.value();
    }

    /**
     * In development only, sets the local cache that overrides fields for testing
     * SetInsightsOverride IncidentFilterFields
     */
    async incidentFilterFieldsSetInsightsOverrideRaw(requestParameters: IncidentFilterFieldsSetInsightsOverrideRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fields === null || requestParameters.fields === undefined) {
            throw new runtime.RequiredError('fields','Required parameter requestParameters.fields was null or undefined when calling incidentFilterFieldsSetInsightsOverride.');
        }

        const queryParameters: any = {};

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_filter_fields_insights_override`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * In development only, sets the local cache that overrides fields for testing
     * SetInsightsOverride IncidentFilterFields
     */
    async incidentFilterFieldsSetInsightsOverride(requestParameters: IncidentFilterFieldsSetInsightsOverrideRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentFilterFieldsSetInsightsOverrideRaw(requestParameters, initOverrides);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum IncidentFilterFieldsListContextEnum {
    All = 'all',
    FollowUps = 'follow_ups',
    Incidents = 'incidents',
    FollowUpsAndIncidents = 'follow_ups_and_incidents',
    PostIncidentTasksAndIncidents = 'post_incident_tasks_and_incidents',
    PagerLoad = 'pager_load'
}
