import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { FollowUpsCreatePriorityOptionRequestBody } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export const FollowUpPriorityCreateModal = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const formMethods = useForm<FollowUpsCreatePriorityOptionRequestBody>({
    defaultValues: {
      is_default: false,
    },
  });
  const {
    setError,
    formState: { isDirty },
  } = formMethods;

  const onClose = () => navigate(`/settings/follow-ups`);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsListPriorityOptions",
    undefined,
    async (apiClient, data: FollowUpsCreatePriorityOptionRequestBody) => {
      await apiClient.followUpsCreatePriorityOption({
        createPriorityOptionRequestBody: data,
      });
    },
    {
      onSuccess: onClose,
      setError,
    },
  );

  return (
    <FormModalV2
      formMethods={formMethods}
      genericError={genericError}
      analyticsTrackingId="create-follow-up-priority"
      title="Create priority"
      disableQuickClose={false}
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          saving={saving}
          onClose={onClose}
          disabled={!isDirty}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        required="Please provide a name"
      />
      <TextareaV2
        formMethods={formMethods}
        name="description"
        label="Description"
        helptext="How would you explain this priority to someone unfamiliar with it?"
        placeholder="For medium importance follow-ups, with an SLA of X days"
        rows={2}
      />
      <CheckboxV2
        formMethods={formMethods}
        name="is_default"
        label={"Make this your default priority"}
      />
    </FormModalV2>
  );
};
