import { FormContextTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { Icon, IconSize } from "@incident-ui/Icon/Icon";
import { Searcher, sortKind } from "fast-fuzzy";

import { PopoverSelectOptions } from "./PopoverSelectOptions";
import { PopoverSelectWrapper } from "./PopoverSelectWrapper";
import {
  OptionWithOnSelectRender,
  PopoverSelectOption,
  PopoverSingleSelectProps,
  SelectOptionWithGroup,
  SingleRenderSelectedFn,
} from "./types";
import {
  isGroupedOptions,
  isSearchableWithDefault,
  useAsyncOptions,
  useManageSelectState,
} from "./utils";

export const PopoverSingleSelect = <
  TSync extends boolean,
  TObject extends boolean,
  TOption extends PopoverSelectOption = PopoverSelectOption,
>({
  object,
  align = "start",
  icon,
  options: suppliedOptions,
  onChange,
  renderSelected,
  loadOptions,
  isLoading: suppliedLoading,
  value,
  placeholder,
  isSearchable,
  isClearable,
  inlineDescription,
  keySelector,
  renderDescription,
  renderTriggerNode,
  noOptionsMessage,
  ...rest
}: PopoverSingleSelectProps<TSync, TObject, TOption>) => {
  const {
    openControl,
    search,
    setSearch,
    handleClose,
    showSecondaryForm,
    setShowSecondaryForm,
  } = useManageSelectState();

  const { options: asyncOptions, isLoading: loadingAync } = useAsyncOptions({
    search,
    loadOptions,
  });

  const isLoading = suppliedLoading ?? loadingAync;

  const options = suppliedOptions ?? asyncOptions ?? [];

  const selectedValue = extractSelectedValue({ value, object });

  const flattenedOptions = isGroupedOptions(options)
    ? (options.flatMap((group) =>
        group.options.map((opt) => ({ ...opt, group: group.label })),
      ) as SelectOptionWithGroup<TOption>[])
    : (options as SelectOptionWithGroup<TOption>[]);

  const selectedOption = flattenedOptions.find(
    (opt) => opt.value === selectedValue,
  );

  const searcher = new Searcher(flattenedOptions, {
    keySelector: keySelector ?? ((s) => s.label),
    threshold: 0.8,
    sortBy: sortKind.bestMatch,
  });

  let filteredOptions = flattenedOptions;

  if (search) {
    filteredOptions = searcher.search(search);
  }

  const onChooseOption = (clickedValue: string | undefined) => {
    const chosenOption = flattenedOptions.find(
      (opt) => opt.value === clickedValue,
    );

    if (chosenOption?.onSelectRender) {
      setShowSecondaryForm(chosenOption as OptionWithOnSelectRender);
      return;
    }

    if (object) {
      onChange(chosenOption);
    } else {
      onChange(clickedValue);
    }
    handleClose();
  };

  const isValueSelected = !!value;

  return (
    <>
      <PopoverSelectWrapper
        align={align}
        {...openControl}
        icon={icon}
        search={search}
        setSearch={setSearch}
        isClearable={isClearable}
        isSearchable={isSearchableWithDefault({
          isSearchable,
          flattenedOptions,
        })}
        triggerNode={
          renderTriggerNode
            ? renderTriggerNode({
                selectedOption,
                onClick: () => openControl.setIsOpen(true),
              })
            : undefined
        }
        isValueSelected={isValueSelected}
        selectedValue={
          <SelectedValue
            value={selectedValue}
            renderSelected={renderSelected}
            options={flattenedOptions}
            placeholder={placeholder}
            renderDescription={renderDescription}
          />
        }
        onClear={() => onChange(undefined)}
        isLoading={isLoading}
        {...rest}
      >
        {showSecondaryForm ? (
          showSecondaryForm.onSelectRender({
            onBack: () => setShowSecondaryForm(null),
            onClose: handleClose,
            onChange: (val) => {
              onChange(val as (string & TOption) | undefined);
              handleClose();
            },
            selectedOption: showSecondaryForm,
          })
        ) : (
          <PopoverSelectOptions
            options={filteredOptions}
            isMulti={false}
            onClickOption={onChooseOption}
            value={selectedValue}
            inlineDescription={inlineDescription}
            search={search}
            allowAdding={rest.allowAdding}
            onCreateOption={rest.onCreateOption}
            noOptionsMessage={noOptionsMessage}
            popoverItemClassName={rest.popoverItemClassName}
          />
        )}
      </PopoverSelectWrapper>
      {renderDescription === "below" && selectedOption?.description && (
        <FormContextTextV2>{selectedOption?.description}</FormContextTextV2>
      )}
    </>
  );
};

const SelectedValue = <TOption extends PopoverSelectOption>({
  value,
  renderSelected,
  options,
  placeholder,
  renderDescription,
}: {
  value?: string;
  placeholder?: string;
  options: SelectOptionWithGroup<TOption>[];
  renderSelected?: SingleRenderSelectedFn<TOption>;
  renderDescription?: "inside-container" | "below";
}) => {
  const valueOption = options.find((opt) => opt.value === value);

  if (!valueOption) {
    return (
      <div className="flex-center-y w-full gap-1">
        <p className="text-sm text-content-secondary">
          {placeholder ?? "Select"}
        </p>
      </div>
    );
  }

  if (renderSelected) {
    return <>{renderSelected(valueOption)}</>;
  }

  const showDescription =
    !!valueOption.description && renderDescription === "inside-container";

  return (
    <div className="text-sm text-content-primary flex items-center gap-1">
      {valueOption.icon && (
        <Icon
          id={valueOption.icon}
          size={IconSize.Small}
          {...valueOption.iconProps}
        />
      )}
      <div className="flex flex-col items-start gap-1">
        {valueOption.label}
        {showDescription && (
          <div className="text-content-tertiary text-xs">
            {valueOption.description}
          </div>
        )}
      </div>
    </div>
  );
};

const extractSelectedValue = <
  TSync extends boolean,
  TObject extends boolean,
  TOption extends PopoverSelectOption,
>({
  value,
  object,
}: Pick<
  PopoverSingleSelectProps<TSync, TObject, TOption>,
  "value" | "object"
>): string => {
  if (object) {
    return (value as PopoverSelectOption)?.value;
  }

  return value as string;
};
