import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";

export const AlertSourceRunscopeSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        In your test settings, click <strong>Integrations</strong> &gt;{" "}
        <strong>Add integration</strong>
      </p>
      <p>
        Connect a new <strong>Webhook</strong> integration
      </p>
      <SetupInfoSingleLineBlocks
        blocks={[
          {
            label: (
              <>
                Copy and paste the following URL in the <strong>URL</strong>{" "}
                field:
              </>
            ),
            title: "Webhook URL",
            code: `${publicApiUrl()}/v2/alert_events/runscope/${
              alertSourceConfig.id
            }`,
          },
          {
            label: (
              <>
                Add a new <strong>Authorization</strong> header with the
                following body:
              </>
            ),
            title: "Authorization Header",
            code: `Bearer ${alertSourceConfig.secret_token}`,
          },
        ]}
      />
      <p>Save your changes and enable the integration</p>
      <p>Run the test to ensure your alert comes through as expected</p>
    </SetupInfoNumberedList>
  );
};
