/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface APIKeyRoleAndAvailability
 */
export interface APIKeyRoleAndAvailability {
    /**
     * Whether the role is available to be requested by a user
     * @type {boolean}
     * @memberof APIKeyRoleAndAvailability
     */
    available: boolean;
    /**
     * Human readable description of the role
     * @type {string}
     * @memberof APIKeyRoleAndAvailability
     */
    description: string;
    /**
     * Unique name for the API key role
     * @type {string}
     * @memberof APIKeyRoleAndAvailability
     */
    name: APIKeyRoleAndAvailabilityNameEnum;
}

/**
* @export
* @enum {string}
*/
export enum APIKeyRoleAndAvailabilityNameEnum {
    Viewer = 'viewer',
    IncidentCreator = 'incident_creator',
    IncidentEditor = 'incident_editor',
    GlobalAccess = 'global_access',
    ManageSettings = 'manage_settings',
    CatalogViewer = 'catalog_viewer',
    CatalogEditor = 'catalog_editor',
    IncidentMembershipsEditor = 'incident_memberships_editor',
    SchedulesEditor = 'schedules_editor',
    SchedulesReader = 'schedules_reader',
    WorkflowsEditor = 'workflows_editor',
    PrivateWorkflowsEditor = 'private_workflows_editor',
    OnCallEditor = 'on_call_editor'
}

export function APIKeyRoleAndAvailabilityFromJSON(json: any): APIKeyRoleAndAvailability {
    return APIKeyRoleAndAvailabilityFromJSONTyped(json, false);
}

export function APIKeyRoleAndAvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIKeyRoleAndAvailability {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'available': json['available'],
        'description': json['description'],
        'name': json['name'],
    };
}

export function APIKeyRoleAndAvailabilityToJSON(value?: APIKeyRoleAndAvailability | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available': value.available,
        'description': value.description,
        'name': value.name,
    };
}

