import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  Loader,
  SlackButtonPreview,
  SlackMessagePreview,
} from "@incident-ui";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  EngineParamBinding,
  EngineParamBindingPayload,
  NudgeNudgeTypeEnum,
  NudgePreviewButton,
  NudgesRenderPreviewRequestBodyNudgeTypeEnum,
  NudgesRenderPreviewResponseBody,
  TextNode,
  useClient,
} from "src/contexts/ClientContext";

type Props = {
  messageContent: EngineParamBinding;
  nudgeType: NudgeNudgeTypeEnum;
  paramBindings: EngineParamBindingPayload[];
};

export const NudgePreview = ({
  messageContent,
  nudgeType,
  paramBindings,
}: Props) => {
  const [preview, setPreview] = useState<
    NudgesRenderPreviewResponseBody | undefined
  >(undefined);

  const apiClient = useClient();

  const stringifiedMessageContent =
    messageContent && messageContent?.value?.literal;

  // We want to make sure we generate a preview as the user edits the form,
  // but we don't want to be too aggressive, hence the debounce.
  const makePreview = useDebounce(async () => {
    const render = await apiClient.nudgesRenderPreview({
      renderPreviewRequestBody: {
        nudge_type:
          nudgeType as unknown as NudgesRenderPreviewRequestBodyNudgeTypeEnum,
        message_content: messageContent,
        param_bindings: paramBindings,
      },
    });
    setPreview(render);
  }, 500);

  // Whenever one of the below values changes, we want to make a new preview.
  useEffect(() => {
    makePreview();
  }, [stringifiedMessageContent, nudgeType, paramBindings, makePreview]);

  // We set a min-height to prevent the height of the component
  // thrashing around before and after the preview has been loaded.

  // We don't show a loading spinner when the API request is in flight because
  // it's visually disruptive — instead, we just show it on the initial state,
  // where no preview has yet been loaded.
  if (!preview) {
    return (
      <SlackMessagePreview className="min-h-[116px]">
        <div className="space-y-2">
          <Loader className="w-auto my-0" />
        </div>
      </SlackMessagePreview>
    );
  }

  return (
    <NudgePreviewInner
      messageContent={preview.message_content}
      buttons={preview.buttons}
    />
  );
};

// NOTE: Our current useDebounce from use-hooks does not support a function
// being passed as the callback.
const useDebounce = (callback, time) => {
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, time);
  }, [time]);

  return debouncedCallback;
};

const snoozeOptions = [
  "Snooze for 15 minutes",
  "Snooze for 30 minutes",
  "Snooze for 1 hour",
  "Snooze for 3 hours",
  "Snooze for this incident",
];

export type NudgePreviewInnerProps = {
  messageContent: TextNode | undefined;
  buttons: NudgePreviewButton[] | undefined;
};
export const NudgePreviewInner = ({
  messageContent,
  buttons,
}: NudgePreviewInnerProps) => {
  return (
    <SlackMessagePreview className="min-h-[116px]">
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          {messageContent && (
            <TemplatedTextDisplay
              value={messageContent}
              style={TemplatedTextDisplayStyle.Compact}
              className="!border-0 !p-0"
            />
          )}
          <DropdownMenu
            align="start"
            triggerButton={
              <Button
                analyticsTrackingId={null}
                icon={IconEnum.DotsHorizontal}
                title="more"
                iconProps={{ className: "!h-8 !w-8" }}
                className="!py-0 !px-1 !border-sm"
                theme={ButtonTheme.Secondary}
              />
            }
          >
            {snoozeOptions.map((opt, i) => (
              <DropdownMenuItem
                key={i}
                onSelect={() => null}
                analyticsTrackingId={null}
                label={opt}
                className="!py-0.5 w-[250px] !cursor-default hover:!bg-white hover:!border-white"
              >
                <div className="font-['lato'] pl-2 pr-6 !cursor-default">
                  {opt}
                </div>
              </DropdownMenuItem>
            ))}
          </DropdownMenu>{" "}
        </div>
        {buttons && (
          <div className="flex gap-2 flex-wrap">
            {buttons.map(({ emoji, text }, idx) => {
              return <SlackButtonPreview key={idx} emoji={emoji} text={text} />;
            })}
          </div>
        )}
      </div>
    </SlackMessagePreview>
  );
};
