import * as d3 from "d3";
import { format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { escape } from "lodash";
import { RefObject, useEffect, useRef } from "react";
import { CalendarEntry } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { SelectedDateInfo } from "../HomeActivityIncidentsList";
import styles from "./Calendar.module.scss";
import { CalendarTooltip } from "./CalendarTooltip";

const formatDate = (date: Date) => format(date, "yyyy-MM-dd");

export enum CalendarView {
  Organisation = "organisation",
  User = "user",
}

type Weekday = "monday" | "weekday" | "sunday";
type CalendarProps = {
  data: Array<CalendarEntry>;
  view: CalendarView;
  width: number;
  cellSize: number;
  weekday: Weekday;
  formatDay: (i: number) => string;
  formatMonth: string;
  colors: (t: number) => string;
  onSelectItem: (dateInfo: SelectedDateInfo) => void;
  selectedItem: SelectedDateInfo | null;
};

export const CalendarDefaults: Omit<CalendarProps, "data"> = {
  width: 980, // width of the chart, in pixels
  view: CalendarView.Organisation, // whether to use numbers for the user or the broader organisation
  cellSize: 17, // width and height of an individual day, in pixels
  weekday: "sunday", // either: weekday, sunday, or monday
  formatDay: (i: number) => "SMTWTFS"[i], // given a day number in [0, 6], the day-of-week label
  formatMonth: "%b", // format specifier string for months (above the chart)
  // color scale for the heatmap, kinda hand wavey, i worked it out with this site: https://observablehq.com/@d3/working-with-color
  colors: d3.piecewise(d3.interpolateRgb.gamma(3), [
    "#EEEFF1",
    "#EEEFF1",
    "#F25533",
    "#c4130a",
  ]),
  onSelectItem: () => null,
  selectedItem: null,
};

const weekDays = (weekday: Weekday) => (weekday === "weekday" ? 5 : 7);
export const calendarHeight = ({
  cellSize,
  weekday,
}: Pick<CalendarProps, "cellSize"> & Pick<CalendarProps, "weekday">) =>
  cellSize * (weekDays(weekday) + 2);

type Entry = Omit<CalendarEntry, "activity_day"> & {
  activity_day: Date;
};

export const Calendar = ({
  data,
  width,
  view,
  cellSize,
  weekday,
  formatDay,
  formatMonth,
  colors,
  onSelectItem,
  selectedItem,
}: CalendarProps): React.ReactElement => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const graph = RenderCalendar({
      data,
      view,
      container,
      width,
      cellSize,
      weekday,
      formatDay,
      formatMonth,
      colors,
      onSelectItem,
      selectedItem,
    });

    return () => {
      graph?.remove();
    };
  }, [
    data,
    view,
    container,
    width,
    cellSize,
    weekday,
    formatDay,
    formatMonth,
    colors,
    onSelectItem,
    selectedItem,
  ]);

  return (
    <div
      id="contributors"
      className={"pt-2 pb-6 text-slate-600"}
      ref={container}
    ></div>
  );
};

const RenderCalendar = ({
  data: rawData,
  view,
  container,
  width,
  cellSize,
  weekday,
  formatDay,
  formatMonth,
  colors,
  onSelectItem,
  selectedItem,
}: CalendarProps & {
  container: RefObject<HTMLDivElement>;
}): SVGElement | null => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const endDate = new Date();
  const endDateStr = formatDate(endDate);
  const startDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1),
  );
  const startDateStr = formatDate(startDate);

  const getValue = (entry: Entry): number => {
    switch (view) {
      case CalendarView.Organisation:
        return entry.minutes_spent;
      case CalendarView.User:
        return entry.user_minutes_spent;
      default:
        assertUnreachable(view);
    }

    return -1; // unreachable
  };

  // We only want to show a single year of data.
  const filteredData = rawData.filter(
    (entry) =>
      startDateStr <= entry.activity_day && entry.activity_day <= endDateStr,
  );

  // create an array of date days
  const dateDays = d3.timeDays(startDate, endDate);

  const data = dateDays.map((dateDay) => {
    const entry = filteredData.find(
      (entry) => entry.activity_day === formatDate(dateDay),
    );
    if (entry) {
      return {
        ...entry,
        // Use the parsed version
        activity_day: dateDay,
      };
    }
    return {
      activity_day: dateDay,
      active_incident_external_ids: [],
      active_incident_ids: [],
      active_incident_names: [],
      minutes_spent: 0,
      user_active_incident_external_ids: [],
      user_active_incident_ids: [],
      user_active_incident_names: [],
      user_minutes_spent: 0,
    };
  });

  // Compute values.
  const X = d3.map(data, (entry) =>
    zonedTimeToUtc(new Date(entry.activity_day), timeZone),
  );
  const Y = d3.map(data, (entry): number => {
    return getValue(entry);
  });
  const I = d3.range(X.length);

  const countDay =
    weekday === "sunday" ? (i: number) => i : (i: number) => (i + 6) % 7;
  const timeWeek = weekday === "sunday" ? d3.timeSunday : d3.timeMonday;
  const weekDays = weekday === "weekday" ? 5 : 7;
  const height = calendarHeight({ cellSize, weekday });

  // Compute a color scale. This assumes a diverging color scheme where the pivot
  // is zero, and we want symmetric difference around zero.
  const max = d3.quantile(Y, 0.9975, Math.abs) || 1.0;
  const color = d3.scaleSequential([-max, +max], colors).unknown("none");

  // Construct formats.
  const timeFormatMonth = d3.timeFormat(formatMonth);

  // We don't use grouping by year for our contribution graph.
  const years = d3.groups(I, (_) => "").reverse();

  const pathMonth = (t) => {
    const d = Math.max(0, Math.min(weekDays, countDay(t.getDay())));
    const w = timeWeek.count(startDate, t);
    return `${
      d === 0
        ? `M${w * cellSize},0`
        : d === weekDays
        ? `M${(w + 1) * cellSize},0`
        : `M${(w + 1) * cellSize},0V${d * cellSize}H${w * cellSize}`
    }V${weekDays * cellSize}`;
  };

  const svg = d3
    .select(container.current)
    .append("svg")
    .attr("width", "100%")
    .attr("height", height)
    .attr("viewBox", [0, 0, width, height])
    .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
    .attr("font-family", "sans-serif")
    .attr("font-size", 10);

  const year = svg
    .selectAll("g")
    .data(years)
    .join("g")
    .attr(
      "transform",
      (d, i) => `translate(40.5,${height * i + cellSize * 1.5})`,
    );

  year
    .append("text")
    .attr("x", -5)
    .attr("y", -5)
    .attr("font-weight", "bold")
    .attr("text-anchor", "end")
    .text(([key]) => key);

  year
    .append("g")
    .attr("text-anchor", "end")
    .selectAll("text")
    .data(weekday === "weekday" ? d3.range(1, 6) : d3.range(7))
    .join("text")
    .attr("x", -7)
    .attr("y", (i) => (countDay(i) + 0.5) * cellSize)
    .attr("dy", "0.31em")
    .style("fill", "#919fae")
    .text(formatDay);

  const month = year
    .append("g")
    .selectAll("g")
    .data(([, I]) => d3.timeMonths(d3.timeMonth(startDate), X[I[I.length - 1]]))
    .join("g");

  month
    .filter((_, i) => !!i)
    .append("path")
    .attr("fill", "none")
    .attr("stroke", "#fff")
    .attr("stroke-width", 3)
    .attr("d", pathMonth);

  month
    .append("text")
    // Remove the first month if it would appear left of the start.
    .filter((d) => timeWeek.count(startDate, timeWeek.ceil(d)) > 2)
    .attr(
      "x",
      (d) => timeWeek.count(startDate, timeWeek.ceil(d)) * cellSize + 2,
    )
    .attr("y", -7)
    .style("fill", "#919fae")
    .text(timeFormatMonth);

  const div = d3
    .select(container.current)
    .append("div")
    .classed(
      tcx(
        styles.tooltip,
        "shadow",
        "px-3",
        "py-2",
        "text-sm",
        "text-slate-100 font-normal !bg-surface-invert !border !border-slate-700",
      ),
      true,
    );

  const tooltip = new CalendarTooltip(div);
  const getTooltip = (i: number) => {
    const datum = data[i];
    if (!datum) {
      throw new Error("could not index data at ${i}");
    }

    const {
      activity_day,
      active_incident_names,
      active_incident_external_ids,
      user_active_incident_names,
      user_active_incident_external_ids,
    } = datum;

    let incidentNames: string[] = [];
    let incidentExternalIDs: string[];
    switch (view) {
      case CalendarView.Organisation:
        incidentNames = active_incident_names;
        incidentExternalIDs = active_incident_external_ids;
        break;

      case CalendarView.User:
        incidentNames = user_active_incident_names;
        incidentExternalIDs = user_active_incident_external_ids;
        break;

      default:
        assertUnreachable(view);
    }

    return [
      `<strong>${activity_day.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })}</strong></br>`,
      ...incidentNames.map((name, index) => {
        return `<strong>INC-${incidentExternalIDs[index]}:</strong> ${escape(
          name,
        )}`;
      }),
    ].join("<br/>");
  };

  // Define each cell.
  year
    .append("g")
    .selectAll("rect")
    .data(
      weekday === "weekday"
        ? ([, I]) => I.filter((i) => ![0, 6].includes(X[i].getDay()))
        : ([, I]) => I,
    )
    .join("rect")
    .attr("width", cellSize - 3)
    .attr("height", cellSize - 3)
    .attr("rx", 1)
    .attr("ry", 1)
    .attr("x", (i) => timeWeek.count(startDate, X[i]) * cellSize + 0.5)
    .attr("y", (i) => countDay(X[i].getDay()) * cellSize + 0.5)
    .attr("fill", (i) => (Y[i] > 0 ? color(Y[i]) : "#EEEFF1"))
    .style("cursor", "pointer")
    .on("mouseover", function (event, i) {
      tooltip.display(i, (i: number) => getTooltip(i));
      tooltip.move(event);
      d3.select(this).style("stroke", "black");
    })
    .on("mouseout", function (_, i) {
      tooltip.hide();
      if (i !== selectedItem?.dataIndex) {
        d3.select(this).style("stroke", "none");
      }
    })
    .on("mousemove", function (event) {
      tooltip.move(event);
    })
    .on("click", function (_event, i) {
      const datum = data[i];
      let incidentIDs: string[] = [];
      switch (view) {
        case CalendarView.Organisation:
          incidentIDs = datum.active_incident_ids;
          break;
        case CalendarView.User:
          incidentIDs = datum.user_active_incident_ids;
          break;
        default:
          assertUnreachable(view);
      }
      onSelectItem({
        incidentIDs,
        date: datum.activity_day,
        dataIndex: i,
      });
      d3.select(this).style("stroke", "black");

      // When we click to select a date, the upper React component will destroy
      // the current d3 graph, which orphans the tooltip.
      //
      // We must remove it, in expectation that it will be recreated immediately
      // after.
      tooltip.remove();
    });

  const node = svg.node();
  if (node == null) {
    return null;
  }
  return Object.assign(node, { scales: { color } });
};
