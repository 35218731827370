/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IssueTrackerFieldValuePayload,
    IssueTrackerFieldValuePayloadFromJSON,
    IssueTrackerFieldValuePayloadFromJSONTyped,
    IssueTrackerFieldValuePayloadToJSON,
} from './IssueTrackerFieldValuePayload';

/**
 * 
 * @export
 * @interface IssueTrackersV2ListCreationFieldsRequestBody
 */
export interface IssueTrackersV2ListCreationFieldsRequestBody {
    /**
     * The values to use for each field
     * @type {Array<IssueTrackerFieldValuePayload>}
     * @memberof IssueTrackersV2ListCreationFieldsRequestBody
     */
    field_values: Array<IssueTrackerFieldValuePayload>;
    /**
     * Optional ID of a specific follow-up to be exported
     * @type {string}
     * @memberof IssueTrackersV2ListCreationFieldsRequestBody
     */
    follow_up_id?: string;
    /**
     * Optional ID of a template to use to pre-fill default values
     * @type {string}
     * @memberof IssueTrackersV2ListCreationFieldsRequestBody
     */
    issue_template_id?: string;
    /**
     * The issue tracker provider to list fields for
     * @type {string}
     * @memberof IssueTrackersV2ListCreationFieldsRequestBody
     */
    provider: IssueTrackersV2ListCreationFieldsRequestBodyProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum IssueTrackersV2ListCreationFieldsRequestBodyProviderEnum {
    Asana = 'asana',
    ClickUp = 'click_up',
    Linear = 'linear',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Github = 'github',
    Gitlab = 'gitlab',
    Shortcut = 'shortcut'
}

export function IssueTrackersV2ListCreationFieldsRequestBodyFromJSON(json: any): IssueTrackersV2ListCreationFieldsRequestBody {
    return IssueTrackersV2ListCreationFieldsRequestBodyFromJSONTyped(json, false);
}

export function IssueTrackersV2ListCreationFieldsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersV2ListCreationFieldsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field_values': ((json['field_values'] as Array<any>).map(IssueTrackerFieldValuePayloadFromJSON)),
        'follow_up_id': !exists(json, 'follow_up_id') ? undefined : json['follow_up_id'],
        'issue_template_id': !exists(json, 'issue_template_id') ? undefined : json['issue_template_id'],
        'provider': json['provider'],
    };
}

export function IssueTrackersV2ListCreationFieldsRequestBodyToJSON(value?: IssueTrackersV2ListCreationFieldsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field_values': ((value.field_values as Array<any>).map(IssueTrackerFieldValuePayloadToJSON)),
        'follow_up_id': value.follow_up_id,
        'issue_template_id': value.issue_template_id,
        'provider': value.provider,
    };
}

