import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import {
  ClickUpBulkExportableFields,
  ClickUpFormData,
} from "@incident-shared/issue-trackers/ExportToClickUpModal";
import { ModalFooter } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { FollowUp } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import {
  BulkExportBlurb,
  BulkExportCallouts,
  BulkExportTitleAndDescription,
  getBulkExportTitle,
  getBulkToastTitle,
  NoFollowUpsToExportModal,
} from "./helpers";

export const BulkExportToClickUpModal = ({
  followUpsToExport,
  numFollowUpsToExportFromPrivate,
  numFollowUpsAlreadyExported,
  onClose,
  onCancel,
  updateCallback,
}: {
  followUpsToExport: FollowUp[];
  numFollowUpsToExportFromPrivate: number;
  numFollowUpsAlreadyExported: number;
  onClose: () => void;
  onCancel: () => void;
  updateCallback: () => void;
}): ReactElement | null => {
  const formMethods = useForm<ClickUpFormData>({
    mode: "onSubmit",
  });

  const showToast = useToast();
  const providerName = "ClickUp";

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    {},
    async (apiClient, data: ClickUpFormData) => {
      await Promise.all(
        followUpsToExport.map((followUp) =>
          apiClient.issueTrackersClickUpCreateIssue({
            clickUpCreateIssueRequestBody: {
              follow_up_id: followUp.id,
              list_id: data.list_id,
              title: followUp.title,
              description: followUp.description,
              assignee_id:
                data.assignee_id === "" ? undefined : data.assignee_id,
            },
          }),
        ),
      );
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: getBulkToastTitle(followUpsToExport.length, providerName),
        });
        updateCallback();
        onClose();
      },
    },
  );

  const title = getBulkExportTitle(followUpsToExport.length);

  if (followUpsToExport.length === 0) {
    return (
      <NoFollowUpsToExportModal
        numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
        onClose={onCancel}
      />
    );
  } else {
    return (
      <FormModalV2
        formMethods={formMethods}
        onSubmit={onSubmit}
        title={title}
        analyticsTrackingId="bulk-add-to-clickup"
        onClose={onCancel}
        genericError={genericError}
        footer={
          <ModalFooter
            confirmButtonText={title}
            saving={saving}
            onClose={onCancel}
            confirmButtonType="submit"
          />
        }
      >
        <BulkExportCallouts
          numFollowUpsToExportFromPrivate={numFollowUpsToExportFromPrivate}
          numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
          accountName="ClickUp team"
        />
        <BulkExportBlurb
          providerIssues="ClickUp tickets"
          providerName={providerName}
        />
        <BulkExportTitleAndDescription providerName={providerName} />
        <ClickUpBulkExportableFields formMethods={formMethods} />
      </FormModalV2>
    );
  }
};
