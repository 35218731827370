import { StaffShowOrganisationResponseBody, User } from "@incident-io/api";
import {
  Avatar,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  LocalDateTime,
  Txt,
} from "@incident-ui";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

import { UsefulLinks } from "./UsefulLinks";

export const KeyInformation = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const { hasCopied, copyTextToClipboard } = useClipboard();

  return (
    <>
      <div className="flex justify-between bg-surface-secondary rounded-2 p-2">
        <LocalDateTime
          timestamp={data.organisation_created_at}
          className="mr-3"
        >
          Signed up {data.organisation_created_at.toLocaleString()}
        </LocalDateTime>

        <code
          className={tcx(
            "cursor-pointer text-xs transition rounded px-2 py-1.5",
            hasCopied ? "bg-green-surface" : "hover:bg-surface-tertiary",
          )}
          onClick={() => copyTextToClipboard(data.organisation_id)}
        >
          {data.organisation_id}
          <Icon
            id={IconEnum.Clipboard}
            size={IconSize.Small}
            className="inline ml-1.5 -mt-0.5"
          />
        </code>
      </div>

      <PlanInfo data={data} />

      <Section>
        <span className="font-semibold">Comms platform</span>
        <span>{data.primary_comms_platform}</span>
      </Section>

      <Section>
        <Txt bold inline>
          Seat usage:
        </Txt>
        <span>{data.viewer_count} viewers</span> &bull;
        <span>{data.responder_count} responders</span> &bull;
        <span>{data.on_call_responder_count} On-call responders</span>
      </Section>

      <EnterpriseInfo data={data} />

      {data.slack_owners && <SlackOwners data={data.slack_owners} />}

      <UsefulLinks data={data} />
    </>
  );
};

const SlackOwners = ({ data }: { data: Array<User> }) => {
  const [expanded, setExpanded] = useState(false);

  const moreThanOne = data.length > 1;

  if (expanded) {
    return (
      <Section>
        <div className="flex w-full justify-between">
          <div className="flex justify-between gap-4">
            <Txt className="pt-0.5" bold inline>
              Slack Owners:
            </Txt>
            <div className="flex flex-col gap-2">
              {data.map((user, i) => {
                return <SlackUser key={`user-${i}`} user={user} />;
              })}
            </div>
          </div>
          <div className="mx-2">
            <Button
              theme={ButtonTheme.Unstyled}
              onClick={() => setExpanded(!expanded)}
              analyticsTrackingId={"slack-owners-contract"}
            >
              <Txt className="text-slate-300 hover:underline hover:text-content-tertiary pt-0.5">
                hide
              </Txt>
            </Button>
          </div>
        </div>
      </Section>
    );
  } else {
    return (
      <Section>
        <div className="flex w-full justify-between">
          <div className="flex justify-between gap-4">
            <Txt className="pt-0.5" bold inline>
              Slack Owners:
            </Txt>
            <SlackUser user={data[0]} />
          </div>
          {moreThanOne && (
            <div className="mx-2">
              <Button
                theme={ButtonTheme.Unstyled}
                onClick={() => setExpanded(!expanded)}
                analyticsTrackingId={"slack-owners-expand"}
              >
                <Txt className="text-slate-300 hover:underline hover:text-content-tertiary pt-0.5">
                  and {data.length - 1} more...
                </Txt>
              </Button>
            </div>
          )}
        </div>
      </Section>
    );
  }
};

const SlackUser = ({ user }: { user: User }) => {
  return (
    <div className="flex gap-1 items-center">
      <Avatar size={IconSize.Large} name={user.name} url={user.avatar_url} />
      <Txt>{user.name}</Txt>
      <Txt>({user.email})</Txt>
    </div>
  );
};

const EnterpriseInfo = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  if (
    !data.arr_dollars &&
    !data.account_executive &&
    !data.customer_success_manager
  ) {
    return null;
  }

  return (
    <Section>
      {data.arr_dollars && (
        <div>
          <Txt bold className="mr-1" inline>
            ARR:
          </Txt>{" "}
          ${data.arr_dollars}
        </div>
      )}

      {data.account_executive && (
        <div className="flex gap-1 items-center">
          <Txt bold inline>
            Account exec:
          </Txt>{" "}
          <Avatar
            size={IconSize.Large}
            url={data.account_executive.avatar_url}
            name={data.account_executive.name}
          />{" "}
          {data.account_executive.name}
        </div>
      )}

      {data.customer_success_manager && (
        <div className="flex gap-1 items-center">
          <Txt bold inline>
            Customer success manager:
          </Txt>{" "}
          <Avatar
            size={IconSize.Large}
            url={data.customer_success_manager.avatar_url}
            name={data.customer_success_manager.name}
          />{" "}
          {data.customer_success_manager.name}
        </div>
      )}
    </Section>
  );
};

const PlanInfo = ({ data }: { data: StaffShowOrganisationResponseBody }) => {
  return (
    <Section>
      <div>
        <Txt bold className="mr-1" inline>
          Plan
        </Txt>{" "}
        {data.billing_setting.plan_name}
      </div>
      {data.trial_end_at && (
        <div>
          <Txt bold className="mr-1" inline>
            Trial ends
          </Txt>{" "}
          <LocalDateTime timestamp={data.trial_end_at}>
            {data.trial_end_at.toLocaleString()}
          </LocalDateTime>
        </div>
      )}
    </Section>
  );
};

const Section = ({ children }: { children: React.ReactNode }) => (
  <div className="text-sm p-2 bg-surface-secondary rounded-[6px] flex gap-2">
    {children}
  </div>
);
