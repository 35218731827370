import {
  BreadcrumbLink,
  Breadcrumbs,
} from "@incident-shared/layout/Breadcrumbs/Breadcrumbs";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  ContentBox,
  DropdownMenu,
  DropdownMenuItem,
  Heading,
  HoverCard,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  Tooltip,
} from "@incident-ui";
import { DropdownMenuGroup } from "@incident-ui/DropdownMenu/DropdownMenu";
import { useState } from "react";
import {
  SavedView,
  SavedViewsListContextEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { SavedViewsContextType, useSavedViews } from "./SavedViewContext";
import { SavedViewDeleteModal } from "./SavedViewDeleteModal";
import { SavedViewRenameModal } from "./SavedViewRenameModal";
import { SavedViewShareModal } from "./SavedViewShareModal";
import { useSavedViewStatePreview } from "./SavedViewStatePreviewContext";

export const SavedViewHeading = ({
  pageName,
  titleRenderer,
  compact = false,
  crumbs,
  currentLinkRenderer,
  hideIcon = false,
  headingSize = "medium",
  iconSize,
  isSampleData = false,
}: {
  pageName: string;
  compact?: boolean;
  titleRenderer?: React.ReactElement;
  currentLinkRenderer?: ({ view }: { view: string }) => React.ReactElement;
  crumbs?: BreadcrumbLink[];
  hideIcon?: boolean;
  headingSize?: "medium" | "2xl";
  iconSize?: IconSize;
  isSampleData?: boolean;
}): React.ReactElement => {
  const savedViewsContext = useSavedViews();
  const { savedViews, setSelectedSavedViewID, context, selectedSavedView } =
    savedViewsContext;
  if (!savedViews) {
    return <Loader />;
  }
  if (selectedSavedView) {
    return (
      <SavedViewSelectedHeading
        {...savedViewsContext}
        pageName={pageName}
        selectedSavedView={selectedSavedView}
        compact={compact}
        crumbs={crumbs}
        currentLinkRenderer={currentLinkRenderer}
      />
    );
  }

  const hasCrumbs = crumbs && crumbs.length > 0;

  return (
    <div className="flex flex-col relative">
      {hasCrumbs && (
        <div>
          <Heading
            level={3}
            size="medium"
            className="text-content-tertiary font-normal"
          >
            <Breadcrumbs links={crumbs} />
          </Heading>
        </div>
      )}
      <div className="flex gap-2 items-center">
        {titleRenderer ? (
          <div>{titleRenderer}</div>
        ) : (
          <SavedViewTitle
            context={context}
            pageName={pageName}
            headingSize={headingSize}
          />
        )}
        {!hideIcon && (
          <SavedViewIconDropdown
            savedViews={savedViews}
            setSelectedSavedViewID={setSelectedSavedViewID}
            iconSize={iconSize}
          />
        )}
        {isSampleData ? (
          <Badge theme={BadgeTheme.Warning} size={BadgeSize.ExtraSmall}>
            Sample data
            <Tooltip
              side={"bottom"}
              content={
                <>
                  We&apos;re showing anonymised sample data to demonstrate this
                  feature
                </>
              }
            >
              <div>
                <Icon
                  id={IconEnum.Info}
                  className={"cursor-pointer"}
                  size={IconSize.Medium}
                />
              </div>
            </Tooltip>
          </Badge>
        ) : undefined}
      </div>
    </div>
  );
};

const SavedViewTitle = ({
  context,
  pageName,
  headingSize,
}: {
  context: SavedViewsListContextEnum;
  pageName: string;
  headingSize: "medium" | "2xl";
}): React.ReactElement => {
  return (
    <Heading
      level={1}
      data-testid={context}
      size={headingSize}
      className="shrink-0"
    >
      <span>{pageName}</span>
    </Heading>
  );
};

const SavedViewIconDropdown = ({
  savedViews,
  setSelectedSavedViewID,
  iconSize = IconSize.Medium,
}: Pick<SavedViewsContextType, "savedViews" | "setSelectedSavedViewID"> & {
  iconSize?: IconSize;
}): React.ReactElement => {
  return (
    <DropdownMenu
      align="start"
      menuClassName="min-w-[200px] max-h-[400px] overflow-auto"
      triggerButton={
        <Button
          analyticsTrackingId={"expand-dropdown-saved-views"}
          theme={ButtonTheme.Naked}
          className={tcx(
            savedViews.presets?.length === 0 &&
              savedViews.userDefined?.length === 0
              ? "!text-slate-300"
              : "",
          )}
        >
          <Icon id={IconEnum.Layer} size={iconSize} />
          <Icon
            id={IconEnum.ChevronDown}
            size={IconSize.Medium}
            className="relative -left-1"
          />
        </Button>
      }
      tooltipContent={"Saved views"}
    >
      <SavedViewDropdownMenuItems
        savedViews={savedViews}
        setSelectedSavedViewID={setSelectedSavedViewID}
      />
    </DropdownMenu>
  );
};

const SavedViewSelectedHeading = ({
  pageName,
  savedViews,
  setSelectedSavedViewID,
  context,
  selectedSavedView,
  compact = false,
  crumbs,
  currentLinkRenderer,
}: Pick<
  SavedViewsContextType,
  "savedViews" | "setSelectedSavedViewID" | "context"
> & {
  pageName: string;
  selectedSavedView: SavedView;
  compact: boolean;
  crumbs?: BreadcrumbLink[];
  currentLinkRenderer?: ({ view }: { view: string }) => React.ReactNode;
}): React.ReactElement => {
  const { viewIsDirty, viewIsPreset, updateURLParamsOfSavedView } =
    useSavedViews();
  const viewIsEditable = viewIsDirty && !viewIsPreset;
  const [renamingSavedView, setRenamingSavedView] = useState<boolean>(false);
  const [deletingSavedView, setDeletingSavedView] = useState<boolean>(false);
  const [sharingSavedView, setSharingSavedView] = useState<boolean>(false);
  const navigate = useOrgAwareNavigate();

  const { trigger: onSave } = useAPIMutation(
    "savedViewsList",
    { context },
    updateURLParamsOfSavedView,
  );

  const originalLink: BreadcrumbLink = {
    title: pageName,
    onClick: () => setSelectedSavedViewID(null),
    renderer: currentLinkRenderer
      ? currentLinkRenderer({ view: selectedSavedView.id })
      : undefined,
  };

  let breadcrumbs = [originalLink];

  if (crumbs) {
    const mappedCrumbs: BreadcrumbLink[] = crumbs.map((crumb) => {
      if ("renderer" in crumb) return crumb;
      return {
        title: crumb.title,
        onClick:
          crumb.onClick ??
          (crumb.to ? () => navigate(crumb.to ?? "") : undefined),
      };
    });
    breadcrumbs = [...mappedCrumbs, originalLink];
  }

  return (
    <div className="relative flex-col flex">
      {!compact && (
        <Heading
          level={3}
          size="medium"
          className="text-content-tertiary font-normal"
        >
          <Breadcrumbs links={breadcrumbs} />
        </Heading>
      )}
      <DropdownMenu
        align="start"
        scroll
        tooltipContent={
          viewIsDirty && !viewIsPreset
            ? "This view has unsaved changes"
            : undefined
        }
        triggerButton={
          <Button
            analyticsTrackingId={"expand-dropdown-saved-views"}
            theme={ButtonTheme.Unstyled}
          >
            <Heading
              level={1}
              data-testid={context}
              size="medium"
              className="shrink-0 flex items-center -ml-[3px]"
            >
              <div className="flex space-x-1.5 items-center">
                <Icon
                  id={IconEnum.Layer}
                  size={IconSize.Medium}
                  className="text-content-primary"
                />
                <span>{selectedSavedView.name}</span>
              </div>
              {viewIsDirty && !viewIsPreset && (
                <Icon
                  id={IconEnum.WarningCircle}
                  size={IconSize.Small}
                  className="ml-1.5"
                />
              )}
              <Icon
                id={IconEnum.ChevronDown}
                size={IconSize.Medium}
                className="text-content-tertiary transition-colors group-hover:text-content-primary relative"
              />
            </Heading>
          </Button>
        }
      >
        <DropdownMenuGroup>
          {(viewIsPreset || viewIsDirty) && (
            <DropdownMenuItem
              analyticsTrackingId={
                "dropdown-discard-saved-view-unsaved-changes-clicked"
              }
              icon={IconEnum.Undo}
              label={viewIsPreset ? "Reset view" : "Discard unsaved changes"}
              onSelect={() => setSelectedSavedViewID(selectedSavedView.id)}
            />
          )}
          {viewIsEditable && (
            <DropdownMenuItem
              analyticsTrackingId={"dropdown-save-saved-view"}
              icon={IconEnum.Checkmark}
              label="Save changes"
              onSelect={() => onSave(selectedSavedView)}
            />
          )}
          <DropdownMenuItem
            analyticsTrackingId={"dropdown-exit-saved-view-clicked"}
            icon={IconEnum.Close}
            label="Exit view"
            onSelect={() => setSelectedSavedViewID(null)}
          />
          {!viewIsPreset && (
            <>
              <DropdownMenuItem
                analyticsTrackingId={"dropdown-rename-saved-view-clicked"}
                icon={IconEnum.Edit}
                label="Rename view"
                onSelect={() => setRenamingSavedView(true)}
              />
              <DropdownMenuItem
                analyticsTrackingId={"dropdown-delete-saved-view-clicked"}
                icon={IconEnum.Delete}
                label="Delete view"
                onSelect={() => setDeletingSavedView(true)}
              />
            </>
          )}
          <DropdownMenuItem
            analyticsTrackingId={"dropdown-share-saved-view-clicked"}
            icon={IconEnum.Link}
            label="Share view"
            onSelect={() => setSharingSavedView(true)}
          />
        </DropdownMenuGroup>
        <SavedViewDropdownMenuItems
          savedViews={savedViews}
          setSelectedSavedViewID={setSelectedSavedViewID}
        />
      </DropdownMenu>

      {renamingSavedView && (
        <SavedViewRenameModal
          savedView={selectedSavedView}
          onClose={() => setRenamingSavedView(false)}
        />
      )}
      {deletingSavedView && (
        <SavedViewDeleteModal
          savedView={selectedSavedView}
          onClose={() => setDeletingSavedView(false)}
        />
      )}
      {sharingSavedView && (
        <SavedViewShareModal
          savedView={selectedSavedView}
          onClose={() => setSharingSavedView(false)}
        />
      )}
    </div>
  );
};

const SavedViewDropdownMenuItems = ({
  savedViews,
  setSelectedSavedViewID,
}: Pick<
  SavedViewsContextType,
  "savedViews" | "setSelectedSavedViewID"
>): React.ReactElement => {
  if (
    savedViews.presets?.length === 0 &&
    savedViews.userDefined?.length === 0
  ) {
    return (
      <DropdownMenuItem
        label={"You don't have any saved views"}
        onSelect={() => null}
        analyticsTrackingId={null}
      />
    );
  }

  return (
    <>
      {savedViews.presets && savedViews.presets?.length > 0 && (
        <ViewMenuGroup
          views={savedViews.presets}
          label="Preset views"
          isPreset={true}
          setSelectedSavedViewID={setSelectedSavedViewID}
        />
      )}

      {savedViews.userDefined && savedViews.userDefined?.length > 0 && (
        <ViewMenuGroup
          views={savedViews.userDefined}
          label="Saved views"
          isPreset={false}
          setSelectedSavedViewID={setSelectedSavedViewID}
        />
      )}
    </>
  );
};

const ViewMenuGroup = ({
  views,
  label,
  isPreset,
  setSelectedSavedViewID,
}: {
  views: Array<{ id: string; name: string; url_params: string }>;
  label: string;
  isPreset: boolean;
  setSelectedSavedViewID;
}): React.ReactElement => (
  <DropdownMenuGroup
    label={label}
    icon={isPreset ? IconEnum.LockClosed : undefined}
  >
    {views.map((view) => (
      <ViewMenuGroupList
        key={view.id}
        id={view.id}
        name={view.name}
        url_params={view.url_params}
        setSelectedSavedViewID={setSelectedSavedViewID}
      />
    ))}
  </DropdownMenuGroup>
);

const ViewMenuGroupList = ({
  id,
  name,
  url_params,
  setSelectedSavedViewID,
}: {
  id: string;
  name: string;
  url_params: string;
  setSelectedSavedViewID;
}): React.ReactElement => {
  const onSelect = () => setSelectedSavedViewID(id);
  const { PreviewComponent } = useSavedViewStatePreview();
  const analyticsTrackingId = "dropdown-select-saved-view";
  const item = (
    <DropdownMenuItem
      key={id}
      label={name}
      onSelect={onSelect}
      analyticsTrackingId={analyticsTrackingId}
    >
      {name}
    </DropdownMenuItem>
  );

  return PreviewComponent ? (
    <HoverCard
      trigger={item}
      content={
        <ContentBox className="border rounded-2 p-4 max-w-lg text-sm">
          <PreviewComponent
            urlParams={new URLSearchParams()}
            viewParams={new URLSearchParams(url_params)}
          />
        </ContentBox>
      }
    />
  ) : (
    item
  );
};
