import { IssueTrackerProviderEnum } from "@incident-shared/issue-trackers";
import React from "react";
import { Route, Routes } from "react-router";

import { IssueTemplateCreateEditModal } from "./IssueTemplateCreateEditModal";
import { JiraIssueTemplateCreateEditModal } from "./JiraIssueTemplateCreateEditModal";
import { LinearIssueTemplateCreateEditModal } from "./LinearIssueTemplateCreateEditModal";
import { FollowUpPriorityCreateModal } from "./PrioritiesCreateModal";
import { FollowUpPriorityEditModal } from "./PrioritiesEditModal";
import { SettingsFollowUpsPage } from "./SettingsFollowUpsPage";

export const FollowUpRoute = (): React.ReactElement => {
  return (
    <>
      <Routes>
        <Route path="priorities">
          <Route path="create" element={<FollowUpPriorityCreateModal />} />
          <Route path=":id/edit" element={<FollowUpPriorityEditModal />} />
        </Route>
        <Route path="templates">
          <Route
            path={`${IssueTrackerProviderEnum.Jira}/create`}
            element={<JiraIssueTemplateCreateEditModal />}
          />
          <Route
            path={`${IssueTrackerProviderEnum.Jira}/:id`}
            element={<JiraIssueTemplateCreateEditModal />}
          />

          <Route
            path={`${IssueTrackerProviderEnum.Linear}/create`}
            element={<LinearIssueTemplateCreateEditModal />}
          />
          <Route
            path={`${IssueTrackerProviderEnum.Linear}/:id`}
            element={<LinearIssueTemplateCreateEditModal />}
          />

          {Object.values(IssueTrackerProviderEnum).map((provider) => (
            // These go _below_ Jira Cloud and Linear, since they have custom
            // modals that need migrating to issuetrackerv2 in the future
            <>
              <Route
                path={`${provider}/create`}
                element={<IssueTemplateCreateEditModal provider={provider} />}
              />
              <Route
                path={`${provider}/:id`}
                element={<IssueTemplateCreateEditModal provider={provider} />}
              />
            </>
          ))}
        </Route>
      </Routes>
      <SettingsFollowUpsPage />
    </>
  );
};
