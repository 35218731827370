/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsightsCreateDashboardRequestBody
 */
export interface InsightsCreateDashboardRequestBody {
    /**
     * Name of this dashboard
     * @type {string}
     * @memberof InsightsCreateDashboardRequestBody
     */
    name: string;
    /**
     * URL parameters that should be applied to this dashboard
     * @type {string}
     * @memberof InsightsCreateDashboardRequestBody
     */
    url_params: string;
    /**
     * Visibility type of this dashboard
     * @type {string}
     * @memberof InsightsCreateDashboardRequestBody
     */
    visibility: InsightsCreateDashboardRequestBodyVisibilityEnum;
}

/**
* @export
* @enum {string}
*/
export enum InsightsCreateDashboardRequestBodyVisibilityEnum {
    Personal = 'personal',
    Shared = 'shared'
}

export function InsightsCreateDashboardRequestBodyFromJSON(json: any): InsightsCreateDashboardRequestBody {
    return InsightsCreateDashboardRequestBodyFromJSONTyped(json, false);
}

export function InsightsCreateDashboardRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsCreateDashboardRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'url_params': json['url_params'],
        'visibility': json['visibility'],
    };
}

export function InsightsCreateDashboardRequestBodyToJSON(value?: InsightsCreateDashboardRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'url_params': value.url_params,
        'visibility': value.visibility,
    };
}

