import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import { SearchProvider } from "@incident-ui/SearchBar/SearchBar";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  ManagedResourceResourceTypeEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { WorkflowsCreateModal } from "../create/WorkflowsCreateModal";
import { WorkflowsFilterBar, WorkflowsList } from "./WorkflowsList";
import { WorkflowsListEmptyState } from "./WorkflowsListEmptyState";

export const WorkflowsListPage = () => {
  const formMethods = useForm();
  const { identity } = useIdentity();
  const filterByType = formMethods.watch("filter");
  const {
    data: filteredWorkflowsData,
    error: filteredWorkflowsError,
    isLoading: filteredWorkflowsLoading,
  } = useAPI("workflowsListWorkflows", {
    incidentType: filterByType,
  });
  const { data: allWorkflowsData, error: allWorkflowsError } = useAPI(
    "workflowsListWorkflows",
    {},
  );

  const {
    data: typesData,
    error: typesError,
    isLoading: typesLoading,
  } = useAPI("incidentTypesList", undefined);

  const {
    data: templatesData,
    error: templatesError,
    isLoading: templatesLoading,
  } = useAPI("workflowsListWorkflowTemplates", undefined);

  const {
    data: managedResourcesData,
    error: managedResourcesError,
    isLoading: managedResourcesLoading,
  } = useAPI(
    "managedResourcesList",
    {
      resourceType: ManagedResourceResourceTypeEnum.Workflow,
    },
    { fallbackData: { managed_resources: [] } },
  );

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const error =
    filteredWorkflowsError ||
    typesError ||
    allWorkflowsError ||
    templatesError ||
    managedResourcesError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }
  if (
    typesLoading ||
    !typesData ||
    templatesLoading ||
    !templatesData ||
    managedResourcesLoading
  ) {
    return <Loader />;
  }

  const orgHasNoWorkflows = allWorkflowsData?.workflows.length === 0;

  const maxWorkflows = identity?.feature_gates?.workflows_count;
  const orgCanCreateWorkflows =
    maxWorkflows === undefined ||
    (allWorkflowsData && allWorkflowsData.workflows.length < maxWorkflows);

  const templatesFiltered = templatesData.templates.filter(
    (template) => template.name !== "pagerduty_escalate",
  );

  return (
    <PageWrapper
      width={PageWidth.Medium}
      icon={IconEnum.Workflows}
      title="Workflows"
      accessory={
        <div className="flex-center-y space-x-2">
          {!orgHasNoWorkflows && (
            <GatedButton
              analyticsTrackingId="workflows-v2-new"
              onClick={() => setShowCreateModal(true)}
              theme={ButtonTheme.Primary}
              icon={IconEnum.Add}
              requiredScope={ScopeNameEnum.WorkflowsCreate}
              upgradeRequired={!orgCanCreateWorkflows}
              upgradeRequiredProps={{
                gate: {
                  type: "numeric",
                  value: maxWorkflows,
                  featureNameSingular: "workflow",
                },
                featureName: "workflows",
              }}
            >
              New workflow
            </GatedButton>
          )}
        </div>
      }
    >
      <div className="flex justify-center">
        <div className="w-full space-y-5 grow">
          {showCreateModal && (
            <WorkflowsCreateModal
              onClose={() => setShowCreateModal(false)}
              templates={templatesFiltered}
            />
          )}

          {filteredWorkflowsData?.workflows.length === 0 ? (
            <WorkflowsListEmptyState templates={templatesFiltered} />
          ) : (
            <SearchProvider>
              <FormProvider {...formMethods}>
                <form>
                  <WorkflowsFilterBar
                    types={typesData.incident_types}
                    expandAll={expandAll}
                    setExpandAll={setExpandAll}
                  />
                  {!filteredWorkflowsData || filteredWorkflowsLoading ? (
                    <Loader />
                  ) : (
                    <WorkflowsList
                      workflows={filteredWorkflowsData.workflows}
                      expandAll={expandAll}
                      orgCanCreateWorkflows={orgCanCreateWorkflows || false}
                      managedResources={managedResourcesData.managed_resources}
                    />
                  )}
                </form>
              </FormProvider>
            </SearchProvider>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};
