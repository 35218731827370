/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimelineItemComment,
    TimelineItemCommentFromJSON,
    TimelineItemCommentFromJSONTyped,
    TimelineItemCommentToJSON,
} from './TimelineItemComment';

/**
 * 
 * @export
 * @interface TimelineItemCommentsListResponseBody
 */
export interface TimelineItemCommentsListResponseBody {
    /**
     * 
     * @type {Array<TimelineItemComment>}
     * @memberof TimelineItemCommentsListResponseBody
     */
    timeline_item_comments: Array<TimelineItemComment>;
}

export function TimelineItemCommentsListResponseBodyFromJSON(json: any): TimelineItemCommentsListResponseBody {
    return TimelineItemCommentsListResponseBodyFromJSONTyped(json, false);
}

export function TimelineItemCommentsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineItemCommentsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeline_item_comments': ((json['timeline_item_comments'] as Array<any>).map(TimelineItemCommentFromJSON)),
    };
}

export function TimelineItemCommentsListResponseBodyToJSON(value?: TimelineItemCommentsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeline_item_comments': ((value.timeline_item_comments as Array<any>).map(TimelineItemCommentToJSON)),
    };
}

