import {
  conditionGroupsToConditions,
  conditionsToGroupPayload,
} from "@incident-shared/engine/conditions";
import { SelectedCondition } from "@incident-shared/engine/conditions/SelectedCondition";
import { ConditionsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionsEditorV2";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Modal, ModalFooter, StaticSingleSelect } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { SelectOption } from "@incident-ui/Select/types";
import { captureMessage } from "@sentry/react";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  Condition,
  EnabledSlackQuickAction,
  EnabledSlackQuickActionActionTypeEnum,
  IncidentChannelConfigsCreateQuickActionRequestBody,
  IncidentChannelConfigsCreateQuickActionRequestBodyActionTypeEnum,
  IncidentChannelConfigsUpdateQuickActionRequestBody,
  IncidentChannelConfigsUpdateQuickActionRequestBodyActionTypeEnum,
  IncidentsBuildScopeContextEnum,
  SlackQuickActionOption,
  SlackQuickActionOptionActionTypeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useProductAccess } from "src/hooks/useProductAccess";
import {
  GetUnicodeForSlackEmoji,
  SLACK_EMOJI_TO_UNICODE,
} from "src/utils/slack";
import { useAPI, useAPIMutation } from "src/utils/swr";

type FormData = Omit<
  | IncidentChannelConfigsCreateQuickActionRequestBody
  | IncidentChannelConfigsUpdateQuickActionRequestBody,
  "condition_groups"
> & {
  conditions: Condition[];
  radio_button_id: string;
};

const ChooseExistingID = "choose-existing";
const ChooseCustomID = "choose-custom";

export const SlackChannelQuickActionsCreateEditModal = ({
  quickAction,
  enabledQuickActions,
  onClose,
}: {
  quickAction?: EnabledSlackQuickAction;
  enabledQuickActions: EnabledSlackQuickAction[];
  onClose: () => void;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const conditions = conditionGroupsToConditions(quickAction?.condition_groups);

  const formMethods = useForm<FormData>({
    defaultValues: {
      // @ts-expect-error its not going to like this because the action type isn't an enum, but
      // everywhere else in this modal expects it to be passed as a json string so its safe
      action_type: JSON.stringify(quickAction) || undefined,
      radio_button_id:
        quickAction?.action_type ===
        EnabledSlackQuickActionActionTypeEnum.Custom
          ? ChooseCustomID
          : ChooseExistingID,
      custom_text:
        quickAction?.action_type ===
        EnabledSlackQuickActionActionTypeEnum.Custom
          ? quickAction.text
          : undefined,
      custom_emoji: quickAction ? ":" + quickAction.emoji + ":" : undefined,
      custom_url: quickAction?.custom_url,
      conditions: conditions,
      custom_field_id: quickAction?.custom_field_id || undefined,
      incident_role_id: quickAction?.incident_role_id || undefined,
    },
  });
  const { setError, watch } = formMethods;

  const isEditing = !!quickAction;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentChannelConfigsListEnabledQuickActions",
    undefined,
    async (apiClient, data: FormData) => {
      let actionType: string | undefined = undefined;
      let customFieldID: string | undefined = undefined;
      let incidentRoleID: string | undefined = undefined;
      let customEmoji: string | undefined = undefined;
      // This is intentionally || undefined to ensure we set `undefined` if the user emptied out their custom text (otherwise we'd get "")
      const customText: string | undefined = data.custom_text || undefined;
      let customURL: string | undefined = undefined;

      if (radioButtonID === ChooseExistingID) {
        // Quite gross: we have to get the action type and ID's out of the JSON string,
        // because we don't have a concept of option ID.
        const object = JSON.parse(data.action_type);
        actionType = object.action_type;
        customFieldID = object.custom_field_id;
        incidentRoleID = object.incident_role_id;
      } else {
        actionType =
          IncidentChannelConfigsUpdateQuickActionRequestBodyActionTypeEnum.Custom;
        // We store emojis in the backend without colons, so we need to remove them here.
        // @ts-expect-error we know this will be set for custom actions
        customEmoji = stripColons(data.custom_emoji);
        if (data.custom_emoji && !customEmoji) {
          setError("custom_emoji", {
            type: "manual",
            message: "Invalid emoji",
          });
          return;
        }
        customURL = data.custom_url;
      }
      if (isEditing) {
        await apiClient.incidentChannelConfigsUpdateQuickAction({
          id: quickAction.id,
          updateQuickActionRequestBody: {
            action_type:
              actionType as unknown as IncidentChannelConfigsUpdateQuickActionRequestBodyActionTypeEnum,
            custom_field_id: customFieldID,
            incident_role_id: incidentRoleID,
            custom_emoji: customEmoji,
            custom_text: customText,
            custom_url: customURL,
            condition_groups: conditionsToGroupPayload(data.conditions || []),
          },
        });
      } else {
        await apiClient.incidentChannelConfigsCreateQuickAction({
          createQuickActionRequestBody: {
            action_type:
              actionType as unknown as IncidentChannelConfigsCreateQuickActionRequestBodyActionTypeEnum,
            custom_field_id: customFieldID,
            incident_role_id: incidentRoleID,
            custom_emoji: customEmoji,
            custom_text: customText,
            custom_url: customURL,
            condition_groups: conditionsToGroupPayload(data.conditions || []),
          },
        });
      }
    },
    {
      setError,
      onSuccess: onClose,
    },
  );

  const { hasResponse } = useProductAccess();

  const {
    data: { quick_action_options: quickActionOptions },
    isLoading: quickActionOptionsLoading,
    error: quickActionOptionsError,
  } = useAPI("incidentChannelConfigsListQuickActionOptions", undefined, {
    fallbackData: { quick_action_options: [] },
    onSuccess: (data) => {
      if (!quickAction) {
        return;
      }

      const currentQuickActionType = data.quick_action_options.find(
        (o) =>
          o.action_type ===
          (quickAction.action_type as unknown as SlackQuickActionOptionActionTypeEnum),
      );

      if (currentQuickActionType) {
        // Because the API always returns `text`, we check if the text on the action is identical to
        // the default. If it is, we'll leave the custom text unspecified. If it's different, we
        // know that the quick action has a custom text specified, and we should set that.
        if (quickAction.text !== currentQuickActionType.text) {
          formMethods.setValue<"custom_text">("custom_text", quickAction.text);
        }
      }
    },
  });

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.QuickActionCustomConditions,
  );

  const selectedTypeEncoded = watch("action_type") as string;
  const selectedType = useMemo(
    () => (selectedTypeEncoded ? JSON.parse(selectedTypeEncoded) : null),
    [selectedTypeEncoded],
  );

  // Build up the options for the 'emoji' drop-down (only relevant when creating a custom quick
  // action).
  const emojiOptions: SelectOption[] = [];
  Object.entries(SLACK_EMOJI_TO_UNICODE).forEach(([key, value]) => {
    emojiOptions.push({
      label: value + "  " + key,
      sort_key: key,
      value: key,
    });
  });

  const title = isEditing ? "Edit quick action" : "Create quick action";

  const error = genericError || scopeError || quickActionOptionsError;
  if (error) {
    const err = new Error("loading error");
    err.cause = error;
    return <ErrorModal error={err} onClose={onClose} />;
  }

  if (quickActionOptionsLoading || scopeLoading) {
    return (
      <Modal
        title={title}
        isOpen={true}
        analyticsTrackingId="create-edit-quick-action-modal"
        loading={true}
        disableQuickClose
        onClose={onClose}
      />
    );
  }

  // Build up the select options for the drop-down in the Create modal.
  const availableOptions = quickActionOptions
    .filter(
      (option) =>
        // Only show the option if it's not already enabled
        enabledQuickActions.filter(
          (x) =>
            x.action_type ===
              (option.action_type as unknown as EnabledSlackQuickActionActionTypeEnum) &&
            x.custom_field_id === option.custom_field_id &&
            x.incident_role_id === option.incident_role_id,
        ).length === 0,
    )
    .map((option) => {
      return convertToSelectOption(option);
    });

  // If we're editing a quick action, we need to find the option that matches the one we're editing.
  // We'll show this in a disabled drop-down on the Edit modal.
  const selectedOption = quickActionOptions.find((option) => {
    if (quickAction) {
      return (
        (option.action_type as unknown as EnabledSlackQuickActionActionTypeEnum) ===
          quickAction.action_type &&
        option.custom_field_id === quickAction.custom_field_id &&
        option.incident_role_id === quickAction.incident_role_id
      );
    } else {
      return false;
    }
  });
  if (isEditing && !selectedOption) {
    captureMessage("Unable to find selected quick action in options", {
      extra: { quickAction, quickActionOptions },
    });
  }

  const radioButtonOptions = [
    {
      value: ChooseExistingID,
      label: "Pre-defined",
      description:
        "Choose a quick action from our list. This includes useful actions such as updating the summary or viewing more details.",
    },
    {
      value: ChooseCustomID,
      label: "Custom link",
      description:
        "Choose the URL you want the quick action to take you to. This can be useful if you have documentation that is commonly accessed during an incident.",
    },
  ];
  const radioButtonID = watch("radio_button_id");

  return (
    <FormModalV2
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      title={title}
      onClose={onClose}
      analyticsTrackingId="create-edit-quick-action-modal"
      disableQuickClose
      footer={
        <ModalFooter
          saving={saving}
          confirmButtonText={isEditing ? "Save" : "Create"}
          confirmButtonType="submit"
          onClose={() => onClose()}
        />
      }
      suppressInitialAnimation
    >
      {/* Radio buttons for type of quick action */}
      {!isEditing && hasResponse && (
        <RadioButtonGroupV2
          formMethods={formMethods}
          label="What sort of quick action would you like to add?"
          name="radio_button_id"
          srLabel="What sort of quick action would you like to add?"
          options={radioButtonOptions}
          boxed
        />
      )}
      {radioButtonID === ChooseCustomID ? (
        // Create custom quick action
        <>
          <InputV2
            formMethods={formMethods}
            label="Text"
            name="custom_text"
            helptext="This will be shown on the button in Slack."
            placeholder="Enter text..."
            rules={{
              required: "Please enter some text",
              maxLength: {
                value: 25,
                message: "Name must be 25 characters or less",
              },
            }}
          />
          <StaticSingleSelectV2
            formMethods={formMethods}
            label="Emoji"
            required
            name="custom_emoji"
            options={emojiOptions}
            helptext="This will be shown on the button in Slack."
            placeholder="Select emoji..."
          />
          <InputV2
            formMethods={formMethods}
            label="URL"
            name="custom_url"
            helptext="Clicking the quick action button will open this URL."
            placeholder="https://example.com"
            required="Please enter a URL"
          />
        </>
      ) : (
        // Choose existing quick action
        <>
          {isEditing && selectedOption ? (
            // If we're editing, we show a fake disabled dropdown. We can't just modify the dropdown
            // component that we use in the create modal because its tricky to set a correct default
            // value for it. This is because we pass the action_type field around as a JSON string
            // rather than an enum. Even when we convert the enabled quick action to a JSON string
            // when rendering default values on the form, it might not be the same JSON string as the one
            // we get when stringifying each quick action option when building SelectOption[].
            <FormInputWrapperV2 label="Type" name="action_type">
              <StaticSingleSelect
                // We pass in all the possible options here, because we could be editing any enabled quick
                // action.
                options={quickActionOptions.map((option) =>
                  convertToSelectOption(option),
                )}
                value={JSON.stringify(selectedOption)}
                disabled={true}
                placeholder="Select quick action type..."
                onChange={() => {
                  return;
                }}
              />
              <InputV2
                formMethods={formMethods}
                label="Customize button"
                className="mt-2"
                name="custom_text"
                helptext="This will be shown on the button in Slack. Leave empty to use the default."
                placeholder={selectedOption.text}
                rules={{
                  maxLength: {
                    value: 25,
                    message: "Name must be 25 characters or less",
                  },
                }}
              />
            </FormInputWrapperV2>
          ) : (
            <>
              <StaticSingleSelectV2
                formMethods={formMethods}
                label="Type"
                className="mt-2"
                required
                name="action_type"
                options={availableOptions}
                placeholder="Select quick action type..."
              />
              <InputV2
                formMethods={formMethods}
                label="Customize button"
                name="custom_text"
                helptext="This will be shown on the button in Slack. Leave empty to use the default."
                placeholder={selectedType?.text}
                rules={{
                  maxLength: {
                    value: 25,
                    message: "Name must be 25 characters or less",
                  },
                }}
              />
            </>
          )}
        </>
      )}
      {/* Conditions */}
      {identity?.feature_gates.incident_types ? (
        <ConditionsEditorV2
          formMethods={formMethods}
          label="Conditions"
          name="conditions"
          className="mt-1"
          showErrorAboveComponent
          wrapperClassName={"mt-2"}
          scope={scope}
          entityNameLabel={"quick action"}
          subjectsLabel={"incidents"}
          explanationStyle="available"
        />
      ) : quickAction && conditions.length > 0 ? (
        <div className="space-y-2">
          {conditions.map((condition) => (
            <SelectedCondition
              key={condition.subject.reference}
              condition={condition}
              theme="slate"
            />
          ))}
        </div>
      ) : undefined}
    </FormModalV2>
  );
};

const convertToSelectOption = (
  option: SlackQuickActionOption,
): SelectOption => {
  return {
    label: GetUnicodeForSlackEmoji(option.emoji) + "  " + option.text,
    sort_key: option.text,
    // We can only pass a single string for the value when selecting an option in the "type"
    // dropdown. However, an option cannot be identified by its action type alone; if it is a
    // "Set custom field" action type for example then we also need to pass the custom field ID.
    // Therefore, lets just pass the whole object as a string.
    value: JSON.stringify(option),
  };
};

const stripColons = (s: string): string | undefined => {
  if (s.startsWith(":") && s.endsWith(":") && s.length > 2) {
    return s.slice(1, -1);
  }
  return undefined;
};
