import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { useForm } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";

import {
  CatalogEntryBulkCreateEditor,
  CatalogEntryBulkCreateEditorData,
} from "../../entry-list/CatalogEntryBulkCreateDrawer";

export const ServiceWizardPasteServicesForm = () => {
  const formMethods = useForm<CatalogEntryBulkCreateEditorData>();
  const apiClient = useClient();

  const navigate = useOrgAwareNavigate();

  const items = formMethods.watch("items") || [];

  const [trigger, { saving }] = useMutation(
    async (data: CatalogEntryBulkCreateEditorData) => {
      return await apiClient.catalogBootstrapServiceType({
        bootstrapServiceTypeRequestBody: {
          manual_entries: data.items,
        },
      });
    },
    {
      onSuccess: (resp) => {
        navigate(
          `/catalog/service-wizard/${resp.catalog_type.id}/add-attributes`,
        );
      },
    },
  );

  return (
    <FormV2 formMethods={formMethods} onSubmit={trigger}>
      <div className="flex flex-col gap-3 grow">
        <CatalogEntryBulkCreateEditor
          formMethods={formMethods}
          name="items"
          label="To get started quickly, paste a list of service names below."
          helptext={`You'll be able to export this configuration later so you can manage it as code once you're happy with your setup.`}
          color={ColorPaletteEnum.Green}
          icon={IconEnum.Server}
          pluralNoun="services"
        />
        <div className="flex items-center justify-end gap-3">
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Secondary}
            onClick={() => {
              formMethods.reset({ items: [], rawItems: "" });
            }}
          >
            Clear
          </Button>
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Primary}
            type="submit"
            loading={saving}
            disabled={items.length < 1}
          >
            Create services
          </Button>
        </div>
      </div>
    </FormV2>
  );
};
