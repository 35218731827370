import { CatalogResource, CatalogResourceIconEnum } from "@incident-io/api";
import { Icon, IconEnum, PopoverSingleSelect, Tooltip } from "@incident-ui";
import {
  PopoverSelectOption,
  PopoverSelectOptionGroup,
  PopoverSingleSelectProps,
} from "@incident-ui/PopoverSelect";
import { captureException } from "@sentry/react";
import _ from "lodash";
import React, { useMemo } from "react";
import { FieldValues, Path, useController } from "react-hook-form";
import { FormInputWrapperV2 } from "src/components/@shared/forms/v2/FormInputWrapperV2";
import {
  InputElementProps,
  parseProps,
} from "src/components/@shared/forms/v2/formsv2";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "src/components/@shared/utils/ColorPalettes";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import {
  CatalogGroupType,
  getIntegrationConfig,
  integrationGroup,
} from "../../catalog/type-list/getIntegrationConfig";
import { CatalogEntryBadge } from "./CatalogEntryBadge";

export const CatalogTypeSelectorV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, CatalogTypeSelectorProps>,
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapperV2<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <CatalogTypeSelector {...field} {...inputProps} />
    </FormInputWrapperV2>
  );
};

type CatalogTypeSelectorProps = Omit<
  PopoverSingleSelectProps<true, false, PopoverSelectOption>,
  "isMulti" | "options" | "placeholder" | "isLoading" | "styles"
> & {
  className?: string;
  triggerClassName?: string;
  mode?: "catalog" | "engine";
  description?: "description" | "value_docstring";
  disabledTooltipContent?: React.ReactNode;
  disabledCatalogTypes?: string[];
};

// Common component for selecting a catalog type.
const CatalogTypeSelector = (
  props: CatalogTypeSelectorProps,
): React.ReactElement => {
  // Get catalog resources, used to type our attributes.
  const { data, isLoading, error } = useAPI("catalogListResources", undefined);

  if (error) {
    throw error;
  }

  const resources = useMemo(
    () =>
      (data?.resources || []).filter(
        (r) => !props.disabledCatalogTypes?.includes(r.type),
      ),
    [data, props.disabledCatalogTypes],
  );
  const optionGroups = buildResourceOptionGroups(
    resources,
    props.mode,
    props.description,
  );

  if (props.disabled) {
    const selectedResource = data?.resources.find(
      (res) =>
        props.value === (props.mode === "catalog" ? res.type : res.config.type),
    );
    if (selectedResource) {
      return (
        <LockedAttributeTypeIndicator
          resourceDisplayParameters={
            selectedResource as ResourceDisplayParameters
          }
          disabledTooltipContent={props.disabledTooltipContent}
        />
      );
    }
  }

  return (
    <PopoverSingleSelect
      {...props}
      options={optionGroups}
      placeholder="Select a type..."
      isLoading={isLoading}
      renderDescription="below"
      triggerClassName={tcx("w-full p-1", props.triggerClassName)}
      renderSelected={(option: CatalogResourceOption) => {
        return (
          <CatalogEntryBadge
            color={option.resource.color as unknown as ColorPaletteEnum}
            label={option.resource.label}
            icon={option.icon || (option.resource.icon as unknown as IconEnum)}
          />
        );
      }}
    />
  );
};

export type CatalogResourceOption = PopoverSelectOption & {
  resource: CatalogResource;
};

const buildResourceOptionGroups = (
  resources: CatalogResource[],
  mode?: "catalog" | "engine",
  description?: "description" | "value_docstring",
): PopoverSelectOptionGroup<CatalogResourceOption>[] => {
  if (!mode) {
    mode = "catalog";
  }

  return _.chain(resources)
    .map((r) => ({ ...r, group: integrationGroup(r) }))
    .groupBy((res) => res.group)
    .map(
      (
        resources,
        group,
      ): PopoverSelectOptionGroup<CatalogResourceOption> & {
        sort_key: string;
      } => {
        const sortedResources = _.sortBy(resources, "label");
        const groupLabel =
          getIntegrationConfig(group as CatalogGroupType).label ?? "";

        return {
          sort_key:
            group === "custom" ? "000" : group === "primitive" ? "001" : group,
          label: groupLabel,
          options: sortedResources.map((resource): CatalogResourceOption => {
            return {
              icon: resource.icon || getPrimitiveIcon(resource.type),
              color: resource.color as unknown as ColorPaletteEnum,
              label: resource.label,

              description:
                description === "description"
                  ? resource.description
                  : resource.value_docstring,
              // Depending on mode, allow selecting either the catalog resource
              // name or the underlying engine type.
              value: mode === "catalog" ? resource.type : resource.config.type,
              iconProps: {
                className: getColorPalette(resource.color).icon,
              },
              resource: resource,
            };
          }),
        };
      },
    )
    .sortBy("sort_key")
    .value();
};

export type ResourceDisplayParameters = {
  label: string;
  type: string;
  icon?: CatalogResourceIconEnum;
  color?: ColorPaletteEnum;
};

export const LockedAttributeTypeIndicator = ({
  disabledTooltipContent,
  resourceDisplayParameters: resource,
}: {
  disabledTooltipContent: React.ReactNode;
  resourceDisplayParameters: ResourceDisplayParameters;
}): React.ReactElement => {
  const icon = resource.icon || getPrimitiveIcon(resource.type);

  return (
    <div className="border border-stroke rounded-2 p-1.5 shadow flex items-center gap-2 justify-between">
      <CatalogEntryBadge
        color={resource.color}
        label={resource.label}
        icon={icon}
      />
      <Tooltip
        analyticsTrackingId={null}
        content={disabledTooltipContent}
        side="right"
        buttonClassName="ml-1"
      >
        <div className="cursor-pointer">
          <Icon id={IconEnum.LockClosed} className="text-slate-600" />
        </div>
      </Tooltip>
    </div>
  );
};

export const getPrimitiveIcon = (type: string): IconEnum => {
  switch (type) {
    case "Bool":
      return IconEnum.ToggleLeft;
    case "String":
      return IconEnum.TextInput;
    case "Text":
      return IconEnum.TextAlignLeft;
    case "Number":
      return IconEnum.Numeric;
    case "User":
      return IconEnum.User;
    default:
      captureException(
        new Error(
          `Trying to getPrimitiveIcon for unexpected primitive type ${type}. Returned a box.`,
        ),
      );
      return IconEnum.Box;
  }
};
