import { PostIncidentFlowCreateRequestBody } from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter, RadioButtonGroup, Txt } from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAPI, useAPIMutation } from "src/utils/swr";

type FormData = PostIncidentFlowCreateRequestBody;

export const PostIncidentFlowCreateModal = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/settings/post-incident-flow");
  const formMethods = useForm<FormData>({});

  const {
    data: { post_incident_flows: flows },
    isLoading: flowsLoading,
  } = useAPI("postIncidentFlowList", undefined, {
    fallbackData: { post_incident_flows: [] },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "postIncidentFlowList",
    undefined,
    async (apiClient, data) => {
      await apiClient.postIncidentFlowCreate({ createRequestBody: data });
    },
    {
      onSuccess: onClose,
      setError: formMethods.setError,
    },
  );

  const [shouldCopy, setShouldCopy] = useState("yes");

  return (
    <FormModalV2
      analyticsTrackingId="create-post-incident-flow"
      genericError={genericError}
      saving={isMutating}
      formMethods={formMethods}
      onSubmit={trigger}
      title="Create post-incident flow"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={isMutating}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Create"
        />
      }
    >
      <InputV2 formMethods={formMethods} name="name" label="Name" required />
      {flows.length > 0 && (
        <RadioButtonGroup
          name="should_copy"
          boxed
          srLabel="Should we start from an existing flow you've configured?"
          value={shouldCopy}
          onChange={(newVal) => setShouldCopy(newVal)}
          options={[
            {
              label: "Start from an existing flow",
              value: "yes",
              renderWhenSelectedNode: () => (
                <>
                  <StaticSingleSelectV2
                    formMethods={formMethods}
                    name="copy_from_post_incident_flow_id"
                    options={flows.map((flow) => ({
                      label: flow.name,
                      value: flow.id,
                    }))}
                    isLoading={flowsLoading}
                    required
                  />

                  <Txt className="mt-1" grey>
                    We&apos;ll copy the steps from one of the post-incident
                    flows you&apos;ve already set up, and you can iterate from
                    there.
                  </Txt>
                </>
              ),
            },
            {
              value: "no",
              label: "Start from the default post-incident flow",
            },
          ]}
        />
      )}
    </FormModalV2>
  );
};
