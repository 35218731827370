import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import {
  FormHelpTextV2,
  FormLabelV2,
} from "@incident-shared/forms/v2/FormInputWrapperV2";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Callout,
  CalloutTheme,
  EmptyState,
  GenericErrorMessage,
  Icon,
  IconEnum,
  Link,
  LoadingModal,
  ModalFooter,
  Txt,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { SelectOption } from "@incident-ui/Select/types";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import {
  PostmortemDestination,
  PostmortemDestinationDocumentProviderEnum,
  PostmortemsCreateDestinationRequestBody,
  PostmortemsCreateDestinationRequestBodyDocumentProviderEnum as DocumentProviderEnum,
  PostmortemsUpdateDestinationRequestBodyDocumentProviderEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI, useAPIMutation } from "src/utils/swr";

import notionDatabaseCopyLinkGraphic from "./notion-database-copy-link.png";
import notionDatabaseSharingGraphic from "./notion-database-sharing.png";

export const PostmortemDestinationCreateEditModal = ({
  mode,
}: {
  mode: Mode;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const params = useParams();
  const destinationID = params.id;
  const onClose = () => navigate(`/settings/post-mortem`);
  const {
    data: { destinations },
    isLoading: loadingDestinations,
    error: errorDestinations,
  } = useAPI("postmortemsListDestinations", undefined, {
    fallbackData: { destinations: [] },
  });
  if (loadingDestinations) {
    return <LoadingModal onClose={onClose} />;
  }

  if (errorDestinations || !destinations) {
    return (
      <GenericErrorMessage description="We couldn't load your post-mortem destinations." />
    );
  }

  if (mode === Mode.Create) {
    // Check if there is another default destination
    const defaultAlreadyExists = destinations.some((dest) => dest.is_default);

    return (
      <PostmortemDestinationCreateEditModalInner
        mode={Mode.Create}
        defaultDestinationExists={defaultAlreadyExists}
      />
    );
  }

  // Edit mode
  const destination = destinations.find((dest) => dest.id === destinationID);
  if (!destination) {
    throw new Error("unreachable: expected destination to exist");
  }

  // Check if there is another default destination
  const defaultAlreadyExists = destinations.some(
    (dest) => dest.id !== destination.id && dest.is_default,
  );

  return (
    <PostmortemDestinationCreateEditModalInner
      // Force a re-mount when the ID changes
      key={destinationID}
      mode={Mode.Edit}
      initialData={destination}
      defaultDestinationExists={defaultAlreadyExists}
    />
  );
};

type CreateDestinationFormData = PostmortemsCreateDestinationRequestBody & {
  provider_location_url?: string;
};

type Props = CreateEditFormProps<PostmortemDestination> & {
  defaultDestinationExists?: boolean;
};

const PostmortemDestinationCreateEditModalInner = ({
  mode,
  initialData,
  defaultDestinationExists = false,
}: Props): React.ReactElement => {
  const isEditing = mode === Mode.Edit;
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate(`/settings/post-mortem`);
  const { integrations, integrationsLoading } = useIntegrations();

  const isDefaultDestination =
    !defaultDestinationExists || initialData?.is_default;

  const availableIntegrations = integrations
    ?.filter(
      (i) =>
        i.installed &&
        Object.values(PostmortemDestinationDocumentProviderEnum).includes(
          i.provider as unknown as PostmortemDestinationDocumentProviderEnum,
        ),
    )
    .map((i): SelectOption => {
      const config = IntegrationConfigFor(i.provider);
      return {
        label: config.label,
        value: i.provider,
        icon: config.icon,
      };
    });

  const formMethods = useForm<CreateDestinationFormData>({
    defaultValues: {
      name: initialData?.name ?? undefined,
      document_provider: initialData
        ? (initialData.document_provider as unknown as DocumentProviderEnum)
        : availableIntegrations?.length === 1
        ? (availableIntegrations[0].value as unknown as DocumentProviderEnum)
        : undefined,
      provider_location: initialData?.provider_location ?? undefined,
      is_default: isEditing
        ? initialData?.is_default
        : !defaultDestinationExists,
    },
  });

  const {
    setError,
    getValues,
    watch,
    formState: { isDirty },
  } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postmortemsListDestinations",
    undefined,
    async (apiClient, data: CreateDestinationFormData) => {
      if (
        data.document_provider === DocumentProviderEnum.Confluence &&
        !data.provider_location &&
        data.provider_location_url
      ) {
        const chunks = data.provider_location_url.split("/");
        // janky but it works. we pull the space & page ID from the URL & bosh it together
        //https://incident-io-dev.atlassian.net/wiki/spaces/~6329e0de9b32cfef9323c5da/pages/18710529/INC-963+our+crons+are+not+running
        const pageID = chunks.at(-2);
        const spaceID = chunks.at(-4);
        data.provider_location = spaceID + "/" + pageID;
      }

      if (
        data.document_provider === DocumentProviderEnum.Notion &&
        !data.provider_location &&
        data.provider_location_url
      ) {
        // "https://www.notion.so/5200462857a345eeaf902fe79ee33461?v=74af62f0fd744e4cb4ae069c0391826a" -> "5200462857a345eeaf902fe79ee33461"
        const chunks = data.provider_location_url.split("/");
        const databaseURL = chunks.at(-1) || ""; // last part of the url
        const databaseID = databaseURL.split("?")[0]; // chop any query params off
        data.provider_location = databaseID;
      }

      if (isEditing) {
        if (!initialData) {
          throw new Error(
            "unreachable: expected destination to exist for edit",
          );
        }
        await apiClient.postmortemsUpdateDestination({
          id: initialData.id,
          updateDestinationRequestBody: {
            name: data.name,
            document_provider:
              data.document_provider as unknown as PostmortemsUpdateDestinationRequestBodyDocumentProviderEnum,
            provider_location: data.provider_location,
            is_default: data.is_default,
          },
        });
      } else {
        await apiClient.postmortemsCreateDestination({
          createDestinationRequestBody: data,
        });
      }
    },
    {
      onSuccess: onClose,
      setError,
    },
  );

  const documentProvider = watch("document_provider");
  const isNotion = documentProvider === "notion";

  const apiClient = useClient();

  if (integrationsLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  if (!integrations) {
    return (
      <GenericErrorMessage description="We couldn't load your post-mortem settings." />
    );
  }
  return (
    <FormModalV2
      formMethods={formMethods}
      genericError={genericError}
      analyticsTrackingId="create-postmortem-destination"
      title={(isEditing ? "Edit" : "Create") + " post-mortem destination"}
      disableQuickClose={isDirty}
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonText={isEditing ? "Save" : "Create"}
          confirmButtonType="submit"
          saving={saving}
          onClose={onClose}
          disabled={!isDirty}
        />
      }
    >
      {availableIntegrations?.length === 0 ? (
        <EmptyState
          icon={IconEnum.Folder}
          content={
            <Txt>
              Post-mortem destinations are used to specify locations where
              post-mortems can be exported to.
              <br />
              <br />
              To create a post-mortem destination, you need to have a document
              provider installed, such as Confluence.
              <br />
              <br />
              To install one, go to{" "}
              <Link
                to={"/settings/integrations"}
                openInNewTab
                analyticsTrackingId={null}
              >
                {"Settings -> Integrations"}
              </Link>
              .
            </Txt>
          }
        />
      ) : (
        <>
          <InputV2
            formMethods={formMethods}
            name="name"
            label="Name"
            required="Please provide a name"
            placeholder="e.g. Platform team post-mortems"
          />
          <StaticSingleSelectV2
            formMethods={formMethods}
            name="document_provider"
            label="Document provider"
            placeholder="Select an integration"
            options={availableIntegrations || []}
            required="Please select an integration"
          />
          {/* Confluence */}
          {documentProvider === "confluence" && (
            <>
              <DynamicSingleSelectV2
                formMethods={formMethods}
                label="Page"
                helptext={
                  <Txt>
                    We&apos;ll create post-mortems as children of the page you
                    select.
                  </Txt>
                }
                name="provider_location"
                placeholder="Search for a Confluence Page"
                rules={{
                  validate: () => {
                    const { provider_location_url, provider_location } =
                      getValues();
                    if (!provider_location && !provider_location_url) {
                      return "You must select a page";
                    }
                    return undefined;
                  },
                }}
                loadOptions={getTypeaheadOptions(
                  apiClient,
                  TypeaheadTypeEnum.ConfluenceContent,
                  {},
                )}
                hydrateOptions={hydrateInitialSelectOptions(
                  apiClient,
                  TypeaheadTypeEnum.ConfluenceContent,
                )}
              />
              <Txt grey className="mt-1 mb-2 max-w-xl">
                Or paste a link to a Confluence page
              </Txt>
              <InputV2
                formMethods={formMethods}
                name="provider_location_url"
                type={InputType.Text}
                placeholder="e.g. https://you.atlassian.net/wiki/spaces/~123/pages/123/name"
              />
            </>
          )}

          {/* Google Docs */}
          {documentProvider === DocumentProviderEnum.GoogleDocs && (
            <div>
              <Txt grey className="mt-1 mb-2 max-w-xl">
                Paste a link to a Google Drive folder
              </Txt>

              <InputV2
                formMethods={formMethods}
                name="provider_location"
                type={InputType.Text}
                placeholder="e.g. https://drive.google.com/drive/folders/1u8nuABC38o7XYZBdnPwyD6yzLz3uxOJB"
              />
            </div>
          )}

          {/* Notion */}
          {isNotion && !isEditing && (
            <div className="flex flex-col gap-4">
              <div className="space-y-2">
                <FormLabelV2 htmlFor={"provider_location_url"} required>
                  Notion Database
                </FormLabelV2>
                <Callout theme={CalloutTheme.Warning}>
                  incident.io will take ownership of this Notion database and
                  will modify properties / columns.
                </Callout>
              </div>

              <div className="space-y-2">
                <FormLabelV2 htmlFor={"provider_location_url"} required>
                  1. Ensure the database is shared with incident.io
                </FormLabelV2>
                <img
                  src={notionDatabaseSharingGraphic}
                  className="shadow-lg w-9/12 mx-auto"
                />
              </div>

              <div className="space-y-2">
                <FormLabelV2 htmlFor={"provider_location_url"} required>
                  2. Paste a link
                </FormLabelV2>
                <Txt grey className="max-w-xl">
                  Paste a link to a Notion{" "}
                  <Txt inline bold>
                    database
                  </Txt>
                  , as opposed to the parent page.
                </Txt>

                <img
                  src={notionDatabaseCopyLinkGraphic}
                  className="shadow-lg w-9/12 mx-auto"
                />

                <InputV2
                  formMethods={formMethods}
                  name="provider_location_url"
                  type={InputType.Text}
                  placeholder="e.g. https://www.notion.so/616cebebafeb44e781f6cdc0c7852c6b"
                />
              </div>
            </div>
          )}

          {(isDefaultDestination && (
            <FormHelpTextV2>
              <div className="flex flex-row gap-1">
                <Icon id={IconEnum.Info} />
                This is your default destination
              </div>
            </FormHelpTextV2>
          )) || (
            <CheckboxV2
              formMethods={formMethods}
              name="is_default"
              label="Make this your default destination"
            />
          )}
        </>
      )}
    </FormModalV2>
  );
};
