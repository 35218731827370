import { Incident, IncidentAlert, IncidentStatus } from "@incident-io/api";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import { captureException } from "@sentry/core";
import { useState } from "react";

import { useAlertResources } from "../../../alerts/common/useAlertResources";
import { IncidentAlerts } from "../body/Alerts";
import { Category } from "../statuses/status-utils";
import { TriageDecision } from "../statuses/TriageDecisionRadio";

// doPendingAlertsRequireAction will check if you're moving to a 'final' status, and you have pending alerts
// then it will return a boolean indicating if you should show a <PendingAlertsBlockerWarning />
export const doPendingAlertsRequireAction = ({
  pendingAlerts,
  selectedStatusID,
  selectedTriageDecision: selectedTriageAction,
  statuses,
}: {
  pendingAlerts: IncidentAlert[];
  selectedStatusID?: string;
  statuses: IncidentStatus[];
  selectedTriageDecision?: TriageDecision;
}) => {
  const finalStatuses = statuses.filter((s) =>
    [
      Category.PostIncident,
      Category.Closed,
      Category.Declined,
      Category.Canceled,
    ].includes(s.category),
  );

  const isMovingToFinalState =
    selectedTriageAction === TriageDecision.Decline ||
    !!finalStatuses.find((s) => s.id === selectedStatusID);

  return isMovingToFinalState && pendingAlerts.length > 0;
};

export const PendingAlertsBlockerWarning = ({
  alertsRequireAction,
  incident,
  pendingAlerts,
}: {
  incident: Incident;
  pendingAlerts: IncidentAlert[];
  alertsRequireAction: boolean;
}) => {
  const [isShowingModal, setIsShowingModal] = useState(false);

  if (!alertsRequireAction) {
    return null;
  }

  return (
    <Callout theme={CalloutTheme.Warning}>
      <p className={"text-sm"}>
        {pendingAlerts.length === 1
          ? "You have an alert that has been grouped into this incident, but not actioned. You must confirm its relation to this incident before it can be updated."
          : `You have ${pendingAlerts.length} alerts that have been grouped into this incident, but have not been actioned. You must confirm their relationship to this incident before it can be updated.`}
      </p>

      <Button
        className={"mt-2"}
        analyticsTrackingId={"deal-with-alerts"}
        onClick={() => {
          setIsShowingModal(true);
        }}
        theme={ButtonTheme.Secondary}
        title={"Manage alerts"}
        size={ButtonSize.Small}
      >
        Manage alerts
      </Button>
      {isShowingModal && (
        <PendingAlertsNeedingUpdateModal
          onClose={() => setIsShowingModal(false)}
          incident={incident}
          pendingAlerts={pendingAlerts}
        />
      )}
    </Callout>
  );
};

export const PendingAlertsNeedingUpdateModal = ({
  onClose,
  incident,
  pendingAlerts,
}: {
  onClose: () => void;
  incident: Incident;
  pendingAlerts: IncidentAlert[];
}) => {
  const {
    error: resourcesError,
    isLoading: resourcesLoading,
    sourcesResponse,
    configsResp,
    resourcesListResp,
  } = useAlertResources();

  const initialState = pendingAlerts.length === 1 ? [pendingAlerts[0].id] : [];
  const [selectedIncidentAlertIDs, setSelectedIncidentAlertIDs] =
    useState<string[]>(initialState);

  if (resourcesError) {
    captureException(resourcesError);
    return <GenericErrorMessage error={resourcesError} />;
  }

  return (
    <Modal
      isOpen
      loading={resourcesLoading}
      analyticsTrackingId={"pending-alerts-requiring-update"}
      title={"Manage pending alerts"}
      onClose={onClose}
      isExtraLarge
    >
      <ModalContent>
        <div className={"flex flex-col"}>
          <div
            className={"flex-center-y items-center space-x-1 justify-between"}
          >
            <div className="text-content-tertiary text-sm pb-2">
              {pendingAlerts.length === 1
                ? "You have an alert that has been grouped into this incident, but not actioned. What would you like to do with it?"
                : `You have ${pendingAlerts.length} alerts that have been grouped into this incident, but have not been actioned. What would you like to do with them?`}
            </div>
          </div>
          {sourcesResponse && resourcesListResp && configsResp && (
            <IncidentAlerts
              linkToAlerts={false}
              context={"modal"}
              kind={"pending"}
              incidentId={incident.id}
              alertSources={sourcesResponse.alert_sources}
              alertSourceConfigs={configsResp.alert_source_configs}
              incidentAlerts={pendingAlerts}
              setSelectedIncidentAlertIDs={setSelectedIncidentAlertIDs}
              selectedIncidentAlertIDs={selectedIncidentAlertIDs}
            />
          )}
        </div>
      </ModalContent>
      <ModalFooter hideConfirmButton={true} onClose={() => onClose()} />
    </Modal>
  );
};
