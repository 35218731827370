import {
  IncidentStatus,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
  IncidentType,
  Severity,
} from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  IncidentStatusBadge,
  SeverityBadge,
} from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export const EditableSeverityBadge = ({
  severity,
  onEdit,
}: {
  severity?: Severity;
  onEdit?: () => void;
}) => {
  return (
    <EditableBadge onEdit={onEdit}>
      <SeverityBadge
        severity={severity}
        size={BadgeSize.Medium}
        className="cursor-pointer hover:!border-slate-400"
      />
    </EditableBadge>
  );
};

export const EditableStatusBadge = ({
  status,
  onEdit,
}: {
  status: IncidentStatus;
  onEdit?: () => void;
}) => {
  if (
    status.category === StatusCategoryEnum.Active ||
    status.category === StatusCategoryEnum.PostIncident ||
    status.category === StatusCategoryEnum.Closed
  ) {
    return null;
  }

  return (
    <EditableBadge onEdit={onEdit}>
      <IncidentStatusBadge
        status={status}
        size={BadgeSize.Medium}
        className="cursor-pointer hover:!border-slate-400"
      />
    </EditableBadge>
  );
};

export const EditableIncidentTypesBadge = ({
  incidentType,
  onEdit,
}: {
  incidentType?: IncidentType;
  onEdit?: () => void;
}) => {
  let label = incidentType?.name;
  if (!incidentType) {
    label = "Add type";
  }

  return (
    <EditableBadge onEdit={onEdit}>
      <Badge
        theme={BadgeTheme.Unstyled}
        className="border border-stroke"
        size={BadgeSize.Medium}
        icon={incidentType ? IconEnum.IncidentType : IconEnum.AddIncidentType}
        label={label}
      />
    </EditableBadge>
  );
};

export const EditableBadge = ({
  onEdit,
  children,
}: {
  onEdit?: () => void;
  children: React.ReactNode;
}) => {
  if (!onEdit) {
    return <>{children}</>;
  }

  return (
    <Button
      theme={ButtonTheme.Unstyled}
      onClick={onEdit}
      className="relative cursor-pointer text-content-primary bg-white rounded-[6px]"
      analyticsTrackingId="edit-severity-badge"
    >
      {children}
      <div
        className={tcx(
          "opacity-0 hover:opacity-100 transition",
          "bg-surface-secondary border border-slate-400 rounded-[6px] flex-center",
          "text-content-primary font-normal text-xs",
          "z-30 absolute top-0 left-0 w-full h-full",
        )}
      >
        <Icon id={IconEnum.Edit} size={IconSize.Medium} />
        Edit
      </div>
    </Button>
  );
};
