import { Icon, IconEnum, IconSize, Interpose } from "@incident-ui";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { MenuPathItem } from "../ReferenceSelectorPopover/ReferenceSelectorPopover";

export const TruncatingReferenceLabel = ({
  path,
  label,
  separatorClassName,
  separatorStyle: style = "chevrons",
}: { separatorStyle?: "chevrons" | "arrows"; separatorClassName?: string } & (
  | {
      path: MenuPathItem[];
      label?: undefined;
    }
  | {
      path?: undefined;
      label: string;
    }
)): React.ReactElement => {
  let labelParts: string[] = [];
  if (path) {
    labelParts = path.map((x) => x.label);
  } else {
    if (label) {
      labelParts = label?.split(" → ");
    }
  }

  const last = labelParts[labelParts.length - 1];
  const allButLast = labelParts.slice(0, labelParts.length - 1);

  return (
    <Interpose
      separator={
        style === "chevrons" ? (
          <Icon
            id={IconEnum.ChevronRight}
            className={tcx("text-content-tertiary", separatorClassName)}
            size={IconSize.Small}
          />
        ) : (
          <span
            className={tcx(
              "shrink-0 mx-1 text-content-tertiary !text-ellipsis !overflow-hidden",
              separatorClassName,
            )}
          >
            →
          </span>
        )
      }
    >
      {allButLast.map((label, i) => (
        <span key={i} className={tcx("shrink-[1000]", "truncate min-w-[24px]")}>
          {label}
        </span>
      ))}

      <span className={tcx("shrink-[1] truncate min-w-[24px]")}>{last}</span>
    </Interpose>
  );
};
