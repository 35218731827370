import {
  Callout,
  CalloutTheme,
  LoadingModal,
  ModalFooter,
  StaticSingleSelect,
} from "@incident-ui";
import { ControlledCheckbox } from "@incident-ui/Checkbox/Checkbox";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { SelectOptions } from "@incident-ui/Select/types";
import { TabModalPane } from "@incident-ui/TabModal/TabModal";
import _, { isEmpty } from "lodash";
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Path, useForm, UseFormReturn } from "react-hook-form";
import { ErrorMessageUI } from "src/components/@shared/forms/ErrorMessage";
import { FormLabelV2 } from "src/components/@shared/forms/v2/FormInputWrapperV2";
import { FormTabModalV2 } from "src/components/@shared/forms/v2/FormV2";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import {
  IssueFieldResponse,
  JIRA_CLOUD_CONFIG,
  JiraFormData,
  JiraStep,
} from "src/components/@shared/integrations/jira/jiraConfig";
import { shouldDisableExportToJiraButton } from "src/components/post-incident/follow-ups/bulk-update/bulk-export/BulkExportToJiraCloudModal";
import { UnsupportedJiraFieldsErrorMessage } from "src/components/post-incident/follow-ups/bulk-update/bulk-export/UnsupportedJiraFieldsErrorMessage";
import {
  IssueTemplateContextEnum,
  UnifiedIssueTemplate,
  useAllIssueTemplates,
} from "src/components/settings/follow-ups/FollowUpExportSection";
import {
  FollowUp,
  Incident,
  JiraIssueDefaultValues,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { IssueTrackerProviderEnum } from "../utils";
import {
  FormEverythingElse,
  FormSelectIssueType,
  FormSelectProject,
  FormSelectSite,
} from "./JiraFormFields";

type JiraModalProps = {
  followUp: FollowUp;
  incident: Incident;
  isPrivateIncident: boolean;
  onClose: () => void;
  updateCallback: (newFollowUp: FollowUp) => Promise<void>;
  defaultValuesFromIssueTemplate?: JiraIssueDefaultValues;
};

export const ExportToJiraCloudModal = (
  props: JiraModalProps,
): React.ReactElement => {
  const { allTemplates, isLoading } = useAllIssueTemplates();
  const jiraCloudIssueTemplates = allTemplates.filter(
    (t) =>
      t.provider === IssueTrackerProviderEnum.Jira &&
      t.context === IssueTemplateContextEnum.FollowUp,
  );

  if (isLoading || !jiraCloudIssueTemplates) {
    return (
      <LoadingModal
        title={`Jira: Create or link to existing issue`}
        onClose={props.onClose}
      />
    );
  }

  return (
    <ExportToJiraModalL2
      {...props}
      jiraCloudIssueTemplates={jiraCloudIssueTemplates}
    />
  );
};

const ExportToJiraModalL2 = ({
  incident,
  followUp,
  isPrivateIncident,
  onClose,
  updateCallback,
  defaultIssueTemplateID,
  jiraCloudIssueTemplates,
}: JiraModalProps & {
  defaultIssueTemplateID?: string;
  jiraCloudIssueTemplates: UnifiedIssueTemplate[];
}): React.ReactElement => {
  const [selectedTemplateID, setSelectedTemplateID] = useState<
    string | undefined
  >(defaultIssueTemplateID);

  const jiraCloudIssueTemplateOptions: SelectOptions = (
    jiraCloudIssueTemplates || []
  ).map((t) => ({
    label: t.name,
    value: t.id,
    sort_key: t.name,
  }));

  // We use this 'wrapper' component so we can do all our data fetching
  // before we mount the form, which makes the default values work more
  // nicely.
  const {
    data,
    isLoading,
    error: defaultValuesError,
  } = useAPI(
    selectedTemplateID ? "issueTrackersJiraGetIssueTemplateForFollowUp" : null,
    {
      followUpId: followUp.id,
      issueTemplateId: selectedTemplateID || "",
    },
  );
  const defaultValues = data?.default_values;

  if (defaultValuesError) {
    return (
      <ErrorModal
        error={
          new Error(
            "Failed to fetch Jira issue template - continuing without a template.",
            { cause: defaultValuesError },
          )
        }
        onClose={onClose}
      />
    );
  }

  return (
    <ExportToJiraModalL3
      loadingTemplate={isLoading}
      selectedTemplateID={selectedTemplateID}
      setSelectedTemplateID={setSelectedTemplateID}
      issueTemplateOptions={jiraCloudIssueTemplateOptions}
      incident={incident}
      followUp={followUp}
      isPrivateIncident={isPrivateIncident}
      onClose={onClose}
      updateCallback={updateCallback}
      defaultValuesFromIssueTemplate={defaultValues || {}}
    />
  );
};

// ExportToJiraModalL3 is a generic component that doesn't know which Jira
// variety it's interacting with. Depending on the `config` passed it, it can
// either use the `/issue_trackers/jira` or `/issue_trackers/jira_server` APIs
//
// NOTE: there is a similar copy of this called BulkExportToJiraModal
export const ExportToJiraModalL3 = ({
  incident,
  followUp,
  isPrivateIncident,
  onClose,
  updateCallback,
  defaultValuesFromIssueTemplate: issueTemplate,
  loadingTemplate,
  selectedTemplateID,
  setSelectedTemplateID: realSetSelectedTemplateID,
  issueTemplateOptions,
}: JiraModalProps & {
  loadingTemplate: boolean;
  selectedTemplateID?: string;
  setSelectedTemplateID: React.Dispatch<SetStateAction<string | undefined>>;
  issueTemplateOptions: SelectOptions;
}): React.ReactElement => {
  const { issueUrlTemplate } = JIRA_CLOUD_CONFIG;

  const [connectToExistingTicket, setConnectToExistingTicket] =
    useState<boolean>(false);

  const defaultValues = useMemo(
    () => ({
      title: followUp.title,
      description: followUp.description,
      connect_to_parent_issue: !!incident.external_issue_reference,
      ...issueTemplate,
    }),
    [
      followUp.title,
      followUp.description,
      incident.external_issue_reference,
      issueTemplate,
    ],
  );

  const formMethods = useForm<JiraFormData>({
    defaultValues,
  });

  const {
    formState: { errors },
    reset,
    setError,
    control,
  } = formMethods;

  const waitingForTemplate = useRef(false);
  const setSelectedTemplateID = (newID: string | undefined) => {
    realSetSelectedTemplateID((prevID) => {
      if (newID !== undefined && prevID !== newID) {
        // If we're selecting a different ID, we need to reset the form once the
        // template loads.
        waitingForTemplate.current = true;
      }

      return newID;
    });
  };
  // Reset the form when a new template is chosen (after loading the template)
  useEffect(() => {
    if (waitingForTemplate.current && !loadingTemplate) {
      reset(defaultValues);
      waitingForTemplate.current = false;
    }
  }, [reset, defaultValues, loadingTemplate]);

  const {
    step,
    siteId,
    projectId,
    issueTypeId,
    issueFields,
    onChangeSite,
    onChangeProject,
    onChangeIssueType,
  } = useSetupJiraExport({
    formMethods,
    followUpId: followUp.id,
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    { incidentId: incident.id },
    async (apiClient, body: JiraFormData) => {
      const { url, ...createFields } = body;
      const newFollowUp = connectToExistingTicket
        ? await JIRA_CLOUD_CONFIG.connectIssue(apiClient, {
            url,
            follow_up_id: followUp.id,
          })
        : (
            await apiClient.issueTrackersJiraCreateIssue({
              jiraCreateIssueRequestBody: {
                ...createFields,
                follow_up_id: followUp.id,
              },
            })
          ).follow_up;

      await updateCallback(newFollowUp);
    },
    { setError, onSuccess: onClose },
  );

  const unsupportedFields = issueFields?.unsupported_required_fields;

  const hasIncidentTicket = !!incident.external_issue_reference;
  const incidentTicketMatchesProvider =
    (incident.external_issue_reference?.provider as string) ===
    (JIRA_CLOUD_CONFIG.integrationProvider as string);

  const canIgnoreSiteID = !siteId;
  const incidentTicketMatchesSiteID =
    canIgnoreSiteID ||
    siteId === incident.external_issue_reference?.provider_instance_id;

  const canLinkToParentTicket =
    hasIncidentTicket &&
    incidentTicketMatchesProvider &&
    incidentTicketMatchesSiteID;

  return (
    <FormTabModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      title={`Jira: Create or link to existing issue`}
      analyticsTrackingId="add-to-jira"
      onClose={onClose}
      hideHeader
      tabs={[
        {
          id: "add",
          label: "Create new",
        },
        {
          id: "connect",
          label: "Connect to existing",
        },
      ]}
      onTabChange={(tab: string) =>
        setConnectToExistingTicket(tab === "connect")
      }
      footer={
        <ModalFooter
          onClose={onClose}
          disabled={shouldDisableExportToJiraButton({
            step: step,
            issueFields: issueFields?.issue_fields ?? null,
            connectToExistingTicket: connectToExistingTicket,
            unsupportedFields: unsupportedFields ?? null,
          })}
          confirmButtonType="submit"
          confirmButtonText={connectToExistingTicket ? "Connect" : "Create"}
          saving={saving}
        />
      }
      suppressInitialAnimation={true}
    >
      <TabModalPane tabId="add" className="space-y-4 p-2">
        {isPrivateIncident && (
          <Callout theme={CalloutTheme.Warning}>
            This is a private incident. This issue will be visible to anyone
            with access to your Jira project.
          </Callout>
        )}
        <p className="text-sm text-content-primary">
          We&apos;ll automatically sync any changes in Jira.
        </p>
        <ErrorMessageUI message={genericError} />
        {incident.external_issue_reference && canLinkToParentTicket && (
          <ControlledCheckbox
            label={
              <>
                Link this issue to the incident ticket{" "}
                <a
                  target="_blank"
                  className="text-content-primary"
                  href={incident.external_issue_reference.issue_permalink}
                  rel="noreferrer"
                >
                  ({incident.external_issue_reference.issue_name})
                </a>
                ?
              </>
            }
            id="connect_to_parent_issue"
            control={control}
            errors={errors}
          />
        )}
        {issueTemplateOptions.length > 0 && (
          <div>
            <FormLabelV2 htmlFor={"issue_template_id"} required={false}>
              Export template
            </FormLabelV2>
            <StaticSingleSelect
              className="mt-3"
              label="Export template"
              options={issueTemplateOptions}
              placeholder="Select a template..."
              isLoading={false}
              isClearable={true}
              onChange={(newVal) => {
                if (!newVal) {
                  setSelectedTemplateID(undefined);
                } else {
                  setSelectedTemplateID(newVal as string);
                }
                onChangeProject();
              }}
              value={selectedTemplateID}
            />
          </div>
        )}
        <FormSelectSite onChangeSite={onChangeSite} />
        <FormSelectProject
          step={step}
          config={JIRA_CLOUD_CONFIG}
          siteId={siteId}
          onChangeProject={onChangeProject}
        />
        <FormSelectIssueType
          siteId={siteId}
          projectId={projectId}
          config={JIRA_CLOUD_CONFIG}
          step={step}
          onChangeIssueType={onChangeIssueType}
        />
        {unsupportedFields ? (
          unsupportedFields.length > 0 ? (
            <UnsupportedJiraFieldsErrorMessage
              unsupportedRequiredFields={unsupportedFields}
            />
          ) : (
            <FormEverythingElse
              siteId={siteId}
              projectId={projectId}
              step={step}
              config={JIRA_CLOUD_CONFIG}
              issueTypeId={issueTypeId}
              issueFields={issueFields?.issue_fields || []}
              hasDescription={issueFields?.has_description}
              defaultValuesFromIssueTemplate={issueTemplate}
              loading={loadingTemplate}
            />
          )
        ) : null}
      </TabModalPane>

      <TabModalPane tabId="connect" className="space-y-4 overflow-y-auto">
        <p className="text-sm">
          Connect this follow-up to an existing Jira ticket by pasting in the
          ticket URL.
        </p>
        <InputV2
          formMethods={formMethods}
          name="url"
          label="Existing ticket URL"
          required={connectToExistingTicket ? "Please enter a URL" : undefined}
          placeholder={issueUrlTemplate}
        />
      </TabModalPane>
    </FormTabModalV2>
  );
};

type UseSetupJiraExportReturn = {
  step: JiraStep;
  siteId: string;
  projectId: string;
  issueTypeId: string;
  issueFields: IssueFieldResponse | null;
  onChangeSite: () => void;
  onChangeProject: () => void;
  onChangeIssueType: () => void;
};

// useSetupJiraExport sets up the hooks and variables required for the Jira export modal. It needs to
// be shared because we have a modal to export a single follow-up, and a modal to export follow-ups
// in bulk.
export const useSetupJiraExport = ({
  formMethods: {
    clearErrors,
    watch,
    getValues,
    setValue,
    setError,
    formState: { errors },
  },
  followUpId,
}: {
  formMethods: UseFormReturn<JiraFormData>;
  followUpId: string | undefined;
}): UseSetupJiraExportReturn => {
  const { getIssueFieldsAPI } = JIRA_CLOUD_CONFIG;

  // Whenever we change either site/project/issue type, we need to refresh our fields.
  const [siteId, projectId, issueTypeId] = watch([
    "site_id",
    "project_id",
    "issue_type_id",
  ]);

  const hasProjectAndType =
    !isEmpty(siteId) && !isEmpty(projectId) && !isEmpty(issueTypeId);
  const { data: issueFields } = useAPI(
    hasProjectAndType ? getIssueFieldsAPI : null,
    {
      siteId,
      projectId,
      issueTypeId,
      followUpId,
    },
    {
      onError: async (jsonErr) => {
        jsonErr.errors.forEach((e) => {
          if (e?.source?.pointer) {
            const pointer = e.source.pointer as unknown as Path<JiraFormData>;
            if (!_.get(errors, pointer)) {
              setError(pointer, {
                type: "manual",
                message: e.message,
              });
            }
          } else {
            console.error(e);
            throw e;
          }
        });
      },
    },
  );

  const { data: sitesData } = useAPI("engineTypeahead", {
    resource: `CatalogEntry["JiraCloudSite"]`,
  });
  useEffect(() => {
    // If there's only one site, we want to automatically select it.
    if (sitesData?.options?.length === 1) {
      const prevSiteId = getValues("site_id");
      if (!prevSiteId) {
        setValue<"site_id">("site_id", sitesData.options[0].value);
      }
    }
  }, [getValues, setValue, sitesData]);

  const step =
    siteId && projectId && issueTypeId
      ? JiraStep.Step3
      : siteId && projectId
      ? JiraStep.Step2
      : siteId
      ? JiraStep.Step1
      : JiraStep.Step0;

  const clearDynamicFields = useCallback(() => {
    // here, we want to clear everything in our form that isn't projectID or issueTypeID.
    const currentValues: JiraFormData = getValues();

    Object.keys(currentValues).forEach((key) => {
      if (key.includes("dynamic_fields")) {
        // @ts-expect-error react-hook-form I don't understand you
        setValue(key as keyof JiraFormData, null);
      }
    });
  }, [getValues, setValue]);

  const onChangeSite = useCallback(() => {
    // @ts-expect-error react-hook-form I don't understand you
    setValue<"issue_type_id">("issue_type_id", null);
    // @ts-expect-error react-hook-form I don't understand you
    setValue<"project_id">("project_id", null);
    // @ts-expect-error react-hook-form I don't understand you
    setValue<"site_id">("site_id", null);
    clearDynamicFields();
    clearErrors();
  }, [clearDynamicFields, setValue, clearErrors]);

  const onChangeProject = useCallback(() => {
    // @ts-expect-error react-hook-form I don't understand you
    setValue<"issue_type_id">("issue_type_id", null);
    // @ts-expect-error react-hook-form I don't understand you
    setValue<"project_id">("project_id", null);
    clearDynamicFields();
    clearErrors();
  }, [clearDynamicFields, setValue, clearErrors]);

  const onChangeIssueType = useCallback(() => {
    // @ts-expect-error react-hook-form I don't understand you
    setValue<"issue_type_id">("issue_type_id", null);
    clearDynamicFields();
    clearErrors();
  }, [clearDynamicFields, setValue, clearErrors]);

  return {
    step,
    siteId,
    projectId,
    issueTypeId,
    issueFields: issueFields ?? null,
    onChangeSite,
    onChangeProject,
    onChangeIssueType,
  };
};
