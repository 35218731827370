/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostmortemSettings
 */
export interface PostmortemSettings {
    /**
     * Default document provider for copy&paste
     * @type {string}
     * @memberof PostmortemSettings
     */
    default_document_provider_copy_paste?: PostmortemSettingsDefaultDocumentProviderCopyPasteEnum;
    /**
     * Default timezone postmortems will be generated in
     * @type {string}
     * @memberof PostmortemSettings
     */
    default_timezone?: string;
    /**
     * Whether to sync followups from external postmortems
     * @type {boolean}
     * @memberof PostmortemSettings
     */
    postmortem_followup_sync?: boolean;
    /**
     * Custom term for postmortem documents
     * @type {string}
     * @memberof PostmortemSettings
     */
    postmortem_rename?: string;
    /**
     * the destination that should be used for test or tutorial incidents
     * @type {string}
     * @memberof PostmortemSettings
     */
    test_or_tutorial_destination_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemSettingsDefaultDocumentProviderCopyPasteEnum {
    Empty = '',
    Confluence = 'confluence',
    GoogleDocs = 'google_docs',
    Notion = 'notion',
    CopyPasteBasecamp = 'copy_paste_basecamp',
    CopyPasteConfluence = 'copy_paste_confluence',
    CopyPasteGithubWiki = 'copy_paste_github_wiki',
    CopyPasteGoogleDocs = 'copy_paste_google_docs',
    CopyPasteNotion = 'copy_paste_notion',
    CopyPasteQuip = 'copy_paste_quip'
}

export function PostmortemSettingsFromJSON(json: any): PostmortemSettings {
    return PostmortemSettingsFromJSONTyped(json, false);
}

export function PostmortemSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default_document_provider_copy_paste': !exists(json, 'default_document_provider_copy_paste') ? undefined : json['default_document_provider_copy_paste'],
        'default_timezone': !exists(json, 'default_timezone') ? undefined : json['default_timezone'],
        'postmortem_followup_sync': !exists(json, 'postmortem_followup_sync') ? undefined : json['postmortem_followup_sync'],
        'postmortem_rename': !exists(json, 'postmortem_rename') ? undefined : json['postmortem_rename'],
        'test_or_tutorial_destination_id': !exists(json, 'test_or_tutorial_destination_id') ? undefined : json['test_or_tutorial_destination_id'],
    };
}

export function PostmortemSettingsToJSON(value?: PostmortemSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_document_provider_copy_paste': value.default_document_provider_copy_paste,
        'default_timezone': value.default_timezone,
        'postmortem_followup_sync': value.postmortem_followup_sync,
        'postmortem_rename': value.postmortem_rename,
        'test_or_tutorial_destination_id': value.test_or_tutorial_destination_id,
    };
}

