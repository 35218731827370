/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FilterFormFields,
    FilterFormFieldsFromJSON,
    FilterFormFieldsFromJSONTyped,
    FilterFormFieldsToJSON,
} from './FilterFormFields';
import {
    IncidentDurationMetric,
    IncidentDurationMetricFromJSON,
    IncidentDurationMetricFromJSONTyped,
    IncidentDurationMetricToJSON,
} from './IncidentDurationMetric';
import {
    IncidentTimestamp,
    IncidentTimestampFromJSON,
    IncidentTimestampFromJSONTyped,
    IncidentTimestampToJSON,
} from './IncidentTimestamp';
import {
    PostIncidentTaskConfigSelectOption,
    PostIncidentTaskConfigSelectOptionFromJSON,
    PostIncidentTaskConfigSelectOptionFromJSONTyped,
    PostIncidentTaskConfigSelectOptionToJSON,
} from './PostIncidentTaskConfigSelectOption';
import {
    SelectOption,
    SelectOptionFromJSON,
    SelectOptionFromJSONTyped,
    SelectOptionToJSON,
} from './SelectOption';

/**
 * 
 * @export
 * @interface IncidentFilterFieldsListInsightsResponseBody
 */
export interface IncidentFilterFieldsListInsightsResponseBody {
    /**
     * 
     * @type {Array<IncidentDurationMetric>}
     * @memberof IncidentFilterFieldsListInsightsResponseBody
     */
    duration_metrics: Array<IncidentDurationMetric>;
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof IncidentFilterFieldsListInsightsResponseBody
     */
    escalation_external_users: Array<SelectOption>;
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof IncidentFilterFieldsListInsightsResponseBody
     */
    escalation_targets: Array<SelectOption>;
    /**
     * 
     * @type {FilterFormFields}
     * @memberof IncidentFilterFieldsListInsightsResponseBody
     */
    fields: FilterFormFields;
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof IncidentFilterFieldsListInsightsResponseBody
     */
    post_incident_flows?: Array<SelectOption>;
    /**
     * 
     * @type {Array<PostIncidentTaskConfigSelectOption>}
     * @memberof IncidentFilterFieldsListInsightsResponseBody
     */
    post_incident_task_configs?: Array<PostIncidentTaskConfigSelectOption>;
    /**
     * 
     * @type {Array<IncidentTimestamp>}
     * @memberof IncidentFilterFieldsListInsightsResponseBody
     */
    timestamps: Array<IncidentTimestamp>;
}

export function IncidentFilterFieldsListInsightsResponseBodyFromJSON(json: any): IncidentFilterFieldsListInsightsResponseBody {
    return IncidentFilterFieldsListInsightsResponseBodyFromJSONTyped(json, false);
}

export function IncidentFilterFieldsListInsightsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFilterFieldsListInsightsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration_metrics': ((json['duration_metrics'] as Array<any>).map(IncidentDurationMetricFromJSON)),
        'escalation_external_users': ((json['escalation_external_users'] as Array<any>).map(SelectOptionFromJSON)),
        'escalation_targets': ((json['escalation_targets'] as Array<any>).map(SelectOptionFromJSON)),
        'fields': FilterFormFieldsFromJSON(json['fields']),
        'post_incident_flows': !exists(json, 'post_incident_flows') ? undefined : ((json['post_incident_flows'] as Array<any>).map(SelectOptionFromJSON)),
        'post_incident_task_configs': !exists(json, 'post_incident_task_configs') ? undefined : ((json['post_incident_task_configs'] as Array<any>).map(PostIncidentTaskConfigSelectOptionFromJSON)),
        'timestamps': ((json['timestamps'] as Array<any>).map(IncidentTimestampFromJSON)),
    };
}

export function IncidentFilterFieldsListInsightsResponseBodyToJSON(value?: IncidentFilterFieldsListInsightsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration_metrics': ((value.duration_metrics as Array<any>).map(IncidentDurationMetricToJSON)),
        'escalation_external_users': ((value.escalation_external_users as Array<any>).map(SelectOptionToJSON)),
        'escalation_targets': ((value.escalation_targets as Array<any>).map(SelectOptionToJSON)),
        'fields': FilterFormFieldsToJSON(value.fields),
        'post_incident_flows': value.post_incident_flows === undefined ? undefined : ((value.post_incident_flows as Array<any>).map(SelectOptionToJSON)),
        'post_incident_task_configs': value.post_incident_task_configs === undefined ? undefined : ((value.post_incident_task_configs as Array<any>).map(PostIncidentTaskConfigSelectOptionToJSON)),
        'timestamps': ((value.timestamps as Array<any>).map(IncidentTimestampToJSON)),
    };
}

