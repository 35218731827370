/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EscalationsRespondEscalationRequestBody
 */
export interface EscalationsRespondEscalationRequestBody {
    /**
     * Whether we're responding with an ack or nack
     * @type {string}
     * @memberof EscalationsRespondEscalationRequestBody
     */
    response: EscalationsRespondEscalationRequestBodyResponseEnum;
    /**
     * Where we are responding from
     * @type {string}
     * @memberof EscalationsRespondEscalationRequestBody
     */
    source: EscalationsRespondEscalationRequestBodySourceEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalationsRespondEscalationRequestBodyResponseEnum {
    Ack = 'ack',
    Nack = 'nack'
}/**
* @export
* @enum {string}
*/
export enum EscalationsRespondEscalationRequestBodySourceEnum {
    App = 'app',
    Dashboard = 'dashboard',
    DashboardViaEmail = 'dashboard-via-email'
}

export function EscalationsRespondEscalationRequestBodyFromJSON(json: any): EscalationsRespondEscalationRequestBody {
    return EscalationsRespondEscalationRequestBodyFromJSONTyped(json, false);
}

export function EscalationsRespondEscalationRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationsRespondEscalationRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'response': json['response'],
        'source': json['source'],
    };
}

export function EscalationsRespondEscalationRequestBodyToJSON(value?: EscalationsRespondEscalationRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'response': value.response,
        'source': value.source,
    };
}

