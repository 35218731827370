import { Product } from "@incident-shared/billing";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { NoPermissionMessage } from "@incident-shared/gates/GatedButton/GatedButton";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Link,
  Loader,
  StackedList,
  ToastTheme,
  Txt,
} from "@incident-ui";
import { DrawerBody, DrawerFooter } from "@incident-ui/Drawer/Drawer";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import {
  IssueTemplateContextEnum,
  UnifiedIssueTemplate,
  useAllIssueTemplates,
} from "src/components/settings/follow-ups/FollowUpExportSection";
import {
  BranchesOnlyExpression,
  ExpressionBranch,
  ExpressionElseBranch,
  IssueTrackersGetAllIssueTrackerIssueSyncFailuresIssueTrackerProviderEnum,
  IssueTrackersGetIssueTrackerSyncConfigContextEnum,
  IssueTrackerSyncConfig,
  IssueTrackerSyncConfigPayload,
  IssueTrackerSyncConfigPayloadContextEnum,
  JiraCloudConfig,
  JiraIssueTemplateContextEnum,
  LegacyIssueTemplate,
} from "src/contexts/ClientContext";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { branchesOnlyExpressionToPayload } from "../../../../@shared/engine/expressions/expressionToPayload";
import { SettingsListItem } from "../../../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSubHeading } from "../../../SettingsSubHeading";
import { ConnectingUserAndSiteJira } from "../../atlassian-connect/ConnectingUserAndSite";
import { IntegrationSettingsSection } from "../../common/IntegrationSettingsSection";
import { CreateEditJiraIssueTemplateModal } from "./CreateEditJiraIssueTemplateModal";
import { IssueTemplateExpressionEditor } from "./IssueTemplateExpressionEditor";
import {
  IssueTrackerSyncFailureActivity,
  IssueTrackerSyncType,
} from "./IssueTrackerSyncFailureActivity";

type FormData = Omit<
  IssueTrackerSyncConfigPayload,
  "issue_template_expression"
> & {
  issue_template_expression?: BranchesOnlyExpression | undefined;
  issue_templates?: LegacyIssueTemplate[] | undefined;
};

export const JiraCloudConfigureDrawer = ({
  connectionError,
}: {
  connectionError: React.ReactNode;
}): React.ReactElement | null => {
  const { data: jiraConfig, error: jiraConfigError } = useAPI(
    "integrationsJiraCloudGetConfig",
    undefined,
  );

  const { data: syncConfig, error: syncConfigError } = useAPI(
    "issueTrackersGetIssueTrackerSyncConfig",
    {
      context: IssueTrackersGetIssueTrackerSyncConfigContextEnum.IncidentTicket,
    },
  );

  const {
    allTemplates: templatesForAllContexts,
    isLoading: templatesLoading,
    error: templatesError,
  } = useAllIssueTemplates();
  const allTemplates = templatesForAllContexts.filter(
    (tmpl) => tmpl.context === IssueTemplateContextEnum.IncidentTicket,
  );

  const error = jiraConfigError || syncConfigError || templatesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (jiraConfig == null || syncConfig == null || templatesLoading) {
    return <Loader />;
  }

  return (
    <ConfigureJiraPageInner
      key={jiraConfig.config.site_id}
      jiraConfig={jiraConfig.config}
      syncConfig={syncConfig.sync_config}
      allTemplates={allTemplates}
      connectionError={connectionError}
    />
  );
};

const ConfigureJiraPageInner = ({
  jiraConfig,
  syncConfig,
  allTemplates,
  connectionError,
}: {
  jiraConfig: JiraCloudConfig;
  syncConfig: IssueTrackerSyncConfig;
  allTemplates: UnifiedIssueTemplate[];
  connectionError: React.ReactNode;
}): React.ReactElement | null => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate(backHref);

  const formMethods = useForm<FormData>({
    defaultValues: {
      ...(syncConfig || { enabled: false }),
      // If there's no description template, put something vaguely sensible in there
      include_private_incidents: syncConfig.include_private_incidents || false,
      issue_template_expression: syncConfig.issue_template_expression,
      context: IssueTrackerSyncConfigPayloadContextEnum.IncidentTicket,
    },
    mode: "onSubmit",
  });

  const syncEnabled = formMethods.watch("enabled");
  const issueTemplates = syncConfig.issue_templates;
  useEffect(() => {
    formMethods.register("issue_templates", {
      required:
        syncEnabled && issueTemplates.length === 0
          ? "Must configure at least one template to enable syncing incidents to Jira"
          : false,
    });
    if (syncEnabled && issueTemplates.length > 0) {
      formMethods.clearErrors("issue_templates");
    }
  }, [formMethods, syncEnabled, issueTemplates]);

  const branchesFieldMethods = useFieldArray({
    control: formMethods.control,
    name: "issue_template_expression.branches",
  });

  const {
    setError,
    watch,
    formState: { isDirty },
  } = formMethods;

  const [autoExportEnabled, includePrivateIncidents] = [
    watch("enabled"),
    watch("include_private_incidents"),
  ];

  const showToast = useToast();
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "issueTrackersGetIssueTrackerSyncConfig",
    {
      context: IssueTrackersGetIssueTrackerSyncConfigContextEnum.IncidentTicket,
    },
    async (apiClient, data: FormData) => {
      return await apiClient.issueTrackersUpdateIssueTrackerSyncConfig({
        updateIssueTrackerSyncConfigRequestBody: {
          sync_config: {
            ...data,
            context: IssueTrackerSyncConfigPayloadContextEnum.IncidentTicket,
            issue_template_expression: data.issue_template_expression?.branches
              ?.length
              ? branchesOnlyExpressionToPayload(
                  data.issue_template_expression,
                  false,
                )
              : undefined,
          },
        },
      });
    },
    {
      onSuccess: () => {
        showToast({
          title: "Jira configuration updated.",
          theme: ToastTheme.Success,
        });
        onClose();
      },
      setError,
    },
  );

  // If the config allows syncing private incidents, it can only be managed by owners.
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const canSeePrivateIncidents = hasScope(ScopeNameEnum.IncidentsGlobalAccess);
  const canEdit =
    autoExportEnabled && includePrivateIncidents
      ? canSeePrivateIncidents
      : canEditSettings;
  const explanationText = canEdit
    ? undefined
    : includePrivateIncidents
    ? "Because private incidents are exported, only Owners can modify these settings"
    : NoPermissionMessage;

  const backHref = "/settings/integrations";

  const invalidTemplatesCount = issueTemplates.filter(
    (template) => template.invalid_reason,
  ).length;

  return (
    <>
      <DrawerBody className="overflow-y-auto">
        <FormV2
          formMethods={formMethods}
          onSubmit={onSubmit}
          genericError={genericError}
          id="configure-jira"
        >
          <div className="flex flex-col gap-6">
            {connectionError}

            {jiraConfig.connecting_user && (
              <ConnectingUserAndSiteJira config={jiraConfig} />
            )}

            {jiraConfig.connect_app_installations.length > 0 && (
              <Txt>
                Connected to the following Jira sites via Atlassian app
                installation{" "}
                <ul>
                  {jiraConfig.connect_app_installations.map((installation) => (
                    <li key={installation.displayURL}>
                      <Link
                        href={installation.displayURL}
                        openInNewTab
                        analyticsTrackingId={null}
                        className={
                          "font-medium !no-underline transition group items-center flex-wrap inline-block"
                        }
                      >
                        <div className="flex items-center">
                          {installation.displayURL}
                          <Icon
                            id={IconEnum.ExternalLink}
                            size={IconSize.Small}
                            className="ml-1 text-slate-400 transition group-hover:text-alarmalade-600"
                          />
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Txt>
            )}

            <IntegrationSettingsSection
              enabled={autoExportEnabled}
              enabledID="enabled"
              label="Sync incidents with Jira"
              helptext={
                <>
                  <span>
                    When enabled, we&apos;ll automatically create a ticket in
                    Jira whenever an incident matches the conditions you
                    specify, and keep it up-to-date as the incident changes.
                    We&apos;ll post incident updates as comments.
                  </span>
                  <br />
                  <br />
                  <span>
                    We will not retro-actively create Jira tickets for closed
                    incidents or retrospective incidents made in a closed state.
                  </span>
                </>
              }
              // Although admins can't modify settings for private incidents, they can turn
              // the whole feature off.
              disabled={!canEditSettings}
              requiredProduct={Product.Response}
            >
              {canEdit && invalidTemplatesCount > 0 && (
                <Callout theme={CalloutTheme.Danger}>
                  {invalidTemplatesCount} of the issue templates listed below
                  have required Jira fields that are either missing or invalid.
                  We will not be able to create new incident tickets until this
                  is resolved.
                  <IssueTrackerSyncFailureActivity
                    issueTrackerProvider={
                      IssueTrackersGetAllIssueTrackerIssueSyncFailuresIssueTrackerProviderEnum.Jira
                    }
                    syncType={IssueTrackerSyncType.Incident}
                  />
                </Callout>
              )}

              <FormInputWrapperV2 name="issue_templates">
                <ConfigureTemplatesSection
                  issueTrackerTemplates={issueTemplates}
                  branches={branchesFieldMethods.fields}
                />
              </FormInputWrapperV2>
              {issueTemplates.length > 0 && (
                <>
                  <IssueTemplateExpressionEditor
                    syncConfig={syncConfig}
                    allTemplates={allTemplates}
                    branches={branchesFieldMethods.fields}
                    onMoveBranch={branchesFieldMethods.move}
                    onAddBranch={branchesFieldMethods.append}
                    onEditBranch={branchesFieldMethods.update}
                    onRemoveBranch={branchesFieldMethods.remove}
                    resetBranches={() => branchesFieldMethods.replace([])}
                    privateIncidentsEnabled={includePrivateIncidents}
                  />
                  <ConfigureCloudSync
                    canSeePrivateIncidents={canSeePrivateIncidents}
                    includePrivateIncidents={includePrivateIncidents}
                  />
                </>
              )}
            </IntegrationSettingsSection>
          </div>
        </FormV2>
      </DrawerBody>
      <DrawerFooter className="flex justify-end items-center gap-2">
        <Button onClick={onClose} analyticsTrackingId="jira-configure-cancel">
          Cancel
        </Button>
        <GatedButton
          theme={ButtonTheme.Primary}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          disabledTooltipContent={explanationText}
          disabled={
            autoExportEnabled
              ? !(canEdit && isDirty && issueTemplates.length > 0) || saving
              : !(canEdit && isDirty) || saving
          }
          type="submit"
          analyticsTrackingId="jira-configure-submit"
          form="configure-jira"
        >
          Save
        </GatedButton>
      </DrawerFooter>
    </>
  );
};

const ConfigureCloudSync = ({
  canSeePrivateIncidents,
  includePrivateIncidents,
}: {
  canSeePrivateIncidents: boolean;
  includePrivateIncidents: boolean;
}): React.ReactElement => {
  const formMethods = useFormContext<FormData>();

  return (
    <div className="space-y-4 mt-4">
      {/* Include private incidents? */}
      <ToggleV2
        formMethods={formMethods}
        name="include_private_incidents"
        label="Enable for private incidents"
        toggleLabelClassName="w-full"
        toggleClassName="!items-start"
        description={
          includePrivateIncidents ? (
            <Callout theme={CalloutTheme.Warning} className="max-w-lg">
              Tickets created for private incidents will be visible to anyone
              with access to your Jira project. Only Owners will be able to
              change these settings.
            </Callout>
          ) : null
        }
        disabled={!canSeePrivateIncidents}
        className="w-full"
      />
    </div>
  );
};

const AddTemplateButton = ({
  issueTrackerTemplates,
  onClose,
}: {
  issueTrackerTemplates: LegacyIssueTemplate[];
  onClose: () => void;
}) => {
  const { identity } = useIdentity();

  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);

  const orgCanCreateIssueTemplates = !numericGateLimitReached(
    identity.feature_gates.incident_ticket_issue_templates_count,
    issueTrackerTemplates.length,
  );

  return (
    <>
      <GatedButton
        theme={ButtonTheme.Secondary}
        onClick={() => setShowAddTemplateModal(true)}
        analyticsTrackingId="add-jira-sync-issue-tracker-template"
        icon={IconEnum.Add}
        requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        upgradeRequired={!orgCanCreateIssueTemplates}
        upgradeRequiredProps={{
          gate: {
            type: "numeric",
            value:
              identity?.feature_gates.incident_ticket_issue_templates_count,
            featureNameSingular: "Issue tracker template",
          },
          featureName: "Issue tracker templates",
        }}
      >
        Add template
      </GatedButton>
      {showAddTemplateModal && (
        <CreateEditJiraIssueTemplateModal
          context={JiraIssueTemplateContextEnum.IncidentTicket}
          onClose={() => {
            setShowAddTemplateModal(false);
            onClose();
          }}
        />
      )}
    </>
  );
};

const ConfigureTemplatesSection = ({
  issueTrackerTemplates,
  branches,
}: {
  issueTrackerTemplates: LegacyIssueTemplate[];
  branches: ExpressionBranch[];
}): React.ReactElement => {
  const refetchJiraCloudConfig = useAPIRefetch(
    "integrationsJiraCloudGetConfig",
    undefined,
  );
  const refetchIssueTrackerSyncConfig = useAPIRefetch(
    "issueTrackersGetIssueTrackerSyncConfig",
    {
      context: IssueTrackersGetIssueTrackerSyncConfigContextEnum.IncidentTicket,
    },
  );
  const refetch = () => {
    refetchJiraCloudConfig();
    refetchIssueTrackerSyncConfig();
  };

  return issueTrackerTemplates.length === 0 ? (
    <EmptyState
      icon={IconEnum.FollowUps}
      content="Add a template which defines the fields of the incident ticket in Jira"
      cta={
        <AddTemplateButton
          issueTrackerTemplates={issueTrackerTemplates}
          onClose={() => refetch()}
        />
      }
    />
  ) : (
    <div>
      <SettingsSubHeading
        title={"Issue templates"}
        titleHeadingSize="small"
        explanation={`When an incident matches any of the the conditions below, we'll use the template
          below to create an issue in Jira.`}
        accessory={
          issueTrackerTemplates.length > 0 && (
            <AddTemplateButton
              issueTrackerTemplates={issueTrackerTemplates}
              onClose={() => refetch()}
            />
          )
        }
      />
      <TemplatesList
        issueTrackerTemplates={issueTrackerTemplates}
        branches={branches}
        refetch={refetchIssueTrackerSyncConfig}
      />
    </div>
  );
};

const TemplatesList = ({
  issueTrackerTemplates,
  branches,
  refetch,
}: {
  issueTrackerTemplates: LegacyIssueTemplate[];
  branches: ExpressionBranch[];
  refetch: () => void;
}): React.ReactElement => {
  const canEdit = useIdentity().hasScope(
    ScopeNameEnum.OrganisationSettingsUpdate,
  );

  const { trigger } = useAPIMutation(
    "integrationsJiraCloudGetConfig",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.issueTrackerIssueTemplatesDestroy({ id });
    },
  );

  const onDelete = async ({ id }: { id: string }) => {
    await trigger({ id });
    refetch();
  };

  const formMethods = useFormContext<FormData>();
  const elseBranch = formMethods.watch("issue_template_expression.else_branch");

  return (
    <>
      <StackedList>
        {issueTrackerTemplates.map((template, _) => {
          const templateInUse = isIssueTemplateReferencedInExpression(
            branches,
            elseBranch,
            template.id,
          );

          return (
            <div key={template.id}>
              <TemplateRow
                template={template}
                canDelete={
                  canEdit && !templateInUse && issueTrackerTemplates.length > 1
                }
                onDelete={onDelete}
                templateInUse={templateInUse}
                showWarning={branches.length !== 0 && !templateInUse}
              />
            </div>
          );
        })}
      </StackedList>
    </>
  );
};

const TemplateRow = ({
  template,
  canDelete,
  onDelete,
  templateInUse,
  showWarning,
}: {
  template: LegacyIssueTemplate;
  canDelete: boolean;
  onDelete: (req: { id: string }) => void;
  templateInUse: boolean;
  showWarning?: boolean;
}): React.ReactElement => {
  const refetch = useAPIRefetch("integrationsJiraCloudGetConfig", undefined);
  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);

  let deleteIsGatedText = "Cannot delete only template";
  if (templateInUse) {
    deleteIsGatedText = "Cannot delete a template that a condition references";
  }

  return (
    <>
      <SettingsListItem
        title={template.name}
        badgeNode={
          <TemplateRowBadge showWarning={showWarning} template={template} />
        }
        icon={IconEnum.Jira}
        buttons={{
          edit: {
            onEdit: () => setShowEditTemplateModal(true),
          },
          delete: {
            resourceTitle: template.name,
            onDelete: () => onDelete({ id: template.id }),
            isGatedText: canDelete ? undefined : deleteIsGatedText,
            deleteConfirmationTitle: "Delete incident issue template",
            deleteConfirmationContent: (
              <>
                Are you sure you wish to delete the{" "}
                <span className="font-bold">{template.name}</span> template?
              </>
            ),
          },
        }}
      />
      {showEditTemplateModal && (
        <CreateEditJiraIssueTemplateModal
          id={template.id}
          context={JiraIssueTemplateContextEnum.IncidentTicket}
          onClose={() => {
            setShowEditTemplateModal(false);
            refetch();
          }}
        />
      )}
    </>
  );
};

const TemplateRowBadge = ({
  template,
  showWarning,
}: {
  template: LegacyIssueTemplate;
  showWarning?: boolean;
}): React.ReactElement => {
  if (template.invalid_reason) {
    return (
      <Badge
        icon={IconEnum.Warning}
        theme={BadgeTheme.Error}
        className="ml-1.5"
      >
        Error
      </Badge>
    );
  }

  if (showWarning) {
    return (
      <Badge
        icon={IconEnum.Warning}
        theme={BadgeTheme.Warning}
        className="ml-1.5"
      >
        Unused
      </Badge>
    );
  }

  return <></>;
};

function isIssueTemplateReferencedInExpression(
  branches: ExpressionBranch[],
  elseBranch: ExpressionElseBranch | undefined,
  issueTemplateID: string,
) {
  // check if branches
  const existsInIfBranch = branches.some(
    (branch) => branch.result.value?.literal === issueTemplateID,
  );
  if (existsInIfBranch) {
    return true;
  }

  // check else branch
  const existsInElseBranch =
    elseBranch?.result.value?.literal === issueTemplateID;
  if (existsInElseBranch) {
    return true;
  }

  return false;
}
