/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogType,
    CatalogTypeFromJSON,
    CatalogTypeFromJSONTyped,
    CatalogTypeToJSON,
} from './CatalogType';

/**
 * 
 * @export
 * @interface AvailableIncidentFormEscalationElement
 */
export interface AvailableIncidentFormEscalationElement {
    /**
     * Whether this element can be hidden
     * @type {boolean}
     * @memberof AvailableIncidentFormEscalationElement
     */
    can_be_hidden: boolean;
    /**
     * Whether this element can be reordered by the user
     * @type {boolean}
     * @memberof AvailableIncidentFormEscalationElement
     */
    can_be_reordered: boolean;
    /**
     * Whether this element can have a default value
     * @type {boolean}
     * @memberof AvailableIncidentFormEscalationElement
     */
    can_have_default: boolean;
    /**
     * Whether this element can have description
     * @type {boolean}
     * @memberof AvailableIncidentFormEscalationElement
     */
    can_have_description: boolean;
    /**
     * 
     * @type {CatalogType}
     * @memberof AvailableIncidentFormEscalationElement
     */
    catalog_type?: CatalogType;
    /**
     * The type of element
     * @type {string}
     * @memberof AvailableIncidentFormEscalationElement
     */
    element_type: AvailableIncidentFormEscalationElementElementTypeEnum;
    /**
     * Is the engine resource an array?
     * @type {boolean}
     * @memberof AvailableIncidentFormEscalationElement
     */
    engine_resource_array: boolean;
    /**
     * The resource type that represents this element (so that we can configure default and fixed values)
     * @type {string}
     * @memberof AvailableIncidentFormEscalationElement
     */
    engine_resource_type: string;
    /**
     * The icon for this element
     * @type {string}
     * @memberof AvailableIncidentFormEscalationElement
     */
    icon: AvailableIncidentFormEscalationElementIconEnum;
    /**
     * The label of this element (e.g. the custom field name)
     * @type {string}
     * @memberof AvailableIncidentFormEscalationElement
     */
    label: string;
    /**
     * The rank of this element (for sorting)
     * @type {number}
     * @memberof AvailableIncidentFormEscalationElement
     */
    rank?: number;
}

/**
* @export
* @enum {string}
*/
export enum AvailableIncidentFormEscalationElementElementTypeEnum {
    User = 'user',
    Priority = 'priority',
    EscalationPolicy = 'escalation_policy',
    CatalogType = 'catalog_type'
}/**
* @export
* @enum {string}
*/
export enum AvailableIncidentFormEscalationElementIconEnum {
    Action = 'action',
    Alert = 'alert',
    Bolt = 'bolt',
    ToggleLeft = 'toggle-left',
    Book = 'book',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Checkmark = 'checkmark',
    Clipboard = 'clipboard',
    Clock = 'clock',
    Close = 'close',
    Cog = 'cog',
    Components = 'components',
    CustomField = 'custom_field',
    Database = 'database',
    Delete = 'delete',
    Doc = 'doc',
    Email = 'email',
    Escalate = 'escalate',
    EscalationPath = 'escalation-path',
    Exclamation = 'exclamation',
    Export = 'export',
    ExternalLink = 'external-link',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    FollowUps = 'follow_ups',
    GitBranchNew = 'git-branch-new',
    Globe = 'globe',
    Incident = 'incident',
    IncidentType = 'incident_type',
    Key = 'key',
    MsTeams = 'ms-teams',
    Merge = 'merge',
    Message = 'message',
    Money = 'money',
    Mug = 'mug',
    NumberInput = 'number-input',
    Priority = 'priority',
    Private = 'private',
    TextAlignLeft = 'text-align-left',
    Search = 'search',
    Server = 'server',
    Severity = 'severity',
    Slack = 'slack',
    SlackChannel = 'slack_channel',
    SlackTeam = 'slack_team',
    Star = 'star',
    Status = 'status',
    StatusPage = 'status-page',
    Store = 'store',
    Tag = 'tag',
    Test = 'test',
    Text = 'text',
    Timestamp = 'timestamp',
    Triage = 'triage',
    User = 'user',
    Users = 'users',
    Warning = 'warning'
}

export function AvailableIncidentFormEscalationElementFromJSON(json: any): AvailableIncidentFormEscalationElement {
    return AvailableIncidentFormEscalationElementFromJSONTyped(json, false);
}

export function AvailableIncidentFormEscalationElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableIncidentFormEscalationElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'can_be_hidden': json['can_be_hidden'],
        'can_be_reordered': json['can_be_reordered'],
        'can_have_default': json['can_have_default'],
        'can_have_description': json['can_have_description'],
        'catalog_type': !exists(json, 'catalog_type') ? undefined : CatalogTypeFromJSON(json['catalog_type']),
        'element_type': json['element_type'],
        'engine_resource_array': json['engine_resource_array'],
        'engine_resource_type': json['engine_resource_type'],
        'icon': json['icon'],
        'label': json['label'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
    };
}

export function AvailableIncidentFormEscalationElementToJSON(value?: AvailableIncidentFormEscalationElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_be_hidden': value.can_be_hidden,
        'can_be_reordered': value.can_be_reordered,
        'can_have_default': value.can_have_default,
        'can_have_description': value.can_have_description,
        'catalog_type': CatalogTypeToJSON(value.catalog_type),
        'element_type': value.element_type,
        'engine_resource_array': value.engine_resource_array,
        'engine_resource_type': value.engine_resource_type,
        'icon': value.icon,
        'label': value.label,
        'rank': value.rank,
    };
}

