import { Badge, BadgeSize, BadgeTheme } from "@incident-ui";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export const PlanBadge = ({
  planName,
  label,
  size = BadgeSize.Small,
  invert,
}: {
  planName: string;
  label?: string;
  size?: BadgeSize;
  invert?: boolean;
}): React.ReactElement => {
  return (
    <Badge
      theme={BadgeTheme.Unstyled}
      size={size}
      className={tcx(
        "rounded border",
        invert ? "border-white text-white" : "border-brand text-brand",
      )}
    >
      {label || planName.toUpperCase()}
    </Badge>
  );
};
