import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { formatLoginWithRedirectURL } from "@incident-shared/utils/loginWithRedirect";
import { Button, IconEnum, Link, Steps } from "@incident-ui";
import React from "react";
import { useIsAuthenticated } from "src/contexts/ClientContext";
import { OnboardingSetDesiredPlanRequestBodyPlanNameEnum as PlanNameEnum } from "src/contexts/ClientContext";
import { useQueryParams } from "src/utils/query-params";

import { SetupScreen } from "../SetupScreen";
import { InstallationStepID, useSetupSteps } from "../useSetupSteps";
import { ReactComponent as Logo } from "./logo.svg";

export const SetupMsTeamsLoginPage = (): React.ReactElement => {
  const steps = useSetupSteps();
  const isAuthenticated = useIsAuthenticated();
  if (isAuthenticated) {
    return <OrgAwareNavigate to={"/setup/msteams"} />;
  }

  return (
    <PageWrapper title="" noHeader width={PageWidth.Narrow}>
      <div className="h-auto xl:h-full flex flex-col items-center justify-center gap-8 py-16">
        <Steps
          steps={steps}
          currentStep={InstallationStepID.Login}
          containerClassName="max-w-xl w-full mb-8"
        />
        <SetupMsTeamsLogin />
      </div>
    </PageWrapper>
  );
};

// SetupLogin is the explanation and CTA for someone hitting the setup flow to
// log in (i.e. oauth using Slack/Teams to tell us who they are). If they log in, and
// we recognise that the org connected to that slack/microsoft team is setup already,
// we'll send them to the dashboard.
const SetupMsTeamsLogin = (): React.ReactElement => {
  const queryParams = useQueryParams();
  const queryPlan = queryParams.get("plan");
  const plan =
    queryPlan && Object.values(PlanNameEnum).includes(queryPlan as PlanNameEnum)
      ? (queryPlan as PlanNameEnum)
      : undefined;

  const loginURL = formatLoginWithRedirectURL({
    loginURL: "/auth/microsoft_login",
    returnPath: "/setup/msteams",
    plan,
  });

  return (
    <>
      <SetupScreen
        title={
          <span className="flex items-center space-x-1 justify-center -mt-4 text-2xl">
            <span>Get started with</span>
            <Logo className="mb-[0.85rem]" />
          </span>
        }
        footer={
          <div className="text-slate-700 text-sm text-center">
            By signing in with Microsoft, you are agreeing to our{" "}
            <Link href="https://incident.io/terms" analyticsTrackingId={null}>
              terms of service
            </Link>
            .
          </div>
        }
      >
        <>
          <div className="block space-y-2">
            <p>First, tell us who you are by signing in with Microsoft.</p>
            <p>This won&lsquo;t give us access to your Teams organisation.</p>
          </div>
          <div className="mt-6">
            <div className="flex justify-center">
              <hr className="w-full px-2 bg-white text-slate-200"></hr>
            </div>
            <div className="mt-7">
              {/* todo: styling*/}
              <Button
                analyticsTrackingId="login-with-micr"
                href={loginURL}
                className="inline-flex items-center cursor-pointer"
                icon={IconEnum.Microsoft}
              >
                Sign in with Microsoft
              </Button>
            </div>
          </div>
        </>
      </SetupScreen>
    </>
  );
};
