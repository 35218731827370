import {
  ScheduleExternalProviderEnum,
  SchedulesCreateFeedRequestBodyFeedTypeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { Gate } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import {
  SearchBar,
  SearchProvider,
  useSearchContext,
} from "@incident-ui/SearchBar/SearchBar";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { ScheduleImportModal } from "src/components/legacy/on-call/schedules/ScheduleImportModal";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { EmptyState } from "../EmptyState";
import { useOnCallEntityCount } from "../utils";
import { CalendarFeedModal } from "./CalendarFeedModal";
import EmptyStateImg from "./images/schedules-empty-state.png";
import { ScheduleCreateEditDrawer } from "./ScheduleCreateEditDrawer";
import { SchedulesList } from "./SchedulesList";

type ScheduleActionState =
  | {
      type: "create";
      id?: never;
    }
  | {
      type: "edit";
      id: string;
    }
  | {
      type: "import";
      // if you've chosen a schedule to import - show the drawer
      // otherwise, we'll show a modal to choose a schedule
      id?: string;
    }
  | {
      type: "duplicate";
      id: string;
    };

export const SchedulesListPage = () => {
  const {
    data: schedulesResp,
    isLoading: schedulesIsLoading,
    error: schedulesError,
  } = useAPI("schedulesList", undefined);

  const {
    data: entityCount,
    isLoading: entityCountLoading,
    error: entityCountError,
  } = useOnCallEntityCount();
  const [scheduleActionState, setScheduleActionState] = useState<
    ScheduleActionState | undefined
  >(undefined);

  const nativeSchedules = (schedulesResp?.schedules ?? []).filter(
    (s) => s.external_provider === ScheduleExternalProviderEnum.Native,
  );

  const [calendarFeedModalOpen, setCalendarFeedModalOpen] = useState(false);

  const { identity } = useIdentity();

  if (schedulesError || entityCountError) {
    return <GenericErrorMessage error={schedulesError || entityCountError} />;
  }

  if (schedulesIsLoading || entityCountLoading || !identity) {
    return <Loader />;
  }

  // If we've just deleted the last on-call entity, we want to send the user back to the get started page
  if (entityCount === 0) {
    return <OrgAwareNavigate to="/on-call/get-started" replace={true} />;
  }

  const numNativeSchedules =
    schedulesResp?.schedules.filter(
      (schedule) =>
        schedule.external_provider === ScheduleExternalProviderEnum.Native,
    ).length ?? Number.MAX_SAFE_INTEGER;

  const scheduleCreateUpgradeRequired = numericGateLimitReached(
    identity.feature_gates.on_call_schedules_count,
    numNativeSchedules,
  );

  const createScheduleGate: Gate = {
    type: "numeric",
    value: identity.feature_gates.on_call_schedules_count,
    featureNameSingular: "on call",
  };
  const schedulesGateFeatureName = `On-call schedules`;

  const importModal =
    scheduleActionState?.type === "import" && !scheduleActionState.id ? (
      <ScheduleImportModal
        setExternalScheduleImportId={(id) => {
          setScheduleActionState({ type: "import", id });
        }}
        onClose={() => setScheduleActionState(undefined)}
      />
    ) : null;

  const scheduleCreateEditDrawer =
    scheduleActionState?.type === "create" ||
    scheduleActionState?.type === "edit" ||
    scheduleActionState?.type === "duplicate" ||
    (scheduleActionState?.type === "import" && !!scheduleActionState?.id) ? (
      <ScheduleCreateEditDrawer
        onClose={() => {
          setScheduleActionState(undefined);
        }}
        editId={
          scheduleActionState?.type === "edit"
            ? scheduleActionState.id
            : undefined
        }
        externalScheduleImportId={
          scheduleActionState?.type === "import"
            ? scheduleActionState.id
            : undefined
        }
        duplicateId={
          scheduleActionState?.type === "duplicate"
            ? scheduleActionState.id
            : undefined
        }
      />
    ) : null;

  if (nativeSchedules.length === 0) {
    return (
      <>
        {importModal}
        <Helmet title={"Schedules - incident.io"} />
        <EmptyState
          copy="Ensure the right people are on call at all times with simple but powerful scheduling that supports even the most complex use cases."
          imageSrc={EmptyStateImg}
          title="No schedules created"
          buttons={[
            {
              children: "Create schedule",
              onClick: () => setScheduleActionState({ type: "create" }),
              analyticsTrackingId: "on-call-create-schedule",
              gatingProps: {
                upgradeRequired: scheduleCreateUpgradeRequired,
                upgradeRequiredProps: {
                  featureName: schedulesGateFeatureName,
                  gate: createScheduleGate,
                },
                requiredScope: ScopeNameEnum.SchedulesCreate,
              },
            },
            {
              children: "Import schedule",
              onClick: () => setScheduleActionState({ type: "import" }),
              analyticsTrackingId: "on-call-import-schedule",
              theme: ButtonTheme.Secondary,
              gatingProps: {
                upgradeRequired: scheduleCreateUpgradeRequired,
                upgradeRequiredProps: {
                  featureName: schedulesGateFeatureName,
                  gate: createScheduleGate,
                },
                requiredScope: ScopeNameEnum.SchedulesCreate,
              },
            },
          ]}
        />
        <AnimatePresence>{scheduleCreateEditDrawer}</AnimatePresence>
      </>
    );
  }

  return (
    <SearchProvider>
      <Helmet title={"Schedules - incident.io"} />
      <SchedulesListPageHeader
        toggleCalendarFeedModalOpen={() => {
          setCalendarFeedModalOpen(!calendarFeedModalOpen);
        }}
        setScheduleActionState={setScheduleActionState}
        scheduleCreateUpgradeRequired={scheduleCreateUpgradeRequired}
        createScheduleGate={createScheduleGate}
        schedulesGateFeatureName={schedulesGateFeatureName}
      />
      <div className={"pb-5"}>
        <SchedulesList
          onDuplicate={(id) => {
            setScheduleActionState({ type: "duplicate", id });
          }}
          onEdit={(id) => {
            setScheduleActionState({ type: "edit", id });
          }}
        />
      </div>
      {calendarFeedModalOpen && (
        <CalendarFeedModal
          feedType={SchedulesCreateFeedRequestBodyFeedTypeEnum.All}
          onCancel={() => setCalendarFeedModalOpen(false)}
        />
      )}
      <AnimatePresence>{scheduleCreateEditDrawer}</AnimatePresence>
      {importModal}
    </SearchProvider>
  );
};

const SchedulesListPageHeader = ({
  toggleCalendarFeedModalOpen,
  setScheduleActionState,
  scheduleCreateUpgradeRequired,
  createScheduleGate,
  schedulesGateFeatureName,
}: {
  toggleCalendarFeedModalOpen: () => void;
  setScheduleActionState: (s: ScheduleActionState) => void;
  scheduleCreateUpgradeRequired: boolean;
  createScheduleGate: Gate;
  schedulesGateFeatureName: string;
}) => {
  const searchBarProps = useSearchContext();

  return (
    <div className="flex gap-2">
      <SearchBar {...searchBarProps} placeholder="Search" autoFocus />
      <div className="grow" />
      <Button
        analyticsTrackingId={null}
        theme={ButtonTheme.Secondary}
        icon={IconEnum.Calendar}
        onClick={toggleCalendarFeedModalOpen}
      >
        <span>Sync calendar</span>
      </Button>
      <GatedButton
        upgradeRequired={scheduleCreateUpgradeRequired}
        upgradeRequiredProps={{
          featureName: schedulesGateFeatureName,
          gate: createScheduleGate,
        }}
        requiredScope={ScopeNameEnum.SchedulesCreate}
        analyticsTrackingId={null}
        theme={ButtonTheme.Secondary}
        icon={IconEnum.Copy}
        onClick={() => setScheduleActionState({ type: "import" })}
      >
        <span>Import schedule</span>
      </GatedButton>
      <GatedButton
        upgradeRequired={scheduleCreateUpgradeRequired}
        upgradeRequiredProps={{
          featureName: schedulesGateFeatureName,
          gate: createScheduleGate,
        }}
        analyticsTrackingId={null}
        theme={ButtonTheme.Secondary}
        icon={IconEnum.Add}
        requiredScope={ScopeNameEnum.SchedulesCreate}
        onClick={() => setScheduleActionState({ type: "create" })}
      >
        <span>New schedule</span>
      </GatedButton>
    </div>
  );
};
