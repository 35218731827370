import { CatalogTypeCategoriesEnum as CategoriesEnum } from "@incident-io/api";
import { OrgAwareLink, useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Card,
  IconEnum,
  LoadingModal,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";

import completedModalGraphic from "./completed-everything-modal.svg";
import servicesModalGraphic from "./set-up-services-modal.svg";
import { useHasTypeOfCategory } from "./useHasTypeOfCategory";

export const WizardCompletedModal = ({
  category,
}: {
  category: CategoriesEnum;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate(`/catalog?category=${category}`);

  const { hasMatchingType: hasServiceType, typesLoading: serviceLoading } =
    useHasTypeOfCategory(CategoriesEnum.Service);

  if (serviceLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  if (category === CategoriesEnum.Team && !hasServiceType) {
    return (
      <Modal
        isOpen={true}
        analyticsTrackingId={`catalog-go-to-services-wizard`}
        hideHeader
        onClose={onClose}
        title="Set up services in Catalog"
        isExtraLarge
      >
        <ModalContent className="!p-0 !m-0">
          <div className="flex items-center bg-green-100 px-32 py-20">
            <img className="w-full" src={servicesModalGraphic} />
          </div>
          <div className="flex flex-col gap-2 px-20 py-10 w-full">
            <div className="self-stretch text-center text-content-primary text-2xl-bold font-bold">
              Set up services in Catalog
            </div>
            <div className="self-stretch text-center text-slate-600 text-lg font-normal">
              Connect your services to route alerts to the right people and more
            </div>
          </div>
        </ModalContent>
        <ModalFooter hideBorder>
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Secondary}
            onClick={onClose}
          >
            Do it later
          </Button>
          <div className="w-4"></div>
          <Button
            analyticsTrackingId={null}
            onClick={onClose}
            theme={ButtonTheme.Primary}
            href="/catalog/service-wizard"
          >
            Set up services
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  const cards = [
    {
      icon: IconEnum.Workflows,
      title: "Workflows",
      description: "Set up workflows to power automations",
      href: "/workflows",
    },
    {
      icon: IconEnum.Alert,
      title: "Alerts",
      description: "Create alerts and route them to the right team",
      href: "/alerts",
    },
    {
      icon: IconEnum.Book,
      title: "Catalog",
      description: "Enrich Catalog with more data",
      href: "/catalog",
    },
  ];

  return (
    <Modal
      isOpen={true}
      analyticsTrackingId={`catalog-wizard-completed`}
      hideHeader
      onClose={onClose}
      title="You're ready to get going"
      isExtraLarge
    >
      <ModalContent className="!p-0 !m-0">
        <div className="flex items-center bg-red-100 px-32 py-20">
          <img className="w-full" src={completedModalGraphic} />
        </div>
        <div className="flex flex-col gap-8 p-6 pt-10 w-full">
          <div className="self-stretch text-center text text-content-primary text-2xl-bold font-bold">
            {`You're ready to get going`}
          </div>
          <div className="grid grid-cols-3 gap-3">
            {cards.map((card) => (
              <OrgAwareLink
                to={card.href}
                key={card.title}
                className="cursor-pointer"
              >
                <Card
                  color={ColorPaletteEnum.Red}
                  clickable
                  key={card.title}
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                />
              </OrgAwareLink>
            ))}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
