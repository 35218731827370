import { Modal, ModalContent, ModalFooter } from "@incident-ui/Modal";
import { ModalProps } from "@incident-ui/Modal/Modal";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { ChatBubble } from "./ChattyProductMarketingBanner";

type CSMChattyProductMarketingModalProps = {
  title: string;
  description: string;
  className?: string;
  cta: React.ReactNode;
  secondaryCta?: React.ReactNode;
  graphic: React.ReactNode;
} & Pick<ModalProps, "analyticsTrackingId" | "isOpen" | "onClose">;

export function CSMChattyProductMarketingModal({
  title,
  description,
  className,
  cta,
  secondaryCta,
  graphic,
  analyticsTrackingId,
  isOpen,
  onClose,
}: CSMChattyProductMarketingModalProps): React.ReactElement {
  const { data } = useAPI("settingsSupport", undefined);

  return (
    <Modal
      isOpen={isOpen}
      analyticsTrackingId={analyticsTrackingId}
      hideHeader
      onClose={onClose}
      title={title}
    >
      <ModalContent className="!p-0 !m-0 relative">
        <ChatBubble csm={data?.csm} className="absolute top-10 left-10" />
        <div className={tcx("flex items-center px-20 pt-28 pb-16", className)}>
          {graphic}
        </div>
        <div className="flex flex-col gap-2 px-10 py-10 w-full">
          <div className="self-stretch text-center text-content-primary text-2xl-bold">
            {title}
          </div>
          <div className="self-stretch text-center text-content-secondary text-base">
            {description}
          </div>
        </div>
      </ModalContent>
      <ModalFooter hideBorder>
        <div className="justify-end flex-row-reverse flex gap-4 items-center">
          {cta}
          {secondaryCta}
        </div>
      </ModalFooter>
    </Modal>
  );
}
