import { Button, ButtonTheme, IconEnum, IconSize } from "@incident-ui";
import { PopoverMultiSelect } from "@incident-ui";

export type TimelineItemFilterOption = {
  type: string;
  label: string;
};
type Props = {
  setFilters: (ids: string[]) => void;
  activeFilters: string[];
  allFilters: TimelineItemFilterOption[];
};
export function TimelineFilters({
  setFilters,
  activeFilters,
  allFilters,
}: Props): React.ReactElement {
  return (
    <PopoverMultiSelect
      object={false}
      triggerClassName="h-5"
      renderTriggerNode={({ onClick }) => (
        <Button
          onClick={onClick}
          theme={ButtonTheme.Naked}
          type="button"
          className="tabular-nums"
          analyticsTrackingId="timeline-filter"
          icon={IconEnum.Filter}
          iconProps={{ className: "!mr-0", size: IconSize.Medium }}
        >
          Filter {activeFilters.length > 0 && `(${activeFilters.length})`}
        </Button>
      )}
      popoverClassName="max-h-[250px] overflow-y-auto"
      options={allFilters.map((f) => ({
        label: f.label,
        value: f.type,
      }))}
      value={activeFilters}
      onChange={setFilters}
    />
  );
}
