import React from "react";
import graphic from "src/components/settings/banners/banner-security.svg";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";

import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { AuditLogsCard } from "./AuditLogsCard";
import { PrivateIncidentsForm } from "./PrivateIncidentsForm";
import { PrivateStreamsForm } from "./PrivateStreamsForm";
import { SamlSSOForm } from "./saml-scim/SamlSSOForm";
import { ScimRedirectCard } from "./saml-scim/ScimRedirectCard";
import { StoreIncidentChannelMessagesForm } from "./StoreIncidentChannelMessagesForm";
import { UserTokenForm } from "./user-token/UserTokenForm";

export const SecurityViewPage = (): React.ReactElement => {
  const commsPlatform = usePrimaryCommsPlatform();
  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        title="Control access and storage"
        subtitle="Manage Slack workspace access and data storage to enhance security."
        graphic={<img src={graphic} className="h-40" />}
      />
      <SamlSSOForm />
      <ScimRedirectCard />
      <AuditLogsCard />
      <UserTokenForm />
      {commsPlatform === CommsPlatform.Slack && (
        <>
          <PrivateIncidentsForm />
          <PrivateStreamsForm />
          <a id="store-incident-channel-messages" className="-mb-6"></a>
          <StoreIncidentChannelMessagesForm />
        </>
      )}
    </SettingsSubPageWrapper>
  );
};
