import {
  AIConfigEnabledFeaturesEnum as AIFeatureEnum,
  Incident,
  IncidentVisibilityEnum,
} from "@incident-io/api";
import { useIdentity } from "src/contexts/IdentityContext";
export { AIConfigEnabledFeaturesEnum as AIFeatureEnum } from "@incident-io/api";
import { useAPI } from "src/utils/swr";

// useAIFeatureForIncident is a hook that returns a function that can be used to determine if a
// particular AI feature is enabled for an incident.
export const useAIFeatureForIncident = (incident: Incident) => {
  const canUseForOrg = useAIFeatureForOrg();

  return (featureName: AIFeatureEnum) => {
    // Private incidents don't get AI!
    if (incident.visibility === IncidentVisibilityEnum.Private) {
      return false;
    }

    // Check whether the org has the feature enabled
    return canUseForOrg(featureName);
  };
};

// useAIFeatureForOrg is a hook that returns a function that can be used to determine if a
// particular AI feature is enabled for an organisation.
export const useAIFeatureForOrg = () => {
  const { identity } = useIdentity();
  const { data } = useAPI("aIShowConfig", undefined);
  const { data: assistantData } = useAPI("aIShowAIAssistant", undefined);

  return (featureName: AIFeatureEnum) => {
    // Assume no AI if we don't have the config.
    if (!data || !assistantData) {
      return false;
    }

    // Assume no AI if we don't have an identity.
    if (!identity) {
      return false;
    }

    // Check the flag for this feature, if applicable
    switch (featureName) {
      case AIFeatureEnum.Assistant:
        // If the Assistant feature gate is not enabled, then Assistant is not enabled.
        if (!identity.feature_gates.ai_assistant) {
          return false;
        }

        // If the organisation has the feature enabled, and the gate turned on, but doesn't actually
        // have an OpenAI Assistant, then we'll block access.
        if (!assistantData._exists) {
          return false;
        }

        break;
    }

    // Check whether this specific feature is enabled (i.e. toggled on in the settings).
    return (
      data.config.enabled && data.config.enabled_features.includes(featureName)
    );
  };
};
