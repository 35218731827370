import { ContentBox } from "@incident-ui";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export const TimelineItemContentBox = ({
  hidden,
  children,
  className,
}: {
  hidden?: boolean;
  children: React.ReactNode;
  className?: string;
}): React.ReactElement => {
  return (
    <ContentBox
      className={tcx(
        "p-4",
        { "!bg-surface-secondary !border-stroke": hidden },
        className,
      )}
      overrideBackground={hidden}
    >
      {children}
    </ContentBox>
  );
};
