import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button } from "@incident-ui/Button/Button";
import { ButtonTheme } from "@incident-ui/Button/Button";
import { ContentBox } from "@incident-ui/ContentBox/ContentBox";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type StepFooterConfig = {
  continueButtonText?: string;
  onContinue?: () => void;
  backButtonText?: string;
  onBack?: () => void;
  continueDisabled?: boolean;
  continueButtonType?: "button" | "reset" | "submit";
  continueExplanationText?: string;
};

export const StepsPageContent = ({
  children,
  className,
}: {
  children: React.ReactNodeArray | React.ReactNode;
  className?: string;
}): React.ReactElement => {
  return (
    <ContentBox className={tcx("p-8 my-6", className)}>{children}</ContentBox>
  );
};

export const StepsPageFooter = ({
  onContinue,
  onBack,
  continueDisabled = false,
  saving,
  genericError,
  backButtonText = "Back",
  continueButtonText = "Continue",
  continueButtonType = "button",
  continueExplanationText,
  className,
}: StepFooterConfig & {
  genericError?: string | null;
  saving?: boolean;
  className?: string;
}): React.ReactElement => {
  return (
    <ContentBox className={tcx("py-4 px-8", className)}>
      <div>
        <ErrorMessageUI message={genericError} className="mb-2" />
      </div>
      <div className="flex flex-between">
        <div>
          {onBack && (
            <Button
              analyticsTrackingId="continue-step"
              onClick={onBack}
              className="flex-center w-full"
            >
              {backButtonText}
            </Button>
          )}
        </div>
        <div>
          {(onContinue || continueButtonType === "submit") && (
            <GatedButton
              theme={ButtonTheme.Primary}
              analyticsTrackingId="continue-step"
              onClick={onContinue}
              className="flex-center w-full"
              loading={saving}
              disabled={continueDisabled}
              type={continueButtonType}
              disabledTooltipContent={continueExplanationText}
            >
              {continueButtonText}
            </GatedButton>
          )}
        </div>
      </div>
    </ContentBox>
  );
};
