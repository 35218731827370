/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimelineItem,
    TimelineItemFromJSON,
    TimelineItemFromJSONTyped,
    TimelineItemToJSON,
} from './TimelineItem';

/**
 * 
 * @export
 * @interface TimelineItemsCreateResponseBody
 */
export interface TimelineItemsCreateResponseBody {
    /**
     * 
     * @type {TimelineItem}
     * @memberof TimelineItemsCreateResponseBody
     */
    timeline_item: TimelineItem;
}

export function TimelineItemsCreateResponseBodyFromJSON(json: any): TimelineItemsCreateResponseBody {
    return TimelineItemsCreateResponseBodyFromJSONTyped(json, false);
}

export function TimelineItemsCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineItemsCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeline_item': TimelineItemFromJSON(json['timeline_item']),
    };
}

export function TimelineItemsCreateResponseBodyToJSON(value?: TimelineItemsCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeline_item': TimelineItemToJSON(value.timeline_item),
    };
}

