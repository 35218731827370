import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ReactComponent as DarkLogoBanner } from "@incident-shared/layout/logo-banner-dark.svg";
import radarCirclesBackgroundSmall from "@incident-shared/layout/radar-circles-background.png";
import radarCirclesBackground1 from "@incident-shared/layout/radar-circles-background@1x.png";
import radarCirclesBackground2 from "@incident-shared/layout/radar-circles-background@2x.png";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { formatLoginWithRedirectURL } from "@incident-shared/utils/loginWithRedirect";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  Loader,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
// eslint-disable-next-line no-restricted-imports
import { Navigate, useLocation } from "react-router";
import {
  AuthShowWorkOSAuthURIRequest,
  AuthShowWorkOSAuthURIResponseBody,
  useClient,
  useIsAuthenticated,
} from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import validator from "validator";

import styles from "./LoginRoute.module.scss";
import { ReactComponent as SlackLogo } from "./slack-logo.svg";

export type Location = {
  state?: {
    returnPath: string;
  };
};

export const LoginRoute = ({ slug }: { slug?: string }): React.ReactElement => {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const returnPath = location?.state?.returnPath || "/dashboard";
  const returnHost = location?.state?.returnHost || window.location.host;

  if (isAuthenticated) {
    return <OrgAwareNavigate to={"/dashboard"} replace />;
  }

  if (slug) {
    return (
      <OrgAwareLoginRoute
        returnPath={returnPath}
        returnHost={returnHost}
        organisationSlug={slug}
      />
    );
  }
  return (
    <AnonymousLoginRoute returnPath={returnPath} returnHost={returnHost} />
  );
};

export const OrgAwareLoginRoute = ({
  organisationSlug,
  returnPath,
  returnHost,
}: {
  organisationSlug: string;
  returnPath?: string;
  returnHost?: string;
}): React.ReactElement => {
  const {
    data: slugData,
    error: slugDataError,
    isLoading,
  } = useAPI("authShowPublicInformationForSlug", { slug: organisationSlug });

  if (slugDataError) {
    // bounce you to our top level login: perhaps the slug doesn't exist?
    return <Navigate to="/login" replace />;
  }

  if (isLoading || !slugData) {
    return <Loader />;
  }

  const canLoginWithoutSaml =
    !slugData.saml_enabled || slugData.saml_is_optional;

  return (
    <LoginBackground
      content={
        <div className="max-w-xl text-center p-4 lg:p-10 text-sm text-slate-700 !rounded-2 max-w-[400px] flex flex-col items-center bg-white border-stroke border shadow-sm z-10">
          <div className="h-18 mb-6 mx-auto">
            <DarkLogoBanner />
          </div>
          <div>
            <h4 className="text-center text-lg text-content-primary mb-2">
              Sign in to access{" "}
              <span className="font-bold truncate max-w-[320px] block">
                {slugData.organisation_name}
              </span>
            </h4>
            <div className="">
              <div className="mt-4 space-y-2">
                {slugData.saml_enabled && (
                  <SamlLogin
                    isExpanded
                    setIsExpanded={() => false}
                    returnPath={returnPath}
                    returnHost={returnHost}
                    showDivider={false}
                    slug={organisationSlug}
                  />
                )}
                {canLoginWithoutSaml &&
                  (slugData.microsoft_signin_enabled ? (
                    <MicrosoftLogin
                      organisationSlug={organisationSlug}
                      returnPath={returnPath}
                    />
                  ) : (
                    <SlackLogin
                      organisationSlug={organisationSlug}
                      returnPath={returnPath}
                      returnHost={returnHost}
                    />
                  ))}
              </div>
            </div>
          </div>
          <TermsOfServiceNotice />
        </div>
      }
    />
  );
};

const SlackLogin = ({
  organisationSlug,
  returnPath,
  returnHost,
}: {
  organisationSlug: string;
  returnPath?: string;
  returnHost?: string;
}) => {
  const loginURL = formatLoginWithRedirectURL({
    organisationSlug,
    returnPath,
    returnHost,
  });

  return (
    <Button
      analyticsTrackingId="login-with-slack"
      href={loginURL}
      className="inline-flex items-center cursor-pointer"
      data-testid="login-with-slack"
      theme={ButtonTheme.Unstyled}
      style={{ padding: 0 }}
    >
      <img
        alt="Sign in with Slack"
        height="40"
        width="172"
        src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
        srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
      />
    </Button>
  );
};

const MicrosoftLogin = ({
  organisationSlug,
  returnPath,
}: {
  organisationSlug: string;
  returnPath?: string;
}) => {
  const loginURL = formatLoginWithRedirectURL({
    loginURL: "/auth/microsoft_login",
    organisationSlug,
    returnPath,
  });

  return (
    <Button
      analyticsTrackingId="login-with-microsoft"
      href={loginURL}
      className="inline-flex items-center cursor-pointer"
      icon={IconEnum.Microsoft}
    >
      Sign in with Microsoft
    </Button>
  );
};

export const AnonymousLoginRoute = ({
  returnPath,
  returnHost,
}: {
  returnPath?: string;
  returnHost?: string;
}): React.ReactElement => {
  const params = useQueryParams();
  let samlErrorText = params.get("saml-err");
  if (samlErrorText && SAML_ERRORS[samlErrorText]) {
    // If it's in our list, we map it to something more human readable.
    samlErrorText = SAML_ERRORS[samlErrorText];
  }

  const queryReturnPath = params.get("return_path");
  if (returnPath === undefined && queryReturnPath) {
    returnPath = queryReturnPath;
  }
  const queryReturnHost = params.get("return_host");
  if (returnHost === undefined && queryReturnHost) {
    returnHost = queryReturnHost;
  }
  const [isSAMLExpanded, setIsSAMLExpanded] = useState(false);

  const loginURL = formatLoginWithRedirectURL({
    returnPath,
    returnHost,
  });

  return (
    <LoginBackground
      content={
        <motion.div
          key={`${isSAMLExpanded}`}
          layout="position"
          className="max-w-xl text-center p-4 lg:p-10 text-sm text-slate-700 !rounded-2 max-w-[400px] flex flex-col items-center bg-white border-stroke border shadow-sm z-10"
          initial={isSAMLExpanded ? "autoHeight" : ""}
          animate={isSAMLExpanded ? "autoHeight" : ""}
          transition={{ ease: "easeOut", layout: { duration: 0.25 } }}
          variants={{
            autoHeight: {
              height: "auto",
            },
          }}
        >
          <div className="h-18 mb-6 mx-auto">
            <DarkLogoBanner />
          </div>
          <div className="space-y-1 flex flex-col items-center w-[220px]">
            <Button
              analyticsTrackingId="login-with-slack"
              href={loginURL}
              className="inline-flex items-center cursor-pointer !px-8 !text-black"
            >
              <SlackLogo className="w-[20px] h-[20px] mr-[12px]" />
              Sign in with Slack
            </Button>
            <SamlLogin
              isExpanded={isSAMLExpanded}
              setIsExpanded={setIsSAMLExpanded}
              returnPath={returnPath}
              returnHost={returnHost}
              showDivider={true}
            />
          </div>
          {samlErrorText && (
            <Callout
              theme={CalloutTheme.Danger}
              showIcon={false}
              className={"mt-3"}
            >
              <div className="space-y-2 pb-1 text-red-500">
                <p>{samlErrorText}</p>
              </div>
            </Callout>
          )}
          <TermsOfServiceNotice />
        </motion.div>
      }
    ></LoginBackground>
  );
};

const SamlLogin = ({
  returnPath = "/dashboard",
  isExpanded,
  setIsExpanded,
  showDivider,
  slug: fixedSlug,
}: {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  returnPath?: string;
  returnHost?: string;
  showDivider: boolean;
  slug?: string;
}): React.ReactElement | null => {
  const apiClient = useClient();
  const formMethods = useForm<AuthShowWorkOSAuthURIRequest>({
    defaultValues: { returnPath, slug: fixedSlug },
  });
  const [needsOrgSlug, setNeedsOrgSlug] = useState(false);
  const slug = formMethods.watch("slug");
  const [isRedirecting, setIsRedirecting] = useState(false);

  const [onSubmit, { saving, genericError }] = useMutation<
    AuthShowWorkOSAuthURIRequest,
    AuthShowWorkOSAuthURIResponseBody
  >(
    async (data) => {
      return apiClient.authShowWorkOSAuthURI(data);
    },
    {
      setError: formMethods.setError,
      onError: (_, fieldErrors) => {
        if (
          fieldErrors &&
          fieldErrors[0].name === "slug" &&
          fieldErrors[0].code === "is_required"
        ) {
          formMethods.clearErrors();
          setNeedsOrgSlug(true);
        }
      },
      onSuccess: (resp) => {
        // Keep the spinner spinning while WorkOS/SAML provider loads
        setIsRedirecting(true);
        window.location.href = resp.workos_auth_uri;
      },
    },
  );

  return (
    <FormV2
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      outerClassName="w-full"
    >
      <AnimatePresence>
        {isExpanded ? (
          <motion.div
            id="samlLogin"
            key="samlLogin"
            initial={{ translateY: -100, opacity: 0, height: 0 }}
            animate={{ translateY: 0, opacity: 1, height: "auto" }}
            transition={{ duration: 0.25 }}
          >
            <div className="space-y-2">
              {showDivider ? (
                <div className="mt-2 border-t w-full border-stroke" />
              ) : undefined}
              {fixedSlug === undefined ? (
                <InputV2
                  formMethods={formMethods}
                  name="email"
                  type={InputType.Text}
                  rules={{
                    validate: (v) =>
                      validator.isEmail(v)
                        ? undefined
                        : "Please enter a valid email address",
                  }}
                  autoComplete="email"
                  placeholder={"me@mycompany.com"}
                  disabled={needsOrgSlug}
                  errorClassName="!text-xs !mt-2"
                />
              ) : null}
              {needsOrgSlug ? (
                <motion.div
                  id="samlLoginOrgInput"
                  key={`${needsOrgSlug}`}
                  initial={{ translateY: -50, opacity: 0, height: 0 }}
                  animate={{ translateY: 0, opacity: 1, height: "auto" }}
                  transition={{ duration: 0.25 }}
                >
                  <InputV2
                    formMethods={formMethods}
                    name="slug"
                    className="text-left"
                    type={InputType.Text}
                    required="Please provide an ID"
                    placeholder={"Enter organization ID"}
                    errorClassName="!text-xs !mt-2"
                  />
                  <div className="mt-3 !mb-4 text-content-tertiary text-left text-xs">
                    e.g. app.incident.io/
                    <span className="font-medium text-content-primary">
                      {slug ? slug : "my-company"}
                    </span>
                  </div>
                </motion.div>
              ) : undefined}
              <Button
                analyticsTrackingId="confirm-login-with-saml"
                theme={ButtonTheme.Primary}
                className={"w-full"}
                loading={saving || isRedirecting}
                type="submit"
              >
                Sign in with SAML SSO
              </Button>
            </div>
          </motion.div>
        ) : (
          <Button
            analyticsTrackingId="login-with-saml"
            theme={ButtonTheme.Naked}
            type={"button"}
            onClick={(e) => {
              e?.preventDefault();
              setIsExpanded(true);
            }}
            className="mt-2 !text-content-primary hover:!text-slate-600"
          >
            or sign in with SAML SSO →
          </Button>
        )}
      </AnimatePresence>
    </FormV2>
  );
};

const SAML_ERRORS: Record<string, string> = {
  "inactive-domain":
    "Your organisation has not finished setting up SAML, please contact your administrator.",
  "invalid-domain":
    "We don't have a matching email domain for your organisation, please contact your administrator.",
  "saml-failure":
    "SAML login failed, please try again or contact your administrator.",
};

// Renders the background gradient image, with the circles at the bottom left.
// `content` is the floating window in the middle of the screen.
const LoginBackground = ({ content }: { content: React.ReactNode }) => {
  const [loadedFullRes, setLoadedFullRes] = useState(false);
  return (
    <div
      className={tcx(
        "flex justify-center items-center px-4 h-full overflow-hidden",
        styles.loginBackground,
      )}
    >
      {content}
      <img
        src={radarCirclesBackgroundSmall}
        className={tcx(
          "bottom-[-20px] right-[-15vw] w-[64vw] min-w-[650px] opacity-40 fixed",
          loadedFullRes ? "hidden" : "visible",
        )}
      />
      <img
        src={radarCirclesBackgroundSmall}
        srcSet={`${radarCirclesBackground1} 900w, ${radarCirclesBackground2} 8000w`}
        sizes={"(max-width: 1400px) 900w, 8000w"}
        className={tcx(
          "bottom-[-20px] right-[-15vw] w-[64vw] min-w-[650px] opacity-40 fixed",
          loadedFullRes ? "visible" : "hidden",
        )}
        onLoad={() => setLoadedFullRes(true)}
      />
    </div>
  );
};

const TermsOfServiceNotice = () => {
  return (
    <div className="mt-3 text-content-tertiary text-center text-xs">
      By signing in, you are agreeing to our{" "}
      <a
        href="https://incident.io/terms"
        className="underline"
        rel="noopener noreferrer"
        target="_blank"
      >
        terms of service
      </a>
      .
    </div>
  );
};
