import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import _ from "lodash";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemSource } from "src/components/timeline/TimelineItemSource";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

export const TimelineItemEscalationCreated = (
  inc: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.escalation_created) {
    throw new Error(
      "malformed timeline item: missing escalation_created field",
    );
  }

  const escalatedTo = item.escalation_created.escalated_to_users?.length
    ? _.uniq(item.escalation_created.escalated_to_users.map((u) => u.name))
    : [];

  const escalationPath = item.escalation_created.escalation_path;

  return {
    icon: IconEnum.Escalate,
    description: (
      <>
        <strong>
          <TimelineItemSource actor={item.escalation_created.creator} />
        </strong>{" "}
        escalated this incident{" "}
        {escalatedTo.length > 0 ? `to ${escalatedTo.join(", ")}` : null}
        {escalationPath ? (
          <>
            {escalatedTo.length > 0
              ? "and escalation path "
              : "to the escalation path "}
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Naked}
              className="font-semibold"
              href={`/on-call/escalation-paths/${escalationPath.id}`}
            >
              {escalationPath.name}
            </Button>
          </>
        ) : null}
      </>
    ),
  };
};
