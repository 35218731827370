import { ContentBox, IconEnum, Link } from "@incident-ui";
import * as Sentry from "@sentry/react";
import React from "react";
import { Incident, TimelineItemComment } from "src/contexts/ClientContext";

import { TimelineItemUIInner } from "./TimelineItemUI";
// @TODO: Check the timeline export for the action create etc things that we are
// now returning.

const ErrorMessageUnknownExplanation = ({
  id,
  incident,
  comments,
}: {
  id: string;
  incident: Incident;
  comments?: TimelineItemComment[];
}): React.ReactElement => (
  <TimelineItemUIInner
    id={id}
    incident={incident}
    comments={comments}
    description="Uh-oh! We couldn't render this item"
    icon={IconEnum.Warning}
    isEditMode={false}
    isStatusUpdate={false}
    item={undefined}
  >
    <ContentBox className="p-4">
      {"We've already notified our engineers, and we'll get it fixed."}
    </ContentBox>
  </TimelineItemUIInner>
);

const ErrorMessageNotInChannelExplanation = ({
  id,
  incident,
  comments,
  timestamp,
  permalink,
}: {
  id: string;
  incident: Incident;
  comments: TimelineItemComment[];
  timestamp?: Date;
  permalink?: string;
}): React.ReactElement => (
  <TimelineItemUIInner
    id={id}
    incident={incident}
    comments={comments}
    description="Pinned message in archived channel"
    icon={IconEnum.Archive}
    timestamp={timestamp}
    isEditMode={false}
    isStatusUpdate={false}
  >
    <ContentBox className="p-4">
      Unarchive the incident Slack channel to see this message.
      {permalink && (
        <>
          {" "}
          You can still{" "}
          <Link openInNewTab href={permalink} analyticsTrackingId={null}>
            view the message in Slack
          </Link>
          .
        </>
      )}
    </ContentBox>
  </TimelineItemUIInner>
);

const ErrorMessageChannelPrivateOrDeletedMessage = ({
  id,
  incident,
  comments,
  timestamp,
  permalink,
}: {
  id: string;
  incident: Incident;
  comments: TimelineItemComment[];
  timestamp: Date;
  permalink?: string;
}): React.ReactElement => (
  <TimelineItemUIInner
    id={id}
    incident={incident}
    comments={comments}
    description="Pinned message in private channel"
    icon={IconEnum.LockClosed}
    timestamp={timestamp}
    isEditMode={false}
    isStatusUpdate={false}
  >
    <ContentBox className="p-4">
      {"For security reasons, we can't show the message content."}
      {permalink && (
        <>
          {" "}
          You may be able to{" "}
          <Link openInNewTab href={permalink} analyticsTrackingId={null}>
            view the message in Slack
          </Link>
          .
        </>
      )}
    </ContentBox>
  </TimelineItemUIInner>
);

export type TimelineItemEnrichmentErrorProps = {
  incident: Incident;
  itemID: string;
  comments: TimelineItemComment[];
  enrichment_error: string;
  occured_at: Date;
  permalink?: string;
  originatingError?: Error;
  componentStack?: string | null;
};

export const TimelineItemEnrichmentError = ({
  incident,
  itemID,
  comments,
  occured_at,
  enrichment_error,
  permalink,
  originatingError,
  componentStack,
}: TimelineItemEnrichmentErrorProps): React.ReactElement => {
  switch (enrichment_error) {
    case "not-in-channel":
      return (
        <ErrorMessageNotInChannelExplanation
          incident={incident}
          id={itemID}
          comments={comments}
          timestamp={occured_at}
          permalink={permalink}
        />
      );
    case "channel-is-private-or-deleted":
    case "thread-not-found":
      return (
        <ErrorMessageChannelPrivateOrDeletedMessage
          incident={incident}
          id={itemID}
          comments={comments}
          timestamp={occured_at}
          permalink={permalink}
        />
      );
    case "slack-pin-unknown":
      // We don't want to create loads of alert noise here, as we're logging the warning on the back end (which is much more useful!).
      // So we just return a generic error.
      return (
        <ErrorMessageUnknownExplanation
          incident={incident}
          id={itemID}
          comments={comments}
        />
      );
    case "unknown":
    default:
      // We're not handling this one nicely on the frontend yet. Someone has
      // probably added a new error code without adding its frontend equivalent.
      //
      // Report the error so we know to handle it, but for the customer, it's
      // the same as the unknown case - generic error message.
      Sentry.captureException(
        new Error(
          `Failed to render timeline enrichment error for code '${enrichment_error}' - have we written a renderer for it?`,
          { cause: originatingError },
        ),
        {
          extra: {
            timeline_item_id: itemID,
            enrichment_error_code: enrichment_error,
            component_stack: componentStack,
          },
        },
      );
      return (
        <ErrorMessageUnknownExplanation
          incident={incident}
          id={itemID}
          comments={comments}
        />
      );
  }
};
