import {
  Button,
  ButtonTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import { useIntercom } from "react-use-intercom";

import introducingBranches from "./introducing-branches-image.png";

export const SEEN_INTRODUCING_BRANCHES_MODAL_LOCAL_STORAGE_KEY =
  "seen-introducing-branches-modal";

export const IntroducingBranchesModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { showArticle } = useIntercom();

  // Set the local storage value to seen.
  window.localStorage.setItem(
    SEEN_INTRODUCING_BRANCHES_MODAL_LOCAL_STORAGE_KEY,
    "seen",
  );

  return (
    <Modal
      isOpen={true}
      analyticsTrackingId={`welcome-escalation-path-modal`}
      hideHeader
      onClose={onClose}
      title="Introducing branches"
      isExtraLarge
    >
      <ModalContent className="!p-0 !m-0">
        {/* Label */}
        <img className="w-full" src={introducingBranches} />

        <div className="flex">
          <div className="mx-20 my-10 flex-col justify-center items-center gap-6 inline-flex">
            <div className="self-stretch text-center text-gray-800 text-3xl font-bold font-['Inter'] leading-9">
              Introducing branches
            </div>
            <div className="self-stretch text-center text-slate-600 text-lg font-normal font-['Inter'] leading-7">
              You can now choose how and where to send escalations based on
              attributes like priority and working hours.
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter hideBorder>
        <Button
          analyticsTrackingId={null}
          onClick={() => {
            showArticle(9310668);
          }}
          theme={ButtonTheme.Secondary}
        >
          Read more
        </Button>
        <div className="w-4"></div>
        <Button
          analyticsTrackingId={null}
          onClick={onClose}
          theme={ButtonTheme.Primary}
        >
          Got it
        </Button>
      </ModalFooter>
    </Modal>
  );
};
