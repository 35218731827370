import { IconEnum } from "@incident-ui";
import React from "react";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemSource } from "src/components/timeline/TimelineItemSource";
import {
  Incident,
  SeverityUpdate,
  TimelineItemObject,
} from "src/contexts/ClientContext";

import { severityNameOrFallback } from "./TimelineItemIncidentUpdateComponent";

export const severityUpdateText = (
  severity_update: SeverityUpdate,
): React.ReactElement => {
  const source = <TimelineItemSource actor={severity_update.actor} />;

  return (
    <>
      {source}{" "}
      {isSeverityUpgrade(severity_update) ? "upgraded " : "downgraded "}
      the incident severity from{" "}
      <strong>{severityNameOrFallback(severity_update.from)}</strong> to{" "}
      <strong>{severity_update.to?.name}</strong>.
    </>
  );
};

export const TimelineItemSeverityUpdate = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.severity_update) {
    throw new Error(
      "malformed timeline item: severity_update was missing severity_update field",
    );
  }

  return {
    description: isSeverityUpgrade(item.severity_update)
      ? "Severity upgraded"
      : "Severity downgraded",
    icon: isSeverityUpgrade(item.severity_update)
      ? IconEnum.ArrowCircleUp
      : IconEnum.ArrowCircleDown,
    avatarUrl: item.severity_update.actor?.user?.avatar_url,
    children: <p>{severityUpdateText(item.severity_update)}</p>,
  };
};

function isSeverityUpgrade(severity_update: SeverityUpdate) {
  return (
    !severity_update.from ||
    (severity_update.to && severity_update.to.rank > severity_update.from.rank)
  );
}
