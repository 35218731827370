import { IconEnum, Markdown } from "@incident-ui";
import SentryLogo from "src/components/timeline/sentry_logo.png";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemContentBox } from "src/components/timeline/TimelineItemContentBox";
import styles from "src/components/timeline/TimelineItems.module.scss";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

export const TimelineItemSlackInferSentry = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.slack_infer_sentry) {
    throw new Error(
      "malformed timeline item: slack_infer_sentry was missing slack_infer_sentry field",
    );
  }

  return {
    avatarUrl: SentryLogo,
    secondaryAvatarUrl: item.slack_infer_sentry.user.avatar_url,
    icon: IconEnum.Link,
    description: (
      <>
        <strong>{item.slack_infer_sentry.user.name}</strong> mentioned a{" "}
        <strong>Sentry Link</strong>
      </>
    ),
    children: (
      <TimelineItemContentBox>
        <Markdown className={styles.contentBox}>
          {item.slack_infer_sentry.slack_message.text}
        </Markdown>
      </TimelineItemContentBox>
    ),
  };
};
