/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';

/**
 * 
 * @export
 * @interface EnabledSlackQuickAction
 */
export interface EnabledSlackQuickAction {
    /**
     * Type of quick action
     * @type {string}
     * @memberof EnabledSlackQuickAction
     */
    action_type: EnabledSlackQuickActionActionTypeEnum;
    /**
     * What conditions must be true for this quick action to be enabled in an incident channel?
     * @type {Array<ConditionGroup>}
     * @memberof EnabledSlackQuickAction
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * ID of the custom field when the quick action involves a custom field
     * @type {string}
     * @memberof EnabledSlackQuickAction
     */
    custom_field_id?: string;
    /**
     * URL to use when the quick action is of the custom type
     * @type {string}
     * @memberof EnabledSlackQuickAction
     */
    custom_url?: string;
    /**
     * Reason why the quick action may not always be shown for an incident
     * @type {string}
     * @memberof EnabledSlackQuickAction
     */
    eligibility_reason?: string;
    /**
     * Emoji on the quick action button
     * @type {string}
     * @memberof EnabledSlackQuickAction
     */
    emoji: string;
    /**
     * Unique identifier for this quick action
     * @type {string}
     * @memberof EnabledSlackQuickAction
     */
    id: string;
    /**
     * ID of the incident role when the quick action involves a role
     * @type {string}
     * @memberof EnabledSlackQuickAction
     */
    incident_role_id?: string;
    /**
     * Rank of this quick action, used for sorting
     * @type {number}
     * @memberof EnabledSlackQuickAction
     */
    rank: number;
    /**
     * Text on the quick action button
     * @type {string}
     * @memberof EnabledSlackQuickAction
     */
    text: string;
}

/**
* @export
* @enum {string}
*/
export enum EnabledSlackQuickActionActionTypeEnum {
    ViewRecap = 'quick_actions_view_recap',
    UpdateSummary = 'quick_actions_update_summary',
    ShareUpdate = 'quick_actions_share_update',
    ResolveIncident = 'quick_actions_resolve_incident',
    Escalate = 'quick_actions_escalate',
    TakeRole = 'quick_actions_take_role',
    JoinCall = 'quick_actions_join_call',
    UpdateStatusPage = 'quick_actions_update_status_page',
    SetCustomField = 'quick_actions_set_custom_field',
    SetFields = 'quick_actions_set_fields',
    ViewAllCommands = 'quick_actions_view_all_commands',
    CreateStream = 'quick_actions_create_stream',
    Custom = 'quick_actions_custom'
}

export function EnabledSlackQuickActionFromJSON(json: any): EnabledSlackQuickAction {
    return EnabledSlackQuickActionFromJSONTyped(json, false);
}

export function EnabledSlackQuickActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnabledSlackQuickAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action_type': json['action_type'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'custom_field_id': !exists(json, 'custom_field_id') ? undefined : json['custom_field_id'],
        'custom_url': !exists(json, 'custom_url') ? undefined : json['custom_url'],
        'eligibility_reason': !exists(json, 'eligibility_reason') ? undefined : json['eligibility_reason'],
        'emoji': json['emoji'],
        'id': json['id'],
        'incident_role_id': !exists(json, 'incident_role_id') ? undefined : json['incident_role_id'],
        'rank': json['rank'],
        'text': json['text'],
    };
}

export function EnabledSlackQuickActionToJSON(value?: EnabledSlackQuickAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action_type': value.action_type,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'custom_field_id': value.custom_field_id,
        'custom_url': value.custom_url,
        'eligibility_reason': value.eligibility_reason,
        'emoji': value.emoji,
        'id': value.id,
        'incident_role_id': value.incident_role_id,
        'rank': value.rank,
        'text': value.text,
    };
}

