import {
  LoadingBar,
  NotFoundError,
  UnexpectedError,
} from "@incident-io/status-page-ui";
import { captureException } from "@sentry/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useAPI } from "src/utils/swr";

import { IncidentHeader } from "./IncidentHeader/IncidentHeader";
import { IncidentUpdates } from "./IncidentUpdates";
import { KeyInformation } from "./KeyInformation/KeyInformation";
import { PageWrapper } from "./PageWrapper";

export const InternalStatusPageIncidentDetailsPage = (): React.ReactElement => {
  const { subpath, id: externalId } = useParams() as {
    subpath?: string;
    id: string;
  };

  return (
    <PageWrapper
      subpath={subpath}
      incidentExternalId={externalId as unknown as number}
    >
      <DetailsPageInner
        subpath={subpath ?? "default"}
        externalId={externalId as unknown as number}
      />
    </PageWrapper>
  );
};

const DetailsPageInner = ({
  subpath,
  externalId,
}: {
  subpath: string;
  externalId: number;
}) => {
  // Summary error is handled at the PageWrapper level
  const { data: summaryData } = useAPI("internalStatusPageContentShowPage", {
    subpath,
  });

  const { data: incidentData, error } = useAPI(
    "internalStatusPageContentShowIncident",
    {
      subpath,
      externalId,
    },
  );

  const analytics = useAnalytics();
  useEffect(() => {
    if (!summaryData || !analytics?.track) {
      return;
    }
    analytics.track("internal-status-page--incident-details-page-viewed", {
      incidentId: externalId,
      subpath,
      internalStatusPageId: summaryData?.summary.id,
    });
  }, [analytics, externalId, subpath, summaryData]);

  // Goa returns a 422 if the externalId is non-numeric, but that's also a
  // not-found-kinda-thing.
  if (error?.status === 404 || error?.status === 422) {
    return <NotFoundError />;
  }

  if (error) {
    console.error(error);
    captureException(error);
    return <UnexpectedError />;
  }

  if (!summaryData || !incidentData) {
    return <LoadingBar className="rounded-2 !h-32" />;
  }
  const { summary } = summaryData;
  const { incident } = incidentData;

  return (
    <>
      <Helmet title={`${incident.name} - ${summary.name}`} />

      <IncidentHeader incident={incident} subpath={summary.subpath} />
      <KeyInformation summary={summary} incident={incident} />
      <IncidentUpdates incident={incident} />
    </>
  );
};
