import { Alert, AlertSchema, CatalogResource } from "@incident-io/api";
import { isEmpty } from "@incident-shared/attribute";
import { DeprecatedTable, OrgAwareLink, Txt } from "@incident-ui";
import { AttributeEntries } from "src/components/@shared/attribute/AttributeEntries";

export const AttributesSection = ({
  alert,
  resources,
  schema,
}: {
  alert: Alert;
  resources: CatalogResource[];
  schema: AlertSchema;
}) => {
  const nonNullAttributes = schema.attributes.filter((attr) => {
    const setAttr = alert.attribute_values[attr.id];
    if (!setAttr) {
      return false;
    }
    return !isEmpty(setAttr);
  });
  const nonNullAttributesCount = nonNullAttributes.length;

  return (
    <div className={"space-y-2"}>
      <Txt className={"font-medium"}>Attributes</Txt>
      <DeprecatedTable className="p-3">
        <tbody>
          {/* Empty state */}
          {schema.attributes.length === 0 && (
            <tr>
              <td className="!py-2" colSpan={2}>
                <Txt lightGrey>
                  You haven&apos;t configured any attributes, configure them on
                  the{" "}
                  <OrgAwareLink
                    to="/alerts/attributes"
                    className="underline cursor-pointer font-medium"
                  >
                    Alert attributes
                  </OrgAwareLink>{" "}
                  page.
                </Txt>
              </td>
            </tr>
          )}

          {/* Attributes */}
          {nonNullAttributesCount === 0 ? (
            <tr>
              <td className="!py-2" colSpan={2}>
                <Txt lightGrey>No attributes have been set for this alert.</Txt>
              </td>
            </tr>
          ) : (
            nonNullAttributes.map((attr) => {
              return (
                <tr key={attr.id} className={"!border-0"}>
                  <td className="!py-2">
                    <Txt lightGrey className="max-w-[230px] truncate">
                      {attr.name}
                    </Txt>
                  </td>
                  <td className="w-full !py-2 break-anywhere">
                    <AttributeEntries
                      mode={"engine"}
                      typeName={attr.type}
                      catalogResources={resources}
                      attributeBinding={alert.attribute_values[attr.id]}
                    />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </DeprecatedTable>
    </div>
  );
};
