import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { OrgAwareLink, OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Avatar,
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  IconEnum,
  IconSize,
  Link,
  Loader,
  ModalFooter,
  Spinner,
  StackedList,
  Tooltip,
} from "@incident-ui";
import { DrawerBody } from "@incident-ui/Drawer/Drawer";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import {
  DebriefSettings,
  GoogleWorkspaceCalendar,
  GoogleWorkspaceCalendarAccessRoleEnum,
  GoogleWorkspaceUser,
  IntegrationSettingsProviderEnum,
  IntegrationsGoogleWorkspaceGetConfigResponseBody,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useDebriefName } from "src/utils/utils";
import { useDebounce } from "use-hooks";

import { SettingsListItem } from "../../../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSubHeading } from "../../../SettingsSubHeading";
import { IntegrationConnectedUser } from "../../common/IntegrationConnectedUser";

export const GoogleCalendarConfigureDrawer = ({
  connectionError,
}: {
  connectionError: React.ReactNode;
}): React.ReactElement | null => {
  const { data, isLoading } = useAPI(
    "integrationsGoogleWorkspaceGetCalendars",
    undefined,
  );

  const { data: configData, isLoading: configLoading } = useAPI(
    "integrationsGoogleWorkspaceGetConfig",
    undefined,
  );

  const { data: settingsResp, isLoading: loadingSettings } = useAPI(
    "debriefsShowSettings",
    undefined,
  );

  if (isLoading || !data || configLoading || loadingSettings || !settingsResp) {
    return <Loader />;
  }

  // Don't include the primary calendar
  const filteredCalendars = data.calendars.filter((calendar) => {
    return !calendar.is_primary;
  });

  return (
    <GoogleCalendarConfigureDrawerInner
      calendars={filteredCalendars}
      configData={configData}
      debriefSettings={settingsResp.settings}
      connectionError={connectionError}
    />
  );
};

const GoogleCalendarConfigureDrawerInner = ({
  calendars,
  configData,
  debriefSettings,
  connectionError,
}: {
  calendars: GoogleWorkspaceCalendar[];
  configData: IntegrationsGoogleWorkspaceGetConfigResponseBody | undefined;
  debriefSettings: DebriefSettings;
  connectionError: React.ReactNode;
}) => {
  const { hasScope } = useIdentity();

  const { trigger: addCalendar, isMutating: saving } = useAPIMutation(
    "integrationsGoogleWorkspaceGetCalendars",
    undefined,
    async (apiClient, payload: { calendar_id: string }) => {
      const calendarIDs = calendars
        .map((calendar) => calendar.calendar_id)
        .concat(payload.calendar_id);
      await apiClient.integrationsGoogleWorkspaceSetCalendars({
        setCalendarsRequestBody: {
          calendars: calendarIDs.map((_calendarID) => ({
            calendar_id: _calendarID,
          })),
        },
      });
    },
  );

  const { trigger: deleteCalendar } = useAPIMutation(
    "integrationsGoogleWorkspaceGetCalendars",
    undefined,
    async (apiClient, payload: { calendar_id: string }) => {
      const filteredCalendars = calendars.filter(
        (calendar) => calendar.calendar_id !== payload.calendar_id,
      );
      await apiClient.integrationsGoogleWorkspaceSetCalendars({
        setCalendarsRequestBody: {
          calendars: filteredCalendars.map(({ calendar_id }) => ({
            calendar_id,
          })),
        },
      });
    },
  );

  const canDelete = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const canDeleteCalendarText = (
    calendarID: string,
  ): React.ReactElement | string | undefined => {
    if (!canDelete) {
      return "You don't have permission to do this";
    } else if (calendarID) {
      if (
        debriefSettings.should_use_calendar_id &&
        debriefSettings.calendar_id &&
        calendarID === debriefSettings.calendar_id
      ) {
        return (
          <>
            This calendar is currently used in your{" "}
            <OrgAwareLink to="/settings/debriefs" className="underline">
              debrief settings
            </OrgAwareLink>{" "}
            so cannot be deleted.
          </>
        );
      }
    }
    return undefined;
  };

  const [showAddCalendarModal, setShowAddCalendarModal] =
    useState<boolean>(false);

  const invalidCalendarCount = calendars.filter(
    (calendar) => calendar.invalid_reason,
  ).length;

  if (!configData) {
    return (
      <OrgAwareNavigate to={"/settings/integrations/google_calendar"} replace />
    );
  }

  const user = configData.config.connecting_user;

  return (
    <DrawerBody>
      {connectionError}
      <IntegrationConnectedUser
        {...user}
        provider={IntegrationSettingsProviderEnum.GoogleCalendar}
      />
      <div>
        <SettingsSubHeading
          title={"Automatic event attachment"}
          explanationClassName="mr-4 mt-1 max-w-4xl"
          explanation={
            user && (
              <>
                <span>
                  We&apos;ll automatically attach events which invite the
                  connected user. As long as the incident identifier (e.g.
                  INC-123) is in the title or description, we&apos;ll find the
                  correct incident to link it to.
                </span>
              </>
            )
          }
        />
      </div>
      <SettingsSubHeading
        title="Subscribed calendars"
        explanation={`We can alternatively detect events which are created in a shared calendar, even if they don't invite the connected user.`}
        className="-pb-4"
        explanationClassName="mr-4 mt-1 max-w-4xl"
        accessory={
          <AddCalendarButton onClick={() => setShowAddCalendarModal(true)} />
        }
      />
      <div className="pb-4">
        {invalidCalendarCount > 0 && (
          <div className="mb-4">
            <Callout theme={CalloutTheme.Danger}>
              We&apos;re having trouble connecting to one of your calendars, so
              we might not be able to detect some of your events. Please try
              removing the calendar and re-adding it.
            </Callout>
          </div>
        )}
        <StackedList>
          {calendars.map((calendar) => {
            const calendarLabel = calendar.name ?? calendar.calendar_id;

            return (
              <SettingsListItem
                key={calendar.id}
                title={calendarLabel}
                badgeNode={
                  <CalendarRowBadge calendar={calendar}></CalendarRowBadge>
                }
                buttons={{
                  requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
                  delete: {
                    resourceTitle: calendarLabel,
                    isGatedText: canDeleteCalendarText(calendar.calendar_id),
                    deleteConfirmationTitle:
                      "Remove subscription to Google Calendar",
                    deleteConfirmationContent: (
                      <span className="text-sm">
                        Are you sure you want to stop subscribing to{" "}
                        <span className="font-semibold">{calendarLabel}</span>?
                      </span>
                    ),
                    onDelete: () =>
                      deleteCalendar({ calendar_id: calendar.calendar_id }),
                  },
                }}
              />
            );
          })}
        </StackedList>
        {calendars.length === 0 && (
          <EmptyState
            icon={IconEnum.Calendar}
            content="You are not subscribed to any shared calendars."
            cta={
              <AddCalendarButton
                onClick={() => setShowAddCalendarModal(true)}
              />
            }
          />
        )}
      </div>
      {showAddCalendarModal && (
        <AddCalendarModal
          onClose={() => setShowAddCalendarModal(false)}
          addCalendar={(newCalendarID) =>
            addCalendar({ calendar_id: newCalendarID }).then(() => undefined)
          }
          connectedUserEmail={user?.email}
          saving={saving}
        />
      )}
    </DrawerBody>
  );
};

const CalendarRowBadge = ({
  calendar,
}: {
  calendar: GoogleWorkspaceCalendar;
}) => {
  const { debriefNameLower } = useDebriefName();
  const { showArticle } = useIntercom();

  if (calendar.invalid_reason) {
    return (
      <Tooltip
        content={
          "The calendar could not be found, or the connected user does not have permission to see it" // TODO: use the actual error when (if ever) we have more detail
        }
      >
        <Badge
          icon={IconEnum.Warning}
          theme={BadgeTheme.Error}
          className="ml-1.5"
        >
          Error
        </Badge>
      </Tooltip>
    );
  }

  if (
    calendar.access_role === GoogleWorkspaceCalendarAccessRoleEnum.Owner ||
    calendar.access_role === GoogleWorkspaceCalendarAccessRoleEnum.Writer
  ) {
    return (
      <Tooltip
        content={`The connected user is able to read and write to this calendar, so we can detect events in this calendar and schedule a ${debriefNameLower} via placeholders.`}
      >
        <Badge icon={IconEnum.Edit} theme={BadgeTheme.Info}>
          Read & write access
        </Badge>
      </Tooltip>
    );
  } else if (
    calendar.access_role === GoogleWorkspaceCalendarAccessRoleEnum.Reader
  ) {
    return (
      <Tooltip
        content={`The connected user is able to read from this calendar, so we can detect events in this calendar but won't be able to schedule a ${debriefNameLower} via placeholders.`}
      >
        <Badge icon={IconEnum.Book} theme={BadgeTheme.Info}>
          Read access
        </Badge>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        content={
          <>
            <span>
              The connected user does not have permission to read events in this
              calendar, so we won&apos;t be able to detect events.{" "}
            </span>
            <Button
              onClick={() => showArticle(8344655)}
              theme={ButtonTheme.Link}
              analyticsTrackingId={"settings-learn-more"}
            >
              Learn more
            </Button>
          </>
        }
      >
        <Badge icon={IconEnum.Warning} theme={BadgeTheme.Error}>
          No read access
        </Badge>
      </Tooltip>
    );
  }
};

const AddCalendarButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <GatedButton
      onClick={onClick}
      requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
      analyticsTrackingId="subscribe-to-google-calendar"
      icon={IconEnum.Add}
      theme={ButtonTheme.Secondary}
    >
      <span className="font-semibold">Add calendar</span>
    </GatedButton>
  );
};

export const GoogleConnectedUser = ({
  user,
}: {
  user: GoogleWorkspaceUser;
}): React.ReactElement => {
  return (
    <>
      <Avatar
        size={IconSize.Medium}
        url={user.avatar_url}
        name={user.name || user.email}
        className="mx-1 inline"
      />
      {user.name ? (
        <span className="font-semibold">
          {user.name} ({user.email})
        </span>
      ) : (
        <span className="font-semibold">{user.email}</span>
      )}
    </>
  );
};

const AddCalendarModal = ({
  addCalendar,
  connectedUserEmail,
  saving,
  onClose,
}: {
  addCalendar: (newCalendarID: string) => Promise<void>;
  connectedUserEmail?: string;
  saving: boolean;
  onClose: () => void;
}) => {
  const formMethods = useForm<{ calendarID: string }>({
    reValidateMode: "onChange",
  });
  const isValid = !get(formMethods.formState.errors, "calendarID");

  const _rawCalendarID = formMethods.watch("calendarID");
  const calendarID = useDebounce(formMethods.watch("calendarID"), 1000);

  const { data, isLoading } = useAPI(
    !!calendarID && calendarID !== ""
      ? "integrationsGoogleWorkspaceGetCalendarInfo"
      : null,
    {
      getCalendarInfoRequestBody: {
        calendar_id: calendarID,
      },
    },
    {
      onSuccess: () => {
        if (calendarID === "primary") {
          formMethods.setError(
            "calendarID",
            {
              message: `Cannot subscribe to the primary calendar ID of the connected user`,
              type: "validate",
            },
            {
              shouldFocus: true,
            },
          );
        } else if (calendarID === connectedUserEmail) {
          formMethods.setError(
            "calendarID",
            {
              message: `Cannot subscribe to the calendar ID of the connected user`,
              type: "validate",
            },
            {
              shouldFocus: true,
            },
          );
        } else {
          formMethods.clearErrors();
        }
      },
      onError: () => {
        formMethods.setError(
          "calendarID",
          {
            message: `Cannot find Google Calendar with ID: "${calendarID}"`,
            type: "validate",
          },
          {
            shouldFocus: true,
          },
        );
      },
    },
  );

  // We'll additionally set an error message once we've got the calendar info and can
  // check the access role.
  if (data && !hasReadAccess(data.calendar.access_role)) {
    formMethods.setError(
      "calendarID",
      {
        message: `The connected user does not have access to read events in this calendar`,
        type: "validate",
      },
      {
        shouldFocus: true,
      },
    );
  }

  // Whenever the calendarID changes, clear any previous errors immediately.
  useEffect(() => {
    formMethods.clearErrors();
  }, [_rawCalendarID, formMethods]);

  const onSubmit = () => {
    addCalendar(calendarID).then(() => {
      formMethods.reset();
      formMethods.resetField("calendarID");
      formMethods.setValue<"calendarID">("calendarID", "");
      onClose();
    });
  };

  const { showArticle } = useIntercom();

  return (
    <FormModalV2
      formMethods={formMethods}
      onClose={onClose}
      title={"Add calendar"}
      analyticsTrackingId={"add-google-calendar"}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Submit"
          cancelButtonText="Cancel"
          disabled={!isValid}
        />
      }
    >
      <div className="text-sm space-y-2">
        <ol className="space-y-2">
          <li>
            1. Head to{" "}
            <Link analyticsTrackingId={null} to="https://calendar.google.com">
              Google Calendar
            </Link>{" "}
          </li>
          <li>
            2. Under &quot;My calendars&quot; or &quot;Other calendars&quot; on
            the left sidebar, select &quot;Settings and Sharing&quot; for the
            calendar you&apos;d like to subscribe
          </li>
          <li>
            3. Copy the Calendar ID from the &quot;Integrate Calendar&quot;
            section
          </li>
          <li>
            4. Paste the Calendar ID below - we will warn you if we cannot find
            it
          </li>{" "}
        </ol>
        <div className="pt-5">
          Still unsure? See our{" "}
          <Button
            className="text-left"
            analyticsTrackingId={null}
            theme={ButtonTheme.Link}
            onClick={() => showArticle(8146184)}
          >
            help article
          </Button>{" "}
          for further instructions.
        </div>
      </div>
      <div className="space-y-2">
        <InputV2
          name="calendarID"
          formMethods={formMethods}
          placeholder="Enter calendar ID"
          disabled={saving}
          suffixNode={isLoading && <Spinner className="shrink" />}
        />
        {_rawCalendarID && // using raw calendar id to make sure we unmount the help text as soon as we clear the form
          data?.calendar && // If we got nothing back we'll be showing an error
          isValid && ( // Check for our custom validation - otherwise the help text shows up regardless
            <div className="text-xs text-slate-700">
              We&apos;ll subscribe to{" "}
              <span className="font-semibold">{data.calendar.name}</span>.
            </div>
          )}
      </div>
    </FormModalV2>
  );
};

function hasReadAccess(access_role: GoogleWorkspaceCalendarAccessRoleEnum) {
  return (
    access_role === GoogleWorkspaceCalendarAccessRoleEnum.Reader ||
    access_role === GoogleWorkspaceCalendarAccessRoleEnum.Writer ||
    access_role === GoogleWorkspaceCalendarAccessRoleEnum.Owner
  );
}
