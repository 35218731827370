import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { SharedIncidentFormData } from "@incident-shared/incident-forms";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Incident } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { SeverityElementSelect } from "../../../@shared/incident-forms/SeverityElementSelect";

type FormData = Pick<SharedIncidentFormData, "severity_id">;

export const UpdateSeverityModal = ({
  onClose,
  incident,
}: {
  onClose: () => void;
  incident: Incident;
}) => {
  const formMethods = useForm<FormData>({
    defaultValues: incident.severity && {
      severity_id: incident.severity?.id,
    },
  });

  const { trigger: onSubmit, isMutating } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, formData: FormData) => {
      await apiClient.incidentsCreateUpdate({
        incidentId: incident.id,
        createUpdateRequestBody: {
          to_severity_id: formData.severity_id,
        },
      });
    },
    {
      onSuccess: onClose,
      setError: formMethods.setError,
    },
  );

  return (
    <FormModalV2
      title="Update severity"
      analyticsTrackingId={"update-incident-severity-modal"}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonText="Save"
          saving={isMutating}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      <SeverityElementSelect
        formMethods={formMethods}
        name="severity_id"
        incidentType={incident.incident_type}
        required={true}
      />
    </FormModalV2>
  );
};
