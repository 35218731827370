import { CatalogEntry, CatalogTypeCategoriesEnum } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Checkbox,
  GenericErrorMessage,
  IconSize,
  LoadingBar,
  SearchBar,
  StackedList,
} from "@incident-ui";
import { Searcher, sortKind } from "fast-fuzzy";
import { useState } from "react";
import { Navigate } from "react-router";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useIsCatalogTypeReady } from "src/hooks/useIsCatalogTypeReady";
import { useQueryParams } from "src/utils/query-params";
import { useAPI, useAPIInfinite, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { WizardLayout } from "../WizardLayout";

const title = "Select your teams";
const subtitle =
  "Catalog lets you connect and manage everything that exists in your organization — from customers, to teams, software services, payment providers, and everything in between.";

export const TeamWizardChooseTeamsStep = () => {
  const { integrations } = useIntegrations();

  // Max page size for the list entries endpoint
  const maxPageSize = 250;
  const queryParams = useQueryParams();

  // Should only hit this if you've been messing around with your query string
  const resourceType = queryParams.get("resource_type") || "";
  const teamType = useIsCatalogTypeReady(resourceType);

  const {
    data: resourcesResponse,
    isLoading: resourcesLoading,
    error: resourcesError,
  } = useAPI("catalogListTeamSourceOfTruthResources", undefined);
  const resource = resourcesResponse?.resources.find(
    (r) => r.resource_type === resourceType,
  );

  // Eager load all catalog entries
  const {
    responses: listData,
    error: entriesError,
    isFullyLoaded: entriesFullyLoaded,
  } = useAPIInfinite(
    teamType ? "catalogListEntries" : null,
    {
      catalogTypeId: teamType?.id || "",
      includeReferences: false,
      pageSize: maxPageSize,
    },
    { eagerLoad: true },
  );

  const loading = resourcesLoading || !entriesFullyLoaded;

  if (!loading && !resource) {
    // The resource type in the URL params is missing or invalid
    return <Navigate to="/catalog/team-wizard" />;
  }

  if (resource && integrations) {
    // Check that required integration has been installed
    const integration = integrations.find(
      (i) => i.provider === resource.required_integration,
    );
    if (integration && !integration.installed) {
      return <Navigate to="/catalog/team-wizard/choose-source-of-truth" />;
    }
  }

  if (resourcesError || entriesError) {
    return <GenericErrorMessage error={resourcesError || entriesError} />;
  }

  if (loading || !resource || !teamType) {
    return (
      <WizardLayout
        category={CatalogTypeCategoriesEnum.Team}
        title={title}
        subtitle={subtitle}
        stepID="choose-teams"
        footer={
          <BootstrapButton
            teamTypeId={teamType?.id || ""}
            selectedIDs={[]}
            disabled={true}
          />
        }
      >
        <LoadingTeamsStackedList />
      </WizardLayout>
    );
  }

  return (
    <TeamWizardChooseTeamsStepLoaded
      teamTypeID={teamType.id}
      defaultSelectAll={resource.most_entries_are_teams}
      allEntries={listData.flatMap((d) => d.catalog_entries)}
    />
  );
};

export const LoadingTeamsStackedList = () => {
  return (
    <div className="flex flex-col gap-6">
      <LoadingSearchBar />
      <StackedList>
        <LoadingStackedListItem loadingBarClassName="w-[105px]" />
        <LoadingStackedListItem loadingBarClassName="w-[92px]" />
        <LoadingStackedListItem loadingBarClassName="w-[170px]" />
        <LoadingStackedListItem loadingBarClassName="w-[105px]" />
        <LoadingStackedListItem loadingBarClassName="w-[95px]" />
      </StackedList>
    </div>
  );
};

interface TeamWizardChooseTeamsStepLoadedProps {
  teamTypeID: string;
  defaultSelectAll: boolean;
  allEntries: CatalogEntry[];
}

export const TeamWizardChooseTeamsStepLoaded = ({
  teamTypeID,
  defaultSelectAll,
  allEntries,
}: TeamWizardChooseTeamsStepLoadedProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const searcher = new Searcher(allEntries, {
    keySelector: (entry) => entry.name,
    threshold: 0.8,
    sortBy: sortKind.insertOrder,
  });
  const entries = searchTerm !== "" ? searcher.search(searchTerm) : allEntries;

  const [allSelected, setAllSelected] = useState<boolean>(defaultSelectAll);
  const [selectedIDs, setSelectedIDs] = useState<string[]>(
    defaultSelectAll ? allEntries.map((e) => e.id) : [],
  );

  if (!allSelected && selectedIDs.length === allEntries.length) {
    // If we manually select all entries, select 'select all'
    setAllSelected(true);
  }

  const select = (id: string) => {
    return () => {
      setSelectedIDs([...selectedIDs, id]);
    };
  };
  const deselect = (id: string) => {
    return () => {
      if (allSelected) {
        // No longer are all entries selected
        setAllSelected(false);
      }
      setSelectedIDs(selectedIDs.filter((sid) => sid !== id));
    };
  };
  const selectAll = () => {
    setAllSelected(true);
    setSelectedIDs(entries.map((entry) => entry.id));
  };
  const deselectAll = () => {
    setAllSelected(false);
    setSelectedIDs([]);
  };

  return (
    <WizardLayout
      category={CatalogTypeCategoriesEnum.Team}
      title={title}
      subtitle={subtitle}
      stepID="choose-teams"
      backHref="/catalog/team-wizard/choose-source-of-truth"
      footer={
        <div className="flex gap-2">
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Secondary}
            loading={false}
            href="/catalog"
          >
            Exit
          </Button>
          <BootstrapButton
            teamTypeId={teamTypeID}
            selectedIDs={selectedIDs}
            disabled={selectedIDs.length === 0 && !allSelected}
          />
        </div>
      }
    >
      <div className="flex flex-col gap-6">
        <SearchBar
          id="search_teams"
          value={searchTerm}
          onChange={setSearchTerm}
          className="w-full"
          inputClassName="bg-white shadow-sm"
          placeholder={`Search ${allEntries.length} teams`}
          autoFocus={true}
          iconProps={{ size: IconSize.Medium }}
        />
        <StackedList>
          <ChooseTeamsStackedListItem
            id="select_all"
            content="Select all"
            checked={allSelected}
            onChange={allSelected ? deselectAll : selectAll}
          />
          {entries.map((entry) => {
            const isSelected = selectedIDs.includes(entry.id);
            const onChange = isSelected ? deselect(entry.id) : select(entry.id);

            return (
              <ChooseTeamsStackedListItem
                key={entry.id}
                id={entry.id}
                content={entry.name}
                checked={isSelected}
                onChange={onChange}
              />
            );
          })}
        </StackedList>
      </div>
    </WizardLayout>
  );
};

interface BootstrapButtonProps {
  teamTypeId: string;
  selectedIDs: string[];
  disabled?: boolean;
}

const BootstrapButton = ({
  teamTypeId,
  selectedIDs,
  disabled,
}: BootstrapButtonProps) => {
  const navigate = useOrgAwareNavigate();

  const {
    trigger: bootstrapTeamType,
    isMutating,
    genericError,
  } = useAPIMutation("catalogListTypes", {}, async (apiClient) => {
    const createTypeResponse = await apiClient.catalogBootstrapTeamType({
      bootstrapTeamTypeRequestBody: {
        catalog_type_id: teamTypeId || "",
        catalog_entry_ids: selectedIDs,
      },
    });
    navigate(
      `/catalog/team-wizard/${createTypeResponse.catalog_type.id}/add-attributes`,
    );
  });

  if (genericError) {
    throw Error("Unable to create team type");
  }

  return (
    <Button
      analyticsTrackingId={null}
      theme={ButtonTheme.Primary}
      loading={isMutating}
      onClick={() => bootstrapTeamType({})}
      disabled={disabled}
    >
      Save and continue
    </Button>
  );
};

interface ChooseTeamsStackedListItemProps {
  id: string;
  content: string;
  checked: boolean;
  onChange: () => void;
}

const ChooseTeamsStackedListItem = ({
  id,
  content,
  checked,
  onChange,
}: ChooseTeamsStackedListItemProps) => (
  // Using a label with htmlFor makes the whole row clickable, instead of just the checkbox.
  <label htmlFor={id} className="flex items-center text-sm-bold gap-3 p-4">
    <Checkbox
      id={id}
      checked={checked}
      className="!cursor-pointer"
      onChange={onChange}
    />
    {content}
  </label>
);

const LoadingSearchBar = () => (
  <StackedList>
    <label className="flex flex-row items-center text-sm-bold gap-3 p-4 h-[40px]">
      <LoadingBar className="h-4 w-4" />
      <LoadingBar className="h-4 w-1/3" />
    </label>
  </StackedList>
);

const LoadingStackedListItem = ({
  loadingBarClassName,
}: {
  loadingBarClassName?: string;
}) => (
  <label className="flex flex-row items-center text-sm-bold gap-3 p-4">
    <LoadingBar className="h-4 w-4" />
    <LoadingBar className={tcx("h-4 w-1/3", loadingBarClassName)} />
  </label>
);
