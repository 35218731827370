import { EngineFormElement } from "@incident-shared/engine";
import { SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import { SettingsSection } from "src/components/settings/SettingsSection";
import {
  EngineParamBinding,
  ScopeNameEnum,
  Settings,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAllResources } from "src/hooks/useResources";
import { useSettings } from "src/hooks/useSettings";
import { useAPIMutation } from "src/utils/swr";

type FormState = {
  enabled: boolean;
  error_notifications_channel?: EngineParamBinding;
};

export const AutomationErrorNotificationChannelEditForm =
  (): React.ReactElement => {
    const { settings } = useSettings();

    if (!settings) {
      return <></>;
    }

    return (
      <AutomationErrorNotificationChannelEditFormInner settings={settings} />
    );
  };

const AutomationErrorNotificationChannelEditFormInner = ({
  settings,
}: {
  settings: Settings;
}): React.ReactElement => {
  const showToast = useToast();
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (resourcesError) {
    throw resourcesError;
  }

  const formMethods = useForm<FormState>({
    defaultValues: {
      error_notifications_channel: {
        value: settings.misc.error_notifications_channel,
      },
      enabled: settings.misc.error_notifications_channel != null,
    },
  });

  const mutation = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data: FormState) => {
      return await apiClient.settingsUpdateErrorNotificationsChannel({
        updateErrorNotificationsChannelRequestBody: {
          error_notifications_channel: data.enabled
            ? data.error_notifications_channel?.value?.literal
            : undefined,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: async ({ settings }) => {
        formMethods.reset({
          error_notifications_channel: {
            value: settings.misc.error_notifications_channel,
          },
          enabled: settings.misc.error_notifications_channel?.literal != null,
        });
        showToast(SharedToasts.SETTINGS_SAVED);
      },
    },
  );

  if (resourcesLoading) {
    return <> </>;
  }

  return (
    <>
      <SettingsSection
        formMethods={formMethods}
        mutation={mutation}
        enabledPath="enabled"
        title="Notify a Slack channel when we encounter an error"
        explanation={`When we encounter an error (e.g. a workflow fails to run, or an integration breaks) we'll notify this Slack channel to keep everyone in the loop.`}
      >
        <EngineFormElement
          name="error_notifications_channel"
          resourceType="SlackChannel"
          array={false}
          resources={resources}
          disabled={!canEditSettings}
          required={false}
          mode="plain_input"
        />
      </SettingsSection>
    </>
  );
};
