import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import {
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import { useForm } from "react-hook-form";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { IncidentsBulkUpdateModeRequestBodyModeEnum } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

export const BulkMarkAsTestForm = ({
  incidentIDs,
  onClose,
  onSubmit,
}: {
  incidentIDs: string[];
  onClose: () => void;
  onSubmit: () => void;
}): React.ReactElement => {
  const { handleSubmit } = useForm();
  const analytics = useAnalytics();
  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const {
    trigger: submit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsList",
    {},
    async (apiClient, _) => {
      analytics?.track("bulkActionApplied", {
        action: "mark as test",
        numIncidents: incidentIDs.length,
      });

      const { result } = await apiClient.incidentsBulkUpdateMode({
        bulkUpdateModeRequestBody: {
          incident_ids: incidentIDs,
          mode: IncidentsBulkUpdateModeRequestBodyModeEnum.Test,
        },
      });

      const errors = result.filter(({ error }) => error);

      if (errors.length > 0) {
        console.error(errors);
        return Promise.reject(errors);
      } else {
        onSubmit();
      }
      return Promise.resolve();
    },
    {
      onSuccess: refreshIncidentList,
    },
  );

  return (
    <Modal
      analyticsTrackingId={"bulk-mark-as-test-modal"}
      title="Mark Incidents as Test Incident"
      as="form"
      isOpen
      onClose={onClose}
      onSubmit={handleSubmit(submit)}
    >
      <ModalContent>
        <div className="mb-4">
          Marking {incidentIDs.length}{" "}
          {incidentIDs.length <= 1
            ? "incident as a test incident"
            : "incidents as test incidents"}
          , are you sure?
        </div>
        <Callout theme={CalloutTheme.Warning}>
          By marking this as a test incident, it will no longer appear on the
          incidents page or in CSV exports. Workflows will no longer run on the
          incident, and we will delete the relevant announcement posts.
        </Callout>
        {genericError && <ErrorMessageUI message={genericError} />}
      </ModalContent>
      <ModalFooter
        hideBorder
        confirmButtonText={"Confirm"}
        saving={saving}
        onClose={onClose}
        confirmButtonType="submit"
      />
    </Modal>
  );
};
