import { Loader } from "@incident-ui";
import React from "react";
import { Route, Routes } from "react-router";

import { useIdentity } from "../../contexts/IdentityContext";
import { CatalogEntryListPageRoute } from "./entry-list/CatalogEntryListPage";
import { CatalogTypeDependentResourcesPage } from "./entry-list/CatalogTypeDependentResourcesPage";
import { CatalogTypeEditPage } from "./entry-list/CatalogTypeEditPage";
import { ManageInGithubRoute } from "./manage-in-github/ManageInGithubRoute";
import { CatalogTypeCreatePage } from "./type-list/CatalogTypeCreatePage";
import { CatalogTypeListPage } from "./type-list/CatalogTypeListPage";
import { ServiceWizardRoutes } from "./wizards/service-wizard/ServiceWizardRoutes";
import { TeamWizardRoutes } from "./wizards/team-wizard/TeamWizardRoutes";

export const CatalogRoute = (): React.ReactElement => {
  const { identity } = useIdentity();

  // Really important to wait for loading to finish before proceeding, to ensure
  // the URL parameters are set correctly.
  if (!identity) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route path="" element={<CatalogTypeListPage />} />
      <Route path="create" element={<CatalogTypeCreatePage />} />
      <Route path=":id/edit" element={<CatalogTypeEditPage />} />
      <Route path=":id/*" element={<CatalogEntryListPageRoute />} />
      <Route
        path=":id/dependent_resources"
        element={<CatalogTypeDependentResourcesPage />}
      />
      <Route path="manage-in-github/*" element={<ManageInGithubRoute />} />
      <Route path="team-wizard/*" element={<TeamWizardRoutes />} />
      <Route path="service-wizard/*" element={<ServiceWizardRoutes />} />
    </Routes>
  );
};
