import { differenceInCalendarDays } from "date-fns";
import { useMemo } from "react";
import {
  TrialStatusPlanNameEnum,
  TrialStatusTrialStatusEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

export type TrialData = {
  trialStatus: TrialStatusTrialStatusEnum;
  planName: TrialStatusPlanNameEnum;
  trialEnd?: Date;
  daysLeft?: number;
};

export const useTrialData = (): TrialData | null => {
  const id = useIdentity();

  const now = useMemo(() => new Date(), []);

  if (!id.identity?.trial_status) {
    return null;
  }

  const data: TrialData = {
    trialStatus: id.identity.trial_status.trial_status,
    planName: id.identity.trial_status.plan_name,
    trialEnd: id.identity.trial_status.end_at,
  };

  if (id.identity.trial_status.end_at) {
    data.daysLeft = differenceInCalendarDays(
      id.identity.trial_status.end_at,
      now,
    );
  }

  return data;
};
