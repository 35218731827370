import {
  DurationInput,
  DurationInputProps,
} from "@incident-ui/DurationInput/DurationInput";
import { FieldValues, Path, useController } from "react-hook-form";

import { FormInputWrapperV2 } from "../FormInputWrapperV2";
import { InputElementProps, parseProps } from "../formsv2";

export const DurationInputV2 = <TFormType extends FieldValues>(
  props: InputElementProps<
    TFormType,
    Omit<DurationInputProps, "initialValue">
  > & {
    onBlurCallback?: () => void;
  },
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps(rest);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapperV2<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <DurationInput
        {...field}
        {...inputProps}
        onChange={(val: number) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapperV2>
  );
};
