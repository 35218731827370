import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { Avatar } from "@incident-ui/Avatar/Avatar";
import { SlackTeamAvatar } from "@incident-ui/Avatar/SlackTeamAvatar";
import { Badge, BadgeSize, BadgeTheme } from "@incident-ui/Badge/Badge";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { SelectOption } from "@incident-ui/Select/types";
import React from "react";
import {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
  MultiValueProps,
  NoticeProps,
  OptionProps,
  SelectComponentsConfig,
  SingleValueProps,
} from "react-select";
import { tcx } from "src/utils/tailwind-classes";

// This file is for all the components that we customise in React-Select to make it look and feel
// consistent with our UI.

function CustomMultiValue<IsMulti extends boolean>({
  data,
  optionIcon,
  optionColor,
  ...props
}: MultiValueProps<SelectOption, IsMulti, GroupBase<SelectOption>> & {
  optionIcon?: IconEnum;
  optionColor?: ColorPaletteEnum;
}): React.ReactElement {
  // Only show the optionIcon if we also have an optionColor, as it looks a bit naff
  // on the primary badge.
  const icon = data.unavailable ? IconEnum.Warning : optionIcon;
  const showIcon = !!optionColor;

  const palette = getColorPalette(optionColor);
  return (
    <Badge
      theme={BadgeTheme.Primary}
      size={BadgeSize.Small}
      className={tcx(
        props.isDisabled ? "!bg-surface-tertiary !text-slate-600" : "",
        optionColor && palette.background,
        optionColor && palette.text,
        data.unavailable
          ? "!text-alarmalade-600 !bg-red-200 !border-red-200"
          : "",
        "max-w-full",
      )}
      closeButtonClassName={props.isDisabled ? "!bg-surface-tertiary" : ""}
      icon={showIcon || data.unavailable ? icon : undefined}
      // @ts-expect-error this is fine
      onClose={props.removeProps.onClick}
    >
      <div className="max-w-full truncate">{data.label}</div>
    </Badge>
  );
}

function CustomClearIndicator<IsMulti extends boolean>(
  props: ClearIndicatorProps<SelectOption, IsMulti, GroupBase<SelectOption>>,
): React.ReactElement {
  return (
    <button onClick={props.clearValue}>
      <Icon
        id={IconEnum.CloseCircle}
        size={IconSize.Small}
        className="text-content-tertiary hover:text-content-secondary"
      />
    </button>
  );
}

function CustomDropdownIndicator<IsMulti extends boolean>(
  _: DropdownIndicatorProps<SelectOption, IsMulti, GroupBase<SelectOption>>,
): React.ReactElement {
  return (
    <Icon
      id={IconEnum.ChevronDown}
      className="text-content-tertiary group-hover:text-content-secondary transition-colors"
    />
  );
}

const OptionIcon = ({
  imageUrl,
  isImageSlackIcon,
  icon,
  iconComponent,
  label,
  color,
}: {
  imageUrl?: string;
  label?: string;
  isImageSlackIcon?: boolean;
  icon?: IconEnum;
  iconComponent?: React.ReactNode;
  color?: ColorPaletteEnum;
}): React.ReactElement | null => {
  if (imageUrl) {
    if (isImageSlackIcon) {
      return (
        <SlackTeamAvatar size={IconSize.Small} name={label} url={imageUrl} />
      );
    }

    return <Avatar size={IconSize.Small} name={label} url={imageUrl} />;
  }

  if (icon) {
    let className = "";
    if (color) {
      const palette = getColorPalette(color);
      className = palette.icon;
    }
    return <Icon id={icon} size={IconSize.Small} className={className} />;
  }
  if (iconComponent) {
    return <>{iconComponent}</>;
  }

  return null;
};

function customOptionWithIcon<IsMulti extends boolean>({
  children,
  ...props
}: OptionProps<
  SelectOption,
  IsMulti,
  GroupBase<SelectOption>
>): React.ReactElement {
  return (
    <components.Option {...props}>
      {props.data.renderFn ? (
        // @ts-expect-error this is the same type, I can't make ts believe me without threading IsMulti through in a nasty way
        props.data.renderFn(props)
      ) : (
        <>
          <OptionIcon
            imageUrl={props.data.image_url}
            icon={props.data.icon}
            color={props.data.color}
            iconComponent={props.data.iconComponent}
            isImageSlackIcon={props.data.is_image_slack_icon}
          />
          {children}
        </>
      )}
    </components.Option>
  );
}

function CustomNoOptionsMessage<IsMulti extends boolean>(
  props: NoticeProps<SelectOption, IsMulti, GroupBase<SelectOption>>,
): React.ReactElement {
  return (
    <components.NoOptionsMessage
      {...props}
      className={"text-slate-700 text-sm"}
    />
  );
}

function CustomSingleValueWithIcon<IsMulti extends boolean>({
  icon,
  optionIcon,
  optionColor,
}: {
  icon?: IconEnum;
  optionIcon?: IconEnum;
  optionColor?: ColorPaletteEnum;
}) {
  return function CustomSingleValue({
    children,
    ...props
  }: SingleValueProps<
    SelectOption,
    IsMulti,
    GroupBase<SelectOption>
  >): React.ReactElement {
    icon = icon || props.data.icon;

    return (
      <components.SingleValue
        {...props}
        className="flex items-center text-content-primary"
      >
        {props.data.renderFn ? (
          // @ts-expect-error this is the same type, I can't make ts believe me without threading IsMulti through in a nasty way
          props.data.renderFn(props)
        ) : (
          <CustomSelectedValue
            {...props.data}
            icon={icon || optionIcon}
            color={props.data.color || optionColor}
          >
            {children}
          </CustomSelectedValue>
        )}
      </components.SingleValue>
    );
  };
}

const CustomSelectedValue = ({
  children,
  unavailable,
  iconComponent,
  image_url,
  icon,
  color,
  is_image_slack_icon,
  label,
}: SelectOption<string> & {
  children: React.ReactNode;
}): React.ReactElement => {
  if (unavailable) {
    icon = IconEnum.Warning;
    color = ColorPaletteEnum.Red;
  }

  if (icon && color) {
    const palette = getColorPalette(color);
    return (
      <Badge
        theme={BadgeTheme.Primary}
        size={BadgeSize.Small}
        className={tcx(
          palette.background,
          palette.text,
          unavailable ? "!text-alarmalade-600 !bg-red-200 !border-red-200" : "",
        )}
        icon={icon}
      >
        {children}
      </Badge>
    );
  }

  return (
    <div className="flex items-center gap-1">
      <OptionIcon
        imageUrl={image_url}
        icon={icon}
        color={color}
        label={label}
        iconComponent={iconComponent}
        isImageSlackIcon={is_image_slack_icon}
      />
      <div>{children}</div>
    </div>
  );
};

export function customComponents<IsMulti extends boolean>({
  icon,
  optionIcon,
  optionColor,
}: {
  icon?: IconEnum;
  optionIcon?: IconEnum;
  optionColor?: ColorPaletteEnum;
}): SelectComponentsConfig<SelectOption, IsMulti, GroupBase<SelectOption>> {
  return {
    MultiValue: (props) =>
      CustomMultiValue<IsMulti>({ ...props, optionIcon, optionColor }),
    ClearIndicator: (props) => CustomClearIndicator<IsMulti>(props),
    DropdownIndicator: (props) => CustomDropdownIndicator<IsMulti>(props),
    SingleValue: CustomSingleValueWithIcon<IsMulti>({
      icon,
      optionIcon,
      optionColor,
    }),
    Option: customOptionWithIcon,
    NoOptionsMessage: (props) => CustomNoOptionsMessage<IsMulti>(props),
  };
}
