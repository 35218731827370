/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OnboardingSetDesiredPlanRequestBody,
    OnboardingSetDesiredPlanRequestBodyFromJSON,
    OnboardingSetDesiredPlanRequestBodyToJSON,
    OnboardingSubmitInformationFormRequestBody,
    OnboardingSubmitInformationFormRequestBodyFromJSON,
    OnboardingSubmitInformationFormRequestBodyToJSON,
} from '../models';

export interface OnboardingSetDesiredPlanRequest {
    setDesiredPlanRequestBody: OnboardingSetDesiredPlanRequestBody;
}

export interface OnboardingSubmitInformationFormRequest {
    submitInformationFormRequestBody: OnboardingSubmitInformationFormRequestBody;
}

/**
 * 
 */
export class OnboardingApi extends runtime.BaseAPI {

    /**
     * Dismisses the \'buy team\' banner for an organisation
     * DismissBuyPlanBanner Onboarding
     */
    async onboardingDismissBuyPlanBannerRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/onboarding/dismiss_buy_plan_banner`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Dismisses the \'buy team\' banner for an organisation
     * DismissBuyPlanBanner Onboarding
     */
    async onboardingDismissBuyPlanBanner(initOverrides?: RequestInit): Promise<void> {
        await this.onboardingDismissBuyPlanBannerRaw(initOverrides);
    }

    /**
     * Requests early access to our On-call product
     * RequestOnCall Onboarding
     */
    async onboardingRequestOnCallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/onboarding/request_on_call`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Requests early access to our On-call product
     * RequestOnCall Onboarding
     */
    async onboardingRequestOnCall(initOverrides?: RequestInit): Promise<void> {
        await this.onboardingRequestOnCallRaw(initOverrides);
    }

    /**
     * Set the desired plan for the organisation
     * SetDesiredPlan Onboarding
     */
    async onboardingSetDesiredPlanRaw(requestParameters: OnboardingSetDesiredPlanRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setDesiredPlanRequestBody === null || requestParameters.setDesiredPlanRequestBody === undefined) {
            throw new runtime.RequiredError('setDesiredPlanRequestBody','Required parameter requestParameters.setDesiredPlanRequestBody was null or undefined when calling onboardingSetDesiredPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/onboarding/desired_plan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardingSetDesiredPlanRequestBodyToJSON(requestParameters.setDesiredPlanRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the desired plan for the organisation
     * SetDesiredPlan Onboarding
     */
    async onboardingSetDesiredPlan(requestParameters: OnboardingSetDesiredPlanRequest, initOverrides?: RequestInit): Promise<void> {
        await this.onboardingSetDesiredPlanRaw(requestParameters, initOverrides);
    }

    /**
     * Save info about the new organisation
     * SubmitInformationForm Onboarding
     */
    async onboardingSubmitInformationFormRaw(requestParameters: OnboardingSubmitInformationFormRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submitInformationFormRequestBody === null || requestParameters.submitInformationFormRequestBody === undefined) {
            throw new runtime.RequiredError('submitInformationFormRequestBody','Required parameter requestParameters.submitInformationFormRequestBody was null or undefined when calling onboardingSubmitInformationForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/onboarding/information`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardingSubmitInformationFormRequestBodyToJSON(requestParameters.submitInformationFormRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save info about the new organisation
     * SubmitInformationForm Onboarding
     */
    async onboardingSubmitInformationForm(requestParameters: OnboardingSubmitInformationFormRequest, initOverrides?: RequestInit): Promise<void> {
        await this.onboardingSubmitInformationFormRaw(requestParameters, initOverrides);
    }

}
