/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LegacyIncidentTriggersBuildScopeResponseBody,
    LegacyIncidentTriggersBuildScopeResponseBodyFromJSON,
    LegacyIncidentTriggersBuildScopeResponseBodyToJSON,
    LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBody,
    LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBodyFromJSON,
    LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBodyToJSON,
    LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceResponseBody,
    LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceResponseBodyFromJSON,
    LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceResponseBodyToJSON,
    LegacyIncidentTriggersExampleResourceResponseBody,
    LegacyIncidentTriggersExampleResourceResponseBodyFromJSON,
    LegacyIncidentTriggersExampleResourceResponseBodyToJSON,
    LegacyIncidentTriggersGetConfigResponseBody,
    LegacyIncidentTriggersGetConfigResponseBodyFromJSON,
    LegacyIncidentTriggersGetConfigResponseBodyToJSON,
    LegacyIncidentTriggersUpdateConfigRequestBody,
    LegacyIncidentTriggersUpdateConfigRequestBodyFromJSON,
    LegacyIncidentTriggersUpdateConfigRequestBodyToJSON,
    LegacyIncidentTriggersUpdateConfigResponseBody,
    LegacyIncidentTriggersUpdateConfigResponseBodyFromJSON,
    LegacyIncidentTriggersUpdateConfigResponseBodyToJSON,
} from '../models';

export interface LegacyIncidentTriggersBuildScopeRequest {
    externalResourceType: LegacyIncidentTriggersBuildScopeExternalResourceTypeEnum;
}

export interface LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequest {
    evaluateIncidentTemplateForExampleResourceRequestBody: LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBody;
}

export interface LegacyIncidentTriggersExampleResourceRequest {
    externalResourceType: LegacyIncidentTriggersExampleResourceExternalResourceTypeEnum;
}

export interface LegacyIncidentTriggersGetConfigRequest {
    externalResourceType: LegacyIncidentTriggersGetConfigExternalResourceTypeEnum;
}

export interface LegacyIncidentTriggersUpdateConfigRequest {
    externalResourceType: LegacyIncidentTriggersUpdateConfigExternalResourceTypeEnum;
    updateConfigRequestBody: LegacyIncidentTriggersUpdateConfigRequestBody;
}

/**
 * 
 */
export class LegacyIncidentTriggersApi extends runtime.BaseAPI {

    /**
     * Build a scope, expanding root-level references into their child references
     * BuildScope Legacy Incident Triggers
     */
    async legacyIncidentTriggersBuildScopeRaw(requestParameters: LegacyIncidentTriggersBuildScopeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LegacyIncidentTriggersBuildScopeResponseBody>> {
        if (requestParameters.externalResourceType === null || requestParameters.externalResourceType === undefined) {
            throw new runtime.RequiredError('externalResourceType','Required parameter requestParameters.externalResourceType was null or undefined when calling legacyIncidentTriggersBuildScope.');
        }

        const queryParameters: any = {};

        if (requestParameters.externalResourceType !== undefined) {
            queryParameters['external_resource_type'] = requestParameters.externalResourceType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/legacy_incident_triggers/scopes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LegacyIncidentTriggersBuildScopeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Build a scope, expanding root-level references into their child references
     * BuildScope Legacy Incident Triggers
     */
    async legacyIncidentTriggersBuildScope(requestParameters: LegacyIncidentTriggersBuildScopeRequest, initOverrides?: RequestInit): Promise<LegacyIncidentTriggersBuildScopeResponseBody> {
        const response = await this.legacyIncidentTriggersBuildScopeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns incident details for an example resource
     * EvaluateIncidentTemplateForExampleResource Legacy Incident Triggers
     */
    async legacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRaw(requestParameters: LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceResponseBody>> {
        if (requestParameters.evaluateIncidentTemplateForExampleResourceRequestBody === null || requestParameters.evaluateIncidentTemplateForExampleResourceRequestBody === undefined) {
            throw new runtime.RequiredError('evaluateIncidentTemplateForExampleResourceRequestBody','Required parameter requestParameters.evaluateIncidentTemplateForExampleResourceRequestBody was null or undefined when calling legacyIncidentTriggersEvaluateIncidentTemplateForExampleResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/legacy_incident_triggers/evaluate_incident_trigger`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBodyToJSON(requestParameters.evaluateIncidentTemplateForExampleResourceRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceResponseBodyFromJSON(jsonValue));
    }

    /**
     * Returns incident details for an example resource
     * EvaluateIncidentTemplateForExampleResource Legacy Incident Triggers
     */
    async legacyIncidentTriggersEvaluateIncidentTemplateForExampleResource(requestParameters: LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequest, initOverrides?: RequestInit): Promise<LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceResponseBody> {
        const response = await this.legacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns an example resource, to be used in the config UI
     * ExampleResource Legacy Incident Triggers
     */
    async legacyIncidentTriggersExampleResourceRaw(requestParameters: LegacyIncidentTriggersExampleResourceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LegacyIncidentTriggersExampleResourceResponseBody>> {
        if (requestParameters.externalResourceType === null || requestParameters.externalResourceType === undefined) {
            throw new runtime.RequiredError('externalResourceType','Required parameter requestParameters.externalResourceType was null or undefined when calling legacyIncidentTriggersExampleResource.');
        }

        const queryParameters: any = {};

        if (requestParameters.externalResourceType !== undefined) {
            queryParameters['external_resource_type'] = requestParameters.externalResourceType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/legacy_incident_triggers/example_resource`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LegacyIncidentTriggersExampleResourceResponseBodyFromJSON(jsonValue));
    }

    /**
     * Returns an example resource, to be used in the config UI
     * ExampleResource Legacy Incident Triggers
     */
    async legacyIncidentTriggersExampleResource(requestParameters: LegacyIncidentTriggersExampleResourceRequest, initOverrides?: RequestInit): Promise<LegacyIncidentTriggersExampleResourceResponseBody> {
        const response = await this.legacyIncidentTriggersExampleResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get organisation\'s incident trigger config. There is only one non-archived config per org, per resource type.
     * GetConfig Legacy Incident Triggers
     */
    async legacyIncidentTriggersGetConfigRaw(requestParameters: LegacyIncidentTriggersGetConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LegacyIncidentTriggersGetConfigResponseBody>> {
        if (requestParameters.externalResourceType === null || requestParameters.externalResourceType === undefined) {
            throw new runtime.RequiredError('externalResourceType','Required parameter requestParameters.externalResourceType was null or undefined when calling legacyIncidentTriggersGetConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/legacy_incident_triggers/{external_resource_type}`.replace(`{${"external_resource_type"}}`, encodeURIComponent(String(requestParameters.externalResourceType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LegacyIncidentTriggersGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get organisation\'s incident trigger config. There is only one non-archived config per org, per resource type.
     * GetConfig Legacy Incident Triggers
     */
    async legacyIncidentTriggersGetConfig(requestParameters: LegacyIncidentTriggersGetConfigRequest, initOverrides?: RequestInit): Promise<LegacyIncidentTriggersGetConfigResponseBody> {
        const response = await this.legacyIncidentTriggersGetConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update incident trigger config
     * UpdateConfig Legacy Incident Triggers
     */
    async legacyIncidentTriggersUpdateConfigRaw(requestParameters: LegacyIncidentTriggersUpdateConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LegacyIncidentTriggersUpdateConfigResponseBody>> {
        if (requestParameters.externalResourceType === null || requestParameters.externalResourceType === undefined) {
            throw new runtime.RequiredError('externalResourceType','Required parameter requestParameters.externalResourceType was null or undefined when calling legacyIncidentTriggersUpdateConfig.');
        }

        if (requestParameters.updateConfigRequestBody === null || requestParameters.updateConfigRequestBody === undefined) {
            throw new runtime.RequiredError('updateConfigRequestBody','Required parameter requestParameters.updateConfigRequestBody was null or undefined when calling legacyIncidentTriggersUpdateConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/legacy_incident_triggers/{external_resource_type}`.replace(`{${"external_resource_type"}}`, encodeURIComponent(String(requestParameters.externalResourceType))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LegacyIncidentTriggersUpdateConfigRequestBodyToJSON(requestParameters.updateConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LegacyIncidentTriggersUpdateConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update incident trigger config
     * UpdateConfig Legacy Incident Triggers
     */
    async legacyIncidentTriggersUpdateConfig(requestParameters: LegacyIncidentTriggersUpdateConfigRequest, initOverrides?: RequestInit): Promise<LegacyIncidentTriggersUpdateConfigResponseBody> {
        const response = await this.legacyIncidentTriggersUpdateConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum LegacyIncidentTriggersBuildScopeExternalResourceTypeEnum {
    PagerDutyIncident = 'pager_duty_incident',
    OpsgenieAlert = 'opsgenie_alert'
}
/**
    * @export
    * @enum {string}
    */
export enum LegacyIncidentTriggersExampleResourceExternalResourceTypeEnum {
    PagerDutyIncident = 'pager_duty_incident',
    OpsgenieAlert = 'opsgenie_alert'
}
/**
    * @export
    * @enum {string}
    */
export enum LegacyIncidentTriggersGetConfigExternalResourceTypeEnum {
    PagerDutyIncident = 'pager_duty_incident',
    OpsgenieAlert = 'opsgenie_alert'
}
/**
    * @export
    * @enum {string}
    */
export enum LegacyIncidentTriggersUpdateConfigExternalResourceTypeEnum {
    PagerDutyIncident = 'pager_duty_incident',
    OpsgenieAlert = 'opsgenie_alert'
}
