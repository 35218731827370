import { Incident } from "@incident-io/api";
import * as Sentry from "@sentry/react";
import { captureMessage, SeverityLevel } from "@sentry/react";
import { isEmpty, isObject } from "lodash";
import React, { useEffect, useRef } from "react";

import { toSentenceCase } from "./formatting";
import { useAPI } from "./swr";

// I Am A String -> i-am-a-string
export function slugify(str: string): string {
  return str.toLowerCase().replace(/\s/g, "-");
}

export const uppercaseFirstLetterOnly = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// This is used to tell the TS compiler to shout at us
// if we add a value to an enum, and don't handle it in
// a switch statement.
// See full explanation at https://javascript.plainenglish.io/never-miss-a-switch-case-with-typescript-684bf5d0e1d1
export const assertUnreachable = (value: never): never => {
  captureMessage("unreachable: unexpected enum value", {
    extra: { value },
  });
  return null as unknown as never;
};

// joinSpansWithCommasAndConnectorWord takes a list of react elements and connects them as if
// you were writing a list: a, b and c.
export const joinSpansWithCommasAndConnectorWord = (
  arr: (React.ReactElement | string)[],
  connectorWord = "and",
): React.ReactElement | null => {
  // if there's 0 items, return null.
  if (arr.length === 0) {
    return null;
  }
  // if there's 1 item, return it.
  if (arr.length === 1) {
    return <>{arr[0]}</>;
  }

  const res: React.ReactElement[] = [];
  arr.forEach((item, i) => {
    res.push(<React.Fragment key={`item-${i}`}>{item}</React.Fragment>);
    // if this is the last item, we're done here
    if (i === arr.length - 1) {
      return;
    }
    // if it's the penultimate item, we want an `and`/other connector word
    if (i === arr.length - 2) {
      res.push(
        <React.Fragment
          key={`${connectorWord}-${i}`}
        >{` ${connectorWord} `}</React.Fragment>,
      );
      return;
    } else {
      res.push(<React.Fragment key={`comma-${i}`}>{", "}</React.Fragment>);
      return;
    }
  });
  return <>{res}</>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const sendWarningToSentry = (msg: string, extra?: any): Error => {
  return sendToSentry(msg, extra, "warning");
};

export const sendToSentry = (
  msg: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  extra?: any,
  severity: SeverityLevel = "error",
): Error => {
  const err = new Error(msg);
  Sentry.withScope(function (scope) {
    scope.setLevel(severity);
    Sentry.captureException(err, { extra });
    if (process.env.NODE_ENV === "development") {
      switch (severity) {
        case "fatal":
        case "error":
          // eslint-disable-next-line no-console
          console.error(err, extra);
          break;
        case "warning":
          // eslint-disable-next-line no-console
          console.warn(err, extra);
          break;
        case "log":
        case "info":
          // eslint-disable-next-line no-console
          console.log(err, extra);
          break;
        case "debug":
          // eslint-disable-next-line no-console
          console.debug(err, extra);
          break;
      }
    }
  });
  return err;
};

export function noop(): void {
  return;
}

export async function asyncNoop(): Promise<void> {
  return;
}

export function useInterval(callback: () => void, delay: number | null): void {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return undefined;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

/**
 * purgeEmpty takes an object and recursively removes
 * all undefined and null values, and empty arrays and objects.
 * If the input is undefined or null, it will return the same
 * If everything in the input is empty, it will return an empty object
 */
export const purgeEmpty = <T,>(obj?: T): T | undefined => {
  // undefined == null so this captures both :vomit:
  if (obj == null) {
    return obj;
  } else {
    const sanitized = {} as T;
    Object.keys(obj).forEach((key) => {
      // Child is array: only include if len > 0
      if (Array.isArray(obj[key])) {
        if (obj[key].length > 0) {
          sanitized[key] = obj[key];
        }
        // Child is object: step into it and check if it or all its children
        // are empty
      } else if (isObject(obj[key]) && !isEmpty(obj[key])) {
        const sanitizedValue = purgeEmpty(obj[key]);
        if (!isEmpty(sanitizedValue)) {
          sanitized[key] = sanitizedValue;
        }
        // Child is literal: include if !null && !undefined
      } else if (obj[key] != null) {
        sanitized[key] = obj[key];
      }
    });
    return sanitized;
  }
};

const formatDocumentName = (name: string) => {
  if (name.toLowerCase() === name) {
    // If the name is all lowercase, return it as is
    return name;
  } else if (name.toUpperCase() === name) {
    // If the name is all uppercase, return it as is
    return name;
  } else if (toSentenceCase(name) === name) {
    // If the name is title case, return it in lowercase
    return name.toLowerCase();
  }
  return name;
};

export const usePostmortemName = (
  incident: Pick<Incident, "incident_type"> | null,
) => {
  const postmortemNameOverrideByIncidentType =
    incident?.incident_type?.postmortem_name_override;

  const { data, isLoading: loading } = useAPI(
    "postmortemsSettingsShow",
    undefined,
  );
  const postmortemSettings = data?.settings;

  const postmortemName =
    postmortemNameOverrideByIncidentType ??
    postmortemSettings?.postmortem_rename ??
    "Post-mortem";
  const postmortemNameFormatted = formatDocumentName(postmortemName);
  const postmortemNameWithArticle = ["a", "e", "i", "o", "u"].includes(
    postmortemName.toLowerCase()[0],
  )
    ? `an ${postmortemNameFormatted}`
    : `a ${postmortemNameFormatted}`;

  return {
    postmortemName,
    postmortemNameFormatted,
    postmortemNameWithArticle,
    loading,
  };
};

export const useDebriefName = () => {
  const { data, isLoading: loading } = useAPI(
    "debriefsShowSettings",
    undefined,
  );
  const debriefSettings = data?.settings;

  const debriefName = debriefSettings?.debrief_rename || "Debrief";
  const debriefNameLower = debriefName.toLowerCase();
  const debriefNameWithArticle = ["a", "e", "i", "o", "u"].includes(
    debriefNameLower[0],
  )
    ? `An ${debriefNameLower}`
    : `A ${debriefNameLower}`;
  const debriefNameWithArticleLower = debriefNameWithArticle.toLowerCase();
  return {
    debriefName,
    debriefNameWithArticle,
    debriefNameLower,
    debriefNameWithArticleLower,
    loading,
  };
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const prepareRankUpdate = <T extends { id: string; rank: number }>(
  items: T[],
) => {
  return {
    rank_updates: items.map((item) => {
      return {
        resource_id: item.id,
        rank: item.rank,
      };
    }),
  };
};

// This is the same type as react-hook-form, but explicitly allows nullable,
// which is what react-hook-form recommends 👿 https://react-hook-form.com/api/useform
// "It is encouraged that you set defaultValues for all inputs to non-undefined values such as the empty string or null."
export type DeepNullablePartial<T> = T extends Array<infer U>
  ? Array<DeepNullablePartial<U>>
  : T extends {
      [key in keyof T]: T[key];
    }
  ? {
      [K in keyof T]?: DeepNullablePartial<T[K]> | null;
    }
  : T | null;

// Convert a string to a hash value
export function stringToHash(string): number {
  let hash = 0;

  if (string.length === 0) return hash;

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  hash = hash >>> 0; // convert to unsigned 32 bit int, this ensure the result is always positive

  return hash;
}

// customerPageVisitURL gives you the URL to visit a customer page, going
// through WorkOS authentication to ensure that the user is logged in.
export function customerPageVisitURL(
  pageID: string,
  subpageID: string,
  organisationID: string,
) {
  return `/api/status_page_customer_pages/${pageID}/${subpageID}/visit?organisation_id=${organisationID}`;
}
