import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import {
  Incident,
  IncidentsUpdateNameRequestBody,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { useRevalidate } from "../../../../utils/use-revalidate";

export function RenameIncidentModal({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement {
  const formMethods = useForm<IncidentsUpdateNameRequestBody>({
    defaultValues: { name: incident.name },
  });
  const { identity } = useIdentity();

  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, args: IncidentsUpdateNameRequestBody) => {
      await apiClient.incidentsUpdateName({
        updateNameRequestBody: args,
        id: incident.id,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: () => {
        refreshIncidentList();
        onClose();
      },
    },
  );

  const channelType = identity.ms_teams_info === undefined ? "Slack" : "Teams";

  return (
    <FormModalV2
      onClose={onClose}
      title="Rename incident"
      analyticsTrackingId="rename-incident"
      formMethods={formMethods}
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          confirmButtonText="Rename"
          saving={saving}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <InputV2
        required
        autoFocus
        name="name"
        formMethods={formMethods}
        label="Enter a new name"
        helptext={`This will also rename the incident ${channelType} channel`}
      />
    </FormModalV2>
  );
}
