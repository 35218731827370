import { CatalogTypeSelectorV2 } from "@incident-shared/catalog";
import { CreateEditExpressionFormData } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import { ExpressionElseBranchInput } from "@incident-shared/engine/expressions/ExpressionElseBranchInput";
import {
  ReferenceExampleOption,
  ReferenceWithExample,
} from "@incident-shared/engine/expressions/ExpressionsEditor";
import { useEvaluateJavascript } from "@incident-shared/engine/expressions/query/operations/ParseTargetForm";
import { getOperationOptions } from "@incident-shared/engine/expressions/query/QueryOperationsEditor";
import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  StaticSingleSelect,
  Txt,
} from "@incident-ui";
import { JSONPreview } from "@incident-ui/JSONPreview/JSONPreview";
import { customStyles } from "@incident-ui/Select/customStyles";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { AttributeEntries } from "src/components/@shared/attribute/AttributeEntries";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import {
  EngineParamBinding,
  EngineParamBindingPayload,
  EngineScope,
  ExpressionOperationOperationTypeEnum as OperationType,
} from "src/contexts/ClientContext";
import { getEmptyScope } from "src/utils/scope";
import { useAPI } from "src/utils/swr";
import { getColor } from "src/utils/twConfig";

export const JSONAttributeExpressionEditor = ({
  payload,
  selectedPayload,
  parsedResultPreview,
  setSelectedPayload,
  isEditingExistingExpression,
  isAssigningToExistingAttribute,
  allowHardcodedValue,
  scope,
  disabledCatalogTypes,
}: {
  payload: ReferenceWithExample;
  selectedPayload?: EngineParamBinding;
  parsedResultPreview?: EngineParamBinding;
  setSelectedPayload: (selectedPayload?: EngineParamBindingPayload) => void;
  isEditingExistingExpression?: boolean;
  isAssigningToExistingAttribute?: boolean;
  allowHardcodedValue?: boolean;
  scope?: EngineScope;
  // The catalog types that should be disabled in the type selector
  disabledCatalogTypes?: string[];
}): React.ReactElement => {
  const formMethods = useFormContext<
    CreateEditExpressionFormData & { hardcoded?: boolean }
  >();
  const { showArticle } = useIntercom();

  // We'll only ever have one operation: `parse`
  const parseOperation = getOperationOptions({
    operationType: OperationType.Parse,
    previousRefLabel: payload?.label || "",
    capitalizeDescription: false,
  });
  const operation = formMethods.watch(`operations.0`);

  const { data: catalogResources } = useAPI("catalogListResources", undefined);
  const operationResultType = operation?.parse?.returns?.type || "";
  const arrayNotSupported = ["Text", "Bool", "Number"].includes(
    operationResultType,
  );

  // This is where we try evaluating the Javascript against the example input
  // we've been provided. If we successfully calculate something, we can build a
  // preview of it.
  const {
    result: jsonResult,
    isLoading: jsonResultIsLoading,
    isTyping: jsonSourceIsUpdating,
    errorMessage: jsonResultErrorMessage,
  } = useEvaluateJavascript({
    source: operation?.parse?.source || "",
    subject: selectedPayload?.value?.literal || "",
  });

  // Allow choosing between the examples if we've been provided with them.
  const [exampleID, setExampleID] = useState<string>(
    payload?.example?.initial || "",
  );

  // Update the result preview
  useEffect(() => {
    let exampleOption: ReferenceExampleOption | undefined;
    if (payload?.example) {
      for (const group of payload.example.groups) {
        for (const option of group.options) {
          if (option.value === exampleID) {
            exampleOption = option;
          }
        }
      }
    }

    setSelectedPayload(exampleOption?.binding);
  }, [payload, exampleID, setSelectedPayload]);

  const hardcoded = formMethods.watch("hardcoded");
  const currentJSONPath = formMethods.watch("operations.0.parse.source");

  const { data: resourcesData, isLoading: resourcesLoading } = useAPI(
    "engineListAllResources",
    {},
  );

  const resources = (resourcesData?.resources || []).filter((r) => {
    return !(disabledCatalogTypes ?? []).includes(r.type);
  });

  const returnType = formMethods.watch("operations.0.returns.type");
  const operationResultArray = Boolean(
    formMethods.watch<"operations.0.returns.array">(
      "operations.0.returns.array",
    ),
  );

  return (
    <div className="space-y-4">
      {allowHardcodedValue && (
        <BooleanRadioButtonGroupV2
          srLabel="Use a constant value or parse an alert"
          name="hardcoded"
          formMethods={formMethods}
          boxed
          horizontal
          trueOption={{
            label: "Use a constant value",
            description:
              "Set a constant value for all alerts from this source, e.g. Environment or Urgency",
          }}
          falseOption={{
            label: "Parse from payload",
            description:
              "Set a dynamic attribute from the payload of your alert, e.g. Service or Team.",
          }}
        />
      )}
      {hardcoded ? (
        <>
          <div className="rounded-[6px] bg-white border border-stroke p-4">
            <div className="text-sm">What is the type of this attribute?</div>
            <fieldset>
              <CatalogTypeSelectorV2
                name={`operations.0.returns.type`}
                inputClassName={"mt-2"}
                formMethods={formMethods}
                mode={"engine"}
                description={"value_docstring"}
                disabledCatalogTypes={disabledCatalogTypes}
                disabled={
                  isAssigningToExistingAttribute || isEditingExistingExpression
                }
                required
              />
            </fieldset>
            {!arrayNotSupported && (
              <CheckboxV2
                name={`operations.0.returns.array`}
                formMethods={formMethods}
                className={"mt-3"}
                label={"Attribute is an array"}
                disabled={
                  isAssigningToExistingAttribute || isEditingExistingExpression
                }
              />
            )}
          </div>
        </>
      ) : (
        <>
          {/* Parse field */}
          <div className="rounded-[6px] bg-white border border-stroke p-4">
            <div className="mb-2">
              <div className="flex justify-between">
                <div className="flex-center-y">
                  <div className="rounded-[6px] bg-surface-secondary p-1 mr-1">
                    <Icon id={parseOperation.icon || IconEnum.ArrowRight} />
                  </div>

                  <div className="text-sm">
                    <span className="font-semibold">Then </span>
                    {parseOperation.description}
                  </div>
                </div>
              </div>
              <FormHelpTextV2 className="!mt-2">
                <p>
                  This input supports JavaScript. ES6 language features are not
                  supported.
                </p>
                You can{" "}
                <Button
                  analyticsTrackingId="alert-source-json-extract-help"
                  onClick={() => showArticle(8826732)}
                  theme={ButtonTheme.Unstyled}
                  className="underline hover:text-content-primary"
                >
                  view examples for extracting data in our help center.
                </Button>
              </FormHelpTextV2>
              <JSONPayloadInput
                payload={payload}
                exampleId={exampleID}
                setExampleId={setExampleID}
                isEditingExisting={
                  isEditingExistingExpression || isAssigningToExistingAttribute
                }
                currentJSONPath={currentJSONPath}
                jsonResult={jsonResult}
                jsonResultIsLoading={jsonResultIsLoading}
                jsonResultErrorMessage={jsonResultErrorMessage}
                jsonSourceIsUpdating={jsonSourceIsUpdating}
              />
            </div>
          </div>
          {/* Choose result type */}
          <div className="rounded-[6px] bg-white border border-stroke p-4 flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <div className="flex-center-y">
                <div className="rounded-[6px] bg-surface-secondary p-1 mr-1">
                  <Icon id={parseOperation.icon || IconEnum.ArrowRight} />
                </div>

                <div className="text-sm">
                  <span className="font-semibold">Choose </span>
                  what the result should be parsed into
                </div>
              </div>
            </div>
            <fieldset>
              <CatalogTypeSelectorV2
                name={`operations.0.returns.type`}
                formMethods={formMethods}
                mode={"engine"}
                description={"value_docstring"}
                disabled={isAssigningToExistingAttribute}
                required
                disabledCatalogTypes={disabledCatalogTypes}
              />
            </fieldset>
            <div className="flex flex-col gap-1">
              {!arrayNotSupported && (
                <CheckboxV2
                  name={`operations.0.returns.array`}
                  formMethods={formMethods}
                  label={"Result is an array"}
                  disabled={isAssigningToExistingAttribute}
                />
              )}
            </div>
            {parsedResultPreview && catalogResources?.resources && (
              <div className="rounded-[6px] bg-surface-secondary p-4 text-sm break-words">
                <AttributeEntries
                  mode={"engine"}
                  typeName={operation.returns?.type}
                  catalogResources={catalogResources.resources}
                  attributeBinding={parsedResultPreview}
                  truncate
                  preview
                  jsonPreview={true}
                />
              </div>
            )}
            {resourcesLoading && <Loader />}
            {resourcesData && (
              <div className="mt-6">
                <ExpressionElseBranchInput
                  disabled={false}
                  label={
                    <div className="flex-center-y">
                      <div className="rounded-[6px] bg-surface-secondary p-1 mr-1 stroke-[1.5px] stroke-current text-slate-800">
                        <Icon id={IconEnum.Rhombus} />
                      </div>
                      <Txt className="text-sm">
                        If we can&apos;t parse the result, what should we
                        return? (optional)
                      </Txt>
                    </div>
                  }
                  isArray={operationResultArray}
                  scope={scope || getEmptyScope()}
                  resourceType={returnType}
                  availableReturnTypeResources={resources}
                />
              </div>
            )}
          </div>
        </>
      )}
      {/* Attribute name */}
      {!isEditingExistingExpression && (
        <div className="rounded-[6px] bg-white border border-stroke p-4">
          <InputV2
            name="label"
            title={"Attribute name"}
            label={"Attribute name"}
            formMethods={formMethods}
            className="w-full"
            labelClassName="font-semibold"
            required
            disabled={isAssigningToExistingAttribute}
            rules={{
              validate: (value) => {
                const val = value.toLowerCase();
                const reservedAttributeNames = ["name", "title", "description"];
                if (reservedAttributeNames.includes(val)) {
                  return `It looks like you're trying to create a ${val} attribute. You should set this in the "Title & description" section instead!`;
                }
                return undefined;
              },
            }}
          />

          {formMethods.watch("label") &&
            formMethods.watch("label").toLowerCase() === "priority" && (
              <Callout theme={CalloutTheme.Warning} className="mt-4">
                Use the global Priority attribute, instead of creating your own
                custom attribute.
              </Callout>
            )}
        </div>
      )}
    </div>
  );
};

const JSONPayloadInput = ({
  payload,
  exampleId,
  setExampleId,
  isEditingExisting,
  currentJSONPath,
  jsonResult,
  jsonResultIsLoading,
  jsonResultErrorMessage,
  jsonSourceIsUpdating,
}: {
  payload: ReferenceWithExample;
  exampleId: string;
  setExampleId: React.Dispatch<React.SetStateAction<string>>;
  isEditingExisting?: boolean;
  currentJSONPath: string;
  jsonResult: string;
  jsonResultIsLoading: boolean;
  jsonResultErrorMessage?: string;
  jsonSourceIsUpdating: boolean;
}) => {
  const formMethods = useFormContext<
    CreateEditExpressionFormData & { hardcoded?: boolean }
  >();

  return (
    <div className="mt-2 bg-surface-invert rounded-2 p-2 space-y-2">
      <div className="flex items-center justify-between space-x-2">
        <InputV2
          name={`operations.0.parse.source`}
          inputClassName="px-3 font-mono bg-surface-invert text-white rounded-[6px] border-none"
          className="grow"
          formMethods={formMethods}
          placeholder="$.some.nested.field"
          insetSuffixNode={
            <Button
              analyticsTrackingId={null}
              className={`text-slate-400 bg-surface-invert rounded-full flex items-center justify-center h-6 w-6 ${
                currentJSONPath !== "$" ? "visible" : "invisible"
              }`}
              title="Reset"
              icon={IconEnum.Close}
              iconProps={{
                size: IconSize.Small,
                className: "hover:!text-white",
              }}
              onClick={() => {
                formMethods.setValue<`operations.0.parse.source`>(
                  `operations.0.parse.source`,
                  "$",
                );
                if (!isEditingExisting) {
                  formMethods.setValue<`label`>(`label`, "");
                }
              }}
              theme={ButtonTheme.Unstyled}
            />
          }
          required
        />
        <StaticSingleSelect
          className={
            "!w-[220px] !bg-surface-invert !text-white rounded-[6px] border-none"
          }
          styles={stylesForInvertedSelect}
          value={exampleId}
          onChange={(value) => {
            if (value && typeof value === "string") {
              setExampleId(value);
            }
          }}
          options={payload?.example?.groups || []}
          isClearable={false}
          renderDescription="below"
        />
      </div>

      {jsonResultErrorMessage ? (
        <div className="p-5 rounded-2 bg-red-50 border border-red-200 break-words">
          <Txt bold>We&apos;re having issues parsing this query</Txt>
          <Txt grey>{jsonResultErrorMessage}</Txt>
        </div>
      ) : (
        <div className="rounded-[6px] overflow-x-auto">
          {jsonResultIsLoading ||
          jsonResult === "undefined" ||
          jsonSourceIsUpdating ? (
            <Loader />
          ) : (
            <JSONPreview
              compact
              payload={JSON.parse(jsonResult)}
              className="overflow-auto max-h-[500px]"
              containerClassName="ml-2 my-3 mr-3"
              onClickKey={(newPath) =>
                formMethods.setValue(
                  "operations.0.parse.source",
                  concatPath(currentJSONPath, newPath),
                )
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export const stylesForInvertedSelect = (invalid) => {
  const defaultStyles = customStyles(invalid, false);
  return {
    ...defaultStyles,
    container: (provided) => ({
      ...provided,
      backgroundColor: getColor("slate", "800"),
      color: getColor("white"),
      borderStyle: "none",
      borderRadius: "6px",
      boxShadow: "none",
      cursor: "pointer",
    }),
    input: (provided) => ({
      ...provided,
      padding: "0px",
      margin: "0px",
      color: getColor("white"),
    }),
    singleValue: (provided) => ({
      ...provided,
      color: getColor("white"),
      fontSize: "14px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: getColor("slate", "900"),
      color: getColor("white"),
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused
        ? getColor("slate", "800")
        : getColor("slate", "900"),
      ":active": {
        backgroundColor: getColor("slate", "800"),
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: getColor("slate", "500"),
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: getColor("slate", "500"),
    }),
  };
};

// If we've already drilled into the JSON, our node paths will be relative
// to whatever our _current_ root is, rather than the root of the whole
// payload, so we need to concatenate onto the current path rather
// than replacing it.
const concatPath = (currentPath: string, newPath: string) => {
  if (currentPath === "$") {
    return newPath;
  }
  const newPathWithoutDollar = newPath.replace(/^\$\./, "");
  const containsWhitespace = newPathWithoutDollar.includes(" ");
  return containsWhitespace
    ? `${currentPath}["${newPathWithoutDollar}"]`
    : `${currentPath}.${newPathWithoutDollar}`;
};
