import {
  ExternalScheduleExternalProviderEnum,
  ExternalScheduleNativeConfigUnavailableReasonEnum,
  IntegrationSettingsProviderEnum as IntegrationProvider,
  UnresolvedExternalUser,
} from "@incident-io/api";
import styles from "@incident-shared/filters/Filters.module.scss";
import {
  Badge,
  BadgeTheme,
  Button,
  Icon,
  IconEnum,
  IconSize,
  Input,
  Link,
  Loader,
  Modal,
  ModalContent,
  OrgAwareLink,
  StackedList,
  Tooltip,
  Txt,
} from "@incident-ui";
import { GenericErrorMessage } from "@incident-ui/GenericErrorMessage/GenericErrorMessage";
import { InputType } from "@incident-ui/Input/Input";
import { ChangeEvent, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPIInfinite } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useDebounce } from "use-hooks";

export const ScheduleImportModal = ({
  onClose,
  setExternalScheduleImportId,
}: {
  setExternalScheduleImportId: (externalId: string) => void;
  onClose: () => void;
}) => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const {
    responses,
    isLoading,
    isFullyLoaded: allEntriesLoaded,
    loadMore: loadMoreEntries,
    error,
  } = useAPIInfinite(
    "schedulesListExternal",
    {
      includeNativeConfigPayload: true,
      pageSize: 15,
      search: debouncedSearch,
    },
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
    },
  );
  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !allEntriesLoaded,
    onLoadMore: loadMoreEntries,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  const { integrations, integrationsError, integrationsLoading } =
    useIntegrations();

  const installedEscalator = integrations?.find(
    (x) =>
      (x.provider === IntegrationProvider.Opsgenie ||
        x.provider === IntegrationProvider.Pagerduty) &&
      x.installed,
  );

  if (error || integrationsError) {
    return <GenericErrorMessage error={error || integrationsError} />;
  }

  const schedules = responses.flatMap((r) => r.external_schedules);

  return (
    <Modal
      title={`Import schedule`}
      analyticsTrackingId="import-schedule"
      isOpen
      onClose={onClose}
      loading={integrationsLoading}
    >
      <ModalContent>
        {!installedEscalator ? (
          <EmptyState />
        ) : (
          <>
            <Input
              id={"search"}
              placeholder={"Search for a schedule"}
              type={InputType.Search}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value ?? "")
              }
              value={search}
              iconName={IconEnum.Search}
              iconProps={{
                id: IconEnum.Search,
                className:
                  "text-content-tertiary child:stroke-[2px] !w-5 !h-5 mr-2 my-auto",
              }}
              className={tcx(
                "bg-white rounded-full placeholder:!text-content-tertiary",
                styles.searchBarInput,
                "mb-4",
              )}
            />
            <StackedList>
              {schedules.map((schedule) => {
                const isDisabled =
                  !!schedule.native_config_unavailable_reason ||
                  schedule.created_native_schedule_id;

                const unavailableInfo =
                  schedule.native_config_unavailable_reason
                    ? UnavailableInfo[schedule.native_config_unavailable_reason]
                    : null;

                const hasMissingUsers =
                  schedule.native_config_unavailable_reason ===
                  ExternalScheduleNativeConfigUnavailableReasonEnum.MissingUserIds;

                const missingUserCount =
                  schedule.unresolvable_external_users?.length || 0;
                const hasMissingUsersLabel =
                  missingUserCount > 1
                    ? `${missingUserCount} unknown users`
                    : `${missingUserCount} unknown user`;

                return (
                  <li
                    key={schedule.id}
                    className={tcx("p-4 w-full text-sm", {
                      "hover:bg-surface-secondary cursor-pointer": !isDisabled,
                    })}
                    onClick={() => {
                      if (schedule.native_config_unavailable_reason) {
                        return;
                      }
                      setExternalScheduleImportId(schedule.id);
                    }}
                  >
                    <div className={"flex flex-row justify-between w-full"}>
                      <div
                        className={tcx({
                          "text-slate-300": isDisabled,
                        })}
                      >
                        <div className={"flex flex-row items-center"}>
                          <Icon
                            id={
                              schedule.external_provider ===
                              ExternalScheduleExternalProviderEnum.Pagerduty
                                ? IconEnum.Pagerduty
                                : IconEnum.Opsgenie
                            }
                            size={IconSize.Medium}
                            className={tcx("mr-2 shrink-0")}
                          />
                          {schedule.name}
                        </div>
                      </div>
                      {unavailableInfo ? (
                        <Tooltip
                          content={
                            hasMissingUsers &&
                            schedule.unresolvable_external_users ? (
                              <UnresolvedUsersTooltipContent
                                users={schedule.unresolvable_external_users}
                                provider={schedule.external_provider}
                              />
                            ) : (
                              unavailableInfo.tooltip
                            )
                          }
                        >
                          {/* We need the div for the tooltip hover to work */}
                          <div>
                            <Badge theme={BadgeTheme.Warning} className="flex">
                              {hasMissingUsers
                                ? hasMissingUsersLabel
                                : unavailableInfo.badge}
                              <Icon
                                size={IconSize.Small}
                                className={tcx("ml-1")}
                                id={IconEnum.Info}
                              />
                            </Badge>
                          </div>
                        </Tooltip>
                      ) : schedule.created_native_schedule_id ? (
                        <OrgAwareLink
                          to={`/on-call/schedules/${schedule.created_native_schedule_id}`}
                        >
                          <Badge theme={BadgeTheme.Info}>
                            Already imported <Icon id={IconEnum.ExternalLink} />
                          </Badge>
                        </OrgAwareLink>
                      ) : (
                        <Icon
                          id={IconEnum.ChevronRight}
                          size={IconSize.Medium}
                        />
                      )}
                    </div>
                  </li>
                );
              })}
              {allEntriesLoaded && schedules.length === 0 && (
                <Txt className={"p-4 text-center"} grey>
                  No schedules found
                </Txt>
              )}
              {!allEntriesLoaded && (
                <div ref={infiniteScrollRef}>
                  <Loader />
                </div>
              )}
            </StackedList>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

const UnavailableInfo: Record<
  ExternalScheduleNativeConfigUnavailableReasonEnum,
  {
    tooltip: string;
    badge: string;
  }
> = {
  [ExternalScheduleNativeConfigUnavailableReasonEnum.MissingUserIds]: {
    // We special case this tooltip for users to list out who is missing
    tooltip: ``,
    badge: `Unknown users`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedInterval]: {
    tooltip: `This schedule has an recurrence interval that isn't yet supported by incident.io.`,
    badge: `Unsupported interval`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedRestrictions]: {
    tooltip: `This schedule has restrictions that aren't yet supported by incident.io.`,
    badge: `Unsupported restrictions`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.StartsInFuture]: {
    tooltip: `This schedule starts in the future, which isn't supported.`,
    badge: `Starts in future`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.EndsInFuture]: {
    tooltip: `This schedule ends in the future, which isn't supported.`,
    badge: `Ends in future`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.NoUsableLayers]: {
    tooltip: `This schedule has no usable layers.`,
    badge: `No usable layers`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.NoConfigSynced]: {
    tooltip: `We've not yet been able to pull the rotation configuration for this schedule - please check back later.`,
    badge: `No configuration synced`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedParticipants]: {
    tooltip: `This schedule has participants that aren't yet supported by incident.io, such as a team or escalation.`,
    badge: `Unsupported participants`,
  },
};

const EmptyState = () => {
  return (
    <div>
      <Txt>
        To import schedules you need to setup an external escalator first.
      </Txt>
      <div className={"flex flex gap-4 mt-4"}>
        <Button
          analyticsTrackingId={`configure-pagerduty-for-import`}
          className="flex-center grow"
          onClick={() => {
            window.location.href = `/settings/integrations/pagerduty`;
          }}
          icon={IconEnum.Pagerduty}
        >
          Connect Pagerduty
        </Button>
        <Button
          analyticsTrackingId={`configure-opsgenie-for-import`}
          className="flex-center grow"
          onClick={() => {
            window.location.href = `/settings/integrations/opsgenie`;
          }}
          icon={IconEnum.Opsgenie}
        >
          Connect Opsgenie
        </Button>
      </div>
    </div>
  );
};

const UnresolvedUsersTooltipContent = ({
  users,
  provider,
}: {
  users: UnresolvedExternalUser[];
  provider: ExternalScheduleExternalProviderEnum;
}) => {
  const providerLabel =
    provider === ExternalScheduleExternalProviderEnum.Pagerduty
      ? "PagerDuty"
      : "Opsgenie";

  return (
    <div className="flex flex-col p-1">
      <span>
        There are {providerLabel} users on this schedule that don&apos;t have
        connected incident.io accounts. Learn how to manually connect them{" "}
        <Link
          className="text-stone-100"
          openInNewTab
          href={
            "https://incident.io/changelog/adding-connected-users-to-the-catalog"
          }
          analyticsTrackingId={"configure-related-accounts-import-modal"}
        >
          here
        </Link>
        .
      </span>
      <ul className="my-1">
        {users?.map((u, idx) => {
          const element = u.url ? (
            <Link
              className="text-stone-100"
              href={u.url}
              openInNewTab
              analyticsTrackingId={"unresolved-external-users"}
            >
              {u.name}
            </Link>
          ) : (
            <div>{u.name}</div>
          );

          return (
            <li key={idx} className="flex gap-1">
              •{element}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
