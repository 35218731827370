import {
  ColorPalette,
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { Icon, IconEnum, IconSize, Interpose } from "@incident-ui";
import { StepConfig, StepStatus } from "@incident-ui/Steps/Steps";
import _ from "lodash";
import React from "react";

import { tcx } from "../../../utils/tailwind-classes";

// This type is designed to parameterised with a restricted string type that
// represents the IDs of all the steps visible in the FE e.g.
//
// type WizardSteps = "start" | "middle" | "end";
//
// The `steps` property of NakedStepsProps is then a typesafe array of the
// steps in the order that they'll get rendered on the FE.
// export type NakedStepConfig<StepID extends string> = {
//   id: StepID;
//   text: string;
// };
export type NakedStepsProps<StepID extends string> = {
  steps: StepConfig<StepID>[];
  currentStepID: StepID;
  containerClassName?: string;
  renderStepCustom?: (text: string, status: StepStatus) => React.ReactElement;
  color: ColorPaletteEnum;
};

// NakedSteps is based heavily on our existing Steps component and offers a
// more-or-less drop-in replacement.
export const NakedSteps = <StepID extends string>({
  steps,
  currentStepID,
  containerClassName,
  color,
  renderStepCustom,
}: NakedStepsProps<StepID>): React.ReactElement => {
  // Avoids blowing up the page if there are no steps
  if (steps.length === 0) {
    return <></>;
  }

  const palette = getColorPalette(color);

  // If we have duplicate step definitions in the `steps` array, throw an error
  const duplicateKey = _.chain(steps)
    .map((step) => step.id)
    .countBy()
    .findKey((count) => count > 1)
    .value();

  if (duplicateKey) {
    throw new Error(
      `NakedSteps encountered a duplicate step key: ${duplicateKey}`,
    );
  }

  // Get the current step index from the provided stepID
  const currentStepIndex = steps.findIndex((sc) => sc.id === currentStepID);

  // Render the step elements
  const renderedStepElements = steps.map(
    (stepConfig, stepIdx): React.ReactElement => {
      return (
        <StepListItem
          key={stepConfig.id}
          step={stepConfig}
          thisStepIndex={stepIdx}
          currentStepIndex={currentStepIndex}
          renderStepCustom={renderStepCustom}
          palette={palette}
        />
      );
    },
  );

  return (
    <nav
      aria-label="Progress"
      className={tcx(
        "flex w-full gap-3 px-2 justify-center",
        containerClassName,
      )}
    >
      <Interpose
        separator={
          <div
            className={tcx(
              "flex-1 mt-2 max-w-[40%]",
              palette.border,
              "border-0 border-t",
            )}
          />
        }
      >
        {renderedStepElements}
      </Interpose>
    </nav>
  );
};

type StepListItemProps<StepID extends string> = {
  step: StepConfig<StepID>;
  thisStepIndex: number;
  currentStepIndex: number;
  renderStepCustom?: (text: string, status: StepStatus) => React.ReactElement;
  palette: ColorPalette;
};

const StepListItem = <StepID extends string>({
  step,
  thisStepIndex,
  currentStepIndex,
  renderStepCustom,
  palette,
}: StepListItemProps<StepID>): React.ReactElement => {
  const status =
    thisStepIndex === currentStepIndex
      ? StepStatus.Current
      : thisStepIndex < currentStepIndex
      ? StepStatus.Complete
      : StepStatus.Upcoming;

  const iconsForStatus: Record<StepStatus, React.ReactElement> = {
    [StepStatus.Complete]: (
      <Icon
        id={IconEnum.Tick}
        size={IconSize.Small}
        className={tcx("text-white rounded-2", palette.invertBg)}
      />
    ),
    [StepStatus.Current]: (
      <Icon
        id={IconEnum.DotsHorizontal}
        size={IconSize.Small}
        className={"bg-black text-white rounded-2"}
      />
    ),
    [StepStatus.Upcoming]: (
      <Icon id={IconEnum.DottedCircle} size={IconSize.Small} />
    ),
  };

  if (renderStepCustom) {
    return renderStepCustom(step.name, status);
  } else {
    return (
      <div
        className={
          "flex flex-col gap-1 max-w-24 text-xs text-center font-semibold items-center"
        }
      >
        <div>{iconsForStatus[status]}</div>
        <span>{step.name}</span>
      </div>
    );
  }
};
