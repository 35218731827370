import { useGetIncidents } from "@incident-shared/incidents";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  Loader,
  StackedList,
} from "@incident-ui";
import { sortBy } from "lodash";
import { useState } from "react";
import { CondensedIncidentListItem } from "src/components/@shared/incidents/CondensedIncidentListItem";
import { useIsSampleData } from "src/components/insights/common/useIsSampleData";

const SHOW_MORE_LIMIT = 5;

export type SelectedDateInfo = {
  incidentIDs: string[];
  date: Date;
  dataIndex: number;
};

export const HomeActivityIncidentsList = ({
  selectedDateInfo,
}: {
  selectedDateInfo: SelectedDateInfo;
}): React.ReactElement | null => {
  const { incidentIDs, date } = selectedDateInfo;

  const [showAll, setShowAll] = useState(false);

  const {
    incidents,
    error: incidentsError,
    isLoading: incidentsLoading,
  } = useGetIncidents({
    filters: [],
    fixedFilters: { pageSize: 100, id: { one_of: incidentIDs } },
  });

  if (incidentsError) throw incidentsError;

  const sortedIncidents = sortBy(incidents, (x) => x.external_id);
  const firstFiveIncidents = (sortedIncidents || []).slice(0, 5);

  const incidentsToShow = showAll ? sortedIncidents : firstFiveIncidents;

  const loading = incidentsLoading;

  const { isSampleData } = useIsSampleData();

  if (incidentIDs.length === 0) {
    return null;
  }
  if (loading || incidents == null) {
    return <Loader />;
  }

  return (
    <>
      <div className="text-sm font-medium text-content-primary mb-2">
        {date.toLocaleDateString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </div>
      {isSampleData ? (
        <Callout theme={CalloutTheme.Plain} iconOverride={IconEnum.New}>
          We&rsquo;re using anonymised sample data to demonstrate this feature.
          Normally, we&rsquo;d show incidents from{" "}
          {date.toLocaleDateString("en-GB", {
            month: "long",
            day: "numeric",
          })}{" "}
          here.
        </Callout>
      ) : incidents.length === 0 ? (
        <Callout theme={CalloutTheme.Plain}>No matching incidents</Callout>
      ) : (
        <>
          <StackedList>
            {incidentsToShow.map((inc) => (
              <CondensedIncidentListItem
                key={inc.id}
                incident={inc}
                showIncidentTypes={false}
              />
            ))}
          </StackedList>
          {incidents.length > SHOW_MORE_LIMIT && (
            <Button
              analyticsTrackingId={`${"active-incident-list"}-${
                showAll ? "less" : "more"
              }`}
              theme={ButtonTheme.Naked}
              onClick={() => setShowAll(!showAll)}
              className="mt-2"
            >
              {showAll ? "Show less" : "Show more"}
            </Button>
          )}
        </>
      )}
    </>
  );
};
