import {
  EngineParamBinding,
  EngineParamBindingPayload,
  EngineParamBindingValue,
  EngineScope,
  Reference,
  Resource,
  ScopeNameEnum,
} from "@incident-io/api";
import {
  EngineBindingValueBadge,
  EngineFormElement,
  getVariableScope,
  isExpression,
} from "@incident-shared/engine";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { ViewExpression } from "@incident-shared/engine/expressions/ViewExpression";
import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  IconEnum,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import {
  useFieldArray,
  useForm,
  useFormContext,
  UseFormSetValue,
} from "react-hook-form";
import { AttributeListItem } from "src/components/catalog/entry-view/AttributeList";

import { PrioritiesCreateEditDrawer } from "../../priorities/PrioritiesCreateEditDrawer";
import { AlertSourceConfigureFormData } from "./AlertSourceConfigurePage";
import { AlertSourceSection } from "./AlertSourceSection";

export const AlertSourcePriority = ({
  scope,
  resources,
}: {
  scope: EngineScope;
  scopeWithExpressions: EngineScope;
  resources: Resource[];
}) => {
  const formMethods = useFormContext<AlertSourceConfigureFormData>();
  const { watch, setValue } = formMethods;
  const variableScope = getVariableScope(scope, resources);

  const [isEditing, setIsEditing] = useState(false);
  const [isManagingPriorities, setIsManagingPriorities] = useState(false);

  const [priority, expressions] = watch([
    "template.priority",
    "template.expressions",
  ]);

  if (!priority || !priority.value) {
    return (
      <Callout
        iconOverride={IconEnum.Priority}
        theme={CalloutTheme.Plain}
        title="Priority"
        subtitle="Set the priority for alerts coming from this alert source. You can use this later in escalation paths to customise how responders are notified."
      />
    );
  }

  const attributeListItems: AttributeListItem[] = [];

  attributeListItems.push({
    title: "Priority",
    tooltipContent:
      "Set the priority for alerts coming from this alert source. You can use this later in escalation paths to customise how responders are notified.",
    renderValues: () => (
      <ContentBox className="p-2 w-full">
        <EngineBindingValueBadge
          value={priority.value as EngineParamBindingValue}
          resourceType={`CatalogEntry["AlertPriority"]`}
          className="w-fit"
        />
      </ContentBox>
    ),
  });

  let expression: ExpressionFormData | undefined;
  if (priority.value?.reference && isExpression(priority.value.reference)) {
    expression = expressions.find((expr) =>
      (priority.value?.reference as string).includes(expr.reference),
    );
  }

  return (
    <>
      <AnimatePresence>
        {isEditing && (
          <PriorityDrawer
            onClose={() => setIsEditing(false)}
            scope={scope}
            resources={resources}
            setValue={setValue}
            initialExpressions={expressions}
            initialBinding={priority}
            onManagePriorities={() => setIsManagingPriorities(true)}
            isManagingPriorities={isManagingPriorities}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isManagingPriorities && (
          <PrioritiesCreateEditDrawer
            title="Manage priorities"
            onClose={() => setIsManagingPriorities(false)}
          />
        )}
      </AnimatePresence>

      <AlertSourceSection
        title="Set priority to:"
        icon={IconEnum.AlertPriority}
        onEdit={() => setIsEditing(true)}
      >
        <>
          {expression ? (
            <ViewExpression
              hideTitleBar
              showExpressionName={false}
              expression={expression}
              scope={scope}
            />
          ) : (
            <EngineBinding
              binding={priority as EngineParamBinding}
              resourceType={`CatalogEntry["AlertPriority"]`}
              variableScope={variableScope}
              displayExpressionAs={"pill"}
            />
          )}
        </>
      </AlertSourceSection>
    </>
  );
};

type PriorityFormData = {
  dynamicPriority: EngineParamBindingPayload;
  staticPriority: EngineParamBindingPayload;
  mode: "static" | "dynamic";
  expressions: ExpressionFormData[];
};

const PriorityDrawer = ({
  onClose: onCloseCallback,
  scope,
  resources,
  setValue,
  initialExpressions,
  initialBinding,
  onManagePriorities,
  isManagingPriorities,
}: {
  onClose: () => void;
  scope: EngineScope;
  resources: Resource[];
  setValue: UseFormSetValue<AlertSourceConfigureFormData>;
  initialExpressions: ExpressionFormData[];
  initialBinding: EngineParamBindingPayload;
  onManagePriorities: () => void;
  isManagingPriorities: boolean;
}) => {
  const initialMode = initialBinding.value?.reference ? "dynamic" : "static";
  const formMethods = useForm<PriorityFormData>({
    defaultValues: {
      expressions: initialExpressions,
      dynamicPriority: initialMode === "dynamic" ? initialBinding : undefined,
      staticPriority: initialMode === "static" ? initialBinding : undefined,
      mode: initialMode,
    },
  });

  const onSubmit = (data: PriorityFormData) => {
    const priority =
      data.mode === "static" ? data.staticPriority : data.dynamicPriority;

    setValue("template.priority", priority, { shouldDirty: true });
    setValue("template.expressions", data.expressions);
    onCloseCallback();
  };

  const expressionsMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });

  const scopeWithExpressions = addExpressionsToScope<Reference>(
    scope,
    expressionsMethods.fields as unknown as ExpressionFormData[],
  );

  const radioOptions: RadioButtonGroupOption[] = [
    {
      value: "static",
      label: "A static value",
      description: "All alerts from this source will have the same priority.",
      renderWhenSelectedNode: () => (
        <EngineFormElement
          name="staticPriority"
          resourceType={`CatalogEntry["AlertPriority"]`}
          array={false}
          resources={resources}
          mode="plain_input"
          required
        />
      ),
    },
    {
      value: "dynamic",
      label: "A dynamic value",
      description: "Set the priority based on the alert payload.",
      renderWhenSelectedNode: () => (
        <EngineFormElement
          name="dynamicPriority"
          resourceType={`CatalogEntry["AlertPriority"]`}
          array={false}
          scope={scopeWithExpressions}
          resources={resources}
          mode="expressions_only_no_static_value"
          required
          expressionLabelOverride="Priority"
        />
      ),
    },
  ];

  const { onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onCloseCallback,
  );
  const onClose = () => onCloseWithWarn(formMethods.formState.isDirty);

  return (
    <Drawer
      width="medium"
      onClose={onClose}
      isInBackground={isManagingPriorities}
    >
      <DrawerContents>
        <DrawerTitle
          title="Priority"
          onClose={onClose}
          icon={IconEnum.Priority}
          color={ColorPaletteEnum.Slate}
        />
        <DrawerBody>
          <FormV2
            id="alert-source-priority"
            formMethods={formMethods}
            onSubmit={onSubmit}
          >
            <ExpressionsMethodsProvider
              expressionsMethods={expressionsMethods}
              allowAllOfACatalogType={false}
            >
              <div className="text-content-secondary">
                Set the priority for alerts coming from this alert source. You
                can use this later in escalation paths to customise how
                responders are notified.
              </div>

              <div className={"flex flex-col gap-4"}>
                <RadioButtonGroupV2
                  formMethods={formMethods}
                  options={radioOptions}
                  name="mode"
                  label="How do you want to set alert priority?"
                  srLabel="Priority mode"
                  boxed
                />
              </div>

              <GatedButton
                analyticsTrackingId="alert-sources-manage-priorities"
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                icon={IconEnum.Cog}
                theme={ButtonTheme.Secondary}
                size={ButtonSize.Medium}
                onClick={onManagePriorities}
              >
                Manage priorities
              </GatedButton>
            </ExpressionsMethodsProvider>
          </FormV2>
        </DrawerBody>
        <DrawerFooter>
          <div className="flex items-center justify-end gap-2">
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Secondary}
              onClick={onCloseCallback}
            >
              Cancel
            </Button>
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Primary}
              onClick={formMethods.handleSubmit(onSubmit)}
              form="alert-source-priority"
            >
              Apply
            </Button>
          </div>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};
