import { GenericErrorMessage, Link } from "@incident-ui";
import React from "react";
import { useQueryParams } from "src/utils/query-params";

export const MicrosoftTeamsTabErrorRoute = (): React.ReactElement => {
  const error = useQueryParams().get("error");

  if (error && error === "organisation_not_found") {
    return (
      <FeedbackScreen
        title="You need an incident.io account."
        content={
          <p>
            We couldn&rsquo;t find an organisation for this Microsoft Teams
            tenant. You need to have an account to be able to use this app. Sign
            up at{" "}
            <Link
              href="https://incident.io/"
              openInNewTab
              analyticsTrackingId={null}
            >
              incident.io
            </Link>
            .
          </p>
        }
      />
    );
  }

  return <GenericErrorMessage />;
};

const FeedbackScreen = ({
  title,
  content,
}: {
  title: string;
  content: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col h-full min-h-full justify-center">
      <div className="flex flex-col items-center gap-4">
        <span className="text-base-bold text-brand">{title}</span>
        <div className="text-content-primary text-center max-w-[50ch]">
          {content}
        </div>
      </div>
    </div>
  );
};
