import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import graphic from "src/components/settings/banners/banner-automation.svg";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";

import { useIdentity } from "../../../contexts/IdentityContext";
import { useSettings } from "../../../hooks/useSettings";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { AutomationAutoArchiveIncidentsEditForm } from "./AutomationAutoArchiveIncidentsEditForm";
import { AutomationAutoCloseIncidentsEditForm } from "./AutomationAutoCloseIncidentsEditForm";
import { AutomationAutoJoinSlackEditForm } from "./AutomationAutoJoinSlackEditForm";
import { AutomationDefaultCallURLEditForm } from "./AutomationDefaultCallURLEditForm";
import { AutomationErrorNotificationChannelEditForm } from "./AutomationErrorNotificationChannelEditForm";
import { AutomationInitialMessageEditForm } from "./AutomationInitialMessageEditForm";
import { AutomationShoutoutsEditForm } from "./AutomationShoutoutsEditForm";

export const AutomationPage = (): React.ReactElement => {
  const { settings } = useSettings();
  const { identity } = useIdentity();
  const { msTeamsAutoDeleteChannels } = useFlags();

  const commsPlatform = usePrimaryCommsPlatform();

  if (!settings || !identity) {
    return <FullPageLoader />;
  }

  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        title="Simplify with automation"
        subtitle="Automate administrative tasks to make incident management easier."
        graphic={<img src={graphic} className="h-40" />}
      />

      {/* Auto-close incidents */}
      {(commsPlatform === CommsPlatform.Slack || msTeamsAutoDeleteChannels) && (
        <AutomationAutoCloseIncidentsEditForm />
      )}

      {/* Auto-archive Slack channels */}
      <AutomationAutoArchiveIncidentsEditForm />

      {/* Incident Watchers */}
      {/* Don't move this! If the multi-select causes the screen to move then
        we'll fail to catch the click on the X button, so we put this setting
        higher in the page to give some scroll buffer. */}
      {commsPlatform === CommsPlatform.Slack && (
        <AutomationAutoJoinSlackEditForm />
      )}

      {/* Initial message in incident channel  */}
      {commsPlatform === CommsPlatform.Slack && (
        <AutomationInitialMessageEditForm />
      )}

      {/* Default Call URL */}
      <AutomationDefaultCallURLEditForm />

      {/* Send shoutouts to another channel  */}
      {commsPlatform === CommsPlatform.Slack && <AutomationShoutoutsEditForm />}

      {/* Notify a Slack channel when we encounter an error */}
      {commsPlatform === CommsPlatform.Slack && (
        <AutomationErrorNotificationChannelEditForm />
      )}
    </SettingsSubPageWrapper>
  );
};
