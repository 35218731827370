import {
  Avatar,
  Button,
  ButtonTheme,
  ContentBox,
  IconEnum,
  IconSize,
  LocalDateTime,
  Textarea,
  ToastTheme,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Incident, TimelineItemComment } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { getAnchorId, timelineCommentAnchorPrefix } from "src/utils/anchor";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./TimelineItems.module.scss";

type FormData = {
  text: string;
};

export function TimelineItemCommentList({
  incident,
  itemId,
  comments,
  shouldFocus,
}: {
  incident: Incident;
  itemId?: string;
  comments: TimelineItemComment[];
  shouldFocus: boolean;
}): React.ReactElement {
  return (
    <ContentBox className={`${styles.itemCommentArea} p-2 mt-2`}>
      <ul className={`${styles.itemCommentRow} divide-y divide-white`}>
        {comments.map((comment) => (
          <TimelineItemCommentRow
            key={comment.id}
            incident={incident}
            comment={comment}
          />
        ))}
      </ul>
      {itemId && (
        <TimelineItemCommentForm
          incident={incident}
          timelineItemId={itemId}
          shouldFocus={shouldFocus}
        />
      )}
    </ContentBox>
  );
}

export function TimelineItemCommentForm({
  incident,
  timelineItemId,
  shouldFocus,
}: {
  incident: Incident;
  timelineItemId: string;
  shouldFocus: boolean;
}): React.ReactElement {
  const { identity } = useIdentity();
  const showToast = useToast();

  const { register, handleSubmit, watch, setValue, reset } =
    useForm<FormData>();

  const {
    trigger: createTimelineItemComment,
    isMutating: savingTimelineItemComment,
  } = useAPIMutation(
    "timelineItemsList",
    { incidentId: incident.id },
    async (apiClient, { text }: FormData) => {
      await apiClient.timelineItemCommentsCreate({
        createRequestBody: {
          incident_id: incident.id,
          text,
          timeline_item_id: timelineItemId,
        },
      });
    },
    {
      onSuccess: () => {
        reset();
      },
      onError: async () => {
        showToast({
          title: "Unexpected error",
          description: "We weren't able to save this comment",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const text = watch("text");

  return (
    <form className="flex" onSubmit={handleSubmit(createTimelineItemComment)}>
      <Avatar
        size={IconSize.Medium}
        name={identity?.user_name}
        url={identity?.user_avatar_url}
        className="mt-1 mr-2"
      />
      {savingTimelineItemComment ? (
        <div className="grow mr-2" />
      ) : (
        <Textarea
          type={InputType.Text}
          id="text"
          autoComplete="none"
          autoFocus={shouldFocus}
          placeholder={"Add a comment"}
          className="grow mr-2"
          {...register("text", {
            required: "A comment is required",
          })}
          rows={1}
          onChange={(e) => {
            const target = e.target as HTMLTextAreaElement;

            setValue<"text">("text", target.value);

            // update the height to match the content
            target.style.height = `inherit`;
            target.style.height = `${target.scrollHeight}px`;
          }}
          onKeyPress={(e) => {
            // submit on Enter, but not Shift+Enter
            if (e.key === "Enter" && e.shiftKey === false) {
              e.preventDefault();
              const target = e.target as HTMLTextAreaElement;
              if (target.value.length > 0) {
                createTimelineItemComment({ text: target.value });
              }
            }
          }}
        />
      )}
      <Button
        title="Send"
        className={tcx("mt-auto mb-1 group-hover:!text-slate-300", {
          invisible: !text || text?.length === 0,
        })}
        analyticsTrackingId="timeline-item-comment-save"
        icon={IconEnum.Send}
        iconProps={{
          size: IconSize.Medium,
        }}
        type="submit"
        theme={ButtonTheme.Naked}
        loading={savingTimelineItemComment}
        disabled={savingTimelineItemComment || text?.length === 0}
      />
    </form>
  );
}

export function TimelineItemCommentRow({
  incident,
  comment,
}: {
  incident: Incident;
  comment: TimelineItemComment;
}): React.ReactElement {
  const { identity } = useIdentity();
  const showToast = useToast();

  const { register, reset, setValue, getValues, handleSubmit } =
    useForm<FormData>({
      defaultValues: { text: comment.text },
    });

  const {
    trigger: deleteTimelineItemComment,
    isMutating: deletingTimelineItemComment,
  } = useAPIMutation(
    "timelineItemsList",
    { incidentId: incident.id },
    async (apiClient, { id }: { id: string }): Promise<void> => {
      await apiClient.timelineItemCommentsDestroy({
        id,
      });
    },
    {
      onError: async () => {
        showToast({
          title: "Unexpected error",
          description: "We weren't able to delete this comment",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const {
    trigger: updateTimelineItemComment,
    isMutating: updatingTimelineItemComment,
  } = useAPIMutation(
    "timelineItemsList",
    { incidentId: incident.id },
    async (apiClient, { text }: { text: string }) => {
      const resp = await apiClient.timelineItemCommentsUpdate({
        id: comment.id,
        updateRequestBody: {
          text: text,
        },
      });
      reset();
      setValue<"text">("text", resp.timeline_item_comment.text);
    },
    {
      onSuccess: () => {
        setEditing(false);
      },
      onError: async () => {
        showToast({
          title: "Unexpected error",
          description: "We weren't able to update this comment",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const [editing, setEditing] = useState(false);

  if (deletingTimelineItemComment) {
    // instantly hide the item whilst hiding is in progress, before the API has confirmed it
    return <></>;
  }

  const text = getValues("text");
  const rows = text?.split("\n").length;

  const anchorId = `${timelineCommentAnchorPrefix}/${comment.id}`;

  return (
    <div
      className={tcx(
        "flex py-2 first:pt-0",
        getAnchorId() === anchorId ? styles.anchored : "",
      )}
      id={anchorId}
    >
      <Avatar
        size={IconSize.Medium}
        name={comment.user.name}
        url={comment.user.avatar_url}
        className="mr-2"
      />
      <div className="w-full">
        <div className="flex">
          <div className="grow">
            <LocalDateTime
              timestamp={comment.created_at}
              className="!border-x-0"
            >
              <strong className="font-semibold">{comment.user.name}</strong>
            </LocalDateTime>
          </div>
          {comment.user.id === identity?.user_id && (
            <>
              {!editing && (
                <Button
                  title="Edit comment"
                  analyticsTrackingId="timeline-item-comment-edit"
                  icon={IconEnum.Edit}
                  iconProps={{
                    size: IconSize.Medium,
                  }}
                  theme={ButtonTheme.Naked}
                  className={tcx(
                    updatingTimelineItemComment ? "" : styles.icons,
                  )}
                  onClick={() => {
                    setEditing(!editing);
                  }}
                  disabled={updatingTimelineItemComment}
                />
              )}
              <Button
                title="Delete comment"
                analyticsTrackingId="timeline-item-comment-archive"
                icon={IconEnum.Delete}
                iconProps={{
                  size: IconSize.Medium,
                }}
                theme={ButtonTheme.Naked}
                className={`${styles.icons}`}
                onClick={() => deleteTimelineItemComment(comment)}
                loading={deletingTimelineItemComment}
                disabled={deletingTimelineItemComment}
              />
            </>
          )}
        </div>
        {editing ? (
          <form
            className="flex flex-center-y"
            onSubmit={handleSubmit(updateTimelineItemComment)}
          >
            <Textarea
              type={InputType.Text}
              id="text"
              autoComplete="none"
              autoFocus
              {...register("text", {
                required: "A comment is required",
              })}
              rows={rows}
              className={"!p-0"}
              onChange={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = `inherit`;
                target.style.height = `${target.scrollHeight}px`;
              }}
              onKeyPress={(e) => {
                // submit on Enter, but not Shift+Enter
                if (e.key === "Enter" && e.shiftKey === false) {
                  e.preventDefault();
                  const target = e.target as HTMLTextAreaElement;
                  updateTimelineItemComment({ text: target.value });
                }
              }}
              disabled={updatingTimelineItemComment}
              readOnly={!editing}
            />
            <Button
              title="Cancel"
              className={tcx("mt-auto mb-1 text-slate-600", {
                invisible: updatingTimelineItemComment,
              })}
              analyticsTrackingId="timeline-item-comment-cancel"
              icon={IconEnum.Close}
              iconProps={{
                size: IconSize.Medium,
              }}
              type="submit"
              theme={ButtonTheme.Naked}
              onClick={() => setEditing(false)}
              disabled={updatingTimelineItemComment}
            />
            <Button
              title="Update"
              className={tcx("mt-auto mb-1 text-slate-600", {
                invisible: !text || text?.length === 0,
              })}
              analyticsTrackingId="timeline-item-comment-save"
              icon={IconEnum.Send}
              iconProps={{
                size: IconSize.Medium,
                className: "rotate-90",
              }}
              type="submit"
              theme={ButtonTheme.Naked}
              loading={updatingTimelineItemComment}
              disabled={updatingTimelineItemComment || text?.length === 0}
            />
          </form>
        ) : (
          <p>
            {comment.text}
            {comment.created_at.getTime() !== comment.updated_at.getTime() && (
              <span className="text-slate-400 ml-1">(edited)</span>
            )}
          </p>
        )}
      </div>
    </div>
  );
}
