import { FormLabelV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { Button, IconEnum } from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";
import { useClipboard } from "src/utils/useClipboard";

type FormData = {
  prompt: string;
  returns_json: boolean;
  ignore_after_timestamp?: Date;
};

type AITestPromptModalProps = {
  incidentIds: string[];
  onClose: () => void;
};

export const AITestPromptModal = ({
  incidentIds,
  onClose,
}: AITestPromptModalProps) => {
  const [text, setText] = useState<string>();
  const { copyTextToClipboard } = useClipboard();
  const apiClient = useClient();
  const formMethods = useForm<FormData>({
    defaultValues: {
      prompt: "",
      returns_json: false,
    },
  });

  const onSubmit = async (values: FormData) => {
    const response = await apiClient.aITestPrompt({
      testPromptRequestBody: {
        incident_ids: incidentIds,
        prompt: values.prompt,
        return_json: values.returns_json,
        ignore_after_timestamp: values.ignore_after_timestamp?.toISOString(),
      },
    });

    setText(response.response);
  };

  const onCopyClick = () => {
    if (text) {
      copyTextToClipboard(text);
    }
  };

  return (
    <FormModalV2
      formMethods={formMethods}
      title="Test AI Prompt"
      onSubmit={onSubmit}
      onClose={onClose}
      analyticsTrackingId={"test-ai-prompt"}
    >
      <div className="flex flex-col gap-4">
        <TextareaV2
          formMethods={formMethods}
          name="prompt"
          label={"Prompt"}
          placeholder="You are an AI responsible for testing AI prompts..."
        />
        <ToggleV2
          formMethods={formMethods}
          name="returns_json"
          label={"Returns JSON"}
          helptext={"Enforce a JSON response from the AI provider."}
        />
        <DateTimeInputV2
          formMethods={formMethods}
          name="ignore_after_timestamp"
          label="Ignore after"
          helptext={
            "If provided, slack messages an incident updates after this time will not be included in the prompt context."
          }
        />
        {text !== undefined && (
          <>
            <FormLabelV2 htmlFor="response">Response</FormLabelV2>
            <div className="relative max-h-40">
              <Button
                icon={IconEnum.Copy}
                title="Copy output"
                className="absolute top-1 right-1"
                onClick={onCopyClick}
                analyticsTrackingId={"copy-ai-output"}
              />
              <pre className="bg-gray-100 p-2 rounded-md max-h-40 overflow-y-auto">
                {text}
              </pre>
            </div>
          </>
        )}
        <Button
          type="submit"
          analyticsTrackingId="test-ai-prompt-submit"
          className="ml-auto"
          loading={formMethods.formState.isSubmitting}
        >
          Test Prompt
        </Button>
      </div>
    </FormModalV2>
  );
};
