import { Icon, IconEnum, IconSize, Link } from "@incident-ui";
import React from "react";
import {
  ExternalDatadogMonitorAlert,
  ExternalResource,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { AttachmentContexts } from "./IncidentAttachment";

export const DatadogMonitorAlert = ({
  className,
  resource,
  alert,
  context,
}: {
  className?: string;
  resource: ExternalResource;
  alert: ExternalDatadogMonitorAlert;
  context: AttachmentContexts;
}): React.ReactElement => {
  return (
    <div className={tcx("flex", className)}>
      <Icon
        id={IconEnum.Datadog}
        size={IconSize.Medium}
        className="text-content-tertiary mr-6 shrink-0 font-semibold mt-1"
      />
      <div>
        <div className="font-medium mb-2">
          <Link
            href={resource.permalink}
            openInNewTab
            analyticsTrackingId={null}
          >
            {alert.title}
          </Link>
        </div>
        <div className="flex space-x-2 text-slate-700">
          {context === AttachmentContexts.RealTime ? (
            <span className="flex-center-y">
              <Icon
                id={IconEnum.Severity}
                className="text-content-tertiary mr-1"
              />
              <span>{alert.alert_priority}</span>
            </span>
          ) : null}
          <span className="flex-center-y">
            <Icon
              id={IconEnum.CustomField}
              className="text-content-tertiary mr-1"
            />
            <span>{alert.tags.join(", ")}</span>
          </span>
        </div>
        <a href={resource.permalink} target={"_blank"} rel="noreferrer">
          <img
            className={"!max-w-[300px]"}
            key={resource.image?.id}
            alt=""
            src={resource.image?.url}
          />
        </a>
      </div>
    </div>
  );
};
