import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme } from "@incident-ui";
import { ReactElement } from "react";
import styles from "src/components/slack/AddToSlackButton.module.scss";
import { OnboardingSetDesiredPlanRequestBodyPlanNameEnum as PlanNameEnum } from "src/contexts/ClientContext";

export const AddToSlackButton = ({
  url,
  returnPath,
  teamId,
}: {
  url: string;
  returnPath?: string;
  teamId?: string;
}): ReactElement => {
  const formattedUrl = formatAddToSlackUrl({
    baseUrl: url,
    returnPath,
    teamId,
  });
  return <RawAddToSlackButton url={formattedUrl.toString() || ""} />;
};

// RawAddToSlackButton doesn't do anything to add the state param or return
// URL to the URL, it's just visual rendering
export const RawAddToSlackButton = ({
  url,
  isUserMissingPermissions = false,
}: {
  url: string | null;
  isUserMissingPermissions?: boolean;
}): React.ReactElement => {
  return (
    <GatedButton
      analyticsTrackingId="add-to-slack"
      className={styles.link}
      theme={ButtonTheme.Unstyled}
      style={{ padding: 0 }}
      href={url ?? ""}
      disabled={isUserMissingPermissions}
      disabledTooltipContent={`You don't have appropriate Slack permissions to add this app - please contact an owner/admin of your Slack workspace.`}
    >
      <img
        alt="Add to Slack"
        height={40}
        width={139}
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
        className={isUserMissingPermissions ? "opacity-50" : ""}
      />
    </GatedButton>
  );
};

function formatAddToSlackUrl({
  baseUrl,
  returnPath,
  teamId,
  plan,
}: {
  baseUrl: string;
  returnPath?: string;
  teamId?: string;
  plan?: PlanNameEnum;
}): URL {
  const url = new URL(baseUrl);
  const search = url.searchParams;
  const state: { return_path?: string; product?: string; plan_name?: string } =
    {};

  if (returnPath) {
    state.return_path = returnPath;
  }
  if (plan) {
    state.plan_name = plan;
  }

  if (Object.keys(state).length > 0) {
    const json = JSON.stringify(state);
    const base64 = btoa(json);
    search.set("state", base64);
  }

  if (teamId) {
    // if we have a teamId, we should put it into the auth_uri
    // so the user authenticates with the correct Slack workspace.
    search.set("team", teamId);
  }
  url.search = search.toString();
  return url;
}
