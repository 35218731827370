import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { Popover } from "@incident-ui/Popover/Popover";
import { SelectOption } from "@incident-ui/Select/types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  IncidentStatus,
  IncidentTimestamp,
  IncidentTimestampSetByRule,
  IncidentTimestampSetByRuleSetOnTransitionEnum as TransitionEnum,
  IncidentTimestampSetByRuleSetOnVisitEnum as VisitEnum,
  IncidentTimestampsUpdateSetByRuleRequestBodySetOnTransitionEnum as PayloadTimestampTransitionEnum,
  IncidentTimestampsUpdateSetByRuleRequestBodySetOnVisitEnum as PayloadTimestampVisitEnum,
} from "src/contexts/ClientContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useAPIMutation } from "src/utils/swr";

import { VISIT_OPTIONS } from "../timestamps-metrics/TimestampSetSection";

type EditRuleFormData = {
  set_on_visit: VisitEnum;
  set_on_transition: TransitionEnum;
};

export const TimestampSetByRuleEditPopover = ({
  timestamp,
  rule,
  disabled,
  betweenStatuses: [previousStatus, nextStatus],
}: {
  timestamp: IncidentTimestamp;
  rule: IncidentTimestampSetByRule;
  disabled?: boolean;
  betweenStatuses: [IncidentStatus | undefined, IncidentStatus | undefined];
}): React.ReactElement | null => {
  const [isOpen, setIsOpen] = useState(false);

  const { trigger: onRemoveRule } = useAPIMutation(
    "incidentTimestampsList",
    undefined,
    async (apiClient, _) => {
      await apiClient.incidentTimestampsDestroySetByRule({
        id: rule.id,
      });
    },
    {
      onSuccess: () => setIsOpen(false),
    },
  );

  const { trigger: updateTimestampSetByRule } = useAPIMutation(
    "incidentTimestampsList",
    undefined,
    async (apiClient, data: EditRuleFormData) => {
      const setOnStatusId =
        data.set_on_transition === TransitionEnum.Enter
          ? nextStatus?.id
          : previousStatus?.id;
      if (!setOnStatusId || !rule) {
        return;
      }

      await apiClient.incidentTimestampsUpdateSetByRule({
        id: rule.id,
        updateSetByRuleRequestBody: {
          set_on_visit:
            data.set_on_visit as unknown as PayloadTimestampVisitEnum,
          set_on_transition:
            data.set_on_transition as unknown as PayloadTimestampTransitionEnum,
          set_on_status_id: setOnStatusId,
        },
      });
    },
  );

  const defaultValues = {
    set_on_visit: rule.set_on_visit,
    set_on_transition: rule.set_on_transition,
  };

  const { setState, hasSaved, saving } = useOptimisticAutoSave({
    saveState: updateTimestampSetByRule,
    initialState: defaultValues,
  });

  const formMethods = useForm<EditRuleFormData>({
    defaultValues,
  });

  useEffect(() => {
    formMethods.watch((data) => {
      // Push this back into the optimistic-save state
      setState((prevState) => ({
        ...prevState,
        ...data,
      }));
    });
  }, [formMethods, setState]);

  const transitionOptions: SelectOption[] = [];
  if (previousStatus) {
    transitionOptions.push({
      value: TransitionEnum.Leave,
      label: `leaves ${previousStatus.name}`,
      sort_key: "1",
    });
  }
  if (nextStatus) {
    transitionOptions.push({
      value: TransitionEnum.Enter,
      label: `enters ${nextStatus.name}`,
      sort_key: "2",
    });
  }

  return (
    <Popover
      onInteractOutside={() => {
        setIsOpen(false);
      }}
      trigger={
        <Button
          theme={ButtonTheme.Naked}
          analyticsTrackingId={null}
          onClick={() => setIsOpen(true)}
          disabled={disabled}
        >
          <div className="flex items-center gap-1">
            <div>{timestamp.name}</div>
            <Icon
              id={IconEnum.Edit}
              size={IconSize.Small}
              className="hidden group-hover:!block"
            />
          </div>
        </Button>
      }
      className="!p-0 w-[500px]"
      sideOffset={-20}
      align="start"
      open={isOpen}
    >
      <FormV2 formMethods={formMethods} onSubmit={updateTimestampSetByRule}>
        <div>
          <div className="flex-center-y justify-between pr-2 bg-surface-secondary border-b border-stroke rounded-t-md">
            <div className="flex items-center gap-2">
              <span className="w-full uppercase text-xs font-medium text-content-tertiary p-2 flex-center-y tracking-widest">
                {timestamp.name}
              </span>
              <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
            </div>
            <Button
              title="Close"
              analyticsTrackingId={null}
              theme={ButtonTheme.Naked}
              icon={IconEnum.Close}
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="flex items-center gap-4 justify-between p-4">
            <div className="text-sm flex items-center gap-1">
              <div>Set when</div>
              <PopoverSingleSelectV2
                className="shrink-0"
                formMethods={formMethods}
                required
                name={`set_on_visit`}
                options={VISIT_OPTIONS}
                triggerStyle="inline-button"
              />
              <PopoverSingleSelectV2
                className="shrink-0"
                formMethods={formMethods}
                required
                name={`set_on_transition`}
                options={transitionOptions}
                triggerStyle="inline-button"
              />
            </div>
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId={null}
              onClick={() => onRemoveRule({})}
            >
              Remove
            </Button>
          </div>
        </div>
      </FormV2>
    </Popover>
  );
};
