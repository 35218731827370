import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxGroupV2 } from "@incident-shared/forms/v2/inputs/CheckboxGroupV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Callout,
  CalloutTheme,
  CopiableText,
  Link,
  ModalFooter,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { APIKeysCreateRequestBody } from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { toSentenceCase } from "../../../utils/formatting";

enum ModalSteps {
  CreateKey = "create-key",
  CopyKey = "copy-key",
}

export const APIKeysCreateModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  const [step, setStep] = useState<ModalSteps>(ModalSteps.CreateKey);
  const [token, setToken] = useState<string | null>(null);
  const {
    data: { roles },
    isLoading,
    error,
  } = useAPI("aPIKeysListRolesAndAvailability", undefined, {
    fallbackData: { roles: [] },
  });

  const formMethods = useForm<APIKeysCreateRequestBody>();
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "aPIKeysList",
    undefined,
    async (apiClient, data) => {
      const { token } = await apiClient.aPIKeysCreate({
        createRequestBody: data,
      });
      setToken(token);
    },
    {
      onSuccess: () => {
        setStep(ModalSteps.CopyKey);
      },
      setError: formMethods.setError,
    },
  );

  if (error) {
    const err = new Error("failed to load roles");
    err.cause = error;
    return <ErrorModal onClose={onClose} error={err} />;
  }

  return (
    <FormModalV2
      onClose={onClose}
      analyticsTrackingId="create-api-key"
      title="Create API Key"
      loading={isLoading}
      onSubmit={onSubmit}
      formMethods={formMethods}
      genericError={genericError}
      footer={
        step === ModalSteps.CreateKey ? (
          <ModalFooter
            confirmButtonType="submit"
            confirmButtonText="Create"
            onClose={onClose}
            saving={saving}
          />
        ) : (
          <ModalFooter
            confirmButtonType="button"
            confirmButtonText="Done"
            onConfirm={onClose}
          />
        )
      }
    >
      {step === ModalSteps.CreateKey && (
        <>
          <InputV2
            formMethods={formMethods}
            name="name"
            label="Name"
            helptext="A human-readable name for your reference"
            required="Please enter a name"
          />
          {roles.some((role) => !role.available) && (
            <Callout
              theme={CalloutTheme.Plain}
              title="Some permissions are not available"
              subtitle={
                <p>
                  You can only add permissions to an API key for actions you are
                  authorised to perform as a user. If you need to create an API
                  key with additional permissions, you can ask an{" "}
                  <Link
                    href="/settings/users/users"
                    analyticsTrackingId={null}
                    openInNewTab
                  >
                    Owner
                  </Link>{" "}
                  from your organisation.
                </p>
              }
            />
          )}
          <CheckboxGroupV2
            formMethods={formMethods}
            name="roles"
            helptext="What this key will be able to do"
            label="Choose permissions"
            options={(roles || []).map((role) => ({
              value: role.name,
              label: toSentenceCase(role.description.replace("Can ", "")),
              disabled: !role.available,
              disabledTooltipContent: !role.available
                ? "You are not authorised to perform this action, please ask an Owner from your organisation."
                : undefined,
            }))}
            required="Please choose at least one"
          />
        </>
      )}
      {step === ModalSteps.CopyKey && (
        <>
          <p className="text-sm">
            Your API key is ready! Here&apos;s your unique access token -
            we&apos;ll only show this once.
          </p>
          <p className="text-sm">
            If you lose it, you&apos;ll need to create a new token.
          </p>
          <CopiableText value={token ?? ""} className="mt-2" />
        </>
      )}
    </FormModalV2>
  );
};
