/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsightsCustomisableDashboard,
    InsightsCustomisableDashboardFromJSON,
    InsightsCustomisableDashboardFromJSONTyped,
    InsightsCustomisableDashboardToJSON,
} from './InsightsCustomisableDashboard';

/**
 * 
 * @export
 * @interface InsightsShowDashboardResponseBody
 */
export interface InsightsShowDashboardResponseBody {
    /**
     * 
     * @type {InsightsCustomisableDashboard}
     * @memberof InsightsShowDashboardResponseBody
     */
    dashboard: InsightsCustomisableDashboard;
}

export function InsightsShowDashboardResponseBodyFromJSON(json: any): InsightsShowDashboardResponseBody {
    return InsightsShowDashboardResponseBodyFromJSONTyped(json, false);
}

export function InsightsShowDashboardResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsShowDashboardResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboard': InsightsCustomisableDashboardFromJSON(json['dashboard']),
    };
}

export function InsightsShowDashboardResponseBodyToJSON(value?: InsightsShowDashboardResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboard': InsightsCustomisableDashboardToJSON(value.dashboard),
    };
}

