/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionBranchesOptsPayload,
    ExpressionBranchesOptsPayloadFromJSON,
    ExpressionBranchesOptsPayloadFromJSONTyped,
    ExpressionBranchesOptsPayloadToJSON,
} from './ExpressionBranchesOptsPayload';
import {
    ExpressionFilterOptsPayload,
    ExpressionFilterOptsPayloadFromJSON,
    ExpressionFilterOptsPayloadFromJSONTyped,
    ExpressionFilterOptsPayloadToJSON,
} from './ExpressionFilterOptsPayload';
import {
    ExpressionNavigateOptsPayload,
    ExpressionNavigateOptsPayloadFromJSON,
    ExpressionNavigateOptsPayloadFromJSONTyped,
    ExpressionNavigateOptsPayloadToJSON,
} from './ExpressionNavigateOptsPayload';
import {
    ExpressionParseOptsPayload,
    ExpressionParseOptsPayloadFromJSON,
    ExpressionParseOptsPayloadFromJSONTyped,
    ExpressionParseOptsPayloadToJSON,
} from './ExpressionParseOptsPayload';

/**
 * 
 * @export
 * @interface ExpressionOperationPayload
 */
export interface ExpressionOperationPayload {
    /**
     * 
     * @type {ExpressionBranchesOptsPayload}
     * @memberof ExpressionOperationPayload
     */
    branches?: ExpressionBranchesOptsPayload;
    /**
     * 
     * @type {ExpressionFilterOptsPayload}
     * @memberof ExpressionOperationPayload
     */
    filter?: ExpressionFilterOptsPayload;
    /**
     * 
     * @type {ExpressionNavigateOptsPayload}
     * @memberof ExpressionOperationPayload
     */
    navigate?: ExpressionNavigateOptsPayload;
    /**
     * The type of the operation
     * @type {string}
     * @memberof ExpressionOperationPayload
     */
    operation_type: ExpressionOperationPayloadOperationTypeEnum;
    /**
     * 
     * @type {ExpressionParseOptsPayload}
     * @memberof ExpressionOperationPayload
     */
    parse?: ExpressionParseOptsPayload;
}

/**
* @export
* @enum {string}
*/
export enum ExpressionOperationPayloadOperationTypeEnum {
    Navigate = 'navigate',
    Filter = 'filter',
    Count = 'count',
    Min = 'min',
    Max = 'max',
    Random = 'random',
    First = 'first',
    Parse = 'parse',
    Branches = 'branches'
}

export function ExpressionOperationPayloadFromJSON(json: any): ExpressionOperationPayload {
    return ExpressionOperationPayloadFromJSONTyped(json, false);
}

export function ExpressionOperationPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpressionOperationPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'branches': !exists(json, 'branches') ? undefined : ExpressionBranchesOptsPayloadFromJSON(json['branches']),
        'filter': !exists(json, 'filter') ? undefined : ExpressionFilterOptsPayloadFromJSON(json['filter']),
        'navigate': !exists(json, 'navigate') ? undefined : ExpressionNavigateOptsPayloadFromJSON(json['navigate']),
        'operation_type': json['operation_type'],
        'parse': !exists(json, 'parse') ? undefined : ExpressionParseOptsPayloadFromJSON(json['parse']),
    };
}

export function ExpressionOperationPayloadToJSON(value?: ExpressionOperationPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'branches': ExpressionBranchesOptsPayloadToJSON(value.branches),
        'filter': ExpressionFilterOptsPayloadToJSON(value.filter),
        'navigate': ExpressionNavigateOptsPayloadToJSON(value.navigate),
        'operation_type': value.operation_type,
        'parse': ExpressionParseOptsPayloadToJSON(value.parse),
    };
}

