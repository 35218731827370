import { CatalogType } from "@incident-io/api";
import { getColorPalette } from "@incident-shared/utils/ColorPalettes";
import {
  Checkbox,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  LoadingBar,
  Tooltip,
  Txt,
} from "@incident-ui";
import { SearchBar } from "@incident-ui/SearchBar/SearchBar";
import { Searcher } from "fast-fuzzy";
import pluralize from "pluralize";
import { ChangeEvent, useState } from "react";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./CatalogTypeManageInGitHubSelectTypesStep.module.scss";

export const CatalogTypeCheckboxGroup = ({
  value = [],
  onChange,
  downloadingRepo = false,
}: {
  value: string[];
  onChange: (val: string[]) => void;
  downloadingRepo?: boolean;
}): React.ReactElement => {
  const {
    data: { catalog_types: allTypes },
    isLoading: typesLoading,
    error: typesError,
  } = useAPI(
    "catalogListTypes",
    { includeCount: true },
    { fallbackData: { catalog_types: [] } },
  );

  const [searchTerm, setSearchTerm] = useState<string>("");

  const catalogTypes = allTypes.filter((type) => {
    return (
      type.mode === "manual" // Only include manual types
    );
  });

  if (typesError) {
    return <GenericErrorMessage error={typesError} />;
  }
  if (typesLoading) {
    return <LoadingBar className="h-32" />;
  }

  const searcher = new Searcher(catalogTypes, {
    keySelector: (s) => [s.name, s.description],
    threshold: 0.8,
  });

  const visibleCatalogTypes = searchTerm
    ? searcher.search(searchTerm)
    : catalogTypes;

  const isExternallyManaged = (catalogType: CatalogType): boolean => {
    return (
      !!catalogType.source_repo_url ||
      !!catalogType.annotations["incident.io/catalog-importer/last-sync-at"]
    );
  };

  // Sort catalog types by (1) whether they're externally managed and (2) by name
  visibleCatalogTypes.sort((a, b) => {
    if (isExternallyManaged(a) && !isExternallyManaged(b)) {
      return 1;
    }
    if (!isExternallyManaged(a) && isExternallyManaged(b)) {
      return -1;
    }
    return a.name > b.name ? 1 : -1;
  });

  return (
    <div className="flex flex-col gap-4">
      {/* Only show the search bar if there are more than 5 types */}
      {catalogTypes.length > 5 && (
        <SearchBar
          value={searchTerm}
          onChange={(value: string) => {
            setSearchTerm(value);
          }}
          className="mr-auto"
          placeholder="Search catalog types"
          autoFocus
        />
      )}
      {/* Search bar empty state */}
      {visibleCatalogTypes.length === 0 && !!searchTerm && (
        <Txt lightGrey>No results found for &quot;{searchTerm}&quot;</Txt>
      )}
      {/* Search bar results */}
      <div>
        {visibleCatalogTypes.map((catalogType, idx) => {
          const iconClasses = getColorPalette(catalogType.color);
          const isFirst = idx === 0;
          const isLast = idx === catalogTypes.length - 1;

          const isSelected = value.includes(catalogType.id);
          const previousIsSelected = isFirst
            ? false
            : value.includes(catalogTypes[idx - 1].id);
          const nextIsSelected = isLast
            ? false
            : value.includes(catalogTypes[idx + 1].id);

          const isExternal = isExternallyManaged(catalogType);
          const catalogTypeDisabled = downloadingRepo || isExternal;

          return (
            <Tooltip
              content={
                isExternal
                  ? "You're already managing this catalog type externally."
                  : undefined
              }
              // Never open the tooltip unless this is an external catalog type
              openStateOverride={isExternal ? undefined : false}
              key={catalogType.id}
            >
              <label
                htmlFor={catalogType.id}
                className={tcx(
                  "flex-center-y p-5 gap-3 border-x border-b",
                  downloadingRepo
                    ? "cursor-wait"
                    : catalogTypeDisabled
                    ? "cursor-not-allowed"
                    : "cursor-pointer",
                  isSelected ? "border-slate-900" : "border-stroke",
                  isSelected && !previousIsSelected && "border-t",
                  isFirst && "rounded-t-xl border-t",
                  isLast && "rounded-b-xl border-b",
                  !isSelected && nextIsSelected && "border-b-0",
                  catalogTypeDisabled && "bg-slate-50",
                )}
              >
                <div
                  className={tcx(
                    iconClasses.background,
                    iconClasses.border,
                    iconClasses.icon,
                    "mr-1.5 rounded-2 p-1.5",
                  )}
                >
                  <Icon
                    id={isExternal ? IconEnum.Tick : catalogType.icon}
                    size={IconSize.XL}
                  />
                </div>
                <div className="flex flex-col grow min-w-0">
                  <div className="flex break-words">
                    <Txt inline bold className="flex-center text-sm">
                      {catalogType.name}
                    </Txt>
                  </div>
                  <Txt lightGrey className="text-xs">
                    {catalogType.description}
                  </Txt>
                </div>
                <Txt className="bg-surface-secondary border border-stroke rounded py-0.5 px-2 text-sm shrink-0">
                  {catalogType.estimated_count}{" "}
                  {pluralize("entry", catalogType.estimated_count)}
                </Txt>
                <Checkbox
                  id={catalogType.id}
                  checkboxClassName={tcx(
                    "h-6 w-6 shadow-none",
                    styles.checkbox,
                  )}
                  checked={value.includes(catalogType.id)}
                  disabled={catalogTypeDisabled}
                  onChange={(boolVal: ChangeEvent<HTMLInputElement>) => {
                    if (boolVal.target.checked) {
                      return onChange([...value, catalogType.id]);
                    }

                    return onChange(
                      value.filter((item) => item !== catalogType.id),
                    );
                  }}
                />
              </label>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};
