import { ConfigureDrawerProps } from "@incident-shared/integrations";
import { Button, ButtonTheme, GenericErrorMessage } from "@incident-ui";
import { AlertSourceConfigRow } from "src/components/alerts/alert-source-list/AlertSourceListPage";
import styles from "src/components/alerts/alert-source-list/AlertSourcesEmptyState.module.scss";
import AlertSourceVisual from "src/components/alerts/images/alerts-empty-state.png";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "./IntegrationDrawer";

export const AlertSourceConfigTeaser = ({
  sourceType,
}: {
  sourceType: string;
}): React.ReactElement => {
  return (
    <div
      className={tcx(
        "flex flex-col gap-4 items-center rounded-2",
        styles.wrapper,
      )}
    >
      <img src={AlertSourceVisual} />
      <div className="text-content-secondary">
        Connect your alert sources and route your alerts to automatically create
        incidents within incident.io
      </div>
      <Button
        analyticsTrackingId="alert-sources-create-from-integration"
        theme={ButtonTheme.Primary}
        href={`/alerts/sources/create?source_type=${sourceType}`}
      >
        Get started
      </Button>
    </div>
  );
};

export const AlertSourceDrawer = (props: ConfigureDrawerProps) => {
  const {
    data: { alert_source_configs: alertSourceConfigs },
    isLoading: sourceConfigsLoading,
    error: sourceConfigsError,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  const { integrations, integrationsLoading, integrationsError } =
    useIntegrations();

  const {
    data: { alert_sources: alertSources },
    isLoading: sourcesLoading,
    error: sourcesError,
  } = useAPI("alertsListSources", undefined, {
    fallbackData: { alert_sources: [] },
  });

  if (
    sourceConfigsLoading ||
    integrationsLoading ||
    sourcesLoading ||
    !integrations
  ) {
    return <IntegrationDrawerContentLoader />;
  }
  if (sourceConfigsError || integrationsError || sourcesError) {
    return <GenericErrorMessage error={sourceConfigsError} />;
  }

  const thisAlertSource = alertSources.find(
    (as) => as.source_type === props.integration.provider,
  );

  return (
    <GenericConfigureDrawerContents {...props}>
      <div className="flex flex-col gap-3">
        <div className="text-base-bold text-content-primary">Alert sources</div>
        <div className="flex flex-col rounded-2 border border-stroke divide-y divide-slate-100">
          {alertSourceConfigs
            .filter((s) => s.source_type === props.integration.provider)
            .map((s) => (
              <AlertSourceConfigRow
                key={s.id}
                alertSourceConfig={s}
                alertSource={thisAlertSource}
                integrations={integrations}
              />
            ))}
        </div>
      </div>
    </GenericConfigureDrawerContents>
  );
};
