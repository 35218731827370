import {
  Incident,
  IncidentsCreateUpdateRequestBodyPostIncidentFlowDecisionEnum,
  IncidentStatusCategoryEnum,
} from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { UpdateOnlyData } from "@incident-shared/incident-forms";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { LoadingModal, ModalFooter } from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { ActiveIncidentDecision } from "../statuses/ActiveIncidentDecisionRadio";
import { PostIncidentFlowDecision } from "../statuses/PostIncidentDecisionRadio";
import { Category } from "../statuses/status-utils";
import {
  IncidentStatusFormData,
  StatusFormElement,
} from "../statuses/StatusFormElement";
import { OpenStreamsWarning } from "../streams/OpenStreamsWarning";
import {
  useIncidentCrudResources,
  useStatusesForIncident,
} from "../useIncidentCrudResources";
import { IncidentResolveForm } from "./ResolveIncidentModal";
import {
  IncidentPauseSection,
  IncidentUpdateFormStep,
} from "./UpdateIncidentModal";

export const UpdateStatusModal = ({
  incident,
  onClose,
}: {
  onClose: () => void;
  incident: Incident;
}) => {
  type UpdateStatusFormData = IncidentStatusFormData & UpdateOnlyData;
  const formMethods = useForm<UpdateStatusFormData>({
    defaultValues: {
      incident_status_id: incident.incident_status.id,
      incident_status_decision: ActiveIncidentDecision.Stay,
    },
  });

  const { ...resources } = useIncidentCrudResources();
  const { statusesLoading, statuses } = useStatusesForIncident({ incident });
  const [formStep, setFormStep] = useState(IncidentUpdateFormStep.Updating);

  const { watch } = formMethods;
  const selectedDecision = watch("incident_status_decision");

  const {
    data: { streams },
    isLoading: streamsLoading,
  } = useAPI(
    "streamsList",
    { parentId: incident.id },
    { fallbackData: { streams: [] } },
  );
  const openStreams = streams.filter(
    (stream) => stream.status.category === IncidentStatusCategoryEnum.Active,
  );
  const openStreamsBlockResolving =
    openStreams.length > 0 &&
    selectedDecision === ActiveIncidentDecision.Resolved;

  const { trigger: createUpdate, isMutating } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, formData: UpdateStatusFormData) => {
      await apiClient.incidentsCreateUpdate({
        incidentId: incident.id,
        createUpdateRequestBody: {
          to_incident_status_id: formData.incident_status_id,
          unpause_in_minutes: formData.unpause_in_minutes,
          post_incident_flow_decision:
            formData.incident_status_decision ===
            PostIncidentFlowDecision.OptOut
              ? IncidentsCreateUpdateRequestBodyPostIncidentFlowDecisionEnum.OptOut
              : undefined,
        },
      });
    },
    {
      onSuccess: onClose,
      setError: formMethods.setError,
    },
  );

  const onSubmit = (formData: UpdateStatusFormData) => {
    if (formData.incident_status_decision === ActiveIncidentDecision.Resolved) {
      setFormStep(IncidentUpdateFormStep.Resolving);
    } else {
      if (formData.incident_status_decision === ActiveIncidentDecision.Paused) {
        // Set to incident status to paused
        const pausedStatus = statuses.find(
          (s) => s.category === Category.Paused,
        );
        if (!pausedStatus) {
          throw new Error("No paused status found");
        }
        formData.incident_status_id = pausedStatus.id;

        if (formData.unpause_in_minutes) {
          if ((formData.unpause_in_minutes as unknown) === "custom") {
            const until = formData.unpause_as_date || new Date();
            const now = new Date();
            const untilInMinutes =
              (until.getTime() - now.getTime()) / (1000 * 60);

            formData.unpause_in_minutes = Math.ceil(untilInMinutes);
          } else {
            formData.unpause_in_minutes = parseInt(
              formData.unpause_in_minutes.toString(),
            );
          }
        }
      }

      createUpdate(formData);
    }
  };

  // If the incident is in triage, redirect to the update incident modal
  // this is only possible by going directly to the URL, but we should still handle it
  if (incident.incident_status.category === Category.Triage) {
    return (
      <OrgAwareNavigate
        replace
        to={`/incidents/${incident.external_id}/update-incident`}
      />
    );
  }

  if (formStep === IncidentUpdateFormStep.Resolving) {
    return (
      <IncidentResolveForm
        {...resources}
        onClose={onClose}
        incident={incident}
        statuses={statuses}
      />
    );
  }

  if (statusesLoading || streamsLoading) {
    return <LoadingModal title={"Update status"} onClose={onClose} />;
  }

  let buttonText = "Save";
  if (selectedDecision === ActiveIncidentDecision.Resolved) {
    buttonText = "Next";
  }

  return (
    <FormModalV2
      title="Update status"
      analyticsTrackingId={"update-incident-severity-modal"}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonText={buttonText}
          saving={isMutating}
          onClose={onClose}
          confirmButtonType="submit"
          disabled={openStreamsBlockResolving}
        />
      }
    >
      <StatusFormElement formMethods={formMethods} incident={incident} />
      {selectedDecision === ActiveIncidentDecision.Paused && (
        <IncidentPauseSection />
      )}
      {openStreamsBlockResolving && (
        <OpenStreamsWarning incident={incident} openStreams={openStreams} />
      )}
    </FormModalV2>
  );
};
