import {
  AlertSource,
  AlertSourceConfig,
  AlertSourceSourceTypeEnum,
  Identity,
  IntegrationSettingsProviderEnum,
  IntegrationSettingsReconnectionReasonEnum,
  SAMLShowResponseBody,
  SCIMShowSettingsResponseBody,
} from "@incident-io/api";
import {
  ALERT_SOURCE_TYPE_CONFIGS,
  drawerUrlFor,
  fakeIntegrations,
  INTEGRATION_CONFIGS,
  IntegrationConfigFor,
  IntegrationListProvider,
  IntegrationsListObject,
  SCIM_PROVIDER_CONFIGS,
  SCIMProviderEnum,
} from "@incident-shared/integrations";
import { GenericErrorMessage, Loader } from "@incident-ui";
import { sortBy } from "lodash";
import { Route, Routes, useOutlet } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI } from "src/utils/swr";

// TODO RESP-1380: All of these need migrating into this as part of the Pattern Party
import { IntegrationDrawer } from "./list/IntegrationDrawer";
import { IntegrationsListPage } from "./list/IntegrationsListPage";
import { ConfigurePagerDutyPage } from "./list/pagerduty/PagerDutyConfigurePage";

export const IntegrationsRoute = (): React.ReactElement => {
  const { loading, error, integrations, alertSourcesToShow } =
    useIntegrationsList();

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <>
      {/* <AtlassianErrorModal /> */}
      <Routes>
        <Route
          path="pagerduty/configure-legacy-trigger"
          element={
            <ConfigurePagerDutyPage backHref="settings/integrations/pagerduty" />
          }
        />
        <Route
          path="/"
          element={
            <IntegrationsListWithOutlet
              integrations={integrations}
              loading={loading}
            />
          }
        >
          {Object.keys(INTEGRATION_CONFIGS).map((provider) => (
            <Route
              key={provider}
              path={drawerUrlFor(provider as IntegrationListProvider)}
              element={
                <IntegrationDrawer
                  activeIntegration={provider as IntegrationListProvider}
                  integrations={integrations}
                  backHref="/settings/integrations"
                />
              }
            />
          ))}
          {alertSourcesToShow.map((sourceType) => (
            <Route
              key={sourceType}
              path={sourceType}
              element={
                <IntegrationDrawer
                  activeIntegration={sourceType as AlertSourceSourceTypeEnum}
                  integrations={integrations}
                  backHref="/settings/integrations"
                />
              }
            />
          ))}
          {Object.values(SCIMProviderEnum).map((provider) => (
            <Route
              key={provider}
              path={drawerUrlFor(provider as IntegrationListProvider)}
              element={
                <IntegrationDrawer
                  activeIntegration={provider as IntegrationListProvider}
                  integrations={integrations}
                  backHref="/settings/integrations"
                />
              }
            />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export const useIntegrationsList = () => {
  const { identity } = useIdentity();

  const {
    integrations: apiIntegrations,
    integrationsLoading,
    integrationsError,
  } = useIntegrations();

  const {
    data: { alert_sources: alertSources },
    isLoading: sourcesLoading,
    error: sourcesError,
  } = useAPI("alertsListSources", undefined, {
    fallbackData: { alert_sources: [] },
  });

  const {
    data: { alert_source_configs: sourceConfigs },
    isLoading: sourceConfigsLoading,
    error: sourceConfigsError,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  const {
    data: samlConfigState,
    isLoading: samlLoading,
    error: samlError,
  } = useAPI("sAMLShow", undefined);

  const {
    data: scimConfigState,
    isLoading: scimLoading,
    error: scimError,
  } = useAPI("sCIMShowSettings", undefined);

  const integrations = makeIntegrationsList({
    integrations: apiIntegrations || [],
    alertSources,
    sourceConfigs,
    samlConfigState,
    scimConfigState,
    identity,
  });

  const alertSourcesToShow = Object.entries(ALERT_SOURCE_TYPE_CONFIGS)
    .filter(([_, config]) => !config.hideFromIntegrationsList)
    .map(([sourceType]) => sourceType);

  return {
    loading:
      integrationsLoading ||
      sourcesLoading ||
      sourceConfigsLoading ||
      samlLoading ||
      scimLoading,
    error:
      integrationsError ||
      sourcesError ||
      sourceConfigsError ||
      samlError ||
      scimError,
    integrations,
    alertSourcesToShow,
  };
};

const IntegrationsListWithOutlet = ({
  integrations,
  loading,
}: {
  integrations: IntegrationsListObject[];
  loading?: boolean;
}) => {
  // The outlet renders any modal or drawer that is open. We render it like this
  // so that it can be _directly_ wrapped in AnimatePresence, to give us exit
  // animations.
  //
  // Sadly exit animations have a habit of causing the drawer to not actually
  // unmount after the animation, so they are currently disabled.
  const drawerOrModal = useOutlet();

  return (
    <>
      {drawerOrModal}
      {loading ? (
        <Loader />
      ) : (
        <IntegrationsListPage integrations={integrations} />
      )}
    </>
  );
};

const makeIntegrationsList = ({
  integrations,
  alertSources,
  sourceConfigs,
  samlConfigState,
  scimConfigState,
  identity,
}: {
  integrations: IntegrationsListObject[];
  alertSources: AlertSource[];
  sourceConfigs: AlertSourceConfig[];
  samlConfigState?: SAMLShowResponseBody;
  scimConfigState?: SCIMShowSettingsResponseBody;
  identity: Identity;
}): IntegrationsListObject[] => {
  const alertSourceAsIntegrations = alertSources
    .filter(
      (as) =>
        ALERT_SOURCE_TYPE_CONFIGS[as.source_type]?.hideFromIntegrationsList !==
        true,
    )
    .map((source) => {
      const hasConfig = sourceConfigs.some(
        (c) => c.source_type === source.source_type,
      );

      return {
        installed: hasConfig,
        provider: source.source_type,
        available_catalog_types: [],
        requires_upgrade: false,
        provider_name: source.name,
        reconnection_reason: IntegrationSettingsReconnectionReasonEnum.Empty,
      };
    });

  const scimProvider = scimConfigState?.scim_config?.directory_type;
  const samlProvider = samlConfigState?.saml_settings?.connection_type;

  const scimIntegrationObject = integrations.find(
    (i) => i.provider === IntegrationSettingsProviderEnum.Scim,
  );

  const scimProvidersAsIntegrations: IntegrationsListObject[] = Object.entries(
    SCIM_PROVIDER_CONFIGS,
  ).map(([provider, config]) => ({
    installed:
      config.samlWorkOSProvider === samlProvider ||
      config.scimWorkOSProvider === scimProvider,
    provider: provider as SCIMProviderEnum,
    available_catalog_types:
      scimIntegrationObject?.available_catalog_types || [],
    requires_upgrade:
      !identity.feature_gates?.saml && !identity.feature_gates.scim,
    provider_name: config.label,
    reconnection_reason: IntegrationSettingsReconnectionReasonEnum.Empty,
    add_integration_url: scimConfigState?.scim_config?.self_serve_portal_url,
  }));

  return sortBy(
    [
      ...integrations,
      ...fakeIntegrations,
      ...alertSourceAsIntegrations,
      ...scimProvidersAsIntegrations,
    ],
    ({ provider }) => IntegrationConfigFor(provider).label,
  ).filter(({ provider }) => {
    const config = IntegrationConfigFor(provider);
    return !config.hideFromListPage;
  });
};
