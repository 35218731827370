import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Loader,
  Modal,
  ModalContent,
} from "@incident-ui";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { ConnectModal } from "src/components/settings/security/user-token/ConnectModal";
import { AddToSlackButton } from "src/components/slack/AddToSlackButton";
import {
  SlackInfoReconnectionReasonEnum as ReconnectionReason,
  SlackInfoUserTokenBehavioursEnum as BehaviourEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useMutation } from "src/utils/fetchData";
import { useQueryParams } from "src/utils/query-params";

import { SetupScreen } from "../SetupScreen";

const InstallBlurb = ({
  askForUserToken,
  wrongUserRole,
  reasonCode,
}: {
  askForUserToken: boolean;
  wrongUserRole: boolean;
  reasonCode: ReconnectionReason;
}): React.ReactElement => {
  const { showArticle } = useIntercom();

  useEffect(() => {
    if (reasonCode === ReconnectionReason.MissingChannelCreatePermissions) {
      showArticle(askForUserToken ? 6814846 : 5667395);
    }
  }, [askForUserToken, reasonCode, showArticle]);

  switch (reasonCode) {
    case ReconnectionReason.MissingChannelCreatePermissions:
      return (
        <div className="space-y-2">
          <Callout theme={CalloutTheme.Danger} className="mb-4">
            Your Slack workspace doesn’t allow bots to create channels, so we
            can’t complete our installation.
          </Callout>
          {wrongUserRole && (
            <Callout theme={CalloutTheme.Danger} className="mb-4">
              You must be logged in as a Slack admin or owner.
            </Callout>
          )}
          <p>
            We need to be able to create incident channels to give you a place
            to coordinate and communicate during the incident.
          </p>
        </div>
      );
    case ReconnectionReason.AnnouncementChannelsAlreadyExist:
      return (
        <div>
          <Callout theme={CalloutTheme.Danger} className="mb-4">
            {`It looks like we were unable to complete our installation because channels #incidents and #incident-io-incidents already exist. Please rename one of them to something else. You can rename the channel we create after you complete the installation.`}
          </Callout>
          <p className="mb-2">
            {
              "If you have any further questions please contact our support team by emailing "
            }
            <a href="mailto:support@incident.io" className="underline">
              support@incident.io
            </a>
            .
          </p>
        </div>
      );
    default:
      return (
        <div className="text-content-primary space-y-2 p-2 text-m">
          <p>Now you’re signed in, let’s install our Slack App 🎉</p>
          <p className="!mb-1">This will:</p>
          <>
            <ol className="list-decimal pl-5 space-y-1 mb-2">
              <li>
                Create an <code>#incidents</code> channel for announcing new
                incidents
              </li>
              <li>
                Add a <code>/incident</code> command so you can create new
                incidents
              </li>
              <li>Add our bot, so we can create incident channels</li>
            </ol>
          </>
        </div>
      );
  }
};

enum ResolveMissingPermission {
  ChangedPermissions = "changed_permissions",
  UseServiceAccount = "use_service_account",
}
type MissingPermissionFormData = {
  strategy: ResolveMissingPermission;
};

const UserTokenRequest = (): React.ReactElement => {
  const formMethods = useForm<MissingPermissionFormData>();
  const { setError, watch } = formMethods;
  const { identity, fetchIdentity } = useIdentity();
  const apiClient = useClient();

  const strategy = watch("strategy");
  const isConnecting = strategy === ResolveMissingPermission.UseServiceAccount;
  const [isOpen, setIsOpen] = useState(false);
  const [retryInstall, { saving: retryingInstall }] = useMutation(
    async () => {
      await apiClient.authRetryAnnouncementChannelsCreate();
      await fetchIdentity();
      setIsOpen(false);
    },
    {
      setError: (_, error) => {
        // There's only one place to put the error
        setError("strategy", error);
        setIsOpen(false); // ensure the modal is close so that user can see the error in the form
      },
    },
  );

  if (!identity) {
    return <Loader />;
  }

  return (
    <FormV2 formMethods={formMethods} onSubmit={() => setIsOpen(true)}>
      <ConnectModal
        isOpen={isOpen && isConnecting}
        onClose={() => setIsOpen(false)}
        identity={identity}
      />

      <Modal
        isOpen={isOpen && !isConnecting}
        onClose={() => setIsOpen(false)}
        title="Complete Slack installation"
        analyticsTrackingId={"resolve-permissions-retry"}
      >
        <ModalContent className="text-sm space-y-4">
          <p>
            We&apos;ll try to create the <code>#incidents</code> channel again.
          </p>
          <p className="text-center">
            <Button
              onClick={() => retryInstall({})}
              loading={retryingInstall}
              analyticsTrackingId="resolve-permissions-retry"
              theme={ButtonTheme.Primary}
            >
              Let&apos;s go
            </Button>
          </p>
        </ModalContent>
      </Modal>

      <div className="text-left">
        <RadioButtonGroupV2
          formMethods={formMethods}
          name="strategy"
          srLabel="How would you like to proceed?"
          required
          options={[
            {
              label: "I've changed channel creation permissions",
              value: ResolveMissingPermission.ChangedPermissions,
            },
            {
              label: "I'd like to use a Slack admin user",
              value: ResolveMissingPermission.UseServiceAccount,
            },
          ]}
        />
      </div>
      <Button
        disabled={!strategy}
        theme={!strategy ? ButtonTheme.Secondary : ButtonTheme.Primary}
        analyticsTrackingId="resolve-install"
        type="submit"
      >
        Continue
      </Button>
    </FormV2>
  );
};

// SetupInstallSlack is the explanation and CTA for someone hitting the setup flow to
// install our app into Slack. If that's failed and there's an
// slack_reconnection_reason set, we will see text telling them what to do next
// (as defined in InstallBlurb).
export const SetupInstallSlack = (): React.ReactElement => {
  const { identity } = useIdentity();

  const queryParams = useQueryParams();
  const wrongUserRole = queryParams.get("message") === "user-token-bad-role";

  const askForUserToken = (
    identity.slack_info?.user_token_behaviours || []
  ).includes(BehaviourEnum.CreatePublicChannel);

  // For a non-Slack org, send them to the MS Teams setup flow
  const slackInfo = identity.slack_info;
  if (!slackInfo) {
    return <OrgAwareNavigate to="/setup/msteams" />;
  }

  return (
    <>
      <SetupScreen title={"Let’s add incident.io to Slack"}>
        <>
          <div className="block text-sm font-base text-slate-700 space-y-4 text-left">
            <InstallBlurb
              reasonCode={slackInfo.reconnection_reason}
              askForUserToken={askForUserToken}
              wrongUserRole={wrongUserRole}
            />
          </div>
          <hr className="px-2 mt-6 mb-8" />
          {askForUserToken ? (
            <UserTokenRequest />
          ) : (
            <AddToSlackButton
              url={slackInfo.install_url}
              returnPath={"/setup"}
            />
          )}
        </>
      </SetupScreen>
      <div className="mt-3 text-slate-700 text-sm text-center">
        We only ask for Slack permissions we need to do our job.
        <br />
        See our{" "}
        <Button
          href="https://help.incident.io/en/collections/3766495-security-faqs"
          theme={ButtonTheme.Link}
          className="underline"
          openInNewTab
          analyticsTrackingId={"install-security-faqs"}
        >
          Security FAQs
        </Button>{" "}
        for more detail.
      </div>
    </>
  );
};
