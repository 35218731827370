import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Loader,
  LoadingModal,
  Modal,
  ModalContent,
  ModalFooter,
  StackedList,
  StackedListButton,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { InputType } from "@incident-ui/Input/Input";
import { captureException } from "@sentry/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import {
  IntegrationsJiraServerGetConfigResponseBody,
  JiraServerConfig,
} from "src/contexts/ClientContext";
import { IntegrationsJiraServerSetBasicRequestBody } from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

type FormData = {
  host: string;
};

enum Step {
  ChooseType = "choose_type",
  OAuth1 = "oauth1",
  Basic = "basic",
}

export const ConnectToJiraServerModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement | null => {
  const [step, setStep] = useState(Step.ChooseType);

  switch (step) {
    case Step.ChooseType:
      return <ChooseTypeModal setStep={setStep} onClose={onClose} />;
    case Step.OAuth1:
      return <OAuth1Modal onClose={onClose} />;
    case Step.Basic:
      return <BasicModal onClose={onClose} />;
    default:
      assertUnreachable(step);
      return null;
  }
};

const ChooseTypeModal = ({
  onClose,
  setStep,
}: {
  onClose: () => void;
  setStep: (step: Step) => void;
}): React.ReactElement => {
  return (
    <Modal
      isOpen
      title="Connect to Jira Server"
      analyticsTrackingId="connect-jira-server-integration-choice"
      onClose={onClose}
    >
      <ModalContent>
        <StackedList>
          <StackedListButton
            analyticsTrackingId="jira-connection-select-OAuth"
            title="Application Link (OAuth 1)"
            description="Create an application link inside Jira Server, and then connect your Jira Server account to incident.io."
            badgeProps={{ theme: BadgeTheme.Info, label: "Recommended" }}
            onSelect={() => setStep(Step.OAuth1)}
          />
          <StackedListButton
            analyticsTrackingId="jira-connection-select-HTTP"
            title="HTTP Basic Authentication"
            description="Create a service account in Jira Server, and enter its username and password here."
            onSelect={() => setStep(Step.Basic)}
          />
        </StackedList>
      </ModalContent>
    </Modal>
  );
};

const cloudCallout = (
  <Callout theme={CalloutTheme.Info}>
    If you use Jira Cloud (e.g.
    <code>company.atlassian.net</code>), please use the Jira Cloud integration
    instead.
  </Callout>
);

type BasicFormData = IntegrationsJiraServerSetBasicRequestBody;
const BasicModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<BasicFormData>();

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "integrationsJiraServerGetConfig",
    undefined,
    async (apiClient, data: BasicFormData) => {
      await apiClient.integrationsJiraServerSetBasic({
        setBasicRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  const { showArticle } = useIntercom();

  return (
    <FormModalV2
      formMethods={formMethods}
      disableQuickClose
      analyticsTrackingId="connect-jira-server-integration-basic"
      title="Connect to Jira Server"
      onSubmit={onSubmit}
      onClose={onClose}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          saving={saving}
          confirmButtonText="Connect"
          cancelButtonText="Close"
          onClose={onClose}
        />
      }
    >
      {cloudCallout}
      <p className="text-sm py-2">
        With this authentication method, we&apos;ll use HTTP Basic
        authentication to call the Jira Server REST API using a service account.
        The username and password here will be base64-encoded and sent in the{" "}
        <code>Authorization</code> header, as described{" "}
        <Button
          href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication"
          analyticsTrackingId={null}
          openInNewTab
          theme={ButtonTheme.Link}
        >
          here
        </Button>
        .
      </p>
      <InputV2
        formMethods={formMethods}
        name="host"
        label="Base URL"
        required="Please provide a Base URL"
        autoComplete="none"
        placeholder={"https://jira.company.com/"}
        helptext={
          <>
            This should be the base URL of your Jira Server instance. If you use
            a firewall, please make sure it accepts traffic from{" "}
            <a
              onClick={() => showArticle(6318426)}
              className="underline hover:cursor-pointer"
            >
              the IP addresses we use
            </a>
            .
          </>
        }
      />
      <InputV2
        formMethods={formMethods}
        name="username"
        label="Service account username"
        type={InputType.Text}
        required="Enter the service account's username"
        autoComplete="none"
        placeholder={"incident-io@company.com"}
      />
      <InputV2
        formMethods={formMethods}
        name="password"
        label="Service account password"
        type={InputType.Password}
        required="Enter the service account's password"
        autoComplete="none"
        placeholder={"************"}
      />
    </FormModalV2>
  );
};

const OAuth1Modal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement | null => {
  const {
    data: jiraServerSettingsData,
    error,
    isLoading,
  } = useAPI("integrationsJiraServerGetConfig", undefined);
  const jiraServerSettings = jiraServerSettingsData?.config;

  if (isLoading || jiraServerSettings == null) {
    return <LoadingModal title="Connect to Jira Server" onClose={onClose} />;
  }

  if (error) {
    return (
      <ErrorModal
        description="There was a problem fetching Jira Server configuration."
        onClose={onClose}
      />
    );
  }

  if (jiraServerSettings.host && !jiraServerSettings.auth_url) {
    captureException(
      new Error(
        "Jira Server config problem: host is set but we couldn't generate an Auth URL",
      ),
    );
  }

  // If we have an auth URL, send them right there
  if (jiraServerSettings.auth_url) {
    window.location.href = jiraServerSettings.auth_url;
    return <Loader />;
  }

  return <RealModal settings={jiraServerSettings} onClose={onClose} />;
};

const RealModal = ({
  settings,
  onClose,
}: {
  settings: JiraServerConfig;
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<FormData>({
    defaultValues: { host: settings.host },
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsJiraServerGetConfig",
    undefined,
    async (apiClient, { host }: FormData) => {
      await apiClient.integrationsJiraServerSetHost({
        setHostRequestBody: { host },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: ({ config }: IntegrationsJiraServerGetConfigResponseBody) => {
        if (!config.host) {
          return;
        }
        if (!config.auth_url) {
          formMethods.setError("host", {
            message:
              "We couldn't generate an authorization link. Please check the Application Link configuration in Jira Server.",
          });
          return;
        }

        window.location.href = config.auth_url;
      },
    },
  );

  return (
    <FormModalV2
      genericError={genericError}
      formMethods={formMethods}
      onSubmit={onSubmit}
      analyticsTrackingId="connect-jira-server-integration"
      title="Connect to Jira Server"
      onClose={onClose}
      suppressInitialAnimation
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonText="Connect"
          confirmButtonType="submit"
          saving={saving}
        />
      }
    >
      {cloudCallout}
      <p className="text-sm text-slate-700">
        We connect to Jira Server using an Application Link. To set this up in
        your Jira Server:
      </p>
      <ol className="my-3 text-sm text-slate-700 list-decimal list-outside px-5 space-y-2">
        <li>
          Using the settings menu in the top-right, click
          &quot;Applications&quot;
        </li>
        <li>Click &quot;Application links&quot; in the left-hand menu</li>
        <li>
          Enter <code>{"https://app.incident.io/"}</code> and click &quot;Create
          new link&quot;
        </li>
        <li>
          Ignore the warning about &quot;no response was received...&quot; and
          click &quot;Continue&quot;
        </li>
        <li>Enter &quot;incident.io&quot; as the application name</li>
        <li>Select &quot;Generic Application&quot; as the application type</li>
        <li>Check &quot;Create incoming link&quot;</li>
        <li>Leave everything else blank, and click &quot;Continue&quot;</li>
        <li>
          Enter the Consumer Key{" "}
          <code className="block p-3 bg-surface-tertiary">
            {settings.consumer_key}
          </code>
        </li>
        <li>
          Enter the Consumer Name <code>incident.io</code>
        </li>
        <li>
          Enter the Public Key
          <pre className="block p-3 bg-surface-tertiary overflow-x-auto">
            {settings.public_key_pem}
          </pre>
        </li>
        <li className="my-2">Click &quot;Continue&quot;</li>
        <li className="my-2">
          Enter the Base URL of your Jira Server:
          <InputV2
            formMethods={formMethods}
            name="host"
            type={InputType.Text}
            required="Please provide a Base URL"
            autoComplete="none"
            placeholder={"https://jira.company.com/"}
          />
        </li>
      </ol>
    </FormModalV2>
  );
};
