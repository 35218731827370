import {
  AlertAttribute,
  ExpressionOperationOperationTypeEnum,
} from "@incident-io/api";
import { CreateEditExpressionFormData } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import { ReferenceWithExample } from "@incident-shared/engine/expressions/ExpressionsEditor";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { JSONAttributeExpressionEditor } from "@incident-shared/engine/expressions/query/operations/JSONAttributeExpressionEditor";
import { usePreviewResults } from "@incident-shared/engine/expressions/query/QueryPreview";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { ModalFooter } from "@incident-ui/Modal";
import { useForm } from "react-hook-form";
import { EnrichedScope, lookupInScopeByType } from "src/utils/scope";
import { v4 as uuidv4 } from "uuid";

export const JSONAttributeExpressionEditorModal = ({
  scope,
  existingExpressions,
  onClose,
  onAddExpression,
  onEditExpression,
  initialExpression,
  initialPath,
  existingAttribute,
  disabledCatalogTypes,
  allowHardcodedValue,
}: {
  allowHardcodedValue?: boolean;
  scope: EnrichedScope<ReferenceWithExample>;
  existingExpressions: ExpressionFormData[];
  onClose: () => void;
  onAddExpression: (
    e: ExpressionFormData,
    existingAttribute?: AlertAttribute,
  ) => void;
  onEditExpression: (e: ExpressionFormData) => void;
  initialExpression?: ExpressionFormData;
  initialPath?: string;
  existingAttribute?: AlertAttribute;
  disabledCatalogTypes?: string[];
}) => {
  const payload = lookupInScopeByType(scope, "JSON");
  const isEditingExistingExpression = !!initialExpression;

  // Build the expression modal with pre-selected defaults:
  // - The expression type is a query
  // - The root reference is also the only and final reference - our payload object
  // - The query operation will always be of type `parse`, and we start with the
  // source set to the `$` object
  const formMethods = useForm<
    CreateEditExpressionFormData & { hardcoded?: boolean }
  >({
    defaultValues: {
      id: initialExpression?.id,
      hardcoded: false,
      label: initialExpression?.label || existingAttribute?.name,
      root_reference: initialExpression?.root_reference || payload?.key,
      reference: initialExpression?.reference || uuidv4().split("-")[0],
      operations: [
        {
          returns: {
            type:
              initialExpression?.returns?.type ||
              existingAttribute?.type ||
              // We'll default to string if we have no return type, otherwise evaluating is very unhappy.
              "String",
            array:
              initialExpression?.returns?.array || existingAttribute?.array,
          },
          operation_type: ExpressionOperationOperationTypeEnum.Parse,
          parse: {
            source:
              initialPath ||
              (initialExpression?.operations &&
                initialExpression?.operations[0].parse?.source) ||
              "$",
          },
        },
      ],
      else_branch: initialExpression?.else_branch,
    },
  });

  const operations = formMethods.watch(`operations`);
  const { setExampleInput, results } = usePreviewResults({
    rootReference: payload,
    scope,
    operations,
  });

  const { setError, clearErrors, reset } = formMethods;

  const onSubmit = (
    expression: ExpressionFormData & { hardcoded?: boolean },
  ) => {
    clearErrors();

    // If the user can customise the label, then validate it's unique.
    // If not, it's an automatically set label, so we don't need to validate it.
    if (
      !isEditingExistingExpression &&
      existingExpressions.some((e) => e.label === expression.label)
    ) {
      setError("label", {
        type: "manual",
        message: "An expression with this name already exists",
      });
      return;
    }
    if (!expression.label || expression.label?.trim().length === 0) {
      setError("label", {
        type: "required",
        message: "Please provide an expression name",
      });
      return;
    }

    // Make sure we maintain the expression type and whether or not the result
    // is an array
    expression.returns = {
      type:
        existingAttribute?.type ||
        (expression.operations ? expression.operations[0]?.returns?.type : ""),
      array:
        existingAttribute?.array ||
        (expression.operations
          ? expression.operations[0]?.returns?.array
          : false),
    };

    if (isEditingExistingExpression) {
      onEditExpression(expression);
      reset();
    } else {
      onAddExpression(expression, existingAttribute);
      reset();
    }
  };

  if (!payload) {
    return <></>;
  }

  return (
    <FormModalV2
      formMethods={formMethods}
      analyticsTrackingId={`json-expression-editor-modal`}
      title={
        isEditingExistingExpression
          ? `Update '${initialExpression?.label}' attribute`
          : existingAttribute
          ? "Configure attribute"
          : "Create an attribute"
      }
      onClose={onClose}
      onSubmit={onSubmit}
      isExtraLarge
      footer={
        <ModalFooter
          confirmButtonType="submit"
          onClose={onClose}
          confirmButtonText={
            isEditingExistingExpression || existingAttribute ? "Update" : "Add"
          }
        />
      }
    >
      <JSONAttributeExpressionEditor
        disabledCatalogTypes={disabledCatalogTypes}
        payload={payload}
        selectedPayload={results[0]}
        parsedResultPreview={results[1]}
        setSelectedPayload={setExampleInput}
        isEditingExistingExpression={isEditingExistingExpression}
        isAssigningToExistingAttribute={!!existingAttribute}
        allowHardcodedValue={allowHardcodedValue}
        scope={scope}
      />
    </FormModalV2>
  );
};
