/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertAttributePayload,
    AlertAttributePayloadFromJSON,
    AlertAttributePayloadFromJSONTyped,
    AlertAttributePayloadToJSON,
} from './AlertAttributePayload';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    EngineParamBindingValue,
    EngineParamBindingValueFromJSON,
    EngineParamBindingValueFromJSONTyped,
    EngineParamBindingValueToJSON,
} from './EngineParamBindingValue';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';

/**
 * 
 * @export
 * @interface AlertSourceSuggestedAttribute
 */
export interface AlertSourceSuggestedAttribute {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertSourceSuggestedAttribute
     */
    attribute_binding: EngineParamBindingPayload;
    /**
     * 
     * @type {Expression}
     * @memberof AlertSourceSuggestedAttribute
     */
    attribute_expression?: Expression;
    /**
     * 
     * @type {AlertAttributePayload}
     * @memberof AlertSourceSuggestedAttribute
     */
    attribute_payload: AlertAttributePayload;
    /**
     * Attribute values that were found with this suggestion, if any were found
     * @type {Array<EngineParamBindingValue>}
     * @memberof AlertSourceSuggestedAttribute
     */
    attribute_values?: Array<EngineParamBindingValue>;
    /**
     * The ID of this suggested attribute
     * @type {string}
     * @memberof AlertSourceSuggestedAttribute
     */
    id: string;
}

export function AlertSourceSuggestedAttributeFromJSON(json: any): AlertSourceSuggestedAttribute {
    return AlertSourceSuggestedAttributeFromJSONTyped(json, false);
}

export function AlertSourceSuggestedAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSourceSuggestedAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute_binding': EngineParamBindingPayloadFromJSON(json['attribute_binding']),
        'attribute_expression': !exists(json, 'attribute_expression') ? undefined : ExpressionFromJSON(json['attribute_expression']),
        'attribute_payload': AlertAttributePayloadFromJSON(json['attribute_payload']),
        'attribute_values': !exists(json, 'attribute_values') ? undefined : ((json['attribute_values'] as Array<any>).map(EngineParamBindingValueFromJSON)),
        'id': json['id'],
    };
}

export function AlertSourceSuggestedAttributeToJSON(value?: AlertSourceSuggestedAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute_binding': EngineParamBindingPayloadToJSON(value.attribute_binding),
        'attribute_expression': ExpressionToJSON(value.attribute_expression),
        'attribute_payload': AlertAttributePayloadToJSON(value.attribute_payload),
        'attribute_values': value.attribute_values === undefined ? undefined : ((value.attribute_values as Array<any>).map(EngineParamBindingValueToJSON)),
        'id': value.id,
    };
}

