import { TextNode } from "@incident-io/api";
import cx from "classnames";
import { createElement } from "react";

import { useUIContext } from "../../UIContext";

export const TemplatedText = ({
  value,
  className,
  disableLinks,
}: {
  value: TextNode;
  className?: string;
  disableLinks?: boolean;
}): React.ReactElement => {
  const { onError } = useUIContext();

  return (
    <div className={cx("break-words", className)}>
      {render(value, "root", disableLinks || false, onError)}
    </div>
  );
};

// Recursively render a templated text. We don't support marks (styling) but
// this should be easy to extend, and felt better than sending actual HTML over
// the API.
const render = (
  node: TextNode,
  key: string,
  disableLinks: boolean,
  onError: (e: Error) => void,
  spanClassName?: string,
): React.ReactElement => {
  if (node.type === "doc") {
    return createElement(
      "div",
      { key },
      node.content?.map((value, idx) =>
        render(value, `${key}-${idx}`, disableLinks, onError),
      ),
    );
  }
  if (node.type === "heading") {
    return createElement(
      "div",
      { key, className: "font-semibold py-2" },
      node.content?.map((value, idx) => {
        return render(value, `${key}-${idx}`, disableLinks, onError);
      }),
    );
  }
  if (node.type === "paragraph") {
    return createElement(
      "p",
      { key, className: "mb-[10px] last:mb-0" },
      node.content?.map((value, idx) =>
        render(value, `${key}-${idx}`, disableLinks, onError),
      ),
    );
  }
  if (node.type === "blockquote") {
    return createElement(
      "p",
      {
        key,
        className:
          "mb-[10px] last:mb-0 pl-2 border-l-4 border-stroke rounded-sm",
      },
      node.content?.map((value, idx) =>
        render(value, `${key}-${idx}`, disableLinks, onError),
      ),
    );
  }
  if (node.type === "codeBlock") {
    return createElement(
      "p",
      {
        key,
        className:
          "p-3 rounded-lg bg-surface-secondary text-slate-600 dark:bg-slate-900 dark:text-slate-50",
      },
      node.content?.map((value, idx) => {
        return render(
          value,
          `${key}-${idx}`,
          disableLinks,
          onError,
          "!font-mono",
        );
      }),
    );
  }
  if (node.type === "lineBreak") {
    return createElement(
      "br",
      { key },
      node.content?.map((value, idx) =>
        render(value, `${key}-${idx}`, disableLinks, onError),
      ),
    );
  }
  if (node.type === "list") {
    return createElement(
      "ul",
      { key, className: "list-disc" },
      node.content?.map((value, idx) => {
        return render(value, `${key}-${idx}`, disableLinks, onError);
      }),
    );
  }
  if (node.type === "listItem") {
    return createElement(
      "li",
      { key, className: "mb-2 ml-4" },
      node.content?.map((value, idx) => {
        return render(value, `${key}-${idx}`, disableLinks, onError);
      }),
    );
  }
  if (node.type === "text") {
    let link: string | undefined;
    const className: string[] = [];
    node.marks?.forEach((mark) => {
      if (mark.type === "bold") {
        className.push("font-semibold");
      }
      if (mark.type === "italic") {
        className.push("italic");
      }
      if (mark.type === "link") {
        className.push("underline hover:opacity-90 transition");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const attributes: any = mark.attrs;
        link = attributes?.href;
      }
    });

    if (link && !disableLinks) {
      return createElement(
        "a",
        {
          key,
          className: cx(className),
          href: link,
          target: "_blank",
          rel: "noopener noreferrer",
        },
        node.text,
      );
    }

    return createElement(
      "span",
      {
        key,
        className: cx(className, spanClassName),
      },
      node.text,
    );
  }
  if (node.type === "horizontalRule") {
    // We don't want to show `<hr>`s, that's ugly.
    return <></>;
  }

  onError(new Error(`unrecognised node type: ${node.type}`));
  return <></>;
};
