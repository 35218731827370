import {
  Incident,
  IncidentVisibilityEnum,
  IntegrationSettingsProviderEnum as IntegrationProvider,
} from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { IconEnum } from "@incident-ui";
import { CommandPaletteItem } from "@incident-ui/CommandPalette/CommandPalette";
import { useContextualItems } from "@incident-ui/CommandPalette/CommandPaletteProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { uniq } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import {
  ExternalResourceResourceTypeEnum,
  IncidentModeEnum,
  IncidentStatusCategoryEnum,
  StatusPageIncident,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

import { useNavigateToModal } from "../../../../utils/query-params";
import { incidentInEditableStatus } from "../helpers";
import { useAttachments, useIncident, useStatusPageIncidents } from "../hooks";
import { Category, isOneOfCategories } from "../statuses/status-utils";
import { AITestPromptModal } from "./AITestPromptModal";
import { CustomFieldEntries } from "./CustomFieldEntries";
import { DebriefSection } from "./DebriefSection";
import { ExternalLinksSection } from "./ExternalLinksSection";
import { IncidentCallsSection } from "./IncidentCallsSection";
import { IncidentDurationMetrics } from "./IncidentDurationMetrics";
import { IncidentEscalations } from "./IncidentEscalations";
import { IncidentTimestamps } from "./IncidentTimestamps";
import { PostMortemPrompt } from "./PostMortemPrompt";
import { RelatedIncidents } from "./RelatedIncidents";
import { IncidentRoleAssignments } from "./RoleAssignments";
import { StatusPageLinkProps, StatusPageLinks } from "./StatusPageLinks";
import { StreamsSummary } from "./streams/StreamsSummary";

export const SidebarDivider = () => {
  return <div className="border-t w-full border-stroke" />;
};

export const ProductAccessCondtionalComponent = ({
  children,
  requiredProduct,
}: {
  children: React.ReactNode;
  requiredProduct?: Product;
}): React.ReactElement => {
  const { hasProduct } = useProductAccess();

  if (!requiredProduct) {
    return <>{children}</>;
  }

  if (hasProduct(requiredProduct)) {
    return <>{children}</>;
  }
  return <></>;
};

export function IncidentSidebar({
  incidentId,
  className,
}: {
  incidentId: string | null;
  className?: string;
}): React.ReactElement {
  const { incident, applicableFields } = useIncident(incidentId);
  const { attachments } = useAttachments(incidentId);

  const { statusPageIncidents } = useStatusPageIncidents(incidentId);

  const navigateToModal = useNavigateToModal();
  const [testAIPromptOpen, setTestAIPromptOpen] = useState(false);

  useIncidentContextualItems({
    incident,
    setTestAIPromptOpen,
  });

  const { identity } = useIdentity();

  const commsPlatform = identity.slack_info
    ? IntegrationProvider.Slack
    : IntegrationProvider.MicrosoftTeams;

  const {
    data: { escalation_paths },
  } = useAPI("escalationPathsList", undefined, {
    fallbackData: { escalation_paths: [], first_level_users: {} },
  });

  const { data: internalStatusPageIncidentsData } = useAPI(
    incidentId == null ? null : "internalStatusPageListIncidentLinks",
    { responseIncidentId: incidentId ?? "" },
    { fallbackData: { internal_status_page_incident_links: [] } },
  );

  const {
    data: { incident_debriefs: debriefs },
  } = useAPI(
    incident ? "debriefsListIncidentDebriefs" : null,
    { incidentId: incident ? incident.id : "" },
    { fallbackData: { incident_debriefs: [] } },
  );

  const {
    data: { incident_relationships },
  } = useAPI(
    incident ? "incidentRelationshipsList" : null,
    { incidentId: incident ? incident.id : "" },
    { fallbackData: { incident_relationships: [] } },
  );

  const {
    data: { streams },
  } = useAPI(
    incident ? "streamsList" : null,
    { parentId: incident ? incident.id : "" },
    { fallbackData: { streams: [] } },
  );

  const atlassianStatuspageAttachments = attachments.filter(
    (a) =>
      a.resource.resource_type ===
      ExternalResourceResourceTypeEnum.AtlassianStatuspageIncident,
  );

  const includePageName =
    uniq(statusPageIncidents.map((inc) => inc.status_page_id)).length > 1;
  const statusPageLinks: StatusPageLinkProps[] = [
    ...atlassianStatuspageAttachments.map(({ id, resource }) => ({
      id,
      href: resource.permalink,
      title: resource.title,
      type: "atlassian" as const,
    })),
    ...statusPageIncidents.map((spInc: StatusPageIncident) => ({
      id: spInc.id,
      href: `/status-pages/${spInc.status_page_id}/incident/${spInc.id}`,
      title: includePageName
        ? `${spInc.status_page.name}: ${spInc.name}`
        : spInc.name,
      type: "public" as const,
    })),
    ...internalStatusPageIncidentsData.internal_status_page_incident_links.map(
      (link) => ({
        id: link.id,
        href: link.url,
        title: link.internal_status_page_name,
        type: "internal" as const,
      }),
    ),
  ];

  const showPostmortemPrompt = incident && incidentInEditableStatus(incident);
  const hasEscalationPaths = incident && escalation_paths.length > 0;
  // Only show the debrief section if the incident has been resolved OR there are debriefs
  const showDebriefs =
    incident &&
    (incident.incident_status.category === IncidentStatusCategoryEnum.Closed ||
      incident.incident_status.category ===
        IncidentStatusCategoryEnum.PostIncident ||
      debriefs.length > 0);

  // Only show streams if the incident can have streams and the feature flag is enabled
  const incIsStreamFriendly =
    incident?.visibility === IncidentVisibilityEnum.Public &&
    (incident?.mode === IncidentModeEnum.Standard ||
      incident?.mode === IncidentModeEnum.Test) &&
    commsPlatform === IntegrationProvider.Slack;

  const showStreams =
    incIsStreamFriendly &&
    !(
      identity.feature_gates?.streams_per_incident_count === 0 &&
      streams.length === 0
    );
  const showEmptyStreams = showStreams && streams.length === 0;
  const showNonEmptyStreams = showStreams && streams.length > 0;

  const { featureRicherIncidentCalls } = useFlags();

  const sections: {
    component: React.ReactElement;
    requiredProduct?: Product;
  }[] = [
    showNonEmptyStreams && {
      requiredProduct: Product.Response,
      component: (
        <StreamsSummary
          key="streams"
          streams={streams}
          parentIncident={incident}
          addStream={() => navigateToModal("streams-create")}
        />
      ),
    },
    {
      component: (
        <IncidentRoleAssignments
          key="role-assignments"
          incidentId={incidentId}
          onEdit={() =>
            navigateToModal(IncidentHeaderModal.EditRoleAssignments)
          }
        />
      ),
    },
    {
      component: (
        <>
          <ExternalLinksSection
            incidentId={incidentId}
            statusPageLink={
              statusPageLinks.length === 1 ? statusPageLinks[0] : null
            }
          />
        </>
      ),
    },
    showPostmortemPrompt && {
      requiredProduct: Product.Response,
      component: <PostMortemPrompt incident={incident} />,
    },
    showDebriefs && {
      requiredProduct: Product.Response,
      component: <DebriefSection incident={incident} debriefs={debriefs} />,
    },
    showEmptyStreams && {
      requiredProduct: Product.Response,
      component: (
        <StreamsSummary
          key="streams"
          streams={streams}
          parentIncident={incident}
          addStream={() => navigateToModal("streams-create")}
        />
      ),
    },
    featureRicherIncidentCalls &&
      incident && {
        requiredProduct: Product.Response,
        component: <IncidentCallsSection incident={incident} />,
      },
    statusPageLinks.length > 1 && {
      component: <StatusPageLinks links={statusPageLinks} />,
    },
    hasEscalationPaths && {
      component: <IncidentEscalations incident={incident} />,
    },
    incident &&
      incident.mode !== IncidentModeEnum.Tutorial && {
        component: (
          <RelatedIncidents
            incident={incident}
            incident_relationships={incident_relationships}
          />
        ),
        requiredProduct: Product.Response,
      },
    incident &&
      incident.mode !== IncidentModeEnum.Tutorial &&
      incident.custom_field_entries.length > 0 && {
        requiredProduct: Product.Response,
        component: (
          <CustomFieldEntries
            incident={incident}
            onEdit={() => navigateToModal(IncidentHeaderModal.EditCustomFields)}
            entries={incident.custom_field_entries}
            applicableFields={applicableFields}
          />
        ),
      },
    incident?.incident_timestamps &&
      incident.incident_timestamps.length > 0 && {
        component: (
          <IncidentTimestamps
            incident={incident}
            timestamps={incident.incident_timestamps}
            onEdit={() => navigateToModal(IncidentHeaderModal.EditTimestamps)}
          />
        ),
      },
    incident?.duration_metrics &&
      incident.duration_metrics.length > 0 && {
        component: (
          <IncidentDurationMetrics
            metrics={incident.duration_metrics}
            timestamps={
              incident.incident_timestamps?.map((x) => x.timestamp) || []
            }
          />
        ),
      },
  ].filter((s) => !!s);

  return (
    <>
      <aside
        className={tcx("text-content-primary text-sm w-80 min-w-80", className)}
      >
        <dl className={"pb-16 space-y-4"}>
          {sections.map((section, i) => {
            return (
              <ProductAccessCondtionalComponent
                key={`section-${i}`}
                requiredProduct={section.requiredProduct}
              >
                <div className="space-y-4">
                  {i !== 0 && <SidebarDivider />}
                  {section.component}
                </div>
              </ProductAccessCondtionalComponent>
            );
          })}
        </dl>
      </aside>

      {incident != null && testAIPromptOpen && (
        <AITestPromptModal
          incidentIds={[incident.id]}
          onClose={() => setTestAIPromptOpen(false)}
        />
      )}
    </>
  );
}

const useIncidentContextualItems = ({
  incident,
  setTestAIPromptOpen,
}: {
  incident: Incident | null;
  setTestAIPromptOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { copyTextToClipboard } = useClipboard();
  const navigateToModal = useNavigateToModal();

  let items: CommandPaletteItem[] = [];
  if (incident) {
    items = [
      {
        label: "Request update",
        analyticsId: "action_incident_request_update",
        key: "action_incident_request_update",
        icon: IconEnum.Hand,
        onSelect: () => navigateToModal(IncidentHeaderModal.RequestUpdate),
      },
      {
        label: "Set severity",
        analyticsId: "action_incident_set_severity",
        key: "action_incident_set_severity",
        icon: IconEnum.Severity,
        onSelect: () => navigateToModal(IncidentHeaderModal.UpdateSeverity),
      },
      {
        label: "Rename incident",
        analyticsId: "action_incident_rename",
        key: "action_incident_rename",
        icon: IconEnum.Status,
        onSelect: () => navigateToModal(IncidentHeaderModal.RenameIncident),
      },
      {
        label: "Edit custom fields",
        analyticsId: "action_incident_edit_custom_fields",
        key: "action_incident_edit_custom_fields",
        icon: IconEnum.CustomField,
        onSelect: () => navigateToModal(IncidentHeaderModal.EditCustomFields),
      },
      {
        label: "Edit timestamps",
        analyticsId: "action_incident_edit_timestamps",
        key: "action_incident_edit_timestamps",
        icon: IconEnum.Clock,
        onSelect: () => navigateToModal(IncidentHeaderModal.EditTimestamps),
      },
      {
        label: "Edit role assignments",
        analyticsId: "action_incident_edit_role_assignments",
        key: "action_incident_edit_role_assignments",
        icon: IconEnum.Users,
        onSelect: () =>
          navigateToModal(IncidentHeaderModal.EditRoleAssignments),
      },
      {
        label: `Copy incident ID (${incident.id})`,
        analyticsId: "action_incident_copy_id",
        key: "action_incident_copy_id",
        icon: IconEnum.Copy,
        onSelect: () => copyTextToClipboard(incident.id),
      },
      {
        label: `Copy incident reference (INC-${incident.external_id})`,
        analyticsId: "action_incident_copy_reference",
        key: "action_incident_copy_reference",
        icon: IconEnum.Copy,
        onSelect: () => copyTextToClipboard(`INC-${incident.external_id}`),
      },
      {
        label: `Copy incident as a markdown link`,
        analyticsId: "action_incident_copy_as_markdown_link",
        key: "action_incident_copy_as_markdown_link",
        icon: IconEnum.Copy,
        onSelect: () =>
          copyTextToClipboard(
            `[INC-${incident.external_id} ${incident.name}](${window.location.origin}${window.location.pathname})`,
          ),
      },
      {
        label: "Test an AI Prompt",
        analyticsId: "test_ai_prompt",
        key: "test_ai_prompt",
        searchString: "test ai prompt",
        icon: IconEnum.Robot,
        hideByDefault: true,
        onSelect: () => {
          setTestAIPromptOpen(true);
        },
        shouldInclude: (identity) => {
          return identity.organisation_is_staff ?? false;
        },
      },
    ];

    if (
      isOneOfCategories([
        Category.Active,
        Category.PostIncident,
        Category.Closed,
      ])(incident.incident_status)
    ) {
      items.push({
        label: "Set status",
        analyticsId: "action_incident_set_status",
        key: "action_incident_set_status",
        icon: IconEnum.Status,
        onSelect: () => navigateToModal(IncidentHeaderModal.UpdateStatus),
      });
      items.push({
        label: "Resolve incident",
        analyticsId: "action_incident_resolve",
        key: "action_incident_resolve",
        icon: IconEnum.Tick,
        onSelect: () => navigateToModal(IncidentHeaderModal.Resolve),
      });
    }
  }

  const title = incident ? `INC-${incident.external_id} ${incident.name}` : "";

  useContextualItems(title, items);
};
