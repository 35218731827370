import { ConditionBadge } from "@incident-shared/engine/conditions";
import { DropdownMenuItem, Icon } from "@incident-ui";
import { parse } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  AvailableFilter,
  ExtendedFormFieldValue,
} from "src/components/@shared/filters";
import { TypeaheadTypeEnum } from "src/components/@shared/forms/Typeahead";
import { useIsInInsightsContext } from "src/components/insights/context/useInsightsContext";
import {
  FormFieldOperator as Operator,
  FormFieldOperatorFieldTypeEnum as FormFieldType,
  useClient,
} from "src/contexts/ClientContext";

import { FULL_TEXT_SEARCH } from "./constants";
import { getFilterFieldTypeaheadHelpers } from "./FilterFormElement";
import { FilterPopover } from "./FilterPopover";

// normalise the filter value into something we can easily render
const FilterObjValue = ({
  filterObj,
  operator,
}: {
  filterObj: ExtendedFormFieldValue;
  operator: Operator;
}): React.ReactElement | null => {
  const apiClient = useClient();
  const [hydratedLabels, setHydratedLabels] = useState<string[] | null>();

  const forInsights = useIsInInsightsContext();
  // TODO: get the dynamic select to populate a globally accessibly cache, so
  // we're not making api calls all over the shop.
  useEffect(() => {
    switch (operator.field_type) {
      case FormFieldType.SingleExternalSelect: {
        if (!filterObj.single_option_value) {
          throw new Error("no single option value for single external select");
        }

        const { hydrateOptions } = getFilterFieldTypeaheadHelpers({
          apiClient,
          typeaheadType: operator.select_config
            ?.typeahead_type as unknown as TypeaheadTypeEnum,
          typeaheadLookupId: filterObj.typeahead_lookup_id,
          forInsights,
        });

        hydrateOptions(filterObj.single_option_value).then((opts) =>
          setHydratedLabels(_.sortBy(opts, "sort_key").map((x) => x.label)),
        );
        return;
      }
      case FormFieldType.MultiExternalSelect:
      case FormFieldType.MultiExternalUserSelect: {
        if (!filterObj.multiple_options_value) {
          throw new Error(
            "no multiple options value for single external select",
          );
        }

        const { hydrateOptions } = getFilterFieldTypeaheadHelpers({
          apiClient,
          typeaheadType: operator.select_config
            ?.typeahead_type as unknown as TypeaheadTypeEnum,
          typeaheadLookupId: filterObj.typeahead_lookup_id,
          forInsights,
        });
        hydrateOptions(filterObj.multiple_options_value).then((opts) =>
          setHydratedLabels(_.sortBy(opts, "sort_key").map((x) => x.label)),
        );
        return;
      }
    }
  }, [apiClient, setHydratedLabels, operator, filterObj, forInsights]);

  const allOptions = (operator.select_config?.options || []).concat(
    (operator.select_config?.option_groups || []).flatMap(
      (group) => group.options,
    ),
  );
  const selectedStaticMultiSelectOptions =
    filterObj.multiple_options_value?.map((val) =>
      allOptions.find((x) => x.value === val),
    );

  switch (operator.field_type) {
    case FormFieldType.TextInput:
    case FormFieldType.NumberInput:
    case FormFieldType.DateInput:
      return <span>{filterObj.string_value}</span>;
    case FormFieldType.DateRangeInput: {
      if (!filterObj.string_value) {
        throw new Error("unreachable: DateRangeInput has no string_value");
      }
      return (
        <span className="mr-1">
          {formatDateRangeFilter(filterObj.string_value)}
        </span>
      );
    }
    case FormFieldType.BooleanInput:
      return <span>{filterObj.bool_value ? "Yes" : "No"}</span>;
    case FormFieldType.None:
      return null;
    case FormFieldType.SingleStaticSelect:
      return (
        <span>
          {
            allOptions.find(
              (opt) => opt.value === filterObj.single_option_value,
            )?.label
          }
        </span>
      );
    case FormFieldType.MultiStaticSelect:
      return (
        <span>
          {_.sortBy(selectedStaticMultiSelectOptions, "sort_key")
            .map((x) => x?.label)
            ?.join(", ")}
        </span>
      );
    case FormFieldType.SingleExternalSelect:
    case FormFieldType.MultiExternalSelect:
    case FormFieldType.MultiExternalUserSelect:
      return hydratedLabels ? (
        <span>{hydratedLabels.join(", ")}</span>
      ) : (
        <span />
      );
    default:
      throw new Error("unsupported form field type");
  }
};

export interface AppliedFilterBaseProps {
  availableFilterFields: AvailableFilter[];
  filterObj: ExtendedFormFieldValue;
  asDropdownItem?: boolean;
  onSelectDropdownItem?: (key: string) => void;
  className?: string;
  readonly?: boolean;
}

export interface EditableAppliedFilterProps extends AppliedFilterBaseProps {
  onDeleteFilter: (key: string) => void;
  readonly?: false | undefined;
}

export interface ReadonlyAppliedFilterProps extends AppliedFilterBaseProps {
  readonly: true;
}

export const AppliedFilter = (
  props: ReadonlyAppliedFilterProps | EditableAppliedFilterProps,
): React.ReactElement | null => {
  const {
    availableFilterFields,
    filterObj,
    asDropdownItem,
    className,
    readonly,
  } = props;
  const fieldConfig = availableFilterFields.find(
    (field) => field.filter_id === filterObj.filter_id,
  );
  if (!fieldConfig) {
    throw new Error(
      "unreachable: trying to render a filter for which we have no config",
    );
  }
  // full_text_search is a special case - it's rendered in the search bar
  // so we can ignore it here
  if (fieldConfig.key === FULL_TEXT_SEARCH) {
    return null;
  }
  const selectedOperator = fieldConfig.operators.find(
    (op) => op.key === filterObj.operator,
  );
  if (!selectedOperator) {
    throw new Error(
      "unreachable: trying to render an operator for which we have no config",
    );
  }

  const criteria =
    selectedOperator.field_type === FormFieldType.None ? null : (
      <FilterObjValue filterObj={filterObj} operator={selectedOperator} />
    );

  if (asDropdownItem) {
    return (
      <DropdownMenuItem
        analyticsTrackingId={null}
        label={fieldConfig.label}
        onSelect={() =>
          readonly ?? props.onSelectDropdownItem?.(filterObj.field_id)
        }
        className={className}
      >
        <div className="flex text-left">
          <Icon
            id={fieldConfig.icon}
            className="h-5 w-5 mr-2 text-content-tertiary shrink-0"
          />
          <div>
            {fieldConfig.label}
            <span className="font-semibold ml-1 text-content-primary">
              {selectedOperator.label}
            </span>
            {criteria ? <span className="ml-1">{criteria}</span> : undefined}
          </div>
        </div>
      </DropdownMenuItem>
    );
  }

  return (
    <FilterPopover
      renderTriggerButton={({ onClick }) => (
        <ConditionBadge
          icon={fieldConfig.icon}
          subject={fieldConfig.label}
          operation={selectedOperator.label}
          criteria={criteria}
          theme="white"
          {...(readonly
            ? {}
            : {
                onClick: () => onClick(filterObj.filter_id),
                onDeleteExpression: () =>
                  props.onDeleteFilter(filterObj.filter_id),
                onDelete: () => props.onDeleteFilter(filterObj.filter_id),
              })}
        />
      )}
    />
  );
};

// This apes metabase's date format, for "past X days/weeks/etc" and
// explicit ("between") date ranges only. You can see the schema here
// https://www.notion.so/incidentio/Metabase-Date-Filter-API-9437d3f4349340348e9036756bc443a4
const formatDateRangeFilter = (filter: string): string => {
  const isRelative = filter.startsWith("past");

  if (isRelative) {
    const re = /(past)([0-9]*)(\w*)(~?)/;

    const vals = filter.match(re) as string[];
    const num = parseInt(vals[2]);
    const unit = vals[3];
    const singularUnit = unit.slice(0, unit.length - 1);

    if (num === 1) {
      return `past 1 ${singularUnit}`;
    } else {
      return `past ${num} ${unit}`;
    }
  } else {
    const dates = filter.split("~");
    const from = parse(dates[0], "yyyy-MM-dd", new Date());
    const to = parse(dates[1], "yyyy-MM-dd", new Date());
    return `${from.toLocaleDateString()} to ${to.toLocaleDateString()}`;
  }
};
