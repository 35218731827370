import { SavedViewsListContextEnum } from "@incident-io/api";
import { useInitialiseInsightsFilters } from "@incident-shared/filters";
import { Loader } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { ExploDashboardID } from "src/components/insights/dashboards/dashboards";
import { useIncidentTriageIncidentsFeatureGateEnabled } from "src/utils/incident-manual-triage";
import { useQueryParams } from "src/utils/query-params";

import { FilterByDurationMetricDropdown } from "../../common/FilterByDurationMetricDropdown";
import { getDurationMetricOptions } from "../../common/getInsightsSelectOptions";
import { GroupByCustomFieldDropdown } from "../../common/GroupByCustomFieldDropdown";
import { InsightsDashboardSection } from "../../common/InsightsDashboardSection";
import { InsightsPage } from "../../common/InsightsPage";
import { useCustomFieldOptions } from "../../common/useCustomFieldOptions";
import { InsightsContextProvider } from "../../context/InsightsContextProvider";
import { StateConfig } from "../../context/types";
import { useInsightsContext } from "../../context/useInsightsContext";

export interface MTTXDashboardStateConfig extends StateConfig {
  split_by: {
    options: SelectOption[];
  };
  duration_metric: {
    options: SelectOption[];
  };
}

export const MTTXDashboard = () => {
  const { customFieldOptions, loading: customFieldsLoading } =
    useCustomFieldOptions();
  const { durationMetricOptions, loading: durationMetricsLoading } =
    useDurationMetricOptions();

  if (customFieldsLoading || durationMetricsLoading) {
    return <Loader />;
  }

  return (
    <InsightsContextProvider<MTTXDashboardStateConfig>
      context={SavedViewsListContextEnum.InsightsV2}
      stateConfig={{
        split_by: {
          options: customFieldOptions,
        },
        duration_metric: {
          options: durationMetricOptions,
        },
      }}
    >
      <InsightsPage dashboard="mttx">
        <DurationMetricsDashboard />
        <DurationMetricBreakdownDashboard />
        <DurationMetricsByCustomFieldDashboard />
      </InsightsPage>
    </InsightsContextProvider>
  );
};

const DurationMetricsDashboard = () => {
  return (
    <InsightsDashboardSection
      title="Duration metrics"
      dashboardEmbedID={ExploDashboardID.ResponseMTTXDurationMetrics}
      description="Compare your incident duration metrics, which are measured between your two given timestamps for each incident."
      initialHeight="1270px"
    />
  );
};

const DurationMetricBreakdownDashboard = () => {
  const { useState, getOptions, refetchFilters } =
    useInsightsContext<MTTXDashboardStateConfig>();

  const [selectedDurationMetricID, setSelectedDurationMetricID] =
    useState("duration_metric");
  const durationMetricOptions = getOptions("duration_metric");
  const selectedDurationMetric = durationMetricOptions.find(
    (metric) => metric.value === selectedDurationMetricID,
  );

  return (
    <InsightsDashboardSection
      title="Duration metric breakdown"
      dashboardEmbedID={ExploDashboardID.ResponseMTTXDurationMetricBreakdown}
      description="Get high level statistics on a single duration metric, and see whether the average is changing over time."
      controls={
        <FilterByDurationMetricDropdown
          selectedDurationMetricID={selectedDurationMetricID}
          setSelectedDurationMetricID={setSelectedDurationMetricID}
          durationMetricOptions={durationMetricOptions}
          refetchFilters={refetchFilters}
        />
      }
      noBottomDivider
      dashboardVariables={{
        duration_metric_id: selectedDurationMetric?.value,
        duration_metric_name: selectedDurationMetric?.label,
      }}
      initialHeight="610px"
    />
  );
};

const DurationMetricsByCustomFieldDashboard = () => {
  const { useState, getOptions, refetchFilters } =
    useInsightsContext<MTTXDashboardStateConfig>();

  const [selectedDurationMetricID, setSelectedDurationMetricID] =
    useState("duration_metric");
  const durationMetricOptions = getOptions("duration_metric");
  const selectedDurationMetric = durationMetricOptions.find(
    (metric) => metric.value === selectedDurationMetricID,
  );

  const [selectedCustomFieldID, setSelectedCustomFieldID] =
    useState("split_by");
  const customFieldOptions = getOptions("split_by");
  const customField = customFieldOptions.find(
    ({ value }) => value === selectedCustomFieldID,
  );

  return (
    <InsightsDashboardSection
      dashboardEmbedID={
        ExploDashboardID.ResponseMTTXDurationMetricByCustomField
      }
      description="Group by a custom field to compare this metric across teams or affected product features."
      controls={
        <>
          <GroupByCustomFieldDropdown
            selectedCustomFieldID={selectedCustomFieldID}
            setSelectedCustomFieldID={setSelectedCustomFieldID}
            customFieldOptions={customFieldOptions}
          />
          <FilterByDurationMetricDropdown
            selectedDurationMetricID={selectedDurationMetricID}
            setSelectedDurationMetricID={setSelectedDurationMetricID}
            durationMetricOptions={durationMetricOptions}
            refetchFilters={refetchFilters}
          />
        </>
      }
      dashboardVariables={{
        duration_metric_id: selectedDurationMetric?.value,
        duration_metric_name: selectedDurationMetric?.label,
        custom_field_id: customField?.value,
        custom_field_name: customField?.label,
      }}
      initialHeight="874px"
    />
  );
};

export const useDurationMetricOptions = () => {
  const urlParams = useQueryParams();
  const { durationMetrics, timestamps, loading } = useInitialiseInsightsFilters(
    urlParams.toString(),
  );

  // If the org is not on pro, hide the timestamps related to triage.
  const featureGateTriageIncidents =
    useIncidentTriageIncidentsFeatureGateEnabled();
  const hideTriage = !featureGateTriageIncidents;

  return {
    durationMetricOptions: getDurationMetricOptions(
      durationMetrics,
      timestamps,
      hideTriage,
    ).map((option) => ({
      sort_key: option.label,
      value: option.id,
      label: option.label,
    })),
    loading,
  };
};
