import {
  Badge,
  BadgeTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Heading,
  LoadingModal,
  ModalFooter,
  SlackButtonPreview,
  Spinner,
  StaticSingleSelect,
} from "@incident-ui";
import { Mrkdwn } from "@incident-ui/Markdown/Mrkdwn";
import { SelectOptions } from "@incident-ui/Select/types";
import IncidentLogo from "@incident-ui/SlackPreviews/images/incident_logo.png";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import {
  SlackChannelFormValue,
  SlackChannelsEditorV2,
} from "src/components/@shared/forms/v2/editors/SlackChannelsEditorV2";
import { FormLabelV2 } from "src/components/@shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "src/components/@shared/forms/v2/FormV2";
import { TemplatedTextInputV2 } from "src/components/@shared/forms/v2/inputs/TemplatedTextInputV2";
import {
  ErrorResponse,
  HydratedSlackChannel,
  Incident,
  IncidentsBuildScopeContextEnum,
  IncidentVisibilityEnum,
  PostmortemSharesCreateShareRequestBody,
  PostmortemShareTemplate,
  TextNode,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { usePostmortemName } from "src/utils/utils";
import { useDebounce } from "use-hooks";

type FormData = Omit<
  PostmortemSharesCreateShareRequestBody,
  "content" | "postmortem_description"
> & {
  slack_channels: SlackChannelFormValue[];
  content?: string;
  postmortem_description?: string;
  postmortem_share_template_id: string;
};

export const SharePostMortemModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement => {
  const {
    data: listTemplatesData,
    error: listTemplatesError,
    isLoading: listTemplatesLoading,
  } = useAPI("postmortemSharesListTemplates", undefined);

  const firstRender = useRef<boolean>(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  const [selectedTemplateID, setSelectedTemplateID] = useState<
    string | undefined
  >();

  const templateOptions = listTemplatesData?.postmortem_share_templates.map(
    (template) => ({
      label: template.name,
      value: template.id,
      sort_key: template.name,
    }),
  );

  useEffect(() => {
    if (listTemplatesData) {
      const defaultTemplate = _.find(
        listTemplatesData.postmortem_share_templates,
        (temp) => temp.is_default,
      );
      defaultTemplate && setSelectedTemplateID(defaultTemplate.id);
    }
  }, [listTemplatesData]);

  return (
    <InnerFormModal
      key={selectedTemplateID}
      suppressInitialAnimation={!firstRender.current}
      defaultTemplate={_.find(
        listTemplatesData?.postmortem_share_templates || [],
        (t) => t.id === selectedTemplateID,
      )}
      templateOptions={templateOptions ?? []}
      selectedTemplateID={selectedTemplateID}
      setSelectedTemplateID={setSelectedTemplateID}
      onClose={onClose}
      listTemplatesError={listTemplatesError}
      listTemplatesLoading={listTemplatesLoading}
      incident={incident}
    />
  );
};

const InnerFormModal = ({
  defaultTemplate,
  suppressInitialAnimation,
  templateOptions,
  selectedTemplateID,
  setSelectedTemplateID,
  onClose,
  listTemplatesError,
  listTemplatesLoading,
  incident,
}: {
  defaultTemplate?: PostmortemShareTemplate;
  suppressInitialAnimation?: boolean;
  templateOptions: SelectOptions;
  selectedTemplateID: string | undefined;
  setSelectedTemplateID: (selectedTemplateID: string | undefined) => void;
  onClose: () => void;
  listTemplatesError: ErrorResponse | undefined;
  listTemplatesLoading: boolean | undefined;
  incident: Incident;
}) => {
  const { postmortemNameFormatted } = usePostmortemName(incident);

  let default_slack_channels = defaultTemplate?.slack_channels ?? [];
  if (defaultTemplate?.share_to_incident_channel) {
    const incidentSlackChannel: HydratedSlackChannel = {
      value: incident.slack_channel_id,
      label: incident.slack_channel_name ?? "Incident Slack Channel",
      is_private: false,
      url: incident.slack_channel_url ?? "",
    };

    // Shove it at the front for consistency / make it obvious
    default_slack_channels = [incidentSlackChannel, ...default_slack_channels];
  }

  const formMethods = useForm<FormData>({
    defaultValues: defaultTemplate && {
      content: defaultTemplate.content as unknown as string,
      postmortem_description: defaultTemplate.description as unknown as string,
      slack_channels: default_slack_channels,
    },
  });
  const { setError } = formMethods;

  const isIncidentPrivate =
    incident?.visibility === IncidentVisibilityEnum.Private;

  const refetchPostIncidentFlowTasks = useAPIRefetch(
    "postIncidentFlowListTasks",
    { incidentId: incident.id },
  );

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postmortemSharesListShares",
    { incidentId: incident.id },
    async (apiClient, data: FormData) => {
      await apiClient.postmortemSharesCreateShare({
        createShareRequestBody: {
          ...data,
          content: data.content as unknown as TextNode,
          postmortem_description:
            data.postmortem_description as unknown as TextNode,
          incident_id: incident.id,
          slack_channel_ids: data.slack_channels.map((c) => c.value),
        },
      });

      await refetchPostIncidentFlowTasks();
    },
    {
      setError,
      onSuccess: () => {
        onClose();
      },
    },
  );

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.FullScope,
  );

  if (scopeLoading) {
    return (
      <LoadingModal
        title={`Share ${postmortemNameFormatted}`}
        onClose={onClose}
      />
    );
  }

  if (scopeError) {
    return <GenericErrorMessage error={scopeError} />;
  }

  return (
    <FormModalV2
      suppressInitialAnimation={suppressInitialAnimation}
      formMethods={formMethods}
      analyticsTrackingId={null}
      onClose={onClose}
      onSubmit={onSubmit}
      isExtraLarge
      title={`Share ${postmortemNameFormatted}`}
      genericError={genericError}
      footer={
        <ModalFooter
          onClose={() => onClose()}
          analyticsTrackingId="share-postmortem-submit"
          saving={saving}
          confirmButtonType="submit"
          confirmButtonText={`Share ${postmortemNameFormatted}`}
          disabled={isIncidentPrivate}
          disabledTooltipContent={
            "Sharing isn't available for private incidents."
          }
        />
      }
    >
      {listTemplatesError && <GenericErrorMessage error={listTemplatesError} />}
      {isIncidentPrivate && (
        <Callout theme={CalloutTheme.Danger}>
          <p>{`Sharing ${postmortemNameFormatted}s is not available for private incidents.`}</p>
        </Callout>
      )}
      {(templateOptions || []).length > 0 && (
        <div>
          <FormLabelV2 htmlFor={""}>Sharing template</FormLabelV2>
          <StaticSingleSelect
            options={templateOptions || []}
            placeholder="Select a template"
            isLoading={listTemplatesLoading}
            isClearable={true}
            onChange={(newVal) => {
              if (!newVal) {
                setSelectedTemplateID(undefined);
              } else {
                setSelectedTemplateID(newVal as string);
              }
            }}
            value={selectedTemplateID}
          />
        </div>
      )}
      <SlackChannelsEditorV2
        formMethods={formMethods}
        name="slack_channels"
        label="Slack channels"
        helptext={`Which channels do you want to share this ${postmortemNameFormatted} in?`}
        disallowPrivateChannels={true}
        required={true}
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="content"
        label="Message header"
        helptext={`Structure the message header, with key fields about your ${postmortemNameFormatted}`}
        format="mrkdwn"
        includeVariables={true}
        includeExpressions={true}
        scope={scope}
        multiLine={true}
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        name={"postmortem_description"}
        label={`Share a brief summary`}
        helptext={`Help people to understand what ${postmortemNameFormatted} is about, and why they might want to read it`}
        placeholder="A brief description of your learnings ..."
        format="mrkdwn"
        includeVariables={false}
        includeExpressions={true}
        multiLine={true}
        required={true}
      />
      <MessagePreview incident={incident} />
    </FormModalV2>
  );
};

const MessagePreview = ({
  incident,
}: {
  incident: Incident;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const { watch } = useFormContext<FormData>();
  const { postmortemNameFormatted } = usePostmortemName(incident);

  const content = watch("content");
  const postmortemDescription = watch("postmortem_description");

  // Debounce content and postmortemDescription to stop the preview from flickering.
  const debouncedContent = useDebounce(content, 300);
  const debouncedPostmortemDescription = useDebounce(
    postmortemDescription,
    300,
  );

  const {
    data: preview,
    mutate: refetchPreview,
    isLoading,
  } = useAPI("postmortemSharesRenderPreview", {
    renderPreviewRequestBody: {
      incident_id: incident.id,
      content: debouncedContent as unknown as TextNode,
      postmortem_description:
        debouncedPostmortemDescription as unknown as TextNode,
    },
  });

  useEffect(() => {
    refetchPreview();
  }, [refetchPreview, debouncedContent, debouncedPostmortemDescription]);

  const slackMrkdwn = preview?.slack_mrkdwn || "";

  // RichTextDisplay appears to not be correctly handling mrkdwn new lines
  // this "patches" it by doubling-up new lines
  const patchedSlackMrkdwn = slackMrkdwn
    .replaceAll(`\n`, `\n\n`)
    .replaceAll(`\t`, `&nbsp;&nbsp;&nbsp;&nbsp;`);

  return (
    <div>
      <Heading
        level={3}
        size="small"
        className={"flex items-center gap-2 font-medium mb-2"}
      >
        {"Slack preview"}
        {isLoading && <Spinner />}
      </Heading>
      <div className="flex bg-white p-4 rounded-[6px] border border-stroke shadow">
        <img
          className={"mt-1.5 mr-2 max-h-[2.5rem] max-w-[2.5rem] rounded-[6px]"}
          src={IncidentLogo}
        />
        <div>
          <div className="flex">
            <p className="font-['lato'] font-bold text-md !mb-0 mr-1">
              incident.io
            </p>
            <Badge
              theme={BadgeTheme.Tertiary}
              className={"font-['lato'] !rounded-none !py-0.5 !px-1"}
            >
              APP
            </Badge>
          </div>
          <Mrkdwn className="text-sm space-y-1" text={patchedSlackMrkdwn} />
          <div className="flex flex-wrap gap-2 mt-2">
            <SlackButtonPreview text="🌐 View incident homepage" />
            <SlackButtonPreview text={`📄 View ${postmortemNameFormatted}`} />
          </div>
          <p className="text-xs text-content-tertiary font-['lato'] mt-2">
            Shared by {identity?.user_name || ""}
          </p>
        </div>
      </div>
    </div>
  );
};
