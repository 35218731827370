/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IssueTrackersShortcutCreateIssueRequestBody
 */
export interface IssueTrackersShortcutCreateIssueRequestBody {
    /**
     * Shortcut user ID to assign to the issue
     * @type {string}
     * @memberof IssueTrackersShortcutCreateIssueRequestBody
     */
    assignee_id?: string;
    /**
     * Description body of the Shortcut issue
     * @type {string}
     * @memberof IssueTrackersShortcutCreateIssueRequestBody
     */
    description?: string;
    /**
     * ID of the follow-up that we're exporting this issue from
     * @type {string}
     * @memberof IssueTrackersShortcutCreateIssueRequestBody
     */
    follow_up_id: string;
    /**
     * The project ID in Shortcut in which to create this issue
     * @type {string}
     * @memberof IssueTrackersShortcutCreateIssueRequestBody
     */
    project_id?: string;
    /**
     * Shortcut user ID to mark as the reporter
     * @type {string}
     * @memberof IssueTrackersShortcutCreateIssueRequestBody
     */
    reporter_id?: string;
    /**
     * The team ID in Shortcut in which to create this issue
     * @type {string}
     * @memberof IssueTrackersShortcutCreateIssueRequestBody
     */
    team_id?: string;
    /**
     * Title of the Shortcut issue
     * @type {string}
     * @memberof IssueTrackersShortcutCreateIssueRequestBody
     */
    title: string;
}

export function IssueTrackersShortcutCreateIssueRequestBodyFromJSON(json: any): IssueTrackersShortcutCreateIssueRequestBody {
    return IssueTrackersShortcutCreateIssueRequestBodyFromJSONTyped(json, false);
}

export function IssueTrackersShortcutCreateIssueRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersShortcutCreateIssueRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee_id': !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'follow_up_id': json['follow_up_id'],
        'project_id': !exists(json, 'project_id') ? undefined : json['project_id'],
        'reporter_id': !exists(json, 'reporter_id') ? undefined : json['reporter_id'],
        'team_id': !exists(json, 'team_id') ? undefined : json['team_id'],
        'title': json['title'],
    };
}

export function IssueTrackersShortcutCreateIssueRequestBodyToJSON(value?: IssueTrackersShortcutCreateIssueRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_id': value.assignee_id,
        'description': value.description,
        'follow_up_id': value.follow_up_id,
        'project_id': value.project_id,
        'reporter_id': value.reporter_id,
        'team_id': value.team_id,
        'title': value.title,
    };
}

