import {
  ControlledInputProps,
  ControlledInputWrapper,
} from "@incident-shared/forms/v1/controlled/ControlledInputWrapper";
import { Icon, IconEnum } from "@incident-ui/Icon/Icon";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import { Txt } from "@incident-ui/Txt/Txt";
import React, { ChangeEvent } from "react";
import { FieldValues } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./Checkbox.module.scss";

export enum CheckboxHelptextDisplayEnum {
  Inline = "inline",
  Newline = "newline",
  Tooltip = "tooltip",
}

export type CheckboxProps = {
  label?: React.ReactNode;
  className?: string;
  checkboxClassName?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  disabledTooltipContent?: React.ReactNode;
  value?: string;
  helptext?: string | React.ReactElement | null;
  helptextDisplay?: CheckboxHelptextDisplayEnum; // we default this to inline
  id: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
} & Omit<React.HTMLAttributes<HTMLInputElement>, "onClick">;

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      className,
      checkboxClassName,
      onChange,
      checked,
      disabled = false,
      disabledTooltipContent,
      helptextDisplay = CheckboxHelptextDisplayEnum.Inline,
      helptext,
      id,
      ...restProps
    }: CheckboxProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <Tooltip content={disabled ? disabledTooltipContent : undefined}>
        <label
          htmlFor={id}
          className={tcx(
            styles.checkbox,
            "group/check inline-flex items-center",
            className,
          )}
        >
          <input
            // Inputs get priority over things with tabIndex=0, since inputs are
            // generally more important than other things on screen.
            tabIndex={1}
            {...restProps}
            ref={ref}
            type="checkbox"
            id={id}
            className={tcx(
              "text-white border-stroke shadow-sm group-hover/check:border-slate-600",
              checkboxClassName,
              {
                "hover:!cursor-pointer": !disabled,
                "hover:!cursor-not-allowed": disabled,
              },
            )}
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            onClick={(e) => e.stopPropagation()}
          />
          {(label || helptext) && (
            <div>
              <div className="ml-2 space-x-1 flex items-center">
                <Txt inline grey>
                  {label}
                </Txt>
                {helptext &&
                  (helptextDisplay === CheckboxHelptextDisplayEnum.Inline ? (
                    <Txt inline grey>
                      {helptext}
                    </Txt>
                  ) : helptextDisplay ===
                    CheckboxHelptextDisplayEnum.Tooltip ? (
                    <Tooltip content={helptext}>
                      <div>
                        <Icon
                          id={IconEnum.Info}
                          className="text-content-tertiary"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <> </>
                  ))}
              </div>
              {helptext &&
              helptextDisplay === CheckboxHelptextDisplayEnum.Newline ? (
                <Txt grey className="ml-2">
                  <p>{helptext}</p>
                </Txt>
              ) : null}
            </div>
          )}
        </label>
      </Tooltip>
    );
  },
);

Checkbox.displayName = "Checkbox";

export const ControlledCheckbox = <FormType extends FieldValues>({
  id,
  errors,
  control,
  label,
  helptext,
  required,
  disabled,
}: ControlledInputProps<FormType>): React.ReactElement => {
  return (
    <ControlledInputWrapper<FormType>
      helptext={helptext}
      id={id}
      errors={errors}
      control={control}
      rules={{
        required: required ? "This field is required" : false,
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <Checkbox
            id={id}
            onChange={onChange}
            checked={!!value} // It's definitely a bool, not undefined
            disabled={disabled}
            label={label as string}
          />
        );
      }}
    />
  );
};
