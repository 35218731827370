import { useEditorState, useEditorView } from "@aeaton/react-prosemirror";
import { EngineScope, Resource } from "@incident-io/api";
import { AddEditExpressionModal } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import { expressionIDfromReference } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { useExpressionsMethods } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { ReferenceSelectorPopover } from "@incident-shared/engine/ReferenceSelectorPopover/ReferenceSelectorPopover";
import { NodeSelection, TextSelection } from "prosemirror-state";
import { useMemo, useRef, useState } from "react";
import { getEmptyScope } from "src/utils/scope";

import { insertVar } from "./variable";

export const VariablePopover = ({
  scope,
  resources,
}: {
  scope?: EngineScope;
  resources?: Resource[];
}) => {
  const view = useEditorView();
  const state = useEditorState();
  const { methods: expressionsMethods, allowAllOfACatalogType } =
    useExpressionsMethods();

  const menuRef = useRef<HTMLDivElement>(null);
  const [expressionIndex, setExpressionIndex] = useState<number>(0);
  const [isExpression, setIsExpression] = useState<boolean | undefined>(
    undefined,
  );
  const [showExpressionModal, setShowExpressionModal] =
    useState<boolean>(false);

  const style = useMemo(() => {
    // We don't want to display any popover in the following cases:
    // - We don't have expression methods
    // - Nothing is selected
    // - Something is selected, but its only text
    if (
      !expressionsMethods ||
      !state.selection ||
      state.selection.empty ||
      !(state.selection instanceof NodeSelection)
    ) {
      return { left: -1000, top: 0 };
    }

    // We have a node selection, but its not a variable, so ignore
    const node = (state.selection as NodeSelection).node;
    if (node.type.name !== "varSpec") {
      return { left: -1000, top: 0 };
    }

    // Keep track of whether this is an expression or not, because we'll have to
    // display different popovers for expressions vs. normal variables
    if (node.attrs.name.includes("expressions")) {
      setIsExpression(true);
    } else {
      setIsExpression(false);
    }

    // Look up the expression from the node's reference label
    const expressionId = expressionIDfromReference(node.attrs.name);
    const expressionIndex = expressionsMethods.fields.findIndex(
      (e) => (e as { reference: string }).reference === expressionId,
    );
    setExpressionIndex(expressionIndex);
    setShowExpressionModal(true);

    // The coordinates at the beginning of the selection and the coordinates at
    // the end of the selection, respectively
    const anchorCoords = view.coordsAtPos(state.selection.$anchor.pos);
    const headCoords = view.coordsAtPos(state.selection.$head.pos);

    // Set the position of our box to the exact position of the variable pill
    return {
      left: anchorCoords.left,
      top: anchorCoords.top,
      width: headCoords.right - anchorCoords.right,
      height: anchorCoords.bottom - anchorCoords.top,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef, state, view]);

  if (!showExpressionModal || isExpression === undefined) {
    return <></>;
  }

  const handleCloseModal = () => {
    // Reset the cursor to the end of the variable and close the modal
    const noneSelection = new TextSelection(state.selection.$head);
    view.dispatch(view.state.tr.setSelection(noneSelection));
    setShowExpressionModal(false);
  };

  if (isExpression) {
    return (
      <div ref={menuRef} className="prosemirror-floater" style={style}>
        <AddEditExpressionModal
          onClose={() => handleCloseModal()}
          onEditExpression={(e) => {
            // Update the expression
            expressionsMethods?.update(expressionIndex, e);
            handleCloseModal();
          }}
          // We don't need to provide this as we'll never be adding a new
          // expression through this path.
          onAddExpression={() => null}
          initialExpression={
            expressionsMethods?.fields[expressionIndex] as ExpressionFormData
          }
          scope={scope || getEmptyScope()}
          resources={resources || []}
          analyticsTrackingContext={"add-edit-expression-modal"}
          existingExpressions={
            (expressionsMethods?.fields as ExpressionFormData[]) || []
          }
          allowAllOfACatalogType={!!allowAllOfACatalogType}
          disableResultArrays={true}
        />
      </div>
    );
  }

  return (
    <div ref={menuRef} className="prosemirror-floater" style={style}>
      <ReferenceSelectorPopover
        scope={scope || getEmptyScope()}
        isSelectable={(ref) => ref.resource.can_be_interpolated}
        onSelectReference={(ref) => {
          insertVar(ref.key, ref.label)(state, view.dispatch);
        }}
        allowExpressions={false}
        pauseOnAddExpression
        isOpenOverride={showExpressionModal}
        setIsOpenOverride={setShowExpressionModal}
        renderTriggerButton={({ onClick }) => {
          return (
            <div
              className="w-full h-full bg-surface-invert"
              onClick={onClick}
            ></div>
          );
        }}
      />
    </div>
  );
};
