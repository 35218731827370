import {
  CatalogEntry,
  CatalogType,
  ScopeNameEnum,
  StatusPage,
  StatusPagePageTypeEnum,
  StatusPageSubPageSlim,
  StatusPageUpdateSubPageRequestBody,
} from "@incident-io/api";
import { Tooltip } from "@incident-io/status-page-ui";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  AddNewButton,
  BadgeSize,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
  Link,
  Loader,
  ModalFooter,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { DeletionConfirmationFormModal } from "src/components/settings/DeletionConfirmationModal";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { DeletePageFormType } from "../../settings/edit/BasicSettings";

export type SubPageFormType = {
  page_source_type: string;
  split_by_catalog_type_id: string;
  split_by_component_attribute_id: string;
  email_whitelist_defined_by_attribute_id: string;
  sub_pages: {
    [key: string]:
      | {
          defined_by_catalog_entry_id: string;
          name: string;
          subpath: string;
          disabled: boolean;
        }
      | undefined;
  };
  allowed_email_domains: string[];
};

export const SubPageSettings = ({
  page,
  subPages,
}: {
  page: StatusPage;
  subPages: StatusPageSubPageSlim[];
}): React.ReactElement => {
  if (!page.split_by_catalog_type_id) {
    throw new Error("Unreachable: Expected parent page");
  }
  const { data: typeData } = useAPI("catalogShowType", {
    id: page.split_by_catalog_type_id,
  });

  const catalogType = typeData?.catalog_type;

  const { data: entriesData } = useAPI("catalogListEntries", {
    catalogTypeId: page.split_by_catalog_type_id,
    pageSize: 50,
    search: "",
    includeReferences: true,
  });

  const entries = entriesData?.catalog_entries;

  const entriesWithoutSubPages = entries?.filter(
    (entry) =>
      !subPages.find(
        (subPage) => subPage.defined_by_catalog_entry_id === entry.id,
      ),
  );

  if (!entries || !catalogType) {
    return <Loader />;
  }

  if (!subPages) {
    throw new Error("Unreachable: Expected sub-pages");
  }

  const isParentPage = page.page_type === StatusPagePageTypeEnum.Parent;

  return (
    <div className="space-y-6 bg-surface-secondary rounded-[6px] p-4 border border-stroke">
      <div>
        <h3 className="font-medium">
          {isParentPage ? "Sub-pages" : "Customer pages"}
        </h3>
        <FormHelpTextV2>
          <div>
            Each of these pages is its own status page, with components and
            incidents.
          </div>
        </FormHelpTextV2>
        <Button
          href={`/catalog/${slugForCatalogType(catalogType)}`}
          className="mb-4 mt-2 !inline-flex"
          title="Edit catalog type"
          analyticsTrackingId="status-page-catalog-type-internal-link"
        >
          Edit {catalogType?.name} catalog type
        </Button>
        <div className="space-y-2">
          {subPages.map((subPage) => {
            const entry = entries.find(
              (e) => e.id === subPage.defined_by_catalog_entry_id,
            );
            if (!entry) {
              throw new Error(
                "Unreachable: Couldn't find matching entry for sub-page",
              );
            }

            return (
              <SubPageItem
                key={entry.id}
                page={page}
                subPage={subPage}
                catalogType={catalogType}
                catalogEntry={entry}
              />
            );
          })}
        </div>
      </div>
      {entriesWithoutSubPages?.length ? (
        <div>
          <h3 className="font-medium">Disabled sub-pages</h3>
          <FormHelpTextV2>
            <div>
              Create new sub-pages for the catalog entries that aren&apos;t
              attached to an existing sub-page.
            </div>
          </FormHelpTextV2>
          <div className="space-y-2">
            {entriesWithoutSubPages.map((entry) => {
              return (
                <EntryWithoutSubPageRow
                  parentPage={page}
                  entry={entry}
                  catalogType={catalogType}
                  key={entry.id}
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const SubPageItem = ({
  page,
  subPage,
  catalogType,
  catalogEntry,
}: {
  page: StatusPage;
  subPage: StatusPageSubPageSlim;
  catalogType: CatalogType;
  catalogEntry: CatalogEntry;
}): React.ReactElement => {
  const { hasScope } = useIdentity();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const canConfigurePage = hasScope(ScopeNameEnum.StatusPagesConfigure);
  const isCustomerPage = page.page_type === StatusPagePageTypeEnum.Customer;

  let hasNoEmailDomains = false;
  if (isCustomerPage) {
    if (page.whitelist_defined_by_attribute_id) {
      const attrValues =
        catalogEntry.attribute_values[page.whitelist_defined_by_attribute_id];
      if (attrValues && attrValues.array_value) {
        hasNoEmailDomains = attrValues.array_value.length === 0;
      } else {
        hasNoEmailDomains = true;
      }
    } else {
      hasNoEmailDomains = true;
    }
  }

  return (
    <>
      {showEditModal ? (
        <EditSubPageModal
          page={page}
          subPage={subPage}
          catalogType={catalogType}
          entry={catalogEntry}
          onClose={() => setShowEditModal(false)}
        />
      ) : null}
      {showDeleteModal ? (
        <DeleteSubPageModal
          page={page}
          subPage={subPage}
          onClose={() => setShowDeleteModal(false)}
        />
      ) : null}

      <div className="bg-white border shadow-sm border-stroke rounded-2 py-4 drop-shadow-sm">
        <div className="px-4 flex justify-between">
          <div className="flex space-x-2 items-center">
            <Link
              to={`/catalog/${catalogEntry.catalog_type_id}/${catalogEntry.id}`}
              className="!no-underline whitespace-nowrap"
              openInNewTab={true}
              onClick={(e) => e.stopPropagation()}
              analyticsTrackingId="catalog-entry-internal-link"
            >
              <CatalogEntryBadge
                color={catalogType.color}
                icon={catalogType.icon}
                label={catalogEntry.name}
                size={BadgeSize.Small}
                clickable
              />
            </Link>
            <Link
              analyticsTrackingId={"status-page-subpage-internal-link"}
              className="!no-underline whitespace-nowrap"
              to={subPage.public_url}
              openInNewTab={true}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="text-sm font-medium">
                {page.name} {isCustomerPage && " for "} {subPage.name}
              </div>
            </Link>
            {hasNoEmailDomains && (
              <Tooltip
                content={
                  "You haven't set any email domains, no one can view this page"
                }
              >
                <span>
                  <Icon
                    id={IconEnum.Warning}
                    size={IconSize.Medium}
                    className="hover:text-content-primary text-slate-400"
                  />
                </span>
              </Tooltip>
            )}
          </div>
          <div className="flex space-x-1">
            <GatedButton
              title="Edit sub-page"
              analyticsTrackingId="status-page-edit-subpage"
              icon={IconEnum.Edit}
              onClick={() => setShowEditModal(true)}
              disabled={!canConfigurePage}
              disabledTooltipContent={`You don't have permission to edit this ${
                isCustomerPage ? "customer page" : "sub-page"
              }`}
              theme={ButtonTheme.Naked}
            />
            <GatedButton
              title="Delete sub-page"
              icon={IconEnum.Delete}
              analyticsTrackingId="status-page-delete-subpage"
              onClick={() => setShowDeleteModal(true)}
              disabled={!canConfigurePage}
              disabledTooltipContent={`You don't have permission to delete this ${
                isCustomerPage ? "customer page" : "sub-page"
              }`}
              theme={ButtonTheme.Naked}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const EditSubPageModal = ({
  page,
  subPage,
  entry,
  catalogType,
  onClose,
}: {
  page: StatusPage;
  subPage: StatusPageSubPageSlim;
  entry: CatalogEntry;
  catalogType: CatalogType;
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<StatusPageUpdateSubPageRequestBody>({
    defaultValues: {
      name: subPage.name,
      subpath: subPage.subpath,
      description: subPage.description,
      intro_text: subPage.intro_text,
    },
  });
  const { watch } = formMethods;
  const showToast = useToast();

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, data: StatusPageUpdateSubPageRequestBody) => {
      await apiClient.statusPageUpdateSubPage({
        id: subPage.id,
        updateSubPageRequestBody: data,
      });

      onClose();
    },
    {
      setError: formMethods.setError,
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Could not update sub-page",
          description:
            "Please try again, or get in touch if the problem persists.",
        });
      },
    },
  );

  const subPath = watch("subpath");
  const isCustomerPage = page.page_type === StatusPagePageTypeEnum.Customer;
  const { featureStatusPageIntroText } = useFlags();

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      title={`Edit ${isCustomerPage ? "customer page" : "sub-page"}`}
      onClose={onClose}
      isExtraLarge
      analyticsTrackingId="status-pages-edit-subpage-modal"
      saving={saving}
      footer={
        <ModalFooter
          confirmButtonText="Save"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <div className="space-y-1">
        <div className="font-medium text-content-primary text-sm">
          {catalogType.name}
        </div>
        <CatalogEntryBadge
          color={catalogType.color}
          icon={catalogType.icon}
          label={entry.name}
          size={BadgeSize.Small}
        />
      </div>
      <InputV2
        label="Page name"
        formMethods={formMethods}
        inputClassName="bg-white"
        helptext={`The public title of this ${
          isCustomerPage ? "customer page" : "sub-page"
        }`}
        name={"name"}
        inputPrefixNode={
          <label htmlFor="name" className="text-slate-600 whitespace-nowrap">
            {page.name} /
          </label>
        }
      />
      <InputV2
        label="URL"
        formMethods={formMethods}
        inputClassName="bg-white"
        name={"subpath"}
        helptext={`Where this ${
          isCustomerPage ? "customer page" : "sub-page"
        } will be publicly accessible.`}
        inputPrefixNode={
          <label htmlFor="subpath" className="text-slate-600 whitespace-nowrap">
            {page.public_url}/
          </label>
        }
      />
      {!isCustomerPage && featureStatusPageIntroText && (
        <>
          <InputV2
            label="Description"
            formMethods={formMethods}
            name={"description"}
            required={false}
            inputClassName="bg-white"
            helptext={
              <>
                Help your customers choose the right sub-page by adding a
                description. This will be shown in the list of sub-pages on your
                main status page.
              </>
            }
          />
          <TemplatedTextInputV2
            formMethods={formMethods}
            label="Introductory text"
            helptext={
              <>
                Free-form text to show at the top of this sub-page. Use this to
                help your customers understand whether they&rsquo;re on the
                right page, and what they should expect to see here.
              </>
            }
            name="intro_text"
            includeVariables={false}
            includeExpressions={false}
            format="mrkdwn"
          />
        </>
      )}
      {subPath !== subPage.subpath ? (
        <Callout theme={CalloutTheme.Warning}>
          You are editing the URL that this{" "}
          {isCustomerPage ? "customer page" : "sub-page"} is publicly visible
          on. This may be confusing for your users if they visit this page via
          the previous link.
        </Callout>
      ) : null}
    </FormModalV2>
  );
};

const DeleteSubPageModal = ({
  page,
  subPage,
  onClose,
}: {
  page: StatusPage;
  subPage: StatusPageSubPageSlim;
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<DeletePageFormType>();

  const { watch } = formMethods;
  const showToast = useToast();

  const { trigger, isMutating: saving } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, _: DeletePageFormType) => {
      await apiClient.statusPageDeleteSubPage({ id: subPage.id });
    },
    {
      onSuccess: () => {
        onClose();

        showToast({
          title: "Sub-page successfully deleted",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: "We weren't able to delete this sub-page",

          theme: ToastTheme.Error,
        });
      },
    },
  );

  const confirmString = watch("confirmString");

  return (
    <DeletionConfirmationFormModal
      onClose={onClose}
      onDelete={trigger}
      resourceTitle={subPage.name}
      isOpen={true}
      title="Delete sub-page"
      analyticsTrackingId="status-page-delete-subpage-modal"
      formMethods={formMethods}
      footer={
        <ModalFooter
          analyticsTrackingId={"status-page-delete-subpage-modal-confirm"}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Delete sub-page"
          cancelButtonText="Cancel"
          saving={saving}
          disabled={
            !confirmString || confirmString.trim() !== subPage.name.trim()
          }
        />
      }
    >
      <>
        <p>
          This action cannot be undone. This will permanently delete the
          sub-page <span className="font-semibold">{subPage.name}</span> and any
          links to incidents associated with it.
        </p>
        <p>
          Your users will no longer be able to view this sub-page, via your
          custom domain or through incident.io.
        </p>
        <InputV2
          helptext={
            <p className="text-content-primary">
              Please type <span className="font-semibold">{subPage.name}</span>{" "}
              to confirm.
            </p>
          }
          rules={{ validate: (value) => value === subPage.name }}
          formMethods={formMethods}
          name="confirmString"
        />
      </>
    </DeletionConfirmationFormModal>
  );
};

type CreateSubPageFormType = {
  name: string;
  subpath: string;
};

const EntryWithoutSubPageRow = ({
  parentPage,
  entry,
  catalogType,
}: {
  parentPage: StatusPage;
  entry: CatalogEntry;
  catalogType: CatalogType;
}) => {
  const [showCreationModal, setShowCreationModal] = useState(false);

  return (
    <>
      {showCreationModal ? (
        <SubPageCreationModal
          parentPage={parentPage}
          entry={entry}
          catalogType={catalogType}
          onClose={() => setShowCreationModal(false)}
        />
      ) : null}
      <div className="bg-white border border-dashed border-stroke rounded-2 py-4">
        <div className="px-4 flex justify-between items-center">
          <div>
            <Link
              to={`/catalog/${entry.catalog_type_id}/${entry.id}`}
              className="!no-underline whitespace-nowrap"
              openInNewTab={true}
              analyticsTrackingId="status-page-catalog-entry-internal-link"
            >
              <CatalogEntryBadge
                color={catalogType.color}
                icon={catalogType.icon}
                label={entry.name}
                size={BadgeSize.Small}
                clickable
              />
            </Link>
          </div>
          <AddNewButton
            title="Enable sub-page"
            analyticsTrackingId="status-page-create-new-subpage"
            onClick={() => setShowCreationModal(true)}
          />
        </div>
      </div>
    </>
  );
};

const SubPageCreationModal = ({
  parentPage,
  entry,
  catalogType,
  onClose,
}: {
  parentPage: StatusPage;
  entry: CatalogEntry;
  catalogType: CatalogType;
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<CreateSubPageFormType>({
    defaultValues: {
      name: entry.name,
      subpath: _.kebabCase(entry.name),
    },
  });
  const showToast = useToast();

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "statusPageShow",
    { id: parentPage.id },
    async (apiClient, data: CreateSubPageFormType) => {
      await apiClient.statusPageCreateSubPage({
        createSubPageRequestBody: {
          ...data,
          defined_by_catalog_entry_id: entry.id,
          parent_status_page_id: parentPage.id,
        },
      });

      onClose();
    },
    {
      setError: formMethods.setError,
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Sub-page created successfully",
        });
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Could not create sub-page",
          description:
            "Please try again, or get in touch if the problem persists.",
        });
      },
    },
  );

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      title="Create sub-page"
      onClose={onClose}
      analyticsTrackingId="status-pages-create-subpage-modal"
      saving={saving}
      footer={
        <ModalFooter
          confirmButtonText="Create sub-page"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <div className="space-y-1">
        <FormHelpTextV2>
          Create a new public sub-page, linked to your existing status page.
          This page will be publicly accessible once created, via a link on your
          main status page.
        </FormHelpTextV2>
        <div className="font-medium text-content-primary text-sm">
          {catalogType.name}
        </div>
        <CatalogEntryBadge
          color={catalogType.color}
          icon={catalogType.icon}
          label={entry.name}
          size={BadgeSize.Small}
        />
      </div>
      <InputV2
        label="Page name"
        formMethods={formMethods}
        inputClassName="bg-white"
        helptext="The public title of this sub-page"
        name={"name"}
        inputPrefixNode={
          <label
            htmlFor="name"
            className="text-slate-600 mr-[-4px] whitespace-nowrap"
          >
            {parentPage.name}
          </label>
        }
      />
      <InputV2
        label="URL"
        formMethods={formMethods}
        inputClassName="bg-white"
        name={"subpath"}
        helptext="Where this sub-page will be publicly accessible."
        inputPrefixNode={
          <label
            htmlFor="subpath"
            className="text-slate-600 mr-[-6px] whitespace-nowrap"
          >
            {parentPage.public_url}/
          </label>
        }
      />
    </FormModalV2>
  );
};
