import { PolicyViolationNotification } from "@incident-shared/policy/PolicyViolationNotification";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  Heading,
  IconEnum,
  IconSize,
  Loader,
} from "@incident-ui";
import { captureException } from "@sentry/react";
import { differenceInSeconds } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import { useState } from "react";
import {
  DebriefsListIncidentDebriefsResponseBody,
  ExternalGoogleCalendarEventAttendee,
  ExternalGoogleCalendarEventAttendeeResponseEnum,
  Incident,
  IncidentDebrief,
  IntegrationSettingsProviderEnum,
  IntegrationSettingsReconnectionReasonEnum,
  Policy,
  PolicyViolationPolicyTypeEnum,
} from "src/contexts/ClientContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPIMutation } from "src/utils/swr";
import { useDebriefName } from "src/utils/utils";

import {
  DurationEnum,
  formatDurationInSeconds,
} from "../../../../utils/datetime";
import { usePoliciesAndViolations } from "../hooks";
import { AvatarList, AvatarListClickableType, MaybeUser } from "./AvatarList";
import { ExternalLink } from "./ExternalLink";
import { ScheduleDebriefModal } from "./ScheduleDebriefModal";

export const DebriefSection = ({
  incident,
  debriefs,
}: {
  incident: Incident;
  debriefs: IncidentDebrief[];
}): React.ReactElement | null => {
  const { debriefName, debriefNameLower } = useDebriefName();
  const { policies, policyViolations } = usePoliciesAndViolations(incident.id);

  const wholeIncidentViolation =
    policyViolations &&
    policyViolations.find(
      (violation) =>
        violation.policy_type === PolicyViolationPolicyTypeEnum.Debrief &&
        violation.resource_id.includes(incident.id),
    );

  let violatedPolicy: Policy | undefined = undefined;
  if (wholeIncidentViolation) {
    violatedPolicy = policies?.find(
      (policy) => policy.id === wholeIncidentViolation.policy_id,
    );
  }

  const { disableDebriefAddToGoogleCalendar } = useFlags();
  const [showScheduleDebriefModal, setShowScheduleDebriefModal] =
    useState<boolean>(false);

  const {
    trigger: destroyIncidentDebrief,
    isMutating: isDestroying,
    genericError: destroyError,
  } = useAPIMutation(
    "debriefsListIncidentDebriefs",
    { incidentId: incident ? incident.id : "" },
    async (
      apiClient,
      {
        id,
        destroyCalendarEvent,
      }: { id: string; destroyCalendarEvent: boolean },
    ) => {
      await apiClient.debriefsDestroyIncidentDebrief({
        id,
        destroyIncidentDebriefRequestBody: {
          destroy_calendar_event: destroyCalendarEvent,
        },
      });
    },
  );

  const sortedDebriefs = _.sortBy(debriefs, function (debrief) {
    return debrief.external_resource.google_calendar_event?.start;
  });

  if (isDestroying) {
    return <Loader />;
  }

  if (destroyError) {
    return <GenericErrorMessage />;
  }

  return (
    <div>
      <div className="flex gap-2 items-center mb-2">
        <Heading level={3} size="small">
          {debriefName}
        </Heading>
      </div>
      {debriefs.length === 0 && !disableDebriefAddToGoogleCalendar ? (
        <div className="flex items-center gap-2">
          <Button
            icon={IconEnum.CalendarPlus}
            iconProps={{
              size: IconSize.Medium,
            }}
            theme={ButtonTheme.Naked}
            onClick={() => {
              setShowScheduleDebriefModal(true);
            }}
            analyticsTrackingId="schedule-debrief"
            className="-ml-0.5"
          >
            Schedule {debriefNameLower}
          </Button>
          {violatedPolicy != null && wholeIncidentViolation && (
            <PolicyViolationNotification
              iconOnly
              policy={violatedPolicy}
              resourceName="debrief"
              level={wholeIncidentViolation.level}
              daysUntil={wholeIncidentViolation.days}
              violationID={wholeIncidentViolation.id}
            />
          )}
        </div>
      ) : (
        sortedDebriefs.map((debrief) => (
          <DebriefDetails
            debrief={debrief}
            key={debrief.id}
            destroyIncidentDebrief={destroyIncidentDebrief}
          />
        ))
      )}
      {showScheduleDebriefModal && (
        <ScheduleDebriefModal
          incident={incident}
          onClose={() => setShowScheduleDebriefModal(false)}
        />
      )}
    </div>
  );
};

const DebriefDetails = ({
  debrief,
  destroyIncidentDebrief,
}: {
  debrief: IncidentDebrief;
  destroyIncidentDebrief: (params: {
    id: string;
    destroyCalendarEvent: boolean;
  }) => Promise<DebriefsListIncidentDebriefsResponseBody>;
}): React.ReactElement | null => {
  const { integrations } = useIntegrations();
  const hasCalendarIntegration =
    integrations?.some(
      (integration) =>
        integration.installed &&
        integration.reconnection_reason ===
          IntegrationSettingsReconnectionReasonEnum.Empty &&
        integration.provider === IntegrationSettingsProviderEnum.GoogleCalendar,
    ) ?? false;
  const { debriefNameLower } = useDebriefName();
  const { policies, policyViolations } = usePoliciesAndViolations(
    debrief.incident_id,
  );

  const policyViolation =
    policyViolations &&
    policyViolations.find((violation) => violation.resource_id === debrief.id);

  let violatedPolicy: Policy | undefined = undefined;
  if (policyViolation) {
    violatedPolicy = policies?.find(
      (policy) => policy.id === policyViolation.policy_id,
    );
  }

  if (!debrief.external_resource.google_calendar_event) {
    captureException(new Error("No Google Calendar event found for debrief"));
    return null;
  }

  const now = new Date();
  let info = "Occurring now";
  if (debrief.external_resource.google_calendar_event.start > now) {
    const diff = differenceInSeconds(
      debrief.external_resource.google_calendar_event.start,
      now,
    );
    const durationString = formatDurationInSeconds(
      diff,
      1,
      DurationEnum.minutes,
    );
    info = `In ${durationString}`;
  } else if (debrief.external_resource.google_calendar_event.end < now) {
    info =
      debrief.external_resource.google_calendar_event.end.toLocaleDateString();
  }

  // We don't want to show the avatar of users that have declined in the sidebar.
  const attendeeUsers =
    debrief.external_resource.google_calendar_event?.attendees
      ?.filter(
        (attendee) =>
          attendee.response !==
          ExternalGoogleCalendarEventAttendeeResponseEnum.No,
      )
      .map(convertToMaybeUser);

  return (
    <div className="flex-center-y gap-2 mt-1">
      <ExternalLink
        iconProps={{ size: IconSize.Large }}
        analyticsTrackingId={"incident-sidebar-slack"}
        href={debrief.external_resource.permalink}
        label={info}
        icon={IconEnum.GoogleCalendar}
      />
      {violatedPolicy != null && policyViolation && (
        <dt className="flex items-center gap-2">
          <PolicyViolationNotification
            iconOnly
            policy={violatedPolicy}
            resourceName="debrief"
            level={policyViolation.level}
            daysUntil={policyViolation.days}
            violationID={policyViolation.id}
          />
        </dt>
      )}
      {attendeeUsers !== undefined && attendeeUsers.length > 0 && (
        <AvatarList
          users={attendeeUsers}
          modalTitle={"Debrief attendees"}
          maxToShow={5}
          clickableType={AvatarListClickableType.Always}
        />
      )}
      <div className="grow"></div>
      <DropdownMenu
        triggerButton={
          <Button
            theme={ButtonTheme.Naked}
            type="button"
            analyticsTrackingId="post-mortem-actions"
            icon={IconEnum.DotsHorizontal}
            iconProps={{ size: IconSize.XL, className: "-my-2" }}
            title="post-mortem-options"
          />
        }
      >
        <DropdownMenuItem
          onSelect={() =>
            destroyIncidentDebrief({
              id: debrief.id,
              destroyCalendarEvent: false,
            })
          }
          analyticsTrackingId={"mark-event-not-as-debrief"}
          label={`Unlink ${debriefNameLower}`}
          icon={IconEnum.LinkBreak}
        >
          Unlink {debriefNameLower}
        </DropdownMenuItem>
        {hasCalendarIntegration && (
          <DropdownMenuItem
            onSelect={() =>
              destroyIncidentDebrief({
                id: debrief.id,
                destroyCalendarEvent: true,
              })
            }
            analyticsTrackingId={"delete-event-and-unlink-debrief"}
            label={`Delete ${debriefNameLower} from calendar`}
            icon={IconEnum.Delete2}
          >
            Delete {debriefNameLower} from calendar
          </DropdownMenuItem>
        )}
      </DropdownMenu>
    </div>
  );
};

function convertToMaybeUser(
  attendee: ExternalGoogleCalendarEventAttendee,
): MaybeUser {
  const maybeUser = {} as MaybeUser;

  if (attendee.user) {
    maybeUser.user = attendee.user;
  } else {
    maybeUser.nonUserLabel = attendee.email;
  }

  maybeUser.attendee = attendee;

  return maybeUser;
}
