/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnrichedImage,
    EnrichedImageFromJSON,
    EnrichedImageFromJSONTyped,
    EnrichedImageToJSON,
} from './EnrichedImage';
import {
    EnrichedSlackMessage,
    EnrichedSlackMessageFromJSON,
    EnrichedSlackMessageFromJSONTyped,
    EnrichedSlackMessageToJSON,
} from './EnrichedSlackMessage';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface SlackImage
 */
export interface SlackImage {
    /**
     * Images from the Slack message
     * @type {Array<EnrichedImage>}
     * @memberof SlackImage
     */
    images: Array<EnrichedImage>;
    /**
     * 
     * @type {User}
     * @memberof SlackImage
     */
    message_user: User;
    /**
     * 
     * @type {EnrichedSlackMessage}
     * @memberof SlackImage
     */
    slack_message: EnrichedSlackMessage;
}

export function SlackImageFromJSON(json: any): SlackImage {
    return SlackImageFromJSONTyped(json, false);
}

export function SlackImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'images': ((json['images'] as Array<any>).map(EnrichedImageFromJSON)),
        'message_user': UserFromJSON(json['message_user']),
        'slack_message': EnrichedSlackMessageFromJSON(json['slack_message']),
    };
}

export function SlackImageToJSON(value?: SlackImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'images': ((value.images as Array<any>).map(EnrichedImageToJSON)),
        'message_user': UserToJSON(value.message_user),
        'slack_message': EnrichedSlackMessageToJSON(value.slack_message),
    };
}

