import { IconEnum } from "@incident-ui";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import {
  Incident,
  IncidentVisibilityChanged,
} from "src/contexts/ClientContext";
import { TimelineItemObject } from "src/contexts/ClientContext";

const message = (change: IncidentVisibilityChanged) => {
  if (change.user) {
    return (
      <>
        <strong>{change.user.name}</strong> made this a {change.new_visibility}{" "}
        incident
      </>
    );
  } else {
    return `Incident converted to a ${change.new_visibility} incident`;
  }
};

export const TimelineItemVisibilityChanged = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  const changed = item.incident_visibility_changed;
  if (!changed) {
    throw new Error(
      "malformed timeline item: incident_visibility_changed was missing incident_visibility_changed field",
    );
  }

  return {
    icon:
      changed.new_visibility === "public"
        ? IconEnum.LockOpen
        : IconEnum.LockClosed,
    avatarUrl: changed.user?.avatar_url,
    description: message(changed),
  };
};
