import {
  Button,
  ButtonTheme,
  EmptyState,
  Heading,
  IconEnum,
  Loader,
} from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import { useLayoutEffect, useRef, useState } from "react";
import { EscalationsListResponseBody } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { EscalationCard } from "./card/EscalationCard";

export const EscalationGrid = () => {
  const { identity } = useIdentity();

  const POLLING_INTERVAL_IN_SECONDS = identity?.organisation_is_demo ? 5 : 10;

  if (!identity) {
    return <Loader />;
  }

  return (
    <EscalationGridInner
      pollingIntervalInSeconds={POLLING_INTERVAL_IN_SECONDS}
    />
  );
};

const EscalationGridInner = ({
  pollingIntervalInSeconds,
}: {
  pollingIntervalInSeconds: number;
}) => {
  const {
    data: resp,
    isLoading,
    isValidating,
  } = useAPI(
    "escalationsList",
    { pageSize: 12, resolved: false },
    {
      refreshInterval: pollingIntervalInSeconds * 1000,
    },
  );

  const [expanded, setExpanded] = useState(true);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="space-y-3 bg-surface-secondary p-4 rounded-xl">
      <div className="flex justify-between h-10">
        <div className="flex space-x-2 align-bottom">
          <Heading
            className="px-2 sm:px-0 flex flex-center-y space-x-1.5"
            level={2}
            size="medium"
          >
            <span>Triggered escalations</span>
          </Heading>
          <div className="flex gap-2 md:gap-4 text-sm items-center flex-wrap">
            <Button
              icon={IconEnum.ExternalLink}
              title="View all escalations"
              href="/on-call/escalations"
              openInNewTab={false}
              theme={ButtonTheme.Naked}
              className="text-content-tertiary transition"
              analyticsTrackingId={"internal-homepage-view-all-escalations"}
            />
          </div>
        </div>
        <div className="flex space-x-3">
          {(resp?.escalations.length ?? 0) > 3 && (
            <Button
              icon={expanded ? IconEnum.Collapse : IconEnum.Expand}
              title=""
              onClick={() => setExpanded(!expanded)}
              analyticsTrackingId={"internal-homepage-expand-escalations"}
              theme={ButtonTheme.Naked}
            />
          )}
        </div>
      </div>

      <EscalationGridImpl
        response={resp ?? null}
        loading={isValidating}
        expanded={expanded}
      />
    </div>
  );
};

export const EscalationGridImpl = ({
  response,
  loading,
  expanded,
}: {
  response: EscalationsListResponseBody | null;
  loading: boolean;
  expanded: boolean;
}) => {
  const escalations = response?.escalations ?? [];
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (!loading && firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  }, [response, loading]);

  if (loading && response == null) {
    return <Loader />;
  }

  return (
    <AnimatePresence presenceAffectsLayout>
      {escalations.length === 0 ? (
        <EmptyState
          icon={IconEnum.Flag}
          title="No triggered escalations"
          className="bg-surface-secondary"
          content="Rest easy, there are currently no triggered escalations &mdash; when there are, they'll appear here"
        />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 items-stretch auto-rows-fr">
          {_.take(escalations, expanded ? 12 : 3).map((escalation) => (
            <EscalationCard
              key={escalation.id}
              escalation={escalation}
              isGridFirstUpdate={firstUpdate.current}
            />
          ))}
        </div>
      )}
    </AnimatePresence>
  );
};
