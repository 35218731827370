import { Workflow } from "@incident-io/api";
import { useState } from "react";
import { useContext } from "react";
import { useQueryParams } from "src/utils/query-params";
import { assertUnreachable } from "src/utils/utils";

import { ClonedWorkflow } from "../../common/types";
import { WorkflowsFormContext } from "../WorkflowsFormContext";

export enum RightDrawerState {
  None = "none",
  ChooseTrigger = "choose_trigger",
  AddStep = "add_step",
  EditStep = "edit_step",
  ViewActivity = "view_activity",
  ExportToTerraform = "export_to_terraform",
}

export enum WorkflowModalState {
  None = "none",
  DeploySettings = "deploy_settings",
  DeleteConfirmation = "delete_confirmation",
  ConfigureTest = "configure_test",
  ChangeTriggerWarning = "change_trigger_warning",
  DisconnectTerraform = "disconnect_terraform",
}

export type WorkflowViewState = {
  leftDrawerIsOpen: boolean;
  onOpenLeftDrawer: () => void;
  onCloseLeftDrawer: () => void;

  rightDrawerState: RightDrawerState;
  onOpenRightDrawer: (state: RightDrawerState) => void;
  onCloseRightDrawer: () => void;

  hasOpenDrawer: boolean;

  modalState: WorkflowModalState;
  onOpenModal: (state: WorkflowModalState) => void;
  onCloseModal: () => void;
};

export const useViewController = ({
  workflow,
  template,
}: {
  workflow?: Workflow | ClonedWorkflow;
  template?: Workflow | ClonedWorkflow;
}): WorkflowViewState => {
  const activity = useQueryParams().get("activity");

  // When you create a new workflow, we initialise the page with the choose trigger drawer open, as it's the
  // first thing you need to do.
  let defaultDrawerState = RightDrawerState.ChooseTrigger;
  if (activity === "true") {
    defaultDrawerState = RightDrawerState.ViewActivity;
    // We check both IDs and names because drafts have IDs but not names and clones have names but not IDs
  } else if (workflow?.id || workflow?.name || template) {
    defaultDrawerState = RightDrawerState.None;
  }

  // The left drawer only has a single state: showing the advanced settings,
  // so we just use a boolean here
  const [showLeftDrawer, setShowLeftDrawer] = useState(false);
  const [rightDrawerState, setRightDrawerState] =
    useState<RightDrawerState>(defaultDrawerState);

  const [modalState, setModalState] = useState<WorkflowModalState>(
    WorkflowModalState.None,
  );

  const isRightDrawerOpen = rightDrawerState !== RightDrawerState.None;
  const isLeftDrawerOpen = showLeftDrawer;
  const hasOpenDrawer = isRightDrawerOpen || isLeftDrawerOpen;

  return {
    leftDrawerIsOpen: showLeftDrawer,
    onOpenLeftDrawer: () => setShowLeftDrawer(true),
    onCloseLeftDrawer: () => setShowLeftDrawer(false),
    rightDrawerState,
    hasOpenDrawer,
    onOpenRightDrawer: (state: RightDrawerState) => setRightDrawerState(state),
    onCloseRightDrawer: () => setRightDrawerState(RightDrawerState.None),
    modalState,
    onOpenModal: (state: WorkflowModalState) => setModalState(state),
    onCloseModal: () => setModalState(WorkflowModalState.None),
  };
};

// useSafeCloseRightDrawer is a hook that returns a function that can be used to safely close the right drawer.
// It guards against closing the right drawer when there's potentially state to be lost.
// Because you can close the drawer by clicking outside of it, we need to host this logic centrally, and
// separate from each individual drawer.
export const useSafeCloseRightDrawer = ({
  triggerName,
  isStepsFormDirty,
  clearStepState,
  rightDrawerState,
  onCloseRightDrawer,
}: {
  triggerName?: string;
  isStepsFormDirty: boolean;
  clearStepState: () => void;
  rightDrawerState: RightDrawerState;
  onCloseRightDrawer: () => void;
}) => {
  // The idea here is to guard against closing the right drawer
  // when there's potentially state to be lost.
  const onSafeCloseRightDrawer = (
    opts?: { forceClose: boolean } | undefined,
  ) => {
    const forceClose = opts?.forceClose || false;

    switch (rightDrawerState) {
      case RightDrawerState.AddStep:
      case RightDrawerState.ViewActivity:
      case RightDrawerState.ExportToTerraform:
        // You can always close these
        onCloseRightDrawer();
        return;
      case RightDrawerState.ChooseTrigger:
        if (!triggerName) {
          // We don't allow the user to close the drawer without a selected trigger.
          return;
        }
        onCloseRightDrawer();
        return;
      case RightDrawerState.EditStep:
        if (isStepsFormDirty && !forceClose) {
          const confirmed = confirm(
            "You have unsaved changes, are you sure you want to close the step editor?",
          );
          // If the user doesn't confirm, don't close the drawer.
          if (!confirmed) return;
        }
        // Close the drawer and clean up any
        // state related with create or editing steps.
        clearStepState();
        onCloseRightDrawer();
        return;
      case RightDrawerState.None:
        // Do nothing, it's not open...
        return;
      default:
        assertUnreachable(rightDrawerState);
    }
  };

  return onSafeCloseRightDrawer;
};

export const useWorkflowsViewState = (): WorkflowViewState => {
  const ctx = useContext(WorkflowsFormContext);
  if (ctx && ctx.viewState) {
    return ctx.viewState;
  }
  throw new Error(
    "useWorkflowsViewState must be used within a WorkflowsFormProvider",
  );
};
