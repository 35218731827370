import { ControlledInputWrapper } from "@incident-shared/forms/v1/controlled";
import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { Toggle } from "@incident-ui";
import { Path, UseFormReturn } from "react-hook-form";
import {
  IncidentsCreateRequestBodyVisibilityEnum as VisibilityEnum,
  IncidentType,
  Settings,
} from "src/contexts/ClientContext";

export type VisibilityData = {
  visibility: VisibilityEnum;
};

export const VisibilityElement = <TFormType extends VisibilityData>({
  formMethods,
  selectedIncidentType,
  hasSelectedIncidentTypeDontKnow,
  settings,
}: {
  formMethods: UseFormReturn<TFormType>;
  selectedIncidentType?: IncidentType;
  hasSelectedIncidentTypeDontKnow: boolean;
  settings: Settings | null;
}) => {
  if (
    !settings?.misc?.private_incidents_enabled ||
    hasSelectedIncidentTypeDontKnow
  ) {
    return <></>;
  }
  const disabled = selectedIncidentType?.private_incidents_only ?? false;
  return (
    <ControlledInputWrapper
      id={"visibility" as Path<TFormType>}
      control={formMethods.control}
      errors={formMethods.formState.errors}
      render={({ field: { onChange, value } }) => (
        <div className="mt-2">
          <Toggle
            id="visibility"
            label="Make this a private incident?"
            on={value === VisibilityEnum.Private || disabled}
            disabled={disabled}
            onToggle={() =>
              onChange(
                value === VisibilityEnum.Public
                  ? VisibilityEnum.Private
                  : VisibilityEnum.Public,
              )
            }
          />
          <FormHelpTextV2>
            {selectedIncidentType?.private_incidents_only
              ? `
${selectedIncidentType?.name} incidents are required to be private.
We'll create a private Slack channel
and only invited individuals can access incident information.
`
              : `If this incident is made private, a private Slack channel is created. Only invited individuals can access incident information.`}
          </FormHelpTextV2>
        </div>
      )}
    />
  );
};
