/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface IntegrationsGitLabHandleInstallRequest {
    organisationId: string;
}

export interface IntegrationsGitLabHandleRedirectRequest {
    code?: string;
    state?: string;
}

/**
 * 
 */
export class IntegrationsGitLabApi extends runtime.BaseAPI {

    /**
     * Handle Gitlab installation by redirecting to the Gitlab install endpoint
     * HandleInstall Integrations - GitLab
     */
    async integrationsGitLabHandleInstallRaw(requestParameters: IntegrationsGitLabHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsGitLabHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/gitlab_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Gitlab installation by redirecting to the Gitlab install endpoint
     * HandleInstall Integrations - GitLab
     */
    async integrationsGitLabHandleInstall(requestParameters: IntegrationsGitLabHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGitLabHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle GitLab redirect
     * HandleRedirect Integrations - GitLab
     */
    async integrationsGitLabHandleRedirectRaw(requestParameters: IntegrationsGitLabHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/gitlab`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle GitLab redirect
     * HandleRedirect Integrations - GitLab
     */
    async integrationsGitLabHandleRedirect(requestParameters: IntegrationsGitLabHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGitLabHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Remove GitLab OAuth token
     * HandleUninstall Integrations - GitLab
     */
    async integrationsGitLabHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/gitlab`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove GitLab OAuth token
     * HandleUninstall Integrations - GitLab
     */
    async integrationsGitLabHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGitLabHandleUninstallRaw(initOverrides);
    }

}
