import {
  Incident,
  IncidentStatusCategoryEnum,
  TimelineItem,
  TimelineItemItemTypeEnum as TimelineItemType,
  TimelineItemsListResponseBody,
} from "@incident-io/api";
import {
  Avatar,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  LocalDateTime,
} from "@incident-ui";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import { formatDurationShort, formatTimestampLocale } from "src/utils/datetime";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { TimelineItemTypeToComponentMap } from "./timeline-items/TimelineItem";
import { statusHasChanged } from "./timeline-items/TimelineItemIncidentUpdateComponent";
import { TimelineItemEnrichmentError } from "./TimelineItemEnrichmentError";
import styles from "./TimelineItems.module.scss";
import {
  buildEnrichmentError,
  enrichmentError,
  ToggleVisibilityButton,
} from "./TimelineItemUI";

type ActivityLogItemUIProps = {
  incident: Incident;
  onShow: (itemIds: string[]) => Promise<TimelineItemsListResponseBody>;
  item: TimelineItem;
  zoomImageSource: string | undefined;
  setZoomImageSource: (value: React.SetStateAction<string | undefined>) => void;
};

export const ActivityLogItemUIWrapper = (
  props: ActivityLogItemUIProps,
): React.ReactElement | null => {
  const enrichmentInfo = enrichmentError(props.incident, props.item);
  if (enrichmentInfo) {
    return <TimelineItemEnrichmentError {...enrichmentInfo} />;
  }

  const errorItem = props.item;
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => (
        <TimelineItemEnrichmentError
          {...buildEnrichmentError(props.incident, errorItem, "fallback")}
          originatingError={error}
          componentStack={componentStack}
        />
      )}
    >
      <ActivityLogItemUI {...props} />
    </Sentry.ErrorBoundary>
  );
};

const ActivityLogItemUI = ({
  incident,
  onShow,
  item,
  zoomImageSource,
  setZoomImageSource,
}: ActivityLogItemUIProps): React.ReactElement | null => {
  // Item might be associated with a stream of this incident, in which case we should
  // load it to display the details.
  const isStreamItem = item.is_stream;

  const {
    data: { stream },
  } = useAPI(
    isStreamItem ? "streamsShow" : null,
    {
      id: item?.incident_id ?? "",
    },
    { fallbackData: { stream: undefined } },
  );
  // The underlying url logic is the same as navigating to modals
  const navigateToDrawer = useNavigateToModal();

  const errorProps = enrichmentError(incident, item);
  if (errorProps) {
    return <TimelineItemEnrichmentError {...errorProps} />;
  }

  const ActivityLogItemComponent =
    TimelineItemTypeToComponentMap[item.item_type];

  if (!ActivityLogItemComponent) {
    return null;
  }
  const constructorProps = ActivityLogItemComponent(
    incident,
    item,
    zoomImageSource,
    setZoomImageSource,
  );
  if (!constructorProps) {
    return null;
  }

  const isStatusUpdate =
    !item.is_stream &&
    (constructorProps.isStatusUpdate ||
      item.item_type === TimelineItemType.StatusChange ||
      (item.item_type === TimelineItemType.IncidentUpdate &&
        statusHasChanged(item.incident_update)));

  const start = item.occured_at;
  const showAbsoluteTimestamp =
    incident.incident_status.category === IncidentStatusCategoryEnum.Closed;

  // Show an absolute timestamp in certain scenarios like the incident being closed.
  // formatDurationShort will also show an absolute timestamp for really old stuff, because
  // durations like '1mo' or '2 days' aren't useful.
  const formatTime = (timestamp: Date): string => {
    return showAbsoluteTimestamp
      ? formatTimestampLocale({ timestamp: timestamp })
      : formatDurationShort(timestamp, new Date(), {
          max: {
            hours: 12,
          },
          suffix: "ago",
        });
  };

  const duration = formatTime(start);
  const isStreamCreate =
    item.incident_update?.previous_incident_status == null && !item.role_update;

  return (
    <li
      className={tcx(
        styles.timelineItem,
        isStatusUpdate && styles.statusUpdate,
        "!py-1", // TODO: when we implement the new UI, let's use tailwind instead of the SCSS
      )}
    >
      <div className={tcx(styles.timelineItemHeader)}>
        <div className="flex ml-1.5">
          <Icon
            className={styles.icon}
            size={IconSize.Large}
            id={constructorProps.icon}
          />
          <div className={styles.itemDescription}>
            <div className="flex items-center gap-2">
              {!isEmpty(constructorProps.avatarUrl) ? (
                <div className={styles.avatar}>
                  <Avatar
                    size={IconSize.Large}
                    url={constructorProps.avatarUrl}
                    className="!w-8 !h-8"
                  />
                  {!isEmpty(constructorProps.secondaryAvatarUrl) && (
                    <Avatar
                      size={IconSize.Medium}
                      url={constructorProps.secondaryAvatarUrl}
                      className={`${styles.secondaryAvatar} border-2 border-white`}
                    />
                  )}
                </div>
              ) : null}
              <div className="flex flex-wrap gap-1">
                {constructorProps.description}
                {isStreamItem && stream && !isStreamCreate && (
                  <Button
                    theme={ButtonTheme.Unstyled}
                    className="ml-2 flex gap-1 items-center min-w-0 shrink max-w-64"
                    onClick={() =>
                      navigateToDrawer(`streams/${stream.external_id}`)
                    }
                    analyticsTrackingId="view-stream-from-timeline"
                  >
                    <Icon
                      size={IconSize.Small}
                      id={IconEnum.GitBranch}
                      className="text-content-secondary"
                    />
                    <div className="text-xs-med text-content-secondary truncate">
                      {stream.name}
                    </div>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center shrink-0">
          <LocalDateTime
            timestamp={item.occured_at}
            showIcon
            className={
              "text-sm pr-2 text-content-tertiary hover:!text-slate-300"
            }
          >
            {duration}
          </LocalDateTime>
          <ToggleVisibilityButton
            onClick={() => onShow([item.id])}
            lightTheme={isStatusUpdate}
            checked={false}
          />
        </div>
      </div>
      {constructorProps.children && (
        <div className={`${styles.itemContent} mt-4`}>
          {constructorProps.children}
        </div>
      )}
    </li>
  );
};
