import {
  Callout,
  CalloutTheme,
  LoadingModal,
  ModalFooter,
  StaticSingleSelect,
  TabModalPane,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { SelectOptions } from "@incident-ui/Select/types";
import _ from "lodash";
import { isEmpty } from "lodash";
import { ReactElement, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { FormLabelV2 } from "src/components/@shared/forms/v2/FormInputWrapperV2";
import { FormTabModalV2 } from "src/components/@shared/forms/v2/FormV2";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import {
  StaticMultiSelectV2,
  StaticSingleSelectV2,
} from "src/components/@shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "src/components/@shared/forms/v2/inputs/TextareaV2";
import {
  FollowUp,
  FollowUpsConnectExternalIssueRequestBodyProviderEnum,
  IssueTrackersLinearCreateIssueRequestBody,
  IssueTrackersLinearTypeaheadOptionsFieldEnum,
  LegacyIssueTemplateContextEnum,
  LegacyIssueTemplateIssueTrackerProviderEnum,
  LinearIssue,
  SelectOption,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { usePrevious } from "use-hooks";

export type LinearFormData = IssueTrackersLinearCreateIssueRequestBody & {
  url: string;
};

export const ExportToLinearModal = (props: {
  followUp: FollowUp;
  isPrivateIncident?: boolean;
  onClose: () => void;
  updateCallback: (newFollowUp: FollowUp) => Promise<void>;
}): ReactElement | null => {
  const { data: issueTemplates, isLoading } = useAPI(
    "issueTrackerIssueTemplatesList",
    undefined,
  );
  const linearIssueTemplates =
    issueTemplates?.issue_tracker_issue_templates.filter(
      (t) =>
        t.issue_tracker_provider ===
          LegacyIssueTemplateIssueTrackerProviderEnum.Linear &&
        t.context === LegacyIssueTemplateContextEnum.FollowUp,
    );
  const defaultIssueTemplateID = linearIssueTemplates?.[0]?.id;

  if (isLoading) {
    return <LoadingModal title="Export to Linear" onClose={props.onClose} />;
  }

  return (
    <ExportToLinearModalL2
      {...props}
      defaultIssueTemplateID={defaultIssueTemplateID}
    />
  );
};

export const ExportToLinearModalL2 = ({
  followUp,
  onClose,
  updateCallback,
  isPrivateIncident,
  defaultIssueTemplateID,
}: {
  followUp: FollowUp;
  isPrivateIncident?: boolean;
  onClose: () => void;
  updateCallback: (newFollowUp: FollowUp) => Promise<void>;
  defaultIssueTemplateID?: string;
}): ReactElement | null => {
  const [selectedTemplateID, setSelectedTemplateID] = useState<
    string | undefined
  >(defaultIssueTemplateID);

  const { data: issueTemplates } = useAPI(
    "issueTrackerIssueTemplatesList",
    undefined,
  );
  const linearIssueTemplates =
    issueTemplates?.issue_tracker_issue_templates.filter(
      (t) =>
        t.issue_tracker_provider ===
        LegacyIssueTemplateIssueTrackerProviderEnum.Linear,
    );
  const linearIssueTemplateOptions: SelectOptions = (
    linearIssueTemplates || []
  ).map((t) => ({
    label: t.name,
    value: t.id,
    sort_key: t.name,
  }));

  const {
    data,
    isValidating: loading,
    error: templateError,
  } = useAPI(
    selectedTemplateID
      ? "issueTrackersLinearGetIssueTemplateForFollowUp"
      : null,
    {
      followUpId: followUp.id,
      issueTemplateId: selectedTemplateID || "",
    },
    // Some weirdness here around loading and preventing using cached template data:
    // * Do not use the template from the cache - we do not want to initialise the
    //   form with stale data as defaults can be derived from custom fields which
    //   may have just changed
    // * Request the template data when the component mounts (rather than from cache)
    // * Use isValidating rather than isLoading to make sure we are ignoring stale values
    // * Set other options to false to ensure we never refetch the data (only get it on mount)
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    },
  );

  const template = data?.issue_template;

  if (templateError) {
    return (
      <ErrorModal
        error={
          new Error(
            "Failed to fetch Linear issue template - continuing without a template.",
            { cause: templateError },
          )
        }
        onClose={onClose}
      />
    );
  }

  return (
    <ActionExportToLinearModalL3
      loadingTemplate={loading}
      selectedTemplateID={selectedTemplateID}
      setSelectedTemplateID={setSelectedTemplateID}
      templateOptions={linearIssueTemplateOptions}
      followUp={followUp}
      onClose={onClose}
      isPrivateIncident={isPrivateIncident}
      updateCallback={updateCallback}
      issueTemplate={template || {}}
    />
  );
};

const ActionExportToLinearModalL3 = ({
  loadingTemplate,
  selectedTemplateID,
  setSelectedTemplateID,
  templateOptions,
  followUp,
  onClose,
  updateCallback,
  isPrivateIncident,
  issueTemplate,
}: {
  loadingTemplate: boolean;
  selectedTemplateID?: string;
  setSelectedTemplateID: (selectedTemplateID?: string) => void;
  templateOptions: SelectOptions;
  followUp: FollowUp;
  isPrivateIncident?: boolean;
  onClose: () => void;
  updateCallback: (newFollowUp: FollowUp) => Promise<void>;
  issueTemplate: LinearIssue;
}): ReactElement | null => {
  const [connectToExistingTicket, setConnectToExistingTicket] =
    useState<boolean>(false);

  const defaultValues = {
    ...issueTemplate,
    title: followUp.title,
    description: followUp.description,
    url: "",
  };

  const formMethods = useForm<LinearFormData>({
    mode: "onSubmit",
    defaultValues,
  });
  const { watch, clearErrors, reset, setError, setValue } = formMethods;

  const linearTeamId = watch("team_id");

  // Reset the form when a new template is chosen (after loading the template)
  const prevTemplate = usePrevious(issueTemplate);
  useEffect(() => {
    if (!_.isEqual(issueTemplate, prevTemplate)) {
      reset(defaultValues);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueTemplate]);

  // Whenever the team changes, reset the options that depend upon it
  useEffect(() => {
    setValue<"project_id">("project_id", issueTemplate.project_id);
    setValue<"assignee_id">("assignee_id", issueTemplate.assignee_id);
    setValue("labels", issueTemplate.labels || []);
  }, [setValue, linearTeamId, issueTemplate]);

  const {
    data: { typeahead_options: teams },
    isLoading: teamsLoading,
    error: teamsError,
  } = useAPI(
    "issueTrackersLinearTypeaheadOptions",
    {
      field: IssueTrackersLinearTypeaheadOptionsFieldEnum.Team,
    },
    {
      fallbackData: { typeahead_options: [] },
      onSuccess: () => clearErrors("assignee_id"),
    },
  );

  const {
    data: { typeahead_options: users },
    isLoading: usersLoading,
    error: usersError,
  } = useAPI(
    isEmpty(linearTeamId) ? null : "issueTrackersLinearTypeaheadOptions",
    {
      field: IssueTrackersLinearTypeaheadOptionsFieldEnum.User,
      userTeamId: linearTeamId,
    },
    {
      fallbackData: { typeahead_options: [] },
    },
  );

  const {
    data: { typeahead_options: labels },
    isLoading: labelsLoading,
    error: labelsError,
  } = useAPI(
    isEmpty(linearTeamId) ? null : "issueTrackersLinearTypeaheadOptions",
    {
      field: IssueTrackersLinearTypeaheadOptionsFieldEnum.Label,
      userTeamId: linearTeamId,
    },
    {
      fallbackData: { typeahead_options: [] },
    },
  );

  const {
    data: { typeahead_options: projects },
    isLoading: projectsLoading,
    error: projectsError,
  } = useAPI(
    isEmpty(linearTeamId) ? null : "issueTrackersLinearTypeaheadOptions",
    {
      field: IssueTrackersLinearTypeaheadOptionsFieldEnum.Project,
      userTeamId: linearTeamId,
    },
    {
      fallbackData: { typeahead_options: [] },
    },
  );

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    { incidentId: followUp.incident_id },
    async (apiClient, data: LinearFormData) => {
      const { follow_up: newFollowUp } = connectToExistingTicket
        ? await apiClient.followUpsConnectExternalIssue({
            id: followUp.id,
            connectExternalIssueRequestBody: {
              url: data.url,
              provider:
                FollowUpsConnectExternalIssueRequestBodyProviderEnum.Linear,
            },
          })
        : await apiClient.issueTrackersLinearCreateIssue({
            linearCreateIssueRequestBody: {
              title: data.title,
              description: data.description,
              team_id: data.team_id,
              project_id:
                data.project_id === "" ||
                !projects
                  ?.map((x) => x.value)
                  .includes(data.project_id || "NO_PROJECT_SELECTED")
                  ? undefined
                  : data.project_id,
              assignee_id:
                data.assignee_id === "" ? undefined : data.assignee_id,
              follow_up_id: followUp.id,
              labels:
                data.labels.filter(
                  (x) => labels?.map((label) => label.value).includes(x),
                ) || [],
            },
          });

      await updateCallback(newFollowUp);
    },
    { setError, onSuccess: onClose },
  );

  const fetchDataError =
    teamsError || usersError || projectsError || labelsError
      ? "There was a problem loading data from Linear."
      : "";

  return (
    <FormTabModalV2
      formMethods={formMethods}
      suppressInitialAnimation={true} // TODO
      onSubmit={onSubmit}
      title="Export to Linear"
      analyticsTrackingId="add-to-linear"
      onClose={onClose}
      loading={teamsLoading}
      genericError={genericError || fetchDataError}
      onTabChange={(tab: string) =>
        setConnectToExistingTicket(tab === "connect")
      }
      tabs={[
        {
          id: "add",
          label: "Create new",
        },
        {
          id: "connect",
          label: "Connect existing",
        },
      ]}
      footer={
        <ModalFooter
          confirmButtonText={connectToExistingTicket ? "Connect" : "Create"}
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      <TabModalPane tabId="add" className="space-y-4">
        {isPrivateIncident && (
          <Callout theme={CalloutTheme.Warning}>
            This is a private incident. This issue will be visible to anyone
            with access to your Linear team.
          </Callout>
        )}
        {templateOptions.length > 0 && (
          <div>
            <FormLabelV2 htmlFor={"issue_template_id"} required={false}>
              Export template
            </FormLabelV2>
            <StaticSingleSelect
              label="Export template"
              options={templateOptions}
              placeholder="Select a template"
              isLoading={false}
              isClearable={true}
              onChange={(newVal) => {
                if (!newVal) {
                  setSelectedTemplateID(undefined);
                } else {
                  setSelectedTemplateID(newVal as string);
                }
              }}
              value={selectedTemplateID}
            />
          </div>
        )}
        <InputV2
          formMethods={formMethods}
          label="Title"
          name="title"
          required={
            connectToExistingTicket ? undefined : "Please enter a title"
          }
        />
        <TextareaV2
          formMethods={formMethods}
          label="Description"
          name="description"
          required={
            connectToExistingTicket ? undefined : "Please enter a description"
          }
          placeholder="Any more details you want to add while you're here?"
          rows={4}
        />
        <LinearBulkExportableFields
          projects={projects ?? null}
          projectsLoading={projectsLoading || loadingTemplate}
          selectedTeamId={linearTeamId}
          teams={teams ?? null}
          teamsLoading={teamsLoading || loadingTemplate}
          users={users ?? null}
          usersLoading={usersLoading || loadingTemplate}
          labels={labels ?? null}
          labelsLoading={labelsLoading || loadingTemplate}
        />
      </TabModalPane>
      <TabModalPane tabId="connect" className="space-y-4">
        <p className="text-sm">
          Connect this follow-up to an existing Linear issue by pasting in the
          issue URL.
        </p>
        <Callout theme={CalloutTheme.Info}>
          If you link this to an issue tracker, we will use that as the source
          of truth, and the follow-up will no longer be editable in incident.io.
        </Callout>
        <InputV2
          formMethods={formMethods}
          name="url"
          label="Existing issue URL"
          required={connectToExistingTicket ? "Please enter a URL" : undefined}
          placeholder="https://linear.app/example/issue/EX-123"
        />
      </TabModalPane>
    </FormTabModalV2>
  );
};

// LinearBulkExportableFields holds the fields that are present on both the singular export and the bulk
// export modals. If you're adding a new field, please consider whether it should be added here (ie.
// a user might want to set it for multiple actions at once), or above (ie. a user will only want to
// set it for a particular followUp).
export const LinearBulkExportableFields = ({
  projects,
  projectsLoading,
  teams,
  teamsLoading,
  users,
  usersLoading,
  labels,
  labelsLoading,
  selectedTeamId,
}: {
  teams: SelectOption[] | null;
  teamsLoading: boolean;
  projects: SelectOption[] | null;
  projectsLoading: boolean;
  users: SelectOption[] | null;
  usersLoading: boolean;
  labels: SelectOption[] | null;
  labelsLoading: boolean;
  selectedTeamId: string | null;
}): ReactElement => {
  const formMethods = useFormContext<LinearFormData>();

  return (
    <>
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Linear team"
        name="team_id"
        required="Please select a team"
        options={teams || []}
        placeholder="Select team"
        isLoading={teamsLoading}
      />
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Linear project"
        name="project_id"
        options={projects || []}
        disabled={selectedTeamId == null}
        placeholder="Select project"
        isLoading={projectsLoading}
        isClearable
      />
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Linear assignee"
        name="assignee_id"
        options={users || []}
        disabled={selectedTeamId == null}
        placeholder="Select assignee"
        isLoading={usersLoading}
        isClearable
      />
      <StaticMultiSelectV2
        formMethods={formMethods}
        label="Labels"
        name="labels"
        options={labels || []}
        disabled={selectedTeamId == null}
        placeholder="Select label"
        isLoading={labelsLoading}
        isClearable
      />
    </>
  );
};
