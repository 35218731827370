import { cloneDeep } from "lodash";
import React from "react";
// eslint-disable-next-line no-restricted-imports
import { Link, LinkProps, NavLink, NavLinkProps } from "react-router-dom";
import {
  // eslint-disable-next-line no-restricted-imports
  // eslint-disable-next-line no-restricted-imports
  Navigate,
  NavigateOptions,
  NavigateProps,
  To,
  // eslint-disable-next-line no-restricted-imports
  useNavigate,
  useParams,
} from "react-router-dom";
import { AnalyticsMetadata, useAnalytics } from "src/contexts/AnalyticsContext";
import { useIsMSTeamsTabFriendlyView } from "src/contexts/MSTeamsTabContext";

type OrgAwareLinkProps = LinkProps & {
  analyticsTrackingId?: string;
  analyticsTrackingMetadata?: AnalyticsMetadata;
  disabled?: boolean;
};

// OrgAwareLink is the equivalent of react-router `<Link/>`, with org-awareness
// included.
export const OrgAwareLink = React.forwardRef<
  HTMLAnchorElement,
  OrgAwareLinkProps
>(
  (
    {
      analyticsTrackingId,
      analyticsTrackingMetadata,
      onClick,
      disabled,
      children,
      ...props
    },
    ref,
  ) => {
    const { slug } = useParams();
    let to = props.to;

    const isMSTeamsTabFriendlyView = useIsMSTeamsTabFriendlyView();

    if (slug && slug !== "~") {
      if (isMSTeamsTabFriendlyView) {
        to = prependSlugIfNeeded(to, `${slug}/ms-teams-tab`);
      } else {
        to = prependSlugIfNeeded(to, slug);
      }
    }

    const analytics = useAnalytics();

    const onClickWithTracking = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
      if (analyticsTrackingId) {
        analytics?.track(
          `${analyticsTrackingId}.clicked-link`,
          analyticsTrackingMetadata,
        );
      }
      e.stopPropagation();
      onClick?.(e);
    };

    if (disabled) {
      return <>{children}</>;
    }

    return (
      <Link
        onClick={onClickWithTracking}
        {...markAsInternalRedirect(props)}
        to={to}
        ref={ref}
      >
        {children}
      </Link>
    );
  },
);

OrgAwareLink.displayName = "OrgAwareLink";

// OrgAwareNavLink is the equivalent of react-router `<NavLink/>`, with org-awareness
// included.
export const OrgAwareNavLink = React.forwardRef<
  HTMLAnchorElement,
  NavLinkProps
>((props, ref) => {
  const { slug } = useParams();
  let to = props.to;

  const isMSTeamsTabFriendlyView = useIsMSTeamsTabFriendlyView();

  if (slug && slug !== "~") {
    if (isMSTeamsTabFriendlyView) {
      to = prependSlugIfNeeded(to, `${slug}/ms-teams-tab`);
    } else {
      to = prependSlugIfNeeded(to, slug);
    }
  }
  return <NavLink {...markAsInternalRedirect(props)} to={to} ref={ref} />;
});

OrgAwareNavLink.displayName = "OrgAwareNavLink";

// OrgAwareNavigate is the equivalent of react-router `<OrgAwareNavigate/>`, with org-awareness
// included.
export const OrgAwareNavigate = (props: NavigateProps) => {
  const { slug } = useParams();
  let to = props.to;

  const isMSTeamsTabFriendlyView = useIsMSTeamsTabFriendlyView();
  if (isMSTeamsTabFriendlyView) {
    to = `/ms-teams-tab${to}`;
  }

  if (slug && slug !== "~") {
    to = prependSlugIfNeeded(to, slug);
  }
  return <Navigate {...markAsInternalRedirect(props)} to={to} />;
};

OrgAwareNavigate.displayName = "OrgAwareNavigate";

// useOrgAwareNavigate is the equivalent of react-router `useNavigate`, with org-awareness
// included.
export const useOrgAwareNavigate = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const isMSTeamsTabFriendlyView = useIsMSTeamsTabFriendlyView();

  return (to: To, options?: NavigateOptions): void => {
    if (slug) {
      if (isMSTeamsTabFriendlyView) {
        to = prependSlugIfNeeded(to, `${slug}/ms-teams-tab`);
      } else {
        to = prependSlugIfNeeded(to, slug);
      }
    }
    return navigate(to, markAsInternalRedirect(options));
  };
};

// prependSlugIfNeeded is a shared util to enrich a To target with
// the provided slug.
const prependSlugIfNeeded = (raw: To, slug: string): To => {
  let to = cloneDeep(raw);

  // Do nothing if this isn't a real link. This shouldn't happen, but if it does
  // it's not a reason to crash the app.
  if (typeof to === "undefined") {
    return to;
  }

  if (typeof to === "string") {
    // If it's a string, and starts with a / (i.e. isn't relative), then
    // prepend the slug
    to = prependSlugToPathIfNeeded(to, slug);
  } else {
    // We've got an object, so let's fix the pathname if it exists
    if (to.pathname) {
      if (to.pathname.startsWith("/")) {
        to.pathname = prependSlugToPathIfNeeded(to.pathname, slug);
      }
    }
  }
  return to;
};

// prependSlugIfNeeded is a shared util to enrich a To target with
// the provided slug.
export const prependSlugToPathIfNeeded = (
  path: string,
  slug: string,
): string => {
  // If it's a string, and starts with a / (i.e. isn't relative), then
  // prepend the slug
  if (path.startsWith("/")) {
    if (path.startsWith("/api") || path.startsWith("/auth")) {
      // Do nothing: these URLs are for the backend and shouldn't be prepended with a slug
      return path;
    } else if (path.startsWith("/" + slug)) {
      return path;
    } else {
      return `/${slug}${path}`;
    }
  }
  return path;
};

const markAsInternalRedirect = (props) => {
  return {
    ...(props ?? {}),
    state: {
      ...props?.state,
      isInternalRedirect: true,
    },
  };
};
