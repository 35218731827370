import { ScopeNameEnum } from "@incident-io/api";
import { UpgradeRequiredProps } from "@incident-shared/gates/GatedButton/GatedButton";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { AnalyticsMetadata } from "src/contexts/AnalyticsContext";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./Modal.module.scss";

export type FooterWithConfirmButtonProps = {
  /* What to do on primary CTA click */
  hideConfirmButton?: boolean;
  onConfirm: () => void;
  confirmButtonType: "button";
  children?: never;
};

type FooterWithSubmitButtonProps = {
  hideConfirmButton?: boolean;
  onConfirm?: never;
  confirmButtonType: "submit";
  children?: never;
};
type FooterWithCustomChildrenProps = {
  hideConfirmButton?: boolean;
  onConfirm?: never;
  confirmButtonType?: never;
  /* Optionally pass in children instead of configuring buttons */
  children: React.ReactElement | (React.ReactElement | null)[];
};

type FooterWithNoConfirmButtonProps = {
  /* Don't render the primary CTA */
  hideConfirmButton: true;
  onConfirm?: never;
  confirmButtonType?: never;
  children?: React.ReactElement | (React.ReactElement | null)[];
};

type FooterButtonProps =
  | FooterWithConfirmButtonProps
  | FooterWithSubmitButtonProps
  | FooterWithCustomChildrenProps
  | FooterWithNoConfirmButtonProps;

export type FooterProps = {
  /* Whether to show loading spinner */
  saving?: boolean;
  /* Disable the primary CTA */
  disabled?: boolean;
  /* Tooltip to show if the primary CTA is disabled (passed to GatedButton) */
  disabledTooltipContent?: string;
  /* Scope required to use the primary CTA (passed to GatedButton) */
  requiredScope?: ScopeNameEnum;
  upgradeRequired?: boolean;
  upgradeRequiredProps?: UpgradeRequiredProps;
  /* What to do when cancel button is clicked (without it, the button won't render) */
  onClose?: () => void;
  /* Text for primary CTA */
  confirmButtonText?: string;
  /* Button theme for primary CTA */
  confirmButtonTheme?: ButtonTheme;
  /* Text for destroy CTA */
  deleteButtonText?: string;
  /* Button theme for destroy CTA */
  deleteButtonTheme?: ButtonTheme;
  /* What to do when delete button is clicked (without it, the button won't render) */
  onDelete?: () => void;
  /* Text for cancel button */
  cancelButtonText?: string;
  /* The name of the analytics event for the confirm button */
  analyticsTrackingId?: string;
  /* Any additional data for analytics */
  analyticsTrackingMetadata?: AnalyticsMetadata;
  /* An optional extra node that's rendered in the footer */
  gadget?: React.ReactElement;
  /* Where should the gadget go? Either on the far left, or in between cancel and confirm. */
  gadgetPosition?: "left" | "middle";
  buttonsClassName?: string;
} & FooterButtonProps;

export const ModalFooter = ({
  hideBorder,
  ...props
}: {
  /* Hide the top border */ hideBorder?: boolean;
} & FooterProps): React.ReactElement => {
  return (
    <>
      <div
        className={tcx(styles.modalFooter, "p-4", {
          "border-t border-stroke": !hideBorder,
        })}
      >
        <FooterContents {...props} />
      </div>
    </>
  );
};

export const FooterContents = ({
  disabled = false,
  disabledTooltipContent,
  requiredScope,
  upgradeRequired,
  upgradeRequiredProps,
  saving = false,
  onClose,
  analyticsTrackingId,
  analyticsTrackingMetadata = {},
  deleteButtonText = "Delete",
  deleteButtonTheme,
  onDelete,
  confirmButtonText = "Save",
  confirmButtonTheme,
  cancelButtonText = "Cancel",
  onConfirm,
  confirmButtonType = "submit",
  hideConfirmButton = undefined,
  gadget,
  gadgetPosition = "left",
  children,
  buttonsClassName,
}: FooterProps): React.ReactElement => {
  const onClickProp =
    confirmButtonType === "submit" ? {} : { onClick: onConfirm };
  if (children) {
    return <>{children}</>;
  }

  return (
    <div className="flex flex-row w-full items-center gap-2">
      {onDelete && (
        <Button
          analyticsTrackingId={`${analyticsTrackingId}-delete`}
          analyticsTrackingMetadata={analyticsTrackingMetadata}
          onClick={onDelete}
          theme={deleteButtonTheme || ButtonTheme.Destroy}
          className={buttonsClassName}
          loading={saving}
        >
          {deleteButtonText}
        </Button>
      )}
      <div className="flex-grow">
        {gadgetPosition === "left" ? gadget : undefined}
      </div>
      <div className={"flex gap-3"}>
        {onClose && (
          <Button
            analyticsTrackingId={`${analyticsTrackingId}-cancel`}
            analyticsTrackingMetadata={analyticsTrackingMetadata}
            onClick={onClose}
            className={buttonsClassName}
            disabled={saving}
            // This should tab _after_ the confirm button
            tabIndex={11}
          >
            {cancelButtonText}
          </Button>
        )}
        {gadgetPosition === "middle" && <>{gadget}</>}
        {!hideConfirmButton && (
          <GatedButton
            className={buttonsClassName}
            type={confirmButtonType}
            analyticsTrackingId={`${analyticsTrackingId}-submit`}
            analyticsTrackingMetadata={analyticsTrackingMetadata}
            theme={confirmButtonTheme || ButtonTheme.Primary}
            disabled={disabled}
            disabledTooltipContent={disabledTooltipContent}
            loading={saving}
            // This should be tabbed to _before_ the cancel button
            tabIndex={10}
            requiredScope={requiredScope}
            upgradeRequired={upgradeRequired}
            upgradeRequiredProps={upgradeRequiredProps}
            {...onClickProp}
          >
            {confirmButtonText}
          </GatedButton>
        )}
      </div>
    </div>
  );
};
