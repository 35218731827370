import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  ContentBox,
  IconEnum,
  StackedList,
} from "@incident-ui";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import _ from "lodash";
import { useState } from "react";
import {
  Incident,
  IncidentAttachment,
  Stream,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import {
  Attachment,
  AttachmentContexts,
} from "../attachments/IncidentAttachment";
import { EmptyTab } from "../EmptyTab";
import { incidentInEditableStatus } from "../helpers";
import { useAttachments, useIncident } from "../hooks";

export const Attachments = ({ incidentId }: { incidentId: string | null }) => {
  const { incident } = useIncident(incidentId);
  const inEditableStatus = incident
    ? incidentInEditableStatus(incident)
    : false;

  if (!incidentId) {
    return <LoadingBar className="h-18" />;
  }

  return (
    <AttachmentsInner
      incidentId={incidentId}
      incident={incident}
      inEditableStatus={inEditableStatus}
    />
  );
};

export const AttachmentsInStream = ({ stream }: { stream: Stream }) => {
  return (
    <AttachmentsInner
      incidentId={stream.id}
      incident={stream}
      inEditableStatus={true} // We don't have any non-editable statuses for streams
    />
  );
};

const AttachmentsInner = ({
  incidentId,
  incident,
  inEditableStatus,
}: {
  incidentId: string;
  incident?: Incident | Stream | null;
  inEditableStatus: boolean;
}) => {
  const { attachments, isLoading: attachmentsLoading } =
    useAttachments(incidentId);

  const [deletingAttachment, setDeletingAttachment] =
    useState<IncidentAttachment | null>(null);

  const { trigger: onRemove, isMutating: isDeleting } = useAPIMutation(
    "incidentAttachmentsList",
    { incidentId: incidentId ?? "" },
    async (apiClient, attachment: IncidentAttachment) => {
      await apiClient.incidentAttachmentsRemove({ id: attachment.id });
    },
    {
      onSuccess: () => setDeletingAttachment(null),
    },
  );

  if (!incident || attachmentsLoading) {
    return <LoadingBar className="h-18" />;
  }

  if (attachments.length === 0) {
    return (
      <EmptyTab
        icon={IconEnum.Attachment}
        subtitle="You haven't created any attachments yet."
      />
    );
  }

  const groupedAttachments = _.groupBy(
    attachments,
    (x) => x.resource.resource_type,
  );

  return (
    <LoadingWrapper loading={isDeleting}>
      <div className="space-y-4 text-sm">
        {Object.entries(groupedAttachments).map(
          ([resourceType, theseAttachments]) => (
            <StackedList key={resourceType}>
              {theseAttachments.map((attachment) => (
                <li className="p-4 flex-center-y" key={attachment.id}>
                  <Attachment
                    resource={attachment.resource}
                    key={attachment.id}
                    className="grow"
                    context={AttachmentContexts.RealTime}
                  />
                  {incident && inEditableStatus ? (
                    <Button
                      title="Remove attachment"
                      analyticsTrackingId="remove-incident-attachment"
                      onClick={() => setDeletingAttachment(attachment)}
                      icon={IconEnum.LinkBreak}
                      theme={ButtonTheme.Tertiary}
                    />
                  ) : null}
                </li>
              ))}
            </StackedList>
          ),
        )}
        {deletingAttachment != null && (
          <ConfirmationDialog
            title="Remove Attachment"
            analyticsTrackingId="remove-attachment"
            isOpen={!!deletingAttachment}
            onCancel={() => setDeletingAttachment(null)}
            onConfirm={() => onRemove(deletingAttachment)}
            confirmButtonText="Remove"
          >
            <>
              <span> Are you sure you want to remove this attachment?</span>
              <ContentBox className="p-4 !pt-0 mt-2">
                <Attachment
                  resource={deletingAttachment.resource}
                  key={deletingAttachment.id}
                  className="mt-4"
                  context={AttachmentContexts.RealTime}
                />
              </ContentBox>
            </>
          </ConfirmationDialog>
        )}
      </div>
    </LoadingWrapper>
  );
};
