import { EngineScope } from "@incident-io/api";
import { AddEditExpressionModal } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import _ from "lodash";
import { useState } from "react";
import { useAllResources } from "src/hooks/useResources";

import { ExpressionDeletionUsages } from "./ExpressionsEditor";
import { useExpressionsMethods } from "./ExpressionsMethodsProvider";
import { ExpressionFormData } from "./expressionToPayload";

export const ExpressionsDropdown = ({
  disabled = false,
  disabledExplanation,
  scopeWithExpressions,
  getExpressionUsages,
  returnMultipleItemsCheckboxHelptext,
}: {
  disabled?: boolean;
  disabledExplanation?: string;
  scopeWithExpressions: EngineScope;
  getExpressionUsages: (
    expression: ExpressionFormData,
  ) => ExpressionDeletionUsages | undefined;
  returnMultipleItemsCheckboxHelptext?: string;
}): React.ReactElement | null => {
  const [expressionState, setExpressionState] = useState<null | "new" | number>(
    null,
  );
  const { resources, resourcesLoading } = useAllResources();

  const { methods } = useExpressionsMethods();
  const expressions = _.uniq(methods?.fields) || [];

  if (expressions.length === 0 || !methods || resourcesLoading) {
    return null;
  }

  return (
    <>
      {expressionState != null && (
        <AddEditExpressionModal
          onClose={() => {
            setExpressionState(null);
          }}
          initialExpression={
            expressionState === "new" ? undefined : expressions[expressionState]
          }
          onAddExpression={(expr) => {
            methods.append(expr);
            setExpressionState(null);
          }}
          onEditExpression={(expr) => {
            methods.update(expressionState as number, expr);
            setExpressionState(null);
          }}
          onDeleteExpression={() => {
            methods.remove(expressionState as number);
            setExpressionState(null);
          }}
          scope={scopeWithExpressions || []}
          resources={resources || []}
          analyticsTrackingContext={"expressions-editor"}
          existingExpressions={expressions}
          allowAllOfACatalogType={true}
          currentlyInUse={
            expressionState === "new"
              ? undefined
              : getExpressionUsages(expressions[expressionState])
          }
          returnMultipleItemsCheckboxHelptext={
            returnMultipleItemsCheckboxHelptext
          }
        />
      )}
      <DropdownMenu
        menuClassName="min-w-[230px] group"
        disabled={disabled}
        tooltipContent={disabledExplanation}
        triggerButton={
          <Button
            analyticsTrackingId={"list-expressions"}
            className="w-fit"
            theme={ButtonTheme.Ghost}
            icon={IconEnum.Expression}
            iconProps={{ size: IconSize.Large }}
          >
            <span>Expressions</span>
            <Icon id={IconEnum.ChevronDown} size={IconSize.Medium} />
          </Button>
        }
        align="start"
      >
        {expressions.map((exp, i) => (
          <DropdownMenuItem
            label={exp.label}
            analyticsTrackingId={"select-expression"}
            key={exp.id}
            onSelect={() => {
              setExpressionState(i);
            }}
          >
            <Icon id={IconEnum.Expression} className="text-amber-500" />
            <span>{exp.label}</span>
          </DropdownMenuItem>
        ))}
        <DropdownMenuItem
          label="Add new expression"
          icon={IconEnum.Add}
          onSelect={() => setExpressionState("new")}
          analyticsTrackingId={"add-expression"}
        />
      </DropdownMenu>
    </>
  );
};
