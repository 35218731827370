import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions/marshall";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ConfigureDrawerProps } from "@incident-shared/integrations";
import { ButtonTheme, GenericErrorMessage } from "@incident-ui";
import { useForm } from "react-hook-form";
import {
  ConditionGroup,
  EngineScope,
  IncidentsBuildScopeContextEnum,
  IntegrationsVantaShowSettingsResponseBody,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useIncidentScope } from "../../../../../hooks/useIncidentScope";
import { IntegrationSettingsSection } from "../../common/IntegrationSettingsSection";
import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

export const VantaConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement => {
  const { data: vantaConfig, error: vantaConfigError } = useAPI(
    "integrationsVantaShowSettings",
    undefined,
  );

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.VantaSync,
  );

  if (vantaConfigError || scopeError) {
    return <GenericErrorMessage error={vantaConfigError || scopeError} />;
  }

  if (!vantaConfig || scopeLoading) {
    return <IntegrationDrawerContentLoader />;
  }

  return (
    <GenericConfigureDrawerContents {...props}>
      <DrawerInner config={vantaConfig} scope={scope} />
    </GenericConfigureDrawerContents>
  );
};

type FormState = {
  trackFollowups: boolean;
  followupConditionGroups: ConditionGroup[];
};

const DrawerInner = ({
  config,
  scope,
}: {
  config: IntegrationsVantaShowSettingsResponseBody;
  scope: EngineScope;
}): React.ReactElement => {
  const formMethods = useForm<FormState>({
    defaultValues: {
      trackFollowups: config.track_followups,
      followupConditionGroups: config.followup_condition_groups || [],
    },
  });
  const {
    setError,
    formState: { isDirty, isValid },
    watch,
  } = formMethods;

  const trackFollowups = watch("trackFollowups");

  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const { refetchSettings } = useSettings();
  const {
    trigger: updateConfig,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsVantaShowSettings",
    undefined,
    async (apiClient, data: FormState) =>
      await apiClient.integrationsVantaSetSettings({
        setSettingsRequestBody: {
          track_followups: data.trackFollowups,
          followup_condition_groups: conditionGroupsToGroupPayloads(
            data.followupConditionGroups,
          ),
        },
      }),
    {
      onSuccess: async () => {
        await refetchSettings();
      },
      setError,
    },
  );

  return (
    <FormV2
      formMethods={formMethods}
      genericError={genericError}
      saving={saving}
      onSubmit={updateConfig}
    >
      <IntegrationSettingsSection
        enabled={trackFollowups}
        enabledID="trackFollowups"
        label="Track follow-ups in Vanta"
        helptext={`When enabled, we'll add all follow-ups matching the conditions you define as Security Tasks in Vanta.`}
        disabled={!canEditSettings}
      >
        <ConditionGroupsEditorV2
          formMethods={formMethods}
          label="Conditions"
          helptext="Which incidents should have their follow-ups tracked in Vanta?"
          name="followupConditionGroups"
          wrapperClassName="mt-2"
          scope={scope}
          populatedIntroSentence="Follow-ups will be exported to Vanta when..."
          emptyIntroSentence={
            <>
              We will export follow-ups to Vanta for{" "}
              <span className="font-medium">all incidents</span>.
            </>
          }
        />
      </IntegrationSettingsSection>
      <GatedButton
        type="submit"
        analyticsTrackingId={"save-vanta-settings"}
        disabled={!isDirty || !isValid}
        loading={saving}
        theme={ButtonTheme.Primary}
        requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
      >
        Save
      </GatedButton>
    </FormV2>
  );
};
