import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { IconEnum, Link } from "@incident-ui";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemContentBox } from "src/components/timeline/TimelineItemContentBox";
import { TimelineItemSource } from "src/components/timeline/TimelineItemSource";
import {
  Incident,
  SlimIncidentVisibilityEnum,
  TimelineItemObject,
} from "src/contexts/ClientContext";

export const TimelineItemMerged = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.incident_merged) {
    throw new Error("malformed timeline item: merged was missing merged field");
  }
  let description: React.ReactNode = null;
  let children: React.ReactNode = null;

  if (
    item.incident_merged.incident.visibility ===
    SlimIncidentVisibilityEnum.Private
  ) {
    description = (
      <>
        <TimelineItemSource actor={item.incident_merged.merger} /> merged{" "}
        <Link
          to={`/incidents/${item.incident_merged.incident.id}`}
          openInNewTab
          analyticsTrackingId={null}
        >
          a private incident
        </Link>{" "}
        into this one
      </>
    );
  } else {
    description = (
      <>
        <TimelineItemSource actor={item.incident_merged.merger} /> merged an
        incident into this one
      </>
    );

    children = (
      <TimelineItemContentBox>
        <div className="font-medium mb-2">
          <Link
            to={`/incidents/${item.incident_merged.incident.id}`}
            openInNewTab
            analyticsTrackingId={null}
          >
            {item.incident_merged.incident.reference}{" "}
            {item.incident_merged.incident.name}
          </Link>
        </div>
        {item.incident_merged.incident.summary && (
          <TemplatedTextDisplay
            value={item.incident_merged.incident.summary.text_node}
            style={TemplatedTextDisplayStyle.Compact}
          />
        )}
      </TimelineItemContentBox>
    );
  }

  return {
    description,
    children,

    icon: IconEnum.Merge,
    avatarUrl: item.incident_merged.merger?.user?.avatar_url,
  };
};
