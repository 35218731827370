import { FieldValues } from "react-hook-form";
import {
  ControlledInputProps,
  ControlledInputWrapper,
} from "src/components/@shared/forms/v1/controlled/ControlledInputWrapper";
import {
  DeprecatedTextEditor,
  Format,
  InterpolatedVariable,
} from "src/components/@shared/forms/v1/deprecated-text-editor/TextEditor";

export const DeprecatedControlledTextEditorInput = <
  FormType extends FieldValues,
>({
  id,
  errors,
  control,
  label,
  placeholder,
  helptext,
  format,
  required,
  variables,
  suffixNode,
  className,
}: {
  variables?: Array<InterpolatedVariable>;
  format?: Format;
  className?: string;
} & ControlledInputProps<FormType>): React.ReactElement => {
  return (
    <ControlledInputWrapper<FormType>
      label={label}
      helptext={helptext}
      id={id}
      errors={errors}
      control={control}
      suffixNode={suffixNode}
      rules={{
        required: required ? "This field is required" : false,
      }}
      className={className}
      render={({ field: { value, onChange } }) => {
        return (
          <DeprecatedTextEditor
            id={id}
            format={format}
            variables={variables}
            placeholder={placeholder}
            onChange={onChange}
            initialValue={value as string}
          />
        );
      }}
    />
  );
};
