import { Tab, TabPane, TabSection } from "@incident-ui";

import { BootstrapCustomerChannel } from "./BootstrapCustomerChannel";
import { StaffPermissionsPage } from "./StaffPermissionsPage";

export const StaffLandingPage = () => {
  return (
    <TabSection tabs={tabs} withIndicator>
      <TabPane tabId="utilities" className="space-y-4">
        <BootstrapCustomerChannel />
      </TabPane>

      <TabPane tabId="users" className="space-y-4">
        <StaffPermissionsPage />
      </TabPane>
    </TabSection>
  );
};

const tabs: Tab[] = [
  {
    label: "Utilities",
    id: "utilities",
  },
  {
    label: "Users and Permissions",
    id: "users",
  },
];
