import styles from "src/components/@ui/Table/DeprecatedTable.module.scss";
import { tcx } from "src/utils/tailwind-classes";

type Props = {
  children: React.ReactNode;
  className?: string;
  tableClassName?: string;
  roundedTop?: boolean;
};

export function DeprecatedTable({
  children,
  className,
  tableClassName,
  roundedTop = true,
}: Props): React.ReactElement {
  return (
    <div
      className={tcx(
        styles.tableWrapper,
        "border text-sm border-stroke bg-white shadow overflow-hidden",
        { [styles.tableWrapperRoundedTop]: roundedTop },
        className,
      )}
    >
      <table
        className={tcx(
          styles.table,
          { [styles.tableRoundedTop]: roundedTop },
          "text-left",
          tableClassName,
        )}
      >
        {children}
      </table>
    </div>
  );
}

export function DeprecatedTableHeaderRow({
  children,
  className,
}: Props): React.ReactElement {
  return (
    <thead
      className={tcx(
        "rounded-t-lg bg-surface-secondary text-slate-600",
        className,
      )}
    >
      <tr>{children}</tr>
    </thead>
  );
}

export function DeprecatedTableHeaderCell({
  children,
  scope = "col",
  className,
  textHidden,
  colSpan,
}: {
  children: React.ReactNode;
  scope?: "col" | "row";
  colSpan?: number;
  className?: string;
  textHidden?: boolean;
}): React.ReactElement {
  return (
    <th
      scope={scope}
      colSpan={colSpan}
      className={tcx("font-normal", className)}
    >
      <span className={tcx({ "sr-only": textHidden })}>{children}</span>
    </th>
  );
}
