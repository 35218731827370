import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import {
  Incident,
  IncidentsCreateUpdateRequestBodyPostIncidentFlowDecisionEnum,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  TextDocumentPayload,
} from "src/contexts/ClientContext";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

type FormData = { message: TextDocumentPayload };
export const MoveToNextStatusModal = ({
  statusToMoveTo,
  incidentId,
  onClose,
}: {
  statusToMoveTo: IncidentStatus;
  incidentId: string;
  onClose: (inc?: Incident) => void;
}) => {
  const formMethods = useForm<FormData>();

  const refetchIncident = useAPIRefetch("incidentsShow", { id: incidentId });
  const refetchTimeline = useAPIRefetch("timelineItemsList", {
    incidentId,
  });
  const refetchUpdates = useAPIRefetch("incidentUpdatesListForIncident", {
    incidentId,
  });

  const {
    trigger: createUpdate,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incidentId },
    async (apiClient, formData: FormData) => {
      await apiClient.incidentsCreateUpdate({
        incidentId,
        createUpdateRequestBody: {
          message: formData.message,
          post_incident_flow_decision:
            IncidentsCreateUpdateRequestBodyPostIncidentFlowDecisionEnum.None,
          to_incident_status_id: statusToMoveTo.id,
        },
      });

      await Promise.all([
        refetchIncident(),
        refetchTimeline(),
        refetchUpdates(),
      ]);
    },
    {
      onSuccess: () => onClose(),
      setError: formMethods.setError,
    },
  );

  const isClosing =
    statusToMoveTo.category === IncidentStatusCategoryEnum.Closed;
  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={createUpdate}
      title={isClosing ? "Close incident" : "Continue post-incident flow"}
      analyticsTrackingId={"move-to-next-status-modal"}
      onClose={onClose}
      genericError={genericError}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText={isClosing ? "Close" : "Next"}
          confirmButtonType="submit"
        />
      }
    >
      <TemplatedTextInputV2
        name="message.text_node"
        placeholder={`What was covered during this part of the post-incident flow?\nAnything that you'd like to note?`}
        label="Message"
        formMethods={formMethods}
        required
        includeVariables={false}
        includeExpressions={false}
        format="slack_rich_text"
      />
    </FormModalV2>
  );
};
