/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnCallNotificationsCreateRuleRequestBody
 */
export interface OnCallNotificationsCreateRuleRequestBody {
    /**
     * Delay in seconds before this rule activates
     * @type {number}
     * @memberof OnCallNotificationsCreateRuleRequestBody
     */
    delay_seconds: number;
    /**
     * The ID of the method to use for this rule, if appropriate
     * @type {string}
     * @memberof OnCallNotificationsCreateRuleRequestBody
     */
    method_id?: string;
    /**
     * Notice period in seconds before this rule activates
     * @type {number}
     * @memberof OnCallNotificationsCreateRuleRequestBody
     */
    notice_period_seconds?: number;
    /**
     * The type of escalation notification, always required
     * @type {string}
     * @memberof OnCallNotificationsCreateRuleRequestBody
     */
    notification_type: OnCallNotificationsCreateRuleRequestBodyNotificationTypeEnum;
    /**
     * The type of notification rule
     * @type {string}
     * @memberof OnCallNotificationsCreateRuleRequestBody
     */
    rule_type: OnCallNotificationsCreateRuleRequestBodyRuleTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum OnCallNotificationsCreateRuleRequestBodyNotificationTypeEnum {
    Slack = 'slack',
    Email = 'email',
    App = 'app',
    Sms = 'sms',
    Phone = 'phone',
    Dashboard = 'dashboard',
    SlackChannel = 'slack_channel',
    MicrosoftTeams = 'microsoft_teams'
}/**
* @export
* @enum {string}
*/
export enum OnCallNotificationsCreateRuleRequestBodyRuleTypeEnum {
    HighUrgency = 'high_urgency',
    LowUrgency = 'low_urgency',
    ShiftChanges = 'shift_changes'
}

export function OnCallNotificationsCreateRuleRequestBodyFromJSON(json: any): OnCallNotificationsCreateRuleRequestBody {
    return OnCallNotificationsCreateRuleRequestBodyFromJSONTyped(json, false);
}

export function OnCallNotificationsCreateRuleRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationsCreateRuleRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delay_seconds': json['delay_seconds'],
        'method_id': !exists(json, 'method_id') ? undefined : json['method_id'],
        'notice_period_seconds': !exists(json, 'notice_period_seconds') ? undefined : json['notice_period_seconds'],
        'notification_type': json['notification_type'],
        'rule_type': json['rule_type'],
    };
}

export function OnCallNotificationsCreateRuleRequestBodyToJSON(value?: OnCallNotificationsCreateRuleRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delay_seconds': value.delay_seconds,
        'method_id': value.method_id,
        'notice_period_seconds': value.notice_period_seconds,
        'notification_type': value.notification_type,
        'rule_type': value.rule_type,
    };
}

