import { InputOrVariable } from "@incident-shared/engine";
import { MenuEntry } from "@incident-shared/engine";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { InputType } from "@incident-ui/Input/Input";
import React from "react";
import { useFormContext } from "react-hook-form";
import {
  EngineScope,
  JiraIssueField as JiraIssueField,
} from "src/contexts/ClientContext";

export function JiraCloudTextInputOrRef({
  field,
  fieldKey,
  disabled = false,
  scope,
}: {
  field: JiraIssueField;
  fieldKey: string;
  disabled?: boolean;
  scope: EngineScope;
}): React.ReactElement {
  const formMethods = useFormContext();

  const literalKey = `${fieldKey}.value.literal`;

  return (
    <div>
      <FormInputWrapperV2
        name={`${fieldKey}.value`}
        label={field.label}
        required={field.required}
      >
        <InputOrVariable
          name={`${fieldKey}.value`}
          label={field.label}
          scopeAndIsAlert={{ scope }}
          required={field.required}
          disabled={disabled}
          array={false}
          includeExpressions={false}
          includeStatic
          includeVariables
          allowAnyResource
          // Hack alert! We can't use TemplatedText here because the backend serializes all the values
          // to a string and it makes our templated text editor sad. Instead, we check that we can interpolate
          // these variables which means we should be able to convert them to a string without any issues in the
          // backend.
          isSelectableOverride={(entry: MenuEntry) =>
            entry.resource.can_be_interpolated
          }
          renderChildren={(renderLightningButton) => (
            <InputV2
              formMethods={formMethods}
              disabled={disabled}
              name={literalKey}
              type={InputType.Text}
              className="w-full"
              // We mustn't make this required, or we render the 'please provide a value' error twice, once
              // in InputOrVariable and once here
              required={false}
              insetSuffixNode={renderLightningButton()}
            />
          )}
        />
      </FormInputWrapperV2>
    </div>
  );
}
