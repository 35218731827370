/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';
import {
    LegacyIncidentTriggerIncidentTemplatePayload,
    LegacyIncidentTriggerIncidentTemplatePayloadFromJSON,
    LegacyIncidentTriggerIncidentTemplatePayloadFromJSONTyped,
    LegacyIncidentTriggerIncidentTemplatePayloadToJSON,
} from './LegacyIncidentTriggerIncidentTemplatePayload';

/**
 * 
 * @export
 * @interface LegacyIncidentTriggersUpdateConfigRequestBody
 */
export interface LegacyIncidentTriggersUpdateConfigRequestBody {
    /**
     * Whether we should auto-decline incidents that match our predefined rules
     * @type {boolean}
     * @memberof LegacyIncidentTriggersUpdateConfigRequestBody
     */
    auto_decline_enabled: boolean;
    /**
     * What condition groups must be true of the external resource to trigger an incident from it?
     * @type {Array<ConditionGroupPayload>}
     * @memberof LegacyIncidentTriggersUpdateConfigRequestBody
     */
    condition_groups?: Array<ConditionGroupPayload>;
    /**
     * How long after an Incident fires on a service do we assume this is probably related, rather than defaulting to assuming this is a new thing
     * @type {number}
     * @memberof LegacyIncidentTriggersUpdateConfigRequestBody
     */
    debounce_window_in_minutes?: number;
    /**
     * Whether auto creation for this trigger is enabled
     * @type {boolean}
     * @memberof LegacyIncidentTriggersUpdateConfigRequestBody
     */
    enabled: boolean;
    /**
     * The expressions to use
     * @type {Array<ExpressionPayload>}
     * @memberof LegacyIncidentTriggersUpdateConfigRequestBody
     */
    expressions?: Array<ExpressionPayload>;
    /**
     * 
     * @type {LegacyIncidentTriggerIncidentTemplatePayload}
     * @memberof LegacyIncidentTriggersUpdateConfigRequestBody
     */
    incident_template?: LegacyIncidentTriggerIncidentTemplatePayload;
}

export function LegacyIncidentTriggersUpdateConfigRequestBodyFromJSON(json: any): LegacyIncidentTriggersUpdateConfigRequestBody {
    return LegacyIncidentTriggersUpdateConfigRequestBodyFromJSONTyped(json, false);
}

export function LegacyIncidentTriggersUpdateConfigRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegacyIncidentTriggersUpdateConfigRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_decline_enabled': json['auto_decline_enabled'],
        'condition_groups': !exists(json, 'condition_groups') ? undefined : ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'debounce_window_in_minutes': !exists(json, 'debounce_window_in_minutes') ? undefined : json['debounce_window_in_minutes'],
        'enabled': json['enabled'],
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'incident_template': !exists(json, 'incident_template') ? undefined : LegacyIncidentTriggerIncidentTemplatePayloadFromJSON(json['incident_template']),
    };
}

export function LegacyIncidentTriggersUpdateConfigRequestBodyToJSON(value?: LegacyIncidentTriggersUpdateConfigRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_decline_enabled': value.auto_decline_enabled,
        'condition_groups': value.condition_groups === undefined ? undefined : ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'debounce_window_in_minutes': value.debounce_window_in_minutes,
        'enabled': value.enabled,
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'incident_template': LegacyIncidentTriggerIncidentTemplatePayloadToJSON(value.incident_template),
    };
}

