/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';

/**
 * 
 * @export
 * @interface RequirementsTemplate
 */
export interface RequirementsTemplate {
    /**
     * Condition groups for this template
     * @type {Array<ConditionGroup>}
     * @memberof RequirementsTemplate
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * The icon for the template
     * @type {string}
     * @memberof RequirementsTemplate
     */
    icon: RequirementsTemplateIconEnum;
    /**
     * The label for the template
     * @type {string}
     * @memberof RequirementsTemplate
     */
    label: string;
}

/**
* @export
* @enum {string}
*/
export enum RequirementsTemplateIconEnum {
    Action = 'action',
    Alert = 'alert',
    Bolt = 'bolt',
    ToggleLeft = 'toggle-left',
    Book = 'book',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Checkmark = 'checkmark',
    Clipboard = 'clipboard',
    Clock = 'clock',
    Close = 'close',
    Cog = 'cog',
    Components = 'components',
    CustomField = 'custom_field',
    Database = 'database',
    Delete = 'delete',
    Doc = 'doc',
    Email = 'email',
    Escalate = 'escalate',
    EscalationPath = 'escalation-path',
    Exclamation = 'exclamation',
    Export = 'export',
    ExternalLink = 'external-link',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    FollowUps = 'follow_ups',
    GitBranchNew = 'git-branch-new',
    Globe = 'globe',
    Incident = 'incident',
    IncidentType = 'incident_type',
    Key = 'key',
    MsTeams = 'ms-teams',
    Merge = 'merge',
    Message = 'message',
    Money = 'money',
    Mug = 'mug',
    NumberInput = 'number-input',
    Priority = 'priority',
    Private = 'private',
    TextAlignLeft = 'text-align-left',
    Search = 'search',
    Server = 'server',
    Severity = 'severity',
    Slack = 'slack',
    SlackChannel = 'slack_channel',
    SlackTeam = 'slack_team',
    Star = 'star',
    Status = 'status',
    StatusPage = 'status-page',
    Store = 'store',
    Tag = 'tag',
    Test = 'test',
    Text = 'text',
    Timestamp = 'timestamp',
    Triage = 'triage',
    User = 'user',
    Users = 'users',
    Warning = 'warning'
}

export function RequirementsTemplateFromJSON(json: any): RequirementsTemplate {
    return RequirementsTemplateFromJSONTyped(json, false);
}

export function RequirementsTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequirementsTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'icon': json['icon'],
        'label': json['label'],
    };
}

export function RequirementsTemplateToJSON(value?: RequirementsTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'icon': value.icon,
        'label': value.label,
    };
}

