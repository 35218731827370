import { CatalogEntryBadge } from "@incident-shared/attribute";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { FormLabelV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  Icon,
  IconEnum,
  Modal,
  ModalContent,
  ModalFooter,
  Steps,
  Txt,
} from "@incident-ui";
import { StepConfig } from "@incident-ui/Steps/Steps";
import { isSameDay } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import {
  ScopeNameEnum,
  StatusPage,
  StatusPageAffectedComponentPayloadStatusEnum as ComponentStatusEnum,
  StatusPageCreateMaintenanceRequestBody,
  StatusPageCreateMaintenanceRequestBodyStatusEnum as IncidentStatusEnum,
  StatusPagePageTypeEnum,
  StatusPageStructure,
  StatusPageTemplate,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { formatTimestampLocale } from "src/utils/datetime";
import { useAPIMutation } from "src/utils/swr";

import { StatusInput } from "../../common/StatusInput";
import { SubPageReviewInfo } from "../../common/SubPageReviewInfo";
import { defaultAllComponentsOperational } from "../../common/utils/default-all-components-operational";
import {
  messageTemplateFor,
  useTemplatedMessageInput,
} from "../../common/utils/template-helpers";
import { MaintenanceWindowDescription } from "../common/MaintenanceTimestampEditModal";
import { SubPageEditor, SubPageEditorWarning } from "../common/SubPageEditor";
import { useSubPageData } from "../hooks/use-sub-page-data";
import {
  INITIAL_MAINTENANCE_STATUS,
  MAINTENANCE_STATUS_CONFIG,
} from "../utils/utils";
import {
  AffectedComponentsEditor,
  AffectedComponentsFormData,
  affectedComponentsFormStateToPayload,
  affectedComponentsReviewInfo,
} from "../view/AffectedComponentsEditor";

type FormData = Pick<
  StatusPageCreateMaintenanceRequestBody,
  | "name"
  | "status"
  | "start_at"
  | "end_at"
  | "created_from_template_id"
  | "manual_sub_page_ids"
> & {
  // Typescript can't handle our recursive rich text type
  message: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  notify_subscribers: boolean;
  automate_maintenance_status: string;
  manual_sub_pages_enabled: boolean;
} & AffectedComponentsFormData;

enum steps {
  Info = "info",
  Review = "review",
}

const stepConfig: StepConfig<steps>[] = [
  {
    id: steps.Info,
    name: "Maintenance details",
  },
  {
    id: steps.Review,
    name: "Review & publish",
  },
];

const modalProps = {
  isOpen: true,
  isExtraLarge: true,
  title: "Schedule maintenance",
  analyticsTrackingId: "status-page-schedule-maintenance",
};

export const StatusPageScheduledMaintenanceCreatePage = ({
  page,
  structure,
  templates,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
  templates: StatusPageTemplate[];
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () =>
    history.length > 0 ? history.back() : navigate(`/status-pages/${page?.id}`);
  const { hasScope } = useIdentity();

  if (!hasScope(ScopeNameEnum.StatusPagesPublishUpdates)) {
    return (
      <Modal {...modalProps} onClose={onClose} disableQuickClose={false}>
        <ModalContent>
          Sorry, you don&apos;t have permission to publish maintenance events to
          your organisation&apos;s public status page.
        </ModalContent>
        <ModalFooter onClose={onClose} hideConfirmButton />
      </Modal>
    );
  }

  return (
    <StatusPageScheduleMaintenanceInner
      page={page}
      structure={structure}
      onClose={onClose}
      templates={templates}
    />
  );
};

const StatusPageScheduleMaintenanceInner = ({
  page,
  structure,
  onClose,
  templates,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
  onClose: () => void;
  templates: StatusPageTemplate[];
}): React.ReactElement => {
  const defaultStatuses = defaultAllComponentsOperational(structure);

  const defaultTemplate = messageTemplateFor(
    templates,
    IncidentStatusEnum.MaintenanceScheduled,
  );

  const formMethods = useForm<FormData>({
    defaultValues: {
      status: INITIAL_MAINTENANCE_STATUS as unknown as IncidentStatusEnum,
      component_statuses: defaultStatuses,
      notify_subscribers: true,
      automate_maintenance_status: AutomateMaintenanceWindowsEnum.Automated,
      message: defaultTemplate?.content,
      created_from_template_id: defaultTemplate?.id,
    },
  });

  const navigate = useOrgAwareNavigate();

  const [currentStep, setCurrentStep] = useState(steps.Info);

  const [
    automateMaintenanceStatus,
    status,
    componentStatuses,
    start_at,
    end_at,
    selectedSubPageIDs,
    manualSubPagesEnabled,
  ] = formMethods.watch([
    "automate_maintenance_status",
    "status",
    "component_statuses",
    "start_at",
    "end_at",
    "manual_sub_page_ids",
    "manual_sub_pages_enabled",
  ]);

  const { catalogType, affectedPages } = useSubPageData(
    page,
    componentStatuses,
    selectedSubPageIDs ?? [],
  );

  const statusHelptext = MAINTENANCE_STATUS_CONFIG[status].helptext;

  // we want to clear any errors when the values are changed, as we set an error
  // if this isn't populated
  useEffect(() => {
    formMethods.clearErrors("component_statuses");
  }, [Object.values(componentStatuses).sort().join(".")]); // eslint-disable-line react-hooks/exhaustive-deps

  const { trigger, isMutating, genericError } = useAPIMutation(
    "statusPageListIncidents",
    {
      statusPageId: page.id,
    },
    async (apiClient, data: FormData) => {
      const payload: StatusPageCreateMaintenanceRequestBody = {
        ...data,
        status_page_id: page.id,
        component_statuses: affectedComponentsFormStateToPayload(data),
        suppress_notifications: !data.notify_subscribers,
        start_at: data.start_at,
        end_at: data.end_at,
        automate_maintenance_status:
          data.automate_maintenance_status ===
          AutomateMaintenanceWindowsEnum.Automated,
        manual_sub_page_ids: data.manual_sub_pages_enabled
          ? data.manual_sub_page_ids
          : undefined,
      };
      const res = await apiClient.statusPageCreateMaintenance({
        createMaintenanceRequestBody: payload,
      });

      navigate(
        `/status-pages/${page.id}/incident/${res.status_page_incident.id}`,
      );

      // Trigger a revalidate
      return;
    },
    {
      setError: formMethods.setError,
    },
  );

  formMethods.register("automate_maintenance_status", {
    validate: (value, formValues) => {
      if (
        value === AutomateMaintenanceWindowsEnum.Automated &&
        formValues.end_at < new Date()
      ) {
        return "Automatic status updates are not supported for retrospective maintenance windows.";
      }
      return undefined;
    },
  });

  const { renderTemplateSelect } = useTemplatedMessageInput<
    FormData,
    "status",
    "status"
  >({
    formMethods,
    page,
    templates,
    statusPath: "status",
    messagePath: "message",
    templateIdPath: "created_from_template_id",
    namePath: "name",
  });

  return (
    <FormModalV2
      {...modalProps}
      disableQuickClose
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={
        currentStep === steps.Review
          ? trigger
          : async () => {
              if (
                Object.values(componentStatuses).filter(
                  (s) => s === ComponentStatusEnum.UnderMaintenance,
                ).length === 0
              ) {
                formMethods.setError(
                  "component_statuses",
                  new Error("must_set_component_status"),
                );
                return;
              } else {
                formMethods.clearErrors("component_statuses");
              }

              const isValid = await formMethods.trigger();
              if (isValid) {
                setCurrentStep(steps.Review);
              }
            }
      }
      saving={isMutating}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={
            currentStep === steps.Info
              ? onClose
              : () => setCurrentStep(steps.Info)
          }
          cancelButtonText={currentStep === steps.Info ? "Cancel" : "Back"}
          confirmButtonType="submit"
          confirmButtonText={
            currentStep === steps.Info ? "Review" : "Publish maintenance"
          }
        />
      }
    >
      <Steps steps={stepConfig} currentStep={currentStep} />
      <div>
        {currentStep === steps.Info ? (
          <div className="space-y-6">
            {page.mirroring_atlassian_page && (
              <Callout theme={CalloutTheme.Warning}>
                This maintenance won&apos;t appear on your Atlassian Statuspage.
                If you&apos;re ready to switch to your incident.io Status Page,
                click &lsquo;Go live&rsquo;.
              </Callout>
            )}
            {renderTemplateSelect()}
            <InputV2
              formMethods={formMethods}
              name="name"
              helptext={
                "The publicly visible name for this maintenance window."
              }
              required="You must set a name for each maintenance window."
              label="Name"
            />

            <div className="flex flex-col gap-2">
              <RadioButtonGroupV2
                formMethods={formMethods}
                label="Automate status updates"
                name="automate_maintenance_status"
                srLabel="Automate status updates"
                required
                boxed
                options={[
                  {
                    label: "Automatically update status",
                    value: AutomateMaintenanceWindowsEnum.Automated,
                    description:
                      "We'll automatically set the maintenance window to 'In progress', and 'Complete' when the window starts & ends.",
                  },
                  {
                    label: "Manually update status",
                    value: AutomateMaintenanceWindowsEnum.Manual,
                    description:
                      "You'll have to set the status manually by providing updates in the dashboard.",
                  },
                ]}
              />
              {automateMaintenanceStatus ===
                AutomateMaintenanceWindowsEnum.Manual && (
                <StatusInput
                  formMethods={formMethods}
                  name="status"
                  label="Status"
                  helptext={statusHelptext}
                  disableComplete={end_at > new Date()}
                  maintenance
                />
              )}
            </div>
            <div className="flex flex-col gap-2">
              <div>
                <FormLabelV2 htmlFor="name" required>
                  Impact window
                </FormLabelV2>
                <div className="flex flex-row items-baseline gap-2 text-sm text-slate-600">
                  Scheduled from{" "}
                  <DateTimeInputV2
                    required={"When does this maintenance window start?"}
                    name="start_at"
                    formMethods={formMethods}
                    rules={{
                      validate: (value: Date): string | undefined => {
                        if (value > end_at) {
                          return "Start date must be before end date";
                        }
                        return undefined;
                      },
                    }}
                  />{" "}
                  until{" "}
                  <DateTimeInputV2
                    required={"When does this maintenance window end?"}
                    name="end_at"
                    formMethods={formMethods}
                    rules={{
                      validate: (value: Date): string | undefined => {
                        if (value < start_at) {
                          return "End date must be after start date";
                        }
                        return undefined;
                      },
                    }}
                  />
                </div>
              </div>
              <MaintenanceWindowDescription
                start_at={start_at}
                end_at={end_at}
              />
            </div>
            <TemplatedTextInputV2
              includeExpressions={false}
              includeVariables={false}
              formMethods={formMethods}
              name="message"
              label="Message"
              required="What is the suspected impact right now?"
              // We only allow basic formatting here - no headings!
              format="mrkdwn"
              multiLine
            />
            <AffectedComponentsEditor
              structure={structure}
              maintenance
              formMethods={formMethods}
              fieldNamePrefix="component_statuses"
            />
            {page.page_type !== StatusPagePageTypeEnum.Standalone && (
              <div className="space-y-2">
                <div className="flex justify-between">
                  <Txt bold>Affected sub-pages</Txt>
                  {!manualSubPagesEnabled && (
                    <Button
                      onClick={() => {
                        formMethods.setValue("manual_sub_pages_enabled", true);
                        formMethods.setValue(
                          `manual_sub_page_ids`,
                          affectedPages.map((page) => page.id),
                        );
                      }}
                      analyticsTrackingId={"edit-sub-pages"}
                      theme={ButtonTheme.Naked}
                      icon={IconEnum.Edit}
                    >
                      Edit
                    </Button>
                  )}
                </div>
                {manualSubPagesEnabled ? (
                  // show a warning that the user is on their own and that they'll
                  // be responsible for manually updating the sub-pages going forward
                  // and then render a list of checkboxes to enable/disable each page,
                  // defaulting to enabling the current list of affected pages.
                  <>
                    <SubPageEditorWarning affectedPages={affectedPages} />
                    <SubPageEditor page={page} />
                  </>
                ) : affectedPages.length === 0 ? (
                  <div className="bg-white p-4 shadow-sm rounded-2 border border-stroke text-sm">
                    This incident will not be visible on any{" "}
                    {page.page_type === StatusPagePageTypeEnum.Parent
                      ? "sub-pages"
                      : "customer pages"}
                    . Change the affected components to ensure that it is
                    visible to your users.
                  </div>
                ) : (
                  <div className="flex items-center flex-wrap gap-2 rounded-2 shadow p-4 bg-white">
                    {affectedPages.map((page) => (
                      <CatalogEntryBadge
                        key={page.id}
                        color={catalogType?.color}
                        icon={catalogType?.icon}
                        label={page.name}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <ReviewInfo page={page} structure={structure} />
        )}
      </div>
    </FormModalV2>
  );
};

export enum AutomateMaintenanceWindowsEnum {
  Manual = "manual",
  Automated = "automated",
}

const ReviewInfo = ({
  page,
  structure,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
}): React.ReactElement => {
  const formMethods = useFormContext<FormData>();
  const [
    name,
    status,
    message,
    startAt,
    endAt,
    componentStatuses,
    notifySubscribers,
    automateMaintenanceStatus,
  ] = formMethods.watch([
    "name",
    "status",
    "message",
    "start_at",
    "end_at",
    "component_statuses",
    "notify_subscribers",
    "automate_maintenance_status",
  ]);

  const componentsToShow = affectedComponentsReviewInfo(
    structure,
    componentStatuses,
  );

  return (
    <>
      <p className="text-sm mb-4">
        Review the information below and hit the{" "}
        <span className="font-semibold">Schedule maintenance</span> button when
        you&apos;re ready.
      </p>
      <ContentBox className="p-4 text-sm grid grid-cols-3 gap-4">
        <div className="text-content-tertiary">Status page</div>
        <div className="col-span-2">{page.name}</div>

        <div className="text-content-tertiary">Name</div>
        <div className="col-span-2">{name}</div>

        <div className="text-content-tertiary">Status</div>
        <div className="col-span-2">
          {MAINTENANCE_STATUS_CONFIG[status].label}
          {automateMaintenanceStatus ===
            AutomateMaintenanceWindowsEnum.Automated && (
            <span className="text-content-tertiary pl-2">(Automated)</span>
          )}
        </div>

        <div className="text-content-tertiary">Message</div>
        <div className="col-span-2">
          <TemplatedTextDisplay
            value={message}
            style={TemplatedTextDisplayStyle.Compact}
          />
        </div>

        <div className="text-content-tertiary">Schedule</div>
        <div className="col-span-2">
          <span className="text-content-tertiary">From</span>{" "}
          {formatTimestampLocale({
            timestamp: startAt,
            dateStyle: "long",
            timeStyle: "short",
          })}{" "}
          <span className="text-content-tertiary">until</span>{" "}
          {formatTimestampLocale({
            timestamp: endAt,
            dateStyle: isSameDay(startAt, endAt) ? undefined : "long",
            timeStyle: "short",
          })}
        </div>

        <div className="text-content-tertiary">Components</div>
        <div className="col-span-2 space-y-2">
          {componentsToShow.length > 0 ? (
            componentsToShow.map(({ componentId, componentName, status }) => {
              const statusConfig = COMPONENT_STATUS_CONFIG[status];
              return (
                <div
                  key={componentId}
                  className="flex flex-col lg:flex-row lg:space-x-3"
                >
                  <div className="flex space-x-1">
                    <Icon
                      id={statusConfig.icon}
                      className={statusConfig.colour}
                    />
                    <span>{componentName}</span>
                  </div>
                  <span className="text-content-tertiary">
                    {statusConfig.label}
                  </span>
                </div>
              );
            })
          ) : (
            <div className="flex space-x-1">
              <Icon
                id={
                  COMPONENT_STATUS_CONFIG[ComponentStatusEnum.Operational].icon
                }
                className={
                  COMPONENT_STATUS_CONFIG[ComponentStatusEnum.Operational]
                    .colour
                }
              />
              <p>No impacted components</p>
            </div>
          )}
        </div>
        <SubPageReviewInfo page={page} incident={null} />
      </ContentBox>
      {!page.subscriptions_disabled && page.active_subscriber_count > 0 && (
        <div className="space-y-2 pt-3">
          <CheckboxV2
            label="Notify subscribers"
            name="notify_subscribers"
            formMethods={formMethods}
          />
          {!notifySubscribers ? (
            <Callout theme={CalloutTheme.Info}>
              We won&apos;t notify any of your subscribers. Future updates for
              this maintenance will default to not notifying subscribers too.
            </Callout>
          ) : (
            <Callout theme={CalloutTheme.Info}>
              Scheduling this maintenance will notify your{" "}
              {page.active_subscriber_count.toLocaleString()} subscribers.
            </Callout>
          )}
        </div>
      )}
    </>
  );
};
