import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import { InterpolatedRef } from "@incident-shared/forms/v1/TemplatedText/TemplatedTextEditor";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Txt,
} from "@incident-ui";
import React from "react";
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import { EnrichedScope } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";

import { ParamWithBinding, WorkflowStepListItem } from "../common/types";

type WorkflowStepCardProps = {
  variableScope: EnrichedScope<InterpolatedRef>;
  step: WorkflowStepListItem;
  stepNumber: number;
  // Required to make the card draggable.
  innerRef?: React.LegacyRef<HTMLDivElement>;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  draggableProps: DraggableProvidedDraggableProps | null | undefined;
  isFirstStep?: boolean;
  isLastStep?: boolean;
  onEditStep: () => void;
  onRemoveStep: () => void;
};

// WorkflowStepCard renders a card that represents a step
// in a workflow, it can be used within or outside of a
// looping step.
export const WorkflowStepCard = (
  props: WorkflowStepCardProps,
): React.ReactElement => {
  const {
    step,
    variableScope,
    stepNumber,
    isFirstStep = true,
    isLastStep = true,
    onEditStep,
    onRemoveStep,
    innerRef,
    dragHandleProps,
    draggableProps,
  } = props;

  const paramBindingsToShow: ParamWithBinding[] = step.step.param_bindings
    .map((binding, idx) => {
      const param = step.step.params[idx];
      return { param, binding };
    })
    .filter(({ param }) => {
      return !param.infer_reference;
    });

  return (
    <div
      ref={innerRef}
      {...draggableProps}
      className={tcx(
        "border-[1px] border-stroke bg-white w-full flex flex-col drop-shadow-sm overflow-hidden",
        // There is a several year old bug in Safari, when using both a border-radius
        // and overflow-hidden. That the overflow property isn't applied correcttly.
        // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
        // Applying "transform-gpu" fixes this bug
        "transform-gpu",
        {
          "rounded-2": isFirstStep && isLastStep, // When it is a single step
          "rounded-t-lg": isFirstStep,
          "rounded-b-lg": isLastStep,
          "border-b-0": !isLastStep,
        },
      )}
    >
      {/* Step header section */}
      <div
        className={tcx("flex flex-row items-center bg-surface-secondary p-3")}
      >
        <div className="mr-2 h-[16px]" {...dragHandleProps}>
          <Icon id={IconEnum.Draggable} size={IconSize.Small} />
        </div>
        <div>
          <Txt bold>
            <h3>{step.step.label}</h3>
          </Txt>
        </div>
        <div className="ml-auto flex align-center">
          <Button
            analyticsTrackingId="workflows-step-edit"
            onClick={onEditStep}
            theme={ButtonTheme.Naked}
            icon={IconEnum.Edit}
            iconProps={{
              size: IconSize.Medium,
            }}
            title="Edit"
            className="mr-1"
          />

          <Button
            analyticsTrackingId="workflows-step-delete"
            onClick={onRemoveStep}
            theme={ButtonTheme.Naked}
            icon={IconEnum.Delete}
            // We need some negative margin to make the icon align with the
            // padding of the card.
            className="-mr-[4px]"
            iconProps={{
              size: IconSize.Medium,
            }}
            title="Delete"
          ></Button>
        </div>
      </div>
      {/* Step content section */}
      {paramBindingsToShow.length > 0 && (
        <div className="p-4 text-sm flex flex-col gap-5">
          {paramBindingsToShow.map(({ binding, param }, idx) => {
            return (
              <div
                key={`pb-${stepNumber}-${idx}`}
                className="text-sm flex flex-col gap-1"
              >
                <div className="font-normal text-slate-600">{param?.label}</div>
                <div className="text-content-primary flex min-w-0 overflow-hidden">
                  <EngineBinding
                    binding={binding}
                    variableScope={variableScope}
                    resourceType={param.type}
                    displayExpressionAs="pill"
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
