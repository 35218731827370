import { Callout, CalloutTheme } from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList } from "./helpers";

export const AlertSourceCloudWatchSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Go to the <strong>AWS</strong> console and open <strong>SNS</strong>
      </p>
      <p>
        Choose <strong>Topics</strong> and click <strong>Create topic</strong>
      </p>
      <p>
        Select <strong>Standard</strong> as the queue type and enter a{" "}
        <strong>name</strong>. We&apos;d recommend naming it match this alert
        source, e.g. &quot;{alertSourceConfig.name} incident.io&quot;.
      </p>
      <p>
        Leave all the remaining fields as their defaults and click{" "}
        <strong>Create topic</strong>.
      </p>
      <p>
        Select your newly created topic and click{" "}
        <strong>Create subscription</strong>.
      </p>
      <div>
        <p>Enter the following values:</p>

        <SetupInfoNumberedList>
          <p>
            <strong>Topic ARN</strong>: Leave this as the default, the topic you
            just created.
          </p>
          <p>
            <strong>Protocol</strong>: HTTPS
          </p>
          <p>
            <strong>Endpoint</strong>: Copy the endpoint below
            <SingleLineCodeBlock
              className={"my-2"}
              title={"Endpoint"}
              code={`${publicApiUrl()}/v2/alert_events/cloudwatch/${
                alertSourceConfig.id
              }`}
            />
          </p>
          <p>
            <strong>Raw message delivery</strong>: Leave this unchecked
          </p>
        </SetupInfoNumberedList>
      </div>

      <p>
        Save your subscription and check that the status is{" "}
        <strong>Confirmed</strong>. If it&apos;s not, please refresh the
        subscription page.
      </p>
      <p>
        Now search for <strong>CloudWatch</strong> in the AWS console. Go to{" "}
        <strong>All alarms</strong> and click <strong>Create alarm</strong>.
      </p>
      <p>
        Configure your alarm as you wish, then in its{" "}
        <strong>Notification</strong> settings you must set up both an{" "}
        <strong>ALARM</strong> and <strong>OK</strong> notification.
        <Callout theme={CalloutTheme.Info} className={"my-2"}>
          You must add both notifications below, or we&apos;ll only ever receive
          your first alert.
        </Callout>
        <SetupInfoNumberedList>
          <p>
            For the first notification, choose <strong>In alarm</strong> and
            then select the SNS topic you created above.
          </p>
          <p>
            For the second notification, choose <strong>OK</strong> and again
            select the SNS topic you created above.
          </p>
        </SetupInfoNumberedList>
      </p>
      <p>
        Click <strong>Create alarm</strong> to save your changes.
      </p>
      <p>
        To receive a test alert, you can manually trigger a test alarm using the
        AWS CLI. To stop firing the alarm, use the same command but replace{" "}
        <strong>ALARM</strong> with <strong>OK</strong>.
        <CodeBlock
          title={"AWS CLI"}
          code={`aws cloudwatch set-alarm-state --alarm-name <alarm-name> --state-value ALARM --state-reason "testing incident.io alerts"`}
        />
      </p>
    </SetupInfoNumberedList>
  );
};
