import {
  AlertRoute,
  CustomField,
  EngineParamBindingValuePayload,
  EngineScope,
  EscalationPath,
  Resource,
  ScopeNameEnum,
} from "@incident-io/api";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { FormModalV2, FormV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { HeaderBarTitle } from "@incident-shared/layout/HeaderBar/HeaderBar";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  ModalFooter,
  ToastTheme,
} from "@incident-ui";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { EscalationPathCreateEditDrawer } from "src/components/escalation-paths/create-edit/EscalationPathCreateEditDrawer";
import { useAllResources } from "src/hooks/useResources";
import { useQueryParams } from "src/utils/query-params";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useAlertResources } from "../common/useAlertResources";
import { AlertRouteSteppedSections } from "./AlertRouteFormSection";
import { AlertRouteChooseSourcesSection } from "./ChooseSourcesSection";
import { AlertRouteCreateIncidentSection } from "./CreateIncidentSection";
import { AlertRouteEscalateSection } from "./EscalateSection";
import { AlertRouteFilterSection } from "./FilterSection";
import {
  AlertRouteCreationSource,
  AlertRouteFormData,
  AlertRouteTitleFormData,
  getDefaultAlertRouteValues,
  parseAlertRoute,
  parseFormData,
} from "./types";

export const AlertRouteCreateEditForm = ({
  source,
  mode,
  initialData,
}: {
  source?: AlertRouteCreationSource;
} & CreateEditFormProps<AlertRoute>) => {
  const {
    data: { scope },
    isLoading: scopeLoading,
    error: scopeError,
  } = useAPI("alertRoutesBuildScope", undefined, {
    fallbackData: { scope: { references: [], aliases: {} } },
  });

  const {
    data: { custom_fields: customFields },
    isLoading: customFieldsLoading,
    error: customFieldsError,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });

  // Check if we have any routes already created
  const {
    data: alertRoutesData,
    isLoading: alertRoutesLoading,
    error: alertRoutesError,
  } = useAPI("alertRoutesListAlertRoutes", undefined, {
    fallbackData: {
      alert_routes: [],
    },
  });

  const { isLoading: alertResourcesLoading } = useAlertResources();
  const { resources, resourcesLoading, resourcesError } = useAllResources();

  const error =
    scopeError || resourcesError || customFieldsError || alertRoutesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (
    scopeLoading ||
    resourcesLoading ||
    alertResourcesLoading ||
    customFieldsLoading ||
    alertRoutesLoading
  ) {
    return <Loader />;
  }

  const modeProps = {
    mode,
    initialData,
  } as CreateEditFormProps<AlertRoute>;

  return (
    <AlertRouteCreateEditFormInner
      {...modeProps}
      source={source}
      alertRoutes={alertRoutesData?.alert_routes || []}
      scope={scope}
      resources={resources}
      customFields={customFields}
    />
  );
};

const AlertRouteCreateEditFormInner = ({
  mode,
  initialData,
  source,
  scope,
  resources,
  customFields,
  alertRoutes,
}: {
  source?: AlertRouteCreationSource;
  scope: EngineScope;
  resources: Resource[];
  customFields: CustomField[];
  alertRoutes: AlertRoute[];
} & CreateEditFormProps<AlertRoute>): React.ReactElement => {
  const queryParams = useQueryParams();
  const initialSourceID = queryParams.get("initial_source_id");

  const showToast = useToast();

  const navigate = useOrgAwareNavigate();
  const onClose = () => {
    navigate("/alerts/routes");
  };

  const formMethods = useForm<AlertRouteFormData>({
    defaultValues: initialData
      ? parseAlertRoute(initialData, initialSourceID)
      : getDefaultAlertRouteValues(
          scope,
          customFields,
          initialSourceID,
          alertRoutes,
          source,
        ),
  });

  const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  const name = formMethods.getValues("name");

  // We need to update the "show" api response so that
  // the next time we open the edit form it initialises
  // with our changes
  const { mutate: mutateSchema } = useAPI(
    initialData ? "alertRoutesShowAlertRoute" : null,
    {
      id: initialData?.id || "",
    },
  );

  const createMutation = useAPIMutation(
    "alertRoutesListAlertRoutes",
    undefined,
    async (apiClient, formData: AlertRouteFormData) => {
      const result = await apiClient.alertRoutesCreateAlertRoute({
        createAlertRouteRequestBody: parseFormData(formData, customFields),
      });

      await mutateSchema(result);

      // Manually reset the form so its no longer dirty
      formMethods.reset(parseAlertRoute(result.alert_route, null));
    },
    {
      onSuccess: async () => {
        onClose();
        showToast({
          theme: ToastTheme.Success,
          title: "Alert route created",
          toastSide: ToastSideEnum.TopRight,
        });
      },
      onError: async () => {
        showToast({
          theme: ToastTheme.Error,
          title: `Could not create alert route`,
          toastSide: ToastSideEnum.TopRight,
        });
      },
      setError: formMethods.setError,
    },
  );

  const editMutation = useAPIMutation(
    "alertRoutesListAlertRoutes",
    undefined,
    async (apiClient, formData: AlertRouteFormData) => {
      if (!initialData) {
        return;
      }

      const result = await apiClient.alertRoutesUpdateAlertRoute({
        // this mutation only ever gets called when this is defined, but TS doesn't know that
        id: initialData?.id as string,
        updateAlertRouteRequestBody: parseFormData(
          {
            ...parseAlertRoute(initialData, initialData.id),
            ...formData,
          },
          customFields,
        ),
      });
      await mutateSchema(result);
      // Manually reset the form so its no longer dirty
      formMethods.reset(parseAlertRoute(result.alert_route, null));
    },
    {
      onSuccess: () => {
        onClose();
        showToast({
          theme: ToastTheme.Success,
          title: "Alert route updated",
          toastSide: ToastSideEnum.TopRight,
        });
      },
      onError: async () => {
        showToast({
          theme: ToastTheme.Error,
          title: `Could not save alert route`,
          toastSide: ToastSideEnum.TopRight,
        });
      },
      setError: formMethods.setError,
    },
  );
  const { trigger: onSubmit, isMutating: saving } =
    mode === Mode.Edit ? editMutation : createMutation;

  const [showEscalationPathDrawer, setShowEscalationPathDrawer] =
    useState(false);
  const [escalationBindingKey, setEscalationBindingKey] = useState(
    "escalationBinding-0",
  );
  const incrementEscalationBindingKey = () => {
    setEscalationBindingKey(
      `escalationBinding-${Number(escalationBindingKey.split("-")[1]) + 1}`,
    );
  };
  const handleNewEscalationPath = (newPath: EscalationPath) => {
    // First, we want to update the form state to push the new escalation path in to the
    // list of selected paths
    const currentBindings = formMethods.getValues("escalationBinding");
    const newBinding = escalationPathToParamBinding(newPath);
    const newArrayValue = [...(currentBindings?.array_value || []), newBinding];
    formMethods.setValue<"escalationBinding">("escalationBinding", {
      array_value: newArrayValue,
    });
    // Then, we want to force the multi-select to rerender so it fetches the new
    // typeahead options
    incrementEscalationBindingKey();
  };

  const expressionMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionMethods}
      allowAllOfACatalogType={false}
    >
      <FormV2
        formMethods={formMethods}
        warnWhenDirty
        onSubmit={onSubmit}
        fullHeight
        saving={saving}
      >
        {showEditTitleModal ? (
          <EditTitleModal
            name={name}
            setName={(name: string) =>
              formMethods.setValue<"name">("name", name)
            }
            onClose={() => setShowEditTitleModal(false)}
          />
        ) : null}
        <PageWrapper
          dottedBackground
          className="bg-slate-50 h-full"
          width={PageWidth.Full}
          icon={IconEnum.AlertRoute}
          title="Edit alert route"
          backHref="/alerts/routes"
          titleNode={
            <HeaderBarTitle
              crumbs={[
                {
                  title: "Alerts",
                  to: "/alerts",
                },
                { title: "Routes", to: "/alerts/routes" },
              ]}
              title="Edit workflow"
              titleNode={<div>{name}</div>}
              isEditable
              onEditTitle={() => {
                setShowEditTitleModal(true);
              }}
            />
          }
          accessory={
            <div className="flex flex-row items-center gap-4">
              <ToggleV2
                formMethods={formMethods}
                name="enabled"
                labelAccessory={<div>veep </div>}
                label={"Active"}
                description="A deactivated alert route will not create incidents or escalations from alerts."
                descriptionInTooltip
              />
              <GatedButton
                theme={ButtonTheme.Primary}
                analyticsTrackingId="alert-route-edit-save"
                requiredScope={ScopeNameEnum.AlertSourceUpdate}
                type="submit"
                loading={saving}
                disabled={!formMethods.formState.isDirty || saving}
              >
                Save changes
              </GatedButton>
            </div>
          }
          overflowX
        >
          <AnimatePresence>
            {showEscalationPathDrawer && (
              <EscalationPathCreateEditDrawer
                onSuccess={handleNewEscalationPath}
                onClose={() => setShowEscalationPathDrawer(false)}
              />
            )}
          </AnimatePresence>
          <AlertRouteSteppedSections
            sections={[
              <AlertRouteChooseSourcesSection
                scope={scope}
                key="choose-sources"
              />,
              <AlertRouteFilterSection key="filter" scope={scope} />,
              <AlertRouteEscalateSection
                key="escalate"
                escalationBindingKey={escalationBindingKey}
                resources={resources}
                scope={scope}
              />,
              <AlertRouteCreateIncidentSection
                key="create-incident"
                resources={resources}
                scope={scope}
              />,
            ]}
          />
        </PageWrapper>
      </FormV2>
    </ExpressionsMethodsProvider>
  );
};

const EditTitleModal = ({
  name,
  setName,
  onClose,
}: {
  name: string;
  setName: (name: string) => void;
  onClose: () => void;
}) => {
  const formMethods = useForm<AlertRouteTitleFormData>({
    defaultValues: { name },
  });

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={(data) => {
        setName(data.name);
        onClose();
      }}
      onClose={onClose}
      analyticsTrackingId="alert-routes-rename"
      title="Edit name"
      footer={
        <ModalFooter
          confirmButtonText="Confirm"
          confirmButtonType="submit"
          onClose={onClose}
          requiredScope={ScopeNameEnum.AlertRouteUpdate}
        />
      }
    >
      <InputV2
        required
        autoFocus
        name="name"
        formMethods={formMethods}
        label="Enter a new name"
      />
    </FormModalV2>
  );
};

export const escalationPathToParamBinding = (
  path: EscalationPath,
): EngineParamBindingValuePayload => ({
  // @ts-expect-error this is fine, it's just there for the multiselect
  label: path.name,
  literal: path.id,
  value: path.id,
  sort_key: path.name,
});
