import { Mode } from "@incident-shared/forms/v2/formsv2";
import { HeaderBarTitle } from "@incident-shared/layout/HeaderBar/HeaderBar";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { GenericErrorMessage, IconEnum, Loader } from "@incident-ui";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAPI } from "src/utils/swr";

import { WorkflowsCreateEditForm } from "../create-edit-form/WorkflowsCreateEditForm";

export const WorkflowsEditPage = () => {
  const { id } = useParams() as { id: string };

  const {
    data: workflowData,
    isLoading: workflowIsLoading,
    error: workflowError,
  } = useAPI("workflowsShowWorkflow", {
    id: id,
  });
  const workflow = workflowData?.workflow;

  // We are using a portal to render an input in the page header
  // We need to wait til the page has loaded before trying to portal
  // to the input
  const titleInputRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);
  const [domReady, setDomReady] = useState(false);
  useEffect(() => {
    if (workflow?.id) {
      setDomReady(true);
    }
  }, [workflow?.id]);

  if (workflowError) {
    return <GenericErrorMessage error={workflowError} />;
  }

  if (workflowIsLoading || !workflowData) {
    return <Loader />;
  }

  return (
    <PageWrapper
      width={PageWidth.Full}
      icon={IconEnum.Workflows}
      title="Edit workflow"
      backHref="/workflows"
      titleNode={
        <HeaderBarTitle
          crumbs={[
            {
              title: "Workflows",
              to: "/workflows",
            },
          ]}
          title="Edit workflow"
          titleNode={
            <div
              className="flex flex-row items-baseline grow"
              ref={titleInputRef}
            />
          }
          isEditable
        />
      }
      overflowX
      accessory={
        <div className="flex flex-row flex-center-y space-x-2">
          <div ref={buttonsRef} />
        </div>
      }
    >
      <WorkflowsCreateEditForm
        mode={Mode.Edit}
        workflow={workflow}
        management={workflowData.management_meta}
        titleInputRef={titleInputRef}
        buttonsRef={buttonsRef}
        domReady={domReady}
      />
    </PageWrapper>
  );
};
