import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { ModalFooter } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { useAllStatuses } from "src/components/legacy/incident/useIncidentCrudResources";
import {
  IncidentTimestampsCreateRequestBody,
  IncidentTimestampSetByRulePayload,
  IncidentTimestampTimestampTypeEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import {
  marshallRequestPayloadSetByConfig,
  SetByEnum,
  TimestampSetSection,
} from "../TimestampSetSection";

type FormType = IncidentTimestampsCreateRequestBody & {
  set_by_mode: SetByEnum;
  // Typescript can't deal with the self-referencing type for templated text
  description: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const IncidentTimestampCreateModal = ({
  onClose,
  rulePrefill,
}: {
  onClose: () => void;
  // targetStatusId is set when you're adding a timestamp contextually from the lifecycle preview. We use it to populate some sensible defaults for you
  rulePrefill?: IncidentTimestampSetByRulePayload | null;
}): React.ReactElement | null => {
  let defaultValues: Partial<FormType> = {};

  if (rulePrefill) {
    defaultValues = {
      ...defaultValues,
      set_by_mode: SetByEnum.FromTransition,
      set_by_rules: [rulePrefill],
    };
  }

  const formMethods = useForm<FormType>({
    defaultValues,
  });

  const { allStatuses, allStatusesLoading } = useAllStatuses();

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    "incidentTimestampsList",
    undefined,
    async (apiClient, data: FormType) => {
      await apiClient.incidentTimestampsCreate({
        createRequestBody: marshallRequestPayloadSetByConfig(data),
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  return (
    <FormModalV2<FormType>
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      onClose={onClose}
      loading={allStatusesLoading}
      analyticsTrackingId={"create-incident-timestamp"}
      title="Create new timestamp"
      disableQuickClose
      // Only make it large if we're showing all the set by rule gumph
      isExtraLarge={rulePrefill == null}
      footer={
        <ModalFooter
          saving={saving}
          confirmButtonText="Create"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        required="Please provide a name"
        placeholder="e.g. Fixed at"
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="description"
        label="Description"
        placeholder="e.g. When we implemented the fix for this incident."
        helptext="This will be displayed to help a user understand what this timestamp means."
        format="mrkdwn"
        required="Please provide a description"
        includeVariables={false}
        includeExpressions={false}
      />
      {/* Don't show the 'set when' configuration if we've prefilled a rule, let's keep it nice and simple! */}
      {!rulePrefill && (
        <TimestampSetSection
          statuses={allStatuses}
          timestampType={IncidentTimestampTimestampTypeEnum.Custom}
        />
      )}
    </FormModalV2>
  );
};
