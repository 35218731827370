import { SavedViewsListContextEnum } from "@incident-io/api";
import { useUserBacklinks } from "@incident-shared/catalog/useUserBacklinks";
import { useFiltersContext } from "@incident-shared/filters";
import {
  GenericErrorMessage,
  Icon,
  IconEnum,
  Loader,
  StaticSingleSelect,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { tcx } from "src/utils/tailwind-classes";

import { InsightsDashboardSection } from "../../common/InsightsDashboardSection";
import { InsightsPage } from "../../common/InsightsPage";
import { InsightsContextProvider } from "../../context/InsightsContextProvider";
import { StateConfig } from "../../context/types";
import { useInsightsContext } from "../../context/useInsightsContext";
import { ExploDashboardID } from "../dashboards";
import { NoTeamsEmptyState } from "./NoTeamsEmptyState";

export interface TeamsDashboardStateConfig extends StateConfig {
  split_by: {
    options: SelectOption[];
  };
}

export const TeamsDashboard = () => {
  const {
    data: backlinks,
    isLoading,
    error,
  } = useUserBacklinks({ isInsightsOverride: true });

  if (isLoading || !backlinks) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const splitByOptions: SelectOption[] = [];
  backlinks.forEach((backlink) => {
    splitByOptions.push({
      value: `user_backlink:${backlink.user_attribute_id}`,
      label: `User → ${backlink.backlink_name}`,
    });
  });
  splitByOptions.push({ value: "user", label: "User" });

  return (
    <InsightsContextProvider<TeamsDashboardStateConfig>
      context={SavedViewsListContextEnum.InsightsV2}
      stateConfig={{ split_by: { options: splitByOptions } }}
      filtersKind="user"
    >
      <TeamsDashboardInner splitByOptions={splitByOptions} />
    </InsightsContextProvider>
  );
};

const TeamsDashboardInner = ({
  splitByOptions,
}: {
  splitByOptions: SelectOption[];
}) => {
  const { useState } = useInsightsContext<TeamsDashboardStateConfig>();
  const { filters } = useFiltersContext();

  const [selectedSplitBy, setSelectedSplitBy] = useState("split_by");

  const disableFilters = filters.length > 0;
  const disableFiltersTooltipContent =
    "Please remove your current filter to select a new one";

  if (splitByOptions.length === 1 && filters.length === 0) {
    return (
      <InsightsPage dashboard="teams" overflowY={false}>
        <NoTeamsEmptyState />
      </InsightsPage>
    );
  }

  const dashboardVariables = () => {
    if (selectedSplitBy === "user") {
      return { split_by_type: selectedSplitBy };
    } else {
      const [type, name] = selectedSplitBy.split(":");
      return {
        split_by_type: type,
        split_by_attribute_id: name,
      };
    }
  };

  return (
    <InsightsPage
      dashboard="teams"
      disableFilters={disableFilters}
      disableFiltersTooltipContent={disableFiltersTooltipContent}
    >
      <InsightsDashboardSection
        dashboardEmbedID={ExploDashboardID.TeamsResponseDashboard}
        title="Time spent on incidents"
        description="Understand the time spent on incidents across your company, split by user or team. If a user is in multiple teams their activity will count for each team."
        initialHeight="478px"
        dashboardVariables={dashboardVariables()}
        controls={
          <>
            <SplitByDropdown
              selectedSplitBy={selectedSplitBy}
              setSelectedSplitBy={setSelectedSplitBy}
              options={splitByOptions}
            />
          </>
        }
      />
      <InsightsDashboardSection
        dashboardEmbedID={ExploDashboardID.TeamsPINCDashboard}
        title="Post-incident"
        description="Understand how many post-incident tasks are handled by teams and users, and how long they take. If a user is in multiple teams their activity will count for each team."
        initialHeight="478px"
        dashboardVariables={dashboardVariables()}
        controls={
          <>
            <SplitByDropdown
              selectedSplitBy={selectedSplitBy}
              setSelectedSplitBy={setSelectedSplitBy}
              options={splitByOptions}
            />
          </>
        }
      />
    </InsightsPage>
  );
};

const SplitByDropdown = ({
  selectedSplitBy,
  setSelectedSplitBy,
  options,
}: {
  selectedSplitBy: string;
  options: SelectOption[];
  setSelectedSplitBy: (newSplitBy: string) => void;
}): React.ReactElement => {
  return (
    <div className="flex items-center">
      <span className="text-sm text-slate-700 mr-2 flex-shrink-0">
        Split by
      </span>
      <StaticSingleSelect
        options={options.map((field) => ({
          ...field,
          renderFn: () => {
            return (
              <div className={tcx("flex text-sm mt-0.5 items-center")}>
                <Icon
                  id={field.value === "user" ? IconEnum.User : IconEnum.Users}
                  className="shrink-0"
                />
                <span className="ml-1 mr-2 flex-wrap">{field.label}</span>
              </div>
            );
          },
        }))}
        className="!text-slate-700 min-w-[200px]"
        value={selectedSplitBy}
        onChange={(value) => value && setSelectedSplitBy(value)}
        isClearable={false}
      />
    </div>
  );
};
