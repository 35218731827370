import { Product } from "@incident-shared/billing";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  AvailableInsights,
  useAvailablePagerLoadInsights,
} from "../../../hooks/useAvailablePagerLoadInsights";

export enum ExploDashboardID {
  OnCallPagerImpact = "QRA3mnoxEd", // Deprecated in favour of the below
  OnCallPagerImpactNative = "aBAMpMm1kv",
  OnCallPagerImpactExternal = "bo1qJD2Azw",
  OnCallSchedules = "2k1L0NJ1yJ",
  OnCallByUser = "bo1qpElxzw",
  OverviewSplitByCustomField = "ovARNaXAlV",
  OverviewIncidentCreation = "jDxeVEe1LG",
  OverviewIncidentCreationBySource = "am1D7GyxwO",
  OverviewIncidentCreationByUser = "b81Jvl51ag",
  PincFollowUpsForUser = "7Jx6rz0xwe",
  PincFollowUpsPerUser = "eVx8ezn1pn",
  PincPostIncidentTasks = "dK1odXeAl0",
  PincFollowUpsByCustomField = "b81JkB8Aag",
  PincFollowUpsCreated = "QgYwPXexvR",
  PincPostIncidentFlows = "2WYnbX7YZX",
  ResponseMTTXDurationMetrics = "aQ1yKkKY8o",
  ResponseMTTXDurationMetricByCustomField = "QRA3BJQ1Ed",
  ResponseMTTXDurationMetricBreakdown = "b910rMKYWQ",
  ResponseTimeSpentInIncidents = "d2AQNjLYb5",
  ResponseWorkloadSplitByCustomField = "d6x5PvWYoR",
  CustomDashboard = "9PY4zE61M4",
  StatusPageViewerCount = "b910rN8YWQ",
  TeamsResponseDashboard = "XyAZm3B1dl",
  TeamsPINCDashboard = "2k1LPoo1yJ",
  IncidentPulseGraphDashboard = "ovARW3VYlV",
  IncidentWorkloadPerUserDashboard = "2k1LPJJ1yJ",
}

export type Dashboard =
  | "overview"
  | "teams"
  | "time-spent-on-incidents"
  | "mttx"
  | "schedules"
  | "pager-load"
  | "follow-ups"
  | "post-incident-flow"
  | "external-pager-load";

export type InsightsListRow = {
  id: Dashboard;
  name: string;
  description: string;
  slug: string;
  icon?: { icon: IconEnum; iconColour: string; bgColour: string };
  disableFilters?: boolean;
};

type DashboardGroup = {
  title: string;
  icon: IconEnum;
  homepageIconColour: string;
  dashboardIconColour: ColorPaletteEnum;
  requiredProduct?: Product;
  dashboards: {
    name: string;
    to: string;
    description: string;
    featureFlag?: string;
    disableFilters?: boolean;
  }[];
};

function onCallDashboards(
  availablePagingInsights: AvailableInsights,
): DashboardGroup["dashboards"] {
  if (availablePagingInsights.native && availablePagingInsights.external) {
    return [
      {
        name: "Pager load (incident.io)",
        to: "pager-load",
        description:
          "Understand how often people are being paged, and at what times of day",
      },
      {
        name: `Pager load (${availablePagingInsights.externalProvider})`,
        to: "external-pager-load",
        description:
          "Understand how often people are being paged, and at what times of day",
        disableFilters: true,
      },
    ];
  } else if (availablePagingInsights.external) {
    return [
      {
        name: `Pager impact`,
        to: "external-pager-load",
        description:
          "Understand how often people are being paged, and at what times of day",
        disableFilters: true,
      },
    ];
  } else {
    // either both false (really weird) or native is true
    return [
      {
        name: "Pager impact",
        to: "pager-load",
        description:
          "Understand how often people are being paged, and at what times of day",
      },
    ];
  }
}

export const useDashboardGroups = () => {
  const flags = useFlags() || {};
  const availablePagingInsights = useAvailablePagerLoadInsights();

  return DashboardGroups.map((group) => {
    if (group.title === "On-call") {
      if (!flags.featureNativeOnCallInsights) {
        group.dashboards = [
          {
            name: "Pager impact",
            to: "pager-load",
            description:
              "Understand how often people are being paged, and at what times of day",
            disableFilters: true,
          },
        ];
      } else {
        group.dashboards = onCallDashboards(availablePagingInsights);
      }
    }

    return {
      ...group,
      dashboards: group.dashboards.filter(
        (d) => !d.featureFlag || flags[d.featureFlag],
      ),
    };
  });
};

const DashboardGroups: DashboardGroup[] = [
  {
    title: "Overview",
    icon: IconEnum.Chart,
    homepageIconColour: "bg-blue-400",
    dashboardIconColour: ColorPaletteEnum.Blue,
    dashboards: [
      {
        name: "At a glance",
        to: "overview",
        description:
          "Explore the total number of incidents by severity, and more",
      },
      {
        name: "Teams",
        to: "teams",
        description: "Explore the total number of incidents split by teams",
      },
    ],
  },
  {
    title: "Response",
    icon: IconEnum.Incident,
    homepageIconColour: "bg-alarmalade-600",
    dashboardIconColour: ColorPaletteEnum.Red,
    requiredProduct: Product.Response,
    dashboards: [
      {
        name: "Time spent on incidents",
        to: "time-spent-on-incidents",
        description:
          "Understand the time spent on incidents across your company",
      },
      {
        name: "MTTX",
        to: "mttx",
        description:
          "Compare your incident duration metrics, measured between chosen timestamps",
      },
    ],
  },
  {
    title: "On-call",
    icon: IconEnum.OnCall,
    homepageIconColour: "bg-teal-600",
    dashboardIconColour: ColorPaletteEnum.Teal,
    dashboards: [],
  },
  {
    title: "Post-incident",
    icon: IconEnum.Bulb,
    homepageIconColour: "bg-pink-500",
    dashboardIconColour: ColorPaletteEnum.Pink,
    requiredProduct: Product.Response,
    dashboards: [
      {
        name: "Follow-ups",
        to: "follow-ups",
        description:
          "See how many follow-ups you have, who they're assigned to and more",
      },
      {
        name: "Post-Incident flow",
        to: "post-incident-flow",
        description:
          "Learn more about the incidents in your post-incident flow",
      },
    ],
  },
];

export const useDashboardMap = () => {
  const flags = useFlags() || {};

  return DashboardGroups.reduce(
    (acc, group) =>
      group.dashboards.reduce((acc, dashboard) => {
        if (dashboard.featureFlag && !flags[dashboard.featureFlag]) {
          return { ...acc };
        }

        return {
          ...acc,
          [dashboard.to]: { ...dashboard, group: group.title },
        };
      }, acc),
    {} as Record<
      Dashboard,
      {
        name: string;
        to: string;
        description: string;
        group: string;
        disableFilters?: boolean;
        requiredProduct?: Product;
      }
    >,
  );
};
