import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { Steps } from "@incident-ui";
import { captureException } from "@sentry/react";
import { Navigate } from "react-router";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";

import { SetupInstallMsTeams } from "./msteams/SetupInstallMsTeams";
import { SetupChoosePlan } from "./SetupChoosePlan";
import { SetupInstallSlack } from "./slack/SetupInstallSlack";
import { InstallationStepID, useCurrentStep } from "./useSetupSteps";

export const SetupPage = ({
  stepFromPath,
  commsPlatformFromPath,
}: {
  stepFromPath: InstallationStepID | null;
  commsPlatformFromPath: CommsPlatform | null;
}): React.ReactElement => {
  const { currentStep, steps } = useCurrentStep();

  const primaryCommsPlatform = usePrimaryCommsPlatform();
  if (!primaryCommsPlatform) {
    captureException(
      new Error("No primary comms platform found from identity"),
    );
    return <Navigate to="/setup/login" replace />;
  }

  const { path: correctPath, Component } =
    STEP_ID_TO_CONFIG[primaryCommsPlatform][currentStep];

  if (
    // If these are null, someone's navigated to just `/setup` and needs sending
    // to the right place
    !stepFromPath ||
    !commsPlatformFromPath ||
    // If they don't match, force the user into the right step
    primaryCommsPlatform !== commsPlatformFromPath ||
    stepFromPath !== currentStep ||
    // If we can't render this step, always redirect.
    !Component
  ) {
    return <OrgAwareNavigate to={correctPath} replace />;
  }

  return (
    <PageWrapper title="" noHeader width={PageWidth.Narrow}>
      <div className="h-auto xl:h-full flex flex-col items-center justify-center gap-8 py-16">
        <Steps
          steps={steps}
          currentStep={currentStep}
          containerClassName="max-w-xl w-full mb-8"
        />
        <Component />
      </div>
    </PageWrapper>
  );
};

type StepRenderConfig = {
  path: string;
  Component?: () => React.ReactElement;
};

const STEP_ID_TO_CONFIG: Record<
  CommsPlatform,
  Record<InstallationStepID, StepRenderConfig>
> = {
  [CommsPlatform.Slack]: {
    [InstallationStepID.Login]: {
      path: "/setup/login",
      // The component for this is rendered differently to the others, since it
      // sits outside of our 'authenticated routes' wrapper.
    },
    [InstallationStepID.ChoosePlan]: {
      path: "/setup/choose-plan",
      Component: SetupChoosePlan,
    },
    [InstallationStepID.Install]: {
      path: "/setup/install",
      Component: SetupInstallSlack,
    },
    [InstallationStepID.Dashboard]: {
      path: "/dashboard",
    },
  },
  [CommsPlatform.MSTeams]: {
    [InstallationStepID.Login]: {
      path: "/setup-msteams/login",
      // The component for this is rendered differently to the others, since it
      // sits outside of our 'authenticated routes' wrapper.
    },
    [InstallationStepID.ChoosePlan]: {
      path: "/setup/msteams/choose-plan",
      Component: SetupChoosePlan,
    },
    [InstallationStepID.Install]: {
      path: "/setup/msteams/install",
      Component: SetupInstallMsTeams,
    },
    [InstallationStepID.Dashboard]: {
      path: "/dashboard",
    },
  },
};
