import {
  FiltersContextProvider,
  useInitialiseFilters,
} from "@incident-shared/filters";
import { ReactElement } from "react";
import { IncidentFilterFieldsListContextEnum } from "src/contexts/ClientContext";

import { useQueryParams } from "../../../utils/query-params";
import { useStatefulQueryParamFilters } from "../../@shared/filters/useStatefulQueryParamFilters";
import { FollowUpParam, FollowUpParams } from "../follow-ups/FollowUpsPage";

export const PostIncidentFiltersProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const urlParams = useQueryParams();

  const { availableIncidentFilterFields: availableFilterFields } =
    useInitialiseFilters(
      urlParams.toString(),
      IncidentFilterFieldsListContextEnum.PostIncidentTasksAndIncidents,
    );

  const { getSelectedFilters, setSelectedFilters } =
    useStatefulQueryParamFilters<FollowUpParams>({
      availableFilterFields,
      availableParams: Object.values(FollowUpParam),
    });

  const filters = getSelectedFilters();

  return (
    <FiltersContextProvider
      filters={filters}
      setFilters={setSelectedFilters}
      availableFilterFields={availableFilterFields}
      kind={"incident"}
    >
      {children}
    </FiltersContextProvider>
  );
};
