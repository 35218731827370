import React from "react";
import { getCurrencySymbol } from "src/utils/currency";
import { tcx } from "src/utils/tailwind-classes";

import { Input, InputProps, InputType } from "./Input";

export const currencyStrToCents = (str: string): number =>
  Math.round(parseFloat(str) * 100);

export const currencyCentsToStr = (cents: number): string =>
  (Math.round((cents / 100 + Number.EPSILON) * 100) / 100).toFixed(2);

export const currencyValidationRules = {
  positive: (str: string): string | true =>
    currencyStrToCents(str) < 0 ? "Please choose a positive number" : true,
  twoDecimalPlaces: (str: string): string | true => {
    const splitArr = str.split(".");
    if (splitArr[1]?.length > 2) {
      return "Amount must be rounded to 2 decimal places";
    }
    return true;
  },
};

type CurrencyInputProps = {
  currency: string;
} & InputProps;

// CurrencyInput allows us to nicely show you how to input
// a currency amount (in major units). The value is deliberately
// stored as a string to avoid floating point hell. You should
// convert this to a number as you marshall to the API.
export const CurrencyInput = React.forwardRef<
  HTMLInputElement,
  CurrencyInputProps
>(
  (
    { currency, className, ...props }: CurrencyInputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <Input
        {...props}
        type={InputType.Number}
        step=".01"
        placeholder="0.00"
        inputPrefixNode={
          <span className="flex-center-y">{getCurrencySymbol(currency)}</span>
        }
        className={tcx("bg-white", className)}
        ref={ref}
      />
    );
  },
);

CurrencyInput.displayName = "CurrencyInput";
