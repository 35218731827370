import {
  ConditionBadge,
  ConditionBadgeProps,
} from "@incident-shared/engine/conditions/ConditionBadge/ConditionBadge";
import {
  ExpressionsMethodsProvider,
  FormDataWithExpressions,
  useExpressionsMethods,
} from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Loader } from "@incident-ui";
import { kebabCase } from "lodash";
import React, { ForwardedRef } from "react";
import { Condition, EngineScope, Resource } from "src/contexts/ClientContext";

import { isExpression } from "../referenceSource";
import { ConditionValue } from "./ConditionValue";
import { EditConditionPopover } from "./EditConditionPopover";

export type SelectedConditionProps = SelectedConditionInnerProps &
  (
    | {
        scope?: EngineScope;
        resources?: Resource[];
        handleEdit?: (newCondition: Condition) => void;
        scopeLoading?: boolean;
        allowExpressions?: boolean;
      }
    | {
        scope?: never;
        resources?: never;
        handleEdit?: never;
        scopeLoading?: never;
        allowExpressions?: never;
      }
  );

export const SelectedCondition = React.forwardRef(
  (
    {
      condition,
      scope,
      resources,
      handleEdit,
      scopeLoading,
      allowExpressions,
      ...rest
    }: SelectedConditionProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): React.ReactElement => {
    // Overwrite the parent methods provider to set the display to mini
    const {
      methods: expressionsMethods,
      allowAllOfACatalogType,
      showExpressionNames,
    } = useExpressionsMethods<FormDataWithExpressions, "expressions">();

    if (!handleEdit) {
      return (
        <SelectedConditionInner condition={condition} ref={ref} {...rest} />
      );
    }

    if (!scope || !resources) {
      return <Loader />;
    }

    return (
      <div ref={ref} className="truncate">
        <ExpressionsMethodsProvider
          expressionsMethods={expressionsMethods}
          allowAllOfACatalogType={!!allowAllOfACatalogType}
          showExpressionNames={showExpressionNames}
          displayMini
        >
          <EditConditionPopover
            scopeLoading={scopeLoading || false}
            scope={scope}
            resources={resources}
            handleEdit={handleEdit}
            condition={condition}
            allowExpressions={allowExpressions}
            RenderTriggerButton={React.forwardRef<
              HTMLLIElement,
              { onClick: () => void }
            >(function ConditionBadgeListElement(
              { onClick }: { onClick: () => void },
              ref,
            ) {
              return (
                <li
                  className="inline-block w-full"
                  data-testid={dataTestId(condition)}
                  onClick={onClick}
                  ref={ref}
                >
                  <SelectedConditionInner
                    condition={condition}
                    isTrigger
                    {...rest}
                  />
                </li>
              );
            })}
          />
        </ExpressionsMethodsProvider>
      </div>
    );
  },
);

SelectedCondition.displayName = "SelectedCondition";

type SelectedConditionInnerProps = Omit<
  ConditionBadgeProps,
  "icon" | "subject" | "operation" | "criteria"
> & {
  condition: Condition;
  hideIcon?: boolean;
};

const SelectedConditionInner = React.forwardRef(
  (
    {
      condition,
      onDelete,
      className,
      inline,
      mini,
      isTrigger,
      noTooltip,
      hideIcon,
      theme,
      color: colorOverride,
    }: SelectedConditionInnerProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): React.ReactElement => {
    const { subject, operation } = condition;
    let icon = condition.subject.icon as unknown as IconEnum;
    let color = condition.subject.color as unknown as ColorPaletteEnum;

    if (isExpression(subject?.reference)) {
      icon = IconEnum.Expression;
      color = ColorPaletteEnum.Yellow;
    }

    return (
      <ConditionBadge
        icon={hideIcon ? undefined : icon}
        color={colorOverride || color}
        subject={subject.label}
        operation={operation.label}
        criteria={
          condition.param_bindings == null ||
          condition.param_bindings.length === 0 ? undefined : (
            <ConditionValue condition={condition} />
          )
        }
        onDelete={onDelete}
        className={className}
        inline={inline}
        mini={mini}
        noTooltip={noTooltip}
        isTrigger={isTrigger}
        theme={theme}
        ref={ref}
      />
    );
  },
);

SelectedConditionInner.displayName = "SelectedConditionInner";

const dataTestId = (condition: Condition): string => {
  return `${kebabCase(condition.subject?.label || "")}-${
    condition.operation.value
  }-${kebabCase(conditionKey(condition))}`;
};

const conditionKey = (condition: Condition): string => {
  if (condition.param_bindings.length > 0) {
    const binding = condition.param_bindings[0];
    if (binding.array_value) {
      return binding.array_value.map((o) => o.label || o.literal).join(", ");
    } else if (binding.value) {
      return binding.value.label || binding.value.literal || "";
    }
  }
  return "";
};
