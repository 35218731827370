import { IncidentRolesShowResponseBody } from "@incident-io/api";
import { conditionsToGroupPayload } from "@incident-shared/engine/conditions/marshall";
import { ConditionsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionsEditorV2";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Badge, BadgeTheme, LoadingModal, ModalFooter } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  Condition,
  EngineScope,
  IncidentRole,
  IncidentRoleRoleTypeEnum,
  IncidentRolesCreateRequestBody,
  IncidentsBuildScopeContextEnum,
} from "src/contexts/ClientContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useIncidentTypesAvailable } from "src/utils/incident-types";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { KeyedMutator } from "swr";

import { useIncidentScope } from "../../../hooks/useIncidentScope";

type FormState = Omit<IncidentRolesCreateRequestBody, "condition_groups"> & {
  conditions: Condition[];
};

const transformFormStateToRequestBody = (
  data: FormState,
  existingRole?: IncidentRole,
): IncidentRolesCreateRequestBody => {
  return {
    ...data,
    // react-hook-form refuses to track data where there is no form field
    // watching it, so we fall back to the existing conditions here, without
    // edits
    condition_groups: conditionsToGroupPayload(
      data.conditions || existingRole?.condition_groups?.[0]?.conditions || [],
    ),
  };
};

export const IncidentRoleCreateEditModal = (): React.ReactElement => {
  const { id } = useParams();
  const mode = id ? Mode.Edit : Mode.Create;

  const navigate = useOrgAwareNavigate();
  const {
    data: incidentRoleData,
    isLoading: roleLoading,
    error: roleError,
    mutate: setIncidentRole,
  } = useAPI(mode === Mode.Edit ? "incidentRolesShow" : null, {
    id: id ?? "",
  });

  const title =
    mode === Mode.Edit ? "Edit incident role" : "Create incident role";

  const onClose = () => navigate("/settings/incident-roles");

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.ApplicableFields,
  );

  if (scopeError || roleError) {
    return <ErrorModal title={title} onClose={onClose} />;
  }

  if (
    scopeLoading ||
    roleLoading ||
    (mode === Mode.Edit && !incidentRoleData)
  ) {
    return <LoadingModal title={title} onClose={onClose} />;
  }

  const props: CreateEditFormProps<IncidentRole> =
    mode === Mode.Edit && incidentRoleData?.incident_role
      ? {
          mode: Mode.Edit,
          initialData: incidentRoleData.incident_role,
        }
      : { mode: Mode.Create };

  return (
    <CreateEditRoleForm
      {...props}
      setIncidentRole={setIncidentRole}
      scope={scope}
      onClose={onClose}
      title={title}
    />
  );
};

const CreateEditRoleForm = ({
  setIncidentRole,
  scope,
  mode,
  onClose,
  title,
  initialData: incidentRole,
}: {
  scope: EngineScope;
  onClose: () => void;
  title: string;
  setIncidentRole: KeyedMutator<IncidentRolesShowResponseBody>;
} & CreateEditFormProps<IncidentRole>) => {
  const {
    trigger: onSubmit,
    isMutating,
    genericError,
  } = useAPIMutation(
    "incidentRolesList",
    undefined,
    async (apiClient, data: FormState) => {
      if (!data.instructions) {
        data.instructions = "";
      }

      switch (mode) {
        case Mode.Create:
          await apiClient.incidentRolesCreate({
            createRequestBody: transformFormStateToRequestBody(
              data,
              incidentRole,
            ),
          });
          return;
        case Mode.Edit: {
          if (!incidentRole) {
            throw new Error(
              "unreachable: form is in edit mode, but no role was provided",
            );
          }
          const resp = await apiClient.incidentRolesUpdate({
            id: incidentRole.id,
            updateRequestBody: transformFormStateToRequestBody(
              data,
              incidentRole,
            ),
          });
          setIncidentRole(resp);
          return;
        }
      }
    },
    {
      setError: (name, error) => {
        // We don't have a good way of showing errors on individual conditions
        // yet, so this sets any
        if (name.includes("conditions")) {
          name = "conditions";
        }

        return setError(name, error);
      },
      onSuccess: onClose,
    },
  );

  const formMethods = useForm<FormState>({
    defaultValues: {
      ...incidentRole,
      conditions: incidentRole?.condition_groups?.[0]?.conditions || [],
    },
  });

  const isLead = incidentRole?.role_type === IncidentRoleRoleTypeEnum.Lead;
  const incidentTypesAvailable = useIncidentTypesAvailable();
  const showConditions = isLead ? false : incidentTypesAvailable;

  const {
    watch,
    setError,
    formState: { errors },
  } = formMethods;

  const incidentRoleShortform = watch("shortform");
  const platform = usePrimaryCommsPlatform();

  const getShortFormHelpText = (shortform: string): string => {
    if (isLead) {
      if (shortform === "lead") {
        return `/incident lead @christine`;
      }
      if (shortform === "commander") {
        return `/incident commander @christine`;
      }
    }
    return `/incident role ${
      incidentRoleShortform || "<reference>"
    } @christine`;
  };
  return (
    <FormModalV2<FormState>
      saving={isMutating}
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      title={title}
      titleBadge={
        isLead ? (
          <Badge theme={BadgeTheme.Info} className="ml-2">
            Lead Role
          </Badge>
        ) : undefined
      }
      disableQuickClose
      onClose={onClose}
      analyticsTrackingId={
        mode === Mode.Create ? "create-incident-role" : "edit-incident-role"
      }
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          saving={isMutating}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        required="Please provide a name"
      />

      {platform === CommsPlatform.Slack && (
        <InputV2
          formMethods={formMethods}
          name="shortform"
          label="Slack reference"
          helptext={
            errors.shortform ? undefined : (
              <p className="mt-2 mb-4 text-sm text-slate-700">
                {"To assign, you'd use "}
                <code className="text-xs">
                  {getShortFormHelpText(incidentRoleShortform || "")}
                </code>
              </p>
            )
          }
          required="Please provide a Slack reference"
        />
      )}
      {showConditions ? (
        <ConditionsEditorV2
          formMethods={formMethods}
          label="Conditions"
          name="conditions"
          wrapperClassName="mt-2 !bg-white"
          scope={scope}
          entityNameLabel="role"
          subjectsLabel="incidents"
          explanationStyle="available"
        />
      ) : null}
      <TextareaV2
        formMethods={formMethods}
        name="description"
        label="Description"
        helptext="How would you explain this role to someone unfamiliar with it?"
        placeholder="Responsible for both internal communication with key stakeholders, and external communication with our customers."
        required="Please provide a description"
        rows={4}
      />
      <TextareaV2
        formMethods={formMethods}
        name="instructions"
        label="Instructions"
        helptext="What guidance would you give to someone in this role during an incident? Feel free to link to any relevant docs."
        placeholder={
          "- Send regular updates\n- Keep them short and clear\n- Keep the status page up to date"
        }
        rows={4}
      />
    </FormModalV2>
  );
};
