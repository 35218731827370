/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemTemplateBlock,
    PostmortemTemplateBlockFromJSON,
    PostmortemTemplateBlockFromJSONTyped,
    PostmortemTemplateBlockToJSON,
} from './PostmortemTemplateBlock';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemsCreateTemplateRequestBody
 */
export interface PostmortemsCreateTemplateRequestBody {
    /**
     * Ordered content blocks to generate this template
     * @type {Array<PostmortemTemplateBlock>}
     * @memberof PostmortemsCreateTemplateRequestBody
     */
    blocks: Array<PostmortemTemplateBlock>;
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemsCreateTemplateRequestBody
     */
    document_title: TextNode;
    /**
     * Whether this template is the default postmortem template for this org, if sent as true will override the current default
     * @type {boolean}
     * @memberof PostmortemsCreateTemplateRequestBody
     */
    is_default: boolean;
    /**
     * Name of the postmortem template
     * @type {string}
     * @memberof PostmortemsCreateTemplateRequestBody
     */
    name: string;
    /**
     * Optional list of TimelineItem types that will be ignored within the post-mortem export if the incident timeline content block is included
     * @type {Array<string>}
     * @memberof PostmortemsCreateTemplateRequestBody
     */
    timeline_items_blocklist: Array<string>;
}

export function PostmortemsCreateTemplateRequestBodyFromJSON(json: any): PostmortemsCreateTemplateRequestBody {
    return PostmortemsCreateTemplateRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsCreateTemplateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsCreateTemplateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blocks': ((json['blocks'] as Array<any>).map(PostmortemTemplateBlockFromJSON)),
        'document_title': TextNodeFromJSON(json['document_title']),
        'is_default': json['is_default'],
        'name': json['name'],
        'timeline_items_blocklist': json['timeline_items_blocklist'],
    };
}

export function PostmortemsCreateTemplateRequestBodyToJSON(value?: PostmortemsCreateTemplateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocks': ((value.blocks as Array<any>).map(PostmortemTemplateBlockToJSON)),
        'document_title': TextNodeToJSON(value.document_title),
        'is_default': value.is_default,
        'name': value.name,
        'timeline_items_blocklist': value.timeline_items_blocklist,
    };
}

