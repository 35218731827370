/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StaffApplySubscriptionRequestBody,
    StaffApplySubscriptionRequestBodyFromJSON,
    StaffApplySubscriptionRequestBodyToJSON,
    StaffBootstrapCustomerChannelRequestBody,
    StaffBootstrapCustomerChannelRequestBodyFromJSON,
    StaffBootstrapCustomerChannelRequestBodyToJSON,
    StaffCreateImpersonationSessionRequestBody,
    StaffCreateImpersonationSessionRequestBodyFromJSON,
    StaffCreateImpersonationSessionRequestBodyToJSON,
    StaffCreateImpersonationSessionResponseBody,
    StaffCreateImpersonationSessionResponseBodyFromJSON,
    StaffCreateImpersonationSessionResponseBodyToJSON,
    StaffDestroyImpersonationSessionResponseBody,
    StaffDestroyImpersonationSessionResponseBodyFromJSON,
    StaffDestroyImpersonationSessionResponseBodyToJSON,
    StaffEnableOnCallRequestBody,
    StaffEnableOnCallRequestBodyFromJSON,
    StaffEnableOnCallRequestBodyToJSON,
    StaffExtendTrialRequestBody,
    StaffExtendTrialRequestBodyFromJSON,
    StaffExtendTrialRequestBodyToJSON,
    StaffGrantOwnerRoleRequestBody,
    StaffGrantOwnerRoleRequestBodyFromJSON,
    StaffGrantOwnerRoleRequestBodyToJSON,
    StaffListUsersResponseBody,
    StaffListUsersResponseBodyFromJSON,
    StaffListUsersResponseBodyToJSON,
    StaffRenameOrganisationRequestBody,
    StaffRenameOrganisationRequestBodyFromJSON,
    StaffRenameOrganisationRequestBodyToJSON,
    StaffSetFeatureGatesRequestBody,
    StaffSetFeatureGatesRequestBodyFromJSON,
    StaffSetFeatureGatesRequestBodyToJSON,
    StaffSetPlanInfoRequestBody,
    StaffSetPlanInfoRequestBodyFromJSON,
    StaffSetPlanInfoRequestBodyToJSON,
    StaffSetUserPermissionsRequestBody,
    StaffSetUserPermissionsRequestBodyFromJSON,
    StaffSetUserPermissionsRequestBodyToJSON,
    StaffShowImpersonationSessionResponseBody,
    StaffShowImpersonationSessionResponseBodyFromJSON,
    StaffShowImpersonationSessionResponseBodyToJSON,
    StaffShowOrganisationResponseBody,
    StaffShowOrganisationResponseBodyFromJSON,
    StaffShowOrganisationResponseBodyToJSON,
    StaffStartTrialRequestBody,
    StaffStartTrialRequestBodyFromJSON,
    StaffStartTrialRequestBodyToJSON,
    StaffTypeaheadOrganisationsResponseBody,
    StaffTypeaheadOrganisationsResponseBodyFromJSON,
    StaffTypeaheadOrganisationsResponseBodyToJSON,
} from '../models';

export interface StaffApplySubscriptionRequest {
    organisationSlug: string;
    applySubscriptionRequestBody: StaffApplySubscriptionRequestBody;
}

export interface StaffArchiveOnCallForOrganisationRequest {
    organisationSlug: string;
}

export interface StaffArchiveOrganisationRequest {
    organisationSlug: string;
}

export interface StaffArchiveResponseForOrganisationRequest {
    organisationSlug: string;
}

export interface StaffBootstrapCustomerChannelRequest {
    bootstrapCustomerChannelRequestBody: StaffBootstrapCustomerChannelRequestBody;
}

export interface StaffCreateImpersonationSessionRequest {
    createImpersonationSessionRequestBody: StaffCreateImpersonationSessionRequestBody;
}

export interface StaffDestroyImpersonationSessionRequest {
    id: string;
}

export interface StaffDisableOrganisationRequest {
    organisationSlug: string;
}

export interface StaffEnableOnCallRequest {
    organisationSlug: string;
    enableOnCallRequestBody: StaffEnableOnCallRequestBody;
}

export interface StaffEnableResponseRequest {
    organisationSlug: string;
}

export interface StaffExtendTrialRequest {
    organisationSlug: string;
    extendTrialRequestBody: StaffExtendTrialRequestBody;
}

export interface StaffGrantOwnerRoleRequest {
    organisationSlug: string;
    grantOwnerRoleRequestBody: StaffGrantOwnerRoleRequestBody;
}

export interface StaffMakeDemoRequest {
    organisationSlug: string;
}

export interface StaffMakeSandboxRequest {
    organisationSlug: string;
}

export interface StaffMoveToBasicRequest {
    organisationSlug: string;
}

export interface StaffRenameOrganisationRequest {
    organisationSlug: string;
    renameOrganisationRequestBody: StaffRenameOrganisationRequestBody;
}

export interface StaffSetFeatureGatesRequest {
    organisationSlug: string;
    setFeatureGatesRequestBody: StaffSetFeatureGatesRequestBody;
}

export interface StaffSetPlanInfoRequest {
    organisationSlug: string;
    setPlanInfoRequestBody: StaffSetPlanInfoRequestBody;
}

export interface StaffSetUserPermissionsRequest {
    id: string;
    setUserPermissionsRequestBody: StaffSetUserPermissionsRequestBody;
}

export interface StaffShowImpersonationSessionRequest {
    id: string;
}

export interface StaffShowOrganisationRequest {
    organisationSlug: string;
}

export interface StaffStartTrialRequest {
    organisationSlug: string;
    startTrialRequestBody: StaffStartTrialRequestBody;
}

export interface StaffTypeaheadOrganisationsRequest {
    query?: string;
    idList?: Array<string>;
}

/**
 * 
 */
export class StaffApi extends runtime.BaseAPI {

    /**
     * Applies a new Stripe subscription to an organisation.
     * ApplySubscription Staff
     */
    async staffApplySubscriptionRaw(requestParameters: StaffApplySubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffApplySubscription.');
        }

        if (requestParameters.applySubscriptionRequestBody === null || requestParameters.applySubscriptionRequestBody === undefined) {
            throw new runtime.RequiredError('applySubscriptionRequestBody','Required parameter requestParameters.applySubscriptionRequestBody was null or undefined when calling staffApplySubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/apply_subscription`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffApplySubscriptionRequestBodyToJSON(requestParameters.applySubscriptionRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Applies a new Stripe subscription to an organisation.
     * ApplySubscription Staff
     */
    async staffApplySubscription(requestParameters: StaffApplySubscriptionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffApplySubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     * Archives an organisation\'s on-call configuration (schedules, escalation paths, etc.)
     * ArchiveOnCallForOrganisation Staff
     */
    async staffArchiveOnCallForOrganisationRaw(requestParameters: StaffArchiveOnCallForOrganisationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffArchiveOnCallForOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/archive_on_call`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an organisation\'s on-call configuration (schedules, escalation paths, etc.)
     * ArchiveOnCallForOrganisation Staff
     */
    async staffArchiveOnCallForOrganisation(requestParameters: StaffArchiveOnCallForOrganisationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffArchiveOnCallForOrganisationRaw(requestParameters, initOverrides);
    }

    /**
     * Set an organisation as archived - for when a customer has churned.
     * ArchiveOrganisation Staff
     */
    async staffArchiveOrganisationRaw(requestParameters: StaffArchiveOrganisationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffArchiveOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/archive`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set an organisation as archived - for when a customer has churned.
     * ArchiveOrganisation Staff
     */
    async staffArchiveOrganisation(requestParameters: StaffArchiveOrganisationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffArchiveOrganisationRaw(requestParameters, initOverrides);
    }

    /**
     * Archives an organisation\'s response configuration (workflows, nudges, auto-export follow up configs.)
     * ArchiveResponseForOrganisation Staff
     */
    async staffArchiveResponseForOrganisationRaw(requestParameters: StaffArchiveResponseForOrganisationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffArchiveResponseForOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/archive_response`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an organisation\'s response configuration (workflows, nudges, auto-export follow up configs.)
     * ArchiveResponseForOrganisation Staff
     */
    async staffArchiveResponseForOrganisation(requestParameters: StaffArchiveResponseForOrganisationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffArchiveResponseForOrganisationRaw(requestParameters, initOverrides);
    }

    /**
     * Bootstrap a customer channel.
     * BootstrapCustomerChannel Staff
     */
    async staffBootstrapCustomerChannelRaw(requestParameters: StaffBootstrapCustomerChannelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bootstrapCustomerChannelRequestBody === null || requestParameters.bootstrapCustomerChannelRequestBody === undefined) {
            throw new runtime.RequiredError('bootstrapCustomerChannelRequestBody','Required parameter requestParameters.bootstrapCustomerChannelRequestBody was null or undefined when calling staffBootstrapCustomerChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/staff/bootstrap_customer_channel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffBootstrapCustomerChannelRequestBodyToJSON(requestParameters.bootstrapCustomerChannelRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bootstrap a customer channel.
     * BootstrapCustomerChannel Staff
     */
    async staffBootstrapCustomerChannel(requestParameters: StaffBootstrapCustomerChannelRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffBootstrapCustomerChannelRaw(requestParameters, initOverrides);
    }

    /**
     * Start impersonating a target organisation.
     * CreateImpersonationSession Staff
     */
    async staffCreateImpersonationSessionRaw(requestParameters: StaffCreateImpersonationSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StaffCreateImpersonationSessionResponseBody>> {
        if (requestParameters.createImpersonationSessionRequestBody === null || requestParameters.createImpersonationSessionRequestBody === undefined) {
            throw new runtime.RequiredError('createImpersonationSessionRequestBody','Required parameter requestParameters.createImpersonationSessionRequestBody was null or undefined when calling staffCreateImpersonationSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/staff/impersonation_sessions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffCreateImpersonationSessionRequestBodyToJSON(requestParameters.createImpersonationSessionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffCreateImpersonationSessionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Start impersonating a target organisation.
     * CreateImpersonationSession Staff
     */
    async staffCreateImpersonationSession(requestParameters: StaffCreateImpersonationSessionRequest, initOverrides?: RequestInit): Promise<StaffCreateImpersonationSessionResponseBody> {
        const response = await this.staffCreateImpersonationSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * End an impersonation session.
     * DestroyImpersonationSession Staff
     */
    async staffDestroyImpersonationSessionRaw(requestParameters: StaffDestroyImpersonationSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StaffDestroyImpersonationSessionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling staffDestroyImpersonationSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/impersonation_sessions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffDestroyImpersonationSessionResponseBodyFromJSON(jsonValue));
    }

    /**
     * End an impersonation session.
     * DestroyImpersonationSession Staff
     */
    async staffDestroyImpersonationSession(requestParameters: StaffDestroyImpersonationSessionRequest, initOverrides?: RequestInit): Promise<StaffDestroyImpersonationSessionResponseBody> {
        const response = await this.staffDestroyImpersonationSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Disable an organisation, and send anyone logging in to the careers page.
     * DisableOrganisation Staff
     */
    async staffDisableOrganisationRaw(requestParameters: StaffDisableOrganisationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffDisableOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/disable`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Disable an organisation, and send anyone logging in to the careers page.
     * DisableOrganisation Staff
     */
    async staffDisableOrganisation(requestParameters: StaffDisableOrganisationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffDisableOrganisationRaw(requestParameters, initOverrides);
    }

    /**
     * Enables On-call for an organisation that\'s in the waitlist
     * EnableOnCall Staff
     */
    async staffEnableOnCallRaw(requestParameters: StaffEnableOnCallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffEnableOnCall.');
        }

        if (requestParameters.enableOnCallRequestBody === null || requestParameters.enableOnCallRequestBody === undefined) {
            throw new runtime.RequiredError('enableOnCallRequestBody','Required parameter requestParameters.enableOnCallRequestBody was null or undefined when calling staffEnableOnCall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/enable_on_call`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffEnableOnCallRequestBodyToJSON(requestParameters.enableOnCallRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enables On-call for an organisation that\'s in the waitlist
     * EnableOnCall Staff
     */
    async staffEnableOnCall(requestParameters: StaffEnableOnCallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffEnableOnCallRaw(requestParameters, initOverrides);
    }

    /**
     * Enables Response for an organisation
     * EnableResponse Staff
     */
    async staffEnableResponseRaw(requestParameters: StaffEnableResponseRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffEnableResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/enable_response`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enables Response for an organisation
     * EnableResponse Staff
     */
    async staffEnableResponse(requestParameters: StaffEnableResponseRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffEnableResponseRaw(requestParameters, initOverrides);
    }

    /**
     * Extends a free trial for an organisation.
     * ExtendTrial Staff
     */
    async staffExtendTrialRaw(requestParameters: StaffExtendTrialRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffExtendTrial.');
        }

        if (requestParameters.extendTrialRequestBody === null || requestParameters.extendTrialRequestBody === undefined) {
            throw new runtime.RequiredError('extendTrialRequestBody','Required parameter requestParameters.extendTrialRequestBody was null or undefined when calling staffExtendTrial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/extend_trial`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffExtendTrialRequestBodyToJSON(requestParameters.extendTrialRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Extends a free trial for an organisation.
     * ExtendTrial Staff
     */
    async staffExtendTrial(requestParameters: StaffExtendTrialRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffExtendTrialRaw(requestParameters, initOverrides);
    }

    /**
     * Grant a user the \'owner\' role for another organisation. We\'ll only permit this if they are a Slack workspace owner.
     * GrantOwnerRole Staff
     */
    async staffGrantOwnerRoleRaw(requestParameters: StaffGrantOwnerRoleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffGrantOwnerRole.');
        }

        if (requestParameters.grantOwnerRoleRequestBody === null || requestParameters.grantOwnerRoleRequestBody === undefined) {
            throw new runtime.RequiredError('grantOwnerRoleRequestBody','Required parameter requestParameters.grantOwnerRoleRequestBody was null or undefined when calling staffGrantOwnerRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/grant_owner_role`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffGrantOwnerRoleRequestBodyToJSON(requestParameters.grantOwnerRoleRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Grant a user the \'owner\' role for another organisation. We\'ll only permit this if they are a Slack workspace owner.
     * GrantOwnerRole Staff
     */
    async staffGrantOwnerRole(requestParameters: StaffGrantOwnerRoleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffGrantOwnerRoleRaw(requestParameters, initOverrides);
    }

    /**
     * List the users in the staff organisation, with their permissions.
     * ListUsers Staff
     */
    async staffListUsersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<StaffListUsersResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffListUsersResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the users in the staff organisation, with their permissions.
     * ListUsers Staff
     */
    async staffListUsers(initOverrides?: RequestInit): Promise<StaffListUsersResponseBody> {
        const response = await this.staffListUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Makes an organisation a demo organisation.
     * MakeDemo Staff
     */
    async staffMakeDemoRaw(requestParameters: StaffMakeDemoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffMakeDemo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/make_demo`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Makes an organisation a demo organisation.
     * MakeDemo Staff
     */
    async staffMakeDemo(requestParameters: StaffMakeDemoRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffMakeDemoRaw(requestParameters, initOverrides);
    }

    /**
     * Makes an organisation a sandbox organisation.
     * MakeSandbox Staff
     */
    async staffMakeSandboxRaw(requestParameters: StaffMakeSandboxRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffMakeSandbox.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/make_sandbox`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Makes an organisation a sandbox organisation.
     * MakeSandbox Staff
     */
    async staffMakeSandbox(requestParameters: StaffMakeSandboxRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffMakeSandboxRaw(requestParameters, initOverrides);
    }

    /**
     * Moves an organisation from a trial to the basic plan.
     * MoveToBasic Staff
     */
    async staffMoveToBasicRaw(requestParameters: StaffMoveToBasicRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffMoveToBasic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/move_to_basic`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Moves an organisation from a trial to the basic plan.
     * MoveToBasic Staff
     */
    async staffMoveToBasic(requestParameters: StaffMoveToBasicRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffMoveToBasicRaw(requestParameters, initOverrides);
    }

    /**
     * Set the name of an organisation for use in search
     * RenameOrganisation Staff
     */
    async staffRenameOrganisationRaw(requestParameters: StaffRenameOrganisationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffRenameOrganisation.');
        }

        if (requestParameters.renameOrganisationRequestBody === null || requestParameters.renameOrganisationRequestBody === undefined) {
            throw new runtime.RequiredError('renameOrganisationRequestBody','Required parameter requestParameters.renameOrganisationRequestBody was null or undefined when calling staffRenameOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/rename_organisation`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffRenameOrganisationRequestBodyToJSON(requestParameters.renameOrganisationRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the name of an organisation for use in search
     * RenameOrganisation Staff
     */
    async staffRenameOrganisation(requestParameters: StaffRenameOrganisationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffRenameOrganisationRaw(requestParameters, initOverrides);
    }

    /**
     * Set the feature gates for a customer\'s account.
     * SetFeatureGates Staff
     */
    async staffSetFeatureGatesRaw(requestParameters: StaffSetFeatureGatesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffSetFeatureGates.');
        }

        if (requestParameters.setFeatureGatesRequestBody === null || requestParameters.setFeatureGatesRequestBody === undefined) {
            throw new runtime.RequiredError('setFeatureGatesRequestBody','Required parameter requestParameters.setFeatureGatesRequestBody was null or undefined when calling staffSetFeatureGates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/set_feature_gates`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffSetFeatureGatesRequestBodyToJSON(requestParameters.setFeatureGatesRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the feature gates for a customer\'s account.
     * SetFeatureGates Staff
     */
    async staffSetFeatureGates(requestParameters: StaffSetFeatureGatesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffSetFeatureGatesRaw(requestParameters, initOverrides);
    }

    /**
     * Adjusts settings on the customer\'s plan
     * SetPlanInfo Staff
     */
    async staffSetPlanInfoRaw(requestParameters: StaffSetPlanInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffSetPlanInfo.');
        }

        if (requestParameters.setPlanInfoRequestBody === null || requestParameters.setPlanInfoRequestBody === undefined) {
            throw new runtime.RequiredError('setPlanInfoRequestBody','Required parameter requestParameters.setPlanInfoRequestBody was null or undefined when calling staffSetPlanInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/set_plan_info`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffSetPlanInfoRequestBodyToJSON(requestParameters.setPlanInfoRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adjusts settings on the customer\'s plan
     * SetPlanInfo Staff
     */
    async staffSetPlanInfo(requestParameters: StaffSetPlanInfoRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffSetPlanInfoRaw(requestParameters, initOverrides);
    }

    /**
     * Set the permissions for a user in the staff organisation.
     * SetUserPermissions Staff
     */
    async staffSetUserPermissionsRaw(requestParameters: StaffSetUserPermissionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling staffSetUserPermissions.');
        }

        if (requestParameters.setUserPermissionsRequestBody === null || requestParameters.setUserPermissionsRequestBody === undefined) {
            throw new runtime.RequiredError('setUserPermissionsRequestBody','Required parameter requestParameters.setUserPermissionsRequestBody was null or undefined when calling staffSetUserPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/staff/users/{id}/actions/set_permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffSetUserPermissionsRequestBodyToJSON(requestParameters.setUserPermissionsRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the permissions for a user in the staff organisation.
     * SetUserPermissions Staff
     */
    async staffSetUserPermissions(requestParameters: StaffSetUserPermissionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffSetUserPermissionsRaw(requestParameters, initOverrides);
    }

    /**
     * Get the latest info about an impersonation session.
     * ShowImpersonationSession Staff
     */
    async staffShowImpersonationSessionRaw(requestParameters: StaffShowImpersonationSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StaffShowImpersonationSessionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling staffShowImpersonationSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/impersonation_sessions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffShowImpersonationSessionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the latest info about an impersonation session.
     * ShowImpersonationSession Staff
     */
    async staffShowImpersonationSession(requestParameters: StaffShowImpersonationSessionRequest, initOverrides?: RequestInit): Promise<StaffShowImpersonationSessionResponseBody> {
        const response = await this.staffShowImpersonationSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve billing information for a customer\'s account.
     * ShowOrganisation Staff
     */
    async staffShowOrganisationRaw(requestParameters: StaffShowOrganisationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StaffShowOrganisationResponseBody>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffShowOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/{organisation_slug}`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffShowOrganisationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Retrieve billing information for a customer\'s account.
     * ShowOrganisation Staff
     */
    async staffShowOrganisation(requestParameters: StaffShowOrganisationRequest, initOverrides?: RequestInit): Promise<StaffShowOrganisationResponseBody> {
        const response = await this.staffShowOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Moves a free-tier organisation over onto a trial of Pro/Enterprise.
     * StartTrial Staff
     */
    async staffStartTrialRaw(requestParameters: StaffStartTrialRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationSlug === null || requestParameters.organisationSlug === undefined) {
            throw new runtime.RequiredError('organisationSlug','Required parameter requestParameters.organisationSlug was null or undefined when calling staffStartTrial.');
        }

        if (requestParameters.startTrialRequestBody === null || requestParameters.startTrialRequestBody === undefined) {
            throw new runtime.RequiredError('startTrialRequestBody','Required parameter requestParameters.startTrialRequestBody was null or undefined when calling staffStartTrial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/staff/{organisation_slug}/actions/start_trial`.replace(`{${"organisation_slug"}}`, encodeURIComponent(String(requestParameters.organisationSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffStartTrialRequestBodyToJSON(requestParameters.startTrialRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Moves a free-tier organisation over onto a trial of Pro/Enterprise.
     * StartTrial Staff
     */
    async staffStartTrial(requestParameters: StaffStartTrialRequest, initOverrides?: RequestInit): Promise<void> {
        await this.staffStartTrialRaw(requestParameters, initOverrides);
    }

    /**
     * List options for an organisations typeahead.
     * TypeaheadOrganisations Staff
     */
    async staffTypeaheadOrganisationsRaw(requestParameters: StaffTypeaheadOrganisationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StaffTypeaheadOrganisationsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.idList) {
            queryParameters['id_list'] = requestParameters.idList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/staff/organisations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffTypeaheadOrganisationsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List options for an organisations typeahead.
     * TypeaheadOrganisations Staff
     */
    async staffTypeaheadOrganisations(requestParameters: StaffTypeaheadOrganisationsRequest, initOverrides?: RequestInit): Promise<StaffTypeaheadOrganisationsResponseBody> {
        const response = await this.staffTypeaheadOrganisationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
