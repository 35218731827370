import { ExpressionFixedResultType } from "@incident-shared/engine/expressions/ifelse/createDefaultExpressionFormValues";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import React from "react";
import { EngineScope, Reference } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { EngineReferenceBadge } from "../labels/EngineReferenceBadge";
import {
  MenuEntry,
  ReferenceSelectorPopover,
} from "../ReferenceSelectorPopover/ReferenceSelectorPopover";
import { referenceSource } from "../referenceSource";

// EngineFormElementReference is like EngineReferenceBadge, but it has an edit button and a delete button. It
// is expected to be rendered alongside other inputs, so has a lot more padding than ReferencePill.
export const EngineFormElementReference = ({
  label,
  referenceKey,
  onUseLiteral,
  scope,
  onSelectReference,
  isSelectable,
  disabled,
  includeExpressions,
  expressionFixedResultType,
  overrideOnAddExpression,
}: {
  label: string;
  referenceKey: string;
  onUseLiteral?: () => void;
  scope: EngineScope;
  onSelectReference: (entry: Reference) => void;
  isSelectable: (entry: MenuEntry) => boolean;
  disabled?: boolean;
  includeExpressions?: boolean;
  expressionFixedResultType?: ExpressionFixedResultType;
  // For alert attributes, we want to override the default behavior of adding an expression. Search
  // 'resourceHasCandidatesInScope' for context.
  overrideOnAddExpression?: () => void;
}): React.ReactElement => {
  return (
    <div className="flex items-center space-x-2 min-w-0 grow border border-stroke rounded-2 px-2 py-1 min-h-[42px]">
      {/* We mess with the padding to make it look more like our other inputs */}
      <ReferenceSelectorPopover
        scope={scope}
        allowExpressions={includeExpressions}
        isSelectable={isSelectable}
        onSelectReference={onSelectReference}
        expressionFixedResultType={expressionFixedResultType}
        renderTriggerButton={({ onClick }) => (
          <button
            onClick={onClick}
            className={tcx(
              "group flex space-x-1 items-center min-w-0 grow text-sm",
              {
                "hover:!cursor-pointer": !disabled,
                "hover:!cursor-not-allowed": disabled,
              },
            )}
            disabled={disabled}
          >
            <EngineReferenceBadge
              label={label}
              editable={!disabled}
              referenceSource={referenceSource(referenceKey)}
            />
          </button>
        )}
        overrideOnAddExpression={overrideOnAddExpression}
      />
      {!!onUseLiteral && (
        <Button
          theme={ButtonTheme.UnstyledPill}
          className="ml-auto text-slate-400 hover:text-content-primary shrink-0 !p-0 !w-7 !h-7 flex-center"
          iconProps={{ className: "!w-5 !h-5 child:stroke-[1.5px]" }}
          analyticsTrackingId={null}
          icon={IconEnum.TextInput}
          onClick={onUseLiteral}
          disabled={disabled}
          title="Use text"
        />
      )}
    </div>
  );
};
