import {
  Incident,
  IncidentVisibilityEnum,
  SuggestedFollowUp,
} from "@incident-io/api";
import { FollowUpCreateModal } from "@incident-shared/follow-ups";
import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Heading,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import {
  AssistantAvatar,
  AssistantAvatarBackground,
} from "src/components/insights/assistant/AssistantAvatar";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./FollowUps.module.scss";
import { useSkip } from "./hooks";

const IconNumbers = [
  IconEnum.NumberBox1Filled,
  IconEnum.NumberBox2Filled,
  IconEnum.NumberBox3Filled,
];

export function FollowUpSuggestionsPanel({
  incident,
  followUpSuggestions,
}: {
  incident: Incident;
  followUpSuggestions: SuggestedFollowUp[];
}): React.ReactElement {
  const [showCreateFollowUpModal, setShowCreateFollowUpModal] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    SuggestedFollowUp | undefined
  >(undefined);

  return (
    <div
      className={tcx(
        "flex flex-row flex-start gap-4 p-4 mt-6 rounded-2 border-[1px] border-color-purple-100",
        styles.rainbowBackground,
      )}
    >
      <div className="p-0.5 rounded-1">
        <AssistantAvatar
          size={IconSize.Small}
          background={AssistantAvatarBackground.Purple}
          rounded={false}
        />
      </div>
      <div className="flex-1">
        <Heading level={3} className="pt-1 pl-2">
          Suggested follow-ups
        </Heading>
        <ol className="pt-2 overflow-hidden">
          <AnimatePresence>
            {followUpSuggestions.map((suggestion, index) => {
              return (
                <FollowUpSuggestion
                  incidentId={incident.id}
                  key={suggestion.id}
                  suggestion={suggestion}
                  icon={IconNumbers[index]}
                  onAccept={() => {
                    setSelectedSuggestion(suggestion);
                    setShowCreateFollowUpModal(true);
                  }}
                />
              );
            })}
          </AnimatePresence>
        </ol>
      </div>

      {showCreateFollowUpModal && (
        <FollowUpCreateModal
          incidentId={incident.id}
          onClose={() => {
            setShowCreateFollowUpModal(false);
            setSelectedSuggestion(undefined);
          }}
          followUpSuggestion={selectedSuggestion}
          isPrivateIncident={
            incident.visibility === IncidentVisibilityEnum.Private
          }
        />
      )}
    </div>
  );
}

function FollowUpSuggestion({
  incidentId,
  suggestion,
  icon,
  onAccept,
}: {
  incidentId: string;
  suggestion: SuggestedFollowUp;
  icon: IconEnum;
  onAccept: (id: string) => void;
}): React.ReactElement {
  const { skipFollowUpSuggestion, skipError, isSkipping } = useSkip(incidentId);

  return (
    <motion.li
      layout
      initial={{ x: 0, opacity: 1 }}
      exit={{ x: 100, opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="flex flex-col gap-2"
    >
      <div
        className={tcx(
          "flex flex-row p-2 items-start gap-3 rounded-2 group",
          styles.rainbowSuggestionBackground,
        )}
      >
        <div className="pt-0.5">
          <Icon id={icon} size={IconSize.Small} />
        </div>
        <div className="flex-1">
          <div className="text-content-primary text-sm-med">
            {suggestion.title}
          </div>
          <div className="text-content-tertiary text-xs-med">
            {suggestion.context}
          </div>
          <ErrorMessageUI message={skipError} />
        </div>
        <div className={tcx("self-center", styles.suggestionButtonWrapper)}>
          <div
            className={tcx(
              "flex flex-row gap-2",
              "-translate-x-2 group-hover:translate-x-0 transition duration-300",
              "group-hover:opacity-100 opacity-0",
            )}
          >
            <Button
              onClick={() => {
                skipFollowUpSuggestion({ id: suggestion.id });
              }}
              theme={ButtonTheme.Ghost}
              size={ButtonSize.Small}
              icon={IconEnum.Close}
              analyticsTrackingId={"skip-follow-up-suggestion"}
              disabled={isSkipping}
            >
              Skip
            </Button>
            <Button
              onClick={() => {
                onAccept(suggestion.id);
              }}
              theme={ButtonTheme.Secondary}
              size={ButtonSize.Small}
              icon={IconEnum.Tick}
              analyticsTrackingId={"accept-follow-up-suggestion"}
            >
              Accept
            </Button>
          </div>
        </div>
      </div>
    </motion.li>
  );
}
