import {
  AlertSchema,
  AlertSource,
  AlertSourceConfig,
  AlertsShowSchemaResponseBody,
  AlertsShowSourceConfigResponseBody,
  AlertsUpdateSourceConfigRequestBody,
  AlertTemplatePayload,
  CatalogResource,
  EngineParamBinding,
  EngineScope,
  Expression,
  Priority,
  Resource,
} from "@incident-io/api";
import { CatalogSetupWidget } from "@incident-shared/catalog/CatalogSetupWidget";
import {
  EngineFormElementProps,
  isEmptyBinding,
} from "@incident-shared/engine";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ReferenceWithExample } from "@incident-shared/engine/expressions/ExpressionsEditor";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import {
  ExpressionFormData,
  expressionToPayload,
} from "@incident-shared/engine/expressions/expressionToPayload";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { GenericErrorMessage, Loader } from "@incident-ui";
import { TitleInput } from "@incident-ui/TitleInput/TitleInput";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Prompt } from "src/components/@shared/utils/Prompt";
import { containsExpressionReference } from "src/components/legacy/workflows/common/utils";
import { useAllResources } from "src/hooks/useResources";
import { useQueryParams } from "src/utils/query-params";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { KeyedMutator } from "swr";
import { useDebounce } from "use-hooks";

import { PrioritiesCreateEditDrawer } from "../../priorities/PrioritiesCreateEditDrawer";
import {
  AlertSourceFormIds,
  AlertSourceStepEnum,
} from "../AlertSourceCreatePage";
import { AlertSourceSplitLayout } from "../AlertSourceLayout";
import { stripInvalidBindings } from "../stripInvalidBindings";
import { useScopeWithAlertPreviews } from "../useScopeWithAlertPreviews";
import { AlertSourceAttributes } from "./AlertSourceAttributes";
import { AlertSourcePriority } from "./AlertSourcePriority";
import { AlertSourceTitleDescription } from "./AlertSourceTitleDescription";
import { AlertsPreviewSplit } from "./AlertsPreviewSplit";
import { useSetupProgress } from "./useSetupProgress";
/*
import { AlertSourceNativePropertiesSection } from "./AlertSourceNativePropertiesSection";
 */

export type AlertSourceAttributeFormElementProps = Omit<
  EngineFormElementProps<AlertSourceConfigureFormData>,
  "name" | "label" | "resourceType"
> & { mode: "variables_and_expressions"; scope: EngineScope };

// This page drives the configuration of alert source configs. It is a vertical
// split pane with config fields (e.g. title, description) on the left and a
// preview of the parsed alerts on the right.
export const AlertSourceConfigurePage = ({
  mode,
  titleInputRef,
  alertSource,
  alertSourceConfig,
}: {
  mode: Mode;
  titleInputRef: React.RefObject<HTMLDivElement>;
  alertSourceConfig: AlertSourceConfig;
  alertSource: AlertSource;
}) => {
  const { resources, resourcesLoading, resourcesError } = useAllResources();

  const {
    data: schemaResponse,
    mutate: mutateSchema,
    isLoading: schemaLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", undefined);

  const {
    data: catalogResourcesResponse,
    isLoading: catalogResourcesLoading,
    error: catalogResourcesError,
  } = useAPI("catalogListResources", undefined);

  const {
    data: prioritiesResponse,
    isLoading: prioritiesLoading,
    error: prioritiesError,
  } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );

  if (
    resourcesError ||
    schemaError ||
    catalogResourcesError ||
    prioritiesError
  ) {
    return (
      <GenericErrorMessage
        error={
          resourcesError ||
          schemaError ||
          catalogResourcesError ||
          prioritiesError
        }
      />
    );
  }

  if (
    schemaLoading ||
    !schemaResponse ||
    catalogResourcesLoading ||
    !catalogResourcesResponse ||
    resourcesLoading ||
    !resources ||
    prioritiesLoading ||
    !prioritiesResponse
  ) {
    return <Loader className={"h-full"} />;
  }

  return (
    <AlertSourceConfigureForm
      existingSource={alertSourceConfig}
      alertSource={alertSource}
      schema={schemaResponse.alert_schema}
      mutateSchema={mutateSchema}
      resources={resources}
      catalogResources={catalogResourcesResponse.resources}
      defaultPriority={_.find(
        prioritiesResponse.priorities,
        (p) => p.is_default,
      )}
      mode={mode}
      titleInputRef={titleInputRef}
    />
  );
};

export const AlertSourceConfigureForm = ({
  existingSource,
  schema,
  mutateSchema,
  resources,
  catalogResources,
  defaultPriority,
  mode,
  titleInputRef,
  alertSource,
}: {
  existingSource: AlertSourceConfig;
  alertSource: AlertSource;
  schema: AlertSchema;
  mutateSchema: KeyedMutator<AlertsShowSchemaResponseBody>;
  resources: Resource[];
  catalogResources: CatalogResource[];
  defaultPriority: Priority | undefined;
  mode: Mode;
  titleInputRef: React.RefObject<HTMLDivElement>;
}) => {
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const formMethods = useForm<AlertSourceConfigureFormData>({
    defaultValues: makeDefaultValues(existingSource, schema, defaultPriority),
  });

  const refetchSourceConfigs = useAPIRefetch(
    "alertsListSourceConfigs",
    undefined,
  );

  const queryParams = useQueryParams();

  const { trigger: onSubmit, genericError } = useAPIMutation(
    "alertsShowSourceConfig",
    {
      id: existingSource?.id ?? "",
    },
    async (apiClient, data: AlertSourceConfigureFormData) => {
      const newSchema = await apiClient.alertsUpdateSchema({
        updateSchemaRequestBody: {
          alert_schema: {
            attributes: Array.from(attributes),
            version: schema.version,
          },
        },
      });
      mutateSchema(newSchema);

      const newConfig = await apiClient.alertsUpdateSourceConfig({
        id: existingSource?.id ?? "",
        updateSourceConfigRequestBody: {
          name: data.name,
          template: stripInvalidBindings({
            expressions: template.expressions
              .filter((e) => getExpressionUsages(e, data)[0].usages.length > 0)
              .map((e) => expressionToPayload(e as unknown as Expression)),
            title: data.template.title,
            description: data.template.description,
            priority: data.template.priority,
            bindings: data.template.bindings,
          }),
          routing: existingSource.routing,
        },
      });

      formMethods.reset({
        ...(newConfig.alert_source_config as unknown as AlertSourceConfigureFormData),
        attributes: newSchema.alert_schema.attributes,
      }); // to ensure isDirty=false

      refetchSourceConfigs();
      return newConfig;
    },
    {
      onSuccess: async (data: AlertsShowSourceConfigResponseBody) => {
        showToast({
          theme: ToastTheme.Success,
          title: `Alert source saved`,
          toastSide: ToastSideEnum.TopRight,
        });

        if (queryParams.get("from") === "alert-routes") {
          return navigate(
            `/alerts/routes/create?initial_source_id=${data.alert_source_config.id}`,
          );
        }

        return null;
      },
      setError: formMethods.setError,
    },
  );

  const [title, description] = formMethods.watch([
    "template.title",
    "template.description",
  ]);
  const emptyTitleOrDescription =
    isEmptyBinding(title) || isEmptyBinding(description);

  const [template, attributes] = formMethods.watch(["template", "attributes"]);

  const {
    data: previewAlerts,
    isLoading: previewAlertsLoading,
    error: previewAlertsError,
    mutate: previewAlertsMutate,
  } = useAPI(
    "alertsPreviewSourceConfigAlerts",
    {
      id: existingSource?.id,
      previewSourceConfigAlertsRequestBody: {
        template: stripInvalidBindings({
          expressions: template.expressions.map((e) =>
            expressionToPayload(e as unknown as Expression),
          ),
          title: title,
          description: description,
          bindings: template.bindings,
        }),
        attributes: attributes,
        version: schema.version,
      },
    },
    {
      // This allows us to show a nicer loader, instead of having layout shift
      // whenever something changes.
      keepPreviousData: true,
    },
  );

  const expressionsMethods = useFieldArray({
    control: formMethods.control,
    name: "template.expressions",
    keyName: "key",
  });

  // Get a scope with the alert JSON previews patched in
  const scope = useScopeWithAlertPreviews({
    alerts: previewAlerts?.alerts ?? [],
    alertSource,
  });

  const scopeWithExpressions = addExpressionsToScope<ReferenceWithExample>(
    scope,
    expressionsMethods.fields as unknown as ExpressionFormData[],
  );

  // We JSON.stringify this so that we can trigger the use effect whenever the
  // template changes, as otherwise swr does not refire.
  const templateChecksum = useDebounce(JSON.stringify(template), 1000);
  useEffect(() => {
    if (!emptyTitleOrDescription) {
      previewAlertsMutate();
    }
  }, [emptyTitleOrDescription, templateChecksum, previewAlertsMutate]);

  const assignedAttributeIds = Object.entries(template.bindings).reduce(
    (lookup, [key, binding]) => {
      if (!isEmptyBinding(binding)) {
        lookup.add(key);
      }
      return lookup;
    },
    new Set<string>(),
  );
  const assignedAttributes = attributes.filter((attr) =>
    assignedAttributeIds.has(attr.id || ""),
  );

  // TODO: Should be URL driven so we can link and navigation works.
  const [showPrioritiesDrawer, setShowPrioritiesDrawer] = useState(false);

  // Determine which of the catalog widget states should be shown based on the
  // current catalog setup progress and the current form state.
  const { state: setupState } = useSetupProgress({
    boundAttributeIds: assignedAttributes.map((a) => a.id),
  });

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionsMethods}
      allowAllOfACatalogType={false}
    >
      <AlertSourceSplitLayout
        step={AlertSourceStepEnum.Configure}
        mode={mode}
        alertSourceConfigId={existingSource.id}
        left={
          <>
            <Prompt
              when={formMethods.formState.isDirty}
              message={
                "Your changes have not been saved. Are you sure you want to navigate away?"
              }
            />
            <FormV2
              formMethods={formMethods}
              onSubmit={onSubmit}
              warnWhenDirty
              genericError={genericError}
              id={AlertSourceFormIds[AlertSourceStepEnum.Configure]}
            >
              <TitleInput
                name={"name"}
                formMethods={formMethods}
                placeholder="Alert source name"
                inputRef={titleInputRef}
                disabled={existingSource.alert_source.max_instances === 1}
                textCursor
              />
              <div className={"flex flex-col gap-4"}>
                <AlertSourceTitleDescription
                  scopeWithExpressions={scopeWithExpressions}
                  resources={resources}
                />
                {setupState ? (
                  <CatalogSetupWidget setupState={setupState} />
                ) : null}
                <AlertSourceAttributes
                  scopeWithExpressions={scopeWithExpressions}
                  resources={resources}
                  alertSourceConfig={existingSource}
                  previewAlerts={previewAlerts?.alerts ?? []}
                  alertSource={alertSource}
                />
                <AlertSourcePriority
                  scope={scope}
                  scopeWithExpressions={scopeWithExpressions}
                  resources={resources}
                />
              </div>
            </FormV2>
          </>
        }
        right={
          <div className={"hidden h-full lg:flex flex-col items-stretch"}>
            <FormProvider {...formMethods}>
              <AlertsPreviewSplit
                alerts={previewAlerts?.alerts ?? []}
                existingSource={existingSource}
                resources={catalogResources}
                schema={schema}
                isLoading={previewAlertsLoading}
                error={previewAlertsError}
                assignedAttributes={assignedAttributes}
              />
            </FormProvider>
          </div>
        }
      />
      <AnimatePresence>
        {showPrioritiesDrawer && (
          <PrioritiesCreateEditDrawer
            onClose={() => setShowPrioritiesDrawer(false)}
          />
        )}
      </AnimatePresence>
    </ExpressionsMethodsProvider>
  );
};

export const makeDefaultValues = (
  config: AlertSourceConfig,
  alertSchema: AlertSchema,
  priority: Priority | undefined,
): AlertSourceConfigureFormData => {
  const defaults = {
    ...config,
    attributes: alertSchema.attributes,
  } as unknown as AlertSourceConfigureFormData;

  if (config.template.priority) {
    defaults.template.priority = config.template.priority;
  } else if (priority) {
    defaults.template.priority = priorityToParamBinding(priority);
  }

  return defaults;
};

// FormData is both an alert source and alert schema payload, as we let you dynamically edit your schema
// by adding attributes here
export type TemplateFormData = Omit<AlertTemplatePayload, "expressions"> & {
  expressions: ExpressionFormData[];
};
type SourceConfigFormData = Omit<
  AlertsUpdateSourceConfigRequestBody,
  "template"
> & {
  template: TemplateFormData;
};
export type AlertSourceConfigureFormData = SourceConfigFormData & AlertSchema;

export const getExpressionUsages = (
  expression: ExpressionFormData,
  formData: AlertSourceConfigureFormData,
) => {
  const containsExpressionRef = (serialisable: unknown) =>
    containsExpressionReference(expression, serialisable);

  const usedInTitle =
    formData.template.title && containsExpressionRef(formData.template.title);
  const usedInDescription =
    formData.template.description &&
    containsExpressionRef(formData.template.description);
  const usedInPriority =
    formData.template.priority &&
    containsExpressionRef(formData.template.priority);

  const attributeUsages = formData.attributes.filter((attr) => {
    const binding = attr.id && formData.template.bindings[attr.id];
    if (binding) {
      if (binding.value) {
        return containsExpressionRef(binding.value);
      } else if (binding.array_value) {
        return binding.array_value.some((v) => containsExpressionRef(v));
      }
    }
    return false;
  });

  const usages: string[] = [];
  if (usedInTitle) {
    usages.push("Title");
  }
  if (usedInDescription) {
    usages.push("Description");
  }
  if (usedInPriority) {
    usages.push("Priority");
  }

  attributeUsages.forEach((attr) => usages.push(attr.name));

  return [{ label: "Alert attributes", usages: usages }];
};

export const priorityToParamBinding = (
  priority: Priority,
): EngineParamBinding => ({
  value: {
    label: priority.name,
    literal: priority.id,
    value: priority.id,
    sort_key: priority.rank.toString(),
  },
});
