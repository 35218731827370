import { IntegrationsListObject } from "@incident-shared/integrations";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Modal,
} from "@incident-ui";
import React from "react";
import { useIntercom } from "react-use-intercom";

import { InstallIntegrationButton } from "../../common/InstallIntegrationButton";

export const ConnectToGitLabModal = ({
  onClose,
  integration,
  isReconnect,
}: {
  onClose: () => void;
  integration: IntegrationsListObject;
  isReconnect?: boolean;
}): React.ReactElement | null => {
  const { showArticle } = useIntercom();

  return (
    <Modal
      title={"Connect to GitLab"}
      isOpen
      analyticsTrackingId="connect-integration-gitlab"
      analyticsTrackingMetadata={{ integration: "GitLab" }}
      onClose={onClose}
    >
      <div className="p-4 gap-4 flex flex-col">
        <p className="text-sm text-slate-700">
          Connect to GitLab to export your actions to GitLab issues and connect
          merge requests to incidents. You can find a detailed guide to our
          GitLab integration{" "}
          <Button
            analyticsTrackingId={"connect-gitlab-integration-help"}
            theme={ButtonTheme.Link}
            onClick={() => showArticle(9072207)}
          >
            in our help center.
          </Button>
        </p>
        <Callout theme={CalloutTheme.Warning}>
          <p className="text-sm text-slate-700">
            You must have a paid GitLab account for us to be able to
            automatically pull updates from GitLab into incident.io. This is
            because we rely on{" "}
            <Button
              analyticsTrackingId={"connect-gitlab-integration-help"}
              theme={ButtonTheme.Link}
              target="_blank"
              href="https://docs.gitlab.com/ee/user/project/integrations/webhooks.html#group-webhooks"
            >
              GitLab&rsquo;s group webhooks
            </Button>{" "}
            to notify us of changes
          </p>
        </Callout>
        <div className="text-right mt-4">
          <InstallIntegrationButton
            integration={integration}
            onOAuthModal={true}
            isReconnect={isReconnect}
          />
        </div>
      </div>
    </Modal>
  );
};
