import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  IncidentUpdate,
  IncidentUpdateOrStatusPageIncidentUpdate,
  StatusPageIncidentSlim,
  StatusPageIncidentUpdate,
  StatusPageSlim,
  Stream,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { IncidentUpdateComponent } from "./IncidentUpdateComponent";
import { IncidentUpdatesHeader } from "./IncidentUpdatesHeader";

export type Update = { occurredAt: Date; id: string } & (
  | {
      incident_update: IncidentUpdate;
      status_page_incident?: never;
      status_page_incident_update?: never;
      status_page?: never;
    }
  | {
      incident_update?: never;
      status_page: StatusPageSlim;
      status_page_incident: StatusPageIncidentSlim;
      status_page_incident_update: StatusPageIncidentUpdate;
    }
);

export const IncidentUpdatesTab = ({
  incidentId,
  stream,
}: {
  incidentId: string | null;
  stream?: Stream;
}) => {
  const {
    data: { updates: rawUpdates },
    isLoading,
  } = useAPI(
    incidentId == null ? null : "incidentUpdatesListForIncident",
    { incidentId: incidentId ?? "" },
    { fallbackData: { updates: [] } },
  );

  if (incidentId == null || isLoading) {
    return <LoadingBar className="w-full h-28 mt-4" />;
  }

  return (
    <IncidentUpdatesTabRender
      incidentId={incidentId}
      updates={rawUpdates}
      stream={stream}
    />
  );
};

export const IncidentUpdatesTabRender = ({
  incidentId,
  updates: rawUpdates,
  stream,
}: {
  incidentId: string | null;
  updates: IncidentUpdateOrStatusPageIncidentUpdate[];
  stream?: Stream;
}) => {
  const updates: Update[] = _.compact(
    rawUpdates.map(
      ({
        incident_update,
        status_page,
        status_page_incident,
        status_page_incident_update,
      }) => {
        if (incident_update) {
          if (
            incident_update.message ||
            incident_update.new_severity?.id !==
              incident_update.previous_severity?.id ||
            incident_update.new_incident_status.id !==
              incident_update.previous_incident_status?.id
          ) {
            return {
              id: incident_update.id,
              occurredAt: incident_update.created_at,
              incident_update,
            };
          }
        }

        if (
          status_page &&
          status_page_incident &&
          status_page_incident_update
        ) {
          return {
            id: status_page_incident_update.id,
            occurredAt: status_page_incident_update.published_at,
            status_page,
            status_page_incident,
            status_page_incident_update,
          };
        }
        return undefined;
      },
    ),
  )
    // The array from the API is sorted earliest -> newest, we want the opposite
    .reverse();

  const [initialUpdateIds, setInitialUpdateIds] = useState<string[]>([]);
  useEffect(() => {
    if (updates?.length && initialUpdateIds.length === 0) {
      setInitialUpdateIds(updates.map((x) => x.id));
    }
  }, [updates, initialUpdateIds.length]);

  const mostRecentInternalUpdate = updates.find(
    ({ incident_update }) => incident_update,
  )?.incident_update;

  return (
    <div className="pt-4">
      {/* This should always be there: all incidents have at least one internal update */}
      {mostRecentInternalUpdate && (
        <IncidentUpdatesHeader
          incidentId={incidentId}
          mostRecentUpdate={mostRecentInternalUpdate}
          stream={stream}
        />
      )}
      <AnimatePresence presenceAffectsLayout>
        {updates.map((update, idx) =>
          update ? (
            <IncidentUpdateComponent
              incidentId={incidentId}
              key={update.id}
              update={update}
              isFirst={idx === updates.length - 1}
              isStream={!!stream}
            />
          ) : null,
        )}
      </AnimatePresence>
    </div>
  );
};
