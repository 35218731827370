import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { ConfirmationDialog } from "@incident-ui";
import React from "react";
import { Action } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

type FormData = { id: string };

export const ActionDeleteModal = ({
  onClose,
  action,
}: {
  onClose: () => void;
  action: Action;
}): React.ReactElement | null => {
  const {
    trigger: deleteAction,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "actionsList",
    { incidentId: action.incident_id },
    async (apiClient, { id }: FormData) => {
      await apiClient.actionsDestroy({
        id,
      });
    },
    { onSuccess: onClose },
  );

  return (
    <ConfirmationDialog
      onCancel={onClose}
      onConfirm={() => deleteAction({ id: action.id })}
      isOpen={true}
      title={`Delete action`}
      analyticsTrackingId="delete-action"
      saving={saving}
    >
      <p>Are you sure you want to delete this action?</p>
      <p className="font-medium mt-2">{action.description}</p>
      <ErrorMessageUI message={genericError} />
    </ConfirmationDialog>
  );
};
