import {
  AnnouncementPostActionOptionParamActionTypeEnum,
  AnnouncementPostsCreateActionRequestBody,
} from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { IconEnum, ModalFooter, ToastTheme } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { SLACK_EMOJI_TO_UNICODE } from "../../../../../utils/slack";

export const AnnouncementPostActionCreateModal = ({
  onClose,
  maxRank,
  refetchPreview,
}: {
  onClose: () => void;
  maxRank: number;
  refetchPreview: () => void;
}): React.ReactElement => {
  const showToast = useToast();

  const { trigger: onSubmit, isMutating } = useAPIMutation(
    "announcementPostsListActions",
    undefined,
    async (apiClient, data: AnnouncementPostsCreateActionRequestBody) => {
      // Add the new action to the bottom of the list
      data.rank = maxRank + 1;
      await apiClient.announcementPostsCreateAction({
        createActionRequestBody: data,
      });
    },
    {
      onSuccess: () => {
        refetchPreview();
        onClose();
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Error updating announcement post configuration",
        });
      },
    },
  );

  const formMethods = useForm<AnnouncementPostsCreateActionRequestBody>();

  const { data: actionOptionData, error: errorActionOptionData } = useAPI(
    "announcementPostsListActionOptions",
    undefined,
  );

  if (errorActionOptionData) throw errorActionOptionData;

  const typeOptions: SelectOption[] = [];
  actionOptionData?.post_actions.forEach((postAction) => {
    typeOptions.push({
      label: postAction.title,
      value: postAction.action_type,
    });
  });

  const { watch, setValue } = formMethods;

  useEffect(() => {
    const subscription = watch((formState, { name }) => {
      // Ignore changes to other fields
      if (name !== "action_type") {
        return;
      }

      if (actionOptionData?.post_actions) {
        const action = actionOptionData.post_actions.find(
          (action) =>
            action.action_type ===
            (formState.action_type as unknown as AnnouncementPostActionOptionParamActionTypeEnum),
        );
        setValue("emoji", action?.emoji);
      }
    });

    return () => subscription.unsubscribe();
  }, [actionOptionData, setValue, watch]);

  const emojiOptions: SelectOption[] = [];
  Object.entries(SLACK_EMOJI_TO_UNICODE).forEach(([key, value]) => {
    emojiOptions.push({
      label: value + "  " + key,
      sort_key: key,
      value: key.slice(1, -1),
    });
  });
  // Fake slack as an emoji
  emojiOptions.push({
    label: ":slack:",
    sort_key: ":slack:",
    value: "slack",
    icon: IconEnum.Slack,
  });

  return (
    <FormModalV2
      formMethods={formMethods}
      onSubmit={onSubmit}
      title={"Add action"}
      analyticsTrackingId={"announcement-post-add-action-modal"}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonText="Save"
          confirmButtonType="submit"
          saving={isMutating}
        />
      }
    >
      <StaticSingleSelectV2
        formMethods={formMethods}
        options={typeOptions}
        label="Type"
        name="action_type"
        required
        placeholder="Select action type..."
      />
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Emoji"
        required={false}
        name="emoji"
        options={emojiOptions}
        isClearable={true}
        placeholder="Select emoji..."
      />
    </FormModalV2>
  );
};
