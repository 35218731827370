import { EngineScope, Resource } from "@incident-io/api";
import { AddEditExpressionModal } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import {
  FormDataWithExpressions,
  useExpressionsMethods,
} from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { ExpressionFixedResultType } from "@incident-shared/engine/expressions/ifelse/createDefaultExpressionFormValues";
import React from "react";

type AddExpressionsModalWrapperProps = {
  isRefAnExpression: boolean;
  reference?: string;
  resources: Resource[];
  scope: EngineScope;
  onClose: () => void;
  expressionFixedResultType?: ExpressionFixedResultType;
  insertExpression?: (expression: ExpressionFormData) => void;
  allowDelete?: boolean;
  pauseOnAddExpression?: boolean;
  // Whether or not we should filter out return types that can't be interpolated
  // into templated text
  filterNonInterpolatable?: boolean;
  elseBranchRequired?: boolean;
};

// ExpressionsModalWrapper; We use this wrapper to encapsulate the hook for the
// expressions methods, which makes its use conditional, based on the
// includeExpressions prop. This is important because not all components that
// use this modal will be wrapped by the ExpressionsProvider, and we want to be
// able to conditionally use the hook to validate the presence of the methods
export const AddExpressionsModalWrapper = (
  props: AddExpressionsModalWrapperProps,
): React.ReactElement => {
  const {
    scope,
    resources,
    reference,
    onClose,
    isRefAnExpression,
    expressionFixedResultType,
    insertExpression,
    allowDelete = true,
    pauseOnAddExpression,
    filterNonInterpolatable,
    elseBranchRequired,
  } = props;

  const { methods, allowAllOfACatalogType } = useExpressionsMethods<
    FormDataWithExpressions,
    "expressions"
  >();

  if (!methods) {
    const err = `useExpressionMethods: Expressions methods not available!
    Did you forget to wrap the ReferenceSelectorPopover component in an ExpressionsProvider?
    `;
    throw new Error(err);
  }

  const expressionsFields = methods?.fields || [];

  const expressionValue = isRefAnExpression
    ? expressionsFields?.find(
        (expr) => `expressions["${expr.reference}"]` === reference,
      )
    : undefined;

  const expressionIndex = expressionsFields?.findIndex(
    (expr) => `expressions["${expr.reference}"]` === reference,
  );

  return (
    <AddEditExpressionModal
      onClose={onClose}
      initialExpression={expressionValue}
      filterNonInterpolatable={filterNonInterpolatable}
      onAddExpression={(expr) => {
        // If we're creating an if/else expression, we
        // sometimes need to sleep here to avoid a race
        // between the form state and prosemirror's internal state.
        methods?.append(expr);
        if (pauseOnAddExpression && !!expr.operations[0].branches?.branches) {
          setTimeout(() => {
            insertExpression?.(expr);
            onClose();
          }, 1000);
        } else {
          insertExpression?.(expr);
          onClose();
        }
      }}
      onEditExpression={(expr) => {
        methods?.update(expressionIndex, expr);
        onClose();
      }}
      onDeleteExpression={
        allowDelete
          ? () => {
              methods?.remove(expressionIndex);
            }
          : undefined
      }
      scope={scope}
      resources={resources}
      analyticsTrackingContext={"workflows-v2"}
      existingExpressions={expressionsFields}
      allowAllOfACatalogType={!!allowAllOfACatalogType}
      fixedResult={expressionFixedResultType}
      hideFixedResultLabel={false}
      elseBranchRequired={elseBranchRequired}
    />
  );
};
