import {
  SECONDS_IN_A_DAY,
  SECONDS_IN_A_MINUTE,
  SECONDS_IN_AN_HOUR,
} from "@incident-shared/forms/constants";
import { Button, ButtonTheme, Icon, IconEnum } from "@incident-ui";
import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { formatDurationInSeconds } from "../../../../utils/datetime";
import { WorkflowFormData } from "../common/types";
import { WorkflowDelayModal } from "./WorkflowDelayModal";

export enum DelayUnitEnum {
  Seconds = "seconds",
  Minutes = "minutes",
  Hours = "hours",
  Days = "days",
}

export type DelayConfig = {
  delay?: string;
  delayUnit?: DelayUnitEnum;
  conditionsApplyOverDelay?: ConditionsApplyOverDelayEnum;
};

export const delayUnitOptions = [
  {
    label: "Seconds",
    value: DelayUnitEnum.Seconds,
  },
  {
    label: "Minutes",
    value: DelayUnitEnum.Minutes,
  },
  {
    label: "Hours",
    value: DelayUnitEnum.Hours,
  },
  {
    label: "Days",
    value: DelayUnitEnum.Days,
  },
];

export enum ConditionsApplyOverDelayEnum {
  Yes = "yes",
  No = "no",
}

// WorkflowDelaySection renders a modal to allow users to edit the delay
// config on a workflow.
export const WorkflowDelaySection = ({
  formMethods,
}: {
  formMethods: UseFormReturn<WorkflowFormData>;
}): React.ReactElement => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [delayForSeconds, conditionsApplyOverDelay] = formMethods.watch([
    "delay_for_seconds",
    "conditions_apply_over_delay",
  ]);

  const hasDelay = !!delayForSeconds;

  const [initialDelay, initialDelayUnit] =
    convertDelayForSecondsToUnit(delayForSeconds);

  const onChange = (config: DelayConfig) => {
    formMethods.setValue<"delay_for_seconds">(
      "delay_for_seconds",
      convertDelayToSeconds(config),
      {
        shouldDirty: true,
      },
    );
    formMethods.setValue<"conditions_apply_over_delay">(
      "conditions_apply_over_delay",
      config.conditionsApplyOverDelay === ConditionsApplyOverDelayEnum.Yes,
      { shouldDirty: true },
    );
  };

  return (
    <>
      {showEditModal ? (
        <WorkflowDelayModal
          initialValue={{
            delay: `${initialDelay}`,
            delayUnit: initialDelayUnit,
            conditionsApplyOverDelay: conditionsApplyOverDelay
              ? ConditionsApplyOverDelayEnum.Yes
              : ConditionsApplyOverDelayEnum.No,
          }}
          onClose={() => setShowEditModal(false)}
          onSubmit={(config: DelayConfig) => {
            onChange(config);
            setShowEditModal(false);
          }}
        />
      ) : null}
      <div
        className={tcx(
          "flex-center-y font-normal text-sm text-content-primary items-center justify-between",
          hasDelay ? "mb-3" : "mb-0",
        )}
      >
        <div className="flex flex-row gap-1">
          <Icon id={IconEnum.Clock} className="text-content-tertiary" />
          {hasDelay ? (
            <span>And runs...</span>
          ) : (
            <span>
              And <span className="font-medium">runs immediately</span>
            </span>
          )}
        </div>
        {!hasDelay && (
          <Button
            analyticsTrackingId="workflows-v2-once-for-edit"
            // Make it a bit darker as it's on a grey background
            className="ml-auto !text-slate-600 hover:!text-content-primary"
            onClick={() => setShowEditModal(true)}
            theme={ButtonTheme.Naked}
          >
            Edit delay
          </Button>
        )}
      </div>

      {hasDelay && (
        <div className="flex flex-row items-center justify-between text-sm rounded-[6px] border border-stroke bg-white hover:border-slate-400 p-3 mr-1 mb-1 transition">
          <button
            className="flex text-left hover:text-content-primary px-1"
            type="button"
          >
            {conditionsApplyOverDelay ? (
              <span>
                Only if the conditions match for
                <span className="font-medium">
                  {" "}
                  {formatDurationInSeconds(delayForSeconds)}
                </span>
              </span>
            ) : (
              <span>
                After{" "}
                <span className="font-medium">
                  {formatDurationInSeconds(delayForSeconds)}
                </span>
                , even if the conditions no longer match
              </span>
            )}
          </button>
          <div className="flex flex-row gap-1">
            <Button
              analyticsTrackingId="workflows-v2-edit-delay"
              theme={ButtonTheme.Naked}
              icon={IconEnum.Edit}
              // Make it a bit darker as it's on a grey background
              className="!text-slate-600 hover:!text-content-primary"
              onClick={() => setShowEditModal(true)}
              title="Edit"
            />
            <Button
              analyticsTrackingId="workflows-v2-remove-delay"
              theme={ButtonTheme.Naked}
              icon={IconEnum.Close}
              // Make it a bit darker as it's on a grey background
              className="!text-slate-600 hover:!text-content-primary"
              onClick={() =>
                onChange({
                  delay: "0",
                  conditionsApplyOverDelay: ConditionsApplyOverDelayEnum.No,
                })
              }
              title="Remove"
            />
          </div>
        </div>
      )}
    </>
  );
};

export const convertDelayForSecondsToUnit = (
  delayForSeconds?: number,
): [number, DelayUnitEnum] => {
  // If no value is set, use hours as the default unit
  if (!delayForSeconds || delayForSeconds === 0) {
    return [0, DelayUnitEnum.Hours];
  }

  // If value in seconds is divisible by seconds in a day, hour, or minute,
  // use that as the unit
  if (delayForSeconds % SECONDS_IN_A_DAY === 0) {
    return [delayForSeconds / SECONDS_IN_A_DAY, DelayUnitEnum.Days];
  }

  if (delayForSeconds % SECONDS_IN_AN_HOUR === 0) {
    return [delayForSeconds / SECONDS_IN_AN_HOUR, DelayUnitEnum.Hours];
  }

  if (delayForSeconds % SECONDS_IN_A_MINUTE === 0) {
    return [delayForSeconds / SECONDS_IN_A_MINUTE, DelayUnitEnum.Minutes];
  }

  // Otherwise, use seconds as the unit
  return [delayForSeconds, DelayUnitEnum.Seconds];
};

export const convertDelayToSeconds = (config: DelayConfig): number => {
  if (!config.delay) {
    return 0;
  }

  const intDelay = parseInt(config.delay);
  switch (config.delayUnit) {
    case DelayUnitEnum.Seconds:
      return intDelay;
    case DelayUnitEnum.Minutes:
      return intDelay * SECONDS_IN_A_MINUTE;
    case DelayUnitEnum.Hours:
      return intDelay * SECONDS_IN_AN_HOUR;
    case DelayUnitEnum.Days:
      return intDelay * SECONDS_IN_A_DAY;
    default:
      return intDelay;
  }
};
