import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { copyDocAsHTML } from "@incident-shared/postmortems";
import {
  Button,
  ContentBox,
  Loader,
  StaticSingleSelect,
  TimeZoneSelect,
} from "@incident-ui";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";

import {
  PostmortemsRenderRequestBodyDocumentProviderEnum,
  PostmortemTemplate,
} from "../../contexts/ClientContext";
import { useAPI } from "../../utils/swr";

export const IncidentsPostMortemDebugRoute = (): React.ReactElement => {
  const { id: incidentID } = useParams() as { id: string };
  const [selectedTemplate, setSelectedTemplate] =
    useState<PostmortemTemplate | null>(null);

  const [timezone, setTimezone] = useState<string | null | undefined>(null);
  const [selectedDocumentProvider, setSelectedDocumentProvider] =
    useState<PostmortemsRenderRequestBodyDocumentProviderEnum>();

  const postMortemDocRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isMarkdown =
    selectedDocumentProvider ===
    PostmortemsRenderRequestBodyDocumentProviderEnum.CopyPasteGithubWiki;

  // Load the incident
  const { data, error } = useAPI("incidentsShow", { id: incidentID });
  const incident = data?.incident;

  useEffect(() => {
    if (error) {
      console.debug({ error });
      if (error && error.status === 404) {
        // Couldn't find the incident, send the user to the 404 page!
        window.location.href = "/404";
      }
    }
  }, [error]);

  // Load templates
  const {
    data: { postmortem_templates: templates },
  } = useAPI(
    "postmortemsListTemplates",
    {},
    {
      fallbackData: { postmortem_templates: [] },
    },
  );
  useEffect(() => {
    const defaultTemplate = templates.find((t) => t.is_default);
    if (defaultTemplate && !selectedTemplate) {
      setSelectedTemplate(defaultTemplate);
    }
  }, [templates, selectedTemplate]);

  // If a template is selected, render the doc
  const { data: renderMarkdownData } = useAPI(
    selectedTemplate && incident && isMarkdown
      ? "postmortemsRenderMarkdown"
      : null,
    {
      renderMarkdownRequestBody: {
        incident_id: incident?.id ?? "",
        postmortem_template_id: selectedTemplate?.id ?? "",
        postmortem_document_options: {
          timezone: timezone || undefined,
        },
      },
    },
  );
  const { data: renderASTData } = useAPI(
    selectedTemplate && incident && !isMarkdown ? "postmortemsRender" : null,
    {
      renderRequestBody: {
        incident_id: incident?.id ?? "",
        postmortem_template_id: selectedTemplate?.id ?? "",
        postmortem_document_options: {
          timezone: timezone || undefined,
        },
        document_provider: selectedDocumentProvider,
      },
    },
  );
  const renderedDoc = renderMarkdownData?.doc || renderASTData?.doc;

  const templateOptions = templates.map((template) => ({
    label: template.name,
    value: template.id,
  }));

  const documentProviderOptions = Object.values(
    PostmortemsRenderRequestBodyDocumentProviderEnum,
  )
    .filter((documentProvider) => {
      return documentProvider.startsWith("copy_paste");
    })
    .map((documentProvider) => ({
      label: _.startCase(documentProvider.replace("copy_paste", "")),
      value: documentProvider,
    }));

  return (
    <PageWrapper title="Postmortem Debug" width={PageWidth.Medium}>
      <div className="space-y-2 max-w-xl">
        <StaticSingleSelect
          value={selectedTemplate?.id || null}
          placeholder="select a template"
          options={templateOptions}
          onChange={(selected) => {
            const selectedTemp =
              templates.find((t) => t.id === selected) || null;
            setSelectedTemplate(selectedTemp);
          }}
        />
        <TimeZoneSelect
          onChange={setTimezone}
          isClearable={true}
          placeholder="Select a timezone"
        />
        <StaticSingleSelect
          value={selectedDocumentProvider}
          placeholder={"Select a document provider"}
          options={documentProviderOptions}
          onChange={(selected) => {
            setSelectedDocumentProvider(
              selected as unknown as PostmortemsRenderRequestBodyDocumentProviderEnum,
            );
          }}
        />

        <Button
          ref={buttonRef}
          analyticsTrackingId={null}
          onClick={async () => {
            await copyDocAsHTML(postMortemDocRef, buttonRef);
          }}
        >
          Copy to clipboard
        </Button>
      </div>

      {selectedTemplate && (
        <ContentBox className="px-4">
          {renderedDoc ? (
            <div ref={postMortemDocRef}>
              {typeof renderedDoc === "string" ? (
                <div className="whitespace-pre">{renderedDoc}</div>
              ) : (
                <TemplatedTextDisplay
                  value={renderedDoc}
                  style={TemplatedTextDisplayStyle.Compact}
                />
              )}
            </div>
          ) : (
            <Loader />
          )}
        </ContentBox>
      )}
    </PageWrapper>
  );
};
