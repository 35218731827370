import { Product } from "@incident-shared/billing";
import {
  Avatar,
  Callout,
  CalloutTheme,
  IconSize,
  Loader,
  SharedToasts,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import {
  Identity,
  Settings,
  UserSlackRoleEnum as SlackRoleEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { ScopesNeededReinstallMessage } from "src/routes/legacy/ReinstallSlackRoute";
import { useAPIMutation } from "src/utils/swr";

import { AddToSlackButton } from "../../slack/AddToSlackButton";
import { SettingsSection } from "../SettingsSection";
import { UpsellNotice } from "../UpsellNotice";

type FormData = {
  enabled: boolean;
};

export const PrivateIncidentsForm = (): React.ReactElement | null => {
  const { settings } = useSettings();
  const { identity } = useIdentity();

  if (!settings) {
    return <Loader />;
  }

  return <PrivateIncidentsFormInner settings={settings} identity={identity} />;
};

const toForm = (settings: Settings): FormData => ({
  enabled: settings.misc.private_incidents_enabled,
});

const PrivateIncidentsFormInner = ({
  settings,
  identity,
}: {
  settings: Settings;
  identity: Identity;
}) => {
  const showToast = useToast();

  const formMethods = useForm<FormData>({
    defaultValues: toForm(settings),
  });

  const {
    reset,
    formState: { isDirty },
  } = formMethods;

  const mutation = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data: FormData) =>
      await apiClient.settingsUpdatePrivateIncidentsEnabled({
        updatePrivateIncidentsEnabledRequestBody: {
          private_incidents_enabled: data.enabled,
        },
      }),
    {
      onSuccess: ({ settings }) => {
        reset(toForm(settings));
        showToast(SharedToasts.SETTINGS_SAVED);
      },
    },
  );

  const slackInfo = identity.slack_info;
  if (!slackInfo) {
    // We do not support private incidents in MS Teams right now!
    return null;
  }
  const missingRequiredScopes =
    identity.slack_info?.missing_token_scopes?.filter(
      ({ scope }) => scope === "groups:write" || scope === "groups:history",
    );

  if (!identity.feature_gates.private_incidents) {
    return (
      <UpsellNotice
        analyticsId={"private-incidents-upsell-banner"}
        title={"Private incidents"}
        planName={"Pro"}
        description={
          "Private incidents allow users to collaborate on sensitive incidents through a private Slack channel and specific permissions. This feature is especially useful for those dealing with sensitive data or in regulation-heavy industries."
        }
        articleId={5947963}
      />
    );
  }

  let content: React.ReactNode;
  if (missingRequiredScopes && missingRequiredScopes.length > 0) {
    content = (
      <Callout theme={CalloutTheme.Info}>
        <div className="space-y-1">
          <ScopesNeededReinstallMessage
            feature={"Private incidents"}
            missingTokenScopes={missingRequiredScopes}
            className={"ml-1"}
          />
          <AddToSlackButton
            url={slackInfo.install_url}
            returnPath={"settings/security"}
          />
        </div>
      </Callout>
    );
  } else if (slackInfo.user_token_owner?.slack_role === SlackRoleEnum.Owner) {
    const owner = slackInfo.user_token_owner;
    content = (
      <Callout theme={CalloutTheme.Info}>
        <Avatar
          size={IconSize.Medium}
          name={owner.name}
          url={owner.avatar_url}
          className="inline mr-1"
        />{" "}
        <span className="font-semibold inline">{owner.name}</span> (the
        connected Slack Owner) will be invited to private incidents, to manage
        the channel.
      </Callout>
    );
  }

  return (
    <SettingsSection
      requiredProduct={Product.Response}
      mutation={mutation}
      formMethods={formMethods}
      enabledPath="enabled"
      isPro
      title="Private incidents"
      isDirty={
        isDirty &&
        !missingRequiredScopes?.length &&
        identity.feature_gates.private_incidents
      }
      explanation={`If enabled, when declaring an incident you'll be able to mark
      it as private. For private incidents, we'll create a private Slack channel
      (instead of a public one), and only the reporter and people that are
      specifically invited can access the information about the incident.`}
    >
      {content}
    </SettingsSection>
  );
};
