import { ControlledInputWrapper } from "@incident-shared/forms/v1/controlled";
import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import {
  CheckboxGroup,
  ContentBox,
  EmptyState,
  IconEnum,
  LoadingModal,
  Modal,
  ModalContent,
  ModalFooter,
  StaticSingleSelect,
} from "@incident-ui";
import { CheckboxSelectOption } from "@incident-ui/Checkbox/CheckboxGroup";
import {
  CurrencyInput,
  currencyValidationRules,
} from "@incident-ui/Input/CurrencyInput";
import { isWithinInterval, parse } from "date-fns";
import _ from "lodash";
import React, { useEffect } from "react";
import { FieldErrors, useForm, UseFormRegister } from "react-hook-form";
import { Country, HolidayPublicEntry } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { v4 as uuidv4 } from "uuid";

import { formatCurrency } from "../../../../utils/currency";
import { toDateObjFromLocal } from "./date-time-helpers";
import { overrideFormTypeToInterval } from "./OnCallPayOverrideModal";
import { OverrideFormType } from "./PayConfigCreateEditForm";

const MODAL_TITLE = "Import public holidays";

// We know, for example, that GBP should default to UK, and USD to US,
// even though there are some other 'correct answers'. This stops us
// looking silly and suggesting Guernsey instead.
const COMMON_CURRENCIES_TO_DEFAULT_COUNTRIES = {
  GBP: "GB",
  USD: "US",
  EUR: "FR",
};

export type PublicHolidayWindow = {
  interval: {
    start: Date;
    end: Date;
  };
  label?: string;
};

export const PublicHolidaysModal = ({
  onClose,
  onAdd,
  currency,
  windows,
  existingOverrides,
  baseRateInCents,
}: {
  onClose: () => void;
  onAdd: (overrides: OverrideFormType[]) => void;
  currency: string;
  windows: PublicHolidayWindow[];
  existingOverrides: OverrideFormType[];
  baseRateInCents: number;
}): React.ReactElement | null => {
  // First, we need to load all our countries.
  // Once we've got those, we can render our form properly.
  const {
    data: countriesData,
    isLoading: loading,
    error,
  } = useAPI("holidaysListCountries", {});
  const countries = countriesData?.countries;
  if (error) {
    throw error;
  }

  if (loading || !countries) {
    return <LoadingModal isOpen title={MODAL_TITLE} onClose={onClose} />;
  }

  const onSubmit = (
    data: PublicHolidayFormType,
    dateToLabel: { [key: string]: string },
  ) => {
    const overrides = data.selected_dates
      .filter(
        (date) =>
          // Ignore anything we've already got
          !existingOverridesIncludesHoliday(
            existingOverrides,
            toDateObjFromLocal({ date, time: "00:00", isEnd: false }),
          ),
      )
      .map((date) => ({
        key: uuidv4(),
        start_at: {
          date: date,
          time: "00:00",
          isEnd: false,
        },
        end_at: {
          date: date,
          time: "00:00",
          isEnd: true,
        },
        rate_pounds: data.rate_pounds,
        name: `${dateToLabel[date]} ${date.slice(0, 4)}`,
      }));
    onAdd(overrides);
  };

  return (
    <PublicHolidaysForm
      countries={countries}
      onClose={onClose}
      onSubmit={onSubmit}
      currency={currency}
      windows={windows}
      existingOverrides={existingOverrides}
      baseRateInCents={baseRateInCents}
    />
  );
};

type PublicHolidayFormType = {
  country_code: string;
  selected_dates: string[];
  rate_pounds: string;
};

const PublicHolidaysForm = ({
  onClose: onCloseCallback,
  onSubmit,
  currency,
  countries,
  windows,
  existingOverrides,
  baseRateInCents,
}: {
  onClose: () => void;
  onSubmit: (
    data: PublicHolidayFormType,
    dateToLabel: { [key: string]: string },
  ) => void;
  currency: string;
  countries: Country[];
  windows: PublicHolidayWindow[];
  existingOverrides: OverrideFormType[];
  baseRateInCents: number;
}): React.ReactElement | null => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<PublicHolidayFormType>({
    defaultValues: {
      country_code: COMMON_CURRENCIES_TO_DEFAULT_COUNTRIES[currency],
      selected_dates: [],
    },
  });

  const selectedCountryCode = watch("country_code");
  const selectedDates = watch("selected_dates");

  // First, we need to load all our countries.
  // Once we've got those, we can render our form properly.
  const {
    data: holidayData,
    isLoading: publicHolidaysLoading,
    error,
  } = useAPI(selectedCountryCode ? "holidaysListPublicEntries" : null, {
    countryCodes: [selectedCountryCode],
  });
  const holidayPublicEntries = holidayData?.holiday_public_entries;
  if (error) {
    throw error;
  }

  // Clear public holidays when you change country
  // this should reliably run before the hook below, as it'll take
  // us some time to get the public holiday options.
  useEffect(() => {
    setValue<"selected_dates">("selected_dates", []);
  }, [setValue, selectedCountryCode]);

  // Auto-select all public holidays when you load a country
  useEffect(() => {
    if (selectedCountryCode && holidayPublicEntries) {
      const initialValue = windows.flatMap((window) =>
        getPublicHolidayOptions(
          holidayPublicEntries,
          window,
          existingOverrides,
        ).map((x) => x.value),
      );
      setValue<"selected_dates">("selected_dates", initialValue);
    }
  }, [
    setValue,
    selectedCountryCode,
    holidayPublicEntries,
    windows,
    existingOverrides,
  ]);

  const getSortKey = (country: Country) => {
    // first, bucket into:
    // (1) is our preferred default
    // (2) is a matching currency
    // (3) else
    let bucket = "3";
    if (COMMON_CURRENCIES_TO_DEFAULT_COUNTRIES[currency] === country.code) {
      bucket = "1";
    } else if (country.currencies?.includes(currency)) {
      bucket = "2";
    }

    // Now concatenate that with the label (downcased) to get a sorted list
    return `${bucket}_${country.name.toLowerCase()}`;
  };

  const countrySelectOptions = countries.map((country) => ({
    label: country.name,
    value: country.code,
    sort_key: getSortKey(country),
  }));

  const onClose = () => {
    reset();
    onCloseCallback();
  };

  const onChangeSelectedDates = (
    dates: string[],
    holidays: HolidayPublicEntry[],
    window: PublicHolidayWindow,
  ): void => {
    // Take the currently selecteddates, remove the ones in this window, and replace them with
    // the dates provided by the onChange handler.
    const datesInWindow = getPublicHolidayOptions(
      holidays,
      window,
      existingOverrides,
    ).map((x) => x.value);
    const selectedDatesOutsideWindow = selectedDates.filter(
      (d) => !datesInWindow.includes(d),
    );

    setValue("selected_dates", [...selectedDatesOutsideWindow, ...dates]);
  };

  const dateToLabelMapping = {};
  holidayPublicEntries?.forEach(
    (holiday) =>
      (dateToLabelMapping[convertServerDateToClientFormat(holiday.date)] =
        holiday.name),
  );

  return (
    <Modal
      isOpen
      title={MODAL_TITLE}
      disableQuickClose
      onClose={onClose}
      onSubmit={handleSubmit((data) => onSubmit(data, dateToLabelMapping))}
      as={"form"}
      analyticsTrackingId={"import-bank-holidays-modal"}
    >
      <ModalContent className="space-y-4">
        {/* Choose your country */}
        <ControlledInputWrapper
          id={"country_code"}
          label="Which country's public holidays would you like to import?"
          isClearable={false}
          errors={errors}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <StaticSingleSelect
                className="mt-1"
                value={value as string}
                onChange={onChange}
                id={"country_code"}
                options={countrySelectOptions}
                placeholder="Choose a country"
                onBlur={onBlur}
              />
            );
          }}
        />
        {holidayPublicEntries && !publicHolidaysLoading && (
          <PublicHolidaySelector
            baseRateInCents={baseRateInCents}
            errors={errors}
            register={register}
            onChangeSelectedDates={onChangeSelectedDates}
            selectedDates={selectedDates}
            publicHolidays={holidayPublicEntries}
            existingOverrides={existingOverrides}
            windows={windows}
            currency={currency}
          />
        )}
      </ModalContent>
      <ModalFooter
        onClose={onClose}
        confirmButtonType="submit"
        confirmButtonText="Import"
      />
    </Modal>
  );
};

const PublicHolidaySelector = ({
  windows,
  publicHolidays,
  existingOverrides,
  errors,
  onChangeSelectedDates,
  currency,
  baseRateInCents,
  register,
  selectedDates,
}: {
  windows: PublicHolidayWindow[];
  publicHolidays: HolidayPublicEntry[];
  register: UseFormRegister<PublicHolidayFormType>;
  existingOverrides: OverrideFormType[];
  errors: FieldErrors;
  baseRateInCents: number;
  currency: string;
  onChangeSelectedDates: (
    dates: string[],
    holidays: HolidayPublicEntry[],
    window: PublicHolidayWindow,
  ) => void;
  selectedDates: string[];
}): React.ReactElement => {
  const windowsWithHolidays = windows.map((window) => {
    const options = getPublicHolidayOptions(
      publicHolidays,
      window,
      existingOverrides,
    );
    return {
      ...window,
      options: options,
    };
  });

  // if there are no public holidays in our windows, then show a 'no public holidays' empty state
  if (_.sum(windowsWithHolidays.map((w) => w.options.length)) === 0) {
    return (
      <EmptyState
        icon={IconEnum.Calendar}
        content="There are no applicable public holidays for the chosen country"
      />
    );
  }

  // if there are no public holidays in our windows, then show a 'all already imported' empty state
  if (
    _.sum(
      windowsWithHolidays.map(
        (w) => w.options.filter((x) => !x.disabled).length,
      ),
    ) === 0
  ) {
    return (
      <EmptyState
        icon={IconEnum.Calendar}
        content="All applicable public holiday overrides are already present in this pay configuration"
      />
    );
  }

  return (
    <>
      <div>
        <p className="font-medium text-content-primary text-sm mb-2">
          Which holidays would you like to import?
        </p>
        <ContentBox className="p-4">
          {windowsWithHolidays.map((window, windowIdx) => {
            if (window.options.length === 0) {
              return null;
            }
            return (
              <FormInputWrapper
                label={window.label}
                id="selected_dates"
                errors={errors}
                key={windowIdx}
              >
                <CheckboxGroup
                  className="mt-2 mb-4"
                  options={window.options}
                  onChange={(dates) =>
                    onChangeSelectedDates(dates, publicHolidays, window)
                  }
                  // We can provide the checkbox group component with values that
                  // it doesn't have options for, and it'll just ignore them (yay)
                  value={selectedDates}
                  showSelectAll
                />
              </FormInputWrapper>
            );
          })}
        </ContentBox>
      </div>
      <FormInputWrapper
        label="How much do you pay per hour on these days?"
        helptext="Note that this will override the rest of your pay configuration"
        errors={errors}
        id="rate_pounds"
      >
        <CurrencyInput
          currency={currency}
          id="rate_pounds"
          {...register("rate_pounds", {
            required: "Please provide a rate",
            validate: currencyValidationRules,
          })}
        />
        <p className="text-content-tertiary text-xs mt-2">
          {`As a reminder, your base rate for this pay configuration is ${formatCurrency(
            currency,
            baseRateInCents,
          )} per hour.`}
        </p>
      </FormInputWrapper>
    </>
  );
};

// convertServerDateToClientFormat takes the date from our holiday library,
// formatted as 2006/12/25 to our client date string 2006-12-25
const convertServerDateToClientFormat = (serverDate: string): string => {
  return serverDate.replaceAll("/", "-");
};

const parseServerDate = (serverDate: string): Date => {
  return parse(serverDate, "yyyy-MM-dd", new Date());
};

const getPublicHolidayOptions = (
  holidays: HolidayPublicEntry[],
  window: PublicHolidayWindow,
  existingOverrides: OverrideFormType[],
): CheckboxSelectOption[] => {
  return (
    holidays
      .filter((holiday) => {
        // First, is it inside our window
        const parsedDate = parse(holiday.date, "yyyy-MM-dd", new Date());

        return isWithinInterval(parsedDate, window.interval);
      })
      .map((holiday) => ({
        label: `${holiday.name} (${parseServerDate(
          holiday.date,
        ).toLocaleDateString()})`,
        value: holiday.date,
        // This is a fudge to turn our holiday date into something that will lexically sort
        // oldest first.
        sort_key: parseServerDate(holiday.date)
          .getTime()
          .toString()
          .padStart(16),
        // Disable checkboxes where they're already covered
        disabled: existingOverridesIncludesHoliday(
          existingOverrides,
          parseServerDate(holiday.date),
        ),
      })) || []
  );
};

const existingOverridesIncludesHoliday = (
  existingOverrides: OverrideFormType[],
  date: Date,
): boolean => {
  return existingOverrides.some((override) =>
    isWithinInterval(date, overrideFormTypeToInterval(override)),
  );
};
