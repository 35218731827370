import React from "react";

type MSTeamsContext = {
  tabFriendlyView?: boolean;
};

const MSTeamsContext = React.createContext<MSTeamsContext>({});

export const MSTeamsContextProvider: React.FC = ({ children }) => {
  return (
    <MSTeamsContext.Provider value={{ tabFriendlyView: true }}>
      {children}
    </MSTeamsContext.Provider>
  );
};

export const DisableMSTeamsContextProvider: React.FC = ({ children }) => {
  return (
    <MSTeamsContext.Provider value={{ tabFriendlyView: false }}>
      {children}
    </MSTeamsContext.Provider>
  );
};

export const useIsMSTeamsTabFriendlyView = (): boolean => {
  const { tabFriendlyView } = React.useContext(MSTeamsContext);
  return tabFriendlyView ?? false;
};
