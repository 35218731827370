import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Callout, CalloutTheme, ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import {
  ActionsCreateRequestBody,
  useClient,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

type Props = {
  onClose: () => void;
  isPrivateIncident: boolean;
  incidentId: string;
};

export const ActionCreateModal = ({
  incidentId,
  onClose,
  isPrivateIncident,
}: Props): React.ReactElement => {
  const formMethods = useForm<ActionsCreateRequestBody>();
  const { setError } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "actionsList",
    { incidentId },
    async (apiClient, body: ActionsCreateRequestBody) => {
      await apiClient.actionsCreate({
        createRequestBody: {
          ...body,
          incident_id: incidentId,
        },
      });
    },
    { onSuccess: onClose, setError },
  );

  const apiClient = useClient();
  return (
    <FormModalV2
      formMethods={formMethods}
      analyticsTrackingId={null}
      onClose={onClose}
      title={`Create action`}
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          onClose={onClose}
          saving={saving}
          confirmButtonType="submit"
          confirmButtonText="Create"
        />
      }
    >
      <Callout theme={CalloutTheme.Info} className="text-sm">
        This action will be posted into the incident channel.
      </Callout>

      <InputV2
        formMethods={formMethods}
        autoFocus
        name="description"
        label="Description"
        required="Please enter a description"
      />

      {/* Assignee */}
      <DynamicSingleSelectV2
        formMethods={formMethods}
        label="Owner"
        required={false}
        helptext={
          isPrivateIncident
            ? "As this is a private incident, you can only assign actions to people with access."
            : undefined
        }
        name="assignee_id"
        placeholder="Select user"
        loadOptions={getTypeaheadOptions(apiClient, TypeaheadTypeEnum.User, {
          incidentId,
        })}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.User,
        )}
      />
    </FormModalV2>
  );
};
