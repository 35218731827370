import type { SVGProps } from "react";
import * as React from "react";
const SvgMinus = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.25 10.5H3.75a.75.75 0 0 1 0-1.5h11.5a.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgMinus;
