import {
  CallParticipant,
  Incident,
  IncidentCallExternalProviderEnum,
  Stream,
} from "@incident-io/api";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonSize,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { DropdownMenuGroup } from "@incident-ui/DropdownMenu/DropdownMenu";
import { useState } from "react";

import { IncidentHeaderModal } from "../../../../routes/legacy/IncidentRoute";
import { useNavigateToModal } from "../../../../utils/query-params";
import { useAPI } from "../../../../utils/swr";
import { useClipboard } from "../../../../utils/useClipboard";
import { IncidentCallsCreateButton } from "../../../incident-calls/IncidentCallsCreateButton";
import { IncidentCallsDeleteModal } from "../../../incident-calls/IncidentCallsDeleteModal";
import { AvatarList, AvatarListClickableType, MaybeUser } from "./AvatarList";

export const IncidentCallsSection = ({
  incident,
}: {
  incident: Incident | Stream;
}) => {
  const { copyTextToClipboard } = useClipboard();
  const navigateToModal = useNavigateToModal();

  const { data } = useAPI("incidentCallsGetForLatestForIncident", {
    incidentId: incident.id,
  });

  const incidentCall = data?.incident_call;
  const activeCallSession = data?.active_call_session;

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  return (
    <div className="space-y-2 flex-col">
      <div className="flex gap-2 items-center">
        <div className="text-content-primary font-semibold">Calls</div>
        <div className="flex grow">
          {activeCallSession ? (
            <Badge icon={IconEnum.Phone} theme={BadgeTheme.Success}>
              Live
            </Badge>
          ) : (
            <Tooltip
              side="top"
              align="start"
              content={
                <div className="flex flex-col gap-2">
                  Manage calls for this incident
                </div>
              }
            />
          )}
        </div>
        {incidentCall ? (
          <>
            <Button
              theme={ButtonTheme.Secondary}
              size={ButtonSize.Small}
              icon={iconForProvider(incidentCall.external_provider)}
              title="Join call"
              href={incidentCall.call_url}
              openInNewTab
              analyticsTrackingId="join-incident-call"
            >
              Join call
            </Button>
            <DropdownMenu
              triggerButton={
                <Button
                  title="More options"
                  className="!py-1.5"
                  analyticsTrackingId="incident-call-more-options"
                  theme={ButtonTheme.Secondary}
                  icon={IconEnum.DotsHorizontal}
                  iconProps={{ size: IconSize.Small }}
                  size={ButtonSize.Small}
                />
              }
            >
              <DropdownMenuGroup>
                <DropdownMenuItem
                  onSelect={() =>
                    navigateToModal(IncidentHeaderModal.UpdateCall)
                  }
                  analyticsTrackingId={"edit-incident-call"}
                  label="Edit"
                  icon={IconEnum.Edit}
                />
                <DropdownMenuItem
                  onSelect={() => copyTextToClipboard(incidentCall.call_url)}
                  analyticsTrackingId={"copy-incident-call"}
                  label="Copy link"
                  icon={IconEnum.Copy}
                />
              </DropdownMenuGroup>
              <DropdownMenuItem
                onSelect={() => setConfirmDeleteModalOpen(true)}
                analyticsTrackingId={"delete-incident-call"}
                label="Remove"
                icon={IconEnum.Archive}
                destructive
              />
            </DropdownMenu>
            <IncidentCallsDeleteModal
              incidentCall={incidentCall}
              isOpen={confirmDeleteModalOpen}
              onClose={() => setConfirmDeleteModalOpen(false)}
            />
          </>
        ) : (
          <IncidentCallsCreateButton incidentId={incident.id} />
        )}
      </div>
      {!!activeCallSession?.participants &&
        activeCallSession.participants.length > 0 && (
          <dl className="flex-center-y justify-between">
            <dt className="text-content-tertiary">Participants</dt>
            <AvatarList
              users={activeCallSession.participants.map((p) =>
                convertToMaybeUser(p),
              )}
              modalTitle={"Call participants"}
              maxToShow={5}
              clickableType={AvatarListClickableType.OnlyOnSeeMore}
            />
          </dl>
        )}
    </div>
  );
};

const iconForProvider = (
  provider: IncidentCallExternalProviderEnum,
): IconEnum => {
  switch (provider) {
    case IncidentCallExternalProviderEnum.Zoom:
      return IconEnum.Zoom;
    case IncidentCallExternalProviderEnum.GoogleMeet:
      return IconEnum.GoogleMeet;
    case IncidentCallExternalProviderEnum.Other:
      return IconEnum.Phone;
    default:
      return IconEnum.Phone;
  }
};

const convertToMaybeUser = (participant: CallParticipant): MaybeUser => {
  const maybeUser = {} as MaybeUser;

  if (participant.user) {
    maybeUser.user = participant.user;
  } else {
    maybeUser.nonUserLabel = participant.email;
  }

  return maybeUser;
};
