import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { TimeZoneSelectV2 } from "@incident-shared/forms/v2/inputs/TimeZoneSelectV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  Icon,
  IconEnum,
  Loader,
  LoadingModal,
  ModalFooter,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { useFlags } from "launchdarkly-react-client-sdk";
import { uniq } from "lodash";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import {
  DebriefPlaceholder,
  DebriefSettings,
  GoogleWorkspaceCalendarAccessRoleEnum,
  Incident,
  PlaceholderTimeslot,
} from "src/contexts/ClientContext";
import { useAPIInfinite, useAPIMutation } from "src/utils/swr";
import { sendWarningToSentry, useDebriefName } from "src/utils/utils";

import { formatTimestampLocale } from "../../../../utils/datetime";
import { ScheduleDebriefMode } from "./ScheduleDebriefModal";

type ScheduleDebriefInPlaceholderFormData = {
  timezone: string;
  timeslot: string;
};

export const ScheduleDebriefInPlaceholderModal = ({
  incident,
  debriefSettings,
  placeholders,
  onClose,
  setMode,
}: {
  incident: Incident;
  debriefSettings: DebriefSettings;
  placeholders: DebriefPlaceholder[];
  setMode: (mode: ScheduleDebriefMode) => void;
  onClose: () => void;
}): React.ReactElement => {
  const { debriefNameLower } = useDebriefName();
  const formMethods = useForm<ScheduleDebriefInPlaceholderFormData>({
    defaultValues: {
      // Default to showing options in the user's timezone
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "debriefsListIncidentDebriefs",
    { incidentId: incident.id },
    async (client, formData: ScheduleDebriefInPlaceholderFormData) => {
      // When we parse the json string, the dates are initially parsed as strings.
      const timeslotWithStringDates = JSON.parse(formData.timeslot);

      // Parse date strings into date objects
      const timeslot = {
        ...timeslotWithStringDates,
        start: new Date(Date.parse(timeslotWithStringDates.start)),
        end: new Date(Date.parse(timeslotWithStringDates.end)),
      } as PlaceholderTimeslot;

      await client.debriefsCreateDebrief({
        createDebriefRequestBody: {
          incident_id: incident.id,
          timeslot: timeslot,
        },
      });
    },
    {
      onSuccess: onClose,
      setError: formMethods.setError,
    },
  );

  const { watch } = formMethods;
  const timezone = watch("timezone");
  const selectedTimeslot = watch("timeslot");

  const placeholdersWithoutWriteAccess = placeholders.filter((placeholder) => {
    return !(
      placeholder.calendar.access_role ===
        GoogleWorkspaceCalendarAccessRoleEnum.Writer ||
      placeholder.calendar.access_role ===
        GoogleWorkspaceCalendarAccessRoleEnum.Owner
    );
  });

  const {
    responses,
    isLoading: loadingMoreTimeslots,
    isFullyLoaded,
    error,
    loadMore: fetchMoreTimeslots,
  } = useAPIInfinite(
    "debriefsSuggestTimeslots",
    {
      incidentId: incident.id,
    },
    {
      revalidateFirstPage: true,
    },
  );

  const loadingInitialTimeslots =
    responses.length === 0 && loadingMoreTimeslots;
  const timeslots = responses.flatMap((response) => response.timeslots);
  const { debriefsDisableManualScheduling } = useFlags();

  if (error) {
    return (
      <ErrorModal
        description="We couldn't load the timeslots."
        error={error}
        onClose={onClose}
      />
    );
  }

  if (loadingInitialTimeslots) {
    return (
      <LoadingModal
        isOpen={true}
        title={`Schedule ${debriefNameLower}`}
        onClose={onClose}
      />
    );
  }

  return (
    <FormModalV2
      analyticsTrackingId="schedule-a-debrief-in-placeholder"
      formMethods={formMethods}
      saving={saving}
      onClose={onClose}
      onSubmit={(r) => {
        onSubmit(r);
        onClose();
      }}
      title={`Schedule ${debriefNameLower}`}
      genericError={genericError}
      suppressInitialAnimation={true}
      footer={
        <ModalFooter
          confirmButtonText={"Schedule"}
          confirmButtonType="submit"
          onClose={onClose}
          hideConfirmButton={false}
          disabled={selectedTimeslot === undefined}
          saving={saving}
        />
      }
    >
      {placeholdersWithoutWriteAccess.length > 0 && (
        <WriteAccessCallout
          placeholdersWithoutWriteAccess={placeholdersWithoutWriteAccess}
        />
      )}
      {debriefsDisableManualScheduling ? null : (
        <div>
          <FormHelpTextV2>
            <div className="flex items-center space-x-4">
              <div>
                We&apos;ll create the event in one of your placeholders, but you
                can always schedule it manually instead.
              </div>
              <Button
                icon={IconEnum.Edit}
                onClick={() => setMode(ScheduleDebriefMode.Default)}
                className={"min-w-[190px]"}
                analyticsTrackingId="change-schedule-debrief-mode"
              >
                Schedule manually
              </Button>
            </div>
          </FormHelpTextV2>
          <hr className="my-4" />
        </div>
      )}
      <TimeZoneSelectV2
        formMethods={formMethods}
        name={"timezone"}
        label="Which timezone would you like to view the options in?"
        inputClassName="mt-1"
      />
      <ChoosePlaceholderRadioGroup
        placeholders={placeholders}
        formMethods={formMethods}
        timezone={timezone}
        timeslots={timeslots}
        loadingMoreTimeslots={loadingMoreTimeslots}
        hasReturnedAllTimeslots={isFullyLoaded}
        fetchMoreTimeslots={fetchMoreTimeslots}
        debriefSettings={debriefSettings}
        debriefNameLower={debriefNameLower}
      />
    </FormModalV2>
  );
};

const WriteAccessCallout = ({
  placeholdersWithoutWriteAccess,
}: {
  placeholdersWithoutWriteAccess: DebriefPlaceholder[];
}): React.ReactElement => {
  // We only want to show each calendar name once, and there is likely to be
  // more than one placeholder in a calendar.
  const calendarNames = uniq(
    placeholdersWithoutWriteAccess.map(
      (placeholder) => placeholder.calendar.name,
    ),
  );

  const { showArticle } = useIntercom();
  return (
    <Callout theme={CalloutTheme.Warning}>
      <span>
        We&apos;re unable to show some options because we do not have permission
        to create events in your calendar
        {calendarNames.length > 1 && "s:"}
      </span>
      {calendarNames.map((calendarName, index) => {
        return (
          <span key={index}>
            <span className="font-semibold"> {calendarName}</span>
            {index !== calendarNames.length - 1 && ","}
          </span>
        );
      })}
      <Button
        onClick={() => showArticle(8344655)}
        theme={ButtonTheme.Link}
        analyticsTrackingId={"settings-learn-more"}
      >
        Learn more
      </Button>
    </Callout>
  );
};

const ChoosePlaceholderRadioGroup = ({
  placeholders,
  formMethods,
  timezone,
  timeslots,
  loadingMoreTimeslots,
  hasReturnedAllTimeslots,
  fetchMoreTimeslots,
  debriefSettings,
  debriefNameLower,
}: {
  placeholders: DebriefPlaceholder[];
  formMethods: UseFormReturn<ScheduleDebriefInPlaceholderFormData, unknown>;
  timezone: string;
  timeslots: PlaceholderTimeslot[];
  loadingMoreTimeslots: boolean;
  hasReturnedAllTimeslots: boolean;
  fetchMoreTimeslots: () => void;
  debriefSettings: DebriefSettings;
  debriefNameLower: string;
}): React.ReactElement => {
  const defaultDuration = debriefSettings.evaluated_duration ?? 30;

  const options: RadioButtonGroupOption[] = timeslots
    .map((timeslot) => {
      const placeholder = placeholders.find(
        (placeholder) => placeholder.id === timeslot.placeholder_id,
      );
      if (!placeholder) {
        sendWarningToSentry(
          "unreachable: placeholder not found for timeslot",
          timeslot,
        );
        return null;
      }
      return {
        label: formatTimestampLocale({
          timeZone: timezone,
          timestamp: timeslot.start,
          dateStyle: "medium",
          timeStyle: "short",
          addWeekday: true,
        }),
        description: placeholder.title + " | " + placeholder.calendar.name,
        // There's no ID so let's convert the whole timeslot object into a json string.
        value: JSON.stringify(timeslot),
      };
    })
    .filter((timeslot) => timeslot != null) as RadioButtonGroupOption[];

  return (
    <>
      {options.length > 0 ? (
        <>
          <RadioButtonGroupV2
            label="When would you like the debrief to be?"
            srLabel="When would you like the debrief to be?"
            helptext={`We'll create a ${defaultDuration} minute ${debriefNameLower} in your chosen placeholder.`}
            options={options}
            name="timeslot"
            formMethods={formMethods}
            helptextClassName="!max-w-none"
          />
          <div className="flex-center mt-2 mb-10">
            <LoadMoreTimeslotsButton
              loadingMoreTimeslots={loadingMoreTimeslots}
              hasReturnedAllTimeslots={hasReturnedAllTimeslots}
              fetchMoreTimeslots={fetchMoreTimeslots}
            />
          </div>
        </>
      ) : (
        <>
          <div className="text-slate-800 text-sm mt-1 font-medium">
            When would you like the debrief to be?
          </div>
          <EmptyState
            icon={IconEnum.Calendar}
            content="No options found within the next year"
          />
        </>
      )}
    </>
  );
};

const LoadMoreTimeslotsButton = ({
  loadingMoreTimeslots,
  hasReturnedAllTimeslots,
  fetchMoreTimeslots,
}: {
  fetchMoreTimeslots: () => void;
  loadingMoreTimeslots: boolean;
  hasReturnedAllTimeslots: boolean;
}) => {
  if (loadingMoreTimeslots) {
    return <Loader />;
  }

  if (hasReturnedAllTimeslots) {
    return (
      <div className="flex text-content-tertiary text-sm mt-1">
        No more timeslots to show
      </div>
    );
  }

  return (
    <Button
      theme={ButtonTheme.Naked}
      onClick={() => fetchMoreTimeslots()}
      analyticsTrackingId={"timeslots-show-more"}
    >
      Show more options
      <Icon id={IconEnum.ChevronDown} />
    </Button>
  );
};
