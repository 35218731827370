import {
  CatalogResourceColorEnum,
  CatalogTypeColorEnum,
  CatalogUpdateTypeRequestBodyColorEnum,
} from "@incident-io/api";

export type ColorPalette = {
  background: string;
  text: string;
  paleText: string;
  border: string;
  icon: string;
  preview: string;
  hover: string;
  groupHover: string;
  hoverBg: string;
  invertBg: string;
  contentBorder: string;
};

export enum ColorPaletteEnum {
  Slate200 = "slate-200",
  SlateOnWhite = "slate-on-white",
  Slate = "slate",
  Blue = "blue",
  Red = "red",
  Pink = "pink",
  Violet = "violet",
  Yellow = "yellow",
  Green = "green",
  Orange = "orange",
  Cyan = "cyan",
  Lime = "lime",
  Teal = "teal",
  Sky = "sky",
  Indigo = "indigo",
  Rose = "rose",
  Fuchsia = "fuchsia",
  Purple = "purple",
  Emerald = "emerald",
}

export const OnCallExcludeColorPalettes = [
  ColorPaletteEnum.SlateOnWhite,
  ColorPaletteEnum.Slate,
  ColorPaletteEnum.Slate200,
];

const ColorPaletteLookup: {
  [key in ColorPaletteEnum]: ColorPalette;
} = {
  [ColorPaletteEnum.SlateOnWhite]: {
    background: "bg-white",
    invertBg: "bg-slate-content",
    hoverBg: "hover:bg-slate-50",
    border: "border border-stroke",
    paleText: "text-slate-300",
    hover: "hover:border-slate-400",
    groupHover: "group-hover:border-slate-400",
    text: "text-slate-600",
    contentBorder: "border-slate-600",
    icon: "text-content-tertiary",
    preview: "bg-slate-400",
  },
  [ColorPaletteEnum.Slate]: {
    background: "bg-surface-secondary",
    invertBg: "bg-slate-content",
    hoverBg: "hover:bg-surface-tertiary",
    text: "text-slate-600",
    contentBorder: "border-slate-600",
    paleText: "text-slate-300",
    border: "border border-stroke",
    icon: "text-content-tertiary",
    preview: "bg-slate-400",
    hover: "hover:border-slate-400",
    groupHover: "group-hover:border-slate-400",
  },
  [ColorPaletteEnum.Slate200]: {
    background: "bg-surface-tertiary",
    invertBg: "bg-slate-content",
    hoverBg: "hover:bg-slate-300",
    text: "text-slate-600",
    contentBorder: "border-slate-600",
    paleText: "text-slate-300",
    border: "border border-stroke",
    icon: "text-content-tertiary",
    preview: "bg-slate-400",
    hover: "hover:border-slate-400",
    groupHover: "group-hover:border-slate-400",
  },
  [ColorPaletteEnum.Blue]: {
    background: "bg-blue-surface",
    invertBg: "bg-blue-content",
    hoverBg: "hover:bg-blue-200",
    text: "text-blue-content",
    contentBorder: "border-blue-content",
    paleText: "text-blue-300",
    border: "border border-blue-300",
    icon: "text-blue-content",
    preview: "bg-blue-400",
    hover: "hover:border-blue-400",
    groupHover: "group-hover:border-blue-400",
  },
  [ColorPaletteEnum.Cyan]: {
    background: "bg-cyan-50",
    invertBg: "bg-cyan-700",
    hoverBg: "hover:bg-cyan-100",
    text: "text-cyan-700",
    contentBorder: "border-cyan-700",
    paleText: "text-cyan-300",
    border: "border border-cyan-400",
    icon: "text-cyan-500",
    preview: "bg-cyan-500",
    hover: "hover:border-cyan-500",
    groupHover: "group-hover:border-cyan-500",
  },
  [ColorPaletteEnum.Red]: {
    background: "bg-alarmalade-surface",
    invertBg: "bg-alarmalade-content",
    hoverBg: "hover:bg-alarmalade-200",
    text: "text-alarmalade-500",
    contentBorder: "border-alarmalade-500",
    paleText: "text-alarmalade-300",
    border: "border border-alarmalade-300",
    icon: "text-alarmalade-500",
    preview: "bg-alarmalade-600",
    hover: "hover:border-alarmalade-600",
    groupHover: "group-hover:border-alarmalade-600",
  },
  [ColorPaletteEnum.Violet]: {
    background: "bg-violet-100",
    invertBg: "bg-violet-700",
    hoverBg: "hover:bg-violet-200",
    text: "text-violet-700",
    contentBorder: "border-violet-700",
    paleText: "text-violet-300",
    border: "border border-violet-300",
    icon: "text-violet-600",
    preview: "bg-violet-600",
    hover: "hover:border-violet-600",
    groupHover: "group-hover:border-violet-600",
  },
  [ColorPaletteEnum.Pink]: {
    background: "bg-pink-50",
    invertBg: "bg-pink-content",
    hoverBg: "hover:bg-pink-surface",
    text: "text-pink-800",
    contentBorder: "border-pink-800",
    paleText: "text-pink-300",
    border: "border border-pink-300",
    icon: "text-pink-500",
    preview: "bg-pink-500",
    hover: "hover:border-pink-400",
    groupHover: "group-hover:border-pink-400",
  },
  [ColorPaletteEnum.Yellow]: {
    background: "bg-amber-surface",
    invertBg: "bg-amber-content",
    hoverBg: "hover:bg-amber-200",
    text: "text-amber-content",
    contentBorder: "border-amber-content",
    paleText: "text-amber-300",
    border: "border border-amber-300",
    icon: "text-amber-500",
    preview: "bg-amber-400",
    hover: "hover:border-amber-500",
    groupHover: "group-hover:border-amber-500",
  },
  [ColorPaletteEnum.Orange]: {
    background: "bg-orange-50",
    invertBg: "bg-orange-700",
    hoverBg: "hover:bg-orange-100",
    text: "text-orange-700",
    contentBorder: "border-orange-700",
    paleText: "text-orange-300",
    border: "border border-orange-300",
    icon: "text-orange-400",
    preview: "bg-orange-400",
    hover: "hover:border-orange-400",
    groupHover: "group-hover:border-orange-400",
  },
  [ColorPaletteEnum.Green]: {
    background: "bg-green-surface",
    invertBg: "bg-green-content",
    hoverBg: "hover:bg-green-200",
    text: "text-green-content",
    contentBorder: "border-green-content",
    paleText: "text-green-300",
    border: "border border-green-300",
    icon: "text-green-500",
    preview: "bg-green-400",
    hover: "hover:border-green-400",
    groupHover: "group-hover:border-green-400",
  },
  [ColorPaletteEnum.Lime]: {
    background: "bg-lime-50",
    invertBg: "bg-lime-700",
    hoverBg: "hover:bg-lime-100",
    text: "text-lime-700",
    contentBorder: "border-lime-700",
    paleText: "text-lime-300",
    border: "border border-lime-300",
    icon: "text-lime-500",
    preview: "bg-lime-500",
    hover: "hover:border-lime-400",
    groupHover: "group-hover:border-lime-400",
  },
  [ColorPaletteEnum.Teal]: {
    background: "bg-teal-50",
    invertBg: "bg-teal-700",
    hoverBg: "hover:bg-teal-100",
    text: "text-teal-700",
    contentBorder: "border-teal-700",
    paleText: "text-teal-300",
    border: "border border-teal-300",
    icon: "text-teal-500",
    preview: "bg-teal-500",
    hover: "hover:border-teal-400",
    groupHover: "group-hover:border-teal-400",
  },
  [ColorPaletteEnum.Sky]: {
    background: "bg-sky-50",
    invertBg: "bg-sky-700",
    hoverBg: "hover:bg-sky-100",
    text: "text-sky-700",
    contentBorder: "border-sky-700",
    paleText: "text-sky-300",
    border: "border border-sky-300",
    icon: "text-sky-500",
    preview: "bg-sky-500",
    hover: "hover:border-sky-400",
    groupHover: "group-hover:border-sky-400",
  },
  [ColorPaletteEnum.Indigo]: {
    background: "bg-indigo-50",
    invertBg: "bg-indigo-700",
    hoverBg: "hover:bg-indigo-100",
    text: "text-indigo-700",
    contentBorder: "border-indigo-700",
    paleText: "text-indigo-300",
    border: "border border-indigo-300",
    icon: "text-indigo-500",
    preview: "bg-indigo-500",
    hover: "hover:border-indigo-400",
    groupHover: "group-hover:border-indigo-400",
  },
  [ColorPaletteEnum.Rose]: {
    background: "bg-rose-50",
    invertBg: "bg-rose-700",
    hoverBg: "hover:bg-rose-100",
    text: "text-rose-700",
    contentBorder: "border-rose-700",
    paleText: "text-rose-300",
    border: "border border-rose-300",
    icon: "text-rose-500",
    preview: "bg-rose-500",
    hover: "hover:border-rose-400",
    groupHover: "group-hover:border-rose-400",
  },
  [ColorPaletteEnum.Fuchsia]: {
    background: "bg-fuchsia-50",
    invertBg: "bg-fuchsia-700",
    hoverBg: "hover:bg-fuchsia-100",
    text: "text-fuchsia-700",
    contentBorder: "border-fuchsia-700",
    paleText: "text-fuchsia-300",
    border: "border border-fuchsia-300",
    icon: "text-fuchsia-500",
    preview: "bg-fuchsia-500",
    hover: "hover:border-fuchsia-400",
    groupHover: "group-hover:border-fuchsia-400",
  },
  [ColorPaletteEnum.Purple]: {
    background: "bg-purple-surface",
    invertBg: "bg-purple-content",
    hoverBg: "hover:bg-purple-200",
    text: "text-purple-content",
    contentBorder: "border-purple-content",
    paleText: "text-purple-300",
    border: "border border-purple-300",
    icon: "text-purple-500",
    preview: "bg-purple-500",
    hover: "hover:border-purple-400",
    groupHover: "group-hover:border-purple-400",
  },
  [ColorPaletteEnum.Emerald]: {
    background: "bg-emerald-50",
    invertBg: "bg-emerald-700",
    hoverBg: "hover:bg-emerald-100",
    text: "text-emerald-700",
    contentBorder: "border-emerald-700",
    paleText: "text-emerald-300",
    border: "border border-emerald-300",
    icon: "text-emerald-500",
    preview: "bg-emerald-500",
    hover: "hover:border-emerald-400",
    groupHover: "group-hover:border-emerald-400",
  },
};

export const getColorPalette = (
  color?:
    | ColorPaletteEnum
    | CatalogTypeColorEnum
    | CatalogUpdateTypeRequestBodyColorEnum
    | CatalogResourceColorEnum,
): Readonly<ColorPalette> => {
  if (!color || !Object.keys(ColorPaletteLookup).includes(color)) {
    return ColorPaletteLookup[ColorPaletteEnum.Slate];
  }
  return ColorPaletteLookup[color];
};
