/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostmortemDocument
 */
export interface PostmortemDocument {
    /**
     * When the postmortem document was created
     * @type {Date}
     * @memberof PostmortemDocument
     */
    created_at: Date;
    /**
     * for user attached post-mortem documents, it will be empty string
     * @type {string}
     * @memberof PostmortemDocument
     */
    document_provider: string;
    /**
     * (optional) for user attached post-mortem documents
     * @type {string}
     * @memberof PostmortemDocument
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PostmortemDocument
     */
    human_readable_error: string;
    /**
     * 
     * @type {string}
     * @memberof PostmortemDocument
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostmortemDocument
     */
    incident_id: string;
    /**
     * 
     * @type {string}
     * @memberof PostmortemDocument
     */
    permalink: string;
    /**
     * 
     * @type {string}
     * @memberof PostmortemDocument
     */
    response_body: string;
    /**
     * 
     * @type {string}
     * @memberof PostmortemDocument
     */
    status: PostmortemDocumentStatusEnum;
    /**
     * (optional) for user attached post-mortem documents
     * @type {string}
     * @memberof PostmortemDocument
     */
    template_id?: string;
    /**
     * When the postmortem document was last updated
     * @type {Date}
     * @memberof PostmortemDocument
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemDocumentStatusEnum {
    Enqueued = 'enqueued',
    Failed = 'failed',
    Created = 'created',
    Review = 'review',
    Complete = 'complete'
}

export function PostmortemDocumentFromJSON(json: any): PostmortemDocument {
    return PostmortemDocumentFromJSONTyped(json, false);
}

export function PostmortemDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'document_provider': json['document_provider'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'human_readable_error': json['human_readable_error'],
        'id': json['id'],
        'incident_id': json['incident_id'],
        'permalink': json['permalink'],
        'response_body': json['response_body'],
        'status': json['status'],
        'template_id': !exists(json, 'template_id') ? undefined : json['template_id'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function PostmortemDocumentToJSON(value?: PostmortemDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'document_provider': value.document_provider,
        'external_id': value.external_id,
        'human_readable_error': value.human_readable_error,
        'id': value.id,
        'incident_id': value.incident_id,
        'permalink': value.permalink,
        'response_body': value.response_body,
        'status': value.status,
        'template_id': value.template_id,
        'updated_at': (value.updated_at.toISOString()),
    };
}

