import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Icon, IconEnum, Modal, ModalFooter } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import {
  FollowUpPriority,
  FollowUpsUpdatePriorityOptionRequestBody,
  Settings,
} from "src/contexts/ClientContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";

export const FollowUpPriorityEditModal = (): React.ReactElement => {
  const { id } = useParams() as { id: string };
  const navigate = useOrgAwareNavigate();
  const { settings } = useSettings();

  const {
    data,
    isLoading: priorityOptionLoading,
    error: loadPriorityOptionError,
  } = useAPI("followUpsShowPriorityOption", { id: id });
  const priorityOption = data?.priority_option;

  const onClose = () => navigate(`/settings/follow-ups`);

  if (loadPriorityOptionError) {
    const err = new Error("Failed to load priority option");
    err.cause = loadPriorityOptionError;
    return <ErrorModal onClose={onClose} error={err} />;
  }

  if (priorityOptionLoading || !priorityOption || !settings) {
    return (
      <Modal
        isOpen
        analyticsTrackingId={null}
        title="Update priority"
        disableQuickClose
        onClose={onClose}
        loading
      />
    );
  }

  return (
    <FollowUpPriorityEditModalInner
      settings={settings}
      priorityOption={priorityOption}
    />
  );
};

export const FollowUpPriorityEditModalInner = ({
  settings,
  priorityOption,
}: {
  settings: Settings;
  priorityOption: FollowUpPriority;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const alreadyDefault = priorityOption.is_default;

  const formMethods = useForm<FollowUpsUpdatePriorityOptionRequestBody>({
    defaultValues: priorityOption,
  });
  const {
    setError,
    formState: { isDirty },
  } = formMethods;

  const onClose = () => navigate(`/settings/follow-ups`);

  const { mutate: setPriorityOption } = useAPI("followUpsShowPriorityOption", {
    id: priorityOption.id,
  });
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsListPriorityOptions",
    undefined,
    async (apiClient, data: FollowUpsUpdatePriorityOptionRequestBody) => {
      const res = await apiClient.followUpsUpdatePriorityOption({
        id: priorityOption.id,
        updatePriorityOptionRequestBody: data,
      });
      setPriorityOption(res);
    },
    {
      onSuccess: onClose,
      setError,
    },
  );

  return (
    <FormModalV2
      formMethods={formMethods}
      genericError={genericError}
      analyticsTrackingId="edit-follow-up-priority"
      title="Update Priority"
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          saving={saving}
          onClose={onClose}
          disabled={!isDirty}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        required="Please provide a name"
      />
      <TextareaV2
        formMethods={formMethods}
        name="description"
        label="Description"
        helptext="How would you explain this priority to someone unfamiliar with it?"
        placeholder="For medium importance follow-ups, with an SLA of X days"
        rows={2}
      />
      {(alreadyDefault && settings.misc.follow_up_priority_required && (
        <FormHelpTextV2>
          <div className="flex flex-row gap-1">
            <Icon id={IconEnum.Info} />
            This is your default priority
          </div>
        </FormHelpTextV2>
      )) || (
        <CheckboxV2
          formMethods={formMethods}
          name="is_default"
          label={"Make this your default priority"}
        />
      )}
    </FormModalV2>
  );
};
