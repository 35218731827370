import {
  CatalogUpdateEntryRequestBody,
  EngineParamBindingPayload,
} from "@incident-io/api";
import _ from "lodash";

import { FormType } from "./CatalogEntryCreateEditForm";

export const marshallEntryPayload = (
  payload: FormType,
): CatalogUpdateEntryRequestBody => {
  const { attribute_values, rank_as_str, ...rest } = payload;

  const filteredValues: {
    [key: string]: EngineParamBindingPayload;
  } = {};

  for (const property in attribute_values) {
    const binding = attribute_values[property];
    binding.array_value = binding.array_value?.filter(
      (val) => !(val.literal === "" || val.literal === undefined),
    );
    if (
      (!binding.value ||
        binding.value.literal === "" ||
        binding.value.literal === undefined) &&
      (!binding.array_value || binding.array_value.length === 0)
    ) {
      // Don't include empty bindings in the payload
      continue;
    }

    filteredValues[property] = binding;
  }

  const uniqueAliases = _.chain(rest.aliases ?? [])
    .map((x) => x.value?.trim())
    .filter((x) => x !== "")
    .filter(Boolean)
    .uniq()
    .value();

  return {
    ...rest,
    aliases: uniqueAliases,
    attribute_values: filteredValues,
    rank: parseInt(rank_as_str),
  };
};
