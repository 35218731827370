import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentFlameNoFill = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 466 624"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M176.074 623.297V539.06h110.975V624C389.668 599.645 466 507.465 466 397.47c0-89.532-63.977-215.493-138.136-254.45 9.986 34.924-4.993 80.492-24.964 76.5-7.526-1.504-8.28-13.313-9.497-32.369C290.851 147.21 286.267 75.434 213.028 0c-5.857 52.677-89.937 159.973-144.681 229.832-7.472 9.535-14.397 18.373-20.502 26.279C18.307 294.36 0 344.307 0 397.47c0 108.964 74.909 200.445 176.074 225.827Zm118.599-178.903c0 34.149-27.704 61.833-61.878 61.833-34.175 0-61.878-27.684-61.878-61.833 0-14.119 4.862-27.383 12.706-37.541 1.621-2.099 3.461-4.447 5.445-6.979 14.538-18.552 36.867-47.047 38.423-61.037 46.078 30.916 67.182 81.78 67.182 105.557Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIncidentFlameNoFill;
