import {
  BillingSettingPlanNameEnum as PlanNameEnum,
  StaffShowOrganisationResponseBody,
  StaffStartTrialRequestBody,
  StaffStartTrialRequestBodyPlanNameEnum,
} from "@incident-io/api";
import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, ConfirmationDialog } from "@incident-ui";
import { Drawer } from "@incident-ui/Drawer/Drawer";
import { addDays } from "date-fns";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { useAPIMutation } from "src/utils/swr";

import { omniLink, PRICING_DASHBOARD_ID } from "./UsefulLinks";

type ModalEnum = keyof typeof COMPONENTS;
export const TrialManagementButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [modalOpen, setModalOpen] = useState<ModalEnum | null>(null);
  const buttons: { modal: ModalEnum; buttonText: string }[] =
    data.billing_setting.plan_name === PlanNameEnum.Trial
      ? [
          { modal: "extend", buttonText: "🔜 Extend trial" },
          { modal: "downgrade", buttonText: "⏬ Downgrade to Basic" },
        ]
      : data.billing_setting.plan_name === PlanNameEnum.BasicV2
      ? [{ modal: "trial", buttonText: "🧪 Start trial" }]
      : [];

  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.ManageTrial,
  );

  if (buttons.length === 0) {
    return <span className="text-xs">This customer is on a paid plan.</span>;
  }
  if (data.is_demo) {
    return <span className="text-xs">This is a demo account.</span>;
  }
  if (data.is_sandbox) {
    return <span className="text-xs">This is an Enterprise sandbox.</span>;
  }

  const onClose = () => setModalOpen(null);
  const Component = modalOpen != null ? COMPONENTS[modalOpen] : null;

  return (
    <>
      {buttons.map(({ modal, buttonText }) => (
        <GatedButton
          analyticsTrackingId={null}
          onClick={() => setModalOpen(modal)}
          key={modal}
          {...disabledProps}
        >
          {buttonText}
        </GatedButton>
      ))}
      <AnimatePresence>
        {Component && <Component data={data} onClose={onClose} />}
      </AnimatePresence>
    </>
  );
};

const ExtendTrialForm = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<{ trial_end_at: Date }>({
    defaultValues: {
      trial_end_at: data.trial_status.end_at,
    },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "staffShowOrganisation",
    {
      organisationSlug: data.organisation_slug,
    },
    async (apiClient, formData) => {
      await apiClient.staffExtendTrial({
        organisationSlug: data.organisation_slug,
        extendTrialRequestBody: formData,
      });
    },
    { setError: formMethods.setError, onSuccess: onClose },
  );

  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.ManageTrial,
    !formMethods.formState.isDirty,
  );

  return (
    <Drawer onClose={onClose} className="p-6 space-y-6" width="medium">
      <FormV2
        formMethods={formMethods}
        onSubmit={trigger}
        saving={isMutating}
        genericError={genericError}
      >
        <h3 className="font-medium">Extend trial</h3>

        <DateTimeInputV2
          formMethods={formMethods}
          name="trial_end_at"
          label="New trial end date"
          required
          rules={{
            validate: (value) =>
              value < new Date() ? "Trials must end in the future" : undefined,
          }}
        />

        <GatedButton
          analyticsTrackingId={null}
          type="submit"
          loading={isMutating}
          {...disabledProps}
        >
          Extend
        </GatedButton>
      </FormV2>
    </Drawer>
  );
};

type TrialFormData = StaffStartTrialRequestBody;

const StartTrialForm = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<TrialFormData>({
    defaultValues: {
      plan_name: StaffStartTrialRequestBodyPlanNameEnum.ProV2,
      trial_end_at: addDays(new Date(), 14),
    },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "staffShowOrganisation",
    {
      organisationSlug: data.organisation_slug,
    },
    async (apiClient, formData) => {
      await apiClient.staffStartTrial({
        organisationSlug: data.organisation_slug,
        startTrialRequestBody: formData,
      });
    },
    { setError: formMethods.setError, onSuccess: onClose },
  );

  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.ManageTrial,
  );

  return (
    <Drawer onClose={onClose} className="p-6 space-y-6" width="medium">
      <FormV2
        formMethods={formMethods}
        onSubmit={trigger}
        saving={isMutating}
        genericError={genericError}
      >
        <div>
          <h3 className="font-medium">Start trial</h3>
          <FormHelpTextV2>
            This will:
            <ul className="list-disc list-inside">
              <li>Immediately show a trial banner in the dashboard</li>
              <li>Unlock all the features on the selected plan</li>
              <li>
                Change the Settings &rarr; Billing page to remove self-serve
                options.
              </li>
            </ul>
          </FormHelpTextV2>
        </div>

        <StaticSingleSelectV2
          formMethods={formMethods}
          name="plan_name"
          label="Plan"
          required
          options={[
            {
              value: StaffStartTrialRequestBodyPlanNameEnum.ProV2,
              label: "Pro",
            },
            {
              value: StaffStartTrialRequestBodyPlanNameEnum.EnterpriseV2,
              label: "Enterprise",
            },
          ]}
        />

        <DateTimeInputV2
          formMethods={formMethods}
          name="trial_end_at"
          label="Trial end date"
          required
          rules={{
            validate: (value) =>
              value < new Date() ? "Trials must end in the future" : undefined,
          }}
        />

        <GatedButton
          analyticsTrackingId={null}
          type="submit"
          loading={isMutating}
          {...disabledProps}
        >
          Start trial
        </GatedButton>
      </FormV2>
    </Drawer>
  );
};

const DowngradeToBasicConfirm = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const { trigger, isMutating } = useAPIMutation(
    "staffShowOrganisation",
    {
      organisationSlug: data.organisation_slug,
    },
    async (apiClient) => {
      await apiClient.staffMoveToBasic({
        organisationSlug: data.organisation_slug,
      });
    },
    { onSuccess: onClose },
  );
  const formMethods = useForm<{ confirm: boolean }>();
  const isChecked = formMethods.watch("confirm");

  return (
    <ConfirmationDialog
      title={`Downgrade ${data.organisation_name} to Basic`}
      onCancel={onClose}
      onConfirm={() => trigger({})}
      isOpen
      saving={isMutating}
      className="text-sm space-y-2"
      disabled={!isChecked}
    >
      <p>Are you sure you want to downgrade to Basic? This will:</p>
      <ul className="list-disc list-inside space-y-1">
        <li>Remove access to set up Team/Pro/Enterprise features</li>
        <li>Allow them to use our product indefinitely</li>
      </ul>

      <FormV2
        formMethods={formMethods}
        onSubmit={() => null}
        outerClassName="border-t border-stroke pt-4"
      >
        <CheckboxV2
          formMethods={formMethods}
          name="confirm"
          label={
            <>
              I have confirmed they are within the limits of Basic using the
              &lsquo;Product features&rsquo; section of the{" "}
              <Button
                theme={ButtonTheme.Unstyled}
                href={omniLink(PRICING_DASHBOARD_ID, data)}
                analyticsTrackingId={null}
                openInNewTab
                className="!text-content-primary border-b border-transparent hover:border-slate-900 transition"
              >
                pricing dashboard
              </Button>
              .
            </>
          }
          required
        />
      </FormV2>
    </ConfirmationDialog>
  );
};

const COMPONENTS = {
  extend: ExtendTrialForm,
  downgrade: DowngradeToBasicConfirm,
  trial: StartTrialForm,
};
