import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, ContentBox, Input, SharedToasts } from "@incident-ui";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { cloneDeep } from "lodash";
import React, { ChangeEvent, useState } from "react";
import {
  FieldValues,
  Path,
  useController,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { PostmortemSettings, ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { useDebriefName } from "src/utils/utils";

import { toSentenceCase } from "../../../../utils/formatting";
import { SettingsSubHeading } from "../../SettingsSubHeading";

const postmortemNameOptions = [
  { label: "Post-mortem", value: "Post-mortem", sortOrder: 1 },
  { label: "Debrief document", value: "Debrief document", sortOrder: 2 },
  { label: "After action review", value: "After action review", sortOrder: 3 },
  {
    label: "Incident post-incident document",
    value: "Incident post-incident document",
    sortOrder: 4,
  },
  {
    label: "Post-incident review",
    value: "Post-incident review",
    sortOrder: 5,
  },
  { label: "Other", value: "other", sortOrder: 6 },
];

type postmortemNameForm = PostmortemSettings & {
  postmortem_custom_name?: string;
};

export const PostmortemNameForm = ({
  postmortemSettings,
}: {
  postmortemSettings: PostmortemSettings;
}): React.ReactElement => {
  const showToast = useToast();
  const formMethods = useForm<postmortemNameForm>({
    defaultValues: {
      ...postmortemSettings,
    },
  });
  const {
    setError,
    reset,
    formState: { isDirty },
  } = formMethods;
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postmortemsSettingsShow",
    undefined,
    async (apiClient, formData: postmortemNameForm) => {
      if (!formData.postmortem_rename) {
        throw new Error("Unreachable: user submitted empty form");
      }
      const payload = cloneDeep(formData);
      payload.postmortem_rename = toSentenceCase(
        // This will never be undefined due to form validation, but typescript doesn't know that
        // and we're guarding against silently setting this to empty string above
        payload.postmortem_rename?.trim() || "",
      );

      await apiClient.postmortemsSettingsUpdatePostmortemName({
        updatePostmortemNameRequestBody: {
          postmortem_rename: payload.postmortem_rename,
        },
      });
    },
    {
      onSuccess: ({ settings }) => {
        showToast(SharedToasts.SETTINGS_SAVED);
        reset(settings);
      },
      setError,
    },
  );

  const canEdit = useIdentity().hasScope(
    ScopeNameEnum.OrganisationSettingsUpdate,
  );

  return (
    <FormV2
      onSubmit={onSubmit}
      genericError={genericError}
      saving={saving}
      formMethods={formMethods}
    >
      <SettingsSubHeading
        title="Customise post-mortem name"
        titleHeadingLevel={2}
        explanation={
          <>
            You can rename &apos;Post-mortem&apos; to your organisation&apos;s
            preferred term, which will be used across the rest of the app.
          </>
        }
      />
      <ContentBox className="p-6 flex-between">
        <PostmortemNameInput
          formMethods={formMethods}
          name="postmortem_rename"
          canEdit={canEdit}
        />
        <GatedButton
          type="submit"
          analyticsTrackingId={null}
          className="mb-6 md:mb-0 mt-4 md:mt-0"
          disabled={!isDirty}
          theme={ButtonTheme.Primary}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        >
          Save
        </GatedButton>
      </ContentBox>
    </FormV2>
  );
};

// allow caller to pass in a formmethods and name to the field
export const PostmortemNameInput = <FormData extends FieldValues>({
  name,
  formMethods,
  canEdit = true,
}: {
  name: Path<FormData>;
  formMethods: UseFormReturn<FormData>;
  canEdit?: boolean;
}) => {
  const { debriefNameLower } = useDebriefName();
  const { field } = useController({
    name,
    rules: {
      validate: (value) => {
        if (value.toLowerCase() === debriefNameLower) {
          return "Your post-mortem name must be different to your debrief name";
        }

        return !!value.trim() || "Please enter a document name";
      },
      required: "Please enter a document name",
      minLength: {
        value: 1,
        message: "Please enter a document name",
      },
      maxLength: {
        value: 30,
        message: "Document name cannot exceed 30 characters",
      },
    },
  });

  const isPresetName = !!postmortemNameOptions.find(
    ({ value }) => field.value === value,
  );

  const [selectedOption, setSelectedOption] = useState<string>(
    isPresetName ? field.value : "other",
  );

  // null means we are not in custom name mode
  // undefined means we are in custom name mode but no value has been entered
  // string means we are in custom name mode and a value has been entered
  const [customName, setCustomName] = useState<string | undefined>(
    isPresetName ? undefined : field.value,
  );

  const selectOnChange = (value: string) => {
    if (value !== "other") {
      field.onChange(value);
    }
    setCustomName(undefined);
    setSelectedOption(value);
  };

  const customNameOnChange = (value: string) => {
    setCustomName(value);
    field.onChange(value);
  };

  return (
    <FormInputWrapperV2 {...formMethods} name={name}>
      <div className="flex gap-2">
        <StaticSingleSelect
          placeholder="Select a document name"
          options={postmortemNameOptions}
          value={selectedOption}
          disabled={!canEdit}
          onChange={(value) => value && selectOnChange(value)}
          className="w-[230px]"
        />
        {selectedOption === "other" && (
          <Input
            placeholder="Enter a document name"
            disabled={!canEdit}
            className="w-[230px]"
            id="postmortem_custom_name"
            value={customName ?? undefined}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              customNameOnChange(e.target.value);
            }}
          />
        )}
      </div>
    </FormInputWrapperV2>
  );
};
