import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import {
  Modal,
  ModalContent,
  ModalFooter,
  Textarea,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import { ScheduleReport } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export const UnpublishModal = ({
  onClose,
  report,
}: {
  onClose: () => void;
  report: ScheduleReport;
}): React.ReactElement | null => {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setError,
  } = useForm<{
    unpublish_reason: string;
  }>({});

  const doClose = () => {
    reset();
    onClose();
  };

  const { trigger: unpublishReport } = useAPIMutation(
    "schedulesShowReport",
    { id: report.id },
    async (
      apiClient,
      {
        unpublish_reason,
      }: {
        unpublish_reason: string;
      },
    ) =>
      await apiClient.schedulesUnpublishReport({
        id: report.id,
        unpublishReportRequestBody: {
          unpublish_reason,
        },
      }),
    {
      setError,
      onSuccess: () => {
        toast({
          title: "Report has been unpublished",
          theme: ToastTheme.Success,
        });
        doClose();
      },
    },
  );

  return (
    <Modal
      isOpen
      title={`Delete '${report.name}'`}
      disableQuickClose
      onClose={doClose}
      onSubmit={handleSubmit(unpublishReport)}
      as={"form"}
      analyticsTrackingId={"unpublish-report"}
    >
      <ModalContent className="space-y-4">
        <div className="text-sm text-slate-700">
          <p>
            You can delete reports when there was a mistake when generating the
            report, and you want to mark the report as being invalid.
            <br />
            <br />
            Deleting will:
            <ul className={"list-disc list-outside"}>
              <li className={"ml-4"}>
                Remove the report from the reports list
              </li>
              <li className={"ml-4"}>
                Display the reason for deletion whenever anyone views the report
                from an existing permalink
              </li>
            </ul>
          </p>
        </div>
        <FormInputWrapper
          label="Reason"
          helptext="Why are you deleting this report?"
          errors={errors}
          id="unpublish_reason"
        >
          <Textarea
            id="unpublish_reason"
            placeholder="e.g. Some holidays were not included, and we have since..."
            {...register("unpublish_reason", {
              required: "Please provide a reason for deletion",
            })}
          />
        </FormInputWrapper>
      </ModalContent>
      <ModalFooter
        onClose={doClose}
        confirmButtonType="submit"
        confirmButtonText={"Delete"}
      />
    </Modal>
  );
};
