import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import {
  InputElementProps,
  parseProps,
} from "@incident-shared/forms/v2/formsv2";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { IconEnum } from "@incident-ui";
import _ from "lodash";
import { DateTime, IANAZone } from "luxon";
import { FieldValues, Path } from "react-hook-form";

export type TimezoneSelectProps = {
  fullWidth?: boolean;
};

export const SingleTimezoneSelectV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, TimezoneSelectProps>,
): React.ReactElement => {
  const timezones = Intl.supportedValuesOf("timeZone");
  const { name, wrapperProps } = parseProps(props);

  const prettyTz = _.chain(timezones)
    .filter((t) => t.includes("/"))
    .map((tz) => ({
      zone: tz,
      offset: IANAZone.create(tz).offsetName(DateTime.now().toMillis(), {
        format: "short",
      }),
      cityComponent: tz.split("/")[1].replaceAll("_", " "),
    }))
    .uniqBy("cityComponent")
    .sort((a, b) => {
      return a.cityComponent.localeCompare(b.cityComponent);
    })
    .value();

  const prettyTzOptions = [
    ...prettyTz.map((tz) => ({
      value: tz.zone,
      label: `${tz.cityComponent} (${tz.offset})`,
    })),
    {
      value: "Etc/UTC",
      label: "Coordinated Universal Time (UTC)",
    },
  ];

  const popularTimezones = {
    label: "Popular Timezones",
    options: [
      { value: "America/Los_Angeles", label: "Los Angeles (PST)" },
      { value: "America/New_York", label: "New York (EST)" },
      { value: "Europe/London", label: "London (GMT)" },
      { value: "Asia/Tokyo", label: "Tokyo (JST)" },
      { value: "Australia/Sydney", label: "Sydney (AEDT)" },
      { value: "Europe/Berlin", label: "Berlin (CET)" },
    ],
  };

  const allTimezones = {
    label: "All Timezones",
    options: prettyTzOptions.filter(
      (tz) => !popularTimezones.options.some((ptz) => ptz.value === tz.value),
    ),
  };

  const groupedOptions = [
    {
      label: "Popular Timezones",
      options: popularTimezones.options.sort((a, b) => {
        return a.label.localeCompare(b.label);
      }),
    },
  ].concat(allTimezones);

  return (
    <FormInputWrapperV2<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <PopoverSingleSelectV2
        icon={IconEnum.Globe}
        options={groupedOptions}
        name={name}
        formMethods={props.formMethods}
        disabled={props.disabled}
        disabledTooltipContent={props.disabledTooltipContent}
        required={props.required}
        fullWidth={props.fullWidth}
      />
    </FormInputWrapperV2>
  );
};
