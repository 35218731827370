import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";

export const AlertSourcePantherSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Create a new <strong>Custom Webhook Alert Destination</strong> in
        Panther
      </p>
      <SetupInfoSingleLineBlocks
        blocks={[
          {
            label: (
              <>
                In the <strong>Custom Webhook URL</strong> input, copy and paste
                the following URL
              </>
            ),
            title: "Webhook URL",
            code: `${publicApiUrl()}/v2/alert_events/panther/${
              alertSourceConfig.id
            }`,
          },
          {
            label: (
              <>
                Toggle <strong>Add custom HTTP headers</strong>, and add an{" "}
                <code>Authorization</code> header:
              </>
            ),
            title: "Authorization",
            code: `Bearer ${alertSourceConfig.secret_token}`,
          },
        ]}
      />
      <p>
        Configure your Panther workflow to use the newly created alert
        destination
      </p>
      <p>
        Click <strong>Send test alert</strong> to verify your configuration is
        working as expected
      </p>
    </SetupInfoNumberedList>
  );
};
