import { useAPI } from "../utils/swr";

export const useUserHasOnCallOnboarded = (): boolean => {
  const { data: onCallOnboardingState } = useAPI(
    "onCallOnboardingCurrentOnboardingState",
    undefined,
  );

  const keys = Object.keys(onCallOnboardingState || {});
  if (keys.length === 0) {
    return true;
  }

  return (
    keys.filter((key) => {
      if (!onCallOnboardingState) {
        return true;
      }
      const group = onCallOnboardingState[key];
      return group.onboarded;
    }).length > 0
  );
};
