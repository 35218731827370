import {
  RadioButtonGroup,
  RadioButtonGroupProps,
} from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { FieldValues, Path, useController } from "react-hook-form";

import { FormInputWrapperV2 } from "../FormInputWrapperV2";
import { InputElementProps, parseProps } from "../formsv2";

export const RadioButtonGroupV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, RadioButtonGroupProps> & {
    onValueChange?: (val: string) => void;
  },
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    RadioButtonGroupProps
  >(rest);
  const {
    // We don't want to pass the ref onwards here: a radio group
    // references multiple inputs, so we have to use a controller here rather
    // than an uncontrolled form input.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapperV2<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <RadioButtonGroup
        {...field}
        {...inputProps}
        onChange={(val: string) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapperV2>
  );
};
