import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";

export const AlertSourcePingdomSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Go to the <strong>Integrations</strong> section in Pingdom
      </p>
      <p>
        Click <strong>Add Integration</strong> and choose{" "}
        <strong>Webhook</strong>
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Then copy and paste in the <strong>Name</strong> and{" "}
            <strong>URL</strong> below
          </>
        }
        blocks={[
          {
            title: "Name",
            code: `${alertSourceConfig.name} (incident.io)`,
          },
          {
            title: "URL",
            code: `${publicApiUrl()}/v2/alert_events/pingdom/${
              alertSourceConfig.id
            }`,
          },
        ]}
      />
      <p>
        Once you&rsquo;ve saved your integration, you can use it when creating
        or editing an alert in Pingdom. After saving the alert, you can test it
        by clicking <strong>Edit Check</strong> for the check you created, and
        then click <strong>Test</strong> in the{" "}
        <strong>Connect Integrations</strong> section.
      </p>
    </SetupInfoNumberedList>
  );
};
