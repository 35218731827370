import { useInitialiseInsightsFilters } from "@incident-shared/filters/utils";
import { Loader } from "@incident-ui";
import _ from "lodash";
import { SavedViewsListContextEnum } from "src/contexts/ClientContext";
import { useQueryParams } from "src/utils/query-params";

import {
  getPostIncidentFlowOptions,
  getPostIncidentTaskConfigOptions,
} from "../../common/getInsightsSelectOptions";
import { GroupByCustomFieldDropdown } from "../../common/GroupByCustomFieldDropdown";
import { InsightsDashboardSection } from "../../common/InsightsDashboardSection";
import { InsightsPage } from "../../common/InsightsPage";
import { useCustomFieldOptions } from "../../common/useCustomFieldOptions";
import { InsightsContextProvider } from "../../context/InsightsContextProvider";
import { FieldConfig, StateConfig } from "../../context/types";
import { useInsightsContext } from "../../context/useInsightsContext";
import { ExploDashboardID } from "../dashboards";
import { SplitByPostIncidentFlow } from "./SplitByPostIncidentFlow";
import { SplitByPostIncidentTaskConfig } from "./SplitByPostIncidentTaskConfig";

export interface PostIncidentFlowDashboardStateConfig extends StateConfig {
  post_incident_flow: FieldConfig;
  post_incident_task: FieldConfig;
  group_by_custom_field: FieldConfig;
}

export const usePostIncidentFlowStateConfig = (): {
  loading: boolean;
  stateConfig: PostIncidentFlowDashboardStateConfig;
} => {
  const { postIncidentFlowOptions, loading: loadingPincFlows } =
    usePostIncidentFlowOptions();
  const { customFieldOptions, loading } = useCustomFieldOptions();
  const {
    postIncidentFlowTaskConfigOptions: taskOptions,
    loading: tasksLoading,
  } = usePostIncidentFlowTaskConfigOptions();
  return {
    loading: loading || loadingPincFlows || tasksLoading,
    stateConfig: {
      post_incident_flow: {
        options: postIncidentFlowOptions,
        default: (opts) => {
          const defaultFlow = opts.find((option) => option.label === "Default");

          return defaultFlow?.value ?? opts[0].value;
        },
      },
      post_incident_task: {
        isGetOptions: true,
        getOptions: (useState) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [pincFlow] = useState("post_incident_flow");
          return (
            taskOptions.find((option) => option.label === pincFlow)?.options ??
            []
          );
        },
      },
      group_by_custom_field: {
        options: customFieldOptions,
      },
    },
  };
};

export const PostIncidentFlowDashboard = (): React.ReactElement => {
  const { loading, stateConfig } = usePostIncidentFlowStateConfig();
  if (loading) {
    return <Loader />;
  }
  return (
    <InsightsContextProvider<PostIncidentFlowDashboardStateConfig>
      context={SavedViewsListContextEnum.InsightsV2}
      stateConfig={stateConfig}
    >
      <InsightsPage dashboard="post-incident-flow">
        <PostIncidentFlowDashboardInner />
        <PostIncidentTaskDashboard />
      </InsightsPage>
    </InsightsContextProvider>
  );
};

const PostIncidentFlowDashboardInner = () => {
  const {
    state: customField,
    setState: setCustomFieldID,
    options,
  } = useGroupByCustomFieldMethods();
  const {
    state: postIncidentFlow,
    setState: setPostIncidentFlowID,
    options: postIncidentFlowOptions,
  } = usePostIncidentFlowField();

  return (
    <InsightsDashboardSection
      title="Post-incident flow"
      description="Understand how many incidents have entered your post-incident flow and what happened to them. You can group by a custom field to compare this across teams or affected product features."
      dashboardEmbedID={ExploDashboardID.PincPostIncidentFlows}
      dashboardVariables={{
        custom_field_id: customField?.value,
        custom_field_name: customField?.label,
        post_incident_flow_id: postIncidentFlow?.value,
        post_incident_flow_name: postIncidentFlow?.label,
      }}
      controls={
        <>
          <SplitByPostIncidentFlow
            postIncidentFlowOptions={postIncidentFlowOptions}
            selectedPostIncidentFlowID={postIncidentFlow?.value ?? ""}
            setSelectedPostIncidentFlowID={setPostIncidentFlowID}
          />
          <GroupByCustomFieldDropdown
            customFieldOptions={options}
            selectedCustomFieldID={customField?.value ?? ""}
            setSelectedCustomFieldID={setCustomFieldID}
          />
        </>
      }
      initialHeight="478px"
    />
  );
};

const PostIncidentTaskDashboard = () => {
  const { state: postIncidentFlow } = usePostIncidentFlowField();
  // If there is no post-incident flow selected, don't render the post-incident task dashboard
  if (!postIncidentFlow || postIncidentFlow.value === "no_flow") {
    return null;
  }

  return <PostIncidentTaskDashboardInner />;
};

const PostIncidentTaskDashboardInner = () => {
  const {
    state: customField,
    setState: setCustomFieldID,
    options: customFieldOptions,
  } = useGroupByCustomFieldMethods();
  const {
    state: postIncidentFlow,
    setState: setPostIncidentFlowID,
    options: postIncidentFlowOptions,
  } = usePostIncidentFlowField();
  const {
    state: postIncidentTaskConfig,
    setState: setPostIncidentTask,
    options: postIncidentTaskOptions,
  } = usePostIncidentFlowTaskField();

  return (
    <InsightsDashboardSection
      title="Post-incident tasks"
      description="Identify what happens to a particular type of post-incident task, and how long it takes to complete. For example, you might want to determine which teams are quick to schedule a debrief."
      dashboardEmbedID={ExploDashboardID.PincPostIncidentTasks}
      dashboardVariables={{
        custom_field_id: customField?.value,
        custom_field_name: customField?.label,
        post_incident_task_config_id: postIncidentTaskConfig?.value,
        post_incident_task_config_name: postIncidentTaskConfig?.label,
      }}
      controls={
        <>
          <SplitByPostIncidentTaskConfig
            postIncidentTaskConfigOptions={postIncidentTaskOptions}
            selectedPostIncidentTaskConfigID={
              postIncidentTaskConfig?.value ?? ""
            }
            setSelectedPostIncidentTaskConfigID={setPostIncidentTask}
          />
          <SplitByPostIncidentFlow
            postIncidentFlowOptions={postIncidentFlowOptions}
            selectedPostIncidentFlowID={postIncidentFlow?.value ?? ""}
            setSelectedPostIncidentFlowID={setPostIncidentFlowID}
          />
          <GroupByCustomFieldDropdown
            customFieldOptions={customFieldOptions}
            selectedCustomFieldID={customField?.value ?? ""}
            setSelectedCustomFieldID={setCustomFieldID}
          />
        </>
      }
      initialHeight="940px"
    />
  );
};

export const usePostIncidentFlowTaskField = () => {
  const { useState, getOptions } =
    useInsightsContext<PostIncidentFlowDashboardStateConfig>();
  const [postIncidentTaskConfig, setPostIncidentTaskConfig] =
    useState("post_incident_task");

  const filteredTaskConfigOptions = getOptions("post_incident_task");

  const selectedPostIncidentTaskConfig = filteredTaskConfigOptions.find(
    (option) => option.value === postIncidentTaskConfig,
  );

  return {
    state: selectedPostIncidentTaskConfig,
    setState: setPostIncidentTaskConfig,
    options: filteredTaskConfigOptions,
  };
};

const useGroupByCustomFieldMethods = () => {
  const { useState, getOptions } =
    useInsightsContext<PostIncidentFlowDashboardStateConfig>();
  const [customFieldID, setCustomFieldID] = useState("group_by_custom_field");
  const options = getOptions("group_by_custom_field");
  const state = options.find((field) => field.value === customFieldID);
  return {
    state,
    setState: setCustomFieldID,
    options,
  };
};

export const usePostIncidentFlowField = () => {
  const { useState, getOptions } =
    useInsightsContext<PostIncidentFlowDashboardStateConfig>();

  const [postIncidentFlowID, setPostIncidentFlowID] =
    useState("post_incident_flow");
  const options = getOptions("post_incident_flow");
  const state = options.find((field) => field.value === postIncidentFlowID);

  return {
    state,
    setState: setPostIncidentFlowID,
    options,
  };
};

export const usePostIncidentFlowOptions = () => {
  const urlParams = useQueryParams();

  const { loading, postIncidentFlows } = useInitialiseInsightsFilters(
    urlParams.toString(),
  );

  return {
    postIncidentFlowOptions: getPostIncidentFlowOptions(postIncidentFlows).map(
      (option) => ({
        value: option.id,
        label: option.label,
      }),
    ),
    loading,
  };
};

export const usePostIncidentFlowTaskConfigOptions = () => {
  const urlParams = useQueryParams();

  const { loading, postIncidentTaskConfigs } = useInitialiseInsightsFilters(
    urlParams.toString(),
  );

  const options = getPostIncidentTaskConfigOptions(postIncidentTaskConfigs);
  const groupedOptions = _.groupBy(options, (option) => option.parent_id);

  return {
    postIncidentFlowTaskConfigOptions: Object.keys(groupedOptions).map(
      (groupName) => ({
        label: groupName,
        options: groupedOptions[groupName].map((option) => ({
          value: option.id,
          label: option.label,
          icon: option.icon,
          description: option.description,
        })),
      }),
    ),
    loading,
  };
};
