import {
  ConditionGroup,
  PolicyPolicyTypeEnum,
  RequirementsTemplate,
} from "@incident-io/api";
import { ConditionBadge } from "@incident-shared/engine/conditions";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { conditionGroupsMatch } from "@incident-shared/engine/conditions/helpers";
import {
  ConditionGroupsEditorV2,
  ConditionGroupsEditorV2Props,
} from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonSize, ButtonTheme, IconEnum } from "@incident-ui";
import { FieldValues, useController } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import {
  InputElementProps,
  parseProps,
} from "../../../@shared/forms/v2/formsv2";

type PolicyRequirementsEditorProps<TFormType extends FieldValues> =
  ConditionGroupsEditorV2Props<TFormType> & {
    policyType: PolicyPolicyTypeEnum;
    templates: RequirementsTemplate[];
  };

export const PolicyRequirementsEditor = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, PolicyRequirementsEditorProps<TFormType>>,
): React.ReactElement => {
  const { name, wrapperProps } = parseProps(props);

  const {
    field,
    // We don't want to pass the ref onwards here: this is a complex component
    // which references multiple inputs, so we have to use a controller here rather
    // than an uncontrolled form input.
  } = useController({
    name,
    rules: props.rules,
  });

  const value = (field.value ?? []) as ConditionGroup[];

  const matchingTemplate = props.templates.find((template) =>
    conditionGroupsMatch(template.condition_groups, value),
  );
  const isEmpty = value?.length === 0;

  const showConditionsEditor = !isEmpty && !matchingTemplate;

  if (showConditionsEditor) {
    return (
      <ConditionGroupsEditorV2
        boxless
        iconless
        {...props}
        wrapperClassName="max-w-full"
      />
    );
  }

  return (
    <FormInputWrapperV2<TFormType> {...wrapperProps} name={name}>
      <div
        className="flex flex-wrap items-center bg-white text-sm gap-2"
        data-testid="conditions-list"
      >
        {matchingTemplate ? (
          <div className={tcx("flex flex-col")}>
            <ConditionBadge
              subject={matchingTemplate.label}
              icon={matchingTemplate.icon as unknown as IconEnum}
              operation=""
              criteria=""
              noTooltip
              theme="slate"
              onDelete={() => field.onChange([])}
            />
          </div>
        ) : (
          <>
            {props.templates.map((template) => (
              <Button
                analyticsTrackingId={null}
                size={ButtonSize.Small}
                key={template.label}
                icon={template.icon as unknown as IconEnum}
                iconProps={{ color: ColorPaletteEnum.Blue }}
                theme={ButtonTheme.Dashed}
                onClick={() => {
                  field.onChange(template.condition_groups);
                }}
              >
                {template.label}
              </Button>
            ))}
            <ConditionGroupsEditorV2
              {...props}
              // Remove all the copy so we are just left with the 'plus' button
              label={undefined}
              helptext={undefined}
              hideIntroSentence
              hideConditionLabel
              boxless
              iconless
            />
          </>
        )}
      </div>
    </FormInputWrapperV2>
  );
};

export const ViewPolicyRequirements = ({
  conditionGroups,
  templates,
}: {
  conditionGroups: ConditionGroup[];
  templates: RequirementsTemplate[];
}): React.ReactElement => {
  const matchingTemplate = templates.find((template) =>
    conditionGroupsMatch(template.condition_groups, conditionGroups),
  );
  const isEmpty = conditionGroups?.length === 0;

  const showDefaultView = !isEmpty && !matchingTemplate;

  if (showDefaultView) {
    return (
      <ConditionGroupsList groups={conditionGroups} boxless iconless mini />
    );
  }

  return (
    <div className="flex flex-wrap items-center bg-white text-sm gap-2">
      <div className={tcx("flex flex-col")}>
        <ConditionBadge
          subject={matchingTemplate?.label || ""}
          operation=""
          criteria=""
          noTooltip
          theme="slate"
          mini
        />
      </div>
    </div>
  );
};
