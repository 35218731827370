import { CalloutTheme } from "@incident-ui";
import { Link } from "@incident-ui";
import React from "react";

export type Gate =
  | {
      type: "numeric";
      /** An undefined value signifies that the gate has no limit */
      value?: number;
      /** The feature name should be lower-case, as it's used in the middle of a sentence */
      featureNameSingular: string;
    }
  | {
      type: "boolean";
    };

type UpgradeRequiredMessageProps = {
  gate: Gate;
  featureName: string;
  calloutTheme?: CalloutTheme;
};

/**
 * This component does not include any logic to decide when it should not be
 * shown, because the parent component should be in charge of determining
 * whether this message needs to be shown or not.
 */
export const UpgradeRequiredMessage = ({
  featureName,
  gate,
}: UpgradeRequiredMessageProps): React.ReactElement => {
  let explanation = (
    <>
      Your current plan does not support <strong>{featureName}</strong>.
    </>
  );

  // Show a different message if it's a numeric gate with a non-zero value
  if (gate && gate.type === "numeric" && gate.value && gate.value > 0) {
    explanation = (
      <>
        Your current plan only supports <strong>{gate.value}</strong>{" "}
        {gate.value === 1 && gate.featureNameSingular
          ? gate.featureNameSingular
          : featureName}
        .
      </>
    );
  }

  return (
    <div>
      <span>{explanation}</span>{" "}
      <Link
        to={"/settings/billing"}
        analyticsTrackingId={"upgrade-required-message"}
        className="text-white text-sm"
      >
        Upgrade
      </Link>
    </div>
  );
};
