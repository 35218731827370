import { forwardRef, LegacyRef } from "react";

export const AppDrawerContainer = forwardRef(
  (_, ref: LegacyRef<HTMLDivElement>) => {
    return (
      <div
        className="z-20 overflow-x-hidden bg-white h-100vh shrink-0"
        ref={ref}
      />
    );
  },
);

AppDrawerContainer.displayName = "AppDrawerContainer";
