import { Icon, IconEnum, IconSize, Link } from "@incident-ui";
import _ from "lodash";
import {
  ExternalResource,
  ExternalZendeskTicket,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { AttachmentContexts } from "./IncidentAttachment";

export const ZendeskTicket = ({
  className,
  resource,
  ticket,
  context,
}: {
  className?: string;
  resource: ExternalResource;
  ticket: ExternalZendeskTicket;
  context: AttachmentContexts;
}): React.ReactElement => {
  return (
    <div className={tcx("flex", className)}>
      <Icon
        id={IconEnum.Zendesk}
        size={IconSize.Medium}
        className="text-content-tertiary mr-6 shrink-0 font-semibold mt-1"
      />
      <div>
        <div className="font-medium mb-2">
          <Link
            href={resource.permalink}
            openInNewTab
            analyticsTrackingId={null}
          >
            {ticket.label}
          </Link>
        </div>
        <div className="flex space-x-2 text-slate-700">
          {context === AttachmentContexts.RealTime ? (
            <span className="flex-center-y">
              <Icon
                id={IconEnum.Status}
                className="text-content-tertiary mr-1"
              />
              <span>{_.startCase(ticket.status)}</span>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};
