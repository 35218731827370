import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import {
  DateTimeInputV2,
  EditAsISOSuffix,
} from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { Callout, CalloutTheme, ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import {
  Incident,
  TimelineItemObject,
  TimelineItemsCreateRequestBodyItemTypeEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export type CustomEventFormType = {
  timestamp: Date;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  description: any;
};

export const CreateEditTimelineCustomEventModal = ({
  incident,
  occurredAt,
  onClose,
  mode,
  initialData,
}: {
  incident: Incident;
  occurredAt?: Date;
  onClose: () => void;
} & CreateEditFormProps<TimelineItemObject>): React.ReactElement => {
  const formMethods = useForm<CustomEventFormType>({
    defaultValues: {
      timestamp: initialData?.occured_at ?? occurredAt,
      title: initialData?.custom_event?.title,
      description: initialData?.custom_event?.description,
    },
  });
  const {
    formState: { isDirty },
  } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "timelineItemsList",
    { incidentId: incident.id },
    async (apiClient, data: CustomEventFormType) => {
      if (mode === Mode.Edit) {
        await apiClient.timelineItemsUpdate({
          id: initialData.id,
          updateRequestBody: {
            occured_at: data.timestamp,
            custom_event: {
              title: data.title,
              description: data.description,
            },
          },
        });
      } else {
        await apiClient.timelineItemsCreate({
          createRequestBody: {
            item_type: TimelineItemsCreateRequestBodyItemTypeEnum.CustomEvent,
            incident_id: incident.id,
            occured_at: data.timestamp,
            custom_event: {
              title: data.title,
              description: data.description,
            },
          },
        });
      }
    },
    {
      onSuccess: () => {
        onClose();
      },
    },
  );

  const title =
    mode === Mode.Create ? "Create custom event" : "Edit custom event";

  const dateTimeInputName = "timestamp";

  return (
    <FormModalV2
      formMethods={formMethods}
      genericError={genericError}
      analyticsTrackingId="create-edit-custom-event"
      title={title}
      disableQuickClose={false}
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          saving={saving}
          onClose={onClose}
          disabled={!isDirty}
        />
      }
    >
      {mode === Mode.Create && (
        <Callout theme={CalloutTheme.Plain}>
          If there is a particular event you want to track, you can add it to
          the timeline here.
        </Callout>
      )}
      <InputV2
        formMethods={formMethods}
        name="title"
        label="Title"
        required
        rules={{
          required: "Please provide a title for the event",
          maxLength: {
            value: 70,
            message: "Name must be 70 characters or less",
          },
        }}
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="description"
        label="Description"
        format="basic"
        helptext="You can add extra details about what happened at this point, if you like."
        includeVariables={false}
        includeExpressions={false}
      />
      <DateTimeInputV2
        name={dateTimeInputName}
        label={"Timestamp"}
        helptext="This will determine where the event appears on the timeline."
        required={true}
        formMethods={formMethods}
        className="grow"
        suffixNode={
          <EditAsISOSuffix
            id={dateTimeInputName}
            formMethods={formMethods}
            showClearButton={false}
          />
        }
      />
    </FormModalV2>
  );
};
