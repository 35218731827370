import { ErrorMessage as RHErrorMessage, Props } from "@hookform/error-message";
import React, { useEffect, useRef } from "react";
import { FieldErrors } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

export const ErrorMessageV2 = <TFieldErrors extends FieldErrors>({
  className,
  ...props
}: {
  className?: string;
} & Props<TFieldErrors, undefined>): React.ReactElement => {
  return (
    <RHErrorMessage
      {...props}
      render={({ message }: { message: string }) => (
        <ErrorMessageUIV2 className={className} message={message} />
      )}
    />
  );
};

// ErrorMessageUI is for displaying errors when you aren't using
// react-hook-form.
export const ErrorMessageUIV2 = ({
  message,
  className,
}: {
  className?: string;
  message?: string | null;
}): React.ReactElement | null => {
  const ref = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (message && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [ref, message]);

  if (!message) {
    return null;
  }

  return (
    <p
      ref={ref}
      className={tcx("text-sm text-red-500 break-words", className)}
      aria-live="assertive"
    >
      {message}
    </p>
  );
};
