/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBody
 */
export interface LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBody {
    /**
     * Unique identifier of the resource type
     * @type {string}
     * @memberof LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBody
     */
    external_resource_type: LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBodyExternalResourceTypeEnum;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBody
     */
    name?: EngineParamBindingPayload;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBody
     */
    summary?: EngineParamBindingPayload;
}

/**
* @export
* @enum {string}
*/
export enum LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBodyExternalResourceTypeEnum {
    PagerDutyIncident = 'pager_duty_incident',
    OpsgenieAlert = 'opsgenie_alert'
}

export function LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBodyFromJSON(json: any): LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBody {
    return LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBodyFromJSONTyped(json, false);
}

export function LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_resource_type': json['external_resource_type'],
        'name': !exists(json, 'name') ? undefined : EngineParamBindingPayloadFromJSON(json['name']),
        'summary': !exists(json, 'summary') ? undefined : EngineParamBindingPayloadFromJSON(json['summary']),
    };
}

export function LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBodyToJSON(value?: LegacyIncidentTriggersEvaluateIncidentTemplateForExampleResourceRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_resource_type': value.external_resource_type,
        'name': EngineParamBindingPayloadToJSON(value.name),
        'summary': EngineParamBindingPayloadToJSON(value.summary),
    };
}

