import { EngineScope, Reference, ScopeNameEnum } from "@incident-io/api";
import { EngineLiteralBadge } from "@incident-shared/engine";
import { FormInputWrapperV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxRowV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  Interpose,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import { InputType } from "@incident-ui/Input/Input";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { Path, useForm, useFormContext, UseFormReturn } from "react-hook-form";
import { filterScope, lookupInScope } from "src/utils/scope";

import { PolicyCreateEditFormData } from "../common/PolicyCreateEditForm";
import { PolicyCapsTxt, PolicyEditableSection } from "./PolicyEditableSection";
type ResponsibleUserData = Pick<
  PolicyCreateEditFormData,
  "warning_days_str" | "warning_days_enabled" | "responsible_user_references"
>;

export const PolicyResponsibleUserSection = ({
  showDrawer,
  setShowDrawer,
  scope,
}: {
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
  scope: EngineScope;
}) => {
  const formMethods = useFormContext<PolicyCreateEditFormData>();
  const { setValue } = formMethods;

  const [warning_days_str, warning_days_enabled, responsible_user_references] =
    formMethods.watch([
      "warning_days_str",
      "warning_days_enabled",
      "responsible_user_references",
    ]);

  const onSubmit = (data: ResponsibleUserData) => {
    setValue<"warning_days_str">("warning_days_str", data.warning_days_str, {
      shouldDirty: true,
    });
    setValue<"warning_days_enabled">(
      "warning_days_enabled",
      data.warning_days_enabled,
      {
        shouldDirty: true,
      },
    );
    setValue<"responsible_user_references">(
      "responsible_user_references",
      data.responsible_user_references,
      {
        shouldDirty: true,
      },
    );
    setShowDrawer(false);
  };

  return (
    <>
      <AnimatePresence>
        {showDrawer && (
          <ResponsibleUsersDrawer
            onSubmit={onSubmit}
            onClose={() => setShowDrawer(false)}
            initialData={{
              warning_days_str,
              warning_days_enabled,
              responsible_user_references,
            }}
            scope={scope}
          />
        )}
      </AnimatePresence>
      <PolicyEditableSection
        onEdit={() => setShowDrawer(true)}
        icon={IconEnum.User}
        title={
          <div className="flex flex-wrap gap-1">
            Who is <span className="text-blue-content">responsible</span> for
            resolving these violations?
          </div>
        }
        bottomContext={
          warning_days_enabled &&
          `We'll send a warning ${warning_days_str} days before a violation`
        }
      >
        {responsible_user_references.length > 0 ? (
          <>
            <div className="text-sm flex flex-wrap items-center text-slate-700 gap-2">
              <Interpose
                separator={
                  <PolicyCapsTxt className="flex-nowrap">
                    or, if not set
                  </PolicyCapsTxt>
                }
              >
                {responsible_user_references.map((ref) => {
                  const userRef = lookupInScope(scope, ref);
                  return userRef ? (
                    <EngineLiteralBadge
                      key={userRef.key}
                      label={userRef.label}
                      noTooltip
                    />
                  ) : undefined;
                })}
              </Interpose>
            </div>
          </>
        ) : (
          <EmptyState
            icon={IconEnum.User}
            content="There is no responsible user configured on this policy"
          />
        )}
      </PolicyEditableSection>
    </>
  );
};

const ResponsibleUsersDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  scope,
}: {
  onSubmit: (data: ResponsibleUserData) => void;
  onClose: () => void;
  initialData?: ResponsibleUserData;
  scope: EngineScope;
}) => {
  const formMethods = useForm<ResponsibleUserData>({
    defaultValues: initialData,
  });
  const { setValue } = formMethods;

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  const [showFallbackResponsibleUser, setShowFallbackResponsibleUser] =
    useState<boolean>(
      (initialData?.responsible_user_references?.length || 0) > 1,
    );

  const availableResponsibleUsers = filterScope(
    scope,
    (x) => x.type === "User",
  ).references;

  return (
    <Drawer width="medium" onClose={onClose}>
      <DrawerContents className="overflow-hidden">
        <DrawerTitle
          title="Responsible users"
          onClose={onClose}
          icon={IconEnum.User}
          subtitle="Choose who should be notified when a policy violation occurs"
        />
        <DrawerBody className="overflow-y-hidden">
          <FormV2
            fullHeight
            formMethods={formMethods}
            onSubmit={onSubmit}
            id="which-incidents"
          >
            <div className="flex flex-col gap-8">
              <FormInputWrapperV2
                name="responsible_user_references"
                label="Responsible individual"
                helptext="You may choose an individual who is responsible for resolving any violations for this policy. We'll notify them via Slack when a violation occurs."
                required
                className="m-0"
              >
                <div className="flex items-center gap-2">
                  <ResponsibleUserSelect
                    formMethods={formMethods}
                    name="responsible_user_references.0"
                    placeholder="Select a user"
                    availableResponsibleUsers={availableResponsibleUsers}
                  />
                  {showFallbackResponsibleUser ? (
                    <>
                      <span className="text-content-tertiary">
                        or, if not set
                      </span>
                      <ResponsibleUserSelect
                        formMethods={formMethods}
                        name="responsible_user_references.1"
                        placeholder="Select fallback user"
                        availableResponsibleUsers={availableResponsibleUsers}
                      />
                      <Button
                        analyticsTrackingId="policy-responsible-individual-fallback"
                        theme={ButtonTheme.Naked}
                        title="Hide fallback"
                        onClick={() => {
                          setValue<"responsible_user_references.1">(
                            "responsible_user_references.1",
                            "",
                          );
                          setShowFallbackResponsibleUser(false);
                        }}
                        icon={IconEnum.Close}
                      />
                    </>
                  ) : (
                    <Button
                      analyticsTrackingId="policy-responsible-individual-fallback"
                      theme={ButtonTheme.Naked}
                      onClick={() => setShowFallbackResponsibleUser(true)}
                    >
                      Add a fallback
                    </Button>
                  )}
                </div>
              </FormInputWrapperV2>

              <CheckboxRowV2
                formMethods={formMethods}
                name="warning_days_enabled"
                disabled={false}
                label="Send a warning before a violation occurs?"
                onSelectedNode={
                  <div className="flex flex-col gap-2">
                    <div className="text-content-secondary text-xs">
                      How many days before a policy violation occurs should we
                      notify the responsible user to warn them?
                    </div>
                    <div className="flex items-center">
                      <InputV2
                        formMethods={formMethods}
                        name="warning_days_str"
                        data-testid="warning-days-input"
                        type={InputType.Number}
                        className="w-[100px]"
                      />
                      <span className="ml-2">days</span>
                    </div>
                  </div>
                }
              />
            </div>
          </FormV2>
        </DrawerBody>
        <DrawerFooter className="flex gap-2 justify-end">
          <Button onClick={() => onClose()} analyticsTrackingId={null}>
            Back
          </Button>
          <GatedButton
            form="which-incidents"
            requiredScope={ScopeNameEnum.PoliciesCreate}
            type="submit"
            theme={ButtonTheme.Primary}
            analyticsTrackingId={null}
          >
            Apply
          </GatedButton>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};

const ResponsibleUserSelect = ({
  formMethods,
  availableResponsibleUsers,
  name,
  placeholder,
}: {
  formMethods: UseFormReturn<ResponsibleUserData>;
  availableResponsibleUsers: Reference[];
  name: Path<ResponsibleUserData>;
  placeholder: string;
}) => {
  return (
    <PopoverSingleSelectV2
      formMethods={formMethods}
      rules={{
        validate: (userReference) => {
          if (userReference === `incident.role["undefined"]`) {
            return `Please select a user`;
          }
          return undefined;
        },
      }}
      required
      name={name}
      options={availableResponsibleUsers.map((ref) => ({
        value: ref.key,
        label: ref.label,
        icon: IconEnum.User,
      }))}
      renderTriggerNode={({ onClick, selectedOption }) => {
        return (
          <button onClick={onClick}>
            <EngineLiteralBadge
              noTooltip
              icon={selectedOption?.icon}
              label={selectedOption?.label || placeholder}
              clickable
              suffixNode={<Icon id={IconEnum.Edit} size={IconSize.Small} />}
            />
          </button>
        );
      }}
    />
  );
};
