import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import {
  Checkbox,
  ContentBox,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import {
  CurrencyInput,
  currencyValidationRules,
} from "@incident-ui/Input/CurrencyInput";
import React from "react";
import { useForm } from "react-hook-form";
import { SchedulePayConfig } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { formatCurrency } from "../../../../utils/currency";

type PayConfigEntry = {
  payConfig: SchedulePayConfig;
  applied: boolean;
  rate_in_pounds: string;
};

export type OverrideMultiInputFormType = {
  pay_config_entries: PayConfigEntry[];
  name: string;
};

export const OverrideModalMultiPayConfig = ({
  onClose: onCloseCallback,
  onSubmit,
  payConfigs,
  date,
}: {
  onClose: () => void;
  onSubmit: (formData: OverrideMultiInputFormType, date: Date) => void;
  payConfigs: SchedulePayConfig[];
  date: Date;
}): React.ReactElement | null => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<OverrideMultiInputFormType>({
    defaultValues: {
      pay_config_entries: payConfigs.map((conf) => ({
        payConfig: conf,
        applied: true,
      })),
    },
  });

  const payConfigEntries = watch("pay_config_entries");

  const onClose = () => {
    reset();
    onCloseCallback();
  };

  return (
    <Modal
      isOpen
      title={`Add holiday rule (${date.toLocaleDateString()})`}
      disableQuickClose
      onClose={onClose}
      onSubmit={handleSubmit((data) => onSubmit(data, date))}
      as={"form"}
      analyticsTrackingId={"pay-config-multi-holiday-modal"}
    >
      <ModalContent className="space-y-4">
        <FormInputWrapper
          label="What should this rule be called?"
          errors={errors}
          id="name"
        >
          <Input
            id="name"
            placeholder="Christmas 2022"
            {...register("name", {
              required: "Please provide a name",
            })}
          />
        </FormInputWrapper>
        {payConfigEntries?.map((entry, idx) => {
          const entryIDPrefix: `pay_config_entries.${number}` = `pay_config_entries.${idx}`;
          return (
            <ContentBox
              key={entry.payConfig.id}
              className={tcx(
                "p-3 border-slate-400",
                entry.applied ? "bg-white" : "bg-surface-secondary",
              )}
            >
              <Checkbox
                className="font-medium !text-content-primary"
                checked={entry.applied}
                label={entry.payConfig.name}
                id={`${entryIDPrefix}.applied`}
                onChange={() =>
                  setValue(`${entryIDPrefix}.applied`, !entry.applied)
                }
              ></Checkbox>
              {entry.applied && (
                <FormInputWrapper
                  label="How much do you pay per hour on this day?"
                  errors={errors}
                  id={`${entryIDPrefix}.rate_in_pounds`}
                  className="mt-1"
                >
                  <CurrencyInput
                    currency={entry.payConfig.currency}
                    id={`${entryIDPrefix}.rate_in_pounds`}
                    {...register(`${entryIDPrefix}.rate_in_pounds`, {
                      validate: currencyValidationRules,
                    })}
                  />
                  <p className="text-content-tertiary text-xs mt-2">
                    {`As a reminder, your base rate for this pay configuration is ${formatCurrency(
                      entry.payConfig.currency,
                      entry.payConfig.base_rate_cents,
                    )} per hour.`}
                  </p>
                </FormInputWrapper>
              )}
            </ContentBox>
          );
        })}
      </ModalContent>
      <ModalFooter
        onClose={onClose}
        confirmButtonType="submit"
        disabled={payConfigEntries.every((entry) => !entry.applied)}
      />
    </Modal>
  );
};
