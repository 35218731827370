import {
  ScopeNameEnum,
  StatusPage,
  StatusPagePageTypeEnum,
  StatusPageUpdateSummaryAPIRequestBody,
} from "@incident-io/api";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, Heading, Txt } from "@incident-ui";
import { useForm } from "react-hook-form";
import SyntaxHighlighter from "react-syntax-highlighter";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./WidgetAPISettings.module.scss";

export const WidgetAPISettings = ({ page }: { page: StatusPage }) => {
  const formMethods = useForm<StatusPageUpdateSummaryAPIRequestBody>({
    defaultValues: {
      expose_status_summary_api: page.expose_status_summary_api,
    },
  });

  const { trigger, isMutating } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, data: StatusPageUpdateSummaryAPIRequestBody) => {
      await apiClient.statusPageUpdateSummaryAPI({
        id: page.id,
        updateSummaryAPIRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: ({ status_page }) =>
        formMethods.reset({
          expose_status_summary_api: status_page.expose_status_summary_api,
        }),
    },
  );

  const { hasScope } = useIdentity();
  const canEdit = hasScope(ScopeNameEnum.StatusPagesConfigure);

  const enabled = formMethods.watch("expose_status_summary_api");

  return (
    <FormV2
      formMethods={formMethods}
      saving={isMutating}
      onSubmit={trigger}
      innerClassName="bg-surface-secondary rounded-[6px] p-4 border border-stroke max-w-3xl space-y-0 w-full"
    >
      <div className="flex items-center gap-4 w-full">
        <div className="flex-none">
          <ToggleV2
            formMethods={formMethods}
            name="expose_status_summary_api"
            disabled={!canEdit}
          />
        </div>
        <div className="grow">
          <Heading level={2}>Widget API</Heading>
          <p className="mt-1 text-sm text-slate-700 mb-2">
            Expose your system status to embed within your website or app using
            our Widget API.
          </p>
        </div>
        <GatedButton
          type="submit"
          analyticsTrackingId={null}
          className="flex-none"
          disabled={!formMethods.formState.isDirty}
          theme={ButtonTheme.Primary}
          requiredScope={ScopeNameEnum.StatusPagesConfigure}
        >
          Save
        </GatedButton>
      </div>

      {enabled ? <Details page={page} /> : null}
    </FormV2>
  );
};

const StandalonePageDetails = ({ page }: { page: StatusPage }) => {
  return (
    <SyntaxHighlighter
      language="json"
      className={tcx(styles.json, "ml-1")}
      useInlineStyles={false}
    >
      {`{
  "page_title": "${page.name}",
  "page_url": "${page.public_url}",
  "ongoing_incidents": [
    {
      "id": "01H0QJ89CRW5BKTY9N89MSDT5S",
      "name": "Login failing for some users",
      "status": "identified", // or investigating / monitoring
      "url": "${page.public_url}incidents/01H0QJ89CRW5BKTY9N89MSDT5S",
      "last_update_at": "${new Date().toISOString()}",
      "last_update_message": "...",
      "current_worst_impact": "partial_outage", // or degraded_performance / full_outage
      "affected_components": [
        {
          "id": "01H0QJ1BXP0E76RGBYRDHKA2KQ",
          "name": "Login",
          "group_name": "Authentication" // optional
        },
      ],
    },
    ...
  ],
  "in_progress_maintenances": [
    {
      "id": "01FDAG4SAP5TYPT98WGR2N7W91",
      "name": "Database upgrade",
      "status": "maintenance_in_progress",
      "last_update_at": "${new Date().toISOString()}",
      "last_update_message": "...",
      "url": "${page.public_url}incidents/01FDAG4SAP5TYPT98WGR2N7W91",
      "affected_components": [
        ...
      ],
      "started_at": "${new Date().toISOString()}",
      "scheduled_end_at": "${new Date().toISOString()}"
    },
    ...
  ],
  "scheduled_maintenances": [
    {
      "id": "01FCNDV6P870EA6S7TK1DSYD5H",
      "name": "Essential maintenance",
      "status": "maintenance_scheduled",
      "last_update_at": "${new Date().toISOString()}",
      "last_update_message": "...",
      "url": "${page.public_url}incidents/01FCNDV6P870EA6S7TK1DSYD5H",
      "affected_components": [
        ...
      ],
      "starts_at": "${new Date().toISOString()}",
      "ends_at": "${new Date().toISOString()}"
    },
    ...
  ]
}`}
    </SyntaxHighlighter>
  );
};

const ParentPageDetails = ({ page }: { page: StatusPage }) => {
  const publicUrlEndsInSlash = page.public_url.endsWith("/");

  const subPageUrl = publicUrlEndsInSlash
    ? `${page.public_url}europe`
    : `${page.public_url}/europe`;

  return (
    <SyntaxHighlighter
      language="json"
      className={tcx(styles.json, "ml-1")}
      useInlineStyles={false}
    >
      {`{
  "subpages": [
    {
      "subpage": "europe",
      "summary": {
        "page_title": "${page.name} - Europe",
        "page_url": "${subPageUrl}",
        "ongoing_incidents": [
          {
            "id": "01H0QJ89CRW5BKTY9N89MSDT5S",
            "name": "Login failing for some users",
            "status": "identified", // or investigating / monitoring
            "url": "${subPageUrl}/incidents/01H0QJ89CRW5BKTY9N89MSDT5S",
            "last_update_at": "${new Date().toISOString()}",
            "last_update_message": "...",
            "current_worst_impact": "partial_outage", // or degraded_performance / full_outage
            "affected_components": [
              {
                "id": "01H0QJ1BXP0E76RGBYRDHKA2KQ",
                "name": "Login",
                "group_name": "Authentication" // optional
              },
            ],
          },
          ...
        ],
        "in_progress_maintenances": [
          {
            "id": "01FDAG4SAP5TYPT98WGR2N7W91",
            "name": "Database upgrade",
            "status": "maintenance_in_progress",
            "last_update_at": "${new Date().toISOString()}",
            "last_update_message": "...",
            "url": "${subPageUrl}/incidents/01FDAG4SAP5TYPT98WGR2N7W91",
            "affected_components": [
              ...
            ],
            "started_at": "${new Date().toISOString()}",
            "scheduled_end_at": "${new Date().toISOString()}"
          },
          ...
        ],
        "scheduled_maintenances": [
          {
            "id": "01FCNDV6P870EA6S7TK1DSYD5H",
            "name": "Essential maintenance",
            "status": "maintenance_scheduled",
            "last_update_at": "${new Date().toISOString()}",
            "last_update_message": "...",
            "url": "${subPageUrl}/incidents/01FCNDV6P870EA6S7TK1DSYD5H",
            "affected_components": [
              ...
            ],
            "starts_at": "${new Date().toISOString()}",
            "ends_at": "${new Date().toISOString()}"
          },
          ...
        ]
      }
    },
    ...
  ]
}`}
    </SyntaxHighlighter>
  );
};

const Details = ({ page }: { page: StatusPage }) => {
  const publicUrlEndsInSlash = page.public_url.endsWith("/");

  return (
    <div className="pt-4 text-sm space-y-2">
      <Txt>
        The Widget API is not authenticated, and returns only the current
        summary of your system status.
      </Txt>
      {page.page_type === StatusPagePageTypeEnum.Standalone ? (
        <Txt>
          It&rsquo;s hosted at{" "}
          <span className="font-medium">
            {publicUrlEndsInSlash ? page.public_url : `${page.public_url}/`}
            api/v1/summary
          </span>
          , and returns:
        </Txt>
      ) : (
        <>
          <Txt>
            It&rsquo;s hosted at{" "}
            <span className="font-medium">
              {publicUrlEndsInSlash ? page.public_url : `${page.public_url}/`}
              api/v1/summary
            </span>
            , and returns the summary of all subpages, under the key{" "}
            <span className="font-medium">subpages</span>.
          </Txt>
          <Txt>
            If you want to get the summary of a specific subpage, you can append
            the slug to the URL, e.g.{" "}
            <span className="font-medium">
              {publicUrlEndsInSlash
                ? `${page.public_url}europe/`
                : `${page.public_url}/europe/`}
              api/v1/summary
            </span>
          </Txt>
        </>
      )}
      {page.page_type === StatusPagePageTypeEnum.Standalone ? (
        <StandalonePageDetails page={page} />
      ) : (
        <ParentPageDetails page={page} />
      )}
    </div>
  );
};
