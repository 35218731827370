/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteAlertSourcePayload,
    AlertRouteAlertSourcePayloadFromJSON,
    AlertRouteAlertSourcePayloadFromJSONTyped,
    AlertRouteAlertSourcePayloadToJSON,
} from './AlertRouteAlertSourcePayload';
import {
    AlertRouteEscalationBindingPayload,
    AlertRouteEscalationBindingPayloadFromJSON,
    AlertRouteEscalationBindingPayloadFromJSONTyped,
    AlertRouteEscalationBindingPayloadToJSON,
} from './AlertRouteEscalationBindingPayload';
import {
    AlertRouteIncidentTemplatePayload,
    AlertRouteIncidentTemplatePayloadFromJSON,
    AlertRouteIncidentTemplatePayloadFromJSONTyped,
    AlertRouteIncidentTemplatePayloadToJSON,
} from './AlertRouteIncidentTemplatePayload';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';
import {
    GroupingKey,
    GroupingKeyFromJSON,
    GroupingKeyFromJSONTyped,
    GroupingKeyToJSON,
} from './GroupingKey';

/**
 * 
 * @export
 * @interface AlertRoutesUpdateAlertRouteRequestBody
 */
export interface AlertRoutesUpdateAlertRouteRequestBody {
    /**
     * Legacy field - the alert sources that will match this alert route
     * @type {Array<string>}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    alert_source_ids: Array<string>;
    /**
     * Which alert sources should this alert route match?
     * @type {Array<AlertRouteAlertSourcePayload>}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    alert_sources: Array<AlertRouteAlertSourcePayload>;
    /**
     * Should we auto cancel escalations when all alerts are resolved?
     * @type {boolean}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    auto_cancel_escalations: boolean;
    /**
     * Should triage incidents be declined when alerts are resolved?
     * @type {boolean}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    auto_decline_enabled: boolean;
    /**
     * What condition groups must be true for this alert route to fire?
     * @type {Array<ConditionGroupPayload>}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * How long should the escalation defer time be?
     * @type {number}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    defer_time_seconds: number;
    /**
     * Whether this alert route is enabled or not
     * @type {boolean}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    enabled: boolean;
    /**
     * Which escalation paths should this alert route escalate to?
     * @type {Array<AlertRouteEscalationBindingPayload>}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    escalation_bindings: Array<AlertRouteEscalationBindingPayload>;
    /**
     * The expressions used in this template
     * @type {Array<ExpressionPayload>}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    expressions?: Array<ExpressionPayload>;
    /**
     * Which attributes should this alert route use to group alerts?
     * @type {Array<GroupingKey>}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    grouping_keys: Array<GroupingKey>;
    /**
     * How large should the grouping window be?
     * @type {number}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    grouping_window_seconds: number;
    /**
     * What condition groups must be true for this alert route to create an incident?
     * @type {Array<ConditionGroupPayload>}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    incident_condition_groups: Array<ConditionGroupPayload>;
    /**
     * Whether this alert route will create incidents or not
     * @type {boolean}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    incident_enabled: boolean;
    /**
     * The name of this alert route config, for the user's reference
     * @type {string}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    name: string;
    /**
     * 
     * @type {AlertRouteIncidentTemplatePayload}
     * @memberof AlertRoutesUpdateAlertRouteRequestBody
     */
    template?: AlertRouteIncidentTemplatePayload;
}

export function AlertRoutesUpdateAlertRouteRequestBodyFromJSON(json: any): AlertRoutesUpdateAlertRouteRequestBody {
    return AlertRoutesUpdateAlertRouteRequestBodyFromJSONTyped(json, false);
}

export function AlertRoutesUpdateAlertRouteRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesUpdateAlertRouteRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_source_ids': json['alert_source_ids'],
        'alert_sources': ((json['alert_sources'] as Array<any>).map(AlertRouteAlertSourcePayloadFromJSON)),
        'auto_cancel_escalations': json['auto_cancel_escalations'],
        'auto_decline_enabled': json['auto_decline_enabled'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'defer_time_seconds': json['defer_time_seconds'],
        'enabled': json['enabled'],
        'escalation_bindings': ((json['escalation_bindings'] as Array<any>).map(AlertRouteEscalationBindingPayloadFromJSON)),
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'grouping_keys': ((json['grouping_keys'] as Array<any>).map(GroupingKeyFromJSON)),
        'grouping_window_seconds': json['grouping_window_seconds'],
        'incident_condition_groups': ((json['incident_condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'incident_enabled': json['incident_enabled'],
        'name': json['name'],
        'template': !exists(json, 'template') ? undefined : AlertRouteIncidentTemplatePayloadFromJSON(json['template']),
    };
}

export function AlertRoutesUpdateAlertRouteRequestBodyToJSON(value?: AlertRoutesUpdateAlertRouteRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_source_ids': value.alert_source_ids,
        'alert_sources': ((value.alert_sources as Array<any>).map(AlertRouteAlertSourcePayloadToJSON)),
        'auto_cancel_escalations': value.auto_cancel_escalations,
        'auto_decline_enabled': value.auto_decline_enabled,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'defer_time_seconds': value.defer_time_seconds,
        'enabled': value.enabled,
        'escalation_bindings': ((value.escalation_bindings as Array<any>).map(AlertRouteEscalationBindingPayloadToJSON)),
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'grouping_keys': ((value.grouping_keys as Array<any>).map(GroupingKeyToJSON)),
        'grouping_window_seconds': value.grouping_window_seconds,
        'incident_condition_groups': ((value.incident_condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'incident_enabled': value.incident_enabled,
        'name': value.name,
        'template': AlertRouteIncidentTemplatePayloadToJSON(value.template),
    };
}

