import {
  SlackChannelFormValue,
  SlackChannelsEditorV2,
} from "@incident-shared/forms/v2/editors/SlackChannelsEditorV2";
import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  GenericErrorMessage,
  Icon,
  IconEnum,
  Loader,
  LoadingModal,
  ModalFooter,
} from "@incident-ui";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import {
  IncidentsBuildScopeContextEnum,
  PostmortemSharesCreateTemplateRequestBody,
  PostmortemSharesUpdateTemplateRequestBody,
  PostmortemShareTemplate,
  ScopeNameEnum,
  TextNode,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useIncidentScope } from "../../../../hooks/useIncidentScope";

export const PostmortemShareTemplateCreateModal = (): React.ReactElement => {
  return <PostmortemShareTemplateCreateEditModal shareTemplate={null} />;
};

export const PostmortemShareTemplateEditModal = (): React.ReactElement => {
  const { id } = useParams();
  const {
    data: { postmortem_share_templates: shareTemplates },
    isLoading: loadingShareTemplates,
    error: errorShareTemplates,
  } = useAPI("postmortemSharesListTemplates", undefined, {
    fallbackData: { postmortem_share_templates: [] },
  });

  if (errorShareTemplates || !shareTemplates) {
    return (
      <GenericErrorMessage description="We couldn't load your post-mortem sharing templates." />
    );
  }

  if (loadingShareTemplates) {
    return <Loader />;
  }

  const shareTemplate = shareTemplates.find((t) => t.id === id);
  if (!shareTemplate) {
    throw new Error("unreachable: expect share template to exist");
  }

  return (
    <PostmortemShareTemplateCreateEditModal shareTemplate={shareTemplate} />
  );
};

type FormData = Omit<
  | PostmortemSharesUpdateTemplateRequestBody
  | PostmortemSharesCreateTemplateRequestBody,
  "content" | "description"
> & {
  content?: string;
  description?: string;
  slack_channels: SlackChannelFormValue[];
  share_to_incident_channel: boolean;
};

const PostmortemShareTemplateCreateEditModal = ({
  shareTemplate,
}: {
  shareTemplate: PostmortemShareTemplate | null;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const formDisabled = !hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const { scope, scopeLoading } = useIncidentScope(
    IncidentsBuildScopeContextEnum.FullScope,
  );

  const isEditing = !!shareTemplate;
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate(`/settings/post-mortem`);

  const formMethods = useForm<FormData>({
    defaultValues: {
      name: shareTemplate?.name ?? undefined,
      is_default: shareTemplate?.is_default ?? false,
      slack_channels: shareTemplate?.slack_channels ?? [],
      content: (shareTemplate?.content as unknown as string) ?? undefined,
      description:
        (shareTemplate?.description as unknown as string) ?? undefined,
      share_to_incident_channel:
        shareTemplate?.share_to_incident_channel ?? false,
    },
  });

  const { setError } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postmortemSharesListTemplates",
    undefined,
    async (apiClient, data: FormData) => {
      if (isEditing) {
        if (!shareTemplate) {
          throw new Error("unreachable: expect share template to exist");
        }

        await apiClient.postmortemSharesUpdateTemplate({
          id: shareTemplate.id,
          updateTemplateRequestBody: {
            name: data.name,
            is_default: data.is_default,
            content: data.content as unknown as TextNode,
            description: data.description as unknown as TextNode,
            slack_channel_ids: data.slack_channels.map((c) => c.value),
            share_to_incident_channel: data.share_to_incident_channel,
          },
        });
      } else {
        await apiClient.postmortemSharesCreateTemplate({
          createTemplateRequestBody: {
            name: data.name,
            is_default: data.is_default,
            content: data.content as unknown as TextNode,
            description: data.description as unknown as TextNode,
            slack_channel_ids: data.slack_channels.map((c) => c.value),
            share_to_incident_channel: data.share_to_incident_channel,
          },
        });
      }
    },
    {
      onSuccess: onClose,
      setError,
    },
  );

  if (scopeLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  return (
    <FormModalV2
      formMethods={formMethods}
      genericError={genericError}
      analyticsTrackingId="create-postmortem-destination"
      title={(isEditing ? "Edit" : "Create") + " post-mortem sharing template"}
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonText={isEditing ? "Save" : "Create"}
          confirmButtonType="submit"
          saving={saving}
          onClose={onClose}
          disabled={formDisabled}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        helptext="An easily identifiable name for this post-mortem share template."
        required="Please enter a name"
        placeholder="e.g. Engineering Share Template"
      />
      {(!shareTemplate?.is_default && (
        <CheckboxV2
          formMethods={formMethods}
          name={"is_default"}
          label={"Make this your default template."}
          disabled={formDisabled}
        />
      )) || (
        <FormHelpTextV2>
          <div className="flex flex-row gap-1">
            <Icon id={IconEnum.Info} />
            This is your default template
          </div>
        </FormHelpTextV2>
      )}
      <SlackChannelsEditorV2
        formMethods={formMethods}
        name="slack_channels"
        label="Slack channels"
        helptext={`Which channels to pre-select when sharing the post-mortem`}
        disallowPrivateChannels={true}
        required
      />
      <CheckboxV2
        formMethods={formMethods}
        label="Automatically include the incident channel when sharing"
        name="share_to_incident_channel"
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="content"
        label="Message header"
        helptext="Structure the message header you wish to send when sharing the post-mortem"
        format="mrkdwn"
        scope={scope}
        includeVariables={true}
        includeExpressions={false}
        multiLine={true}
        required
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="description"
        label="Share a brief summary"
        helptext="Add any text to help users fill in the brief summary"
        format="mrkdwn"
        includeVariables={false}
        includeExpressions={false}
        multiLine={true}
      />
    </FormModalV2>
  );
};
