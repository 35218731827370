import {
  AddFilterButton,
  ToggleIncludePrivateIncidentsFilter,
} from "@incident-shared/filters";
import { HeaderBar } from "@incident-shared/layout/HeaderBar/HeaderBar";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Heading,
  Icon,
  IconEnum,
} from "@incident-ui";
import { DropdownMenuGroup } from "@incident-ui/DropdownMenu/DropdownMenu";
import React from "react";
import { useLocation } from "react-router";

import { CreateOrUpdateSavedViewButtonModal } from "../../saved-views/CreateOrUpdateSavedViewButtonModal";
import { SavedViewHeading } from "../../saved-views/SavedViewHeading";
import { useDashboardGroups } from "../dashboards/dashboards";
import { InsightsDatePicker } from "./InsightsDatePicker";
import { useIsSampleData } from "./useIsSampleData";

export const InsightsHeader = ({
  title,
  groupCrumb,
  disableFilters,
  hideFilters = false,
  excludeSavedViews = false,
  icon,
  iconColour,
  disableFiltersTooltipContent,
}: {
  disableFilters?: boolean;
  hideFilters?: boolean;
  excludeSavedViews?: boolean;
  title: string;
  groupCrumb?: string;
  icon: IconEnum;
  iconColour?: ColorPaletteEnum;
  disableFiltersTooltipContent?: string;
}): React.ReactElement => {
  const DashboardGroups = useDashboardGroups();
  const navigate = useOrgAwareNavigate();
  const { search } = useLocation();
  const { isSampleData, excludeCustomFields } = useIsSampleData();

  let crumbs: { title: string; to?: string }[] = [];
  if (groupCrumb) {
    crumbs = [
      {
        title: "Insights",
        to: "/insights",
      },
      {
        title: groupCrumb,
      },
    ];
  }

  const hasCrumbs = crumbs && crumbs.length > 0;

  return (
    <HeaderBar
      icon={icon}
      color={iconColour}
      title={title}
      titleNode={
        <>
          <SavedViewHeading
            pageName={title}
            crumbs={crumbs}
            isSampleData={isSampleData}
            currentLinkRenderer={() => (
              <DropdownMenu
                align="start"
                scroll={false}
                triggerButton={
                  <Button
                    analyticsTrackingId={null}
                    theme={ButtonTheme.Naked}
                    className="h-5 overflow-hidden text-xs !gap-0"
                  >
                    <span className="font-normal">{title}</span>
                    <Icon id={IconEnum.ChevronDown} />
                  </Button>
                }
              >
                {DashboardGroups.map((group) => (
                  <DropdownMenuGroup key={group.title} label={group.title}>
                    {group.dashboards.map((dashboard) => (
                      <DropdownMenuItem
                        key={dashboard.name}
                        analyticsTrackingId={null}
                        label={dashboard.name}
                        onSelect={() =>
                          navigate({
                            pathname: `/insights/${dashboard.to}`,
                            search,
                          })
                        }
                      />
                    ))}
                  </DropdownMenuGroup>
                ))}
              </DropdownMenu>
            )}
            titleRenderer={
              <Heading
                level={1}
                size={hasCrumbs ? "medium" : "2xl"}
                className="shrink-0 flex items-center"
              >
                {hasCrumbs ? (
                  <DropdownMenu
                    align="start"
                    scroll={false}
                    triggerButton={
                      <Button
                        analyticsTrackingId={null}
                        theme={ButtonTheme.Unstyled}
                        className="!gap-0"
                      >
                        <span>{title}</span>
                        <Icon
                          id={IconEnum.ChevronDown}
                          className="text-content-tertiary group-hover:text-content-primary"
                        />
                      </Button>
                    }
                  >
                    {DashboardGroups.map((group) => (
                      <DropdownMenuGroup key={group.title} label={group.title}>
                        {group.dashboards.map((dashboard) => (
                          <DropdownMenuItem
                            key={dashboard.name}
                            analyticsTrackingId={null}
                            label={dashboard.name}
                            onSelect={() =>
                              navigate(`/insights/${dashboard.to}`)
                            }
                          />
                        ))}
                      </DropdownMenuGroup>
                    ))}
                  </DropdownMenu>
                ) : (
                  <span>{title}</span>
                )}
              </Heading>
            }
          />
        </>
      }
      accessory={
        !hideFilters && (
          <InsightsHeaderActions
            excludeCustomFields={excludeCustomFields}
            excludeSavedViews={excludeSavedViews}
            disableFilters={disableFilters}
            disableFiltersTooltipContent={disableFiltersTooltipContent}
          />
        )
      }
    />
  );
};

export const InsightsHeaderActions = ({
  excludeCustomFields,
  additionalActions,
  excludeSavedViews = false,
  disableFilters,
  disableFiltersTooltipContent = "Filtering by incidents is currently not supported for this dashboard.",
}: {
  excludeCustomFields?: boolean;
  additionalActions?: React.ReactElement;
  excludeSavedViews?: boolean;
  disableFilters?: boolean;
  disableFiltersTooltipContent?: string;
}): React.ReactElement => {
  return (
    <div className="flex flex-nowrap justify-end items-end gap-2 flex-center-y">
      <ToggleIncludePrivateIncidentsFilter className="pt-2 pb-2 flex-center-y mr-6" />
      <InsightsDatePicker />
      <AddFilterButton
        excludeCustomFields={excludeCustomFields}
        disabled={disableFilters}
        disabledTooltipContent={disableFiltersTooltipContent}
      />
      {additionalActions && additionalActions}
      {!excludeSavedViews && <CreateOrUpdateSavedViewButtonModal />}
    </div>
  );
};
