import {
  IncidentStatusCategoryEnum,
  InternalStatusPageContentSummary,
  InternalStatusPageIncident,
  StatusPageAffectedComponentStatusEnum,
  StatusPageContentComponentImpactStatusEnum,
  StatusPageContentIncidentTypeEnum,
} from "@incident-io/api";
import {
  Card,
  ComponentStatusIcon,
  ContentBoxTheme,
  HeadsUp as HeadsUpRender,
  TemplatedText,
  Tooltip,
  useUIContext,
} from "@incident-io/status-page-ui";
import { Avatar, BadgeSize, IconSize, IncidentStatusBadge } from "@incident-ui";
import { SeverityBadge } from "@incident-ui/Badge/SeverityBadge";
import _ from "lodash";
import React, { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";
import {
  joinSpansWithCommasAndConnectorWord,
  useInterval,
} from "src/utils/utils";

import { formatDurationShort } from "../../../../utils/datetime";
import { CustomFieldValueRender } from "../KeyInformation/KeyInformation";
import { toGenericStructureItem } from "../utils";

export const HeadsUp = ({
  summary,
}: {
  summary: InternalStatusPageContentSummary;
}): React.ReactElement => {
  const affectedComponents = summary.ongoing_incidents.flatMap((inc) =>
    inc.affected_components.map((comp) => ({
      component_id: comp.id,
      status: StatusPageAffectedComponentStatusEnum.FullOutage,
    })),
  );

  const items = summary.structure.components;
  const structure = items.map(toGenericStructureItem);

  const ongoingIncidents = summary.ongoing_incidents.map((incident) => ({
    id: incident.external_id.toString(),
    name: incident.name,
    type: StatusPageContentIncidentTypeEnum.Incident,
    worstImpacts: incident.affected_components.map((affComp) => ({
      component_id: affComp.id,
      status: StatusPageContentComponentImpactStatusEnum.FullOutage,
    })),
  }));

  return (
    <HeadsUpRender
      affectedComponents={affectedComponents}
      structure={structure}
      ongoingIncidents={ongoingIncidents}
      theme={
        ongoingIncidents.length > 0 ? ContentBoxTheme.FullOutage : undefined
      }
      supportUrl={null}
      DetailOngoingIncidents={({ setCurrentIncident }) => (
        <DetailOngoingIncidents
          setCurrentIncident={setCurrentIncident}
          summary={summary}
        />
      )}
      noHeaderIcon
      useComponentsOverGroups={
        summary.use_currently_affected_components_over_groups
      }
    />
  );
};

export const Duration = ({
  since,
  prefix = "Ongoing for",
  suffix,
}: {
  since: Date;
  prefix?: string;
  suffix?: string;
}) => {
  const calculateDuration = () =>
    formatDurationShort(since, new Date(), {
      prefix,
      significantFigures: 1,
      suffix,
    });

  const [duration, setDuration] = useState(calculateDuration);
  useInterval(() => setDuration(calculateDuration), 1 * 1000);

  return <span>{duration}</span>;
};

const DetailOngoingIncidents = ({
  setCurrentIncident,
  summary,
}: {
  setCurrentIncident: (id: string | null) => void;
  summary: InternalStatusPageContentSummary;
}) => {
  return (
    <>
      {summary.ongoing_incidents.map((incident) => (
        <IncidentCard
          key={incident.id}
          incident={incident}
          setCurrentIncident={setCurrentIncident}
        />
      ))}
    </>
  );
};

const IncidentCard = ({
  incident,
  setCurrentIncident,
}: {
  incident: InternalStatusPageIncident;
  setCurrentIncident: (id: string | null) => void;
}) => {
  const leadRole = incident.lead_assignment.role;
  const incidentLead = incident.lead_assignment.assignee;

  const { IncidentLink } = useUIContext();
  const latestUpdate = _.last(_.orderBy(incident.updates, "created_at"));

  return (
    <Card
      onMouseOver={() => setCurrentIncident(incident.external_id.toString())}
      onMouseOut={() => setCurrentIncident(null)}
    >
      <IncidentLink
        incident={{
          incident_id: incident.external_id.toString(),
          name: incident.name,
        }}
        className="flex flex-col px-3 divide-y divide-slate-200 dark:divide-slate-800"
        analyticsTrackingId="internal-status-page--heads-up-incident-clicked"
      >
        <div className="flex justify-between py-3">
          <div className="flex items-center font-medium gap-1.5">
            {incident.incident_status.category ===
            IncidentStatusCategoryEnum.Paused ? (
              <IncidentStatusBadge
                status={incident.incident_status}
                size={BadgeSize.Medium}
                iconOnly
                naked
              />
            ) : (
              <ComponentStatusIcon
                componentStatus={
                  StatusPageContentComponentImpactStatusEnum.FullOutage
                }
                className={tcx("h-7 py-[6.5px] pl-0.5 flex-none")}
              />
            )}{" "}
            {incident.name}
          </div>
          {incidentLead && (
            <Tooltip
              content={
                <>
                  <span className="text-content-tertiary mr-2">
                    {leadRole.name}
                  </span>
                  <span className="text-content-primary dark:text-slate-100">
                    {incidentLead.name}
                  </span>
                </>
              }
              className="flex-none"
            >
              <Avatar
                size={IconSize.Large}
                url={incidentLead.avatar_url}
                name={incidentLead.name}
                className="ml-2"
              />
            </Tooltip>
          )}
        </div>

        <div className="flex flex-wrap items-center gap-2 py-3">
          {incident.severity ? (
            <>
              <SeverityBadge
                severity={incident.severity}
                size={BadgeSize.Medium}
                className="text-content-primary"
                naked
              />
              <span className="text-slate-400">&bull;</span>
            </>
          ) : undefined}
          <IncidentStatusBadge
            status={incident.incident_status}
            size={BadgeSize.Medium}
            className="text-content-primary"
            naked
          />
          <span className="text-slate-400">&bull;</span>
          <div className="flex items-center">
            <Duration since={incident.reported_at} />
          </div>
          {latestUpdate && (
            <>
              <span className="text-slate-400 hidden md:flex">&bull;</span>
              <div className="flex items-center hidden md:flex">
                <Duration
                  since={latestUpdate.created_at}
                  prefix="Last updated"
                  suffix="ago"
                />
              </div>
            </>
          )}
        </div>

        <MessageAndMeta incident={incident} />
      </IncidentLink>
    </Card>
  );
};

const MessageAndMeta = ({
  incident,
}: {
  incident: InternalStatusPageIncident;
}) => {
  const metaListings: {
    id: string;
    label: string;
    values: () => React.ReactElement;
  }[] = [];

  const latestUpdate = _.last(_.orderBy(incident.updates, "created_at"));

  if (incident.affected_components.length > 0) {
    metaListings.push({
      id: "affected_components",
      label: "Affected components",
      values: () =>
        incident.affected_components.length === 0 ? (
          <>None</>
        ) : (
          joinSpansWithCommasAndConnectorWord(
            incident.affected_components.map((component) => (
              <span
                key={component.id}
                className="text-content-primary dark:text-slate-100"
              >
                {component.name}
              </span>
            )),
          ) ?? <></>
        ),
    });
  }

  incident.custom_field_entries.forEach((entry) => {
    if (entry.values.length === 0) return;

    metaListings.push({
      id: entry.custom_field.id,
      label: entry.custom_field.name,
      values: () =>
        joinSpansWithCommasAndConnectorWord(
          entry.values.map((value, idx) => (
            <CustomFieldValueRender key={idx} {...value} />
          )),
        ) ?? <></>,
    });
  });

  if (metaListings.length === 0 && latestUpdate?.message === undefined) {
    return null;
  }

  return (
    <div className="space-y-1.5 py-3">
      {latestUpdate !== undefined && latestUpdate.message !== undefined && (
        <TemplatedText
          value={latestUpdate.message}
          className="line-clamp-6 mb-2.5"
        />
      )}

      {metaListings.map(({ id, label, values: Values }) => (
        <div key={id} className="text-content-tertiary">
          <span className="text-content-tertiary mr-2">{label}</span>

          <Values />
        </div>
      ))}
    </div>
  );
};
