import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { DynamicMultiSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Incident, useClient } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

type GiveShoutoutForm = {
  recipient_users: string[];
  message: string;
};

export function GiveShoutoutModal({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement {
  const formMethods = useForm<GiveShoutoutForm>();
  const apiClient = useClient();

  const refreshTasks = useRevalidate(["postIncidentFlowListTasks"]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postIncidentFlowListTasks",
    {
      incidentId: incident.id,
    },
    async (apiClient, args: GiveShoutoutForm) => {
      await apiClient.shoutoutsCreate({
        createRequestBody: {
          // The user ID is extracted from auth server side, but the payload still requires it
          creator_user_id: "",
          incident_id: incident.id,
          message_text: args.message,
          recipient_user_ids: args.recipient_users,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: () => {
        refreshTasks();
        onClose();
      },
    },
  );

  return (
    <FormModalV2
      onClose={onClose}
      title="Give a shoutout"
      analyticsTrackingId="give-shoutout"
      formMethods={formMethods}
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          confirmButtonText="Send"
          saving={saving}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <DynamicMultiSelectV2
        formMethods={formMethods}
        name="recipient_users"
        autoFocus={true}
        label="Select users"
        required={true}
        helptext={"Who is the shoutout for?"}
        placeholder="Select users"
        loadOptions={getTypeaheadOptions(apiClient, TypeaheadTypeEnum.User, {
          incidentId: incident.id,
        })}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.User,
        )}
      />
      <TextareaV2
        required
        autoFocus
        name="message"
        formMethods={formMethods}
        label="Message"
        helptext="This is the text of the shoutout."
        placeholder="They did a fantastic job of identifying the root cause quickly and communicating this with the team."
      />
    </FormModalV2>
  );
}
