/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';

/**
 * 
 * @export
 * @interface InsightsCustomisableDashboard
 */
export interface InsightsCustomisableDashboard {
    /**
     * When the dashboard was archived
     * @type {Date}
     * @memberof InsightsCustomisableDashboard
     */
    archived_at?: Date;
    /**
     * When the dashboard was created
     * @type {Date}
     * @memberof InsightsCustomisableDashboard
     */
    created_at: Date;
    /**
     * 
     * @type {Actor}
     * @memberof InsightsCustomisableDashboard
     */
    creator: Actor;
    /**
     * Unique identifier for the dashboard
     * @type {string}
     * @memberof InsightsCustomisableDashboard
     */
    id: string;
    /**
     * Name of this dashboard
     * @type {string}
     * @memberof InsightsCustomisableDashboard
     */
    name: string;
    /**
     * Unique identifier for the organisation
     * @type {string}
     * @memberof InsightsCustomisableDashboard
     */
    organisation_id: string;
    /**
     * When the dashboard was updated
     * @type {Date}
     * @memberof InsightsCustomisableDashboard
     */
    updated_at: Date;
    /**
     * URL parameters that should be applied to this dashboard
     * @type {string}
     * @memberof InsightsCustomisableDashboard
     */
    url_params: string;
    /**
     * Visibility type of this dashboard
     * @type {string}
     * @memberof InsightsCustomisableDashboard
     */
    visibility: InsightsCustomisableDashboardVisibilityEnum;
}

/**
* @export
* @enum {string}
*/
export enum InsightsCustomisableDashboardVisibilityEnum {
    Personal = 'personal',
    Shared = 'shared'
}

export function InsightsCustomisableDashboardFromJSON(json: any): InsightsCustomisableDashboard {
    return InsightsCustomisableDashboardFromJSONTyped(json, false);
}

export function InsightsCustomisableDashboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsCustomisableDashboard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'archived_at': !exists(json, 'archived_at') ? undefined : (new Date(json['archived_at'])),
        'created_at': (new Date(json['created_at'])),
        'creator': ActorFromJSON(json['creator']),
        'id': json['id'],
        'name': json['name'],
        'organisation_id': json['organisation_id'],
        'updated_at': (new Date(json['updated_at'])),
        'url_params': json['url_params'],
        'visibility': json['visibility'],
    };
}

export function InsightsCustomisableDashboardToJSON(value?: InsightsCustomisableDashboard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'archived_at': value.archived_at === undefined ? undefined : (value.archived_at.toISOString()),
        'created_at': (value.created_at.toISOString()),
        'creator': ActorToJSON(value.creator),
        'id': value.id,
        'name': value.name,
        'organisation_id': value.organisation_id,
        'updated_at': (value.updated_at.toISOString()),
        'url_params': value.url_params,
        'visibility': value.visibility,
    };
}

