import { Mode } from "@incident-shared/forms/v2/formsv2";
import React from "react";
import { Route, Routes } from "react-router";

import { PostmortemDestinationCreateEditModal } from "./destination/PostmortemDestinationCreateEditModal";
import { PostmortemTemplateCreatePage } from "./post-mortems/PostmortemTemplateCreatePage";
import { PostmortemTemplateEditPage } from "./post-mortems/PostmortemTemplateEditPage";
import { PostmortemsPage } from "./PostmortemsPage";
import {
  PostmortemShareTemplateCreateModal,
  PostmortemShareTemplateEditModal,
} from "./share/PostmortemShareTemplateCreateEditModal";

export const PostmortemRoute = (): React.ReactElement => {
  return (
    <>
      <Routes>
        {/* These routes are full page edits - we don't want the main page to render underneath */}
        <Route path="templates">
          <Route path="create" element={<PostmortemTemplateCreatePage />} />
          <Route path=":id/edit" element={<PostmortemTemplateEditPage />} />
        </Route>
        <Route path="*" element={<PostmortemModalsRoute />} />
      </Routes>
    </>
  );
};

// We need to split this route so that the main page still renders behind the modals
const PostmortemModalsRoute = (): React.ReactElement => {
  return (
    <>
      <PostmortemsPage />
      <Routes>
        <Route path="destinations">
          <Route
            path=":id/edit"
            element={<PostmortemDestinationCreateEditModal mode={Mode.Edit} />}
          />
          <Route
            path="create"
            element={
              <PostmortemDestinationCreateEditModal mode={Mode.Create} />
            }
          />
        </Route>
        <Route path="shares">
          <Route
            path="templates/:id/edit"
            element={<PostmortemShareTemplateEditModal />}
          />
          <Route
            path="templates/create"
            element={<PostmortemShareTemplateCreateModal />}
          />
        </Route>
      </Routes>
    </>
  );
};
