import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { NoPermissionMessage } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  BadgeTheme,
  ButtonTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  Loader,
  StackedListItem,
  Toggle,
} from "@incident-ui";
import {
  SearchBar,
  SearchProvider,
  useSearchContext,
} from "@incident-ui/SearchBar/SearchBar";
import {
  AccordionStackedList,
  OverflowAction,
} from "@incident-ui/StackedList/AccordionStackedList";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { Searcher, sortKind } from "fast-fuzzy";
import { orderBy } from "lodash";
import React, { useState } from "react";
import { FieldValues } from "react-hook-form";
import graphic from "src/components/settings/banners/banner-nudges.svg";
import { ClientType, Nudge, ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { Plurality, ProductUpsellNotice } from "../../ProductUpsellNotice";
import { SettingsHeading } from "../../SettingsHeading";
import { SettingsSubPageWrapper } from "../../SettingsRoute";
import { UpsellNotice } from "../../UpsellNotice";
import { NudgePreviewInner } from "../common/NudgePreview";
import { NudgeSelectTypeModal } from "./NudgeTypeSelectModal";

export const NudgesListPage = (): React.ReactElement => {
  const { identity } = useIdentity();
  const { hasProduct } = useProductAccess();
  const {
    data: customNudgesListData,
    error: customNudgesListErr,
    isLoading: customNudgesListLoading,
  } = useAPI("nudgesListNudges", undefined);

  const [expandAll, setExpandAll] = useState(false);
  const [showChooseTypeModal, setShowChooseTypeModal] = useState(false);

  if (customNudgesListErr) {
    return <GenericErrorMessage error={customNudgesListErr} />;
  }

  const nudgesList = customNudgesListData;
  const loadingNudges = customNudgesListLoading;

  const hasFeatureGate = identity?.feature_gates.custom_nudges;

  return (
    <SettingsSubPageWrapper
      accessory={
        hasProduct(Product.Response) ? (
          <GatedButton
            theme={ButtonTheme.Primary}
            onClick={() => setShowChooseTypeModal(true)}
            analyticsTrackingId="create-nudge"
            icon={IconEnum.Add}
            requiredScope={ScopeNameEnum.NudgesCreate}
            requiredProduct={Product.Response}
            disabled={!hasFeatureGate}
            disabledTooltipContent={
              "You need to be on our Pro tier to add a nudge"
            }
          >
            Add nudge
          </GatedButton>
        ) : undefined
      }
    >
      {hasFeatureGate ? (
        <SettingsHeading
          articleId={8282410}
          title="Send timely nudges"
          subtitle="Create and send nudges to incident channels to improve your incident response."
          graphic={<img src={graphic} className="h-40" />}
        />
      ) : (
        <UpsellNotice
          analyticsId={"nudges-upsell-banner"}
          title={"Nudges"}
          planName={"Pro"}
          articleId={8282410}
          description={
            <>
              <p>
                Nudges can prompt responders to take action, helping to make
                sure that your organization&apos;s incident response best
                practices are followed. This is done by sending messages to
                incident channels.
              </p>
              <p>
                In order to customise these nudges, and create new ones,
                you&apos;ll need to be on our Pro tier.
              </p>
            </>
          }
        />
      )}
      {hasProduct(Product.Response) ? (
        <div>
          <SearchProvider>
            {showChooseTypeModal ? (
              <NudgeSelectTypeModal
                onClose={() => setShowChooseTypeModal(false)}
              />
            ) : null}
            <NudgesHeader expandAll={expandAll} setExpandAll={setExpandAll} />
            {loadingNudges ? (
              <Loader />
            ) : (
              <NudgesList
                nudges={(nudgesList && nudgesList.nudges) || []}
                expandAll={expandAll}
                hasFeatureGate={hasFeatureGate}
              />
            )}
          </SearchProvider>
        </div>
      ) : (
        <ProductUpsellNotice
          featureName="Nudges"
          plurality={Plurality.Plural}
          requiredProduct={Product.Response}
        />
      )}
    </SettingsSubPageWrapper>
  );
};

type NudgesHeaderProps = {
  expandAll: boolean;
  setExpandAll: (expandAll: boolean) => void;
};

const NudgesHeader = ({ setExpandAll, expandAll }: NudgesHeaderProps) => {
  const searchBarProps = useSearchContext();

  return (
    <div className="w-full mb-3 flex-center-y justify-between">
      <SearchBar
        {...searchBarProps}
        placeholder="Search nudges"
        className="mr-auto"
        autoFocus
      />
      <div className="px-4 shrink-0">
        <Toggle
          id="expand-all"
          onToggle={() => setExpandAll(!expandAll)}
          on={expandAll}
          label={expandAll ? "Collapse all" : "Expand all"}
        />
      </div>
    </div>
  );
};

type NudgesListProps = {
  nudges: Nudge[];
  expandAll: boolean;
  hasFeatureGate: boolean | undefined;
};

const NudgesList = ({ nudges, expandAll, hasFeatureGate }: NudgesListProps) => {
  const sortedNudges = orderBy(nudges, "updated_at", "asc");
  const { value: search } = useSearchContext();

  const searcher = new Searcher(sortedNudges, {
    keySelector: (nudge) => nudge.name,
    sortBy: sortKind.insertOrder,
    threshold: 0.8,
  });

  const items = search ? searcher.search(search) : sortedNudges;

  const navigate = useOrgAwareNavigate();
  const showToast = useToast();
  const { hasScope } = useIdentity();
  const canEditNudges = hasScope(ScopeNameEnum.NudgesUpdate);

  const { trigger: toggleNudgeStateAPI, isMutating: isChangingState } =
    useAPIMutation(
      "nudgesListNudges",
      undefined,
      async (apiClient: ClientType, data: FieldValues) => {
        if (data.currentlyEnabled) {
          await apiClient.nudgesDisable({
            id: data.nudgeId,
          });
        } else {
          await apiClient.nudgesEnable({
            id: data.nudgeId,
          });
        }

        return;
      },
      {
        setError: () => {
          showToast({
            theme: ToastTheme.Error,
            title: "Failed to update nudge",
            toastSide: ToastSideEnum.Bottom,
          });
        },
      },
    );

  const { trigger: deleteNudgeAPI, isMutating: isDeleting } = useAPIMutation(
    "nudgesListNudges",
    undefined,
    async (apiClient: ClientType, data: FieldValues) => {
      await apiClient.nudgesDestroy({
        id: data.nudgeId,
      });

      return;
    },
    {
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Nudge deleted",
          toastSide: ToastSideEnum.Bottom,
        });
      },
      setError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to update nudge",
          toastSide: ToastSideEnum.Bottom,
        });
      },
    },
  );

  const toggleNudgeState = async ({ id, currentlyEnabled }) => {
    await toggleNudgeStateAPI({ nudgeId: id, currentlyEnabled } as FieldValues);
  };
  const deleteNudge = async ({ id }) => {
    await deleteNudgeAPI({ nudgeId: id } as FieldValues);
  };

  const overflowActions: OverflowAction<Nudge>[] = [
    {
      id: "edit",
      label: "Edit nudge",
      icon: IconEnum.Edit,
      onSelect: (id) => {
        navigate(`${id}/edit`);
      },
      tooltipContent: canEditNudges ? null : <>{NoPermissionMessage}</>,
      shouldHide: (_) => false,
    },
    {
      id: "disable",
      label: "Disable nudge",
      icon: IconEnum.ToggleLeft,
      onSelect: (id) => {
        toggleNudgeState({ id, currentlyEnabled: true });
      },
      tooltipContent: canEditNudges ? null : <>{NoPermissionMessage}</>,
      shouldHide: (item) => !item.enabled,
    },
    {
      id: "enable",
      label: "Enable nudge",
      icon: IconEnum.ToggleRight,
      onSelect: (id) => {
        toggleNudgeState({ id, currentlyEnabled: false });
      },
      tooltipContent: canEditNudges ? null : <>{NoPermissionMessage}</>,
      shouldHide: (item) => item.enabled,
    },
  ];

  const deleteAction = {
    id: "delete",
    label: "Delete nudge",
    icon: IconEnum.Delete2,
    onDelete: (id) => {
      deleteNudge({ id });
    },
    onDisable: (id) => {
      toggleNudgeState({ id, currentlyEnabled: true });
    },
    tooltipContent: canEditNudges
      ? undefined
      : () => <>{NoPermissionMessage}</>,
  };

  return (
    <AccordionStackedList
      items={items}
      expandAll={expandAll}
      overflowActions={hasFeatureGate ? overflowActions : []}
      deleteAction={hasFeatureGate ? deleteAction : undefined}
      renderAccordion={(item) => {
        return (
          <NudgePreviewInner
            messageContent={item.preview.message_content}
            buttons={item.preview.buttons}
          />
        );
      }}
      deleteModalTitle={(item) => `Delete nudge "${item.name}"?`}
      renderDeleteModalContent={(item) => (
        <>
          <Callout theme={CalloutTheme.Warning}>
            Are you sure you want to permanently delete{" "}
            <span className="font-semibold">{item.name}</span>?
            {item.enabled && (
              <> Instead you could disable it to prevent it from running.</>
            )}
          </Callout>
        </>
      )}
      renderRow={(item) => {
        return (
          <StackedListItem
            noPadding
            icon={item.icon as unknown as IconEnum}
            title={item.name}
            badgeProps={
              item.enabled
                ? undefined
                : { label: "Disabled", theme: BadgeTheme.Tertiary }
            }
            disabled={!item.enabled}
          />
        );
      }}
      renderEmptyState={() => {
        if (search) {
          return (
            <EmptyState
              icon={IconEnum.Search}
              title="No matching nudges"
              content="We couldn't find any nudges that match your search."
            />
          );
        }

        return (
          <EmptyState
            icon={IconEnum.Nudge}
            content="You don't have any nudges yet"
          />
        );
      }}
      getRowClassName={() => "hover:bg-surface-secondary"}
      rowOnClick={(item) => {
        navigate(`${item.id}/edit`);
      }}
      isDeleting={(_) => {
        return isDeleting;
      }}
      isDisabling={(_) => {
        return isChangingState;
      }}
      isDisabled={(item) => {
        return !item.enabled;
      }}
    />
  );
};
