import {
  TimelineItemItemTypeEnum as TimelineItemType,
  TimelineItemsToggleVisibilityRequestBodyVisibilityEnum,
} from "@incident-io/api";
import {
  ContentBox,
  EmptyState,
  IconEnum,
  InlineErrorMessage,
  LoadingBar,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { ErrorBoundary } from "@sentry/react";
import _ from "lodash";
import { useState } from "react";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { useIncident, useTimelineItems } from "../legacy/incident/hooks";
import { ActivityLogItemUIWrapper } from "./ActivityLogItemUI";
import { EnrichmentWarnings } from "./EnrichmentWarnings";
import { SortOrder } from "./Timeline";
import { statusHasChanged } from "./timeline-items/TimelineItemIncidentUpdateComponent";
import styles from "./TimelineItems.module.scss";
import { enrichmentError } from "./TimelineItemUI";
import { sortTimelineItems } from "./TimelineUI";

export const ActivityLogWrapper = ({
  incidentId,
  sortOrder = SortOrder.NewestFirst,
}: {
  incidentId: string | null;
  sortOrder: SortOrder;
}): React.ReactElement => {
  return (
    <ErrorBoundary
      fallback={
        <InlineErrorMessage description="We couldn't load the activity log for this incident." />
      }
    >
      <ActivityLog incidentId={incidentId} sortOrder={sortOrder} />
    </ErrorBoundary>
  );
};

const ActivityLog = ({
  incidentId,
  sortOrder,
}: {
  incidentId: string | null;
  sortOrder: SortOrder;
}): React.ReactElement => {
  const { incident } = useIncident(incidentId);
  const {
    timelineItems: activityLogItems,
    isLoading: activityLogItemsLoading,
  } = useTimelineItems(incidentId);

  const showToast = useToast();

  // When a user clicks on an image (e.g. in a pinned slack message), we'll pop it open in a modal.
  const [zoomImageSource, setZoomImageSource] = useState<string | undefined>();

  const { trigger: onVisibilityChange } = useAPIMutation(
    "timelineItemsList",
    { incidentId: incidentId ?? "" },
    async (
      apiClient,
      { itemIds, hide }: { itemIds: string[]; hide: boolean },
    ) => {
      await Promise.all(
        itemIds.map((itemId) =>
          apiClient.timelineItemsToggleVisibility({
            id: itemId,
            toggleVisibilityRequestBody: {
              visibility: hide
                ? TimelineItemsToggleVisibilityRequestBodyVisibilityEnum.Hide
                : TimelineItemsToggleVisibilityRequestBodyVisibilityEnum.Show,
            },
          }),
        ),
      );
    },
    {
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: `We weren't able to update the visibility of this activity log item`,
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const onShow = (itemIds: string[]) =>
    onVisibilityChange({ hide: false, itemIds });

  if (activityLogItemsLoading || !incident) {
    return <LoadingBar className="mt-5 h-28" />;
  }

  if (_.isEmpty(activityLogItems)) {
    return (
      <EmptyState
        content="There hasn't been an activity yet."
        icon={IconEnum.Activity}
      />
    );
  }

  const hiddenItems = activityLogItems
    // We serve up some timeline items _knowing_ that in reality we don't want
    // to show them. This is for legacy backend reasons and to help with
    // debugging.
    .filter(
      (item) =>
        enrichmentError(incident, item)?.enrichment_error !==
        "thread-not-found",
    )
    .filter(
      (item) =>
        item.item_type !== TimelineItemType.TimelineNote &&
        item.item_type !== TimelineItemType.CustomEvent,
    )
    .filter((item) => item.hidden_at);

  if (_.isEmpty(hiddenItems)) {
    return (
      <EmptyState
        content="All events have been added to the timeline"
        icon={IconEnum.Activity}
      />
    );
  }

  const items = sortTimelineItems(hiddenItems, sortOrder);

  return (
    <div className="space-y-4">
      <EnrichmentWarnings incident={incident} items={items} />
      <ContentBox className={tcx(styles.timeline, "intercom-timeline")}>
        <div className={styles.lineHolder}>
          <div className={styles.verticalLine} />
        </div>
        <ul className={styles.timelineItemWrapper}>
          {items.map((element, i) => {
            const isStatusUpdate =
              element.item_type === TimelineItemType.StatusChange ||
              (element.item_type === TimelineItemType.IncidentUpdate &&
                statusHasChanged(element.incident_update));

            return (
              <div
                key={i}
                className={tcx(
                  styles.timelineItemHolder,
                  styles.editEnabled,
                  isStatusUpdate && styles.statusUpdateHolder,
                )}
              >
                <ActivityLogItemUIWrapper
                  key={i}
                  incident={incident}
                  onShow={onShow}
                  item={element}
                  zoomImageSource={zoomImageSource}
                  setZoomImageSource={setZoomImageSource}
                />
              </div>
            );
          })}
        </ul>
      </ContentBox>
    </div>
  );
};
