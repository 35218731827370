import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  COMPONENT_STATUS_CONFIG,
  STATUS_PAGE_INCIDENT_STATUS_NAME,
} from "@incident-shared/utils/StatusPages";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  Difference,
  Icon,
  IconEnum,
  ModalFooter,
} from "@incident-ui";
import { addMilliseconds, differenceInMilliseconds } from "date-fns";
import _ from "lodash";
import React, { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import {
  StatusPage,
  StatusPageAffectedComponentPayloadStatusEnum,
  StatusPageCreateRetrospectiveIncidentRequestBody,
  StatusPageIncidentUpdatePayload,
  StatusPageIncidentUpdatePayloadToStatusEnum as RequestBodyToStatusEnum,
  StatusPageIncidentUpdateToStatusEnum,
  StatusPageStructure,
  StatusPageTemplate,
  TextNode,
} from "src/contexts/ClientContext";
import { formatTimestampLocale } from "src/utils/datetime";
import { useQueryParams } from "src/utils/query-params";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { v4 as uuidv4 } from "uuid";

import { GroupStatusIndicator } from "../../incidents/common/GroupStatusIndicator";
import { incidentStatusSelectOptions } from "../../incidents/utils/utils";
import {
  AffectedComponentsEditor,
  AffectedComponentsFormData,
} from "../../incidents/view/AffectedComponentsEditor";
import { useStatusPageSubscriptionCount } from "../hooks/use-status-page-subscription-count";
import { defaultAllComponentsOperational } from "../utils/default-all-components-operational";
import {
  messageTemplateFor,
  useTemplatedMessageInput,
} from "../utils/template-helpers";
type StatusUpdateFormData = Omit<
  StatusPageIncidentUpdatePayload,
  "component_statuses" | "message"
> & { message: unknown } & AffectedComponentsFormData & {
    id: string;
  };

type FormData = Pick<
  StatusPageCreateRetrospectiveIncidentRequestBody,
  "name" | "notify_subscribers" | "idempotency_key"
> & {
  updates: StatusUpdateFormData[];
} & AffectedComponentsFormData;

const modalProps = {
  isOpen: true,
  isExtraLarge: true,
  title: "Publish retrospective incident",
  analyticsTrackingId: "status-page-publish-retrospective-incident",
};

export const PublishRetrospectiveIncident = ({
  page,
  structure,
  templates,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
  templates: StatusPageTemplate[];
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () =>
    history.length > 0 ? history.back() : navigate(`/status-pages/${page?.id}`);
  const params = useQueryParams();

  const defaultStatuses = defaultAllComponentsOperational(structure);

  const defaultPublishAt = new Date();
  defaultPublishAt.setSeconds(0);

  const defaultTemplate = messageTemplateFor(
    templates,
    RequestBodyToStatusEnum.Resolved,
  );

  const formMethods = useForm<FormData>({
    defaultValues: {
      idempotency_key: uuidv4(),
      name: params.get("name") || "",
      notify_subscribers: false,
      updates: [
        {
          published_at: defaultPublishAt,
          to_status: RequestBodyToStatusEnum.Resolved,
          component_statuses: defaultStatuses,
          message: defaultTemplate?.content,
          created_from_template_id: defaultTemplate?.id,
          id: uuidv4(),
        },
      ],
    },
  });

  const notifySubscribers = formMethods.watch("notify_subscribers");
  const updates = formMethods.watch("updates");

  const addUpdateAt = async (idx?: number) => {
    // We won't let you add another status update if your currently active one is invalid
    if (!(await formMethods.trigger("updates")) && activeUpdateId != null) {
      return;
    }

    if (idx === undefined) {
      idx = updates.length;
    }

    // laterRecord is one that *occurred* later, meaning it's higher up (earlier) in the list
    const laterRecord = idx !== 0 ? updates[idx - 1] : null;
    const earlierRecord = idx < updates.length ? updates[idx] : null;
    const id = uuidv4();
    const update: StatusUpdateFormData = laterRecord
      ? {
          id,
          published_at: earlierRecord
            ? addMilliseconds(
                earlierRecord.published_at,
                differenceInMilliseconds(
                  laterRecord.published_at,
                  earlierRecord.published_at,
                ) / 2,
              )
            : new Date(laterRecord.published_at),
          to_status: laterRecord.to_status,
          component_statuses: laterRecord.component_statuses,
          message: undefined,
        }
      : {
          id,
          published_at: new Date(),
          to_status: RequestBodyToStatusEnum.Resolved,
          component_statuses: defaultStatuses,
          message: undefined,
        };

    const template = messageTemplateFor(templates, update.to_status);
    if (template) {
      update.message = template.content;
      update.created_from_template_id = template.id;
    }

    const newUpdates = [...updates];
    newUpdates.splice(idx, 0, update);
    formMethods.setValue<"updates">("updates", newUpdates);
    setActiveUpdateId(id);
  };

  const removeUpdateAt = (idx?: number) => {
    if (idx === undefined) {
      idx = updates.length;
    }

    let newUpdates = [...updates];
    newUpdates.splice(idx, 1);
    newUpdates = _.orderBy(newUpdates, "published_at", "desc");
    newUpdates[0].to_status = RequestBodyToStatusEnum.Resolved;
    formMethods.setValue<"updates">("updates", newUpdates);
  };

  const {
    trigger,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "statusPageListIncidents",
    {
      statusPageId: page.id,
    },
    async (apiClient, data: FormData) => {
      const payload: StatusPageCreateRetrospectiveIncidentRequestBody = {
        ...data,
        status_page_id: page.id,
        updates: data.updates.map((u) => ({
          ...u,
          message: u.message as TextNode,
          component_statuses: Object.keys(u.component_statuses).map(
            (component_id) => ({
              component_id,
              status: u.component_statuses[component_id],
            }),
          ),
        })),
      };
      const res = await apiClient.statusPageCreateRetrospectiveIncident({
        createRetrospectiveIncidentRequestBody: payload,
      });

      navigate(
        `/status-pages/${page.id}/incident/${res.status_page_incident.id}`,
      );

      // Revalidate the list response
      return;
    },
    {
      setError: formMethods.setError,
    },
  );

  const [activeUpdateId, setActiveUpdateId] = useState<string | null>(
    updates[0].id,
  );

  const saveCurrentUpdate = async () => {
    const isValid = await formMethods.trigger("updates");
    if (!isValid) {
      return;
    }

    const newUpdates = _.orderBy([...updates], "published_at", "desc");
    newUpdates[0].to_status = RequestBodyToStatusEnum.Resolved;
    formMethods.setValue<"updates">("updates", newUpdates);
    setActiveUpdateId(null);
  };

  const onSubmit = async (req: FormData) => {
    await saveCurrentUpdate();
    // since we change the updates when saving the current update, we reassign it on the request
    req.updates = updates;
    return trigger(req);
  };

  const editUpdate = async (id: string) => {
    // If you're in a state where you've somehow managed to get invalid updates without currently editing one
    // we'll let you enter edit mode regardless of validity state.
    if (activeUpdateId == null) {
      setActiveUpdateId(id);
    }

    // Otherwise, you're trying to switch between two updates, in which case we'll validate that the current
    // one is valid before switching.
    const isValid = await formMethods.trigger("updates");

    if (!isValid) {
      return;
    }

    const newUpdates = _.orderBy([...updates], "published_at", "desc");
    newUpdates[0].to_status = RequestBodyToStatusEnum.Resolved;
    formMethods.setValue<"updates">("updates", newUpdates);
    setActiveUpdateId(id);
  };

  const components: { [key: string]: string } = {};
  structure.items.forEach(({ component, group }) => {
    if (component && !component.hidden) {
      components[component.component_id] = component.name;
    }

    if (group && !group.hidden) {
      group.components.forEach((component) => {
        if (!component.hidden) {
          components[component.component_id] = component.name;
        }
      });
    }
  });

  const componentIds = updates.flatMap((u) => {
    return Object.keys(u.component_statuses).filter(
      (cId) =>
        u.component_statuses[cId] !==
        StatusPageAffectedComponentPayloadStatusEnum.Operational,
    );
  });

  const activeSubscriberCount = useStatusPageSubscriptionCount({
    statusPageId: page.id,
    componentIds: componentIds.length > 0 ? componentIds : undefined,
  });

  const componentStatuses = (
    u: StatusUpdateFormData,
    previousUpdate?: StatusUpdateFormData,
  ) => {
    const statuses = structure.items
      .map(({ group, component }) => {
        if (
          !component ||
          component.hidden ||
          !componentIds.includes(component.component_id)
        ) {
          return undefined;
        }

        if (group && !group.hidden) {
          return (
            <GroupStatusIndicator
              key={group.id}
              group={group}
              statuses={u.component_statuses}
            />
          );
        }
        if (component && !component.hidden) {
          const prevStatus =
            previousUpdate?.component_statuses?.[component.component_id];
          const newStatus = u.component_statuses[component.component_id];
          return (
            <div key={component.component_id}>
              <span className="font-medium pr-1">
                {components[component.component_id]}
              </span>
              <Difference
                prev={
                  prevStatus
                    ? COMPONENT_STATUS_CONFIG[prevStatus].label
                    : undefined
                }
                next={COMPONENT_STATUS_CONFIG[newStatus].label}
              />
            </div>
          );
        }
        return undefined;
      })
      .filter((s) => s !== undefined);

    return statuses.length > 0 ? (
      statuses
    ) : (
      <span className="text-content-tertiary">
        All components are operational
      </span>
    );
  };

  return (
    <FormModalV2
      {...modalProps}
      disableQuickClose
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      saving={saving}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          cancelButtonText="Cancel"
          confirmButtonType="submit"
          confirmButtonText="Publish incident"
        />
      }
    >
      <>
        {page.mirroring_atlassian_page && (
          <Callout theme={CalloutTheme.Warning}>
            This incident won&apos;t appear on your Atlassian Statuspage. If
            you&apos;re ready to switch to your incident.io Status Page, click
            &lsquo;Go live&rsquo;.
          </Callout>
        )}
        <FormHelpTextV2>
          Keep your customers up to date about an issue which has already been
          resolved with a retrospective incident. Customise the timeline of your
          incident and write all updates here.
        </FormHelpTextV2>
        <InputV2
          formMethods={formMethods}
          name="name"
          required="You must set a name for each incident"
          label="Name"
          helptext="The publicly visible name for this incident."
        />
        <div className="font-medium text-sm">Updates timeline</div>
        <div>
          {updates.map((update, i) => {
            const previousUpdateStatus =
              i !== 0 ? updates[i - 1].to_status : undefined;
            const nextUpdateStatus =
              i !== updates.length - 1 ? updates[i + 1].to_status : undefined;

            return (
              <>
                <TimelineItem
                  update={update}
                  previousStatus={previousUpdateStatus}
                  nextStatus={nextUpdateStatus}
                  currentStatus={update.to_status}
                  className="mb-4"
                  isFirst={i === 0}
                >
                  {activeUpdateId === update.id ? (
                    <TimelineUpdateEdit
                      // Force a re-mount when the update changes
                      key={update.id}
                      page={page}
                      structure={structure}
                      templates={templates}
                      index={i}
                      formMethods={formMethods}
                      onSave={saveCurrentUpdate}
                      onAddUpdateClick={() => addUpdateAt(i + 1)}
                    />
                  ) : (
                    <TimelineUpdateCollapsed
                      page={page}
                      update={update}
                      updates={updates}
                      formMethods={formMethods}
                      componentStatuses={componentStatuses}
                      onEditClick={() => editUpdate(update.id)}
                      onDeleteClick={() => removeUpdateAt(i)}
                      onAddUpdateClick={() => addUpdateAt(i + 1)}
                      isLast={i === updates.length - 1}
                    />
                  )}
                </TimelineItem>
              </>
            );
          })}
          <TimelineItem>
            <Button
              analyticsTrackingId="status-pages.retrospective-incident.add-later-event"
              onClick={() => addUpdateAt()}
              theme={ButtonTheme.Secondary}
            >
              Add earlier update
            </Button>
          </TimelineItem>
        </div>

        {activeSubscriberCount !== undefined && activeSubscriberCount > 0 && (
          <div className="space-y-2 pt-4">
            <CheckboxV2
              label="Notify subscribers"
              name="notify_subscribers"
              formMethods={formMethods}
            />
            {!notifySubscribers ? (
              <Callout theme={CalloutTheme.Info}>
                We won&rsquo;t notify any of your subscribers.
              </Callout>
            ) : (
              <Callout theme={CalloutTheme.Info}>
                We&rsquo;ll notify your {activeSubscriberCount.toLocaleString()}{" "}
                subscriber{activeSubscriberCount === 1 ? "" : "s"}.
              </Callout>
            )}
          </div>
        )}
      </>
    </FormModalV2>
  );
};

const TimelineUpdateEdit = ({
  formMethods,
  index,
  structure,
  page,
  templates,
  onSave,
  onAddUpdateClick,
}: {
  formMethods: UseFormReturn<FormData>;
  index: number;
  page: StatusPage;
  structure: StatusPageStructure;
  templates: StatusPageTemplate[];
  onSave: () => void;
  onAddUpdateClick: () => void;
}): React.ReactElement => {
  const isFirst = index === 0;
  const updates = formMethods.watch("updates");

  const status = formMethods.watch(`updates.${index}.to_status`);
  useTemplatedMessageInput<
    FormData,
    `updates.${number}.to_status`,
    `updates.${number}.to_status`
  >({
    formMethods,
    templates,
    page,
    statusPath: `updates.${index}.to_status`,
    messagePath: `updates.${index}.message`,
    templateIdPath: `updates.${index}.created_from_template_id`,
  });

  return (
    <>
      <ContentBox className="p-4">
        <div className="space-y-4">
          <div className="grid gap-4 grid-cols-2">
            <StaticSingleSelectV2
              key={`updates.${index}.status`}
              label="Status"
              name={`updates.${index}.to_status`}
              formMethods={formMethods}
              options={
                isFirst
                  ? incidentStatusSelectOptions.filter(
                      (o) =>
                        o.value ===
                        StatusPageIncidentUpdateToStatusEnum.Resolved,
                    )
                  : incidentStatusSelectOptions
              }
              disabled={isFirst}
              disabledTooltipContent="The final update in a retrospective incident must have a resolved status."
            />
            <DateTimeInputV2
              key={`updates.${index}.published_at`}
              label="Occurred at"
              name={`updates.${index}.published_at`}
              formMethods={formMethods}
              rules={{
                validate: (value: Date): string | undefined => {
                  if (value > new Date()) {
                    return "Date cannot be in the future";
                  }
                  return undefined;
                },
              }}
            />
          </div>
          <div className="space-y-2">
            <TemplatedTextInputV2
              formMethods={formMethods}
              name={`updates.${index}.message`}
              includeVariables={false}
              includeExpressions={false}
              label="Message"
              placeholder="What was the impact at this point?"
              required="You must set a message"
              format="mrkdwn"
              multiLine
            />
            {status !== RequestBodyToStatusEnum.Resolved ? (
              <AffectedComponentsEditor<
                FormData,
                `updates.${number}.component_statuses`,
                `updates.${number}.component_statuses`
              >
                formMethods={formMethods}
                fieldNamePrefix={`updates.${index}.component_statuses`}
                structure={structure}
                maintenance={false}
              />
            ) : (
              <p className="text-sm text-content-tertiary">
                All components will be marked as operational. You can adjust the
                impact timeline on the next page.
              </p>
            )}
            <Button
              type="button"
              theme={ButtonTheme.Primary}
              onClick={onSave}
              analyticsTrackingId={null}
            >
              Save
            </Button>
          </div>
        </div>
      </ContentBox>
      {index !== updates.length - 1 && (
        <AddTimelineUpdate onClick={onAddUpdateClick} />
      )}
    </>
  );
};

const TimelineUpdateCollapsed = ({
  updates,
  update,
  componentStatuses,
  onEditClick,
  onDeleteClick,
  onAddUpdateClick,
  isLast,
}: {
  formMethods: UseFormReturn<FormData>;
  updates: StatusUpdateFormData[];
  update: StatusUpdateFormData;
  page: StatusPage;
  componentStatuses: (
    u: StatusUpdateFormData,
    previousUpdate?: StatusUpdateFormData,
  ) => JSX.Element | (JSX.Element | undefined)[];
  onEditClick: () => void;
  onDeleteClick: () => void;
  onAddUpdateClick: () => void;
  isLast: boolean;
}): React.ReactElement => {
  const i = updates.findIndex((u) => u.id === update.id);

  return (
    <>
      <ContentBox className="p-4 flex justify-between flex-col space-y-4 lg:space-y-0 lg:flex-row">
        <div className="flex flex-col space-y-2">
          <div className="font-medium">
            {STATUS_PAGE_INCIDENT_STATUS_NAME[update.to_status]}
          </div>

          {update.message && (
            <TemplatedTextDisplay
              value={update.message as TextNode}
              style={TemplatedTextDisplayStyle.Compact}
            />
          )}

          <div className="flex items-center space-x-2 text-slate-400">
            <span className="text-slate-400">
              {formatTimestampLocale({
                timestamp: update.published_at,
                dateStyle: "short",
                timeStyle: "short",
              })}
            </span>
            <Button
              type="button"
              onClick={onEditClick}
              analyticsTrackingId={null}
              theme={ButtonTheme.Naked}
            >
              Edit
            </Button>
            {updates.length > 1 && (
              <Button
                type="button"
                onClick={onDeleteClick}
                analyticsTrackingId={null}
                theme={ButtonTheme.Naked}
              >
                Delete
              </Button>
            )}
          </div>
        </div>
        <div className="w-full bg-surface-secondary !max-w-[300px] p-4 space-y-3 flex flex-col items-start rounded-2 border border-stroke">
          {componentStatuses(
            update,
            i < updates.length - 1 ? updates[i + 1] : undefined,
          )}
        </div>
      </ContentBox>
      {!isLast && <AddTimelineUpdate onClick={onAddUpdateClick} />}
    </>
  );
};

const AddTimelineUpdate = ({
  onClick,
}: {
  onClick: () => void;
}): React.ReactElement => {
  return (
    <div className="group cursor-pointer" onClick={onClick}>
      <div className="invisible group-hover:visible flex items-center pt-4">
        <div className="flex-grow h-[2px] bg-surface-tertiary"></div>
        <div className="px-2 text-slate-400 flex items-center">
          <Icon id={IconEnum.AddCircle} />
          Add update
        </div>
        <div className="flex-grow h-[2px] bg-surface-tertiary"></div>
      </div>
    </div>
  );
};

const TimelineItem = ({
  children,
  className,
  previousStatus,
  currentStatus,
  nextStatus,
  isFirst,
}: {
  update?: StatusUpdateFormData;
  children: React.ReactElement;
  className?: string;
  previousStatus?: RequestBodyToStatusEnum;
  currentStatus?: RequestBodyToStatusEnum;
  nextStatus?: RequestBodyToStatusEnum;
  isFirst?: boolean;
}): React.ReactElement => {
  return (
    <div className="flex text-sm">
      <TimelineMarker
        previousStatus={previousStatus}
        currentStatus={currentStatus}
        nextStatus={nextStatus}
        isFirst={isFirst}
      />
      <div className={tcx("w-full", className)}>{children}</div>
    </div>
  );
};

const TimelineMarker = ({
  previousStatus,
  currentStatus,
  nextStatus,
  isFirst,
}: {
  previousStatus?: RequestBodyToStatusEnum;
  currentStatus?: RequestBodyToStatusEnum;
  nextStatus?: RequestBodyToStatusEnum;
  isFirst?: boolean;
}): React.ReactElement => {
  return (
    <div className="flex text-sm">
      <div className="flex flex-col items-center mr-4 flex-none">
        <div
          className={tcx(
            "w-px h-6",
            !previousStatus && currentStatus
              ? "bg-transparent"
              : getColourForBorder(
                  undefined,
                  previousStatus,
                  currentStatus,
                  isFirst,
                ),
          )}
        />
        <div
          className={tcx(
            "flex-none w-2 h-2 rounded-full",
            getColourForMarker(currentStatus),
          )}
        ></div>
        <div
          className={tcx(
            "w-px h-full",
            !currentStatus
              ? "bg-transparent"
              : getColourForBorder(
                  previousStatus,
                  currentStatus,
                  nextStatus,
                  isFirst,
                ),
          )}
        />
      </div>
    </div>
  );
};

const COLOUR_FOR_MARKER = {
  green: "bg-green-400",
  red: "bg-alarmalade-600",
  grey: "bg-slate-300",
};

const COLOUR_FOR_BORDER = {
  green: "border-green-300 bg-green-300",
  red: "border-red-300 bg-red-300",
  grey: "border-stroke bg-slate-300",
  gradient: "bg-gradient-to-b from-green-300 to-red-300",
};

const getColourForBorder = (
  previousStatus?: RequestBodyToStatusEnum,
  currentStatus?: RequestBodyToStatusEnum,
  nextStatus?: RequestBodyToStatusEnum,
  isFirst?: boolean,
) => {
  if (
    !isFirst &&
    !previousStatus &&
    nextStatus &&
    nextStatus !== RequestBodyToStatusEnum.Resolved
  ) {
    return COLOUR_FOR_BORDER["red"];
  }
  if (
    currentStatus === RequestBodyToStatusEnum.Resolved &&
    nextStatus === RequestBodyToStatusEnum.Resolved
  ) {
    return COLOUR_FOR_BORDER["green"];
  } else if (
    currentStatus === RequestBodyToStatusEnum.Resolved &&
    nextStatus &&
    nextStatus !== RequestBodyToStatusEnum.Resolved
  ) {
    return COLOUR_FOR_BORDER["gradient"];
  } else if (
    currentStatus &&
    currentStatus !== RequestBodyToStatusEnum.Resolved &&
    nextStatus &&
    nextStatus !== RequestBodyToStatusEnum.Resolved
  ) {
    return COLOUR_FOR_BORDER["red"];
  }
  return COLOUR_FOR_BORDER["grey"];
};

const getColourForMarker = (currentStatus?: RequestBodyToStatusEnum) => {
  if (!currentStatus) {
    return COLOUR_FOR_MARKER["grey"];
  }
  if (currentStatus === RequestBodyToStatusEnum.Resolved) {
    return COLOUR_FOR_MARKER["green"];
  }
  return COLOUR_FOR_MARKER["red"];
};
