import { IntegrationConfigFor } from "@incident-shared/integrations";
import { PolicyViolationNotification } from "@incident-shared/policy/PolicyViolationNotification";
import { CreatePostMortemModal } from "@incident-shared/postmortems";
import { SharePostMortemModal } from "@incident-shared/postmortems";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  Heading,
  IconEnum,
  IconSize,
  Link,
  Loader,
  LocalDateTime,
  Spinner,
  Tooltip,
} from "@incident-ui";
import _ from "lodash";
import { useState } from "react";
import {
  Incident,
  IntegrationSettingsProviderEnum,
  Policy,
  PolicyViolation,
  PolicyViolationPolicyTypeEnum,
  PostmortemDocument,
  PostmortemDocumentStatusEnum,
  PostmortemsEnqueueCreateDocumentRequestBody,
  PostmortemsSetDocumentStatusRequestBodyStatusEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useAPIRefetch } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { usePostmortemName } from "src/utils/utils";

import { getAnchorId, postmortemAnchorId } from "../../../../utils/anchor";
import { incidentInEditableStatus } from "../helpers";
import { usePoliciesAndViolations } from "../hooks";
import { usePostmortem } from "../postincidentflow/usePostmortem";
import { ExternalLink } from "./ExternalLink";
import styles from "./IncidentSidebar.module.scss";

export function PostMortemPrompt({
  incident,
}: {
  incident: Incident;
}): React.ReactElement | null {
  const { policies, policyViolations } = usePoliciesAndViolations(incident.id);

  // If the incident isn't in an editable state, we don't want to allow someone
  // to generate post mortems either
  if (!incidentInEditableStatus(incident)) {
    return null;
  }

  const policyViolation =
    policyViolations &&
    policyViolations.find(
      (violation) =>
        violation.policy_type === PolicyViolationPolicyTypeEnum.PostMortem,
    );

  let violatedPolicy: Policy | undefined = undefined;
  if (policyViolation) {
    violatedPolicy = policies?.find(
      (policy) => policy.id === policyViolation.policy_id,
    );
  }

  return (
    <PostmortemDocumentComponent
      incident={incident}
      violatedPolicy={violatedPolicy}
      policyViolation={policyViolation}
    />
  );
}

const PostmortemDocumentComponent = ({
  incident,
  violatedPolicy,
  policyViolation,
}: {
  incident: Incident;
  violatedPolicy: Policy | undefined;
  policyViolation: PolicyViolation | undefined;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);

  const {
    availableDocument: document,
    enqueuedDocument,
    failedDocument,
  } = usePostmortem(incident);

  const {
    data: { shares: postmortemShares },
  } = useAPI(
    "postmortemSharesListShares",
    { incidentId: incident.id },
    { fallbackData: { shares: [] } },
  );

  const refetchPostIncidentFlowTasks = useAPIRefetch(
    "postIncidentFlowListTasks",
    { incidentId: incident.id },
  );
  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });

  const latestPostmortemShare =
    postmortemShares &&
    postmortemShares.length > 0 &&
    postmortemShares[postmortemShares.length - 1];

  const latestPostmortemShareMessage =
    latestPostmortemShare &&
    latestPostmortemShare.share_messages.length > 0 &&
    latestPostmortemShare.share_messages[0];

  const {
    loading: nameLoading,
    postmortemName,
    postmortemNameFormatted,
  } = usePostmortemName(incident);

  const { hasScope } = useIdentity();
  const canMarkAsComplete = hasScope(ScopeNameEnum.PostMortemsMarkAsComplete);

  const {
    trigger: destroyDocument,
    isMutating: destroying,
    genericError: destroyError,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident.id },
    async (apiClient, { id }: { id: string }) => {
      await apiClient.postmortemsDestroyDocument({ id });
      await refetchPostIncidentFlowTasks();
    },
  );

  const { trigger: retryDocumentCreation, isMutating: retrying } =
    useAPIMutation(
      "postmortemsListDocuments",
      { incidentId: incident.id },
      async (apiClient, document: PostmortemDocument) => {
        const body: PostmortemsEnqueueCreateDocumentRequestBody = {
          incident_id: incident.id,
          postmortem_document_id: document.id,
        };

        // Try to create the document again
        await apiClient.postmortemsEnqueueCreateDocument({
          enqueueCreateDocumentRequestBody: body,
        });

        await refetchPostIncidentFlowTasks();

        // Refetch the incident so anything on the page that uses postmortem_document_url will know
        // about it
        await refetchIncident();
      },
    );

  const {
    trigger: updateDocumentStatus,
    isMutating: updatingStatus,
    genericError: statusError,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident.id },
    async (
      apiClient,
      {
        id,
        status,
      }: {
        id: string;
        status: PostmortemDocumentStatusEnum;
      },
    ) => {
      await apiClient.postmortemsSetDocumentStatus({
        id,
        setDocumentStatusRequestBody: {
          status:
            status as unknown as PostmortemsSetDocumentStatusRequestBodyStatusEnum,
        },
      });
      await refetchPostIncidentFlowTasks();
    },
  );

  if (nameLoading) {
    return <Loader />;
  }

  if (destroyError || statusError) {
    return <GenericErrorMessage />;
  }

  let documentProviderName: string | undefined;
  let icon: IconEnum | undefined;
  if (document) {
    const { providerName, providerIcon } = getDocumentProvider(document);
    [documentProviderName, icon] = [providerName, providerIcon];
  }

  if (failedDocument) {
    const { providerName, providerIcon } = getDocumentProvider(failedDocument);
    [documentProviderName, icon] = [providerName, providerIcon];
  }

  return (
    <div className="space-y-2 pb-1.5">
      <div className="flex gap-2 items-center mb-2">
        <Heading level={3} size="small">
          {postmortemName}
        </Heading>
        {/* Dropdown menu */}
        {document && !destroying && !retrying ? (
          <>
            <PostmortemStatusBadge
              status={document.status}
              loading={updatingStatus}
            />
            {violatedPolicy != null && policyViolation ? (
              <dt className="flex items-center gap-2">
                <PolicyViolationNotification
                  iconOnly
                  policy={violatedPolicy}
                  resourceName="incident"
                  level={policyViolation.level}
                  daysUntil={policyViolation.days}
                  violationID={policyViolation.id}
                />
              </dt>
            ) : null}
            <DropdownMenu
              triggerButton={
                <Button
                  theme={ButtonTheme.Naked}
                  type="button"
                  className="ml-auto"
                  analyticsTrackingId="post-mortem-actions"
                  icon={IconEnum.DotsHorizontal}
                  iconProps={{ size: IconSize.XL }}
                  title="post-mortem-options"
                />
              }
            >
              {document.status !== PostmortemDocumentStatusEnum.Created && (
                <DropdownMenuItem
                  onSelect={() =>
                    updateDocumentStatus({
                      id: document.id,
                      status: PostmortemDocumentStatusEnum.Created,
                    })
                  }
                  analyticsTrackingId={"post-mortem-status-created"}
                  label={"Mark as Created"}
                  icon={IconEnum.ArrowLeft}
                >
                  <span className="font-normal">
                    Mark as{" "}
                    <span className="font-semibold !text-slate-700">
                      Created
                    </span>
                  </span>
                </DropdownMenuItem>
              )}
              {document.status !== PostmortemDocumentStatusEnum.Review && (
                <DropdownMenuItem
                  onSelect={() =>
                    updateDocumentStatus({
                      id: document.id,
                      status: PostmortemDocumentStatusEnum.Review,
                    })
                  }
                  analyticsTrackingId={"post-mortem-status-in-review"}
                  label={"Mark as In review"}
                  icon={IconEnum.ArrowRight}
                >
                  <span>
                    Mark as{" "}
                    <span className="font-semibold !text-slate-700">
                      In review
                    </span>
                  </span>
                </DropdownMenuItem>
              )}
              {document.status !== PostmortemDocumentStatusEnum.Complete && (
                <DropdownMenuItem
                  onSelect={() =>
                    updateDocumentStatus({
                      id: document.id,
                      status: PostmortemDocumentStatusEnum.Complete,
                    })
                  }
                  icon={IconEnum.Tick}
                  analyticsTrackingId={"post-mortem-status-complete"}
                  label={"Mark as Complete"}
                  disabled={!canMarkAsComplete}
                >
                  <span
                    title={
                      canMarkAsComplete
                        ? ""
                        : "You do not have permission to mark post-mortems as complete."
                    }
                  >
                    Mark as{" "}
                    <span className="font-semibold !text-slate-700">
                      Completed
                    </span>
                  </span>
                </DropdownMenuItem>
              )}

              <DropdownMenuItem
                onSelect={() => destroyDocument({ id: document.id })}
                analyticsTrackingId={"delete-post-mortem"}
                label={`Unlink ${postmortemNameFormatted}`}
                icon={IconEnum.LinkBreak}
              >
                {`Unlink`}
              </DropdownMenuItem>

              {identity.can_share_postmortems && (
                <DropdownMenuItem
                  onSelect={() => setShowShareModal(true)}
                  analyticsTrackingId={"share-post-mortem"}
                  label={`Share ${postmortemNameFormatted}`}
                  icon={IconEnum.Announcement}
                >
                  {`Share`}
                </DropdownMenuItem>
              )}
            </DropdownMenu>
          </>
        ) : enqueuedDocument || failedDocument ? (
          // If there isn't a document, but there is an enqueued document, show
          // a cancel button.
          <DropdownMenu
            triggerButton={
              <Button
                theme={ButtonTheme.Naked}
                type="button"
                analyticsTrackingId="post-mortem-actions"
                icon={IconEnum.DotsHorizontal}
                iconProps={{ size: IconSize.XL, className: "-my-2" }}
                title="post-mortem-options"
              />
            }
          >
            {failedDocument && (
              <DropdownMenuItem
                onSelect={() => retryDocumentCreation(failedDocument)}
                analyticsTrackingId="retry-creating-post-mortem"
                label="Retry"
                icon={IconEnum.Refresh2}
              >
                Retry
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              onSelect={() => {
                // Have to check individually like this because typescript can't
                // recognise that if one doesn't exist then the other does
                if (enqueuedDocument) {
                  destroyDocument({
                    id: enqueuedDocument.id,
                  });
                }

                if (failedDocument) {
                  destroyDocument({
                    id: failedDocument.id,
                  });
                }
              }}
              analyticsTrackingId={"cancel-pending-post-mortem"}
              label={enqueuedDocument ? `Cancel` : `Clear`}
              icon={IconEnum.Close}
            >
              {enqueuedDocument ? `Cancel` : `Clear`}
            </DropdownMenuItem>
          </DropdownMenu>
        ) : null}
      </div>

      {/* Document link section */}
      <div>
        <div
          className={tcx(
            getAnchorId() === postmortemAnchorId ? styles.anchored : "",
            "intercom-post-mortem-document",
            "flex items-center space-x-1",
          )}
          id={postmortemAnchorId}
        >
          <dd className="flex items-center grow space-x-1">
            {document ? (
              <>
                <ExternalLink
                  href={document.permalink}
                  analyticsTrackingId="view-postmortem"
                  label={
                    documentProviderName
                      ? `View in ${documentProviderName}` // "View in Notion"
                      : `View ${postmortemNameFormatted}`
                  }
                  icon={icon ?? IconEnum.File}
                  iconProps={{
                    size: IconSize.Medium,
                    className: "ml-0.5 text-content-tertiary",
                  }}
                />
              </>
            ) : enqueuedDocument ? (
              // If there isn't a document, but there is an enqueued document,
              // show a loader.
              <div className="space-x-2 flex flex-row items-center mt-1 grow-0">
                <span>
                  <Loader large={false} className="!my-0 flex-none" />
                </span>
                <span className="text-content-tertiary">Creating...</span>
              </div>
            ) : failedDocument ? (
              // If there is a failed document show an error message
              <PostMortemCreationFailureTooltip
                postmortemNameFormatted={postmortemNameFormatted}
                provider={documentProviderName}
                providerError={
                  failedDocument.human_readable_error
                    ? failedDocument.human_readable_error
                    : failedDocument.response_body
                }
              >
                <div className="flex-center-y mt-0.5">
                  <Badge icon={IconEnum.Warning} theme={BadgeTheme.Warning}>
                    {"Failed to create" +
                      (documentProviderName
                        ? ` in ${documentProviderName}`
                        : "")}
                  </Badge>
                </div>
              </PostMortemCreationFailureTooltip>
            ) : (
              <>
                <Button
                  icon={IconEnum.FilePlus}
                  iconProps={{
                    size: IconSize.Medium,
                  }}
                  theme={ButtonTheme.Naked}
                  onClick={() => {
                    setShowCreateModal(true);
                  }}
                  analyticsTrackingId="create-a-postmortem-prompt"
                  className="-ml-0.5"
                >
                  {`Add ${postmortemNameFormatted}`}
                </Button>
              </>
            )}
          </dd>
          {violatedPolicy != null && !document && policyViolation ? (
            <dt className="flex items-center gap-2 -mt-1">
              <PolicyViolationNotification
                iconOnly
                policy={violatedPolicy}
                resourceName="incident"
                level={policyViolation.level}
                daysUntil={policyViolation.days}
                violationID={policyViolation.id}
              />
            </dt>
          ) : null}
        </div>
      </div>

      {/* Sharing section */}
      {!!(
        document &&
        !destroying &&
        !retrying &&
        latestPostmortemShareMessage
      ) && (
        <div>
          <p className="text-sm text-content-tertiary">
            {latestPostmortemShareMessage.slack_message_url ? (
              <>
                {"Last shared in "}
                <Link
                  href={latestPostmortemShareMessage.slack_message_url}
                  analyticsTrackingId="view-shared-postmortem"
                  className="!text-content-tertiary no-underline hover:underline"
                  openInNewTab
                >
                  #{latestPostmortemShareMessage.slack_channel_name}
                </Link>
                {" on "}
                <LocalDateTime timestamp={latestPostmortemShare.updated_at} />
              </>
            ) : (
              <>
                <span>Last shared on </span>
                <LocalDateTime timestamp={latestPostmortemShare.updated_at} />
              </>
            )}
          </p>
        </div>
      )}

      {/* Modals */}
      {showCreateModal && (
        <CreatePostMortemModal
          incident={incident}
          onClose={() => {
            setShowCreateModal(false);
          }}
        />
      )}
      {showShareModal && (
        <SharePostMortemModal
          incident={incident}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      )}
    </div>
  );
};

const getDocumentProvider = (
  document: PostmortemDocument,
): {
  providerName: string;
  providerIcon: IconEnum;
} => {
  const config =
    !!document.document_provider &&
    !document.document_provider.startsWith("copy_paste_") &&
    IntegrationConfigFor(
      document.document_provider as unknown as IntegrationSettingsProviderEnum,
    );
  if (config && config.icon) {
    return {
      providerName: _.startCase(document.document_provider),
      providerIcon: config.icon,
    };
  } else {
    const docProvider = inferDocumentProviderFromPermalink({
      permalink: document.permalink,
    });

    return {
      providerName: _.startCase(docProvider),
      providerIcon: iconForDocumentProvider({ documentProvider: docProvider }),
    };
  }
};

const PostMortemCreationFailureTooltip = ({
  postmortemNameFormatted,
  provider,
  providerError,
  children,
}: {
  postmortemNameFormatted: string;
  provider: string | undefined;
  providerError: string | undefined;
  children: React.ReactElement;
}): React.ReactElement => (
  <Tooltip
    content={
      <div>
        <p className="mb-2">
          {`There was an error whilst creating the ${postmortemNameFormatted}`}
        </p>
        {provider && providerError ? (
          <>
            <p className="mb-1">Latest error returned from {provider}:</p>
            <code className="whitespace-pre-wrap">
              {providerError.replaceAll("\\n", "\n").replaceAll('\\"', "'")}
            </code>
          </>
        ) : null}
      </div>
    }
  >
    {children}
  </Tooltip>
);

const inferDocumentProviderFromPermalink = ({
  permalink,
}: {
  permalink: string;
}): string | undefined => {
  if (permalink.includes("atlassian.net/wiki/")) {
    return "confluence";
  }
  if (permalink.includes("https://docs.google.com/")) {
    return "google_docs";
  }
  if (permalink.includes("https://www.notion.so/")) {
    return "notion";
  }
  if (permalink.includes("https://paper.dropbox.com")) {
    return "dropbox";
  }

  return undefined;
};

const iconForDocumentProvider = ({
  documentProvider,
}: {
  documentProvider?: string;
}): IconEnum => {
  if (!documentProvider) {
    return IconEnum.File;
  }

  const iconsByDocumentProvider = {
    confluence: IconEnum.Confluence,
    dropbox: IconEnum.Dropbox,
    google_docs: IconEnum.GoogleDocs,
    notion: IconEnum.Notion,
  };

  return iconsByDocumentProvider[documentProvider] || IconEnum.File;
};

const PostmortemStatusBadge = ({
  status,
  loading,
}: {
  status: PostmortemDocumentStatusEnum;
  loading: boolean;
}): React.ReactElement => {
  if (loading) {
    return (
      <Badge
        theme={BadgeTheme.Unstyled}
        className="bg-surface-secondary border border-blue-200"
        size={BadgeSize.ExtraSmall}
      >
        <div className="flex justify-center w-8">
          <Spinner />
        </div>
      </Badge>
    );
  }

  switch (status) {
    case PostmortemDocumentStatusEnum.Created:
      return (
        <Badge theme={BadgeTheme.Info} size={BadgeSize.ExtraSmall}>
          Created
        </Badge>
      );
    case PostmortemDocumentStatusEnum.Review:
      return (
        <Badge theme={BadgeTheme.Warning} size={BadgeSize.ExtraSmall}>
          In review
        </Badge>
      );
    case PostmortemDocumentStatusEnum.Complete:
      return (
        <Badge theme={BadgeTheme.Success} size={BadgeSize.ExtraSmall}>
          Completed
        </Badge>
      );
    default:
      return <></>;
  }
};
