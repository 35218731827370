import { HeaderBar } from "@incident-shared/layout/HeaderBar/HeaderBar";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Heading,
  IconEnum,
} from "@incident-ui";
import { Incident } from "src/contexts/ClientContext";

import { ActivityLogWrapper } from "./ActivityLog";
import { SortOrder, TimelineWrapper } from "./Timeline";

export const TimelineEditor = ({ incident }: { incident: Incident }) => {
  return (
    <PageWrapper
      width={PageWidth.Full}
      noPadding
      headerNode={<TimelineEditorHeader incident={incident} />}
      overflowY={false}
      title={"Timeline editor"}
    >
      <div className={"flex flex-col lg:flex-row h-full"}>
        <div className={"flex flex-col px-8 py-6 h-full w-full lg:w-[50%]"}>
          <div className="overflow-y-auto grow">
            <Heading level={3} size="medium" className="mb-4">
              Timeline
            </Heading>
            <TimelineWrapper
              incidentId={incident.id}
              sortOrder={SortOrder.OldestFirst}
              activeFilters={[]}
              isEditMode={true}
            />
          </div>
        </div>
        <div className={"flex flex-col px-8 py-6 h-full w-full lg:w-[50%]"}>
          <div className="overflow-y-auto grow">
            <Heading level={3} size="medium" className="mb-4">
              Activity log
            </Heading>
            <ActivityLogWrapper
              incidentId={incident.id}
              sortOrder={SortOrder.OldestFirst}
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

const TimelineEditorHeader = ({
  incident,
}: {
  incident: Incident;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const backHref = `/incidents/${incident.external_id}?tab=timeline`;

  return (
    <>
      <HeaderBar
        title="Timeline editor"
        className="px-4 md:px-8"
        icon={IconEnum.Incident}
        backOnClick={() => navigate(backHref, { replace: true })}
        crumbs={[
          {
            title: "Incidents",
            to: "/incidents",
          },
          {
            title: `INC-${incident.external_id}: ${incident.name}`,
            onClick: (_) => navigate(backHref, { replace: true }),
          },
        ]}
        accessory={
          <Button
            analyticsTrackingId={"exit-timeline-editor"}
            title="Done"
            theme={ButtonTheme.Primary}
            onClick={() => navigate(backHref, { replace: true })}
          >
            Done
          </Button>
        }
      />
      <Callout theme={CalloutTheme.Plain} className="!pl-8 mb-2">
        Edit your timeline by adding events from the activity log, or by
        hovering between timeline items and clicking &apos;Add event&apos;. Any
        changes will be reflected in your postmortem. You will need to leave the
        editor to add comments.
      </Callout>
    </>
  );
};
