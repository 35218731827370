// Theme
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "lodash";
import "tinymce/icons/default";
// Useful plugins that tinymce supports
// this one automatically links a URL
import "tinymce/plugins/autolink";
// this one provides a nice interface for creating links
import "tinymce/plugins/link";
// this one does pattern matching for auto formatting (see below)
import "tinymce/plugins/textpattern";
// Our custom styles
import "./richtexteditor.css";

import { Loader } from "@incident-ui";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import {
  htmlToMarkdown,
  htmlToMrkdwn,
  markdownToHTML,
  mrkdwnToHtml,
} from "src/utils/rich-text";
import { TinyMCE } from "tinymce";

export type InterpolatedVariable = { label: string; key: string };

export type Format = "mrkdwn" | "html" | "markdown" | "plain" | "basic";
const getConfig = (format: Format): Config => {
  switch (format) {
    case "mrkdwn":
      return {
        // It also has funky newline formatting, which is covered above
        htmlTransformer: (html: string) => Promise.resolve(htmlToMrkdwn(html)),
        // All newlines should be represented as <br>. The editor is configured to
        // only produce <br>, rather than <p> elements.
        initialValueTransformer: (input: string) =>
          Promise.resolve(mrkdwnToHtml(input)),
        // Slack mrkdwn can't do headings
        toolbar: `undo redo | bold italic | link`,
      };
    case "markdown":
      return {
        toolbar: `undo redo | h1 h2 h3 bold italic | link`,
        initialValueTransformer: (input) => markdownToHTML(input),
        htmlTransformer: htmlToMarkdown,
      };
    case "html":
      return {
        htmlTransformer: (html: string) => Promise.resolve(html),
        // initialValueTransformer=identity is fine for this!
        toolbar: `undo redo | h1 h2 h3 bold italic | link`,
        initialValueTransformer: (input: string) => Promise.resolve(input),
      };
    case "plain":
      return {
        toolbar: `undo redo`,
        htmlTransformer: (html: string) => Promise.resolve(htmlToMrkdwn(html)),
        initialValueTransformer: (input: string) =>
          Promise.resolve((input || "").replace(/\n/g, "<br/>\n")),
      };
    case "basic":
      return {
        toolbar: `undo redo | bold italic | link`,
        htmlTransformer: (html: string) => Promise.resolve(htmlToMrkdwn(html)),
        initialValueTransformer: (input: string) =>
          Promise.resolve((input || "").replace(/\n/g, "<br/>\n")),
      };
    default:
      throw new Error("Unreachable: invalid text editor format");
  }
};

type Props = {
  id: string;
  placeholder?: string;
  initialValue: string;
  // This receives the Slack mrkdwn contents of the editor each time it is changed
  onChange: (value: string) => void;
  variables?: Array<InterpolatedVariable>;
  format?: Format;
};

declare global {
  interface Window {
    tinyMCE: TinyMCE;
  }
}

type Config = {
  htmlTransformer: HtmlTransformerFunc;
  initialValueTransformer: InitialValueTransformerFunc;
  toolbar: string;
};
type HtmlTransformerFunc = (html: string) => Promise<string>;
type InitialValueTransformerFunc = (input: string) => Promise<string>;

export const DeprecatedTextEditor = ({
  id,
  initialValue = "",
  placeholder,
  onChange,
  format = "mrkdwn",
}: Props): React.ReactElement => {
  const handleEditorChange = (value: string) => {
    onChange(value);
  };

  const [cachedInitialValue, setCachedInitialValue] = useState<string | null>(
    null,
  );

  // This editor accepts an initial value, but then transforms it to present it
  // in the DOM, and applies a transformation on the way back out too.
  //
  // Those transformations are not exact inverses, which means you can get into
  // an infinite loop if you repeatedly pass a value in and out of the editor.
  //
  // To avoid this, we initialise the editor just once with the value from the
  // outside world, and ignore any changes to the initial value that come back
  // into the editor component via a controller form component.
  //
  // That's what this useState is doing- it's a hack to only ever use the first
  // value we see, rather than each repeated value the editor might push to the
  // parent component.
  useEffect(() => {
    if (cachedInitialValue == null) {
      getConfig(format)
        .initialValueTransformer(initialValue)
        .then((val) => setCachedInitialValue(val));
    }
  }, [initialValue, format, cachedInitialValue]);

  // If we haven't parsed the initial value yet, show a loader until we have it.
  // This should be near-instant, but can't be guaranteed.
  if (cachedInitialValue == null) {
    return <Loader />;
  }

  const height = format === "plain" ? 100 : 300;

  const { htmlTransformer, toolbar } = getConfig(format);

  return (
    <>
      <Editor
        id={id}
        initialValue={cachedInitialValue}
        // @ts-expect-error this really does exist
        placeholder={placeholder}
        onEditorChange={(_, editor) => {
          const content = editor.getContent();

          htmlTransformer(content).then((outputContent) =>
            // This looks async, but that's because the libary we're using for
            // transforming markdown/html has an async API (because it can do
            // things to the filesystem), but we're not using it for anything
            // async, so this should happen very fast.
            handleEditorChange(outputContent),
          );
        }}
        init={{
          // Don't use the built in skin, we have our own (imported as css)
          skin: false,
          height: height,
          menubar: false,
          // Don't wrap the whole content in a <p>, because it confuses the
          // conversion to mrkdwn so use a div instead
          forced_root_block: "div",
          // Persuade the editor not to insert <p>, and to use <br> to
          // represent line breaks instead. This makes the editor a bit
          // 'dumber' which is more suitable when you consider this is
          // markdown, not a word document we're trying to construct.
          force_br_newlines: true,
          plugins: ["autolink textpattern link variable"],
          // We can add more buttons here if we like - in the future
          // we may wish to set this as a prop in the DeprecatedTextEditor component
          // so the parent component can control what displays here.
          toolbar,
          setup: (editor) => {
            // disable underlines, markdown doesn't support it!
            editor.on("init", function () {
              editor.addShortcut("meta+u", "", "");
            });
          },
          // This allows the editor to auto-format mrkdwn syntax
          textpattern_patterns: [
            { start: "*", end: "*", format: "bold" },
            { start: "_", end: "_", format: "italic" },
            { start: "~", end: "~", format: "strikethrough" },
          ],
          // The CSS file for the editor content (inc variables) this is
          // rendered in an iframe, so needs to be in our public folder so
          // can't be transpiled or anything
          content_css: "/richtexteditor-content.css",
          content_css_cors: true,
          // Remove the "powered by tiny" at the bottom
          branding: false,
          // Remove the element breadcrumb menu at the bottom
          elementpath: false,
        }}
      />
    </>
  );
};
