/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentLearningsCreateRequestBody,
    IncidentLearningsCreateRequestBodyFromJSON,
    IncidentLearningsCreateRequestBodyToJSON,
    IncidentLearningsCreateResponseBody,
    IncidentLearningsCreateResponseBodyFromJSON,
    IncidentLearningsCreateResponseBodyToJSON,
    IncidentLearningsShareLearningsRequestBody,
    IncidentLearningsShareLearningsRequestBodyFromJSON,
    IncidentLearningsShareLearningsRequestBodyToJSON,
    IncidentLearningsShareLearningsResponseBody,
    IncidentLearningsShareLearningsResponseBodyFromJSON,
    IncidentLearningsShareLearningsResponseBodyToJSON,
    IncidentLearningsUpdateRequestBody,
    IncidentLearningsUpdateRequestBodyFromJSON,
    IncidentLearningsUpdateRequestBodyToJSON,
    IncidentLearningsUpdateResponseBody,
    IncidentLearningsUpdateResponseBodyFromJSON,
    IncidentLearningsUpdateResponseBodyToJSON,
} from '../models';

export interface IncidentLearningsCreateRequest {
    createRequestBody: IncidentLearningsCreateRequestBody;
}

export interface IncidentLearningsShareLearningsRequest {
    incidentId: string;
    shareLearningsRequestBody: IncidentLearningsShareLearningsRequestBody;
}

export interface IncidentLearningsUpdateRequest {
    id: string;
    updateRequestBody: IncidentLearningsUpdateRequestBody;
}

/**
 * 
 */
export class IncidentLearningsApi extends runtime.BaseAPI {

    /**
     * Creates a learning for an incident
     * Create IncidentLearnings
     */
    async incidentLearningsCreateRaw(requestParameters: IncidentLearningsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLearningsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling incidentLearningsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentLearningsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLearningsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates a learning for an incident
     * Create IncidentLearnings
     */
    async incidentLearningsCreate(requestParameters: IncidentLearningsCreateRequest, initOverrides?: RequestInit): Promise<IncidentLearningsCreateResponseBody> {
        const response = await this.incidentLearningsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Shares the learnings for an incident to a slack channel
     * ShareLearnings IncidentLearnings
     */
    async incidentLearningsShareLearningsRaw(requestParameters: IncidentLearningsShareLearningsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLearningsShareLearningsResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentLearningsShareLearnings.');
        }

        if (requestParameters.shareLearningsRequestBody === null || requestParameters.shareLearningsRequestBody === undefined) {
            throw new runtime.RequiredError('shareLearningsRequestBody','Required parameter requestParameters.shareLearningsRequestBody was null or undefined when calling incidentLearningsShareLearnings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/share/{incident_id}`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentLearningsShareLearningsRequestBodyToJSON(requestParameters.shareLearningsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLearningsShareLearningsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Shares the learnings for an incident to a slack channel
     * ShareLearnings IncidentLearnings
     */
    async incidentLearningsShareLearnings(requestParameters: IncidentLearningsShareLearningsRequest, initOverrides?: RequestInit): Promise<IncidentLearningsShareLearningsResponseBody> {
        const response = await this.incidentLearningsShareLearningsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a learning for an incident
     * Update IncidentLearnings
     */
    async incidentLearningsUpdateRaw(requestParameters: IncidentLearningsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLearningsUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentLearningsUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling incidentLearningsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentLearningsUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLearningsUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates a learning for an incident
     * Update IncidentLearnings
     */
    async incidentLearningsUpdate(requestParameters: IncidentLearningsUpdateRequest, initOverrides?: RequestInit): Promise<IncidentLearningsUpdateResponseBody> {
        const response = await this.incidentLearningsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
