import { FormHelpTextV2 } from "@incident-shared/forms/v2/FormInputWrapperV2";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { PopoverMultiSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import {
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  GenericErrorMessage,
  Heading,
  LoadingBar,
} from "@incident-ui";
import { Searcher } from "fast-fuzzy";
import { sortBy } from "lodash";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { StaffPermissionEnum } from "src/hooks/useCheckStaffPermissions";
import { useAPI } from "src/utils/swr";

import { permissionOptions } from "./staffPermissions";
import { StaffUserRow } from "./StaffUserRow";

export const StaffPermissionsPage = () => {
  const {
    data: { users },
    isLoading,
    error,
  } = useAPI("staffListUsers", undefined, {
    fallbackData: { users: [] },
  });

  const userSearcher = useMemo(
    () => new Searcher(users, { keySelector: (user) => user.name }),
    [users],
  );

  const formMethods = useForm({
    defaultValues: {
      search: "",
      hasPermissions: [] as StaffPermissionEnum[],
    },
  });

  const [search, requiredPermissions] = formMethods.watch([
    "search",
    "hasPermissions",
  ]);
  const filteredUsers = useMemo(() => {
    let filteredUsers = users;
    if (search) {
      filteredUsers = userSearcher.search(search);
    }
    if (requiredPermissions.length > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        requiredPermissions.every((permission) =>
          (user.permissions as unknown as StaffPermissionEnum[]).includes(
            permission,
          ),
        ),
      );
    }

    return sortBy(filteredUsers, "name");
  }, [users, search, requiredPermissions, userSearcher]);

  if (error) {
    return <GenericErrorMessage error={error} />;
  }
  if (isLoading) {
    return <LoadingBar className="h-32" />;
  }

  return (
    <>
      <div>
        <Heading level={3}>Staff members</Heading>
        <FormHelpTextV2>
          All staff members have access to this staff area of the dashboard.
          They can view the list of organisations, the plan they are on, and
          which features they have access to. Additional permissions can be
          granted to allow people to edit these settings.
        </FormHelpTextV2>
      </div>
      <FormV2
        formMethods={formMethods}
        onSubmit={() => null}
        innerClassName="flex !space-y-0 gap-4 w-full"
      >
        <InputV2
          formMethods={formMethods}
          name="search"
          placeholder="By name..."
          className="flex-1"
        />
        <PopoverMultiSelectV2
          formMethods={formMethods}
          name="hasPermissions"
          placeholder="By permission..."
          className="flex-1"
          options={permissionOptions}
        />
      </FormV2>
      <DeprecatedTable className="w-fit min-w-full">
        <DeprecatedTableHeaderRow>
          <DeprecatedTableHeaderCell>Name</DeprecatedTableHeaderCell>
          <DeprecatedTableHeaderCell>Permissions</DeprecatedTableHeaderCell>
          <DeprecatedTableHeaderCell>&nbsp;</DeprecatedTableHeaderCell>
        </DeprecatedTableHeaderRow>
        <tbody>
          {filteredUsers.map((user) => (
            <StaffUserRow key={user.id} user={user} />
          ))}
        </tbody>
      </DeprecatedTable>
    </>
  );
};
