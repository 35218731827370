import {
  StatusPage,
  StatusPageCreateMigrationRequestBodyTypeEnum,
} from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
  ModalFooter,
  ProgressBar,
} from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { PollMigrationModal } from "./PollMigrationModal";

type FormType = {
  pageId: string;
};

export const ImportModal = ({ onClose }: { onClose: () => void }) => {
  const formMethods = useForm<FormType>();
  const {
    data: { available_migrations: availablePages },
    isLoading,
  } = useAPI("statusPageListAvailableMigrations", undefined, {
    fallbackData: { available_migrations: [] },
  });

  const [migrationId, setMigrationId] = useState<string | null>(null);
  const [completedData, setCompletedData] = useState<{
    page: StatusPage;
    prevCompleted: number;
    prevTotal: number;
  } | null>(null);
  const navigate = useOrgAwareNavigate();
  const { trigger, isMutating } = useAPIMutation(
    "statusPageList",
    undefined,
    async (apiClient, { pageId }) => {
      const res = await apiClient.statusPageCreateMigration({
        createMigrationRequestBody: {
          type: StatusPageCreateMigrationRequestBodyTypeEnum.Import,
          atlassian_page_id: pageId,
        },
      });
      setMigrationId(res.migration.id);
    },
  );

  if (completedData != null) {
    const goToSettings = () => {
      navigate(`/status-pages/${completedData.page.id}/settings`);
      onClose();
    };

    return (
      <Modal
        isOpen
        suppressInitialAnimation
        title="Import from Statuspage"
        analyticsTrackingId={"status-page-import"}
        onClose={goToSettings}
        disableQuickClose={false}
      >
        <ModalContent className="space-y-3 text-sm">
          <p className="font-medium">Your shiny new page is ready!</p>
          <ProgressBar
            numCompleted={completedData.prevTotal}
            numTotal={completedData.prevTotal}
            barClassName="duration-1000"
            transitionCompletedFrom={completedData.prevCompleted}
          />
          <p>
            We&apos;ve imported your components, logo, and incident and
            maintenance history.
          </p>

          <p>
            Check out your imported page, or adjust your settings. Once
            you&apos;re ready to complete the switch, click &lsquo;Go
            live&rsquo;.
          </p>
        </ModalContent>
        <ModalFooter>
          <div className="flex gap-2">
            <Button
              analyticsTrackingId={"status-page-import-configure"}
              onClick={goToSettings}
            >
              Customise
            </Button>

            <Button
              href={completedData.page.public_url}
              openInNewTab
              analyticsTrackingId={"status-page-view-page"}
              onClick={goToSettings}
              theme={ButtonTheme.Primary}
            >
              View page
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  if (migrationId != null) {
    return (
      <PollMigrationModal
        migrationId={migrationId}
        verb="importing your page"
        onSuccess={(page, prevCompleted, prevTotal) =>
          setCompletedData({ page, prevCompleted, prevTotal })
        }
        onClose={onClose}
      />
    );
  }

  return (
    <FormModalV2
      onClose={onClose}
      formMethods={formMethods}
      onSubmit={trigger}
      title="Import from Statuspage"
      analyticsTrackingId={"status-page-import"}
      loading={isLoading}
      saving={isMutating}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          confirmButtonText="Import"
          disabled={!formMethods.formState.isValid}
        />
      }
      innerClassName="text-sm"
    >
      <p className="!mb-2">To get things started, we&apos;ll:</p>
      <ul className="list-disc space-y-2 pl-4 !mt-2">
        <li>Copy over your logo, page setup and components</li>
        <li>Import your incident and maintenance history</li>
        <li>
          Sync across changes to incidents and maintenance windows posted to
          your Atlassian Statuspage
        </li>
        <li>
          Hide the new page in <code>/incident statuspage</code> until you
          complete the switch
        </li>
      </ul>
      <Callout theme={CalloutTheme.Plain}>
        We <span className="font-semibold">won&apos;t</span> import your
        subscriber list until you&apos;re ready to go live.
      </Callout>
      <hr />
      <StaticSingleSelectV2
        label="Select a page"
        labelWrapperClassName="mb-1"
        formMethods={formMethods}
        name={"pageId"}
        required="Please select a page"
        options={availablePages.map((page) => ({
          label: page.name,
          value: page.atlassian_page_id,
        }))}
      />
    </FormModalV2>
  );
};
