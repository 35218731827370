import { Product } from "@incident-shared/billing";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ConfigureDrawerProps } from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Heading,
} from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import { DrawerBody, DrawerFooter } from "@incident-ui/Drawer/Drawer";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import {
  IntegrationsJiraServerUpdateAutoExportSettingsRequestBody,
  JiraServerConfig,
  ScopeNameEnum,
  Settings,
  Severity,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { IntegrationSettingsSection } from "../../common/IntegrationSettingsSection";
import { IntegrationDrawerContentLoader } from "../IntegrationDrawer";
import { ConfigureJiraServerAutoExport } from "./ConfigureJiraServerAutoExport";

export const JiraServerConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const { settings } = useSettings();

  const {
    data: { severities },
    isLoading: loading,
  } = useAPI("severitiesList", undefined, { fallbackData: { severities: [] } });

  const { data: jiraConfigData, error: jiraConfigError } = useAPI(
    "integrationsJiraServerGetConfig",
    undefined,
  );
  const jiraConfig = jiraConfigData?.config;
  if (jiraConfigError) {
    return <GenericErrorMessage error={jiraConfigError} />;
  }

  if (loading || !jiraConfig || settings == null) {
    return <IntegrationDrawerContentLoader />;
  }

  return (
    <ConfigureJiraModalInner
      severities={severities}
      settings={settings}
      existingConfig={jiraConfig}
      {...props}
    />
  );
};

const ConfigureJiraModalInner = ({
  severities,
  existingConfig,
  aboutThis,
  connectionError,
  catalogTypeList,
}: {
  severities: Severity[];
  settings: Settings;
  existingConfig: JiraServerConfig;
} & ConfigureDrawerProps): React.ReactElement | null => {
  const { hasScope } = useIdentity();
  const { airbnbJiraServerAutoExport } = useFlags();

  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const canSeePrivateIncidents = hasScope(ScopeNameEnum.IncidentsGlobalAccess);
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/settings/integrations");

  const enabled = existingConfig.auto_export_enabled;

  const formMethods =
    useForm<IntegrationsJiraServerUpdateAutoExportSettingsRequestBody>({
      defaultValues: {
        auto_export_enabled: enabled,
        auto_export_config: existingConfig.auto_export_config || {
          include_private_incidents: false,
        },
      },
      mode: "onSubmit",
    });

  const {
    setError,
    reset,
    watch,
    formState: { isDirty },
  } = formMethods;

  const [autoExportEnabled, projectId, issueTypeId, includePrivateIncidents] = [
    watch("auto_export_enabled"),
    watch("auto_export_config.project_id"),
    watch("auto_export_config.issue_type_id"),
    watch("auto_export_config.include_private_incidents"),
  ];

  const privateIncidentSettingValid =
    canSeePrivateIncidents || !includePrivateIncidents;

  const isValid =
    (projectId != null && issueTypeId != null && privateIncidentSettingValid) ||
    !autoExportEnabled;

  const { refetchSettings } = useSettings();
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsJiraServerGetConfig",
    undefined,
    async (apiClient, data) => {
      return await apiClient.integrationsJiraServerUpdateAutoExportSettings({
        updateAutoExportSettingsRequestBody: data,
      });
    },
    {
      onSuccess: () => {
        // I don't understand why we can't re-pass the values into this form,
        // but this definitely works so let's go with it.
        // When I used reset(data) I found that the form would reset to step 1,
        // even though there was still a project ID. Very strange.
        reset(undefined, { keepValues: true });
        refetchSettings();
        onClose();
      },
      setError,
    },
  );

  let helpText = (
    <>{`When enabled, we’ll automatically create a ticket in Jira whenever you create an incident with a specified minimum severity.`}</>
  );
  if (airbnbJiraServerAutoExport) {
    // Hack for Airbnb: we have some special logic for auto creating or connecting to tickets in Jira Server.
    helpText = (
      <>
        {`When enabled, we’ll ensure that every incident becomes attached to a Jira Server ticket.`}
        <ul className="p-2 pl-4 list-disc">
          <li>
            {
              "If you declare the incident within incident.io, we'll create a ticket in Jira Server."
            }
          </li>
          <li>
            {
              "If the incident is triggered via PagerDuty, we'll attach the incident to the ticket that the page relates to."
            }
          </li>
        </ul>
      </>
    );
  }

  const { showArticle } = useIntercom();

  const orgId = useIdentity().identity.organisation_id;

  const webhookUrl = `https://app.incident.io/webhooks/jira_server/${orgId}`;
  return (
    <>
      <DrawerBody>
        {connectionError}
        {aboutThis}
        <div className="space-y-2">
          <Heading level={3}>Configure webhooks</Heading>
          <p>
            Sync changes in Jira Server back to incident.io faster using
            webhooks. Follow{" "}
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId="jira-server-webhook-guide"
              onClick={() => showArticle(9557297)}
            >
              {" "}
              our guide to configuring a Jira Server webhook
            </Button>
            . Your webhook URL is:
          </p>
          <CodeBlock hideHeader hideLineNumbers code={webhookUrl} />
        </div>

        {catalogTypeList}
        <FormV2
          formMethods={formMethods}
          onSubmit={onSubmit}
          saving={saving}
          genericError={genericError}
          id="configure-jira-server"
        >
          <IntegrationSettingsSection
            enabled={autoExportEnabled}
            enabledID="auto_export_enabled"
            label="Sync incidents with Jira Server"
            helptext={helpText}
            disabled={!canEditSettings}
            requiredProduct={Product.Response}
          >
            <ConfigureJiraServerAutoExport severities={severities} />
          </IntegrationSettingsSection>
        </FormV2>
      </DrawerBody>
      <DrawerFooter className="flex justify-end items-center gap-2">
        <Button onClick={onClose} analyticsTrackingId="jira-configure-cancel">
          Cancel
        </Button>
        <GatedButton
          theme={ButtonTheme.Primary}
          disabled={!(isDirty && isValid)}
          disabledTooltipContent={
            !privateIncidentSettingValid
              ? "Only incident.io owners can save when including private incidents"
              : undefined
          }
          type="submit"
          analyticsTrackingId="jira-configure-submit"
          form="configure-jira-server"
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        >
          Save
        </GatedButton>
      </DrawerFooter>
    </>
  );
};
