import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Input,
} from "@incident-ui";
import { DynamicSingleSelectWithObj } from "@incident-ui/Select/DynamicSingleSelect";
import { useState } from "react";
import { FieldValues, Path, useController } from "react-hook-form";
import {
  getTypeaheadOptions,
  TypeaheadTypeEnum,
} from "src/components/@shared/forms/Typeahead";
import { useClient } from "src/contexts/ClientContext";

import { FormInputWrapperV2 } from "../FormInputWrapperV2";
import { InputElementProps, parseProps } from "../formsv2";
import { SlackChannelFormValue } from "./SlackChannelsEditorV2";

export const SlackChannelEditorV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, SlackChannelEditorProps>,
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const {
    // We don't want to pass the ref onwards here: react-select and our wrappers
    // have their own refs, so we always use a controlled input here.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapperV2<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <SlackChannelEditor {...inputProps} {...field} />
    </FormInputWrapperV2>
  );
};

type SlackChannelEditorProps = {
  name: string;
  initialValue?: SlackChannelFormValue;
  value: SlackChannelFormValue;
  onChange: (val: SlackChannelFormValue | undefined) => void;
  onBlur: () => void;
  disallowPrivateChannels?: boolean;
  hideWarning?: boolean;
};

const SlackChannelEditor = ({
  name,
  value,
  onChange,
  onBlur,
  initialValue,
  disallowPrivateChannels,
  hideWarning,
}: SlackChannelEditorProps): React.ReactElement => {
  const [usePrivateChannel, setUsePrivateChannel] = useState(
    !!initialValue?.is_private,
  );
  const apiClient = useClient();

  return (
    <>
      {usePrivateChannel ? (
        <PrivateChannelInput name={`${name}.value`} hideWarning={hideWarning} />
      ) : (
        <DynamicSingleSelectWithObj
          value={value}
          onChange={(val) =>
            onChange(val ? { ...val, is_private: false } : undefined)
          }
          loadOptions={getTypeaheadOptions(
            apiClient,
            TypeaheadTypeEnum.SlackChannel,
          )}
          onBlur={onBlur}
          placeholder="Select channels"
        />
      )}
      {!disallowPrivateChannels && (
        <ToggleUsePrivateChannelButton
          usePrivateChannel={usePrivateChannel}
          onClick={() => {
            onChange(
              usePrivateChannel
                ? undefined
                : { is_private: true, label: "", value: "" },
            );
            setUsePrivateChannel(!usePrivateChannel);
          }}
        />
      )}
    </>
  );
};

type PrivateChannelInputProps = {
  name: string;
  hideWarning?: boolean;
};

export const PrivateChannelInput = ({
  name,
  hideWarning = false,
}: PrivateChannelInputProps): React.ReactElement => {
  const { field } = useController({ name });

  return (
    <div>
      <div className="flex items-center text-sm gap-1">
        <div className="text-slate-700 shrink-0">Private channel ID</div>
        <Input id={name} {...field} />
      </div>
      <div className="mt-2 text-slate-600 text-sm">
        You can find the ID of a channel by opening the channel settings in
        Slack, it&apos;s written at the bottom of the screen in light grey.
      </div>
      {!hideWarning && (
        <Callout theme={CalloutTheme.Warning} className="mt-4">
          All admins and owners will be able to see the name of this private
          channel
        </Callout>
      )}
    </div>
  );
};

export const ToggleUsePrivateChannelButton = ({
  usePrivateChannel,
  onClick,
  disabled,
}: {
  usePrivateChannel: boolean;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button
      analyticsTrackingId={
        usePrivateChannel ? "use-public-channel" : "use-private-channel"
      }
      className="mt-3 underline"
      theme={ButtonTheme.Naked}
      onClick={onClick}
      disabled={disabled}
    >
      {usePrivateChannel
        ? "Choose from public channels"
        : "Use a private channel"}
    </Button>
  );
};
