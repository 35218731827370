import { FormHelpText } from "@incident-shared/forms/v1/FormInputHelpers";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  IconSize,
  Input,
  Tooltip,
} from "@incident-ui";
import { InputProps, InputType } from "@incident-ui/Input/Input";
import { Popover } from "@incident-ui/Popover/Popover";
import { addYears, subYears } from "date-fns";
import { DateTime } from "luxon";
import { ChangeEvent, useState } from "react";
import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseFormReturn,
} from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

import { FormInputWrapperV2 } from "../FormInputWrapperV2";
import { InputElementProps, parseProps } from "../formsv2";

export const DateTimeInputV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, Omit<InputProps, "type">> & {
    onBlurCallback?: () => void;
    onValueChange?: (val: Date) => void;
    timezone?: string;
    displayNowButton?: boolean;
    includeSeconds?: boolean;
    clearable?: boolean;
  },
): React.ReactElement => {
  const { onValueChange, timezone, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps(rest);
  const { field, fieldState } = useController({
    name,
    rules,
  });
  let insetSuffixNode = props.displayNowButton ? (
    <Button
      onClick={() =>
        props.formMethods.setValue(
          name,
          new Date() as PathValue<TFormType, Path<TFormType>>,
        )
      }
      analyticsTrackingId=""
      theme={ButtonTheme.Naked}
      className="ml-1 text-xs"
    >
      Now
    </Button>
  ) : (
    props.insetSuffixNode
  );

  const { value: valueAsDate, onChange } = field;

  if (props.clearable) {
    insetSuffixNode = (
      <>
        {insetSuffixNode}
        <Button
          className="ml-1 text-xs"
          analyticsTrackingId=""
          theme={ButtonTheme.Naked}
          icon={IconEnum.Close}
          title="Clear date"
          onClick={() => {
            props.formMethods.setValue(
              name,
              null as PathValue<TFormType, Path<TFormType>>,
            );
          }}
        />
      </>
    );
  }

  const onChangeString = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return undefined;
    }
    const date = DateTime.fromISO(e.target.value, {
      zone: timezone || "local",
    }).toJSDate();
    const res = onChange(date);

    onValueChange && onValueChange(date);
    return res;
  };

  const format = props.includeSeconds
    ? "yyyy-MM-dd'T'HH:mm:ss"
    : "yyyy-MM-dd'T'HH:mm";

  return (
    <FormInputWrapperV2<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <Input
        id={name}
        type={InputType.DatetimeLocal}
        // Don't allow selecting something >10 years away from now.
        // Note that `inputProps` or `field` might override this.
        min={subYears(new Date(), 10).toDateString() + "T00:00"}
        max={addYears(new Date(), 10).toDateString() + "T00:00"}
        {...field}
        {...inputProps}
        invalid={!!fieldState.error}
        onChange={onChangeString}
        onBlur={() => {
          props.onBlurCallback && props.onBlurCallback();

          return field.onBlur();
        }}
        value={
          valueAsDate
            ? DateTime.fromJSDate(valueAsDate)
                .setZone(timezone || "local")
                .toFormat(format)
            : ""
        }
        step={props.includeSeconds ? "1" : "60"}
        insetSuffixNode={insetSuffixNode}
      />
    </FormInputWrapperV2>
  );
};

export const EditAsISOSuffix = <TFormType extends FieldValues>({
  id,
  formMethods,
  showClearButton = true,
  showCopyButton = true,
}: {
  id: Path<TFormType>;
  formMethods: UseFormReturn<TFormType>;
  showClearButton?: boolean;
  showCopyButton?: boolean;
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const val = formMethods.watch(id);
  const isoString: string | undefined =
    val?.toISOString && !isNaN(val) && val.toISOString();

  return (
    <div className="flex">
      <Popover
        onInteractOutside={() => setPopoverOpen(false)}
        side="right"
        align="start"
        alignOffset={-18}
        className="p-4"
        trigger={
          <Button
            theme={ButtonTheme.Naked}
            analyticsTrackingId={null}
            icon={IconEnum.Edit}
            iconProps={{ size: IconSize.Medium, className: "!mr-0" }}
            onClick={() => {
              setPopoverOpen(true);
            }}
          >
            <span></span>
          </Button>
        }
        open={popoverOpen}
      >
        <EditAsISOInner
          id={id}
          formMethods={formMethods}
          defaultValue={isoString}
        />
      </Popover>

      {showCopyButton && (
        <CopyToClipboardButton
          value={isoString}
          tooltipContent={
            <>
              <p>Copy to clipboard:</p>
              <p className="font-mono">{isoString}</p>
            </>
          }
        />
      )}
      {showClearButton && (
        <Button
          theme={ButtonTheme.Naked}
          analyticsTrackingId={null}
          icon={IconEnum.Close}
          iconProps={{ size: IconSize.Medium, className: "!mr-0" }}
          onClick={() => {
            // @ts-expect-error if you use undefined, which ts wants, it does nothing
            formMethods.setValue(id, null);
            formMethods.trigger();
          }}
        >
          <span></span>
        </Button>
      )}
    </div>
  );
};

const EditAsISOInner = <TFormType extends FieldValues>({
  id,
  formMethods,
  defaultValue,
}: {
  id: Path<TFormType>;
  formMethods: UseFormReturn<TFormType>;
  defaultValue?: string;
}): React.ReactElement => {
  const [value, setValue] = useState(defaultValue || "");
  const isEmpty = value === "";

  const p = Date.parse(value);
  const isValid = !isNaN(p);

  return (
    <div className="w-[18em] space-y-2">
      <Input
        id={id}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
          const p = Date.parse(e.target.value);
          if (!isNaN(p)) {
            const d = new Date(p);
            // @ts-expect-error At this point we cant know that the type of 'id' is a Date
            formMethods.setValue(id, d);
          }
        }}
        className="font-mono"
        placeholder="yyyy-MM-ddTHH:mm:ss.SSSZ"
        autoFocus={false}
      />
      <FormHelpText>
        Timestamps with no timezone will be interpreted as UTC.
      </FormHelpText>
      {!isEmpty && !isValid && (
        <Callout theme={CalloutTheme.Warning}>
          <p>Please enter in ISO 8601 format:</p>
          <span className="font-mono">yyyy-MM-ddTHH:mm:ss.SSSZ</span>
        </Callout>
      )}
    </div>
  );
};

const CopyToClipboardButton = ({
  tooltipContent,
  value,
}: {
  tooltipContent?: React.ReactNode;
  value?: string;
}) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();

  if (!value) {
    return (
      <Button
        theme={ButtonTheme.Naked}
        analyticsTrackingId={null}
        icon={IconEnum.Copy}
        iconProps={{
          size: IconSize.Medium,
          className: "!mr-0",
        }}
        disabled={true}
      >
        <span></span>
      </Button>
    );
  }

  return (
    <Tooltip
      analyticsTrackingId={null}
      content={tooltipContent}
      delayDuration={100}
    >
      <Button
        theme={ButtonTheme.Naked}
        analyticsTrackingId={null}
        icon={hasCopied ? IconEnum.Tick : IconEnum.Copy}
        className={tcx(
          "transition-none",
          hasCopied ? "!text-green-content" : undefined,
        )}
        iconProps={{
          size: IconSize.Medium,
          className: "!mr-0",
        }}
        onClick={() => {
          copyTextToClipboard(value);
        }}
      >
        <span></span>
      </Button>
    </Tooltip>
  );
};
