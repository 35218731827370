import { FormInputWrapper } from "@incident-shared/forms/v1/FormInputHelpers";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  Input,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  RadioButton,
} from "@incident-ui";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FieldErrorsImpl, useForm, UseFormRegister } from "react-hook-form";
import { SavedViewsCreateRequestBody } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPIMutation } from "src/utils/swr";

import { useSavedViews } from "./SavedViewContext";
import { useSavedViewStatePreview } from "./SavedViewStatePreviewContext";

type FormData = Pick<SavedViewsCreateRequestBody, "name">;

export const CreateOrUpdateSavedViewButtonModal = ({
  renderSavedViewPreview,
}: {
  renderSavedViewPreview?: () => React.ReactElement;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const {
    selectedSavedView,
    viewIsDirty,
    viewIsPreset,
    context,
    createSavedView,
    updateURLParamsOfSavedView,
  } = useSavedViews();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);

  useEffect(() => {
    setIsUpdateMode(!!selectedSavedView && !viewIsPreset);
  }, [selectedSavedView, viewIsPreset]);

  const {
    formState: { errors },
    setError,
    register,
    handleSubmit,
  } = useForm<FormData>();

  const { trigger: createOrUpdateSavedView } = useAPIMutation(
    "savedViewsList",
    { context },
    async (apiClient, data: FormData) => {
      if (isUpdateMode) {
        if (!selectedSavedView) {
          throw new Error("Unreachable: updating but don't have a saved view");
        }

        await updateURLParamsOfSavedView(apiClient, selectedSavedView);
      } else {
        await createSavedView(apiClient, data);
      }
    },
    { setError, onSuccess: () => setShowModal(false) },
  );

  if (!identity) {
    return <Loader />;
  }

  return (
    <>
      <GatedButton
        alwaysShownTooltipContent={
          selectedSavedView && !viewIsDirty
            ? "This view has no unsaved changes"
            : undefined
        }
        icon={IconEnum.Layer}
        theme={ButtonTheme.Secondary}
        className={"md:mr-0"}
        onClick={() => setShowModal(true)}
        upgradeRequiredProps={{
          gate: {
            type: "numeric",
            value: identity.feature_gates.saved_views_per_context_count,
            featureNameSingular: "saved view",
          },
          featureName: "saved views",
        }}
        upgradeRequired={
          identity.feature_gates.saved_views_per_context_count === 0
        }
        analyticsTrackingId={null}
      >
        <span className="mobile-hidden">Save view</span>
      </GatedButton>

      <Modal
        analyticsTrackingId="save-view-modal-opened"
        title="Save view"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        disableQuickClose
        onSubmit={handleSubmit(createOrUpdateSavedView)}
        as="form"
      >
        <CreateOrUpdateSavedViewsFormModalBody
          formMethods={{
            register,
            errors,
          }}
          onClose={() => setShowModal(false)}
          isUpdateMode={isUpdateMode}
          setIsUpdateMode={setIsUpdateMode}
          renderSavedViewPreview={renderSavedViewPreview}
        />
      </Modal>
    </>
  );
};

const CreateOrUpdateSavedViewsFormModalBody = ({
  onClose,
  formMethods,
  isUpdateMode,
  setIsUpdateMode,
}: {
  onClose: () => void;
  formMethods: {
    register: UseFormRegister<FormData>;
    errors: Partial<
      FieldErrorsImpl<{
        name: string;
      }>
    >;
  };
  isUpdateMode: boolean;
  setIsUpdateMode: Dispatch<SetStateAction<boolean>>;
  renderSavedViewPreview?: () => React.ReactElement;
}): React.ReactElement => {
  const { selectedSavedView, viewIsPreset } = useSavedViews();
  const urlParams = useQueryParams();
  const { PreviewComponent } = useSavedViewStatePreview();

  const { errors, register } = formMethods;

  const newSavedViewNameInput = (
    <FormInputWrapper id={"name"} errors={errors}>
      <Input
        id={"name"}
        autoFocus
        disabled={isUpdateMode}
        placeholder="Enter your view name here"
        {...register("name", {
          required: !isUpdateMode ? "Please enter a name" : false,
          maxLength: {
            value: 45,
            message: "Name must be less than 45 characters",
          },
          minLength: {
            value: 3,
            message: "Name must be at least 3 characters",
          },
        })}
      />
    </FormInputWrapper>
  );

  return (
    <>
      <ModalContent className="space-y-4 text-sm">
        <Callout showIcon theme={CalloutTheme.Info}>
          This view will be available to everyone in your organisation.
        </Callout>
        <div>
          {selectedSavedView && !viewIsPreset ? (
            <div className="space-y-2">
              <RadioButton
                id="new"
                label={
                  <div>
                    <div className="mb-2">
                      Create a new view with the following name:
                    </div>
                    {newSavedViewNameInput}
                  </div>
                }
                onChange={() => setIsUpdateMode(false)}
                value=""
                checked={!isUpdateMode}
                className="flex-grow-1 flex !items-baseline"
              />
              <RadioButton
                id="update"
                label={
                  <p>
                    Update <b>{selectedSavedView.name}</b> view
                  </p>
                }
                onChange={() => setIsUpdateMode(true)}
                value=""
                checked={isUpdateMode}
                className="flex !items-baseline"
              />
            </div>
          ) : (
            newSavedViewNameInput
          )}
          {PreviewComponent && (
            <>
              <div className="my-4">
                <hr />
              </div>
              <div className="space-y-3">
                <p>The following values will be saved as part of this view.</p>
                <PreviewComponent
                  urlParams={urlParams}
                  viewParams={
                    new URLSearchParams(selectedSavedView?.url_params ?? "")
                  }
                />
              </div>
            </>
          )}
        </div>
      </ModalContent>
      <ModalFooter
        confirmButtonType="submit"
        confirmButtonText="Save"
        onClose={onClose}
      />
    </>
  );
};
