import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { Loader, Modal } from "@incident-ui";
import { useIdentity } from "src/contexts/IdentityContext";

import { PayConfigCreateEditForm } from "../../../components/legacy/on-call-legacy/pay-configurations/PayConfigCreateEditForm";
import { SchedulesCreatePayConfigRequestBody } from "../../../contexts/ClientContext";
import { useAPIMutation } from "../../../utils/swr";

export const OnCallPayConfigsCreateRoute = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const { identity } = useIdentity();
  const backHref = "/on-call/pay-calculator/pay-configurations";
  const onClose = () => navigate(backHref);

  const {
    trigger: createPayConfig,
    isMutating: savingPayConfig,
    genericError: payConfigError,
  } = useAPIMutation(
    "schedulesListPayConfig",
    undefined,
    async (apiClient, data: SchedulesCreatePayConfigRequestBody) => {
      await apiClient.schedulesCreatePayConfig({
        createPayConfigRequestBody: data,
      });
    },
    {
      onSuccess: onClose,
    },
  );

  if (!identity) {
    return <Loader />;
  }

  if (!identity.feature_gates.on_call_calculator) {
    return <OrgAwareNavigate to="/on-call/pay-calculator" replace />;
  }

  return (
    <Modal
      analyticsTrackingId="create-pay-configuration"
      title="Add pay configuration"
      onClose={onClose}
      disableQuickClose
      isOpen
      isExtraLarge
    >
      <ErrorMessageUI message={payConfigError} />
      <div>
        {savingPayConfig ? (
          <Loader />
        ) : (
          <PayConfigCreateEditForm
            mode={Mode.Create}
            continueButtonText="Add pay configuration"
            onSubmit={createPayConfig}
            onClose={onClose}
          />
        )}
      </div>
    </Modal>
  );
};
