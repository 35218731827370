import {
  Trigger,
  Workflow,
  WorkflowsDisableWorkflowResponseBody,
  WorkflowsEnableWorkflowResponseBody,
  WorkflowsListWorkflowsResponseBody,
  WorkflowStateEnum,
  WorkflowsUpdateWorkflowResponseBody,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useContext } from "react";
import { UseFormReturn } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { useAPIMutation } from "src/utils/swr";

import { formStateToPayloadRequestBody } from "../../common/marshall";
import { ClonedWorkflow, WorkflowFormData } from "../../common/types";
import { useMutateWorkflow } from "../../hooks";
import { makeFormDefaultValues } from "../makeFormDefaultValues";
import { WorkflowsFormContext } from "../WorkflowsFormContext";

export type WorkflowMutations = {
  createWorkflow: (data: WorkflowFormData) => Promise<void>;
  updateWorkflow: (
    data: WorkflowFormData,
  ) => Promise<WorkflowsUpdateWorkflowResponseBody>;
  publishWorkflow: (
    data: WorkflowFormData,
  ) => Promise<WorkflowsUpdateWorkflowResponseBody>;
  disableWorkflow: () => Promise<WorkflowsDisableWorkflowResponseBody>;
  enableWorkflow: () => Promise<WorkflowsEnableWorkflowResponseBody>;
  deleteWorkflow: () => Promise<WorkflowsListWorkflowsResponseBody>;
  state: {
    isCreating: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isEnabling: boolean;
    isDisabling: boolean;
    isPublishing: boolean;
  };
  isMutating: boolean;
};

export const useMutationController = ({
  formMethods,
  workflow,
  trigger,
  isDraft,
  onCloseModal,
}: {
  formMethods: UseFormReturn<WorkflowFormData>;
  workflow?: Workflow | ClonedWorkflow;
  trigger?: Trigger;
  isDraft: boolean;
  onCloseModal: () => void;
}): WorkflowMutations => {
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();
  const apiClient = useClient();

  const { reset } = formMethods;

  // We need to make sure that when the form is submitted successfully, we reset
  // its state to the default values - e.g. so that the isDirty property is
  // cleared.
  const onSuccessFormReset = ({ workflow }) => {
    reset(makeFormDefaultValues({ workflow }));
  };

  const showSuccessToast = (title: string) => {
    showToast({
      theme: ToastTheme.Success,
      title,
      toastSide: ToastSideEnum.Bottom,
    });
  };

  const showErrorToast = (title: string) => {
    showToast({
      theme: ToastTheme.Error,
      title: title + " Please contact support if this happens again.",
      toastSide: ToastSideEnum.Bottom,
    });
  };

  const [createWorkflow, { saving: isCreating }] = useMutation(
    async (data) => {
      if (workflow?.id) {
        throw new Error("Workflow already exists, not creating.");
      }
      if (!trigger) {
        throw new Error("No trigger selected, not creating.");
      }

      const { workflow: newWorkflow } = await apiClient.workflowsCreateWorkflow(
        {
          createWorkflowRequestBody: {
            workflow: formStateToPayloadRequestBody(data),
            trigger: trigger.name,
          },
        },
      );

      onSuccessFormReset({ workflow: workflow });
      navigate(`/workflows/${newWorkflow.id}`);
    },
    {
      setError: formMethods.setError,
      onSuccess: () => {
        onCloseModal();
        showSuccessToast("Draft saved");
      },
      onError: () => {
        showErrorToast("There was an error saving your draft.");
      },
    },
  );

  const { trigger: updateWorkflow, isMutating: isUpdating } = useMutateWorkflow(
    workflow?.id || "",
    async (apiClient, data: WorkflowFormData) => {
      if (!workflow?.id) {
        return undefined;
      }

      return await apiClient.workflowsUpdateWorkflow({
        id: workflow.id,
        updateWorkflowRequestBody: {
          trigger: trigger?.name || "",
          workflow: formStateToPayloadRequestBody(data),
          pending_run_strategy: data.pending_run_strategy,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: (data: WorkflowsUpdateWorkflowResponseBody) => {
        if (data.workflow) {
          onSuccessFormReset({ workflow: data.workflow });
        }
        onCloseModal();
        showSuccessToast(isDraft ? "Draft saved" : "Workflow saved");
      },
      onError: () => {
        showErrorToast(
          isDraft
            ? "There was an error saving your draft."
            : "There was an error saving your workflow.",
        );
      },
    },
  );

  const { trigger: publishWorkflow, isMutating: isPublishing } =
    useMutateWorkflow(
      workflow?.id || "",
      async (apiClient, data: WorkflowFormData) => {
        if (!workflow?.id) {
          return undefined;
        }

        return await apiClient.workflowsUpdateWorkflow({
          id: workflow.id,
          updateWorkflowRequestBody: {
            trigger: trigger?.name || "",
            workflow: formStateToPayloadRequestBody({
              ...data,
              state: WorkflowStateEnum.Active,
            }),
            pending_run_strategy: data.pending_run_strategy,
          },
        });
      },
      {
        onSuccess: (data: WorkflowsUpdateWorkflowResponseBody) => {
          onSuccessFormReset({ workflow: data.workflow });
          onCloseModal();
          showSuccessToast("Workflow is live");
        },
        onError: () => {
          showErrorToast("There was an error saving your workflow.");
        },
      },
    );

  const { trigger: disableWorkflow, isMutating: isDisabling } =
    useMutateWorkflow(
      workflow?.id || "",
      async (client, { id }) => {
        return await client.workflowsDisableWorkflow({ id });
      },
      {
        onSuccess: (data: WorkflowsDisableWorkflowResponseBody) => {
          onSuccessFormReset({ workflow: data.workflow });
          onCloseModal();
          showSuccessToast("Workflow disabled");
        },
        onError: () => {
          showErrorToast("There was an error disabling this workflow.");
        },
      },
    );

  const { trigger: enableWorkflow, isMutating: isEnabling } = useMutateWorkflow(
    workflow?.id || "",
    async (client, { id }) => {
      return await client.workflowsEnableWorkflow({ id });
    },
    {
      onSuccess: (data: WorkflowsEnableWorkflowResponseBody) => {
        onSuccessFormReset({ workflow: data.workflow });
        showSuccessToast("Workflow enabled");
      },
      onError: () => {
        showErrorToast("There was an error enabling this workflow.");
      },
    },
  );

  const { trigger: deleteWorkflow, isMutating: isDeleting } = useAPIMutation(
    "workflowsListWorkflows",
    { incidentType: undefined },
    async (client, { id }) => {
      await client.workflowsDestroyWorkflow({ id });
    },
    {
      onSuccess: () => {
        showSuccessToast("Workflow deleted");
        onCloseModal();
        navigate("/workflows");
      },
      onError: () => {
        showErrorToast("There was an error deleting this workflow.");
      },
    },
  );

  // This is a fudge to make TS happy - we won't make any of these calls if we're in create mode (and
  // therefore have no id)
  const maybeWorkflowId = workflow?.id || "";

  return {
    createWorkflow,
    updateWorkflow,
    deleteWorkflow: () => deleteWorkflow({ id: maybeWorkflowId }),
    enableWorkflow: () => enableWorkflow({ id: maybeWorkflowId }),
    disableWorkflow: () => disableWorkflow({ id: maybeWorkflowId }),
    publishWorkflow,
    state: {
      isCreating,
      isUpdating,
      isDeleting,
      isEnabling,
      isDisabling,
      isPublishing,
    },
    isMutating:
      isCreating ||
      isUpdating ||
      isDeleting ||
      isPublishing ||
      isDisabling ||
      isEnabling,
  };
};

export const useWorkflowsMutations = (): WorkflowMutations => {
  const ctx = useContext(WorkflowsFormContext);
  if (ctx && ctx.mutations) {
    return ctx.mutations;
  }
  throw new Error(
    "useWorkflowsMutations must be used within a WorkflowsFormProvider",
  );
};
