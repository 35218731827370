import { SelectOption, SelectOptionGroup } from "@incident-ui/Select/types";

import { IInsightsContext } from "./InsightsContext";

export type FieldKey = string;
export type FieldConfig = (
  | {
      isArray: true;
    }
  | {
      isArray?: never;
    }
) &
  // I'm sure there's a nicer way to handle these 3 situations
  (| FieldConfigGroupOptionsDefined
    | FieldConfigOptionsDefined
    | FieldConfigGetOptions<StateConfig>
  );

export interface FieldConfigGroupOptionsDefined {
  isGroupedOptions: true;
  isGetOptions?: never;
  options: SelectOptionGroup[];
}
export interface FieldConfigOptionsDefined {
  isGroupedOptions?: never;
  getOptions?: never;
  isGetOptions?: never;
  options: SelectOption[];
  default?: (opts: SelectOption[]) => string;
}

// This is used if the options you provide are based on the current state
// E.g.: PINC task options are dependent on the currently selected PINC flow
export interface FieldConfigGetOptions<SC extends StateConfig> {
  isGroupedOptions?: never;
  options?: never;
  isGetOptions: true;
  getOptions: (useState: IInsightsContext<SC>["useState"]) => SelectOption[];
}

// This type is used by a dashboard to define its' specific state it'll like to have
export interface StateConfig {
  [key: FieldKey]: FieldConfig;
}

// Date aggregation options supported in Insights dashboards
export enum DateAggregation {
  Day = "day",
  Week = "week",
  Month = "month",
  Quarter = "quarter",
}

export const dateAggregationAdjective = (da: DateAggregation): string => {
  const capitalized = da.charAt(0).toUpperCase() + da.slice(1);
  if (da === DateAggregation.Day) {
    return capitalized.slice(0, -1) + "ily";
  }
  return capitalized + "ly";
};
