import { Callout, CalloutTheme, Link } from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import { publicApiUrl } from "src/utils/environment";

import { AlertSourceSetupProps } from "../AlertSourceConnectPage";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";

export const AlertSourceElasticSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  const setupInfoParagraphClass = "gap-4";

  return (
    <SetupInfoNumberedList>
      <p className={setupInfoParagraphClass}>
        Create a new <strong>Webhook connector</strong> by navigating to{" "}
        <strong>Stack management &gt; Connectors</strong> and clicking{" "}
        <strong>Create connector &gt; Webhook</strong>.
      </p>
      <div className="flex flex-col gap-2 overflow-hidden">
        <SetupInfoSingleLineBlocks
          intro={
            <>
              Give your connector a descriptive name (e.g.{" "}
              <code>incident.io</code>) and configure the connector to send a{" "}
              <code>POST</code> request to:
            </>
          }
          blocks={[
            {
              title: "Endpoint URL",
              code: `${publicApiUrl()}/v2/alert_events/elasticsearch/${
                alertSourceConfig.id
              }`,
            },
          ]}
        />
        <SetupInfoSingleLineBlocks
          intro={
            <>
              Select <code>None</code> as the authentication method, toggle the{" "}
              <strong>Add HTTP header</strong> option, and add the{" "}
              <code>Authorization</code> and <code>Content-Type</code> headers:
            </>
          }
          blocks={[
            {
              title: "Authorization header",
              code: `Bearer ${alertSourceConfig.secret_token}`,
            },
            {
              title: "Content-Type header",
              code: "application/json",
            },
          ]}
        />
        <p>
          Click <strong>Save</strong>.
        </p>
      </div>
      <div className={"flex flex-col gap-4"}>
        <p>
          Navigate to <strong>Stack Management &gt; Rules</strong>. Either
          create a new rule or edit an existing rule.
        </p>
        <p>
          In the <strong>Actions</strong> section, Click the{" "}
          <strong>Webhook</strong> tile. Select the connector you created in the
          previous step. Set the action frequency to{" "}
          <strong>On status changes</strong> and select <code>Fired</code> from
          the <strong>Run when</strong> dropdown.
        </p>
        <p>
          In the <strong>Body</strong> input, add the following:
        </p>
        <CodeBlock
          title={"Body"}
          code={`{
  "title": "{{rule.name}}",
  "description": "{{context.reason}}",
  "source_url": "{{context.alertDetailsUrl}}",
  "status": "firing",
  "deduplication_key": "{{alert.uuid}}",
  "metadata": {
    "custom_field": "..."
  }
}`}
        />
        <Callout theme={CalloutTheme.Info}>
          The <code>metadata</code> object can be used to pass arbitrary fields
          to our alerts API. See{" "}
          <Link
            href={
              "https://www.elastic.co/guide/en/kibana/current/rule-action-variables.html"
            }
            analyticsTrackingId={null}
          >
            here
          </Link>{" "}
          for a list of available Elastic variables. These fields can be parsed
          into alert attributes in the Incident dashboard.
        </Callout>
        <p>
          Press <strong>Save</strong>.
        </p>
      </div>
      <div className={"flex flex-col gap-4"}>
        <p>
          If you&apos;d like to configure Elastic to resolve alerts in
          incident.io when they get resolved in Elastic, you&apos;ll need to
          create another action, similar to the action created in step 3, with
          the <strong>Run when</strong> dropdown set to <code>Recovered</code>{" "}
          and the <code>status</code> field of the payload set to{" "}
          <code>resolved.</code> e.g.
        </p>
        <CodeBlock
          title={"Body"}
          code={`{
  "title": "{{rule.name}}",
  "description": "{{context.reason}}",
  "source_url": "{{context.alertDetailsUrl}}",
  "status": "resolved",
  "deduplication_key": "{{alert.uuid}}",
  "metadata": {
    "custom_field": "..."
  }
 }`}
        />
      </div>
    </SetupInfoNumberedList>
  );
};
