import { ErrorMessage } from "@hookform/error-message";
import {
  CustomFieldFieldTypeEnum,
  InternalStatusPage,
  ScopeNameEnum,
} from "@incident-io/api";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { FormV2 } from "@incident-shared/forms/v2/FormV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, Callout, CalloutTheme } from "@incident-ui";
import { useEffect, useRef } from "react";
import { DeepPartial, useForm } from "react-hook-form";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { expressionToPayload } from "../../../../@shared/engine/expressions/expressionToPayload";
import { QueryExpressionInput } from "../QueryExpressionInput";
import {
  StructureFormContent,
  StructureFormType,
} from "../StructureFormContent";

type FormType = StructureFormType &
  Pick<
    InternalStatusPage,
    "component_custom_field_id" | "component_source_expression"
  >;

export const StructureForm = ({ page }: { page: InternalStatusPage }) => {
  const { hasScope } = useIdentity();

  // Load the list of available custom fields
  const {
    data: { custom_fields: allCustomFields },
    isLoading: fieldsLoading,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });
  const availableCustomFields = allCustomFields.filter(
    (field) =>
      field.field_type === CustomFieldFieldTypeEnum.MultiSelect ||
      field.field_type === CustomFieldFieldTypeEnum.SingleSelect,
  );

  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  const formMethods = useForm<FormType>({
    defaultValues: {
      ...page,
      component_source_expression_payload: page.component_source_expression,
      groups_over_components:
        page.use_currently_affected_components_over_groups &&
        page.auto_expand_groups,
    },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "internalStatusPageShowPage",
    { id: page.id },
    async (apiClient, data) => {
      await apiClient.internalStatusPageUpdateStructure({
        id: page.id,
        updateStructureRequestBody: {
          ...data,
          component_source_expression: data.component_source_expression_payload
            ? expressionToPayload(data.component_source_expression_payload)
            : undefined,
        },
      });
      await apiClient.internalStatusPageUpdateGroupingBehaviour({
        id: page.id,
        updateGroupingBehaviourRequestBody: {
          use_currently_affected_components_over_groups:
            data.groups_over_components,
          auto_expand_groups: data.groups_over_components,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: ({ internal_status_page }) =>
        formMethods.reset({
          ...internal_status_page,
          component_source_expression_payload:
            internal_status_page.component_source_expression,
          groups_over_components:
            internal_status_page.use_currently_affected_components_over_groups &&
            internal_status_page.auto_expand_groups,
        }),
    },
  );

  // When the form state changes we want to do two things:
  // 1. Stash the form state for the current component custom field in a ref, so that
  // 2. When the component custom field changes, we restore the form state from
  // the last time it was selected.
  //
  // We use a ref for this since we don't want to trigger re-renders based on it
  // at all.
  const previousSettings = useRef<
    Record<string, DeepPartial<StructureFormType>>
  >({ [page.component_custom_field_id]: page });
  useEffect(() => {
    const { unsubscribe } = formMethods.watch((data, { name }) => {
      const fieldId = data.component_custom_field_id;
      if (fieldId === undefined) return;

      if (name !== "component_custom_field_id") {
        // Store the settings for the current component ID, and leave it there.
        previousSettings.current[fieldId] = data;
        return;
      }

      // If the component custom field is changing, reset the rest of the form
      // state to whatever was last there for that
      formMethods.reset(
        {
          component_custom_field_id: fieldId,
          hidden_catalog_entry_ids: [],
          hidden_catalog_group_values: [],
          hidden_custom_field_group_ids: [],
          hidden_custom_field_option_ids: [],
          ...previousSettings.current[fieldId],
        },
        // This tells react-hook-form to continue checking for dirtiness against
        // the values we set up in `useForm`, i.e. the current data in the
        // backend API.
        { keepDefaultValues: true },
      );
    });

    return () => unsubscribe();
  }, [formMethods]);

  const selectedField = allCustomFields.find(
    (field) => field.id === formMethods.watch("component_custom_field_id"),
  );

  return (
    <ExpressionsMethodsProvider allowAllOfACatalogType={true}>
      <FormV2
        formMethods={formMethods}
        onSubmit={trigger}
        saving={isMutating}
        innerClassName="bg-surface-secondary rounded-[6px] p-4 border border-stroke"
      >
        <h3 className="font-medium">Components</h3>
        <ErrorMessage message={genericError} />

        <StaticSingleSelectV2
          formMethods={formMethods}
          isLoading={fieldsLoading}
          name="component_custom_field_id"
          label="Which custom field should power your components?"
          helptext={
            <>
              This custom field will form the components of your page. If this
              field is set on an active incident, the linked components will be
              marked as affected on your page.
            </>
          }
          required={"Please select which custom field to use for components"}
          options={availableCustomFields.map((field) => ({
            value: field.id,
            label: field.name,
          }))}
        />

        {selectedField && (
          <>
            <Callout theme={CalloutTheme.Info} className="!items-center">
              <div className="flex items-center justify-between gap-2">
                <p>
                  This custom field controls the components and their order on
                  your internal status page. To adjust this, edit the &lsquo;
                  {selectedField.name}&rsquo; field.
                </p>
                <Button
                  analyticsTrackingId={"internal-sp-edit-component-field"}
                  href={`/settings/custom-fields/${selectedField.id}/edit?for-internal-sp`}
                  openInNewTab
                >
                  Edit custom field
                </Button>
              </div>
            </Callout>
            <QueryExpressionInput selectedField={selectedField} />

            <StructureFormContent
              selectedField={selectedField}
              formMethods={formMethods}
              label="Page structure"
            />

            <div>
              <h3 className="font-medium text-content-primary text-sm">
                Automatically expand groups?
              </h3>
              <ToggleV2
                formMethods={formMethods}
                label="Expand groups"
                align="left"
                labelClassName="text-sm !font-normal"
                toggleClassName="text-sm !font-normal"
                name="groups_over_components"
                helptext={
                  "If enabled and if you have groups on your page, they will be expanded by default."
                }
              />
            </div>
          </>
        )}

        <GatedButton
          type="submit"
          theme={ButtonTheme.Primary}
          analyticsTrackingId={"status-page-edit-basics"}
          analyticsTrackingMetadata={{ status_page_id: page.id }}
          loading={isMutating}
          disabled={missingPermission || !formMethods.formState.isDirty}
          disabledTooltipContent={
            missingPermission
              ? "You do not have permission to configure this internal status page"
              : undefined
          }
        >
          Save
        </GatedButton>
      </FormV2>
    </ExpressionsMethodsProvider>
  );
};
