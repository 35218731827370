import { ConditionValue } from "@incident-shared/engine/conditions/ConditionValue";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { singular } from "pluralize";
import {
  ConditionGroup,
  EngineParamBinding,
  EngineScope,
  Resource,
  ResourceFieldConfigArrayTypeEnum as ArrayFormFieldType,
  ResourceFieldConfigTypeEnum as FormFieldType,
} from "src/contexts/ClientContext";
import { lookupInScope, mergeScopes } from "src/utils/scope";
import { useAPI } from "src/utils/swr";
import { assertUnreachable, sendToSentry } from "src/utils/utils";

import { makeExpressionReference } from "../../../@shared/engine/expressions/addExpressionsToScope";
import { ExpressionDeletionUsages } from "../../../@shared/engine/expressions/ExpressionsEditor";
import { WorkflowStep, WorkflowStepGroup } from "./types";

// useLoopScope takes a scope and, if there's a forEach variable provided,
// builds a scope for that forEach variable (getting all its children from the
// api). It then concatenates those references to the original scope.
export const useLoopScope = (
  forEach: string | undefined,
  scope: EngineScope,
): EngineScope => {
  const loopVar = forEach ? lookupInScope(scope, forEach) : undefined;

  // Build a scope from the object we're iterating over, so that we can access any children
  // it might have.
  const {
    data: { scope: loopScope },
    isLoading: loopScopeLoading,
    error: scopeError,
    // Note that this means this hook won't run if forEach is undefined (which is great!)
  } = useAPI(
    forEach ? "engineBuildScope" : null,
    {
      buildScopeRequestBody: {
        scope: [
          {
            // This `loop_variable` magic string is expected by the backend - changing it will
            // break workflow loops!
            key: `loop_variable`,
            label: loopVar ? `Each ${singular(loopVar.label)}` : "",
            type: loopVar?.type || "",
            array: false, // it is no longer an array
          },
        ],
      },
    },
    { fallbackData: { scope: { references: [], aliases: {} } } },
  );

  if (scopeError) throw scopeError;

  let result = scope;
  if (forEach && !loopScopeLoading) {
    if (!loopVar) {
      throw new Error(
        "Unreachable: looping over a variable that is not in scope",
      );
    }

    // Put our extra scope at the front, so it comes up at the top of
    // the list in the UI.
    result = mergeScopes(scope, loopScope);
  }

  return result;
};

// some form elements only populate the `literal`, leaving the `label` untouched.
// this means that in edit mode, where the backend provides the labels, the labels
// don't update meaning it appears that nothing has changed (even though the literal
// has changed). To combat this, we find these particular params and hydrate the label.
export const rehydrateStepParamBindings = (
  data: WorkflowStep,
  resources: Resource[],
): WorkflowStep => {
  const hydratedBindings = data.param_bindings.map(
    (binding, idx): EngineParamBinding => {
      const param = data.params[idx];
      if (!param) {
        throw sendToSentry(
          "unreachable: found param binding with no equivalent param.",
          { param_bindings: data.param_bindings, params: data.params },
        );
      }
      const resource = resources.find((x) => x.type === param.type);
      if (!resource) {
        throw sendToSentry(
          "unreachable: found param with no associated resource.",
          { param, resources },
        );
      }
      if (param.infer_reference) {
        // we don't do anything with infer reference params, they're not interacted with by the user.
        return binding;
      }
      return rehydrateBinding({ array: param.array, resource, binding });
    },
  );
  return { ...data, param_bindings: hydratedBindings };
};

export const rehydrateBinding = ({
  array,
  resource,
  binding,
}: {
  array: boolean;
  resource: Resource;
  binding: EngineParamBinding;
}): EngineParamBinding => {
  if (array) {
    switch (resource.field_config.array_type) {
      case ArrayFormFieldType.MultiTextInput:
        if (!binding?.array_value) {
          return binding;
        }
        binding.array_value.forEach((val) => {
          if (val && val.literal) {
            val.label = val.literal;
          }
        });
        return binding;
      case ArrayFormFieldType.MultiExternalSelect:
      case ArrayFormFieldType.MultiExternalUserSelect:
      case ArrayFormFieldType.MultiStaticSelect:
      case ArrayFormFieldType.MultiDynamicSelect:
        // these all hydrate the label themselves, so we don't need to do anything
        return binding;
      case ArrayFormFieldType.None:
        throw sendToSentry(
          "unreachable: cannot validate form field type 'none'.",
          { field_config: resource.field_config, binding },
        );
      default:
        assertUnreachable(resource.field_config.array_type);
    }
  } else {
    if (binding.value?.reference) {
      return binding;
    }
    switch (resource.field_config.type) {
      case FormFieldType.DateInput:
      case FormFieldType.DateTimeInput:
      case FormFieldType.TextInput:
      case FormFieldType.TextNumberInput:
      case FormFieldType.TextAreaInput:
      case FormFieldType.RichTextEditorInput:
      case FormFieldType.DurationInput:
      case FormFieldType.TemplatedTextEditorInput:
        // @ts-expect-error I think the safest thing is to leave this as is
        binding.value.label = binding.value.literal;
        return binding;
      case FormFieldType.Checkbox:
        if (!binding.value) {
          binding.value = { label: "No", sort_key: "no" };
        }
        if (!binding.value.literal || binding.value.literal === "false") {
          // If the checkbox was never interacted with, treat it as false
          binding.value.literal = "false";
        } else {
          binding.value.literal = "true";
        }
        binding.value.label = binding.value?.literal === "true" ? "Yes" : "No";
        return binding;
      case FormFieldType.SingleExternalSelect:
      case FormFieldType.SingleExternalUserSelect:
      case FormFieldType.SingleStaticSelect:
      case FormFieldType.SingleDynamicSelect:
      case FormFieldType.SlackChannelInput:
        // these all hydrate the label themselves, so we don't need to do anything
        return binding;
      case FormFieldType.None:
        throw sendToSentry(
          "unreachable: cannot validate form field type 'none'.",
          { field_config: resource.field_config, binding },
        );
      default:
        assertUnreachable(resource.field_config.type);
    }
  }
  throw sendToSentry("unreachable: couldn't parse binding, unclear why.", {
    binding,
    array,
    resource,
  });
};

export const containsExpressionReference = (
  expression: Pick<ExpressionFormData, "reference">,
  serialisable: unknown,
) => {
  const expressionReference = makeExpressionReference(expression);

  return JSON.stringify(serialisable)
    .replaceAll("\\", "")
    .includes(expressionReference);
};

export const getExpressionUsages = (
  expression: ExpressionFormData,
  selectedStepGroups: WorkflowStepGroup[],
  selectedConditionGroups: ConditionGroup[],
): ExpressionDeletionUsages => {
  const expressionReference = makeExpressionReference(expression);
  const containsExpressionRef = (serialisable: unknown) =>
    containsExpressionReference(expression, serialisable);

  const stepUsages = selectedStepGroups
    .flatMap((group) => group.steps)
    .filter(containsExpressionRef)
    .map((s) => <span key={s.key}>{s.label}</span>);

  const loopUsages = selectedStepGroups
    .filter((group) => group.forEach === expressionReference)
    .map((group) => (
      <span key={group.key}>{`Loop over ${expression.label}`}</span>
    ));

  const conditionUsages = selectedConditionGroups
    .flatMap((group) => group.conditions)
    .filter(containsExpressionRef)
    .map((c, index) => (
      <span key={index}>
        {c.subject.label ?? ""}
        <span className={"font-medium"}> {c.operation.label} </span>
        <ConditionValue condition={c} />
      </span>
    ));

  return [
    { label: "Loops", usages: loopUsages },
    { label: "Conditions", usages: conditionUsages },
    { label: "Steps", usages: stepUsages },
  ];
};
