import React from "react";
import { Route, Routes } from "react-router";
import { CommsPlatform } from "src/hooks/usePrimaryCommsPlatform";

import { SetupPage } from "./SetupPage";
import { InstallationStepID } from "./useSetupSteps";

export const SetupRoute = (): React.ReactElement => {
  return (
    <Routes>
      {/* MS Teams lives on its own separate paths for now */}
      <Route
        path="msteams/choose-plan"
        element={
          <SetupPage
            stepFromPath={InstallationStepID.ChoosePlan}
            commsPlatformFromPath={CommsPlatform.MSTeams}
          />
        }
      />
      <Route
        path="msteams/install"
        element={
          <SetupPage
            stepFromPath={InstallationStepID.Install}
            commsPlatformFromPath={CommsPlatform.MSTeams}
          />
        }
      />
      {/* End of MS Teams */}

      <Route
        path="choose-plan"
        element={
          <SetupPage
            stepFromPath={InstallationStepID.ChoosePlan}
            commsPlatformFromPath={CommsPlatform.Slack}
          />
        }
      />
      <Route
        path="install"
        element={
          <SetupPage
            stepFromPath={InstallationStepID.Install}
            commsPlatformFromPath={CommsPlatform.Slack}
          />
        }
      />

      {/* we always redirect from here - /setup never renders on it's own */}
      <Route
        path="*"
        element={<SetupPage stepFromPath={null} commsPlatformFromPath={null} />}
      />
    </Routes>
  );
};
