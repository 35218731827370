import { Button, ButtonTheme, StackedList } from "@incident-ui";
import React, { useCallback, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { EditingState } from "src/components/legacy/workflows/common/types";
import {
  EngineParamBindingValue,
  EngineScope,
  Resource,
} from "src/contexts/ClientContext";
import { ExpressionBranch } from "src/contexts/ClientContext";

import {
  AddEditExpressionBranch,
  RenderResultInputProps,
} from "./AddEditExpressionBranch";
import { ExpressionBranchRow } from "./ExpressionBranchRow";

export type Branch = ExpressionBranch;
export type EditingBranchState = EditingState<number>;

export const ExpressionBranchesList = ({
  branches,
  selectedExpressionTypeResource,
  scope,
  onAddBranch: onAddWithoutCancel,
  onEditBranch: onEditWithoutCancel,
  onMoveBranch,
  onRemoveBranch,
  availableReturnTypeResources,
  renderResultBinding,
  renderResultInput,
  extraRow,
}: {
  branches: (Branch & { id: string })[];
  selectedExpressionTypeResource: Resource | undefined;
  scope: EngineScope;
  onMoveBranch: (indexA: number, indexB: number) => void;
  onAddBranch: (branch: Branch) => void;
  onRemoveBranch: (index: number) => void;
  onEditBranch: (index: number, branch: Branch) => void;
  // The resources currently available, given the users' single/multi return type option.
  // This is determined by whether a resource type can display a single or array form field or not.
  availableReturnTypeResources: Resource[];
  renderResultBinding?: (
    binding: EngineParamBindingValue,
  ) => React.ReactElement;
  renderResultInput?: (props: RenderResultInputProps) => React.ReactElement;
  extraRow?: React.ReactNode;
}): React.ReactElement => {
  const hasExistingBranches = branches.length > 0;
  const [editingState, setEditingState] = useState<EditingBranchState>(
    hasExistingBranches
      ? null
      : {
          isAdding: true,
        },
  );

  const onAddBranch = (branch: Branch) => {
    onAddWithoutCancel(branch);
    setEditingState(null);
  };
  const onEditBranch = (index: number, branch: Branch) => {
    onEditWithoutCancel(index, branch);
    setEditingState(null);
  };

  const onDragEnd = useCallback(
    (result) => {
      // Only listen for drop events (ignore things like 'CANCEL' events, where
      // the user just cancelled/aborted)
      if (result.reason !== "DROP") {
        return;
      }

      // If we dropped it outside the list, no-op
      if (!result.destination) {
        return;
      }

      const fromIndex = result.source.index;
      const toIndex = result.destination.index;
      onMoveBranch(fromIndex, toIndex);
    },
    [onMoveBranch],
  );

  const isEditingAnyBranch = !!editingState;

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="main_template">
          {(provided) => {
            return (
              <StackedList
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="mb-4 !bg-white rounded-2"
              >
                {branches.map((branch, index) => {
                  const isBeingEdited =
                    editingState?.isEditing &&
                    index === editingState?.editingItem;

                  if (isBeingEdited) {
                    return (
                      <AddEditExpressionBranch
                        key={branch.id}
                        setEditingState={setEditingState}
                        selectedExpressionTypeResource={
                          selectedExpressionTypeResource
                        }
                        scope={scope}
                        onEdit={(b) => onEditBranch(index, b)}
                        onAdd={(b) => onAddBranch(b)}
                        editingBranch={branch}
                        canCancel={true}
                        isElseCase={index > 0}
                        availableReturnTypeResources={
                          availableReturnTypeResources
                        }
                        renderResultInput={renderResultInput}
                      />
                    );
                  }

                  return (
                    <Draggable
                      isDragDisabled={isEditingAnyBranch}
                      key={branch.id}
                      draggableId={branch.id}
                      index={index}
                    >
                      {(provided) => (
                        <ExpressionBranchRow
                          index={index}
                          branch={branch}
                          isDisabled={isEditingAnyBranch}
                          draggableProvided={provided}
                          setEditingState={setEditingState}
                          onDelete={() => onRemoveBranch(index)}
                          selectedExpressionTypeResource={
                            selectedExpressionTypeResource
                          }
                          isLastBranch={branches.length === 1}
                          renderResultBinding={renderResultBinding}
                        />
                      )}
                    </Draggable>
                  );
                })}

                {editingState?.isAdding && (
                  <AddEditExpressionBranch
                    setEditingState={setEditingState}
                    selectedExpressionTypeResource={
                      selectedExpressionTypeResource
                    }
                    availableReturnTypeResources={availableReturnTypeResources}
                    scope={scope}
                    onEdit={(b) => {
                      onEditBranch(0, b);
                    }}
                    onAdd={(b) => {
                      onAddBranch(b);
                    }}
                    editingBranch={undefined}
                    canCancel={hasExistingBranches}
                    isElseCase={hasExistingBranches}
                    renderResultInput={renderResultInput}
                  />
                )}

                {extraRow}
                {provided.placeholder}
              </StackedList>
            );
          }}
        </Droppable>
      </DragDropContext>
      {!isEditingAnyBranch && (
        <Button
          theme={ButtonTheme.Secondary}
          analyticsTrackingId="workflows-add-new-expression"
          className="shrink-0"
          onClick={() => setEditingState({ isAdding: true })}
        >
          Add another rule
        </Button>
      )}
    </div>
  );
};
