import { IconEnum, Link, Markdown } from "@incident-ui";
import { Modal } from "@incident-ui/Modal/Modal";
import { ModalContent } from "@incident-ui/Modal/ModalContent";
import { ModalFooter } from "@incident-ui/Modal/ModalFooter";
import _ from "lodash";
import React from "react";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { TimelineItemContentBox } from "src/components/timeline/TimelineItemContentBox";
import styles from "src/components/timeline/TimelineItems.module.scss";
import {
  Incident,
  SlackPin,
  TimelineItemObject,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

export const TimelineItemSlackPin = (
  _: Incident,
  item: TimelineItemObject,
  zoomImageSource: string | undefined,
  setZoomImageSource: (value: React.SetStateAction<string | undefined>) => void,
): TimelineItemRenderProps | null => {
  const slackPin = item.slack_pin;
  if (!slackPin) {
    throw new Error(
      "malformed timeline item: slack_pin was missing slack_pin field",
    );
  }

  // Don't render an item if the pin has no message, or the message has been deleted.
  if (!slackPin.message || slackPin.message.deleted) {
    return null;
  }

  const pinText = pinMessageText(slackPin);

  const imageZoomModal = (
    <Modal
      title="No title shown"
      isOpen={!!zoomImageSource}
      analyticsTrackingId={null}
      onClose={() => setZoomImageSource(undefined)}
      hideHeader
      maximised
    >
      <ModalContent>
        <img src={zoomImageSource} className={""} />
      </ModalContent>
      <ModalFooter
        hideConfirmButton={true}
        confirmButtonType="button"
        onConfirm={() => setZoomImageSource(undefined)}
        onClose={() => setZoomImageSource(undefined)}
        cancelButtonText={"Close"}
      />
    </Modal>
  );

  // This means we'll only build a context box if the message has text OR an image.
  // There might be neither if the message just contains a file attachment.
  if (slackPin.message.text !== "" || slackPin.images) {
    return {
      description: pinText,
      avatarUrl: slackPin.message_user?.avatar_url,
      icon: IconEnum.Pin,
      children: (
        <>
          {imageZoomModal}
          <TimelineItemContentBox>
            <Markdown className={styles.contentBox}>
              {slackPin.message.text}
            </Markdown>
            {slackPin.images &&
              slackPin.images.map((im) =>
                im ? (
                  <img
                    key={im.id}
                    src={im.url}
                    className={tcx(styles.slackPinImage)}
                    onClick={() => setZoomImageSource(im.url)}
                  />
                ) : null,
              )}
          </TimelineItemContentBox>
        </>
      ),
    };
  } else {
    return {
      description: pinText,
      avatarUrl: slackPin.message_user?.avatar_url,
      icon: IconEnum.Bookmark,
    };
  }
};

export const SlackMessageLink = ({
  permalink,
  text,
}: {
  permalink: string;
  text: string;
}): React.ReactElement =>
  permalink ? (
    <Link href={permalink} openInNewTab analyticsTrackingId={null}>
      {text}
    </Link>
  ) : (
    <>{text}</>
  );

export const pinMessageText = (slack_pin: SlackPin): React.ReactElement => {
  const messageLink = (
    <SlackMessageLink text="message" permalink={slack_pin.message.permalink} />
  );

  let containingFiles = "";
  if (slack_pin.message.num_files > 0) {
    containingFiles = `containing ${slack_pin.message.num_files} file`;
    if (slack_pin.message.num_files !== 1) {
      containingFiles += "s";
    }
  }

  if (!slack_pin.user) {
    return (
      <>
        A {messageLink} {containingFiles} was pinned
      </>
    );
  }
  // Message that was pinned wasn't from a user
  if (
    _.isEmpty(slack_pin.message_user) ||
    !slack_pin.message_user ||
    !slack_pin.message_user.name
  ) {
    return (
      <>
        A {messageLink} {containingFiles} was pinned by{" "}
        <strong>{slack_pin.user.name}</strong>
      </>
    );
  }

  // At this point, we can be confident that there _is_ a message_user (a human
  // wrote the message that was pinned).

  // User pinned their own message.
  if (slack_pin.message_user.id === slack_pin.user.id) {
    return (
      <>
        <strong>{slack_pin.user.name}&apos;s</strong> {messageLink}{" "}
        {containingFiles}
        {" was pinned"}
      </>
    );
  }

  // User pinned somebody else's message
  return (
    <>
      <strong>{slack_pin.message_user.name}&apos;s</strong> {messageLink}{" "}
      {containingFiles}
      {" was pinned by "}
      <strong>{slack_pin.user.name}</strong>
    </>
  );
};
