import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import {
  JIRA_CLOUD_CONFIG,
  JiraFormData,
  JiraStep,
} from "@incident-shared/integrations/jira/jiraConfig";
import { useSetupJiraExport } from "@incident-shared/issue-trackers";
import {
  FormEverythingElse,
  FormSelectIssueType,
  FormSelectProject,
  FormSelectSite,
  RENDERED_OPTIONAL_FIELDS,
} from "@incident-shared/issue-trackers/jira/JiraFormFields";
import { ModalFooter } from "@incident-ui";
import { ControlledCheckbox } from "@incident-ui/Checkbox/Checkbox";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import { FollowUp } from "src/contexts/ClientContext";
import { JiraIssueField } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import {
  BulkExportBlurb,
  BulkExportCallouts,
  getBulkExportTitle,
  getBulkToastTitle,
  NoFollowUpsToExportModal,
} from "./helpers";
import { UnsupportedJiraFieldsErrorMessage } from "./UnsupportedJiraFieldsErrorMessage";

export const BulkExportToJiraCloudModal = ({
  followUpsToExport,
  numFollowUpsToExportFromPrivate,
  numFollowUpsAlreadyExported,
  onClose,
  onCancel,
  updateCallback,
}: {
  followUpsToExport: FollowUp[];
  numFollowUpsToExportFromPrivate: number;
  numFollowUpsAlreadyExported: number;
  onClose: () => void;
  onCancel: () => void;
  updateCallback: () => Promise<void>;
}): React.ReactElement => {
  const formMethods = useForm<JiraFormData>();

  const showToast = useToast();

  const {
    setError,
    control,
    formState: { errors },
  } = formMethods;

  const {
    step,
    siteId,
    projectId,
    issueTypeId,
    issueFields,
    onChangeSite,
    onChangeProject,
    onChangeIssueType,
  } = useSetupJiraExport({
    formMethods,
    followUpId: undefined, // we're exporting multiple follow-ups, so don't have a single follow-up ID to pass here
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    {},
    async (apiClient, body: JiraFormData) => {
      await Promise.all(
        followUpsToExport.map((followUp) => {
          return apiClient.issueTrackersJiraCreateIssue({
            jiraCreateIssueRequestBody: {
              ...body,
              title: followUp.title,
              description: followUp.description ?? "",
              follow_up_id: followUp.id,
              // We don't support connecting to the parent issue from bulk export (yet)
              connect_to_parent_issue: body.connect_to_parent_issue ?? false,
            },
          });
        }),
      );
    },
    {
      setError,
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: getBulkToastTitle(followUpsToExport.length, "Jira"),
        });
        await updateCallback();
        onClose();
      },
    },
  );

  const title = getBulkExportTitle(followUpsToExport.length);

  if (followUpsToExport.length === 0) {
    return (
      <NoFollowUpsToExportModal
        numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
        onClose={onCancel}
      />
    );
  } else {
    const unsupportedRequiredFields = issueFields?.unsupported_required_fields;
    return (
      <FormModalV2
        formMethods={formMethods}
        onSubmit={onSubmit}
        title={title}
        analyticsTrackingId="bulk-add-to-jira"
        onClose={onCancel}
        contentClassName="overflow-y-auto p-4"
        genericError={genericError}
        footer={
          <ModalFooter
            disabled={shouldDisableExportToJiraButton({
              step: step,
              issueFields: issueFields?.issue_fields ?? null,
              connectToExistingTicket: false,
              unsupportedFields: unsupportedRequiredFields ?? null,
            })}
            confirmButtonText={title}
            saving={saving}
            onClose={onCancel}
            confirmButtonType="submit"
          />
        }
      >
        <BulkExportCallouts
          numFollowUpsToExportFromPrivate={numFollowUpsToExportFromPrivate}
          numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
          accountName="Jira project"
        />
        <BulkExportBlurb providerIssues="Jira tickets" providerName={"Jira"} />
        <FormSelectSite onChangeSite={onChangeSite} />
        <FormSelectProject
          step={step}
          config={JIRA_CLOUD_CONFIG}
          siteId={siteId}
          onChangeProject={onChangeProject}
        />
        <FormSelectIssueType
          siteId={siteId}
          projectId={projectId}
          config={JIRA_CLOUD_CONFIG}
          step={step}
          onChangeIssueType={onChangeIssueType}
        />
        {step === JiraStep.Step3 && (
          <ControlledCheckbox
            label={
              <>
                Link the created issues to their relevant incident tickets (if
                any).
              </>
            }
            id="connect_to_parent_issue"
            control={control}
            errors={errors}
          />
        )}
        {unsupportedRequiredFields ? (
          unsupportedRequiredFields.length > 0 ? (
            <UnsupportedJiraFieldsErrorMessage
              unsupportedRequiredFields={unsupportedRequiredFields}
            />
          ) : (
            <FormEverythingElse
              siteId={siteId}
              projectId={projectId}
              step={step}
              config={JIRA_CLOUD_CONFIG}
              issueTypeId={issueTypeId}
              issueFields={issueFields?.issue_fields || []}
              providerName={"Jira"}
              hasDescription={issueFields.has_description}
              isBulkExport
              loading={false}
            />
          )
        ) : null}
      </FormModalV2>
    );
  }
};

// [INC-1139] this is a hack to disable the submit button until the options for the
// dynamic fields have loaded. We used to disable the button until we hit step3, but
// there's a lag until the blocks for assignee and reporter etc are loaded, so a user
// can submit and then re-submit once they've chosen an assignee, which causes the
// export to fail on the second time.
export const shouldDisableExportToJiraButton = ({
  step,
  issueFields,
  connectToExistingTicket,
  unsupportedFields,
}: {
  step: JiraStep;
  // eslint-disable-next-line @typescript-eslint/ban-types
  issueFields: JiraIssueField[] | null;
  connectToExistingTicket: boolean | null;
  unsupportedFields: string[] | null;
}): boolean => {
  // If we're connecting to an existing ticket, always enable the button
  if (connectToExistingTicket) {
    return false;
  }

  // If we're not at step 3 yet, disable the button
  if (step !== JiraStep.Step3) {
    return true;
  }

  // If we haven't fetched the issue fields yet, disable the button
  if (!issueFields) {
    return true;
  }

  // There are fields that we can't render in the UI, so we show an error and disable the continue button
  if (!unsupportedFields || unsupportedFields.length > 0) {
    return true;
  }

  // If there aren't any dynamic fields to render, enable the button
  const fieldsToRender = issueFields.filter(
    (field) => field.required || RENDERED_OPTIONAL_FIELDS.includes(field.key),
  );
  if (fieldsToRender.length === 0) {
    return false;
  }

  return false;
};
