import { Callout, CalloutTheme, ModalFooter, TabModalPane } from "@incident-ui";
import { useEffect } from "react";
import { ReactElement, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { FormTabModalV2 } from "src/components/@shared/forms/v2/FormV2";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "src/components/@shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "src/components/@shared/forms/v2/inputs/TextareaV2";
import {
  FollowUp,
  FollowUpsConnectExternalIssueRequestBodyProviderEnum,
  IssueTrackersAsanaCreateIssueRequestBody,
  IssueTrackersAsanaTypeaheadOptionsFieldEnum,
  SelectOption,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

export type AsanaFormData = IssueTrackersAsanaCreateIssueRequestBody & {
  team_id: string;
  url: string;
};

export const ExportToAsanaModal = ({
  followUp,
  onClose,
  updateCallback,
  isPrivateIncident,
}: {
  followUp: FollowUp;
  isPrivateIncident?: boolean;
  onClose: () => void;
  updateCallback: (followUp: FollowUp) => void;
}): ReactElement | null => {
  const [connectToExistingTicket, setConnectToExistingTicket] =
    useState<boolean>(false);

  const formMethods = useForm<AsanaFormData>({
    mode: "onSubmit",
    defaultValues: {
      title: followUp.title,
      description: followUp.description,
      url: "",
    },
  });
  const { watch, setError, setValue } = formMethods;

  const {
    data: { typeahead_options: teams },
    isLoading: teamsLoading,
    error: teamsError,
  } = useAPI(
    "issueTrackersAsanaTypeaheadOptions",
    {
      field: IssueTrackersAsanaTypeaheadOptionsFieldEnum.Team,
    },
    { fallbackData: { typeahead_options: [] } },
  );

  // Load projects and users once we have the team selected
  const teamId = watch("team_id");
  const {
    data: { typeahead_options: projects },
    isLoading: projectsLoading,
    error: projectsError,
  } = useAPI(
    teamId ? "issueTrackersAsanaTypeaheadOptions" : null,
    { field: IssueTrackersAsanaTypeaheadOptionsFieldEnum.Project, teamId },
    { fallbackData: { typeahead_options: [] } },
  );

  const {
    data: { typeahead_options: users },
    isLoading: usersLoading,
    error: usersError,
  } = useAPI(
    teamId ? "issueTrackersAsanaTypeaheadOptions" : null,
    { field: IssueTrackersAsanaTypeaheadOptionsFieldEnum.User, teamId },
    { fallbackData: { typeahead_options: [] } },
  );

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      // Ignore changes to other fields
      if (name !== "team_id") {
        return;
      }

      // Clear the assignee and project: they may be invalid
      // @ts-expect-error there's no clearValue function, apparently
      setValue<"project_id">("project_id", null);
      // @ts-expect-error there's no clearValue function, apparently
      setValue<"assignee_id">("assignee_id", null);
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    // The thing we really want sorted is the list of follow ups on the incident
    // details page
    "followUpsList",
    { incidentId: followUp.incident_id },
    async (apiClient, data: AsanaFormData) => {
      const { follow_up: newFollowUp } = connectToExistingTicket
        ? await apiClient.followUpsConnectExternalIssue({
            id: followUp.id,
            connectExternalIssueRequestBody: {
              provider:
                FollowUpsConnectExternalIssueRequestBodyProviderEnum.Asana,
              url: data.url,
            },
          })
        : await apiClient.issueTrackersAsanaCreateIssue({
            asanaCreateIssueRequestBody: {
              follow_up_id: followUp.id,
              title: data.title,
              description: data.description,
              project_id: data.project_id,
              assignee_id:
                data.assignee_id === "" ? undefined : data.assignee_id,
            },
          });

      updateCallback(newFollowUp);
    },
    { onSuccess: onClose, setError },
  );

  const fetchDataError =
    teamsError || usersError || projectsError
      ? "There was a problem loading data from Asana."
      : "";

  return (
    <FormTabModalV2
      genericError={genericError || fetchDataError}
      formMethods={formMethods}
      onSubmit={onSubmit}
      title="Export to Asana"
      analyticsTrackingId="add-to-asana"
      onClose={onClose}
      loading={teamsLoading}
      onTabChange={(tab: string) =>
        setConnectToExistingTicket(tab === "connect")
      }
      tabs={[
        {
          id: "add",
          label: "Create new",
        },
        {
          id: "connect",
          label: "Connect existing",
        },
      ]}
      footer={
        <ModalFooter
          confirmButtonText={connectToExistingTicket ? "Connect" : "Create"}
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      <TabModalPane tabId="add">
        <div className="space-y-4">
          {isPrivateIncident && (
            <Callout theme={CalloutTheme.Warning}>
              This is a private incident. This issue will be visible to anyone
              with access to your Asana project.
            </Callout>
          )}
          <p className="text-sm">
            We&apos;ll automatically sync any changes in Asana.
          </p>
          <InputV2
            formMethods={formMethods}
            label="Title"
            name="title"
            required={
              connectToExistingTicket ? undefined : "Please enter a title"
            }
          />
          <TextareaV2
            formMethods={formMethods}
            label="Description"
            name="description"
            required={
              connectToExistingTicket ? undefined : "Please enter a description"
            }
            placeholder="Any more details you want to add while you're here?"
            rows={4}
          />
          <AsanaBulkExportableFields
            projects={projects ?? null}
            projectsLoading={projectsLoading}
            teams={teams ?? null}
            teamsLoading={teamsLoading}
            users={users ?? null}
            usersLoading={usersLoading}
          />
        </div>
      </TabModalPane>
      <TabModalPane tabId="connect" className="space-y-4">
        <p className="text-sm">
          Connect this follow-up to an existing Asana issue by pasting in the
          issue URL.
        </p>
        <Callout theme={CalloutTheme.Info}>
          If you link this to an issue tracker, we will use that as the source
          of truth, and the follow-up will no longer be editable in incident.io.
        </Callout>
        <InputV2
          formMethods={formMethods}
          name="url"
          label="Existing issue URL"
          required={connectToExistingTicket ? "Please enter a URL" : undefined}
          placeholder="https://app.asana.com/0/123456/123456"
        />
      </TabModalPane>
    </FormTabModalV2>
  );
};

// AsanaBulkExportableFields holds the fields that are present on both the singular export and the bulk
// export modals. If you're adding a new field, please consider whether it should be added here (ie.
// a user might want to set it for multiple actions at once), or above (ie. a user will only want to
// set it for a particular followUp).
export const AsanaBulkExportableFields = ({
  projects,
  projectsLoading,
  teams,
  teamsLoading,
  users,
  usersLoading,
}: {
  projects: SelectOption[] | null;
  projectsLoading: boolean;
  teams: SelectOption[] | null;
  teamsLoading: boolean;
  users: SelectOption[] | null;
  usersLoading: boolean;
}): ReactElement => {
  const formMethods = useFormContext<AsanaFormData>();
  return (
    <>
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Asana team"
        name="team_id"
        required="Please select a team"
        options={teams || []}
        placeholder="Select team"
        isLoading={teamsLoading}
      />
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Asana project"
        name={"project_id"}
        required="Please select a project"
        options={projects || []}
        placeholder="Select project"
        isLoading={projectsLoading}
        disabled={projects == null}
      />
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Asana assignee"
        name={"assignee_id"}
        options={users || []}
        placeholder="Select assignee"
        isLoading={usersLoading}
        disabled={users == null}
      />
    </>
  );
};
