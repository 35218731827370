import {
  Badge,
  BadgeTheme,
  DropdownMenu,
  DropdownMenuLink,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { kebabCase } from "lodash";
import React from "react";
import { OrgAwareNavLink } from "src/components/@shared/org-aware";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./SecondaryNavHorizontal.module.scss";

export type SecondaryNavHorizontalItemType = {
  label: string;
  slug: string;
  icon?: IconEnum;
  hide?: boolean;
  beta?: boolean;
};

export const SecondaryNavHorizontal = ({
  items,
  pathPrefix,
}: {
  items: SecondaryNavHorizontalItemType[];
  pathPrefix: string;
}): React.ReactElement => {
  return (
    <>
      <MobileNav items={items} pathPrefix={pathPrefix} />
      <DesktopNav items={items} pathPrefix={pathPrefix} />
    </>
  );
};

const DesktopNav = ({
  items,
  pathPrefix,
}: {
  items: SecondaryNavHorizontalItemType[];
  pathPrefix: string;
}): React.ReactElement => {
  return (
    <div className="hidden md:block -mt-2">
      <div className="border-b border-stroke flex justify-between">
        <nav className="-mb-px flex space-x-4" aria-label="Tabs">
          {items
            .filter((i) => !i.hide)
            .map((item) => (
              <OrgAwareNavLink
                key={item.slug}
                to={item.slug}
                className={({ isActive }) =>
                  tcx(
                    "border-transparent text-slate-700 hover:text-content-primary hover:border-slate-400",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm",
                    isActive ? "!border-slate-900 !text-content-primary" : "",
                  )
                }
                data-intercom-target={`${pathPrefix}-nav-${item.slug}`}
              >
                <div className="flex-center-y">
                  {item.icon && (
                    <Icon
                      id={item.icon}
                      size={IconSize.Medium}
                      className="mr-1"
                    />
                  )}
                  <span className={"truncate"}>{item.label}</span>
                  {item.beta && (
                    <Badge theme={BadgeTheme.Info} className="ml-2">
                      Beta <Icon size={IconSize.Small} id={IconEnum.Test} />
                    </Badge>
                  )}
                </div>
              </OrgAwareNavLink>
            ))}
        </nav>
      </div>
    </div>
  );
};

const MobileNav = ({
  items,
  pathPrefix,
}: {
  items: SecondaryNavHorizontalItemType[];
  pathPrefix: string;
}): React.ReactElement => {
  return (
    <div className={`${styles.mobileMenu} md:hidden z-[1000]`}>
      <DropdownMenu
        triggerIcon={IconEnum.Menu}
        triggerIconSize={IconSize.Large}
        screenReaderText="Settings menu"
        analyticsTrackingId="settings-menu"
      >
        {items
          .filter((i) => !i.hide)
          .map((page) => (
            <DropdownMenuLink
              label={page.label}
              key={page.slug}
              to={`/${pathPrefix}/${page.slug}`}
              analyticsTrackingId={kebabCase(page.label)}
            />
          ))}
      </DropdownMenu>
    </div>
  );
};
