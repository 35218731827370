/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentCallsCreateFromURLRequestBody,
    IncidentCallsCreateFromURLRequestBodyFromJSON,
    IncidentCallsCreateFromURLRequestBodyToJSON,
    IncidentCallsCreateFromURLResponseBody,
    IncidentCallsCreateFromURLResponseBodyFromJSON,
    IncidentCallsCreateFromURLResponseBodyToJSON,
    IncidentCallsCreateRequestBody,
    IncidentCallsCreateRequestBodyFromJSON,
    IncidentCallsCreateRequestBodyToJSON,
    IncidentCallsCreateResponseBody,
    IncidentCallsCreateResponseBodyFromJSON,
    IncidentCallsCreateResponseBodyToJSON,
    IncidentCallsGetForLatestForIncidentResponseBody,
    IncidentCallsGetForLatestForIncidentResponseBodyFromJSON,
    IncidentCallsGetForLatestForIncidentResponseBodyToJSON,
    IncidentCallsShowResponseBody,
    IncidentCallsShowResponseBodyFromJSON,
    IncidentCallsShowResponseBodyToJSON,
} from '../models';

export interface IncidentCallsCreateRequest {
    createRequestBody: IncidentCallsCreateRequestBody;
}

export interface IncidentCallsCreateFromURLRequest {
    createFromURLRequestBody: IncidentCallsCreateFromURLRequestBody;
}

export interface IncidentCallsDeleteRequest {
    id: string;
}

export interface IncidentCallsGetForLatestForIncidentRequest {
    incidentId: string;
}

export interface IncidentCallsShowRequest {
    id: string;
}

/**
 * 
 */
export class IncidentCallsApi extends runtime.BaseAPI {

    /**
     * Create a new call for an incident
     * Create IncidentCalls
     */
    async incidentCallsCreateRaw(requestParameters: IncidentCallsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentCallsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling incidentCallsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_calls`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentCallsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentCallsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new call for an incident
     * Create IncidentCalls
     */
    async incidentCallsCreate(requestParameters: IncidentCallsCreateRequest, initOverrides?: RequestInit): Promise<IncidentCallsCreateResponseBody> {
        const response = await this.incidentCallsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new call for an incident from a URL
     * CreateFromURL IncidentCalls
     */
    async incidentCallsCreateFromURLRaw(requestParameters: IncidentCallsCreateFromURLRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentCallsCreateFromURLResponseBody>> {
        if (requestParameters.createFromURLRequestBody === null || requestParameters.createFromURLRequestBody === undefined) {
            throw new runtime.RequiredError('createFromURLRequestBody','Required parameter requestParameters.createFromURLRequestBody was null or undefined when calling incidentCallsCreateFromURL.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_calls/from_url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentCallsCreateFromURLRequestBodyToJSON(requestParameters.createFromURLRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentCallsCreateFromURLResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new call for an incident from a URL
     * CreateFromURL IncidentCalls
     */
    async incidentCallsCreateFromURL(requestParameters: IncidentCallsCreateFromURLRequest, initOverrides?: RequestInit): Promise<IncidentCallsCreateFromURLResponseBody> {
        const response = await this.incidentCallsCreateFromURLRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an incident call
     * Delete IncidentCalls
     */
    async incidentCallsDeleteRaw(requestParameters: IncidentCallsDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentCallsDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_calls/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an incident call
     * Delete IncidentCalls
     */
    async incidentCallsDelete(requestParameters: IncidentCallsDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentCallsDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get latest call for an incident
     * GetForLatestForIncident IncidentCalls
     */
    async incidentCallsGetForLatestForIncidentRaw(requestParameters: IncidentCallsGetForLatestForIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentCallsGetForLatestForIncidentResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentCallsGetForLatestForIncident.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_calls`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentCallsGetForLatestForIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get latest call for an incident
     * GetForLatestForIncident IncidentCalls
     */
    async incidentCallsGetForLatestForIncident(requestParameters: IncidentCallsGetForLatestForIncidentRequest, initOverrides?: RequestInit): Promise<IncidentCallsGetForLatestForIncidentResponseBody> {
        const response = await this.incidentCallsGetForLatestForIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show an incident call
     * Show IncidentCalls
     */
    async incidentCallsShowRaw(requestParameters: IncidentCallsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentCallsShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentCallsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_calls/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentCallsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show an incident call
     * Show IncidentCalls
     */
    async incidentCallsShow(requestParameters: IncidentCallsShowRequest, initOverrides?: RequestInit): Promise<IncidentCallsShowResponseBody> {
        const response = await this.incidentCallsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
