import { ContentBox } from "@incident-ui";
import React from "react";

// SetupScreen is a UI only component to render screens in the Setup flow.
export const SetupScreen = ({
  title,
  children,
  footer,
  padChildren = true,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  padChildren?: boolean;
}): React.ReactElement => {
  const classNames = padChildren ? "text-center p-6" : "text-center";

  return (
    <div className="flex flex-col items-center justify-center mx-auto md:mt-2 max-w-[500px] gap-4">
      {title && (
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h1 className="text-center text-2xl font-semibold text-content-primary">
            {title}
          </h1>
        </div>
      )}

      <ContentBox className={classNames}>{children}</ContentBox>
      {footer}
    </div>
  );
};
