import { SlackChannelNameEditForm } from "@incident-shared/settings";
import { Loader } from "@incident-ui";
import React from "react";
import graphic from "src/components/settings/banners/banner-slack-channels.svg";
import { SettingsHeading } from "src/components/settings/SettingsHeading";
import { SlackChannelBookmarksEditSection } from "src/components/settings/slack-channel/SlackChannelBookmarksEditSection";
import { SlackChannelQuickActionsEditSection } from "src/components/settings/slack-channel/SlackChannelQuickActionsEditSection";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";

import { SettingsSubPageWrapper } from "../SettingsRoute";

export const SlackChannelPage = (): React.ReactElement => {
  const { settings } = useSettings();
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  if (!settings) {
    return <Loader />;
  }

  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        graphic={<img src={graphic} className="h-40" />}
        title="Personalize Slack channels"
        subtitle="Set up naming, bookmarks, and quick actions for your incident channels."
      />
      <SlackChannelNameEditForm settings={settings} />
      <SlackChannelQuickActionsEditSection canEditSettings={canEditSettings} />
      <SlackChannelBookmarksEditSection canEditSettings={canEditSettings} />
    </SettingsSubPageWrapper>
  );
};
