import {
  Identity,
  IdentityAtlassianConnectionStatusEnum,
  IdentitySlackReconnectionReasonEnum,
  IdentitySlackUserReconnectionReasonEnum,
  IdentityUserBaseRoleSlugEnum,
  IdentityUserSlackRoleEnum,
  IdentityUserStateEnum,
  TrialStatusPlanNameEnum,
  TrialStatusTrialStatusEnum,
} from "src/contexts/ClientContext";

export const emptyIdentity: Identity = {
  actor_organisation_id: "",
  actor_organisation_slug: "",
  app_installed: false,
  atlassian_connection_status: IdentityAtlassianConnectionStatusEnum.Connected,
  feature_gates: {
    custom_dashboards: false,
    custom_field_conditions: false,
    advanced_insights: false,
    ai_assistant: false,
    ai_features: false,
    api_access: false,
    audit_logs: false,
    custom_nudges: false,
    debrief_placeholders: false,
    follow_up_priorities: false,
    follow_up_priorities_customisable: false,
    incident_types: false,
    on_call_calculator: false,
    post_incident_flow_custom_tasks: false,
    post_incident_task_auto_assign: false,
    post_incident_task_auto_remind: false,
    rbac_base_roles_customisable: false,
    rbac_custom_roles: false,
    private_incidents: false,
    saml: false,
    scim: false,
    slack_enterprise_grid_install: false,
    status_pages_customer_pages: false,
    status_pages_sub_pages: false,
    triage_incidents: false,
    use_telecom: false,
    webhooks: false,
  },
  first_login_at: new Date(),
  first_time_on_call_email_sent: false,
  has_created_a_status_page: false,
  has_created_tutorial: false,
  has_reported_first_incident: false,
  incident_types_enabled: false,
  intercom_user_id_hash: "",
  onboarding_information: {
    tell_us_more_form_submitted: false,
    admins_invited: false,
    buy_plan_banner_dismissed: false,
    requested_on_call: false,
  },
  onboarding_tasks: [],
  only_admins_and_owners_see_reinstall_banner: false,
  organisation_created_at: 0,
  organisation_has_reported_first_incident: false,
  organisation_id: "",
  organisation_is_demo: false,
  organisation_is_sandbox: false,
  organisation_name: "",
  organisation_slug: "",
  org_dismissed_ctas: [],
  user_dismissed_ctas: [],
  scopes: [],
  trial_status: {
    grace_incidents_remaining: 0,
    trial_status: TrialStatusTrialStatusEnum.NotStarted,
    plan_name: TrialStatusPlanNameEnum.BasicV2,
  },
  user_avatar_url: "",
  user_base_role_slug: IdentityUserBaseRoleSlugEnum.User,
  user_email: "",
  user_id: "",
  user_locale: "en-GB",
  user_name: "",
  user_state: IdentityUserStateEnum.Viewer,
  can_create_retrospective_incidents: true,
  can_create_tutorial_incidents: true,
  can_share_postmortems: true,

  // DEPRECATED: Use slack_info instead
  add_to_slack_url: "https://slack.com/",
  user_slack_user_id: "U02J3UJF81Z",
  user_slack_role: IdentityUserSlackRoleEnum.Regular,
  slack_user_reconnection_reason: IdentitySlackUserReconnectionReasonEnum.Empty,
  slack_reconnection_reason: IdentitySlackReconnectionReasonEnum.Empty,
  slack_user_token_behaviours: [],
  slack_user_token_owner: undefined,
  missing_slack_token_scopes: [],
  // END DEPRECATED
};
