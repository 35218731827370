import { IncidentCall } from "@incident-io/api";
import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { ConfirmationDialog } from "@incident-ui";

import { useAPIMutation } from "../../utils/swr";

export const IncidentCallsDeleteModal = ({
  incidentCall,
  isOpen,
  onClose,
}: {
  incidentCall: IncidentCall;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    trigger: onDelete,
    genericError,
    isMutating,
  } = useAPIMutation(
    "incidentCallsGetForLatestForIncident",
    { incidentId: incidentCall.incident_id },
    async (apiClient) => {
      await apiClient.incidentCallsDelete({ id: incidentCall.id });
    },
    {
      onSuccess: () => {
        onClose();
      },
    },
  );

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={onClose}
      title="Remove call"
      onConfirm={() => onDelete({ id: incidentCall.id })}
      saving={isMutating}
    >
      <p>Are you sure you want to remove this call?</p>
      <ErrorMessageUI message={genericError} />
    </ConfirmationDialog>
  );
};
