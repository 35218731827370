import { Button, ButtonTheme, Icon, IconEnum, Tooltip } from "@incident-ui";

export const TypeAttributeListItem = ({
  name,
  icon,
  onDelete,
  iconTooltip,
}: {
  name: string;
  icon: IconEnum;
  iconTooltip?: string;
  onDelete?: () => void;
}): React.ReactElement => {
  const iconComponent = (
    <Icon id={icon} className="shrink-0 mr-2 text-slate-700" />
  );

  return (
    <li className="p-2 text-sm flex-between">
      <p className="flex-center-y">
        {iconTooltip ? (
          <Tooltip content={iconTooltip}>
            <div>{iconComponent}</div>
          </Tooltip>
        ) : (
          iconComponent
        )}
        {name} {iconTooltip && <span className="sr-only">{iconTooltip}</span>}
      </p>
      {onDelete != null && (
        <Button
          analyticsTrackingId="incident-types-remove-attribute"
          theme={ButtonTheme.Naked}
          icon={IconEnum.Close}
          title="Remove"
          onClick={onDelete}
        />
      )}
    </li>
  );
};
