import {
  IntegrationSettingsProviderEnum as IntegrationProvider,
  LegacyIncidentTriggersGetConfigExternalResourceTypeEnum,
  LegacyIncidentTriggersUpdateConfigExternalResourceTypeEnum,
} from "@incident-io/api";
import { FormModalV2 } from "@incident-shared/forms/v2/FormV2";
import { IntegrationsListObject } from "@incident-shared/integrations";
import { Button, ButtonTheme, ContentBox, ModalFooter } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { useAPI, useAPIMutation } from "src/utils/swr";

import DatadogAttachmentScreenshot from "./datadog_attachment_screenshot.png";

enum DatadogIntegrationStatus {
  NotInstalled = "NotInstalled",
  InstalledWithoutTrigger = "InstalledWithoutTrigger",
  InstalledWithTrigger = "InstalledWithTrigger",
}

export const ConnectToDatadogModal = ({
  onClose,
  allIntegrations,
}: {
  onClose: () => void;
  allIntegrations: IntegrationsListObject[];
}): React.ReactElement | null => {
  const installedEscalator = allIntegrations.find(
    (x) =>
      (x.provider === IntegrationProvider.Opsgenie ||
        x.provider === IntegrationProvider.Pagerduty) &&
      x.installed,
  );
  const screenshot = (
    <img
      src={DatadogAttachmentScreenshot}
      alt={"screenshot of the incident.io datadog attachment"}
      className={"w-full h-auto my-4 mx-auto border border-stroke rounded-2"}
    />
  );
  const { showArticle } = useIntercom();
  const sharedContent = (
    <>
      <p className="text-sm text-slate-700 mt-2">
        That means when you join an incident channel, you&apos;ll see a graph of
        the triggered monitor as well as the description you wrote for that
        monitor&apos;s notification - no more clicking around to find the
        information you need, it&apos;s all there right in the channel.
      </p>
      <p className="text-sm text-slate-700 mt-2">
        In order for this to work, you&apos;ll need to:
        <ul className="list-disc list-inside">
          <li>
            <Button
              theme={ButtonTheme.Link}
              analyticsTrackingId="connect-datadog-integration-datadog-docs"
              href="https://docs.datadoghq.com/monitors/notify/"
              target="_blank"
            >
              Have your Datadog monitors notify{" "}
              {installedEscalator?.provider_name || "your escalator"}
            </Button>
          </li>
          <li>
            <Button
              theme={ButtonTheme.Link}
              analyticsTrackingId="connect-datadog-integration-incident-docs"
              href="https://help.incident.io/en/articles/5948111-can-we-automatically-create-incidents"
              target="_blank"
            >
              Have {installedEscalator?.provider_name || "your escalator"}{" "}
              configured to automatically create incidents
            </Button>
          </li>
        </ul>
      </p>
      <p className="text-sm text-slate-700 mt-2">
        You can read more about this integration in our{" "}
        <Button
          analyticsTrackingId="connect-datadog-integration-help"
          theme={ButtonTheme.Link}
          onClick={() => showArticle(6930062)}
        >
          help center.
        </Button>
      </p>
      {screenshot}
    </>
  );

  const supportedEscalators = allIntegrations.filter(
    (x) =>
      x.provider === IntegrationProvider.Opsgenie ||
      x.provider === IntegrationProvider.Pagerduty,
  );

  const externalResourceType =
    installedEscalator === undefined
      ? undefined
      : installedEscalator.provider === IntegrationProvider.Pagerduty
      ? LegacyIncidentTriggersUpdateConfigExternalResourceTypeEnum.PagerDutyIncident
      : installedEscalator.provider === IntegrationProvider.Opsgenie
      ? LegacyIncidentTriggersUpdateConfigExternalResourceTypeEnum.OpsgenieAlert
      : undefined;

  const { data: triggerData, isLoading } = useAPI(
    externalResourceType === undefined
      ? null
      : "legacyIncidentTriggersGetConfig",
    {
      externalResourceType:
        externalResourceType as unknown as LegacyIncidentTriggersGetConfigExternalResourceTypeEnum,
    },
  );

  const integrationStatus = !installedEscalator
    ? DatadogIntegrationStatus.NotInstalled
    : triggerData?.incident_trigger_config?.enabled
    ? DatadogIntegrationStatus.InstalledWithTrigger
    : DatadogIntegrationStatus.InstalledWithoutTrigger;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient) => {
      await apiClient.integrationsDatadogInstall();
    },
    {
      onSuccess: onClose,
    },
  );

  const formMethods = useForm();

  if (integrationStatus === DatadogIntegrationStatus.InstalledWithTrigger) {
    return (
      <FormModalV2
        genericError={genericError}
        formMethods={formMethods}
        analyticsTrackingId="connect-datadog-integration"
        title="Connect to Datadog"
        onClose={onClose}
        disableQuickClose={false}
        onSubmit={onSubmit}
        loading={isLoading}
        footer={
          <ModalFooter
            saving={saving}
            onClose={onClose}
            confirmButtonText="Connect"
            confirmButtonType="submit"
          />
        }
      >
        <ContentBox className="p-4">
          <p className="text-sm text-slate-700">
            Our Datadog integration connects via your existing{" "}
            {installedEscalator?.provider_name} integration to automatically
            pull through information about any monitor that triggers an alert.
          </p>
          {sharedContent}
        </ContentBox>
      </FormModalV2>
    );
  } else if (
    integrationStatus === DatadogIntegrationStatus.InstalledWithoutTrigger
  ) {
    return (
      <FormModalV2
        formMethods={formMethods}
        genericError={genericError}
        analyticsTrackingId="connect-datadog-integration"
        title="Connect to Datadog"
        onClose={onClose}
        disableQuickClose={false}
        onSubmit={onSubmit}
        loading={isLoading}
      >
        <p className="text-sm text-slate-700">
          Our Datadog integration connects via your existing{" "}
          {installedEscalator?.provider_name} integration to automatically pull
          through information about any monitor that triggers an alert.
        </p>
        {sharedContent}
        <p className="text-sm text-slate-700">
          You must enable incident triggers in your{" "}
          {installedEscalator?.provider_name} integration in order to install
          the Datadog integration.
        </p>
        <Button
          analyticsTrackingId={`configure=${installedEscalator?.provider}-for-datadog`}
          className="flex-center w-full mt-4"
          onClick={() => {
            window.location.href = `/settings/integrations/${installedEscalator?.provider}`;
          }}
        >
          Configure {installedEscalator?.provider_name}
        </Button>
      </FormModalV2>
    );
  } else {
    return (
      <FormModalV2
        formMethods={formMethods}
        genericError={genericError}
        analyticsTrackingId="connect-datadog-integration"
        title="Connect to Datadog"
        onClose={onClose}
        disableQuickClose={false}
        onSubmit={onSubmit}
        loading={isLoading}
      >
        <p className="text-sm text-slate-700">
          Our Datadog integration connects via an existing{" "}
          {supportedEscalators.map((x) => x.provider_name).join(" or ")}{" "}
          integration to automatically pull through information about any
          monitor that triggers an alert.
        </p>
        {sharedContent}
        <p className={"text-sm text-slate-700 mt-2"}>
          You must have an existing{" "}
          {supportedEscalators.map((x) => x.provider_name).join(" or ")}{" "}
          integration in order to install the Datadog integration.
        </p>
        <div className="mt-4 space-x-4 w-full flex items-center justify-between">
          {supportedEscalators.map((x) => (
            <Button
              key={x.provider}
              analyticsTrackingId={`configure-${x.provider}-for-datadog`}
              className="flex-center w-full"
              onClick={() => {
                window.location.href = `/settings/integrations/${x.provider}`;
              }}
            >
              Connect to {x.provider_name}
            </Button>
          ))}
        </div>
      </FormModalV2>
    );
  }
};

export const DatadogTeaser = () => {
  return (
    <img
      src={DatadogAttachmentScreenshot}
      alt={"screenshot of the incident.io datadog attachment"}
      className={"w-full h-auto my-4 mx-auto border border-stroke rounded-2"}
    />
  );
};
