import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import React from "react";
import { AnalyticsMetadata } from "src/contexts/AnalyticsContext";
import { tcx } from "src/utils/tailwind-classes";

export type FloatingFooterProps = {
  /* Classname for the container */
  containerClassName?: string;
  /* Whether to show loading spinner */
  saving?: boolean;
  /* Disable the primary CTA */
  disabled?: boolean;
  /* What to do when cancel button is clicked (without it, the button won't render) */
  onClose?: () => void;
  /* Text for primary CTA */
  confirmButtonText?: string;
  /* Text for cancel button */
  cancelButtonText?: string;
  /* The name of the analytics event for the confirm button */
  analyticsTrackingId?: string;
  /* Any additional data for analytics */
  analyticsTrackingMetadata?: AnalyticsMetadata;
  gadget?: React.ReactElement;
  buttonsClassName?: string;
} & (
  | {
      /* What to do on primary CTA click */
      hideConfirmButton?: boolean;
      onConfirm: () => void;
      confirmButtonType: "button";
      children?: never;
    }
  | {
      hideConfirmButton?: boolean;
      onConfirm?: never;
      confirmButtonType: "submit";
      children?: never;
    }
  | {
      hideConfirmButton?: boolean;
      onConfirm?: never;
      confirmButtonType?: never;
      /* Optionally pass in children instead of configuring buttons */
      children: React.ReactElement | (React.ReactElement | null)[];
    }
  | {
      /* Don't render the primary CTA */
      hideConfirmButton: true;
      onConfirm?: never;
      confirmButtonType?: never;
      children?: React.ReactElement | (React.ReactElement | null)[];
    }
);

export const FloatingFooter = ({
  error,
  confirmButtonType,
  onConfirm,
  containerClassName,
  children,
  onClose,
  analyticsTrackingId,
  confirmButtonText,
  buttonsClassName,
  analyticsTrackingMetadata,
  disabled,
  saving,
  hideConfirmButton,
  cancelButtonText,
}: FloatingFooterProps & { error?: string | null }): React.ReactElement => {
  const onClickProp =
    confirmButtonType === "submit" ? {} : { onClick: onConfirm };
  return (
    <div
      className={tcx(
        "sticky bottom-[-6rem] flex justify-end space-x-2 py-4 bg-white border-t-2 border-slate-100 !mt-8",
        containerClassName,
      )}
    >
      {children ? (
        children
      ) : (
        <>
          <ErrorMessageUI message={error} />
          {onClose && (
            <Button
              analyticsTrackingId={`${analyticsTrackingId}-cancel`}
              analyticsTrackingMetadata={analyticsTrackingMetadata}
              onClick={onClose}
              className={tcx(buttonsClassName, "flex-center")}
            >
              {cancelButtonText || "Cancel"}
            </Button>
          )}
          {!hideConfirmButton && (
            <Button
              className={tcx(buttonsClassName, "flex-center")}
              type={confirmButtonType}
              analyticsTrackingId={`${analyticsTrackingId}-submit`}
              analyticsTrackingMetadata={analyticsTrackingMetadata}
              theme={ButtonTheme.Primary}
              disabled={disabled}
              loading={saving}
              {...onClickProp}
            >
              {confirmButtonText || "Save"}
            </Button>
          )}
        </>
      )}
    </div>
  );
};
