import { ErrorMessageUI } from "@incident-shared/forms/ErrorMessage";
import { Modal, ModalContent, ModalFooter } from "@incident-ui";
import { compact } from "lodash";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { StatusSelect } from "../../incident/statuses/StatusSelect";
import { BulkNoTriggersNotice } from "./BulkNoTriggersNotice";

export type BulkSetIncidentStatusFormData = {
  to_incident_status_id: string;
};

export function BulkSetIncidentStatusForm({
  incidentIDs,
  onClose,
  onSubmit,
}: {
  incidentIDs: string[];
  onClose: () => void;
  onSubmit: () => void;
}): React.ReactElement {
  const formMethods = useForm<BulkSetIncidentStatusFormData>({});
  const { handleSubmit } = formMethods;

  const [bulkErrors, setBulkErrors] = useState<string[] | null>(null);
  const analytics = useAnalytics();

  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const {
    trigger: submit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsList",
    {},
    async (apiClient, data: BulkSetIncidentStatusFormData) => {
      analytics?.track("bulkActionApplied", {
        action: "set status",
        numIncidents: incidentIDs.length,
      });

      const { results } = await apiClient.incidentsBulkCreateUpdate({
        bulkCreateUpdateRequestBody: {
          incident_ids: incidentIDs,
          incident_update: {
            to_incident_status_id: data.to_incident_status_id,
          },
        },
      });

      const errors = compact(results.map((result) => result.error));
      if (errors && errors.length !== 0) {
        setBulkErrors(errors);
      } else {
        onSubmit();
      }
    },
    {
      onSuccess: refreshIncidentList,
    },
  );

  return (
    <Modal
      as="form"
      onSubmit={handleSubmit(submit)}
      title={"Set Incident Status"}
      isOpen
      analyticsTrackingId="bulk-set-status"
      onClose={onClose}
    >
      <ModalContent>
        <FormProvider {...formMethods}>
          <StatusSelect
            formMethods={formMethods}
            allLifecycles
            name="to_incident_status_id"
          />
        </FormProvider>
        <BulkNoTriggersNotice className="mt-4" />
        {genericError && <ErrorMessageUI message={genericError} />}
        {bulkErrors && (
          <ErrorMessageUI
            message={`We encountered a problem updating ${bulkErrors.length} of your incidents. If you keep encountering errors, please contact support.`}
          />
        )}
      </ModalContent>
      <ModalFooter
        hideBorder
        confirmButtonText={"Apply"}
        saving={saving}
        onClose={onClose}
        confirmButtonType="submit"
      />
    </Modal>
  );
}
